var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Organisation = /** @class */ (function () {
            function Organisation(def) {
                this.addresses = [];
                this.telephoneNumbers = [];
                this.mobileNumbers = [];
                this.faxNumbers = [];
                this.emailAddresses = [];
                this.partyRoles = [];
                if (def != null) {
                    this.id = def.Id;
                    this.name = def.Name;
                    this.addresses = _(def.Addresses).map(function (a) { return new Model.Address(a); });
                    this.telephoneNumbers = _(def.TelephoneNumbers).map(function (t) { return new Model.TelephoneNumber(t); });
                    this.mobileNumbers = _(def.MobileNumbers).map(function (m) { return new Model.TelephoneNumber(m); });
                    this.faxNumbers = _(def.FaxNumbers).map(function (f) { return new Model.TelephoneNumber(f); });
                    this.emailAddresses = _(def.EmailAddresses).map(function (e) { return new Model.Email(e); });
                    this.partyRoles = def.PartyRoles || [];
                }
            }
            Organisation.prototype.getAllNumbers = function () {
                return _.union(this.telephoneNumbers, this.mobileNumbers, this.faxNumbers);
            };
            Organisation.prototype.getHealthCareOrganisationPartyRole = function () {
                return _(this.partyRoles)
                    .find(function (pr) { return pr.PartyRoleType.Id == RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareOrganisation; });
            };
            return Organisation;
        }());
        Model.Organisation = Organisation;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
