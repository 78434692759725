/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var PatientNameController = /** @class */ (function (_super) {
                __extends(PatientNameController, _super);
                function PatientNameController($scope, $translate, toaster, patientSvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.patientSvc = patientSvc;
                    _this.$onInit();
                    return _this;
                }
                PatientNameController.prototype.$onInit = function () {
                    var _this = this;
                    this.patientSvc.getContactDetails(this.$scope.careRequest.matchedPatientId)
                        .success(function (ags) {
                        _this.$scope.patient = {
                            firstName: ags.FirstName,
                            lastName: ags.LastName
                        };
                        _this.$scope.careRequest.matchedPartyId = ags.PartyId;
                    })
                        .error(function (m) { return _this.errorCallback(m); });
                    if (this.$scope.careRequestTemplate.isFinal && this.$scope.careRequestTemplate.careRequestStepType.Id === RemeCare.Shared.Contract.Code.CareRequestStepType.Acceptance) {
                        this.getCarePlansForPatient(this.$scope.careRequest.matchedPatientId);
                    }
                };
                PatientNameController.prototype.getCarePlansForPatient = function (patientId) {
                    var _this = this;
                    this.patientSvc.getCarePlans(patientId)
                        .success(function (ags) {
                        _this.$scope.carePlans = _(ags).map(function (x) { return new RemeCare.Model.Careplan(x); }).map(function (x) { return ({
                            Id: x.id,
                            Text: x.statusOpen ? x.name + _this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStartDate() })
                                : x.name + _this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStatusChangedDate() })
                        }); });
                    })
                        .error(function (m) { return _this.errorCallback(m); });
                };
                return PatientNameController;
            }(Directive.CareRequestPartControllerBase));
            var PatientNameDirective = /** @class */ (function (_super) {
                __extends(PatientNameDirective, _super);
                function PatientNameDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = ['$scope', '$translate', 'toaster', 'patientSvc',
                        function ($scope, $translate, toaster, careRequestSvc, patientSvc) {
                            return new PatientNameController($scope, $translate, toaster, careRequestSvc, patientSvc);
                        }];
                    _this.templateUrl = 'views/careRequest/directive/patientName.html';
                    return _this;
                }
                return PatientNameDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestPatientName', function () { return new PatientNameDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
