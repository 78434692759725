module RemeCare.Core.Controllers {
    import TypeAndSpecialty = RemeCare.Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;

    interface ISearchHealthCareProfessionalPartyCriteria {
        firstName?: string;
        lastName?: string;
        email?: string;
        rizivNumber?: string;
        city?: string;
        zipCode?: string;
    }

    interface ISearchHealthCareProfessionalPartyScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        searchHealthCareProfessionalPartyGrid:Shared.Framework.Grid.Grid<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>;
        isBusy: boolean;
        criteriaCollapsed: boolean;
        totalItems: number;
        title: string;
        typeAndSpecialties: Array<TypeAndSpecialty>;

        prefill: Contract.HealthCareParty.Read.IHealthCareProfessionalParty;

        clearSearchParams: () => void;
        toggleCriteria: () => void;
        search: () => void;
    }

    class SearchHealthCareProfessionalPartyCtrl extends Shared.Framework.ControllerBase<ISearchHealthCareProfessionalPartyScope> {
        constructor(
            $scope: ISearchHealthCareProfessionalPartyScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            private gridBuilderSvc:Shared.Framework.Grid.GridBuilderFactory,
            private uiGridConstants,
            private healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc) {

            super($scope, $translate, toaster);

            this.$scope.clearSearchParams = () => this.clearSearchParams();
            this.$scope.toggleCriteria = () => this.toggleCriteria();
            this.$scope.search = () => this.search();
        }

        public $onInit(): void {
            this.$scope.searchHealthCareProfessionalPartyGrid = this.buildGrid();

            if (this.$scope.prefill) {
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.firstName = this.$scope.prefill.FirstName;
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.lastName = this.$scope.prefill.LastName;
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.email = this.$scope.prefill.Email;
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.rizivNumber = this.$scope.prefill.IdentificationNumber;
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.zipCode = this.$scope.prefill.ZipCode;
                this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.city = this.$scope.prefill.City;
                this.search();
            }
        }

        clearSearchParams(): void {
            this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria = {}
        }

        toggleCriteria(): void {
            this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
        }

        search(): void {
            this.$scope.searchHealthCareProfessionalPartyGrid.pagingOptions.currentPage = 1;
            this.$scope.searchHealthCareProfessionalPartyGrid.search();
        }

        private executeSearch(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchHealthCareProfessionalPartyCriteria)
            : JQueryPromise<Shared.Contract.ISearchResult<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>> {
            var deferred = jQuery.Deferred<Shared.Contract.ISearchResult<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>>();
            this.$scope.isBusy = true;
            var query: Contract.HealthCareParty.Read.Query.ISearchHealthCareProfessionalPartyQuery = {
                page: page,
                pageSize: pageSize,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                email: criteria.email,
                identificationNumber: criteria.rizivNumber,
                city: criteria.city,
                zipCode: criteria.zipCode,
                typeAndSpecialties: this.$scope.typeAndSpecialties
            }
            this.healthCareProfessionalSearchSvc.searchProfessionals(query)
                .success((data) => {
                    var professionals = [];
                    angular.forEach(data.Items, (professional: any) => {
                        professional.ZipCity = (professional.ZipCode ? professional.ZipCode + ' ' : '') + (professional.City ? professional.City : '');
                        professionals.push(professional);
                    });
                    data.Items = professionals;
                    deferred.resolve(data);
                    this.$scope.isBusy = false;
                    this.$scope.criteriaCollapsed = true;
                }).error((message) => {
                    super.errorCallback(message);
                    deferred.reject();
                });
            return deferred;
        }

        private buildGrid():Shared.Framework.Grid.Grid<Contract.HealthCareParty.Read.IHealthCareProfessionalParty> {
            var grid = this.gridBuilderSvc
                .createGridBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>((page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchHealthCareProfessionalPartyCriteria) =>
                    this.executeSearch(page, pageSize, sortField, sortDirection, criteria))
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('Email', 'General.Email')
                .addColumn('ZipCity', 'Views.General.Search.ZipAndCity')
                .addSelectButtonColumn((selected) => this.resultSelected(selected))
                .build();
            return grid;
        }

        private resultSelected(result: Contract.HealthCareParty.Read.IHealthCareProfessionalParty): void {
            this.$scope.$close(result);
        }
    }

    angular.module('RemeCareApp')
        .controller('searchHealthCareProfessionalPartyCtrl', (
            $scope: ISearchHealthCareProfessionalPartyScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            uiGridConstants,
            healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc
        ) => new SearchHealthCareProfessionalPartyCtrl(
            $scope,
            $translate,
            toaster,
            gridBuilderSvc,
            uiGridConstants,
            healthCareProfessionalSearchSvc));
}