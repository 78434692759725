/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var DateHelper = RemeCare.Shared.DateHelper;
        var LineChartNumericExternalController = /** @class */ (function (_super) {
            __extends(LineChartNumericExternalController, _super);
            // @ngInject
            function LineChartNumericExternalController($rootScope, $locale, dateHelper, spinnerSvc, toaster, patientSvc, $q, carePlanApiSvc, telemonitoringApiSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.toaster = toaster;
                _this.patientSvc = patientSvc;
                _this.$q = $q;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.telemonitoringApiSvc = telemonitoringApiSvc;
                return _this;
            }
            LineChartNumericExternalController.prototype.init = function () {
                var _this = this;
                this.chartConfigs = [
                    {
                        options: {
                            chart: {
                                animation: false,
                                type: 'line',
                                alignTicks: true,
                                height: 200,
                                zoomType: 'xy',
                                spacingTop: 40,
                                marginLeft: 37,
                                marginRight: 37,
                            },
                            xAxis: this.getXAxisConfig(),
                            credits: {
                                enabled: false
                            },
                            exporting: {
                                enabled: false
                            },
                            tooltip: {
                                xDateFormat: this.getDateFormat(true),
                                shared: true
                            },
                            legend: {
                                enabled: this.showLegend
                            }
                        },
                        series: [],
                        title: {
                            text: null
                        }
                    }
                ];
                return this.patientSvc.getPatient(this.patientId)
                    .success(function (p) {
                    _this.patientCode = p.PatientNumber.toString();
                    _this.externalDataSourceParameters = _this.getExternalDataSourceParameters();
                }).catch(function (e) {
                    _this.toaster.error(e);
                    throw e;
                });
            };
            LineChartNumericExternalController.prototype.getExternalDataSourceParameters = function () {
                return _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.ExternalDataSourceParameter; })
                    .map(function (mps) { return mps; })
                    .value();
            };
            LineChartNumericExternalController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var graphPromises, graphs, error_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.errorMessage = null;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                graphPromises = _(this.externalDataSourceParameters)
                                    .map(function (edsp) { return _this.createGraph(edsp); });
                                return [4 /*yield*/, this.$q.all(graphPromises)];
                            case 2:
                                graphs = _a.sent();
                                this.configureChart(graphs);
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _a.sent();
                                this.toaster.error(error_1);
                                this.errorMessage = error_1;
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            LineChartNumericExternalController.prototype.createGraph = function (externalDataSourceParameter) {
                var _this = this;
                var query = {
                    parameterCode: externalDataSourceParameter.externalCharacteristicReference,
                    context: externalDataSourceParameter.externalContextReference,
                    patientCode: this.patientCode,
                    from: DateHelper.toServerDateString(this.dateInfo.fromDate),
                    until: DateHelper.toServerDateString(this.getUntilDate())
                };
                return this.telemonitoringApiSvc.findMeasurements(query)
                    .then(function (m) {
                    var graph = _this.measurementsToGraph(externalDataSourceParameter, m.data);
                    var externalNumericGraph = new Patient.Model.NumberGraph(graph);
                    return externalNumericGraph;
                }).catch(function (e) {
                    return _this.$q.reject(e.data);
                });
            };
            LineChartNumericExternalController.prototype.measurementsToGraph = function (externalDataSourceParameter, measurements) {
                var graph = {
                    Scale: {
                        Id: externalDataSourceParameter.unit,
                        Text: externalDataSourceParameter.unit
                    },
                    Subject: {
                        Id: externalDataSourceParameter.labelCharacteristic,
                        Text: externalDataSourceParameter.labelCharacteristic
                    },
                    GraphPoints: _(measurements).map(function (m) {
                        return {
                            X: m.RegistrationDateTime,
                            Y: m.Value
                        };
                    })
                };
                return graph;
            };
            LineChartNumericExternalController.prototype.configureChart = function (graphs) {
                var _this = this;
                this.chartConfigs[0].options.xAxis = this.getXAxisConfig();
                var yAxes = this.getYAxis(graphs);
                this.chartConfigs[0].options.yAxis = yAxes;
                this.chartConfigs[0].series = _(graphs).map(function (g) { return _this.getDataSeries(g, yAxes); });
                this.chartConfigs[0].loading = false;
            };
            LineChartNumericExternalController.prototype.getYAxis = function (graphs) {
                var _this = this;
                var units = _(graphs).chain().map(function (g) { return g.scale; }).uniq(false, function (s) { return s != null ? s.Id : null; }).value();
                var axes = _(units).map(function (u) {
                    var max = _(graphs).chain()
                        .filter(function (g) { return (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id); })
                        .map(function (g) { return _this.getMaxYScaleValue(g); })
                        .max()
                        .value();
                    var min = _(graphs).chain()
                        .filter(function (g) { return (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id); })
                        .map(function (g) { return _this.getMinYScaleValue(g); })
                        .min()
                        .value();
                    return {
                        title: {
                            text: u != null ? u.Text : null
                        },
                        max: max,
                        min: min,
                        id: u != null && u.Id != null ? u.Id : 'unscaled'
                    };
                });
                var groupedAxes = _(axes).chain().groupBy(function (a) { return a.min + ";" + a.max; })
                    .map(function (ag) {
                    return {
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            text: _(ag).chain().map(function (a) { return a.title.text; }).filter(function (t) { return t != null; }).value().join(' - '),
                            y: -20,
                            x: -27,
                            textAlign: 'left',
                        },
                        max: _(ag).first().max,
                        min: _(ag).first().min,
                        id: _(ag).map(function (a) { return a.id; }).join(';'),
                        startOnTick: _this.monitoringPart.ordinatePercentageBelowLowest != null,
                        endOnTick: _this.monitoringPart.ordinatePercentageAboveHighest != null,
                    };
                })
                    .each(function (a, index) {
                    a.opposite = index % 2 === 1;
                    if (a.opposite) {
                        a.title.textAlign = 'right';
                        a.title.x = 27;
                        a.labels = {
                            align: 'left',
                            x: 0,
                            y: -2,
                        };
                    }
                    else {
                        a.labels = {
                            align: 'right',
                            x: 0,
                            y: -2,
                        };
                    }
                })
                    .value();
                if (!groupedAxes[0] || !groupedAxes[0].title.text) {
                    this.chartConfigs[0].options.chart.marginLeft = 37;
                }
                if (!groupedAxes[1] || !groupedAxes[1].title.text) {
                    this.chartConfigs[0].options.chart.marginRight = 37;
                }
                return groupedAxes;
            };
            LineChartNumericExternalController.prototype.getDataSeries = function (graph, yAxes) {
                var _this = this;
                var monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) { return mps.type === MonitoringPartSourceType.ExternalDataSourceParameter
                    && mps.unit === graph.scale.Id
                    && mps.labelCharacteristic === graph.subject.Id; });
                var chartLine = monitoringPartSource.sourceParameterRepresentation;
                var id = graph.scale && graph.scale.Id != null ? graph.scale.Id : 'unscaled';
                var yAxis = _.find(yAxes, function (a) { return a.id.indexOf(id) >= 0; });
                return {
                    animation: false,
                    data: _(graph.graphPoints).chain().map(function (gp) { return _this.getDataPoint(gp); }).sortBy(function (gp) { return gp[0]; }).value(),
                    color: chartLine.colour,
                    name: graph.subject.Text,
                    lineWidth: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Thin ? 1 : 2,
                    dashStyle: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                    yAxis: yAxis.id,
                    step: false,
                    marker: {
                        enabled: true
                    },
                    enableMouseTracking: true
                };
            };
            LineChartNumericExternalController.prototype.getDataPoint = function (graphPoint) {
                return [moment(graphPoint.x).valueOf(), graphPoint.y];
            };
            return LineChartNumericExternalController;
        }(Patient.ChartMonitoringPartController));
        var LineChartNumericExternalComponent = /** @class */ (function (_super) {
            __extends(LineChartNumericExternalComponent, _super);
            function LineChartNumericExternalComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = LineChartNumericExternalController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return LineChartNumericExternalComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringLineChartNumericExternal', new LineChartNumericExternalComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
