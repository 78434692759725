var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MediaDocumentsController = /** @class */ (function () {
            function MediaDocumentsController($stateParams) {
                this.$stateParams = $stateParams;
            }
            MediaDocumentsController.prototype.$onInit = function () {
                this.patientId = this.$stateParams.patientId;
            };
            return MediaDocumentsController;
        }());
        Patient.remeCarePatientModule.component('mediaDocuments', {
            controller: MediaDocumentsController,
            templateUrl: 'patient/controllers/mediaDocuments/mediaDocuments.html'
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
