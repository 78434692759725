var RemeCare;
(function (RemeCare) {
    var ValidationService = /** @class */ (function () {
        function ValidationService(nationalNumberSvc) {
            this.nationalNumberSvc = nationalNumberSvc;
        }
        ValidationService.prototype.verifyEmail = function (email) {
            if (!email) {
                return true;
            }
            var validMail = email.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
            return validMail != null;
        };
        return ValidationService;
    }());
    RemeCare.ValidationService = ValidationService;
    RemeCare.remeCareAppModule.service('validationSvc', ValidationService);
})(RemeCare || (RemeCare = {}));
