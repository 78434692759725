module RemeCare.Patient {
    
    interface IEditCareTeamPartyScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        careTeamParty: Contract.HealthCareParty.Read.ICareTeamPartyAssignment;
        name: string;
        partyRoleId: Shared.Contract.Guid;
        dateInfo: {
            validFromDate: Date;
            validUntilDate: Date;
        }
        form: ng.IFormController;
        today: Date;
        minDate(): Date;
        validFromInPast(): boolean;
        confirm(): void;
        readOnly: boolean;
    }

    class EditCareTeamPartyController extends Shared.Framework.ControllerBase<IEditCareTeamPartyScope> {
        
        constructor(
            $scope: IEditCareTeamPartyScope,
            $translate: ng.translate.ITranslateService,
            toaster: Shared.Framework.Toaster,
            private patientId: Shared.Contract.Guid,
            private patientSvc: PatientService) {
            super($scope, $translate, toaster);
            $scope.minDate = () => this.minDate();
            $scope.validFromInPast = () => this.validFromInPast();
            $scope.confirm = () => this.confirm();
        }

        public $onInit() {
            this.$scope.dateInfo = {
                validFromDate: Shared.DateHelper.serverDateStringToDate(this.$scope.careTeamParty.ValidFromDate),
                validUntilDate: Shared.DateHelper.serverDateStringToDate(this.$scope.careTeamParty.ValidUntilDate)
            }
            this.$scope.today = Shared.DateHelper.today();
        }

        private validFromInPast(): boolean {
            return this.$scope.dateInfo.validFromDate != null
                && moment(this.$scope.dateInfo.validFromDate).isBefore(this.$scope.today)
        }

        private minDate(): Date {
            if (this.$scope.dateInfo.validFromDate != null
                && moment(this.$scope.dateInfo.validFromDate).isAfter(this.$scope.today)) {
                return this.$scope.dateInfo.validFromDate;
            }
            return this.$scope.today;
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.patientSvc.assignActorRole(this.patientId, this.$scope.careTeamParty.CarePlan.TherapyId,
                this.$scope.careTeamParty.Id, this.$scope.careTeamParty.ActorRole.Id, this.$scope.partyRoleId,
                this.$scope.dateInfo.validFromDate, this.$scope.dateInfo.validUntilDate)
                .success(() => this.$scope.$close())
                .error(e => this.errorCallback(e));
        }
    }

    remeCarePatientModule.controller('editCareTeamPartyCtrl', (
        $scope: IEditCareTeamPartyScope,
        $translate: ng.translate.ITranslateService,
        toaster: Shared.Framework.Toaster,
        patientId: Shared.Contract.Guid,
        patientSvc: PatientService
    ) => new EditCareTeamPartyController(
        $scope,
        $translate,
        toaster,
        patientId,
        patientSvc
    ));
}