var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Custom;
    (function (Custom) {
        var CardioCoachService = /** @class */ (function (_super) {
            __extends(CardioCoachService, _super);
            function CardioCoachService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            CardioCoachService.prototype.evaluateUptitrationAsync = function (registrations, errorCallback) {
                var params = registrations;
                return this.postPromise("CardioCoach/" + registrations.actionId + "/UptitrationEvaluation", params, null, null, errorCallback);
            };
            CardioCoachService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return CardioCoachService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        Custom.CardioCoachService = CardioCoachService;
        Custom.remeCareCustomModule.service('cardioCoachSvc', CardioCoachService);
    })(Custom = RemeCare.Custom || (RemeCare.Custom = {}));
})(RemeCare || (RemeCare = {}));
