namespace RemeCare.Patient {
    import FindCarePlansQuery = Contract.Patient.Read.IFindCarePlansQuery;
    import Framework = Shared.Framework;

    interface IPrescriptionScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        medicationInfo: { medication: Shared.Contract.Read.IMedication };
        prescription: Model.Prescription;
        dateInfo: {
            minDate: Date;
            minUntilDate: Date;
            fromDateDisabled: boolean;
        };
        isNew: boolean;
        readOnly: boolean;
        canChangeMedication: boolean;
        enableAdHoc: boolean;
        enablePeriodicity: boolean;
        isFollowUpSchema: boolean;
        prescriptionForm: ng.IFormController;

        clearMedication(): void;
        searchMedication(): void;
        save(): void;
        delete(): void;
        prescriptionTypeChanged(): void;
        setDateRanges(): void;
        changeFollowedUpSchema(): void;
        getMedications(medicationName: string): Promise<Shared.Contract.Read.IMedication[]>;
        evaluateMedication(medication: Shared.Contract.Read.IMedication): void;
        addAdministrationInformation(): void;
        hasAdministrationInformation(): boolean;
    }

    class PrescriptionController extends Shared.Framework.ControllerBase<IPrescriptionScope> {
        public static $inject = [
            '$scope',
            '$translate',
            'toaster',
            '$dialog',
            'patientId',
            'modalBuilderFactory',
            'patientSvc',
            'masterdataSvc',
            'medicationSearchSvc',
        ];
        private patientLang: string;
        private initialMedication: Shared.Contract.Read.IMedication;
        constructor(
            protected $scope: IPrescriptionScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly patientId: Shared.Contract.Guid,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientService: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) {
            super($scope, $translate, toaster);
            $scope.clearMedication = () => this.clearMedication();
            $scope.searchMedication = () => this.searchMedication();
            $scope.save = () => this.save();
            $scope.delete = () => this.delete();
            $scope.prescriptionTypeChanged = () => this.prescriptionTypeChanged();
            $scope.setDateRanges = () => this.setDateRanges();
            $scope.changeFollowedUpSchema = () => this.changeFollowedUpSchema();
            $scope.getMedications = (m) => this.getMedicationsAsync(m);
            $scope.evaluateMedication = (m) => this.evaluateMedicationAsync(m);
            $scope.addAdministrationInformation = () => this.addAdministrationInformation();
            $scope.hasAdministrationInformation = () => this.hasAdministrationInformation();
        }

        public $onInit(): void {
            this.$scope.medicationInfo = { medication: null };
            if (this.$scope.prescription == null) {
                this.$scope.isNew = true;
                this.$scope.canChangeMedication = true;
                this.createEmptyPrescription();
            } else {
                this.$scope.isNew = false;
                this.$scope.prescription.onlyIntakeMoments =
                    this.$scope.prescription.isFollowedUp && this.$scope.prescription.startsInThePast();
                this.loadMedicationAsync();
            }
            this.loadPatientDataAsync();
            this.setDateRanges();
            this.watchDuration();
        }

        public addAdministrationInformation(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setTemplateUrl('views/patient/medication/extraAdministrationInformation.html')
                .setController('AdministrationInformationModalCtrl')
                .setScope({
                    prescription: angular.copy(this.$scope.prescription),
                    medicationInfo: angular.copy(this.$scope.medicationInfo),
                })
                .setSize(Framework.Helper.ModalSize.large)
                .setResultCallBack((prescription: Model.Prescription) => {
                    (this.$scope.prescription.prescribedAdministrationInformation =
                        prescription.prescribedAdministrationInformation),
                        (this.$scope.prescription.solventToAddInformation = prescription.solventToAddInformation);
                })
                .build();
        }

        public hasAdministrationInformation(): boolean {
            return this.$scope.prescription
                ? this.$scope.prescription.prescribedAdministrationInformation
                    ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans
                        ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans > 0
                        : false
                    : false
                : false;
        }

        private createEmptyPrescription() {
            this.$scope.prescription = new Model.Prescription(null, null, this.$translate);
            this.$scope.prescription.patientId = this.patientId;
            this.$scope.prescription.makeDefault();
            const tomorrow = moment(Shared.DateHelper.today())
                .add(1, 'days')
                .toDate();
            this.$scope.prescription.validFromDate = tomorrow;
        }

        private async loadPatientDataAsync(): Promise<void> {
            try {
                const patient = await this.patientService.getPatientNameAsync(this.$scope.prescription.patientId);
                this.patientLang = _(patient).first().LanguageISO2Code;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadMedicationAsync(): Promise<void> {
            try {
                this.$scope.medicationInfo.medication = await this.medicationSearchSvc.getMedicationAsync(
                    this.$scope.prescription.medication.ProductId
                );

                this.$scope.canChangeMedication = !!this.$scope.medicationInfo.medication.ActiveSubstance;
                this.initialMedication = this.$scope.medicationInfo.medication;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getMedicationsAsync(medicationName: string): Promise<Shared.Contract.Read.IMedication[]> {
            try {
                const result = await this.medicationSearchSvc.searchMedicationsAsync({
                    medicationName: medicationName,
                    activeSubstance: this.$scope.prescription.isFollowedUp
                        ? this.initialMedication.ActiveSubstance
                        : null,
                    isActive: true,
                    page: 1,
                    pageSize: 1000,
                });
                return result.Items;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private setDateRanges(): void {
            let minUntilDate =
                this.$scope.prescription.validFromDate != null
                    ? new Date(this.$scope.prescription.validFromDate.valueOf())
                    : null;
            if (this.$scope.prescription.isFollowedUp) {
                if (minUntilDate) {
                    if (Shared.DateHelper.today().valueOf() > minUntilDate.valueOf()) {
                        minUntilDate = Shared.DateHelper.today();
                    }
                } else {
                    minUntilDate = Shared.DateHelper.today();
                }
            }
            this.$scope.dateInfo = {
                minDate:
                    this.$scope.prescription.isFollowedUp && !this.$scope.prescription.startsInThePast()
                        ? Shared.DateHelper.tomorrow()
                        : null,
                minUntilDate: minUntilDate,
                fromDateDisabled:
                    !this.$scope.isNew &&
                    this.$scope.prescription.startsInThePast() &&
                    !this.$scope.isFollowUpSchema &&
                    this.$scope.prescription.isFollowedUp,
            };
        }

        private watchDuration(): void {
            this.$scope.$watch(
                (s: IPrescriptionScope) => s.prescription.periodicities[0].duration,
                (newValue, oldValue) => {
                    if (!angular.equals(newValue, oldValue)) {
                        if (newValue == null || newValue.Quantity == null || newValue.Unit == null) {
                            this.$scope.prescription.validUntilDate = null;
                        } else if (newValue.Quantity != null && newValue.Unit != null) {
                            let validUntilDate = Shared.DateHelper.addDuration(
                                this.$scope.prescription.validFromDate,
                                newValue
                            );
                            validUntilDate = Shared.DateHelper.addDuration(validUntilDate, {
                                Quantity: -1,
                                Unit: Shared.Contract.Code.DurationUnit.Days,
                            });
                            this.$scope.prescription.validUntilDate = validUntilDate;
                        }
                    }
                },
                true
            );
        }

        private clearMedication(): void {
            this.$scope.medicationInfo.medication = null;
            this.$scope.prescription.medication = null;
            if (this.$scope.isNew) {
                this.createEmptyPrescription();
            }
        }

        private searchMedication(): void {
            this.modalBuilderFactory
                .createModalBuilder<Shared.Contract.Read.IMedication>()
                .setTemplateUrl('views/patient/medication/searchMedication.html')
                .setController('searchMedicationCtrl')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setResultCallBack((m: Shared.Contract.Read.IMedication) => {
                    this.evaluateMedicationAsync(m);
                })
                .setResolve({
                    productClassIds: () => null,
                    productIds: () => null,
                })
                .setDismissCallBack(() => {
                    if (this.$scope.prescription.medication == null) {
                        this.$scope.$close();
                    }
                })
                .setScope({
                    activeSubstance: this.$scope.prescription.isFollowedUp
                        ? this.$scope.medicationInfo.medication.ActiveSubstance
                        : null,
                    prescriptionId: this.$scope.prescription.isFollowedUp ? this.$scope.prescription.id : null,
                })
                .build();
        }

        private async evaluateMedicationAsync(medication: Shared.Contract.Read.IMedication): Promise<void> {
            if (this.initialMedication != null) {
                this.setMedication(medication);
                return;
            }
            try {
                const careplans = await this.patientService.getCarePlansAsync(this.patientId, {
                    StatusIds: [Shared.Contract.Code.CarePlanStatus.Open, Shared.Contract.Code.CarePlanStatus.OnHold],
                    ProductId: medication.ProductId,
                    ProductClassIds: _(medication.MedicationTypes).map((t) => t.Id),
                } as FindCarePlansQuery);
                this.$scope.prescription.setCarePlans(careplans);
                if (careplans.length > 0) {
                    try {
                        await this.confirmFollowedUpMedicationAsync(medication, careplans);
                        this.setMedication(medication);
                    } catch (e) {
                        this.setMedication(this.initialMedication);
                    }
                } else {
                    this.setMedication(medication);
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async confirmFollowedUpMedicationAsync(
            medication: Shared.Contract.Read.IMedication,
            carePlans: Contract.Patient.Read.ICareplan[]
        ): Promise<void> {
            let message =
                this.$translate.instant('General.Attention') +
                '!' +
                '\n' +
                this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedicationWarning') +
                '\n' +
                '\n' +
                this.$translate.instant('General.CarePlan') +
                ': ';

            message += _(this.$scope.prescription.carePlans)
                .map((cp) => cp.Name)
                .join(', ');

            const result = await this.$dialog.messageBox(
                this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedication'),
                message,
                [
                    { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                    { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                ]
            );
            if (result === 'yes') {
                return this.setFollowedUpSettingsAsync(medication, carePlans);
            } else {
                throw Error;
            }
        }

        private async setFollowedUpSettingsAsync(
            medication: Shared.Contract.Read.IMedication,
            carePlans: Contract.Patient.Read.ICareplan[]
        ): Promise<void> {
            const query: Contract.Patient.Read.IStructuralTherapyActionQuery = {
                productId: medication.ProductId,
                productClassIds: _(medication.MedicationTypes).map((t) => t.Id),
                therapyIds: _(carePlans).map((cp) => cp.TherapyId),
            };
            const stas = await this.patientService.getStructuralTherapyActionsAsync(this.patientId, query);
            this.$scope.enableAdHoc = _(stas).any((s) => s.StartTypeAdHoc);
            this.$scope.enablePeriodicity = _(stas).any((s) => !s.StartTypeAdHoc);
            if (this.$scope.enableAdHoc && !this.$scope.enablePeriodicity) {
                this.$scope.prescription.makeAdHoc(this.patientLang, this.masterdataSvc);
            } else if (this.$scope.enablePeriodicity && !this.$scope.enableAdHoc && this.$scope.prescription.isAdHoc) {
                this.$scope.prescription.makeDefault();
            }
        }

        private setMedication(medication: Shared.Contract.Read.IMedication): void {
            this.$scope.medicationInfo.medication = medication;
            if (medication == null) {
                this.$scope.prescription.medication = null;
            } else {
                this.$scope.prescription.medication = medication;
            }
            this.setDateRanges();
        }

        private save(): void {
            if (this.$scope.prescriptionForm.$invalid) {
                this.$scope.prescriptionForm.$setSubmitted();
                this.showValidationErrorMessage();
                return;
            }

            if (
                this.$scope.prescription.isFollowedUp &&
                !this.$scope.prescription.isAdHoc &&
                (this.$scope.prescription.periodicities == null ||
                    _(this.$scope.prescription.periodicities).size() === 0)
            ) {
                this.toaster.error(this.$translate.instant('Periodicity.PeriodicityRequired'));
                return;
            }
            if (
                this.$scope.prescription.isFollowedUp &&
                !this.$scope.prescription.isAdHoc &&
                _(this.$scope.prescription.periodicities).any((x) => _(x.intraDayTimings).size() === 0)
            ) {
                this.toaster.error(this.$translate.instant('Periodicity.IntraDayTimingRequired'));
                return;
            }

            this.$scope.$close({
                action: 'save',
                prescription: this.$scope.prescription,
            });
        }

        private prescriptionTypeChanged(): void {
            if (this.$scope.prescription.isAdHoc) {
                this.$scope.prescription.makeAdHoc(this.patientLang, this.masterdataSvc);
            } else {
                this.$scope.prescription.makeDefault();
            }
        }

        private delete(): void {
            this.$dialog.confirmBox('General.Confirm', 'Views.PatientFile.Medication.DeleteConfirm', () => {
                this.$scope.$close({
                    action: 'delete',
                    prescription: this.$scope.prescription,
                });
            });
        }

        private changeFollowedUpSchema(): void {
            const message =
                this.$translate.instant('General.Attention') +
                '!' +
                '\n' +
                this.$translate.instant('Views.PatientFile.Medication.MedicationSchemaChangeWarning');
            this.$dialog
                .messageBox(this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedication'), message, [
                    { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                    { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                ])
                .then((result) => {
                    if (result === 'yes') {
                        this.$scope.isNew = true;
                        this.$scope.prescription.onlyIntakeMoments = false;
                        this.$scope.isFollowUpSchema = true;
                        this.$scope.prescription.validFromDate = Shared.DateHelper.tomorrow();
                        this.setDateRanges();
                    }
                });
        }
    }

    remeCarePatientModule.controller('prescriptionCtrl', PrescriptionController);
}
