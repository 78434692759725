'use strict';

module RemeCare.Contract.Core {
    export class SimplifiedRecurrenceStatusses {
        public static xTimesDay: string =  "5756866b-1eac-4b57-b653-2e6e2d071954"; 
        public static xTimesOnYDays: string = "1ecb7150-dffe-4cc5-abdf-8b8636af6b5f";
        public static alternateDay: string = "a25d8618-613b-4639-859c-4c318dc1ff84"; 
        public static weekschedule: string= "d1708c43-c9cc-4526-bc34-5ebdbb5c3e62"; 
        public static other: string = "70b6e8a4-7564-4fec-8506-708c1882055d"; 

        constructor() {
        }
    }
}