var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var MonitoringPartType = RemeCare.Shared.Contract.Code.MonitoringPartType;
        var PartWidth = RemeCare.Shared.Contract.Code.PartWidth;
        var MonitoringPartMergeService = /** @class */ (function () {
            function MonitoringPartMergeService() {
                this.merger = new LineChartNumericMerger()
                    .addMerger(new TableNumericQualitativeMerger())
                    .addMerger(new CumulativeBarChartMerger())
                    .addMerger(new FloatingBarChartMerger())
                    .addMerger(new ColourQualitativeTimeLineMerger())
                    .addMerger(new MedicationAdherenceChartMerger())
                    .addMerger(new MedicationDoseChartMerger())
                    .addMerger(new ActionTableMerger())
                    .addMerger(new ActionTimeLineMerger())
                    .addMerger(new CumulativeObjectiveChartMerger())
                    .addMerger(new ObjectiveScoreTableMerger())
                    .addMerger(new LineChartNumericExternalMerger())
                    .addMerger(new BoxplotMerger());
            }
            MonitoringPartMergeService.prototype.mergeMonitoringParts = function (monitoringParts) {
                var toBeMerged = monitoringParts.slice();
                var merged = [];
                for (var i = 0; i < toBeMerged.length; i++) {
                    var target = toBeMerged[i];
                    var mergeCandidates = toBeMerged.slice(i + 1);
                    if (target.monitoringPart.isMergeable) {
                        for (var j = 0; j < mergeCandidates.length; j++) {
                            if (this.tryMerge(target, mergeCandidates[j])) {
                                toBeMerged.splice(i + j + 1, 1);
                            }
                        }
                    }
                    merged.push(target);
                }
                return merged;
            };
            MonitoringPartMergeService.prototype.tryMerge = function (target, source) {
                var merged = this.merger.tryMerge(target.monitoringPart, source.monitoringPart);
                if (merged) {
                    _.each(source.carePlanIds, function (carePlanId) {
                        if (!_.contains(target.carePlanIds, carePlanId)) {
                            target.carePlanIds.push(carePlanId);
                        }
                    });
                }
                return merged;
            };
            return MonitoringPartMergeService;
        }());
        Patient.MonitoringPartMergeService = MonitoringPartMergeService;
        Patient.remeCarePatientModule.service('monitoringPartMergeSvc', MonitoringPartMergeService);
        var Merger = /** @class */ (function () {
            function Merger() {
            }
            Merger.prototype.addMerger = function (merger) {
                if (this.next) {
                    this.next.addMerger(merger);
                }
                else {
                    this.next = merger;
                }
                return this;
            };
            Merger.prototype.tryMerge = function (target, source) {
                if (!target.isMergeable
                    || !source.isMergeable
                    || target.type !== source.type) {
                    return false;
                }
                if (this.canHandle(target)) {
                    if (!this.canMerge(target, source)) {
                        return false;
                    }
                    this.mergeMonitoringPartSources(target, source);
                    // take longest name of target and source
                    if (target.name && source.name && target.name.length < source.name.length
                        || !target.name && source.name) {
                        target.name = source.name;
                    }
                    // take largest width of target and source
                    if (target.width === PartWidth.Third) {
                        target.width = source.width;
                    }
                    else if (target.width === PartWidth.Half && source.width !== PartWidth.Third) {
                        target.width = source.width;
                    }
                    else if (target.width === PartWidth.TwoThird && source.width === PartWidth.Full) {
                        target.width = source.width;
                    }
                    return true;
                }
                if (this.next) {
                    return this.next.tryMerge(target, source);
                }
                return false;
            };
            Merger.prototype.anyMonitoringPartSourcesEqual = function (target, source) {
                return _.any(target.monitoringPartSources, function (targetMonitoringPartSource) {
                    return _.any(source.monitoringPartSources, function (sourceMonitoringPartSource) {
                        return targetMonitoringPartSource.isEqual(sourceMonitoringPartSource);
                    });
                });
            };
            Merger.prototype.mergeMonitoringPartSources = function (target, source) {
                _.each(source.monitoringPartSources, function (sourceMonitoringPartSource) {
                    if (!_.any(target.monitoringPartSources, function (targetMonitoringPartSource) {
                        return targetMonitoringPartSource.isEqual(sourceMonitoringPartSource);
                    })) {
                        target.monitoringPartSources.push(sourceMonitoringPartSource);
                    }
                });
            };
            return Merger;
        }());
        var LineChartNumericMerger = /** @class */ (function (_super) {
            __extends(LineChartNumericMerger, _super);
            function LineChartNumericMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            LineChartNumericMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.LineChartNumeric;
            };
            LineChartNumericMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && target.isAxisConfigEqual(source)
                    && this.anyMonitoringPartSourcesEqual(target, source);
            };
            return LineChartNumericMerger;
        }(Merger));
        var TableNumericQualitativeMerger = /** @class */ (function (_super) {
            __extends(TableNumericQualitativeMerger, _super);
            function TableNumericQualitativeMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            TableNumericQualitativeMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.TableNumericQualitative;
            };
            TableNumericQualitativeMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && target.maxRows === source.maxRows
                    && this.anyMonitoringPartSourcesEqual(target, source);
            };
            return TableNumericQualitativeMerger;
        }(Merger));
        var CumulativeBarChartMerger = /** @class */ (function (_super) {
            __extends(CumulativeBarChartMerger, _super);
            function CumulativeBarChartMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            CumulativeBarChartMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.CumulativeBarChart;
            };
            CumulativeBarChartMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && target.isAxisConfigEqual(source)
                    && this.anyMonitoringPartSourcesEqual(target, source);
            };
            return CumulativeBarChartMerger;
        }(Merger));
        var FloatingBarChartMerger = /** @class */ (function (_super) {
            __extends(FloatingBarChartMerger, _super);
            function FloatingBarChartMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            FloatingBarChartMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.FloatingBarChart;
            };
            FloatingBarChartMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && target.isAxisConfigEqual(source)
                    && this.areGraphPointSourcesEqual(target, source);
            };
            FloatingBarChartMerger.prototype.areGraphPointSourcesEqual = function (target, source) {
                var targetParameters = this.getQuantitativeMeasuringPointParameter(target);
                var sourceParameters = this.getQuantitativeMeasuringPointParameter(source);
                for (var i = 0; i < targetParameters.length; i++) {
                    if (!targetParameters[i].isEqual(sourceParameters[i])) {
                        return false;
                    }
                }
                return true;
            };
            FloatingBarChartMerger.prototype.getQuantitativeMeasuringPointParameter = function (chart) {
                return _.chain(chart.monitoringPartSources)
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; })
                    .sortBy(function (mps) { return mps.sequence; })
                    .value();
            };
            return FloatingBarChartMerger;
        }(Merger));
        var ColourQualitativeTimeLineMerger = /** @class */ (function (_super) {
            __extends(ColourQualitativeTimeLineMerger, _super);
            function ColourQualitativeTimeLineMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ColourQualitativeTimeLineMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.ColourQualitativeTimeLine;
            };
            ColourQualitativeTimeLineMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && this.anyMonitoringPartSourcesEqual(target, source);
            };
            return ColourQualitativeTimeLineMerger;
        }(Merger));
        var MedicationAdherenceChartMerger = /** @class */ (function (_super) {
            __extends(MedicationAdherenceChartMerger, _super);
            function MedicationAdherenceChartMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            MedicationAdherenceChartMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.MedicationAdherenceChart;
            };
            MedicationAdherenceChartMerger.prototype.canMerge = function (target, source) {
                // always merge
                return true;
            };
            return MedicationAdherenceChartMerger;
        }(Merger));
        var MedicationDoseChartMerger = /** @class */ (function (_super) {
            __extends(MedicationDoseChartMerger, _super);
            function MedicationDoseChartMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            MedicationDoseChartMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.MedicationDoseChart;
            };
            MedicationDoseChartMerger.prototype.canMerge = function (target, source) {
                // always merge
                return true;
            };
            return MedicationDoseChartMerger;
        }(Merger));
        var ActionTableMerger = /** @class */ (function (_super) {
            __extends(ActionTableMerger, _super);
            function ActionTableMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ActionTableMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.ActionTable;
            };
            ActionTableMerger.prototype.canMerge = function (target, source) {
                // never merge
                return false;
            };
            return ActionTableMerger;
        }(Merger));
        var ActionTimeLineMerger = /** @class */ (function (_super) {
            __extends(ActionTimeLineMerger, _super);
            function ActionTimeLineMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ActionTimeLineMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.ActionTimeLine;
            };
            ActionTimeLineMerger.prototype.canMerge = function (target, source) {
                // never merge
                return false;
            };
            return ActionTimeLineMerger;
        }(Merger));
        var CumulativeObjectiveChartMerger = /** @class */ (function (_super) {
            __extends(CumulativeObjectiveChartMerger, _super);
            function CumulativeObjectiveChartMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            CumulativeObjectiveChartMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.CumulativeObjectiveChart;
            };
            CumulativeObjectiveChartMerger.prototype.canMerge = function (target, source) {
                // never merge
                return false;
            };
            return CumulativeObjectiveChartMerger;
        }(Merger));
        var ObjectiveScoreTableMerger = /** @class */ (function (_super) {
            __extends(ObjectiveScoreTableMerger, _super);
            function ObjectiveScoreTableMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ObjectiveScoreTableMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.ObjectiveScoreTable;
            };
            ObjectiveScoreTableMerger.prototype.canMerge = function (target, source) {
                // never merge
                return false;
            };
            return ObjectiveScoreTableMerger;
        }(Merger));
        var LineChartNumericExternalMerger = /** @class */ (function (_super) {
            __extends(LineChartNumericExternalMerger, _super);
            function LineChartNumericExternalMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            LineChartNumericExternalMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.LineChartNumericExternal;
            };
            LineChartNumericExternalMerger.prototype.canMerge = function (target, source) {
                return target.isPeriodConfigEqual(source)
                    && target.isAxisConfigEqual(source)
                    && this.anyMonitoringPartSourcesEqual(target, source);
            };
            return LineChartNumericExternalMerger;
        }(Merger));
        var BoxplotMerger = /** @class */ (function (_super) {
            __extends(BoxplotMerger, _super);
            function BoxplotMerger() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            BoxplotMerger.prototype.canHandle = function (target) {
                return target.type === MonitoringPartType.Boxplot;
            };
            BoxplotMerger.prototype.canMerge = function (target, source) {
                var targetParameters = this.getQuantitativeMeasuringPointParameter(target);
                var sourceParameters = this.getQuantitativeMeasuringPointParameter(source);
                for (var i = 0; i < targetParameters.length; i++) {
                    if (!targetParameters[i].isEqual(sourceParameters[i])) {
                        return false;
                    }
                }
                return true;
            };
            BoxplotMerger.prototype.getQuantitativeMeasuringPointParameter = function (chart) {
                return _.chain(chart.monitoringPartSources)
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; })
                    .sortBy(function (mps) { return mps.sequence; })
                    .value();
            };
            return BoxplotMerger;
        }(Merger));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
