/// <reference path="./monitoringPartComponentBase.ts"/>

module RemeCare.Patient {
    import MonitoringPartSourceAction = Shared.Framework.Model.MonitoringPartSourceAction;

    class MedicationDoseChartController extends ChartMonitoringPartController {
        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly patientSvc: PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            const medicationFilters = _.map(
                this.monitoringPart.monitoringPartSources,
                (mps: MonitoringPartSourceAction) => ({
                    structuralTherapyActionId: mps.structuralTherapyActionId,
                    ingredientIds: (mps.filter && mps.filter.ingredientIds) || []
                })
            );
            try {
                const medicationLevelGraphs = await this.patientSvc.getMedicationLevelGraphsAsync(
                    this.patientId,
                    medicationFilters,
                    this.dateInfo.fromDate,
                    this.getUntilDate()
                );
                const graphs = _(medicationLevelGraphs).map(g => new Model.NumberGraph(g, true));
                const ingredientIds = _(graphs).map(g => g.subject.Id);
                const unitIds = _(graphs)
                    .chain()
                    .map(g => (g.scale != null ? g.scale.Id : null))
                    .filter(id => id != null)
                    .value();
                const ingredients =
                    ingredientIds.length > 0
                        ? (await this.masterdataSvc.findIngredientsAsync(ingredientIds)).Items
                        : new Array<Shared.Contract.IEntityTranslation>();
                const units =
                    unitIds.length > 0
                        ? await this.masterdataSvc.findProductUnitsAsync(unitIds)
                        : new Array<Shared.Contract.IEntityTranslation>();
                _(graphs).forEach(g => {
                    g.subject = _.find(ingredients, i => i.Id === g.subject.Id);
                    g.scale = g.scale != null ? _.find(units, u => u.Id === g.scale.Id) : null;
                });
                this.configureCharts(graphs);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        private configureCharts(graphs: Array<Model.NumberGraph>): void {
            const chartConfigs = _(graphs).map(
                g =>
                    <HighChartsNGConfig>{
                        options: {
                            chart: {
                                animation: false,
                                type: 'line',
                                height: 200,
                                zoomType: 'xy',
                                spacingTop: 40,
                                marginLeft: 37,
                                marginRight: 37
                            },
                            xAxis: this.getXAxisConfig(null, true),
                            yAxis: {
                                startOnTick: false,
                                endOnTick: false,
                                title: {
                                    align: 'high',
                                    offset: 0,
                                    rotation: 0,
                                    text: g.scale ? g.scale.Text : null,
                                    y: -20,
                                    x: -27,
                                    textAlign: 'left'
                                },
                                max: this.getMaxYScaleValue(g),
                                min: this.getMinYScaleValue(g),
                                labels: {
                                    align: 'right',
                                    x: 0,
                                    y: -2
                                }
                            },
                            tooltip: {
                                xDateFormat: this.getDateFormat(false),
                                pointFormat:
                                    '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} {point.series.yAxis.options.title.text}</b><br/>'
                            },
                            title: {
                                text: g.subject.Text
                            },
                            exporting: {
                                enabled: false
                            },
                            credits: {
                                enabled: false
                            },
                            legend: {
                                enabled: this.showLegend
                            }
                        },
                        series: [this.mapDataPoints(g)]
                    }
            );
            this.chartConfigs = chartConfigs;
        }

        private mapDataPoints(graph: Model.NumberGraph): HighchartsLineChartSeriesOptions {
            return <HighchartsLineChartSeriesOptions>{
                animation: false,
                data: _(graph.graphPoints)
                    .chain()
                    .map(gp => this.getDataPoint(gp))
                    .sortBy(gp => gp[0])
                    .value(),
                step: true,
                marker: {
                    enabled: false
                },
                name: graph.subject.Text
            };
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number>): Array<number> {
            return [graphPoint.x.valueOf(), graphPoint.y];
        }
    }

    class MedicationDoseChartComponent extends MonitoringPartComponentBase {
        public controller = MedicationDoseChartController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringMedicationDoseChart', new MedicationDoseChartComponent());
}
