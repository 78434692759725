/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import Consultation = Model.Consultation;

    interface IConsultationsScope extends ICareRequestPartBaseScope {
        grid: Shared.Framework.Grid.Grid<Consultation>;
        addConsultation(): void;
    }

    class ConsultationsController extends CareRequestPartControllerBase<IConsultationsScope> {
        constructor(
            protected $scope: IConsultationsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.addConsultation = () => this.addConsultationAsync();
            this.$onInit();
        }

        public $onInit(): void {
            this.buildGrid();
        }

        private buildGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<Model.Consultation>()
                .addColumn('consultationDate', 'Views.Patient.Details.ConsultationDate', {
                    cellFilter: 'date: "shortDate"',
                })
                .addColumn('careProviderType.Text', 'Views.Patient.Details.CareProviderType')
                .addColumn('reason', 'Views.Patient.Details.Reason');
            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn(c => this.editConsultationAsync(c))
                    .addDeleteButtonColumn();
            }
            this.$scope.grid = gridBuilder.build();
            this.$scope.grid.setData(this.$scope.careRequest.consultations);
        }

        private async addConsultationAsync(): Promise<void> {
            const consultation = new Consultation();
            try {
                const c = await this.editConsultationAsync(consultation);
                this.$scope.careRequest.consultations.push(c);
            } catch (e) {
                // Promise is rejected when modal is closed/dismissed. Expected behavior
            }
        }

        private editConsultationAsync(consultation: Consultation): Promise<Consultation> {
            return this.modalBuilderFactory
                .createComponentModalBuilder<Consultation>('rcConsultationModal')
                .setBindings({
                    consultation: angular.copy(consultation),
                    careRequestPart: this.$scope.careRequestPart,
                })
                .build().result;
        }
    }

    class ConsultationsDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'gridBuilderSvc',
            'modalBuilderFactory',
            'careRequestSvc',
            ($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) =>
                new ConsultationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc),
        ];

        public templateUrl = 'views/careRequest/directive/consultations.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestConsultations', () => new ConsultationsDirective());
}
