var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var CarePlanApiService = /** @class */ (function (_super) {
                __extends(CarePlanApiService, _super);
                function CarePlanApiService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                CarePlanApiService.prototype.findAssignablePartiesAsync = function (patientId, actionTemplateId) {
                    return this.getPromise("ActionTemplates/" + actionTemplateId + "/AssignableParties?patientId=" + patientId);
                };
                CarePlanApiService.prototype.findQuantitativeGraphPointsAsync = function (carePlanIds, quantitativeMeasuringPoints, fromDate, untilDate, multiplePerDay) {
                    return this.getInParts('CarePlans/QuantitativeGraphPoints', quantitativeMeasuringPoints, 10, function (subset) {
                        var query = {
                            carePlanIds: carePlanIds,
                            quantitativeMeasuringPoints: subset,
                            fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                            untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                            multiplePerDay: multiplePerDay,
                        };
                        return query;
                    });
                };
                CarePlanApiService.prototype.findQuantitativeReferencePointsAsync = function (carePlanIds, quantitativeReferencePoints, fromDate, untilDate) {
                    return this.getInParts('CarePlans/QuantitativeReferencePoints', quantitativeReferencePoints, 10, function (subset) {
                        var query = {
                            carePlanIds: carePlanIds,
                            quantitativeReferencePoints: subset,
                            fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                            untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                        };
                        return query;
                    });
                };
                CarePlanApiService.prototype.findQualitativeGraphPointsAsync = function (carePlanIds, qualitativeMeasuringPoints, fromDate, untilDate, multiplePerDay) {
                    return this.getInParts('CarePlans/QualitativeGraphPoints', qualitativeMeasuringPoints, 10, function (subset) {
                        var query = {
                            carePlanIds: carePlanIds,
                            qualitativeMeasuringPoints: subset,
                            fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                            untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                            multiplePerDay: multiplePerDay,
                        };
                        return query;
                    });
                };
                CarePlanApiService.prototype.findParametersAsync = function (carePlanIds, parameters, page, pageSize, fromDate, untilDate) {
                    var query = {
                        page: page,
                        pageSize: pageSize,
                        carePlanIds: carePlanIds,
                        params: parameters,
                        fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                        untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                    };
                    return this.getPromise("CarePlans/Parameters", query);
                };
                CarePlanApiService.prototype.getObjectiveScoreAsync = function (carePlanId, objectiveId, fromDate, untilDate, page, pageSize) {
                    var params = {
                        page: page,
                        pageSize: pageSize,
                        fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                        untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                    };
                    return this.getPromise("CarePlans/" + carePlanId + "/ObjectiveScores/" + objectiveId, params);
                };
                CarePlanApiService.prototype.getCarePlanParameterValuesAsync = function (carePlanId) {
                    return _.isArray(carePlanId)
                        ? this.getPromise("CarePlans/CarePlanParameterValues", { carePlanIds: carePlanId }, 'careplan.parameters')
                        : this.getPromise("CarePlans/" + carePlanId + "/CarePlanParameterValues", null, 'careplan.parameters');
                };
                CarePlanApiService.prototype.getCarePlanStringAsync = function (carePlanId, definitionId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/CarePlanStrings/" + definitionId);
                };
                CarePlanApiService.prototype.saveCarePlanStringAsync = function (carePlanId, definitionId, carePlanString) {
                    return this.postPromise("CarePlans/" + carePlanId + "/CarePlanStrings/" + definitionId, carePlanString, 'saveCarePlanDetail');
                };
                CarePlanApiService.prototype.deleteCarePlanStringAsync = function (carePlanId, definitionId, pointInTime) {
                    return this.deletePromise("CarePlans/" + carePlanId + "/CarePlanStrings/" + definitionId + "/" + pointInTime);
                };
                CarePlanApiService.prototype.changeCarePlanStringValidityAsync = function (carePlanId, definitionId, versionValidityChange) {
                    var url = "CarePlans/" + carePlanId + "/CarePlanStrings/" + definitionId + "/" + versionValidityChange.PointInTime;
                    return this.putPromise(url, versionValidityChange);
                };
                CarePlanApiService.prototype.getCarePlanAnamnesesAsync = function (carePlanId, definitionId, date) {
                    return this.getPromise("CarePlans/" + carePlanId + "/CarePlanAnamneses/" + definitionId, {
                        validityDate: RemeCare.Shared.DateHelper.toServerDateString(date),
                    });
                };
                CarePlanApiService.prototype.saveCarePlanAnamnesisAsync = function (carePlanId, definitionId, carePlanAnamnesis) {
                    return this.postPromise("CarePlans/" + carePlanId + "/CarePlanAnamneses/" + definitionId, carePlanAnamnesis, 'saveCarePlanDetail');
                };
                CarePlanApiService.prototype.deleteCarePlanAnamnesisAsync = function (carePlanId, definitionId, pointInTime) {
                    return this.deletePromise("CarePlans/" + carePlanId + "/CarePlanAnamneses/" + definitionId + "/" + pointInTime);
                };
                CarePlanApiService.prototype.changeCarePlanAnamnesisValidityAsync = function (carePlanId, definitionId, versionValidityChange) {
                    var url = "CarePlans/" + carePlanId + "/CarePlanAnamneses/" + definitionId + "/" + versionValidityChange.PointInTime;
                    return this.putPromise(url, versionValidityChange);
                };
                CarePlanApiService.prototype.getCarePlanDatesAsync = function (carePlanId, definitionId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/CarePlanDates/" + definitionId);
                };
                CarePlanApiService.prototype.saveCarePlanDateAsync = function (carePlanId, definitionId, carePlanDate) {
                    return this.postPromise("CarePlans/" + carePlanId + "/CarePlanDates/" + definitionId, carePlanDate, 'saveCarePlanDetail');
                };
                CarePlanApiService.prototype.deleteCarePlanDateAsync = function (carePlanId, definitionId, pointInTime) {
                    return this.deletePromise("CarePlans/" + carePlanId + "/CarePlanDates/" + definitionId + "/" + pointInTime);
                };
                CarePlanApiService.prototype.changeCarePlanDateValidityAsync = function (carePlanId, definitionId, versionValidityChange) {
                    var url = "CarePlans/" + carePlanId + "/CarePlanDates/" + definitionId + "/" + versionValidityChange.PointInTime;
                    return this.putPromise(url, versionValidityChange);
                };
                CarePlanApiService.prototype.getCarePlanTherapyOptionsAsync = function (carePlanId, definitionId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/CarePlanTherapyOptions/" + definitionId);
                };
                CarePlanApiService.prototype.saveCarePlanTherapyOptionAsync = function (carePlanId, definitionId, carePlanTherapyOption) {
                    return this.postPromise("CarePlans/" + carePlanId + "/CarePlanTherapyOptions/" + definitionId, carePlanTherapyOption, 'saveCarePlanDetail');
                };
                CarePlanApiService.prototype.deleteCarePlanTherapyOptionAsync = function (carePlanId, definitionId, pointInTime) {
                    return this.deletePromise("CarePlans/" + carePlanId + "/CarePlanTherapyOptions/" + definitionId + "/" + pointInTime);
                };
                CarePlanApiService.prototype.changeCarePlanTherapyOptionValidityAsync = function (carePlanId, definitionId, versionValidityChange) {
                    var url = "CarePlans/" + carePlanId + "/CarePlanTherapyOptions/" + definitionId + "/" + versionValidityChange.PointInTime;
                    return this.putPromise(url, versionValidityChange);
                };
                CarePlanApiService.prototype.getObjectivesAsync = function (carePlanId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/Objectives");
                };
                CarePlanApiService.prototype.getObjectiveAsync = function (carePlanId, objectiveId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/Objectives/" + objectiveId);
                };
                CarePlanApiService.prototype.savePersonalisedObjectiveValueAsync = function (carePlanId, objectiveId, objectiveValue) {
                    return this.postPromise("CarePlans/" + carePlanId + "/Objectives/" + objectiveId, objectiveValue, 'saveObjective');
                };
                CarePlanApiService.prototype.updatePersonalisedObjectiveValueAsync = function (carePlanId, objectiveId, objectiveValue) {
                    return this.putPromise("CarePlans/" + carePlanId + "/Objectives/" + objectiveId, objectiveValue, 'saveObjective');
                };
                CarePlanApiService.prototype.deletePersonalisedObjectiveValueAsync = function (carePlanId, objectiveId, objectiveValueId) {
                    return this.deletePromise("CarePlans/" + carePlanId + "/Objectives/" + objectiveId + "/" + objectiveValueId, 'saveObjective');
                };
                CarePlanApiService.prototype.getRuleThresholdsAsync = function (carePlanId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/RuleThresholds");
                };
                CarePlanApiService.prototype.getRuleThreshold = function (carePlanId, ruleThresholdId) {
                    return this.get("CarePlans/" + carePlanId + "/RuleThresholds/" + ruleThresholdId);
                };
                CarePlanApiService.prototype.savePersonalisedRuleThresholdValue = function (carePlanId, ruleThresholdId, ruleThresholdValue) {
                    return this.post("CarePlans/" + carePlanId + "/RuleThresholds/" + ruleThresholdId, ruleThresholdValue, 'saveRuleThreshold');
                };
                CarePlanApiService.prototype.updatePersonalisedRuleThresholdValue = function (carePlanId, ruleThresholdId, ruleThresholdValue) {
                    return this.put("CarePlans/" + carePlanId + "/RuleThresholds/" + ruleThresholdId, ruleThresholdValue, 'saveRuleThreshold');
                };
                CarePlanApiService.prototype.deletePersonalisedRuleThresholdValue = function (carePlanId, ruleThresholdId, ruleThresholdValue) {
                    return this.delete("CarePlans/" + carePlanId + "/RuleThresholds/" + ruleThresholdId + "/" + ruleThresholdValue, 'saveRuleThreshold');
                };
                CarePlanApiService.prototype.getMediaLibItemsAsync = function (carePlanId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/MediaLibItems");
                };
                CarePlanApiService.prototype.switchMediaLibItemsAsync = function (carePlanId, itemId1, itemId2) {
                    var url = "CarePlans/" + carePlanId + "/SwitchMediaLibItems";
                    return this.postPromise(url, {
                        id1: itemId1,
                        id2: itemId2,
                    }, 'switchMediaLibItems');
                };
                CarePlanApiService.prototype.getPersonalisedActionAsync = function (carePlanId, structuralTherapyActionId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/StructuralTherapyActionPersonalisation/" + structuralTherapyActionId, null, 'getPersonalisedTherapyAction');
                };
                CarePlanApiService.prototype.setPersonalisedTherapyActionAsync = function (carePlanId, structuralTherapyActionId, peronalisedTherapyAction) {
                    var url = "CarePlans/" + carePlanId + "/PersonalisedTherapyAction/" + structuralTherapyActionId;
                    return this.postPromise(url, peronalisedTherapyAction, 'setPersonalisedTherapyAction');
                };
                CarePlanApiService.prototype.deletePersonalisedTherapyActionAsync = function (carePlanId, structuralTherapyActionId, date) {
                    var url = "CarePlans/" + carePlanId + "/PersonalisedTherapyAction/" + structuralTherapyActionId + "/" + RemeCare.Shared.DateHelper.toServerDateString(date);
                    return this.deletePromise(url);
                };
                CarePlanApiService.prototype.setPersonalisedTherapyActionContextSetting = function (carePlanId, structuralTherapyActionId, personalisedTherapyActionContextSetting) {
                    var url = "CarePlans/" + carePlanId + "/PersonalisedTherapyActionContextSetting/" + structuralTherapyActionId;
                    return this.post(url, personalisedTherapyActionContextSetting, 'personalisedContextSetting');
                };
                CarePlanApiService.prototype.deletePersonalisedTherapyActionContextSetting = function (carePlanId, structuralTherapyActionId, date) {
                    var url = "CarePlans/" + carePlanId + "/PersonalisedTherapyActionContextSetting/" + structuralTherapyActionId + "/" + RemeCare.Shared.DateHelper.toServerDateString(date);
                    return this.delete(url);
                };
                CarePlanApiService.prototype.getPersonalisableStructuralTherapyActionsAsync = function (carePlanId) {
                    return this.getPromise("CarePlans/" + carePlanId + "/PersonalisableStructuralTherapyActions", null, 'getPersonalisableStructuralTherapyActions');
                };
                CarePlanApiService.prototype.changeCarePlanStatus = function (carePlanId, reasonId, statusCode) {
                    var params = { carePlanId: carePlanId, reasonId: reasonId, statusCode: statusCode };
                    return this.post("CarePlans/" + carePlanId + "/Status", params, 'careplan.status');
                };
                CarePlanApiService.prototype.changeCarePlanStartDate = function (carePlanId, newStartDate) {
                    var params = { newStartDate: newStartDate };
                    return this.put("CarePlans/" + carePlanId + "/StartDate", params);
                };
                CarePlanApiService.prototype.getGroupRelatedCarePlansAsync = function (groupId) {
                    return this.getPromise("CarePlans/Groups/" + groupId + "/CarePlans", null, 'linkedThroughGroup');
                };
                CarePlanApiService.prototype.getNextAndLastScheduledDateAsync = function (structuralTherapyActionId, carePlanId, perspectiveDateTime) {
                    var params = {
                        structuralTherapyActionId: structuralTherapyActionId,
                        carePlanId: carePlanId,
                        perspectiveDateTime: perspectiveDateTime,
                    };
                    return this.getPromise("CarePlans/" + carePlanId + "/StructuralTherapyActions/" + structuralTherapyActionId + "/NextSchedule", params, 'nextDate');
                };
                CarePlanApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
                return CarePlanApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.CarePlanApiService = CarePlanApiService;
            RemeCare.remeCareAppModule.service('carePlanApiSvc', CarePlanApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
