/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var MedicationSchemaController = /** @class */ (function (_super) {
                __extends(MedicationSchemaController, _super);
                function MedicationSchemaController($scope, $translate, toaster, therapyApiSvc, gridBuilderSvc, medicationSearchSvc, modalBuilderFactory, patientService, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.therapyApiSvc = therapyApiSvc;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.medicationSearchSvc = medicationSearchSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.patientService = patientService;
                    _this.careRequestSvc = careRequestSvc;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                MedicationSchemaController.prototype.$onInit = function () {
                    var _this = this;
                    this.isForTherapyAction =
                        this.$scope.careRequestPart.type.Id ===
                            RemeCare.Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction;
                    var productIds = _(this.$scope.careRequest.careRequestMedications)
                        .chain()
                        .filter(function (crm) {
                        return (_this.isForTherapyAction && crm.structuralTherapyActionId != null) ||
                            (!_this.isForTherapyAction && crm.structuralTherapyActionId == null);
                    })
                        .map(function (crm) { return crm.productId; })
                        .value();
                    if (productIds.length > 0) {
                        // No need to await this
                        this.initProducts(productIds);
                    }
                    else {
                        this.buildGrids();
                    }
                };
                MedicationSchemaController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                MedicationSchemaController.prototype.initProducts = function (productIds) {
                    return __awaiter(this, void 0, void 0, function () {
                        var medications, e_1;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.medicationSearchSvc.getMedicationsAsync(productIds)];
                                case 1:
                                    medications = _a.sent();
                                    _(medications).forEach(function (p) {
                                        return _(_this.$scope.careRequest.careRequestMedications).forEach(function (crm) {
                                            if (crm.productId === p.ProductId) {
                                                crm.medication = p;
                                                if (!crm.medication.IsActive) {
                                                    crm.medication.MedicationName +=
                                                        ' (' + _this.$translate.instant('General.Inactive') + ')';
                                                }
                                            }
                                        });
                                    });
                                    this.buildGrids();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _a.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                MedicationSchemaController.prototype.buildGrids = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var result, e_2, grid_1;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!this.isForTherapyAction) return [3 /*break*/, 5];
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.therapyApiSvc.getMedicationActions(this.$scope.careRequest.therapyId, this.$scope.careRequest.id, this.$scope.careRequestPart.medicationActionId)];
                                case 2:
                                    result = _a.sent();
                                    this.$scope.medicationConfigurations = _(result).map(function (stap) {
                                        var grid = _this.buildGrid(stap.StartTypeAdHoc);
                                        _(_this.$scope.careRequest.careRequestMedications)
                                            .filter(function (c) { return c.structuralTherapyActionId === stap.Id; })
                                            .forEach(function (m) { return __awaiter(_this, void 0, void 0, function () {
                                            var res;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.texualizeSchema(m)];
                                                    case 1:
                                                        res = _a.sent();
                                                        m.medicationSchema = res;
                                                        grid.addRow(m);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                        return {
                                            name: stap.Name,
                                            grid: grid,
                                            addMedication: function () { return _this.addMedication(grid, stap.Id, stap.StartTypeAdHoc); },
                                        };
                                    });
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_2 = _a.sent();
                                    this.toaster.error(e_2);
                                    return [3 /*break*/, 4];
                                case 4: return [3 /*break*/, 6];
                                case 5:
                                    grid_1 = this.buildGrid();
                                    _(this.$scope.careRequest.careRequestMedications)
                                        .filter(function (c) { return c.structuralTherapyActionId == null; })
                                        .forEach(function (m) { return __awaiter(_this, void 0, void 0, function () {
                                        var res;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.texualizeSchema(m)];
                                                case 1:
                                                    res = _a.sent();
                                                    m.medicationSchema = res;
                                                    grid_1.addRow(m);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                    this.$scope.medicationConfigurations = [
                                        {
                                            name: null,
                                            grid: grid_1,
                                            addMedication: function () { return _this.addMedication(grid_1); },
                                        },
                                    ];
                                    _a.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    });
                };
                MedicationSchemaController.prototype.texualizeSchema = function (careRequestMedication) {
                    return __awaiter(this, void 0, void 0, function () {
                        var result, tokens, e_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(careRequestMedication.periodicities.length === 1 &&
                                        careRequestMedication.periodicities[0].unstructuredPeriodicity)) return [3 /*break*/, 1];
                                    result = careRequestMedication.periodicities[0].unstructuredPeriodicity;
                                    return [2 /*return*/, result];
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.patientService.tokenizeMedicationSchemaAsync(_(careRequestMedication.periodicities).map(function (p) { return p.toWriteModel(); }))];
                                case 2:
                                    tokens = _a.sent();
                                    return [2 /*return*/, this.renderMedicationSchema(tokens, this.$translate, careRequestMedication.medication.UnitName)];
                                case 3:
                                    e_3 = _a.sent();
                                    this.toaster.error(e_3);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                MedicationSchemaController.prototype.renderMedicationSchema = function (tokens, $translate, unitName) {
                    var renderPipe = new Array();
                    angular.forEach(tokens, function (t) {
                        var params = {};
                        for (var i = 0; i < t.Parameters.length; i++) {
                            params['value' + i] = t.Parameters[i];
                        }
                        if (t.TemplateResourceKey === 'General.Periodicity.Tokens.DayTimingDose') {
                            params['value' + 1] = unitName;
                        }
                        var text;
                        if (t.TemplateResourceKey.toString().indexOf('General.') !== -1) {
                            text = $translate.instant(t.TemplateResourceKey, params);
                        }
                        else {
                            text = t.TemplateResourceKey.toString();
                        }
                        renderPipe.push(text);
                    });
                    return renderPipe.join('');
                };
                MedicationSchemaController.prototype.buildGrid = function (isAdHoc) {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addConditionallyStyledColumn('medication.MedicationName', 'General.MedicationName', 'medication.IsActive', {
                        red: false,
                    })
                        .addColumn('medication.ActiveSubstance', 'General.ActiveSubstanceDose')
                        .addColumn('medicationSchema', 'General.Schema')
                        .addColumn('validFromDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                        .addColumn('validUntilDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' });
                    if (!this.$scope.readOnly) {
                        gridBuilder = gridBuilder
                            .addEditButtonWithPromiseFunctionColumn(function (crm) { return _this.editMedication(crm, isAdHoc); })
                            .addDeleteButtonColumn(function (d) {
                            return (_this.$scope.careRequest.careRequestMedications = _(_this.$scope.careRequest.careRequestMedications).without(d));
                        });
                    }
                    else {
                        gridBuilder = gridBuilder.addEditButtonWithPromiseFunctionColumn(function (crm) { return _this.editMedication(crm, isAdHoc); }, 'eye-open');
                    }
                    return gridBuilder.build();
                };
                MedicationSchemaController.prototype.addMedication = function (grid, structuralTherapyActionId, isAdHoc) {
                    var _this = this;
                    if (isAdHoc == null) {
                        isAdHoc = false;
                    }
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/careRequest/directive/medication/careRequestMedication.html')
                        .setController('careRequestMedicationCtrl')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        readOnly: false,
                        patientLang: this.$scope.careRequest.patient != null
                            ? this.$scope.careRequest.patient.communicationLanguage
                            : null,
                        prescription: null,
                        hideIntraDayConfig: true,
                        isAdHoc: isAdHoc,
                        structuralTherapyActionId: structuralTherapyActionId,
                    })
                        .setResultCallBack(function (m) { return __awaiter(_this, void 0, void 0, function () {
                        var res, e_4;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    if (!m) {
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, this.texualizeSchema(m)];
                                case 1:
                                    res = _a.sent();
                                    m.medicationSchema = res;
                                    grid.addRow(m);
                                    this.$scope.careRequest.careRequestMedications.push(m);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_4 = _a.sent();
                                    this.toaster.error(e_4);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })
                        .build();
                };
                MedicationSchemaController.prototype.editMedication = function (medication, isAdHoc) {
                    var _this = this;
                    return new Promise(function (resolve) {
                        _this.modalBuilderFactory
                            .createModalBuilder()
                            .setTemplateUrl('views/careRequest/directive/medication/careRequestMedication.html')
                            .setController('careRequestMedicationCtrl')
                            .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                            .setScope({
                            readOnly: false,
                            patientLang: _this.$scope.careRequest.patient != null
                                ? _this.$scope.careRequest.patient.communicationLanguage
                                : null,
                            prescription: angular.copy(medication),
                            hideIntraDayConfig: true,
                            isAdHoc: isAdHoc || medication.periodicities[0].unstructuredPeriodicity != null,
                            structuralTherapyActionId: medication.structuralTherapyActionId,
                        })
                            .setResultCallBack(function (m) { return __awaiter(_this, void 0, void 0, function () {
                            var res, e_5;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!m) {
                                            return [2 /*return*/];
                                        }
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this.texualizeSchema(m)];
                                    case 2:
                                        res = _a.sent();
                                        m.medicationSchema = res;
                                        resolve(m);
                                        this.$scope.careRequest.careRequestMedications = _(this.$scope.careRequest.careRequestMedications).without(medication);
                                        this.$scope.careRequest.careRequestMedications.push(m);
                                        return [3 /*break*/, 4];
                                    case 3:
                                        e_5 = _a.sent();
                                        this.toaster.error(e_5);
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); })
                            .build();
                    });
                };
                MedicationSchemaController.$inject = [
                    '$scope',
                    '$translate',
                    'toaster',
                    'therapyApiSvc',
                    'gridBuilderSvc',
                    'medicationSearchSvc',
                    'modalBuilderFactory',
                    'patientSvc',
                    'careRequestSvc',
                ];
                return MedicationSchemaController;
            }(Directive.CareRequestPartControllerBase));
            var MedicationSchemaDirective = /** @class */ (function (_super) {
                __extends(MedicationSchemaDirective, _super);
                function MedicationSchemaDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = MedicationSchemaController;
                    _this.templateUrl = 'views/careRequest/directive/medication/medicationSchema.html';
                    return _this;
                }
                return MedicationSchemaDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestMedicationSchema', function () { return new MedicationSchemaDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
