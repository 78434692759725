namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import FindPrescriptionsForPatientQuery = Contract.Patient.Read.IFindPrescriptionsForPatientQuery;
    import Prescription = Contract.Patient.Read.IPrescription;
    import FindCarePlansQuery = Contract.Patient.Read.IFindCarePlansQuery;
    import SentencePart = Contract.Patient.Read.ISentencePart;
    import CarePlanQuery = Contract.Patient.Read.ICarePlanQuery;
    import TherapyQuery = Contract.Patient.Read.ITherapyQuery;
    import StructuralTherapyActionQuery = Contract.Patient.Read.IStructuralTherapyActionQuery;
    import TherapyBusinessContextConfiguration = Contract.Core.Read.ITherapyBusinessContextRightConfiguration;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import ActionTemplate = Contract.Patient.Read.IActionTemplate;
    import SearchResult = Shared.Contract.ISearchResult;

    export class PatientService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public getPatient(patientId: Guid): ng.IHttpPromise<Contract.Party.Read.IPatientDetail> {
            return this.get(`Patients/${patientId}`);
        }

        /** @deprecated */
        public getContactDetails(
            patientId: Shared.Contract.Guid
        ): ng.IHttpPromise<Contract.Patient.Read.IPatientContactDetails> {
            return this.get(`Patients/${patientId}/Contact`);
        }

        public getContactDetailsAsync(
            patientId: Shared.Contract.Guid
        ): Promise<Contract.Patient.Read.IPatientContactDetails> {
            return this.getPromise(`Patients/${patientId}/Contact`);
        }

        /** @deprecated */
        public getCarePlans(
            patientId: Guid,
            query?: FindCarePlansQuery
        ): ng.IHttpPromise<Contract.Patient.Read.ICareplan[]> {
            return this.get(`Patients/${patientId}/CarePlans`, query);
        }

        public getCarePlansAsync(
            patientId: Guid,
            query?: FindCarePlansQuery
        ): Promise<Contract.Patient.Read.ICareplan[]> {
            return this.getPromise(`Patients/${patientId}/CarePlans`, query);
        }

        public getCareTeamAsync(
            patientId: Guid,
            partyType: Shared.Contract.Code.PartyType,
            query: Contract.HealthCareParty.Read.Query.ISearchCareTeamQuery
        ): Promise<SearchResult<Contract.HealthCareParty.Read.ICareTeamParty>> {
            const spinnerSuffix = partyType === Shared.Contract.Code.PartyType.Person ? 'Person' : 'Organisation';
            return this.getPromise(`Patients/${patientId}/CareTeam/${partyType}`, query, `careTeam${spinnerSuffix}`);
        }

        public getInformals(
            patientId: Guid,
            query: Contract.HealthCareParty.Read.Query.ISearchInformalsQuery
        ): ng.IHttpPromise<SearchResult<Contract.HealthCareParty.Read.IInformalParty>> {
            return this.get(`Patients/${patientId}/Informals`, query, 'informals');
        }

        public getContactPersonsAsync(
            patientId: Guid,
            query: Contract.HealthCareParty.Read.Query.ISearchContactPersonsQuery
        ): Promise<SearchResult<Contract.HealthCareParty.Read.IContactPerson>> {
            return this.getPromise(`Patients/${patientId}/ContactPersons`, query, 'contactPersons');
        }

        public getContactPersonAsync(
            patientId: Guid,
            contactPersonId: Guid,
            query: Contract.HealthCareParty.Read.Query.ISearchContactPersonsQuery
        ): Promise<Contract.HealthCareParty.Read.IContactPerson> {
            return this.getPromise(`Patients/${patientId}/ContactPersons/${contactPersonId}`, query, 'contactPersons');
        }

        public getPatientPrescriptionsAsync(
            patientId: Guid,
            query: FindPrescriptionsForPatientQuery
        ): Promise<Shared.Contract.ISearchResult<Prescription>> {
            const url = `Patients/${patientId}/Prescriptions`;
            return this.getPromise(url, query, 'prescriptions');
        }

        /** @deprecated */
        public tokenizeMedicationSchema(schema: Shared.Contract.Write.IPeriodicity[]): ng.IHttpPromise<SentencePart[]> {
            const url = 'Tokenization/MedicationSchema';
            return this.post(url, schema) as ng.IHttpPromise<SentencePart[]>;
        }

        public tokenizeMedicationSchemaAsync(schema: Shared.Contract.Write.IPeriodicity[]): Promise<SentencePart[]> {
            const url = 'Tokenization/MedicationSchema';
            return this.postPromise(url, schema) as Promise<SentencePart[]>;
        }

        public savePatientPrescriptionAsync(prescription: Contract.Patient.Write.IPrescription): Promise<{}> {
            const url = `Patients/${prescription.patientId}/Prescriptions`;
            return this.postPromise(url, prescription, 'prescription');
        }

        public updatePatientPrescriptionAsync(prescription: Contract.Patient.Write.IPrescription): Promise<{}> {
            const url = `Patients/${prescription.patientId}/Prescriptions/${prescription.id}`;
            return this.putPromise(url, prescription, 'prescription');
        }

        public deletePatientPrescriptionAsync(patientId: Guid, prescriptionId: Guid): Promise<{}> {
            const url = `Patients/${patientId}/Prescriptions/${prescriptionId}`;
            return this.deletePromise(url, 'prescription');
        }

        public getMonitoringConfigurationAsync(
            patientId: Guid,
            query: TherapyQuery
        ): Promise<Shared.Contract.Read.ITherapyMonitoringParts[]> {
            const url = `Patients/${patientId}/MonitoringParts`;
            return this.getPromise<Shared.Contract.Read.ITherapyMonitoringParts[]>(
                url,
                query,
                'patient.monitoringParts'
            );
        }

        public getPersonsAsync(patientId: Guid, query?: CarePlanQuery): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise(`Patients/${patientId}/Persons`, query);
        }

        public getActionExecutorsAsync(patientId: Guid): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise(`Patients/${patientId}/Actions/Executors`);
        }

        /** @deprecated */
        public getStructuralTherapyActions(
            patientId: Guid,
            query: StructuralTherapyActionQuery
        ): ng.IHttpPromise<Contract.Therapy.Read.IStructuralTherapyActionSummaryReadModel[]> {
            return this.get(`Patients/${patientId}/StructuralTherapyActions`, query);
        }

        public getStructuralTherapyActionsAsync(
            patientId: Guid,
            query: StructuralTherapyActionQuery
        ): Promise<Contract.Therapy.Read.IStructuralTherapyActionSummaryReadModel[]> {
            return this.getPromise<Contract.Therapy.Read.IStructuralTherapyActionSummaryReadModel[]>(
                `Patients/${patientId}/StructuralTherapyActions`,
                query
            );
        }

        public getTherapyActionPartParametersAsync(patientId: Guid, therapyId?: Guid): Promise<EntityTranslation[]> {
            const params = {
                therapyId,
            };
            return this.getPromise(`Patients/${patientId}/TherapyActionPartParameters`, params);
        }

        public getRegistrationValuesAsync(
            patientId: Guid,
            query: Contract.Patient.Read.IFindRegistrationValuesQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.Patient.Read.IRegistrationValueOverview>> {
            return this.getPromise(`Patients/${patientId}/RegistrationValues`, query, 'registrationvalues');
        }

        public getAlarmDefinitionsAsync(
            patientId: Guid,
            query?: TherapyQuery
        ): Promise<Shared.Contract.Read.IAlarmDefinition[]> {
            return this.getPromise(`Patients/${patientId}/AlarmDefinitions`, query);
        }

        public getAlarmsAsync(
            patientId: Guid,
            query: Contract.Alarms.Read.IFindAlarmsQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.Alarms.Read.IAlarm>> {
            return this.getPromise(`Patients/${patientId}/Alarms`, query, 'alarms');
        }

        public getPatientNameAsync(patientId: Guid): Promise<Contract.Patient.Read.IPersonRoleSummary[]> {
            return this.getPromise(`Patients/${patientId}/Name`);
        }

        public findPartyRoles(
            query: Contract.Patient.Read.IFindPartyRoleSummariesQuery
        ): ng.IHttpPromise<Contract.Patient.Read.IPersonRoleSummary[]> {
            return this.get('Party/PartyRoles', query);
        }

        public getProfileRightsForPatientAsync(patientId: Guid): Promise<Contract.Patient.Read.IProfileRights> {
            return this.getPromise(`Patients/${patientId}/ProfileRights`);
        }

        public saveAlarmHandlingAsync(patientId: Guid, alarmId: Guid): Promise<{}> {
            return this.postPromise(`Patients/${patientId}/Alarms/${alarmId}/Handled`, {});
        }

        /** @deprecated */
        public getTherapyBusinessContextRights(
            patientId: Guid,
            query?: CarePlanQuery
        ): ng.IHttpPromise<TherapyBusinessContextConfiguration[]> {
            return this.get(`Patients/${patientId}/TherapyBusinessContextRights`, query, 'rights');
        }

        public getTherapyBusinessContextRightsAsync(
            patientId: Guid,
            query?: CarePlanQuery
        ): Promise<TherapyBusinessContextConfiguration[]> {
            return this.getPromise(`Patients/${patientId}/TherapyBusinessContextRights`, query, 'rights');
        }

        public getPatientFileHospitalisationsAsync(patientId: Guid): Promise<Contract.Core.Read.IHospitalisation[]> {
            return this.getPromise(`Patients/${patientId}/Hospitalisations`);
        }

        public getHospitalisationReasons(patientId: Guid): ng.IHttpPromise<EntityTranslation[]> {
            return this.get(`Patients/${patientId}/HospitalisationReasons`);
        }

        public getPatientFileConsultationsAsync(patientId: Guid): Promise<Contract.Core.Read.IConsultation[]> {
            return this.getPromise(`Patients/${patientId}/Consultations`);
        }

        public savePatientFileHospitalisation(
            patientId: Guid,
            hospitalisation: Contract.Core.Write.IHospitalisation
        ): ng.IHttpPromise<{}> {
            return this.post(`Patients/${patientId}/Hospitalisations`, hospitalisation);
        }

        public updatePatientFileHospitalisation(
            patientId: Guid,
            hospitalisationId: Guid,
            hospitalisation: Contract.Core.Write.IHospitalisation
        ): ng.IHttpPromise<{}> {
            return this.put(`Patients/${patientId}/Hospitalisations/${hospitalisationId}`, hospitalisation);
        }

        public deletePatientFileHospitalisationAsync(patientId: Guid, hospitalisationId: Guid): Promise<void> {
            return this.deletePromise(`Patients/${patientId}/Hospitalisations/${hospitalisationId}`);
        }

        public savePatientFileConsultationAsync(
            patientId: Guid,
            consultation: Contract.Core.Write.IConsultation
        ): Promise<void> {
            return this.postPromise(`Patients/${patientId}/Consultations`, consultation, 'saveConsultation');
        }

        public updatePatientFileConsultationAsync(
            patientId: Guid,
            consultationId: Guid,
            consultation: Contract.Core.Write.IConsultation
        ): Promise<void> {
            return this.putPromise(
                `Patients/${patientId}/Consultations/${consultationId}`,
                consultation,
                'saveConsultation'
            );
        }

        public deletePatientFileConsultationAsync(patientId: Guid, consultationId: Guid): Promise<void> {
            return this.deletePromise(`Patients/${patientId}/Consultations/${consultationId}`);
        }

        public getNonPlannedActionTemplatesAsync(patientId: Guid): Promise<ActionTemplate[]> {
            return this.getPromise(`Patients/${patientId}/NonPlannedActionTemplates`, null, 'actiontemplates');
        }
        public registerNonPlannedRegistration(
            patientId: Guid,
            templateId: Guid,
            registration: Contract.CarePlanAction.Write.IMakeAdHocRegistration
        ): ng.IHttpPromise<{}> {
            return this.post(
                `Patients/${patientId}/NonPlannedActionTemplates/${templateId}/NonPlannedRegistrations`,
                registration,
                'actions'
            );
        }

        public getAnamnesisInfos(patientId: Guid): ng.IHttpPromise<Contract.Patient.Read.IAnamnesisInfo[]> {
            return this.get(`Patients/${patientId}/AnamnesisInfos`);
        }

        public saveAnamnesisInfo(
            patientId: Guid,
            anamnesisInfo: Contract.Patient.Write.IAnamnesisInfo
        ): ng.IHttpPromise<{}> {
            return this.post(`Patients/${patientId}/AnamnesisInfos`, anamnesisInfo);
        }

        public updateAnamnesisInfo(
            patientId: Guid,
            anamnesisInfoId: Guid,
            anamnesisInfo: Contract.Patient.Write.IAnamnesisInfo
        ): ng.IHttpPromise<{}> {
            return this.put(`Patients/${patientId}/AnamnesisInfos/${anamnesisInfoId}`, anamnesisInfo);
        }

        public deleteAnamnesisInfo(patientId: Guid, anamnesisInfoId: Guid): ng.IHttpPromise<{}> {
            return this.delete(`Patients/${patientId}/AnamnesisInfos/${anamnesisInfoId}`);
        }

        public getPatientFileSufferedDiseases(
            patientId: Guid
        ): ng.IHttpPromise<Contract.Patient.Read.IPatientFileSufferedDisease[]> {
            return this.get(`Patients/${patientId}/SufferedDiseases`);
        }

        public savePatientFileSufferedDisease(
            patientId: Guid,
            sufferedDisease: Contract.Patient.Write.IPatientFileSufferedDisease
        ): ng.IHttpPromise<{}> {
            return this.post(`Patients/${patientId}/SufferedDiseases`, sufferedDisease);
        }

        public deletePatientFileSufferedDisease(patientId: Guid, diseaseId: Guid): ng.IHttpPromise<{}> {
            return this.delete(`Patients/${patientId}/SufferedDiseases/${diseaseId}`);
        }

        public getPossibleDiseases(patientId: Guid): ng.IHttpPromise<Contract.Patient.Read.IDisease[]> {
            return this.get(`Patients/${patientId}/PossibleDiseases`);
        }

        public saveInternalRemark(patientId: Guid, remark: string): ng.IHttpPromise<{}> {
            return this.put(`Patients/${patientId}/InternalRemark`, {
                remark,
            });
        }

        public createMutuality(
            patientId: Guid,
            mutality: Contract.Party.Write.IHealthInsuranceFundAssignment
        ): ng.IHttpPromise<{}> {
            return this.post(`Patients/${patientId}/Mutualities`, mutality);
        }

        public changeMutalityValidity(
            patientId: Guid,
            id: number,
            newValidFrom: Date,
            newValidUntil?: Date
        ): ng.IHttpPromise<{}> {
            const newFrom = Shared.DateHelper.toServerDateString(newValidFrom);
            const newUntil = Shared.DateHelper.toServerDateString(newValidUntil);
            return this.post(`Patients/${patientId}/Mutualities/${id}`, {
                newValidFromDate: newFrom,
                newValidUntilDate: newUntil,
            });
        }

        public setExternalReference(
            patientId: Guid,
            externalReference: RemeCare.Contract.Core.IExternalReference
        ): ng.IHttpPromise<{}> {
            return this.post(`Patients/${patientId}/ExternalReferences`, externalReference);
        }

        public assignActorRole(
            patientId: Guid,
            therapyId: Guid,
            id: Guid,
            actorRoleId: Guid,
            partyRoleId: Guid,
            validFromDate: Date,
            validUntilDate: Date
        ): ng.IHttpPromise<{}> {
            let url = `Patients/${patientId}/Therapies/${therapyId}/ActorRoleAssignments`;
            if (id != null) {
                url += `/${id}`;
            }
            return this.post(
                url,
                {
                    actorRoleId,
                    partyRoleId,
                    validFromDate: Shared.DateHelper.toServerDateString(validFromDate),
                    validUntilDate: Shared.DateHelper.toServerDateString(validUntilDate),
                },
                'actorrole.assign'
            );
        }

        public getPossibleActorRoles(
            patientId: Guid,
            excludeHealthCareNonProfessional: boolean
        ): ng.IHttpPromise<Contract.Patient.Read.ITherapyActorRoles[]> {
            return this.get(
                `Patients/${patientId}/PossibleActorRoleAssignments?excludeHealthCareNonProfessional=${excludeHealthCareNonProfessional}`
            );
        }

        public removeActorRoleAssignmentAsync(patientId: Guid, therapyId: Guid, id: Guid): Promise<{}> {
            return this.deletePromise(`Patients/${patientId}/Therapies/${therapyId}/ActorRoleAssignments/${id}`);
        }

        public registerPatientAsync(patient: Contract.Patient.Write.IRegisterPatient): Promise<Guid> {
            return this.postPromise('Patients', patient, 'patient.create') as Promise<Guid>;
        }

        public getDocumentAsync(
            patientId: Guid,
            documentId: Guid
        ): Promise<Contract.Patient.Read.IPatientFileDocument> {
            return this.getPromise(`Patients/${patientId}/Documents/${documentId}`, null, 'patient.documents');
        }

        public findFollowUpDocumentsAsync(
            patientId: Guid,
            query: Contract.Patient.Read.IFindPatientFileDocumentsQuery
        ): Promise<SearchResult<Contract.Patient.Read.IPatientFileDocument>> {
            return this.getPromise(`Patients/${patientId}/FollowUpDocuments`, query, 'patient.followUpDocuments');
        }

        public findDocumentsAsync(
            patientId: Guid,
            query: Contract.Patient.Read.IFindPatientFileDocumentsQuery
        ): Promise<SearchResult<Contract.Patient.Read.IPatientFileDocument>> {
            return this.getPromise(`Patients/${patientId}/Documents`, query, 'patient.documents');
        }

        public saveDocumentAsync(
            patientId: Guid,
            documentId: Guid,
            patientFileDocument: Contract.Patient.Read.IPatientFileDocument
        ): Promise<void> {
            return this.putPromise(
                `Patients/${patientId}/Documents/${documentId}`,
                patientFileDocument,
                'patient.documents.save'
            );
        }

        public findCarePlanMediaAsync(
            patientId: Guid,
            query: Contract.Patient.Read.IFindCarePlanMediaQuery
        ): Promise<SearchResult<Contract.Patient.Read.ICarePlanMedia>> {
            return this.getPromise(`Patients/${patientId}/CarePlanMedia`, query, 'patient.media');
        }

        public deleteDocumentAsync(patientId: Guid, documentId: Guid): Promise<{}> {
            return this.deletePromise(`Patients/${patientId}/Documents/${documentId}`, 'patient.documents.delete');
        }

        public getTherapyComplianceGraphAsync(
            patientId: Guid,
            structuralTherapyActionIds: Guid[],
            fromDate?: Date,
            untilDate?: Date
        ): Promise<Contract.CarePlan.Read.ITherapyComplianceGraph[]> {
            return this.getPromise<Contract.CarePlan.Read.ITherapyComplianceGraph[]>(
                `Patients/${patientId}/TherapyComplianceGraphs`,
                {
                    structuralTherapyActionIds,
                    fromDate: Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: Shared.DateHelper.toServerDateString(untilDate),
                }
            );
        }

        public getMedicationLevelGraphsAsync(
            patientId: Guid,
            medicationFilters: Array<{
                structuralTherapyActionId: Guid;
                ingredientIds?: Guid[];
            }>,
            fromDate?: Date,
            untilDate?: Date
        ): Promise<Array<Contract.Core.IGraph<number>>> {
            const params = {
                medicationFilters,
                fromDate: Shared.DateHelper.toServerDateString(fromDate),
                untilDate: Shared.DateHelper.toServerDateString(untilDate),
            };
            return this.getPromise<Array<Contract.Core.IGraph<number>>>(
                `Patients/${patientId}/MedicationLevelGraphs`,
                params
            );
        }

        public getConnectionsAsync(patientId: string): Promise<Contract.Patient.Read.IPatientConnection[]> {
            return this.getPromise<Contract.Patient.Read.IPatientConnection[]>(`Patients/${patientId}/Connections`);
        }

        public deleteConnectionAsync(patientId: string, connectionId: string): Promise<void> {
            return this.deletePromise(`Patients/${patientId}/Connections/${connectionId}`, 'fitbit-connection');
        }
    }

    remeCarePatientModule.service('patientSvc', PatientService);
}
