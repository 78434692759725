namespace RemeCare.Core.Services {
    import ISearchResult = Shared.Contract.ISearchResult;
    import ISearchHealthCareProfessionalPartyQuery = Contract.HealthCareParty.Read.Query.ISearchHealthCareProfessionalPartyQuery;
    import IHealthCareProfessionalParty = Contract.HealthCareParty.Read.IHealthCareProfessionalParty;

    export class HealthCareProfessionalSearchSvc extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /**@deprecated*/
        public searchProfessionals(
            query: ISearchHealthCareProfessionalPartyQuery
        ): ng.IHttpPromise<ISearchResult<IHealthCareProfessionalParty>> {
            const url = 'HealthCareProfessionals';
            return super.get(url, query, 'professionalsSearch');
        }

        public searchProfessionalsAsync(
            query: ISearchHealthCareProfessionalPartyQuery
        ): Promise<ISearchResult<IHealthCareProfessionalParty>> {
            const url = 'HealthCareProfessionals';
            return super.getPromise(url, query, 'professionalsSearch');
        }

        public getHealthCareProfessional(
            id: Shared.Contract.Guid
        ): Promise<Contract.HealthCareParty.Read.IHealthCareProfessionalParty> {
            return this.getPromise(`HealthCareProfessionals/${id}`);
        }
    }

    remeCareAppModule.service('healthCareProfessionalSearchSvc', HealthCareProfessionalSearchSvc);
}
