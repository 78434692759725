var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlan = RemeCare.Model.Careplan;
        var CarePlanStatus = RemeCare.Shared.Contract.Code.CarePlanStatus;
        var PatientFileCarePlanController = /** @class */ (function (_super) {
            __extends(PatientFileCarePlanController, _super);
            function PatientFileCarePlanController($scope, $translate, toaster, $stateParams, patientSvc, careRequestSearchSvc, $state, modalBuilderFactory, authservice) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.patientSvc = patientSvc;
                _this.careRequestSearchSvc = careRequestSearchSvc;
                _this.$state = $state;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                _this.$scope.editCarePlanStatus = function () { return _this.editCarePlanStatus(); };
                _this.$scope.editCarePlanStartDate = function () { return _this.editCarePlanStartDate(); };
                _this.$scope.viewCareRequest = function () { return _this.viewCareRequest(); };
                _this.$scope.createCareRequest = function (therapy) { return _this.createCareRequest(therapy); };
                _this.$scope.carePlanSelected = function (carePlan) { return _this.carePlanSelected(carePlan); };
                _this.$scope.addAllCarePlansOption = function () { return _this.addAllCarePlansOption(); };
                return _this;
            }
            PatientFileCarePlanController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                this.patientSvc
                    .getCarePlans(this.$scope.patientId)
                    .success(function (ags) {
                    var open = _(_(ags)
                        .filter(function (x) { return x.Status.Id === CarePlanStatus.Open; })
                        .map(function (x) { return new CarePlan(x); })).sortBy(function (x) { return x.startDate; });
                    var closed = _(_(ags)
                        .filter(function (x) { return x.Status.Id !== CarePlanStatus.Open; })
                        .map(function (x) { return new CarePlan(x); })).sortBy(function (x) { return x.statusChangedDate; });
                    _this.$scope.carePlans = open.concat(closed);
                    _this.$scope.carePlanSummary = _(_this.$scope.carePlans).map(function (x) { return ({
                        Id: x.id,
                        Text: x.statusOpen
                            ? x.name +
                                _this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                                    CarePlanStatus: x.status,
                                    StatusDate: x.getFormattedStartDate(),
                                })
                            : x.name +
                                _this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                                    CarePlanStatus: x.status,
                                    StatusDate: x.getFormattedStatusChangedDate(),
                                }),
                    }); });
                    _this.$scope.carePlanSelected(_(_this.$scope.carePlanSummary).first());
                    _this.$scope.addAllCarePlansOption();
                })
                    .error(function (m) { return _this.errorCallback(m); });
                this.$scope.state = this.$state.current;
                this.$scope.state.params = angular.copy(this.$state.params);
                this.$scope.therapies = [];
                if (this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.careRequest, RemeCare.Shared.Framework.AuthRight.Create)) {
                    this.careRequestSearchSvc
                        .getPossibleTherapies()
                        .success(function (t) {
                        _this.$scope.therapies = t;
                    })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            PatientFileCarePlanController.prototype.editCarePlanStatus = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('carePlanStatusModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/carePlanStatusModal.html')
                    .setScope({
                    carePlan: this.$scope.carePlan,
                })
                    .setResultCallBack(function () { })
                    .build();
            };
            PatientFileCarePlanController.prototype.editCarePlanStartDate = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('carePlanStartDateModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/carePlanStartDateModal.html')
                    .setScope({
                    carePlan: this.$scope.carePlan,
                })
                    .setResultCallBack(function () { })
                    .build();
            };
            PatientFileCarePlanController.prototype.createCareRequest = function (therapy) {
                this.$state.go('careRequests.careRequest', {
                    therapyId: therapy.Id,
                    existingPatient: this.$stateParams.patientId,
                });
            };
            PatientFileCarePlanController.prototype.viewCareRequest = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('careRequestModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/careRequestModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                    carePlan: this.$scope.carePlan,
                })
                    .setResultCallBack(function () { })
                    .build();
            };
            PatientFileCarePlanController.prototype.carePlanSelected = function (carePlan) {
                this.$scope.carePlan = _.find(this.$scope.carePlans, function (x) { return x.id === carePlan.Id; });
                var telemonitoringId = _(this.$scope.carePlan.externalReferences).filter(function (r) { return r.System === 'TMPortal' && r.Attribute === 'telemonitoringId'; });
                this.$scope.telemonitoringId =
                    telemonitoringId && telemonitoringId.length ? telemonitoringId[0].Value : undefined;
                this.getRights();
                this.navigateToCarePlanDetails();
            };
            PatientFileCarePlanController.prototype.addAllCarePlansOption = function () {
                // Select all CarePlans is not allowed
            };
            PatientFileCarePlanController.prototype.getRights = function () {
                var _this = this;
                if (!this.$scope.carePlan) {
                    return;
                }
                var query = {
                    carePlanId: this.$scope.carePlan.id,
                };
                this.patientSvc
                    .getTherapyBusinessContextRights(this.$stateParams.patientId, query)
                    .success(function (ags) {
                    var maxRight = _(_(ags).filter(function (t) {
                        return t.TherapyBusinessContext ===
                            RemeCare.Shared.Contract.Code.TherapyBusinessContextCode.CarePlanStatusChange &&
                            t.CarePlan === _this.$scope.carePlan.id;
                    })).max(function (t) { return t.ExecutionRight; }).ExecutionRight;
                    _this.$scope.allowChangeStatus =
                        (maxRight & RemeCare.Shared.Framework.AuthRight.Write) !== 0 && _this.$scope.carePlan.statusOpen;
                    _this.$scope.allowChangeStartDate =
                        (maxRight & RemeCare.Shared.Framework.AuthRight.Write) !== 0 &&
                            moment().isBefore(_this.$scope.carePlan.startDate, 'day');
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileCarePlanController.prototype.navigateToCarePlanDetails = function () {
                if (this.$scope.carePlans.length > 0 && this.$state.current.name === 'patients.patientfile.carePlans') {
                    this.$state.go('patients.patientfile.carePlans.detail', null, {
                        location: 'replace',
                    });
                }
            };
            return PatientFileCarePlanController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('PatientFileCarePlanCtrl', PatientFileCarePlanController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
