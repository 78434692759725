var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestSvc = /** @class */ (function (_super) {
            __extends(CareRequestSvc, _super);
            function CareRequestSvc() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            /** @deprecated */
            CareRequestSvc.prototype.getCareRequest = function (id) {
                return this.get("CareRequests/" + id, null, 'carerequest');
            };
            CareRequestSvc.prototype.getCareRequestAsync = function (id) {
                return this.getPromise("CareRequests/" + id, null, 'carerequest');
            };
            CareRequestSvc.prototype.saveCareRequestAsync = function (careRequest) {
                return this.postPromise('CareRequests', careRequest);
            };
            CareRequestSvc.prototype.cancelCareRequestAsync = function (careRequestId) {
                return _super.prototype.deletePromise.call(this, "CareRequests/" + careRequestId);
            };
            CareRequestSvc.prototype.isVisibleWithConditionality = function (careRequestPart, careRequestTemplate, careRequest) {
                var returnValue = false;
                // Get all the parts that have the current careRequestPart as a condition (GoToPart)
                var partsWithMatchingGoToId = Array();
                careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach(function (part) {
                    if (part.answerConditions.length > 0) {
                        part.answerConditions.forEach(function (ac) {
                            if (ac.goToCareRequestPartId == careRequestPart.id) {
                                partsWithMatchingGoToId.push(part);
                            }
                        });
                    }
                });
                // If there is no matching part, there is no condition specified for the current CareRequestPart => just show the part
                if (partsWithMatchingGoToId.length === 0) {
                    return true;
                }
                // If there is at least one matchingPart, this means that default the current CareRequestPart should be hidden, except if the condition is fullfilled
                // Check if the condition is fulfilled
                partsWithMatchingGoToId.forEach(function (part) {
                    part.answerConditions.forEach(function (ac) {
                        if (ac.goToCareRequestPartId === careRequestPart.id) {
                            if (ac.AnswerTherapyDiseaseId != null) {
                                if (careRequest.comorbidities.indexOf(ac.AnswerTherapyDiseaseId) > -1
                                    || careRequest.treatedDiseases.indexOf(ac.AnswerTherapyDiseaseId) > -1) {
                                    returnValue = true;
                                }
                            }
                            if (ac.answerBoolean != null) {
                                var currentOptions = careRequest.therapyOptions.filter(function (to) { return to.careRequestPartLocalIdentity == part.localIdentity; });
                                currentOptions.forEach(function (co) {
                                    if (co.option == ac.answerBoolean) {
                                        returnValue = true;
                                    }
                                });
                            }
                            if (ac.answerCodeSetItemId != null) {
                                var currentAnamneseValues = careRequest.anamneses.filter(function (a) { return a.careRequestPartLocalIdentity == part.localIdentity; }).map(function (a) { return a.registrationValue.getValueIds(); });
                                var currentAnamneses = [].concat.apply([], currentAnamneseValues);
                                currentAnamneses.forEach(function (ca) {
                                    if (ca == ac.answerCodeSetItemId) {
                                        returnValue = true;
                                    }
                                });
                            }
                        }
                    });
                });
                return returnValue;
            };
            CareRequestSvc.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return CareRequestSvc;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        CareRequest.CareRequestSvc = CareRequestSvc;
        CareRequest.remeCareCareRequestModule.service('careRequestSvc', CareRequestSvc);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
