var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var AlarmsApiService = /** @class */ (function (_super) {
                __extends(AlarmsApiService, _super);
                function AlarmsApiService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                AlarmsApiService.prototype.findAlarmsAsync = function (query) {
                    return this.getPromise('Alarms', query, 'alarms');
                };
                AlarmsApiService.prototype.getAlarmAsync = function (id) {
                    return this.getPromise("Alarms/" + id);
                };
                AlarmsApiService.prototype.getAlarmDefinitionAsync = function (alarmId) {
                    return this.getPromise("Alarms/" + alarmId + "/AlarmDefinition");
                };
                AlarmsApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
                return AlarmsApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.AlarmsApiService = AlarmsApiService;
            RemeCare.remeCareAppModule.service('alarmsApiSvc', AlarmsApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
