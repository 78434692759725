module RemeCare.Contract.Core {
    
    export class Characteristic {
        
        public static heartBeatId = 'cf091fea-4835-4b90-92a1-f99f99ff0af7';

        public static weightId = '2c47bcd7-a9c0-40fd-90f0-a0d3e03fd136';

        public static systolicPressureId = 'f493c88d-df5e-4007-a7bc-f6b9c7a6f4a5';

        public static diastolicPressureId = '81954bd9-d5e1-47c5-ae9a-94d3b2379916';

        public static potassiumId = '04a8e4dc-99e1-4cbf-9240-aac4a2f0ae5c';

        public static creatineId = 'ca184789-f944-45b5-9c8c-69c3ee54ba69';

        public static sodiumId = 'edad326f-3ae8-45e1-961a-074331ba55b1';
    }
} 