module RemeCare.Model {
    import Guid = Shared.Contract.Guid;

    export class IntakeMoment {
        intraDayTimingId: Guid;
        preferredTime: Date;
        quantity: number;

        public toWrite(): Contract.Core.Write.IIntraDayTimingParameter {
            return {
                Id: this.intraDayTimingId,
                PreferredTime: Shared.DateHelper.toServerTimeString(this.preferredTime),
                Quantity: this.quantity
            };
        }

        static buildFromIIntakeMoment(intakeMoment: Contract.Core.IIntakeMoment): IntakeMoment {
            var result = new IntakeMoment();
            result.intraDayTimingId = intakeMoment.Id;
            result.quantity = intakeMoment.Quantity;
            result.preferredTime = Shared.DateHelper.serverTimeToDate(intakeMoment.PreferredTime)
            return result;
        }
    }
} 