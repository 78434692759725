namespace RemeCare.CarePlanAction {
    interface ISignatureScope extends IComponentScope {
        component: SignatureDescriptor;
        signatureUpdated: (newModel: string) => void;
    }

    class SignatureController extends ComponentControllerBase<ISignatureScope> {
        // @ngInject
        constructor($scope: ISignatureScope, guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService) {
            super($scope, guidanceApiSvc);
            $scope.signatureUpdated = (newModel: string) => ($scope.component.signatureImage = newModel);
        }

        protected init(): void {}
    }

    class SignatureDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/signature.html';

        public controller = SignatureController;
    }

    angular.module('RemeCare.CarePlanAction').directive('rcSignature', () => new SignatureDirective());
}
