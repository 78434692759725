var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var QualitativeObservationController = /** @class */ (function (_super) {
            __extends(QualitativeObservationController, _super);
            // @ngInject
            function QualitativeObservationController($scope, toaster, masterdataSvc, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.toaster = toaster;
                _this.masterdataSvc = masterdataSvc;
                return _this;
            }
            QualitativeObservationController.prototype.init = function () {
                this.loadData();
            };
            QualitativeObservationController.prototype.loadData = function () {
                var _this = this;
                var pointInTime = this.$scope.carePlanAction.processVersionDate;
                if (pointInTime === undefined) {
                    pointInTime = moment.utc().toDate();
                }
                this.masterdataSvc
                    .getCodeSetItems(this.$scope.component.optionGroupId, RemeCare.Shared.DateHelper.toServerDateString(pointInTime))
                    .success(function (o) { return (_this.$scope.options = o); })
                    .error(function (m) { return _this.toaster.error(m); });
            };
            return QualitativeObservationController;
        }(CarePlanAction.ComponentControllerBase));
        var QualitativeObservationDirective = /** @class */ (function (_super) {
            __extends(QualitativeObservationDirective, _super);
            function QualitativeObservationDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/qualitativeObservation.html';
                _this.controller = QualitativeObservationController;
                return _this;
            }
            return QualitativeObservationDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcQualitativeObservation', function () { return new QualitativeObservationDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
