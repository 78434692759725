module RemeCare.Inbox {
    import Alarm = Contract.Alarms.Read.IAlarm;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import AlarmDefinition = Shared.Contract.Read.IAlarmDefinition;

    interface IAlarmDetailScope extends Shared.Framework.IBaseScope {
        alarm: Alarm;
        alarmDefinition: AlarmDefinition;
        alarmStatuses: Array<EnumTranslation>;
        patient: EntityTranslation;
        getRecipientList: () => void;
        getFormattedCreationDate: () => void;
        getHandlingInformation: () => void;
        getHandledByInformation: () => void;
    }

    class AlarmDetailController extends Shared.Framework.ControllerBase<IAlarmDetailScope> {
        constructor(
            protected $scope: IAlarmDetailScope,
            protected $translate,
            protected toaster,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($scope, $translate, toaster);
            this.$scope.getRecipientList = () => this.getRecipientList();
            this.$scope.getFormattedCreationDate = () => this.getFormattedCreationDate();
            this.$scope.getHandlingInformation = () => this.getHandlingInformation();
            this.$scope.getHandledByInformation = () => this.getHandledByInformation();
        }

        public $onInit() {
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            let patientName: Contract.Patient.Read.IPersonRoleSummary[];

            try {
                [this.$scope.alarmStatuses, patientName] = await Promise.all([
                    this.masterdataSvc.getAlarmStatusesAsync(),
                    this.patientSvc.getPatientNameAsync(this.$scope.alarm.Patient.Id)
                ]);

                this.$scope.patient = <EntityTranslation>{
                    Id: _(patientName).first().Id,
                    Text: _(patientName).first().FirstName + ' ' + _(patientName).first().LastName
                };
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private getRecipientList(): string {
            const roles = _(this.$scope.alarmDefinition.AlarmTargetGroups).map(x => x.Role.Text);
            return roles.join(', ');
        }

        private getFormattedCreationDate(): string {
            return moment(Shared.DateHelper.serverDateStringToDateTime(this.$scope.alarm.CreationDate)).format(
                'DD/MM/YYYY HH:mm'
            );
        }

        private getHandlingInformation(): string {
            return _(this.$scope.alarmDefinition.AlarmTargetGroups).first() &&
                _(this.$scope.alarmDefinition.AlarmTargetGroups).first().RequiresHandlingByAll
                ? this.$translate.instant('Views.Alarms.HandledByMessage', {
                      amount: this.$translate.instant('Views.Alarms.HandledByAll')
                  })
                : _(this.$scope.alarmDefinition.AlarmTargetGroups).any()
                ? this.$translate.instant('Views.Alarms.HandledByMessage', {
                      amount: this.$translate.instant('Views.Alarms.HandledByOne')
                  })
                : '';
        }

        private getHandledByInformation(): string {
            return _(this.$scope.alarmDefinition.AlarmTargetGroups).first() &&
                _(this.$scope.alarmDefinition.AlarmTargetGroups).first().RequiresHandlingByAll
                ? this.$translate.instant('Views.Alarms.CurrentHandledBy', {
                      current: this.$scope.alarm.AmountOfHandlings,
                      total: _(this.$scope.alarmDefinition.AlarmTargetGroups).size()
                  })
                : null;
        }
    }

    remeCareInboxModule.controller(
        'alarmDetailCtrl',
        ($scope, $translate, toaster, patientSvc, masterdataSvc) =>
            new AlarmDetailController($scope, $translate, toaster, patientSvc, masterdataSvc)
    );
}
