var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlan = RemeCare.Model.Careplan;
        var DateFilterType = RemeCare.Contract.Core.Codes.DateFilterTypeCode;
        var PatientRegistrationsBaseController = /** @class */ (function () {
            function PatientRegistrationsBaseController($stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc) {
                this.$stateParams = $stateParams;
                this.$filter = $filter;
                this.$translate = $translate;
                this.toaster = toaster;
                this.patientSvc = patientSvc;
                this.masterdataSvc = masterdataSvc;
                this.actorRolesApiSvc = actorRolesApiSvc;
            }
            PatientRegistrationsBaseController.prototype.$onInit = function () {
                this.loadDataAsync();
                this.init();
            };
            PatientRegistrationsBaseController.prototype.insertAllPatientRoles = function (actorRoleIds) {
                if (!actorRoleIds) {
                    return actorRoleIds;
                }
                // No merged patient actor role set
                if (!this.mergedPatientActorRoleId) {
                    return actorRoleIds;
                }
                // Patient actor role is not selected
                if (!_.contains(actorRoleIds, this.mergedPatientActorRoleId)) {
                    return actorRoleIds;
                }
                // Add all patient actor roles to the array of actor role ids, union filters duplicates
                return _.union(actorRoleIds, _.map(this.patientActorRoles, function (ar) { return ar.Id; }));
            };
            PatientRegistrationsBaseController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, dateFilterTypes, cps, mergedPatientActorRole, e_1;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                dateFilterTypes = void 0;
                                cps = void 0;
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getDateFilterTypesAsync(),
                                        this.patientSvc.getCarePlansAsync(this.$stateParams.patientId),
                                        this.masterdataSvc.getCarePlanActionStatuses(),
                                        this.actorRolesApiSvc.findActorRolesAsync({ PatientId: this.$stateParams.patientId }),
                                        this.patientSvc.getActionExecutorsAsync(this.$stateParams.patientId),
                                    ])];
                            case 1:
                                _a = _b.sent(), dateFilterTypes = _a[0], cps = _a[1], this.carePlanActionStatuses = _a[2], this.actorRoles = _a[3], this.persons = _a[4];
                                this.patientActorRoles = _.filter(this.actorRoles, function (ar) { return ar.IsPatient; });
                                if (this.patientActorRoles.length > 1) {
                                    mergedPatientActorRole = this.patientActorRoles[0];
                                    mergedPatientActorRole.Text = this.$translate.instant('General.Patient');
                                    this.mergedPatientActorRoleId = mergedPatientActorRole.Id;
                                    this.actorRoles = _.filter(this.actorRoles, function (ar) { return !ar.IsPatient || ar.Id === _this.mergedPatientActorRoleId; });
                                }
                                this.actorRoles = _.sortBy(this.actorRoles, function (a) { return (a.Text ? a.Text.toLowerCase() : ''); });
                                this.dateFilterTypes = _(dateFilterTypes).filter(function (x) {
                                    return x.Id === DateFilterType.PlannedDate ||
                                        x.Id === DateFilterType.ExecutionDate ||
                                        x.Id === DateFilterType.ModificationDate;
                                });
                                this.carePlans = CarePlan.getSortedOpenCloseCareplans(cps);
                                this.carePlanTranslations = _(this.carePlans).map(function (x) { return x.getSummary(_this.$translate, _this.$filter); });
                                this.addAllCarePlansOption(this.carePlanTranslations);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientRegistrationsBaseController.prototype.addAllCarePlansOption = function (carePlans) {
                carePlans.unshift({
                    Id: null,
                    Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
                });
            };
            return PatientRegistrationsBaseController;
        }());
        Patient.PatientRegistrationsBaseController = PatientRegistrationsBaseController;
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
