namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import Periodicity = Shared.Framework.Model.Periodicity;

    export class StructuralTherapyActionPersonalisation {
        public configuredTherapyActions: ConfiguredTherapyAction[] = [];
        public contextSettingVersions: PersonalisedTherapyActionContextSetting[] = [];
        public personalisableContextSettings: PersonalisableTherapyActionPartContextSetting[] = [];
        public defaultConfigurations: ConfiguredTherapyAction[] = [];

        constructor(serverObject?: Contract.CarePlan.Read.IStructuralTherapyActionPersonalisation) {
            if (serverObject) {
                this.configuredTherapyActions = _(serverObject.ConfiguredTherapyActions)
                    .chain()
                    .map((cta) => new ConfiguredTherapyAction(cta))
                    .sortBy((cta) => cta.validFromDate)
                    .value();
                this.personalisableContextSettings = _(serverObject.PersonalisableContextSettings)
                    .chain()
                    .map((ptapcs) => new PersonalisableTherapyActionPartContextSetting(ptapcs))
                    .sortBy((ptapcs) => ptapcs.sequence)
                    .value();
                this.contextSettingVersions = _(serverObject.ContextSettingVersions)
                    .chain()
                    .map(
                        (ptacs) =>
                            new PersonalisedTherapyActionContextSetting(ptacs, this.personalisableContextSettings)
                    )
                    .sortBy((ptacs) => ptacs.validFromDate)
                    .value();
                this.defaultConfigurations = _(serverObject.DefaultConfigurations)
                    .chain()
                    .map((dc) => new ConfiguredTherapyAction(dc))
                    .sortBy((dc) => dc.validFromDate)
                    .value();
            }
        }
    }

    export class ConfiguredTherapyAction {
        public id: Guid;
        public validFromDate: Date;
        public validUntilDate: Date;
        public isPersonalised: boolean;
        public isRemovedFromCarePlan: boolean;
        public periodicities: Periodicity[] = [];
        public resetToDefault: boolean;
        public isPeriodicitiesPersonalised: boolean;
        public isIntradayTimingPersonalised: boolean;
        public possibleActionDurationOptionName: string;
        public possibleActionDurationOptionId: Guid;
        public durationOfAction: Date;

        constructor(serverObject?: Contract.CarePlan.Read.IConfiguredTherapyAction) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.isPersonalised = serverObject.IsPersonalised;
                this.isRemovedFromCarePlan = serverObject.IsRemovedFromCarePlan;
                const periodicityFactory = new Shared.Framework.Factory.PeriodicityFactory();
                this.periodicities = _(serverObject.Periodicities).map((p) => periodicityFactory.createPeriodicity(p));
                this.isPeriodicitiesPersonalised = serverObject.IsPeriodicitiesPersonalised;
                this.isIntradayTimingPersonalised = serverObject.IsIntradayTimingPersonalised;
                this.possibleActionDurationOptionName = serverObject.PossibleActionDurationOptionName;
                this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                this.durationOfAction = Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
            }
        }

        public canBeEdited(): boolean {
            return !this.validFromDate || moment(this.validFromDate).isAfter(moment());
        }

        public copy(): ConfiguredTherapyAction {
            const result = new ConfiguredTherapyAction();
            result.id = this.id;
            result.validFromDate = this.validFromDate;
            result.validUntilDate = this.validUntilDate;
            result.isPersonalised = this.isPersonalised;
            result.isPeriodicitiesPersonalised = this.isPeriodicitiesPersonalised;
            result.isRemovedFromCarePlan = this.isRemovedFromCarePlan;
            result.periodicities = _(this.periodicities).map((p) => p.copy());
            result.resetToDefault = this.resetToDefault;
            result.isIntradayTimingPersonalised = this.isIntradayTimingPersonalised;
            result.durationOfAction = this.durationOfAction;
            result.possibleActionDurationOptionName = this.possibleActionDurationOptionName;
            result.possibleActionDurationOptionId = this.possibleActionDurationOptionId;
            return result;
        }

        public toServerWrite(): Contract.CarePlan.Write.IPersonalisedTherapyAction {
            return {
                personalisedTherapyActionId: this.id,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                isRemovedFromCarePlan: this.isRemovedFromCarePlan,
                periodicities: _(this.periodicities).map((p) => p.toWriteModel()),
                resetToDefault: this.resetToDefault,
                possibleActionDurationOptionId: this.possibleActionDurationOptionId,
                durationOfAction: Shared.DateHelper.toServerTimeString(this.durationOfAction),
            } as Contract.CarePlan.Write.IPersonalisedTherapyAction;
        }
    }

    export class PersonalisedTherapyActionContextSetting {
        public validFromDate: Date;
        public validUntilDate: Date;
        public contextSettings: PersonalisedTherapyActionPartContextSetting[] = [];
        public personalisableContextSettings: PersonalisedTherapyActionPartContextSetting[] = [];

        constructor(
            serverObject?: Contract.CarePlan.Read.IPersonalisedTherapyActionContextSetting,
            personalisableContextSettings?: PersonalisableTherapyActionPartContextSetting[]
        ) {
            if (serverObject != null) {
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.setContextSettings(
                    _(serverObject.ContextSettings).map(
                        (cs) => new PersonalisedTherapyActionPartContextSetting(cs, personalisableContextSettings)
                    )
                );
            }
        }

        public setContextSettings(
            personalisedTherapyActionPartContextSetting: PersonalisedTherapyActionPartContextSetting[]
        ) {
            this.contextSettings = personalisedTherapyActionPartContextSetting;
            this.personalisableContextSettings = _(personalisedTherapyActionPartContextSetting).filter(
                (cs) => cs.canPersonaliseToHidePart || cs.canPersonaliseTelemonitoringDeactivation
            );
        }

        public canBeEdited(): boolean {
            return !this.validFromDate || moment(this.validFromDate).isAfter(moment());
        }

        public toServerWrite(): Contract.CarePlan.Write.IPersonalisedTherapyActionContextSetting {
            return {
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                personalisedTherapyActionPartContextSettings: _(this.contextSettings).map((cs) => cs.toServerWrite()),
            } as Contract.CarePlan.Write.IPersonalisedTherapyActionContextSetting;
        }
    }

    export class PersonalisedTherapyActionPartContextSetting {
        public id: Guid;
        public isHidden: boolean;
        public usesNoTelemonitoring: boolean;
        public therapyActionPartId: Guid;

        public canPersonaliseToHidePart: boolean;
        public canPersonaliseTelemonitoringDeactivation: boolean;
        public sequence: number;
        public name: string;

        constructor(
            serverObject?: Contract.CarePlan.Read.IPersonalisedTherapyActionPartContextSetting,
            personalisableContextSettings?: PersonalisableTherapyActionPartContextSetting[]
        ) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.isHidden = serverObject.IsHidden;
                this.usesNoTelemonitoring = serverObject.UsesNoTelemonitoring;
                this.therapyActionPartId = serverObject.TherapyActionPartId;
                const contextSetting = _(personalisableContextSettings).find(
                    (pcs) => pcs.therapyActionPartId === this.therapyActionPartId
                );
                this.canPersonaliseToHidePart = contextSetting.canPersonaliseToHidePart;
                this.canPersonaliseTelemonitoringDeactivation = contextSetting.canPersonaliseTelemonitoringDeactivation;
                this.sequence = contextSetting.sequence;
                this.name = contextSetting.name;
            }
        }

        public toServerWrite(): Contract.CarePlan.Write.IPersonalisedTherapyActionPartContextSetting {
            return {
                personalisedTherapyActionPartContextSettingId: this.id,
                therapyActionPartId: this.therapyActionPartId,
                isHidden: this.isHidden,
                usesNoTelemonitoring: this.usesNoTelemonitoring,
            } as Contract.CarePlan.Write.IPersonalisedTherapyActionPartContextSetting;
        }
    }

    export class PersonalisableTherapyActionPartContextSetting {
        public therapyActionPartId: Guid;
        public canPersonaliseToHidePart: boolean;
        public canPersonaliseTelemonitoringDeactivation: boolean;
        public sequence: number;
        public name: string;

        constructor(serverObject?: Contract.CarePlan.Read.IPersonalisableTherapyActionPartContextSetting) {
            if (serverObject != null) {
                this.therapyActionPartId = serverObject.TherapyActionPartId;
                this.canPersonaliseToHidePart = serverObject.CanPersonaliseToHidePart;
                this.canPersonaliseTelemonitoringDeactivation = serverObject.CanPersonaliseTelemonitoringDeactivation;
                this.sequence = serverObject.Sequence;
                this.name = serverObject.Name;
            }
        }
    }
}
