var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var LinkedExternalIdentitiyProviderController = /** @class */ (function () {
            function LinkedExternalIdentitiyProviderController() {
            }
            LinkedExternalIdentitiyProviderController.prototype.$onInit = function () { };
            LinkedExternalIdentitiyProviderController.prototype.buttonClicked = function () {
                this.onButtonClicked({
                    provider: this.linkedProvider
                });
            };
            return LinkedExternalIdentitiyProviderController;
        }());
        Management.remeCareManagementModule.component('rcLinkedExternalIdentityProvider', {
            bindings: {
                buttonLabel: '@',
                linkedProvider: '<',
                onButtonClicked: '&',
            },
            controller: LinkedExternalIdentitiyProviderController,
            templateUrl: 'views/management/persons/userDetails/linkedExternalIdentityProvider.html',
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
