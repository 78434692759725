/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MedicationDoseChartController = /** @class */ (function (_super) {
            __extends(MedicationDoseChartController, _super);
            // @ngInject
            function MedicationDoseChartController($rootScope, $locale, dateHelper, spinnerSvc, toaster, $q, patientSvc, masterdataSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.$q = $q;
                _this.patientSvc = patientSvc;
                _this.masterdataSvc = masterdataSvc;
                return _this;
            }
            MedicationDoseChartController.prototype.init = function () {
                return this.$q.resolve();
            };
            MedicationDoseChartController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var medicationFilters, medicationLevelGraphs, graphs, ingredientIds, unitIds, ingredients_1, _a, units_1, _b, error_1;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                medicationFilters = _.map(this.monitoringPart.monitoringPartSources, function (mps) { return ({
                                    structuralTherapyActionId: mps.structuralTherapyActionId,
                                    ingredientIds: (mps.filter && mps.filter.ingredientIds) || []
                                }); });
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 9, , 10]);
                                return [4 /*yield*/, this.patientSvc.getMedicationLevelGraphsAsync(this.patientId, medicationFilters, this.dateInfo.fromDate, this.getUntilDate())];
                            case 2:
                                medicationLevelGraphs = _c.sent();
                                graphs = _(medicationLevelGraphs).map(function (g) { return new Patient.Model.NumberGraph(g, true); });
                                ingredientIds = _(graphs).map(function (g) { return g.subject.Id; });
                                unitIds = _(graphs)
                                    .chain()
                                    .map(function (g) { return (g.scale != null ? g.scale.Id : null); })
                                    .filter(function (id) { return id != null; })
                                    .value();
                                if (!(ingredientIds.length > 0)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.masterdataSvc.findIngredientsAsync(ingredientIds)];
                            case 3:
                                _a = (_c.sent()).Items;
                                return [3 /*break*/, 5];
                            case 4:
                                _a = new Array();
                                _c.label = 5;
                            case 5:
                                ingredients_1 = _a;
                                if (!(unitIds.length > 0)) return [3 /*break*/, 7];
                                return [4 /*yield*/, this.masterdataSvc.findProductUnitsAsync(unitIds)];
                            case 6:
                                _b = _c.sent();
                                return [3 /*break*/, 8];
                            case 7:
                                _b = new Array();
                                _c.label = 8;
                            case 8:
                                units_1 = _b;
                                _(graphs).forEach(function (g) {
                                    g.subject = _.find(ingredients_1, function (i) { return i.Id === g.subject.Id; });
                                    g.scale = g.scale != null ? _.find(units_1, function (u) { return u.Id === g.scale.Id; }) : null;
                                });
                                this.configureCharts(graphs);
                                return [3 /*break*/, 10];
                            case 9:
                                error_1 = _c.sent();
                                this.toaster.error(error_1);
                                return [3 /*break*/, 10];
                            case 10: return [2 /*return*/];
                        }
                    });
                });
            };
            MedicationDoseChartController.prototype.configureCharts = function (graphs) {
                var _this = this;
                var chartConfigs = _(graphs).map(function (g) {
                    return ({
                        options: {
                            chart: {
                                animation: false,
                                type: 'line',
                                height: 200,
                                zoomType: 'xy',
                                spacingTop: 40,
                                marginLeft: 37,
                                marginRight: 37
                            },
                            xAxis: _this.getXAxisConfig(null, true),
                            yAxis: {
                                startOnTick: false,
                                endOnTick: false,
                                title: {
                                    align: 'high',
                                    offset: 0,
                                    rotation: 0,
                                    text: g.scale ? g.scale.Text : null,
                                    y: -20,
                                    x: -27,
                                    textAlign: 'left'
                                },
                                max: _this.getMaxYScaleValue(g),
                                min: _this.getMinYScaleValue(g),
                                labels: {
                                    align: 'right',
                                    x: 0,
                                    y: -2
                                }
                            },
                            tooltip: {
                                xDateFormat: _this.getDateFormat(false),
                                pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} {point.series.yAxis.options.title.text}</b><br/>'
                            },
                            title: {
                                text: g.subject.Text
                            },
                            exporting: {
                                enabled: false
                            },
                            credits: {
                                enabled: false
                            },
                            legend: {
                                enabled: _this.showLegend
                            }
                        },
                        series: [_this.mapDataPoints(g)]
                    });
                });
                this.chartConfigs = chartConfigs;
            };
            MedicationDoseChartController.prototype.mapDataPoints = function (graph) {
                var _this = this;
                return {
                    animation: false,
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataPoint(gp); })
                        .sortBy(function (gp) { return gp[0]; })
                        .value(),
                    step: true,
                    marker: {
                        enabled: false
                    },
                    name: graph.subject.Text
                };
            };
            MedicationDoseChartController.prototype.getDataPoint = function (graphPoint) {
                return [graphPoint.x.valueOf(), graphPoint.y];
            };
            return MedicationDoseChartController;
        }(Patient.ChartMonitoringPartController));
        var MedicationDoseChartComponent = /** @class */ (function (_super) {
            __extends(MedicationDoseChartComponent, _super);
            function MedicationDoseChartComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = MedicationDoseChartController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return MedicationDoseChartComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringMedicationDoseChart', new MedicationDoseChartComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
