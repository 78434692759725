module RemeCare.Contract.Core.Codes {
     
     export enum AdministrationMeansCode {
         Oral = 1,
         Subcutaneous = 2,
         Intramuscular = 3,
         Intravenous = 4,
         Transdermal = 5,
         Inhalation = 6,
         Enteral = 7,
         Nasal = 8,
         Ocular = 9,
         Vaginal = 10,
         Cutaneous = 11,
         Ear = 12,
         Anal = 13,
         Intracavernal = 14,
         Chirurgical = 15,
         Buccal = 16,
         Other = 17
     }
 }