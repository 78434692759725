module RemeCare.Management {

    class ManageOrganisationControlller implements ng.IComponentController {

        // bindings
        public $transition$;

        public organisation: Model.Organisation;
        public addressGrid: Shared.Framework.Grid.Grid<Model.Address>;
        public phoneFaxGrid: Shared.Framework.Grid.Grid<Model.TelephoneNumber>;
        public emailGrid: Shared.Framework.Grid.Grid<Model.Email>;
        public relatedCareProvidersGrid: Shared.Framework.Grid.Grid<Contract.Party.Read.ICareProviderRelation>;
        public externalIdentityProviders: ExternalIdentityProvider[];

        constructor(
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private authservice: Shared.Framework.AuthService,
            private toaster: Shared.Framework.Toaster,
            private partyApiSvc: Core.Services.PartyApiService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory) {
        }

        public $onInit(): void {
            this.buildGrids();
            this.partyApiSvc.getOrganisationDetails(this.$transition$.params().partyId)
                .success(r => {
                    this.organisation = new Model.Organisation(r);
                    this.setGridData();
                })
                .error(e => this.toaster.error(e));
            this.retrieveExternalIdentityProviders();
        }

        public externalIdentityProviderLinkedChanged(provider: ExternalIdentityProvider): void {
            provider.loading = true;
            const organisationId = this.$transition$.params().partyId;
            const promise = provider.linked
                ? this.partyApiSvc.linkExternalIdentityProvider(organisationId, provider.id)
                : this.partyApiSvc.unlinkExternalIdentityProvider(organisationId, provider.id);
            promise.error((e) => {
                this.toaster.error(e);
                this.$timeout(() => {
                    provider.toggleLinked();
                });
            }).finally(() => provider.loading = false);
        }

        private retrieveExternalIdentityProviders(): void {
            if (!this.authservice.hasRight(
                Shared.Framework.AuthContext.managementOrganisationsExternalIdentity,
                Shared.Framework.AuthRight.Read)) {
                return;
            }
            this.$q.all([
                this.partyApiSvc.getPossibleExternalIdentityProviders(),
                this.partyApiSvc.getLinkedExternalIdentityProviders(this.$transition$.params().partyId)
            ]).then((results) => {
                this.externalIdentityProviders = _.map(results[0].data, (eip) => {
                    const provider = new ExternalIdentityProvider(eip);
                    provider.linked = _.any(results[1].data, (leip) => leip.Id === eip.Id);
                    return provider;
                });
            });
        }

        private buildGrids(): void {
            this.addressGrid = this.gridBuilderSvc
                .createGridBuilder<Model.Address>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('addressLine1', 'General.StreetAndNumber')
                .addColumn('city', 'General.City')
                .addEnumColumn('country', 'General.Country', Shared.Framework.ReferenceDataTypes.country)
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.phoneFaxGrid = this.gridBuilderSvc
                .createGridBuilder<Model.TelephoneNumber>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addEnumColumn('type', '', Shared.Framework.ReferenceDataTypes.contactPointType)
                .addColumn('toString()', 'General.Number')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.emailGrid = this.gridBuilderSvc
                .createGridBuilder<Model.Email>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('email', 'General.Email')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.relatedCareProvidersGrid = this.gridBuilderSvc
                .createGridBuilder<Contract.Party.Read.ICareProviderRelation>()
                .addColumn('HealthCareProfessional.Title', 'General.PersonTitle')
                .addColumn('HealthCareProfessional.FirstName', 'General.FirstName')
                .addColumn('HealthCareProfessional.LastName', 'General.LastName')
                .addColumn('FunctionInOrganisation', 'General.Function')
                .addDateColumn('ValidFromDate', 'General.From', 'shortDate')
                .addDateColumn('ValidUntilDate', 'General.Until', 'shortDate')
                .addNavigationColumn('eye-open', 'management.persons.detail', { partyId: 'HealthCareProfessional.PartyId' })
                .setExternalSorting(false)
                .setSorting(true)
                .build();
        }

        private setGridData(): void {
            this.addressGrid.setData(this.organisation.addresses);
            this.phoneFaxGrid.setData(this.organisation.getAllNumbers());
            this.emailGrid.setData(this.organisation.emailAddresses);
            var relations = _(this.organisation.getHealthCareOrganisationPartyRole().CareProviderRelations)
                .chain()
                .sortBy(cpr => cpr.HealthCareProfessional.LastName)
                .sortBy(cpr => cpr.HealthCareProfessional.FirstName)
                .value();
            this.relatedCareProvidersGrid.setData(relations);
        }
    }

    remeCareManagementModule.component('rcManageOrganisationDetail',
        {
            bindings: {
                $transition$: '<'
            },
            controller: ManageOrganisationControlller,
            templateUrl: 'views/management/organisations/organisationDetail.html'
        });
}