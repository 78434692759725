module RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import RuleThreshold = Shared.Framework.Model.RuleThresholdDefinition;
    import RuleThresholdValue = Shared.Framework.Model.RuleThresholdValue;


    interface IPatientFileEditRuleThresholdValueModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        carePlan: CarePlan;

        threshold: RuleThreshold;
        editValue: RuleThresholdValue;
        previousValue: RuleThresholdValue;

        newValidFromDate: Date;

        minDate: Date;
        maxDate: Date;

        confirm: () => void;
    }

    class PatientFileEditRuleThresholdValueModalController extends Shared.Framework.ControllerBase<IPatientFileEditRuleThresholdValueModalScope> {

        constructor(
            $scope: IPatientFileEditRuleThresholdValueModalScope,
            $translate,
            toaster,
            private $dialog,
            private $stateParams,
            private carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }
        
        public $onInit() {
            this.$scope.patientId = this.$stateParams.patientId;
            this.$scope.newValidFromDate = this.$scope.editValue.validFromDate;

            var minDate = moment().add(1, 'days').startOf('day').toDate();
            this.$scope.previousValue = _(_(_(this.$scope.threshold.ruleThresholdValues).filter(v => v.isPersonalised && moment(v.validFromDate).isBefore(this.$scope.editValue.validFromDate))).sortBy(v => v.validFromDate)).last();
            this.$scope.minDate = this.$scope.previousValue && moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() > minDate ? moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() : minDate;

            this.$scope.maxDate = this.$scope.editValue.validUntilDate;
        }

        private confirm(): void {
            if (!this.$scope.editValue.isPersonalised) {
                this.$scope.previousValue.validUntilDate = moment(this.$scope.newValidFromDate).add(-1, 'day').toDate();
                this.carePlanApiSvc.updatePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.previousValue.toServerWrite())
                    .success(() => { this.$scope.$close() })
                    .error((m) => this.errorCallback(m));
            } else {
                this.$scope.editValue.validFromDate = this.$scope.newValidFromDate;
                this.carePlanApiSvc.savePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.editValue.toServerWrite())
                    .success(() => { this.$scope.$close() })
                    .error((m) => this.errorCallback(m));
            }
        }
    }

    angular.module('RemeCare.Patient').controller('PatientFileEditRuleThresholdValueModalCtrl', (
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        carePlanApiSvc: Core.Services.CarePlanApiService
    ) => new PatientFileEditRuleThresholdValueModalController(
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        carePlanApiSvc
    ));
}