var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient_1) {
        var PatientSimpleSearchController = /** @class */ (function (_super) {
            __extends(PatientSimpleSearchController, _super);
            // @ngInject
            function PatientSimpleSearchController($scope, $translate, toaster, authservice, modalBuilderFactory) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.authservice = authservice;
                _this.modalBuilderFactory = modalBuilderFactory;
                $scope.searchPatient = function () { return _this.searchPatient(); };
                $scope.clearPatient = function () { return _this.clearPatient(); };
                _this.$onInit();
                return _this;
            }
            PatientSimpleSearchController.prototype.$onInit = function () {
                this.$scope.isSelectedFromModal = false;
                this.$scope.isInternalUser = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientInternal, RemeCare.Shared.Framework.AuthRight.Read);
                this.$scope.data = { patient: null, patientNumber: null };
            };
            PatientSimpleSearchController.prototype.searchPatient = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('inboxPatientSearchController as searchCtrl')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setTemplateUrl('views/inbox/alarms/patientSearch.html')
                    .setResultCallBack(function (r) {
                    _this.selectPatient(r);
                })
                    .build();
            };
            PatientSimpleSearchController.prototype.selectPatient = function (patient) {
                this.$scope.isSelectedFromModal = true;
                this.$scope.data.patient = patient;
                this.$scope.data.patientNumber = patient.PatientNumber.toString();
            };
            PatientSimpleSearchController.prototype.clearPatient = function () {
                this.$scope.isSelectedFromModal = false;
                this.$scope.data.patient = null;
                this.$scope.data.patientNumber = null;
            };
            return PatientSimpleSearchController;
        }(RemeCare.Shared.Framework.ControllerBase));
        var PatientSimpleSearchDirective = /** @class */ (function () {
            function PatientSimpleSearchDirective() {
                this.restrict = 'E';
                this.templateUrl = 'views/patient/patientSimpleSearch.html';
                this.scope = {
                    data: '=ngModel',
                    patientNumber: '=patientNumber',
                    patientNumberSearch: '=patientNumberSearch',
                };
                this.controller = PatientSimpleSearchController;
            }
            return PatientSimpleSearchDirective;
        }());
        Patient_1.remeCarePatientModule.directive('rcPatientSimpleSearch', function () { return new PatientSimpleSearchDirective(); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
