module RemeCare.CarePlanAction {

    interface IPlanNewContactScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        newContactForm: ng.IFormController;
        fromDate: Date;
        untilDate: Date;
        remark: string;
        actionName: string;
        hasTemporarySave: boolean;
        planNewContact(): void;
        fromDateChanged(): void;
    }

    class PlanNewContactController extends Shared.Framework.ControllerBase<IPlanNewContactScope> {

        constructor(
            $scope: IPlanNewContactScope,
            $translate,
            toaster,
            private carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private authservice: Shared.Framework.AuthService) {
            super($scope, $translate, toaster);
            $scope.planNewContact = () => this.planNewContact();
            $scope.fromDateChanged = () => this.fromDateChanged();
        }

        public $onInit(): void {
            if (this.$scope.fromDate == null) {
                this.$scope.fromDate = new Date();
                this.fromDateChanged();
            }
        }

        private fromDateChanged(): void {
            if (this.$scope.fromDate != null && (this.$scope.untilDate == null || this.$scope.untilDate < this.$scope.fromDate)) {
                this.$scope.untilDate = new Date(this.$scope.fromDate.getTime());
            }
        }

        private planNewContact(): void {
            if (this.$scope.newContactForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            var params = {
                from: Shared.DateHelper.toServerDateTimeString(this.$scope.fromDate),
                until: this.$scope.untilDate != null
                    ? Shared.DateHelper.toServerDateTimeString(this.$scope.untilDate)
                    : Shared.DateHelper.toServerDateTimeString(this.$scope.fromDate),
                remark: this.$scope.remark,
                type: Shared.Contract.Code.ActionType.TelephoneCallPlanning
            }
            this.$scope.$close(params);             
        }
    }

    remeCareCarePlanActionModule.controller('planNewContactCtrl', PlanNewContactController);
}