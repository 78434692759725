'use strict';
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Consultation = /** @class */ (function () {
            function Consultation(serverParty) {
                if (serverParty != null) {
                    this.id = serverParty.Id;
                    this.consultationDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverParty.Date);
                    this.careProviderType = serverParty.CareProviderType;
                    this.reason = serverParty.Reason;
                }
            }
            Consultation.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    date: RemeCare.Shared.DateHelper.toServerDateString(this.consultationDate),
                    careProviderType: this.careProviderType ? this.careProviderType.Id : null,
                    reason: this.reason
                };
            };
            return Consultation;
        }());
        Model.Consultation = Consultation;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
