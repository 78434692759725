/// <reference path="../../../../carePlanAction/model/carePlanAction.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanActionDetailModalController = /** @class */ (function (_super) {
            __extends(CarePlanActionDetailModalController, _super);
            function CarePlanActionDetailModalController($scope, $translate, toaster, carePlanActionApiSvc, partyApiSvc, gridBuilderSvc, modalBuilderFactory, authservice) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.partyApiSvc = partyApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                _this.$scope.showHistory = function () { return _this.showHistory(); };
                _this.$scope.registerFunctions = function (functions) {
                    return _this.registerFunctions(functions);
                };
                return _this;
            }
            CarePlanActionDetailModalController.prototype.$onInit = function () {
                this.$scope.panelCollapsed = true;
                this.$scope.fullSize = true;
                this.buildRegistrationValueGrid();
                this.getCarePlanActionRegistrationsAsync();
                this.getCarePlanActionAsync(null);
            };
            CarePlanActionDetailModalController.prototype.registerFunctions = function (functions) {
                this.$scope.cancelRegistrations = function () { return functions.cancelRegistrations(); };
                this.$scope.register = function (isPermanent) { return functions.register(isPermanent); };
                this.$scope.cancel = function () { return functions.cancel(); };
                this.$scope.planNewContact = function (includeParts) { return functions.planNewContact(includeParts); };
                this.$scope.hasPhoneCall = function () { return functions.hasPhoneCall(); };
                this.$scope.functionsRegistered = true;
            };
            CarePlanActionDetailModalController.prototype.getCarePlanActionRegistrationsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this.$scope;
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionRegistrationsAsync(this.$scope.actionId)];
                            case 1:
                                _a.registrationValues = _b.sent();
                                this.$scope.registrationsGrid.setData(this.$scope.registrationValues);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanActionDetailModalController.prototype.getCarePlanActionAsync = function (pointInTime) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, carePlanAction, persons, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    pointInTime: pointInTime,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 5, , 6]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionAsync(this.$scope.actionId, query)];
                            case 2:
                                carePlanAction = _a.sent();
                                if (!(carePlanAction.Patient != null)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.partyApiSvc.getPersonsAsync([carePlanAction.Patient.Id])];
                            case 3:
                                persons = _a.sent();
                                this.$scope.action = new RemeCare.CarePlanAction.CarePlanAction(this.authservice, carePlanAction, persons[0]);
                                this.$scope.canBeCancelled =
                                    this.$scope.action.status !== RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed && this.$scope.action.status !==
                                        RemeCare.Contract.Core.Codes.CarePlanActionStatus.Cancelled;
                                this.$scope.patientName = persons[0].FirstName + " " + persons[0].LastName;
                                this.configure();
                                _a.label = 4;
                            case 4: return [3 /*break*/, 6];
                            case 5:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 6];
                            case 6: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanActionDetailModalController.prototype.configure = function () {
                this.$scope.hasRegistrationParts =
                    _(this.$scope.action.components).filter(function (c) {
                        return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.ProvideInformationObject &&
                            c.questions.length === 0;
                    }).length < this.$scope.action.components.length;
            };
            CarePlanActionDetailModalController.prototype.buildRegistrationValueGrid = function () {
                var _this = this;
                this.$scope.registrationsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('RegistrationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', {
                    cellFilter: 'displayUtcDateTimeFilter',
                })
                    .addColumn('RegisteredBy', 'Views.PatientFile.Monitoring.RegisteredBy')
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (rv) { return _this.showCarePlanAction(rv.RegistrationDateTime); }, function (rv) { return _this.editCarePlanAction(rv); }, function () { return !_this.$scope.editRight; })
                    .setExternalSorting(false)
                    .build();
            };
            CarePlanActionDetailModalController.prototype.showCarePlanAction = function (pointInTime) {
                this.getCarePlanActionAsync(pointInTime);
            };
            CarePlanActionDetailModalController.prototype.editCarePlanAction = function (registrationValue) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.getCarePlanActionAsync(registrationValue.RegistrationDateTime);
                        return [2 /*return*/, registrationValue];
                    });
                });
            };
            CarePlanActionDetailModalController.prototype.showHistory = function () {
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcCarePlanActionHistory')
                    .setBindings({
                    actionId: this.$scope.actionId,
                })
                    .setSize('xl')
                    .build();
            };
            return CarePlanActionDetailModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('carePlanActionDetailModalCtrl', CarePlanActionDetailModalController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
