var RemeCare;
(function (RemeCare) {
    var Framework;
    (function (Framework) {
        var RemeCareLocation = /** @class */ (function () {
            function RemeCareLocation() {
            }
            RemeCareLocation.prototype.$onInit = function () { };
            return RemeCareLocation;
        }());
        RemeCare.remeCareAppModule.component('rcLocation', {
            controller: RemeCareLocation,
            templateUrl: 'framework/components/location/location.html',
            bindings: {
                address: '=',
                disabled: '=?ngDisabled',
                changeLocation: '&?',
            },
        });
    })(Framework = RemeCare.Framework || (RemeCare.Framework = {}));
})(RemeCare || (RemeCare = {}));
