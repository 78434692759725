'use strict';

module RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class Hospitalisation {
        static hospitalisationReasonOther = 'e09020e8-4645-481f-a709-bc5de3acf14c';

        id: Guid;
        startDate: Date;
        endDate: Date;
        departmentType: Shared.Contract.IEnumTranslation;
        hospitalisationReason: EntityTranslation;
        otherReason: string;

        constructor(serverParty?: Contract.Core.Read.IHospitalisation) {
            if (serverParty != null) {
                this.id = serverParty.Id;
                this.startDate = Shared.DateHelper.serverDateStringToDate(serverParty.StartDate);
                this.endDate = Shared.DateHelper.serverDateStringToDate(serverParty.EndDate);
                this.departmentType = serverParty.DepartmentType;
                this.hospitalisationReason = serverParty.HospitalisationReason;
                this.otherReason = serverParty.OtherReason;
            }
        }

        getDuration(): number {
            if (this.startDate == null || this.endDate == null) return 0;

            var momentStart = moment(this.startDate);
            var momentEnd = moment(this.endDate);

            if (momentStart.isAfter(momentEnd, 'day')) return 0;

            return momentEnd.diff(momentStart, 'day') + 1;
        }

        getHospitalisationReason(): string {
            if (this.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                return this.hospitalisationReason.Text + ': ' + this.otherReason;
            }
            return this.hospitalisationReason.Text;
        }

        toServerWrite(): Contract.Core.Write.IHospitalisation {
            return <Contract.Core.Write.IHospitalisation> {
                id: this.id,
                careType: this.departmentType != null ? this.departmentType.Id : null,
                reasonId: this.hospitalisationReason != null ? this.hospitalisationReason.Id : null,
                startDate: Shared.DateHelper.toServerDateString(this.startDate),
                endDate: Shared.DateHelper.toServerDateString(this.endDate),
                otherReason: this.hospitalisationReason != null && this.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther
                    ? this.otherReason
                    : null
            }
        }
    }
}