var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Controllers;
        (function (Controllers) {
            var SearchHealthCareProfessionalPartyCtrl = /** @class */ (function (_super) {
                __extends(SearchHealthCareProfessionalPartyCtrl, _super);
                function SearchHealthCareProfessionalPartyCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareProfessionalSearchSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.uiGridConstants = uiGridConstants;
                    _this.healthCareProfessionalSearchSvc = healthCareProfessionalSearchSvc;
                    _this.$scope.clearSearchParams = function () { return _this.clearSearchParams(); };
                    _this.$scope.toggleCriteria = function () { return _this.toggleCriteria(); };
                    _this.$scope.search = function () { return _this.search(); };
                    return _this;
                }
                SearchHealthCareProfessionalPartyCtrl.prototype.$onInit = function () {
                    this.$scope.searchHealthCareProfessionalPartyGrid = this.buildGrid();
                    if (this.$scope.prefill) {
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.firstName = this.$scope.prefill.FirstName;
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.lastName = this.$scope.prefill.LastName;
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.email = this.$scope.prefill.Email;
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.rizivNumber = this.$scope.prefill.IdentificationNumber;
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.zipCode = this.$scope.prefill.ZipCode;
                        this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria.city = this.$scope.prefill.City;
                        this.search();
                    }
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.clearSearchParams = function () {
                    this.$scope.searchHealthCareProfessionalPartyGrid.searchCriteria = {};
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.toggleCriteria = function () {
                    this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.search = function () {
                    this.$scope.searchHealthCareProfessionalPartyGrid.pagingOptions.currentPage = 1;
                    this.$scope.searchHealthCareProfessionalPartyGrid.search();
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                    var _this = this;
                    var deferred = jQuery.Deferred();
                    this.$scope.isBusy = true;
                    var query = {
                        page: page,
                        pageSize: pageSize,
                        firstName: criteria.firstName,
                        lastName: criteria.lastName,
                        email: criteria.email,
                        identificationNumber: criteria.rizivNumber,
                        city: criteria.city,
                        zipCode: criteria.zipCode,
                        typeAndSpecialties: this.$scope.typeAndSpecialties
                    };
                    this.healthCareProfessionalSearchSvc.searchProfessionals(query)
                        .success(function (data) {
                        var professionals = [];
                        angular.forEach(data.Items, function (professional) {
                            professional.ZipCity = (professional.ZipCode ? professional.ZipCode + ' ' : '') + (professional.City ? professional.City : '');
                            professionals.push(professional);
                        });
                        data.Items = professionals;
                        deferred.resolve(data);
                        _this.$scope.isBusy = false;
                        _this.$scope.criteriaCollapsed = true;
                    }).error(function (message) {
                        _super.prototype.errorCallback.call(_this, message);
                        deferred.reject();
                    });
                    return deferred;
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.buildGrid = function () {
                    var _this = this;
                    var grid = this.gridBuilderSvc
                        .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                        return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria);
                    })
                        .addColumn('LastName', 'General.LastName')
                        .addColumn('FirstName', 'General.FirstName')
                        .addColumn('Email', 'General.Email')
                        .addColumn('ZipCity', 'Views.General.Search.ZipAndCity')
                        .addSelectButtonColumn(function (selected) { return _this.resultSelected(selected); })
                        .build();
                    return grid;
                };
                SearchHealthCareProfessionalPartyCtrl.prototype.resultSelected = function (result) {
                    this.$scope.$close(result);
                };
                return SearchHealthCareProfessionalPartyCtrl;
            }(RemeCare.Shared.Framework.ControllerBase));
            angular.module('RemeCareApp')
                .controller('searchHealthCareProfessionalPartyCtrl', function ($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareProfessionalSearchSvc) { return new SearchHealthCareProfessionalPartyCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareProfessionalSearchSvc); });
        })(Controllers = Core.Controllers || (Core.Controllers = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
