namespace RemeCare.SelfService {
    class InternalLoginController implements ng.IComponentController {
        public onConfirm: (args: { birthDate: Date; password: string }) => void;

        public birthDate: string;
        public password: string;
        public interalLoginForm: Shared.Framework.Directive.IFormController;
        public dateRegex: RegExp;

        public $onInit(): void {
            this.dateRegex = Framework.Regexes.dateRegex;
        }

        public confirm(): void {
            if (this.interalLoginForm.$invalid) {
                this.interalLoginForm.showValidationErrorMessage();
                return;
            }
            this.onConfirm({
                birthDate: moment(this.birthDate, 'D-M-YYYY').toDate(),
                password: this.password,
            });
        }
    }

    remeCareSelfServiceModule.component('rcInternalLogin', {
        bindings: {
            confirmLabel: '<',
            loader: '<',
            onConfirm: '&',
            userName: '<',
        },
        controller: InternalLoginController,
        templateUrl: 'views/selfService/internalLogin.html',
    });
}
