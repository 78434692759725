var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Component;
        (function (Component) {
            var Nationality = Shared.Contract.Code.Nationality;
            var NationalityIso2 = Shared.Contract.Code.NationalityIso2;
            var InputNationalNumberController = /** @class */ (function () {
                function InputNationalNumberController($timeout, nationalNumberSvc) {
                    this.$timeout = $timeout;
                    this.nationalNumberSvc = nationalNumberSvc;
                }
                InputNationalNumberController.prototype.$onInit = function () {
                    this.infoMessage = this.infoMessage || 'General.NationalNumber.InfoMessage';
                    this.label = this.label || 'General.NationalNumber';
                    this.validate = !(this.validate === false);
                };
                InputNationalNumberController.prototype.onChange = function () {
                    var _this = this;
                    this.$timeout(function () {
                        _this.changed && _this.changed();
                    });
                };
                InputNationalNumberController.prototype.validateNationalNumber = function (value) {
                    return !this.validate
                        || !value
                        || (this.nationality !== NationalityIso2.Belgian && this.nationality !== Nationality.Belgian)
                        || this.nationalNumberSvc.isValidNationalNumber(value);
                };
                return InputNationalNumberController;
            }());
            Shared.remeCareSharedModule.component('rcInputNationalNumber', {
                bindings: {
                    changed: '&?ngChange',
                    disabled: '<?ngDisabled',
                    infoMessage: '@',
                    model: '=ngModel',
                    label: '@',
                    nationality: '<?',
                    required: '<?ngRequired',
                    validate: '<?'
                },
                controller: InputNationalNumberController,
                require: {
                    formCtrl: '^form',
                },
                templateUrl: 'shared/components/inputNationalNumber/inputNationalNumber.html',
            });
        })(Component = Shared.Component || (Shared.Component = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
