namespace RemeCare.Patient {
    import ActionType = Shared.Contract.Code.ActionType;
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;
    import AuthService = Shared.Framework.AuthService;
    import Grid = Shared.Framework.Grid.Grid;
    import GridBuilderFactory = Shared.Framework.Grid.GridBuilderFactory;
    import Guid = Shared.Contract.Guid;
    import ICarePlan = Contract.Patient.Read.ICareplan;
    import IEntityTranslation = Shared.Contract.IEntityTranslation;
    import IPatientFileDocument = Contract.Patient.Read.IPatientFileDocument;
    import IPatientFileDocumentsQuery = Contract.Patient.Read.IFindPatientFileDocumentsQuery;
    import ISearchResult = Shared.Contract.ISearchResult;
    import IStructuralTherapyActionQuery = Contract.Patient.Read.IStructuralTherapyActionQuery;
    import Toaster = Shared.Framework.Toaster;

    interface ISelectablePatientFileDocument extends IPatientFileDocument {
        selected?: boolean;
    }

    class FollowUpDocumentsController implements ng.IComponentController {
        // bindings
        public patientId: Guid;

        public carePlans: ICarePlan[];
        public grid: Grid<ISelectablePatientFileDocument>;
        public newestDocument: ISelectablePatientFileDocument;
        public oldestDocument: ISelectablePatientFileDocument;
        public structuralTherapyActions: IEntityTranslation[];
        public noFollowUpDocuments: boolean;

        constructor(
            private readonly authservice: AuthService,
            private readonly gridBuilderSvc: GridBuilderFactory,
            private readonly patientSvc: PatientService,
            private readonly toaster: Toaster,
            private readonly uiGridConstants: uiGrid.IUiGridConstants,
            private readonly baseUrl: string
        ) {}

        public $onInit(): void {
            this.loadFilters();
            this.grid = this.buildGrid();
            this.grid.search().then((result) => {
                if (result.Items.length > 0) {
                    result.Items[0].selected = true;
                    this.documentSelectionChanged(result.Items[0]);
                }
            });
        }

        public closeDocument(document: ISelectablePatientFileDocument): void {
            document.selected = false;
            this.documentSelectionChanged(document);
            this.setDocumentSelected(this.grid.getData());
        }

        private buildGrid(): Grid<ISelectablePatientFileDocument> {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<ISelectablePatientFileDocument>((p, ps, sf, so, c) =>
                    this.executeSearch(p, ps, sf, so, c)
                )
                .addColumn('Name', 'General.Name')
                .addColumn('CarePlan', 'General.CarePlan')
                .addDateColumn('ReferenceDate', 'General.DocumentObservationDate', 'shortDate', {
                    sort: { direction: this.uiGridConstants.DESC },
                });
            if (this.authservice.hasRight(AuthContext.patientDocuments, AuthRight.Write)) {
                gridBuilder = gridBuilder.addNavigationColumn(
                    'pencil',
                    'patients.patientfile.mediaDocuments.documents',
                    {
                        documentId: 'Id',
                    }
                );
            }
            gridBuilder = gridBuilder.addCheckBoxColumnFunction(
                'General.Compare',
                'selected',
                (pfd) => !this.canToggleDocument(pfd),
                (pfd) => this.documentSelectionChanged(pfd)
            );
            return gridBuilder.build();
        }

        private canToggleDocument(document: ISelectablePatientFileDocument): boolean {
            return !this.oldestDocument || document.selected;
        }

        private documentSelectionChanged(document: ISelectablePatientFileDocument): ISelectablePatientFileDocument {
            if (document.selected) {
                if (this.newestDocument) {
                    if (moment(this.newestDocument.ReferenceDate).isAfter(moment(document.ReferenceDate))) {
                        this.oldestDocument = document;
                    } else {
                        this.oldestDocument = this.newestDocument;
                        this.newestDocument = document;
                    }
                } else {
                    this.newestDocument = document;
                }
            } else {
                if (this.newestDocument && this.newestDocument.Id === document.Id) {
                    this.newestDocument = this.oldestDocument;
                }
                this.oldestDocument = null;
            }
            return document;
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): Promise<ISearchResult<IPatientFileDocument>> {
            const query = {
                ...criteria,
                page,
                pageSize,
                sortField,
                sortOrder,
                referenceFromDate: criteria.referenceFromDate
                    ? Shared.DateHelper.toServerDateString(criteria.referenceFromDate)
                    : null,
                referenceUntilDate: criteria.referenceUntilDate
                    ? Shared.DateHelper.toServerDateString(criteria.referenceUntilDate)
                    : null,
            } as IPatientFileDocumentsQuery;
            try {
                const searchResult = await this.patientSvc.findFollowUpDocumentsAsync(this.patientId, query);
                for (const doc of searchResult.Items) {
                    doc.Url = Shared.DocumentHelper.fixDocumentUrl(doc.Url, this.baseUrl, doc.Name);
                }

                if (searchResult.Items.length === 0 && !this.grid.isSearchFiltered()) {
                    this.noFollowUpDocuments = true;
                    return searchResult;
                }
                this.noFollowUpDocuments = false;
                this.setDocumentSelected(searchResult.Items);
                return searchResult;
            } catch (error) {
                this.toaster.error(error);
                return Promise.reject(error);
            }
        }

        private setDocumentSelected(documents: ISelectablePatientFileDocument[]): void {
            _.each(documents, (doc: ISelectablePatientFileDocument) => {
                doc.selected =
                    (this.newestDocument && this.newestDocument.Id === doc.Id) ||
                    (this.oldestDocument && this.oldestDocument.Id === doc.Id);
            });
        }

        private async loadFilters(): Promise<void> {
            try {
                this.carePlans = await this.patientSvc.getCarePlansAsync(this.patientId);
                const structuralTherapyActionQuery = {
                    actionTypeCodes: [ActionType.RequestInformationObject],
                    hasFollowedUpDocumentPart: true,
                } as IStructuralTherapyActionQuery;
                this.structuralTherapyActions = await this.patientSvc.getStructuralTherapyActionsAsync(
                    this.patientId,
                    structuralTherapyActionQuery
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private getActionText(patientFileDocument: IPatientFileDocument): string {
            if (patientFileDocument.Action) {
                return `: ${patientFileDocument.Action}`;
            }
            return '';
        }
    }

    remeCarePatientModule.component('rcFollowUpDocuments', {
        bindings: {
            patientId: '<',
        },
        controller: FollowUpDocumentsController,
        templateUrl: 'patient/controllers/mediaDocuments/followUpDocuments/followUpDocuments.html',
    });
}
