var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanStartDateModalController = /** @class */ (function (_super) {
            __extends(CarePlanStartDateModalController, _super);
            function CarePlanStartDateModalController($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, $dialog) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.$state = $state;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.$dialog = $dialog;
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            CarePlanStartDateModalController.prototype.$onInit = function () {
                this.$scope.minDate = RemeCare.Shared.DateHelper.today();
            };
            CarePlanStartDateModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.carePlanApiSvc.changeCarePlanStartDate(this.$scope.carePlan.id, RemeCare.Shared.DateHelper.toServerDateString(this.$scope.newStartDate))
                    .success(function () {
                    _this.$scope.$close();
                    _this.$state.reload();
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            return CarePlanStartDateModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('carePlanStartDateModalCtrl', function ($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, $dialog) { return new CarePlanStartDateModalController($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, $dialog); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
