namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import ObjectiveDefinition = Shared.Framework.Model.ObjectiveDefinition;
    import ObjectiveValue = Shared.Framework.Model.ObjectiveValue;

    interface IPatientFileObjectiveOverviewScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlan: CarePlan;
        patientId: Guid;

        objective: ObjectiveDefinition;
        defaultObjective: ObjectiveDefinition;

        versionsGrid: Shared.Framework.Grid.Grid<ObjectiveValue>;

        readOnly: boolean;
        addObjectiveValue: () => void;
        confirm: () => void;
    }

    class PatientFileObjectiveOverviewController extends Shared.Framework.ControllerBase<
        IPatientFileObjectiveOverviewScope
    > {
        constructor(
            protected $scope: IPatientFileObjectiveOverviewScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super($scope, $translate, toaster);
            this.$scope.addObjectiveValue = () => this.addObjectiveValue();
            this.$scope.confirm = () => this.confirm();
        }

        public async $onInit(): Promise<void> {
            this.$scope.patientId = this.$stateParams.patientId;
            const firstValue = _(this.$scope.objective.objectiveValues)
                .chain()
                .sortBy(t => t.validFromDate)
                .first()
                .value();

            if (moment(firstValue.validFromDate).isBefore(this.$scope.carePlan.startDate)) {
                firstValue.validFromDate = this.$scope.carePlan.startDate;
            }

            this.buildVersionsGrid();
            await this.getDefaultObjective();
        }

        private confirm(): void {
            this.$scope.$close(this.$scope.objective);
        }

        private async getDefaultObjective(): Promise<void> {
            try {
                this.$scope.defaultObjective = new ObjectiveDefinition(
                    await this.therapyApiSvc.getObjectiveAsync(this.$scope.carePlan.therapyId, this.$scope.objective.id)
                );
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private buildVersionsGrid(): void {
            this.$scope.versionsGrid = this.gridBuilderSvc
                .createGridBuilder<ObjectiveValue>()
                .addColumn('amount', 'Views.PatientFile.CarePlans.ObjectiveValue')
                .addColumn('validFromDate', 'General.ValidFrom', { cellFilter: 'date: "shortDate"' })
                .addColumn('validUntilDate', 'General.ValidUntil', { cellFilter: 'date: "shortDate"' })
                .addBoolColumn('isPersonalised', 'Views.PatientFile.CarePlans.Personalised')
                .addConditionalEditButtonWithPromiseFunctionColumn(
                    v => this.editObjectiveValue(v),
                    v => {
                        return (
                            (this.$scope.objective.getPreviousObjectiveValue(v) === null ||
                                !this.$scope.objective.getPreviousObjectiveValue(v).isPersonalised) &&
                            (!v.isPersonalised || this.$scope.readOnly || moment().isAfter(moment(v.validFromDate)))
                        );
                    }
                )
                .addConditionalDeleteButtonColumn(
                    v => {
                        const personalised = _(
                            _(this.$scope.objective.objectiveValues).filter(x => x.isPersonalised)
                        ).sortBy(x => x.validFromDate);
                        return (
                            _(personalised).size() === 0 ||
                            v.validFromDate !== _(personalised).last().validFromDate ||
                            !v.isPersonalised ||
                            this.$scope.readOnly
                        );
                    },
                    v => this.deleteValue(v)
                )
                .setSorting(false)
                .build();

            this.setVersionsGridData();
        }

        private setVersionsGridData(): void {
            this.$scope.versionsGrid.setData(this.$scope.objective.objectiveValues);
        }

        private addObjectiveValue(): void {
            this.modalBuilderFactory
                .createModalBuilder<ObjectiveDefinition>()
                .setController('PatientFileAddObjectiveValueModalCtrl')
                .setTemplateUrl('views/patient/carePlans/objectivesThresholds/addObjectiveValueModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    objective: this.$scope.objective,
                    defaultValue: this.$scope.defaultObjective.getCurrentObjectiveValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: false,
                })
                .setResultCallBack(async () => {
                    await this.getObjective(this.$scope.objective.id);
                })
                .build();
        }

        private editObjectiveValue(value: ObjectiveValue): JQueryPromise<ObjectiveValue> {
            const deferred = jQuery.Deferred<ObjectiveValue>();
            this.modalBuilderFactory
                .createModalBuilder<ObjectiveValue>()
                .setController('PatientFileEditObjectiveValueModalCtrl')
                .setTemplateUrl('views/patient/carePlans/objectivesThresholds/editValueModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    objective: this.$scope.objective,
                    defaultValue: this.$scope.defaultObjective.getCurrentObjectiveValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: value,
                })
                .setResultCallBack(async () => {
                    await this.getObjective(this.$scope.objective.id);
                })
                .build();
            return deferred.promise();
        }

        private async deleteValue(value: ObjectiveValue): Promise<void> {
            try {
                await this.carePlanApiSvc.deletePersonalisedObjectiveValueAsync(
                    this.$scope.carePlan.id,
                    this.$scope.objective.id,
                    value.id
                );
                await this.getObjective(this.$scope.objective.id);
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private async getObjective(objectiveId: Guid): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getObjectiveAsync(this.$scope.carePlan.id, objectiveId);
                this.$scope.objective = new ObjectiveDefinition(ags);
                this.setVersionsGridData();
            } catch (e) {
                this.errorCallback(e);
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'PatientFileObjectiveOverviewCtrl',
            (
                $scope,
                $translate,
                toaster,
                $stateParams,
                carePlanApiSvc: Core.Services.CarePlanApiService,
                therapyApiSvc: Core.Services.TherapyApiService,
                modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
                gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
            ) =>
                new PatientFileObjectiveOverviewController(
                    $scope,
                    $translate,
                    toaster,
                    $stateParams,
                    carePlanApiSvc,
                    therapyApiSvc,
                    modalBuilderFactory,
                    gridBuilderSvc
                )
        );
}
