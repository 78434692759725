var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ContactPersonRolesController = /** @class */ (function () {
            function ContactPersonRolesController(toaster, masterdataSvc, gridBuilderSvc, modalBuilderFactory) {
                this.toaster = toaster;
                this.masterdataSvc = masterdataSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            ContactPersonRolesController.prototype.$onInit = function () {
            };
            return ContactPersonRolesController;
        }());
        Patient.remeCarePatientModule.component('rcPartyRoles', {
            templateUrl: 'views/patient/patientInfo/contactPersons/contactPersonRolesComponent.html',
            controller: ContactPersonRolesController,
            controllerAs: 'contactPersonRolesCtrl',
            bindings: {
                contactPerson: '<',
                patientId: '<'
            }
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
