namespace RemeCare.Patient {
    import Hospitalisation = RemeCare.Model.Hospitalisation;
    import Consultation = RemeCare.Model.Consultation;

    interface IPatientFileHospiConsScope extends Shared.Framework.IBaseScope {
        hospitalisations: Hospitalisation[];
        hospitalisationsGrid: Shared.Framework.Grid.Grid<Hospitalisation>;

        consultations: Consultation[];
        consultationsGrid: Shared.Framework.Grid.Grid<Consultation>;

        addHospitalisation: () => void;
        addConsultation: () => void;

        deleteRight: boolean;
        editRight: boolean;

        deleteRightHospi: boolean;
        editRightHospi: boolean;
    }

    class PatientFileHopsiConsOverviewController extends Shared.Framework.ControllerBase<IPatientFileHospiConsScope> {
        constructor(
            protected $scope: IPatientFileHospiConsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly patientSvc: Patient.PatientService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService
        ) {
            super($scope, $translate, toaster);
            this.$scope.addHospitalisation = () => this.addHospitalisation();
            this.$scope.addConsultation = () => this.addConsultation();
        }

        public $onInit(): void {
            this.$scope.hospitalisations = [];
            this.$scope.consultations = [];

            this.buildHospitalisationGrid();
            this.buildConsultationGrid();

            this.getHospitalisationsAsync();
            this.getConsultationsAsync();

            this.$scope.deleteRight = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientMedical,
                Shared.Framework.AuthRight.Delete
            );
            this.$scope.editRight = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientMedical,
                Shared.Framework.AuthRight.Write
            );

            this.$scope.deleteRightHospi = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientMedicalHospitalisation,
                Shared.Framework.AuthRight.Delete
            );
            this.$scope.editRightHospi = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientMedicalHospitalisation,
                Shared.Framework.AuthRight.Write
            );
        }

        private async getHospitalisationsAsync(): Promise<void> {
            try {
                const ags = await this.patientSvc.getPatientFileHospitalisationsAsync(this.$stateParams.patientId);
                this.$scope.hospitalisations = _(ags).map(x => new Hospitalisation(x));
                this.$scope.hospitalisationsGrid.setData(this.$scope.hospitalisations);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getConsultationsAsync(): Promise<void> {
            try {
                const ags = await this.patientSvc.getPatientFileConsultationsAsync(this.$stateParams.patientId);
                this.$scope.consultations = _(ags).map(x => new Consultation(x));
                this.$scope.consultationsGrid.setData(this.$scope.consultations);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildHospitalisationGrid(): void {
            this.$scope.hospitalisationsGrid = this.gridBuilderSvc
                .createGridBuilder<Hospitalisation>()
                .addColumn('startDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                .addColumn('endDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' })
                .addColumn('departmentType.Text', 'General.Department')
                .addColumn('getHospitalisationReason()', 'General.Reason')
                .addConditionalEditButtonWithPromiseFunctionColumn(
                    h => this.editHospitalisation(h),
                    () => !this.$scope.editRightHospi
                )
                .addConditionalDeleteButtonColumn(() => !this.$scope.deleteRightHospi, null, c =>
                    this.deleteHospitalisation(c)
                )
                .build();
        }

        private buildConsultationGrid(): void {
            this.$scope.consultationsGrid = this.gridBuilderSvc
                .createGridBuilder<Consultation>()
                .addColumn('consultationDate', 'Views.PatientFile.ConsultationDate', {
                    cellFilter: 'date: "shortDate"',
                })
                .addColumn('careProviderType.Text', 'General.CareProvider', { cellFilter: 'date: "shortDate"' })
                .addColumn('reason', 'General.Reason')
                .addConditionalEditButtonWithPromiseFunctionColumn(
                    c => this.editConsultation(c),
                    () => !this.$scope.editRight
                )
                .addConditionalDeleteButtonColumn(() => !this.$scope.deleteRight, null, c => this.deleteConsultation(c))
                .build();
        }

        private addHospitalisation(): void {
            this.modalBuilderFactory
                .createModalBuilder<Hospitalisation>()
                .setController('patientFileAddHospitalisationModalCtrl')
                .setTemplateUrl('views/patient/patientInfo/hospiCons/addHospitalisationModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    hospitalisation: new Hospitalisation(),
                })
                .setResultCallBack(() => {
                    this.getHospitalisationsAsync();
                })
                .build();
        }

        private addConsultation(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<Consultation>('rcAddConsultation')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setBindings({
                    consultation: new Consultation(),
                })
                .setResultCallBack(() => {
                    this.getConsultationsAsync();
                })
                .build();
        }

        private editHospitalisation(hospitalisation: Hospitalisation): Promise<Hospitalisation> {
            return new Promise<Hospitalisation>(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<Hospitalisation>()
                    .setController('patientFileAddHospitalisationModalCtrl')
                    .setTemplateUrl('views/patient/patientInfo/hospiCons/addHospitalisationModal.html')
                    .setSize(Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                        hospitalisation: angular.copy(hospitalisation),
                    })
                    .setResultCallBack(r => {
                        this.getHospitalisationsAsync();
                        resolve(r);
                    })
                    .build();
            });
        }

        private editConsultation(consultation: Consultation): Promise<Consultation> {
            return this.modalBuilderFactory
                .createComponentModalBuilder<Consultation>('rcAddConsultation')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setBindings({
                    consultation: angular.copy(consultation),
                })
                .setResultCallBack(() => {
                    this.getConsultationsAsync();
                })
                .build().result;
        }

        private deleteHospitalisation(hospitalisation: Hospitalisation): Promise<boolean> {
            return new Promise<boolean>(resolve => {
                this.$dialog.confirmBox(
                    'Views.PatientFile.PatientInfo.RemoveHospitalisation',
                    'Views.PatientFile.PatientInfo.RemoveHospitalisationText',
                    async () => {
                        try {
                            await this.patientSvc.deletePatientFileHospitalisationAsync(
                                this.$stateParams.patientId,
                                hospitalisation.id
                            );
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private deleteConsultation(consultation: Consultation): Promise<boolean> {
            return new Promise<boolean>(resolve => {
                this.$dialog.confirmBox(
                    'Views.PatientFile.PatientInfo.RemoveConsultation',
                    'Views.PatientFile.PatientInfo.RemoveConsultationText',
                    async () => {
                        try {
                            await this.patientSvc.deletePatientFileConsultationAsync(
                                this.$stateParams.patientId,
                                consultation.id
                            );
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'patientFileHospiConsOverviewCtrl',
            (
                $scope,
                $translate,
                toaster,
                $stateParams,
                $dialog,
                patientSvc: Patient.PatientService,
                gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
                modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
                authservice: Shared.Framework.AuthService
            ) =>
                new PatientFileHopsiConsOverviewController(
                    $scope,
                    $translate,
                    toaster,
                    $stateParams,
                    $dialog,
                    patientSvc,
                    gridBuilderSvc,
                    modalBuilderFactory,
                    authservice
                )
        );
}
