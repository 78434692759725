var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SearchGlobalActorRolesController = /** @class */ (function (_super) {
            __extends(SearchGlobalActorRolesController, _super);
            function SearchGlobalActorRolesController($scope, $translate, toaster, $state, $dialog, globalActorRoleSvc, gridBuilderSvc, authservice, modalBuilderFactory) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$state = $state;
                _this.$dialog = $dialog;
                _this.globalActorRoleSvc = globalActorRoleSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.authservice = authservice;
                _this.modalBuilderFactory = modalBuilderFactory;
                $scope.search = function () { return _this.search(); };
                $scope.addGlobalActorRole = function () { return _this.addGlobalActorRole(); };
                return _this;
            }
            SearchGlobalActorRolesController.prototype.$onInit = function () {
                this.canEdit = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.managementActorRoles, RemeCare.Shared.Framework.AuthRight.Write);
                this.buildGrid();
                this.$scope.searchCollapsed = true;
            };
            SearchGlobalActorRolesController.prototype.addGlobalActorRole = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('globalActorRoleTranslationsCtrl')
                    .setTemplateUrl('views/management/globalActorRoles/globalActorRoleTranslations.html')
                    .setScope({
                    globalActorRole: new Management.GlobalActorRole(),
                    editMode: true,
                })
                    .setResultCallBack(function (gar) { return __awaiter(_this, void 0, void 0, function () {
                    var r, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.globalActorRoleSvc.createGlobalActorRoleAsync(gar.toServerWrite())];
                            case 1:
                                r = _a.sent();
                                this.openDetails(r);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })
                    .build();
            };
            SearchGlobalActorRolesController.prototype.search = function () {
                this.$scope.grid.pagingOptions.currentPage = 1;
                this.$scope.grid.search();
            };
            SearchGlobalActorRolesController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var name, query, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                name = criteria.name;
                                query = {
                                    name: name ? name : '',
                                    partyRoleIds: null,
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.globalActorRoleSvc.searchSummariesAsync(query)];
                            case 2: return [2 /*return*/, _a.sent()];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                throw e_2;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            SearchGlobalActorRolesController.prototype.openDetails = function (id) {
                this.$state.go('management.globalActorRoles.details', { actorRoleId: id });
            };
            SearchGlobalActorRolesController.prototype.deleteActorAsync = function (entity) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, new Promise(function (resolve) {
                                var text = _this.$translate.instant('Views.Management.GlobalActorRoles.RemoveGlobalActorRoleText', {
                                    actorRole: entity.Text,
                                });
                                _this.$dialog.confirmBox('Views.Management.GlobalActorRoles.RemoveGlobalActorRole', text, function () { return __awaiter(_this, void 0, void 0, function () {
                                    var e_3;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                _a.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, this.globalActorRoleSvc.deleteGlobalActorRoleAsync(entity.Id)];
                                            case 1:
                                                _a.sent();
                                                resolve(true);
                                                return [3 /*break*/, 3];
                                            case 2:
                                                e_3 = _a.sent();
                                                this.toaster.error(e_3);
                                                resolve(false);
                                                return [3 /*break*/, 3];
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); }, function () {
                                    resolve(false);
                                });
                            })];
                    });
                });
            };
            SearchGlobalActorRolesController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria);
                })
                    .setBindToUrl()
                    .setSearchWhenReady()
                    .addColumn('Text', 'General.Name', { width: '*' })
                    .addConditionalDeleteButtonColumn(function (x) { return !x.CanBeDeleted; }, null, function (ar) { return _this.deleteActorAsync(ar); })
                    .addConditionalShowEditNoButtonNavigationColumn(function () { return !_this.canEdit; }, function () { return false; }, 'management.globalActorRoles.details', { actorRoleId: 'Id' });
                this.$scope.grid = gridBuilder.build();
            };
            return SearchGlobalActorRolesController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('searchGlobalActorRolesCtrl', SearchGlobalActorRolesController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
