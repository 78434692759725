var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Patient = /** @class */ (function () {
            function Patient(serverObject) {
                this.id = serverObject.Id;
                this.lastName = serverObject.LastName;
                this.firstName = serverObject.FirstName;
                this.dateOfBirth =
                    serverObject.DateOfBirth != null ? moment(serverObject.DateOfBirth, 'YYYY-MM-DD').toDate() : null;
                this.nationalNumber = serverObject.NationalNumber;
                this.address = serverObject.Address;
                this.city = (serverObject.ZipCode || '') + ' ' + (serverObject.City || '');
                this.phone = new RemeCare.Model.PhoneNumber(serverObject.Phone).toString();
                this.cell = new RemeCare.Model.PhoneNumber(serverObject.Mobile).toString();
                this.email = serverObject.Email;
                this.internalRemark = serverObject.InternalRemark;
            }
            return Patient;
        }());
        Model.Patient = Patient;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
