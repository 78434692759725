var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var TelemonitoringApiSvc = /** @class */ (function (_super) {
                __extends(TelemonitoringApiSvc, _super);
                function TelemonitoringApiSvc() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                TelemonitoringApiSvc.prototype.findMeasurements = function (query) {
                    return this.get('Telemonitoring/measurements', query);
                };
                TelemonitoringApiSvc.$inject = ['baseUrl', '$http', 'spinnerSvc'];
                return TelemonitoringApiSvc;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.TelemonitoringApiSvc = TelemonitoringApiSvc;
            RemeCare.remeCareAppModule.service('telemonitoringApiSvc', TelemonitoringApiSvc);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
