var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestUIControlType = RemeCare.Shared.Contract.Code.CareRequestUIControlType;
        var ModalityType = RemeCare.Shared.Contract.Code.ModalityType;
        var ContactPersonApplicationController = /** @class */ (function (_super) {
            __extends(ContactPersonApplicationController, _super);
            function ContactPersonApplicationController($scope, $translate, toaster, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.masterdataSvc = masterdataSvc;
                $scope.confirm = function () { return _this.confirm(); };
                $scope.isPresent = function (t) { return _this.isPresent(t); };
                $scope.isRequired = function (t) { return _this.isRequired(t); };
                $scope.atLeastOneNumber = function () { return _this.atLeastOneNumber(); };
                return _this;
            }
            ContactPersonApplicationController.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getCountries()
                    .success(function (r) { return _this.$scope.countries = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getGenders()
                    .success(function (r) { return _this.$scope.genders = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getContactPersonTypes()
                    .success(function (r) { return _this.$scope.contactPersonTypes = r; })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            ContactPersonApplicationController.prototype.confirm = function () {
                if (this.$scope.contactPersonForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.contactPerson);
            };
            ContactPersonApplicationController.prototype.atLeastOneNumber = function () {
                if (!this.$scope.isRequired(CareRequestUIControlType.ContactPersonTelephoneMobile))
                    return true;
                return (this.$scope.contactPerson.person.contactInfo.telephone.isFilled() ||
                    this.$scope.contactPerson.person.contactInfo.mobile.isFilled());
            };
            ContactPersonApplicationController.prototype.isPresent = function (controlType) {
                var control = _(this.$scope.careRequestPart.uiControls).find(function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id !== ModalityType.Not;
            };
            ContactPersonApplicationController.prototype.isRequired = function (controlType) {
                var control = _(this.$scope.careRequestPart.uiControls).find(function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id === ModalityType.Mandatory;
            };
            return ContactPersonApplicationController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule.controller('contactPersonApplicationCtrl', function ($scope, $translate, toaster, masterdataSvc) { return new ContactPersonApplicationController($scope, $translate, toaster, masterdataSvc); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
