module RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import SufferedDisease = Patient.Model.PatientFileSufferedDisease;

    interface IPatientFileAddSufferedDiseaseModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        sufferedDisease: SufferedDisease;

        diseases: Array<Contract.Patient.Read.IDisease>;

        selectedDisease: Contract.Patient.Read.IDisease;
        selectedTherapies: Array<Guid>;

        form: ng.IFormController;

        diseaseChanged(): void;
        confirm(): void;
    }

    class PatientFileAddSufferedDiseaseModalController extends Shared.Framework.ControllerBase<IPatientFileAddSufferedDiseaseModalScope> {

        constructor(
            $scope: IPatientFileAddSufferedDiseaseModalScope,
            $translate,
            toaster,
            private $dialog,
            private $stateParams,
            private patientSvc: Patient.PatientService
        ) {
            super($scope, $translate, toaster);
            this.$scope.diseaseChanged = () => this.diseaseChanged();
            this.$scope.confirm = () => this.confirm();
        }


        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            this.$scope.selectedTherapies = [];

            _(this.$scope.sufferedDisease.sufferedDiseaseTherapies).chain()
                .map(x => x.Id)
                .forEach(x => this.$scope.selectedTherapies.push(x));
            
            if (this.$scope.sufferedDisease.disease != null) {
                this.$scope.selectedDisease = _.find(this.$scope.diseases, d => d.Id === this.$scope.sufferedDisease.disease.Id);
            }
        }

        private diseaseChanged(): void {
            this.$scope.selectedTherapies = [];
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.sufferedDisease.disease = this.$scope.selectedDisease;
            this.$scope.sufferedDisease.sufferedDiseaseTherapies = [];

            _(this.$scope.selectedTherapies).forEach(x => this.$scope.sufferedDisease
                .addTherapyLink(_.find(this.$scope.selectedDisease.Therapies, t => t.Id === x)));

            this.patientSvc.savePatientFileSufferedDisease(this.$scope.patientId, this.$scope.sufferedDisease.toServerWrite())
                .success(() => this.$scope.$close(this.$scope.sufferedDisease))
                .error((m) => this.errorCallback(m));
        }
    }

    angular.module('RemeCare.Patient').controller('patientFileAddSufferedDiseaseModalCtrl', (
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        patientSvc: Patient.PatientService
    ) => new PatientFileAddSufferedDiseaseModalController(
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        patientSvc
    ));
}