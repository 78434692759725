var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Characteristic = /** @class */ (function () {
                function Characteristic() {
                }
                Characteristic.heartBeatId = 'cf091fea-4835-4b90-92a1-f99f99ff0af7';
                Characteristic.weightId = '2c47bcd7-a9c0-40fd-90f0-a0d3e03fd136';
                Characteristic.systolicPressureId = 'f493c88d-df5e-4007-a7bc-f6b9c7a6f4a5';
                Characteristic.diastolicPressureId = '81954bd9-d5e1-47c5-ae9a-94d3b2379916';
                Characteristic.potassiumId = '04a8e4dc-99e1-4cbf-9240-aac4a2f0ae5c';
                Characteristic.creatineId = 'ca184789-f944-45b5-9c8c-69c3ee54ba69';
                Characteristic.sodiumId = 'edad326f-3ae8-45e1-961a-074331ba55b1';
                return Characteristic;
            }());
            Core.Characteristic = Characteristic;
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
