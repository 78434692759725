module RemeCare.Management {

    export class ExternalIdentityProvider {

        public id: Shared.Contract.Guid;
        public name: string;
        public remeCareBaseUrl: string;
        public icon: string;

        public linked: boolean;
        public loading: boolean;

        constructor(serverObject?: Contract.Security.IExternalIdentityProvider) {
            if (serverObject) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.remeCareBaseUrl = serverObject.RemeCareBaseUrl;
                this.icon = serverObject.Icon;
            }
        }

        public toggleLinked(): void {
            this.linked = !this.linked;
        }
    }
}