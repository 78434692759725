module RemeCare.CareRequest {

    interface ICancelCareRequestScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        confirm(): void;

        title: string;
        careRequest: CareRequest;
    }

    class CancelCareRequestController extends Shared.Framework.ControllerBase<ICancelCareRequestScope> {

        constructor(
            $scope: ICancelCareRequestScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {}

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.$close(this.$scope.careRequest);
        }
    }

    remeCareCareRequestModule.controller('cancelCareRequestModalCtrl', (
        $scope,
        $translate,
        toaster
    ) => new CancelCareRequestController(
            $scope,
            $translate,
            toaster
    ));
}