namespace RemeCare.CareRequest {
    export class CareRequestSearchService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public searchCareRequestsAsync(
            query: Contract.CareRequest.Read.ICareRequestQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.CareRequest.Read.ICareRequestSummary>> {
            const url = 'CareRequests';
            return this.getPromise(url, query, 'carerequests');
        }

        /** @deprecated */
        public getPossibleTherapies(): ng.IHttpPromise<Shared.Contract.IEntityTranslation[]> {
            return this.get('CareRequests/PossibleTherapies', null, 'careRequests.possibleTherapies');
        }

        public getPossibleTherapiesAsync(): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise('CareRequests/PossibleTherapies', null, 'careRequests.possibleTherapies');
        }
    }

    remeCareCareRequestModule.service('careRequestSearchSvc', CareRequestSearchService);
}
