module RemeCare.CareRequest.Directive {
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;
    import ModalityType = Shared.Contract.Code.ModalityType;
    import TypeAndSpecialty = RemeCare.Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;

    export class CareRequestPartComponentControllerBase {
        public careRequestPart: Shared.Framework.Model.CareRequestRoleDefinition;

        protected convertToTypeAndSpecialties(
            inclusions: _Chain<Shared.Framework.Model.ActorRoleInclusionDetail>
        ): Array<TypeAndSpecialty> {
            return inclusions
                .filter(i => i.healthCareProfessionalTypeId != null)
                .map(
                    i =>
                        <TypeAndSpecialty>{
                            healthCareProfessionalTypeId: i.healthCareProfessionalTypeId,
                            healthCareSpecialtyProfessionId: i.healthCareSpecialtyProfessionId
                        }
                )
                .value();
        }

        protected isPresent(
            controlType: CareRequestUIControlType,
            part?: Shared.Framework.Model.CareRequestPart
        ): boolean {
            if (!part) {
                part = this.careRequestPart;
            }
            const control = _.find(part.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id !== ModalityType.Not;
            }
        }

        protected isRequired(
            controlType: CareRequestUIControlType,
            part?: Shared.Framework.Model.CareRequestPart
        ): boolean {
            part = part || this.careRequestPart;
            if (part == null) {
                return false;
            }
            const control = _.find(part.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id === ModalityType.Mandatory;
            }
        }
    }
}
