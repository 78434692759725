namespace RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import CarePlanStatus = Shared.Contract.Code.CarePlanStatus;

    export class Careplan {
        public static getSortedOpenCloseCareplans(careplans: Contract.Patient.Read.ICareplan[]): Careplan[] {
            const open = _(careplans)
                .chain()
                .filter((x) => x.Status.Id === CarePlanStatus.Open)
                .map((x) => new Careplan(x))
                .sortBy((x) => x.startDate)
                .value();
            const closed = _(careplans)
                .chain()
                .filter((x) => x.Status.Id !== CarePlanStatus.Open)
                .map((x) => new Careplan(x))
                .sortBy((x) => x.statusChangedDate)
                .value();
            return open.concat(closed);
        }
        public id: Guid;
        public patientId: Guid;
        public therapyId: Guid;
        public status: string;
        public statusId: number;
        public name: string;
        public startDate: Date;
        public statusOpen: boolean;
        public statusChangedDate: Date;
        public statusChangedReason: string;
        public externalReferences: RemeCare.Contract.Core.IExternalReference[];

        constructor(serverObject?: Contract.Patient.Read.ICareplan) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.patientId = serverObject.PatientId;
                this.therapyId = serverObject.TherapyId;
                this.status = serverObject.Status.Text;
                this.statusId = serverObject.Status.Id;
                this.name = serverObject.Name;
                this.startDate = Shared.DateHelper.serverDateStringToDate(serverObject.StartDate);
                this.statusOpen = serverObject.Status.Id === CarePlanStatus.Open;
                this.statusChangedDate = Shared.DateHelper.serverDateStringToDate(serverObject.StatusChangedDate);
                this.statusChangedReason = serverObject.StatusChangedReason;
                this.externalReferences = serverObject.ExternalReferences;
            }
        }

        public getFormattedStartDate(): string {
            return moment(this.startDate).format('DD/MM/YYYY');
        }

        public getFormattedStatusChangedDate(): string {
            if (this.statusChangedDate) {
                return moment(this.statusChangedDate).format('DD/MM/YYYY');
            }

            return null;
        }

        public getSummary(
            translate: ng.translate.ITranslateService,
            filter: ng.IFilterService
        ): Shared.Contract.IEntityTranslation {
            return {
                Id: this.id,
                Text: this.statusOpen
                    ? this.name +
                      translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                          CarePlanStatus: this.status,
                          StatusDate: filter('date')(this.startDate, 'shortDate'),
                      })
                    : this.name +
                      translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                          CarePlanStatus: this.status,
                          StatusDate: filter('date')(this.statusChangedDate, 'shortDate'),
                      }),
            };
        }
    }
}
