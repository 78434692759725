var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var DateRangeFilterCode;
                (function (DateRangeFilterCode) {
                    DateRangeFilterCode[DateRangeFilterCode["LastWeek"] = 1] = "LastWeek";
                    DateRangeFilterCode[DateRangeFilterCode["LastTwoWeeks"] = 2] = "LastTwoWeeks";
                    DateRangeFilterCode[DateRangeFilterCode["LastMonth"] = 3] = "LastMonth";
                    DateRangeFilterCode[DateRangeFilterCode["LastThreeMonths"] = 4] = "LastThreeMonths";
                    DateRangeFilterCode[DateRangeFilterCode["LastYear"] = 5] = "LastYear";
                })(DateRangeFilterCode = Codes.DateRangeFilterCode || (Codes.DateRangeFilterCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
