/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {
    import Hospitalisation = Model.Hospitalisation;

    interface IHospitalisationsScope extends ICareRequestPartBaseScope {
        grid: Shared.Framework.Grid.Grid<Hospitalisation>;
        addHospitalisation(): void;
    }

    class HospitalisationsController extends CareRequestPartControllerBase<IHospitalisationsScope> {

        constructor(
            $scope: IHospitalisationsScope,
            $translate,
            toaster,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.addHospitalisation = () => this.addHospitalisation();
            this.$onInit();
        }

        public $onInit(): void {
            this.buildGrid();
        }

        private buildGrid(): void {
            var gridBuilder = this.gridBuilderSvc
                .createGridBuilder<Model.Hospitalisation>()
                .addColumn('startDate', 'Views.CareRequest.Details.StartDateHospitalisation', { cellFilter: 'date:"shortDate"' })
                .addColumn('getDuration()', 'Views.CareRequest.Details.DurationHospitalisation')
                .addColumn('departmentType.Text', 'Views.CareRequest.Details.CareType')
                .addColumn('hospitalisationReason.Text', 'Views.CareRequest.Details.Reason');
            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn(c => this.editHospitalisation(c))
                    .addDeleteButtonColumn();
            }
            this.$scope.grid = gridBuilder.build();
            this.$scope.grid.setData(this.$scope.careRequest.hospitalisations);
        }

        private addHospitalisation(): void {
            var hospitalisation = new Hospitalisation();
            this.editHospitalisation(hospitalisation)
                .done(r => this.$scope.careRequest.hospitalisations.push(r));
        }

        private editHospitalisation(hospitalisation: Hospitalisation): JQueryPromise<Hospitalisation> {
            var deferred = jQuery.Deferred<Hospitalisation>();
            this.modalBuilderFactory.createModalBuilder<Hospitalisation>()
                .setTemplateUrl('views/careRequest/directive/hospitalisationModal.html')
                .setController('hospitalisationCtrl')
                .setScope({
                    hospitalisation: angular.copy(hospitalisation),
                    careRequestPart: this.$scope.careRequestPart,
                    therapyId: this.$scope.careRequest.therapyId
        })
                .setResultCallBack(r => deferred.resolve(r))
                .setDismissCallBack(() => deferred.reject())
                .build();
            return deferred.promise();
        }
    }

    class HospitalisationsDirective extends CareRequestPartDirectiveBase {

        controller = ['$scope', '$translate', 'toaster', 'gridBuilderSvc', 'modalBuilderFactory', 'careRequestSvc',
            ($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) =>
            new HospitalisationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc)];

        templateUrl = 'views/careRequest/directive/hospitalisations.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestHospitalisations', () => new HospitalisationsDirective());
}