var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var TherapyAction = /** @class */ (function () {
                function TherapyAction() {
                }
                TherapyAction.cardioCoachTitrationQuestionsId = '8f824a23-835f-4e2d-8045-9edf0e49df8e';
                TherapyAction.cardioCoachDivergentParametersQuestionsId = 'b1d5613f-1546-4bee-ba83-3b2412605e03';
                return TherapyAction;
            }());
            Core.TherapyAction = TherapyAction;
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
