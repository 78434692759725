var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Controllers;
        (function (Controllers) {
            var SearchHealthCareOrganisationPartyCtrl = /** @class */ (function (_super) {
                __extends(SearchHealthCareOrganisationPartyCtrl, _super);
                function SearchHealthCareOrganisationPartyCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareOrganisationSearchSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.uiGridConstants = uiGridConstants;
                    _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                    _this.$scope.clearSearchParams = function () { return _this.clearSearchParams(); };
                    _this.$scope.toggleCriteria = function () { return _this.toggleCriteria(); };
                    _this.$scope.search = function () { return _this.search(); };
                    return _this;
                }
                SearchHealthCareOrganisationPartyCtrl.prototype.$onInit = function () {
                    this.$scope.searchHealthCareOrganisationPartyGrid = this.buildGrid();
                    if (this.$scope.prefill) {
                        this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.name = this.$scope.prefill.Name;
                        this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.zipCode = this.$scope.prefill.ZipCode;
                        this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.city = this.$scope.prefill.City;
                        this.search();
                    }
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.clearSearchParams = function () {
                    this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria = {};
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.toggleCriteria = function () {
                    this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.search = function () {
                    this.$scope.searchHealthCareOrganisationPartyGrid.pagingOptions.currentPage = 1;
                    this.$scope.searchHealthCareOrganisationPartyGrid.search();
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                    var _this = this;
                    var deferred = jQuery.Deferred();
                    this.$scope.isBusy = true;
                    var query = {
                        page: page,
                        pageSize: pageSize,
                        name: criteria.name,
                        city: criteria.city,
                        zipCode: criteria.zipCode,
                        organisationTypes: this.$scope.organisationTypes
                    };
                    this.healthCareOrganisationSearchSvc.searchOrganisations(query)
                        .success(function (data) {
                        var organisations = [];
                        angular.forEach(data.Items, function (organisation) {
                            organisation.ZipCity = (organisation.ZipCode ? organisation.ZipCode + ' ' : '') + (organisation.City ? organisation.City : '');
                            organisations.push(organisation);
                        });
                        data.Items = organisations;
                        deferred.resolve(data);
                        _this.$scope.isBusy = false;
                        _this.$scope.criteriaCollapsed = true;
                    }).error(function (message) {
                        _super.prototype.errorCallback.call(_this, message);
                        deferred.reject();
                    });
                    return deferred;
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.buildGrid = function () {
                    var _this = this;
                    var grid = this.gridBuilderSvc
                        .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                        return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria);
                    })
                        .addColumn('Name', 'General.Name')
                        .addColumn('ZipCity', 'Views.General.Search.ZipAndCity')
                        .addSelectButtonColumn(function (selected) { return _this.resultSelected(selected); })
                        .build();
                    return grid;
                };
                SearchHealthCareOrganisationPartyCtrl.prototype.resultSelected = function (result) {
                    this.$scope.$close(result);
                };
                return SearchHealthCareOrganisationPartyCtrl;
            }(RemeCare.Shared.Framework.ControllerBase));
            angular.module('RemeCare.CareRequest')
                .controller('searchHealthCareOrganisationPartyCtrl', function ($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareOrganisationSearchSvc) { return new SearchHealthCareOrganisationPartyCtrl($scope, $translate, toaster, gridBuilderSvc, uiGridConstants, healthCareOrganisationSearchSvc); });
        })(Controllers = Core.Controllers || (Core.Controllers = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
