var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var PrescribedAdministrationInformation = /** @class */ (function () {
                function PrescribedAdministrationInformation(serverObject) {
                    if (serverObject != null) {
                        this.prescribedAdministrationMeans = serverObject.PrescribedAdministrationMeans;
                        this.administrationMethod = serverObject.AdministrationMethod;
                        this.speedQuantity = serverObject.SpeedQuantity;
                        this.speedUnit = serverObject.SpeedUnit;
                        if (serverObject.Duration != null) {
                            this.duration = {
                                Quantity: serverObject.Duration.Quantity,
                                Unit: serverObject.Duration.Unit
                            };
                        }
                    }
                }
                PrescribedAdministrationInformation.prototype.clear = function () {
                    this.duration = null;
                    this.administrationMethod = null;
                    this.speedQuantity = null;
                    this.speedUnit = null;
                };
                PrescribedAdministrationInformation.prototype.toServerWrite = function () {
                    if (this.duration != null && this.duration.Unit != null) {
                        return {
                            PrescribedAdministrationMeans: this.prescribedAdministrationMeans,
                            AdministrationMethod: this.administrationMethod,
                            Duration: {
                                Quantity: this.duration.Quantity,
                                Unit: this.duration.Unit,
                            },
                            SpeedQuantity: this.speedQuantity,
                            SpeedUnit: this.speedUnit
                        };
                    }
                    else {
                        return {
                            PrescribedAdministrationMeans: this.prescribedAdministrationMeans,
                            AdministrationMethod: this.administrationMethod,
                            Duration: null,
                            SpeedQuantity: this.speedQuantity,
                            SpeedUnit: this.speedUnit
                        };
                    }
                };
                return PrescribedAdministrationInformation;
            }());
            Model.PrescribedAdministrationInformation = PrescribedAdministrationInformation;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
