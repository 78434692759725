var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var AssignApplicationProfileController = /** @class */ (function (_super) {
            __extends(AssignApplicationProfileController, _super);
            function AssignApplicationProfileController($scope, $translate, toaster, $q, partyId, assignedProfiles, toAssign, securityApiSvc, partyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$q = $q;
                _this.partyId = partyId;
                _this.assignedProfiles = assignedProfiles;
                _this.toAssign = toAssign;
                _this.securityApiSvc = securityApiSvc;
                _this.partyApiSvc = partyApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            AssignApplicationProfileController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.fixedProfile = this.toAssign != null;
                if (this.$scope.fixedProfile) {
                    this.$scope.selectedProfile = this.toAssign;
                }
                else {
                    this.$q.all([
                        this.securityApiSvc.findAssignableApplicationProfiles(),
                        this.partyApiSvc.findPartyRoleTypes(this.partyId)
                    ])
                        .then(function (r) {
                        var profiles = r[0].data;
                        profiles = _(profiles).filter(function (ap) { return !_(_this.assignedProfiles)
                            .any(function (aap) { return aap.ApplicationProfile.Id === ap.Id; }); });
                        var partyRoleTypes = r[1].data;
                        profiles = profiles.filter(function (ap) {
                            if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser ||
                                ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Configurator) {
                                return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee; });
                            }
                            else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager ||
                                ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager ||
                                ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                                ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.TherapyProvider) {
                                return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee ||
                                    prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; });
                            }
                            else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Patient) {
                                return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Patient; });
                            }
                            else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.InformalCareProvider) {
                                return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional; });
                            }
                            return false;
                        });
                        _this.$scope.applicationProfiles = profiles;
                    })
                        .catch(function (e) { return _this.errorCallback(e); });
                }
            };
            AssignApplicationProfileController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.securityApiSvc.assignProfile(this.partyId, this.$scope.selectedProfile.Id, this.$scope.reason)
                    .success(function () { return _this.$scope.$close(); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return AssignApplicationProfileController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('assignApplicationProfileCtrl', function ($scope, $translate, toaster, $q, partyId, assignedProfiles, toAssign, securityApiSvc, partyApiSvc) { return new AssignApplicationProfileController($scope, $translate, toaster, $q, partyId, assignedProfiles, toAssign, securityApiSvc, partyApiSvc); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
