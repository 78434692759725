module RemeCare.Patient {

    import Guid = Shared.Contract.Guid;
    import Mutuality = RemeCare.Model.HealthInsuranceFundAssignment;

    interface IEditMutualityScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        mutualities: Array<Shared.Contract.Read.IHealthInsuranceFund>;
        mutuality: Mutuality;
        form: ng.IFormController;
        onlyValidFromDate: boolean;
        confirm(): void;
    }

    class EditMutualityScope extends Shared.Framework.ControllerBase<IEditMutualityScope> {
        
        constructor(
            $scope: IEditMutualityScope,
            $translate,
            toaster,
            private patientId: Guid,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private patientSvc: PatientService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.masterdataSvc.getHealthInsuranceFunds()
                .success(r => this.$scope.mutualities = r)
                .error(e => this.errorCallback(e));
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                this.$scope.showErrors = true;
                return;
            }
            if (this.$scope.onlyValidFromDate) {
                this.patientSvc.changeMutalityValidity(this.patientId, this.$scope.mutuality.id, this.$scope.mutuality.validFromDate, this.$scope.mutuality.validUntilDate)
                    .success(() => this.$scope.$close(this.$scope.mutuality))
                    .error(e => this.errorCallback(e));
            } else {
                this.patientSvc.createMutuality(this.patientId, this.$scope.mutuality.toServerWrite())
                    .success(() => this.$scope.$close(this.$scope.mutuality))
                    .error(e => this.errorCallback(e));
            }
        }
    }

    remeCarePatientModule.controller('editMutualityCtrl', (
        $scope,
        $translate,
        toaster,
        patientId: Guid,
        masterdataSvc: Shared.Framework.MasterdataService,
        patientSvc: PatientService
    ) => new EditMutualityScope(
        $scope,
        $translate,
        toaster,
        patientId,
        masterdataSvc,
        patientSvc
    ));
}