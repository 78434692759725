namespace RemeCare.CarePlanAction {
    interface IPhoneCallScope extends IComponentScope {
        component: PhoneCallDescriptor;
        showTime: boolean;
        showUntil: boolean;
        getNumberOfAttempts(): void;
    }

    class PhoneCallController extends ComponentControllerBase<IPhoneCallScope> {
        // @ngInject
        constructor(
            $scope: IPhoneCallScope,
            private $translate: ng.translate.ITranslateService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            $scope.getNumberOfAttempts = () => this.getNumberOfAttempts();
        }

        protected init(): void {}

        private getNumberOfAttempts(): string {
            if (this.$scope.component.maxNumberOfAttempts) {
                let amountLeft =
                    this.$scope.component.maxNumberOfAttempts - this.$scope.component.previousContacts.length;
                amountLeft = amountLeft > 0 ? amountLeft : 0;
                return this.$translate.instant('Views.Action.PhoneCall.NumberOfAttempts', {
                    current: amountLeft,
                    max: this.$scope.component.maxNumberOfAttempts,
                });
            }
            return '';
        }
    }

    class PhoneCallDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/phoneCall.html';

        public controller = PhoneCallController;
    }

    remeCareCarePlanActionModule.directive('rcPhoneCall', () => new PhoneCallDirective());
}
