var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        IntegrationMonitor.FAILED_STATUS = 'FAILED';
        var IntegrationMessagesSearchController = /** @class */ (function () {
            function IntegrationMessagesSearchController(integrationMessageSearchSvc, gridBuilderSvc, $translate, toaster, storageSvc) {
                this.integrationMessageSearchSvc = integrationMessageSearchSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.$translate = $translate;
                this.toaster = toaster;
                this.storageSvc = storageSvc;
            }
            IntegrationMessagesSearchController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.loadDataAsync()];
                            case 1:
                                _a.sent();
                                this.buildGrid();
                                this.isBuildSearch = true;
                                this.IntegrationMessagesGrid.search();
                                this.isBuildSearch = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessagesSearchController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria, count, nextPage) {
                    criteria.Page = (_this.isBuildSearch && criteria.Page) || page; // case that page larger than number of pages is handled in backend (latest page is then shown)
                    criteria.PageSize = pageSize;
                    return _this.executeSearchAsync(criteria);
                })
                    .addColumn('PatientNationalNumber', 'General.NationalNumber', { enableSorting: false, width: 175 })
                    .addColumn('Sender', 'Views.IntegrationMonitor.Sender', { enableSorting: false })
                    .addDateColumn('ReceivedDateTime', 'Views.IntegrationMonitor.ReceivedOn', 'short', {
                    enableSorting: false,
                    width: 200,
                })
                    .addDateColumn('PlannedDateTimeFrom', 'Views.IntegrationMonitor.PlannedDateAndTime', 'short', {
                    enableSorting: false,
                    width: 200,
                })
                    .addColumn('ErrorCode', 'Views.IntegrationMonitor.IntegrationMessage.ErrorCode', {
                    enableSorting: false,
                })
                    .addColumn('Status', 'General.Status', { enableSorting: false, width: 150 })
                    .addNavigationColumn('folder-open', 'integrationMonitor.message', { messageId: 'Id' });
                var knownInformation = this.storageSvc.get(StorageServiceConstants.integrationMonitorKey);
                this.IntegrationMessagesGrid = gridBuilder
                    .setSearchCriteria(function (c) {
                    c.Page = c.Page || (knownInformation && knownInformation.Page);
                    c.PageSize = c.PageSize || (knownInformation && knownInformation.PageSize);
                    c.NationalNumber = c.NationalNumber || (knownInformation && knownInformation.NationalNumber);
                    c.Sender = c.Sender || (knownInformation && knownInformation.Sender);
                    c.ErrorCode = c.ErrorCode || (knownInformation && knownInformation.ErrorCode);
                    c.Status = c.Status || (knownInformation && knownInformation.Status);
                    c.ReceivedDateTimeFrom =
                        c.ReceivedDateTimeFrom || (knownInformation && knownInformation.ReceivedDateTimeFrom);
                    c.ReceivedDateTimeUntil =
                        c.ReceivedDateTimeUntil || (knownInformation && knownInformation.ReceivedDateTimeUntil);
                    c.PlannedDateTimeFrom =
                        c.PlannedDateTimeFrom || (knownInformation && knownInformation.PlannedDateTimeFrom);
                    c.PlannedDateTimeUntil =
                        c.PlannedDateTimeUntil || (knownInformation && knownInformation.PlannedDateTimeUntil);
                    _this.checkFilterCriteria(c);
                })
                    .setBindToUrl(this.bindToUrl)
                    .build();
                this.IntegrationMessagesGrid.pagingOptions.currentPage =
                    (knownInformation && knownInformation.Page) || this.IntegrationMessagesGrid.pagingOptions.currentPage;
                this.IntegrationMessagesGrid.pagingOptions.pageSize = 20;
            };
            IntegrationMessagesSearchController.prototype.checkFilterCriteria = function (criteria) {
                // Check if Status, Sender and ErrorCode are still valid
                if (criteria.Status && this.possibleStatuses.map(function (x) { return x.Name; }).indexOf(criteria.Status) === -1) {
                    criteria.Status = null;
                }
                if (criteria.Sender && this.possibleSenders.map(function (x) { return x.Name; }).indexOf(criteria.Sender) === -1) {
                    criteria.Sender = null;
                }
                if (criteria.ErrorCode && this.possibleErrorCodes.map(function (x) { return x.Name; }).indexOf(criteria.ErrorCode) === -1) {
                    criteria.ErrorCode = null;
                }
                // Set default values
                if (!criteria.Sender) {
                    criteria.Sender = this.possibleSenders[0].Name;
                }
                if (!criteria.Status) {
                    criteria.Status = this.possibleStatuses.filter(function (s) { return s.Name === IntegrationMonitor.FAILED_STATUS; })[0].Name;
                }
            };
            IntegrationMessagesSearchController.prototype.executeSearchAsync = function (criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, searchResult, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: criteria.Page,
                                    pageSize: criteria.PageSize,
                                    NationalNumber: criteria.NationalNumber,
                                    Sender: criteria.Sender,
                                    ErrorCode: criteria.ErrorCode,
                                    Status: criteria.Status,
                                    ReceivedDateTimeFrom: RemeCare.Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeFrom),
                                    ReceivedDateTimeUntil: RemeCare.Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeUntil),
                                    PlannedDateTimeFrom: RemeCare.Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeFrom),
                                    PlannedDateTimeUntil: RemeCare.Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeUntil),
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.integrationMessageSearchSvc.findIntegrationMessagesAsync(query)];
                            case 2:
                                searchResult = _a.sent();
                                this.searchCollapsed = true;
                                this.saveFiltersAndMessageIdsToStorage(criteria, searchResult.Items.map(function (x) { return x.Id; }), searchResult.HasNextPage);
                                return [2 /*return*/, searchResult];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                throw e_1;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessagesSearchController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, senders, errorCodes, statuses, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.integrationMessageSearchSvc.getSendersAsync(),
                                        this.integrationMessageSearchSvc.getErrorCodesAsync(),
                                        this.integrationMessageSearchSvc.getStatusesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), senders = _a[0], errorCodes = _a[1], statuses = _a[2];
                                this.possibleSenders = _(senders).sortBy(function (s) { return s.Name; });
                                this.possibleErrorCodes = _(errorCodes).sortBy(function (ec) { return ec.Translation; });
                                this.possibleStatuses = statuses;
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessagesSearchController.prototype.saveFiltersAndMessageIdsToStorage = function (criteria, messageIds, hasNextPage) {
                var page = criteria.Page || null;
                var pageSize = criteria.PageSize || null;
                var nationalNumberNew = criteria.NationalNumber || null;
                var senderNew = criteria.Sender || null;
                var errorCodeNew = criteria.ErrorCode || null;
                var statusNew = criteria.Status || null;
                var receivedDateTimeFromNew = criteria.ReceivedDateTimeFrom || null;
                var receivedDateTimeUntilNew = criteria.ReceivedDateTimeUntil || null;
                var plannedDateTimeFromNew = criteria.PlannedDateTimeFrom || null;
                var plannedDateTimeUntilNew = criteria.PlannedDateTimeUntil || null;
                var knownFilters = {
                    Page: page,
                    PageSize: pageSize,
                    NationalNumber: nationalNumberNew,
                    Sender: senderNew,
                    ErrorCode: errorCodeNew,
                    Status: statusNew,
                    ReceivedDateTimeFrom: receivedDateTimeFromNew,
                    ReceivedDateTimeUntil: receivedDateTimeUntilNew,
                    PlannedDateTimeFrom: plannedDateTimeFromNew,
                    PlannedDateTimeUntil: plannedDateTimeUntilNew,
                    MessageIds: messageIds,
                    HasNextPage: hasNextPage,
                };
                this.storageSvc.store(StorageServiceConstants.integrationMonitorKey, knownFilters);
            };
            return IntegrationMessagesSearchController;
        }());
        IntegrationMonitor.remeCareIntegrationMonitorModule.component('rcIntegrationMessagesSearch', {
            bindings: {
                bindToUrl: '@?',
            },
            controller: IntegrationMessagesSearchController,
            templateUrl: 'views/integrationMonitor/integrationMessagesSearch.html',
        });
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
