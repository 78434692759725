module RemeCare.Patient {
    import Guid = Shared.Contract.Guid;

    class MediaDocumentsController implements ng.IComponentController {
        public patientId: Guid;

        constructor(private $stateParams) {
        }

        public $onInit(): void {
            this.patientId = this.$stateParams.patientId;
        }
    }

    remeCarePatientModule.component('mediaDocuments',
    {
        controller: MediaDocumentsController,
        templateUrl: 'patient/controllers/mediaDocuments/mediaDocuments.html'
    });
}