var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var PhoneCallController = /** @class */ (function (_super) {
            __extends(PhoneCallController, _super);
            // @ngInject
            function PhoneCallController($scope, $translate, modalBuilderFactory, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$translate = $translate;
                _this.modalBuilderFactory = modalBuilderFactory;
                $scope.getNumberOfAttempts = function () { return _this.getNumberOfAttempts(); };
                return _this;
            }
            PhoneCallController.prototype.init = function () { };
            PhoneCallController.prototype.getNumberOfAttempts = function () {
                if (this.$scope.component.maxNumberOfAttempts) {
                    var amountLeft = this.$scope.component.maxNumberOfAttempts - this.$scope.component.previousContacts.length;
                    amountLeft = amountLeft > 0 ? amountLeft : 0;
                    return this.$translate.instant('Views.Action.PhoneCall.NumberOfAttempts', {
                        current: amountLeft,
                        max: this.$scope.component.maxNumberOfAttempts,
                    });
                }
                return '';
            };
            return PhoneCallController;
        }(CarePlanAction.ComponentControllerBase));
        var PhoneCallDirective = /** @class */ (function (_super) {
            __extends(PhoneCallDirective, _super);
            function PhoneCallDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/phoneCall.html';
                _this.controller = PhoneCallController;
                return _this;
            }
            return PhoneCallDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        CarePlanAction.remeCareCarePlanActionModule.directive('rcPhoneCall', function () { return new PhoneCallDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
