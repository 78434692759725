var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        CarePlanAction.remeCareCarePlanActionModule.component('rcComponentHeader', {
            bindings: {
                component: '<',
                showRequired: '<',
                uniqueId: '@',
            },
            templateUrl: 'views/carePlanAction/directive/componentHeader.html',
        });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
