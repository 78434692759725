var RemeCare;
(function (RemeCare) {
    var Guidance;
    (function (Guidance) {
        var GuidanceIconController = /** @class */ (function () {
            // @ngInject
            function GuidanceIconController(guidanceApiSvc) {
                this.guidanceApiSvc = guidanceApiSvc;
            }
            GuidanceIconController.prototype.$onInit = function () {
                this.item = this.guidanceApiSvc.getGuidanceItem(this.uniqueId);
            };
            GuidanceIconController.prototype.showGuidance = function () {
                this.guidanceApiSvc.toggleItem(this.item.Id);
            };
            return GuidanceIconController;
        }());
        Guidance.remeCareGuidanceModule.component('rcGuidanceIcon', {
            bindings: {
                component: '<',
                iconFunc: '<',
                uniqueId: '@',
            },
            controller: GuidanceIconController,
            templateUrl: 'views/guidance/directive/guidanceIcon.html',
        });
    })(Guidance = RemeCare.Guidance || (RemeCare.Guidance = {}));
})(RemeCare || (RemeCare = {}));
