namespace RemeCare.Patient {
    import CareRequestPart = Shared.Contract.Read.ICareRequestPart;
    import GridBuilder = Shared.Framework.Grid.SearchGridBuilder;
    import Guid = Shared.Contract.Guid;
    import Version = Shared.Contract.Read.IVersion;
    import VersionValidityChange = Shared.Framework.Model.VersionValidityChange;

    export abstract class CarePlanDetailComponentControllerBase<T extends Patient.Model.CarePlanDetail>
        implements ng.IComponentController {
        public carePlanId: Shared.Contract.Guid;
        public definitionId: Guid;
        public editRight: boolean;
        public patientId: Guid;
        public title: string;
        public part: CareRequestPart;
        public versionsGrid: Shared.Framework.Grid.Grid<T>;

        constructor(
            protected $dialog: Shared.Service.DialogService,
            protected patientSvc: PatientService,
            protected toaster: Shared.Framework.Toaster,
            protected therapyApiSvc: Core.Services.TherapyApiService,
            protected authservice: Shared.Framework.AuthService,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public $onInit(): void {
            this.loadDataAsync();
            this.buildVersionsGrid();
        }

        public async addVersionAsync(detail?: T): Promise<T> {
            return new Promise<T>(resolve => {
                const minVersion = _(this.versionsGrid.getData())
                    .chain()
                    .sortBy(x => x.validFromDate)
                    .reverse()
                    .first()
                    .value();
                const minDate = minVersion ? moment(minVersion.validFromDate) : moment();
                const scope = {
                    definition: {
                        Id: this.definitionId,
                        Text: this.title,
                    },
                    carePlanId: this.carePlanId,
                    minDate: minDate.add(1, 'day'),
                };
                this.extendAddScope(scope, detail);
                this.modalBuilderFactory
                    .createModalBuilder<T>()
                    .setController(this.getAddController())
                    .setTemplateUrl(this.getAddTemplateUrl())
                    .setScope(scope)
                    .setResultCallBack(r => {
                        resolve(r);
                        this.setVersionGridData();
                    })
                    .build();
            });
        }

        protected isVersionEditDisabled(version: T): boolean {
            // If version is in the future, everyone can edit
            if (!moment().isAfter(moment(version.validFromDate))) {
                return false;
            }

            // Otherwise, if user doesn't have patientCarePlanCorrectParameterValue right, cannot edit
            if (
                !this.authservice.hasRight(
                    Shared.Framework.AuthContext.patientCarePlanCorrectParameterValue,
                    Shared.Framework.AuthRight.Write
                )
            ) {
                return true;
            }

            // Otherwise, the very first version (in time) can be edited. See SPEC-88
            if (_.last(this.versionsGrid.getData()) !== version) {
                return true;
            }

            return false;
        }

        protected abstract deleteAsync(fromDate: string): Promise<void>;

        protected buildVersionsGrid(): void {
            let gridBuilder = this.gridBuilderSvc.createGridBuilder<T>();
            gridBuilder = this.addGridRows(gridBuilder);
            this.versionsGrid = gridBuilder
                .addColumn('validFromDate', 'General.ValidFrom', { cellFilter: 'date: "shortDate"' })
                .addColumn('validUntilDate', 'General.ValidUntil', { cellFilter: 'date: "shortDate"' })
                .addConditionalEditButtonWithPromiseFunctionColumn(
                    v => this.editVersionAsync(v),
                    v => this.isVersionEditDisabled(v)
                )
                .addConditionalDeleteButtonColumn(
                    v => {
                        return (
                            moment().isAfter(moment(v.validFromDate), 'day') ||
                            moment().isSame(moment(v.validFromDate), 'day')
                        );
                    },
                    () => this.setVersionGridData(),
                    v => this.deleteVersionAsync(v)
                )
                .setSorting(false)
                .build();
            this.setVersionGridData();
        }

        protected abstract addGridRows(gridBuilder: GridBuilder<T>): GridBuilder<T>;

        protected abstract changeValidityAsync(versionValidityChange: VersionValidityChange): Promise<void>;

        protected abstract setVersionGridData(): void;

        protected abstract onPartRetrieved(): void;

        protected abstract getAddController(): string;

        protected abstract getAddTemplateUrl(): string;

        protected abstract extendAddScope(scope: any, detail: T): void;

        private async loadDataAsync(): Promise<void> {
            try {
                const carePlans = await this.patientSvc.getCarePlansAsync(this.patientId);
                const carePlan = _.find(carePlans, cp => cp.Id === this.carePlanId);
                this.getPartAsync(carePlan.TherapyId);
                this.getRightAsync(carePlan.Id);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getPartAsync(therapyId: Guid): Promise<void> {
            try {
                const p = await this.therapyApiSvc.getCareRequestPartAsync(therapyId, this.definitionId);
                this.part = p;
                this.onPartRetrieved();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getRightAsync(carePlanId: Guid): Promise<void> {
            try {
                const rights = await this.patientSvc.getTherapyBusinessContextRightsAsync(this.patientId, {
                    carePlanId: carePlanId,
                });
                const right = _.find(
                    rights,
                    r =>
                        r.TherapyBusinessContext ===
                        Shared.Contract.Code.TherapyBusinessContextCode.CarePlanConfiguration
                );
                this.editRight =
                    // tslint:disable-next-line:no-bitwise
                    right != null ? (right.ExecutionRight & Shared.Framework.AuthRight.Write) !== 0 : false;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async deleteVersionAsync(detail: T): Promise<boolean> {
            return new Promise<boolean>(resolve => {
                this.$dialog.confirmBox(
                    'General.Versions.DeleteVersionConfirm',
                    'General.Versions.DeleteVersionConfirmMessage',
                    async () => {
                        const fromDate = Shared.DateHelper.toServerDateString(moment(detail.validFromDate).toDate());
                        try {
                            await this.deleteAsync(fromDate);
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private async editVersionAsync(detail: T): Promise<T> {
            if (moment().isAfter(detail.validFromDate)) {
                return this.addVersionAsync(detail);
            }
            return new Promise<T>(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<VersionValidityChange>()
                    .setController('changeVersionValidityCtrl')
                    .setTemplateUrl('views/changeVersionValidity.html')
                    .setSize(Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                        change: new VersionValidityChange({
                            ValidFromDate: Shared.DateHelper.toServerDateString(detail.validFromDate),
                        } as Version),
                        versions: _(this.versionsGrid.getData()).map(x => {
                            return {
                                ValidFromDate: Shared.DateHelper.toServerDateString(x.validFromDate),
                                ValidUntilDate: Shared.DateHelper.toServerDateString(x.validUntilDate),
                            };
                        }),
                    })
                    .setResultCallBack(async r => {
                        try {
                            await this.changeValidityAsync(r);
                            resolve(detail);
                            this.setVersionGridData();
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    })
                    .build();
            });
        }
    }
}
