var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Person = /** @class */ (function () {
            function Person(def) {
                this.addresses = [];
                this.telephoneNumbers = [];
                this.mobileNumbers = [];
                this.faxNumbers = [];
                this.emailAddresses = [];
                this.partyRoles = [];
                if (def != null) {
                    this.id = def.Id;
                    this.partyId = def.PartyId;
                    this.firstName = def.FirstName;
                    this.lastName = def.LastName;
                    this.middleName = def.MiddleName;
                    this.initials = def.Initials;
                    this.title = def.Title;
                    this.gender = def.Gender;
                    this.dateOfBirth = RemeCare.Shared.DateHelper.serverDateStringToDate(def.DateOfBirth);
                    this.dateOfDeath = RemeCare.Shared.DateHelper.serverDateStringToDate(def.DateOfDeath);
                    this.language = def.Language;
                    this.communicationLanguage = def.CommunicationLanguage;
                    this.nationality = def.Nationality;
                    this.maritalStatus = def.MaritalStatus;
                    this.educationLevel = def.EducationLevel;
                    this.technologicalAffinities = def.TechnologicalAffinities || [];
                    this.nationalNumber = def.NationalNumber;
                    this.communicationPreference = def.CommunicationPreference;
                    this.addresses = _(def.Addresses).map(function (a) { return new Model.Address(a); });
                    this.telephoneNumbers = _(def.TelephoneNumbers).map(function (t) { return new Model.TelephoneNumber(t); });
                    this.mobileNumbers = _(def.MobileNumbers).map(function (m) { return new Model.TelephoneNumber(m); });
                    this.faxNumbers = _(def.FaxNumbers).map(function (f) { return new Model.TelephoneNumber(f); });
                    this.emailAddresses = _(def.EmailAddresses).map(function (e) { return new Model.Email(e); });
                    this.partyRoles = def.PartyRoles || [];
                }
            }
            Person.prototype.getAllNumbers = function () {
                return _.union(this.telephoneNumbers, this.mobileNumbers, this.faxNumbers);
            };
            Person.prototype.getDefaultAddress = function () {
                var addresses = _(this.addresses).filter(function (a) {
                    return a.validFromDate <= RemeCare.Shared.DateHelper.today() &&
                        (a.validUntilDate >= RemeCare.Shared.DateHelper.today() || a.validUntilDate === null);
                });
                var address = _.find(addresses, function (a) { return a.contactPointUsageType === RemeCare.Shared.Contract.Code.ContactPointUsageType.Residence; });
                if (address != null) {
                    return address;
                }
                return _.find(addresses, function (a) { return a.contactPointUsageType === RemeCare.Shared.Contract.Code.ContactPointUsageType.Domicile; });
            };
            Person.prototype.getDefaultTelephone = function () {
                var telephoneNumbers = _(this.telephoneNumbers).filter(function (a) {
                    return a.validFromDate <= RemeCare.Shared.DateHelper.today() &&
                        (a.validUntilDate >= RemeCare.Shared.DateHelper.today() || a.validUntilDate === null);
                });
                return (_(telephoneNumbers)
                    .chain()
                    .sortBy(function (t) { return t.contactPointUsageType; })
                    .first()
                    .value() || new Model.TelephoneNumber());
            };
            Person.prototype.getDefaultMobile = function () {
                var mobileNumbers = _(this.mobileNumbers).filter(function (a) {
                    return a.validFromDate <= RemeCare.Shared.DateHelper.today() &&
                        (a.validUntilDate >= RemeCare.Shared.DateHelper.today() || a.validUntilDate === null);
                });
                return (_(mobileNumbers)
                    .chain()
                    .sortBy(function (t) { return t.contactPointUsageType; })
                    .first()
                    .value() || new Model.TelephoneNumber());
            };
            Person.prototype.getDefaultEmail = function () {
                var emailAddresses = _(this.emailAddresses).filter(function (a) {
                    return a.validFromDate <= RemeCare.Shared.DateHelper.today() &&
                        (a.validUntilDate >= RemeCare.Shared.DateHelper.today() || a.validUntilDate === null);
                });
                return _(emailAddresses)
                    .chain()
                    .sortBy(function (t) { return t.contactPointUsageType; })
                    .first()
                    .value();
            };
            Person.prototype.getPersonalInfo = function () {
                return {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    title: this.title != null ? this.title.Id : null,
                    middleName: this.middleName,
                    initials: this.initials,
                    gender: this.gender != null ? this.gender.Id : null,
                    dateOfBirth: RemeCare.Shared.DateHelper.toServerDateString(this.dateOfBirth),
                    dateOfDeath: RemeCare.Shared.DateHelper.toServerDateString(this.dateOfDeath),
                    nationalNumber: this.nationalNumber,
                    communicationLanguage: this.communicationLanguage != null ? this.communicationLanguage.Id : null,
                    language: this.language != null ? this.language.Id : null,
                    nationality: this.nationality != null ? this.nationality.Id : null,
                    maritalStatus: this.maritalStatus != null ? this.maritalStatus.Id : null,
                    educationLevel: this.educationLevel != null ? this.educationLevel.Id : null,
                    technologicalAffinities: _(this.technologicalAffinities).map(function (t) { return t.Id; }),
                };
            };
            Person.prototype.isPatient = function () {
                return _(this.partyRoles).any(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Patient; });
            };
            Person.prototype.getPatientPartyRole = function () {
                return _(this.partyRoles).find(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Patient; });
            };
            Person.prototype.isEmployee = function () {
                return _(this.partyRoles).any(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee; });
            };
            Person.prototype.getEmployeePartyRole = function () {
                return _.find(this.partyRoles, function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee; });
            };
            Person.prototype.isHealthCareProfessional = function () {
                return _(this.partyRoles).any(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; });
            };
            Person.prototype.getHealthCareProfessionalPartyRole = function () {
                return _(this.partyRoles).find(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; });
            };
            Person.prototype.getContactPartyRoleForPatient = function (patientId) {
                var contactPersons = _(this.partyRoles).filter(function (pr) { return pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.ContactPerson; });
                return _(contactPersons.filter(function (pr) { return pr.ContactFor.PartyRoleId === patientId; })).first();
            };
            Person.prototype.getCurrentRelations = function () {
                var role = this.getHealthCareProfessionalPartyRole();
                if (role == null) {
                    return [];
                }
                var today = RemeCare.Shared.DateHelper.today();
                return _(role.CareProviderRelations).filter(function (cpr) {
                    return (!moment(RemeCare.Shared.DateHelper.serverDateStringToDate(cpr.ValidFromDate)).isAfter(today) &&
                        (cpr.ValidUntilDate == null ||
                            !moment(RemeCare.Shared.DateHelper.serverDateStringToDate(cpr.ValidUntilDate)).isBefore(today)));
                });
            };
            Person.prototype.getOldRelations = function () {
                var role = this.getHealthCareProfessionalPartyRole();
                if (role == null) {
                    return [];
                }
                var today = RemeCare.Shared.DateHelper.today();
                return _(role.CareProviderRelations).filter(function (cpr) {
                    return moment(RemeCare.Shared.DateHelper.serverDateStringToDate(cpr.ValidUntilDate)).isBefore(today);
                });
            };
            return Person;
        }());
        Model.Person = Person;
        var PatientPerson = /** @class */ (function (_super) {
            __extends(PatientPerson, _super);
            function PatientPerson(def) {
                var _this = _super.call(this, def) || this;
                _this.healthInsuranceFunds = [];
                _this.externalReferences = [];
                if (def != null) {
                    _this.healthInsuranceFunds = _(def.HealthInsuranceFunds)
                        .chain()
                        .map(function (h) { return new HealthInsuranceFundAssignment(h); })
                        .sortBy(function (h) { return h.validFromDate; })
                        .value();
                    _this.externalReferences = def.ExternalReferences || [];
                    _this.remark = def.Remark;
                }
                return _this;
            }
            PatientPerson.prototype.getCurrentInsuranceFund = function () {
                return _.find(this.healthInsuranceFunds, function (h) { return h.validUntilDate == null || moment().isBefore(h.validUntilDate); });
            };
            PatientPerson.prototype.toRegisterPatient = function () {
                return {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    middleName: this.middleName,
                    initials: this.initials,
                    title: this.title ? this.title.Id : null,
                    gender: this.gender ? this.gender.Id : null,
                    dateOfBirth: RemeCare.Shared.DateHelper.toServerDateString(this.dateOfBirth),
                    nationalNumber: this.nationalNumber,
                    communicationLanguage: this.communicationLanguage ? this.communicationLanguage.Code : null,
                    maritalStatus: this.maritalStatus ? this.maritalStatus.Id : null,
                    language: this.language ? this.language.Code : null,
                    educationLevel: this.educationLevel ? this.educationLevel.Id : null,
                    nationality: this.nationality ? this.nationality.Code : null,
                    technologyAffinity: _(this.technologicalAffinities).map(function (ta) { return ta.Id; }),
                    contactPoints: _(this.addresses)
                        .chain()
                        .union(this.telephoneNumbers)
                        .union(this.emailAddresses)
                        .filter(function (cp) { return !cp.isEmpty(); })
                        .map(function (cp) { return cp.toContactPointParameters(); })
                        .value(),
                };
            };
            return PatientPerson;
        }(Person));
        Model.PatientPerson = PatientPerson;
        var HealthInsuranceFundAssignment = /** @class */ (function () {
            function HealthInsuranceFundAssignment(def) {
                if (def != null) {
                    this.id = def.Id;
                    this.healthInsuranceFund = def.HealthInsuranceFund;
                    this.healthInsuranceRiskCode = def.HealthInsuranceRiskCode;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(def.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(def.ValidUntilDate);
                }
            }
            HealthInsuranceFundAssignment.prototype.getCodeAndName = function () {
                return this.healthInsuranceFund.Code + ' - ' + this.healthInsuranceFund.Text;
            };
            HealthInsuranceFundAssignment.prototype.toServerWrite = function () {
                return {
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    healthInsuranceFund: this.healthInsuranceFund.Id,
                    healthInsuranceRiskCode: this.healthInsuranceRiskCode,
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                };
            };
            return HealthInsuranceFundAssignment;
        }());
        Model.HealthInsuranceFundAssignment = HealthInsuranceFundAssignment;
        var ContactPersonPerson = /** @class */ (function (_super) {
            __extends(ContactPersonPerson, _super);
            function ContactPersonPerson(personDetail, roleInfo) {
                var _this = _super.call(this, personDetail) || this;
                if (roleInfo != null) {
                    _this.relationship = roleInfo.Relationship;
                    _this.isHealthCareNonProfessional = roleInfo.IsHealthCareNonProfessional;
                    _this.remarkInternalUse = roleInfo.Remark;
                    _this.partyRoleId = roleInfo.PartyRoleId;
                    _this.isActive = roleInfo.IsActive;
                }
                return _this;
            }
            ContactPersonPerson.prototype.getPartyRoleNotLinkedToPatient = function (patientId) {
                return _(this.partyRoles).filter(function (pr) {
                    return !(pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.ContactPerson &&
                        pr.ContactFor.PartyRoleId === patientId);
                });
            };
            ContactPersonPerson.prototype.hasPartyRolesNotLinkedToPatient = function (patientId) {
                return _(this.partyRoles).any(function (pr) {
                    return !(pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.ContactPerson &&
                        pr.ContactFor.PartyRoleId === patientId) &&
                        !(pr.PartyRoleType.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional &&
                            _(pr.NonProfessionalForPatients).all(function (fp) { return fp.Patient.PartyRoleId === patientId; }));
                });
            };
            ContactPersonPerson.prototype.updateContactPoints = function (person) {
                this.addresses = _(person.Addresses).map(function (a) { return new Model.Address(a); });
                this.telephoneNumbers = _(person.TelephoneNumbers).map(function (t) { return new Model.TelephoneNumber(t); });
                this.mobileNumbers = _(person.MobileNumbers).map(function (m) { return new Model.TelephoneNumber(m); });
                this.faxNumbers = _(person.FaxNumbers).map(function (f) { return new Model.TelephoneNumber(f); });
                this.emailAddresses = _(person.EmailAddresses).map(function (e) { return new Model.Email(e); });
            };
            ContactPersonPerson.prototype.toRegisterContactPerson = function () {
                var personalInfo = this.getPersonalInfo();
                personalInfo.RelationshipId = this.relationship ? this.relationship.Id : null;
                personalInfo.IsHealthCareNonProfessional = this.isHealthCareNonProfessional;
                personalInfo.MatchedPartyId = this.id;
                personalInfo.RemarkInternalUse = this.remarkInternalUse;
                personalInfo.ContactPoints = _(this.addresses)
                    .chain()
                    .union(this.telephoneNumbers)
                    .union(this.emailAddresses)
                    .filter(function (cp) { return !cp.isEmpty(); })
                    .map(function (cp) { return cp.toContactPointParameters(); })
                    .value();
                personalInfo.IsActive = this.isActive;
                return personalInfo;
            };
            return ContactPersonPerson;
        }(Person));
        Model.ContactPersonPerson = ContactPersonPerson;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
