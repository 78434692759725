var RemeCare;
(function (RemeCare) {
    var RemeCareAppConfig = /** @class */ (function () {
        function RemeCareAppConfig($stateProvider, $urlServiceProvider, $translateProvider, $httpProvider, baseUrl, tmhDynamicLocaleProvider) {
            this.$stateProvider = $stateProvider;
            this.$urlServiceProvider = $urlServiceProvider;
            this.$translateProvider = $translateProvider;
            this.$httpProvider = $httpProvider;
            this.baseUrl = baseUrl;
            this.tmhDynamicLocaleProvider = tmhDynamicLocaleProvider;
            this.init();
        }
        RemeCareAppConfig.prototype.init = function () {
            this.$stateProvider.state('home', {
                url: '/',
                controller: 'homeCtrl',
                template: '',
            });
            this.$urlServiceProvider.rules.otherwise({
                state: 'home',
            });
            this.$translateProvider.useLoaderCache(true);
            this.$translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: this.baseUrl + 'Localization/{lang}/{part}',
            });
            var $cookies;
            angular.injector(['ngCookies']).invoke([
                '$cookies',
                function (_$cookies_) {
                    $cookies = _$cookies_;
                },
            ]);
            var language = $cookies.get('applicationLanguage');
            language = language || (navigator.language ? navigator.language : navigator.userLanguage);
            if (language.indexOf('fr') > -1) {
                language = 'fr';
            }
            else if (language.indexOf('en') > -1) {
                language = 'en';
            }
            else {
                language = 'nl';
            }
            this.$translateProvider.use(language);
            this.$translateProvider.preferredLanguage(language);
            this.$translateProvider.useSanitizeValueStrategy(null);
            // Set default Accept-Language header
            this.$httpProvider.defaults.headers.common['Accept-Language'] = language;
            delete this.$httpProvider.defaults.headers.common['X-Requested-With'];
            this.tmhDynamicLocaleProvider.localeLocationPattern('lib/i18n/angular-locale_{{locale}}.js');
        };
        return RemeCareAppConfig;
    }());
    RemeCare.remeCareAppModule.config(function ($stateProvider, $urlServiceProvider, $translateProvider, $httpProvider, baseUrl, tmhDynamicLocaleProvider) {
        return new RemeCareAppConfig($stateProvider, $urlServiceProvider, $translateProvider, $httpProvider, baseUrl, tmhDynamicLocaleProvider);
    });
    var RemeCareApp = /** @class */ (function () {
        function RemeCareApp($rootScope, $http, $cookies, $translate, $locale, tmhDynamicLocale, userTrackSvc, spinnerSvc, translationResolver) {
            this.$rootScope = $rootScope;
            this.$http = $http;
            this.$cookies = $cookies;
            this.$translate = $translate;
            this.$locale = $locale;
            this.tmhDynamicLocale = tmhDynamicLocale;
            this.userTrackSvc = userTrackSvc;
            this.spinnerSvc = spinnerSvc;
            this.translationResolver = translationResolver;
            this.setRootScope();
            this.translationResolver.setParts(['General', 'Views_InformedConsent']);
            this.userTrackSvc.startTracking();
            this.configure();
            if (!('ontouchstart' in document.documentElement)) {
                document.documentElement.className += ' no-touch';
            }
        }
        RemeCareApp.prototype.setRootScope = function () {
            var _this = this;
            this.$rootScope.$on('$translateLoadingStart', function (e) {
                // Show a loading message until translations are loaded
                _this.spinnerSvc.show('loader');
            });
            this.$rootScope.$on('$translateLoadingEnd', function (e) {
                // Hide loading message
                _this.spinnerSvc.hide('loader');
            });
        };
        RemeCareApp.prototype.configure = function () {
            var language = this.$cookies.get('applicationLanguage');
            language = language || (navigator.language ? navigator.language : navigator.userLanguage);
            if (language.indexOf('fr') > -1) {
                language = 'fr';
            }
            else if (language.indexOf('en') > -1) {
                language = 'en';
            }
            else {
                language = 'nl';
            }
            this.$http.defaults.headers.common['Accept-Language'] = language;
            this.$translate.use(language);
            moment.locale(language);
            this.tmhDynamicLocale.set(language);
            this.$locale.id = language;
        };
        return RemeCareApp;
    }());
    RemeCare.remeCareAppModule.run(function ($rootScope, $http, $cookies, $translate, $locale, tmhDynamicLocale, userTrackSvc, spinnerSvc, translationResolver) {
        return new RemeCareApp($rootScope, $http, $cookies, $translate, $locale, tmhDynamicLocale, userTrackSvc, spinnerSvc, translationResolver);
    });
    var HomeController = /** @class */ (function () {
        function HomeController(authservice, $state, $window) {
            this.authservice = authservice;
            this.$state = $state;
            this.$window = $window;
        }
        HomeController.prototype.$onInit = function () {
            var profile = this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.applicationProfile);
            if (!profile) {
                this.$state.go('checkApplicationProfile');
                return;
            }
            this.redirect(profile);
        };
        HomeController.prototype.redirect = function (profile) {
            var profileCode = parseInt(profile);
            switch (profileCode) {
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser:
                    this.$state.go('management');
                    break;
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager:
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager:
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider:
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.TherapyProvider:
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.InformalCareProvider:
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.Patient:
                    this.$state.go('agenda');
                    break;
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.Configurator:
                    this.$window.location.href = '/remeConfig';
                    break;
                case RemeCare.Shared.Contract.Code.ApplicationProfileType.UnknownCareProvider:
                    this.$window.history.back();
                    break;
                default:
                    this.$state.go('checkApplicationProfile');
                    break;
            }
        };
        return HomeController;
    }());
    RemeCare.remeCareAppModule.controller('homeCtrl', function (authservice, $state, $window) { return new HomeController(authservice, $state, $window); });
})(RemeCare || (RemeCare = {}));
