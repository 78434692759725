/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestDocumentController = /** @class */ (function (_super) {
                __extends(CareRequestDocumentController, _super);
                function CareRequestDocumentController($scope, $translate, toaster, $filter, modalBuilderFactory, documentSvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$filter = $filter;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.documentSvc = documentSvc;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.onDocumentAdded = function (d) { return _this.onDocumentAdded(d); };
                    $scope.onDocumentDeleted = function (d) { return _this.onDocumentDeleted(d); };
                    $scope.getAdvancedColumnData = function (d) { return _this.getAdvancedColumnData(d); };
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                CareRequestDocumentController.prototype.$onInit = function () {
                    var _this = this;
                    this.$scope.advancedColumns = this.$scope.careRequestPart.hasReferenceDate
                        ? ['General.DocumentObservationDate']
                        : [];
                    this.$scope.careRequestDocuments = _(this.$scope.careRequest.careRequestDocuments)
                        .filter(function (t) { return t.careRequestDocumentDefinitionId === _this.$scope.careRequestPart.localIdentity; });
                    this.$scope.documents = _(this.$scope.careRequestDocuments).map(function (c) { return c.document; });
                };
                CareRequestDocumentController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                CareRequestDocumentController.prototype.onDocumentAdded = function (d) {
                    var _this = this;
                    var careRequestDocument = CareRequest.CareRequestDocument.createFromDocument(d, this.$scope.careRequestPart.localIdentity);
                    if (this.$scope.careRequestPart.hasReferenceDate) {
                        this.showReferenceDateModal()
                            .done(function (date) {
                            careRequestDocument.referenceDate = date;
                            _this.$scope.careRequestDocuments.push(careRequestDocument);
                            _this.$scope.careRequest.careRequestDocuments.push(careRequestDocument);
                        })
                            .fail(function () {
                            _this.documentSvc.deleteDocument(d.Id);
                            var index = _this.$scope.documents.indexOf(d);
                            _this.$scope.documents.splice(index, 1);
                        });
                    }
                    else {
                        this.$scope.careRequestDocuments.push(careRequestDocument);
                        this.$scope.careRequest.careRequestDocuments.push(careRequestDocument);
                    }
                };
                CareRequestDocumentController.prototype.onDocumentDeleted = function (d) {
                    var careRequestDocument = _.find(this.$scope.careRequestDocuments, function (c) { return c.id === d.Id; });
                    var index = this.$scope.careRequestDocuments.indexOf(careRequestDocument);
                    this.$scope.careRequestDocuments.splice(index, 1);
                    index = this.$scope.careRequest.careRequestDocuments.indexOf(careRequestDocument);
                    this.$scope.careRequest.careRequestDocuments.splice(index, 1);
                };
                CareRequestDocumentController.prototype.getAdvancedColumnData = function (id) {
                    if (!this.$scope.careRequestPart.hasReferenceDate)
                        return [];
                    var careRequestDocument = _.find(this.$scope.careRequestDocuments, function (c) { return c.id === id; });
                    if (careRequestDocument == null)
                        return [''];
                    return [this.$filter('date')(careRequestDocument.referenceDate, 'shortDate')];
                };
                CareRequestDocumentController.prototype.showReferenceDateModal = function () {
                    var deferred = jQuery.Deferred();
                    this.modalBuilderFactory.createModalBuilder()
                        .setController('referenceDateCtrl')
                        .setTemplateUrl('views/shared/referenceDate.html')
                        .setResultCallBack(function (r) { return deferred.resolve(r); })
                        .setDismissCallBack(function () { return deferred.reject(); })
                        .build();
                    return deferred.promise();
                };
                return CareRequestDocumentController;
            }(Directive.CareRequestPartControllerBase));
            var CareRequestDocumentDirective = /** @class */ (function (_super) {
                __extends(CareRequestDocumentDirective, _super);
                function CareRequestDocumentDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = ['$scope', '$translate', 'toaster', '$filter', 'modalBuilderFactory', 'documentSvc', 'careRequestSvc',
                        function ($scope, $translate, toaster, $filter, modalBuilderFactory, documentSvc, careRequestSvc) { return new CareRequestDocumentController($scope, $translate, toaster, $filter, modalBuilderFactory, documentSvc, careRequestSvc); }];
                    _this.templateUrl = 'views/careRequest/directive/careRequestDocument.html';
                    return _this;
                }
                return CareRequestDocumentDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestDocument', function () { return new CareRequestDocumentDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
