/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QualitativeMeasuringPointParameter = Shared.Framework.Model.QualitativeMeasuringPointParameter;
    import QualitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQualitativeMeasuringPoint;
    import ColourQualitative = Shared.Framework.Model.ColourQualitative;

    class ColourQualitativeController extends HighChartsMonitoringPartController<
        Shared.Framework.Model.MonitoringPartTimeLine
    > {
        private static black = '#000000';

        public codeSets: Shared.Contract.Read.ICodeSetTypeSearch[];
        private qualitativeMeasuringPoints: QualitativeMeasuringPoint[];
        private showMultiplePerDay: boolean;

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected async init(): Promise<void> {
            this.qualitativeMeasuringPoints = _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter(mps => mps.type === MonitoringPartSourceType.QualitativeMeasuringPointParameter)
                .map(mps => mps as QualitativeMeasuringPointParameter)
                .map(mps => {
                    return {
                        observableEntityId: mps.observableEntity.Id,
                        codeSetId: mps.codeSet.Id,
                    } as QualitativeMeasuringPoint;
                })
                .value();
            const codeSetIds = _(this.qualitativeMeasuringPoints).map(qmp => qmp.codeSetId);
            try {
                this.codeSets = await this.masterdataSvc.findCodeSetTypes(codeSetIds);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        protected async onDateChange(): Promise<void> {
            try {
                const oneMonthEarlier = moment(this.dateInfo.untilDate);
                oneMonthEarlier.subtract(1, 'months');
                this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
                const dateFormat = this.getDateFormat(this.showMultiplePerDay);
                _.each(this.chartConfigs, chartConfig => {
                    chartConfig.options.tooltip.xDateFormat = dateFormat;
                });
                const graphPoints = await this.carePlanApiSvc.findQualitativeGraphPointsAsync(
                    this.carePlanIds,
                    this.qualitativeMeasuringPoints,
                    this.dateInfo.fromDate,
                    this.getUntilDate(),
                    this.showMultiplePerDay
                );
                const graphs = _.map(graphPoints, g => new Model.QualitativeGraph(g, !this.showMultiplePerDay));
                this.configureCharts(_.sortBy(graphs, graph => this.findSequence(graph)));
            } catch (error) {
                this.toaster.error(error);
            }
        }

        protected containsData(config: HighChartsNGConfig): boolean {
            return !_(config.series).all(s => _(s.data).all(d => d[1] == null));
        }

        private findSequence(graph: Model.QualitativeGraph): number {
            const observableEntityId = graph.subject.Id;
            const codeSetId = graph.scale.Id;
            const monitoringPartSource = _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter(mps => mps.type === MonitoringPartSourceType.QualitativeMeasuringPointParameter)
                .map(mps => mps as QualitativeMeasuringPointParameter)
                .find(mps => mps.observableEntity.Id === observableEntityId && mps.codeSet.Id === codeSetId)
                .value();
            const sequence = monitoringPartSource == null ? 0 : monitoringPartSource.sequence;
            return sequence;
        }

        private configureCharts(graphs: Model.QualitativeGraph[]): void {
            const chartConfigs: HighChartsNGConfig[] = _(graphs).map(g => {
                return {
                    options: {
                        chart: {
                            animation: false,
                            type: 'column',
                            height: 170,
                            zoomType: 'x',
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        yAxis: {
                            title: {
                                text: '<i></i>',
                            },
                            labels: {
                                enabled: false,
                            },
                            gridLineWidth: 0,
                            minorGridLineWidth: 0,
                            visible: true,
                            startOnTick: false,
                            endOnTick: false,
                        },
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(this.showMultiplePerDay),
                            pointFormat: '<span style="color: {point.color}">\u25CF</span> {series.name}<br/>',
                        },
                        title: {
                            text: null,
                        },
                        subtitle: {
                            text: g.subject.Text,
                            style: {
                                fontWeight: 'bold',
                                fontSize: 'larger',
                            },
                        },
                        plotOptions: {
                            column: {
                                animation: false,
                                stacking: 'percentage',
                            },
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: this.mapDataPoints(g),
                };
            });
            this.chartConfigs = chartConfigs;
        }

        private mapDataPoints(graph: Model.QualitativeGraph): HighchartsColumnChartSeriesOptions[] {
            const colourQualitative = _(this.monitoringPart.monitoringPartSources)
                .chain()
                .map(mps => mps as QualitativeMeasuringPointParameter)
                .find(
                    (mps: QualitativeMeasuringPointParameter) =>
                        mps.observableEntity &&
                        mps.observableEntity.Id === graph.subject.Id &&
                        mps.codeSet &&
                        graph.scale &&
                        mps.codeSet.Id === graph.scale.Id
                )
                .value().sourceParameterRepresentation as ColourQualitative;

            const cstcsis = _(this.codeSets).find<Shared.Contract.Read.ICodeSetTypeSearch>(
                cs => cs.Id === graph.scale.Id
            ).CodeSetTypeCodeSetItems;

            const series = _(cstcsis)
                .chain()
                .map(cstcsi => ({
                    codeSetItem: cstcsi,
                    codeSetColor: _.find(
                        colourQualitative.codeSetColours,
                        csc => csc.codeSetItemId === cstcsi.CodeSetItemId
                    ),
                }))
                .sortBy(csccsi => csccsi.codeSetItem.Sequence)
                .map(csc => {
                    return {
                        animation: false,
                        name: csc.codeSetItem.Name,
                        color: (csc.codeSetColor && csc.codeSetColor.colour) || ColourQualitativeController.black,
                        data: _(graph.graphPoints).map(gp => {
                            return [gp.x.valueOf(), this.hasDataPoint(gp, csc.codeSetItem.CodeSetItemId) ? 1 : null];
                        }),
                        maxPointWidth: 20,
                        pointPlacement: 'on',
                    } as HighchartsColumnChartSeriesOptions;
                })
                .value();
            return series;
        }

        private hasDataPoint(
            graphPoint: Model.GraphPoint<Date, Shared.Contract.ICodedEntityTranslation[]>,
            id: Shared.Contract.Guid
        ): boolean {
            const ids = _(graphPoint.y).map(y => y.Id);
            return _(ids).contains(id);
        }
    }

    class ColourQualitativeComponent extends MonitoringPartComponentBase {
        public controller = ColourQualitativeController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringColourQualitative', new ColourQualitativeComponent());
}
