/// <reference path="../../../Remedus.RemeCare.Web.Client.Shared/dist/js/remecare-shared.d.ts"/>

declare var phoneUtils: any;

namespace RemeCare.CarePlanAction {
    export let remeCareCarePlanActionModule = angular.module('RemeCare.CarePlanAction', []);
}
namespace RemeCare.Agenda {
    export let remeCareAgendaModule = angular.module('RemeCare.Agenda', []);
}
namespace RemeCare.CareRequest {
    export let remeCareCareRequestModule = angular.module('RemeCare.CareRequest', []);
}
namespace RemeCare.Patient {
    export let remeCarePatientModule = angular.module('RemeCare.Patient', []);
}
namespace RemeCare.Management {
    export let remeCareManagementModule = angular.module('RemeCare.Management', []);
}
namespace RemeCare.Inbox {
    export let remeCareInboxModule = angular.module('RemeCare.Inbox', []);
}
namespace RemeCare.Custom {
    export let remeCareCustomModule = angular.module('RemeCare.Custom', []);
}
namespace RemeCare.SelfService {
    export let remeCareSelfServiceModule = angular.module('RemeCare.SelfService', []);
}
namespace RemeCare.OneTimeLogin {
    export let remeCareOneTimeLoginModule = angular.module('RemeCare.OneTimeLogin', []);
}
namespace RemeCare.Connections {
    export let remeCareConnectionsModule = angular.module('RemeCare.Connections', []);
}
namespace RemeCare.Guidance {
    export let remeCareGuidanceModule = angular.module('RemeCare.Guidance', []);
}

namespace RemeCare.IntegrationMonitor {
    export let remeCareIntegrationMonitorModule = angular.module('RemeCare.IntegrationMonitor', []);
}

namespace RemeCare {
    export let remeCareAppModule = angular
        .module('RemeCareApp', [
            'ngAnimate',
            'ngCookies',
            'ngSanitize',
            'ui.bootstrap.collapse',
            'ui.bootstrap.dropdown',
            'ui.bootstrap.modal',
            'ui.bootstrap.modal.dialog',
            'ui.bootstrap.pagination',
            'ui.bootstrap.popover',
            'ui.bootstrap.tabs',
            'ui.bootstrap.tpls',
            'ui.bootstrap.typeahead',
            'ui.bootstrap-slider',
            'ui.calendar',
            'ui.grid',
            'ui.grid.autoResize',
            'ui.grid.expandable',
            'ui.grid.resizeColumns',
            'ui.validate',
            'uuid4',
            'mgcrea.ngStrap.select',
            'pascalprecht.translate',
            'highcharts-ng',
            'frapontillo.bootstrap-switch',
            'tmh.dynamicLocale',
            'templates',
            'ui.tinymce',
            Shared.remeCareSharedModule.name,
            Patient.remeCarePatientModule.name,
            CareRequest.remeCareCareRequestModule.name,
            CarePlanAction.remeCareCarePlanActionModule.name,
            Agenda.remeCareAgendaModule.name,
            Management.remeCareManagementModule.name,
            Inbox.remeCareInboxModule.name,
            Custom.remeCareCustomModule.name,
            SelfService.remeCareSelfServiceModule.name,
            OneTimeLogin.remeCareOneTimeLoginModule.name,
            Connections.remeCareConnectionsModule.name,
            Guidance.remeCareGuidanceModule.name,
            IntegrationMonitor.remeCareIntegrationMonitorModule.name,
        ])
        .config(($provide) => {
            // Override the ScrollEvent to allow scrolling over grids
            $provide.decorator('ScrollEvent', ($delegate) => {
                $delegate.prototype.atTop = (scrollTop) => {
                    return true;
                };

                $delegate.prototype.atBottom = (scrollTop) => {
                    return true;
                };

                return $delegate;
            });
        });

    Bootstrap.remeBootModule.constant('appName', remeCareAppModule.name);
    // Shared.Framework.useAngularModule(remeCareAppModule);
}
