module RemeCare.Patient {
    
    interface IPatientContactModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        name: string;
        cellPhone: string;
        phoneNumber: string;
        email: string;

        confirm(): void;
    }

    class PatientContactModalController extends Shared.Framework.ControllerBase<IPatientContactModalController> {
        constructor(
            $scope: IPatientContactModalController,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {}
    }

    angular.module('RemeCare.Patient').controller('patientContactModalCtrl', (
        $scope: IPatientContactModalController,
        $translate,
        toaster,
        $stateParams,
        $state: ng.ui.IStateService
    ) => new PatientContactModalController(
        $scope,
        $translate,
        toaster,
        $stateParams,
        $state
    ));

}