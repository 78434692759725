module RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;
    import RegistrationValue = RemeCare.Model.RegistrationValue;
    import RegistrationValueFactory = RemeCare.Model.RegistrationValueFactory;
    import CarePlanParameterValue = RemeCare.Contract.CareRequest.Write.ICarePlanParameterValue;

    export class Anamnesis {
        id: Guid;
        careRequestPartLocalIdentity: Guid;
        registrationValue: RegistrationValue;

        constructor(serverObject?: Contract.CareRequest.Read.IAnamnesis) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                this.registrationValue = RegistrationValueFactory.createRegistrationValueFromServer(serverObject.RegistrationValue);
            }
        }
        
        toServerWrite(): CarePlanParameterValue {
            return {
                id: this.id,
                type: this.registrationValue.type === Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity ?
                    Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis : Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis,
                definitionId: this.careRequestPartLocalIdentity,
                registrationValue: this.registrationValue.toServerWrite()
            };
        }
    }
}