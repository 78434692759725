/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestPartType = RemeCare.Shared.Contract.Code.CareRequestPartType;
            var ActorRoleInclusionOption = RemeCare.Shared.Framework.Model.ActorRoleInclusionOption;
            var CareRequestAutoAssignRoleController = /** @class */ (function (_super) {
                __extends(CareRequestAutoAssignRoleController, _super);
                function CareRequestAutoAssignRoleController($scope, $translate, toaster, $rootScope, careRequestSvc, healthCareProfessionalSearchSvc, healthCareOrganisationSearchSvc, partyApiSvc, spinnerSvc, authservice, patientFileForExternalSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.$rootScope = $rootScope;
                    _this.careRequestSvc = careRequestSvc;
                    _this.healthCareProfessionalSearchSvc = healthCareProfessionalSearchSvc;
                    _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                    _this.partyApiSvc = partyApiSvc;
                    _this.spinnerSvc = spinnerSvc;
                    _this.authservice = authservice;
                    _this.patientFileForExternalSvc = patientFileForExternalSvc;
                    // Emit to careRequestor and careRequestRoles components so they can update their state & grids
                    $scope.toggleRole = function (role) {
                        role.isSelected
                            ? _this.$rootScope.$emit('CAREREQUEST_ROLE_SELECT', role)
                            : _this.$rootScope.$emit('CAREREQUEST_ROLE_UNSELECT', role);
                    };
                    // When roles are removed from careRequest/careRequestRoles components, we want to update their checkboxes here
                    _this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECTED', function (event, role, rolePart) {
                        _this.$scope.autoAssignResults.forEach(function (res) {
                            res.matchedRoles.forEach(function (r) {
                                if (r.actorRoleId === role.roleId && r.rolePart === rolePart) {
                                    r.isSelected = false;
                                }
                            });
                        });
                    });
                    return _this;
                }
                CareRequestAutoAssignRoleController.prototype.$onInit = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var autocreateroles, tryAssignCareRequestor, responsibleHcp, responsibleHcpResult, partyRoleId, partyId, _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this.$scope.autoAssignResults = [];
                                    if (this.$scope.careRequest.isReadOnly() ||
                                        this.authservice.getProfile() !== RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider) {
                                        return [2 /*return*/];
                                    }
                                    autocreateroles = 'autocreateroles';
                                    this.spinnerSvc.show(autocreateroles);
                                    _c.label = 1;
                                case 1:
                                    _c.trys.push([1, , 6, 7]);
                                    tryAssignCareRequestor = true;
                                    return [4 /*yield*/, this.getResponsibleHealthCareProfessionalAsync()];
                                case 2:
                                    responsibleHcp = _c.sent();
                                    if (!responsibleHcp) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.autoAssignRoles(responsibleHcp.PartyId, responsibleHcp.PartyRoleId, tryAssignCareRequestor, this.$translate.instant('Views.CareRequest.AutoAssignRole.NamedMessage', {
                                            name: responsibleHcp.FirstName + ' ' + responsibleHcp.LastName,
                                        }))];
                                case 3:
                                    responsibleHcpResult = _c.sent();
                                    this.$scope.autoAssignResults.push(responsibleHcpResult);
                                    tryAssignCareRequestor = !responsibleHcpResult.careRequestorAssigned;
                                    _c.label = 4;
                                case 4:
                                    partyRoleId = this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.partyRoleId);
                                    partyId = this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.partyId);
                                    _b = (_a = this.$scope.autoAssignResults).push;
                                    return [4 /*yield*/, this.autoAssignRoles(partyId, partyRoleId, tryAssignCareRequestor, this.$translate.instant('Views.CareRequest.AutoAssignRole.Message'))];
                                case 5:
                                    _b.apply(_a, [_c.sent()]);
                                    return [3 /*break*/, 7];
                                case 6:
                                    this.spinnerSvc.hide(autocreateroles);
                                    return [7 /*endfinally*/];
                                case 7: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.getResponsibleHealthCareProfessionalAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var searchResult, responsibleHcp, healthCareProfessionalSearchResult, healthCareProfessionalSearchResult;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.patientFileForExternalSvc.getLastPatientFileForExternalAsync()];
                                case 1:
                                    searchResult = _a.sent();
                                    if (searchResult.length !== 1) {
                                        return [2 /*return*/];
                                    }
                                    responsibleHcp = new CareRequest.PatientFileForExternal(searchResult[0]).responsibleHealthCareProfessional;
                                    if (!responsibleHcp) {
                                        return [2 /*return*/];
                                    }
                                    if (!responsibleHcp.HealthProviderId) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.healthCareProfessionalSearchSvc.searchProfessionalsAsync({ strictIdentificationNumber: true, identificationNumber: responsibleHcp.HealthProviderId })];
                                case 2:
                                    healthCareProfessionalSearchResult = _a.sent();
                                    if (healthCareProfessionalSearchResult.Items.length === 1) {
                                        return [2 /*return*/, healthCareProfessionalSearchResult.Items[0]];
                                    }
                                    _a.label = 3;
                                case 3:
                                    if (!responsibleHcp.CitizenId) return [3 /*break*/, 5];
                                    return [4 /*yield*/, this.healthCareProfessionalSearchSvc.searchProfessionalsAsync({ nationalNumber: responsibleHcp.CitizenId })];
                                case 4:
                                    healthCareProfessionalSearchResult = _a.sent();
                                    if (healthCareProfessionalSearchResult.Items.length === 1) {
                                        return [2 /*return*/, healthCareProfessionalSearchResult.Items[0]];
                                    }
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.autoAssignRoles = function (partyId, partyRoleId, includeRequestor, infoMessage) {
                    return __awaiter(this, void 0, void 0, function () {
                        var autoAssignResult, organisationRoles, firstOrganisationRole_1, requestorRoles, requestorRole_1, professionalRoles, firstProfessionalRole_1;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.calculateMatchedRoles(partyId, partyRoleId, includeRequestor, infoMessage)];
                                case 1:
                                    autoAssignResult = _a.sent();
                                    organisationRoles = autoAssignResult.matchedRoles.filter(function (mr) { return mr.partyRoleType === CareRequest.PartyRoleType.HealthCareOrganisation; });
                                    if (organisationRoles.length === 1) {
                                        firstOrganisationRole_1 = organisationRoles[0];
                                        firstOrganisationRole_1.isSelected = true;
                                        this.$scope.toggleRole(firstOrganisationRole_1);
                                        autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(function (r) { return r !== firstOrganisationRole_1; });
                                    }
                                    requestorRoles = autoAssignResult.matchedRoles.filter(function (r) { return r.rolePart === CareRequest.RolePart.CareRequestorRole; });
                                    if (requestorRoles.length === 1) {
                                        requestorRole_1 = requestorRoles[0];
                                        autoAssignResult.careRequestorAssigned = true;
                                        requestorRole_1.isSelected = true;
                                        this.$scope.toggleRole(requestorRole_1);
                                        // Remove all matched roles with this actor role id, also the ones from careteam
                                        autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(function (r) { return r.actorRoleId !== requestorRole_1.actorRoleId; });
                                    }
                                    professionalRoles = autoAssignResult.matchedRoles.filter(function (mr) { return mr.partyRoleType === CareRequest.PartyRoleType.HealthCareProfessional; });
                                    if (!autoAssignResult.careRequestorAssigned && professionalRoles.length === 1) {
                                        firstProfessionalRole_1 = professionalRoles[0];
                                        firstProfessionalRole_1.isSelected = true;
                                        this.$scope.toggleRole(firstProfessionalRole_1);
                                        autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(function (r) { return r !== firstProfessionalRole_1; });
                                    }
                                    // When re-opening a temp saved care request, go over the matched roles to see if they are already selected
                                    if (this.$scope.careRequest.careRequestRoles && autoAssignResult.matchedRoles) {
                                        autoAssignResult.matchedRoles.forEach(function (matchedRole) {
                                            var foundRoles = _this.$scope.careRequest.careRequestRoles.filter(function (crr) {
                                                return crr.roleId === matchedRole.actorRoleId && crr.partyRoleId === matchedRole.party.PartyRoleId;
                                            });
                                            if (foundRoles && foundRoles.length > 0) {
                                                matchedRole.isSelected = true;
                                            }
                                        });
                                    }
                                    return [2 /*return*/, autoAssignResult];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.calculateMatchedRoles = function (partyId, partyRoleId, includeRequestor, infoMessage) {
                    return __awaiter(this, void 0, void 0, function () {
                        var parts, personDetail, personOrganisation, matchedRoles, _i, parts_1, part, _a, _b, _c, _d, _e;
                        return __generator(this, function (_f) {
                            switch (_f.label) {
                                case 0:
                                    parts = this.$scope.careRequestTemplate.careRequestSetup.careRequestParts;
                                    return [4 /*yield*/, this.partyApiSvc.getPersonDetailsAsync(partyId, false)];
                                case 1:
                                    personDetail = _f.sent();
                                    return [4 /*yield*/, this.getCurrentHealthCareOrganization(personDetail, partyRoleId)];
                                case 2:
                                    personOrganisation = _f.sent();
                                    matchedRoles = [];
                                    _i = 0, parts_1 = parts;
                                    _f.label = 3;
                                case 3:
                                    if (!(_i < parts_1.length)) return [3 /*break*/, 8];
                                    part = parts_1[_i];
                                    if (!(includeRequestor && part.type.Id === CareRequestPartType.CareRequestor)) return [3 /*break*/, 5];
                                    _b = (_a = matchedRoles).push;
                                    return [4 /*yield*/, this.addRequestorRole(part, partyRoleId, personOrganisation, personDetail)];
                                case 4:
                                    _b.apply(_a, [_f.sent()]);
                                    return [3 /*break*/, 7];
                                case 5:
                                    if (!(part.type.Id === CareRequestPartType.CareRequestRole)) return [3 /*break*/, 7];
                                    _d = (_c = matchedRoles.push).apply;
                                    _e = [matchedRoles];
                                    return [4 /*yield*/, this.addCareTeamRoles(part, partyRoleId, personOrganisation, personDetail)];
                                case 6:
                                    _d.apply(_c, _e.concat([(_f.sent())]));
                                    _f.label = 7;
                                case 7:
                                    _i++;
                                    return [3 /*break*/, 3];
                                case 8:
                                    matchedRoles = matchedRoles.filter(function (r) { return !!r; });
                                    return [2 /*return*/, {
                                            personDetail: personDetail,
                                            personOrganisation: personOrganisation,
                                            matchedRoles: matchedRoles,
                                            careRequestorAssigned: false,
                                            infoMessage: infoMessage,
                                        }];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.addRequestorRole = function (part, partyRoleId, personOrg, personDetail) {
                    return __awaiter(this, void 0, void 0, function () {
                        var actorRoleInclusionDetails, partyRoleIds, roleName, _a, org, ts_1, query, orgRole;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    actorRoleInclusionDetails = this.getActorRoleInclusionDetails(part.roleRequestingHealthCareProfessional.actorRoleInclusions);
                                    partyRoleIds = actorRoleInclusionDetails
                                        .map(function (arid) { return arid.partyRoleId; })
                                        .filter(function (id) { return id != null; })
                                        .value();
                                    roleName = part
                                        .roleRequestingHealthCareProfessional.name;
                                    _a = part.roleRequestingHealthCareProfessional.radioSelection;
                                    switch (_a) {
                                        case ActorRoleInclusionOption.Individuals: return [3 /*break*/, 1];
                                        case ActorRoleInclusionOption.Specialties: return [3 /*break*/, 3];
                                    }
                                    return [3 /*break*/, 5];
                                case 1:
                                    if (!_.contains(partyRoleIds, partyRoleId)) {
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, this.actorMeetsOrganisationRole(part.roleRequestingHealthCareOrganisation, personOrg)];
                                case 2:
                                    org = _b.sent();
                                    return [2 /*return*/, this.createMatchedRole({
                                            roleName: roleName,
                                            displayName: roleName + ' (' + this.$translate.instant('Views.Patient.Search.Requestor') + ')',
                                            partyRoleType: CareRequest.PartyRoleType.HealthCareProfessional,
                                            rolePart: CareRequest.RolePart.CareRequestorRole,
                                            actorRoleId: part
                                                .roleRequestingHealthCareProfessional.actorRoleId,
                                            party: this.getFromPersonDetail(personDetail, partyRoleId),
                                            organization: org,
                                        })];
                                case 3:
                                    ts_1 = [];
                                    part.roleRequestingHealthCareProfessional.actorRoleInclusions.forEach(function (role) {
                                        role.actorRoleInclusionDetails.forEach(function (detail) {
                                            ts_1.push({
                                                healthCareProfessionalTypeId: detail.healthCareProfessionalTypeId,
                                                healthCareSpecialtyProfessionId: detail.healthCareSpecialtyProfessionId,
                                            });
                                        });
                                    });
                                    if (ts_1.length === 0) {
                                        return [2 /*return*/];
                                    }
                                    query = {
                                        partyRoleId: partyRoleId,
                                        page: 1,
                                        pageSize: 50,
                                        typeAndSpecialties: ts_1,
                                    };
                                    return [4 /*yield*/, this.actorMeetsOrganisationRole(part.roleRequestingHealthCareOrganisation, personOrg)];
                                case 4:
                                    orgRole = _b.sent();
                                    return [2 /*return*/, this.addHealthCareProfessionalIfConditionsAreMet(query, roleName, roleName + ' (' + this.$translate.instant('Views.Patient.Search.Requestor') + ')', CareRequest.RolePart.CareRequestorRole, part.roleRequestingHealthCareProfessional
                                            .actorRoleId, orgRole)];
                                case 5: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.addCareTeamRoles = function (part, partyRoleId, personOrg, personDetail) {
                    return __awaiter(this, void 0, void 0, function () {
                        var result, _loop_1, this_1, _i, _a, conf, state_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    result = [];
                                    _loop_1 = function (conf) {
                                        var actorRoleInclusionDetails, partyRoleIds, _a, org, ts_2, query, _b, _c;
                                        return __generator(this, function (_d) {
                                            switch (_d.label) {
                                                case 0:
                                                    if (!conf.isVisible) {
                                                        return [2 /*return*/, "continue"];
                                                    }
                                                    actorRoleInclusionDetails = this_1.getActorRoleInclusionDetails(conf.role.actorRoleInclusions);
                                                    partyRoleIds = actorRoleInclusionDetails
                                                        .map(function (arid) { return arid.partyRoleId; })
                                                        .filter(function (id) { return id != null; })
                                                        .value();
                                                    _a = conf.role.radioSelection;
                                                    switch (_a) {
                                                        case ActorRoleInclusionOption.Individuals: return [3 /*break*/, 1];
                                                        case ActorRoleInclusionOption.OrganisationTypes: return [3 /*break*/, 2];
                                                        case ActorRoleInclusionOption.Specialties: return [3 /*break*/, 4];
                                                    }
                                                    return [3 /*break*/, 6];
                                                case 1:
                                                    if (_.contains(partyRoleIds, partyRoleId)) {
                                                        result.push(this_1.createMatchedRole({
                                                            roleName: conf.role.name,
                                                            displayName: conf.role.name,
                                                            partyRoleType: CareRequest.PartyRoleType.HealthCareProfessional,
                                                            rolePart: CareRequest.RolePart.CareTeamRole,
                                                            actorRoleId: conf.role.actorRoleId,
                                                            party: this_1.getFromPersonDetail(personDetail, partyRoleId),
                                                            organization: personOrg,
                                                        }));
                                                    }
                                                    return [3 /*break*/, 6];
                                                case 2: return [4 /*yield*/, this_1.actorMeetsOrganisationRole(conf.role, personOrg)];
                                                case 3:
                                                    org = _d.sent();
                                                    if (org) {
                                                        result.push(this_1.createMatchedRole({
                                                            roleName: conf.role.name,
                                                            displayName: conf.role.name,
                                                            partyRoleType: CareRequest.PartyRoleType.HealthCareOrganisation,
                                                            rolePart: CareRequest.RolePart.CareTeamRole,
                                                            actorRoleId: conf.role.actorRoleId,
                                                            party: personOrg,
                                                            organization: org,
                                                        }));
                                                    }
                                                    return [3 /*break*/, 6];
                                                case 4:
                                                    ts_2 = [];
                                                    conf.role.actorRoleInclusions
                                                        .filter(function (inclusion) {
                                                        return inclusion.partyRoleType ===
                                                            RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional;
                                                    })
                                                        .forEach(function (inclusion) {
                                                        inclusion.actorRoleInclusionDetails.forEach(function (detail) {
                                                            ts_2.push({
                                                                healthCareProfessionalTypeId: detail.healthCareProfessionalTypeId,
                                                                healthCareSpecialtyProfessionId: detail.healthCareSpecialtyProfessionId,
                                                            });
                                                        });
                                                    });
                                                    if (ts_2.length === 0) {
                                                        return [2 /*return*/, { value: void 0 }];
                                                    }
                                                    query = {
                                                        partyRoleId: partyRoleId,
                                                        page: 1,
                                                        pageSize: 50,
                                                        typeAndSpecialties: ts_2,
                                                    };
                                                    _c = (_b = result).push;
                                                    return [4 /*yield*/, this_1.addHealthCareProfessionalIfConditionsAreMet(query, conf.role.name, conf.role.name, CareRequest.RolePart.CareTeamRole, conf.role.actorRoleId, personOrg)];
                                                case 5:
                                                    _c.apply(_b, [_d.sent()]);
                                                    return [3 /*break*/, 6];
                                                case 6: return [2 /*return*/];
                                            }
                                        });
                                    };
                                    this_1 = this;
                                    _i = 0, _a = part.careRequestRoleConfigurations;
                                    _b.label = 1;
                                case 1:
                                    if (!(_i < _a.length)) return [3 /*break*/, 4];
                                    conf = _a[_i];
                                    return [5 /*yield**/, _loop_1(conf)];
                                case 2:
                                    state_1 = _b.sent();
                                    if (typeof state_1 === "object")
                                        return [2 /*return*/, state_1.value];
                                    _b.label = 3;
                                case 3:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 4: return [2 /*return*/, result];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.getCurrentHealthCareOrganization = function (personDetails, partyRoleId) {
                    return __awaiter(this, void 0, void 0, function () {
                        var partyRole, currentRelation, result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    partyRole = personDetails.PartyRoles.filter(function (role) { return role.PartyRoleId === partyRoleId && 'CareProviderRelations' in role; });
                                    if (partyRole.length !== 1) {
                                        return [2 /*return*/, null];
                                    }
                                    currentRelation = partyRole[0].CareProviderRelations.filter(function (relation) {
                                        return moment(relation.ValidFromDate).isBefore(RemeCare.Shared.DateHelper.today()) &&
                                            (relation.ValidUntilDate == null ||
                                                moment(relation.ValidUntilDate).isAfter(RemeCare.Shared.DateHelper.today()));
                                    });
                                    if (currentRelation.length !== 1) {
                                        return [2 /*return*/, null];
                                    }
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                                            partyRoleId: currentRelation[0].HealthCareOrganisation.PartyRoleIds[0],
                                        })];
                                case 1:
                                    result = _a.sent();
                                    if (result.Total === 1) {
                                        return [2 /*return*/, result.Items[0]];
                                    }
                                    else {
                                        return [2 /*return*/, null];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.actorMeetsOrganisationRole = function (role, healthCareOrganisationParty) {
                    return __awaiter(this, void 0, void 0, function () {
                        var orgTypes, results;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!healthCareOrganisationParty) {
                                        return [2 /*return*/, null];
                                    }
                                    if (healthCareOrganisationParty.PartyRoleId === null) {
                                        return [2 /*return*/, null];
                                    }
                                    if (role !== null && role.actorRoleInclusions !== null) {
                                        orgTypes = _(role.actorRoleInclusions)
                                            .chain()
                                            .map(function (ari) { return ari.actorRoleInclusionDetails; })
                                            .flatten()
                                            .map(function (arid) {
                                            if (arid.healthCareOrganisationTypeId === null) {
                                                return null;
                                            }
                                            else {
                                                return arid.healthCareOrganisationTypeId.toString();
                                            }
                                        })
                                            .value()
                                            .filter(function (item) {
                                            return item != null;
                                        });
                                    }
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                                            organisationTypes: orgTypes,
                                            partyRoleId: healthCareOrganisationParty.PartyRoleId,
                                        })];
                                case 1:
                                    results = _a.sent();
                                    if (results.Total === 1) {
                                        return [2 /*return*/, results.Items[0]];
                                    }
                                    return [2 /*return*/, null];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.getActorRoleInclusionDetails = function (inclusions) {
                    return _(inclusions)
                        .chain()
                        .map(function (ari) { return ari.actorRoleInclusionDetails; })
                        .flatten();
                };
                CareRequestAutoAssignRoleController.prototype.getFromPersonDetail = function (personDetail, partyRoleId) {
                    return {
                        FirstName: personDetail.FirstName,
                        LastName: personDetail.LastName,
                        PartyRoleId: partyRoleId,
                        MobileNumber: personDetail.MobileNumbers[0],
                        PhoneNumber: personDetail.TelephoneNumbers[0],
                        Email: (personDetail.EmailAddresses[0] && personDetail.EmailAddresses[0].Email) || null,
                        AddressLine: (personDetail.Addresses[0] && personDetail.Addresses[0].AddressLine1) || null,
                        City: (personDetail.Addresses[0] && personDetail.Addresses[0].City) || null,
                        HealthCareSpecialtyProfessions: [],
                        IdentificationNumber: personDetail.NationalNumber,
                        ZipCode: (personDetail.Addresses[0] && personDetail.Addresses[0].ZipCode) || null,
                        PartyId: personDetail.Id,
                    };
                };
                CareRequestAutoAssignRoleController.prototype.addHealthCareProfessionalIfConditionsAreMet = function (query, roleName, displayName, rolePart, actorRoleId, personOrg) {
                    return __awaiter(this, void 0, void 0, function () {
                        var prof1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.healthCareProfessionalSearchSvc.searchProfessionalsAsync(query)];
                                case 1:
                                    prof1 = _a.sent();
                                    if (prof1.Items.length > 0) {
                                        return [2 /*return*/, this.createMatchedRole({
                                                roleName: roleName,
                                                displayName: displayName,
                                                partyRoleType: CareRequest.PartyRoleType.HealthCareProfessional,
                                                rolePart: rolePart,
                                                actorRoleId: actorRoleId,
                                                party: prof1.Items[0],
                                                organization: personOrg,
                                            })];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestAutoAssignRoleController.prototype.createMatchedRole = function (role) {
                    return __assign({}, role, { isSelected: false });
                };
                return CareRequestAutoAssignRoleController;
            }(Directive.CareRequestPartControllerBase));
            var CareRequestAutoAssignRoleDirective = /** @class */ (function (_super) {
                __extends(CareRequestAutoAssignRoleDirective, _super);
                function CareRequestAutoAssignRoleDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        '$rootScope',
                        'careRequestSvc',
                        'healthCareProfessionalSearchSvc',
                        'healthCareOrganisationSearchSvc',
                        'partyApiSvc',
                        'spinnerSvc',
                        'authservice',
                        'patientFileForExternalSvc',
                        function ($scope, $translate, toaster, $rootScope, careRequestSvc, healthCareProfessionalSearchSvc, healthCareOrganisationSearchSvc, partyApiSvc, spinnerSvc, authservice, patientFileForExternalSvc) {
                            return new CareRequestAutoAssignRoleController($scope, $translate, toaster, $rootScope, careRequestSvc, healthCareProfessionalSearchSvc, healthCareOrganisationSearchSvc, partyApiSvc, spinnerSvc, authservice, patientFileForExternalSvc);
                        },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/careRequestAutoAssignRole.html';
                    return _this;
                }
                return CareRequestAutoAssignRoleDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestAutoAssignRole', function () { return new CareRequestAutoAssignRoleDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
