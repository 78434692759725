namespace RemeCare.CarePlanAction {
    interface ISetDetailsScope extends IComponentScope {
        component: SetDetailsDescriptor;
        address: Contract.CarePlanAction.Read.ILocation;
        possibleActors: Shared.Contract.IEntityTranslation[];
        placeholder: string;
        minDate: Date;
        changeLocation(): void;
        plannedDateTimeFromChanged(): void;
        onTimeChanged(): void;
    }

    class SetDetailsController extends ComponentControllerBase<ISetDetailsScope> {
        // @ngInject
        constructor(
            protected $scope: ISetDetailsScope,
            private readonly $locale: ng.ILocaleService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            $scope.changeLocation = () => this.changeLocation();
            $scope.onTimeChanged = () => this.onTimeChanged();
        }

        public onTimeChanged(): void {
            if (this.$scope.component.hasTime && !this.$scope.component.isWindow) {
                this.$scope.component.value.plannedTimeUntil = this.$scope.component.value.plannedTimeFrom;
            }
        }

        protected init(): void {
            this.onTimeChanged();
            this.$scope.minDate = moment()
                .startOf('day')
                .toDate();
            this.$scope.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
            if (this.$scope.component.hasLocation) {
                if (this.$scope.component.value.hasLocation()) {
                    if (this.$scope.component.value.location.ContactPointUsageId != null) {
                        this.findAddressAsync({
                            contactPointUsageId: this.$scope.component.value.location.ContactPointUsageId,
                        });
                    } else {
                        this.$scope.address = {
                            Street: this.$scope.component.value.location.Street,
                            ZipCode: this.$scope.component.value.location.ZipCode,
                            City: this.$scope.component.value.location.City,
                        };
                    }
                } else if (this.$scope.component.defaultLocation != null) {
                    if (this.$scope.component.defaultLocation.ContactPointUsageTypeId != null) {
                        this.findAddressAndSetLocationAsync({
                            contactPointUsageTypeId: this.$scope.component.defaultLocation.ContactPointUsageTypeId,
                            partyRoleId: this.$scope.carePlanAction.patientId,
                        });
                    } else if (this.$scope.component.defaultLocation.HealthCareOrganisationId != null) {
                        this.findAddressAndSetLocationAsync({
                            partyRoleId: this.$scope.component.defaultLocation.HealthCareOrganisationId,
                        });
                    }
                }
            }
            if (this.$scope.component.hasExecutor) {
                this.findAssignablePartiesAsync();
            }
        }

        private async findAddressAsync(query: Contract.Party.Read.Query.IFindAddressesQuery): Promise<void> {
            try {
                const addressResult = await this.partyApiSvc.findAddressAsync(query);
                this.$scope.address = addressResult.Items[0];
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async findAddressAndSetLocationAsync(
            query: Contract.Party.Read.Query.IFindAddressesQuery
        ): Promise<void> {
            try {
                const addressResult = await this.partyApiSvc.findAddressAsync(query);
                this.setLocation(addressResult.Items[0]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async findAssignablePartiesAsync(): Promise<void> {
            try {
                this.$scope.possibleActors = await this.carePlanApiSvc.findAssignablePartiesAsync(
                    this.$scope.carePlanAction.patientId,
                    this.$scope.component.relatedStructuralTherapyAction.Id
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private changeLocation(): void {
            this.modalBuilderFactory
                .createModalBuilder<Contract.Party.Read.IAddress>()
                .setController('locationCtrl')
                .setTemplateUrl('views/carePlanAction/location/locationModal.html')
                .setScope({
                    patientId: this.$scope.carePlanAction.patientId,
                })
                .setResultCallBack((r) => this.setLocation(r))
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private setLocation(address: Contract.Party.Read.IAddress): void {
            this.$scope.address = address;
            this.$scope.component.value.location = {};
            if (address == null) {
                return;
            }
            if (address.Id != null) {
                this.$scope.component.value.location.ContactPointUsageId = address.Id;
            } else {
                this.$scope.component.value.location.Name = address.AddressLine2;
                this.$scope.component.value.location.Street = address.AddressLine1;
                this.$scope.component.value.location.ZipCode = address.ZipCode;
                this.$scope.component.value.location.City = address.City;
                this.$scope.component.value.location.Remark = address.Remark;
                this.$scope.address = this.$scope.component.value.location;
            }
        }
    }

    class SetDetailsDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/setDetails.html';

        public controller = SetDetailsController;
    }

    angular.module('RemeCare.CarePlanAction').directive('rcSetDetails', () => new SetDetailsDirective());
}
