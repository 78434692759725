var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Filter;
        (function (Filter) {
            RemeCare.remeCareAppModule
                .filter('phone', function () {
                return function (phoneNr) {
                    return new RemeCare.Model.PhoneNumber(phoneNr).toString();
                };
            });
        })(Filter = Shared.Filter || (Shared.Filter = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
