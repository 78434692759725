module RemeCare.Contract.Core.Codes {
    
    export enum DurationUnitCode {
        Hours = 1,
        Days = 2,
        Weeks = 3,
        Months = 4,
        Years = 5,
        Minutes = 6
}
} 