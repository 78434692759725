namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import ExternalReference = RemeCare.Contract.Core.IExternalReference;

    interface IEditExternalReferenceScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        externalReference: ExternalReference;
        form: ng.IFormController;
        confirm(): void;
    }

    class EditExternalReferenceScope extends Shared.Framework.ControllerBase<IEditExternalReferenceScope> {
        private oldValidFrom: Date;

        constructor(
            $scope: IEditExternalReferenceScope,
            $translate,
            toaster,
            private patientId: Guid,
            private patientSvc: PatientService
        ) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {}

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                this.$scope.showErrors = true;
                return;
            }
            this.patientSvc
                .setExternalReference(this.patientId, this.$scope.externalReference)
                .success(() => this.$scope.$close(this.$scope.externalReference))
                .error((e) => this.errorCallback(e));
        }
    }

    remeCarePatientModule.controller(
        'editExternalReferenceCtrl',
        ($scope, $translate, toaster, patientId: Guid, patientSvc: PatientService) =>
            new EditExternalReferenceScope($scope, $translate, toaster, patientId, patientSvc)
    );
}
