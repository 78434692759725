namespace RemeCare.Core.Services {
    import ISearchResult = Shared.Contract.ISearchResult;
    import ISearchHealthCareOrganisationPartyQuery = Contract.HealthCareParty.Read.Query.ISearchHealthCareOrganisationPartyQuery;
    import IHealthCareOrganisationParty = Contract.HealthCareParty.Read.IHealthCareOrganisationParty;
    import Guid = Shared.Contract.Guid;

    export class HealthCareOrganisationSearchSvc extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /**@deprecated*/
        public searchOrganisations(
            query: ISearchHealthCareOrganisationPartyQuery
        ): ng.IHttpPromise<ISearchResult<IHealthCareOrganisationParty>> {
            const url = 'HealthCareParty/Organisation';
            return super.get(url, query, 'organisationSearch');
        }

        public searchOrganisationsAsync(
            query: ISearchHealthCareOrganisationPartyQuery
        ): Promise<ISearchResult<IHealthCareOrganisationParty>> {
            const url = 'HealthCareParty/Organisation';
            return super.getPromise(url, query, 'organisationSearch');
        }

        public getHealthCareOrganisationsAsync(
            ids: Shared.Contract.Guid[]
        ): Promise<Contract.HealthCareParty.Read.IHealthCareOrganisationParty[]> {
            return this.getInParts('healthCareParty/Organisations', ids, 30, (set) => ({
                healthCareOrganisationIds: set,
                pageSize: 30,
            }));
        }
    }

    remeCareAppModule.service('healthCareOrganisationSearchSvc', HealthCareOrganisationSearchSvc);
}
