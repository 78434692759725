var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var InformedConsentHistory = RemeCare.Shared.Framework.Model.InformedConsentHistory;
        var InformedConsentModalController = /** @class */ (function () {
            function InformedConsentModalController(gridBuilderSvc, modalBuilderFactory, toaster, informedConsentSvc, authservice) {
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.toaster = toaster;
                this.informedConsentSvc = informedConsentSvc;
                this.authservice = authservice;
            }
            InformedConsentModalController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.buildGrids();
                        this.informedConsentGrid.setData(this.acceptedInformedConsents);
                        this.initializeAuditTrail();
                        return [2 /*return*/];
                    });
                });
            };
            InformedConsentModalController.prototype.initializeAuditTrail = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var informedConsentHistoryReadModels, auditTrail, historyPerInformedConsent, title, trail, grid, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.informedConsentSvc.getInformedConsentsHistory(this.patientId)];
                            case 1:
                                informedConsentHistoryReadModels = _a.sent();
                                auditTrail = new InformedConsentHistory(informedConsentHistoryReadModels);
                                historyPerInformedConsent = _(auditTrail.parts).groupBy(function (ich) { return ich.informedConsentTitle; });
                                this.auditTrails = [];
                                for (title in historyPerInformedConsent) {
                                    trail = _.sortBy(historyPerInformedConsent[title], function (h) { return h.validFrom; }).reverse();
                                    grid = this.buildAuditGrid();
                                    grid.setData(trail);
                                    this.auditTrails.push({
                                        grid: grid,
                                        name: title
                                    });
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            InformedConsentModalController.prototype.deleteInformedConsentModal = function (informedConsent) {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcDeleteInformedConsentModal')
                    .setBindings({
                    patientName: this.patientName,
                })
                    .setResultCallBack(function (r) { return __awaiter(_this, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.informedConsentSvc.revokePatientInformedConsent(this.patientId, informedConsent.InformedConsentId, r)];
                            case 1:
                                _a.sent();
                                this.informedConsentsToAccept.push(informedConsent);
                                this.initializeAuditTrail();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.acceptedInformedConsents.push(informedConsent);
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })
                    .setDismissCallBack(function () { return _this.acceptedInformedConsents.push(informedConsent); })
                    .build();
            };
            InformedConsentModalController.prototype.buildAuditGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('version', 'General.Version', { width: 70 })
                    .addEnumColumn('patientInformedConsentStateCode', 'Views.PatientFile.InformedConsents.Action', RemeCare.Shared.Framework.ReferenceDataTypes.PatientInformedConsentHistoryType, { width: 100 })
                    .addColumn('stateSetByPartyRole', 'General.By')
                    .addDateColumn('validFrom', 'General.ValidFrom', 'short', { width: 100 })
                    .addDateColumn('validUntil', 'General.ValidUntil', 'short', { width: 100 })
                    .addColumn('revokedByPartyRole', 'Views.PatientFile.InformedConsents.RevokedBy')
                    .addTranslateColumn('revokedRemark', 'General.Remark')
                    .build();
            };
            InformedConsentModalController.prototype.buildGrids = function () {
                var _this = this;
                this.informedConsentGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('TherapyName', 'General.Therapy')
                    .addColumn('Title', 'General.Name')
                    .addColumn('Version', 'General.Version')
                    .addConditionalShowDeleteButtonColumn(function (ic) { return _this.authservice.getProfile() !== RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser; }, function (ic) { return _this.deleteInformedConsentModal(ic); })
                    .build();
            };
            return InformedConsentModalController;
        }());
        Patient.remeCarePatientModule.component('rcInformedConsentModal', {
            bindings: {
                $close: '&',
                $dismiss: '&',
                informedConsentsToAccept: '<',
                acceptedInformedConsents: '<',
                informedConsents: '<',
                patientId: '<',
                patientName: '<',
            },
            controller: InformedConsentModalController,
            templateUrl: 'patient/components/informedConsentModal/informedConsentModal.html'
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
