namespace RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    interface IApplicationProfileAssignmentsScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        name: string;
        grid: Shared.Framework.Grid.Grid<IApplicationProfileAssignment>;
        addProfile(): void;
    }

    interface IApplicationProfileAssignment extends Shared.Contract.Read.IApplicationProfileAssignment {
        canEdit: boolean;
    }

    class ApplicationProfileAssignmentsController extends Shared.Framework.ControllerBase<
        IApplicationProfileAssignmentsScope
    > {
        private customTemplate =
            '<div class="text-center vertical-center">' +
            `<button ng-if="row.entity.IsRevoked" ng-disabled="!row.entity.canEdit" ng-click="grid.api.remeCare.handleClick(row.entity)" class="btn btn-primary btn-sm" bs-tooltip data-container="body" data-delay="500,100" data-title="${this.$translate.instant(
                'Views.Management.Persons.AssignApplicationProfile'
            )}">` +
            '<i class="glyphicon glyphicon-plus"></i>' +
            '</button>' +
            `<button ng-if="!row.entity.IsRevoked" ng-disabled="!row.entity.canEdit" ng-click="grid.api.remeCare.handleClick(row.entity)" class="btn btn-primary btn-sm" bs-tooltip data-container="body" data-delay="500,100" data-title="${this.$translate.instant(
                'Views.Management.Persons.RevokeApplicationProfile'
            )}">` +
            '<i class="glyphicon glyphicon-minus"></i>' +
            '</button>' +
            '</div>';

        constructor(
            protected $scope: IApplicationProfileAssignmentsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly person: Model.Person,
            private readonly securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.addProfile = () => this.addProfile();
        }

        public $onInit(): void {
            this.$scope.name = `${this.person.firstName} ${this.person.lastName}`;
            this.buildGrid();
            this.loadDataAsync();
        }

        private buildGrid(): void {
            this.$scope.grid = this.gridBuilderSvc
                .createGridBuilder<IApplicationProfileAssignment>()
                .addBoolColumn('IsRevoked', '', true)
                .addColumn('ApplicationProfile.Text', 'Views.Management.Persons.ApplicationProfile')
                .addColumn('FromDate', 'General.From', { cellFilter: 'date: "shortDate" ' })
                .addColumn('UntilDate', 'General.UntilInclusive', { cellFilter: 'date: "shortDate" ' })
                .addColumn('ModifiedBy', 'General.ModifiedBy')
                .addTranslateColumn('StatusChangeReason', 'General.Reason')
                .addCustomColumn('', this.customTemplate, apa => this.toggleProfileAssignment(apa), { width: 80 })
                .build();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                const [assignableProfiles, applicationProfileAssignments]: [
                    EnumTranslation[],
                    Shared.Contract.Read.IApplicationProfileAssignment[]
                ] = await Promise.all([
                    this.securityApiSvc.findAssignableApplicationProfilesAsync(),
                    this.securityApiSvc.getApplicationProfileAssignmentsAsync(this.person.id),
                ]);

                const mapped = _.map(applicationProfileAssignments, apa => {
                    const casted = apa as IApplicationProfileAssignment;
                    casted.canEdit = _.any(assignableProfiles, p => p.Id === apa.ApplicationProfile.Id);
                    return casted;
                });
                this.$scope.grid.setData(mapped);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private toggleProfileAssignment(profileAssignment: IApplicationProfileAssignment): void {
            if (profileAssignment.IsRevoked) {
                this.assignProfile(profileAssignment.ApplicationProfile);
            } else {
                this.revokeProfile(profileAssignment.ApplicationProfile);
            }
        }

        private getAssignedProfiles(): IApplicationProfileAssignment[] {
            return _(this.$scope.grid.getData()).filter(apa => !apa.IsRevoked);
        }

        private addProfile(): void {
            this.assignProfile();
        }

        private revokeProfile(profile: EnumTranslation): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('revokeApplicationProfileCtrl')
                .setTemplateUrl('views/management/persons/revokeApplicationProfile.html')
                .setResolve({
                    partyId: () => this.person.id,
                    toRevoke: () => profile,
                })
                .setResultCallBack(() => this.loadDataAsync())
                .build();
        }

        private assignProfile(profile?: EnumTranslation): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('assignApplicationProfileCtrl')
                .setTemplateUrl('views/management/persons/assignApplicationProfile.html')
                .setResolve({
                    partyId: () => this.person.id,
                    assignedProfiles: () => this.getAssignedProfiles(),
                    toAssign: () => profile,
                })
                .setResultCallBack(() => this.loadDataAsync())
                .build();
        }
    }

    remeCareManagementModule.controller(
        'applicationProfileAssignmentsCtrl',
        ($scope, $translate, toaster, person, securityApiSvc, gridBuilderSvc, modalBuilderFactory) =>
            new ApplicationProfileAssignmentsController(
                $scope,
                $translate,
                toaster,
                person,
                securityApiSvc,
                gridBuilderSvc,
                modalBuilderFactory
            )
    );
}
