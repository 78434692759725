/// <reference path="componentDirectiveBase.ts"/>

namespace RemeCare.CarePlanAction {
    interface IAdHocAdministrationScope extends IComponentScope {
        component: AdministrationDescriptor;
        options: Shared.Contract.IChoiceListOption[];
        medicationDose: MedicationDose;
        carePlanAction: RemeCare.CarePlanAction.CarePlanAction;
        medicationDoseChanged(): void;
    }

    class AdHocAdministrationController extends ComponentControllerBase<IAdHocAdministrationScope> {
        // @ngInject
        constructor(
            $scope: IAdHocAdministrationScope,
            private toaster: Shared.Framework.Toaster,
            private masterdataSvc: Shared.Framework.MasterdataService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            $scope.medicationDoseChanged = () => this.medicationDoseChanged();
        }

        protected init(): void {
            this.loadData();
        }

        private loadData(): void {
            let pointInTime = this.$scope.carePlanAction.processVersionDate;
            if (pointInTime === undefined) {
                pointInTime = moment.utc().toDate();
            }
            this.masterdataSvc
                .getCodeSetItems(
                    this.$scope.component.value.ChoiceListId,
                    RemeCare.Shared.DateHelper.toServerDateString(pointInTime)
                )
                .success((o) => (this.$scope.options = o))
                .error((m) => this.toaster.error(m));
        }

        private medicationDoseChanged(): void {
            this.$scope.medicationDose = _(this.$scope.component.medicationDoses)
                .chain()
                .filter((md) => md.personalisedTherapyActionId === this.$scope.component.personalisedTherapyActionId)
                .first()
                .value();
        }
    }

    class AdHocAdministrationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/adHocAdministration.html';

        public controller = AdHocAdministrationController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcAdministrationAdhoc', () => new AdHocAdministrationDirective());
}
