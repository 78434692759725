module RemeCare.CareRequest.Directive {

    class CareRequestPartHeaderController implements ng.IComponentController {
        public careRequestPart: Shared.Framework.Model.CareRequestRoleDefinition;

        constructor(private $translate: ng.translate.ITranslateService) {

        }

        public $onInit(): void {}
    }

    RemeCare.CareRequest.remeCareCareRequestModule.component('rcCarerequestPartHeader',
        {
            controller: CareRequestPartHeaderController,
            bindings: {
                careRequestPart: '=',
            },
            templateUrl: 'views/careRequest/directive/careRequestPartHeaderComponent.html'
        });
}