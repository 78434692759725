/// <reference path="./monitoringPartComponentBase.ts"/>

module RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import ChartLine = Shared.Framework.Model.ChartLine;
    import MeasurementsQuery = Contract.Core.Read.Query.IFindMeasurementsQuery;
    import ExternalDataSourceParameter = Shared.Framework.Model.ExternalDataSourceParameter;
    import DateHelper = Shared.DateHelper;
    import IMeasurement = Contract.Core.Read.IMeasurement;

    class LineChartNumericExternalController extends ChartMonitoringPartController {

        private externalDataSourceParameters: Array<ExternalDataSourceParameter>;

        // @ngInject
        constructor(
            $rootScope: ng.IRootScopeService,
            $locale: ng.ILocaleService,
            dateHelper,
            spinnerSvc: Shared.Framework.Service.SpinnerService,
            private toaster: Shared.Framework.Toaster,
            private patientSvc: PatientService,
            private $q: ng.IQService,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private telemonitoringApiSvc: Core.Services.TelemonitoringApiSvc) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.chartConfigs = [
                <HighChartsNGConfig>{
                    options: {
                        chart: {
                            animation: false,
                            type: 'line',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'xy',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(),
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(true),
                            shared: true
                        },
                        legend: {
                            enabled: this.showLegend
                        }
                    },
                    series: [],
                    title: {
                        text: null
                    }
                }
            ];

            return this.patientSvc.getPatient(this.patientId)
                .success(p => {
                    this.patientCode = p.PatientNumber.toString();
                    this.externalDataSourceParameters = this.getExternalDataSourceParameters();
                }).catch((e) => {
                    this.toaster.error(e);
                    throw e;
                });
        }

        private getExternalDataSourceParameters(): Array<ExternalDataSourceParameter> {
            return _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter(mps => mps.type === MonitoringPartSourceType.ExternalDataSourceParameter)
                .map(mps => <ExternalDataSourceParameter>mps)
                .value();
        }

        protected async onDateChange(): Promise<void> {
            this.errorMessage = null;
            try {
                const graphPromises = _(this.externalDataSourceParameters)
                    .map(edsp => this.createGraph(edsp));
                const graphs = await this.$q.all(graphPromises);
                this.configureChart(graphs);
            } catch (error) {
                this.toaster.error(error);
                this.errorMessage = error;
            }
        }

        private createGraph(externalDataSourceParameter: ExternalDataSourceParameter): ng.IPromise<Model.NumberGraph> {
            var query = <MeasurementsQuery>{
                parameterCode: externalDataSourceParameter.externalCharacteristicReference,
                context: externalDataSourceParameter.externalContextReference,
                patientCode: this.patientCode,
                from: DateHelper.toServerDateString(this.dateInfo.fromDate),
                until: DateHelper.toServerDateString(this.getUntilDate())
            }
            return this.telemonitoringApiSvc.findMeasurements(query)
                .then(m => {
                    var graph = this.measurementsToGraph(externalDataSourceParameter, m.data);
                    var externalNumericGraph = new Model.NumberGraph(graph);
                    return externalNumericGraph;
                }).catch(e => {
                    return this.$q.reject(e.data);
                });
        }

        private measurementsToGraph(externalDataSourceParameter: ExternalDataSourceParameter, measurements: Array<IMeasurement>):
            Contract.Core.IGraph<number> {
            var graph = <Contract.Core.IGraph<number>>{
                Scale: {
                    Id: externalDataSourceParameter.unit,
                    Text: externalDataSourceParameter.unit
                },
                Subject: {
                    Id: externalDataSourceParameter.labelCharacteristic,
                    Text: externalDataSourceParameter.labelCharacteristic
                },
                GraphPoints: _(measurements).map(m => {
                    return <Contract.Core.IGraphPoint<string, number>>{
                        X: m.RegistrationDateTime,
                        Y: m.Value
                    }
                })
            }
            return graph;
        }

        private configureChart(graphs: Array<Model.NumberGraph>): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig();
            var yAxes = this.getYAxis(graphs);
            this.chartConfigs[0].options.yAxis = yAxes;
            this.chartConfigs[0].series = _(graphs).map(g => this.getDataSeries(g, yAxes));
            this.chartConfigs[0].loading = false;
        }

        private getYAxis(graphs: Array<Model.NumberGraph>): Array<HighchartsAxisOptions> {
            var units = _(graphs).chain().map(g => g.scale).uniq(false, s => s != null ? s.Id : null).value();
            var axes = _(units).map(u => {
                var max = _(graphs).chain()
                    .filter(g => (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id))
                    .map(g => this.getMaxYScaleValue(g))
                    .max()
                    .value();
                var min = _(graphs).chain()
                    .filter(g => (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id))
                    .map(g => this.getMinYScaleValue(g))
                    .min()
                    .value();
                return <HighchartsAxisOptions>{
                    title: {
                        text: u != null ? u.Text : null
                    },
                    max: max,
                    min: min,
                    id: u != null && u.Id != null ? <string>u.Id : 'unscaled'
                }
            });
            const groupedAxes = _(axes).chain().groupBy(a => `${a.min};${a.max}`)
                .map(ag => {
                    return <HighchartsAxisOptions>{
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            text: _(ag).chain().map(a => a.title.text).filter(t => t != null).value().join(' - '),
                            y: -20,
                            x: -27,
                            textAlign: 'left',
                        },
                        max: _(ag).first().max,
                        min: _(ag).first().min,
                        id: _(ag).map(a => a.id).join(';'),
                        startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                        endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                    }
                })
                .each((a, index) => {
                    a.opposite = index % 2 === 1;
                    if (a.opposite) {
                        (a.title as any).textAlign = 'right';
                        a.title.x = 27;
                        a.labels = {
                            align: 'left',
                            x: 0,
                            y: -2,
                        }
                    } else {
                        a.labels = {
                            align: 'right',
                            x: 0,
                            y: -2,
                        }
                    }
                })
                .value();

            if (!groupedAxes[0] || !groupedAxes[0].title.text) {
                this.chartConfigs[0].options.chart.marginLeft = 37;
            }
            if (!groupedAxes[1] || !groupedAxes[1].title.text) {
                this.chartConfigs[0].options.chart.marginRight = 37;
            }

            return groupedAxes;
        }

        private getDataSeries(graph: Model.NumberGraph, yAxes: Array<HighchartsAxisOptions>): HighchartsLineChartSeriesOptions {
            var monitoringPartSource = <MonitoringPartSourceParameter>_.find(this.monitoringPart.monitoringPartSources,
                mps => mps.type === MonitoringPartSourceType.ExternalDataSourceParameter
                && (<ExternalDataSourceParameter>mps).unit === graph.scale.Id
                && (<ExternalDataSourceParameter>mps).labelCharacteristic === graph.subject.Id);

            var chartLine = <ChartLine>monitoringPartSource.sourceParameterRepresentation;
            var id = graph.scale && graph.scale.Id != null ? <string>graph.scale.Id : 'unscaled';
            var yAxis = _.find(yAxes, a => a.id.indexOf(id) >= 0);
            return <HighchartsLineChartSeriesOptions>{
                animation: false,
                data: _(graph.graphPoints).chain().map(gp => this.getDataPoint(gp)).sortBy(gp => gp[0]).value(),
                color: chartLine.colour,
                name: graph.subject.Text,
                lineWidth: chartLine.lineType.Id === Shared.Contract.Code.LineType.Thin ? 1 : 2,
                dashStyle: chartLine.lineType.Id === Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                yAxis: yAxis.id,
                step: false,
                marker: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number>): Array<number> {
            return [moment(graphPoint.x).valueOf(), graphPoint.y];
        }
    }

    class LineChartNumericExternalComponent extends MonitoringPartComponentBase {
        public controller = LineChartNumericExternalController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringLineChartNumericExternal', new LineChartNumericExternalComponent());
}