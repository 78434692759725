module RemeCare.Patient {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import Guid = Shared.Contract.Guid;
    import CarePlan = RemeCare.Model.Careplan;
    import CareRequestTemplate = RemeCare.CareRequest.CareRequestTemplate;
    import CareRequest = RemeCare.CareRequest.CareRequest;

    interface ICareRequestModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlan: CarePlan;

        careRequestTemplate: CareRequestTemplate;
        careRequest: CareRequest;

        readOnly: boolean;
        form: ng.IFormController;
    }


    class CareRequestModalController extends Shared.Framework.ControllerBase<ICareRequestModalController> {
        constructor(
            $scope: ICareRequestModalController,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService,
            private $q: ng.IQService,
            private therapyApiSvc: Core.Services.TherapyApiService,
            private careRequestSvc: RemeCare.CareRequest.CareRequestSvc
        ) {
            super($scope, $translate, toaster);
        }

        public async $onInit() {
            this.$q.all([
                this.therapyApiSvc.getCareRequestTemplate(this.$scope.carePlan.therapyId, this.$scope.carePlan.id),
                this.careRequestSvc.getCareRequest(this.$scope.carePlan.id),
                await this.therapyApiSvc.getTherapyDiseasesAsync(this.$scope.carePlan.therapyId, Shared.Contract.Code.DiseaseType.Comorbidity),
                await this.therapyApiSvc.getTherapyDiseasesAsync(this.$scope.carePlan.therapyId, Shared.Contract.Code.DiseaseType.TreatedDisease)
            ])
            .then((results: any) => {
                this.prepareCareRequest(results[0].data,results[2].data,results[3].data,results[1].data);
            }, e => this.errorCallback(e));
        }

        private prepareCareRequest(careRequestTemplate: Contract.CareRequest.Read.ICareRequestTemplate,
            allowedComorbidities: Shared.Contract.Read.ITherapyDiseaseReadModel[],
            allowedTreatedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[],careRequest?: Contract.CareRequest.Read.ICareRequest): void {
            this.$scope.careRequestTemplate = new CareRequestTemplate(careRequestTemplate);
            this.$scope.careRequest = new CareRequest(careRequest, careRequestTemplate,allowedComorbidities, allowedTreatedDiseases);
            this.$scope.readOnly = true;
        }
    }

    angular.module('RemeCare.Patient').controller('careRequestModalCtrl', CareRequestModalController);

}