/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceAction = Shared.Framework.Model.MonitoringPartSourceAction;

    class TherapyComplianceGraphController extends ChartMonitoringPartController {
        private structuralTherapyActionIds: Shared.Contract.Guid[];
        private registrationPeriod: string = 'registrationPeriod';
        private reminderTime: string = 'reminderTime';
        private configToGraph: { [configKey: string]: Model.TherapyComplianceGraph[] } = {};

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly $filter: ng.IFilterService,
            private readonly patientSvc: PatientService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        public init(): Promise<void> {
            this.structuralTherapyActionIds = _.map(
                this.monitoringPart.monitoringPartSources,
                (mps) => (mps as MonitoringPartSourceAction).structuralTherapyActionId
            );

            if (!this.structuralTherapyActionIds.length) {
                this.toaster.error('Views.PatientFile.Monitoring.TherapyCompliance.ConfigError');
            }

            (Highcharts as any).SVGRenderer.prototype.symbols.cross = (x, y, w, h) => {
                return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
            };
            if ((Highcharts as any).VMLRenderer) {
                (Highcharts as any).VMLRenderer.prototype.symbols.cross = (Highcharts as any).SVGRenderer.prototype.symbols.cross;
            }

            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            try {
                if (!this.structuralTherapyActionIds.length) {
                    return;
                }

                const therapyComplianceGraphs = await this.patientSvc.getTherapyComplianceGraphAsync(
                    this.patientId,
                    this.structuralTherapyActionIds,
                    this.dateInfo.fromDate,
                    this.getUntilDate()
                );
                const graphs = _(therapyComplianceGraphs)
                    .chain()
                    .map((g) => new Model.TherapyComplianceGraph(g))
                    .groupBy((g) => g.subject.Id)
                    .value();
                this.configureCharts(graphs);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        private configureCharts(graphs: Model.TherapyComplianceGraph[][]): void {
            const chartConfigs = _(graphs).map((g) => {
                const config = {
                    options: {
                        chart: {
                            animation: false,
                            height: 200,
                            zoomType: 'xy',
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        title: {
                            text: g[0].subject.Text,
                        },
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        yAxis: {
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                second: '%H',
                                minute: '%H',
                                hour: '%H',
                                day: '%H',
                                week: '%H',
                                month: '%H',
                                year: '%H',
                            },
                            min: this.getMinY(g),
                            max: this.getMaxY(g),
                            startOnTick: false,
                            endOnTick: false,
                            tickInterval: 3600 * 1000, // round on hours
                            title: {
                                text: null,
                            },
                            plotBands: this.getPlotBands(g),
                            plotLines: this.getPlotLines(g),
                            reversed: true,
                        },
                        plotOptions: {
                            animation: false,
                            series: {
                                marker: {
                                    enabled: true,
                                },
                                events: {
                                    legendItemClick: () => {
                                        return false;
                                    },
                                },
                            },
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: this.getSeries(g),
                } as HighChartsNGConfig;
                this.configToGraph[config.options.title.text] = g;
                return config;
            });
            this.chartConfigs = chartConfigs;
        }

        private getMinY(graph: Model.TherapyComplianceGraph[]): number {
            let min = _(graph)
                .chain()
                .map((g) => g.getMinYValue())
                .min((m) => m.valueOf())
                .value()
                .clone();
            min = min.subtract(1, 'hours');
            return min.valueOf();
        }

        private getMaxY(graph: Model.TherapyComplianceGraph[]): number {
            let max = _(graph)
                .chain()
                .map((g) => g.getMaxYValue())
                .max((m) => m.valueOf())
                .value()
                .clone();
            max = max.add(1, 'hours');
            return max.valueOf();
        }

        private getPlotBands(graphs: Model.TherapyComplianceGraph[]): HighchartsPlotBands[] {
            const result = [];
            _(graphs).forEach((graph) => {
                if (graph.registrationFrom && graph.registrationUntil) {
                    result.push({
                        color: 'rgba(255,0,0,0.04)',
                        from: graph.registrationFrom.valueOf(),
                        to: graph.registrationUntil.valueOf(),
                        id: this.registrationPeriod,
                    } as HighchartsPlotBands);
                }
            });
            return result;
        }

        private getPlotLines(graphs: Model.TherapyComplianceGraph[]): HighchartsPlotLines[] {
            const result = [];
            _(graphs).forEach((graph) => {
                if (graph.reminderTime) {
                    result.push({
                        color: 'orange',
                        value: graph.reminderTime.valueOf(),
                        dashStyle: 'longDash',
                        width: 2,
                        id: this.reminderTime,
                    } as HighchartsPlotLines);
                }
                if (graph.preferredTime) {
                    result.push({
                        color: 'green',
                        value: graph.preferredTime.valueOf(),
                        width: 2,
                        id: this.reminderTime,
                    } as HighchartsPlotLines);
                }
            });
            return result;
        }

        private getLegendSeries(): HighchartsIndividualSeriesOptions[] {
            return [
                {
                    animation: false,
                    type: 'scatter',
                    marker: {
                        symbol: 'circle',
                    },
                    color: 'blue',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.RegisteredByPatient'),
                } as HighchartsScatterChartSeriesOptions,
                {
                    animation: false,
                    type: 'scatter',
                    marker: {
                        symbol: 'circle',
                    },
                    color: 'orange',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.RegisteredByMonitor'),
                } as HighchartsScatterChartSeriesOptions,
                {
                    animation: false,
                    type: 'scatter',
                    marker: {
                        symbol: 'cross',
                        lineColor: '#d9534f',
                        lineWidth: 2,
                    },
                    color: '#d9534f',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.NotTaken'),
                } as HighchartsScatterChartSeriesOptions,
                {
                    animation: false,
                    type: 'scatter',
                    marker: {
                        symbol: 'triangle',
                    },
                    color: 'orange',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.NoRegistration'),
                } as HighchartsScatterChartSeriesOptions,
                {
                    animation: false,
                    type: 'line',
                    marker: {
                        enabled: false,
                    },
                    color: 'green',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.PreferredTime'),
                    lineWidth: 2,
                } as HighchartsLineChartSeriesOptions,
                {
                    animation: false,
                    type: 'line',
                    marker: {
                        enabled: false,
                    },
                    color: 'orange',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.ReminderTime'),
                    lineWidth: 2,
                    dashStyle: 'Dash',
                } as HighchartsLineChartSeriesOptions,
                {
                    animation: false,
                    type: 'column',
                    color: 'rgba(255,0,0,0.1)',
                    name: this.$translate.instant('Views.PatientFile.Monitoring.RegistrationWindow'),
                    pointPlacement: 'on',
                } as HighchartsColumnChartSeriesOptions,
            ];
        }

        private getSeries(graphs: Model.TherapyComplianceGraph[]): HighchartsLineChartSeriesOptions[] {
            const that = this;
            const series = this.getLegendSeries();
            _(graphs).forEach((graph) => {
                series.push({
                    animation: false,
                    data: _(graph.graphPoints).map(
                        (gp) =>
                            ({
                                x: moment(gp.x).valueOf(),
                                y: graph.getYValue(gp.y).valueOf(),
                                name: gp.y.CodeSetItem
                                    ? gp.y.CodeSetItem.Text
                                    : this.$translate.instant('Views.PatientFile.Monitoring.NoRegistration'),
                                color: this.getColour(gp.y),
                                marker: {
                                    symbol: this.getSymbol(gp.y),
                                    lineColor: this.getColour(gp.y),
                                    lineWidth: 2,
                                },
                            } as HighchartsDataPoint)
                    ),
                    type: 'line',
                    showInLegend: false,
                    tooltip: {
                        headerFormat: '',
                        // ReSharper disable SuspiciousThisUsage
                        // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                        pointFormatter: function() {
                            return (
                                `${that.$filter('date')(this.x, 'shortDate')} - ${that.$filter('date')(
                                    this.y,
                                    'shortTime'
                                )}<br/>` + `<b>${this.name}</b>`
                            );
                        },
                        // ReSharper restore SuspiciousThisUsage
                        dateTimeLabelFormats: {
                            millisecond: '%A, %b %e, %H:%M:%S.%L',
                            second: '%A, %b %e, %H:%M:%S',
                            minute: '%A, %b %e, %H:%M',
                            hour: '%A, %b %e, %H:%M',
                            day: '%A, %b %e, %Y',
                            week: 'Week from %A, %b %e, %Y',
                            month: '%B %Y',
                            year: '%Y',
                        },
                        xDateFormat: '%Y',
                    },
                    turboThreshold: 0,
                } as HighchartsLineChartSeriesOptions);
            });
            return series;
        }

        private getColour(registration: Contract.CarePlan.Read.ICodeSetItemRegistration): string {
            if (registration.CodeSetItem == null) {
                return 'orange';
            }
            if (registration.CodeSetItem.NumericValue === 0) {
                return 'red';
            }
            if (registration.CodeSetItem.NumericValue === 1 && registration.RegisteredByPatient) {
                return 'blue';
            }
            return 'orange';
        }

        private getSymbol(registration: Contract.CarePlan.Read.ICodeSetItemRegistration): string {
            if (registration.CodeSetItem == null) {
                return 'triangle';
            }
            if (registration.CodeSetItem.NumericValue === 0) {
                return 'cross';
            }
            if (registration.CodeSetItem.NumericValue === 1) {
                return 'circle';
            }
            return 'triangle';
        }
    }

    class TherapyComplianceGraphComponent extends MonitoringPartComponentBase {
        public controller = TherapyComplianceGraphController;

        public templateUrl = 'views/patient/monitoring/dashboard/therapyComplianceGraphs.html';
    }

    remeCarePatientModule.component('rcMonitoringTherapyComplianceGraph', new TherapyComplianceGraphComponent());
}
