var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var ReferenceDateController = /** @class */ (function (_super) {
                __extends(ReferenceDateController, _super);
                function ReferenceDateController($scope, $translate, toaster) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    $scope.confirm = function () { return _this.confirm(); };
                    return _this;
                }
                ReferenceDateController.prototype.$onInit = function () { };
                ReferenceDateController.prototype.confirm = function () {
                    if (this.$scope.form.$invalid) {
                        this.showValidationErrorMessage();
                        return;
                    }
                    this.$scope.$close(this.$scope.referenceDate);
                };
                return ReferenceDateController;
            }(RemeCare.Shared.Framework.ControllerBase));
            RemeCare.remeCareAppModule.controller('referenceDateCtrl', function ($scope, $translate, toaster) { return new ReferenceDateController($scope, $translate, toaster); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
