namespace RemeCare.CarePlanAction {
    interface ICareActScope extends IComponentScope {
        component: CareActDescriptor;
        address: Contract.CarePlanAction.Read.ILocation;
        options: Shared.Contract.IChoiceListOption[];
        plannedText: string;
        possibleActionDurationOptionName: string;
        carePlanAction: RemeCare.CarePlanAction.CarePlanAction;
        rescheduleAction(): void;
    }

    class CareActController extends ComponentControllerBase<ICareActScope> {
        // @ngInject
        constructor(
            protected $scope: ICareActScope,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService,
            private readonly $filter: ng.IFilterService
        ) {
            super($scope, guidanceApiSvc);
            $scope.rescheduleAction = () => this.rescheduleAction();
        }

        protected init(): void {
            this.setPlannedText();
            this.initializeAsync();
            this.loadDataAsync();
            this.setPossibleActionDurationOptionName();
        }

        private async loadDataAsync(): Promise<void> {
            if (!this.$scope.component.choiceListId) {
                return;
            }

            try {
                let pointInTime = this.$scope.carePlanAction.processVersionDate;
                if (pointInTime === undefined) {
                    pointInTime = moment.utc().toDate();
                }
                this.$scope.options = await this.masterdataSvc.getCodeSetItemsAsync(
                    this.$scope.component.choiceListId,
                    RemeCare.Shared.DateHelper.toServerDateString(pointInTime)
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private setPossibleActionDurationOptionName(): void {
            const pado = _.find(
                this.$scope.component.possibleActionDurationOptions,
                (pado) =>
                    pado.Id ===
                    (this.$scope.carePlanAction as RemeCare.CarePlanAction.CarePlanAction)
                        .possibleActionDurationOptionId
            );
            this.$scope.possibleActionDurationOptionName = pado != null ? pado.Text : ' ';
        }
        private setPlannedText(): void {
            const cpa = this.$scope.carePlanAction as RemeCare.CarePlanAction.CarePlanAction;
            if (!this.$scope.carePlanAction || !cpa.plannedDateTimeFrom) {
                this.$scope.plannedText = '';
            }

            const includesTime = this.$scope.carePlanAction.includesEventTime;
            this.$scope.plannedText = this.$filter('date')(
                cpa.plannedDateTimeFrom,
                includesTime ? 'short' : 'shortDate'
            );

            if (!cpa.plannedDateTimeUntil || !includesTime || !this.$scope.carePlanAction.isEventDateTimeRange) {
                return;
            }

            this.$scope.plannedText =
                this.$scope.plannedText + ' - ' + this.$filter('date')(cpa.plannedDateTimeUntil, 'shortTime');
        }

        private async initializeAsync(): Promise<void> {
            if (this.$scope.component.hasLocation) {
                if (this.$scope.carePlanAction.location != null) {
                    if (this.$scope.carePlanAction.location.ContactPointUsageId != null) {
                        try {
                            const r = await this.partyApiSvc.findAddressAsync({
                                page: 1,
                                pageSize: 1,
                                contactPointUsageId: this.$scope.carePlanAction.location.ContactPointUsageId,
                            });
                            this.$scope.address = r.Items[0]
                                ? {
                                      Street: r.Items[0].AddressLine1,
                                      ZipCode: r.Items[0].ZipCode,
                                      City: r.Items[0].City,
                                  }
                                : {};
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    } else {
                        this.$scope.address = this.$scope.carePlanAction.location;
                    }
                }
            }
        }

        private rescheduleAction(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<IScheduleResult>('rescheduleActionCtrl')
                .setBindings({
                    address: this.$scope.address,
                    carePlanAction: this.$scope.carePlanAction,
                    component: this.$scope.component,
                })
                .setResultCallBack((r) => {
                    this.$scope.address = r.address;
                    this.$scope.carePlanAction = r.carePlanAction;
                    this.setPossibleActionDurationOptionName();
                    this.setPlannedText();
                })
                .build();
        }
    }

    class CareActDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/careAct.html';

        public controller = CareActController;
    }

    remeCareCarePlanActionModule.directive('rcCareAct', () => new CareActDirective());
}
