module RemeCare.Model {
    import IPartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;

    export class MergePersonCandidate {
        public personId: Shared.Contract.Guid;
        public partyRoles: Array<IPartyRoleSummary>;
        public carePlans: Array<Contract.Patient.Read.ICareplan>;
        public carePlanNames: string;
        public capacities: string;
        public isMergeEffective: boolean;

        constructor(serverObject: Contract.Party.Read.IMergeCandidatePerson) {
            this.personId = serverObject.PersonId;
            this.partyRoles = serverObject.PartyRoles;
        }
    }
}