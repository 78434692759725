namespace RemeCare.CarePlanAction {
    import Prescription = Patient.Model.Prescription;

    interface IMedicationSchemaScope extends IComponentScope {
        component: MedicationSchemaDescriptor;
        prescriptionChanged(prescription: Prescription): void;
        prescriptionDeleted(prescription: Prescription): void;
    }

    class MedicationSchemaController extends ComponentControllerBase<IMedicationSchemaScope> {
        // @ngInject
        constructor(
            protected $scope: IMedicationSchemaScope,
            private readonly $timeout: ng.ITimeoutService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            this.$scope.prescriptionChanged = (p) => this.prescriptionChanged(p);
            this.$scope.prescriptionDeleted = (p) => this.prescriptionDeleted(p);
        }

        protected init(): void {
            this.$scope.$on('actionSaveError', (event, data: string | string[]) => {
                if (
                    (_.isString(data) && data === 'Care.Validation.Medication.SchemaModified') ||
                    (_.isArray(data) && _(data).any((d) => d === 'Care.Validation.Medication.SchemaModified'))
                ) {
                    // make the medication schema read only and then editable again
                    // so the prescription overview directive will re-initialize
                    this.$scope.readOnly = true;
                    this.$timeout(
                        () => {
                            this.$scope.readOnly = false;
                        },
                        0,
                        true
                    );
                }
            });
        }

        private prescriptionChanged(prescription: Prescription): void {
            this.$scope.component.addOrReplace(prescription);
        }

        private prescriptionDeleted(prescription: Prescription): void {
            this.$scope.component.delete(prescription);
        }
    }

    class MedicationSchemaDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/medicationSchema.html';
        public controller = MedicationSchemaController;
    }

    remeCareCarePlanActionModule.directive('rcMedicationSchema', () => new MedicationSchemaDirective());
}
