var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var RemeCareCareRequestConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareCareRequestConfiguration, _super);
            function RemeCareCareRequestConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareCareRequestConfiguration.prototype.config = function () {
                this.addRoute('careRequests', '/CareRequests', // Be careful changing this route as this is being referenced in the integration API
                'views/shared/general.html', null, ['Views_CareRequest', 'Views_Patient', 'Periodicity'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.careRequest,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'careRequests.search' });
                this.add('careRequests.search', {
                    component: 'careRequestSearch',
                    params: {
                        savedCareRequest: null
                    },
                    url: '/Search',
                }, ['Views_CareRequest', 'Views_Patient'], {
                    context: RemeCare.Shared.Framework.AuthContext.careRequest,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('careRequests.careRequest', {
                    component: 'rcCareRequest',
                    params: {
                        careRequestId: null,
                        existingPatient: null,
                        referringState: null,
                    },
                    url: '/:careRequestId?therapyId',
                }, ['Views_CareRequest', 'Views_Patient', 'Periodicity'], {
                    context: RemeCare.Shared.Framework.AuthContext.careRequest,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
            };
            return RemeCareCareRequestConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        angular
            .module('RemeCare.CareRequest')
            .config(function ($stateProvider) { return new RemeCareCareRequestConfiguration($stateProvider); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
