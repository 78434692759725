var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Therapy;
        (function (Therapy_1) {
            var Therapy = /** @class */ (function () {
                function Therapy() {
                }
                return Therapy;
            }());
            Therapy_1.Therapy = Therapy;
        })(Therapy = Contract.Therapy || (Contract.Therapy = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
