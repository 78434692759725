namespace RemeCare.CarePlanAction {
    interface IMissingRegistrationsScope extends IComponentScope {
        component: MissingRegistrationsDescriptor;
    }

    class MissingRegistrationsController extends ComponentControllerBase<IMissingRegistrationsScope> {
        // @ngInject
        constructor(
            $scope: IMissingRegistrationsScope,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {}
    }

    class MissingRegistrationsDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/missingRegistrations.html';

        public controller = MissingRegistrationsController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcMissingRegistrations', () => new MissingRegistrationsDirective());
}
