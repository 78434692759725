module RemeCare.SelfService {
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import Framework = Shared.Framework;

    class RequestNewPasswordController implements ng.IComponentController {
        public requestSuccess: boolean;
        public userId: string;
        public newPasswordForm: Shared.Framework.Directive.IFormController;
        public languages: Array<EnumCodeTranslation>;

        constructor(
            private selfServiceApi: SelfServiceApiService,
            private masterdataSvc: Framework.MasterdataService,
            private toaster: Shared.Framework.Toaster,
            private languageSvc: Shared.Framework.Service.LanguageService
        ) {}

        public async $onInit(): Promise<void> {
            try {
                const language = await this.masterdataSvc.getInterfaceLanguagesAsync();
                this.languages = _.sortBy(language, lang => {
                    if (lang.Code === 'nl') {
                        return 1;
                    }
                    return -1;
                });
            } catch (exception) {
                this.toaster.error(exception);
            }
        }

        public changeLanguage(lang: string): void {
            this.languageSvc.setLang(lang, false);
        }

        public async requestNewPassword(): Promise<void> {
            if (this.newPasswordForm.$invalid) {
                this.newPasswordForm.showValidationErrorMessage();
                return;
            }
            try {
                await this.selfServiceApi.requestNewPasswordAsync(this.userId);
                this.requestSuccess = true;
            } catch (exception) {
                this.toaster.error(exception);
            }
        }
    }

    remeCareSelfServiceModule.component('rcRequestNewPassword', {
        controller: RequestNewPasswordController,
        templateUrl: 'views/selfService/requestNewPassword.html'
    });
}
