namespace RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import DateHelper = Shared.DateHelper;

    export class GroupAssignment {
        public id: Shared.Contract.Guid;
        public validFromDate: Date;
        public validUntilDate: Date;
        public groupId: Shared.Contract.Guid;
        public partyId: Shared.Contract.Guid;
        public party: RemeCare.Contract.Party.Read.IParty;
        public organisation: RemeCare.Contract.Party.Read.IOrganisation;
        public person: RemeCare.Management.IPerson;
        public name: string;
        public groupName: string;
        public nameAndGroup: string;
        public type: EnumTranslation[];
        public isActive = false;
        public isNew = true;

        constructor(serverObject?: RemeCare.Contract.Management.Read.IGroupAssignment, groupName?: string) {
            if (serverObject) {
                this.id = serverObject.Id;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.groupId = serverObject.GroupId;
                this.partyId = serverObject.PartyId;
                this.organisation = serverObject.Organisation;
                this.person = serverObject.Person ? this.map(serverObject.Person) : null;
                this.name = this.person
                    ? this.person.LastName.concat(`  ${this.person.FirstName}`)
                    : this.organisation.Name;
                this.groupName = groupName;
                this.nameAndGroup = this.name.concat(` - ${this.groupName}`);
                this.isActive = this.validUntilDate == null || moment(this.validUntilDate).isAfter(DateHelper.today());
                this.type = serverObject.Person
                    ? this.person.healthCareProfessionalTypes
                    : [this.organisation.HealthCareOrganisationType];
                this.isNew = false;
            }
        }

        public toServerWrite(): RemeCare.Contract.Management.Write.IGroupAssignment {
            return {
                id: this.id,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
                groupId: this.groupId,
                partyId: this.partyId,
            };
        }

        private map(person: Contract.Party.Read.IPerson): Management.IPerson {
            const result = person as IPerson;
            result.partyRoleTypes = _(person.PartyRoles).map(pr => pr.Type);
            result.healthCareProfessionalTypes = result.PartyRoles.map(pr => pr.HealthCareProfessionalType).filter(
                x => x != null
            );
            result.specialties = _.flatten(
                result.PartyRoles.map(pr => pr.HealthCareProffesionalSpecialties).filter(x => x != null),
                true
            );
            return result;
        }
    }
}
