var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var HealthCareParty = /** @class */ (function () {
            function HealthCareParty(serverParty) {
                if (serverParty != null) {
                    this.partyRoleId = serverParty.PartyRoleId;
                    this.address = serverParty.AddressLine;
                    this.city = serverParty.City;
                    this.zipCode = serverParty.ZipCode;
                    this.country = null;
                }
            }
            return HealthCareParty;
        }());
        Model.HealthCareParty = HealthCareParty;
        var HealthCareProfessional = /** @class */ (function (_super) {
            __extends(HealthCareProfessional, _super);
            function HealthCareProfessional(serverParty) {
                var _this = _super.call(this, serverParty) || this;
                _this.phoneNumber = new Model.PhoneNumber();
                _this.mobileNumber = new Model.PhoneNumber();
                if (serverParty != null) {
                    _this.lastName = serverParty.LastName;
                    _this.firstName = serverParty.FirstName;
                    _this.email = serverParty.Email;
                    _this.identificationNumber = serverParty.IdentificationNumber;
                    _this.phoneNumber = new Model.PhoneNumber(serverParty.PhoneNumber);
                    _this.mobileNumber = new Model.PhoneNumber(serverParty.MobileNumber);
                    _this.healthCareSpecialtyProfessions = serverParty.HealthCareSpecialtyProfessions;
                }
                return _this;
            }
            HealthCareProfessional.prototype.getName = function () {
                return (this.lastName != null ? this.lastName : '') + " " + (this.firstName != null ? this.firstName : '');
            };
            return HealthCareProfessional;
        }(HealthCareParty));
        Model.HealthCareProfessional = HealthCareProfessional;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
