var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestPartType = RemeCare.Shared.Contract.Code.CareRequestPartType;
            var ApplicationProfileType = RemeCare.Shared.Contract.Code.ApplicationProfileType;
            var AutoAssignRole = /** @class */ (function (_super) {
                __extends(AutoAssignRole, _super);
                function AutoAssignRole() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                AutoAssignRole.PartType = -1;
                return AutoAssignRole;
            }(RemeCare.Shared.Framework.Model.CareRequestPart));
            var NumTranslation = /** @class */ (function () {
                function NumTranslation(Id, Text) {
                    this.Id = Id;
                    this.Text = Text;
                }
                return NumTranslation;
            }());
            var CareRequestController = /** @class */ (function () {
                function CareRequestController($compile, $element, authservice, $scope) {
                    this.$compile = $compile;
                    this.$element = $element;
                    this.authservice = authservice;
                    this.$scope = $scope;
                }
                CareRequestController.prototype.$onInit = function () {
                    this.render();
                };
                CareRequestController.prototype.$onChanges = function (changes) {
                    if (changes.careRequestTemplate && !changes.careRequestTemplate.isFirstChange()) {
                        this.render();
                    }
                };
                CareRequestController.prototype.addAutoAssignRole = function (parts) {
                    // Only for care providers
                    var appProfile = this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.applicationProfile);
                    if (appProfile !== ApplicationProfileType.CareProvider.toString()) {
                        return;
                    }
                    // Determine position of requestor part or careteam part
                    var insertAtIndex = _.findIndex(parts, function (part) { return part.type.Id === CareRequestPartType.CareRequestor; });
                    if (insertAtIndex < 0) {
                        insertAtIndex = _.findIndex(parts, function (part) { return part.type.Id === CareRequestPartType.CareRequestRole; });
                    }
                    // If one of the above parts is found, insert the auto assign role part before it
                    if (insertAtIndex >= 0) {
                        var autoAssignRole = new AutoAssignRole();
                        autoAssignRole.type = new NumTranslation(AutoAssignRole.PartType, 'AutoAssignRole');
                        parts.splice(insertAtIndex, 0, autoAssignRole);
                    }
                };
                CareRequestController.prototype.render = function () {
                    var _this = this;
                    var html = '';
                    if (this.careRequestTemplate != null) {
                        if (this.showInactivePartsReadonly) {
                            this.careRequestPartsToUse = _(this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive).filter(function (crp) {
                                return crp.isActive ||
                                    crp instanceof RemeCare.Shared.Framework.Model.QualitativeAnamnesisDefinition;
                            }); // Only for qualitative anamneses we want to show inactives
                        }
                        else {
                            this.careRequestPartsToUse = this.careRequestTemplate.careRequestSetup.careRequestParts;
                        }
                        this.careRequestPartsToUse = _(this.careRequestPartsToUse).sortBy(function (c) { return c.sequence; });
                        this.addAutoAssignRole(this.careRequestPartsToUse);
                        _(this.careRequestPartsToUse).forEach(function (part, index) {
                            html += _this.getDirectiveHtml(part.type.Id, index, _this.careRequestTemplate, _this.careRequest);
                        });
                    }
                    this.$element.html(html).show();
                    this.$compile(this.$element.contents())(this.$scope.$new());
                };
                CareRequestController.prototype.getDirectiveHtml = function (partType, index, template, careRequest) {
                    var directiveName;
                    switch (partType) {
                        case CareRequestPartType.Patient:
                            if (careRequest.matchedPatientId != null && careRequest.patient == null) {
                                directiveName = 'patient-name';
                            }
                            else {
                                directiveName =
                                    template.careRequestStepType.Id === RemeCare.Shared.Contract.Code.CareRequestStepType.Application
                                        ? 'patient-application'
                                        : 'patient-acceptance';
                            }
                            break;
                        case CareRequestPartType.CareRequestor:
                            directiveName =
                                template.careRequestStepType.Id === RemeCare.Shared.Contract.Code.CareRequestStepType.Application
                                    ? 'care-requestor-application'
                                    : 'care-requestor-acceptance';
                            break;
                        case CareRequestPartType.CareRequestRole:
                            directiveName = 'roles';
                            break;
                        case CareRequestPartType.QuantitativeAnamnesis:
                            directiveName = 'quantitative-anamnesis';
                            break;
                        case CareRequestPartType.QualitativeAnamnesis:
                            directiveName = 'qualitative-anamnesis';
                            break;
                        case CareRequestPartType.TherapyOption:
                            directiveName = 'therapy-option';
                            break;
                        case CareRequestPartType.Date:
                            directiveName = 'date';
                            break;
                        case CareRequestPartType.String:
                            directiveName = 'string';
                            break;
                        case CareRequestPartType.RequestDocument:
                            directiveName = 'document';
                            break;
                        case CareRequestPartType.Hospitalisation:
                            directiveName = 'hospitalisations';
                            break;
                        case CareRequestPartType.Consultation:
                            directiveName = 'consultations';
                            break;
                        case CareRequestPartType.MedicationSchemaTherapyAction:
                            directiveName = 'medication-schema';
                            break;
                        case CareRequestPartType.MedicationSchemaOther:
                            directiveName = 'medication-schema';
                            break;
                        case CareRequestPartType.Comorbidities:
                            directiveName = 'comorbidities';
                            break;
                        case CareRequestPartType.TreatedDiseases:
                            directiveName = 'treated-diseases';
                            break;
                        case CareRequestPartType.Signature:
                            directiveName = 'signature';
                            break;
                        case AutoAssignRole.PartType:
                            directiveName = 'auto-assign-role';
                            break;
                        default:
                            return '<p>Part not implemented</p>';
                    }
                    var directiveHtml = "<rc-care-request-" + directiveName + " care-request-template=\"$ctrl.careRequestTemplate\" care-request-part=\"$ctrl.careRequestPartsToUse[" + index + "]\" care-request=\"$ctrl.careRequest\" show-errors=\"$ctrl.showErrors\" step-type=\"" + template.careRequestStepType.Id + "\" read-only=\"$ctrl.careRequest.isReadOnly()\" on-matched-patient-changed=\"$ctrl.onMatchedPatientChanged()\" validate-national-number=\"$ctrl.validateNationalNumber\"></rc-care-request-" + directiveName + ">";
                    return directiveHtml;
                };
                return CareRequestController;
            }());
            CareRequest.remeCareCareRequestModule.component('rcCareRequestCompiler', {
                bindings: {
                    careRequestTemplate: '<',
                    careRequest: '=',
                    showErrors: '=',
                    onMatchedPatientChanged: '&',
                    validateNationalNumber: '<',
                    showInactivePartsReadonly: '<',
                },
                controller: CareRequestController,
            });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
