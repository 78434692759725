/// <reference path="../model/prescription.ts"/>

namespace RemeCare.Patient {
    import FindCarePlansQuery = Contract.Patient.Read.IFindCarePlansQuery;
    import Prescription = Patient.Model.Prescription;

    class PrescriptionOverviewController implements ng.IComponentController {
        public readOnly: boolean;
        public prescriptionsGrid: Shared.Framework.Grid.Grid<Prescription>;
        public gridId: string;
        public allowAdd: boolean;
        public showGrid: boolean;
        public isMonitoring: boolean;
        public isPatientFile: boolean;

        private isAction: boolean;
        private prescriptions: Contract.Patient.Read.IPrescription[];
        private patientId: Shared.Contract.Guid;
        private carePlanId: Shared.Contract.Guid;
        private hideIntraDayConfig: boolean;
        private multiSave: boolean;
        private guidanceIconLocation: number;
        private onPrescriptionChange: (params: { prescription: Prescription }) => void;
        private onPrescriptionDeleted: (params: { prescription: Prescription }) => void;

        private followupTemplate =
            '<div class="ui-grid-cell-contents" bs-tooltip data-container="body" data-delay="500,100" data-html="true" data-title="{{ row.entity.followUpText }}">' +
            '<span class="control-label">' +
            '<i ng-class="{\'glyphicon-eye-close\': !row.entity.isFollowedUp, \'glyphicon-eye-open\': row.entity.isFollowedUp}" class="glyphicon" ><i>' +
            '</span>' +
            '</div>';

        // @ngInject
        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly idService: Shared.Framework.Service.IIdService,
            private readonly uuid4,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientSvc: Patient.PatientService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) {}

        public $onInit(): void {
            this.readOnly = this.prescriptions != null;
            this.hideIntraDayConfig = true;
            this.buildGrid();
            this.showGrid = this.readOnly;
            if (this.readOnly) {
                // Don't await
                this.initializePrescriptionsAsync();
            }
            // Don't await
            this.initializeAllowAddAsync();
        }

        public $onChanges(changes): void {
            if (this.readOnly) {
                return;
            }
            if (changes.carePlanId && this.prescriptionsGrid) {
                this.showGrid = false;
                this.prescriptionsGrid.search();
            }
        }

        public async addPrescriptionAsync(): Promise<void> {
            if (this.readOnly) {
                return;
            }

            try {
                const p = await this.editPrescriptionAsync(null, false);
                if (this.multiSave) {
                    p.executionRight = 15;
                    p.id = this.uuid4.generate();
                    const schema = await this.patientSvc.tokenizeMedicationSchemaAsync(
                        _(p.periodicities).map((per) => per.toWriteModel())
                    );
                    p.renderMedicationSchema(schema, this.$translate);
                    this.prescriptionsGrid.addRow(p);
                    if (this.onPrescriptionChange) {
                        this.onPrescriptionChange({ prescription: p });
                    }
                } else {
                    await this.patientSvc.savePatientPrescriptionAsync(p.toWriteModel());
                    this.prescriptionsGrid.search();
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async initializePrescriptionsAsync(): Promise<void> {
            try {
                const productIds = _(this.prescriptions).map((p) => p.Medication.Id);
                const medications = await this.medicationSearchSvc.getMedicationsAsync(productIds);
                const prescriptions = _(this.prescriptions).map((p) => {
                    const medication = _.find<Shared.Contract.Read.IMedication>(
                        medications,
                        (medication) => medication.ProductId === p.Medication.Id
                    );
                    return new Prescription(p, medication, this.$translate);
                });
                this.prescriptionsGrid.setData(prescriptions);
                this.prescriptionsGrid.totalItems = prescriptions ? prescriptions.length : 0;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async initializeAllowAddAsync(): Promise<void> {
            try {
                const ags = await this.patientSvc.getTherapyBusinessContextRightsAsync(this.patientId);
                const maxRight = _(
                    _(ags).filter((t) =>
                        this.isAction
                            ? t.TherapyBusinessContext ===
                              Shared.Contract.Code.TherapyBusinessContextCode.CarePlanActionMedicationSchema
                            : t.TherapyBusinessContext ===
                              Shared.Contract.Code.TherapyBusinessContextCode.MedicationSchema
                    )
                ).max((t) => t.ExecutionRight).ExecutionRight;
                this.allowAdd =
                    (maxRight & (Shared.Framework.AuthRight.Create | Shared.Framework.AuthRight.Write)) !== 0;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private editPrescriptionAsync(prescription: Prescription, readOnly: boolean): Promise<Prescription> {
            return new Promise<Prescription>((resolve, reject) => {
                this.modalBuilderFactory
                    .createModalBuilder<{
                        action: string;
                        prescription: Prescription;
                    }>()
                    .setTemplateUrl('views/patient/medication/prescriptionModal.html')
                    .setController('prescriptionCtrl')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        readOnly: readOnly,
                        prescription: prescription,
                        hideIntraDayConfig: this.hideIntraDayConfig,
                    })
                    .setResultCallBack(async (p) => {
                        if (this.readOnly) {
                            reject();
                            return;
                        }
                        if (p.action === 'delete') {
                            if (this.multiSave) {
                                const data = _(this.prescriptionsGrid.getData()).filter(
                                    (pr) => pr.id !== p.prescription.id
                                );
                                this.prescriptionsGrid.setData(data);
                                if (this.onPrescriptionDeleted) {
                                    this.onPrescriptionDeleted({ prescription: p.prescription });
                                }
                                reject();
                            } else {
                                await this.patientSvc.deletePatientPrescriptionAsync(
                                    p.prescription.patientId,
                                    p.prescription.id
                                );
                                const data = _(this.prescriptionsGrid.getData()).filter(
                                    (pr) => pr.id !== p.prescription.id
                                );
                                this.prescriptionsGrid.setData(data);
                                reject();
                            }
                        } else if (p.action === 'save') {
                            resolve(p.prescription);
                        } else {
                            reject();
                        }
                    })
                    .setResolve({
                        patientId: () => this.patientId,
                    })
                    .build();
            });
        }

        private buildGrid(): void {
            this.gridId = this.idService.generateId();
            let gridBuilder = this.readOnly
                ? this.gridBuilderSvc.createGridBuilder<Prescription>()
                : this.gridBuilderSvc.createGridBuilder<Prescription>(
                      (page, pageSize, sortField, sortDirection, criteria) =>
                          this.executeSearch(page, pageSize, sortField, sortDirection, criteria)
                  );
            gridBuilder = gridBuilder
                .addConditionallyStyledColumn(
                    'getName()',
                    'General.MedicationName',
                    'medication.IsActive',
                    {
                        red: false,
                    },
                    { enableSorting: false }
                )
                .addActionColumn('info-sign', (p) => this.showMedicationDetailsAsync(p))
                .addColumn('getComposition()', 'General.ActiveSubstance', { enableSorting: false })
                .addColumn('validFromDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                .addColumn('medicationSchema', 'Views.Patient.Medication.MedicationSchema', {
                    width: '*',
                    enableSorting: false,
                })
                .addCustomColumn('', this.followupTemplate, () => {}, { width: '25', enableSorting: false })
                .addColumn('validUntilDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' });
            if (!this.isPatientFile && !this.isMonitoring) {
                gridBuilder = gridBuilder.addConditionalShowEditButtonWithPromiseFunctionColumn(
                    (p) => this.showPrescriptionDetails(p),
                    (p) => this.modifyPrescriptionAsync(p),
                    (p) => !p.canEdit() || (p.executionRight & Shared.Framework.AuthRight.Write) === 0
                );
            }
            this.prescriptionsGrid = gridBuilder.setMultiLine(this.gridId).build();
            this.prescriptionsGrid.pagingOptions.pageSize = 100;

            this.prescriptionsGrid.searchCriteria.validFromDate = Shared.DateHelper.today();
        }

        private async showMedicationDetailsAsync(prescription: Prescription): Promise<void> {
            try {
                const medication = await this.medicationSearchSvc.getMedicationAsync(prescription.medication.ProductId);
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setScope({ medicationInfo: { medication: medication } })
                    .setController('MedicationModalCtrl')
                    .setTemplateUrl('views/patient/medication/medicationModal.html')
                    .setSize('lg')
                    .build();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<Patient.Model.Prescription>> {
            const query = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                validFromDate: criteria.validFromDate,
                validUntilDate: criteria.validUntilDate,
                carePlanId: this.carePlanId,
                isMonitoring: this.isMonitoring,
                isPatientFile: this.isPatientFile,
                isAction: this.isAction,
            } as Contract.Patient.Read.IFindPrescriptionsForPatientQuery;
            if (this.carePlanId != null) {
                try {
                    const carePlans = await this.patientSvc.getCarePlansAsync(this.patientId, {} as FindCarePlansQuery);
                    const carePlan = _.find(carePlans, (cp) => cp.Id === this.carePlanId);
                    const crc = await this.therapyApiSvc.getCareRequestTemplateAsync(carePlan.TherapyId, carePlan.Id);
                    this.showGrid = _(crc.CareRequestSetup.CareRequestParts).any(
                        (crp) =>
                            (crp.Type.Id === Shared.Contract.Code.CareRequestPartType.MedicationSchemaOther ||
                                crp.Type.Id ===
                                    Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction) &&
                            crp.ShowInPatientFile
                    );
                    if (this.showGrid) {
                        return await this.doSearchAsync(query);
                    } else {
                        return {
                            Total: 0,
                            Items: [],
                        };
                    }
                } catch (e) {
                    this.toaster.error(e);
                }
            } else {
                this.showGrid = true;
                try {
                    return await this.doSearchAsync(query);
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }

        private async doSearchAsync(
            query: Contract.Patient.Read.IFindPrescriptionsForPatientQuery
        ): Promise<Shared.Contract.ISearchResult<Patient.Model.Prescription>> {
            try {
                const data = await this.patientSvc.getPatientPrescriptionsAsync(this.patientId, query);
                const productIds = _(data.Items).map((p) => p.Medication.Id);
                const medications = await this.medicationSearchSvc.getMedicationsAsync(productIds);
                const prescriptions = _(data.Items).map((prescription) => {
                    const medication = _.find<Shared.Contract.Read.IMedication>(
                        medications,
                        (m) => m.ProductId === prescription.Medication.Id
                    );
                    return new Patient.Model.Prescription(
                        prescription,
                        medication,
                        this.$translate,
                        this.isPatientFile
                    );
                });

                return {
                    Total: data.Total,
                    Items: prescriptions,
                };
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private showPrescriptionDetails(prescription: Prescription) {
            this.editPrescriptionAsync(prescription, true);
        }

        private async modifyPrescriptionAsync(prescription: Prescription): Promise<Prescription> {
            const originalPrescription = prescription;
            prescription = angular.copy(prescription);
            const prescriptionStartsInFuture = !moment(prescription.validFromDate).isBefore(
                Shared.DateHelper.tomorrow()
            );
            try {
                const p = await this.editPrescriptionAsync(prescription, false);
                try {
                    if (p == null) {
                        return null;
                    } else {
                        if (this.multiSave) {
                            const schema = await this.patientSvc.tokenizeMedicationSchemaAsync(
                                _(p.periodicities).map((per) => per.toWriteModel())
                            );
                            p.renderMedicationSchema(schema, this.$translate);
                            const existingPrescription = _.find(
                                this.prescriptionsGrid.getData(),
                                (pr) =>
                                    pr.id === p.id &&
                                    pr.validFromDate.valueOf() !== p.validFromDate.valueOf() &&
                                    moment(pr.validFromDate).isBefore(Shared.DateHelper.tomorrow())
                            );
                            if (
                                existingPrescription &&
                                existingPrescription.isFollowedUp &&
                                !prescriptionStartsInFuture
                            ) {
                                existingPrescription.validUntilDate = moment(p.validFromDate)
                                    .add(-1, 'days')
                                    .toDate();
                                this.prescriptionsGrid.addRow(p);
                                if (this.onPrescriptionChange) {
                                    this.onPrescriptionChange({ prescription: p });
                                }
                                return existingPrescription;
                            } else {
                                if (this.onPrescriptionChange) {
                                    this.onPrescriptionChange({ prescription: p });
                                }
                                return p;
                            }
                        } else {
                            await this.patientSvc.updatePatientPrescriptionAsync(p.toWriteModel());
                            this.prescriptionsGrid.search();
                            return p;
                        }
                    }
                } catch (e) {
                    this.toaster.error(e);
                    this.prescriptionsGrid.search();
                    return originalPrescription;
                }
            } catch (e) {}
        }
    }

    remeCarePatientModule.component('rcPrescriptionOverview', {
        controller: PrescriptionOverviewController,
        templateUrl: 'views/patient/medication/prescriptionOverview.html',
        bindings: {
            patientId: '<',
            carePlanId: '<?',
            isMonitoring: '<?',
            isPatientFile: '<?',
            multiSave: '<?',
            onPrescriptionChange: '&?',
            onPrescriptionDeleted: '&?',
            prescriptions: '<?',
            guidanceIconLocation: '<',
            uniqueId: '@',
            isAction: '<?', // If these prescriptions are shown as a careplanaction part
        },
    });
}
