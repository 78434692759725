namespace RemeCare.Patient {
    import CarePlan = Contract.Patient.Read.ICareplan;
    import CarePlanMedia = Contract.Patient.Read.ICarePlanMedia;
    import CarePlanMediaQuery = Contract.Patient.Read.IFindCarePlanMediaQuery;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;
    import InformationObjectType = Shared.Contract.Code.InformationObjectType;
    import Grid = Shared.Framework.Grid.Grid;
    import SearchResult = Shared.Contract.ISearchResult;

    class PatientFileMediaController implements ng.IComponentController {
        public grid: Grid<CarePlanMedia>;
        public carePlans: CarePlan[];
        public informationObjectTypes: EnumTranslation[];
        public patientId: Guid;

        constructor(
            private readonly fileIconSvc: Core.Services.FileIconService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientSvc: PatientService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly uiGridConstants: uiGrid.IUiGridConstants,
            private readonly baseUrl: string
        ) {}

        public $onInit(): void {
            this.loadDataAsync();
            this.buildGrid();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [this.carePlans, this.informationObjectTypes] = await Promise.all([
                    this.patientSvc.getCarePlansAsync(this.patientId),
                    this.masterdataSvc.getReferenceDataAsync(Shared.Framework.ReferenceDataTypes.informationObjectType),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            this.grid = this.gridBuilderSvc
                .createGridBuilder<CarePlanMedia>((p, ps, sf, so, c) => this.executeSearchAsync(p, ps, sf, so, c))
                .addColumn('InformationMessage.Name', 'General.Name')
                .addEnumColumn(
                    'InformationMessage.InformationObjectType',
                    'General.Type',
                    Shared.Framework.ReferenceDataTypes.informationObjectType
                )
                .addDateColumn('TimeProvided', 'Media.TimeProvided', 'short', {
                    width: 180,
                    sort: { direction: this.uiGridConstants.DESC },
                })
                .addColumn('ProvidedTo', 'Media.ProvidedTo', { cellFilter: 'delimitedDisplay:", "' })
                .addColumn('CarePlan', 'General.CarePlan', { enableSorting: false })
                .addIconColumn('', (c) => this.getIcon(c), (c) => this.showMedia(c))
                .build();
            this.grid.searchCriteria.types = [InformationObjectType.LinkToFile, InformationObjectType.InScreenVideo];
            this.grid.search();
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): Promise<SearchResult<CarePlanMedia>> {
            const query: CarePlanMediaQuery = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortOrder,
                carePlans: criteria.carePlans,
                types: criteria.types,
                fromDate: Shared.DateHelper.toServerDateString(criteria.fromDate),
                untilDate: Shared.DateHelper.toServerDateString(criteria.untilDate),
            };

            try {
                return await this.patientSvc.findCarePlanMediaAsync(this.patientId, query);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private getIcon(carePlanMedia: CarePlanMedia): string {
            if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.LinkToFile) {
                const extension = carePlanMedia.InformationMessage.Name
                    ? carePlanMedia.InformationMessage.Name.split('.').pop()
                    : null;
                return this.fileIconSvc.getFileIcon(extension);
            } else if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.InScreenVideo) {
                return 'video-camera';
            } else {
                return 'comment-o';
            }
        }

        private showMedia(carePlanMedia: CarePlanMedia): void {
            if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.LinkToFile) {
                window.open(
                    Shared.DocumentHelper.fixDocumentUrl(carePlanMedia.InformationMessage.Url, this.baseUrl),
                    '_blank'
                );
            } else {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/mediaDocuments/informationMessage.html')
                    .setScope({
                        message: carePlanMedia.InformationMessage,
                    })
                    .setSize(
                        carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.Text
                            ? Shared.Framework.Helper.ModalSize.medium
                            : Shared.Framework.Helper.ModalSize.large
                    )
                    .build();
            }
        }
    }

    remeCarePatientModule.component('patientFileMedia', {
        controller: PatientFileMediaController,
        templateUrl: 'views/patient/mediaDocuments/patientFileMedia.html',
        bindings: {
            patientId: '<',
        },
    });
}
