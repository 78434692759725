module RemeCare.Core.Services {

    export class TelemonitoringApiSvc extends Shared.Framework.ApiServiceBase {
        static $inject = ['baseUrl', '$http', 'spinnerSvc'];

        findMeasurements(query: Contract.Core.Read.Query.IFindMeasurementsQuery):
            ng.IHttpPromise<Array<Contract.Core.Read.IMeasurement>> {
            return this.get('Telemonitoring/measurements', query);
        }
    }

    remeCareAppModule.service('telemonitoringApiSvc', TelemonitoringApiSvc);
}