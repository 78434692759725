module RemeCare.Patient {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import Guid = Shared.Contract.Guid;
    import CarePlan = RemeCare.Model.Careplan;

    interface ICarePlanStatusModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlan: CarePlan;
        reasons: Array<EntityTranslation>;
        selectedReason: Guid;

        form: ng.IFormController;

        confirm: () => void;
    }


    class CarePlanStatusModalController extends Shared.Framework.ControllerBase<ICarePlanStatusModalController> {
        constructor(
            $scope: ICarePlanStatusModalController,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private therapyApiSvc: Core.Services.TherapyApiService,
            private $dialog
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit() {
            this.therapyApiSvc.getCarePlanStatusReasons(this.$scope.carePlan.therapyId, Shared.Contract.Code.CarePlanStatus.Stopped)
                .success((ags) => {
                    this.$scope.reasons = ags;
                })
                .error((m) => this.errorCallback(m));
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$dialog.confirmBox('Views.PatientFile.ChangeCarePlanStatus', 'Views.PatientFile.StopCarePlanText',
                () => {
                    this.carePlanApiSvc.changeCarePlanStatus(this.$scope.carePlan.id, this.$scope.selectedReason, Shared.Contract.Code.CarePlanStatus.Stopped)
                        .success(() => {
                            this.toaster.success(this.$translate.instant('Views.PatientFile.ChangeCarePlanStatus.Success'));
                            this.$scope.$close();
                            this.$state.reload();
                        })
                        .error(m => this.errorCallback(m));
                }
            );
        }
    }

    angular.module('RemeCare.Patient').controller('carePlanStatusModalCtrl', (
        $scope: ICarePlanStatusModalController,
        $translate,
        toaster,
        $stateParams,
        $state: ng.ui.IStateService,
        carePlanApiSvc: Core.Services.CarePlanApiService,
        therapyApiSvc: Core.Services.TherapyApiService,
        $dialog
    ) => new CarePlanStatusModalController(
            $scope,
            $translate,
            toaster,
            $stateParams,
            $state,
            carePlanApiSvc,
            therapyApiSvc,
            $dialog
    ));

}