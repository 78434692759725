/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {
    
    interface IStringScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.StringDefinition;
        careRequestString: CareRequestString;
    }

    class StringController extends CareRequestPartControllerBase<IStringScope> {
        
        constructor(
            $scope: IStringScope,
            $translate,
            toaster,
            careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.$scope.careRequestString = _.find(this.$scope.careRequest.careRequestStrings,
                t => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity);
            if (this.$scope.careRequestString == null) {
                this.$scope.careRequestString = new CareRequestString();
                this.$scope.careRequestString.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.$scope.careRequest.careRequestStrings.push(this.$scope.careRequestString);
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }
    }

    class StringDirective extends CareRequestPartDirectiveBase {
        
        controller = ['$scope', '$translate', 'toaster', 'careRequestSvc',
            ($scope, $translate, toaster, careRequestSvc) => new StringController($scope, $translate, toaster, careRequestSvc)];

        templateUrl = 'views/careRequest/directive/string.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestString', () => new StringDirective());
}