namespace RemeCare.Model {
    export class Remark {
        public therapyActionPartId?: Shared.Contract.Guid;
        public remark: string;
        public registeredBy: string;
        public registeredByPartyRoleId?: Shared.Contract.Guid;
        public executionDateTime: Date;
        public actionTypeCode: Shared.Contract.Code.ActionType;

        public title: string;

        constructor(serverObject: Contract.CarePlanAction.Read.IRemark) {
            this.remark = serverObject.Remark;
            this.therapyActionPartId = serverObject.TherapyActionPartId;
            this.registeredByPartyRoleId = serverObject.RegisteredByPartyRoleId;
            this.executionDateTime = Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);
            this.actionTypeCode = serverObject.ActionTypeCode;

            this.registeredBy = Shared.StringHelper.joinNonEmpty(' ', [
                serverObject.RegisteredByFirstName,
                serverObject.RegisteredByLastName,
            ]);

            this.title = `${this.registeredBy}: ${Shared.DateHelper.dateTimeHumanReadible(this.executionDateTime)}`;
        }
    }
}
