namespace RemeCare.Management {
    import Guid = Shared.Contract.Guid;

    export class GlobalActorRoleService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /** @deprecated */
        public searchSummaries(
            query: Contract.Management.Read.Query.IFindGlobalActorRolesQuery
        ): ng.IHttpPromise<Shared.Contract.ISearchResult<Contract.Management.Read.IGlobalActorRoleSummary>> {
            return this.get('GlobalActorRoles', query, 'globalActorRoles.search');
        }

        public searchSummariesAsync(
            query: Contract.Management.Read.Query.IFindGlobalActorRolesQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.Management.Read.IGlobalActorRoleSummary>> {
            return this.getPromise('GlobalActorRoles', query, 'globalActorRoles.search');
        }

        public createGlobalActorRoleAsync(globalActorRole: Contract.Management.Write.IGlobalActorRole): Promise<Guid> {
            return this.postPromise('GlobalActorRoles', globalActorRole) as Promise<Guid>;
        }

        public deleteGlobalActorRoleAsync(id: Guid): Promise<void> {
            return this.deletePromise(`GlobalActorRoles/${id}`);
        }

        public getGlobalActorRoleAsync(id: Guid): Promise<Contract.Management.Read.IGlobalActorRole> {
            return this.getPromise(`GlobalActorRoles/${id}`);
        }

        public addPartyRole(id: Guid, partyRoleId: Guid, partyRoleType: Shared.Contract.Code.PartyRoleType) {
            return this.postPromise(`GlobalActorRoles/${id}/PartyRoles/${partyRoleType}/${partyRoleId}`, null);
        }

        public deletePartyRoleAsync(id: Guid, partyRoleId: Guid): Promise<{}> {
            return this.deletePromise(`GlobalActorRoles/${id}/PartyRoles/${partyRoleId}`);
        }

        public saveRemarkAsync(id: Guid, remark: string): Promise<{}> {
            return this.postPromise(`GlobalActorRoles/${id}/Remark`, {
                remark: remark,
            });
        }

        public saveTranslationsAsync(id: Guid, translations: Shared.Contract.ITranslationWrite[]): Promise<Guid> {
            return this.postPromise(
                `GlobalActorRoles/${id}/Translations`,
                {
                    translations: translations,
                },
                'globalActorRoleTranslations'
            ) as Promise<Guid>;
        }

        public setActiveAsync(id: Guid, isActive: boolean): Promise<{}> {
            return this.postPromise(`GlobalActorRoles/${id}/Active/${isActive}`, null);
        }
    }

    remeCareManagementModule.service('globalActorRoleSvc', GlobalActorRoleService);
}
