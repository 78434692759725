namespace RemeCare.Patient {
    import CareTeamParty = Contract.HealthCareParty.Read.ICareTeamParty;
    import CareTeamPartyAssignment = Contract.HealthCareParty.Read.ICareTeamPartyAssignment;
    import SearchResult = Shared.Contract.ISearchResult;

    export interface ITherapyActorRole extends Shared.Contract.Read.IActorRole {
        Therapy: Shared.Contract.IEntityTranslation;
        Text: string;
    }

    export abstract class CareTeamPartyBaseController implements ng.IComponentController {
        public actorRoles: ITherapyActorRole[];
        public partyOverviewGrid: Shared.Framework.Grid.Grid<CareTeamParty>;
        public partyAssignmentGrid: Shared.Framework.Grid.Grid<CareTeamPartyAssignment>;
        public selectedParty: CareTeamParty;
        public hasMoreParties: boolean;
        protected patientId: Shared.Contract.Guid;
        protected editRight: boolean;
        protected careTeamsCanEdit: Contract.Core.Read.ITherapyBusinessContextRightConfiguration[];
        protected onlyActive: boolean;

        // @ngInject
        constructor(
            protected $scope: ng.IScope,
            protected $translate: ng.translate.ITranslateService,
            protected $filter: ng.IFilterService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: PatientService,
            protected authservice: Shared.Framework.AuthService,
            protected partyApiSvc: Core.Services.PartyApiService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public $onInit(): void {
            this.init();
            this.$scope.$watch(
                () => this.onlyActive,
                () => {
                    this.partyOverviewGrid.search();
                }
            );
        }

        public hasActorRoles(): boolean {
            return _(this.actorRoles).any();
        }

        public addCareTeamParty(ar: ITherapyActorRole): void {
            const partyRoleIds = this.getPartyRoleIds(ar);
            if (partyRoleIds.length > 0) {
                this.showPartySelector(ar, this.getActorRoleInclusionDetails(ar).value());
            } else {
                this.showPartySearchModal(ar);
            }
        }

        public criteriaChanged(): void {
            if (!this.partyOverviewGrid) {
                return;
            }
            this.partyOverviewGrid.pagingOptions.currentPage = 1;
            this.partyOverviewGrid.search();
        }

        public getActiveCarePlans(party: CareTeamParty): string {
            return _(
                party.Assignments.filter(
                    (as) => as.CarePlan.Status.Id === Shared.Contract.Code.CarePlanStatus.Open
                ).map((a) => a.CarePlan.Name)
            )
                .unique()
                .join(', ');
        }

        protected abstract init(): void;

        protected buildGrids(): void {
            this.buildOverviewGrid();
        }

        protected abstract buildOverviewGrid(): void;

        protected isActiveAssignment(assignment: CareTeamPartyAssignment): boolean {
            return (
                assignment.CarePlan.Status.Id === Shared.Contract.Code.CarePlanStatus.Open &&
                ((moment(assignment.ValidFromDate).isBefore(moment()) && assignment.ValidUntilDate == null) ||
                    moment(assignment.ValidUntilDate).isAfter(moment()))
            );
        }

        protected showPartyAssignments(party: CareTeamParty, partyType: Shared.Contract.Code.PartyType): void {
            this.selectedParty = party;

            if (party) {
                this.modalBuilderFactory
                    .createComponentModalBuilder<string>('rcPartyAssigmentsModal')
                    .setBindings({
                        party: party,
                        partyType: partyType,
                        patientId: this.patientId,
                        editRight: this.editRight,
                        careTeamsCanEdit: this.careTeamsCanEdit,
                    })
                    .setResultCallBack((r) => {})
                    .setLarge()
                    .build();
            }
        }

        protected searchCareTeamAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any,
            partyType: Shared.Contract.Code.PartyType,
            filterFun: (p: CareTeamParty) => boolean
        ): Promise<SearchResult<CareTeamParty>> {
            return new Promise<SearchResult<CareTeamParty>>(async (resolve, reject) => {
                try {
                    const query: Contract.HealthCareParty.Read.Query.ISearchCareTeamQuery = {
                        page,
                        pageSize,
                        sortField,
                        sortOrder,
                        excludeStopped: false,
                        excludeHealthCareNonProfessional: true,
                    };
                    const careTeam = await this.patientSvc.getCareTeamAsync(this.patientId, partyType, query);
                    if (filterFun) {
                        careTeam.Items = careTeam.Items.filter(filterFun);
                    }
                    careTeam.Total = careTeam.Items.length;
                    this.hasMoreParties = careTeam.Total > 1;
                    resolve(careTeam);
                } catch (e) {
                    this.toaster.error(e);
                    reject();
                }
            });
        }

        protected showCareTeamAssignmentModalAsync(
            careTeamPartyAssignment: CareTeamPartyAssignment,
            partyRoleId: Shared.Contract.Guid,
            firstName: string,
            lastName: string
        ): Promise<CareTeamPartyAssignment> {
            return new Promise<CareTeamPartyAssignment>((resolve) => {
                const nameArray = [];
                if (firstName != null) {
                    nameArray.push(firstName);
                }
                if (lastName != null) {
                    nameArray.push(lastName);
                }
                const name = nameArray.join(' ');
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('editCareTeamPartyCtrl')
                    .setTemplateUrl('views/patient/careTeam/editCareTeamPartyAssignment.html')
                    .setResolve({
                        patientId: () => this.patientId,
                    })
                    .setScope({
                        partyRoleId,
                        name,
                        careTeamParty: angular.copy(careTeamPartyAssignment),
                        readOnly: !(careTeamPartyAssignment.CanEdit && this.editRight),
                    })
                    .setResultCallBack(() => {
                        this.partyOverviewGrid.search();
                        resolve(careTeamPartyAssignment);
                    })
                    .build();
            });
        }

        protected deleteCareTeamPartyAsync(careTeamPartyAssignment: CareTeamPartyAssignment): Promise<boolean> {
            return new Promise<boolean>(async (resolve) => {
                try {
                    await this.patientSvc.removeActorRoleAssignmentAsync(
                        this.patientId,
                        careTeamPartyAssignment.CarePlan.TherapyId,
                        careTeamPartyAssignment.Id
                    );
                    resolve(true);
                    this.removeSelected();
                } catch (e) {
                    this.toaster.error(e);
                    resolve(false);
                }
            });
        }

        protected removeSelected(): void {
            this.selectedParty = null;
            this.partyAssignmentGrid.setData(null);
        }

        protected getPartyRoleIds(actorRole: ITherapyActorRole): Shared.Contract.Guid[] {
            return this.getActorRoleInclusionDetails(actorRole)
                .map((arid) => arid.PartyRoleId)
                .filter((id) => id != null)
                .value();
        }

        protected getActorRoleInclusionDetails(
            actorRole: ITherapyActorRole
        ): _Chain<Shared.Contract.Read.IActorRoleInclusionDetail> {
            return _(actorRole.ActorRoleInclusions)
                .chain()
                .map((ari) => ari.ActorRoleInclusionDetails)
                .flatten();
        }

        protected createCareTeamParty(
            actorRole: ITherapyActorRole,
            hcParty: Contract.HealthCareParty.Read.IHealthCareParty
        ): CareTeamParty {
            return {
                PartyRoleId: hcParty.PartyRoleId,
                Assignments: [
                    {
                        CarePlan: {
                            TherapyId: actorRole.Therapy.Id,
                            Name: actorRole.Therapy.Text,
                        },
                        ActorRole: {
                            Id: actorRole.ActorRoleId,
                            Text: actorRole.Name,
                        },
                        CanEdit: true,
                    },
                ],
            } as CareTeamParty;
        }

        protected abstract showPartySelector(
            actorRole: ITherapyActorRole,
            organisation: Shared.Contract.Read.IActorRoleInclusionDetail[]
        ): void;

        protected abstract showPartySearchModal(actorRole: ITherapyActorRole): void;
    }
}
