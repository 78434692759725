namespace RemeCare.Patient {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import administrationMeansCode = Contract.Core.Codes.AdministrationMeansCode;
    import PrescribedAdministrationInformation = Patient.Model.PrescribedAdministrationInformation;
    import durationUnitCodes = Contract.Core.Codes.DurationUnitCode;

    interface IAdministrationInformationModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        durations: EnumTranslation[];
        speedUnits: EnumTranslation[];
        solventToAddUnits: EnumTranslation[];
        administrationMethods: EnumTranslation[];
        prescription: Model.Prescription;
        medicationInfo: { medication: Shared.Contract.Read.IMedication };
        extraInformationForm: ng.IFormController;

        confirm(): void;
        needsSolution(): boolean;
        needsExtraAdministrationInfo(): boolean;
        hasAdministrationInformation(): boolean;
        changed(): void;
        delete(): void;
    }

    class AdministrationInformationModalController extends Shared.Framework.ControllerBase<
        IAdministrationInformationModalScope
    > {
        constructor(
            protected $scope: IAdministrationInformationModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($scope, $translate, toaster);
            $scope.needsSolution = () => this.needsSolution();
            $scope.needsExtraAdministrationInfo = () => this.needsExtraAdministrationInfo();
            $scope.confirm = () => this.confirm();
            $scope.hasAdministrationInformation = () => this.hasAdministrationInformation();
            $scope.delete = () => this.delete();
            $scope.changed = () => this.changed();
        }

        public $onInit(): void {
            this.loadDataAsync();
            if (this.$scope.prescription.prescribedAdministrationInformation == null) {
                this.$scope.prescription.prescribedAdministrationInformation = new PrescribedAdministrationInformation(
                    null
                );
            }

            if (
                this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans == null ||
                this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans < 1
            ) {
                this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans = this.$scope.medicationInfo.medication.DefaultAdministrationMeans.Id;
            }
        }

        public needsSolution(): boolean {
            if (this.$scope.prescription.prescribedAdministrationInformation != null) {
                if (
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        administrationMeansCode.Intravenous ||
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        Contract.Core.Codes.AdministrationMeansCode.Subcutaneous ||
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        administrationMeansCode.Other
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        }

        public confirm(): void {
            if (this.$scope.extraInformationForm.$invalid) {
                this.$scope.extraInformationForm.$setSubmitted();
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.prescription);
        }

        public delete(): void {
            this.$scope.prescription.prescribedAdministrationInformation = null;
            this.$scope.prescription.solventToAddInformation = null;
            this.$scope.$close(this.$scope.prescription);
        }

        public needsExtraAdministrationInfo(): boolean {
            if (this.$scope.prescription.prescribedAdministrationInformation != null) {
                if (
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        administrationMeansCode.Intravenous ||
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        administrationMeansCode.Other ||
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        administrationMeansCode.Enteral ||
                    this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans ===
                        Contract.Core.Codes.AdministrationMeansCode.Subcutaneous
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        }

        public changed(): void {
            if (!this.$scope.prescription) {
                return;
            }

            if (this.$scope.prescription.solventToAddInformation) {
                this.$scope.prescription.solventToAddInformation.clear();
            }
            if (this.$scope.prescription.prescribedAdministrationInformation) {
                this.$scope.prescription.prescribedAdministrationInformation.clear();
            }
        }

        public hasAdministrationInformation(): boolean {
            return this.$scope.prescription
                ? this.$scope.prescription.prescribedAdministrationInformation
                    ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans != null
                    : false
                : false;
        }

        private async loadDataAsync(): Promise<void> {
            let durationResult: EnumTranslation[];
            try {
                [
                    durationResult,
                    this.$scope.administrationMethods,
                    this.$scope.speedUnits,
                    this.$scope.solventToAddUnits,
                ] = await Promise.all([
                    this.masterdataSvc.getDurationUnitsAsync(true),
                    this.masterdataSvc.getAdministrationMethodsAsync(),
                    this.masterdataSvc.getSpeedUnitsAsync(),
                    this.masterdataSvc.getSolventToAddUnitsAsync(),
                ]);

                this.$scope.durations = _.chain(durationResult)
                    .filter(duration => {
                        return duration.Id !== durationUnitCodes.Years && duration.Id !== durationUnitCodes.Months;
                    })
                    .value()
                    .sort((el1, el2) => {
                        if (el1.Id === durationUnitCodes.Minutes || el2.Id === durationUnitCodes.Minutes) {
                            return 1;
                        } else {
                            return el1.Id - el2.Id;
                        }
                    });
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'AdministrationInformationModalCtrl',
            (
                $scope: IAdministrationInformationModalScope,
                $translate,
                toaster: Shared.Framework.Toaster,
                masterdataSvc
            ) => new AdministrationInformationModalController($scope, $translate, toaster, masterdataSvc)
        );
}
