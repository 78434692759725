var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var HospitalisationController = /** @class */ (function (_super) {
            __extends(HospitalisationController, _super);
            function HospitalisationController($scope, $translate, toaster, $stateParams, masterdataSvc, careRequestSvc, therapyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.masterdataSvc = masterdataSvc;
                _this.careRequestSvc = careRequestSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.$scope.now = new Date();
                _this.$scope.confirm = function () { return _this.confirm(); };
                _this.$scope.isPresent = function (t) { return _this.isPresent(t); };
                _this.$scope.isRequired = function (t) { return _this.isRequired(t); };
                return _this;
            }
            HospitalisationController.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getCareTypes()
                    .success(function (r) { return _this.$scope.careTypes = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.therapyApiSvc.getHospitalisationReasons(this.$scope.therapyId)
                    .success(function (r) { return _this.$scope.reasons = r; })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            HospitalisationController.prototype.confirm = function () {
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.hospitalisation);
            };
            HospitalisationController.prototype.isPresent = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id !== RemeCare.Shared.Contract.Code.ModalityType.Not;
            };
            HospitalisationController.prototype.isRequired = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id === RemeCare.Shared.Contract.Code.ModalityType.Mandatory;
            };
            return HospitalisationController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule.controller('hospitalisationCtrl', function ($scope, $translate, toaster, $stateParams, masterdataSvc, careRequestSvc, therapyApiSvc) { return new HospitalisationController($scope, $translate, toaster, $stateParams, masterdataSvc, careRequestSvc, therapyApiSvc); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
