namespace RemeCare.CarePlanAction {
    interface IDynamicActionControllerScope extends ng.IScope {
        action: CarePlanAction;
        actionView: string;
        phoneNumber: string;
        cellPhoneNumber: string;
        fullSize: boolean;
        editRight: boolean;
    }

    export interface ICarePlanActionModalFunctions {
        cancelRegistrations: () => void;
        register: (permanent: boolean) => void;
        cancel(): void;
        planNewContact(includeParts: boolean): void;
        hasPhoneCall(): boolean;
    }

    class DynamicActionControllerDirective implements ng.IDirective {
        public restict: string = 'E';

        public scope: { [boundProperty: string]: string } = {
            action: '=',
            registeredCallback: '&',
            cancelledCallback: '&',
            registerFunctions: '&',
            fullSize: '=',
            editRight: '=',

        };
        constructor(
            private readonly $compile: ng.ICompileService,
            private readonly $templateCache: ng.ITemplateCacheService
        ) { }

        public link: ng.IDirectiveLinkFn = (scope: IDynamicActionControllerScope, element: ng.IAugmentedJQuery) => {
            this.configure(scope);
            this.render(scope, element);
            scope.$watch('action', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.configure(scope);
                    this.render(scope, element);
                }
            });
        };

        private configure(scope: IDynamicActionControllerScope): void {
            if (scope.action == null) {
                return;
            }
            scope.phoneNumber =
                scope.action.patient != null
                    ? new Framework.PhoneNumber(scope.action.patient.Telephone).toString()
                    : '';
            scope.cellPhoneNumber =
                scope.action.patient != null ? new Framework.PhoneNumber(scope.action.patient.Mobile).toString() : '';
        }

        private render(scope: IDynamicActionControllerScope, element: ng.IAugmentedJQuery): void {
            let html = '';
            if (scope.action != null) {
                let controller = 'defaultCarePlanActionCtrl';
                let view = 'views/carePlanAction/carePlanActionDetail.html';
                if (scope.action.customScreen) {
                    const split = scope.action.customScreen.split('/');
                    controller = split[0];
                    for (let i = 1; i < split.length; i++) {
                        split[i] = `${split[i].charAt(0).toUpperCase()}${split[i].substr(1)}`;
                        controller += split[i];
                    }
                    controller += 'Ctrl';
                    view = `views/custom/${scope.action.customScreen}.html`;
                }
                scope.actionView = view;
                html = (this.$templateCache.get('views/carePlanAction/dynamicActionController.html') as string).replace(
                    '$$controller$$',
                    controller
                );
            }
            element.html(html).show();
            this.$compile(element.contents())(scope);
        }
    }

    remeCareCarePlanActionModule.directive(
        'dynamicActionController',
        ($compile: ng.ICompileService, $templateCache: ng.ITemplateCacheService) =>
            new DynamicActionControllerDirective($compile, $templateCache)
    );
}
