namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import CarePlanStatus = Shared.Contract.Code.CarePlanStatus;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import CarePlanDetail = Contract.Patient.Read.ICarePlanParameterValue;
    import TherapyMonitoringParts = Shared.Contract.Read.ITherapyMonitoringParts;
    import DateRangeFilterCode = Contract.Core.Codes.DateRangeFilterCode;

    interface IRange {
        amountOfTime: number;
        translateKey: string;
        translateLongKey: string;
        unitOfTime: string;
        value: DateRangeFilterCode;
    }

    interface IDateInfo {
        fromDate: Date;
        untilDate: Date;
        range: IRange;
    }

    class PatientFileMonitoringController implements ng.IComponentController {
        public patientId: Shared.Contract.Guid;
        public carePlanMonitoringParts: ICarePlanMonitoringPart[];
        public carePlan: Shared.Contract.IEntityTranslation;
        public carePlans: CarePlan[];
        public carePlanSummary: EntityTranslation[];
        public carePlanDetails: CarePlanDetail[];
        public dateFilterOptions: IRange[];
        public dateInfo: IDateInfo;
        public dateEdit: boolean;
        public showGraphLegends: boolean;
        public showPartsWithoutData: boolean;

        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly loadMeasuringSvc: Shared.Framework.Service.LoadMeasuringService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly monitoringPartFactory: Shared.Framework.Factory.IMonitoringPartFactory,
            private readonly monitoringPartMergeSvc: MonitoringPartMergeService,
            private readonly patientSvc: Patient.PatientService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public async $onInit(): Promise<void> {
            this.dateInfo = {
                fromDate: null,
                untilDate: null,
                range: null,
            };
            this.dateFilterOptions = [
                {
                    amountOfTime: 7,
                    translateKey: 'Views.PatientFile.Monitoring.LastWeek',
                    translateLongKey: 'Views.PatientFile.Monitoring.LastWeekLong',
                    unitOfTime: 'days',
                    value: DateRangeFilterCode.LastWeek,
                },
                {
                    amountOfTime: 14,
                    translateKey: 'Views.PatientFile.Monitoring.LastTwoWeeks',
                    translateLongKey: 'Views.PatientFile.Monitoring.LastTwoWeeksLong',
                    unitOfTime: 'days',
                    value: DateRangeFilterCode.LastTwoWeeks,
                },
                {
                    amountOfTime: 1,
                    translateKey: 'Views.PatientFile.Monitoring.LastMonth',
                    translateLongKey: 'Views.PatientFile.Monitoring.LastMonthLong',
                    unitOfTime: 'month',
                    value: DateRangeFilterCode.LastMonth,
                },
                {
                    amountOfTime: 3,
                    translateKey: 'Views.PatientFile.Monitoring.LastThreeMonths',
                    translateLongKey: 'Views.PatientFile.Monitoring.LastThreeMonthsLong',
                    unitOfTime: 'months',
                    value: DateRangeFilterCode.LastThreeMonths,
                },
                {
                    amountOfTime: 1,
                    translateKey: 'Views.PatientFile.Monitoring.LastYear',
                    translateLongKey: 'Views.PatientFile.Monitoring.LastYearLong',
                    unitOfTime: 'year',
                    value: DateRangeFilterCode.LastYear,
                },
            ];

            try {
                const ags = await this.patientSvc.getCarePlansAsync(this.patientId);
                const open = _.chain(ags)
                    .filter(x => x.Status.Id === CarePlanStatus.Open)
                    .map(x => new CarePlan(x))
                    .sortBy(x => x.startDate)
                    .value();

                const closed = _.chain(ags)
                    .filter(x => x.Status.Id !== CarePlanStatus.Open)
                    .map(x => new CarePlan(x))
                    .sortBy(x => x.statusChangedDate)
                    .value();

                this.carePlans = open.concat(closed);
                this.carePlanSummary = _(this.carePlans).map(x => {
                    return {
                        Id: x.id,
                        Text: x.statusOpen
                            ? x.name +
                              `<span class="text-nowrap">${this.$translate.instant(
                                  'Views.PatientFile.CarePlans.StatusActive',
                                  { CarePlanStatus: x.status, StatusDate: x.getFormattedStartDate() }
                              )}</span>`
                            : x.name +
                              `<span class="text-nowrap">${this.$translate.instant(
                                  'Views.PatientFile.CarePlans.StatusNotActive',
                                  { CarePlanStatus: x.status, StatusDate: x.getFormattedStatusChangedDate() }
                              )}</span>`,
                    };
                });
                this.addAllCarePlansOption();
                this.carePlanSelected(_.find(this.carePlanSummary, cp => cp.Id == null), true);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public toggleDateEdit(): void {
            this.dateEdit = !this.dateEdit;
        }

        public async carePlanSelected(carePlan: EntityTranslation, initial?: boolean): Promise<void> {
            if (!initial) {
                this.loadMeasuringSvc.reset();
            }
            this.carePlan = carePlan;
            let carePlans: EntityTranslation[] = [];
            if (carePlan == null || carePlan.Id == null) {
                carePlans = _(this.carePlans).map(cp => {
                    return {
                        Id: cp.id,
                        Text: cp.name,
                    };
                });
            } else {
                carePlans.push(carePlan);
            }

            if (carePlans.length !== 0) {
                await this.loadCarePlanDetails(carePlans);
                this.carePlanMonitoringParts = await this.loadMonitoringParts(carePlans);
            }
        }

        public fromChanged(): void {
            this.loadMeasuringSvc.reset();
            this.dateInfo.range = null;
            if (this.dateInfo.fromDate && !this.dateInfo.untilDate) {
                this.dateInfo.untilDate = moment
                    .min(moment(this.dateInfo.fromDate).add(3, 'months'), moment(Shared.DateHelper.today()))
                    .toDate();
            }
        }

        public untilChanged(): void {
            this.loadMeasuringSvc.reset();
            this.dateInfo.range = null;
        }

        public clearFromDate(): void {
            this.loadMeasuringSvc.stopMeasuring();
            this.dateInfo.fromDate = null;
            this.dateInfo.range = null;
        }

        public clearUntilDate(): void {
            this.loadMeasuringSvc.stopMeasuring();
            this.dateInfo.untilDate = null;
            this.dateInfo.range = null;
        }

        public setDateInfo(range: IRange): void {
            this.dateInfo.range = range;
            this.loadMeasuringSvc.reset();
            this.dateInfo.fromDate = moment(Shared.DateHelper.today())
                .subtract(this.dateInfo.range.amountOfTime, this.dateInfo.range.unitOfTime)
                .toDate();
            this.dateInfo.untilDate = Shared.DateHelper.today();
        }

        protected addAllCarePlansOption(): void {
            this.carePlanSummary.splice(0, 0, {
                Id: null,
                Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
            } as EntityTranslation);
        }

        private async loadCarePlanDetails(carePlans: EntityTranslation[]): Promise<void> {
            const carePlanIds = _.map(carePlans, cp => cp.Id);
            try {
                this.carePlanDetails = await this.carePlanApiSvc.getCarePlanParameterValuesAsync(carePlanIds);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadMonitoringParts(carePlans: EntityTranslation[]): Promise<ICarePlanMonitoringPart[]> {
            try {
                const therapyIds = _.filter(this.carePlans, cp =>
                    _.any(carePlans, (icp: EntityTranslation) => icp.Id === cp.id)
                ).map(cp => cp.therapyId);
                const query: Contract.Patient.Read.ITherapyQuery = {
                    therapyIds,
                };

                let groups: EnumTranslation[];
                let therapyMonitoringParts: TherapyMonitoringParts[];
                [groups, therapyMonitoringParts] = await Promise.all([
                    this.masterdataSvc.getMonitoringPartGroupsAsync(),
                    this.patientSvc.getMonitoringConfigurationAsync(this.patientId, query),
                ]);
                const carePlanMonitoringParts = await Promise.all(
                    _.map(therapyMonitoringParts, tmp => {
                        try {
                            return this.mapTherapyMonitoringPartsToCarePlanAsync(tmp, carePlans);
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    })
                );

                let monitoringPartsList = _.flatten(carePlanMonitoringParts) as ICarePlanMonitoringPart[];
                monitoringPartsList = this.sortMonitoringParts(monitoringPartsList, groups);
                monitoringPartsList = this.monitoringPartMergeSvc.mergeMonitoringParts(monitoringPartsList);
                return monitoringPartsList;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private sortMonitoringParts(
            monitoringParts: ICarePlanMonitoringPart[],
            groups: Shared.Contract.IEnumTranslation[]
        ): ICarePlanMonitoringPart[] {
            const groupedParts = _.groupBy(
                monitoringParts,
                cpmp => cpmp.monitoringPart.assignedGroup && cpmp.monitoringPart.assignedGroup.Id
            );
            let groupedList = _.map(groups, g => groupedParts[g.Id]);
            groupedList = _.filter(groupedList, cpmps => cpmps != null);
            if (groupedParts[null as string]) {
                groupedList.push(groupedParts[null as string]);
            }
            return _.flatten(groupedList);
        }

        private async mapTherapyMonitoringPartsToCarePlanAsync(
            therapyMonitoringParts: Shared.Contract.Read.ITherapyMonitoringParts,
            selectedCarePlans: EntityTranslation[]
        ): Promise<ICarePlanMonitoringPart[]> {
            const monitoringParts = await this.monitoringPartFactory.createAsync(
                therapyMonitoringParts.TherapyId,
                therapyMonitoringParts.MonitoringParts
            );
            const carePlanIds = _(this.carePlans)
                .chain()
                .filter(
                    cp =>
                        cp.therapyId === therapyMonitoringParts.TherapyId &&
                        selectedCarePlans.some(sc => sc.Id === cp.id)
                )
                .map(cp => cp.id)
                .value();
            const sortedMonitoringParts = _.sortBy(monitoringParts, mp => mp.sequence);
            return _.map(sortedMonitoringParts, mp => {
                try {
                    return {
                        carePlanIds: carePlanIds,
                        monitoringPart: mp,
                    } as ICarePlanMonitoringPart;
                } catch (e) {
                    this.toaster.error(e);
                }
            });
        }
    }

    remeCarePatientModule.component('patientFileMonitoring', {
        controller: PatientFileMonitoringController,
        templateUrl: 'views/patient/monitoring/dashboard/monitoring.html',
        bindings: {
            patientId: '<',
        },
    });
}
