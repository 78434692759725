var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        var ContactPointType = RemeCare.Shared.Contract.Code.ContactPointType;
        var RequestUserAccountComponent = /** @class */ (function () {
            function RequestUserAccountComponent(selfServiceApi, toaster, masterdataSvc, languageSvc, $translate) {
                this.selfServiceApi = selfServiceApi;
                this.toaster = toaster;
                this.masterdataSvc = masterdataSvc;
                this.languageSvc = languageSvc;
                this.$translate = $translate;
            }
            RequestUserAccountComponent.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var requestAccessTypes, language, exception_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.telephoneNumber = (RemeCare.Model.ContactPointFactory.createFromType(ContactPointType.MobilePhone));
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, this.masterdataSvc.getReferenceDataAsync('RequestAccessType')];
                            case 2:
                                requestAccessTypes = _a.sent();
                                this.requestAccessTypes = requestAccessTypes;
                                return [4 /*yield*/, this.masterdataSvc.getInterfaceLanguagesAsync()];
                            case 3:
                                language = _a.sent();
                                this.languages = _.sortBy(language, function (lang) {
                                    if (lang.Code === 'nl') {
                                        return 1;
                                    }
                                    return -1;
                                });
                                return [3 /*break*/, 5];
                            case 4:
                                exception_1 = _a.sent();
                                this.toaster.error(exception_1);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            RequestUserAccountComponent.prototype.needsRiziv = function () {
                switch (this.capacity && this.capacity.Id) {
                    case RemeCare.Shared.Contract.Code.RequestAccessType.DoctorSpecialistAssociatedToAHospital:
                        return true;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.DoctorSpecialistNotAssociatedToAHospital:
                        return true;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.GeneralPractitioner:
                        return true;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.HomeCareNurse:
                        return true;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.HospitalPharmacist:
                        return true;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.Pharmacist:
                        return true;
                    default:
                        return false;
                }
            };
            RequestUserAccountComponent.prototype.shouldBeSentTo = function () {
                var sales = 'sales';
                var operations = 'operations';
                switch (this.capacity && this.capacity.Id) {
                    case RemeCare.Shared.Contract.Code.RequestAccessType.DoctorSpecialistAssociatedToAHospital:
                        return sales;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.HospitalNurse:
                        return sales;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.HospitalPharmacist:
                        return sales;
                    case RemeCare.Shared.Contract.Code.RequestAccessType.HospitalStaff:
                        return sales;
                    default:
                        return operations;
                }
            };
            RequestUserAccountComponent.prototype.changeLanguage = function (lang) {
                return __awaiter(this, void 0, void 0, function () {
                    var requestAccessTypes;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.languageSvc.setLang(lang, false);
                                return [4 /*yield*/, this.masterdataSvc.getReferenceDataAsync('RequestAccessType')];
                            case 1:
                                requestAccessTypes = _a.sent();
                                this.requestAccessTypes = requestAccessTypes;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            RequestUserAccountComponent.prototype.requestUserAccount = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var emailRegEx, exception_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.newUserAccountForm.$invalid) {
                                    this.newUserAccountForm.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (!emailRegEx.test(String(this.requestUserAccountInfo.email).toLowerCase())) {
                                    this.toaster.error('General.Validation.EmailAddress.Invalid');
                                    return [2 /*return*/];
                                }
                                if (this.requestUserAccountInfo.riziv != null && this.requestUserAccountInfo.riziv !== '' && !/^\d{11}$/.test(this.requestUserAccountInfo.riziv)) {
                                    this.toaster.error('General.Validation.Riziv.Invalid');
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                this.requestUserAccountInfo.phone = this.telephoneNumber.toString();
                                this.requestUserAccountInfo.languageISO2Code = this.$translate.preferredLanguage();
                                if (this.capacity) {
                                    this.requestUserAccountInfo.capacity = this.capacity.Text;
                                }
                                this.requestUserAccountInfo.directedTo = this.shouldBeSentTo();
                                return [4 /*yield*/, this.selfServiceApi.requestUserAccountAsync(this.requestUserAccountInfo)];
                            case 2:
                                _a.sent();
                                this.requestSuccess = true;
                                return [3 /*break*/, 4];
                            case 3:
                                exception_2 = _a.sent();
                                this.toaster.error(exception_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return RequestUserAccountComponent;
        }());
        SelfService.remeCareSelfServiceModule.component('rcRequestUserAccount', {
            controller: RequestUserAccountComponent,
            templateUrl: 'views/selfService/requestUserAccount.html'
        });
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
