var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PersonalInformationComponent = /** @class */ (function () {
            function PersonalInformationComponent($state, $stateParams, modalBuilderFactory, gridBuilderSvc, toaster) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.modalBuilderFactory = modalBuilderFactory;
                this.gridBuilderSvc = gridBuilderSvc;
                this.toaster = toaster;
            }
            PersonalInformationComponent.prototype.$onInit = function () { };
            PersonalInformationComponent.prototype.buildAddressGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('addressLine1', 'General.Address')
                    .addColumn('zipCode', 'General.Zipcode')
                    .addColumn('city', 'General.City')
                    .addEnumColumn('country', 'General.Country', RemeCare.Shared.Framework.ReferenceDataTypes.country)
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInformationComponent.prototype.buildEmailGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('email', 'General.Email')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInformationComponent.prototype.buildPhoneNumbersGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('toString()', 'General.Number')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInformationComponent.prototype.showAddresses = function () {
                var grid = this.buildAddressGrid();
                grid.setData(this.person.addresses);
                this.showContactPointsModal('Views.PatientFile.DetailAddresses', grid);
            };
            PersonalInformationComponent.prototype.showPhoneNumbers = function () {
                var grid = this.buildPhoneNumbersGrid();
                grid.setData(this.person.telephoneNumbers);
                this.showContactPointsModal('Views.PatientFile.DetailPhone', grid);
            };
            PersonalInformationComponent.prototype.showMobileNumbers = function () {
                var grid = this.buildPhoneNumbersGrid();
                grid.setData(this.person.mobileNumbers);
                this.showContactPointsModal('Views.PatientFile.DetailMobile', grid);
            };
            PersonalInformationComponent.prototype.showEmails = function () {
                var grid = this.buildEmailGrid();
                grid.setData(this.person.emailAddresses);
                this.showContactPointsModal('Views.PatientFile.DetailEmail', grid);
            };
            PersonalInformationComponent.prototype.showContactPointsModal = function (title, grid) {
                this.modalBuilderFactory.createModalBuilder()
                    .setTemplateUrl('views/shared/contactPointDetail.html')
                    .setScope({
                    title: title,
                    grid: grid
                })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            return PersonalInformationComponent;
        }());
        Patient.remeCarePatientModule.component('rcPersonalInformation', {
            templateUrl: 'views/patient/patientInfo/contactPersons/personInformationComponent.html',
            controller: PersonalInformationComponent,
            controllerAs: 'personalInformationCtrl',
            bindings: {
                person: '='
            }
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
