var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var RescheduleActionController = /** @class */ (function () {
            function RescheduleActionController($translate, toaster, $locale, carePlanApiSvc, carePlanActionApiSvc, modalBuilderFactory) {
                this.$translate = $translate;
                this.toaster = toaster;
                this.$locale = $locale;
                this.carePlanApiSvc = carePlanApiSvc;
                this.carePlanActionApiSvc = carePlanActionApiSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            RescheduleActionController.prototype.$onInit = function () {
                this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
            };
            RescheduleActionController.prototype.$onChanges = function (onChangesObj) {
                if (onChangesObj.carePlanAction) {
                    if (this.carePlanAction.id != null) {
                        this.loadDataAsync();
                        this.setInfoMessageAsync(this.carePlanAction.id);
                        this.dateInfo = {
                            date: this.carePlanAction.plannedDateTimeFrom
                                ? new Date(this.carePlanAction.plannedDateTimeFrom.valueOf())
                                : null,
                            from: this.carePlanAction.plannedDateTimeFrom
                                ? new Date(this.carePlanAction.plannedDateTimeFrom.valueOf())
                                : null,
                            until: this.carePlanAction.plannedDateTimeUntil
                                ? new Date(this.carePlanAction.plannedDateTimeUntil.valueOf())
                                : null,
                        };
                        this.partyRole = angular.copy(this.carePlanAction.partyRole);
                        this.includesTimeNoWindow = this.carePlanAction.includesEventTime;
                        if (this.carePlanAction.isEventDateTimeRange) {
                            this.includesTimeWindow = true;
                            this.includesTimeNoWindow = false;
                        }
                        this.possibleActionDurationOptionId = this.carePlanAction.possibleActionDurationOptionId;
                        this.durationOfAction = this.carePlanAction.durationOfAction;
                    }
                }
                this.showActionDuration = false;
                if (onChangesObj.component) {
                    this.hasLocation = this.component.hasLocation;
                    if (this.hasLocation === true && this.carePlanAction.location) {
                        this.location = {
                            ContactPointUsageId: this.carePlanAction.location.ContactPointUsageId,
                            Street: this.carePlanAction.location.Street,
                            Name: this.carePlanAction.location.Name,
                            City: this.carePlanAction.location.City,
                            ZipCode: this.carePlanAction.location.ZipCode,
                            Remark: this.carePlanAction.location.Remark,
                        };
                    }
                    this.hasExecutor = this.component.hasExecutor;
                    this.hasActionDurationOption = this.component.hasActionDurationOption;
                    this.possibleActionDurationOptions = this.component.possibleActionDurationOptions;
                    this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(this.hasActionDurationOption, this.durationOfAction, this.possibleActionDurationOptions);
                }
                if (onChangesObj.address) {
                    this.address = angular.copy(this.address);
                }
            };
            RescheduleActionController.prototype.changeLocation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('locationCtrl')
                    .setTemplateUrl('views/carePlanAction/location/locationModal.html')
                    .setScope({
                    patientId: this.carePlanAction.patientId,
                })
                    .setResultCallBack(function (r) { return _this.setLocation(r); })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            RescheduleActionController.prototype.dateChanged = function () {
                if (this.dateInfo.from == null) {
                    return;
                }
                if (this.dateInfo.until == null) {
                    this.dateInfo.until = new Date(this.dateInfo.from.getTime());
                }
                else {
                    this.dateInfo.until.setFullYear(this.dateInfo.from.getFullYear(), this.dateInfo.from.getMonth(), this.dateInfo.from.getDate());
                }
            };
            RescheduleActionController.prototype.confirmAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var from, until, params, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.rescheduleForm.$setSubmitted();
                                if (this.rescheduleForm.$invalid) {
                                    return [2 /*return*/];
                                }
                                from = new Date(this.dateInfo.date.getTime());
                                until = new Date(this.dateInfo.date.getTime());
                                // If includesTimeWindow, then the timestamps are set on dateInfo.from and dateInfo.until
                                // Otherwise date/time are both set on dateInfo.date
                                if (this.includesTimeWindow) {
                                    from.setHours(this.dateInfo.from.getHours());
                                    from.setMinutes(this.dateInfo.from.getMinutes());
                                    from.setSeconds(this.dateInfo.from.getSeconds());
                                    until.setHours(this.dateInfo.until.getHours());
                                    until.setMinutes(this.dateInfo.until.getMinutes());
                                    until.setSeconds(this.dateInfo.until.getSeconds());
                                }
                                this.filterLocationProperties();
                                params = {
                                    from: RemeCare.Shared.DateHelper.toServerDateTimeString(from),
                                    until: RemeCare.Shared.DateHelper.toServerDateTimeString(until),
                                    actor: this.partyRole != null && this.hasExecutor ? this.partyRole.Id : null,
                                    location: this.hasLocation ? this.location : null,
                                    type: RemeCare.Shared.Contract.Code.ActionType.CareAct,
                                    reason: this.reason,
                                    durationOfAction: RemeCare.Shared.DateHelper.toServerTimeString(this.durationOfAction),
                                    possibleActionDurationOptionId: this.possibleActionDurationOptionId,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.rescheduleAsync(this.carePlanAction.carePlanActionId, params)];
                            case 2:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.Action.CareAct.RescheduleSuccess'));
                                this.carePlanAction.plannedDateTimeFrom = from;
                                this.carePlanAction.plannedDateTimeUntil = until;
                                this.carePlanAction.eventDateFrom = from;
                                this.carePlanAction.eventDateUntil = until;
                                this.carePlanAction.eventTimeFrom = from;
                                this.carePlanAction.eventTimeUntil = until;
                                this.carePlanAction.partyRole = this.partyRole;
                                this.carePlanAction.location = this.location;
                                this.carePlanAction.durationOfAction = this.durationOfAction;
                                this.carePlanAction.possibleActionDurationOptionId = this.possibleActionDurationOptionId;
                                this.$close({ result: { address: this.address, carePlanAction: this.carePlanAction } });
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            RescheduleActionController.prototype.setLocation = function (address) {
                var idLocation = {
                    ContactPointUsageId: address.Id,
                };
                var customLocation = {
                    Name: address.AddressLine2,
                    Street: address.AddressLine1,
                    ZipCode: address.ZipCode,
                    City: address.City,
                    Remark: address.Remark,
                };
                this.address = customLocation;
                this.location = address.Id != null ? idLocation : customLocation;
            };
            RescheduleActionController.prototype.filterLocationProperties = function () {
                if (this.location == null) {
                    this.location = {
                        ContactPointUsageId: null,
                        Street: null,
                        Name: null,
                        City: null,
                        ZipCode: null,
                        Remark: null,
                    };
                }
                var idLocation = {
                    ContactPointUsageId: this.location.ContactPointUsageId,
                };
                var customLocation = {
                    Name: this.location.Name,
                    Street: this.location.Street,
                    ZipCode: this.location.ZipCode,
                    City: this.location.City,
                    Remark: this.location.Remark,
                };
                this.location = this.location.ContactPointUsageId != null ? idLocation : customLocation;
            };
            RescheduleActionController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this;
                                return [4 /*yield*/, this.carePlanApiSvc.findAssignablePartiesAsync(this.carePlanAction.patientId, this.carePlanAction.actionTemplateId)];
                            case 1:
                                _a.possibleActors = _b.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            RescheduleActionController.prototype.setInfoMessageAsync = function (actionId) {
                return __awaiter(this, void 0, void 0, function () {
                    var result, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionConfigurationInformationAsync(actionId)];
                            case 1:
                                result = _a.sent();
                                if (result.HasPeriodicity && result.CanPersonalisePeriodicity) {
                                    this.infoMessage = 'Views.Action.CareAct.Reschedule.InfoCanPersonalisePeriodicity';
                                }
                                else if (result.HasPeriodicity) {
                                    this.infoMessage = 'Views.Action.CareAct.Reschedule.InfoNoPersonalisePeriodicity';
                                }
                                else {
                                    this.infoMessage = '';
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return RescheduleActionController;
        }());
        CarePlanAction.remeCareCarePlanActionModule.component('rescheduleActionCtrl', {
            bindings: {
                $close: '&',
                $dismiss: '&',
                address: '<',
                carePlanAction: '<',
                component: '<',
            },
            controller: RescheduleActionController,
            templateUrl: 'views/carePlanAction/directive/rescheduleAction.html',
        });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
