var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var ComponentDescriptorFactory = /** @class */ (function () {
            function ComponentDescriptorFactory() {
            }
            ComponentDescriptorFactory.prototype.createComponentDescriptor = function (authservice, serverObject) {
                switch (serverObject.ActionType.Id) {
                    case RemeCare.Shared.Contract.Code.ActionType.QuantitativeObservation:
                        return new CarePlanAction.QuantitativeObservationDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.QualitativeObservation:
                        return new CarePlanAction.QualitativeObservationDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.Administration:
                        return new CarePlanAction.AdministrationDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.CareAct:
                        return new CarePlanAction.CareActDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.FixDetails:
                        return new CarePlanAction.SetDetailsDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.ProvideInformationObject:
                        return new CarePlanAction.ProvideInformationObjectDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.RequestInformationObject:
                        return new CarePlanAction.RequestInformationObjectDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.Remark:
                        return new CarePlanAction.RemarkDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.PreviousRegistration:
                        return new CarePlanAction.PreviousRegistrationDescriptor(authservice, serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning:
                        return new CarePlanAction.PhoneCallDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.Date:
                        return new CarePlanAction.DateDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.MissingRegistrations:
                        return new CarePlanAction.MissingRegistrationsDescriptor(authservice, serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.MedicationSchema:
                        return new CarePlanAction.MedicationSchemaDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.AberrantRegistrations:
                        return new CarePlanAction.AberrantRegistrationsDescriptor(serverObject);
                    case RemeCare.Shared.Contract.Code.ActionType.Signature:
                        return new CarePlanAction.SignatureDescriptor(serverObject);
                    default:
                        return null;
                }
            };
            return ComponentDescriptorFactory;
        }());
        CarePlanAction.ComponentDescriptorFactory = ComponentDescriptorFactory;
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
