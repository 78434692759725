var RemeCare;
(function (RemeCare) {
    var Framework;
    (function (Framework) {
        var SignaturePadController = /** @class */ (function () {
            function SignaturePadController($element, idService) {
                this.$element = $element;
                this.idService = idService;
            }
            SignaturePadController.prototype.$onInit = function () {
                var _this = this;
                this.uniqueName = this.idService.generateId();
                this.canvas = this.getCanvas();
                this.signaturePad = new SignaturePad(this.canvas);
                if (this.model) {
                    this.signaturePad.fromDataURL(this.model, { ratio: 1 });
                    this.internalModel = this.model;
                }
                if (this.readonly) {
                    this.signaturePad.off();
                }
                this.signaturePad.addEventListener('endStroke', function () {
                    // For some reason, two-way binding did not always update the model in the calling component.
                    // Especially on TempSave an ad-hoc action. See PF-3038 for the original bug
                    // I'm probably using angular wrong but using an explicit update callback is my fix.
                    _this.internalModel = _this.cropSignatureCanvas(_this.canvas);
                    _this.modelUpdated({ newModel: _this.internalModel });
                });
            };
            SignaturePadController.prototype.$onChanges = function (changes) {
                if (!this.signaturePad) {
                    return;
                }
                if (changes.readonly) {
                    if (changes.readonly.currentValue) {
                        this.signaturePad.off();
                    }
                    else {
                        this.signaturePad.on();
                    }
                }
            };
            SignaturePadController.prototype.clear = function () {
                this.signaturePad.clear();
                this.model = null;
            };
            SignaturePadController.prototype.getCanvas = function () {
                return this.$element.find('.signature-pad')[0];
            };
            // https://github.com/szimek/signature_pad/issues/49#issuecomment-867566006
            SignaturePadController.prototype.cropSignatureCanvas = function (canvas) {
                var originalCtx = canvas.getContext('2d');
                var originalWidth = canvas.width;
                var originalHeight = canvas.height;
                var imageData = originalCtx.getImageData(0, 0, originalWidth, originalHeight);
                var minX = originalWidth + 1, maxX = -1, minY = originalHeight + 1, maxY = -1, x = 0, y = 0, currentPixelColorValueIndex;
                for (y = 0; y < originalHeight; y++) {
                    for (x = 0; x < originalWidth; x++) {
                        currentPixelColorValueIndex = (y * originalWidth + x) * 4;
                        var currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
                        if (currentPixelAlphaValue > 0) {
                            if (minX > x) {
                                minX = x;
                            }
                            if (maxX < x) {
                                maxX = x;
                            }
                            if (minY > y) {
                                minY = y;
                            }
                            if (maxY < y) {
                                maxY = y;
                            }
                        }
                    }
                }
                var croppedWidth = maxX - minX;
                var croppedHeight = maxY - minY;
                if (croppedWidth < 0 || croppedHeight < 0) {
                    return null;
                }
                var cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);
                var croppedCanvas = document.createElement('canvas'), croppedCtx = croppedCanvas.getContext('2d');
                croppedCanvas.width = croppedWidth;
                croppedCanvas.height = croppedHeight;
                croppedCtx.putImageData(cuttedImageData, 0, 0);
                return croppedCanvas.toDataURL();
            };
            return SignaturePadController;
        }());
        RemeCare.remeCareAppModule.component('rcSignaturePad', {
            bindings: {
                model: '<?model',
                readonly: '<?ngReadonly',
                required: '=ngRequired',
                modelUpdated: '&',
            },
            controller: SignaturePadController,
            require: {
                formCtrl: '^form',
            },
            templateUrl: 'framework/components/signaturePad/signaturePad.html',
        });
    })(Framework = RemeCare.Framework || (RemeCare.Framework = {}));
})(RemeCare || (RemeCare = {}));
