namespace RemeCare.Core {
    class ExternalReferenceModalController implements ng.IComponentController {
        public externalReference: RemeCare.Contract.Core.IExternalReference;
        public externalReferenceForm: Shared.Framework.Directive.IFormController;
        public $close: (r: { result: RemeCare.Contract.Core.IExternalReference }) => void;

        public async $onInit(): Promise<void> {}

        public confirm(): void {
            if (this.externalReferenceForm.$invalid) {
                this.externalReferenceForm.showValidationErrorMessage();
                return;
            }
            this.$close({ result: this.externalReference });
        }
    }

    RemeCare.Patient.remeCarePatientModule.component('rcExternalReferenceModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            externalReference: '<',
        },
        controller: ExternalReferenceModalController,
        templateUrl: 'core/components/externalReferenceModal/externalReferenceModal.html',
    });
}
