namespace RemeCare.IntegrationMonitor {
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;
    
    class RemeCareIntegrationMonitorConfiguration extends Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.addGeneric(
                'integrationMonitor',
                {
                    url: '/IntegrationMessages',
                    redirectTo: 'integrationMonitor.search',
                },
                ['Views_IntegrationMonitor'],
                {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                }
            )

            this.addGeneric(
                'integrationMonitor.search',
                {
                    url: '/Search',
                    component: 'rcSearchIntegrationMessages'
                },
                ['Patient','Views_IntegrationMonitor'],
                {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                }
            )

            this.addGeneric<IIntegrationMessageStateParams>(
                'integrationMonitor.message',
                {
                    component: 'rcIntegrationMessage',
                    params: {
                        messageId: null,
                    },
                    url: '/:messageId'
                },
                ['Patient','Views_IntegrationMonitor','Views_IntegrationMonitor_IntegrationMessage'],
                {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                }
            )
        }
    }

    remeCareIntegrationMonitorModule.config($stateProvider => new RemeCareIntegrationMonitorConfiguration($stateProvider));
}
