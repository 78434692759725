var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest_1) {
        var CareRequestSetup = RemeCare.Shared.Framework.Model.CareRequestSetup;
        var Consultation = RemeCare.Model.Consultation;
        var Hospitalisation = RemeCare.Model.Hospitalisation;
        var Periodicity = RemeCare.Shared.Framework.Model.Periodicity;
        var CareRequestTemplate = /** @class */ (function () {
            function CareRequestTemplate(serverObject) {
                if (serverObject != null) {
                    this.therapy = serverObject.Therapy;
                    this.careRequestSetup = new CareRequestSetup(serverObject.CareRequestSetup);
                    this.careRequestStepSequence = serverObject.CareRequestStepSequence;
                    this.totalSteps = serverObject.TotalSteps;
                    this.careRequestStepType = serverObject.CareRequestStepType;
                    this.isFinal = serverObject.IsFinal;
                    this.isStudy = serverObject.IsStudy;
                }
            }
            CareRequestTemplate.prototype.getSaveButtonTranslation = function () {
                if (this.careRequestStepType.Id === RemeCare.Shared.Contract.Code.CareRequestStepType.Application) {
                    return this.isFinal ? 'Views.CareRequest.Details.PermSubmit' : 'Views.CareRequest.Details.TempSubmit';
                }
                else {
                    return this.isFinal ? 'Views.CareRequest.Details.PermAccept' : 'Views.CareRequest.Details.TempAccept';
                }
            };
            CareRequestTemplate.prototype.getCancelButtonTranslation = function () {
                if (this.careRequestStepType.Id === RemeCare.Shared.Contract.Code.CareRequestStepType.Application) {
                    return 'Views.CareRequest.Details.CancelApplication';
                }
                else {
                    return 'Views.CareRequest.Details.CancelAcceptation';
                }
            };
            return CareRequestTemplate;
        }());
        CareRequest_1.CareRequestTemplate = CareRequestTemplate;
        var CareRequest = /** @class */ (function () {
            function CareRequest(serverObject, template, allowedComorbidities, allowedTreatedDiseases) {
                this.template = template;
                this.patient = new CareRequest_1.RequestPerson();
                this.careRequestor = new CareRequestor();
                this.patientContactPersons = [];
                this.therapyOptions = [];
                this.careRequestDates = [];
                this.careRequestStrings = [];
                this.careRequestSignatures = [];
                this.anamneses = [];
                this.careRequestDocuments = [];
                this.careRequestRoles = [];
                this.careRequestMedications = [];
                this.comorbidities = [];
                this.treatedDiseases = [];
                this.consultations = [];
                this.hospitalisations = [];
                this.externalReferences = [];
                this.timestamp = [];
                this.modifyingEnabled = false;
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.stepSequence = serverObject.StepSequence;
                    this.stepType = serverObject.StepType;
                    this.therapyId = serverObject.TherapyId;
                    this.matchedPartyId = serverObject.MatchedPartyId;
                    this.matchedPatientId = serverObject.MatchedPatientId;
                    this.intermediaryHealthCareOrganisationTypeId = serverObject.IntermediaryHealthCareOrganisationTypeId;
                    this.intermediarySpecialtyId = serverObject.IntermediarySpecialtyId;
                    if (serverObject.Patient == null && serverObject.MatchedPatientId != null) {
                        this.patient = null;
                    }
                    else {
                        this.patient = new CareRequest_1.RequestPerson(serverObject.Patient);
                    }
                    this.careRequestor = new CareRequestor(serverObject.CareRequestor);
                    this.careRequestRoles = _(serverObject.CareRequestRoles).map(function (crr) { return new CareRequestRole(crr); });
                    this.patientContactPersons = _(serverObject.PatientContactPersons).map(function (p) { return new CareRequest_1.PatientContactPerson(p); });
                    this.therapyOptions = _(serverObject.TherapyOptions).map(function (t) { return new TherapyOption(t); });
                    this.careRequestDates = _(serverObject.CareRequestDates).map(function (d) { return new CareRequestDate(d); });
                    this.careRequestStrings = _(serverObject.CareRequestStrings).map(function (s) { return new CareRequestString(s); });
                    this.careRequestSignatures = _(serverObject.CareRequestSignatures).map(function (s) { return new CareRequestSignature(s); });
                    this.anamneses = _(serverObject.Anamneses).map(function (a) { return new CareRequest_1.Anamnesis(a); });
                    this.careRequestDocuments = _(serverObject.CareRequestDocuments).map(function (c) { return new CareRequestDocument(c); });
                    this.careRequestMedications = _(serverObject.CareRequestMedications).map(function (c) { return new CareRequestMedication(c); });
                    this.careRequestStatus = serverObject.CareRequestStatus;
                    this.comorbidities = this.mapToTherapyDiseaseIds(_(serverObject.CareRequestSufferedDiseases)
                        .chain()
                        .filter(function (sd) { return sd.DiseaseType.Id === RemeCare.Shared.Contract.Code.DiseaseType.Comorbidity; })
                        .map(function (sd) { return sd.Disease.Id; })
                        .value(), allowedComorbidities);
                    this.treatedDiseases = this.mapToTherapyDiseaseIds(_(serverObject.CareRequestSufferedDiseases)
                        .chain()
                        .filter(function (sd) { return sd.DiseaseType.Id === RemeCare.Shared.Contract.Code.DiseaseType.TreatedDisease; })
                        .map(function (sd) { return sd.Disease.Id; })
                        .value(), allowedTreatedDiseases);
                    this.consultations = _(serverObject.Consultations).map(function (c) { return new Consultation(c); });
                    this.hospitalisations = _(serverObject.Hospitalisations).map(function (h) { return new Hospitalisation(h); });
                    this.externalReferences = serverObject.ExternalReferences || [];
                    this.timestamp = serverObject.Timestamp;
                    this.readOnly = serverObject.ReadOnly;
                    this.statusChangeRemark = serverObject.StatusChangeRemark;
                    this.CareRequestInitiator = serverObject.CareRequestInitiator;
                }
            }
            CareRequest.prototype.enableModifying = function () {
                this.modifyingEnabled = true;
            };
            CareRequest.prototype.isModifiable = function () {
                return !this.careRequestStatus || this.careRequestStatus.Id < RemeCare.Shared.Contract.Code.CareRequestStatus.Final;
            };
            CareRequest.prototype.isReadOnly = function () {
                if (this.isModified === true) {
                    return true;
                }
                return (!this.modifyingEnabled &&
                    (this.readOnly || !this.isModifiable() || this.template.CareRequestStepSequence < this.stepSequence));
            };
            CareRequest.prototype.toServerWrite = function (isPermanent, allowedComorbidities, allowedTreatedDiseases) {
                var comorbidities = _(this.mapToDiseaseIds(this.comorbidities, allowedComorbidities)).map(function (c) {
                    return {
                        diseaseId: c,
                        type: RemeCare.Shared.Contract.Code.DiseaseType.Comorbidity,
                    };
                });
                var treatedDiseases = _(this.mapToDiseaseIds(this.treatedDiseases, allowedTreatedDiseases)).map(function (t) {
                    return {
                        diseaseId: t,
                        type: RemeCare.Shared.Contract.Code.DiseaseType.TreatedDisease,
                    };
                });
                var therpyOptions = _(this.therapyOptions).map(function (to) { return to.toServerWrite(); });
                var dates = _(this.careRequestDates).map(function (crd) { return crd.toServerWrite(); });
                var strings = _(this.careRequestStrings).map(function (crs) { return crs.toServerWrite(); });
                var signatures = _(this.careRequestSignatures).map(function (crs) { return crs.toServerWrite(); });
                var anamneses = this.anamneses.map(function (a) { return a.toServerWrite(); });
                return {
                    id: this.id,
                    stepSequence: this.stepSequence,
                    stepType: this.stepType.Id,
                    therapyId: this.therapyId,
                    isPermanent: isPermanent,
                    matchedPartyId: this.matchedPartyId,
                    matchedPatientId: this.matchedPatientId,
                    intermediaryHealthCareOrganisationType: this.intermediaryHealthCareOrganisationTypeId,
                    intermediarySpecialty: this.intermediarySpecialtyId,
                    patient: this.patient != null ? this.patient.toServerWrite() : null,
                    careRequestor: this.careRequestor.toServerWrite(),
                    roles: _(this.careRequestRoles).map(function (crr) { return crr.toServerWrite(); }),
                    patientContactPersons: _(this.patientContactPersons).map(function (pcp) { return pcp.toServerWrite(); }),
                    parameterValues: therpyOptions
                        .concat(dates)
                        .concat(strings)
                        .concat(anamneses)
                        .concat(signatures),
                    documents: _(this.careRequestDocuments).map(function (c) { return c.toServerWrite(); }),
                    sufferedDiseases: comorbidities.concat(treatedDiseases),
                    medications: _(this.careRequestMedications).map(function (c) { return c.toServerWrite(); }),
                    consultations: _(this.consultations).map(function (c) { return c.toServerWrite(); }),
                    hospitalisations: _(this.hospitalisations).map(function (h) { return h.toServerWrite(); }),
                    externalReferences: this.externalReferences,
                    timestamp: this.timestamp,
                    statusChangeRemark: this.statusChangeRemark,
                };
            };
            CareRequest.prototype.mapToDiseaseIds = function (therapyDiseasesIds, allowedDiseases) {
                if (!therapyDiseasesIds ||
                    therapyDiseasesIds.length === 0 ||
                    !allowedDiseases ||
                    allowedDiseases.length === 0) {
                    return []; // No matches to return
                }
                var diseaseIds = [];
                therapyDiseasesIds.forEach(function (therapyDiseaseId) {
                    var filtered = allowedDiseases.filter(function (td) { return td.TherapyDiseaseId === therapyDiseaseId; })[0];
                    if (filtered != null && filtered.Disease != null && filtered.Disease.Id) {
                        diseaseIds.push(filtered.Disease.Id);
                    }
                });
                return diseaseIds;
            };
            CareRequest.prototype.mapToTherapyDiseaseIds = function (diseaseIds, allowedDiseases) {
                if (!diseaseIds || diseaseIds.length === 0 || !allowedDiseases || allowedDiseases.length === 0) {
                    return []; // No matches to return
                }
                var therapyDiseaseIds = [];
                diseaseIds.forEach(function (diseaseId) {
                    var filtered = allowedDiseases.filter(function (d) { return d.Disease.Id === diseaseId; })[0];
                    if (filtered != null && filtered.TherapyDiseaseId) {
                        therapyDiseaseIds.push(filtered.TherapyDiseaseId);
                    }
                });
                return therapyDiseaseIds;
            };
            return CareRequest;
        }());
        CareRequest_1.CareRequest = CareRequest;
        var TherapyOption = /** @class */ (function () {
            function TherapyOption(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                    this.option = serverObject.Option;
                }
            }
            TherapyOption.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: RemeCare.Shared.Contract.Code.CareRequestPartType.TherapyOption,
                    definitionId: this.careRequestPartLocalIdentity,
                    boolValue: this.option,
                };
            };
            return TherapyOption;
        }());
        CareRequest_1.TherapyOption = TherapyOption;
        var CareRequestSignature = /** @class */ (function () {
            function CareRequestSignature(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                    this.signatureImage = serverObject.SignatureImage;
                }
            }
            CareRequestSignature.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: RemeCare.Shared.Contract.Code.CareRequestPartType.Signature,
                    definitionId: this.careRequestPartLocalIdentity,
                    stringValue: this.signatureImage,
                };
            };
            return CareRequestSignature;
        }());
        CareRequest_1.CareRequestSignature = CareRequestSignature;
        var CareRequestString = /** @class */ (function () {
            function CareRequestString(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                    this.string = serverObject.String;
                }
            }
            CareRequestString.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: RemeCare.Shared.Contract.Code.CareRequestPartType.String,
                    definitionId: this.careRequestPartLocalIdentity,
                    stringValue: this.string,
                };
            };
            return CareRequestString;
        }());
        CareRequest_1.CareRequestString = CareRequestString;
        var CareRequestDate = /** @class */ (function () {
            function CareRequestDate(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                    this.date = moment.utc(serverObject.Date).toDate();
                }
            }
            CareRequestDate.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: RemeCare.Shared.Contract.Code.CareRequestPartType.Date,
                    definitionId: this.careRequestPartLocalIdentity,
                    dateTimeValue: RemeCare.Shared.DateHelper.toServerDateString(this.date),
                };
            };
            return CareRequestDate;
        }());
        CareRequest_1.CareRequestDate = CareRequestDate;
        var CareRequestDocument = /** @class */ (function () {
            function CareRequestDocument(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestDocumentDefinitionId = serverObject.CareRequestPartLocalIdentity;
                    this.name = serverObject.Name;
                    this.url = serverObject.Url;
                    this.referenceDate =
                        serverObject.ReferenceDate != null ? moment(serverObject.ReferenceDate).toDate() : null;
                    this.document = angular.copy(serverObject);
                }
            }
            CareRequestDocument.createFromDocument = function (document, careRequestDocumentDefinitionId) {
                var result = new CareRequestDocument();
                result.id = document.Id;
                result.url = document.Url;
                result.name = document.Name;
                result.document = document;
                result.careRequestDocumentDefinitionId = careRequestDocumentDefinitionId;
                return result;
            };
            CareRequestDocument.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    definitionId: this.careRequestDocumentDefinitionId,
                    name: this.name,
                    url: this.url,
                    referenceDate: RemeCare.Shared.DateHelper.toServerDateString(this.referenceDate),
                    size: this.document.Size,
                };
            };
            return CareRequestDocument;
        }());
        CareRequest_1.CareRequestDocument = CareRequestDocument;
        var CareRequestor = /** @class */ (function () {
            function CareRequestor(serverObject) {
                this.contactPerson = new CareRequest_1.RequestPerson();
                this.responsible = new CareRequest_1.RequestPerson();
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.specialityId = serverObject.SpecialityId;
                    this.contactPersonFunction = serverObject.ContactPersonFunction;
                    this.healthCareOrganisationId = serverObject.HealthCareOrganisationId;
                    this.healthCareProfessionalId = serverObject.HealthCareProfessionalId;
                    this.contactPerson = new CareRequest_1.RequestPerson(serverObject.ContactPerson);
                    this.responsible = new CareRequest_1.RequestPerson(serverObject.Responsible);
                }
            }
            CareRequestor.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    speciality: this.specialityId,
                    contactPersonFunction: this.contactPersonFunction,
                    healthCareOrganisationId: this.healthCareOrganisationId,
                    healthCareProfessionalId: this.healthCareProfessionalId,
                    contactPerson: this.contactPerson.toServerWrite(),
                    responsible: this.responsible.toServerWrite(),
                };
            };
            return CareRequestor;
        }());
        CareRequest_1.CareRequestor = CareRequestor;
        var CareRequestRole = /** @class */ (function () {
            function CareRequestRole(serverObject) {
                this.requestParty = new CareRequest_1.RequestParty();
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.roleId = serverObject.RoleId;
                    this.partyRoleId = serverObject.PartyRoleId;
                    this.groupId = serverObject.GroupId;
                    if (serverObject.RequestParty != null) {
                        if (serverObject.RequestParty.Type === RemeCare.Shared.Contract.Code.PartyType.Person) {
                            this.requestParty = new CareRequest_1.RequestPerson(serverObject.RequestParty);
                        }
                        else {
                            this.requestParty = new CareRequest_1.RequestOrganisation(serverObject.RequestParty);
                        }
                    }
                }
            }
            CareRequestRole.create = function (roleId, partyType) {
                var result = new CareRequestRole();
                result.roleId = roleId;
                if (partyType === RemeCare.Shared.Contract.Code.PartyType.Organisation) {
                    result.requestParty = new CareRequest_1.RequestOrganisation();
                }
                else {
                    result.requestParty = new CareRequest_1.RequestPerson();
                }
                return result;
            };
            CareRequestRole.createForParty = function (roleId, requestParty) {
                var result = new CareRequestRole();
                result.roleId = roleId;
                result.requestParty = requestParty;
                result.groupId = requestParty.group && requestParty.group.groupId;
                return result;
            };
            CareRequestRole.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    roleId: this.roleId,
                    partyRoleId: this.partyRoleId,
                    groupId: this.groupId,
                    requestParty: this.partyRoleId == null ? this.requestParty.toServerWrite() : null,
                };
            };
            CareRequestRole.prototype.getName = function () {
                if (this.matchedParty != null) {
                    return this.matchedParty.getName();
                }
                return this.requestParty.getName();
            };
            return CareRequestRole;
        }());
        CareRequest_1.CareRequestRole = CareRequestRole;
        var CareRequestMedication = /** @class */ (function () {
            function CareRequestMedication(serverObject) {
                this.periodicities = [];
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    this.remarkPeriodicity = serverObject.RemarkPeriodicity;
                    this.structuralTherapyActionId = serverObject.StructuralTherapyActionId;
                    var factory_1 = new RemeCare.Shared.Framework.Factory.PeriodicityFactory();
                    this.periodicities = _(serverObject.Periodicities).map(function (p) { return factory_1.createPeriodicity(p); });
                    this.productId = serverObject.ProductId;
                    this.isSimplePrescription = this.canBeShownAsSimplePrescription();
                    this.prescribedAdministrationInformation = new RemeCare.Patient.Model.PrescribedAdministrationInformation(serverObject.PrescribedAdministrationInformation);
                    this.solventToAddInformation = new RemeCare.Patient.Model.SolventToAddInformation(serverObject.SolventToAddInformation);
                }
                else {
                    this.solventToAddInformation = new RemeCare.Patient.Model.SolventToAddInformation(null);
                    this.prescribedAdministrationInformation = new RemeCare.Patient.Model.PrescribedAdministrationInformation(null);
                }
            }
            CareRequestMedication.prototype.toServerWrite = function () {
                var toReturn = {
                    id: this.id,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                    remark: this.remarkPeriodicity,
                    structuralTherapyActionId: this.structuralTherapyActionId,
                    productId: this.medication.ProductId,
                    periodicities: _(this.periodicities).map(function (p) { return p.toWriteModel(); }),
                };
                if (this.solventToAddInformation != null) {
                    toReturn.solventToAddInformation = this.solventToAddInformation.toServerWrite();
                }
                else {
                    toReturn.solventToAddInformation = null;
                }
                if (this.prescribedAdministrationInformation != null) {
                    toReturn.prescribedAdministrationInformation = this.prescribedAdministrationInformation.toServerWrite();
                }
                else {
                    toReturn.prescribedAdministrationInformation = null;
                }
                return toReturn;
            };
            CareRequestMedication.prototype.canBeShownAsSimplePrescription = function () {
                if (this.periodicities != null && this.periodicities.length === 1) {
                    var periodicity = this.periodicities[0];
                    if (_(periodicity.intraDayTimings).any(function (idt) {
                        return idt.noEatingWindowBefore != null ||
                            idt.noEatingWindowAfter != null ||
                            moment(idt.preferredTime).minutes() !== 0;
                    })) {
                        return false;
                    }
                    if (periodicity.hasDuration) {
                        return false;
                    }
                    if (periodicity.recurrence != null &&
                        periodicity.recurrence.Quantity === 1 &&
                        periodicity.recurrence.Unit === RemeCare.Shared.Contract.Code.DurationUnit.Days) {
                        return (this.prescribedAdministrationInformation == null ||
                            this.prescribedAdministrationInformation.prescribedAdministrationMeans == null ||
                            this.prescribedAdministrationInformation.prescribedAdministrationMeans < 1);
                    }
                }
                return false;
            };
            CareRequestMedication.prototype.makeDefault = function () {
                var factory = new RemeCare.Shared.Framework.Factory.PeriodicityFactory();
                var periodicity = factory.createPeriodicityFromDurationUnit(RemeCare.Shared.Contract.Code.DurationUnit.Days);
                periodicity.recurrence.Quantity = 1;
                this.periodicities = [periodicity];
                this.isAdHoc = false;
                this.isSimplePrescription = true;
            };
            CareRequestMedication.prototype.makeAdHoc = function (patientLang, masterdataSvc, $translate) {
                var periodicity = new Periodicity();
                periodicity.unstructuredTargetQuantity = 1;
                this.isAdHoc = true;
                this.isSimplePrescription = false;
                this.periodicities = [periodicity];
                if (!this.isFollowedUp) {
                    return;
                }
                // Set 'If needed' as text for followed up ad hoc medication
                if (patientLang) {
                    masterdataSvc.getTranslation(patientLang, 'Views.Patient.Medication.IfNeeded').success(function (trans) {
                        var ifNeededTrans = trans['Views.Patient.Medication.IfNeeded'];
                        periodicity.unstructuredPeriodicity = ifNeededTrans;
                    });
                }
                else {
                    var ifNeededTrans = $translate.instant('Views.Patient.Medication.IfNeeded');
                    periodicity.unstructuredPeriodicity = ifNeededTrans;
                }
            };
            return CareRequestMedication;
        }());
        CareRequest_1.CareRequestMedication = CareRequestMedication;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
