var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var PatientFileSufferedDisease = /** @class */ (function () {
                function PatientFileSufferedDisease(serverObject) {
                    if (serverObject != null) {
                        this.patientId = serverObject.PatientId;
                        this.disease = serverObject.Disease;
                        this.remark = serverObject.Remark;
                        this.diagnosisDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.DiagnosisDate);
                        this.sufferedDiseaseTherapies = _(serverObject.SufferedDiseaseTherapies)
                            .chain().map(function (x) { return x.Therapy; }).uniq(function (x) { return x.Id; }).value();
                    }
                    else {
                        this.sufferedDiseaseTherapies = [];
                    }
                }
                PatientFileSufferedDisease.prototype.getTherapyList = function () {
                    return _(this.sufferedDiseaseTherapies).map(function (x) { return x.Text; }).join(", ");
                };
                PatientFileSufferedDisease.prototype.addTherapyLink = function (therapy) {
                    if (!_(this.sufferedDiseaseTherapies).chain().map(function (x) { return x.Id; }).contains(therapy.Id).value()) {
                        this.sufferedDiseaseTherapies.push(therapy);
                    }
                };
                PatientFileSufferedDisease.prototype.toServerWrite = function () {
                    return {
                        DiseaseId: this.disease.Id,
                        PatientId: this.patientId,
                        DiagnosisDate: this.diagnosisDate !== null ? RemeCare.Shared.DateHelper.toServerDateString(this.diagnosisDate) : null,
                        Remark: this.remark,
                        SufferedDiseaseTherapies: _(this.sufferedDiseaseTherapies).map(function (x) { return x.Id; })
                    };
                };
                return PatientFileSufferedDisease;
            }());
            Model.PatientFileSufferedDisease = PatientFileSufferedDisease;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
