namespace RemeCare.CarePlanAction {
    interface IRemarkScope extends IComponentScope {
        hasStandardPhrases: boolean;

        standardPhrases: string[];
        languages: Shared.Contract.IEnumTranslation[];
        loadStandardPhrases: (langCode: string) => void;

        component: RemarkDescriptor;

        addTimestamp(): void;
    }

    class RemarkController extends ComponentControllerBase<IRemarkScope> {
        // @ngInject
        constructor(
            $scope: IRemarkScope,
            private $element: ng.IAugmentedJQuery,
            private toaster: Shared.Framework.Toaster,
            private $filter: ng.IFilterService,
            private authservice: Shared.Framework.AuthService,
            private carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private masterdataSvc: Shared.Framework.MasterdataService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);

            $scope.loadStandardPhrases = (langCode: string) => this.loadStandardPhrases(langCode);
            $scope.addTimestamp = () => this.addTimestamp();
        }

        public addTimestamp(): void {
            if (!this.$scope.component.enabled || this.$scope.readOnly) { return; }
            let textArea = this.$element.find('textarea')[0] as HTMLTextAreaElement;
            let cursorPos = this.getCursorPosition(textArea);
            let timeStamp = this.$filter('date')(new Date(), 'short');
            let text = textArea.value;
            text =
                text.slice(0, cursorPos) +
                '[' +
                this.authservice.getClaim(Shared.Framework.ClaimTypes.firstname) +
                ' ' +
                this.authservice.getClaim(Shared.Framework.ClaimTypes.lastname) +
                ' ' +
                timeStamp +
                '] ' +
                text.slice(cursorPos, text.length);
            if (text.length > 3000) {
                text = text.slice(0, 3000);
            }
            this.$scope.component.value = text;

            textArea.focus();
        }

        protected init(): void {
            this.$scope.hasStandardPhrases = this.$scope.component.hasStandardPhrases;

            this.masterdataSvc
                .getInterfaceLanguages()
                .success((r) => {
                    this.$scope.languages = r;
                })
                .error((e) => this.toaster.error(e));
        }

        private getCursorPosition(textArea: HTMLTextAreaElement) {
            let iCaretPos = 0;

            if ((document as any).selection) {
                textArea.focus();
                let oSel = (document as any).selection.createRange();
                oSel.moveStart('character', -textArea.value.length);
                iCaretPos = oSel.text.length;
            } else if (textArea.selectionStart || textArea.selectionStart === 0) {
                iCaretPos = textArea.selectionStart;
            }
            return iCaretPos;
        }

        private loadStandardPhrases(langCode: string) {
            this.carePlanActionApiSvc
                .getStandardPhrases(this.$scope.carePlanAction.actionTemplateId, langCode)
                .success((ags) => {
                    this.$scope.standardPhrases = _(ags).uniq();
                })
                .error((e) => this.toaster.error(e));
        }
    }

    class RemarkDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/remark.html';

        public controller = RemarkController;
    }

    angular.module('RemeCare.CarePlanAction').directive('rcRemark', () => new RemarkDirective());
}
