var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ToggleUserStateController = /** @class */ (function (_super) {
            __extends(ToggleUserStateController, _super);
            function ToggleUserStateController($scope, $translate, toaster, partyId, securityApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.partyId = partyId;
                _this.securityApiSvc = securityApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            ToggleUserStateController.prototype.$onInit = function () { };
            ToggleUserStateController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.userDetails.IsBlocked) {
                    this.securityApiSvc.unblockUser(this.partyId, this.$scope.userDetails.StatusChangeReason)
                        .success(function () {
                        _this.$scope.userDetails.IsBlocked = false;
                        _this.$scope.userDetails.LastStateChange = RemeCare.Shared.DateHelper.toServerDateTimeString(new Date());
                        _this.$scope.$close(_this.$scope.userDetails);
                    })
                        .error(function (e) { return _this.errorCallback(e); });
                }
                else {
                    this.securityApiSvc.blockUser(this.partyId, this.$scope.userDetails.StatusChangeReason)
                        .success(function () {
                        _this.$scope.userDetails.IsBlocked = true;
                        _this.$scope.userDetails.LastStateChange = RemeCare.Shared.DateHelper.toServerDateTimeString(new Date());
                        _this.$scope.$close(_this.$scope.userDetails);
                    })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            return ToggleUserStateController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('toggleUserStateCtrl', function ($scope, $translate, toaster, partyId, securityApiSvc) { return new ToggleUserStateController($scope, $translate, toaster, partyId, securityApiSvc); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
