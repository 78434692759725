module RemeCare.Management {
    
    interface IGlobalActorRoleTranslationsScope extends ng.ui.bootstrap.IModalScope, Shared.Framework.IBaseScope {
        languages: Array<Shared.Contract.IEnumTranslation>;
        form: ng.IFormController;
        editMode: boolean;
        globalActorRole: GlobalActorRole;
        confirm(): void;
    }

    class GlobalActorRoleTranslationsController extends Shared.Framework.ControllerBase<IGlobalActorRoleTranslationsScope> {
        
        constructor(
            $scope: IGlobalActorRoleTranslationsScope,
            $translate,
            toaster,
            private masterdataSvc: Shared.Framework.MasterdataService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.masterdataSvc.getInterfaceLanguages()
                .success(r => this.$scope.languages = r)
                .error(e => this.errorCallback(e));
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.globalActorRole);
        }
    }

    remeCareManagementModule.controller('globalActorRoleTranslationsCtrl', (
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ) => new GlobalActorRoleTranslationsController(
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ));
}