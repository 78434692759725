namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import DateFilterType = Contract.Core.Codes.DateFilterTypeCode;
    export abstract class PatientRegistrationsBaseController implements ng.IComponentController {
        public dateFilterTypes: EnumTranslation[];
        public carePlanActionStatuses: EnumTranslation[];
        public persons: EntityTranslation[];
        public carePlanTranslations: EntityTranslation[];
        public carePlans: CarePlan[];
        public actorRoles: RemeCare.Contract.Therapy.Read.IActorRoleSummary[];
        private patientActorRoles: RemeCare.Contract.Therapy.Read.IActorRoleSummary[];
        private mergedPatientActorRoleId: Shared.Contract.Guid;

        constructor(
            protected $stateParams: PatientFileStateParams,
            protected $filter: ng.IFilterService,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: Patient.PatientService,
            protected masterdataSvc: Shared.Framework.MasterdataService,
            protected actorRolesApiSvc: Core.Services.ActorRolesApiService
        ) { }

        public $onInit(): void {
            this.loadDataAsync();
            this.init();
        }

        protected abstract init(): void;

        protected abstract dataLoaded(): void;

        protected insertAllPatientRoles(actorRoleIds: Shared.Contract.Guid[]): Shared.Contract.Guid[] {
            if (!actorRoleIds) {
                return actorRoleIds;
            }

            // No merged patient actor role set
            if (!this.mergedPatientActorRoleId) {
                return actorRoleIds;
            }

            // Patient actor role is not selected
            if (!_.contains(actorRoleIds, this.mergedPatientActorRoleId)) {
                return actorRoleIds;
            }

            // Add all patient actor roles to the array of actor role ids, union filters duplicates
            return _.union(actorRoleIds, _.map(this.patientActorRoles, ar => ar.Id));
        }

        private async loadDataAsync(): Promise<void> {
            try {
                let dateFilterTypes: EnumTranslation[];
                let cps: Contract.Patient.Read.ICareplan[];
                [dateFilterTypes, cps, this.carePlanActionStatuses, this.actorRoles, this.persons] = await Promise.all([
                    this.masterdataSvc.getDateFilterTypesAsync(),
                    this.patientSvc.getCarePlansAsync(this.$stateParams.patientId),
                    this.masterdataSvc.getCarePlanActionStatuses(),
                    this.actorRolesApiSvc.findActorRolesAsync({ PatientId: this.$stateParams.patientId }),
                    this.patientSvc.getActionExecutorsAsync(this.$stateParams.patientId),
                ]);

                this.patientActorRoles = _.filter(this.actorRoles, ar => ar.IsPatient);
                if (this.patientActorRoles.length > 1) {
                    // If there are multiple patient actor roles, only show 1
                    // The this.insertAllPatientRoles() function can be used to add all patient actor roles
                    //  to a filter if the 'one' patient actor role was selected
                    const mergedPatientActorRole = this.patientActorRoles[0];
                    mergedPatientActorRole.Text = this.$translate.instant('General.Patient');
                    this.mergedPatientActorRoleId = mergedPatientActorRole.Id;
                    this.actorRoles = _.filter(
                        this.actorRoles,
                        ar => !ar.IsPatient || ar.Id === this.mergedPatientActorRoleId
                    );
                }
                this.actorRoles = _.sortBy(this.actorRoles, a => (a.Text ? a.Text.toLowerCase() : ''));

                this.dateFilterTypes = _(dateFilterTypes).filter(
                    x =>
                        x.Id === DateFilterType.PlannedDate ||
                        x.Id === DateFilterType.ExecutionDate ||
                        x.Id === DateFilterType.ModificationDate
                );

                this.carePlans = CarePlan.getSortedOpenCloseCareplans(cps);
                this.carePlanTranslations = _(this.carePlans).map(x => x.getSummary(this.$translate, this.$filter));
                this.addAllCarePlansOption(this.carePlanTranslations);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private addAllCarePlansOption(carePlans: EntityTranslation[]): void {
            carePlans.unshift({
                Id: null,
                Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
            } as EntityTranslation);
        }
    }
}
