namespace RemeCare.Patient {
    import MonitoringPart = Shared.Framework.Model.MonitoringPart;
    import MonitoringPartTable = Shared.Framework.Model.MonitoringPartTable;

    export interface ICarePlanMonitoringPart {
        carePlanIds: Shared.Contract.Guid[];
        monitoringPart: Shared.Framework.Model.MonitoringPart;
    }

    class MonitoringPartsController implements ng.IController {
        // bindings
        public globalFrom: Date;
        public globalUntil: Date;
        public maxActivatedIndex: number;
        public monitoringParts: ICarePlanMonitoringPart[];
        public onFromChanged: () => void;
        public onUntilChanged: () => void;

        private childScope: ng.IScope;

        constructor(
            private readonly $compile: ng.ICompileService,
            private readonly $element: ng.IAugmentedJQuery,
            private readonly $scope: ng.IScope
        ) {}

        public $onInit(): void {
            this.render();
        }

        public $onChanges(changes: ng.IOnChangesObject): void {
            if (
                (changes.globalFrom && !changes.globalFrom.isFirstChange() && changes.globalFrom.currentValue) ||
                (changes.globalUntil && !changes.globalUntil.isFirstChange() && changes.globalUntil.currentValue) ||
                (changes.monitoringParts &&
                    !changes.monitoringParts.isFirstChange() &&
                    changes.monitoringParts.currentValue)
            ) {
                this.render();
            }
        }

        public onPartLoaded = () => {
            this.maxActivatedIndex++;
        };

        private render(): void {
            this.childScope && this.childScope.$destroy();
            this.childScope = this.$scope.$new();
            this.maxActivatedIndex = 3;
            let html = '';

            this.monitoringParts = this.monitoringParts || [];
            for (let i = 0; i < this.monitoringParts.length; i++) {
                html += this.getDirectiveHtml(this.monitoringParts[i].monitoringPart, i);
            }
            this.$element.html(html).show();
            this.$compile(this.$element.contents())(this.childScope);
        }

        private getDirectiveHtml(part: MonitoringPart, index: number): string {
            let directiveName;
            switch (part.type) {
                case Shared.Contract.Code.MonitoringPartType.LineChartNumeric:
                    directiveName = 'line-graph';
                    break;
                case Shared.Contract.Code.MonitoringPartType.TableNumericQualitative:
                    directiveName = 'parameters-table';
                    directiveName += (part as MonitoringPartTable).invert ? '-inverted' : '';
                    break;
                case Shared.Contract.Code.MonitoringPartType.CumulativeBarChart:
                    directiveName = 'cumulative-bar-graph';
                    break;
                case Shared.Contract.Code.MonitoringPartType.FloatingBarChart:
                    directiveName = 'floating-bar-graph';
                    break;
                case Shared.Contract.Code.MonitoringPartType.ColourQualitativeTimeLine:
                    directiveName = 'colour-qualitative';
                    break;
                case Shared.Contract.Code.MonitoringPartType.MedicationAdherenceChart:
                    directiveName = 'therapy-compliance-graph';
                    break;
                case Shared.Contract.Code.MonitoringPartType.MedicationDoseChart:
                    directiveName = 'medication-dose-chart';
                    break;
                case Shared.Contract.Code.MonitoringPartType.ActionTable:
                    directiveName = 'action-table';
                    break;
                case Shared.Contract.Code.MonitoringPartType.ActionTimeLine:
                    directiveName = 'action-time-line';
                    break;
                case Shared.Contract.Code.MonitoringPartType.CumulativeObjectiveChart:
                    directiveName = 'cumulative-objective-graph';
                    break;
                case Shared.Contract.Code.MonitoringPartType.ObjectiveScoreTable:
                    directiveName = 'objective-score-table';
                    directiveName += (part as MonitoringPartTable).invert ? '-inverted' : '';
                    break;
                case Shared.Contract.Code.MonitoringPartType.LineChartNumericExternal:
                    directiveName = 'line-chart-numeric-external';
                    break;
                case Shared.Contract.Code.MonitoringPartType.Boxplot:
                    directiveName = 'boxplot';
                    break;
                default:
                    return '<p>Part not implemented</p>';
            }

            let widthClass: string;
            switch (part.width) {
                case Shared.Contract.Code.PartWidth.Full:
                    widthClass = 'col-md-12';
                    break;
                case Shared.Contract.Code.PartWidth.Half:
                    widthClass = 'col-md-6';
                    break;
                case Shared.Contract.Code.PartWidth.Third:
                    widthClass = 'col-md-4';
                    break;
                case Shared.Contract.Code.PartWidth.TwoThird:
                    widthClass = 'col-md-8';
                    break;
                default:
                    widthClass = 'col-md-12';
                    break;
            }
            return `<rc-monitoring-${directiveName} ng-if="${index} < $ctrl.maxActivatedIndex" monitoring-part="$ctrl.monitoringParts[${index}].monitoringPart" care-plan-ids="$ctrl.monitoringParts[${index}].carePlanIds" patient-id="$ctrl.patientId" global-from="$ctrl.globalFrom" global-until="$ctrl.globalUntil" on-from-changed="$ctrl.onFromChanged()" on-until-changed="$ctrl.onUntilChanged()" on-part-loaded="$ctrl.onPartLoaded()" hide-when-no-data="$ctrl.hidePartsWithoutData" show-legend="$ctrl.showGraphLegends" width-class="${widthClass}"></rc-monitoring-${directiveName}>`;
        }
    }

    remeCarePatientModule.component('rcMonitoringParts', {
        bindings: {
            globalFrom: '<',
            globalUntil: '<',
            hidePartsWithoutData: '<',
            monitoringParts: '<',
            onFromChanged: '&',
            onUntilChanged: '&',
            patientId: '<',
            showGraphLegends: '<',
        },
        controller: MonitoringPartsController,
    } as ng.IComponentOptions);
}
