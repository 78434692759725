var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var IntakeMoment = /** @class */ (function () {
            function IntakeMoment() {
            }
            IntakeMoment.prototype.toWrite = function () {
                return {
                    Id: this.intraDayTimingId,
                    PreferredTime: RemeCare.Shared.DateHelper.toServerTimeString(this.preferredTime),
                    Quantity: this.quantity
                };
            };
            IntakeMoment.buildFromIIntakeMoment = function (intakeMoment) {
                var result = new IntakeMoment();
                result.intraDayTimingId = intakeMoment.Id;
                result.quantity = intakeMoment.Quantity;
                result.preferredTime = RemeCare.Shared.DateHelper.serverTimeToDate(intakeMoment.PreferredTime);
                return result;
            };
            return IntakeMoment;
        }());
        Model.IntakeMoment = IntakeMoment;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
