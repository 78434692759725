var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanStatusModalController = /** @class */ (function (_super) {
            __extends(CarePlanStatusModalController, _super);
            function CarePlanStatusModalController($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, therapyApiSvc, $dialog) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.$state = $state;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.$dialog = $dialog;
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            CarePlanStatusModalController.prototype.$onInit = function () {
                var _this = this;
                this.therapyApiSvc.getCarePlanStatusReasons(this.$scope.carePlan.therapyId, RemeCare.Shared.Contract.Code.CarePlanStatus.Stopped)
                    .success(function (ags) {
                    _this.$scope.reasons = ags;
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            CarePlanStatusModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$dialog.confirmBox('Views.PatientFile.ChangeCarePlanStatus', 'Views.PatientFile.StopCarePlanText', function () {
                    _this.carePlanApiSvc.changeCarePlanStatus(_this.$scope.carePlan.id, _this.$scope.selectedReason, RemeCare.Shared.Contract.Code.CarePlanStatus.Stopped)
                        .success(function () {
                        _this.toaster.success(_this.$translate.instant('Views.PatientFile.ChangeCarePlanStatus.Success'));
                        _this.$scope.$close();
                        _this.$state.reload();
                    })
                        .error(function (m) { return _this.errorCallback(m); });
                });
            };
            return CarePlanStatusModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('carePlanStatusModalCtrl', function ($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, therapyApiSvc, $dialog) { return new CarePlanStatusModalController($scope, $translate, toaster, $stateParams, $state, carePlanApiSvc, therapyApiSvc, $dialog); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
