namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import ActionType = Shared.Contract.Code.ActionType;
    import CarePlan = Contract.Patient.Read.ICareplan;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Grid = Shared.Framework.Grid.Grid;
    import PatientFileDocument = Contract.Patient.Read.IPatientFileDocument;
    import SearchResult = Shared.Contract.ISearchResult;
    import StructuralTherapyActionQuery = Contract.Patient.Read.IStructuralTherapyActionQuery;
    import PatientFileDocumentsQuery = Contract.Patient.Read.IFindPatientFileDocumentsQuery;

    class PatientFileDocumentsController implements ng.IComponentController {
        protected static injectionParameters = ['baseUrl'];
        public carePlans: CarePlan[];
        public sources: EnumTranslation[];
        public structuralTherapyActions: EntityTranslation[];
        public grid: Grid<PatientFileDocument>;

        private patientId: Guid;

        constructor(
            private readonly $dialog: Shared.Service.DialogService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly fileIconSvc: Core.Services.FileIconService,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly uiGridConstants: uiGrid.IUiGridConstants,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly baseUrl: string
        ) {}

        public async $onInit(): Promise<void> {
            this.buildGrid();

            const structuralTherapyActionQuery: StructuralTherapyActionQuery = {
                actionTypeCodes: [ActionType.RequestInformationObject],
            };
            try {
                [this.carePlans, this.sources, this.structuralTherapyActions] = await Promise.all([
                    this.patientSvc.getCarePlansAsync(this.patientId),
                    this.masterdataSvc.getReferenceDataAsync(
                        Shared.Framework.ReferenceDataTypes.patientFileDocumentSource
                    ),
                    this.patientSvc.getStructuralTherapyActionsAsync(this.patientId, structuralTherapyActionQuery),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): Promise<SearchResult<PatientFileDocument>> {
            const query = {
                ...criteria,
                page,
                pageSize,
                sortField,
                sortOrder,
                fromDate: criteria.fromDate ? Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                untilDate: criteria.untilDate ? Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
            };

            try {
                return await this.patientSvc.findDocumentsAsync(this.patientId, query);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private buildGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<PatientFileDocument>((p, ps, sf, so, c) => this.executeSearch(p, ps, sf, so, c))
                .setBindToUrl()
                .setSearchWhenReady()
                .addColumn('Name', 'General.Name')
                .addColumn('CarePlan', 'General.CarePlan')
                .addColumn('DocumentCategories', 'General.Category', { cellFilter: 'delimitedDisplay : ", "' })
                .addEnumColumn(
                    'Source',
                    'General.Source',
                    Shared.Framework.ReferenceDataTypes.patientFileDocumentSource,
                    null,
                    (pfd) => this.getActionText(pfd)
                )
                .addDateColumn('UploadDateTime', 'Document.CreationDate', 'short', {
                    sort: { direction: this.uiGridConstants.DESC },
                })
                .addColumn('Uploader', 'Document.UploadedBy')
                .addIconRefColumn((pfd) => this.getComposedUrl(pfd), null, (pfd) => this.getIcon(pfd));
            if (
                this.authservice.hasRight(
                    Shared.Framework.AuthContext.patientDocuments,
                    Shared.Framework.AuthRight.Write
                )
            ) {
                gridBuilder = gridBuilder.addNavigationColumn(
                    'pencil',
                    'patients.patientfile.mediaDocuments.documents',
                    { documentId: 'Id' }
                );
            }
            if (this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.Superuser) {
                gridBuilder = gridBuilder.addConditionalDeleteButtonColumn(
                    (pfd) => pfd.Source === Contract.Core.Codes.PatientFileDocumentSource.Action,
                    null,
                    (pfd) => this.deleteDocument(pfd)
                );
            }
            this.grid = gridBuilder.build();
        }

        private getComposedUrl(patientFileDocument: PatientFileDocument): string {
            return Shared.DocumentHelper.fixDocumentUrl(
                patientFileDocument.Url,
                this.baseUrl,
                patientFileDocument.Name
            );
        }

        private deleteDocument(patientFileDocument: PatientFileDocument): Promise<boolean> {
            return new Promise<boolean>((resolve) => {
                this.$dialog.confirmBox(
                    patientFileDocument.Name,
                    'Document.DeleteDocumentConfirmation',
                    async () => {
                        try {
                            await this.patientSvc.deleteDocumentAsync(this.patientId, patientFileDocument.Id);
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private getActionText(patientFileDocument: PatientFileDocument): string {
            if (patientFileDocument.Action) {
                return `: ${patientFileDocument.Action}`;
            }
            return '';
        }

        private getIcon(patientFileDocument: PatientFileDocument): string {
            const extension = patientFileDocument.Name ? patientFileDocument.Name.split('.').pop() : null;
            return this.fileIconSvc.getFileIcon(extension);
        }
    }

    remeCarePatientModule.component('patientFileDocuments', {
        controller: PatientFileDocumentsController,
        templateUrl: 'views/patient/mediaDocuments/patientFileDocuments.html',
        bindings: {
            patientId: '<',
        },
    });
}
