var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var SolventToAddInformation = /** @class */ (function () {
                function SolventToAddInformation(serverObject) {
                    if (serverObject != null) {
                        this.solventToAddUnit = serverObject.SolventToAddUnit;
                        this.solventToAddQuantity = serverObject.SolventToAddQuantity;
                        this.solventToAddType = serverObject.SolventToAddType;
                    }
                }
                SolventToAddInformation.prototype.toServerWrite = function () {
                    return (this.solventToAddQuantity != null &&
                        this.solventToAddUnit != null) ||
                        this.solventToAddType != null
                        ? {
                            SolventToAddUnit: this.solventToAddUnit,
                            SolventToAddQuantity: this.solventToAddQuantity,
                            SolventToAddType: this.solventToAddType
                        }
                        : null;
                };
                SolventToAddInformation.prototype.clear = function () {
                    this.solventToAddType = null;
                    this.solventToAddQuantity = null;
                    this.solventToAddUnit = null;
                };
                return SolventToAddInformation;
            }());
            Model.SolventToAddInformation = SolventToAddInformation;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
