module RemeCare.Management {
    import FindPersonMergeCandidatesQuery = Contract.Party.Read.Query.IFindPersonMergeCandidatesQuery;
    import ContactPointUsageType = Shared.Contract.Code.ContactPointUsageType;
    import Guid = Shared.Contract.Guid;
    import MergePersonCandidate = Model.MergePersonCandidate;

    class MergePersonController implements ng.IComponentController {
        public person: Model.Person;
        public mergeEffectives: Array<Model.MergePersonCandidate>;
        public mergeCandidates: Array<Model.MergePersonCandidate>;
        public mergeCandiateGrid: Shared.Framework.Grid.Grid<Model.MergePersonCandidate>;
        public candidatesSearched: boolean;
        public mergePersonInfo: Guid[];

        constructor(
            private $dialog: Shared.Service.DialogService,
            private $translate: ng.translate.ITranslateService,
            private toaster: Shared.Framework.Toaster,
            private partyApiSvc: Core.Services.PartyApiService,
            private patientSvc: Patient.PatientService,
            private $stateParams,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public $onInit() {
            this.candidatesSearched = false;
            this.buildGrids();
        }

        private buildGrids(): void {
            this.mergeCandiateGrid = this.gridBuilderSvc
                .createGridBuilder<Model.MergePersonCandidate>()
                .addColumn('capacities', 'General.Capacity')
                .addColumn('carePlanNames', 'General.CarePlan')
                .addExternalLinkColumn(
                    'General.Details',
                    'General.LookDetails',
                    'management.persons.detail',
                    '_blank',
                    { partyId: 'personId' }
                )
                .addCheckBoxColumn('Views.Management.Merge', 'isMergeEffective')
                .build();
        }

        public checkForMergeCandidates(): void {
            this.candidatesSearched = true;
            const domicileAddress = _.find(
                this.person.addresses,
                x => x.contactPointUsageType === ContactPointUsageType.Domicile
            );
            const query = <FindPersonMergeCandidatesQuery>{
                id: this.person.id,
                firstName: this.person.firstName,
                lastName: this.person.lastName,
                identificationNumber: this.person.nationalNumber,
                birthDate: Shared.DateHelper.toServerDateString(this.person.dateOfBirth)
            };

            if (domicileAddress != null) {
                query.address = domicileAddress.addressLine1;
                query.zipCode = domicileAddress.zipCode;
                query.country = domicileAddress.country;
            }

            this.partyApiSvc
                .findPersonMergeCandidates(query)
                .success(r => {
                    this.mergeCandidates = r.map(x => new MergePersonCandidate(x));
                    this.mergeCandidates.forEach(x => {
                        x.capacities = x.partyRoles.map(x => x.Type.Text).join(' -');
                        x.carePlans = new Array();
                        const patients = x.partyRoles.filter(
                            x => x.Type.Id === Contract.Core.Codes.PartyRoleTypeCode.Patient
                        );
                        patients.forEach(p => {
                            this.patientSvc
                                .getCarePlans(p.Id)
                                .success(r => {
                                    x.carePlans = x.carePlans.concat(r);
                                    x.carePlanNames = x.carePlans.map(x => x.Name).join(' -');
                                })
                                .error(e => this.toaster.error(e));
                        });
                    });
                    this.mergeCandiateGrid.setData(this.mergeCandidates);
                })
                .error(e => this.toaster.error(e));
        }

        private healthCareDialogBox(): void {
            this.$dialog.messageBox('Views.Management.MergeSelected', 'Views.Management.HealthCareWarning', [
                { result: 'Ok', label: 'General.Ok', cssClass: 'btn btn-default' }
            ]);
        }

        private nonHealthCareDialogBox(): void {
            this.$dialog
                .warningMessageBox(
                    this.$translate.instant('Views.Management.MergeSelected'),
                    this.$translate.instant('Views.Management.PopUpWarning'),
                    [
                        { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                        {
                            result: 'yes',
                            label: this.$translate.instant('Views.Management.Merge'),
                            cssClass: 'btn-primary'
                        }
                    ]
                )
                .then(result => {
                    if (result === 'yes') {
                        this.mergePersonInfo = this.mergeCandidates
                            .filter(x => x.isMergeEffective)
                            .map(x => x.personId);
                        this.partyApiSvc
                            .mergePersons(this.$stateParams.partyId, this.mergePersonInfo)
                            .success(() => {
                                this.candidatesSearched = false;
                            })
                            .error(e => {
                                this.toaster.error(e);
                            });
                    }
                });
        }

        private hasPartyRoleHealthCare(id): boolean {
            return (
                id === Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional ||
                id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional
            );
        }

        public confirmMerge(): void {
            if (this.mergeCandidates.filter(x => x.isMergeEffective).length === 0) {
                this.toaster.error(this.$translate.instant('Views.Management.NoMergeEffectiveSelected'));
                return;
            }

            //check if our survivor has type healthcare
            const survivorHealthCare = _.find(this.person.partyRoles, x =>
                this.hasPartyRoleHealthCare(x.PartyRoleType.Id)
            );

            //check how many merge candidates have healthcareprovider roles
            const mergeCandidatesHealthCare = _.filter(
                this.mergeCandidates,
                x => _.find(x.partyRoles, y => this.hasPartyRoleHealthCare(y.Type.Id)) != null
            );

            //if more than two mergecandidates have are healthcareproviders or if the merge candidate and at least one mergecandidate have it we can't merge
            if (
                (survivorHealthCare != null && mergeCandidatesHealthCare.length >= 1) ||
                mergeCandidatesHealthCare.length >= 2
            ) {
                this.healthCareDialogBox();
            } else {
                this.nonHealthCareDialogBox();
            }
        }
    }

    RemeCare.Management.remeCareManagementModule.component('rcMergePerson', {
        controller: MergePersonController,
        controllerAs: 'mergePersonCtrl',
        bindings: {
            person: '<'
        },
        templateUrl: 'views/management/persons/mergeCandidates.html'
    });
}
