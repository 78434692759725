namespace RemeCare.Framework {
    interface SignaturePad {
        on(): void;
        off(): void;
        clear(): void;
        fromDataURL(data: string, opts?: any): void;
        toDataURL(): string;
        addEventListener(e: string, fn: () => void): void;
    }

    declare var SignaturePad;
    class SignaturePadController implements ng.IComponentController {
        public model: string;
        public readonly: boolean;
        public required: boolean;
        public uniqueName: string;
        public internalModel: string;
        private canvas: Element;
        private modelUpdated: (args: { newModel: string }) => void;

        private signaturePad: SignaturePad;
        constructor(
            private readonly $element: ng.IAugmentedJQuery,
            private readonly idService: Shared.Framework.Service.IIdService
        ) {}

        public $onInit(): void {
            this.uniqueName = this.idService.generateId();
            this.canvas = this.getCanvas();
            this.signaturePad = new SignaturePad(this.canvas);
            if (this.model) {
                this.signaturePad.fromDataURL(this.model, { ratio: 1 });
                this.internalModel = this.model;
            }

            if (this.readonly) {
                this.signaturePad.off();
            }

            this.signaturePad.addEventListener('endStroke', () => {
                // For some reason, two-way binding did not always update the model in the calling component.
                // Especially on TempSave an ad-hoc action. See PF-3038 for the original bug
                // I'm probably using angular wrong but using an explicit update callback is my fix.
                this.internalModel = this.cropSignatureCanvas(this.canvas);
                this.modelUpdated({ newModel: this.internalModel });
            });
        }

        public $onChanges(changes: ng.IOnChangesObject): void {
            if (!this.signaturePad) {
                return;
            }
            if (changes.readonly) {
                if (changes.readonly.currentValue) {
                    this.signaturePad.off();
                } else {
                    this.signaturePad.on();
                }
            }
        }

        public clear(): void {
            this.signaturePad.clear();
            this.model = null;
        }

        private getCanvas(): Element {
            return this.$element.find('.signature-pad')[0];
        }

        // https://github.com/szimek/signature_pad/issues/49#issuecomment-867566006
        private cropSignatureCanvas(canvas: any): any {
            const originalCtx = canvas.getContext('2d');

            const originalWidth = canvas.width;
            const originalHeight = canvas.height;
            const imageData = originalCtx.getImageData(0, 0, originalWidth, originalHeight);

            let minX = originalWidth + 1,
                maxX = -1,
                minY = originalHeight + 1,
                maxY = -1,
                x = 0,
                y = 0,
                currentPixelColorValueIndex;

            for (y = 0; y < originalHeight; y++) {
                for (x = 0; x < originalWidth; x++) {
                    currentPixelColorValueIndex = (y * originalWidth + x) * 4;
                    const currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
                    if (currentPixelAlphaValue > 0) {
                        if (minX > x) {
                            minX = x;
                        }
                        if (maxX < x) {
                            maxX = x;
                        }
                        if (minY > y) {
                            minY = y;
                        }
                        if (maxY < y) {
                            maxY = y;
                        }
                    }
                }
            }

            const croppedWidth = maxX - minX;
            const croppedHeight = maxY - minY;
            if (croppedWidth < 0 || croppedHeight < 0) {
                return null;
            }
            const cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);

            const croppedCanvas = document.createElement('canvas'),
                croppedCtx = croppedCanvas.getContext('2d');

            croppedCanvas.width = croppedWidth;
            croppedCanvas.height = croppedHeight;
            croppedCtx.putImageData(cuttedImageData, 0, 0);

            return croppedCanvas.toDataURL();
        }
    }

    remeCareAppModule.component('rcSignaturePad', {
        bindings: {
            model: '<?model',
            readonly: '<?ngReadonly',
            required: '=ngRequired',
            modelUpdated: '&',
        },
        controller: SignaturePadController,
        require: {
            formCtrl: '^form',
        },
        templateUrl: 'framework/components/signaturePad/signaturePad.html',
    });
}
