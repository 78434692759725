/// <reference path="../../contract/core/codes/genderCode.ts"/>
var RemeCare;
(function (RemeCare) {
    var GenderCode = RemeCare.Contract.Core.Codes.GenderCode;
    var Nationality = RemeCare.Shared.Contract.Code.Nationality;
    var NationalityIso2 = RemeCare.Shared.Contract.Code.NationalityIso2;
    var NationalNumberService = /** @class */ (function () {
        function NationalNumberService(toaster) {
            this.toaster = toaster;
        }
        NationalNumberService.prototype.isValidNationalNumber = function (nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationalNumber || !/^\d{11}$/.test(nationalNumber)) {
                return false;
            }
            return this.isNumberFromBefore2000(nationalNumber) || this.isNumberFrom2000Onwards(nationalNumber);
        };
        NationalNumberService.prototype.isValidDateOfBirth = function (dateOfBirth, nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!dateOfBirth || !nationalNumber) {
                return true;
            }
            var dateOfBirthByNationalNumber = this.getDateOfBirth(nationalNumber);
            return !dateOfBirthByNationalNumber || moment(dateOfBirth).isSame(dateOfBirthByNationalNumber, 'day');
        };
        NationalNumberService.prototype.getDateOfBirth = function (nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!this.isValidNationalNumber(nationalNumber)) {
                return null;
            }
            var century = this.isNumberFromBefore2000(nationalNumber) ? 1900 : 2000;
            var year = parseInt(nationalNumber.substring(0, 2));
            // If national number without entry in national register,
            // 20 or 40 is added to month
            var month = parseInt(nationalNumber.substring(2, 4));
            if (month - 40 >= 0) {
                month = month - 40;
            }
            else if (month - 20 >= 0) {
                month = month - 20;
            }
            var day = parseInt(nationalNumber.substring(4, 6));
            if (day === 0 || month === 0) {
                return null;
            }
            return new Date(century + year, month - 1, day);
        };
        NationalNumberService.prototype.isValidGender = function (gender, nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!gender || !nationalNumber) {
                return true;
            }
            var genderByNationalNumber = this.getGender(nationalNumber);
            return (!genderByNationalNumber ||
                genderByNationalNumber === GenderCode.Unknown ||
                gender === genderByNationalNumber);
        };
        NationalNumberService.prototype.getGender = function (nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!this.isValidNationalNumber(nationalNumber)) {
                return null;
            }
            // If national number without entry in national register,
            // 20 is added to month if gender is unknown, otherwise 40 is added
            var month = parseInt(nationalNumber.substring(2, 4));
            var genderUnknown = month - 20 >= 0 && month - 40 < 0;
            if (genderUnknown) {
                return GenderCode.Unknown;
            }
            var sequenceNumber = parseInt(nationalNumber.substring(6, 9));
            return sequenceNumber % 2 === 0 ? GenderCode.Female : GenderCode.Male;
        };
        NationalNumberService.prototype.isValidNationality = function (nationality, nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationality || !nationalNumber) {
                return true;
            }
            var nationalityByNationalNumber = this.getNationality(nationalNumber);
            return !nationalityByNationalNumber || nationality === nationalityByNationalNumber;
        };
        NationalNumberService.prototype.getNationality = function (nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            return this.isBelgian(nationalNumber) ? Nationality.Belgian : null;
        };
        NationalNumberService.prototype.isValidNationalityCode = function (nationality, nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationality || !nationalNumber) {
                return true;
            }
            var nationalityByNationalNumber = this.getNationalityCode(nationalNumber);
            return !nationalityByNationalNumber || nationality === nationalityByNationalNumber;
        };
        NationalNumberService.prototype.getNationalityCode = function (nationalNumber) {
            nationalNumber = this.sanitize(nationalNumber);
            return this.isBelgian(nationalNumber) ? NationalityIso2.Belgian : null;
        };
        NationalNumberService.prototype.validateAndSetProperties = function (nationalNumber, dateOfBirthGetterSetter, genderGetterSetter, nationalityGetterSetter, nationalityCodeGetterSetter, overwrite) {
            nationalNumber = this.sanitize(nationalNumber);
            if (this.isValidNationalNumber(nationalNumber)) {
                if (dateOfBirthGetterSetter) {
                    var dateOfBirth = this.getDateOfBirth(nationalNumber);
                    if (dateOfBirth) {
                        if (!dateOfBirthGetterSetter()) {
                            overwrite && dateOfBirthGetterSetter(dateOfBirth);
                        }
                        else if (!this.isValidDateOfBirth(dateOfBirthGetterSetter(), nationalNumber)) {
                            this.toaster.warning('General.Validation.DateOfBirthDoesNotMatchNationalNumber');
                        }
                    }
                }
                if (genderGetterSetter) {
                    var gender = this.getGender(nationalNumber);
                    if (!genderGetterSetter()) {
                        overwrite && genderGetterSetter(gender);
                    }
                    else if (!this.isValidGender(genderGetterSetter(), nationalNumber)) {
                        this.toaster.warning('General.Validation.GenderDoesNotMatchNationalNumber');
                    }
                }
                if (nationalityGetterSetter) {
                    var nationality = this.getNationality(nationalNumber);
                    if (nationality) {
                        if (!nationalityGetterSetter()) {
                            overwrite && nationalityGetterSetter(nationality);
                        }
                        else if (!this.isValidNationality(nationalityGetterSetter(), nationalNumber)) {
                            this.toaster.warning('General.Validation.NationalityDoesNotMatchNationalNumber');
                        }
                    }
                }
                if (nationalityCodeGetterSetter) {
                    var nationality = this.getNationalityCode(nationalNumber);
                    if (!nationalityCodeGetterSetter()) {
                        overwrite && nationalityCodeGetterSetter(nationality);
                    }
                    else if (!this.isValidNationalityCode(nationalityCodeGetterSetter(), nationalNumber)) {
                        this.toaster.warning('General.Validation.NationalityDoesNotMatchNationalNumber');
                    }
                }
            }
        };
        NationalNumberService.prototype.sanitize = function (nationalNumber) {
            if (!nationalNumber) {
                return nationalNumber;
            }
            return nationalNumber.replace(/\D/g, '');
        };
        NationalNumberService.prototype.isBelgian = function (nationalNumber) {
            if (!this.isValidNationalNumber(nationalNumber)) {
                return false;
            }
            var month = parseInt(nationalNumber.substring(2, 4));
            // If national number without entry in national register,
            // 20 or 40 is added to month. In this case we can't derive nationality
            return month - 20 < 0;
        };
        NationalNumberService.prototype.isNumberFromBefore2000 = function (nationalNumber) {
            var number = parseInt(nationalNumber.substring(0, 9));
            var modulo = 97 - (number % 97);
            return modulo === parseInt(nationalNumber.substring(9));
        };
        NationalNumberService.prototype.isNumberFrom2000Onwards = function (nationalNumber) {
            var number = parseInt("2" + nationalNumber.substring(0, 9));
            var modulo = 97 - (number % 97);
            return modulo === parseInt(nationalNumber.substring(9));
        };
        return NationalNumberService;
    }());
    RemeCare.NationalNumberService = NationalNumberService;
    RemeCare.remeCareAppModule.service('nationalNumberSvc', NationalNumberService);
})(RemeCare || (RemeCare = {}));
