(function (b, a?) {
    if (!b) { return }
    var c = b.Chart.prototype,
        d = (<any>b).Legend.prototype;
    (<any>b).extend(c,
    {
        legendSetVisibility: function(h) {
            var i = this, k = i.legend, e, g, j, m = i.options.legend, f, l;
            if (m.enabled == h) {
                return;
            }
            m.enabled = h;
            if (!h) {
                d.destroy.call(k);
                e = k.allItems;
                if (e) {
                    for (g = 0, j = e.length; g < j; ++g) {
                        e[g].legendItem = a;
                    }
                }
                k.group = {}
            }
            c.render.call(i);
            if (!m.floating) {
                f = i.scroller;
                if (f && f.render) {
                    l = i.xAxis[0].getExtremes();
                    f.render(l.min, l.max);
                }
            }
        },
        legendHide: function() {
            this.legendSetVisibility(false);
        },
        legendShow: function() {
            this.legendSetVisibility(true);
        },
        legendToggle: function(e) {
            if (typeof e != "boolean") {
                e = (this.options.legend.enabled ^ 1);
            }
            this.legendSetVisibility(e);
        }
    });
}(Highcharts));

interface HighchartsChartObject {
    legendHide(): void;
    legendSetVisibility(visible: boolean): void;
    legendShow(): void;
    legendToggle(visible?: boolean): void;
}