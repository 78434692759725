module RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    interface IAddUserScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        applicationProfiles: Array<EnumTranslation>;
        newUser: Shared.Contract.Write.INewUser;
        mailToOptions: Array<Shared.Contract.IItemTranslation<boolean>>;
        userNameProposal: string;
        userNameRegex: RegExp;
        confirm(): void;

        maxLength: number;
    }

    class AddUserController extends Shared.Framework.ControllerBase<IAddUserScope> {

        constructor(
            $scope: IAddUserScope,
            $translate,
            toaster,
            private $q: ng.IQService,
            private partyId: Shared.Contract.Guid,
            private firstName: string,
            private lastName: string,
            private securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private partyApiSvc: Core.Services.PartyApiService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.userNameRegex = /^[^"\/\\\[\]:;|=,+*?<>]{1,20}$/;
            this.$scope.maxLength = 18;

            this.$scope.mailToOptions = [
                {
                    Id: true,
                    Text: this.$translate.instant('General.User')
                }, {
                    Id: false,
                    Text: this.$translate.instant('Views.Management.Persons.RemecareMail')
                }
            ];
            var userName = `${this.firstName}.${this.lastName}`;
            userName = userName.replace(/\s/g, "");
            userName = userName.substr(0, 18);
            userName = userName.trim();
            this.$scope.newUser = <Shared.Contract.Write.INewUser>{
                userName: userName,
                partyId: this.partyId
            };
            this.$q.all([
                this.securityApiSvc.findAssignableApplicationProfiles(),
                this.partyApiSvc.findPartyRoleTypes(this.partyId)
            ])
                .then((r: any) => {
                    var profiles = <Array<EnumTranslation>>r[0].data;
                    var partyRoleTypes = <Array<EnumTranslation>>r[1].data;
                    profiles = profiles.filter(ap => {
                        if (ap.Id === Shared.Contract.Code.ApplicationProfileType.Superuser ||
                            ap.Id === Shared.Contract.Code.ApplicationProfileType.Configurator) {
                            return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.Employee);
                        } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                            ap.Id === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager ||
                            ap.Id === Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                            ap.Id === Shared.Contract.Code.ApplicationProfileType.TherapyProvider) {
                            return _(partyRoleTypes).any(
                                prt => prt.Id === Shared.Contract.Code.PartyRoleType.Employee ||
                                    prt.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional);
                        } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.Patient) {
                            return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.Patient);
                        } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.InformalCareProvider) {
                            return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional);
                        }
                        return false;
                    });
                    this.$scope.applicationProfiles = profiles;
                })
                .catch(e => this.errorCallback(e));
        }

        confirm(): void {
            if (this.$scope.newUser.userName.length > this.$scope.maxLength) {
                this.toaster.error(this.$translate.instant('Views.Management.Persons.UserNameTooLong'));
                return;
            }

            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.securityApiSvc.checkUserNameExists(this.$scope.newUser.userName)
                .success(r => {
                    if (r !== this.$scope.newUser.userName) {
                        this.$scope.userNameProposal = r;
                    } else {
                        this.securityApiSvc.createUser(this.$scope.newUser)
                            .success(() => this.$scope.$close())
                            .error(e => this.errorCallback(e));
                    }
                })
                .error(e => this.errorCallback(e));
        }
    }

    remeCareManagementModule.controller('addUserCtrl', AddUserController);
}