var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanDetailsController = /** @class */ (function () {
            function CarePlanDetailsController() {
            }
            CarePlanDetailsController.prototype.$onInit = function () {
                this.collapsed = true;
            };
            CarePlanDetailsController.prototype.toggleShowDetails = function () {
                this.collapsed = !this.collapsed;
            };
            CarePlanDetailsController.prototype.getRegistrationValue = function (detail) {
                if (detail.RegistrationValue == null) {
                    return '';
                }
                if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue) {
                    return detail.RegistrationValue.ChoiceListOption.Name;
                }
                else if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity) {
                    var unit = detail.Unit != null ? " " + detail.Unit.Text : '';
                    return "" + detail.RegistrationValue.Amount + unit;
                }
                else if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue) {
                    return _(detail.RegistrationValue.MultiChoiceListOptions)
                        .chain()
                        .sortBy(function (x) { return x.Sequence; })
                        .map(function (x) { return x.Name; })
                        .value()
                        .toString();
                }
                return '';
            };
            return CarePlanDetailsController;
        }());
        Patient.remeCarePatientModule.component('rcCarePlanDetails', {
            bindings: {
                carePlanDetails: '<',
            },
            controller: CarePlanDetailsController,
            templateUrl: 'views/patient/monitoring/dashboard/carePlanDetail.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
