'use strict';

namespace RemeCare.Contract.HealthCareParty.Read {
    import Guid = Shared.Contract.Guid;
    import TelephoneNumber = Contract.Core.ITelephoneNumber;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = RemeCare.Shared.Contract.IEnumTranslation;

    export interface IHealthCareParty {
        PartyRoleId: Guid;
        ZipCode: string;
        City: string;
        AddressLine: string;
        Email: string;
        PhoneNumber: TelephoneNumber;
        IdentificationNumber: string;
    }

    export interface IHealthCareProfessionalParty extends IHealthCareParty {
        LastName: string;
        FirstName: string;
        MobileNumber: TelephoneNumber;
        HealthCareSpecialtyProfessions: Shared.Contract.IEnumTranslation[];
        PartyId: Guid;
    }

    export interface IContactPerson extends IHealthCareProfessionalParty {
        Relationship: EnumTranslation;
        IsHealthCareNonProfessional: boolean;
        IsActive: boolean;
        PartyId: Guid;
        Remark: string;
    }

    export interface IHealthCareOrganisationParty extends IHealthCareParty {
        Name: string;
        HealthCareOrganisationType: EnumTranslation;
    }

    export interface ICareTeamParty {
        PartyRoleId: Guid;
        HealthCareProfessionalTypeId?: number;
        SpecialityTypeIds?: number[];
        Name: string;
        LastName: string;
        Assignments: ICareTeamPartyAssignment[];
    }

    export interface ICareTeamPartyAssignment {
        Id: Guid;
        ActorRole: EntityTranslation;
        CarePlan: Contract.Patient.Read.ICareplan;
        ValidFromDate: string;
        ValidUntilDate: string;
        CanEdit: boolean;
    }

    export interface IInformalParty {
        Id: Guid;
        PartyRoleId: Guid;
        FirstName: string;
        LastName: string;
        PhoneNumber: TelephoneNumber;
        MobileNumber: TelephoneNumber;
        ValidFromDate: string;
        ValidUntilDate: string;
    }
}
