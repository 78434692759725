var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PersonalisedContextSettingController = /** @class */ (function (_super) {
            __extends(PersonalisedContextSettingController, _super);
            function PersonalisedContextSettingController($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.carePlanApiSvc = carePlanApiSvc;
                $scope.modifyContextSetting = function (c) { return _this.modifyContextSetting(c); };
                $scope.canEdit = function (cs) { return _this.canEdit(cs); };
                $scope.cancel = function () { return _this.cancel(); };
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PersonalisedContextSettingController.prototype.$onInit = function () {
                var _this = this;
                this.buildGrid();
                this.$scope.$watch(function (s) { return s.personalisedAction; }, function (newValue, oldValue) {
                    if (newValue !== oldValue && newValue != null) {
                        _this.setData();
                    }
                });
                if (this.$scope.personalisedAction) {
                    this.setData();
                }
            };
            PersonalisedContextSettingController.prototype.setData = function () {
                var firstValue = _(this.$scope.personalisedAction.contextSettingVersions).chain()
                    .sortBy(function (t) { return t.validFromDate; })
                    .first()
                    .value();
                if (firstValue && moment(firstValue.validFromDate).isBefore(this.$scope.carePlanStartDate)) {
                    firstValue.validFromDate = this.$scope.carePlanStartDate;
                }
                this.$scope.personalisedContextSettingsGrid.setData(this.$scope.personalisedAction.contextSettingVersions);
                this.$scope.selectedContextSetting = _(this.$scope.personalisedAction.contextSettingVersions)
                    .chain()
                    .filter(function (cta) { return cta.validFromDate <= RemeCare.Shared.DateHelper.today(); })
                    .sortBy(function (cta) { return cta.validFromDate; })
                    .last()
                    .value();
            };
            PersonalisedContextSettingController.prototype.calcMinDate = function () {
                var today = moment(RemeCare.Shared.DateHelper.today());
                var fromDate;
                if (_(this.$scope.selectedContextSetting.contextSettings).any(function (cs) { return cs.id != null; })) {
                    var index = this.$scope.personalisedAction.contextSettingVersions.length - 2;
                    fromDate = index >= 0
                        ? moment(this.$scope.personalisedAction.contextSettingVersions[index].validFromDate)
                        : today;
                }
                else {
                    var lastVersion = _(this.$scope.personalisedAction.contextSettingVersions).last();
                    fromDate = lastVersion ? moment(lastVersion.validFromDate) : today;
                }
                this.$scope.minDate = fromDate.isAfter(today)
                    ? fromDate.add(1, 'days').toDate()
                    : today.add(1, 'days').toDate();
            };
            PersonalisedContextSettingController.prototype.buildGrid = function () {
                var _this = this;
                this.$scope.personalisedContextSettingsGrid = this.gridBuilderSvc.createGridBuilder()
                    .addColumn('validFromDate', 'General.Versions.ValidFrom', { cellFilter: 'date: "shortDate"' })
                    .addColumn('validUntilDate', 'General.Versions.ValidUntil', { cellFilter: 'date: "shortDate"' })
                    .addActionColumn('eye-open', function (ptacs) {
                    _this.$scope.selectedContextSetting = ptacs;
                    _this.$scope.editMode = false;
                })
                    .addConditionalDeleteButtonColumn(function (cs) { return !_this.canEdit(cs) || _this.$scope.editRights.readOnly; }, function (cs) { return _this.delete(cs); }, function (cs) { return _this.confirmDelete(cs); })
                    .setSorting(false)
                    .build();
                this.$scope.personalisedContextSettingsGrid.setData([]);
            };
            PersonalisedContextSettingController.prototype.canEdit = function (contextSetting) {
                if (!contextSetting)
                    return false;
                var lastContextSetting = _(this.$scope.personalisedAction.contextSettingVersions).last();
                return lastContextSetting != null
                    && contextSetting.validFromDate === lastContextSetting.validFromDate
                    && contextSetting.canBeEdited();
            };
            PersonalisedContextSettingController.prototype.delete = function (contextSetting) {
                this.$scope.actions.reload();
            };
            PersonalisedContextSettingController.prototype.confirmDelete = function (contextSetting) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.$dialog.confirmBox('General.Versions.DeleteVersionConfirm', 'General.Versions.DeleteVersionConfirmMessage', function () {
                    _this.carePlanApiSvc
                        .deletePersonalisedTherapyActionContextSetting(_this.$scope.carePlanId, _this.$scope.structuralTherapyAction.Id, contextSetting.validFromDate)
                        .success(function () { return deferred.resolve(true); })
                        .error(function (e) {
                        _this.errorCallback(e);
                        deferred.resolve(false);
                    });
                }, function () {
                    deferred.resolve(false);
                });
                return deferred.promise();
            };
            PersonalisedContextSettingController.prototype.modifyContextSetting = function (contextSetting) {
                this.$scope.previousSelectedContextSetting = this.$scope.selectedContextSetting;
                if (contextSetting) {
                    contextSetting = this.$scope.copy(contextSetting);
                }
                else {
                    contextSetting = new Patient.PersonalisedTherapyActionContextSetting();
                    var contextSettings = _(this.$scope.personalisedAction.personalisableContextSettings)
                        .map(function (pcs) {
                        var ptapcs = new Patient.PersonalisedTherapyActionPartContextSetting();
                        ptapcs.therapyActionPartId = pcs.therapyActionPartId;
                        ptapcs.canPersonaliseTelemonitoringDeactivation = pcs.canPersonaliseTelemonitoringDeactivation;
                        ptapcs.canPersonaliseToHidePart = pcs.canPersonaliseToHidePart;
                        ptapcs.name = pcs.name;
                        ptapcs.sequence = pcs.sequence;
                        return ptapcs;
                    });
                    contextSetting.setContextSettings(contextSettings);
                }
                this.$scope.selectedContextSetting = contextSetting;
                this.$scope.editMode = true;
                this.calcMinDate();
            };
            PersonalisedContextSettingController.prototype.cancel = function () {
                this.$scope.editMode = false;
                this.$scope.selectedContextSetting = this.$scope.previousSelectedContextSetting;
            };
            PersonalisedContextSettingController.prototype.confirm = function () {
                var _this = this;
                this.$scope.form.$setSubmitted();
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                var personalisedTherapyActionContextSetting = this.$scope.selectedContextSetting.toServerWrite();
                this.carePlanApiSvc.setPersonalisedTherapyActionContextSetting(this.$scope.carePlanId, this.$scope.structuralTherapyAction.Id, personalisedTherapyActionContextSetting)
                    .success(function () {
                    _this.$scope.editMode = false;
                    _this.toaster.success(_this.$translate.instant('General.SaveSuccess'));
                    _this.$scope.actions.reload();
                })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return PersonalisedContextSettingController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('personalisedContextSettingController', function ($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc) { return new PersonalisedContextSettingController($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
