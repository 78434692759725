/// <reference path="careRequestPartDirectiveBase.ts"/>
/// <reference path="../controllers/acceptanceControllerBase.ts"/>
/// <reference path="../../contract/core/codes/partyRoleTypeCode.ts"/>
/// <reference path="../../contract/core/codes/genderCode.ts"/>

namespace RemeCare.CareRequest.Directive {
    import Person = Contract.Party.Read.IPerson;
    import PartyRoleTypeCode = Contract.Core.Codes.PartyRoleTypeCode;
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import GenderCode = Contract.Core.Codes.GenderCode;

    interface IPatientAcceptanceScope extends ICareRequestPartBaseScope, IAcceptanceControllerScope {
        noNumberRegex: RegExp;
        commlanguages: EnumCodeTranslation[];
        countries: EnumCodeTranslation[];
        genders: EnumTranslation[];
        maritalStatusses: EnumTranslation[];
        educations: EnumTranslation[];
        technologicalAffinities: EnumTranslation[];
        nationalities: EnumCodeTranslation[];
        newPatient: boolean;
        contactPersonsGrid: Shared.Framework.Grid.Grid<PatientContactPerson>;
        now: Date;

        carePlans: EntityTranslation[];

        updateMatch: boolean;

        onMatchedPatientChanged: () => void;

        addContactPerson(): void;
        isNewPatientRequired(controlType: CareRequestUIControlType): boolean;
        isRequired(controlType: CareRequestUIControlType): boolean;
        identificationNumberChanged(): void;
        birthDateChanged(): void;
        genderChanged(): void;
        nationalityChanged(): void;
        isBirthDateValid(): boolean;
        isGenderValid(): boolean;
        isNationalityValid(): boolean;
    }

    class PatientAcceptanceController extends AcceptanceControllerBase<IPatientAcceptanceScope> {
        private matchedPartyId: Shared.Contract.Guid;
        private matchedPatientId: Shared.Contract.Guid;

        // @ngInject
        constructor(
            protected $scope: IPatientAcceptanceScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected partyApiSvc: Core.Services.PartyApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly nationalNumberSvc: NationalNumberService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientSvc: Patient.PatientService,
            private readonly authservice: Shared.Framework.AuthService
        ) {
            super($scope, $translate, toaster, gridBuilderSvc, partyApiSvc);
            $scope.now = new Date();

            $scope.addContactPerson = () => this.addContactPersonAsync();
            $scope.identificationNumberChanged = () => this.identificationNumberChanged();
            $scope.birthDateChanged = () => this.birthDateChanged();
            $scope.genderChanged = () => this.genderChanged();
            $scope.nationalityChanged = () => this.nationalityChanged();

            $scope.isBirthDateValid = () => this.isBirthDateValid();
            $scope.isGenderValid = () => this.isGenderValid();
            $scope.isNationalityValid = () => this.isNationalityValid();

            $scope.copyToSearch = (src, dest) => this.copyToSearch(src, dest, this.$scope.careRequest.patient);
            $scope.updateField = p => this.updateField(p, this.$scope.careRequest.patient);
            $scope.personSelected = p => this.personSelected(p);
            $scope.isNewPatientRequired = v => this.isNewPatientRequired(v);
            this.$onInit();
        }

        public async $onInit(): Promise<void> {
            super.$onInit();
            this.buildContactPersonsGrid();
            this.$scope.newPatient = this.$scope.careRequest.matchedPartyId ? false : null;
            if (
                this.$scope.newPatient == null &&
                this.$scope.careRequest.careRequestStatus != null &&
                this.$scope.careRequest.careRequestStatus.Id === Shared.Contract.Code.CareRequestStatus.Final
            ) {
                this.$scope.newPatient = true;
            }
            if (this.$scope.newPatient) {
                this.matchedPartyId = this.$scope.careRequest.matchedPartyId;
                this.matchedPatientId = this.$scope.careRequest.matchedPatientId;
            } else {
                this.matchedPartyId = null;
                this.matchedPatientId = null;
            }

            this.$scope.updateMatch = this.authservice.hasRight(
                Shared.Framework.AuthContext.patient,
                Shared.Framework.AuthRight.Write
            );
            if (this.matchedPartyId != null) {
                if (this.matchedPatientId != null) {
                    this.getCarePlansForPatientAsync(this.matchedPatientId);
                }
                try {
                    const person = await this.partyApiSvc.getPersonAsync(this.matchedPartyId);
                    this.$scope.selectedPerson = RequestPerson.createFromPerson(person);
                    this.$scope.selectedPerson.updatePerson(this.$scope.careRequest.patient);
                } catch (e) {
                    this.toaster.error(e);
                }
            }
            this.$scope.$watch('newPatient', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue) {
                        this.$scope.careRequest.matchedPartyId = null;
                        this.$scope.careRequest.matchedPatientId = null;
                        this.$scope.carePlans = null;
                    } else {
                        this.$scope.careRequest.matchedPartyId = this.matchedPartyId;
                        this.$scope.careRequest.matchedPatientId = this.matchedPatientId;
                        this.getCarePlansForPatientAsync(this.matchedPatientId);
                    }
                    this.$scope.onMatchedPatientChanged();
                }
            });
            this.loadMasterdataAsync();

            this.$scope.noNumberRegex = Framework.Regexes.noNumberRegex;
            this.enrichContactPersons();
        }

        public identificationNumberChanged(): void {
            this.validateIdentificationNumberAndSetProperties(true, true, true, true);
        }

        public isBirthDateValid(): boolean {
            return this.nationalNumberSvc.isValidDateOfBirth(
                this.$scope.careRequest.patient.birthDate,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public birthDateChanged(): void {
            this.validateIdentificationNumberAndSetProperties(true, false, false);
        }

        public isGenderValid(): boolean {
            return this.nationalNumberSvc.isValidGender(
                this.$scope.careRequest.patient.gender,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public genderChanged(): void {
            this.validateIdentificationNumberAndSetProperties(false, true, false);
        }

        public isNationalityValid(): boolean {
            return this.nationalNumberSvc.isValidNationalityCode(
                this.$scope.careRequest.patient.nationality,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public nationalityChanged(): void {
            this.validateIdentificationNumberAndSetProperties(false, false, true);
        }

        private async loadMasterdataAsync(): Promise<void> {
            try {
                [
                    this.$scope.commlanguages,
                    this.$scope.countries,
                    this.$scope.genders,
                    this.$scope.maritalStatusses,
                    this.$scope.educations,
                    this.$scope.technologicalAffinities,
                    this.$scope.nationalities,
                ] = await Promise.all([
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.masterdataSvc.getMaritalStatussesAsync(),
                    this.masterdataSvc.getEducationsAsync(),
                    this.masterdataSvc.getTechnologicalAffinitiesAsync(),
                    this.masterdataSvc.getNationalitiesAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private isNewPatientRequired(type: CareRequestUIControlType): boolean {
            return this.$scope.isRequired(type) && (this.$scope.newPatient || this.$scope.newPatient == null);
        }

        private async getCarePlansForPatientAsync(patientId: Shared.Contract.Guid): Promise<void> {
            if (!patientId) {
                this.$scope.carePlans = [];
                return;
            }

            try {
                const ags = await this.patientSvc.getCarePlansAsync(patientId);
                this.$scope.carePlans = _(ags)
                    .map(x => new Model.Careplan(x))
                    .map(x => {
                        return {
                            Id: x.id,
                            Text: x.statusOpen
                                ? x.name +
                                  this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                                      CarePlanStatus: x.status,
                                      StatusDate: x.getFormattedStartDate(),
                                  })
                                : x.name +
                                  this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                                      CarePlanStatus: x.status,
                                      StatusDate: x.getFormattedStatusChangedDate(),
                                  }),
                        };
                    });
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private enrichContactPersons(): void {
            _(this.$scope.careRequest.patientContactPersons).each(async pcp => {
                if (pcp.matchedPartyId == null) {
                    return;
                }
                try {
                    const contactPerson = await this.partyApiSvc.getPersonAsync(pcp.matchedPartyId);
                    pcp.matchedParty = RequestPerson.createFromPerson(contactPerson);
                } catch (e) {
                    this.toaster.error(e);
                }
            });
            this.$scope.contactPersonsGrid.setData(this.$scope.careRequest.patientContactPersons);
        }

        private validateIdentificationNumberAndSetProperties(
            checkDateOfBirth: boolean,
            checkGender: boolean,
            checkNationality: boolean,
            overwrite?: boolean
        ): void {
            const dateOfBirthGetterSetter =
                checkDateOfBirth && this.$scope.isPresent(CareRequestUIControlType.PatientBirthDate)
                    ? (dateOfBirth?: Date) => {
                          if (dateOfBirth) {
                              this.$scope.careRequest.patient.birthDate = dateOfBirth;
                          }
                          return this.$scope.careRequest.patient.birthDate;
                      }
                    : null;
            const genderGetterSetter =
                checkGender && this.$scope.isPresent(CareRequestUIControlType.PatientGender)
                    ? (gender?: GenderCode) => {
                          if (gender) {
                              this.$scope.careRequest.patient.gender = gender;
                          }
                          return this.$scope.careRequest.patient.gender;
                      }
                    : null;
            const nationalityGetterSetter =
                checkNationality && this.$scope.isPresent(CareRequestUIControlType.PatientNationality)
                    ? (nationality?: string) => {
                          if (nationality) {
                              this.$scope.careRequest.patient.nationality = nationality;
                          }
                          return this.$scope.careRequest.patient.nationality;
                      }
                    : null;

            this.nationalNumberSvc.validateAndSetProperties(
                this.$scope.careRequest.patient.identificationNumber,
                dateOfBirthGetterSetter,
                genderGetterSetter,
                null,
                nationalityGetterSetter,
                overwrite
            );
        }

        private personSelected(person: Person): void {
            this.$scope.searchOpen = false;
            this.$scope.selectedPerson = RequestPerson.createFromPerson(person);
            this.$scope.careRequest.patient.update = new RequestPersonUpdate();
            this.$scope.careRequest.patient.contactInfo.update = new RequestContactInfoUpdate();
            this.matchedPartyId = person.PartyId;
            const patient = _.find(person.PartyRoles, r => r.Type.Id === PartyRoleTypeCode.Patient);
            this.matchedPatientId = patient ? patient.Id : null;

            if (!this.$scope.newPatient) {
                this.$scope.careRequest.matchedPartyId = this.matchedPartyId;
                this.$scope.careRequest.matchedPatientId = this.matchedPatientId;
                this.getCarePlansForPatientAsync(this.matchedPatientId);
                this.$scope.onMatchedPatientChanged();
            } else {
                this.$scope.careRequest.matchedPartyId = null;
                this.$scope.careRequest.matchedPatientId = null;
            }
        }

        private buildContactPersonsGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<PatientContactPerson>()
                .addColumn('lastName()', 'General.LastName')
                .addColumn('firstName()', 'General.FirstName')
                .addColumn('type.Text', 'Views.CareRequest.Details.CareTeamInformal.SupportType')
                .addBoolColumn('isHCNonProfessional', 'Views.CareRequest.Details.HCNonProf');
            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn(p => this.editContactPersonAsync(p))
                    .addDeleteButtonColumn();
            }
            this.$scope.contactPersonsGrid = gridBuilder.build();
        }

        private async addContactPersonAsync(): Promise<void> {
            const newPerson = await this.editContactPersonAsync();
            this.$scope.contactPersonsGrid.addRow(newPerson);
        }

        private editContactPersonAsync(contactPerson?: PatientContactPerson): Promise<PatientContactPerson> {
            return new Promise<PatientContactPerson>((resolve, reject) => {
                this.modalBuilderFactory
                    .createModalBuilder<PatientContactPerson>()
                    .setController('contactPersonAcceptanceCtrl')
                    .setTemplateUrl('views/careRequest/directive/contactPersonAcceptance/contactPersonAcceptance.html')
                    .setScope({
                        contactPerson: angular.copy(contactPerson) || new PatientContactPerson(),
                        careRequestPart: this.$scope.careRequestPart,
                    })
                    .setResultCallBack(p => resolve(p))
                    .setDismissCallBack(() => reject())
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .build();
            });
        }
    }

    class PatientAcceptanceDirective extends CareRequestPartDirectiveBase {
        public controller = PatientAcceptanceController;

        public templateUrl = 'views/careRequest/directive/patientAcceptance/patientAcceptance.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestPatientAcceptance', () => new PatientAcceptanceDirective());
}
