var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        var ApiServiceBase = RemeCare.Shared.Framework.ApiServiceBase;
        var IntegrationMessageService = /** @class */ (function (_super) {
            __extends(IntegrationMessageService, _super);
            function IntegrationMessageService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            IntegrationMessageService.prototype.getIntegrationMessageDetailAsync = function (id) {
                return this.getPromise("IntegrationMessages/" + id, null, 'getIntegrationMessageDetail');
            };
            IntegrationMessageService.prototype.retryIntegrationMessageAsync = function (id) {
                return this.postPromise("IntegrationMessages/" + id + "/retry", null);
            };
            IntegrationMessageService.prototype.archiveIntegrationMessageAsync = function (id) {
                return this.postPromise("IntegrationMessages/" + id + "/archive", null);
            };
            IntegrationMessageService.prototype.unarchiveIntegrationMessageAsync = function (id) {
                return this.postPromise("IntegrationMessages/" + id + "/unarchive", null);
            };
            IntegrationMessageService.$inject = ApiServiceBase.injectionParameters;
            return IntegrationMessageService;
        }(ApiServiceBase));
        IntegrationMonitor.IntegrationMessageService = IntegrationMessageService;
        IntegrationMonitor.remeCareIntegrationMonitorModule.service('integrationMessageSvc', IntegrationMessageService);
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
