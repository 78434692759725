module RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import RuleThreshold = Shared.Framework.Model.RuleThresholdDefinition;
    import RuleThresholdValue = Shared.Framework.Model.RuleThresholdValue;


    interface IPatientFileRuleThresholdOverviewScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlan: CarePlan;
        patientId: Guid;

        threshold: RuleThreshold;
        defaultThreshold: RuleThreshold;

        valueGrid: Shared.Framework.Grid.Grid<RuleThresholdValue>;

        readOnly: boolean;
        addRuleThresholdValue: () => void;
        confirm: () => void;
    }

    class PatientFileRuleThresholdOverviewController extends Shared.Framework.ControllerBase<IPatientFileRuleThresholdOverviewScope> {

        constructor(
            $scope: IPatientFileRuleThresholdOverviewScope,
            $translate,
            toaster,
            private $stateParams,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private therapyApiSvc: Core.Services.TherapyApiService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super($scope, $translate, toaster);
            this.$scope.addRuleThresholdValue = () => this.addRuleThresholdValue();
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit() {
            this.$scope.patientId = this.$stateParams.patientId;
            var firstValue = _(this.$scope.threshold.ruleThresholdValues).chain()
                .sortBy(t => t.validFromDate)
                .first()
                .value();

            if (moment(firstValue.validFromDate).isBefore(this.$scope.carePlan.startDate)) {
                firstValue.validFromDate = this.$scope.carePlan.startDate;
            }

            this.buildValueGrid();
            this.getDefaultRuleThreshold();
        }

        private confirm(): void {
            this.$scope.$close(this.$scope.threshold);
        }

        private getDefaultRuleThreshold(): void {
            this.therapyApiSvc.getRuleThreshold(this.$scope.carePlan.therapyId, this.$scope.threshold.id)
                .success((ags) => {
                    this.$scope.defaultThreshold = new RuleThreshold(ags);
                })
                .error((m) => this.errorCallback(m));
        }

        private buildValueGrid(): void {
            this.$scope.valueGrid = this.gridBuilderSvc.createGridBuilder<RuleThresholdValue>()
                .addColumn("getValueString()", "Views.PatientFile.CarePlans.RuleThresholdValue")
                .addColumn("validFromDate", "General.ValidFrom", { cellFilter: 'date: "shortDate"' })
                .addColumn("validUntilDate", "General.ValidUntil", { cellFilter: 'date: "shortDate"' })
                .addBoolColumn("isPersonalised", "Views.PatientFile.CarePlans.Personalised")
                .addConditionalEditButtonWithPromiseFunctionColumn(v => this.editRuleThresholdValue(v),
                v => {
                    return (
                        (this.$scope.threshold.getPreviousRuleThresholdValue(v) === null ||
                            !this.$scope.threshold.getPreviousRuleThresholdValue(v).isPersonalised)
                        && (!v.isPersonalised || moment().isAfter(moment(v.validFromDate)) || this.$scope.readOnly)
                    );
                })
                .addConditionalDeleteButtonColumn(v => {
                    var personalised = _(_(this.$scope.threshold.ruleThresholdValues).filter(x => x.isPersonalised)).sortBy(x => x.validFromDate);
                    return _(personalised).size() === 0
                        || v.validFromDate !== _(personalised).last().validFromDate
                        || !v.isPersonalised
                        || this.$scope.readOnly;
                }, v => this.deleteValue(v))
                .setSorting(false)
                .build();

            this.setValueGridData();
        }

        private setValueGridData(): void {
            this.$scope.valueGrid.setData(this.$scope.threshold.ruleThresholdValues);
        }

        private addRuleThresholdValue(): void {
            this.modalBuilderFactory.createModalBuilder<RuleThreshold>()
                .setController('PatientFileAddRuleThresholdValueModalCtrl')
                .setTemplateUrl('views/patient/carePlans/objectivesThresholds/addRuleThresholdValueModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    threshold: this.$scope.threshold,
                    defaultValue: this.$scope.defaultThreshold.getCurrentRuleThresholdValue(),
                    values: this.$scope.threshold.ruleThresholdValues,
                    carePlan: this.$scope.carePlan
                })
                .setResultCallBack(() => {
                    this.getRuleThreshold(this.$scope.threshold.id);
                })
                .build();
        }


        private editRuleThresholdValue(value: RuleThresholdValue): JQueryPromise<RuleThresholdValue> {
            var deferred = jQuery.Deferred<RuleThresholdValue>();
            this.modalBuilderFactory.createModalBuilder<RuleThresholdValue>()
                .setController('PatientFileEditRuleThresholdValueModalCtrl')
                .setTemplateUrl('views/patient/carePlans/objectivesThresholds/editValueModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    threshold: this.$scope.threshold,
                    defaultValue: this.$scope.defaultThreshold.getCurrentRuleThresholdValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: value
                })
                .setResultCallBack(() => {
                    this.getRuleThreshold(this.$scope.threshold.id);
                })
                .build();
            return deferred.promise();
        }

        private deleteValue(value: RuleThresholdValue): void {
            this.carePlanApiSvc.deletePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, value.id)
                .success(() => {
                    this.getRuleThreshold(this.$scope.threshold.id);
                })
                .error((m) => this.errorCallback(m));
        }

        private getRuleThreshold(thresholdId: Guid) {
            this.carePlanApiSvc.getRuleThreshold(this.$scope.carePlan.id, thresholdId)
                .success((ags) => {
                    this.$scope.threshold = new RuleThreshold(ags);
                    this.setValueGridData();
                })
                .error((m) => this.errorCallback(m));
        }
    }

    angular.module('RemeCare.Patient').controller('PatientFileRuleThresholdOverviewCtrl', (
        $scope,
        $translate,
        toaster,
        $stateParams,
        carePlanApiSvc: Core.Services.CarePlanApiService,
        therapyApiSvc: Core.Services.TherapyApiService,
        modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
        gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
    ) => new PatientFileRuleThresholdOverviewController(
        $scope,
        $translate,
        toaster,
        $stateParams,
        carePlanApiSvc,
        therapyApiSvc,
        modalBuilderFactory,
        gridBuilderSvc
    ));
}