namespace RemeCare.Model {
    import CountryIso2 = Shared.Contract.Code.CountryIso2;
    export class PhoneNumber implements Shared.Directive.ITelephoneNumber {
        public static fromString(phoneNumber: string): PhoneNumber {
            const result = new PhoneNumber();
            if (!phoneNumber) {
                return result;
            }
            const split = phoneNumber.split(' ');
            result.countryPrefix = split[0] ? split[0].replace('+', '') : null;
            result.areaCode = split[1] ? split[1].replace('(', '').replace(')', '') : null;
            result.localNumber = '';
            for (let i = 2; i < split.length; i++) {
                result.localNumber += split[i];
            }
            return result;
        }

        public static fromObject(phoneNumber: any): PhoneNumber {
            const result = new PhoneNumber();
            if (!phoneNumber) {
                return result;
            }

            if (phoneNumber.countryPrefix) {
                result.countryPrefix = phoneNumber.countryPrefix;
            }

            if (phoneNumber.areaCode) {
                result.areaCode = phoneNumber.areaCode;
            }

            if (phoneNumber.localNumber) {
                result.localNumber = phoneNumber.localNumber;
            }

            return result;
        }

        public static parse(phoneNumber: string, regionCode: CountryIso2 = CountryIso2.Belgium): PhoneNumber {
            if (!phoneNumber) {
                return new PhoneNumber();
            }
            return this.fromE164(phoneUtils.formatE164(phoneNumber, regionCode), regionCode);
        }

        public static fromE164(phoneNumber: string, regionCode: CountryIso2 = CountryIso2.Belgium): PhoneNumber {
            if (!phoneNumber) {
                return new PhoneNumber();
            }
            const o = new PhoneNumber();
            o.countryPrefix = phoneUtils.getCountryCodeForRegion(
                phoneUtils.getRegionCodeForNumber(phoneNumber, regionCode)
            );

            if (o.countryPrefix) {
                // Ensure it is a string
                o.countryPrefix = '' + o.countryPrefix;
            }

            // Note: international numbering standards (e.g. E.164 and E.123) only make a clear-cut distintion between country codes and subscriber numbers
            // 'local number' and 'area code' are therefore not stricly defined, the code below is a best effort
            const nn: string[] = phoneUtils.formatNational(phoneNumber, regionCode).split(' '); // National formatting is our best guess to split a number in a meaningful way
            o.areaCode = this.sanitize(nn.shift(), true); // Assume the first group is some form of area code, drop leading zeroes
            o.localNumber = this.sanitize(nn.join()); // Force whatever remains into the local part, don't drop zeroes

            return o;
        }

        private static sanitize(dirty: string, dropLeadingZeroes = false): string {
            if (dropLeadingZeroes) {
                return dirty.replace(/\D|^(0+)/g, '');
            } else {
                return dirty.replace(/\D/g, '');
            }
        }

        private static addSpaces(str: string): string {
            let result = '';
            if (str.length % 2 === 0) {
                for (let i = 0; i < str.length; i++) {
                    result += str[i];
                    if (i % 2 === 1) {
                        result += ' ';
                    }
                }
            } else {
                for (let i = 0; i < str.length; i++) {
                    result += str[i];
                    if (i % 2 === 0 && i !== 0) {
                        result += ' ';
                    }
                }
            }
            return result;
        }

        public countryPrefix: string;
        public areaCode: string;
        public localNumber: string;
        private id: Shared.Contract.Guid;

        constructor(serverObject?: Contract.Core.ITelephoneNumber) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.countryPrefix = serverObject.CountryPrefix;
                this.areaCode = serverObject.AreaCode;
                this.localNumber = serverObject.LocalNumber;
            }
        }

        public toString(): string {
            const countryPrefix = this.countryPrefix ? `+${this.countryPrefix} ` : '';
            const areaCode = this.areaCode ? `(${this.areaCode}) ` : '';
            const localNumber = this.localNumber ? this.addSpaces(this.localNumber) : '';
            return `${countryPrefix}${areaCode}${localNumber}`;
        }

        public isValid(): boolean {
            return (
                (_.isEmpty(this.countryPrefix) && _.isEmpty(this.areaCode) && _.isEmpty(this.localNumber)) ||
                (!_.isEmpty(this.countryPrefix) && !_.isEmpty(this.areaCode) && !_.isEmpty(this.localNumber))
            );
        }

        public isEmpty(): boolean {
            return _.isEmpty(this.countryPrefix) && _.isEmpty(this.areaCode) && _.isEmpty(this.localNumber);
        }

        public isFilled(): boolean {
            return !_.isEmpty(this.countryPrefix) && !_.isEmpty(this.areaCode) && !_.isEmpty(this.localNumber);
        }

        public toServerWrite(): Contract.Core.ITelephoneNumber {
            return {
                Id: this.id,
                CountryPrefix: this.countryPrefix,
                AreaCode: this.areaCode,
                LocalNumber: this.localNumber,
            } as Contract.Core.ITelephoneNumber;
        }

        public isSame(toCompare: PhoneNumber) {
            return (
                this.countryPrefix === toCompare.countryPrefix &&
                this.areaCode === toCompare.areaCode &&
                this.localNumber === toCompare.localNumber
            );
        }

        private addSpaces(str: string): string {
            return PhoneNumber.addSpaces(str);
        }
    }
}
