/// <reference path="componentDirectiveBase.ts" />
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var AberrantRegistrationController = /** @class */ (function (_super) {
            __extends(AberrantRegistrationController, _super);
            // @ngInject
            function AberrantRegistrationController($scope, gridBuilderSvc, carePlanActionApiSvc, toaster, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$scope = $scope;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.toaster = toaster;
                return _this;
            }
            AberrantRegistrationController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var registrations, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                this.$scope.aberrantRegistrationsGrid = this.gridBuilderSvc
                                    .createGridBuilder()
                                    .addColumn('Name', 'General.Name')
                                    .addDateColumn('Date', 'General.Date', 'shortDate')
                                    .addColumn('Value', 'Views.Action.AberrantRegistrationValues')
                                    .build();
                                if (!(this.$scope.component.aberrantRegistrationValueIds.length > 0)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.carePlanActionApiSvc.getAberrantRegistrationInformation(this.$scope.component.aberrantRegistrationValueIds)];
                            case 1:
                                registrations = _a.sent();
                                registrations = _.sortBy(registrations, function (r) { return r.Date; }).reverse();
                                this.$scope.aberrantRegistrationsGrid.setData(registrations);
                                _a.label = 2;
                            case 2: return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return AberrantRegistrationController;
        }(CarePlanAction.ComponentControllerBase));
        var AberrantRegistrationDirective = /** @class */ (function (_super) {
            __extends(AberrantRegistrationDirective, _super);
            function AberrantRegistrationDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/aberrantRegistrations.html';
                _this.controller = AberrantRegistrationController;
                return _this;
            }
            return AberrantRegistrationDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        CarePlanAction.remeCareCarePlanActionModule.directive('rcAberrantRegistrations', function () { return new AberrantRegistrationDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
