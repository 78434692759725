module RemeCare.SelfService {

    remeCareSelfServiceModule.component('rcExternalIdentityProvider',
        {
            bindings: {
                providers: '<',
                onProviderSelected: '&'
            },
            templateUrl: 'views/selfService/externalIdentityProvider.html'
        });
}