module RemeCare.Management {

    export class ManagementController {

        // @ngInject
        constructor(
            private $scope: ng.IScope,
            private sidebarSvc: Shared.Framework.Service.SidebarService) {
        }

        public $onInit(): void {
            this.sidebarSvc.setLeftSidebarTitle('General.ManagementModule');
            this.sidebarSvc.setLeftSidebarIcon('menu-hamburger');
            this.$scope.$on('$destroy',
                () => {
                    this.sidebarSvc.clear();
                });
        }
    }

    remeCareManagementModule.controller('managementCtrl', ManagementController);
}