/// <reference path="../../../../contract/core/codes/dateFilterTypeCode.ts"/>

module RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;
    import DateFilterType = Contract.Core.Codes.DateFilterTypeCode;
    import Alarm = Contract.Alarms.Read.IAlarm;
    import AlarmsQuery = Contract.Alarms.Read.IFindAlarmsQuery;

    class PatientFileAlarmsController implements ng.IComponentController {
        grid: Shared.Framework.Grid.Grid<Alarm>;
        alarmCodes: Array<EntityTranslation>;
        alarmLevels: Array<EnumTranslation>;
        alarmTypes: Array<EnumTranslation>;
        persons: Array<EntityTranslation>;
        dateFilterTypes: Array<EnumTranslation>;
        carePlans: Array<EntityTranslation>;
        alarmDefinitions: Array<Shared.Contract.Read.IAlarmDefinition>;
        editRight: boolean;
        patientId: Guid;

        constructor(
            private readonly $filter: ng.IFilterService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadDataAsync();
            this.editRight = true;
            this.search();
        }

        private async loadDataAsync(): Promise<void> {
            let dateFilterTypes: Shared.Contract.IEnumTranslation[];
            let retrievedCarePlans: Contract.Patient.Read.ICareplan[];

            try {
                [
                    dateFilterTypes,
                    this.alarmLevels,
                    this.alarmTypes,
                    this.persons,
                    this.alarmDefinitions,
                    retrievedCarePlans
                ] = await Promise.all([
                    this.masterdataSvc.getDateFilterTypesAsync(),
                    this.masterdataSvc.getAlarmLevelsAsync(),
                    this.masterdataSvc.getAlarmTypesAsync(),
                    this.patientSvc.getPersonsAsync(this.patientId),
                    this.patientSvc.getAlarmDefinitionsAsync(this.patientId),
                    this.patientSvc.getCarePlansAsync(this.patientId)
                ]);

                this.dateFilterTypes = _(dateFilterTypes).filter(
                    x => x.Id === DateFilterType.CreationDate || x.Id === DateFilterType.HandledDate
                );
                this.grid.searchCriteria.selectedDateFilter = DateFilterType.CreationDate;

                this.alarmCodes = _(this.alarmDefinitions).map(
                    x =>
                        <EntityTranslation>{
                            Id: x.Id,
                            Text: x.AlarmCode
                        }
                );

                const carePlans = CarePlan.getSortedOpenCloseCareplans(retrievedCarePlans);
                this.carePlans = _(carePlans).map(x => x.getSummary(this.$translate, this.$filter));
                this.addAllCarePlansOption(this.carePlans);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected addAllCarePlansOption(carePlans: Array<EntityTranslation>): void {
            carePlans.unshift(<EntityTranslation>{
                Id: null,
                Text: this.$translate.instant('Views.PatientFile.AllCarePlans')
            });
        }

        private search(): void {
            this.grid.pagingOptions.currentPage = 1;
            this.grid.search();
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<Alarm>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('AlarmCode.Text', 'Views.Alarms.AlarmCode')
                .addColumn('Message', 'General.Description')
                .addColumn('AlarmLevel.Text', 'Views.Alarms.Level')
                .addUtcDateColumn('CreationDate', 'Views.PatientFile.Monitoring.CreationDate', 'short')
                .addColumn('Status.Text', 'General.Status')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    a => this.showAlarm(a),
                    a => this.editAlarmAsync(a),
                    a => a.ReadOnly
                );

            this.grid = gridBuilder.build();
            this.grid.pagingOptions.pageSize = 20;
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<Alarm>> {
            const query = <AlarmsQuery>{
                carePlanId: criteria.carePlanId,
                alarmTypes: criteria.selectedTypes,
                alarmDefinitions: criteria.selectedCodes,
                alarmLevels: criteria.selectedLevels,
                recipients: criteria.selectedRecipients,
                dateFilterType: criteria.selectedDateFilter,
                startDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                endDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                patient: null,
                patientId: null
            };

            try {
                return await this.patientSvc.getAlarmsAsync(this.patientId, query);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private showAlarm(alarm: Alarm): void {
            this.modalBuilderFactory
                .createModalBuilder<Alarm>()
                .setController('patientFileAlarmDetailModalCtrl')
                .setTemplateUrl('views/patient/monitoring/alarms/alarmDetailModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    alarm: alarm,
                    alarmDefinition: _(_(this.alarmDefinitions).filter(a => a.Id === alarm.AlarmCode.Id)).first()
                })
                .setResultCallBack(() => {
                    this.grid.search();
                })
                .build();
        }

        private async editAlarmAsync(alarm: Alarm): Promise<Alarm> {
            return new Promise<Alarm>(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<Alarm>()
                    .setController('patientFileAlarmDetailModalCtrl')
                    .setTemplateUrl('views/patient/monitoring/alarms/alarmDetailModal.html')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        alarm: alarm,
                        alarmDefinition: _(_(this.alarmDefinitions).filter(a => a.Id === alarm.AlarmCode.Id)).first(),
                        readOnly: alarm.ReadOnly
                    })
                    .setResultCallBack(r => {
                        this.grid.search();
                        resolve(r);
                    })
                    .build();
            });
        }
    }

    remeCarePatientModule.component('patientFileRegistrationAlarms', {
        controller: PatientFileAlarmsController,
        templateUrl: 'views/patient/monitoring/alarms/alarms.html',
        bindings: {
            patientId: '@'
        }
    });
}
