var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartsController = /** @class */ (function () {
            function MonitoringPartsController($compile, $element, $scope) {
                var _this = this;
                this.$compile = $compile;
                this.$element = $element;
                this.$scope = $scope;
                this.onPartLoaded = function () {
                    _this.maxActivatedIndex++;
                };
            }
            MonitoringPartsController.prototype.$onInit = function () {
                this.render();
            };
            MonitoringPartsController.prototype.$onChanges = function (changes) {
                if ((changes.globalFrom && !changes.globalFrom.isFirstChange() && changes.globalFrom.currentValue) ||
                    (changes.globalUntil && !changes.globalUntil.isFirstChange() && changes.globalUntil.currentValue) ||
                    (changes.monitoringParts &&
                        !changes.monitoringParts.isFirstChange() &&
                        changes.monitoringParts.currentValue)) {
                    this.render();
                }
            };
            MonitoringPartsController.prototype.render = function () {
                this.childScope && this.childScope.$destroy();
                this.childScope = this.$scope.$new();
                this.maxActivatedIndex = 3;
                var html = '';
                this.monitoringParts = this.monitoringParts || [];
                for (var i = 0; i < this.monitoringParts.length; i++) {
                    html += this.getDirectiveHtml(this.monitoringParts[i].monitoringPart, i);
                }
                this.$element.html(html).show();
                this.$compile(this.$element.contents())(this.childScope);
            };
            MonitoringPartsController.prototype.getDirectiveHtml = function (part, index) {
                var directiveName;
                switch (part.type) {
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.LineChartNumeric:
                        directiveName = 'line-graph';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.TableNumericQualitative:
                        directiveName = 'parameters-table';
                        directiveName += part.invert ? '-inverted' : '';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.CumulativeBarChart:
                        directiveName = 'cumulative-bar-graph';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.FloatingBarChart:
                        directiveName = 'floating-bar-graph';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.ColourQualitativeTimeLine:
                        directiveName = 'colour-qualitative';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.MedicationAdherenceChart:
                        directiveName = 'therapy-compliance-graph';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.MedicationDoseChart:
                        directiveName = 'medication-dose-chart';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.ActionTable:
                        directiveName = 'action-table';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.ActionTimeLine:
                        directiveName = 'action-time-line';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.CumulativeObjectiveChart:
                        directiveName = 'cumulative-objective-graph';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.ObjectiveScoreTable:
                        directiveName = 'objective-score-table';
                        directiveName += part.invert ? '-inverted' : '';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.LineChartNumericExternal:
                        directiveName = 'line-chart-numeric-external';
                        break;
                    case RemeCare.Shared.Contract.Code.MonitoringPartType.Boxplot:
                        directiveName = 'boxplot';
                        break;
                    default:
                        return '<p>Part not implemented</p>';
                }
                var widthClass;
                switch (part.width) {
                    case RemeCare.Shared.Contract.Code.PartWidth.Full:
                        widthClass = 'col-md-12';
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.Half:
                        widthClass = 'col-md-6';
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.Third:
                        widthClass = 'col-md-4';
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.TwoThird:
                        widthClass = 'col-md-8';
                        break;
                    default:
                        widthClass = 'col-md-12';
                        break;
                }
                return "<rc-monitoring-" + directiveName + " ng-if=\"" + index + " < $ctrl.maxActivatedIndex\" monitoring-part=\"$ctrl.monitoringParts[" + index + "].monitoringPart\" care-plan-ids=\"$ctrl.monitoringParts[" + index + "].carePlanIds\" patient-id=\"$ctrl.patientId\" global-from=\"$ctrl.globalFrom\" global-until=\"$ctrl.globalUntil\" on-from-changed=\"$ctrl.onFromChanged()\" on-until-changed=\"$ctrl.onUntilChanged()\" on-part-loaded=\"$ctrl.onPartLoaded()\" hide-when-no-data=\"$ctrl.hidePartsWithoutData\" show-legend=\"$ctrl.showGraphLegends\" width-class=\"" + widthClass + "\"></rc-monitoring-" + directiveName + ">";
            };
            return MonitoringPartsController;
        }());
        Patient.remeCarePatientModule.component('rcMonitoringParts', {
            bindings: {
                globalFrom: '<',
                globalUntil: '<',
                hidePartsWithoutData: '<',
                monitoringParts: '<',
                onFromChanged: '&',
                onUntilChanged: '&',
                patientId: '<',
                showGraphLegends: '<',
            },
            controller: MonitoringPartsController,
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
