module RemeCare.Patient {
    import ActionTemplate = Patient.Model.ActionTemplate;

    interface IAdhocRegistrationModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        action: ActionTemplate;
        showErrors: boolean;
        cancel: () => void;
        register: (isPermanent:boolean) => void;
        registrationForm: ng.IFormController;
        showEventDateTime: boolean;
        customView: string;
    }

    class AdhocRegistrationModalController extends Shared.Framework.ControllerBase<IAdhocRegistrationModalScope> {
        constructor(
            $scope: IAdhocRegistrationModalScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            private $rootScope: ng.IRootScopeService,
            private $stateParams,
            private patientSvc: Patient.PatientService
        ) {
            super($scope, $translate, toaster);
            this.$scope.register = (isPermanent:boolean) => this.register(isPermanent);
            this.$scope.cancel = () => this.cancel();
        }

        public $onInit(): void {
            this.$scope.showEventDateTime = !_(this.$scope.action.components)
                .any(c => c.actionTypeCode === Shared.Contract.Code.ActionType.CareAct
                    || c.actionTypeCode === Shared.Contract.Code.ActionType.Administration);

            this.$scope.customView = `views/custom/${this.$scope.action.customScreen}.html`;
        }

        private register(isPermanent:boolean): void {
            if (isPermanent && this.$scope.registrationForm.$invalid) {
                this.$scope.showErrors = true;
                this.showValidationErrorMessage();
                return;
            }

            var registrations = this.$scope.action.toServerWrite(isPermanent);

            this.patientSvc.registerNonPlannedRegistration(this.$scope.action.patientId,
                    this.$scope.action.id,
                    registrations)
                .success(() => {
                    this.generalSuccess();
                    this.$scope.$close(registrations);
                })
                .catch(e => {
                    this.toaster.error(e.data);
                    this.$rootScope.$broadcast('actionSaveError', e.headers('X-Errors'));
                });

        }
        
        private cancel(): void {
            this.$scope.$dismiss();
        }
    }

    remeCarePatientModule.controller('adhocRegistrationModalCtrl', AdhocRegistrationModalController);
} 