/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import ReferenceParameterObjective = Shared.Framework.Model.ReferenceParameterObjective;
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import ChartBar = Shared.Framework.Model.ChartBar;
    import ChartLine = Shared.Framework.Model.ChartLine;

    class CumulativeObjectiveGraphController extends ChartMonitoringPartController {
        private referenceParameterObjective: ReferenceParameterObjective;
        private quantitativeMeasuringPointParameter: QuantitativeMeasuringPointParameter;
        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.referenceParameterObjective = _.find(
                this.monitoringPart.monitoringPartSources,
                mps => mps.type === Shared.Contract.Code.MonitoringPartSourceType.ReferenceParameterObjective
            ) as ReferenceParameterObjective;
            this.quantitativeMeasuringPointParameter = _.find(
                this.monitoringPart.monitoringPartSources,
                mps => mps.type === Shared.Contract.Code.MonitoringPartSourceType.QuantitativeMeasuringPointParameter
            ) as QuantitativeMeasuringPointParameter;
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            try {
                const objectiveScoreSearchResult = await this.carePlanApiSvc.getObjectiveScoreAsync(
                    this.carePlanIds[0],
                    this.referenceParameterObjective.objective.Id,
                    this.dateInfo.fromDate,
                    this.dateInfo.untilDate
                );
                this.configureChart(objectiveScoreSearchResult.Items);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        protected getMinYScaleValue(graph: Patient.Model.IHasMinMaxY): number {
            return 0;
        }

        protected containsData(config: HighChartsNGConfig): boolean {
            return !_(config.series).all(s => _(s.data).all(d => (d as number[])[1] === 0) || (s as any).isReference);
        }

        private createGraph(objectiveScoreDetails: Contract.CarePlan.Read.IObjectiveScoreDetail[]): Model.NumberGraph {
            const result = new Model.NumberGraph();
            result.subject = this.referenceParameterObjective.objective;
            result.scale = this.quantitativeMeasuringPointParameter.unit;
            result.graphPoints = _(objectiveScoreDetails)
                .chain()
                .map(osd => osd.DayValues)
                .flatten()
                .map(dv => new Model.GraphPoint(this.dateHelper.serverDateStringToDate(dv.Date), dv.ValueToDate))
                .sortBy(gp => gp.x)
                .value();
            return result;
        }

        private configureChart(objectiveScoreDetails: Contract.CarePlan.Read.IObjectiveScoreDetail[]): void {
            const graph = this.createGraph(objectiveScoreDetails);
            const chartConfig = {
                options: {
                    chart: {
                        animation: false,
                        type: 'column',
                        height: 200,
                        zoomType: 'xy',
                        spacingTop: 40,
                        marginLeft: 37,
                        marginRight: 37,
                    },
                    xAxis: this.getXAxisConfig(null, true),
                    yAxis: {
                        startOnTick: false,
                        endOnTick: false,
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            text: graph.scale ? graph.scale.Text : null,
                            y: -20,
                            x: -27,
                            textAlign: 'left',
                        },
                        max: this.getMaxY(graph, objectiveScoreDetails),
                        min: this.getMinYScaleValue(graph),
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -2,
                        },
                    },
                    tooltip: {
                        xDateFormat: this.getDateFormat(false),
                        pointFormat:
                            '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} {point.series.yAxis.options.title.text}</b><br/>',
                    },
                    title: {
                        text: null,
                    },
                    exporting: {
                        enabled: false,
                    },
                    credits: {
                        enabled: false,
                    },
                    legend: {
                        enabled: this.showLegend,
                    },
                },
                series: this.mapDataPoints(graph, objectiveScoreDetails),
            } as HighChartsNGConfig;
            this.chartConfigs = [chartConfig];
        }

        private getMaxY(
            graph: Model.NumberGraph,
            objectiveScoreDetails: Contract.CarePlan.Read.IObjectiveScoreDetail[]
        ): number {
            const graphMax = this.getMaxYScaleValue(graph);
            let objectiveMax = _(objectiveScoreDetails).max(osd => osd.ObjectiveValue).ObjectiveValue;
            objectiveMax = this.getLimitedMaxYValue(this.monitoringPart, objectiveMax);
            return Math.max(graphMax, objectiveMax);
        }

        private mapDataPoints(
            graph: Model.NumberGraph,
            objectiveScore: Contract.CarePlan.Read.IObjectiveScoreDetail[]
        ): HighchartsSeriesOptions[] {
            const columns = {
                animation: false,
                type: 'column',
                data: _(graph.graphPoints)
                    .chain()
                    .map(gp => this.getDataPoint(gp))
                    .sortBy(gp => gp[0])
                    .value(),
                marker: {
                    enabled: false,
                },
                name: this.quantitativeMeasuringPointParameter.characteristic.Text,
                color: (this.referenceParameterObjective.sourceParameterRepresentation as ChartBar).colour,
                maxPointWidth: 20,
                pointPlacement: 'on',
            } as HighchartsColumnChartSeriesOptions;
            const line = {
                animation: false,
                type: 'line',
                data: this.getLinePoints(objectiveScore),
                step: true,
                isReference: true,
                color: (this.quantitativeMeasuringPointParameter.sourceParameterRepresentation as ChartLine).colour,
                lineWidth:
                    (this.quantitativeMeasuringPointParameter.sourceParameterRepresentation as ChartLine).lineType
                        .Id === Shared.Contract.Code.LineType.Thin
                        ? 1
                        : 2,
                dashStyle:
                    (this.quantitativeMeasuringPointParameter.sourceParameterRepresentation as ChartLine).lineType
                        .Id === Shared.Contract.Code.LineType.Dashed
                        ? 'Dash'
                        : 'Solid',
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 1,
                },
                name: this.referenceParameterObjective.objective.Text,
                tooltip: {
                    xDateFormat: this.getDateFormat(false),
                },
            } as HighchartsLineChartSeriesOptions;
            return [columns, line];
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number>): number[] {
            return [graphPoint.x.valueOf(), graphPoint.y];
        }

        private getLinePoints(
            objectiveScoreDetails: Contract.CarePlan.Read.IObjectiveScoreDetail[]
        ): HighchartsDataPoint[] {
            objectiveScoreDetails = _(objectiveScoreDetails).sortBy(osd =>
                this.dateHelper.serverDateStringToDate(osd.FromDate).valueOf()
            );
            const points = _(objectiveScoreDetails).map(osd => {
                return {
                    x: this.dateHelper.serverDateStringToDate(osd.FromDate).valueOf(),
                    y: osd.ObjectiveValue,
                } as HighchartsDataPoint;
            });
            const last = _(objectiveScoreDetails).last();
            if (last != null) {
                points.push({
                    x: this.dateHelper.serverDateStringToDate(last.UntilDate).valueOf(),
                    y: last.ObjectiveValue,
                } as HighchartsDataPoint);
            }
            return points;
        }
    }

    class CumulativeObjectiveGraphComponent extends MonitoringPartComponentBase {
        public controller = CumulativeObjectiveGraphController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringCumulativeObjectiveGraph', new CumulativeObjectiveGraphComponent());
}
