var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var AcceptanceControllerBase = /** @class */ (function (_super) {
            __extends(AcceptanceControllerBase, _super);
            function AcceptanceControllerBase($scope, $translate, toaster, gridBuilderSvc, partyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.partyApiSvc = partyApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.$scope.openSearch = function () { return _this.openSearch(); };
                _this.$scope.isPresent = function (ct) { return _this.isPresent(ct); };
                _this.$scope.isRequired = function (ct) { return _this.isRequired(ct); };
                return _this;
            }
            AcceptanceControllerBase.prototype.$onInit = function () {
                this.buildSearchGrid();
            };
            AcceptanceControllerBase.prototype.isPresent = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id !== RemeCare.Shared.Contract.Code.ModalityType.Not;
            };
            AcceptanceControllerBase.prototype.isRequired = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id === RemeCare.Shared.Contract.Code.ModalityType.Mandatory;
            };
            AcceptanceControllerBase.prototype.atLeastOneNumber = function (requestPerson, type) {
                if (!this.$scope.isRequired(type))
                    return true;
                return (requestPerson.contactInfo.telephone.isFilled() ||
                    requestPerson.contactInfo.mobile.isFilled());
            };
            AcceptanceControllerBase.prototype.buildSearchGrid = function () {
                var _this = this;
                this.$scope.personSearchGrid = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.searchPersons(page, pageSize, sortField, sortDirection, criteria);
                })
                    .addColumn('LastName', 'General.LastName')
                    .addColumn('FirstName', 'General.FirstName')
                    .addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date:"shortDate"' })
                    .addSelectButtonColumn(function (s) { return _this.$scope.personSelected(s); })
                    .setRowDetailsTemplate('views/careRequest/directive/patientAcceptance/personDetails.html', 265)
                    .build();
            };
            AcceptanceControllerBase.prototype.searchPersons = function (page, pageSize, sortField, sortDirection, criteria) {
                var deferred = jQuery.Deferred();
                var query = {
                    page: page,
                    pageSize: pageSize,
                    sortField: sortField,
                    sortOrder: sortDirection,
                    firstName: criteria.firstName,
                    lastName: criteria.lastName,
                    identificationNumber: criteria.identificationNumber,
                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.birthDate),
                    communicationLanguage: criteria.communicationLanguage,
                    gender: criteria.gender,
                    address: criteria.address,
                    zipCode: criteria.zipCode,
                    city: criteria.city,
                    country: criteria.country,
                    nationality: criteria.nationality,
                    email: criteria.email
                };
                this.partyApiSvc.findPersons(query)
                    .success(function (r) { return deferred.resolve(r); })
                    .error(function (e) { return deferred.reject(e); });
                return deferred.promise();
            };
            AcceptanceControllerBase.prototype.copyToSearch = function (src, dest, obj) {
                var value = this.getDescendantProp(obj, src);
                this.$scope.personSearchGrid.searchCriteria[dest] = angular.copy(value);
            };
            AcceptanceControllerBase.prototype.getDescendantProp = function (obj, desc) {
                var arr = desc.split('.');
                while (arr.length && (obj = obj[arr.shift()]))
                    ;
                return obj;
            };
            AcceptanceControllerBase.prototype.setDescendantProp = function (obj, desc, val) {
                var arr = desc.split('.');
                while (arr.length > 1 && (obj = obj[arr.shift()]))
                    ;
                obj[arr.shift()] = val;
            };
            AcceptanceControllerBase.prototype.openSearch = function () {
                this.$scope.searchOpen = true;
            };
            AcceptanceControllerBase.prototype.updateField = function (field, requestPerson) {
                var _this = this;
                if (_.isArray(field)) {
                    _(field).forEach(function (f) { return _this.updateField(f, requestPerson); });
                    return;
                }
                var srcField = this.getDescendantProp(requestPerson, field);
                this.setDescendantProp(this.$scope.selectedPerson, field, srcField);
                switch (field) {
                    case 'lastName':
                        requestPerson.update.hasUpdatedLastName = true;
                        break;
                    case 'firstName':
                        requestPerson.update.hasUpdatedFirstName = true;
                        break;
                    case 'identificationNumber':
                        requestPerson.update.hasUpdatedIdentificationNumber = true;
                        break;
                    case 'birthDate':
                        requestPerson.update.hasUpdatedBirthDate = true;
                        break;
                    case 'communicationLanguage':
                        requestPerson.update.hasUpdatedCommunicationLanguage = true;
                        break;
                    case 'gender':
                        requestPerson.update.hasUpdatedGender = true;
                        break;
                    case 'contactInfo.address.addressLine1':
                        requestPerson.contactInfo.update.hasUpdatedAddress = true;
                        break;
                    case 'contactInfo.address.zipCode':
                        requestPerson.contactInfo.update.hasUpdatedPostalCode = true;
                        break;
                    case 'contactInfo.address.city':
                        requestPerson.contactInfo.update.hasUpdatedCity = true;
                        break;
                    case 'contactInfo.address.country':
                        requestPerson.contactInfo.update.hasUpdatedCountry = true;
                        break;
                    case 'nationality':
                        requestPerson.update.hasUpdatedNationality = true;
                        break;
                    case 'contactInfo.telephone.countryPrefix':
                        requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                        break;
                    case 'contactInfo.telephone.areaCode':
                        requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                        break;
                    case 'contactInfo.telephone.localNumber':
                        requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                        break;
                    case 'contactInfo.mobile.countryPrefix':
                        requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                        break;
                    case 'contactInfo.mobile.areaCode':
                        requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                        break;
                    case 'contactInfo.mobile.localNumber':
                        requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                        break;
                    case 'contactInfo.email':
                        requestPerson.contactInfo.update.hasUpdatedEmail = true;
                        break;
                    case 'maritalStatus':
                        requestPerson.update.hasUpdatedMaritalStatus = true;
                        break;
                    case 'technologyAffinities':
                        requestPerson.update.hasUpdatedTechnologyAffinity = true;
                        break;
                    case 'educationLevel':
                        requestPerson.update.hasUpdatedEducationLevel = true;
                        break;
                }
            };
            return AcceptanceControllerBase;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.AcceptanceControllerBase = AcceptanceControllerBase;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
