namespace RemeCare.Model {
    export class RemarkGroup {
        public therapyActionPartId?: Shared.Contract.Guid;
        public preceedingTitle: string;
        public textRegistration: string;
        public remarks: Remark[];

        public title: string;

        constructor(serverObject: Contract.CarePlanAction.Read.IRemark, remarks: Remark[]) {
            this.therapyActionPartId = serverObject.TherapyActionPartId;
            this.preceedingTitle = serverObject.PreceedingTitle;
            this.textRegistration = serverObject.TextRegistration;
            this.remarks = _.sortBy(remarks, r => r.executionDateTime).reverse();

            if (this.preceedingTitle) {
                if (this.textRegistration) {
                    this.title = `${this.preceedingTitle} - ${this.textRegistration}`;
                } else {
                    this.title = this.preceedingTitle;
                }
            } else if (this.textRegistration) {
                this.title = this.textRegistration;
            } else {
                this.title = '';
            }
        }
    }
}
