'use strict';

module RemeCare.Model {
    import Guid = Shared.Contract.Guid;

    export class Consultation {
        id: Guid;
        consultationDate: Date;        
        careProviderType: Shared.Contract.IEnumTranslation;      
        reason: string;

        constructor(serverParty?: Contract.Core.Read.IConsultation) {
            if (serverParty != null) {
                this.id = serverParty.Id;
                this.consultationDate = Shared.DateHelper.serverDateStringToDate(serverParty.Date);
                this.careProviderType = serverParty.CareProviderType;                           
                this.reason = serverParty.Reason;
            }
        }

        toServerWrite(): Contract.Core.Write.IConsultation {
            return <Contract.Core.Write.IConsultation> {
                id: this.id,
                date: Shared.DateHelper.toServerDateString(this.consultationDate),
                careProviderType: this.careProviderType ? this.careProviderType.Id : null,
                reason: this.reason
            }
        }
    }
}