var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var AgendaHeaderController = /** @class */ (function () {
            function AgendaHeaderController(authservice) {
                this.authservice = authservice;
            }
            AgendaHeaderController.prototype.$onInit = function () {
                this.isPatient = this.authservice.isPatient();
            };
            return AgendaHeaderController;
        }());
        Agenda.remeCareAgendaModule.component('rcAgendaHeader', {
            controller: AgendaHeaderController,
            controllerAs: 'agendaHeaderCtrl',
            templateUrl: 'views/agenda/agendaHeader.html',
            transclude: true
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
