var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var RemarkGroup = /** @class */ (function () {
            function RemarkGroup(serverObject, remarks) {
                this.therapyActionPartId = serverObject.TherapyActionPartId;
                this.preceedingTitle = serverObject.PreceedingTitle;
                this.textRegistration = serverObject.TextRegistration;
                this.remarks = _.sortBy(remarks, function (r) { return r.executionDateTime; }).reverse();
                if (this.preceedingTitle) {
                    if (this.textRegistration) {
                        this.title = this.preceedingTitle + " - " + this.textRegistration;
                    }
                    else {
                        this.title = this.preceedingTitle;
                    }
                }
                else if (this.textRegistration) {
                    this.title = this.textRegistration;
                }
                else {
                    this.title = '';
                }
            }
            return RemarkGroup;
        }());
        Model.RemarkGroup = RemarkGroup;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
