module RemeCare.Agenda {
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;

    class ColleagueAgendaController implements ng.IComponentController {
        public agendaItems: Array<Model.PersonalAgendaAction>;
        public filter: IAgendaFilter;
        public selectedActions: Array<Shared.Contract.Guid>;
        public showCalendar: boolean;
        private urlBinder: Shared.Framework.Factory.UrlBinder;

        constructor(
            private carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private toaster: Shared.Framework.Toaster,
            private storageSvc: Shared.Framework.Service.StorageService,
            private urlBinderFactory: Shared.Framework.Factory.UrlBinderFactory) {
        }

        public $onInit(): void {
            //we store this as the last chosen agenda
            this.storageSvc.store(StorageServiceConstants.agendaKey, StorageServiceConstants.colleagueAgenda);
            this.filter = {};
            this.urlBinder = this.urlBinderFactory.createUrlBinder(this.filter);
            this.urlBinder.bindAllFromUrl();
            if (this.urlBinder.isEmpty()) {
                this.setDefaultFilters();
                this.showFilters();
            } else {
                this.search();
            }
            
        }

        private setDefaultFilters(): void {
            this.filter.showAllTodos = false;
            this.filter.statuses = [
                Contract.Core.Codes.CarePlanActionStatus.Open,
                Contract.Core.Codes.CarePlanActionStatus.Overdue,
                Contract.Core.Codes.CarePlanActionStatus.Completed
            ];
        }

        public async searchItems(): Promise<void> {
            if (!this.filter.actor) return;
            var query: Contract.CarePlanAction.Read.IFindPersonalAgendaActionsQuery = {
                startDate: Shared.DateHelper.toServerDateString(this.filter.fromDate),
                endDate: Shared.DateHelper.toServerDateString(this.filter.untilDate),
                dateFilterTypes: [Contract.Core.Codes.DateFilterTypeCode.PlannedDate, Contract.Core.Codes.DateFilterTypeCode.EventDate],
                statuses: this.filter.statuses,
                partyId: this.filter.actor.PartyId
            };
            try {
                const personalAgendaActions = await this.carePlanActionApiSvc.findPersonalAgendaActions(query);
                this.agendaItems = _(personalAgendaActions).map(x => new Model.PersonalAgendaAction(x));
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public showFilters(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<IAgendaFilter>('rcColleagueAgendaFilter')
                .setBindings({
                    filter: angular.copy(this.filter)
                })
                .setResultCallBack((r) => {
                    angular.copy(r, this.filter);
                    this.urlBinder.reflectInUrl(true);
                    this.search();
                })
                .build();
        }

        private search(): void {
            if (this.showCalendar) {
                this.searchItems();
            }
            this.showCalendar = this.filter.actor != null;
        }

        public isFiltered(): boolean {
            return this.filter.actor != null ||
                (this.filter.statuses != null && this.filter.statuses.length > 0);
        }
    }

    remeCareAgendaModule.component('rcColleagueAgenda',
        {
            controller: ColleagueAgendaController,
            templateUrl: 'agenda/colleagueAgenda/colleagueAgenda.html'
        });
}