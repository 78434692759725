var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Framework = RemeCare.Shared.Framework;
        var PrescriptionController = /** @class */ (function (_super) {
            __extends(PrescriptionController, _super);
            function PrescriptionController($scope, $translate, toaster, $dialog, patientId, modalBuilderFactory, patientService, masterdataSvc, medicationSearchSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$dialog = $dialog;
                _this.patientId = patientId;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.patientService = patientService;
                _this.masterdataSvc = masterdataSvc;
                _this.medicationSearchSvc = medicationSearchSvc;
                $scope.clearMedication = function () { return _this.clearMedication(); };
                $scope.searchMedication = function () { return _this.searchMedication(); };
                $scope.save = function () { return _this.save(); };
                $scope.delete = function () { return _this.delete(); };
                $scope.prescriptionTypeChanged = function () { return _this.prescriptionTypeChanged(); };
                $scope.setDateRanges = function () { return _this.setDateRanges(); };
                $scope.changeFollowedUpSchema = function () { return _this.changeFollowedUpSchema(); };
                $scope.getMedications = function (m) { return _this.getMedicationsAsync(m); };
                $scope.evaluateMedication = function (m) { return _this.evaluateMedicationAsync(m); };
                $scope.addAdministrationInformation = function () { return _this.addAdministrationInformation(); };
                $scope.hasAdministrationInformation = function () { return _this.hasAdministrationInformation(); };
                return _this;
            }
            PrescriptionController.prototype.$onInit = function () {
                this.$scope.medicationInfo = { medication: null };
                if (this.$scope.prescription == null) {
                    this.$scope.isNew = true;
                    this.$scope.canChangeMedication = true;
                    this.createEmptyPrescription();
                }
                else {
                    this.$scope.isNew = false;
                    this.$scope.prescription.onlyIntakeMoments =
                        this.$scope.prescription.isFollowedUp && this.$scope.prescription.startsInThePast();
                    this.loadMedicationAsync();
                }
                this.loadPatientDataAsync();
                this.setDateRanges();
                this.watchDuration();
            };
            PrescriptionController.prototype.addAdministrationInformation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/medication/extraAdministrationInformation.html')
                    .setController('AdministrationInformationModalCtrl')
                    .setScope({
                    prescription: angular.copy(this.$scope.prescription),
                    medicationInfo: angular.copy(this.$scope.medicationInfo),
                })
                    .setSize(Framework.Helper.ModalSize.large)
                    .setResultCallBack(function (prescription) {
                    (_this.$scope.prescription.prescribedAdministrationInformation =
                        prescription.prescribedAdministrationInformation),
                        (_this.$scope.prescription.solventToAddInformation = prescription.solventToAddInformation);
                })
                    .build();
            };
            PrescriptionController.prototype.hasAdministrationInformation = function () {
                return this.$scope.prescription
                    ? this.$scope.prescription.prescribedAdministrationInformation
                        ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans
                            ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans > 0
                            : false
                        : false
                    : false;
            };
            PrescriptionController.prototype.createEmptyPrescription = function () {
                this.$scope.prescription = new Patient.Model.Prescription(null, null, this.$translate);
                this.$scope.prescription.patientId = this.patientId;
                this.$scope.prescription.makeDefault();
                var tomorrow = moment(RemeCare.Shared.DateHelper.today())
                    .add(1, 'days')
                    .toDate();
                this.$scope.prescription.validFromDate = tomorrow;
            };
            PrescriptionController.prototype.loadPatientDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var patient, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientService.getPatientNameAsync(this.$scope.prescription.patientId)];
                            case 1:
                                patient = _a.sent();
                                this.patientLang = _(patient).first().LanguageISO2Code;
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.loadMedicationAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this.$scope.medicationInfo;
                                return [4 /*yield*/, this.medicationSearchSvc.getMedicationAsync(this.$scope.prescription.medication.ProductId)];
                            case 1:
                                _a.medication = _b.sent();
                                this.$scope.canChangeMedication = !!this.$scope.medicationInfo.medication.ActiveSubstance;
                                this.initialMedication = this.$scope.medicationInfo.medication;
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.getMedicationsAsync = function (medicationName) {
                return __awaiter(this, void 0, void 0, function () {
                    var result, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.medicationSearchSvc.searchMedicationsAsync({
                                        medicationName: medicationName,
                                        activeSubstance: this.$scope.prescription.isFollowedUp
                                            ? this.initialMedication.ActiveSubstance
                                            : null,
                                        isActive: true,
                                        page: 1,
                                        pageSize: 1000,
                                    })];
                            case 1:
                                result = _a.sent();
                                return [2 /*return*/, result.Items];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.setDateRanges = function () {
                var minUntilDate = this.$scope.prescription.validFromDate != null
                    ? new Date(this.$scope.prescription.validFromDate.valueOf())
                    : null;
                if (this.$scope.prescription.isFollowedUp) {
                    if (minUntilDate) {
                        if (RemeCare.Shared.DateHelper.today().valueOf() > minUntilDate.valueOf()) {
                            minUntilDate = RemeCare.Shared.DateHelper.today();
                        }
                    }
                    else {
                        minUntilDate = RemeCare.Shared.DateHelper.today();
                    }
                }
                this.$scope.dateInfo = {
                    minDate: this.$scope.prescription.isFollowedUp && !this.$scope.prescription.startsInThePast()
                        ? RemeCare.Shared.DateHelper.tomorrow()
                        : null,
                    minUntilDate: minUntilDate,
                    fromDateDisabled: !this.$scope.isNew &&
                        this.$scope.prescription.startsInThePast() &&
                        !this.$scope.isFollowUpSchema &&
                        this.$scope.prescription.isFollowedUp,
                };
            };
            PrescriptionController.prototype.watchDuration = function () {
                var _this = this;
                this.$scope.$watch(function (s) { return s.prescription.periodicities[0].duration; }, function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        if (newValue == null || newValue.Quantity == null || newValue.Unit == null) {
                            _this.$scope.prescription.validUntilDate = null;
                        }
                        else if (newValue.Quantity != null && newValue.Unit != null) {
                            var validUntilDate = RemeCare.Shared.DateHelper.addDuration(_this.$scope.prescription.validFromDate, newValue);
                            validUntilDate = RemeCare.Shared.DateHelper.addDuration(validUntilDate, {
                                Quantity: -1,
                                Unit: RemeCare.Shared.Contract.Code.DurationUnit.Days,
                            });
                            _this.$scope.prescription.validUntilDate = validUntilDate;
                        }
                    }
                }, true);
            };
            PrescriptionController.prototype.clearMedication = function () {
                this.$scope.medicationInfo.medication = null;
                this.$scope.prescription.medication = null;
                if (this.$scope.isNew) {
                    this.createEmptyPrescription();
                }
            };
            PrescriptionController.prototype.searchMedication = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/medication/searchMedication.html')
                    .setController('searchMedicationCtrl')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setResultCallBack(function (m) {
                    _this.evaluateMedicationAsync(m);
                })
                    .setResolve({
                    productClassIds: function () { return null; },
                    productIds: function () { return null; },
                })
                    .setDismissCallBack(function () {
                    if (_this.$scope.prescription.medication == null) {
                        _this.$scope.$close();
                    }
                })
                    .setScope({
                    activeSubstance: this.$scope.prescription.isFollowedUp
                        ? this.$scope.medicationInfo.medication.ActiveSubstance
                        : null,
                    prescriptionId: this.$scope.prescription.isFollowedUp ? this.$scope.prescription.id : null,
                })
                    .build();
            };
            PrescriptionController.prototype.evaluateMedicationAsync = function (medication) {
                return __awaiter(this, void 0, void 0, function () {
                    var careplans, e_4, e_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.initialMedication != null) {
                                    this.setMedication(medication);
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 9, , 10]);
                                return [4 /*yield*/, this.patientService.getCarePlansAsync(this.patientId, {
                                        StatusIds: [RemeCare.Shared.Contract.Code.CarePlanStatus.Open, RemeCare.Shared.Contract.Code.CarePlanStatus.OnHold],
                                        ProductId: medication.ProductId,
                                        ProductClassIds: _(medication.MedicationTypes).map(function (t) { return t.Id; }),
                                    })];
                            case 2:
                                careplans = _a.sent();
                                this.$scope.prescription.setCarePlans(careplans);
                                if (!(careplans.length > 0)) return [3 /*break*/, 7];
                                _a.label = 3;
                            case 3:
                                _a.trys.push([3, 5, , 6]);
                                return [4 /*yield*/, this.confirmFollowedUpMedicationAsync(medication, careplans)];
                            case 4:
                                _a.sent();
                                this.setMedication(medication);
                                return [3 /*break*/, 6];
                            case 5:
                                e_4 = _a.sent();
                                this.setMedication(this.initialMedication);
                                return [3 /*break*/, 6];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                this.setMedication(medication);
                                _a.label = 8;
                            case 8: return [3 /*break*/, 10];
                            case 9:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 10];
                            case 10: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.confirmFollowedUpMedicationAsync = function (medication, carePlans) {
                return __awaiter(this, void 0, void 0, function () {
                    var message, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                message = this.$translate.instant('General.Attention') +
                                    '!' +
                                    '\n' +
                                    this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedicationWarning') +
                                    '\n' +
                                    '\n' +
                                    this.$translate.instant('General.CarePlan') +
                                    ': ';
                                message += _(this.$scope.prescription.carePlans)
                                    .map(function (cp) { return cp.Name; })
                                    .join(', ');
                                return [4 /*yield*/, this.$dialog.messageBox(this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedication'), message, [
                                        { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                                        { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                                    ])];
                            case 1:
                                result = _a.sent();
                                if (result === 'yes') {
                                    return [2 /*return*/, this.setFollowedUpSettingsAsync(medication, carePlans)];
                                }
                                else {
                                    throw Error;
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.setFollowedUpSettingsAsync = function (medication, carePlans) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, stas;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    productId: medication.ProductId,
                                    productClassIds: _(medication.MedicationTypes).map(function (t) { return t.Id; }),
                                    therapyIds: _(carePlans).map(function (cp) { return cp.TherapyId; }),
                                };
                                return [4 /*yield*/, this.patientService.getStructuralTherapyActionsAsync(this.patientId, query)];
                            case 1:
                                stas = _a.sent();
                                this.$scope.enableAdHoc = _(stas).any(function (s) { return s.StartTypeAdHoc; });
                                this.$scope.enablePeriodicity = _(stas).any(function (s) { return !s.StartTypeAdHoc; });
                                if (this.$scope.enableAdHoc && !this.$scope.enablePeriodicity) {
                                    this.$scope.prescription.makeAdHoc(this.patientLang, this.masterdataSvc);
                                }
                                else if (this.$scope.enablePeriodicity && !this.$scope.enableAdHoc && this.$scope.prescription.isAdHoc) {
                                    this.$scope.prescription.makeDefault();
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.setMedication = function (medication) {
                this.$scope.medicationInfo.medication = medication;
                if (medication == null) {
                    this.$scope.prescription.medication = null;
                }
                else {
                    this.$scope.prescription.medication = medication;
                }
                this.setDateRanges();
            };
            PrescriptionController.prototype.save = function () {
                if (this.$scope.prescriptionForm.$invalid) {
                    this.$scope.prescriptionForm.$setSubmitted();
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.prescription.isFollowedUp &&
                    !this.$scope.prescription.isAdHoc &&
                    (this.$scope.prescription.periodicities == null ||
                        _(this.$scope.prescription.periodicities).size() === 0)) {
                    this.toaster.error(this.$translate.instant('Periodicity.PeriodicityRequired'));
                    return;
                }
                if (this.$scope.prescription.isFollowedUp &&
                    !this.$scope.prescription.isAdHoc &&
                    _(this.$scope.prescription.periodicities).any(function (x) { return _(x.intraDayTimings).size() === 0; })) {
                    this.toaster.error(this.$translate.instant('Periodicity.IntraDayTimingRequired'));
                    return;
                }
                this.$scope.$close({
                    action: 'save',
                    prescription: this.$scope.prescription,
                });
            };
            PrescriptionController.prototype.prescriptionTypeChanged = function () {
                if (this.$scope.prescription.isAdHoc) {
                    this.$scope.prescription.makeAdHoc(this.patientLang, this.masterdataSvc);
                }
                else {
                    this.$scope.prescription.makeDefault();
                }
            };
            PrescriptionController.prototype.delete = function () {
                var _this = this;
                this.$dialog.confirmBox('General.Confirm', 'Views.PatientFile.Medication.DeleteConfirm', function () {
                    _this.$scope.$close({
                        action: 'delete',
                        prescription: _this.$scope.prescription,
                    });
                });
            };
            PrescriptionController.prototype.changeFollowedUpSchema = function () {
                var _this = this;
                var message = this.$translate.instant('General.Attention') +
                    '!' +
                    '\n' +
                    this.$translate.instant('Views.PatientFile.Medication.MedicationSchemaChangeWarning');
                this.$dialog
                    .messageBox(this.$translate.instant('Views.PatientFile.Medication.FollowedUpMedication'), message, [
                    { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                    { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                ])
                    .then(function (result) {
                    if (result === 'yes') {
                        _this.$scope.isNew = true;
                        _this.$scope.prescription.onlyIntakeMoments = false;
                        _this.$scope.isFollowUpSchema = true;
                        _this.$scope.prescription.validFromDate = RemeCare.Shared.DateHelper.tomorrow();
                        _this.setDateRanges();
                    }
                });
            };
            PrescriptionController.$inject = [
                '$scope',
                '$translate',
                'toaster',
                '$dialog',
                'patientId',
                'modalBuilderFactory',
                'patientSvc',
                'masterdataSvc',
                'medicationSearchSvc',
            ];
            return PrescriptionController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('prescriptionCtrl', PrescriptionController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
