var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Remark = /** @class */ (function () {
            function Remark(serverObject) {
                this.remark = serverObject.Remark;
                this.therapyActionPartId = serverObject.TherapyActionPartId;
                this.registeredByPartyRoleId = serverObject.RegisteredByPartyRoleId;
                this.executionDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);
                this.actionTypeCode = serverObject.ActionTypeCode;
                this.registeredBy = RemeCare.Shared.StringHelper.joinNonEmpty(' ', [
                    serverObject.RegisteredByFirstName,
                    serverObject.RegisteredByLastName,
                ]);
                this.title = this.registeredBy + ": " + RemeCare.Shared.DateHelper.dateTimeHumanReadible(this.executionDateTime);
            }
            return Remark;
        }());
        Model.Remark = Remark;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
