var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientNotFoundController = /** @class */ (function () {
            function PatientNotFoundController(appConfig) {
                this.appConfig = appConfig;
            }
            PatientNotFoundController.prototype.$onInit = function () {
                this.remedusInfoMail = this.appConfig.remedusInfoMail;
            };
            return PatientNotFoundController;
        }());
        Patient.remeCarePatientModule.component('rcPatientNotFound', {
            controller: PatientNotFoundController,
            templateUrl: 'patient/controllers/patientNotFound/patientNotFound.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
