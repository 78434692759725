module RemeCare.Management {
    import Guid = Shared.Contract.Guid;
    import ApplicationProfileAssignment = Shared.Contract.Read.IApplicationProfileAssignment;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    
    interface IAssignApplicationProfileScope extends ng.ui.bootstrap.IModalScope, Shared.Framework.IBaseScope {
        fixedProfile: boolean;
        selectedProfile: EnumTranslation;
        reason: string;
        applicationProfiles: Array<EnumTranslation>;
        form: ng.IFormController;
        confirm(): void;
    }

    class AssignApplicationProfileController extends Shared.Framework.ControllerBase<IAssignApplicationProfileScope> {

        constructor(
            $scope: IAssignApplicationProfileScope,
            $translate,
            toaster,
            private $q: ng.IQService,
            private partyId: Guid,
            private assignedProfiles: Array<ApplicationProfileAssignment>,
            private toAssign: EnumTranslation,
            private securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private partyApiSvc: Core.Services.PartyApiService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.fixedProfile = this.toAssign != null;
            if (this.$scope.fixedProfile) {
                this.$scope.selectedProfile = this.toAssign;
            } else {
                this.$q.all([
                        this.securityApiSvc.findAssignableApplicationProfiles(),
                        this.partyApiSvc.findPartyRoleTypes(this.partyId)
                    ])
                    .then((r: any) => {
                        var profiles = <Array<EnumTranslation>>r[0].data;
                        profiles = _(profiles).filter(ap => !_(this.assignedProfiles)
                            .any(aap => aap.ApplicationProfile.Id === ap.Id));
                        var partyRoleTypes = <Array<EnumTranslation>>r[1].data;
                        profiles = profiles.filter(ap => {
                            if (ap.Id === Shared.Contract.Code.ApplicationProfileType.Superuser ||
                                ap.Id === Shared.Contract.Code.ApplicationProfileType.Configurator) {
                                return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.Employee);
                            } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                                ap.Id === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager ||
                                ap.Id === Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                                ap.Id === Shared.Contract.Code.ApplicationProfileType.TherapyProvider) {
                                return _(partyRoleTypes).any(
                                    prt => prt.Id === Shared.Contract.Code.PartyRoleType.Employee ||
                                    prt.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional);
                            } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.Patient) {
                                return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.Patient);
                            } else if (ap.Id === Shared.Contract.Code.ApplicationProfileType.InformalCareProvider) {
                                return _(partyRoleTypes).any(prt => prt.Id === Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional);
                            }
                            return false;
                        });
                        this.$scope.applicationProfiles = profiles;
                    })
                    .catch(e => this.errorCallback(e));
            }
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.securityApiSvc.assignProfile(this.partyId, this.$scope.selectedProfile.Id, this.$scope.reason)
                .success(() => this.$scope.$close())
                .error(e => this.errorCallback(e));
        }
    }

    remeCareManagementModule.controller('assignApplicationProfileCtrl', (
        $scope,
        $translate,
        toaster,
        $q,
        partyId,
        assignedProfiles,
        toAssign,
        securityApiSvc,
        partyApiSvc
    ) => new AssignApplicationProfileController(
        $scope,
        $translate,
        toaster,
        $q,
        partyId,
        assignedProfiles,
        toAssign,
        securityApiSvc,
        partyApiSvc
    ));
}