namespace RemeCare.Patient {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import ContactPerson = RemeCare.Model.ContactPersonPerson;

    class EditContactPersonComponent implements ng.IComponentController {
        public contactPersonTypes: EnumTranslation[];
        public personTitles: EnumTranslation[];
        public communicationLanguages: EnumTranslation[];
        public languages: EnumTranslation[];
        public nationalities: EnumTranslation[];
        public genders: EnumTranslation[];
        public maritalStatuses: EnumTranslation[];
        public educationLevels: EnumTranslation[];
        public technologicalAffinities: EnumTranslation[];
        public personalInfoForm: Shared.Framework.Directive.IFormController;
        public internalRemarkForm: ng.IFormController;
        public contactPerson: ContactPerson;
        public patientId: Shared.Contract.Guid;
        public readonly: boolean;

        constructor(
            private readonly $stateParams: RemeCare.Patient.EditContactPersonStateParams,
            private readonly $state: ng.ui.IStateService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly patientSvc: Patient.PatientService
        ) {}

        public $onInit(): void {
            this.patientId = this.$stateParams.patientId;
            if (this.$stateParams.contactPerson) {
                this.contactPerson = this.$stateParams.contactPerson;
            } else {
                this.loadContactPersonAsync(this.$stateParams.contactPersonId);
            }
            this.isReadonly();
            this.retrieveMasterDataAsync();
        }

        public async reload(): Promise<void> {
            try {
                const p = await this.partyApiSvc.getPersonDetailsAsync(this.contactPerson.partyId, true);
                const temp = angular.copy(this.contactPerson);
                temp.updateContactPoints(p);
                this.contactPerson = temp;
                this.isReadonly();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public onCancel(): void {
            this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                patientId: this.$stateParams.patientId,
            } as RemeCare.Patient.ContactPersonOverviewStateParams);
        }

        public async confirm(): Promise<void> {
            this.personalInfoForm.$setSubmitted();

            if (this.personalInfoForm.$invalid) {
                this.personalInfoForm.showValidationErrorMessage();
                return;
            }

            const registeredContactPerson = this.contactPerson.toRegisterContactPerson();
            registeredContactPerson.ContactPoints = [];

            try {
                await this.partyApiSvc.createContactPersonAsync(this.patientId, registeredContactPerson);
                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                    patientId: this.$stateParams.patientId,
                } as RemeCare.Patient.ContactPersonOverviewStateParams);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadContactPersonAsync(partyId: Shared.Contract.Guid): Promise<void> {
            try {
                const [personDetail, contactPerson]: [
                    Contract.Party.Read.IPersonDetail,
                    Contract.HealthCareParty.Read.IContactPerson
                ] = await Promise.all([
                    this.partyApiSvc.getPersonDetailsAsync(partyId, true),
                    this.patientSvc.getContactPersonAsync(this.patientId, partyId, { includeInactive: false }),
                ]);
                this.contactPerson = new ContactPerson(personDetail, contactPerson);
                this.isReadonly();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async retrieveMasterDataAsync(): Promise<void> {
            try {
                [
                    this.personTitles,
                    this.communicationLanguages,
                    this.languages,
                    this.nationalities,
                    this.genders,
                    this.maritalStatuses,
                    this.educationLevels,
                    this.technologicalAffinities,
                    this.contactPersonTypes,
                ] = await Promise.all([
                    this.masterdataSvc.getTitlesAsync(),
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getLanguagesAsync(),
                    this.masterdataSvc.getNationalitiesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.masterdataSvc.getMaritalStatussesAsync(),
                    this.masterdataSvc.getEducationsAsync(),
                    this.masterdataSvc.getTechnologicalAffinitiesAsync(),
                    this.masterdataSvc.getContactPersonTypesAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private isReadonly(): void {
            this.readonly = true;
            if (this.contactPerson) {
                this.readonly = _(this.contactPerson.partyRoles).any(
                    pr =>
                        pr.PartyRoleType.Id !== Shared.Contract.Code.PartyRoleType.ContactPerson &&
                        pr.PartyRoleType.Id !== Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional
                );
            }
        }
    }

    remeCarePatientModule.component('rcEditContactPerson', {
        templateUrl: 'views/patient/patientInfo/contactPersons/editContactPerson.html',
        controller: EditContactPersonComponent,
        bindings: {},
    });
}
