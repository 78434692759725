module RemeCare.SelfService {
    class ResetPasswordController implements ng.IComponentController {
        public activating: boolean;
        public key: string;
        public userId: string;

        // bindings
        public $transition$;

        constructor(
            private readonly $location: ng.ILocationService,
            private readonly $window: ng.IWindowService,
            private readonly selfServiceApi: SelfServiceApiService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public $onInit(): void {
            this.activating = this.$transition$.params().activating;
            this.key = this.$transition$.params().key;
            this.userId = this.$transition$.params().userId;
        }

        public async resetPasswordAsync(birthDate: Date, password: string): Promise<string> {
            try {
                await this.selfServiceApi.resetPasswordAsync(this.key, birthDate, this.userId, password);
                return 'Views.SelfService.ResetPassword.ResetSuccess';
            } catch (e) {
                const message = e && e.hasOwnProperty('Message') ? e.Message : e;
                this.toaster.error(message);
                throw message;
            }
        }

        public async loadProvidersAsync(): Promise<Contract.Security.IExternalIdentityProvider[]> {
            try {
                const result = await this.selfServiceApi.getExternalIdentityProvidersAsync(
                    this.key,
                    this.userId,
                    Contract.Security.Codes.ActivationType.NewPassword
                );
                return result;
            } catch (e) {
                const message = e && e.hasOwnProperty('Message') ? e.Message : e;
                throw message;
            }
        }

        public async activateProviderAsync(): Promise<string> {
            try {
                await this.selfServiceApi.resetExternalIdentityProviderAsync(this.key, this.userId);
                return 'Views.SelfService.ExternalLinkSuccess';
            } catch (e) {
                const message = e && e.hasOwnProperty('Message') ? e.Message : e;
                throw message;
            }
        }

        public selectProvider(provider: Contract.Security.IExternalIdentityProvider): void {
            if (provider.AlreadyLinked) {
                this.$window.location.href = provider.RemeCareBaseUrl;
            } else {
                this.$location.search('activating', true);
                const target = this.$location.absUrl().replace(this.$window.location.origin, provider.RemeCareBaseUrl);
                this.$window.location.href = target;
            }
        }
    }

    remeCareSelfServiceModule.component('rcResetPassword', {
        bindings: {
            $transition$: '<'
        },
        controller: ResetPasswordController,
        templateUrl: 'views/selfService/resetPassword.html'
    });
}
