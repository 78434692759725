/// <reference path="componentDirectiveBase.ts" />

namespace RemeCare.CarePlanAction {
    interface IProvideInformationObjectScope extends IComponentScope {
        component: ProvideInformationObjectDescriptor;
        getDocumentUrl(component: ProvideInformationObjectDescriptor): string;
    }

    class ProvideInformationObjectController extends ComponentControllerBase<IProvideInformationObjectScope> {
        // @ngInject
        constructor(
            $scope: IProvideInformationObjectScope,
            private readonly baseUrl: string,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            $scope.getDocumentUrl = (component: ProvideInformationObjectDescriptor) => this.getDocumentUrl(component);
        }

        public getDocumentUrl(component: ProvideInformationObjectDescriptor): string {
            return this.baseUrl + 'Documents/' + component.documentId + '/' + component.name.replace(/[/\\*&=+%]/g, '');
        }

        protected init(): void {}
    }

    class ProvideInformationObjectDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/provideInformationObject.html';

        public controller = ProvideInformationObjectController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcProvideInformationObject', () => new ProvideInformationObjectDirective());
}
