var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var DateFilterTypeCode;
                (function (DateFilterTypeCode) {
                    DateFilterTypeCode[DateFilterTypeCode["PlannedDate"] = 1] = "PlannedDate";
                    DateFilterTypeCode[DateFilterTypeCode["ExecutionDate"] = 2] = "ExecutionDate";
                    DateFilterTypeCode[DateFilterTypeCode["ModificationDate"] = 3] = "ModificationDate";
                    DateFilterTypeCode[DateFilterTypeCode["RegistrationDate"] = 4] = "RegistrationDate";
                    DateFilterTypeCode[DateFilterTypeCode["ObservationDate"] = 5] = "ObservationDate";
                    DateFilterTypeCode[DateFilterTypeCode["CreationDate"] = 6] = "CreationDate";
                    DateFilterTypeCode[DateFilterTypeCode["HandledDate"] = 7] = "HandledDate";
                    DateFilterTypeCode[DateFilterTypeCode["SuggestedDate"] = 8] = "SuggestedDate";
                    DateFilterTypeCode[DateFilterTypeCode["EventDate"] = 9] = "EventDate";
                })(DateFilterTypeCode = Codes.DateFilterTypeCode || (Codes.DateFilterTypeCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
