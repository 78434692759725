namespace RemeCare.Patient {
    import Patient = Contract.Patient.Read.IPatient;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import PartyRoleType = Shared.Contract.Code.PartyRoleType;
    import PhoneNumber = RemeCare.Model.PhoneNumber;
    import IOrganisation = Contract.Party.Read.IOrganisation;
    import IPerson = Contract.Party.Read.IPerson;
    import IPartyRole = Contract.Party.Read.IPartyRole;
    import IPartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;

    interface IPatientSearchFilters {
        carePlanStatus: number;
    }

    class PatientSearchController implements ng.IComponentController {
        // bindings
        public bindToUrl: boolean;
        public onPatientSelected: (params: { patient: Patient }) => void;

        public patientGrid: Shared.Framework.Grid.Grid<Patient>;
        public searchCollapsed: boolean;
        public patientSearchForm: Shared.Framework.Directive.IFormController;
        public countries: EnumCodeTranslation[];
        public communicationLanguages: EnumCodeTranslation[];
        public languages: EnumCodeTranslation[];
        public genders: EnumTranslation[];
        public therapies: EntityTranslation[];
        public carePlanStatuses: EnumTranslation[];
        public isInternalUser: boolean;
        private gridBuilder: Shared.Framework.Grid.SearchGridBuilder<Patient>;

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly patientSearchSvc: Patient.PatientSearchService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private authservice: Shared.Framework.AuthService
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadMasterdataAsync();
            this.isInternalUser = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientInternal,
                Shared.Framework.AuthRight.Read
            );
        }

        private setSearchCriteria() : void {
            const knownInformation = this.storageSvc.get<IPatientSearchFilters>(StorageServiceConstants.patientKey);

            this.patientGrid = this.gridBuilder
                .setRowDetailsTemplate('patient/components/patientSearch/patientSearchDetail.html', 120)
                .setSearchCriteria((c) => {
                    c.phoneNumber =
                        c.phoneNumber instanceof String || !c.phoneNumber
                            ? PhoneNumber.fromString(c.phoneNumber)
                            : PhoneNumber.fromObject(c.phoneNumber);
                    c.carePlanStatus = c.carePlanStatus || (knownInformation && knownInformation.carePlanStatus);
                    this.setDelayedCriteria(c);
                })
                .build();
        }

        private async setDelayedCriteria(criteria?: IPatientSearchFilters) : Promise<void>
        {
            this.carePlanStatuses = await this.masterdataSvc.getCarePlanStatusesAsync();

            if(criteria)
            {
                if(criteria.carePlanStatus)
                {
                    if(this.carePlanStatuses.map(cps => cps.Id).indexOf(criteria.carePlanStatus) === -1)
                    {
                        criteria.carePlanStatus = null;
                    }
                }
            }
        }

        private handleFilters(criteria: IPatientSearchFilters): void {
            let newCarePlanStatus = null; 

            if (criteria.carePlanStatus) {
                newCarePlanStatus = criteria.carePlanStatus
            }

            const knownFilters: IPatientSearchFilters = {
                carePlanStatus: newCarePlanStatus
            };

            this.storageSvc.store(StorageServiceConstants.patientKey, knownFilters);
        }

        private async loadMasterdataAsync(): Promise<void> {
            try {
                [
                    this.communicationLanguages,
                    this.countries,
                    this.languages,
                    this.genders,
                    this.therapies,
                ] = await Promise.all([
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getLanguagesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.therapyApiSvc.getTherapiesAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<Patient>> {
            sortField = sortField || 'lastName';
            sortDirection = sortDirection || 'asc';

            const query = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                identificationNumber: criteria.identificationNumber,
                dateOfBirth: Shared.DateHelper.toServerDateString(criteria.birthDate),
                address: criteria.address,
                zipCode: criteria.zipCode,
                city: criteria.city,
                country: criteria.country,
                email: criteria.email,
                gender: criteria.gender,
                communicationLanguage: criteria.communicationLanguage,
                language: criteria.language,
                phoneNumber: criteria.phoneNumber ? criteria.phoneNumber.toServerWrite() : null,
                partyRoleType: Shared.Contract.Code.PartyRoleType.Patient,
                therapyId: criteria.therapyId,
                carePlanStatus: criteria.carePlanStatus,
                patientNumber: criteria.patientNumber,
                carePlanStartDateFrom: Shared.DateHelper.toServerDateString(criteria.carePlanStartDateFrom),
                carePlanStartDateUntil: Shared.DateHelper.toServerDateString(criteria.carePlanStartDateUntil),
                careRequestorHealthCareProfessionalId:
                    criteria.careRequestorPerson &&
                    _.find(
                        criteria.careRequestorPerson.PartyRoles,
                        (pr: IPartyRoleSummary) => pr.Type.Id === PartyRoleType.HealthCareProfessional
                    ).Id,
                assignedHealthCareOrganisationId:
                    criteria.careRequestorOrganisation &&
                    _.find(
                        criteria.careRequestorOrganisation.PartyRoles,
                        (pr: IPartyRole) => pr.PartyRoleType.Id === PartyRoleType.HealthCareOrganisation
                    ).PartyRoleId,
            } as Contract.Party.Read.Query.IFindPatientsQuery;

            this.handleFilters(criteria);
            try {
                const searchResult = await this.patientSearchSvc.findPatientsAsync(query);
                const maxNbOfCarePlans = _.chain(searchResult.Items)
                    // use 1 as minimum, so the grid doesn't get shrunk and not show the text for care plans
                    .map(p => (p.CarePlans && p.CarePlans.length) || 1)
                    .max()
                    .value();
                const detailsHeight = 130 + (maxNbOfCarePlans - 1) * 31;
                this.patientGrid.gridOptions.expandableRowHeight = detailsHeight;
                this.searchCollapsed = true;
                return searchResult;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private buildGrid() {
            const propertyMappers = {
                careRequestorPerson: {
                    getObject: (stringValue: string) => this.partyApiSvc.getPersonAsync(stringValue),
                    getString: (object: IPerson) => object && (object.PartyId as string),
                },
                careRequestorOrganisation: {
                    getObject: (stringValue: string) => this.partyApiSvc.getOrganisationAsync(stringValue),
                    getString: (object: IOrganisation) => object && (object.Id as string),
                },
                phoneNumber: {
                    getObject: (stringValue: string) => Promise.resolve(PhoneNumber.fromString(stringValue)),
                    getString: (object: PhoneNumber) => object && object.toString(),
                },
            } as Shared.Framework.Factory.PropertyMappers;
            this.gridBuilder = this.gridBuilderSvc
                .createGridBuilder<Patient>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .setBindToUrl(this.bindToUrl, propertyMappers)
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('PatientNumber', 'Patient.PatientNumber');

            this.gridBuilder = this.gridBuilder
                .addColumn('DateOfBirth', 'Views.Patient.General.DateOfBirth', { cellFilter: 'date: "shortDate" ' })
                .addColumn('City', 'General.Place')
                .addColumn('CommunicationLanguage', 'General.Language');
            if (this.onPatientSelected == null) {
                this.gridBuilder = this.gridBuilder.addNavigationColumn('folder-open', 'patients.patientfile', { patientId: 'Id' });
            } else {
                this.gridBuilder = this.gridBuilder.addSelectButtonColumn(p => this.onPatientSelected({ patient: p }));
            }

            this.setSearchCriteria();
        }
    }

    remeCarePatientModule.component('rcPatientSearch', {
        bindings: {
            bindToUrl: '@?',
            onPatientSelected: '&?',
        },
        controller: PatientSearchController,
        templateUrl: 'patient/components/patientSearch/patientSearch.html',
    });
}
