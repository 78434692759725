var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PersonalisedStructuralTherapyActionController = /** @class */ (function (_super) {
            __extends(PersonalisedStructuralTherapyActionController, _super);
            function PersonalisedStructuralTherapyActionController($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc, modalBuilderFactory) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$dialog = $dialog;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                $scope.modifyConfiguration = function (c) { return _this.modifyConfiguration(c); };
                $scope.canEdit = function (c) { return _this.canEdit(c); };
                return _this;
            }
            PersonalisedStructuralTherapyActionController.prototype.$onInit = function () {
                var _this = this;
                this.buildGrids();
                this.$scope.$watch(function (s) { return s.personalisedAction; }, function (newValue, oldValue) {
                    if (newValue !== oldValue && newValue != null) {
                        _this.setData();
                    }
                });
                if (this.$scope.personalisedAction) {
                    this.setData();
                }
            };
            PersonalisedStructuralTherapyActionController.prototype.getReadableTime = function (date) {
                return RemeCare.Shared.DateHelper.timeHumanReadible(date);
            };
            PersonalisedStructuralTherapyActionController.prototype.setData = function () {
                var firstValue = _(this.$scope.personalisedAction.configuredTherapyActions)
                    .chain()
                    .sortBy(function (t) { return t.validFromDate; })
                    .first()
                    .value();
                if (firstValue && moment(firstValue.validFromDate).isBefore(this.$scope.carePlanStartDate)) {
                    firstValue.validFromDate = this.$scope.carePlanStartDate;
                }
                this.$scope.configuredTherapyActionGrid.setData(this.$scope.personalisedAction.configuredTherapyActions);
                this.$scope.selectedConfiguredTherapyAction = _(this.$scope.personalisedAction.configuredTherapyActions)
                    .chain()
                    .filter(function (cta) { return cta.validFromDate <= RemeCare.Shared.DateHelper.today(); })
                    .sortBy(function (cta) { return cta.validFromDate; })
                    .last()
                    .value();
                this.setNextAndLastDate();
                this.$scope.readableDurationOfAction = this.getReadableTime(this.$scope.selectedConfiguredTherapyAction.durationOfAction);
            };
            PersonalisedStructuralTherapyActionController.prototype.setNextAndLastDate = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var dates, lastDateTime, dates, nextDateTime, dates, nextDateTime, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.$scope.lastDate = null;
                                this.$scope.nextDate = null;
                                // The nextDate & lastdate cannot be calculated starting from sequence 2
                                if (this.$scope.structuralTherapyAction.Sequence !== 1) {
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 8, , 9]);
                                if (!(this.$scope.selectedConfiguredTherapyAction.periodicities.length > 0)) return [3 /*break*/, 7];
                                if (!moment(this.$scope.selectedConfiguredTherapyAction.validUntilDate)
                                    .startOf('day')
                                    .isBefore(moment().startOf('day'))) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.carePlanApiSvc.getNextAndLastScheduledDateAsync(this.$scope.structuralTherapyAction.Id, this.$scope.carePlanId, RemeCare.Shared.DateHelper.toServerDateString(moment(this.$scope.selectedConfiguredTherapyAction.validUntilDate)
                                        .add(1, 'days')
                                        .toDate()))];
                            case 2:
                                dates = _a.sent();
                                lastDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(dates.Last);
                                if (lastDateTime != null &&
                                    moment(lastDateTime).isAfter(moment(this.$scope.selectedConfiguredTherapyAction.validFromDate))) {
                                    this.$scope.lastDate = RemeCare.Shared.DateHelper.dateTimeHumanReadible(lastDateTime);
                                }
                                return [3 /*break*/, 7];
                            case 3:
                                if (!moment(this.$scope.selectedConfiguredTherapyAction.validFromDate).isAfter(moment())) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.carePlanApiSvc.getNextAndLastScheduledDateAsync(this.$scope.structuralTherapyAction.Id, this.$scope.carePlanId, RemeCare.Shared.DateHelper.toServerDateString(this.$scope.selectedConfiguredTherapyAction.validFromDate))];
                            case 4:
                                dates = _a.sent();
                                nextDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(dates.Next);
                                if (nextDateTime != null) {
                                    this.$scope.nextDate = RemeCare.Shared.DateHelper.dateTimeHumanReadible(nextDateTime);
                                }
                                return [3 /*break*/, 7];
                            case 5: return [4 /*yield*/, this.carePlanApiSvc.getNextAndLastScheduledDateAsync(this.$scope.structuralTherapyAction.Id, this.$scope.carePlanId, RemeCare.Shared.DateHelper.momentToServerDateTimeString(moment()))];
                            case 6:
                                dates = _a.sent();
                                nextDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(dates.Next);
                                if (nextDateTime != null) {
                                    this.$scope.nextDate = RemeCare.Shared.DateHelper.dateTimeHumanReadible(nextDateTime);
                                }
                                _a.label = 7;
                            case 7: return [3 /*break*/, 9];
                            case 8:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 9];
                            case 9: return [2 /*return*/];
                        }
                    });
                });
            };
            PersonalisedStructuralTherapyActionController.prototype.calcMinDate = function () {
                var today = moment(RemeCare.Shared.DateHelper.today());
                var fromDate;
                if (this.$scope.selectedConfiguredTherapyAction.id) {
                    var index = this.$scope.personalisedAction.configuredTherapyActions.length - 1;
                    fromDate =
                        index >= 0
                            ? moment(this.$scope.personalisedAction.configuredTherapyActions[index].validFromDate)
                            : today;
                }
                else {
                    fromDate = moment(_(this.$scope.personalisedAction.configuredTherapyActions).last().validFromDate);
                }
                this.$scope.minDate = fromDate.isAfter(today)
                    ? fromDate.add(1, 'days').toDate()
                    : today.add(1, 'days').toDate();
            };
            PersonalisedStructuralTherapyActionController.prototype.buildGrids = function () {
                var _this = this;
                var template = "<div ng-if= \"(row.entity.durationOfAction != null || row.entity.possibleActionDurationOptionId != null) && row.entity.isPersonalised\" class=\"text-center vertical-center\">  <span class=\"fa-stack fa-sm\" > <i class=\"fa fa-calendar-o fa-stack-2x\" style=\"font-size:17px; margin-top:4px;\"></i> <i class=\"fa fa-clock-o fa-stack-1x\" style=\"font-size:12px; margin:4px\"></i></span></div>";
                this.$scope.configuredTherapyActionGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('validFromDate', 'General.Versions.ValidFrom', { cellFilter: 'date: "shortDate"' })
                    .addColumn('validUntilDate', 'General.Versions.ValidUntil', { cellFilter: 'date: "shortDate"' })
                    .addIconColumn('Views.PatientFile.CarePlans.PersonalisedExcluded', function (cta) { return (cta.isRemovedFromCarePlan ? 'eye-slash' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addIconColumn('Views.PatientFile.CarePlans.PeriodicityPersonalised', function (cta) { return (cta.isPeriodicitiesPersonalised ? 'calendar' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addIconColumn('Views.PatientFile.CarePlans.IntradayTimingPersonalised', function (cta) { return (cta.isIntradayTimingPersonalised ? 'clock-o' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addCustomColumn('Views.PatientFile.CarePlans.Planning', template, function () { }, { width: '100' })
                    .addAction(function (cta) {
                    _this.$scope.selectedConfiguredTherapyAction = cta;
                    _this.setNextAndLastDate();
                    _this.$scope.readableDurationOfAction = _this.getReadableTime(_this.$scope.selectedConfiguredTherapyAction.durationOfAction);
                })
                    .addConditionalDeleteButtonColumn(function (cta) { return !_this.canEdit(cta) || _this.$scope.editRights.readOnly; }, function (cta) { return _this.delete(cta); }, function (cta) { return _this.confirmDelete(cta); })
                    .setSorting(false)
                    .build();
                this.$scope.configuredTherapyActionGrid.setData([]);
            };
            PersonalisedStructuralTherapyActionController.prototype.canEdit = function (configuredTherapyAction) {
                if (!configuredTherapyAction) {
                    return false;
                }
                var lastPersonal = _(this.$scope.personalisedAction.configuredTherapyActions)
                    .chain()
                    // .filter(cta => cta.isPersonalised)
                    .last()
                    .value();
                return (lastPersonal != null &&
                    configuredTherapyAction.id === lastPersonal.id &&
                    configuredTherapyAction.canBeEdited());
            };
            PersonalisedStructuralTherapyActionController.prototype.delete = function (configuredTherapyAction) {
                this.$scope.actions.reload();
            };
            PersonalisedStructuralTherapyActionController.prototype.confirmDelete = function (configuredTherapyAction) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.$dialog.confirmBox('General.Versions.DeleteVersionConfirm', 'General.Versions.DeleteVersionConfirmMessage', function () { return __awaiter(_this, void 0, void 0, function () {
                        var e_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.carePlanApiSvc.deletePersonalisedTherapyActionAsync(this.$scope.carePlanId, this.$scope.structuralTherapyAction.Id, configuredTherapyAction.validFromDate)];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_2 = _a.sent();
                                    this.errorCallback(e_2);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, function () {
                        resolve(false);
                    });
                });
            };
            PersonalisedStructuralTherapyActionController.prototype.modifyConfiguration = function (configuration) {
                var _this = this;
                return new Promise(function (resolve) {
                    var newConfiguration = configuration ? configuration.copy() : new Patient.ConfiguredTherapyAction();
                    if (configuration == null) {
                        newConfiguration.periodicities = _(_this.$scope.selectedConfiguredTherapyAction.periodicities).map(function (p) { return p.copy(); });
                    }
                    var lastValue = _(_this.$scope.personalisedAction.configuredTherapyActions)
                        .chain()
                        .sortBy(function (t) { return t.validFromDate; })
                        .last()
                        .value();
                    newConfiguration.isPersonalised = true;
                    newConfiguration.resetToDefault = false;
                    _this.calcMinDate();
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('editActionCtrl')
                        .setTemplateUrl('views/patient/carePlans/carePlanActions/editAction.html')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        configuredTherapyAction: newConfiguration,
                        minDate: _this.$scope.minDate,
                        omitEnabled: _this.$scope.structuralTherapyAction.Sequence === 1 &&
                            !_this.$scope.structuralTherapyAction.IsMandatoryInCarePlan,
                        carePlanId: _this.$scope.carePlanId,
                        structuralTherapyActionId: _this.$scope.structuralTherapyAction.Id,
                        defaultConfigurations: _this.$scope.personalisedAction.defaultConfigurations,
                        canPersonalisePeriodicity: _this.$scope.structuralTherapyAction.CanPersonalisePeriodicity,
                        canPersonaliseDeviationPlanning: _this.$scope.structuralTherapyAction
                            .CanPersonaliseDeviationPlanning,
                        previousPersonalised: lastValue.isPersonalised,
                        name: _this.$scope.structuralTherapyAction.StructuralTherapyActionName,
                        hasActionDurationOption: _this.$scope.structuralTherapyAction.HasActionDurationOption,
                        possibleActionDurationOptions: _this.$scope.structuralTherapyAction
                            .PossibleActionDurationOptions,
                    })
                        .setResultCallBack(function (r) {
                        _this.$scope.actions.reload();
                        resolve(r);
                    })
                        .build();
                });
            };
            return PersonalisedStructuralTherapyActionController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('personalisedStructuralTherapyActionController', function ($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc, modalBuilderFactory) {
            return new PersonalisedStructuralTherapyActionController($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc, modalBuilderFactory);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
