namespace RemeCare.Framework {
    class RemeCareLocation {
        public $onInit(): void {}
    }

    remeCareAppModule.component('rcLocation', {
        controller: RemeCareLocation,
        templateUrl: 'framework/components/location/location.html',
        bindings: {
            address: '=',
            disabled: '=?ngDisabled',
            changeLocation: '&?',
        },
    });
}
