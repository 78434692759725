module RemeCare.Management {
    import IGroup = Contract.Management.Read.IGroup;
    import SearchResult = Shared.Contract.ISearchResult;

    class SearchGroupController implements ng.IComponentController {
        public grid: Shared.Framework.Grid.Grid<Contract.Management.Read.IGroup>;
        public searchCollapsed: boolean;

        constructor(
            private toaster: Shared.Framework.Toaster,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private partyApiSvc: Core.Services.PartyApiService) {
        }

        public $onInit(): void {
            this.buildGrid();
        }


        private buildGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<Contract.Management.Read.IGroup>(
                (page, pageSize, sortField, sortDirection, criteria) =>
                    this.searchGroups(page, pageSize, sortField, sortDirection, criteria))
                .setBindToUrl()
                .addColumn('Name', 'General.Name')
                .addDateColumn('ValidFromDate', 'General.StartDate', 'shortDate')
                .addDateColumn('ValidUntilDate', 'General.EndDate', 'shortDate')
                .addNavigationColumn('pencil', 'management.groups.groupInformation', { readOnly: true, groupId: 'Id' });

            this.grid = builder.build();
        }

        private searchGroups(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: any): JQueryPromise<SearchResult<IGroup>> {
            let query = <Contract.Management.Read.Query.IFindGroupsQuery>{
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                memberFirstName: criteria.memberFirstName,
                memberLastName: criteria.memberLastName,
                groupName: criteria.groupName,
                organisationName: criteria.organisationName,
                includeExpiredGroups: criteria.includeExpiredGroups
            }
            const deferred = jQuery.Deferred<SearchResult<IGroup>>();
            this.partyApiSvc.findGroups(query)
                .success(r => {
                    deferred.resolve(r);
                    this.searchCollapsed = true;
                })
                .error(e => deferred.reject(e));
            return deferred.promise();
        }
    }
    RemeCare.Management.remeCareManagementModule.component('rcSearchGroups',
        {
            controller: SearchGroupController,
            controllerAs: '$ctrl',
            bindings: {
            },
            templateUrl: 'management/controllers/groups/searchGroups/searchGroups.html'
        });
}