var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileAddSufferedDiseaseModalController = /** @class */ (function (_super) {
            __extends(PatientFileAddSufferedDiseaseModalController, _super);
            function PatientFileAddSufferedDiseaseModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.$stateParams = $stateParams;
                _this.patientSvc = patientSvc;
                _this.$scope.diseaseChanged = function () { return _this.diseaseChanged(); };
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileAddSufferedDiseaseModalController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                this.$scope.selectedTherapies = [];
                _(this.$scope.sufferedDisease.sufferedDiseaseTherapies).chain()
                    .map(function (x) { return x.Id; })
                    .forEach(function (x) { return _this.$scope.selectedTherapies.push(x); });
                if (this.$scope.sufferedDisease.disease != null) {
                    this.$scope.selectedDisease = _.find(this.$scope.diseases, function (d) { return d.Id === _this.$scope.sufferedDisease.disease.Id; });
                }
            };
            PatientFileAddSufferedDiseaseModalController.prototype.diseaseChanged = function () {
                this.$scope.selectedTherapies = [];
            };
            PatientFileAddSufferedDiseaseModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.sufferedDisease.disease = this.$scope.selectedDisease;
                this.$scope.sufferedDisease.sufferedDiseaseTherapies = [];
                _(this.$scope.selectedTherapies).forEach(function (x) { return _this.$scope.sufferedDisease
                    .addTherapyLink(_.find(_this.$scope.selectedDisease.Therapies, function (t) { return t.Id === x; })); });
                this.patientSvc.savePatientFileSufferedDisease(this.$scope.patientId, this.$scope.sufferedDisease.toServerWrite())
                    .success(function () { return _this.$scope.$close(_this.$scope.sufferedDisease); })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            return PatientFileAddSufferedDiseaseModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('patientFileAddSufferedDiseaseModalCtrl', function ($scope, $translate, toaster, $dialog, $stateParams, patientSvc) { return new PatientFileAddSufferedDiseaseModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
