/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {
    
    interface ITherapyOptionScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.OptionDefinition;
        therapyOption: TherapyOption;
    }

    class TherapyOptionController extends CareRequestPartControllerBase<ITherapyOptionScope> {
        
        constructor(
            $scope: ITherapyOptionScope,
            $translate,
            toaster,
            careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.$scope.therapyOption = _.find(this.$scope.careRequest.therapyOptions,
                t => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity);
            if (this.$scope.therapyOption == null) {
                this.$scope.therapyOption = new TherapyOption();
                this.$scope.therapyOption.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.$scope.careRequest.therapyOptions.push(this.$scope.therapyOption);
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }
    }

    class TherapyOptionDirective extends CareRequestPartDirectiveBase {
        
        controller = ['$scope', '$translate', 'toaster', 'careRequestSvc',
            ($scope, $translate, toaster, careRequestSvc) => new TherapyOptionController($scope, $translate, toaster, careRequestSvc)];

        templateUrl = 'views/careRequest/directive/therapyOption.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestTherapyOption', () => new TherapyOptionDirective());
}