module RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import AnamnesisInfo = Patient.Model.AnamnesisInfo;

    interface IPatientFileAddAnamnesisInfoModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        anamnesisInfo: AnamnesisInfo;

        form: ng.IFormController;

        confirm: () => void;
    }

    class PatientFileAddAnamnesisInfoModalController extends Shared.Framework.ControllerBase<IPatientFileAddAnamnesisInfoModalScope> {

        constructor(
            $scope: IPatientFileAddAnamnesisInfoModalScope,
            $translate,
            toaster,
            private $dialog,
            private $stateParams,
            private patientSvc: Patient.PatientService,
            private masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }
        
        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            if (this.$scope.anamnesisInfo.id === null || this.$scope.anamnesisInfo.id === undefined) {
                this.patientSvc.saveAnamnesisInfo(this.$scope.patientId, this.$scope.anamnesisInfo.toServerWrite())
                    .success(() => this.$scope.$close())
                    .error((m) => this.errorCallback(m));
            } else {
                this.patientSvc.updateAnamnesisInfo(this.$scope.patientId, this.$scope.anamnesisInfo.id, this.$scope.anamnesisInfo.toServerWrite())
                    .success(() => this.$scope.$close())
                    .error((m) => this.errorCallback(m));
            }
        }
    }

    angular.module('RemeCare.Patient').controller('patientFileAddAnamnesisInfoModalCtrl', (
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        patientSvc: Patient.PatientService,
        masterdataSvc: Shared.Framework.MasterdataService
    ) => new PatientFileAddAnamnesisInfoModalController(
            $scope,
            $translate,
            toaster,
            $dialog,
            $stateParams,
            patientSvc,
            masterdataSvc
    ));
}