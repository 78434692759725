namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import IEntityTranslation = Shared.Contract.IEntityTranslation;

    interface IPersonalisations {
        periodicity: boolean;
        deviationPlanning: boolean;
        periodicityAndDeviationPlanning: boolean;
    }

    interface IEditActionScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        configuredTherapyAction: ConfiguredTherapyAction;
        defaultConfigurations: ConfiguredTherapyAction[];
        minDate: Date;
        omitEnabled: boolean;
        carePlanId: Guid;
        structuralTherapyActionId: Guid;
        canPersonalisePeriodicity: boolean;
        canPersonaliseDeviationPlanning: boolean;
        previousPersonalised: boolean;
        confirm(): Promise<void>;
        name: string;
        omitChangedcheckValidFromDate(): void;
        omitChanged(): void;
        resetToDefaultChanged(): void;
        periodicityChanged(): void;
        deviationPlanningChanged(): void;
        periodicityAndDeviationPlanningChanged(): void;
        possibleActionDurationOptions: IEntityTranslation[];
        hasActionDurationOption: boolean;
        personalisations: IPersonalisations;
    }

    class EditActionController extends Shared.Framework.ControllerBase<IEditActionScope> {
        constructor(
            protected $scope: IEditActionScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
            $scope.checkValidFromDate = () => this.checkValidFromDate();
            $scope.omitChanged = () => this.omitChanged();
            $scope.resetToDefaultChanged = () => this.resetToDefaultChanged();
            $scope.periodicityChanged = () => this.periodicityChanged();
            $scope.deviationPlanningChanged = () => this.deviationPlanningChanged();
            $scope.periodicityChanged = () => this.periodicityChanged();
            $scope.periodicityAndDeviationPlanningChanged = () => this.periodicityAndDeviationPlanningChanged();
        }

        public $onInit(): void {
            this.$scope.personalisations = {
                periodicity: false,
                periodicityAndDeviationPlanning: false,
                deviationPlanning: false
            }
            this.setRadioButtonOptionsCorrect();
            this.$scope.$watch(
                (s: IEditActionScope) => s.configuredTherapyAction.resetToDefault,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        if (newValue) {
                            this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                            const defaultConfiguration =
                                this.getDefaultConfiguration(this.$scope.configuredTherapyAction.validFromDate);
                            this.setDefaultConfigurationPeriodicity(
                                defaultConfiguration);
                            this.setDefaultConfigurationDuration(defaultConfiguration);
                        }
                    }
                }
            );
        }

        private setRadioButtonOptionsCorrect(): void {
            if (this.$scope.configuredTherapyAction.isPeriodicitiesPersonalised &&
                (this.$scope.configuredTherapyAction.possibleActionDurationOptionId != null ||
                    this.$scope.configuredTherapyAction.durationOfAction != null)) {
                this.$scope.personalisations.periodicityAndDeviationPlanning = true;
            } else {
                if (this.$scope.configuredTherapyAction.isPeriodicitiesPersonalised) {
                    this.$scope.personalisations.periodicity = true;
                }
                if (this.$scope.configuredTherapyAction.possibleActionDurationOptionId != null ||
                    this.$scope.configuredTherapyAction.durationOfAction != null) {
                    this.$scope.personalisations.deviationPlanning = true;
                }
            }
        }

        private setDefaultConfigurationToShow(): void {
            let date = Shared.DateHelper.today();
            if (this.$scope.configuredTherapyAction.validFromDate != null) {
                date = this.$scope.configuredTherapyAction.validFromDate;
            }
            const defaultConfiguration =
                this.getDefaultConfiguration(date);
            this.setDefaultConfigurationPeriodicity(defaultConfiguration);
            this.setDefaultConfigurationDuration(defaultConfiguration);
        }

        private personalisationsUncheck(): void {
            this.$scope.personalisations.periodicity = false;
            this.$scope.personalisations.deviationPlanning = false;
            this.$scope.personalisations.periodicityAndDeviationPlanning = false;
        }

        private omitChanged(): void {
            this.personalisationsUncheck();
            this.$scope.configuredTherapyAction.resetToDefault = false;
        }

        private resetToDefaultChanged(): void {
            this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
            this.personalisationsUncheck();
            this.setDefaultConfigurationToShow();
        }

        private periodicityChanged(): void {
            this.$scope.configuredTherapyAction.resetToDefault = false;
            this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
            this.$scope.personalisations.periodicityAndDeviationPlanning = false;
            this.$scope.personalisations.deviationPlanning = false;
        }

        private deviationPlanningChanged(): void {
            this.$scope.configuredTherapyAction.resetToDefault = false;
            this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
            this.$scope.personalisations.periodicityAndDeviationPlanning = false;
            this.$scope.personalisations.periodicity = false;
        }

        private periodicityAndDeviationPlanningChanged(): void {
            this.$scope.configuredTherapyAction.resetToDefault = false;
            this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
            this.$scope.personalisations.deviationPlanning = false;
            this.$scope.personalisations.periodicity = false;
        }

        private getDefaultConfiguration(date?: Date): ConfiguredTherapyAction {
            date = date ? Shared.DateHelper.dayOfDate(date) : Shared.DateHelper.today();
            const defaultConfiguration = _.find(
                this.$scope.defaultConfigurations,
                dc => dc.validUntilDate == null || moment(dc.validUntilDate).isAfter(date)
            );
            return defaultConfiguration;
        }

        private setDefaultConfigurationPeriodicity(defaultConfiguration: ConfiguredTherapyAction): void {
            this.$scope.configuredTherapyAction.periodicities = _(defaultConfiguration.periodicities).map<Shared.Framework.Model.Periodicity>(p => p.copy());
        }

        private setDefaultConfigurationDuration(defaultConfiguration: ConfiguredTherapyAction): void {
            this.$scope.configuredTherapyAction.durationOfAction = defaultConfiguration.durationOfAction;
            this.$scope.configuredTherapyAction.possibleActionDurationOptionId =
                defaultConfiguration.possibleActionDurationOptionId;
            this.$scope.configuredTherapyAction.possibleActionDurationOptionName =
                defaultConfiguration.possibleActionDurationOptionName;
        }

        private checkValidFromDate(): void {
            if (this.$scope.configuredTherapyAction.resetToDefault) {
                const defaultConfiguration =
                    this.getDefaultConfiguration(this.$scope.configuredTherapyAction.validFromDate);
                this.setDefaultConfigurationPeriodicity(
                    defaultConfiguration);
                this.setDefaultConfigurationDuration(defaultConfiguration);
            }
        }

        private async confirm(): Promise<void> {
            this.$scope.form.$setSubmitted();
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            const personalisedTherapyAction = this.$scope.configuredTherapyAction.toServerWrite();
            try {
                await this.carePlanApiSvc.setPersonalisedTherapyActionAsync(
                    this.$scope.carePlanId,
                    this.$scope.structuralTherapyActionId,
                    personalisedTherapyAction
                );
                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                this.$scope.$close();
            } catch (e) {
                this.errorCallback(e);
            }
        }
    }

    remeCarePatientModule.controller(
        'editActionCtrl',
        ($scope, $translate, toaster, carePlanApiSvc) =>
            new EditActionController($scope, $translate, toaster, carePlanApiSvc)
    );
}
