module RemeCare.CareRequest {
    import CareRequestPart = Shared.Framework.Model.CareRequestPart;

    interface IHospitalisationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        careRequestPart: CareRequestPart;
        therapyId: Shared.Contract.Guid;
        careTypes: Array<Shared.Contract.IEnumTranslation>;
        reasons: Array<Shared.Contract.IEntityTranslation>;
        hospitalisation: Model.Hospitalisation;
        form: ng.IFormController;
        now: Date;
        confirm(): void;
        isPresent(t: Shared.Contract.Code.CareRequestUIControlType): boolean;
        isRequired(t: Shared.Contract.Code.CareRequestUIControlType): boolean;
    }

    class HospitalisationController extends Shared.Framework.ControllerBase<IHospitalisationScope> {
        
        constructor(
            $scope,
            $translate,
            toaster,
            private $stateParams,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private careRequestSvc: CareRequestSvc,
            private therapyApiSvc: Core.Services.TherapyApiService) {
            super($scope, $translate, toaster);
            this.$scope.now = new Date();
            this.$scope.confirm = () => this.confirm();
            this.$scope.isPresent = (t) => this.isPresent(t);
            this.$scope.isRequired = (t) => this.isRequired(t);
        }

        public $onInit(): void {
            this.masterdataSvc.getCareTypes()
                .success(r => this.$scope.careTypes = r)
                .error(e => this.errorCallback(e));
            this.therapyApiSvc.getHospitalisationReasons(this.$scope.therapyId)
                .success(r => this.$scope.reasons = r)
                .error(e => this.errorCallback(e));
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.hospitalisation);
        }

        private isPresent(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id !== Shared.Contract.Code.ModalityType.Not;
        }

        private isRequired(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id === Shared.Contract.Code.ModalityType.Mandatory;
        }
    }

    remeCareCareRequestModule.controller('hospitalisationCtrl', (
        $scope,
        $translate,
        toaster,
        $stateParams,
        masterdataSvc,
        careRequestSvc: CareRequestSvc,
        therapyApiSvc: Core.Services.TherapyApiService
    ) => new HospitalisationController(
            $scope,
            $translate,
            toaster,
            $stateParams,
            masterdataSvc,
            careRequestSvc,
            therapyApiSvc
    ));
}