var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileCarePlanDetailController = /** @class */ (function (_super) {
            __extends(PatientFileCarePlanDetailController, _super);
            function PatientFileCarePlanDetailController($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.currentCodeSetTypesCache = [];
                _this.$scope.getRegistrationValue = function (value) { return _this.getRegistrationValue(value); };
                _this.$scope.getMissingValues = function (value) { return _this.getMissingValues(value); };
                return _this;
            }
            PatientFileCarePlanDetailController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                this.$scope.carePlanDetails = [];
                this.$scope.dataLoaded = false;
                if (this.$scope.carePlan) {
                    this.getCarePlanDetailsAsync(this.$scope.carePlan.id).then(function (r) {
                        _this.$scope.dataLoaded = true;
                    });
                }
                this.$scope.$watch('carePlan', function (newValue, oldValue) {
                    if (newValue !== oldValue && newValue !== null) {
                        _this.getCarePlanDetailsAsync(_this.$scope.carePlan.id).then(function (r) {
                            _this.$scope.dataLoaded = true;
                        });
                    }
                });
            };
            PatientFileCarePlanDetailController.prototype.getCarePlanDetailsAsync = function (carePlanId) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, _b, _i, _c, crp, a, codeSetItems, e_1;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _d.trys.push([0, 7, , 8]);
                                _a = this.$scope;
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanParameterValuesAsync(carePlanId)];
                            case 1:
                                _a.carePlanDetails = _d.sent();
                                this.$scope.editRight =
                                    _(this.$scope.carePlanDetails).any(function (detail) {
                                        return (detail.ExecutionRight &
                                            (RemeCare.Shared.Framework.AuthRight.Create | RemeCare.Shared.Framework.AuthRight.Write)) !==
                                            0;
                                    }) && this.$scope.carePlan.statusOpen;
                                _b = this.$scope;
                                return [4 /*yield*/, this.therapyApiSvc.getCareRequestTemplateAsync(this.$scope.carePlan.therapyId, carePlanId)];
                            case 2:
                                _b.careRequestTemplate = _d.sent();
                                _i = 0, _c = this.$scope.careRequestTemplate.CareRequestSetup.CareRequestParts;
                                _d.label = 3;
                            case 3:
                                if (!(_i < _c.length)) return [3 /*break*/, 6];
                                crp = _c[_i];
                                if (!(crp.QualitativeAnamnesis && crp.QualitativeAnamnesis.CodeSet)) return [3 /*break*/, 5];
                                a = crp;
                                return [4 /*yield*/, this.masterdataSvc.getCodeSetItemsAsync(crp.QualitativeAnamnesis.CodeSet.Id)];
                            case 4:
                                codeSetItems = _d.sent();
                                if (codeSetItems) {
                                    this.currentCodeSetTypesCache.push({
                                        codeSetTypeId: crp.QualitativeAnamnesis.CodeSet.Id,
                                        codeSetItems: codeSetItems,
                                    });
                                }
                                _d.label = 5;
                            case 5:
                                _i++;
                                return [3 /*break*/, 3];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                e_1 = _d.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 8];
                            case 8: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileCarePlanDetailController.prototype.getRegistrationValue = function (detail) {
                if (detail.RegistrationValue == null) {
                    return '';
                }
                if (this.getMissingValues(detail) !== '') {
                    return ''; // don't show the value if its (partially) incorrect
                }
                if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue) {
                    return detail.RegistrationValue.ChoiceListOption.Name;
                }
                else if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity) {
                    var unit = detail.Unit != null ? " " + detail.Unit.Text : '';
                    return "" + detail.RegistrationValue.Amount + unit;
                }
                else if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue) {
                    return _(detail.RegistrationValue.MultiChoiceListOptions)
                        .chain()
                        .sortBy(function (x) { return x.Sequence; })
                        .map(function (x) { return x.Name; })
                        .value()
                        .toString();
                }
                return '';
            };
            PatientFileCarePlanDetailController.prototype.getMissingValues = function (detail) {
                if (!this.$scope.dataLoaded) {
                    return '';
                }
                if (!detail) {
                    return '';
                }
                if (!detail.RegistrationValue) {
                    return '';
                } // If no registration was done in the past, there are no missing values
                // Enlist the registered values
                var registeredCodeSetItems = [];
                if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue) {
                    registeredCodeSetItems.push(detail.RegistrationValue.ChoiceListOption);
                }
                else if (detail.RegistrationValue.Type ===
                    RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue) {
                    _(detail.RegistrationValue.MultiChoiceListOptions).forEach(function (mclo) {
                        return registeredCodeSetItems.push(mclo);
                    });
                }
                var part = _(this.$scope.careRequestTemplate.CareRequestSetup.CareRequestParts).filter(function (crp) { return crp.LocalIdentity === detail.Definition.Id; })[0];
                if (!part) {
                    return '';
                } // if the part does not exist, we don't bother anymore
                if (!part.QualitativeAnamnesis) {
                    return '';
                } // if it's not a qualitative anamnesis we don't bother
                if (!part.QualitativeAnamnesis.CodeSet) {
                    return '';
                }
                // Compare and enlist missing values
                var currentCodeSetItemsList = this.currentCodeSetTypesCache.filter(function (cstc) { return cstc.codeSetTypeId === part.QualitativeAnamnesis.CodeSet.Id; })[0].codeSetItems;
                var missingValueList = [];
                _(registeredCodeSetItems).forEach(function (rcsi) {
                    if (!_(currentCodeSetItemsList).any(function (csi) { return csi.Id === rcsi.Id; })) {
                        missingValueList.push(rcsi.Name);
                    }
                });
                return missingValueList.join(', ');
            };
            return PatientFileCarePlanDetailController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('PatientFileCarePlanDetailCtrl', PatientFileCarePlanDetailController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
