namespace RemeCare.Patient {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import ExternalReference = RemeCare.Contract.Core.IExternalReference;
    import HealthInsuranceFund = RemeCare.Model.HealthInsuranceFundAssignment;

    interface IPersonalInformationContactsScope extends Shared.Framework.IBaseScope {
        person: RemeCare.Model.PatientPerson;
    }

    class PersonalInformationContactsController implements ng.IComponentController {
        public person: RemeCare.Model.PatientPerson;
        public externalReferencesGrid: Shared.Framework.Grid.Grid<ExternalReference>;
        public mutualitiesGrid: Shared.Framework.Grid.Grid<HealthInsuranceFund>;
        public onAddressesChange: () => void;
        public onContactPointsChange: () => void;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public $onInit(): void {}

        public $onChanges(): void {
            this.buildGrids();
        }

        public async addExternalReference(): Promise<void> {
            try {
                const externalRef = await this.editExternalReference();
                this.externalReferencesGrid.addRow(externalRef);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public async addMutuality(): Promise<void> {
            try {
                const mutuality = await this.editMutuality();
                this.mutualitiesGrid.addRow(mutuality);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrids(): void {
            this.externalReferencesGrid = this.gridBuilderSvc
                .createGridBuilder<RemeCare.Contract.Core.IExternalReference>()
                .addColumn('System', 'General.System')
                .addColumn('Attribute', 'General.Attribute')
                .addColumn('Value', 'General.Value')
                .addEditButtonWithPromiseFunctionColumn((e) => this.editExternalReference(e))
                .build();

            this.mutualitiesGrid = this.gridBuilderSvc
                .createGridBuilder<HealthInsuranceFund>()
                .addColumn('getCodeAndName()', 'General.Mutuality')
                .addColumn('healthInsuranceRiskCode', 'General.RiskCode')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addEditButtonWithPromiseFunctionColumn((h) => this.editMutuality(h))
                .build();

            if (this.person != null) {
                this.externalReferencesGrid.setData(this.person.externalReferences);
                this.mutualitiesGrid.setData(this.person.healthInsuranceFunds);
            }
        }

        private editExternalReference(externalReference?: ExternalReference): Promise<ExternalReference> {
            return new Promise<ExternalReference>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<ExternalReference>()
                    .setController('editExternalReferenceCtrl')
                    .setTemplateUrl('views/patient/patientInfo/personalInfo/editExternalReference.html')
                    .setScope({
                        externalReference: angular.copy(externalReference),
                    })
                    .setResolve({
                        patientId: () => this.person.id,
                    })
                    .setResultCallBack((r) => resolve(r))
                    .build();
            });
        }

        private editMutuality(mutuality?: HealthInsuranceFund): Promise<HealthInsuranceFund> {
            return new Promise<HealthInsuranceFund>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<HealthInsuranceFund>()
                    .setController('editMutualityCtrl')
                    .setTemplateUrl('views/patient/patientInfo/personalInfo/editMutuality.html')
                    .setScope({
                        mutuality: mutuality != null ? angular.copy(mutuality) : new HealthInsuranceFund(),
                        onlyValidFromDate: mutuality != null,
                    })
                    .setResolve({
                        patientId: () => this.person.id,
                    })
                    .setResultCallBack((r) => resolve(r))
                    .build();
            });
        }
    }

    remeCarePatientModule.component('patientInfoPersonalInformationContacts', {
        templateUrl: 'views/patient/patientInfo/personalInfo/contactPoints.html',
        controller: PersonalInformationContactsController,
        bindings: {
            person: '<',
            onContactPointsChange: '&',
            onAddressesChange: '&',
        },
    });
}
