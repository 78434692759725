namespace RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class RegistrationValueFactory {
        public static createRegistrationValueFromServer(
            serverObject: Contract.CareRequest.Read.IRegistrationValue
        ): RegistrationValue {
            return this.createRegistrationValue(serverObject.Type, serverObject);
        }

        public static createRegistrationValueFromType(
            type: Shared.Contract.Code.AnamnesisRegistrationType
        ): RegistrationValue {
            return this.createRegistrationValue(type);
        }

        private static createRegistrationValue(
            type: Shared.Contract.Code.AnamnesisRegistrationType,
            serverObject?: Contract.CareRequest.Read.IRegistrationValue
        ): RegistrationValue {
            switch (type) {
                case Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity:
                    return new RegistrationPhysicalQuantity(
                        serverObject as Contract.CareRequest.Read.IRegistrationPhysicalQuantity
                    );
                case Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue:
                    return new RegistrationCodeSetValue(
                        serverObject as Contract.CareRequest.Read.IRegistrationCodeSetValue
                    );
                case Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue:
                    return new RegistrationMultiCodeSetValue(
                        serverObject as Contract.CareRequest.Read.IRegistrationMultiCodeSetValue
                    );
                default:
                    throw new Error();
            }
        }
    }

    export abstract class RegistrationValue {
        public id: Guid;
        public type: Shared.Contract.Code.AnamnesisRegistrationType;
        public registrationDateTime: Date;

        constructor(serverObject?: Contract.CareRequest.Read.IRegistrationValue) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.type = serverObject.Type;
                this.registrationDateTime = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.RegistrationDateTime
                );
            }
        }

        public toServerWrite(): Contract.Core.Write.IRegistrationValue {
            return {
                id: this.id,
                type: this.type,
            };
        }

        public abstract getValueString(): string;
        public abstract getValueIds(): Array<Guid>;
    }

    export class RegistrationPhysicalQuantity extends RegistrationValue {
        public amount: number;

        constructor(serverObject?: Contract.CareRequest.Read.IRegistrationPhysicalQuantity) {
            super(serverObject);
            if (serverObject != null) {
                this.amount = serverObject.Amount;
            }
        }

        public getValueString(): string {
            return this.amount.toString();
        }

        public getValueIds(): Array<Guid> {
            return [];
        }

        public toServerWrite(): Contract.Core.Write.IRegistrationPhysicalQuantity {
            const result = super.toServerWrite() as Contract.Core.Write.IRegistrationPhysicalQuantity;
            result.amount = this.amount;
            return result;
        }
    }

    export class RegistrationCodeSetValue extends RegistrationValue {
        public codeSetItem: EntityTranslation;

        constructor(serverObject?: Contract.CareRequest.Read.IRegistrationCodeSetValue) {
            super(serverObject);
            if (serverObject != null) {
                this.codeSetItem =
                    serverObject.ChoiceListOption != null
                        ? {
                              Id: serverObject.ChoiceListOption.Id,
                              Text: serverObject.ChoiceListOption.Name,
                          }
                        : null;
            }
        }

        public getValueString(): string {
            return this.codeSetItem.Text;
        }

        public getValueIds(): Array<Guid> {
            if(this.codeSetItem) {
                return [this.codeSetItem.Id];
            }
            return [];
        }

        public toServerWrite(): Contract.Core.Write.IRegistrationCodeSetValue {
            const result = super.toServerWrite() as Contract.Core.Write.IRegistrationCodeSetValue;
            result.choiceListOptionId = this.codeSetItem ? this.codeSetItem.Id : null;
            return result;
        }
    }

    export class RegistrationMultiCodeSetValue extends RegistrationValue {
        public multiCodeSetItems: Guid[] = [];
        public multiCodeSetItemsTranslations: EntityTranslation[] = [];

        constructor(serverObject?: Contract.CareRequest.Read.IRegistrationMultiCodeSetValue) {
            super(serverObject);
            if (serverObject != null) {
                this.multiCodeSetItems = _(serverObject.MultiChoiceListOptions).map((x) => x.Id);
                this.multiCodeSetItemsTranslations = _(serverObject.MultiChoiceListOptions)
                    .chain()
                    .sortBy((mclo) => mclo.Sequence)
                    .map((mclo) => {
                        return {
                            Id: mclo.Id,
                            Text: mclo.Name,
                        };
                    })
                    .value();
            }
        }

        public getValueString(): string {
            return _(this.multiCodeSetItemsTranslations)
                .map((x) => x.Text)
                .toString();
        }

        public getValueIds(): Array<Guid> {
            return this.multiCodeSetItems;
        }

        public toServerWrite(): Contract.Core.Write.IRegistrationMultiCodeSetValue {
            const result = super.toServerWrite() as Contract.Core.Write.IRegistrationMultiCodeSetValue;
            result.multiChoiceListOptionIds = this.multiCodeSetItems;
            return result;
        }
    }
}
