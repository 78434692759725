var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var OrganisationExternalIdentityProviders = /** @class */ (function () {
            function OrganisationExternalIdentityProviders() {
            }
            OrganisationExternalIdentityProviders.prototype.$onInit = function () {
            };
            return OrganisationExternalIdentityProviders;
        }());
        Management.remeCareManagementModule.component('rcOrganisationExternalIdentityProviders', {
            bindings: {
                providers: '<',
                linkedChanged: '&'
            },
            templateUrl: 'views/management/organisations/externalIdentityProviders.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
