module RemeCare.SelfService {
    import ContactPointType = Shared.Contract.Code.ContactPointType;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Framework = Shared.Framework;

    class RequestUserAccountComponent implements ng.IComponentController {
        public newUserAccountForm: Shared.Framework.Directive.IFormController;
        public requestUserAccountInfo: IRequestUserAccountInformation;
        public capacity: EnumTranslation;
        public telephoneNumber: Model.TelephoneNumber;
        public languages: Array<EnumCodeTranslation>;
        public requestSuccess: boolean;
        public requestAccessTypes: Array<EnumTranslation>;

        constructor(
            private readonly selfServiceApi: SelfServiceApiService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly languageSvc: Shared.Framework.Service.LanguageService,
            private readonly $translate: ng.translate.ITranslateService
        ) {}

        public async $onInit(): Promise<void> {
            this.telephoneNumber = <Model.TelephoneNumber>(
                Model.ContactPointFactory.createFromType(ContactPointType.MobilePhone)
            );
            try {
                const requestAccessTypes = await this.masterdataSvc.getReferenceDataAsync('RequestAccessType');
                this.requestAccessTypes = requestAccessTypes;
                const language = await this.masterdataSvc.getInterfaceLanguagesAsync();
                this.languages = _.sortBy(language, lang => {
                    if (lang.Code === 'nl') {
                        return 1;
                    }
                    return -1;
                });
            } catch (exception) {
                this.toaster.error(exception);
            }
        }

        public needsRiziv(): boolean {
            switch (this.capacity && this.capacity.Id) {
                case Shared.Contract.Code.RequestAccessType.DoctorSpecialistAssociatedToAHospital:
                    return true;
                case Shared.Contract.Code.RequestAccessType.DoctorSpecialistNotAssociatedToAHospital:
                    return true;
                case Shared.Contract.Code.RequestAccessType.GeneralPractitioner:
                    return true;
                case Shared.Contract.Code.RequestAccessType.HomeCareNurse:
                    return true;
                case Shared.Contract.Code.RequestAccessType.HospitalPharmacist:
                    return true;
                case Shared.Contract.Code.RequestAccessType.Pharmacist:
                    return true;
                default:
                    return false;
            }
        }

        private shouldBeSentTo(): string {
            const sales = 'sales';
            const operations = 'operations';
            switch (this.capacity && this.capacity.Id) {
                case Shared.Contract.Code.RequestAccessType.DoctorSpecialistAssociatedToAHospital:
                    return sales;
                case Shared.Contract.Code.RequestAccessType.HospitalNurse:
                    return sales;
                case Shared.Contract.Code.RequestAccessType.HospitalPharmacist:
                    return sales;
                case Shared.Contract.Code.RequestAccessType.HospitalStaff:
                    return sales;
                default:
                    return operations;
            }
        }

        public async changeLanguage(lang: string): Promise<void> {
            this.languageSvc.setLang(lang, false);
            const requestAccessTypes = await this.masterdataSvc.getReferenceDataAsync('RequestAccessType');
            this.requestAccessTypes = requestAccessTypes;
        }

        public async requestUserAccount(): Promise<void> {
            if (this.newUserAccountForm.$invalid) {
                this.newUserAccountForm.showValidationErrorMessage();
                return;
            }
            const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRegEx.test(String(this.requestUserAccountInfo.email).toLowerCase())) {
                this.toaster.error('General.Validation.EmailAddress.Invalid');
                return;
            }
            if (this.requestUserAccountInfo.riziv != null && this.requestUserAccountInfo.riziv !== '' && !/^\d{11}$/.test(this.requestUserAccountInfo.riziv)) {
                this.toaster.error('General.Validation.Riziv.Invalid');
                return;
            }
            try {
                this.requestUserAccountInfo.phone = this.telephoneNumber.toString();
                this.requestUserAccountInfo.languageISO2Code = this.$translate.preferredLanguage();
                if (this.capacity) {
                    this.requestUserAccountInfo.capacity = this.capacity.Text;
                }
                this.requestUserAccountInfo.directedTo = this.shouldBeSentTo();
                await this.selfServiceApi.requestUserAccountAsync(this.requestUserAccountInfo);
                this.requestSuccess = true;
            } catch (exception) {
                this.toaster.error(exception);
            }
        }
    }

    remeCareSelfServiceModule.component('rcRequestUserAccount', {
        controller: RequestUserAccountComponent,
        templateUrl: 'views/selfService/requestUserAccount.html'
    });
}
