var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var AuthContext = RemeCare.Shared.Framework.AuthContext;
        var AuthRight = RemeCare.Shared.Framework.AuthRight;
        var PatientInformedConsentStatusCode = RemeCare.Shared.Contract.Code.PatientInformedConsentStatusCode;
        var PatientFileController = /** @class */ (function () {
            function PatientFileController(toaster, $state, patientSvc, informedConsentSvc, securityApiSvc, masterdataSvc, authservice, modalBuilderFactory, sidebarSvc) {
                this.toaster = toaster;
                this.$state = $state;
                this.patientSvc = patientSvc;
                this.informedConsentSvc = informedConsentSvc;
                this.securityApiSvc = securityApiSvc;
                this.masterdataSvc = masterdataSvc;
                this.authservice = authservice;
                this.modalBuilderFactory = modalBuilderFactory;
                this.sidebarSvc = sidebarSvc;
            }
            PatientFileController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var informedConsentsSearchResult, e_1, ags, success, args, _a, _b, e_2, e_3;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                this.isPatient = this.authservice.isPatient();
                                this.isInternal = this.authservice.hasRight(AuthContext.patientInternal, AuthRight.Read);
                                if (this.authservice.hasRight(AuthContext.patientMonitorRegistrations, AuthRight.Read) ||
                                    this.authservice.hasRight(AuthContext.patientMonitorAlarms, AuthRight.Read)) {
                                    this.onlyDashboard = false;
                                }
                                else {
                                    this.onlyDashboard = true;
                                }
                                this.patientSummary = {};
                                if (!(this.authservice.hasRight(AuthContext.patientInformedConsents, AuthRight.Read) && !this.isPatient)) return [3 /*break*/, 4];
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.informedConsentSvc.getInformedConsentSummary(this.patientId)];
                            case 2:
                                informedConsentsSearchResult = _c.sent();
                                this.informedConsents = informedConsentsSearchResult.Items;
                                this.acceptedInformedConsents = _.filter(this.informedConsents, function (ic) {
                                    return ic.Status === PatientInformedConsentStatusCode.Accepted;
                                });
                                this.informedConsentsToAccept = _.filter(this.informedConsents, function (ic) {
                                    return ic.Status !== PatientInformedConsentStatusCode.Accepted;
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _c.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4:
                                _c.trys.push([4, 13, , 14]);
                                return [4 /*yield*/, this.patientSvc.getProfileRightsForPatientAsync(this.patientId)];
                            case 5:
                                ags = _c.sent();
                                this.rights = ags;
                                if (!this.rights.HasMedicalInfoRights && !this.rights.HasPersonalInfoRights) {
                                    // no medical info rights and no personal info rights. Redirect to not found
                                    this.$state.go('patients.notfound');
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.patientSvc.getContactDetails(this.patientId)];
                            case 6:
                                success = _c.sent();
                                args = success.data;
                                this.patientLoaded = true;
                                return [4 /*yield*/, this.generatePatientSummary(args)];
                            case 7:
                                _c.sent();
                                this.sidebarSvc.setLeftSidebarTitle(this.patientSummary.Name);
                                _a = this;
                                return [4 /*yield*/, this.securityApiSvc.isUserBlocked(args.PartyId)];
                            case 8:
                                _a.userStatus = _c.sent();
                                if (!(this.userStatus != null)) return [3 /*break*/, 12];
                                _c.label = 9;
                            case 9:
                                _c.trys.push([9, 11, , 12]);
                                _b = this.patientSummary;
                                return [4 /*yield*/, this.securityApiSvc.getUserDetailsAsync(args.PartyId)];
                            case 10:
                                _b.UserDetails = _c.sent();
                                return [3 /*break*/, 12];
                            case 11:
                                e_2 = _c.sent();
                                return [3 /*break*/, 12];
                            case 12:
                                if (this.rights.HasMedicalInfoRights) {
                                    if (this.$state.current.name === 'patients.patientfile') {
                                        if (this.authservice.hasRight(AuthContext.patientMonitor, AuthRight.Read)) {
                                            this.$state.go('patients.patientfile.monitoring', null, { location: 'replace' });
                                        }
                                        else if (this.authservice.hasRight(AuthContext.patientMedication, AuthRight.Read)) {
                                            this.$state.go('patients.patientfile.medication', null, { location: 'replace' });
                                        }
                                        else if (this.authservice.hasRight(AuthContext.patientCarePlan, AuthRight.Read)) {
                                            this.$state.go('patients.patientfile.carePlans', null, { location: 'replace' });
                                        }
                                        else {
                                            this.$state.go('patients.patientfile.patientInfo', { patientId: this.patientId });
                                        }
                                    }
                                    // else do nothing (will transation to original state)
                                }
                                else if (this.rights.HasPersonalInfoRights) {
                                    if (this.$state.current.name === 'patients.patientfile.carePlans' &&
                                        this.authservice.hasRight(AuthContext.patientCarePlan, AuthRight.Read)) {
                                        // Careplans screens is always accessible if the profile has a right to the screen,
                                        // not only if they have the rights for one of the patients care plans
                                        return [2 /*return*/];
                                    }
                                    if (this.$state.current.name.indexOf('patients.patientfile.patientInfo') >= 0 ||
                                        this.$state.current.name.indexOf('patients.patientfile.careTeam') >= 0 ||
                                        this.$state.current.name.indexOf('patients.patientfile.communication') >= 0 ||
                                        this.$state.current.name.indexOf('patients.patientfile.mediaDocuments') >= 0) {
                                        if (this.$state.current.name === 'patients.patientfile') {
                                            this.$state.go('patients.patientfile.patientInfo', { patientId: this.patientId }, { location: 'replace' });
                                        }
                                        // else do nothing (will transation to original state)
                                    }
                                    else {
                                        this.$state.go('patients.patientfile.patientInfo', { patientId: this.patientId }, { location: 'replace' });
                                    }
                                }
                                return [3 /*break*/, 14];
                            case 13:
                                e_3 = _c.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 14];
                            case 14: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileController.prototype.$onDestroy = function () {
                this.sidebarSvc.clear();
            };
            PatientFileController.prototype.userHasInternalRemark = function () {
                return this.patientSummary.Remark != null && this.patientSummary.Remark.length > 0;
            };
            PatientFileController.prototype.showInternalRemarkModal = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcInternalRemarkModal')
                    .setBindings({
                    remark: this.patientSummary.Remark,
                    patientId: this.patientId,
                })
                    .setResultCallBack(function (result) {
                    _this.patientSummary.Remark = result;
                    _this.patientSvc
                        .saveInternalRemark(_this.patientId, result)
                        .success(function () { })
                        .error(function (e) {
                        _this.toaster.error(e.data);
                    });
                })
                    .setLarge()
                    .build();
            };
            PatientFileController.prototype.isShowUserInfoModalDisabled = function () {
                return !(this.authservice.hasRight(AuthContext.management, AuthRight.Read) &&
                    this.patientSummary.UserDetails != null);
            };
            PatientFileController.prototype.showUserInfoModal = function () {
                if (this.authservice.hasRight(AuthContext.management, AuthRight.Read) &&
                    this.patientSummary.UserDetails != null) {
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('patientAuthActivityModalCtrl')
                        .setTemplateUrl('views/patient/patientAuthActivityModal.html')
                        .setScope({
                        userDetails: this.patientSummary.UserDetails,
                    })
                        .build();
                }
            };
            PatientFileController.prototype.isShowContactModalDisabled = function () {
                if (this.patientSummary == null) {
                    return true;
                }
                var dataCount = this.patientSummary.CellPhone == null
                    ? 0
                    : new RemeCare.Model.PhoneNumber(this.patientSummary.CellPhone).toString().trim().length;
                dataCount +=
                    this.patientSummary.Phone == null
                        ? 0
                        : new RemeCare.Model.PhoneNumber(this.patientSummary.Phone).toString().trim().length;
                dataCount += this.patientSummary.Email == null ? 0 : this.patientSummary.Email.trim().length;
                return dataCount === 0;
            };
            PatientFileController.prototype.showContactModal = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('patientContactModalCtrl')
                    .setTemplateUrl('views/patient/patientContactModal.html')
                    .setScope({
                    name: this.patientSummary.Name,
                    cellPhone: new RemeCare.Model.PhoneNumber(this.patientSummary.CellPhone).toString(),
                    phoneNumber: new RemeCare.Model.PhoneNumber(this.patientSummary.Phone).toString(),
                    email: this.patientSummary.Email,
                })
                    .build();
            };
            PatientFileController.prototype.showInformedConsentModal = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcInformedConsentModal')
                    .setBindings({
                    acceptedInformedConsents: this.acceptedInformedConsents,
                    informedConsentsToAccept: this.informedConsentsToAccept,
                    informedConsents: this.informedConsents,
                    patientId: this.patientId,
                    patientName: this.patientSummary.Name,
                })
                    .setDismissCallBack(function () { return __awaiter(_this, void 0, void 0, function () {
                    var informedConsentsSearchResult, e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.informedConsentSvc.getInformedConsentSummary(this.patientId)];
                            case 1:
                                informedConsentsSearchResult = _a.sent();
                                this.informedConsents = informedConsentsSearchResult.Items;
                                this.acceptedInformedConsents = _.filter(this.informedConsents, function (ic) {
                                    return ic.Status === PatientInformedConsentStatusCode.Accepted;
                                });
                                this.informedConsentsToAccept = _.filter(this.informedConsents, function (ic) {
                                    return ic.Status !== PatientInformedConsentStatusCode.Accepted;
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })
                    .setLarge()
                    .build();
            };
            PatientFileController.prototype.generatePatientSummary = function (patientDetails) {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, gender, com, commLang, e_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, this.masterdataSvc.getGendersAsync()];
                            case 1:
                                ags = _a.sent();
                                gender = _.find(ags, function (x) { return x.Id === patientDetails.GenderId; });
                                this.patientSummary.Gender = gender ? gender.Text : '';
                                return [4 /*yield*/, this.masterdataSvc.getCommLanguagesAsync()];
                            case 2:
                                com = _a.sent();
                                commLang = _.find(com, function (x) { return x.Id === patientDetails.CommunicationLanguageId; });
                                this.patientSummary.CommLanguage = commLang ? commLang.Text : '';
                                return [3 /*break*/, 4];
                            case 3:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 4];
                            case 4:
                                this.patientSummary.Name = (patientDetails.FirstName || '-') + ' ' + (patientDetails.LastName || '-');
                                this.patientSummary.PatientNumber = patientDetails.PatientNumber;
                                if (patientDetails.City) {
                                    this.patientSummary.City = patientDetails.City;
                                }
                                if (patientDetails.DateOfBirth) {
                                    this.patientSummary.DateOfBirth = moment(patientDetails.DateOfBirth).format('DD/MM/YYYY');
                                }
                                this.patientSummary.CellPhone = patientDetails.CellPhone;
                                this.patientSummary.Phone = patientDetails.Phone;
                                this.patientSummary.Remark = patientDetails.Remark;
                                this.patientSummary.Email = patientDetails.Email;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            return PatientFileController;
        }());
        Patient.remeCarePatientModule.component('rcPatientFile', {
            controller: PatientFileController,
            templateUrl: 'views/patient/patientFile.html',
            bindings: {
                patientId: '<rcPatientId',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
