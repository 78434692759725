var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var GlobalActorRoleDetailsController = /** @class */ (function (_super) {
            __extends(GlobalActorRoleDetailsController, _super);
            function GlobalActorRoleDetailsController($scope, $translate, toaster, $state, $stateParams, $dialog, authservice, partyApiSvc, globalActorRoleSvc, gridBuilderSvc, modalBuilderFactory) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$state = $state;
                _this.$stateParams = $stateParams;
                _this.$dialog = $dialog;
                _this.authservice = authservice;
                _this.partyApiSvc = partyApiSvc;
                _this.globalActorRoleSvc = globalActorRoleSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.searchFunction = function (page, pageSize, sortField, sortOrder, criteria) {
                    var query = {
                        partyRoleIds: _this.$scope.globalActorRolePartyIds,
                        sortOrder: sortOrder,
                        pageSize: pageSize,
                        page: page,
                        sortField: sortField,
                    };
                    return _this.partyApiSvc.findPersonsAsync(query).then(function (persons) {
                        if (persons.Items != null) {
                            _this.$scope.globalActorRole.parties = persons.Items;
                        }
                        return persons;
                    });
                };
                $scope.editTranslations = function () { return _this.editTranslations(); };
                $scope.saveRemark = function () { return _this.saveRemarkAsync(); };
                $scope.handleActive = function () { return _this.handleActiveAsync(); };
                $scope.addPartyRole = function () { return _this.addPartyRoleAsync(); };
                return _this;
            }
            GlobalActorRoleDetailsController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.$scope.canEdit = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.managementActorRoles, RemeCare.Shared.Framework.AuthRight.Write);
                        this.buildGrid();
                        this.getGlobalActorRoleAsync();
                        return [2 /*return*/];
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.getGlobalActorRoleAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var globalActorRoleData, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.globalActorRoleSvc.getGlobalActorRoleAsync(this.$stateParams.actorRoleId)];
                            case 1:
                                globalActorRoleData = _a.sent();
                                this.$scope.globalActorRole = new Management.GlobalActorRole(globalActorRoleData);
                                if (_(globalActorRoleData.PartyRoleIds).any()) {
                                    this.$scope.globalActorRolePartyIds = globalActorRoleData.PartyRoleIds;
                                    this.$scope.grid.search();
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.buildGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(this.searchFunction)
                    .addColumn('LastName', 'General.LastName')
                    .addColumn('FirstName', 'General.FirstName')
                    .addColumn('PartyRoles[0].PartyRoleType.Text', 'General.Type')
                    .addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date: "shortDate"' })
                    .addActionColumn('eye-open', function (p) { return _this.navigateToPerson(p); });
                if (this.$scope.canEdit) {
                    builder = builder.addDeleteButtonColumn(null, function (p) { return _this.deletePartyRoleAsync(p); });
                }
                this.$scope.grid = builder.build();
            };
            GlobalActorRoleDetailsController.prototype.navigateToPerson = function (person) {
                this.$state.go('management.persons.detail', { partyId: person.PartyId });
            };
            GlobalActorRoleDetailsController.prototype.deletePartyRoleAsync = function (person) {
                var _this = this;
                var text = this.$translate.instant('Views.Management.GlobalActorRoles.RemoveMemberText', {
                    firstName: person.FirstName,
                    lastName: person.LastName,
                    actorRole: this.$scope.globalActorRole.name,
                });
                return new Promise(function (resolve) {
                    _this.$dialog.confirmBox('Views.Management.GlobalActorRoles.RemoveMember', text, function () { return __awaiter(_this, void 0, void 0, function () {
                        var e_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 3, , 4]);
                                    return [4 /*yield*/, this.globalActorRoleSvc.deletePartyRoleAsync(this.$scope.globalActorRole.id, person.PartyRoles[0].Id)];
                                case 1:
                                    _a.sent();
                                    this.generalSuccess();
                                    return [4 /*yield*/, this.getGlobalActorRoleAsync()];
                                case 2:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_2 = _a.sent();
                                    this.toaster.error(e_2);
                                    resolve(false);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); }, function () {
                        resolve(false);
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.saveRemarkAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.globalActorRoleSvc.saveRemarkAsync(this.$scope.globalActorRole.id, this.$scope.globalActorRole.internalRemark)];
                            case 1:
                                _a.sent();
                                this.generalSuccess();
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.errorCallback(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.handleActiveAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.globalActorRoleSvc.setActiveAsync(this.$scope.globalActorRole.id, this.$scope.globalActorRole.isActive)];
                            case 1:
                                _a.sent();
                                this.generalSuccess();
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _a.sent();
                                this.errorCallback(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.addPartyRoleAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var person, partyRole, e_5;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 4, , 5]);
                                return [4 /*yield*/, this.modalBuilderFactory.showSearchModalAsync('Views.Management.GlobalActorRoles.SearchEmployeesAndHealthCareProfessionals', 'views/management/globalActorRoles/searchPersonsCriteria.html', function (q) {
                                        if (q.partyRoleType == null) {
                                            q.partyRoleTypes = [
                                                RemeCare.Shared.Contract.Code.PartyRoleType.Employee,
                                                RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional,
                                            ];
                                        }
                                        else {
                                            q.partyRoleTypes = null;
                                        }
                                        return _this.partyApiSvc.findPersonsAsync(q);
                                    }, [
                                        {
                                            displayName: 'General.LastName',
                                            field: 'LastName',
                                        },
                                        {
                                            displayName: 'General.FirstName',
                                            field: 'FirstName',
                                        },
                                        {
                                            displayName: 'General.DateOfBirth',
                                            field: 'DateOfBirth',
                                            options: {
                                                cellFilter: 'date: "shortDate"',
                                            },
                                        },
                                        {
                                            displayName: 'General.City',
                                            field: 'City',
                                        },
                                    ], 'views/management/globalActorRoles/searchPersonsDetails.html', 120)];
                            case 1:
                                person = _a.sent();
                                partyRole = _.find(person.PartyRoles, function (pr) { return pr.Type.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee; });
                                if (partyRole == null) {
                                    partyRole = _.find(person.PartyRoles, function (pr) { return pr.Type.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; });
                                }
                                return [4 /*yield*/, this.globalActorRoleSvc.addPartyRole(this.$scope.globalActorRole.id, partyRole.Id, partyRole.Type.Id)];
                            case 2:
                                _a.sent();
                                this.generalSuccess();
                                return [4 /*yield*/, this.getGlobalActorRoleAsync()];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            GlobalActorRoleDetailsController.prototype.editTranslations = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('globalActorRoleTranslationsCtrl')
                    .setTemplateUrl('views/management/globalActorRoles/globalActorRoleTranslations.html')
                    .setScope({
                    globalActorRole: this.copy(this.$scope.globalActorRole),
                    editMode: true,
                })
                    .setResultCallBack(function (gar) { return __awaiter(_this, void 0, void 0, function () {
                    var e_6;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.globalActorRoleSvc.saveTranslationsAsync(gar.id, gar.toServerWrite().translations)];
                            case 1:
                                _a.sent();
                                this.getGlobalActorRoleAsync();
                                this.generalSuccess();
                                return [3 /*break*/, 3];
                            case 2:
                                e_6 = _a.sent();
                                this.toaster.error(e_6);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); })
                    .build();
            };
            return GlobalActorRoleDetailsController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('globalActorRoleDetailsCtrl', GlobalActorRoleDetailsController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
