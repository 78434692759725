var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientConnectionsController = /** @class */ (function () {
            function PatientConnectionsController($stateParams, $window, $dialog, $translate, storageSvc, appConfig, patientSvc, toaster) {
                this.$stateParams = $stateParams;
                this.$window = $window;
                this.$dialog = $dialog;
                this.$translate = $translate;
                this.storageSvc = storageSvc;
                this.appConfig = appConfig;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
            }
            PatientConnectionsController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.loading = true;
                        this.fitbitToggle = false;
                        this.loadConnections();
                        return [2 /*return*/];
                    });
                });
            };
            PatientConnectionsController.prototype.onFitbitToggled = function () {
                var _this = this;
                if (this.fitbitToggle === false) {
                    this.$dialog.confirmBox('Views.Management.Persons.Connections.Fitbit.RemoveDialog.Title', 'Views.Management.Persons.Connections.Fitbit.RemoveDialog.Body', function () { return _this.deleteFitbitConnection(); }, function () { return (_this.fitbitToggle = true); });
                    return;
                }
                this.$dialog
                    .messageBox(this.$translate.instant('Views.Management.Persons.Connections.Fitbit.RedirectDialog.Title'), this.$translate.instant('Views.Management.Persons.Connections.Fitbit.RedirectDialog.Body'), [
                    { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                    { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                ])
                    .then(function (result) {
                    if (result === 'yes') {
                        _this.redirectToFitbitAuth();
                    }
                    else {
                        _this.fitbitToggle = false;
                    }
                });
            };
            PatientConnectionsController.prototype.loadConnections = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var connections, fitbitConnection;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.patientSvc.getConnectionsAsync(this.$stateParams.patientId)];
                            case 1:
                                connections = _a.sent();
                                if (connections) {
                                    fitbitConnection = _(connections)
                                        .chain()
                                        .filter(function (c) { return c.Provider === RemeCare.Shared.Contract.Code.ConnectionType.Fitbit; })
                                        .first()
                                        .value();
                                    if (fitbitConnection) {
                                        this.fitbitToggle = true;
                                        this.fitbitConnectionId = fitbitConnection.Id;
                                    }
                                }
                                this.loading = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientConnectionsController.prototype.redirectToFitbitAuth = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var pkcePair, base64PatientId, fitbitAuthUrl;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, RemeCare.Shared.PkceHelper.generatePkcePair()];
                            case 1:
                                pkcePair = _a.sent();
                                this.storageSvc.store(RemeCare.Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier, pkcePair.codeVerifier, false);
                                base64PatientId = RemeCare.Shared.Base64Helper.base64urlencode(this.$stateParams.patientId);
                                fitbitAuthUrl = "https://www.fitbit.com/oauth2/authorize" +
                                    "?response_type=code" +
                                    "&code_challenge_method=S256" +
                                    ("&code_challenge=" + pkcePair.codeChallenge) +
                                    "&scope=activity+heartrate" +
                                    ("&state=" + base64PatientId) +
                                    ("&client_id=" + this.appConfig.fitbitClientId) +
                                    "&prompt=login consent";
                                this.$window.location.href = fitbitAuthUrl;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientConnectionsController.prototype.deleteFitbitConnection = function () {
                var _this = this;
                this.patientSvc
                    .deleteConnectionAsync(this.$stateParams.patientId, this.fitbitConnectionId)
                    .catch(function (r) {
                    _this.toaster.error(r);
                    _this.fitbitToggle = true;
                });
            };
            return PatientConnectionsController;
        }());
        Patient.remeCarePatientModule.component('rcPatientConnections', {
            controller: PatientConnectionsController,
            templateUrl: 'patient/components/patientInfo/personalInfo/connections/patientConnections.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
