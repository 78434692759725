module RemeCare.Patient {
    import FindPartyRoleSummariesQuery = Contract.Patient.Read.IFindPartyRoleSummariesQuery;
    import PartyRoleType = Shared.Contract.Code.PartyRoleType;

    class PatientFileGatewayController {

        constructor(
            private toaster: Shared.Framework.Toaster,
            private $state: ng.ui.IStateService,
            private $stateParams,
            private patientSvc: PatientService
        ) {
        }

        public $onInit(): void {
            var query = <FindPartyRoleSummariesQuery>{
                nationalNumber: this.$stateParams.nationalNumber,
                extPhoneNumber: this.$stateParams.extPhoneNumber,
                System: this.$stateParams.system,
                Attribute: this.$stateParams.attribute,
                Value: this.$stateParams.value,
                partyRoleType: PartyRoleType.Patient
            }
            this.patientSvc.findPartyRoles(query)
                .success(result => {
                    if (result.length === 1) {
                        this.redirectToPatientFile(result[0]);
                    } else {
                        this.redirectToSearch();
                    }
                })
                .error(err => this.toaster.error(err));
        }

        private redirectToPatientFile(patient: Contract.Patient.Read.IPersonRoleSummary): void {
            this.$state.go('patients.patientfile', { patientId: patient.Id });
        }

        private redirectToSearch(): void {
            this.$state.go('patients');
        }
    }

    angular.module("RemeCare.Patient").controller("PatientFileGatewayCtrl", (
        toaster: Shared.Framework.Toaster,
        $state: ng.ui.IStateService,
        $stateParams,
        patientSvc: PatientService
    ) => new PatientFileGatewayController(
        toaster,
        $state,
        $stateParams,
        patientSvc
    ));
}