namespace RemeCare.Model {
    export class Patient {
        public id: Shared.Contract.Guid;
        public lastName: string;
        public firstName: string;
        public dateOfBirth: Date;
        public nationalNumber: string;
        public address: string;
        public city: string;
        public phone: string;
        public cell: string;
        public email: string;
        public internalRemark: string;

        constructor(serverObject: Contract.Patient.Read.IPatient) {
            this.id = serverObject.Id;
            this.lastName = serverObject.LastName;
            this.firstName = serverObject.FirstName;
            this.dateOfBirth =
                serverObject.DateOfBirth != null ? moment(serverObject.DateOfBirth, 'YYYY-MM-DD').toDate() : null;
            this.nationalNumber = serverObject.NationalNumber;
            this.address = serverObject.Address;
            this.city = (serverObject.ZipCode || '') + ' ' + (serverObject.City || '');
            this.phone = new RemeCare.Model.PhoneNumber(serverObject.Phone).toString();
            this.cell = new RemeCare.Model.PhoneNumber(serverObject.Mobile).toString();
            this.email = serverObject.Email;
            this.internalRemark = serverObject.InternalRemark;
        }
    }
}
