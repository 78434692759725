var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var EditExternalReferenceScope = /** @class */ (function (_super) {
            __extends(EditExternalReferenceScope, _super);
            function EditExternalReferenceScope($scope, $translate, toaster, patientId, patientSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.patientId = patientId;
                _this.patientSvc = patientSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            EditExternalReferenceScope.prototype.$onInit = function () { };
            EditExternalReferenceScope.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    this.$scope.showErrors = true;
                    return;
                }
                this.patientSvc
                    .setExternalReference(this.patientId, this.$scope.externalReference)
                    .success(function () { return _this.$scope.$close(_this.$scope.externalReference); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return EditExternalReferenceScope;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('editExternalReferenceCtrl', function ($scope, $translate, toaster, patientId, patientSvc) {
            return new EditExternalReferenceScope($scope, $translate, toaster, patientId, patientSvc);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
