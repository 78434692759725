var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var remeCareCareRequestModule = RemeCare.CareRequest.remeCareCareRequestModule;
        var CareRequestRoleController = /** @class */ (function (_super) {
            __extends(CareRequestRoleController, _super);
            function CareRequestRoleController($scope, $translate, toaster) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope.confirm = function () { return _this.confirm(); };
                _this.$scope.useWithGroup = function () { return _this.useWithGroup(); };
                return _this;
            }
            CareRequestRoleController.prototype.$onInit = function () {
                this.$scope.config.possibleGroupAssignments.forEach(function (x) { return x.isActive = true; });
            };
            CareRequestRoleController.prototype.confirm = function () {
                var groupMembers = _.filter(this.$scope.config.possibleGroupAssignments, function (ga) { return ga.isActive; });
                if (this.$scope.config.role.maximalNumber != null && groupMembers.length > this.$scope.config.role.maximalNumber) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.VolationMaximumRequirement'));
                    return;
                }
                var params = {
                    groupMembers: groupMembers,
                    needsMemberOutOfGroup: false,
                };
                this.$scope.$close(params);
            };
            CareRequestRoleController.prototype.useWithGroup = function () {
                var groupMembers = _.filter(this.$scope.config.possibleGroupAssignments, function (ga) { return ga.isActive; });
                if (this.$scope.config.role.maximalNumber != null && groupMembers.length >= this.$scope.config.role.maximalNumber) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.VolationMaximumRequirement'));
                    return;
                }
                var params = {
                    groupMembers: groupMembers,
                    needsMemberOutOfGroup: true,
                };
                this.$scope.$close(params);
            };
            CareRequestRoleController.$inject = ['$scope', '$translate', 'toaster'];
            return CareRequestRoleController;
        }(RemeCare.Shared.Framework.ControllerBase));
        remeCareCareRequestModule.controller('careRequestRoleController', CareRequestRoleController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
