module RemeCare.Core.Controllers {

    interface ISearchHealthCareOrganisationPartyCriteria {
        name?: string;
        city?: string;
        zipCode?: string;
    }

    interface ISearchHealthCareOrganisationPartyScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        searchHealthCareOrganisationPartyGrid:Shared.Framework.Grid.Grid<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>;
        isBusy: boolean;
        criteriaCollapsed: boolean;
        totalItems: number;
        title: string;
        organisationTypes: Array<Shared.Contract.Guid>;

        prefill: Contract.HealthCareParty.Read.IHealthCareOrganisationParty;

        clearSearchParams: () => void;
        toggleCriteria: () => void;
        search: () => void;
    }

    class SearchHealthCareOrganisationPartyCtrl extends Shared.Framework.ControllerBase<ISearchHealthCareOrganisationPartyScope> {
        constructor(
            $scope: ISearchHealthCareOrganisationPartyScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            private gridBuilderSvc:Shared.Framework.Grid.GridBuilderFactory,
            private uiGridConstants,
            private healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc) {

            super($scope, $translate, toaster);

            this.$scope.clearSearchParams = () => this.clearSearchParams();
            this.$scope.toggleCriteria = () => this.toggleCriteria();
            this.$scope.search = () => this.search();
        }

        public $onInit(): void {
            this.$scope.searchHealthCareOrganisationPartyGrid = this.buildGrid();

            if (this.$scope.prefill) {
                this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.name = this.$scope.prefill.Name;
                this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.zipCode = this.$scope.prefill.ZipCode;
                this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria.city = this.$scope.prefill.City;
                this.search();
            }
        }

        clearSearchParams(): void {
            this.$scope.searchHealthCareOrganisationPartyGrid.searchCriteria = {}
        }

        toggleCriteria(): void {
            this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
        }

        search(): void {
            this.$scope.searchHealthCareOrganisationPartyGrid.pagingOptions.currentPage = 1;
            this.$scope.searchHealthCareOrganisationPartyGrid.search();
        }

        private executeSearch(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchHealthCareOrganisationPartyCriteria)
            : JQueryPromise<Shared.Contract.ISearchResult<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>> {
            var deferred = jQuery.Deferred<Shared.Contract.ISearchResult<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>>();
            this.$scope.isBusy = true;
            var query: Contract.HealthCareParty.Read.Query.ISearchHealthCareOrganisationPartyQuery = {
                page: page,
                pageSize: pageSize,
                name: criteria.name,
                city: criteria.city,
                zipCode: criteria.zipCode,
                organisationTypes: this.$scope.organisationTypes
            }
            this.healthCareOrganisationSearchSvc.searchOrganisations(query)
                .success((data) => {
                    var organisations = [];
                    angular.forEach(data.Items, (organisation: any) => {
                        organisation.ZipCity = (organisation.ZipCode ? organisation.ZipCode + ' ' : '') + (organisation.City ? organisation.City : '');
                        organisations.push(organisation);
                    });
                    data.Items = organisations;
                    deferred.resolve(data);
                    this.$scope.isBusy = false;
                    this.$scope.criteriaCollapsed = true;
                }).error((message) => {
                    super.errorCallback(message);
                    deferred.reject();
                });
            return deferred;
        }

        private buildGrid():Shared.Framework.Grid.Grid<Contract.HealthCareParty.Read.IHealthCareOrganisationParty> {
            var grid = this.gridBuilderSvc
                .createGridBuilder<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>((page: number, pageSize: number, sortField: string, sortDirection: string, criteria: ISearchHealthCareOrganisationPartyCriteria) =>
                    this.executeSearch(page, pageSize, sortField, sortDirection, criteria))
                .addColumn('Name', 'General.Name')
                .addColumn('ZipCity', 'Views.General.Search.ZipAndCity')
                .addSelectButtonColumn((selected) => this.resultSelected(selected))
                .build();
            return grid;
        }

        private resultSelected(result: Contract.HealthCareParty.Read.IHealthCareOrganisationParty): void {
            this.$scope.$close(result);
        }
    }

    angular.module('RemeCare.CareRequest')
        .controller('searchHealthCareOrganisationPartyCtrl', (
            $scope: ISearchHealthCareOrganisationPartyScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            uiGridConstants,
            healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc
        ) => new SearchHealthCareOrganisationPartyCtrl(
            $scope,
            $translate,
            toaster,
            gridBuilderSvc,
            uiGridConstants,
            healthCareOrganisationSearchSvc));
}