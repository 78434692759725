/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import CareRequestUiControlType = Shared.Contract.Code.CareRequestUIControlType;
    import TypeAndSpecialty = Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;
    import PartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;

    interface ICareRequestorApplicationScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.CareRequestorDefinition;
        professionals: PartyRoleSummary[];
        professional: Model.HealthCareProfessional;
        organisations: PartyRoleSummary[];
        organisationName: string;
        typeAndSpecialties: TypeAndSpecialty[];
        specialties: Shared.Contract.IEnumTranslation[];
        autoAssignedRole: boolean;
        atLeastOneNumberCareRequestor(): boolean;
        atLeastOneNumberCareRequestorContact(): boolean;
        searchProfessional(): void;
        searchOrganisation(): void;
        clearOrganisation(): void;
        clearProfessional(): void;
        isMaxReached(role: Shared.Framework.Model.ActorRole): boolean;
        requestorProfessionalRequired(): boolean;
    }

    class CareRequestorApplicationController extends CareRequestPartControllerBase<ICareRequestorApplicationScope> {
        // @ngInject
        constructor(
            protected $scope: ICareRequestorApplicationScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc,
            private readonly healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.atLeastOneNumberCareRequestor = () => this.atLeastOneNumberCareRequestor();
            $scope.atLeastOneNumberCareRequestorContact = () => this.atLeastOneNumberCareRequestorContact();
            $scope.searchProfessional = () => this.searchProfessional();
            $scope.searchOrganisation = () => this.searchOrganisation();
            $scope.clearOrganisation = () => this.clearOrganisation();
            $scope.clearProfessional = () => this.clearProfessional();
            $scope.isMaxReached = (a) => this.isMaxReached(a);
            $scope.autoAssignedRole = false;
            $scope.requestorProfessionalRequired = () => this.requestorProfessionalRequired();
            this.$onInit();
            this.$rootScope.$on('CAREREQUEST_ROLE_SELECT', (event, role: IApplyForRole) => {
                if (role.rolePart !== RolePart.CareRequestorRole) {
                    return;
                }
                $scope.autoAssignedRole = true;
                this.$scope.careRequest.careRequestor.healthCareProfessionalId = role.party.PartyRoleId;
                this.loadProfessional();
                if (role.organization) {
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId = role.organization.PartyRoleId;
                    this.$scope.organisationName = role.organization.Name;
                }
            });
            this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECT', (event, role: IApplyForRole) => {
                if (role.rolePart !== RolePart.CareRequestorRole) {
                    return;
                }
                $scope.autoAssignedRole = false;
                this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
                this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
                this.$scope.organisationName = '';
            });
        }

        public $onInit(): void {
            this.loadProfessionals();
            this.loadDataAsync();
            if (this.$scope.careRequest.careRequestor.healthCareProfessionalId != null) {
                this.loadProfessional();
            }
            if (this.$scope.careRequest.careRequestor.healthCareOrganisationId != null) {
                this.loadOrganisation();
            }
            if (
                this.$scope.isPresent(CareRequestUiControlType.CareRequestorHCOrganisation) &&
                this.$scope.careRequestPart.roleRequestingHealthCareOrganisation
            ) {
                this.loadOrganisations();
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.$scope.specialties = await this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadOrganisations(): Promise<void> {
            try {
                this.$scope.organisations = await this.therapyApiSvc.getPossiblePartiesAsync(
                    this.$scope.careRequest.therapyId,
                    this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleId
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadOrganisation(): Promise<void> {
            try {
                const organisation = (await this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                    page: 1,
                    pageSize: 1,
                    partyRoleId: this.$scope.careRequest.careRequestor.healthCareOrganisationId,
                })).Items[0];
                this.$scope.organisationName = organisation != null ? organisation.Name : '';
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadProfessional(): Promise<void> {
            try {
                this.$scope.professional = new Model.HealthCareProfessional(
                    (await this.healthCareProfessionalSearchSvc.searchProfessionalsAsync({
                        page: 1,
                        pageSize: 1,
                        partyRoleId: this.$scope.careRequest.careRequestor.healthCareProfessionalId,
                    })).Items[0]
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadProfessionals(): Promise<void> {
            try {
                this.$scope.professionals = await this.therapyApiSvc.getPossiblePartiesAsync(
                    this.$scope.careRequest.therapyId,
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private requestorProfessionalRequired(): boolean {
            return (
                this.isRequired(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional) ||
                (this.$scope.careRequestPart.roleRequestingHealthCareProfessional &&
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.minimalNumber > 0)
            );
        }

        private atLeastOneNumberCareRequestor(): boolean {
            if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorTelephoneMobile)) {
                return true;
            }
            return (
                this.$scope.careRequest.careRequestor.responsible.contactInfo.telephone.isFilled() ||
                this.$scope.careRequest.careRequestor.responsible.contactInfo.mobile.isFilled()
            );
        }

        private atLeastOneNumberCareRequestorContact(): boolean {
            if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorContactTelephoneMobile)) {
                return true;
            }
            return (
                this.$scope.careRequest.careRequestor.contactPerson.contactInfo.telephone.isFilled() ||
                this.$scope.careRequest.careRequestor.contactPerson.contactInfo.mobile.isFilled()
            );
        }

        private searchProfessional(): void {
            const typeAndSpecialties = this.convertToTypeAndSpecialties(
                _(this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleInclusions)
                    .chain()
                    .map((ari) => ari.actorRoleInclusionDetails)
                    .flatten()
            );
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>()
                .setController('searchHealthCareProfessionalPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                .setScope({
                    title: this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name,
                    typeAndSpecialties: typeAndSpecialties,
                })
                .setResultCallBack((r) => {
                    this.$scope.professional = new Model.HealthCareProfessional(r);
                    this.$scope.careRequest.careRequestor.healthCareProfessionalId = r.PartyRoleId;
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private searchOrganisation(): void {
            const orgTypes = _(this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleInclusions)
                .chain()
                .map((ari) => ari.actorRoleInclusionDetails)
                .flatten()
                .map((arid) => arid.healthCareOrganisationTypeId)
                .value();

            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>()
                .setController('searchHealthCareOrganisationPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                .setScope({
                    title: this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name,
                    organisationTypes: orgTypes,
                })
                .setResultCallBack((r) => {
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId = r.PartyRoleId;
                    this.$scope.organisationName = r.Name;
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private clearProfessional(): void {
            this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
            this.$scope.professional = null;
            this.$scope.autoAssignedRole = false;
            this.$rootScope.$emit(
                'CAREREQUEST_ROLE_UNSELECTED',
                CareRequestRole.create(
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId,
                    Shared.Contract.Code.PartyType.Person
                ),
                RolePart.CareRequestorRole
            );
        }
        private clearOrganisation(): void {
            this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
            this.$scope.organisationName = null;
        }

        private isMaxReached(role: Shared.Framework.Model.ActorRole): boolean {
            if (!role.maximalNumber) {
                return false;
            }

            const requestRoles = _(this.$scope.careRequest.careRequestRoles).filter(
                (crr) => crr.roleId === role.actorRoleId
            );
            return requestRoles.length >= role.maximalNumber;
        }
    }

    class CareRequestorDirective extends CareRequestPartDirectiveBase {
        public controller = CareRequestorApplicationController;

        public templateUrl = 'views/careRequest/directive/careRequestor/careRequestorApplication.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestCareRequestorApplication', () => new CareRequestorDirective());
}
