var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        var InternalLoginController = /** @class */ (function () {
            function InternalLoginController() {
            }
            InternalLoginController.prototype.$onInit = function () {
                this.dateRegex = RemeCare.Framework.Regexes.dateRegex;
            };
            InternalLoginController.prototype.confirm = function () {
                if (this.interalLoginForm.$invalid) {
                    this.interalLoginForm.showValidationErrorMessage();
                    return;
                }
                this.onConfirm({
                    birthDate: moment(this.birthDate, 'D-M-YYYY').toDate(),
                    password: this.password,
                });
            };
            return InternalLoginController;
        }());
        SelfService.remeCareSelfServiceModule.component('rcInternalLogin', {
            bindings: {
                confirmLabel: '<',
                loader: '<',
                onConfirm: '&',
                userName: '<',
            },
            controller: InternalLoginController,
            templateUrl: 'views/selfService/internalLogin.html',
        });
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
