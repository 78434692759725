/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var Consultation = RemeCare.Model.Consultation;
            var ConsultationsController = /** @class */ (function (_super) {
                __extends(ConsultationsController, _super);
                function ConsultationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.addConsultation = function () { return _this.addConsultationAsync(); };
                    _this.$onInit();
                    return _this;
                }
                ConsultationsController.prototype.$onInit = function () {
                    this.buildGrid();
                };
                ConsultationsController.prototype.buildGrid = function () {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('consultationDate', 'Views.Patient.Details.ConsultationDate', {
                        cellFilter: 'date: "shortDate"',
                    })
                        .addColumn('careProviderType.Text', 'Views.Patient.Details.CareProviderType')
                        .addColumn('reason', 'Views.Patient.Details.Reason');
                    if (!this.$scope.readOnly) {
                        gridBuilder = gridBuilder
                            .addEditButtonWithPromiseFunctionColumn(function (c) { return _this.editConsultationAsync(c); })
                            .addDeleteButtonColumn();
                    }
                    this.$scope.grid = gridBuilder.build();
                    this.$scope.grid.setData(this.$scope.careRequest.consultations);
                };
                ConsultationsController.prototype.addConsultationAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var consultation, c, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    consultation = new Consultation();
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.editConsultationAsync(consultation)];
                                case 2:
                                    c = _a.sent();
                                    this.$scope.careRequest.consultations.push(c);
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_1 = _a.sent();
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                ConsultationsController.prototype.editConsultationAsync = function (consultation) {
                    return this.modalBuilderFactory
                        .createComponentModalBuilder('rcConsultationModal')
                        .setBindings({
                        consultation: angular.copy(consultation),
                        careRequestPart: this.$scope.careRequestPart,
                    })
                        .build().result;
                };
                return ConsultationsController;
            }(Directive.CareRequestPartControllerBase));
            var ConsultationsDirective = /** @class */ (function (_super) {
                __extends(ConsultationsDirective, _super);
                function ConsultationsDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        'gridBuilderSvc',
                        'modalBuilderFactory',
                        'careRequestSvc',
                        function ($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) {
                            return new ConsultationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc);
                        },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/consultations.html';
                    return _this;
                }
                return ConsultationsDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestConsultations', function () { return new ConsultationsDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
