var RemeCare;
(function (RemeCare) {
    var OneTimeLogin;
    (function (OneTimeLogin) {
        var OneTimeLoginFailureReason;
        (function (OneTimeLoginFailureReason) {
            OneTimeLoginFailureReason[OneTimeLoginFailureReason["None"] = 0] = "None";
            OneTimeLoginFailureReason[OneTimeLoginFailureReason["UnkownKey"] = 1] = "UnkownKey";
            OneTimeLoginFailureReason[OneTimeLoginFailureReason["Expired"] = 2] = "Expired";
            OneTimeLoginFailureReason[OneTimeLoginFailureReason["AlreadyUsed"] = 3] = "AlreadyUsed";
            OneTimeLoginFailureReason[OneTimeLoginFailureReason["UnkownPartyId"] = 4] = "UnkownPartyId";
        })(OneTimeLoginFailureReason = OneTimeLogin.OneTimeLoginFailureReason || (OneTimeLogin.OneTimeLoginFailureReason = {}));
    })(OneTimeLogin = RemeCare.OneTimeLogin || (RemeCare.OneTimeLogin = {}));
})(RemeCare || (RemeCare = {}));
