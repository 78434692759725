/// <reference path="../../../../model/registrationValue.ts"/>

namespace RemeCare.Patient {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import RegistrationPhysicalQuantityValue = RemeCare.Model.RegistrationPhysicalQuantity;
    import RegistrationCodeSetValue = RemeCare.Model.RegistrationCodeSetValue;
    import RegistrationMultiCodeSetValue = RemeCare.Model.RegistrationMultiCodeSetValue;
    import RegistrationType = Shared.Contract.Code.AnamnesisRegistrationType;

    interface IAddCarePlanAnamnesisModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;

        carePlanId: Shared.Contract.Guid;
        definition: EntityTranslation;
        carePlanAnamnesis: Patient.Model.CarePlanAnamnesis;

        codeSetItems: Shared.Contract.ICodeSetItem[];
        uiControlType: Shared.Contract.IEnumTranslation;

        minDate: Date;
        dateEditable: boolean;

        unit: Shared.Contract.Read.IUnitLimitTranslation;
        decimals: number;

        confirm(): void;
    }

    class AddCarePlanAnamnesisModalController extends Shared.Framework.ControllerBase<
        IAddCarePlanAnamnesisModalController
    > {
        constructor(
            protected $scope: IAddCarePlanAnamnesisModalController,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirmAsync();
        }

        public $onInit(): void {
            if (!this.$scope.carePlanAnamnesis.registrationValue) {
                if (this.$scope.codeSetItems) {
                    if (
                        this.$scope.uiControlType.Id === Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                        this.$scope.uiControlType.Id === Shared.Contract.Code.UIControlType.DropdownListMultiple
                    ) {
                        this.$scope.carePlanAnamnesis.registrationValue = new RegistrationMultiCodeSetValue();
                        this.$scope.carePlanAnamnesis.registrationValue.type =
                            RegistrationType.RegistrationMultiCodeSetValue;
                    } else {
                        this.$scope.carePlanAnamnesis.registrationValue = new RegistrationCodeSetValue();
                        this.$scope.carePlanAnamnesis.registrationValue.type =
                            RegistrationType.RegistrationCodeSetValue;
                    }
                } else {
                    this.$scope.carePlanAnamnesis.registrationValue = new RegistrationPhysicalQuantityValue();
                    this.$scope.carePlanAnamnesis.registrationValue.type =
                        RegistrationType.RegistrationPhysicalQuantity;
                }
            }
            this.$scope.dateEditable = !moment().isAfter(this.$scope.carePlanAnamnesis.validFromDate);
            if (moment(this.$scope.minDate).isBefore(Shared.DateHelper.today())) {
                this.$scope.minDate = moment(Shared.DateHelper.today())
                    .add('day', 1)
                    .toDate();
            }
            if (!this.$scope.dateEditable) {
                this.$scope.minDate = null;
            }
        }

        private async confirmAsync(): Promise<void> {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.carePlanAnamnesis.validUntilDate = null;
            this.$scope.carePlanAnamnesis.carePlanId = this.$scope.carePlanId;
            this.$scope.carePlanAnamnesis.definition = this.$scope.definition;

            try {
                await this.carePlanApiSvc.saveCarePlanAnamnesisAsync(
                    this.$scope.carePlanId,
                    this.$scope.definition.Id,
                    this.$scope.carePlanAnamnesis.toServerWrite()
                );
                this.$scope.$close(this.$scope.carePlanAnamnesis);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.controller('addCarePlanAnamnesisModalCtrl', AddCarePlanAnamnesisModalController);
}
