/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var StringController = /** @class */ (function (_super) {
                __extends(StringController, _super);
                function StringController($scope, $translate, toaster, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                StringController.prototype.$onInit = function () {
                    var _this = this;
                    this.$scope.careRequestString = _.find(this.$scope.careRequest.careRequestStrings, function (t) { return t.careRequestPartLocalIdentity === _this.$scope.careRequestPart.localIdentity; });
                    if (this.$scope.careRequestString == null) {
                        this.$scope.careRequestString = new CareRequest.CareRequestString();
                        this.$scope.careRequestString.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                        this.$scope.careRequest.careRequestStrings.push(this.$scope.careRequestString);
                    }
                };
                StringController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                return StringController;
            }(Directive.CareRequestPartControllerBase));
            var StringDirective = /** @class */ (function (_super) {
                __extends(StringDirective, _super);
                function StringDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = ['$scope', '$translate', 'toaster', 'careRequestSvc',
                        function ($scope, $translate, toaster, careRequestSvc) { return new StringController($scope, $translate, toaster, careRequestSvc); }];
                    _this.templateUrl = 'views/careRequest/directive/string.html';
                    return _this;
                }
                return StringDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestString', function () { return new StringDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
