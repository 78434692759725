var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var ContactPersonTypeCode;
                (function (ContactPersonTypeCode) {
                    ContactPersonTypeCode[ContactPersonTypeCode["Partner"] = 1] = "Partner";
                    ContactPersonTypeCode[ContactPersonTypeCode["Parent"] = 2] = "Parent";
                    ContactPersonTypeCode[ContactPersonTypeCode["Child"] = 3] = "Child";
                    ContactPersonTypeCode[ContactPersonTypeCode["Neighbour"] = 4] = "Neighbour";
                    ContactPersonTypeCode[ContactPersonTypeCode["Friend"] = 5] = "Friend";
                    ContactPersonTypeCode[ContactPersonTypeCode["Other"] = 6] = "Other";
                })(ContactPersonTypeCode = Codes.ContactPersonTypeCode || (Codes.ContactPersonTypeCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
