namespace RemeCare.Patient {
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;

    export interface PatientFileStateParams {
        patientId: RemeCare.Shared.Contract.Guid;
    }

    export interface EditContactPersonStateParams extends PatientFileStateParams {
        contactPerson: RemeCare.Model.ContactPersonPerson;
        contactPersonId: RemeCare.Shared.Contract.Guid;
    }

    export interface AddContactPersonStateParams extends PatientFileStateParams {}

    export interface ContactPersonOverviewStateParams extends PatientFileStateParams {}

    export interface CreateContactPersonStateParams extends PatientFileStateParams {}

    export interface PatientJourneyStateParams extends PatientFileStateParams {}

    class RemeCarePatientConfiguration extends Shared.Framework.RouteConfiguration {
        private static readonly patientsPatientFileMonitoringDashboardRoute =
            'patients.patientfile.monitoring.registration.dashboard';

        constructor(
            $stateProvider: ng.ui.IStateProvider,
            private readonly loadMeasuringSvcProvider: Shared.Framework.Service.LoadMeasuringServiceProvider
        ) {
            super($stateProvider);
            this.setupMeasurements();
        }

        protected config(): void {
            this.$stateProvider.state('patientfilegateway', {
                url: '/PatientFileForExternal?system&attribute&value&nationalNumber&extPhoneNumber',
                controller: 'PatientFileGatewayCtrl',
            });

            this.addGeneric<AddContactPersonStateParams>(
                'patients.patientfile.patientInfo.contactPersons.addContactPerson',
                {
                    component: 'addContactPerson',
                    url: '/Search',
                    params: {
                        patientId: null,
                    },
                },
                ['Views_PatientFile'],
                {
                    context: AuthContext.patient,
                    right: AuthRight.Write,
                }
            );

            this.addGeneric<EditContactPersonStateParams>(
                'patients.patientfile.patientInfo.contactPersons.editContactPerson',
                {
                    component: 'rcEditContactPerson',
                    url: '/:contactPersonId/Edit',
                    params: {
                        contactPerson: null,
                        patientId: null,
                        contactPersonId: null,
                    },
                },
                ['Views_PatientFile', 'Views_CareRequest', 'Views_Management'],
                {
                    context: AuthContext.patient,
                    right: AuthRight.Write,
                }
            );

            this.addGeneric<CreateContactPersonStateParams>(
                'patients.patientfile.patientInfo.contactPersons.createContactPerson',
                {
                    component: 'createContactPerson',
                    url: '/New',
                    params: {
                        patientId: null,
                    },
                },
                ['Views_PatientFile', 'Views_CareRequest', 'Views_Management'],
                {
                    context: AuthContext.patient,
                    right: AuthRight.Write,
                }
            );

            this.addRoute(
                'patients',
                '/Patients', // Be careful changing this route as this is being referenced in the integration API
                'views/shared/general.html',
                null,
                ['Views_Patient_Search', 'Views_Patient_General'],
                null,
                null,
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.search' }
            );

            this.add(
                'patients.search',
                {
                    component: 'rcSearchPatient',
                    url:
                        '/Search?firstName&lastName&birthDate&therapyId&carePlanStatus&carePlanStartDateFrom&carePlanStartDateUntil&identificationNumber&patientNumber&address&country&communicationLanguage&language&gender&phoneNumber&email&careRequestorPerson&careRequestorOrganisation',
                    // Be careful changing this route as this is being referenced in the integration API
                },
                ['Patient', 'Views_Patient_Search', 'Views_Patient_General'],
                {
                    context: AuthContext.searchPatient,
                    right: AuthRight.Read,
                }
            );

            this.addComponentRoute(
                'patients.patientfile',
                '/:patientId', // Be careful changing this route as this is being referenced in the integration API
                params => `<rc-patient-file rc-patient-id="'${params.patientId}'"></rc-patient-file>`,
                ['Views_PatientFile', 'Views_InformedConsent'],
                null,
                null,
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                },
                {
                    params: {
                        patientId: null,
                    },
                }
            );

            this.addRoute(
                'patients.patientfile.monitoring',
                '/Monitoring', // Be careful changing this route as this is being referenced in the integration API
                'views/patient/monitoring/general.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.patientfile.monitoring.registration.dashboard' }
            );

            this.addRoute(
                'patients.patientfile.monitoring.dashboard',
                '/Dashboard', // Be careful changing this route as this is being referenced in the integration API
                'views/patient/monitoring/general.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.patientfile.monitoring.registration.dashboard' }
            );

            this.addRoute(
                'patients.patientfile.monitoring.registration',
                '/Overview',
                'views/patient/monitoring/registrations/registrations.html',
                'patientFileRegistrationsMonitorCtrl',
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addComponentRoute(
                RemeCarePatientConfiguration.patientsPatientFileMonitoringDashboardRoute,
                '/Dashboard',
                params => `<patient-file-monitoring patient-id="'${params.patientId}'"></patient-file-monitoring>`,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.monitoring.registrations',
                '/Registrations',
                'views/baseView.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.patientfile.monitoring.registrations.overview.actions' }
            );

            this.addRoute(
                'patients.patientfile.monitoring.registrations.overview',
                '/Overview',
                'views/patient/monitoring/registrations/registrations.html',
                'patientFileRegistrationsMonitorCtrl',
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.add(
                'patients.patientfile.monitoring.registrations.overview.actions',
                {
                    component: 'rcPatientFileRegistrationActions',
                    url: '/Actions',
                },
                ['Views_PatientFile', 'Views_Action', 'Views_Patient'],
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addComponentRoute(
                'patients.patientfile.monitoring.registrations.overview.parameters',
                '/Parameters',
                params =>
                    `<patient-file-registration-parameters patient-id="${
                        params.patientId
                    }"></patient-file-registration-parameters>`,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addGeneric<PatientJourneyStateParams>(
                'patients.patientfile.monitoring.registrations.overview.patientjourney',
                {
                    component: 'rcPatientFilePatientJourney',
                    url: '/PatientJourney',
                    params: {
                        patientId: null,
                    },
                },
                ['Views_PatientFile', 'Views_Action'],
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.add(
                'patients.patientfile.monitoring.registrations.adhoc',
                {
                    component: 'rcAdhocRegistrations',
                    url: '/Adhoc',
                },
                ['Views_PatientFile', 'Views_Action'],
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addComponentRoute(
                'patients.patientfile.monitoring.alarms',
                '/Alarms',
                params =>
                    `<patient-file-registration-alarms patient-id="${
                        params.patientId
                    }"></patient-file-registration-alarms>`,
                ['Views_PatientFile', 'Views_Alarms'],
                null,
                null,
                {
                    context: AuthContext.patientMonitor,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.medication',
                '/Medication',
                'views/patient/medication/medicationOverview.html',
                'medicationOverviewCtrl',
                ['Views_PatientFile', 'Views_Patient_Medication', 'Periodicity'],
                null,
                null,
                {
                    context: AuthContext.patientMedication,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.carePlans',
                '/CarePlans',
                'views/patient/carePlans/carePlans.html',
                'PatientFileCarePlanCtrl',
                ['Views_PatientFile', 'Views_CareRequest_Search_SelectTherapy'],
                null,
                null,
                {
                    context: AuthContext.patientCarePlan,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.carePlans.detail',
                '/Detail',
                'views/patient/carePlans/carePlanDetail.html',
                'PatientFileCarePlanDetailCtrl',
                ['Views_PatientFile', 'Views_CareRequest'],
                null,
                null,
                {
                    context: AuthContext.patientCarePlan,
                    right: AuthRight.Read,
                }
            );

            this.$stateProvider.state('patients.patientfile.carePlans.detail.anamnesis', {
                url: '/:carePlanId/Anamneses/:definitionId',
                views: {
                    '@patients.patientfile.carePlans': {
                        template: (params: any) =>
                            `<care-plan-anamnesis patient-id="${params.patientId}" care-plan-id="${
                                params.carePlanId
                            }" definition-id="${params.definitionId}" />`,
                    },
                },
                params: {
                    definitionId: null,
                },
            });

            this.$stateProvider.state('patients.patientfile.carePlans.detail.date', {
                url: '/:carePlanId/Dates/:definitionId',
                views: {
                    '@patients.patientfile.carePlans': {
                        template: (params: any) =>
                            `<care-plan-date patient-id="${params.patientId}" care-plan-id="${
                                params.carePlanId
                            }" definition-id="${params.definitionId}" />`,
                    },
                },
                params: {
                    definitionId: null,
                },
            });

            this.$stateProvider.state('patients.patientfile.carePlans.detail.string', {
                url: '/:carePlanId/Strings/:definitionId',
                views: {
                    '@patients.patientfile.carePlans': {
                        template: (params: any) =>
                            `<care-plan-string patient-id="${params.patientId}" care-plan-id="${
                                params.carePlanId
                            }" definition-id="${params.definitionId}" />`,
                    },
                },
                params: {
                    definitionId: null,
                },
            });

            this.$stateProvider.state('patients.patientfile.carePlans.detail.therapyoption', {
                url: '/:carePlanId/TherapyOptions/:definitionId',
                views: {
                    '@patients.patientfile.carePlans': {
                        template: (params: any) =>
                            `<care-plan-therapy-option patient-id="${params.patientId}" care-plan-id="${
                                params.carePlanId
                            }" definition-id="${params.definitionId}" />`,
                    },
                },
                params: {
                    definitionId: null,
                },
            });

            this.addRoute(
                'patients.patientfile.carePlans.actions',
                '/Actions',
                'views/patient/carePlans/carePlanActions/carePlanActions.html',
                'PatientFileCarePlanActionsCtrl',
                ['Views_PatientFile', 'Periodicity'],
                null,
                null,
                {
                    context: AuthContext.patientCarePlan,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.carePlans.rulethresholds',
                '/ObjectivesAndRuleThresholds',
                'views/patient/carePlans/carePlanObjectivesAndThresholds.html',
                'PatientFileObjectivesAndThresholdsCtrl',
                ['Views_PatientFile', 'Configuration_Therapies_Monitoring_Characteristic'],
                null,
                null,
                {
                    context: AuthContext.patientCarePlan,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.carePlans.media',
                '/Media',
                'views/patient/carePlans/carePlanMedia.html',
                'CarePlanMediaHistoryCtrl',
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientCarePlan,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.patientInfo',
                '/PatientInfo',
                'views/patient/patientInfo/general.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.patientfile.patientInfo.personalInfo' }
            );

            this.addRoute(
                'patients.patientfile.patientInfo.personalInfo',
                '/PersonalInfo',
                'views/patient/patientInfo/personalInfo/personalInfo.html',
                'patientInfoPersonalInfoCtrl',
                ['Views_PatientFile', 'Views_Management_Persons'],
                null,
                null,
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.patientInfo.contactPersons',
                '/ContactPersons',
                'views/patient/patientInfo/contactPersons/contactPersons.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                },
                { redirectTo: 'patients.patientfile.patientInfo.contactPersons.overview' }
            );

            this.addGeneric<ContactPersonOverviewStateParams>(
                'patients.patientfile.patientInfo.contactPersons.overview',
                {
                    component: 'rcContactPersonsOverview',
                    url: '/Overview',
                    params: {
                        patientId: null,
                    },
                },
                ['Views_PatientFile'],
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.patientInfo.medicalHistory',
                '/MedicalHistory',
                'views/patient/patientInfo/medicalHistory/medicalHistoryOverview.html',
                'patientFileMedicalHistoryOverviewCtrl',
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMedical,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.patientInfo.hospitalisationConsultation',
                '/HospitalisationsAndConsultations',
                'views/patient/patientInfo/hospiCons/hospiConsOverview.html',
                'patientFileHospiConsOverviewCtrl',
                ['Views_PatientFile'],
                null,
                null,
                {
                    context: AuthContext.patientMedical,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.patientInfo.materials',
                '/Materials',
                'views/patient/patientInfo/patientInfo/patientInfo.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                null
            );

            this.addComponentRoute(
                'patients.patientfile.careTeam',
                '/CareTeam',
                'rcCareTeam',
                ['Views_PatientFile', 'Views_Patient_CareTeam'],
                null,
                null,
                {
                    context: AuthContext.patientCareTeam,
                    right: AuthRight.Read,
                }
            );

            this.addRoute(
                'patients.patientfile.communication',
                '/Communication',
                'views/patient/communication/communication.html',
                null,
                ['Views_PatientFile'],
                null,
                null,
                null
            );

            this.addRoute(
                'patients.patientfile.mediaDocuments',
                null,
                null,
                null,
                ['Views_PatientFile'],
                null,
                null,
                null,
                {
                    redirectTo: 'patients.patientfile.mediaDocuments.overview',
                }
            );

            this.add(
                'patients.patientfile.mediaDocuments.overview',
                {
                    url: '/MediaAndDocuments?carePlans&sources&structuralTherapyActions&fromDate&untilDate',
                    views: {
                        '@patients.patientfile': {
                            template: '<media-documents/>',
                        },
                    },
                },
                ['Views_PatientFile'],
                {
                    context: AuthContext.patientDocuments,
                    right: AuthRight.Read,
                }
            );

            this.add(
                'patients.patientfile.mediaDocuments.documents',
                {
                    url: '/Documents/:documentId',
                    views: {
                        '@patients.patientfile': {
                            template: (params: any) =>
                                `<patient-file-document patient-id="${params.patientId}" document-id="${
                                    params.documentId
                                }" />`,
                        },
                    },
                    data: {
                        permissions: {
                            context: AuthContext.patientDocuments,
                            right: AuthRight.Write ^ AuthRight.Create,
                        },
                    },
                    params: {
                        documentId: '',
                    },
                },
                ['Views_PatientFile'],
                {
                    context: AuthContext.patientDocuments,
                    right: AuthRight.Read,
                }
            );

            this.add(
                'patients.notfound',
                {
                    component: 'rcPatientNotFound',
                    url: '/PatientNotFound',
                },
                ['Views_PatientFile'],
                {
                    context: AuthContext.patient,
                    right: AuthRight.Read,
                }
            );
        }

        private setupMeasurements(): void {
            this.loadMeasuringSvcProvider.registerStateToMeasure(
                RemeCarePatientConfiguration.patientsPatientFileMonitoringDashboardRoute
            );
        }
    }

    remeCarePatientModule.config(
        (
            $stateProvider: ng.ui.IStateProvider,
            loadMeasuringSvcProvider: Shared.Framework.Service.LoadMeasuringServiceProvider
        ) => new RemeCarePatientConfiguration($stateProvider, loadMeasuringSvcProvider)
    );
}
