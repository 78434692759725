namespace RemeCare.Model {
    export class ActionBase {
        public id: Shared.Contract.Guid;
        public actionTemplateId: Shared.Contract.Guid;
        public name: string;
        public components: CarePlanAction.ComponentDescriptor[];
        public customScreen: string;
        public eventDateFrom: Date;
        public eventTimeFrom: Date;
        public eventDateUntil: Date;
        public eventTimeUntil: Date;
        public textRegistration: string;
        public textFooter: string;
        public patientId: Shared.Contract.Guid;
        public includesEventDate: boolean;
        public includesEventTime: boolean;
        public isEventDateTimeRange: boolean;
        public eventDateTimeLabel: string;
        public location: Contract.CarePlanAction.Read.ILocation;
        public authservice: Shared.Framework.AuthService;

        constructor(authservice: Shared.Framework.AuthService, actionTemplateId?: Shared.Contract.Guid, serverObject?: Contract.Action.IAction) {
            if (serverObject) {
                const cFactory = new CarePlanAction.ComponentDescriptorFactory();
                this.components = _(serverObject.ActionParts).map(c => cFactory.createComponentDescriptor(authservice, c));
                this.customScreen = serverObject.CustomScreen;
                this.id = serverObject.Id;
                this.actionTemplateId = actionTemplateId;
                this.name = serverObject.Name;
                this.textRegistration = serverObject.TextRegistration;
                this.textFooter = serverObject.TextFooter;
                this.includesEventDate = serverObject.IncludesEventDate;
                this.includesEventTime = serverObject.IncludesEventTime;
                this.isEventDateTimeRange = serverObject.IsEventDateTimeRange;
                this.eventDateTimeLabel = serverObject.EventDateTimeLabel;
            }
        }

        public getEventDateTimeFrom(): Date {
            const result = moment(this.eventDateFrom)
                .startOf('day')
                .toDate();
            if (this.includesEventTime) {
                Shared.DateHelper.setTime(result, this.eventTimeFrom);
            }
            return result;
        }

        public getEventDateTimeUntil(): Date {
            const result = moment(this.eventDateUntil)
                .startOf('day')
                .toDate();
            if (this.includesEventTime) {
                Shared.DateHelper.setTime(result, this.eventTimeUntil);
            }
            return result;
        }

        public hasCareAct(): boolean {
            return _(this.components).any(c => c.actionTypeCode === Shared.Contract.Code.ActionType.CareAct);
        }
    }
}
