var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Custom;
    (function (Custom) {
        var UptitrationEvaluationController = /** @class */ (function (_super) {
            __extends(UptitrationEvaluationController, _super);
            function UptitrationEvaluationController($scope, $translate, toaster, $rootScope, $state, $dialog, $window, carePlanActionApiSvc, modalBuilderFactory, carePlanApiSvc, masterdataSvc, cardioCoachSvc) {
                var _this = _super.call(this, $scope, $translate, toaster, $rootScope, $state, $dialog, $window, carePlanActionApiSvc, modalBuilderFactory) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$rootScope = $rootScope;
                _this.$state = $state;
                _this.$dialog = $dialog;
                _this.$window = $window;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.cardioCoachSvc = cardioCoachSvc;
                _this.initAsync();
                return _this;
            }
            UptitrationEvaluationController.prototype.getValueText = function () {
                var _this = this;
                var selectedOptions = _(this.$scope.proposal.value)
                    .chain()
                    .map(function (v) { return _(_this.$scope.options).find(function (o) { return o.Id === v; }); })
                    .map(function (o) { return o.Name; })
                    .value();
                return selectedOptions.join(', ');
            };
            UptitrationEvaluationController.prototype.makeRegistrationAsync = function (params, errorCallback) {
                return this.cardioCoachSvc.evaluateUptitrationAsync(params, errorCallback);
            };
            UptitrationEvaluationController.prototype.initMedicationNameAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var r, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionMedicationAsync(this.$scope.action.carePlanActionId)];
                            case 1:
                                r = _a.sent();
                                this.$scope.medicationName = r.BrandName;
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            UptitrationEvaluationController.prototype.initCodeSetItemsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var pointInTime, _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                pointInTime = this.$scope.action.processVersionDate;
                                if (pointInTime === undefined) {
                                    pointInTime = moment.utc().toDate();
                                }
                                _a = this.$scope;
                                return [4 /*yield*/, this.masterdataSvc.getCodeSetItemsAsync(this.$scope.proposal.optionGroupId, RemeCare.Shared.DateHelper.toServerDateString(pointInTime))];
                            case 1:
                                _a.options = _b.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            UptitrationEvaluationController.prototype.initAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result, registration, e_3, result, registration, e_4, result, registration, e_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.initMedicationNameAsync();
                                this.$scope.proposal = _(this.$scope.action.components).find(function (c) {
                                    return c.observableEntity != null &&
                                        c.observableEntity.Id ===
                                            Custom.CardioCoachConstants.uptitrationProposal;
                                });
                                this.$scope.currentDose = _(this.$scope.action.components).find(function (c) {
                                    return c.characteristicId ===
                                        Custom.CardioCoachConstants.currentDailyDose;
                                });
                                this.$scope.nextDose = _(this.$scope.action.components).find(function (c) {
                                    return c.characteristicId ===
                                        Custom.CardioCoachConstants.nextLevelDose;
                                });
                                this.$scope.proposal.hidden = true;
                                this.$scope.currentDose.hidden = true;
                                this.$scope.nextDose.hidden = true;
                                this.initCodeSetItemsAsync();
                                if (!(this.$scope.action.status !== RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed &&
                                    this.$scope.action.status !== RemeCare.Contract.Core.Codes.CarePlanActionStatus.Cancelled)) return [3 /*break*/, 15];
                                if (!(this.$scope.proposal.value == null || this.$scope.proposal.value.length === 0)) return [3 /*break*/, 5];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanAnamnesesAsync(this.$scope.action.carePlanIds[0], Custom.CardioCoachConstants.uptitrationProposalAnamnesis, this.$scope.action.plannedDateTimeFrom)];
                            case 2:
                                result = _a.sent();
                                registration = _(result)
                                    .chain()
                                    .map(function (a) { return a.RegistrationValue; })
                                    .filter(function (r) { return r != null; })
                                    .sortBy(function (r) { return RemeCare.Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime); })
                                    .last()
                                    .value();
                                this.$scope.proposal.value = registration != null ? [registration.ChoiceListOption.Id] : null;
                                this.setDoseIndicators();
                                return [3 /*break*/, 4];
                            case 3:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 4];
                            case 4: return [3 /*break*/, 6];
                            case 5:
                                this.setDoseIndicators();
                                _a.label = 6;
                            case 6:
                                if (!(this.$scope.currentDose.value == null)) return [3 /*break*/, 10];
                                _a.label = 7;
                            case 7:
                                _a.trys.push([7, 9, , 10]);
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanAnamnesesAsync(this.$scope.action.carePlanIds[0], Custom.CardioCoachConstants.currentDailyDoseAnamnesis, this.$scope.action.plannedDateTimeFrom)];
                            case 8:
                                result = _a.sent();
                                registration = _(result)
                                    .chain()
                                    .map(function (a) { return a.RegistrationValue; })
                                    .filter(function (r) { return r != null; })
                                    .sortBy(function (r) { return RemeCare.Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime); })
                                    .last()
                                    .value();
                                this.$scope.currentDose.value = registration != null ? registration.Amount : null;
                                return [3 /*break*/, 10];
                            case 9:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 10];
                            case 10:
                                if (!(this.$scope.nextDose.value == null)) return [3 /*break*/, 14];
                                _a.label = 11;
                            case 11:
                                _a.trys.push([11, 13, , 14]);
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanAnamnesesAsync(this.$scope.action.carePlanIds[0], Custom.CardioCoachConstants.nextLevelDoseAnamnesis, this.$scope.action.plannedDateTimeFrom)];
                            case 12:
                                result = _a.sent();
                                registration = _(result)
                                    .chain()
                                    .map(function (a) { return a.RegistrationValue; })
                                    .filter(function (r) { return r != null; })
                                    .sortBy(function (r) { return RemeCare.Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime); })
                                    .last()
                                    .value();
                                this.$scope.nextDose.value = registration != null ? registration.Amount : null;
                                return [3 /*break*/, 14];
                            case 13:
                                e_5 = _a.sent();
                                return [3 /*break*/, 14];
                            case 14: return [3 /*break*/, 16];
                            case 15:
                                this.setDoseIndicators();
                                _a.label = 16;
                            case 16: return [2 /*return*/];
                        }
                    });
                });
            };
            UptitrationEvaluationController.prototype.setDoseIndicators = function () {
                this.$scope.showDoses = !_(this.$scope.proposal.value).contains(Custom.CardioCoachConstants.noUptirationNoMedication);
            };
            return UptitrationEvaluationController;
        }(RemeCare.CarePlanAction.DefaultCarePlanActionController));
        Custom.remeCareCustomModule.controller('cardioCoachUptitrationEvaluationCtrl', UptitrationEvaluationController);
    })(Custom = RemeCare.Custom || (RemeCare.Custom = {}));
})(RemeCare || (RemeCare = {}));
