module RemeCare.Patient {
    import TelephoneNumber = RemeCare.Model.TelephoneNumber;
    import Email = RemeCare.Model.Email;
    import Address = RemeCare.Model.Address;
    import ApplicationProfileType = Shared.Contract.Code.ApplicationProfileType;

    interface IPersonalInfoScope extends Shared.Framework.IBaseScope {
        person: RemeCare.Model.PatientPerson;
        showPhoneNumbers(): void;
        showMobileNumbers(): void;
        showEmails(): void;
        showAddresses(): void;
        edit(): void;
        editInfo: {
            editMode: boolean;
            person?: RemeCare.Model.PatientPerson;
            internalRemarkForm?: ng.IFormController;
        };
        saveRemark(): void;
        reload(): void;
        maxDate: Date;
        editDisabled: boolean;
        savePatient(patient: RemeCare.Model.PatientPerson);
    }

    class PersonalInfoController extends Shared.Framework.ControllerBase<IPersonalInfoScope> {

        constructor(
            $scope: IPersonalInfoScope,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService,
            private patientSvc: PatientService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private authservice: Shared.Framework.AuthService,
            private partyApiSvc: Core.Services.PartyApiService) {
            super($scope, $translate, toaster);
            $scope.showPhoneNumbers = () => this.showPhoneNumbers();
            $scope.showMobileNumbers = () => this.showMobileNumbers();
            $scope.showEmails = () => this.showEmails();
            $scope.showAddresses = () => this.showAddresses();
            $scope.edit = () => this.edit();
            $scope.saveRemark = () => this.saveRemark();
            $scope.reload = () => this.reload();
            $scope.savePatient = p => this.savePatient(p);
        }

        public $onInit(): void {
            this.$scope.editInfo = {
                editMode: false
            };
            var appProfile = this.authservice.getClaim(Shared.Framework.ClaimTypes.applicationProfile);
            this.$scope.editDisabled = (appProfile !== ApplicationProfileType.Superuser.toString())
            && (appProfile !== ApplicationProfileType.AdministrativeManager.toString())
            && (appProfile !== ApplicationProfileType.CareManager.toString());

            
            this.$scope.maxDate = moment().toDate();
            this.reload();
        }

        private reload(): void {
            this.patientSvc.getPatient(this.$stateParams.patientId)
                .success(r => this.$scope.person = new RemeCare.Model.PatientPerson(r))
                .error(e => this.errorCallback(e));
        }

        private edit(): void {
            this.$scope.editInfo.person = this.copy(this.$scope.person);
            this.$scope.editInfo.editMode = true;
        }

        private buildPhoneNumbersGrid(): Shared.Framework.Grid.Grid<TelephoneNumber> {
            return this.gridBuilderSvc
                .createGridBuilder<TelephoneNumber>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('toString()', 'General.Number')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }

        private buildEmailGrid(): Shared.Framework.Grid.Grid<Email> {
            return this.gridBuilderSvc
                .createGridBuilder<Email>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('email', 'General.Email')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }

        private buildAddressGrid(): Shared.Framework.Grid.Grid<Address> {
            return this.gridBuilderSvc
                .createGridBuilder<Address>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('addressLine1', 'General.Address')
                .addColumn('zipCode', 'General.Zipcode')
                .addColumn('city', 'General.City')
                .addEnumColumn('country', 'General.Country', Shared.Framework.ReferenceDataTypes.country)
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }

        private showPhoneNumbers(): void {
            var grid = this.buildPhoneNumbersGrid();
            grid.setData(this.$scope.person.telephoneNumbers);
            this.showContactPointsModal('Views.PatientFile.DetailPhone', grid);
        }

        private showMobileNumbers(): void {
            var grid = this.buildPhoneNumbersGrid();
            grid.setData(this.$scope.person.mobileNumbers);
            this.showContactPointsModal('Views.PatientFile.DetailMobile', grid);
        }

        private showEmails(): void {
            var grid = this.buildEmailGrid();
            grid.setData(this.$scope.person.emailAddresses);
            this.showContactPointsModal('Views.PatientFile.DetailEmail', grid);
        }

        private showAddresses(): void {
            var grid = this.buildAddressGrid();
            grid.setData(this.$scope.person.addresses);
            this.showContactPointsModal('Views.PatientFile.DetailAddresses', grid);
        }

        private showContactPointsModal(title: string, grid: Shared.Framework.Grid.Grid<RemeCare.Model.ContactPoint>): void {
            this.modalBuilderFactory.createModalBuilder()
                .setTemplateUrl('views/shared/contactPointDetail.html')
                .setScope({
                    title: title,
                    grid: grid
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private saveRemark(): void {
            this.patientSvc.saveInternalRemark(this.$scope.editInfo.person.id, this.$scope.editInfo.person.remark)
                .success(() => {
                    this.$scope.person.remark = this.$scope.editInfo.person.remark;
                    this.generalSuccess();
                })
                .error(e => this.errorCallback(e));
        }

        private savePatient(person: RemeCare.Model.PatientPerson): void {
            this.partyApiSvc.modifyPersonalInfo(person.partyId,
                person.getPersonalInfo())
                .success(() => this.$state.reload())
                .error(e => this.toaster.error(e));
        }
    }

    remeCarePatientModule.controller('patientInfoPersonalInfoCtrl', PersonalInfoController);
}