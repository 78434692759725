var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        var MyAgendaController = /** @class */ (function () {
            function MyAgendaController(toaster, $state, $animate, actorRolesApiSvc, carePlanActionApiSvc, authservice, modalBuilderFactory, patientSvc, storageSvc, urlBinderFactory, patientSearchSvc) {
                this.toaster = toaster;
                this.$state = $state;
                this.$animate = $animate;
                this.actorRolesApiSvc = actorRolesApiSvc;
                this.carePlanActionApiSvc = carePlanActionApiSvc;
                this.authservice = authservice;
                this.modalBuilderFactory = modalBuilderFactory;
                this.patientSvc = patientSvc;
                this.storageSvc = storageSvc;
                this.urlBinderFactory = urlBinderFactory;
                this.patientSearchSvc = patientSearchSvc;
            }
            MyAgendaController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var actorRolesPromise, nonPlannedActionTemplatesPromise, _a, actorRoles, nonPlannedActionTemplates, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.storageSvc.store(StorageServiceConstants.agendaKey, StorageServiceConstants.myAgenda);
                                this.isTodoListVisible = true;
                                this.selectedActions = [];
                                this.filter = {};
                                this.urlBinder = this.urlBinderFactory.createUrlBinder(this.filter);
                                this.urlBinder.bindAllFromUrl();
                                if (!this.urlBinder.isEmpty()) return [3 /*break*/, 1];
                                this.setDefaultFilters();
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, this.enrichFilterDataAsync()];
                            case 2:
                                _b.sent();
                                _b.label = 3;
                            case 3:
                                _b.trys.push([3, 5, , 6]);
                                actorRolesPromise = this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true });
                                nonPlannedActionTemplatesPromise = this.isPatient
                                    ? this.patientSvc.getNonPlannedActionTemplatesAsync(this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.partyRoleId))
                                    : Promise.resolve([]);
                                return [4 /*yield*/, Promise.all([actorRolesPromise, nonPlannedActionTemplatesPromise])];
                            case 4:
                                _a = _b.sent(), actorRoles = _a[0], nonPlannedActionTemplates = _a[1];
                                this.filter.actorRoles = _(actorRoles).map(function (r) { return r.Id; });
                                this.loaded = true;
                                this.actionTemplates = nonPlannedActionTemplates;
                                this.actionTemplates.forEach(function (at) { return (at.displayName = at.Name || ''); });
                                return [3 /*break*/, 6];
                            case 5:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 6];
                            case 6: return [2 /*return*/];
                        }
                    });
                });
            };
            MyAgendaController.prototype.makeAdhocRegistration = function (template) {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('adhocRegistrationModalCtrl')
                    .setTemplateUrl('views/patient/monitoring/registrations/adhocRegistrationModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                    action: new RemeCare.Patient.Model.ActionTemplate(this.authservice, this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.partyRoleId), template),
                })
                    .build();
            };
            MyAgendaController.prototype.isFiltered = function () {
                return ((this.filter.patientData != null && this.filter.patientData.patient != null) ||
                    (this.filter.actionTypes != null && this.filter.actionTypes.length > 0) ||
                    (this.filter.statuses != null && this.filter.statuses.length > 0) ||
                    (this.filter.therapyIds != null && this.filter.therapyIds.length > 0) ||
                    (this.filter.actionAssignmentTypes != null && this.filter.actionAssignmentTypes.length > 0) ||
                    (this.filter.actorRoles != null && this.filter.actorRoles.length > 0));
            };
            MyAgendaController.prototype.searchItems = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var query, personalAgendaActions, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    startDate: RemeCare.Shared.DateHelper.toServerDateString(this.filter.fromDate),
                                    endDate: RemeCare.Shared.DateHelper.toServerDateString(this.filter.untilDate),
                                    dateFilterTypes: [
                                        RemeCare.Contract.Core.Codes.DateFilterTypeCode.PlannedDate,
                                        RemeCare.Contract.Core.Codes.DateFilterTypeCode.EventDate,
                                    ],
                                    statuses: this.filter.statuses,
                                    actionTypes: this.filter.actionTypes,
                                    therapyIds: this.filter.therapyIds,
                                    patientId: this.filter.patientData && this.filter.patientData.patient
                                        ? this.filter.patientData.patient.Id
                                        : null,
                                    actorRoles: this.filter.actorRoles,
                                    actionAssignmentTypes: this.filter.actionAssignmentTypes,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.findPersonalAgendaActions(query)];
                            case 2:
                                personalAgendaActions = _a.sent();
                                this.agendaItems = _(personalAgendaActions).map(function (x) { return new RemeCare.Model.PersonalAgendaAction(x); });
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            MyAgendaController.prototype.toggleTodoList = function () {
                if (this.isTodoListVisible) {
                    this.hideTodoList();
                }
                else {
                    this.showTodoList();
                }
            };
            MyAgendaController.prototype.registerAdHoc = function () {
                var _this = this;
                if (this.filter.patientData != null && this.filter.patientData.patient != null) {
                    this.$state.go('patients.patientfile.monitoring.registrations.adhoc', {
                        patientId: this.filter.patientData.patient.Id,
                    });
                    return;
                }
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setTemplateUrl('views/patient/searchPatientModal.html')
                    .setResultCallBack(function (p) {
                    _this.$state.go('patients.patientfile.monitoring.registrations.adhoc', { patientId: p.Id });
                })
                    .build();
            };
            MyAgendaController.prototype.showFilters = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcMyAgendaFilter')
                    .setBindings({
                    filter: angular.copy(this.filter),
                })
                    .setResultCallBack(function (r) {
                    angular.copy(r, _this.filter);
                    _this.urlBinder.reflectInUrl(true);
                    _this.searchItems();
                })
                    .build();
            };
            MyAgendaController.prototype.setDefaultFilters = function () {
                this.filter.showAllTodos = false;
                this.filter.actionAssignmentTypes = [];
                this.filter.actionTypes = [];
                this.filter.actorRoles = [];
                this.filter.statuses = [
                    RemeCare.Contract.Core.Codes.CarePlanActionStatus.Open,
                    RemeCare.Contract.Core.Codes.CarePlanActionStatus.Overdue,
                    RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed,
                ];
                this.filter.therapyIds = [];
                switch (this.authservice.getProfile()) {
                    case RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager:
                    case RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager:
                        this.filter.actionAssignmentTypes = [RemeCare.Shared.Contract.Code.ActionAssignmentType.Me];
                        break;
                    case RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider:
                    case RemeCare.Shared.Contract.Code.ApplicationProfileType.TherapyProvider:
                        this.filter.actionAssignmentTypes = [
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Me,
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Unassigned,
                        ];
                        break;
                    case RemeCare.Shared.Contract.Code.ApplicationProfileType.Patient:
                        this.isPatient = true;
                        this.filter.actionAssignmentTypes = [
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Me,
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.OtherInActorRole,
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Unassigned,
                        ];
                        break;
                }
            };
            MyAgendaController.prototype.enrichFilterDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var searchResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!this.filter ||
                                    !this.filter.patientData ||
                                    !this.filter.patientData.patient ||
                                    !this.filter.patientData.patient.Id) {
                                    this.setDefaultFilters();
                                    return [2 /*return*/];
                                }
                                if (this.filter.patientData.patient.FirstName && this.filter.patientData.patient.LastName) {
                                    // Relevant patientdata already present
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.patientSearchSvc.findPatientsAsync({
                                        patientIds: [this.filter.patientData.patient.Id],
                                    })];
                            case 1:
                                searchResult = _a.sent();
                                if (!searchResult.Items || !searchResult.Items.length) {
                                    this.setDefaultFilters();
                                    return [2 /*return*/];
                                }
                                this.filter.patientData.patient = searchResult.Items[0];
                                this.filter.patientData.patientNumber = '' + this.filter.patientData.patient.PatientNumber;
                                this.urlBinder.reflectInUrl(true);
                                return [2 /*return*/];
                        }
                    });
                });
            };
            MyAgendaController.prototype.showTodoList = function () {
                this.isTodoListVisible = true;
                var calendar = angular.element('#agenda-calendar');
                var todoList = angular.element('#agenda-todo-list');
                this.$animate.addClass(calendar, 'col-offset-right-300', {
                    duration: 0.5,
                });
                this.$animate.animate(todoList, null, { right: '0px' }, null, { duration: 0.5 }).then(function () {
                    $(window).resize();
                });
            };
            MyAgendaController.prototype.hideTodoList = function () {
                this.isTodoListVisible = false;
                var calendar = angular.element('#agenda-calendar');
                var todoList = angular.element('#agenda-todo-list');
                this.$animate.animate(todoList, null, { right: '-300px' }, null, { duration: 0.5 });
                this.$animate
                    .removeClass(calendar, 'col-offset-right-300', {
                    duration: 0.5,
                })
                    .then(function () {
                    $(window).resize();
                });
            };
            return MyAgendaController;
        }());
        Agenda.remeCareAgendaModule.component('rcMyAgenda', {
            controller: MyAgendaController,
            templateUrl: 'agenda/myAgenda/myAgenda.html',
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
