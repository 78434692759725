/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import RegistrationValueFactory = Model.RegistrationValueFactory;

    interface IQualitativeAnamnesisScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.QualitativeAnamnesisDefinition;
        anamnesis: Anamnesis;
        step: number;
        codeSetItems: Shared.Contract.IChoiceListOption[];
        missingValues: string;
        hasRegistrationValue: boolean;
        careRequest: CareRequest;
    }

    class QualitativeAnamnesisController extends CareRequestPartControllerBase<IQualitativeAnamnesisScope> {
        private codeSetItemsInternal: Shared.Contract.IChoiceListOption[];

        constructor(
            protected $scope: IQualitativeAnamnesisScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            protected careRequestSvc: CareRequestSvc,
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.initializeAnamneses();
            this.loadDataAsync().then((r) => {
                // Find missing values, removed from the latest answer list
                if (
                    this.$scope.careRequestPart.uiControlType.Id === Shared.Contract.Code.UIControlType.Checkbox ||
                    this.$scope.careRequestPart.uiControlType.Id === Shared.Contract.Code.UIControlType.Dropdown ||
                    this.$scope.careRequestPart.uiControlType.Id === Shared.Contract.Code.UIControlType.Radio ||
                    this.$scope.careRequestPart.uiControlType.Id === Shared.Contract.Code.UIControlType.Slider
                ) {
                    // single model
                    const registrationValue = this.$scope.anamnesis
                        .registrationValue as RemeCare.Model.RegistrationCodeSetValue;
                    this.$scope.hasRegistrationValue = registrationValue.codeSetItem != null;
                    if (registrationValue.codeSetItem && registrationValue.codeSetItem.Id) {
                        if (
                            !_(this.codeSetItemsInternal)
                                .chain()
                                .map((csi) => csi.Id)
                                .contains(registrationValue.codeSetItem.Id)
                        ) {
                            this.$scope.missingValues = registrationValue.codeSetItem.Text;
                            registrationValue.codeSetItem = null;
                        }
                    }
                } else {
                    if (
                        this.$scope.careRequestPart.uiControlType.Id ===
                            Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                        this.$scope.careRequestPart.uiControlType.Id ===
                            Shared.Contract.Code.UIControlType.DropdownListMultiple
                    ) {
                        // multi-model
                        const registrationValue = this.$scope.anamnesis
                            .registrationValue as RemeCare.Model.RegistrationMultiCodeSetValue;
                        this.$scope.hasRegistrationValue = registrationValue.multiCodeSetItems.length > 0;
                        const missingValuesList: RemeCare.Shared.Contract.IChoiceListOption[] = [];
                        _(registrationValue.multiCodeSetItems).forEach((mcsi) => {
                            const csiList = _(this.codeSetItemsInternal).map((csi) => csi.Id);
                            if (!_(csiList).any((csi) => csi === mcsi)) {
                                const text = _(registrationValue.multiCodeSetItemsTranslations).filter(
                                    (trans) => trans.Id === mcsi
                                )[0].Text;
                                missingValuesList.push({
                                    Id: mcsi,
                                    Name: text,
                                } as RemeCare.Shared.Contract.IChoiceListOption);
                            }
                        });
                        this.$scope.missingValues = missingValuesList.map((mvl) => mvl.Name).join(', ');
                    } else {
                        this.toaster.error('Invalud UI Control Type');
                    }
                }
            });
            this.checkForModifications();
        }

        private checkForModifications() {
            if(!this.$scope.careRequestPart.isActive && this.$scope.hasRegistrationValue) {
                this.$scope.careRequest.isModified = true;
            }

            if(this.$scope.careRequestPart.isActive && this.$scope.missingValues && this.$scope.missingValues.length > 0 && !this.$scope.readOnly) {
                this.$scope.careRequest.isModified = true;
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }

        private initializeAnamneses(): void {
            this.$scope.anamnesis = _.find(
                this.$scope.careRequest.anamneses,
                (t) => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity
            );
            this.$scope.hasRegistrationValue = false;
            if (this.$scope.anamnesis == null) {
                this.$scope.anamnesis = new Anamnesis();
                this.$scope.anamnesis.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.initDefaultValues();
                this.$scope.careRequest.anamneses.push(this.$scope.anamnesis);
            }
        }

        private initDefaultValues(): void {
            if (
                this.$scope.careRequestPart.uiControlType.Id ===
                    Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                this.$scope.careRequestPart.uiControlType.Id ===
                    Shared.Contract.Code.UIControlType.DropdownListMultiple
            ) {
                this.$scope.anamnesis.registrationValue = RegistrationValueFactory.createRegistrationValueFromType(
                    Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue
                );
            } else {
                this.$scope.anamnesis.registrationValue = RegistrationValueFactory.createRegistrationValueFromType(
                    Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue
                );
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                if (
                    (this.$scope.careRequest.careRequestStatus && this.$scope.careRequest.careRequestStatus.Id === 3) ||
                    !this.$scope.careRequestPart.isActive
                ) {
                    // if CareRequest is final or the part is inactive, load code set items at registration date time
                    this.codeSetItemsInternal = await this.masterdataSvc.getCodeSetItemsAsync(
                        this.$scope.careRequestPart.codeSet.Id,
                        RemeCare.Shared.DateHelper.toServerDateString(
                            this.$scope.anamnesis.registrationValue.registrationDateTime
                        )
                    );
                } else {
                    // if CareRequest is NOT final, load code set items valid today
                    this.codeSetItemsInternal = await this.masterdataSvc.getCodeSetItemsAsync(
                        this.$scope.careRequestPart.codeSet.Id
                    );
                }
                this.$scope.codeSetItems = angular.copy(this.codeSetItemsInternal);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    class QualitativeAnamnesisDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'masterdataSvc',
            'careRequestSvc',
            ($scope, $translate, toaster, masterdataSvc, careRequestSvc) =>
                new QualitativeAnamnesisController($scope, $translate, toaster, masterdataSvc, careRequestSvc),
        ];

        public templateUrl = 'views/careRequest/directive/qualitativeAnamnesis.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestQualitativeAnamnesis', () => new QualitativeAnamnesisDirective());
}
