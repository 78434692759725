var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var PartyType = RemeCare.Shared.Contract.Code.PartyType;
            var ActorRoleInclusionOption = RemeCare.Shared.Framework.Model.ActorRoleInclusionOption;
            var CareRequestRolesController = /** @class */ (function (_super) {
                __extends(CareRequestRolesController, _super);
                function CareRequestRolesController($timeout, $translate, toaster, gridBuilderSvc, partyApiSvc, healthCareOrganisationSearchSvc, modalBuilderFactory, authservice, $rootScope) {
                    var _this = _super.call(this) || this;
                    _this.$timeout = $timeout;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.partyApiSvc = partyApiSvc;
                    _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.authservice = authservice;
                    _this.$rootScope = $rootScope;
                    _this.$rootScope.$on('CAREREQUEST_ROLE_SELECT', function (event, role) {
                        if (role.rolePart !== CareRequest.RolePart.CareTeamRole) {
                            return;
                        }
                        if (_this.careRequestRoleConfigurations.filter(function (con) { return con.role.actorRoleId === role.actorRoleId; })
                            .length > 0) {
                            switch (role.partyRoleType) {
                                case CareRequest.PartyRoleType.HealthCareProfessional:
                                    var party = CareRequest.RequestPerson.createFromHealthCareProfessional(role.party);
                                    var careRequestRole = CareRequest.CareRequestRole.createForParty(role.actorRoleId, party);
                                    careRequestRole.partyRoleId = role.party.PartyRoleId;
                                    if (!_this.gridAlreadyContainsRole(careRequestRole)) {
                                        _this.grid.addRow(careRequestRole);
                                    }
                                    break;
                                case CareRequest.PartyRoleType.HealthCareOrganisation:
                                    var organisation = CareRequest.RequestOrganisation.createFromHealthCareOrganisation(role.party);
                                    var careRequestRoleOrg = CareRequest.CareRequestRole.createForParty(role.actorRoleId, organisation);
                                    careRequestRoleOrg.partyRoleId = role.party.PartyRoleId;
                                    if (!_this.gridAlreadyContainsRole(careRequestRoleOrg)) {
                                        _this.grid.addRow(careRequestRoleOrg);
                                    }
                                    break;
                            }
                        }
                    });
                    _this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECT', function (event, role) {
                        if (role.rolePart !== CareRequest.RolePart.CareTeamRole) {
                            return;
                        }
                        if (_this.careRequestRoleConfigurations.filter(function (con) { return con.role.actorRoleId === role.actorRoleId; })
                            .length > 0) {
                            var newData = _this.grid
                                .getData()
                                .filter(function (crr) { return crr.roleId !== role.actorRoleId || crr.partyRoleId !== role.party.PartyRoleId; });
                            _this.careRequest.careRequestRoles = newData;
                            _this.grid.setData(_this.careRequest.careRequestRoles);
                        }
                    });
                    return _this;
                }
                CareRequestRolesController.prototype.$onInit = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var ids, persons, healthCareOrganisation, _a;
                        var _this = this;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    this.buildGrid();
                                    this.useGroups = false;
                                    this.careRequestTemplate.careRequestSetup.careRequestParts.forEach(function (crp) {
                                        if (crp.type.Id === RemeCare.Shared.Contract.Code.CareRequestPartType.CareRequestRole) {
                                            var rolePart = crp;
                                            rolePart.careRequestRoleConfigurations.forEach(function (crc) {
                                                crc.isVisible = !_this.maxReached(crc);
                                            });
                                        }
                                    });
                                    this.careRequestRoleConfigurations = this.careRequestPart.careRequestRoleConfigurations.map(function (crc) {
                                        return _this.map(crc);
                                    });
                                    this.careRequestRoleConfigurations.map(function (crc) { return _this.completeDisplayNameConfiguration(crc); });
                                    ids = _(this.careRequest.careRequestRoles)
                                        .chain()
                                        .map(function (crr) { return crr.partyRoleId; })
                                        .filter(function (id) { return id != null; })
                                        .value();
                                    if (!(ids.length > 0)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.partyApiSvc.getPersonsAsync(ids)];
                                case 1:
                                    persons = _b.sent();
                                    _(persons).forEach(function (professional) {
                                        var role = _.find(_this.careRequest.careRequestRoles, function (crr) {
                                            var personPartyRoleIds = _(professional.PartyRoles).map(function (pr) { return pr.Id; });
                                            return _(personPartyRoleIds).contains(crr.partyRoleId);
                                        });
                                        role.requestParty = CareRequest.RequestPerson.createFromPerson(professional);
                                    });
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync(ids)];
                                case 2:
                                    healthCareOrganisation = _b.sent();
                                    _(healthCareOrganisation).forEach(function (organisation) {
                                        var role = _.find(_this.careRequest.careRequestRoles, function (crr) { return crr.partyRoleId === organisation.PartyRoleId; });
                                        role.requestParty = CareRequest.RequestOrganisation.createFromHealthCareOrganisation(organisation);
                                    });
                                    _b.label = 3;
                                case 3:
                                    _a = this;
                                    return [4 /*yield*/, this.partyApiSvc.getRelatedGroupsAsync(null)];
                                case 4:
                                    _a.groups = _b.sent();
                                    this.canUseGroupsWithSelect =
                                        (this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                                            this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.TherapyProvider) &&
                                            this.groups.length > 0;
                                    this.canUseGroupsWithSearch =
                                        this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager ||
                                            this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager;
                                    this.careRequestRoleConfigurations.map(function (crc) { return _this.completeDisplayNameConfiguration(crc); });
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.preferredGroupFirstName = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var person;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.determineSearchPersonSuggestion()];
                                case 1:
                                    person = _a.sent();
                                    return [2 /*return*/, person !== undefined ? person.FirstName : ''];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.preferredGroupLastName = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var person;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.determineSearchPersonSuggestion()];
                                case 1:
                                    person = _a.sent();
                                    return [2 /*return*/, person !== undefined ? person.LastName : ''];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.groupsChanged = function () {
                    var _this = this;
                    this.$timeout(function () { return __awaiter(_this, void 0, void 0, function () {
                        var groups;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this.membersGrid.setData(null);
                                    this.collapseGrid = true;
                                    if (!(this.selectedGroup != null && this.useGroups)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.partyApiSvc.getGroupsAsync([this.selectedGroup])];
                                case 1:
                                    groups = _a.sent();
                                    this.groupMembers = groups.map(function (garm) { return new RemeCare.Management.GroupAssignment(garm, _this.mapToGroupName(garm.GroupId)); });
                                    this.allGroupMembers = this.groupMembers;
                                    if (this.useGroups) {
                                        this.membersGrid.setData(this.groupMembers);
                                        this.collapseGrid = this.membersGrid.getData().length > 10;
                                    }
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                };
                CareRequestRolesController.prototype.isRoleRequired = function (config) {
                    var min = this.getMinimumNumber(config.role);
                    return min > 0;
                };
                CareRequestRolesController.prototype.maxReached = function (config) {
                    var max = this.getMaximalNumber(config.role);
                    if (max == null) {
                        return false;
                    }
                    var roles = _(this.careRequest.careRequestRoles).filter(function (crr) { return crr.roleId === config.role.actorRoleId; });
                    return roles.length >= max;
                };
                CareRequestRolesController.prototype.isValidConfig = function (config) {
                    var roles = _(this.careRequest.careRequestRoles).filter(function (crr) { return crr.roleId === config.role.actorRoleId; });
                    var minimal = this.getMinimumNumber(config.role);
                    var maximal = this.getMaximalNumber(config.role);
                    if (roles.length < minimal) {
                        return false;
                    }
                    if (maximal != null && roles.length > maximal) {
                        return false;
                    }
                    return true;
                };
                CareRequestRolesController.prototype.clicked = function (config) {
                    var _this = this;
                    if (this.useGroups) {
                        config.possibleGroupAssignments = this.getGroupAssignmentsThatMatchConfiguration(config);
                    }
                    if (this.useGroups &&
                        config.possibleGroupAssignments != null &&
                        config.possibleGroupAssignments.length > 0) {
                        this.modalBuilderFactory
                            .createModalBuilder()
                            .setController('careRequestRoleController')
                            .setTemplateUrl('views/careRequest/directive/careRequestRoleModal.html')
                            .setScope({
                            config: config,
                        })
                            .setResultCallBack(function (r) {
                            r.groupMembers.forEach(function (gm) { return _this.groupAssignmentSelectedAsync(gm, config); });
                            if (r.needsMemberOutOfGroup) {
                                _this.addRole(config);
                            }
                        })
                            .build();
                    }
                    else {
                        this.addRole(config);
                    }
                };
                CareRequestRolesController.prototype.gridAlreadyContainsRole = function (role) {
                    if (!role) {
                        return false;
                    }
                    var currentData = this.grid.getData();
                    if (!currentData || currentData.length === 0) {
                        return false;
                    }
                    var filteredData = currentData.filter(function (crr) {
                        return crr.requestParty.id === role.requestParty.id &&
                            crr.roleId === role.roleId &&
                            crr.partyRoleId === role.partyRoleId;
                    });
                    if (!filteredData || filteredData.length === 0) {
                        return false;
                    }
                    return true;
                };
                CareRequestRolesController.prototype.determineSearchPersonSuggestion = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(this.careRequestTemplate.careRequestStepSequence === this.careRequestTemplate.totalSteps)) return [3 /*break*/, 4];
                                    if (!(this.careRequest.CareRequestInitiator === undefined)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.loadRequestorPerson()];
                                case 1:
                                    _a.sent();
                                    if (this.careRequestRequestorPerson !== undefined) {
                                        return [2 /*return*/, this.careRequestRequestorPerson];
                                    }
                                    return [3 /*break*/, 4];
                                case 2: 
                                // Scenario: If last step and continuing from an Application phase, then use Initiator
                                return [4 /*yield*/, this.loadInitiatorPerson()];
                                case 3:
                                    // Scenario: If last step and continuing from an Application phase, then use Initiator
                                    _a.sent();
                                    if (this.careRequestInitiatorPerson !== undefined) {
                                        return [2 /*return*/, this.careRequestInitiatorPerson];
                                    }
                                    _a.label = 4;
                                case 4: 
                                // Other scenarios
                                return [2 /*return*/, undefined];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.loadInitiatorPerson = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var persons;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(this.careRequestInitiatorPerson === undefined)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.partyApiSvc.getPersonsAsync([this.careRequest.CareRequestInitiator])];
                                case 1:
                                    persons = _a.sent();
                                    if (persons.length !== 0) {
                                        this.careRequestInitiatorPerson = persons[0];
                                    }
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.loadRequestorPerson = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var persons;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    // Load every time
                                    if (this.careRequest.careRequestor.healthCareProfessionalId === undefined) {
                                        this.careRequestRequestorPerson = undefined;
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, this.partyApiSvc.getPersonsAsync([
                                            this.careRequest.careRequestor.healthCareProfessionalId,
                                        ])];
                                case 1:
                                    persons = _a.sent();
                                    if (persons.length !== 0) {
                                        this.careRequestRequestorPerson = persons[0];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.groupAssignmentSelectedAsync = function (ga, config) {
                    return __awaiter(this, void 0, void 0, function () {
                        var actorRoleInclusionDetails, partyRoleIds, requestPerson, careRequestRole, partyRoleId, hco, party, careRequestRole, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (this.groupMembers !== undefined) {
                                        this.groupMembers = this.groupMembers.filter(function (gm) { return gm.id !== ga.id; });
                                    }
                                    actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
                                    partyRoleIds = actorRoleInclusionDetails
                                        .map(function (arid) { return arid.partyRoleId; })
                                        .filter(function (id) { return id != null; })
                                        .value();
                                    if (!(config.role.partyType === PartyType.Person)) return [3 /*break*/, 1];
                                    requestPerson = CareRequest.RequestPerson.createFromPerson(ga.person);
                                    requestPerson.group = ga;
                                    careRequestRole = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, requestPerson);
                                    switch (config.role.radioSelection) {
                                        case ActorRoleInclusionOption.Individuals:
                                            careRequestRole.partyRoleId = _.find(ga.person.PartyRoles, function (pr) {
                                                return _.contains(partyRoleIds, pr.Id);
                                            }).Id;
                                            break;
                                        case ActorRoleInclusionOption.Patient:
                                            careRequestRole.partyRoleId = _.find(ga.person.PartyRoles, function (pr) { return pr.Type.Id === RemeCare.Contract.Core.Codes.PartyRoleTypeCode.Patient; }).Id;
                                            break;
                                        case ActorRoleInclusionOption.Specialties:
                                            careRequestRole.partyRoleId = _.find(ga.person.PartyRoles, function (pr) {
                                                return _.some(actorRoleInclusionDetails.value(), function (arid) {
                                                    return pr.HealthCareProfessionalType.Id === arid.healthCareProfessionalTypeId &&
                                                        (!arid.healthCareSpecialtyProfessionId ||
                                                            _.contains(_.map(pr.HealthCareProffesionalSpecialties, function (hcps) { return hcps.Id; }), arid.healthCareSpecialtyProfessionId));
                                                });
                                            }).Id;
                                            break;
                                    }
                                    this.grid.addRow(careRequestRole);
                                    return [3 /*break*/, 5];
                                case 1:
                                    partyRoleId = void 0;
                                    switch (config.role.radioSelection) {
                                        case ActorRoleInclusionOption.Organisations:
                                            partyRoleId = _.find(ga.organisation.PartyRoles, function (pr) {
                                                return _.contains(partyRoleIds, pr.PartyRoleId);
                                            }).PartyRoleId;
                                            break;
                                        case ActorRoleInclusionOption.OrganisationTypes:
                                            partyRoleId = ga.organisation.PartyRoles[0].PartyRoleId;
                                            break;
                                    }
                                    _a.label = 2;
                                case 2:
                                    _a.trys.push([2, 4, , 5]);
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([
                                            partyRoleId,
                                        ])];
                                case 3:
                                    hco = _a.sent();
                                    party = CareRequest.RequestOrganisation.createFromHealthCareOrganisation(hco[0]);
                                    party.group = ga;
                                    careRequestRole = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, party);
                                    careRequestRole.partyRoleId = partyRoleId;
                                    this.grid.addRow(careRequestRole);
                                    return [3 /*break*/, 5];
                                case 4:
                                    e_1 = _a.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 5];
                                case 5: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.completeDisplayNameConfiguration = function (config) {
                    var maxNumber = this.getMaximalNumber(config.role);
                    var minNumber = this.getMinimumNumber(config.role);
                    config.displayName = config.role.name;
                    if (this.isRequestorRole(config.role)) {
                        config.displayName = config.displayName.concat(" (" + this.$translate.instant('Views.CareRequest.Details.BesidesRequestor') + " ) ");
                    }
                    if (minNumber === 0 || (maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber) {
                        config.displayName = config.displayName.concat(' ( ');
                    }
                    if (minNumber === 0) {
                        config.displayName = config.displayName.concat(this.$translate.instant('Views.CareRequest.Details.NotRequired'));
                    }
                    if (minNumber === 0 && ((maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber)) {
                        config.displayName = config.displayName.concat(' , ');
                    }
                    if (maxNumber != null && maxNumber !== minNumber) {
                        config.displayName = config.displayName.concat(this.$translate.instant('Views.CareRequest.Details.Maximal') + "  " + maxNumber);
                    }
                    if (maxNumber === minNumber) {
                        config.displayName = config.displayName.concat(this.$translate.instant('Views.CareRequest.Details.XRequired', { minimalNumber: minNumber }) + "  ");
                    }
                    if (minNumber === 0 || (maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber) {
                        config.displayName = config.displayName.concat(' ) ');
                    }
                };
                CareRequestRolesController.prototype.mapToGroupName = function (id) {
                    return _.find(this.groups, function (gr) { return gr.Id === id; }).Text;
                };
                CareRequestRolesController.prototype.careRequestRoleDeleted = function (careRequestRole) {
                    this.$rootScope.$emit('CAREREQUEST_ROLE_UNSELECTED', careRequestRole, CareRequest.RolePart.CareTeamRole);
                    if (careRequestRole.requestParty.group === null) {
                        return;
                    }
                    var gmToAdd = _.find(this.allGroupMembers, function (gm) { return gm.id === careRequestRole.requestParty.group.id; });
                    if (gmToAdd != null) {
                        this.groupMembers = this.groupMembers.concat([gmToAdd]);
                    }
                };
                CareRequestRolesController.prototype.buildGrid = function () {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('getName()', 'General.Name')
                        .addActionOnScopeColumn('$ctrl.parent.getRoleName', 'General.Role')
                        .addBoolColumn('partyRoleId', 'General.Linked')
                        .setRowDetailsTemplate('views/careRequest/directive/contactDetails.html', 150);
                    if (!this.readOnly) {
                        gridBuilder = gridBuilder
                            .addDeleteButtonColumn(function (crr) { return _this.careRequestRoleDeleted(crr); })
                            .addEditButtonWithPromiseFunctionColumn(function (r) { return _this.editCareRequestRoleAsync(r); });
                    }
                    this.grid = gridBuilder.build();
                    this.grid.setData(this.careRequest.careRequestRoles);
                    var memberGridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('name', 'General.Name')
                        .addColumn('type', 'General.Type', { cellFilter: 'delimitedDisplay:", "', enableSorting: false })
                        .addColumn('person.specialties', 'General.Specialty', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    });
                    this.membersGrid = memberGridBuilder.build();
                };
                // Used in grid above, do not remove
                // tslint:disable-next-line:typedef
                CareRequestRolesController.prototype.getRoleName = function (careRequestRole) {
                    var config = _.find(this.careRequestRoleConfigurations, function (c) { return c.role.actorRoleId === careRequestRole.roleId; });
                    return config != null ? config.role.name : '';
                };
                CareRequestRolesController.prototype.addRole = function (config) {
                    var actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
                    var partyRoleIds = actorRoleInclusionDetails
                        .map(function (arid) { return arid.partyRoleId; })
                        .filter(function (id) { return id != null; })
                        .value();
                    if (config.role.partyType === RemeCare.Shared.Contract.Code.PartyType.Person) {
                        if (partyRoleIds.length > 0) {
                            this.showProfessionalSelectorAsync(config, actorRoleInclusionDetails.value());
                            return;
                        }
                        else if (config.canBeSearchedByExternal) {
                            this.showProfessionalSearchModal(config);
                        }
                        else {
                            this.addCareRequestRoleAsync(config);
                        }
                    }
                    else {
                        if (partyRoleIds.length > 0) {
                            this.showOrganisationSelectorAsync(config, partyRoleIds);
                            return;
                        }
                        else {
                            this.showOrganisationSearchModal(config);
                        }
                    }
                };
                CareRequestRolesController.prototype.getGroupAssignmentsThatMatchConfiguration = function (config) {
                    var actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
                    var partyRoleIds = actorRoleInclusionDetails
                        .map(function (arid) { return arid.partyRoleId; })
                        .filter(function (id) { return id != null; })
                        .value();
                    if (this.selectedGroup == null) {
                        return [];
                    }
                    switch (config.role.radioSelection) {
                        case ActorRoleInclusionOption.Patient:
                            return this.groupMembers.filter(function (gm) {
                                return gm.person &&
                                    gm.person.partyRoleTypes.some(function (prt) { return prt.Id === RemeCare.Contract.Core.Codes.PartyRoleTypeCode.Patient; });
                            });
                        case ActorRoleInclusionOption.Individuals:
                            return this.groupMembers.filter(function (gm) {
                                return gm.person &&
                                    gm.person.PartyRoles.map(function (pr) { return pr.Id; }).some(function (prId) { return _.contains(partyRoleIds, prId); });
                            });
                        case ActorRoleInclusionOption.Specialties:
                            return this.groupMembers.filter(function (gm) {
                                return gm.person &&
                                    _.some(actorRoleInclusionDetails.value(), function (arid) {
                                        return _.contains(_.map(gm.person.healthCareProfessionalTypes, function (hpt) { return hpt.Id; }), arid.healthCareProfessionalTypeId) &&
                                            (!arid.healthCareSpecialtyProfessionId ||
                                                _.contains(_.map(gm.person.specialties, function (sp) { return sp.Id; }), arid.healthCareSpecialtyProfessionId));
                                    });
                            });
                        case ActorRoleInclusionOption.Organisations:
                            return this.groupMembers.filter(function (gm) {
                                return gm.organisation &&
                                    gm.organisation.PartyRoles.map(function (pr) { return pr.PartyRoleId; }).some(function (prId) {
                                        return _.contains(partyRoleIds, prId);
                                    });
                            });
                        case ActorRoleInclusionOption.OrganisationTypes:
                            return this.groupMembers.filter(function (gm) {
                                return gm.organisation &&
                                    _.some(actorRoleInclusionDetails.value(), function (arid) {
                                        return arid.healthCareOrganisationTypeId === gm.organisation.HealthCareOrganisationType.Id;
                                    });
                            });
                    }
                };
                CareRequestRolesController.prototype.getActorRoleInclusionDetails = function (config) {
                    return _(config.role.actorRoleInclusions)
                        .chain()
                        .map(function (ari) { return ari.actorRoleInclusionDetails; })
                        .flatten();
                };
                CareRequestRolesController.prototype.getExistingPartyRoleIds = function (config) {
                    return _(this.grid.getData())
                        .chain()
                        .filter(function (crr) { return crr.roleId === config.role.actorRoleId; })
                        .map(function (crr) { return crr.partyRoleId; })
                        .value();
                };
                CareRequestRolesController.prototype.showProfessionalSelectorAsync = function (config, professional) {
                    return __awaiter(this, void 0, void 0, function () {
                        var existingPartyRoleIds, pr, e_2, r, person, careRequestRole_1, e_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    existingPartyRoleIds = this.getExistingPartyRoleIds(config);
                                    professional = _(professional).filter(function (p) { return !_(existingPartyRoleIds).contains(p.partyRoleId); });
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 7, , 8]);
                                    pr = void 0;
                                    _a.label = 2;
                                case 2:
                                    _a.trys.push([2, 4, , 5]);
                                    return [4 /*yield*/, this.modalBuilderFactory.showSelectorAsync(professional, ['partyName'], ' ', config.role.name)];
                                case 3:
                                    pr = _a.sent();
                                    return [3 /*break*/, 5];
                                case 4:
                                    e_2 = _a.sent();
                                    return [2 /*return*/];
                                case 5: return [4 /*yield*/, this.partyApiSvc.getPersonsAsync([pr.partyRoleId])];
                                case 6:
                                    r = _a.sent();
                                    person = CareRequest.RequestPerson.createFromPerson(r[0]);
                                    careRequestRole_1 = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, person);
                                    careRequestRole_1.partyRoleId = pr.partyRoleId;
                                    if (_.any(this.grid.getData(), function (x) {
                                        return x.partyRoleId === careRequestRole_1.partyRoleId && x.roleId === careRequestRole_1.roleId;
                                    })) {
                                        this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                                        return [2 /*return*/];
                                    }
                                    this.grid.addRow(careRequestRole_1);
                                    return [3 /*break*/, 8];
                                case 7:
                                    e_3 = _a.sent();
                                    this.toaster.error(e_3);
                                    return [3 /*break*/, 8];
                                case 8: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.showOrganisationSelectorAsync = function (config, organisationIds) {
                    return __awaiter(this, void 0, void 0, function () {
                        var existingPartyRoleIds, r, _a, org, e_4, organisation, careRequestRole_2, e_5;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    existingPartyRoleIds = this.getExistingPartyRoleIds(config);
                                    organisationIds = _(organisationIds).filter(function (o) { return !_(existingPartyRoleIds).contains(o); });
                                    _b.label = 1;
                                case 1:
                                    _b.trys.push([1, 9, , 10]);
                                    if (!(organisationIds.length === 0)) return [3 /*break*/, 2];
                                    _a = [];
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync(organisationIds)];
                                case 3:
                                    _a = _b.sent();
                                    _b.label = 4;
                                case 4:
                                    r = _a;
                                    org = void 0;
                                    _b.label = 5;
                                case 5:
                                    _b.trys.push([5, 7, , 8]);
                                    return [4 /*yield*/, this.modalBuilderFactory.showSelectorAsync(r, ['Name'], '', config.role.name)];
                                case 6:
                                    org = _b.sent();
                                    return [3 /*break*/, 8];
                                case 7:
                                    e_4 = _b.sent();
                                    return [2 /*return*/];
                                case 8:
                                    organisation = CareRequest.RequestOrganisation.createFromHealthCareOrganisation(org);
                                    careRequestRole_2 = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, organisation);
                                    careRequestRole_2.partyRoleId = org.PartyRoleId;
                                    if (_.any(this.grid.getData(), function (x) {
                                        return x.partyRoleId === careRequestRole_2.partyRoleId && x.roleId === careRequestRole_2.roleId;
                                    })) {
                                        this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                                        return [2 /*return*/];
                                    }
                                    this.grid.addRow(careRequestRole_2);
                                    return [3 /*break*/, 10];
                                case 9:
                                    e_5 = _b.sent();
                                    this.toaster.error(e_5);
                                    return [3 /*break*/, 10];
                                case 10: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.showProfessionalSearchModal = function (config) {
                    var _this = this;
                    var specialties = this.convertToTypeAndSpecialties(this.getActorRoleInclusionDetails(config));
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('searchHealthCareProfessionalPartyCtrl')
                        .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                        .setScope({
                        title: config.role.name,
                        typeAndSpecialties: specialties,
                    })
                        .setResultCallBack(function (r) {
                        var party = CareRequest.RequestPerson.createFromHealthCareProfessional(r);
                        var careRequestRole = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, party);
                        careRequestRole.partyRoleId = r.PartyRoleId;
                        if (_.any(_this.grid.getData(), function (x) {
                            return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                        })) {
                            _this.toaster.error(_this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                            return;
                        }
                        _this.grid.addRow(careRequestRole);
                    })
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .build();
                };
                CareRequestRolesController.prototype.showOrganisationSearchModal = function (config) {
                    var _this = this;
                    var orgTypes = this.getActorRoleInclusionDetails(config)
                        .map(function (arid) { return arid.healthCareOrganisationTypeId; })
                        .filter(function (id) { return id != null; })
                        .value();
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('searchHealthCareOrganisationPartyCtrl')
                        .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                        .setScope({
                        title: config.role.name,
                        organisationTypes: orgTypes,
                    })
                        .setResultCallBack(function (r) {
                        var party = CareRequest.RequestOrganisation.createFromHealthCareOrganisation(r);
                        var careRequestRole = CareRequest.CareRequestRole.createForParty(config.role.actorRoleId, party);
                        careRequestRole.partyRoleId = r.PartyRoleId;
                        if (_.any(_this.grid.getData(), function (x) {
                            return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                        })) {
                            _this.toaster.error(_this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                            return;
                        }
                        _this.grid.addRow(careRequestRole);
                    })
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .build();
                };
                CareRequestRolesController.prototype.editCareRequestRoleAsync = function (careRequestRole) {
                    var config = _.find(this.careRequestPart.careRequestRoleConfigurations, function (c) { return c.role.actorRoleId === careRequestRole.roleId; });
                    return this.showFreeTextModalAsync(config, careRequestRole);
                };
                CareRequestRolesController.prototype.addCareRequestRoleAsync = function (config) {
                    return __awaiter(this, void 0, void 0, function () {
                        var careRequestRole, r_1, e_6;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    careRequestRole = CareRequest.CareRequestRole.create(config.role.actorRoleId, RemeCare.Shared.Contract.Code.PartyType.Person);
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, this.showFreeTextModalAsync(config, careRequestRole)];
                                case 2:
                                    r_1 = _a.sent();
                                    if (_.any(this.grid.getData(), function (x) {
                                        return r_1.partyRoleId && x.partyRoleId === r_1.partyRoleId && x.roleId === r_1.roleId;
                                    })) {
                                        this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                                        return [2 /*return*/];
                                    }
                                    this.grid.addRow(r_1);
                                    return [3 /*break*/, 4];
                                case 3:
                                    e_6 = _a.sent();
                                    this.toaster.error(e_6);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestRolesController.prototype.showFreeTextModalAsync = function (config, careRequestRole) {
                    var _this = this;
                    return new Promise(function (resolve, reject) {
                        var specialties = _this.convertToTypeAndSpecialties(_this.getActorRoleInclusionDetails(config));
                        _this.modalBuilderFactory
                            .createModalBuilder()
                            .setController('careRequestRoleCtrl')
                            .setTemplateUrl('views/careRequest/directive/careRequestRole.html')
                            .setScope({
                            typeAndSpecialties: specialties,
                            configuration: config,
                            careRequestPart: _this.careRequestPart,
                            careRequestRole: angular.copy(careRequestRole),
                            title: config.role.name,
                            inAcceptance: _this.stepType === RemeCare.Shared.Contract.Code.CareRequestStepType.Acceptance,
                        })
                            .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                            .setResultCallBack(function (r) { return resolve(r); })
                            .build();
                    });
                };
                CareRequestRolesController.prototype.getCareRequestorPart = function () {
                    return _.find(this.careRequestTemplate.careRequestSetup.careRequestParts, function (p) { return p.type.Id === RemeCare.Shared.Contract.Code.CareRequestPartType.CareRequestor; });
                };
                CareRequestRolesController.prototype.getMinimumNumber = function (role) {
                    return this.getBoundary(role, role.minimalNumber);
                };
                CareRequestRolesController.prototype.getMaximalNumber = function (role) {
                    return this.getBoundary(role, role.maximalNumber);
                };
                CareRequestRolesController.prototype.getBoundary = function (role, normalBoundary) {
                    var careRequestorPart = this.getCareRequestorPart();
                    if (careRequestorPart == null) {
                        return normalBoundary;
                    }
                    if (careRequestorPart.roleRequestingHealthCareProfessional != null &&
                        careRequestorPart.roleRequestingHealthCareProfessional.actorRoleId === role.actorRoleId &&
                        this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional, careRequestorPart)) {
                        // If this is the same professional role as the one requested in the care request part, lower the boundary
                        if (normalBoundary > 0) {
                            return normalBoundary - 1;
                        }
                        return normalBoundary;
                    }
                    if (careRequestorPart.roleRequestingHealthCareOrganisation != null &&
                        careRequestorPart.roleRequestingHealthCareOrganisation.actorRoleId === role.actorRoleId &&
                        this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation, careRequestorPart)) {
                        // If this is the same organisation role as the one requested in the care request part, lower the boundary
                        if (normalBoundary > 0) {
                            return normalBoundary - 1;
                        }
                        return normalBoundary;
                    }
                    return normalBoundary;
                };
                CareRequestRolesController.prototype.isRequestorRole = function (role) {
                    var requestorPart = this.getCareRequestorPart();
                    if (requestorPart == null) {
                        return false;
                    }
                    return ((requestorPart.roleRequestingHealthCareProfessional != null &&
                        requestorPart.roleRequestingHealthCareProfessional.actorRoleId === role.actorRoleId &&
                        this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional, requestorPart)) ||
                        (requestorPart.roleRequestingHealthCareOrganisation != null &&
                            requestorPart.roleRequestingHealthCareOrganisation.actorRoleId === role.actorRoleId &&
                            this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation, requestorPart)));
                };
                CareRequestRolesController.prototype.map = function (config) {
                    var result = config;
                    result.possibleGroupAssignments = [];
                    return result;
                };
                return CareRequestRolesController;
            }(Directive.CareRequestPartComponentControllerBase));
            CareRequest.remeCareCareRequestModule.component('rcCareRequestRoles', {
                controller: CareRequestRolesController,
                bindings: {
                    careRequestTemplate: '=',
                    careRequestPart: '=',
                    careRequest: '=',
                    showErrors: '=',
                    stepType: '=',
                    readOnly: '=',
                    onMatchedPatientChanged: '&',
                },
                templateUrl: 'views/careRequest/directive/careRequestRoles.html',
            });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
