var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestPartType = RemeCare.Shared.Contract.Code.CareRequestPartType;
        var MissingAutoCreateUserFields;
        (function (MissingAutoCreateUserFields) {
            MissingAutoCreateUserFields[MissingAutoCreateUserFields["Any"] = 0] = "Any";
            MissingAutoCreateUserFields[MissingAutoCreateUserFields["BirthDate"] = 1] = "BirthDate";
            MissingAutoCreateUserFields[MissingAutoCreateUserFields["Email"] = 2] = "Email";
        })(MissingAutoCreateUserFields || (MissingAutoCreateUserFields = {}));
        var CareRequestController = /** @class */ (function () {
            function CareRequestController($timeout, $translate, appConfig, toaster, therapyApiSvc, careRequestSvc, $state, partyApiSvc, patientSvc, patientFileForExternalSvc, spinnerSvc, $dialog, validationSvc) {
                this.$timeout = $timeout;
                this.$translate = $translate;
                this.appConfig = appConfig;
                this.toaster = toaster;
                this.therapyApiSvc = therapyApiSvc;
                this.careRequestSvc = careRequestSvc;
                this.$state = $state;
                this.partyApiSvc = partyApiSvc;
                this.patientSvc = patientSvc;
                this.patientFileForExternalSvc = patientFileForExternalSvc;
                this.spinnerSvc = spinnerSvc;
                this.$dialog = $dialog;
                this.validationSvc = validationSvc;
                this.missingFieldsForAutoCreateUser = false;
                this.missingBirthDateForAutoCreateUser = false;
                this.missingEmailForAutoCreateUser = false;
                this.validateNationalNumber = false;
                this.externalPatientDataUsed = false;
                this.externalPatientDataUsedTelemonitoring = false;
            }
            CareRequestController.prototype.$onInit = function () {
                this.therapyId = this.$transition$.params().therapyId;
                this.loadDataAsync();
                this.patchPersonInfo = new CareRequest.PatchPersonInfo();
            };
            CareRequestController.prototype.retrieveMatchedPatient = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, _b, _c, ags, e_1;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _a = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.Any)];
                            case 1:
                                _a.missingFieldsForAutoCreateUser = _d.sent();
                                _b = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.BirthDate)];
                            case 2:
                                _b.missingBirthDateForAutoCreateUser = _d.sent();
                                _c = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.Email)];
                            case 3:
                                _c.missingEmailForAutoCreateUser = _d.sent();
                                if (!(this.careRequest.matchedPatientId != null)) return [3 /*break*/, 8];
                                _d.label = 4;
                            case 4:
                                _d.trys.push([4, 6, , 7]);
                                return [4 /*yield*/, this.patientSvc.getPatientNameAsync(this.careRequest.matchedPatientId)];
                            case 5:
                                ags = _d.sent();
                                this.patient = {
                                    firstName: _(ags).first().FirstName,
                                    id: _(ags).first().Id,
                                    lastName: _(ags).first().LastName,
                                };
                                this.getPatientCarePlansAsync(this.careRequest.matchedPatientId);
                                return [3 /*break*/, 7];
                            case 6:
                                e_1 = _d.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 7];
                            case 7: return [3 /*break*/, 9];
                            case 8:
                                this.carePlanExists = false;
                                this.addForbidden = false;
                                _d.label = 9;
                            case 9: return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestController.prototype._missingFieldsForAutoCreateUser = function (fields) {
                if (fields === void 0) { fields = MissingAutoCreateUserFields.Any; }
                return __awaiter(this, void 0, void 0, function () {
                    var person;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!this.careRequestTemplate || !this.careRequestTemplate.careRequestSetup) {
                                    return [2 /*return*/, false];
                                }
                                // Only when flag autoCreateUserForPatient is set in configuration
                                if (!this.careRequestTemplate.careRequestSetup.autoCreateUserForPatient) {
                                    return [2 /*return*/, false];
                                }
                                if (!(this.careRequest.matchedPartyId != null)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.partyApiSvc.getPersonAsync(this.careRequest.matchedPartyId)];
                            case 1:
                                person = _a.sent();
                                this.dateOfBirth = person.DateOfBirth;
                                this.emailAddress = person.Email;
                                return [3 /*break*/, 5];
                            case 2:
                                if (!(this.careRequest.matchedPatientId != null)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.patientSvc
                                        .getPatient(this.careRequest.matchedPatientId)
                                        .success(function (p) {
                                        _this.dateOfBirth = p.DateOfBirth;
                                        _this.emailAddress = p.EmailAddresses[0].Email;
                                    })
                                        .catch(function (e) {
                                        _this.toaster.error(e);
                                        throw e;
                                    })];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 4: return [2 /*return*/, false];
                            case 5:
                                if ((fields === MissingAutoCreateUserFields.Any || fields === MissingAutoCreateUserFields.Email) &&
                                    (!this.emailAddress || !this.validationSvc.verifyEmail(this.emailAddress))) {
                                    return [2 /*return*/, true];
                                }
                                if ((fields === MissingAutoCreateUserFields.Any || fields === MissingAutoCreateUserFields.BirthDate) &&
                                    !this.dateOfBirth) {
                                    return [2 /*return*/, true];
                                }
                                return [2 /*return*/, false];
                        }
                    });
                });
            };
            CareRequestController.prototype.savePersonInfo = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (!this.missingFieldsForAutoCreateUser) {
                            return [2 /*return*/, Promise.resolve({})];
                        }
                        return [2 /*return*/, this.partyApiSvc.patchPersonInfo(this.careRequest.matchedPartyId, {
                                partyId: this.careRequest.matchedPartyId,
                                birthDate: RemeCare.Shared.DateHelper.toServerDateString(this.patchPersonInfo.birthDate),
                                email: this.patchPersonInfo.email,
                            })];
                    });
                });
            };
            CareRequestController.prototype.saveCareRequest = function (permanent) {
                var _this = this;
                this.validateNationalNumber = permanent;
                this.$timeout(function () { return __awaiter(_this, void 0, void 0, function () {
                    var careRequest, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (permanent) {
                                    this.form.$setSubmitted();
                                    if (this.form.$invalid) {
                                        this.form.showValidationErrorMessage();
                                        return [2 /*return*/];
                                    }
                                }
                                this.filterOutInActiveValues();
                                this.filterOutInvisibleValues();
                                careRequest = this.careRequest.toServerWrite(permanent, this.comorbidities, this.treatedDiseases);
                                this.spinnerSvc.show('loader');
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, 4, 5]);
                                return [4 /*yield*/, this.savePersonInfo()
                                        .then(function () { return __awaiter(_this, void 0, void 0, function () {
                                        var followUpMessage;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this.careRequestSvc.saveCareRequestAsync(careRequest)];
                                                case 1:
                                                    _a.sent();
                                                    if (this.careRequestTemplate.careRequestStepType.Id ===
                                                        RemeCare.Shared.Contract.Code.CareRequestStepType.Application &&
                                                        permanent) {
                                                        followUpMessage = this.$translate.instant('Views.CareRequest.Details.SavedFollowUp', {
                                                            remedusInfoMail: "<a href=\"mailto:" + this.appConfig.remedusInfoMail + "\">" + this.appConfig.remedusInfoMail + "</a>",
                                                            remedusPhone: "<span class=\"text-nowrap\">" + this.appConfig.remedusPhone + "</span>",
                                                        });
                                                        this.toaster.success(this.$translate.instant('Views.CareRequest.Details.Saved'), followUpMessage, true);
                                                    }
                                                    else {
                                                        this.toaster.success(this.$translate.instant('Views.CareRequest.Details.SavedLong'));
                                                    }
                                                    this.goBack(permanent);
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); })
                                        .catch(function (e) { return _this.toaster.error(e); })];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 5];
                            case 4:
                                this.spinnerSvc.hide('loader');
                                return [7 /*endfinally*/];
                            case 5: return [2 /*return*/];
                        }
                    });
                }); });
            };
            CareRequestController.prototype.cancelCareRequest = function () {
                var _this = this;
                this.$dialog.confirmBox('Views.CareRequest.Details.Cancel.Title', 'Views.CareRequest.Details.Cancel.Text', function () { return __awaiter(_this, void 0, void 0, function () {
                    var e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, 3, 4]);
                                this.spinnerSvc.show('loader');
                                return [4 /*yield*/, this.careRequestSvc.cancelCareRequestAsync(this.careRequest.id)];
                            case 1:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.CareRequest.Details.CareRequestCancelled'));
                                this.$state.go('careRequests');
                                return [3 /*break*/, 4];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 4];
                            case 3:
                                this.spinnerSvc.hide('loader');
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
            };
            CareRequestController.prototype.filterOutInvisibleValues = function () {
                var _this = this;
                // filter out invisible fields due to conditionality
                this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach(function (part) {
                    if (!_this.careRequestSvc.isVisibleWithConditionality(part, _this.careRequestTemplate, _this.careRequest)) {
                        _this.deleteInvisibleConditionalValues(part);
                    }
                });
            };
            CareRequestController.prototype.deleteInvisibleConditionalValues = function (careRequestPart) {
                switch (careRequestPart.type.Id) {
                    case CareRequestPartType.Patient:
                        this.careRequest.patient = null;
                        break;
                    case CareRequestPartType.CareRequestor:
                        this.careRequest.careRequestor = null;
                        break;
                    case CareRequestPartType.CareRequestRole:
                        this.careRequest.careRequestRoles = [];
                        break;
                    case (CareRequestPartType.QuantitativeAnamnesis, CareRequestPartType.QualitativeAnamnesis):
                        var filteredAnamneses = this.careRequest.anamneses.filter(function (a) { return a.careRequestPartLocalIdentity !== careRequestPart.localIdentity; });
                        this.careRequest.anamneses = filteredAnamneses;
                        break;
                    case CareRequestPartType.TherapyOption:
                        var filteredOptions = this.careRequest.therapyOptions.filter(function (to) { return to.careRequestPartLocalIdentity !== careRequestPart.localIdentity; });
                        this.careRequest.therapyOptions = filteredOptions;
                        break;
                    case CareRequestPartType.Date:
                        var filteredDates = this.careRequest.careRequestDates.filter(function (d) { return d.careRequestPartLocalIdentity !== careRequestPart.localIdentity; });
                        this.careRequest.careRequestDates = filteredDates;
                        break;
                    case CareRequestPartType.String:
                        var filteredStrings = this.careRequest.careRequestStrings.filter(function (s) { return s.careRequestPartLocalIdentity !== careRequestPart.localIdentity; });
                        this.careRequest.careRequestStrings = filteredStrings;
                        break;
                    case CareRequestPartType.RequestDocument:
                        var filteredDocs = this.careRequest.careRequestDocuments.filter(function (d) { return d.id !== careRequestPart.id; });
                        this.careRequest.careRequestDocuments = filteredDocs;
                        break;
                    case CareRequestPartType.Hospitalisation:
                        var filteredHospi = this.careRequest.hospitalisations.filter(function (h) { return h.id !== careRequestPart.id; });
                        this.careRequest.hospitalisations = filteredHospi;
                        break;
                    case CareRequestPartType.Consultation:
                        var filteredConsult = this.careRequest.consultations.filter(function (c) { return c.id !== careRequestPart.id; });
                        this.careRequest.consultations = filteredConsult;
                        break;
                    case (CareRequestPartType.MedicationSchemaTherapyAction, CareRequestPartType.MedicationSchemaOther):
                        var filteredMedic = this.careRequest.careRequestMedications.filter(function (m) { return m.id !== careRequestPart.id; });
                        this.careRequest.careRequestMedications = filteredMedic;
                        break;
                    case CareRequestPartType.Comorbidities:
                        var filteredComor = this.careRequest.comorbidities.filter(function (c) { return c !== careRequestPart.localIdentity; });
                        this.careRequest.comorbidities = filteredComor;
                        break;
                    case CareRequestPartType.TreatedDiseases:
                        var filteredTreatedDis = this.careRequest.treatedDiseases.filter(function (t) { return t !== careRequestPart.localIdentity; });
                        this.careRequest.treatedDiseases = filteredTreatedDis;
                        break;
                }
            };
            CareRequestController.prototype.filterOutInActiveValues = function () {
                var _this = this;
                this.careRequest.anamneses = _(this.careRequest.anamneses).filter(function (a) {
                    return _(_(_this.careRequestTemplate.careRequestSetup.careRequestParts).map(function (p) { return p.localIdentity; })).contains(a.careRequestPartLocalIdentity);
                });
            };
            CareRequestController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var careRequestId, _a, comorbidities, treatedDiseases, _b, careRequestTemplate, careRequest, careRequestTemplate, patientFileForExternal, e_4;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                careRequestId = this.$transition$.params().careRequestId;
                                _c.label = 1;
                            case 1:
                                _c.trys.push([1, 11, , 12]);
                                return [4 /*yield*/, Promise.all([
                                        this.therapyApiSvc.getTherapyDiseasesAsync(this.therapyId, RemeCare.Shared.Contract.Code.DiseaseType.Comorbidity),
                                        this.therapyApiSvc.getTherapyDiseasesAsync(this.therapyId, RemeCare.Shared.Contract.Code.DiseaseType.TreatedDisease),
                                    ])];
                            case 2:
                                _a = _c.sent(), comorbidities = _a[0], treatedDiseases = _a[1];
                                this.comorbidities = comorbidities;
                                this.treatedDiseases = treatedDiseases;
                                if (!careRequestId) return [3 /*break*/, 5];
                                return [4 /*yield*/, Promise.all([
                                        this.therapyApiSvc.getCareRequestTemplateAsync(this.therapyId, careRequestId),
                                        this.careRequestSvc.getCareRequestAsync(careRequestId),
                                    ])];
                            case 3:
                                _b = _c.sent(), careRequestTemplate = _b[0], careRequest = _b[1];
                                return [4 /*yield*/, this.prepareCareRequestAsync(careRequestTemplate, careRequest)];
                            case 4:
                                _c.sent();
                                return [3 /*break*/, 8];
                            case 5: return [4 /*yield*/, this.therapyApiSvc.getCareRequestTemplateAsync(this.therapyId)];
                            case 6:
                                careRequestTemplate = _c.sent();
                                return [4 /*yield*/, this.prepareCareRequestAsync(careRequestTemplate)];
                            case 7:
                                _c.sent();
                                _c.label = 8;
                            case 8:
                                this.careRequest.isModified = !this.careRequestTemplate.careRequestSetup
                                    .isLatestValidCareRequestSetupVersion;
                                this.checkInvalidConfig();
                                if (!(!this.careRequest.isReadOnly() && !this.careRequest.id)) return [3 /*break*/, 10];
                                return [4 /*yield*/, this.patientFileForExternalSvc.getLastPatientFileForExternalAsync()];
                            case 9:
                                patientFileForExternal = _c.sent();
                                if (patientFileForExternal.length === 1) {
                                    this.useExternalPatientData(new CareRequest.PatientFileForExternal(patientFileForExternal[0]));
                                }
                                _c.label = 10;
                            case 10: return [3 /*break*/, 12];
                            case 11:
                                e_4 = _c.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 12];
                            case 12: return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestController.prototype.checkInvalidConfig = function () {
                var _this = this;
                var goToIds = Array();
                var partIds = Array();
                this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach(function (part) {
                    partIds.push(part.id);
                    if (part.answerConditions.length > 0) {
                        part.answerConditions.forEach(function (ac) {
                            goToIds.push(ac.goToCareRequestPartId);
                        });
                    }
                });
                goToIds.forEach(function (goToId) {
                    if (partIds.indexOf(goToId) === -1) {
                        _this.careRequest.isModified = true;
                    }
                });
            };
            CareRequestController.prototype.getPatientCarePlansAsync = function (patientId) {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, existingCarePlans, resource, e_5;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getCarePlansAsync(patientId)];
                            case 1:
                                ags = _a.sent();
                                existingCarePlans = _(ags).filter(function (x) {
                                    return x.TherapyId === _this.careRequest.therapyId &&
                                        (_this.careRequest.id == null || _this.careRequest.id !== x.Id);
                                });
                                if (existingCarePlans != null && _(existingCarePlans).size() !== 0) {
                                    this.carePlanExists = true;
                                    resource = void 0;
                                    if (_(existingCarePlans).any(function (cp) { return cp.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open; })) {
                                        resource = 'Views.CareRequest.Details.DuplicateCarePlanActive';
                                        this.addForbidden = true;
                                    }
                                    else if (_(existingCarePlans).any(function (cp) { return cp.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.OnHold; })) {
                                        resource = 'Views.CareRequest.Details.DuplicateCarePlanOnHold';
                                        this.addForbidden = true;
                                    }
                                    else {
                                        resource = 'Views.CareRequest.Details.DuplicateCarePlanStopped';
                                    }
                                    this.errorMessage = this.$translate.instant(resource, {
                                        therapyName: _(existingCarePlans).first().Name,
                                        patientName: this.patient.lastName + ' ' + this.patient.firstName,
                                    });
                                }
                                else {
                                    this.carePlanExists = false;
                                    this.addForbidden = false;
                                    this.errorMessage = '';
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestController.prototype.prepareCareRequestAsync = function (careRequestTemplate, careRequest) {
                return __awaiter(this, void 0, void 0, function () {
                    var existingPatient, _a, _b, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                this.careRequestTemplate = new CareRequest.CareRequestTemplate(careRequestTemplate);
                                this.careRequest = new CareRequest.CareRequest(careRequest, careRequestTemplate, this.comorbidities, this.treatedDiseases);
                                if (careRequest == null) {
                                    this.careRequest.stepSequence = this.careRequestTemplate.careRequestStepSequence;
                                    this.careRequest.stepType = this.careRequestTemplate.careRequestStepType;
                                    this.careRequest.therapyId = this.therapyId;
                                }
                                existingPatient = this.$transition$.params().existingPatient;
                                if (!(existingPatient != null)) return [3 /*break*/, 4];
                                this.careRequest.matchedPatientId = existingPatient;
                                this.careRequest.patient = null;
                                _a = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.Any)];
                            case 1:
                                _a.missingFieldsForAutoCreateUser = _d.sent();
                                _b = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.BirthDate)];
                            case 2:
                                _b.missingBirthDateForAutoCreateUser = _d.sent();
                                _c = this;
                                return [4 /*yield*/, this._missingFieldsForAutoCreateUser(MissingAutoCreateUserFields.Email)];
                            case 3:
                                _c.missingEmailForAutoCreateUser = _d.sent();
                                _d.label = 4;
                            case 4:
                                this.enrichCareRequestRolesAsync();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestController.prototype.enrichCareRequestRolesAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var partyRoleIds, query, r_1, e_6;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                partyRoleIds = _(this.careRequest.careRequestRoles)
                                    .select(function (x) { return x.partyRoleId != null; })
                                    .map(function (x) { return x.partyRoleId; });
                                if (partyRoleIds.length === 0) {
                                    return [2 /*return*/];
                                }
                                query = {
                                    partyRoleIds: partyRoleIds,
                                    page: 1,
                                    pageSize: partyRoleIds.length,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.findPersonsAsync(query)];
                            case 2:
                                r_1 = _a.sent();
                                _(this.careRequest.careRequestRoles).each(function (crr) {
                                    if (crr.partyRoleId == null) {
                                        return;
                                    }
                                    crr.matchedParty = CareRequest.RequestPerson.createFromPerson(_.find(r_1.Items, function (x) {
                                        return _(x.PartyRoles).any(function (pr) { return pr.Id === crr.partyRoleId; });
                                    }));
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                e_6 = _a.sent();
                                this.toaster.error(e_6);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestController.prototype.goBack = function (savedCareRequest) {
                var referringState = this.$transition$.params().referringState;
                if (referringState) {
                    this.$state.go(referringState.name, referringState.params);
                }
                else {
                    this.$state.go('careRequests.search', {
                        savedCareRequest: savedCareRequest,
                    });
                }
            };
            CareRequestController.prototype.useExternalPatientData = function (patientFileForExternal) {
                var _this = this;
                // Timeout to allow components to be ready to pick up changes.
                // If brave enough to remove, retest PF-3139
                this.$timeout(function () {
                    var telemonitoringId = _(patientFileForExternal.externalReferences).filter(function (r) { return r.System === 'TMPortal' && r.Attribute === 'telemonitoringId'; });
                    if (telemonitoringId && telemonitoringId.length) {
                        _this.externalPatientDataUsedTelemonitoring = true;
                    }
                    else {
                        _this.externalPatientDataUsed = true;
                    }
                    _this.careRequest.patient.patientFileForExternalRequestId = patientFileForExternal.id;
                    _this.careRequest.patient.firstName = (patientFileForExternal.patientParameters.FirstName ||
                        '') + " " + (patientFileForExternal.patientParameters.SecondName || '');
                    _this.careRequest.patient.lastName = patientFileForExternal.patientParameters.LastName;
                    _this.careRequest.patient.birthDate = patientFileForExternal.patientParameters.BirthdateDate;
                    _this.careRequest.patient.externalReferenceNumber = patientFileForExternal.patientParameters.ExtRef;
                    _this.careRequest.patient.nationality = patientFileForExternal.patientParameters.Nationality;
                    _this.careRequest.patient.gender = patientFileForExternal.patientParameters.NumericGenderCode;
                    _this.careRequest.patient.identificationNumber = patientFileForExternal.patientParameters.CitizenId;
                    _this.careRequest.patient.communicationLanguage =
                        patientFileForExternal.patientParameters.PreferredLanguage;
                    for (var _i = 0, _a = patientFileForExternal.patientParameters.ExternalReferences; _i < _a.length; _i++) {
                        var ref = _a[_i];
                        _this.careRequest.patient.externalReferences.push(ref);
                    }
                    _this.careRequest.patient.contactInfo.email = patientFileForExternal.patientParameters.Email;
                    _this.careRequest.patient.contactInfo.telephone = patientFileForExternal.patientParameters.PhoneParsed;
                    _this.careRequest.patient.contactInfo.mobile = patientFileForExternal.patientParameters.GsmParsed;
                    _this.careRequest.patient.contactInfo.address.addressLine1 =
                        patientFileForExternal.patientParameters.Address;
                    _this.careRequest.patient.contactInfo.address.city = patientFileForExternal.patientParameters.City;
                    _this.careRequest.patient.contactInfo.address.zipCode = patientFileForExternal.patientParameters.Zipcode;
                    _this.careRequest.patient.contactInfo.address.country =
                        patientFileForExternal.patientParameters.NumericCountryCode;
                    for (var _b = 0, _c = patientFileForExternal.externalReferences; _b < _c.length; _b++) {
                        var ref = _c[_b];
                        _this.careRequest.externalReferences.push(ref);
                    }
                }, 100);
            };
            return CareRequestController;
        }());
        CareRequest.remeCareCareRequestModule.component('rcCareRequest', {
            bindings: {
                $transition$: '<',
            },
            controller: CareRequestController,
            templateUrl: 'views/careRequest/careRequest.html',
        });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
