/// <reference path="componentDirectiveBase.ts" />

namespace RemeCare.CarePlanAction {
    interface IAberrantRegistrationsScope extends IComponentScope {
        component: AberrantRegistrationsDescriptor;
        aberrantRegistrationsGrid: Shared.Framework.Grid.Grid<Contract.CarePlanAction.Read.IAberrantRegistration>;
    }

    class AberrantRegistrationController extends ComponentControllerBase<IAberrantRegistrationsScope> {
        // @ngInject
        constructor(
            public readonly $scope: IAberrantRegistrationsScope,
            public readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly toaster: Shared.Framework.Toaster,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected async init(): Promise<void> {
            try {
                this.$scope.aberrantRegistrationsGrid = this.gridBuilderSvc
                    .createGridBuilder<Contract.CarePlanAction.Read.IAberrantRegistration>()
                    .addColumn('Name', 'General.Name')
                    .addDateColumn('Date', 'General.Date', 'shortDate')
                    .addColumn('Value', 'Views.Action.AberrantRegistrationValues')
                    .build();
                if (this.$scope.component.aberrantRegistrationValueIds.length > 0) {
                    let registrations = await this.carePlanActionApiSvc.getAberrantRegistrationInformation(
                        this.$scope.component.aberrantRegistrationValueIds
                    );
                    registrations = _.sortBy(registrations, (r) => r.Date).reverse();
                    this.$scope.aberrantRegistrationsGrid.setData(registrations);
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    class AberrantRegistrationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/aberrantRegistrations.html';
        public controller = AberrantRegistrationController;
    }

    remeCareCarePlanActionModule.directive('rcAberrantRegistrations', () => new AberrantRegistrationDirective());
}
