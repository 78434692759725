namespace RemeCare.IntegrationMonitor {

    import IIntegrationMessageFilter = RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageFilter;
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;
    import IIntegrationMessageSummary = RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageSummary;

    export interface IIntegrationMessageStateParams {
        messageId: string;
    }

    class IntegrationMessageController implements ng.IComponentController {
        public messageId: string;
        public message: Model.IntegrationMessage;
        public tabs: Array<Shared.Contract.ITab>;
        public isRetrying: boolean = false;
        public isArchiving: boolean = false;
        public isUnarchiving: boolean = false;
        private knownFiltersAndMessageIds: IIntegrationMessageFilter;
        private nextPageMessageIds: string[];
        private previousPageMessageIds: string[];
        private nextMessageId: string;
        private previousMessageId: string;
        private previousHasNextPage: boolean;
        private nextHasNextPage: boolean;
        private isFirstMessageOfPage: boolean = false;
        private isLastMessageOfPage: boolean = false;

        constructor(
            private readonly $stateParams: IIntegrationMessageStateParams,
            private readonly $state: ng.ui.IStateService,
            protected $translate: ng.translate.ITranslateService,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly integrationMessageSvc: IntegrationMonitor.IntegrationMessageService,
            private readonly integrationMessageSearchSvc: IntegrationMonitor.IntegrationMessageSearchService,
            private readonly toaster: Shared.Framework.Toaster
        ) {
        }

        public async $onInit(): Promise<void> {
            this.messageId = this.$stateParams.messageId;
            this.knownFiltersAndMessageIds = this.storageSvc.get<IIntegrationMessageFilter>(StorageServiceConstants.integrationMonitorKey);
            await this.setNextAndPreviousMessageIds();
            this.initTabs();
            try {
                await this.getIntegrationMessageDetailAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public goBack(): void {
            this.$state.go('integrationMonitor.search');
        }

        public goToNextMessage(): void {
            if (this.isLastMessageOfPage) {
                this.saveFiltersAndMessageIdsToStorage(this.getNextPageFilters(), this.nextPageMessageIds, this.nextHasNextPage);
            }
            this.$state.go('integrationMonitor.message', { messageId: this.nextMessageId })
        }

        public goToPreviousMessage(): void {
            if (this.isFirstMessageOfPage) {
                this.saveFiltersAndMessageIdsToStorage(this.getPreviousPageFilters(), this.previousPageMessageIds, this.previousHasNextPage);
            }
            this.$state.go('integrationMonitor.message', { messageId: this.previousMessageId })
        }

        public async retryMessage(): Promise<void> {
            this.isRetrying = true;
            this.message.isRetryable = false; // user will have to refresh themselves 
            this.message.isArchiveable = false;
            this.message.isFailed = false;
            this.message.statusTranslation = this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.InProgressStatus')
            try {
                await this.integrationMessageSvc.retryIntegrationMessageAsync(this.messageId);
                setTimeout(async () => {
                    try {
                        await this.getIntegrationMessageDetailAsync();
                        this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyRetried'));
                    } catch (e) {
                        this.toaster.error(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyRetriedButNotYetAvailableForRefresh'));
                    }
                }, 2000);
            } catch (e) {
                this.toaster.error(e);
            }
            this.isRetrying = false;
        }

        public async archiveMessage(): Promise<void> {
            this.isArchiving = true;
            try {
                await this.integrationMessageSvc.archiveIntegrationMessageAsync(this.messageId);
                await this.getIntegrationMessageDetailAsync();
                this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyArchived'));
            } catch (e) {
                this.toaster.error(e);
            }
            this.isArchiving = false;
        }

        public async unarchiveMessage(): Promise<void> {
            this.isUnarchiving = true;
            try {
                await this.integrationMessageSvc.unarchiveIntegrationMessageAsync(this.messageId);
                await this.getIntegrationMessageDetailAsync();
                this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyUnarchived'));
            } catch (e) {
                this.toaster.error(e);
            }
            this.isUnarchiving = false;
        }

        private initTabs(): void {
            this.tabs = [{
                heading: this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.Message'),
                src: 'views/integrationMonitor/integrationMessageInformation.html',
                active: true
            }, {
                heading: this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.TechnicalInformation'),
                src: 'views/integrationMonitor/integrationMessageTechnicalInformation.html',
                active: false
            }];
        }

        private async getIntegrationMessageDetailAsync(): Promise<void> {
            const result = await this.integrationMessageSvc.getIntegrationMessageDetailAsync(this.messageId);
            this.message = new Model.IntegrationMessage(result);

        }

        private async setNextAndPreviousMessageIds(): Promise<void> {
            const messageIds = this.knownFiltersAndMessageIds.MessageIds;
            const indexOfMessageId = messageIds.indexOf(this.messageId);
            this.isLastMessageOfPage = indexOfMessageId === messageIds.length - 1;
            this.isFirstMessageOfPage = indexOfMessageId === 0;
            if (this.isLastMessageOfPage && this.isFirstMessageOfPage) {
                this.nextMessageId = null;
                await this.setPreviousMessageParametersFromPreviousPage();
            }
            else if (this.isLastMessageOfPage) {
                this.previousMessageId = messageIds[messageIds.length - 2];
                await this.setNextMessageParametersFromNextPage();
            }
            else if (this.isFirstMessageOfPage) {
                this.nextMessageId = messageIds[1];
                await this.setPreviousMessageParametersFromPreviousPage();
            } else {
                this.nextMessageId = messageIds[indexOfMessageId + 1];
                this.previousMessageId = messageIds[indexOfMessageId - 1]
            }
        }


        private async setNextMessageParametersFromNextPage() {
            if (!this.knownFiltersAndMessageIds.HasNextPage) {
                this.nextMessageId = null;
            } else {
                const nextPageFilters = this.getNextPageFilters();
                const searchResult = await this.executeSearchAsync(nextPageFilters);
                this.nextPageMessageIds = searchResult.Items.map(message => message.Id);
                this.nextHasNextPage = searchResult.HasNextPage;
                this.nextMessageId = this.nextPageMessageIds[0]
            }
        }

        private async setPreviousMessageParametersFromPreviousPage() {
            if (this.knownFiltersAndMessageIds.Page === 1) {
                this.previousMessageId = null;
            } else {
                const previousPageFilters = this.getPreviousPageFilters();
                const searchResult = await this.executeSearchAsync(previousPageFilters);
                this.previousHasNextPage = searchResult.HasNextPage;
                this.previousPageMessageIds = searchResult.Items.map(message => message.Id);
                this.previousMessageId = this.previousPageMessageIds[this.previousPageMessageIds.length - 1]
            }
        }

        private getPreviousPageFilters() {
            return {
                ...this.knownFiltersAndMessageIds,
                Page: this.knownFiltersAndMessageIds.Page - 1
            };
        }

        private getNextPageFilters() {
            return {
                ...this.knownFiltersAndMessageIds,
                Page: this.knownFiltersAndMessageIds.Page + 1
            };
        }

        private async executeSearchAsync(
            criteria: IIntegrationMessageFilter,
        ): Promise<Shared.Contract.ISearchResult<IIntegrationMessageSummary>> {
            const query: Contract.IntegrationMonitor.Read.Query.IFindIntegrationMessagesQuery = {
                page: criteria.Page,
                pageSize: criteria.PageSize,
                NationalNumber: criteria.NationalNumber,
                Sender: criteria.Sender,
                ErrorCode: criteria.ErrorCode,
                Status: criteria.Status,
                ReceivedDateTimeFrom: Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeFrom),
                ReceivedDateTimeUntil: Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeUntil),
                PlannedDateTimeFrom: Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeFrom),
                PlannedDateTimeUntil: Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeUntil),
            };

            try {
                return await this.integrationMessageSearchSvc.findIntegrationMessagesAsync(query);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }


        private saveFiltersAndMessageIdsToStorage(criteria: RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageFilter, messageIds: string[], hasNextPage: boolean): void {
            let page = criteria.Page || null;
            let pageSize = criteria.PageSize || null;
            let nationalNumberNew = criteria.NationalNumber || null;
            let senderNew = criteria.Sender || null;
            let errorCodeNew = criteria.ErrorCode || null;
            let statusNew = criteria.Status || null;
            let receivedDateTimeFromNew = criteria.ReceivedDateTimeFrom || null;
            let receivedDateTimeUntilNew = criteria.ReceivedDateTimeUntil || null;
            let plannedDateTimeFromNew = criteria.PlannedDateTimeFrom || null;
            let plannedDateTimeUntilNew = criteria.PlannedDateTimeUntil || null;

            const knownFilters: IIntegrationMessageFilter = {
                Page: page,
                PageSize: pageSize,
                NationalNumber: nationalNumberNew,
                Sender: senderNew,
                ErrorCode: errorCodeNew,
                Status: statusNew,
                ReceivedDateTimeFrom: receivedDateTimeFromNew,
                ReceivedDateTimeUntil: receivedDateTimeUntilNew,
                PlannedDateTimeFrom: plannedDateTimeFromNew,
                PlannedDateTimeUntil: plannedDateTimeUntilNew,
                MessageIds: messageIds, // quick and dirty way to pass message ids to details page (PF-1066)
                HasNextPage: hasNextPage
            };

            this.storageSvc.store(StorageServiceConstants.integrationMonitorKey, knownFilters);
        }

    }

    remeCareIntegrationMonitorModule.component('rcIntegrationMessage', {
        controller: IntegrationMessageController,
        templateUrl: 'views/integrationMonitor/integrationMessage.html',
    });
}