namespace RemeCare.CarePlanAction {
    interface IQuantitativeObservationScope extends IComponentScope {
        component: QuantitativeObservationDescriptor;
    }

    class QuantitativeObservationController extends ComponentControllerBase<IQuantitativeObservationScope> {
        // @ngInject
        constructor(
            $scope: IQuantitativeObservationScope,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {}
    }

    class QuantitativeObservationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/quantitativeObservation.html';

        public controller = QuantitativeObservationController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcQuantitativeObservation', () => new QuantitativeObservationDirective());
}
