namespace RemeCare.CarePlanAction {
    interface IPreviousRegistrationScope extends IComponentScope {
        component: PreviousRegistrationDescriptor;
    }

    class PreviousRegistrationController extends ComponentControllerBase<IPreviousRegistrationScope> {
        // @ngInject
        constructor(
            $scope: IPreviousRegistrationScope,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {}
    }

    class PreviousRegistrationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/previousRegistration.html';

        public controller = PreviousRegistrationController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcPreviousRegistration', () => new PreviousRegistrationDirective());
}
