namespace RemeCare.IntegrationMonitor {
    import ApiServiceBase = Shared.Framework.ApiServiceBase;

    export class IntegrationMessageService extends ApiServiceBase {
        public static $inject = ApiServiceBase.injectionParameters;
        
        public getIntegrationMessageDetailAsync(id: string): Promise<Contract.IntegrationMonitor.Read.IIntegrationMessageDetail> {
            return this.getPromise(`IntegrationMessages/${id}`, null, 'getIntegrationMessageDetail');
        }

        public retryIntegrationMessageAsync(id: string): Promise<void> {
            return this.postPromise(`IntegrationMessages/${id}/retry`, null);
        }

        public archiveIntegrationMessageAsync(id: string): Promise<void> {
            return this.postPromise(`IntegrationMessages/${id}/archive`, null);
        }

        public unarchiveIntegrationMessageAsync(id: string): Promise<void> {
            return this.postPromise(`IntegrationMessages/${id}/unarchive`, null);
        }
        
    }

    remeCareIntegrationMonitorModule.service('integrationMessageSvc', IntegrationMessageService);
}