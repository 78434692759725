var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var ActorRolesApiService = /** @class */ (function (_super) {
                __extends(ActorRolesApiService, _super);
                function ActorRolesApiService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                ActorRolesApiService.prototype.findActorRolesAsync = function (query) {
                    return this.getPromise('ActorRoles', query, 'actorRoles');
                };
                ActorRolesApiService.prototype.findGlobalActorRolesAsync = function () {
                    var query = {
                        PageSize: 50,
                    };
                    return this.getPromise('GlobalActorRoles', query, 'globalActorRoles', null, function (r) { return r.Items; });
                };
                ActorRolesApiService.$inject = ['baseUrl', '$http', 'spinnerSvc'];
                return ActorRolesApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.ActorRolesApiService = ActorRolesApiService;
            RemeCare.remeCareAppModule.service('actorRolesApiSvc', ActorRolesApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
