module RemeCare.Contract.Core.Codes {

    export enum DateFilterTypeCode {
        PlannedDate = 1,
        ExecutionDate = 2,
        ModificationDate = 3,
        RegistrationDate = 4,
        ObservationDate = 5,
        CreationDate = 6,
        HandledDate = 7,
        SuggestedDate = 8,
        EventDate = 9
    }
}