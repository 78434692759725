namespace RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import ContactPointType = Shared.Contract.Code.ContactPointType;
    import ContactPointUsageType = Shared.Contract.Code.ContactPointUsageType;
    import ContactPerson = RemeCare.Model.ContactPersonPerson;

    class CreateContactPersonController implements ng.IComponentController {
        public contactPersonForm: Shared.Framework.Directive.IFormController;
        public countries: EnumCodeTranslation[];
        public communicationLanguages: EnumCodeTranslation[];
        public languages: EnumCodeTranslation[];
        public genders: EnumTranslation[];
        public contactPersonTypes: EnumTranslation[];
        public homeTelephone: Model.TelephoneNumber;
        public defaultMobile: Model.TelephoneNumber;
        public defaultEmail: Model.Email;
        public homeAddress: Model.Address;
        public contactPerson: ContactPerson;
        public patientId: Shared.Contract.Guid;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams: Patient.CreateContactPersonStateParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly partyApiSvc: Core.Services.PartyApiService
        ) {}

        public $onInit(): void {
            // Don't await
            this.loadDataAsync();
            this.patientId = this.$stateParams.patientId;
            this.contactPerson = new ContactPerson();
            this.homeAddress = Model.ContactPointFactory.createFromType(ContactPointType.Address) as Model.Address;
            this.homeAddress.contactPointUsageType = ContactPointUsageType.Domicile;
            this.homeTelephone = Model.ContactPointFactory.createFromType(
                ContactPointType.Telephone
            ) as Model.TelephoneNumber;
            this.homeTelephone.contactPointUsageType = ContactPointUsageType.Domicile;
            this.defaultMobile = Model.ContactPointFactory.createFromType(
                ContactPointType.MobilePhone
            ) as Model.TelephoneNumber;
            this.defaultMobile.contactPointUsageType = ContactPointUsageType.Default;
            this.defaultEmail = Model.ContactPointFactory.createFromType(ContactPointType.Email) as Model.Email;
            this.defaultEmail.contactPointUsageType = ContactPointUsageType.Default;
        }

        public atLeastOneNumber(): boolean {
            const result = !this.defaultMobile.isEmpty() || !this.homeTelephone.isEmpty();
            return result;
        }

        public async saveContactPerson(): Promise<void> {
            this.contactPersonForm.$setSubmitted();
            if (this.contactPersonForm.$invalid) {
                this.contactPersonForm.showValidationErrorMessage();
                return;
            }

            this.contactPerson.addresses = [this.homeAddress];
            this.contactPerson.telephoneNumbers = [this.homeTelephone, this.defaultMobile];
            this.contactPerson.emailAddresses = [this.defaultEmail];
            const registeredContactPerson = this.contactPerson.toRegisterContactPerson();
            try {
                await this.partyApiSvc.createContactPersonAsync(this.patientId, registeredContactPerson);
                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                    patientId: this.$stateParams.patientId,
                } as RemeCare.Patient.ContactPersonOverviewStateParams);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public cancel(): void {
            this.$state.go('patients.patientfile.patientInfo.contactPersons', {
                patientId: this.$stateParams.patientId,
            } as RemeCare.Patient.ContactPersonOverviewStateParams);
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [
                    this.countries,
                    this.communicationLanguages,
                    this.languages,
                    this.genders,
                    this.contactPersonTypes,
                ] = await Promise.all([
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getLanguagesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.masterdataSvc.getContactPersonTypesAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareManagementModule.component('createContactPerson', {
        controller: CreateContactPersonController,
        templateUrl: 'views/patient/patientInfo/contactPersons/createContactPerson.html',
        bindings: {
            partyRoleId: '<',
        },
    });
}
