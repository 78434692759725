/// <reference path="../model/prescription.ts"/>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Prescription = Patient.Model.Prescription;
        var PrescriptionOverviewController = /** @class */ (function () {
            // @ngInject
            function PrescriptionOverviewController($translate, toaster, idService, uuid4, gridBuilderSvc, modalBuilderFactory, patientSvc, therapyApiSvc, medicationSearchSvc) {
                this.$translate = $translate;
                this.toaster = toaster;
                this.idService = idService;
                this.uuid4 = uuid4;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.patientSvc = patientSvc;
                this.therapyApiSvc = therapyApiSvc;
                this.medicationSearchSvc = medicationSearchSvc;
                this.followupTemplate = '<div class="ui-grid-cell-contents" bs-tooltip data-container="body" data-delay="500,100" data-html="true" data-title="{{ row.entity.followUpText }}">' +
                    '<span class="control-label">' +
                    '<i ng-class="{\'glyphicon-eye-close\': !row.entity.isFollowedUp, \'glyphicon-eye-open\': row.entity.isFollowedUp}" class="glyphicon" ><i>' +
                    '</span>' +
                    '</div>';
            }
            PrescriptionOverviewController.prototype.$onInit = function () {
                this.readOnly = this.prescriptions != null;
                this.hideIntraDayConfig = true;
                this.buildGrid();
                this.showGrid = this.readOnly;
                if (this.readOnly) {
                    // Don't await
                    this.initializePrescriptionsAsync();
                }
                // Don't await
                this.initializeAllowAddAsync();
            };
            PrescriptionOverviewController.prototype.$onChanges = function (changes) {
                if (this.readOnly) {
                    return;
                }
                if (changes.carePlanId && this.prescriptionsGrid) {
                    this.showGrid = false;
                    this.prescriptionsGrid.search();
                }
            };
            PrescriptionOverviewController.prototype.addPrescriptionAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var p, schema, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.readOnly) {
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 7, , 8]);
                                return [4 /*yield*/, this.editPrescriptionAsync(null, false)];
                            case 2:
                                p = _a.sent();
                                if (!this.multiSave) return [3 /*break*/, 4];
                                p.executionRight = 15;
                                p.id = this.uuid4.generate();
                                return [4 /*yield*/, this.patientSvc.tokenizeMedicationSchemaAsync(_(p.periodicities).map(function (per) { return per.toWriteModel(); }))];
                            case 3:
                                schema = _a.sent();
                                p.renderMedicationSchema(schema, this.$translate);
                                this.prescriptionsGrid.addRow(p);
                                if (this.onPrescriptionChange) {
                                    this.onPrescriptionChange({ prescription: p });
                                }
                                return [3 /*break*/, 6];
                            case 4: return [4 /*yield*/, this.patientSvc.savePatientPrescriptionAsync(p.toWriteModel())];
                            case 5:
                                _a.sent();
                                this.prescriptionsGrid.search();
                                _a.label = 6;
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 8];
                            case 8: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.initializePrescriptionsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var productIds, medications_1, prescriptions, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                productIds = _(this.prescriptions).map(function (p) { return p.Medication.Id; });
                                return [4 /*yield*/, this.medicationSearchSvc.getMedicationsAsync(productIds)];
                            case 1:
                                medications_1 = _a.sent();
                                prescriptions = _(this.prescriptions).map(function (p) {
                                    var medication = _.find(medications_1, function (medication) { return medication.ProductId === p.Medication.Id; });
                                    return new Prescription(p, medication, _this.$translate);
                                });
                                this.prescriptionsGrid.setData(prescriptions);
                                this.prescriptionsGrid.totalItems = prescriptions ? prescriptions.length : 0;
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.initializeAllowAddAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, maxRight, e_3;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getTherapyBusinessContextRightsAsync(this.patientId)];
                            case 1:
                                ags = _a.sent();
                                maxRight = _(_(ags).filter(function (t) {
                                    return _this.isAction
                                        ? t.TherapyBusinessContext ===
                                            RemeCare.Shared.Contract.Code.TherapyBusinessContextCode.CarePlanActionMedicationSchema
                                        : t.TherapyBusinessContext ===
                                            RemeCare.Shared.Contract.Code.TherapyBusinessContextCode.MedicationSchema;
                                })).max(function (t) { return t.ExecutionRight; }).ExecutionRight;
                                this.allowAdd =
                                    (maxRight & (RemeCare.Shared.Framework.AuthRight.Create | RemeCare.Shared.Framework.AuthRight.Write)) !== 0;
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.editPrescriptionAsync = function (prescription, readOnly) {
                var _this = this;
                return new Promise(function (resolve, reject) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/patient/medication/prescriptionModal.html')
                        .setController('prescriptionCtrl')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        readOnly: readOnly,
                        prescription: prescription,
                        hideIntraDayConfig: _this.hideIntraDayConfig,
                    })
                        .setResultCallBack(function (p) { return __awaiter(_this, void 0, void 0, function () {
                        var data, data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (this.readOnly) {
                                        reject();
                                        return [2 /*return*/];
                                    }
                                    if (!(p.action === 'delete')) return [3 /*break*/, 4];
                                    if (!this.multiSave) return [3 /*break*/, 1];
                                    data = _(this.prescriptionsGrid.getData()).filter(function (pr) { return pr.id !== p.prescription.id; });
                                    this.prescriptionsGrid.setData(data);
                                    if (this.onPrescriptionDeleted) {
                                        this.onPrescriptionDeleted({ prescription: p.prescription });
                                    }
                                    reject();
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, this.patientSvc.deletePatientPrescriptionAsync(p.prescription.patientId, p.prescription.id)];
                                case 2:
                                    _a.sent();
                                    data = _(this.prescriptionsGrid.getData()).filter(function (pr) { return pr.id !== p.prescription.id; });
                                    this.prescriptionsGrid.setData(data);
                                    reject();
                                    _a.label = 3;
                                case 3: return [3 /*break*/, 5];
                                case 4:
                                    if (p.action === 'save') {
                                        resolve(p.prescription);
                                    }
                                    else {
                                        reject();
                                    }
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); })
                        .setResolve({
                        patientId: function () { return _this.patientId; },
                    })
                        .build();
                });
            };
            PrescriptionOverviewController.prototype.buildGrid = function () {
                var _this = this;
                this.gridId = this.idService.generateId();
                var gridBuilder = this.readOnly
                    ? this.gridBuilderSvc.createGridBuilder()
                    : this.gridBuilderSvc.createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                        return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria);
                    });
                gridBuilder = gridBuilder
                    .addConditionallyStyledColumn('getName()', 'General.MedicationName', 'medication.IsActive', {
                    red: false,
                }, { enableSorting: false })
                    .addActionColumn('info-sign', function (p) { return _this.showMedicationDetailsAsync(p); })
                    .addColumn('getComposition()', 'General.ActiveSubstance', { enableSorting: false })
                    .addColumn('validFromDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                    .addColumn('medicationSchema', 'Views.Patient.Medication.MedicationSchema', {
                    width: '*',
                    enableSorting: false,
                })
                    .addCustomColumn('', this.followupTemplate, function () { }, { width: '25', enableSorting: false })
                    .addColumn('validUntilDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' });
                if (!this.isPatientFile && !this.isMonitoring) {
                    gridBuilder = gridBuilder.addConditionalShowEditButtonWithPromiseFunctionColumn(function (p) { return _this.showPrescriptionDetails(p); }, function (p) { return _this.modifyPrescriptionAsync(p); }, function (p) { return !p.canEdit() || (p.executionRight & RemeCare.Shared.Framework.AuthRight.Write) === 0; });
                }
                this.prescriptionsGrid = gridBuilder.setMultiLine(this.gridId).build();
                this.prescriptionsGrid.pagingOptions.pageSize = 100;
                this.prescriptionsGrid.searchCriteria.validFromDate = RemeCare.Shared.DateHelper.today();
            };
            PrescriptionOverviewController.prototype.showMedicationDetailsAsync = function (prescription) {
                return __awaiter(this, void 0, void 0, function () {
                    var medication, e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.medicationSearchSvc.getMedicationAsync(prescription.medication.ProductId)];
                            case 1:
                                medication = _a.sent();
                                this.modalBuilderFactory
                                    .createModalBuilder()
                                    .setScope({ medicationInfo: { medication: medication } })
                                    .setController('MedicationModalCtrl')
                                    .setTemplateUrl('views/patient/medication/medicationModal.html')
                                    .setSize('lg')
                                    .build();
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, carePlans, carePlan, crc, e_5, e_6;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    validFromDate: criteria.validFromDate,
                                    validUntilDate: criteria.validUntilDate,
                                    carePlanId: this.carePlanId,
                                    isMonitoring: this.isMonitoring,
                                    isPatientFile: this.isPatientFile,
                                    isAction: this.isAction,
                                };
                                if (!(this.carePlanId != null)) return [3 /*break*/, 9];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 7, , 8]);
                                return [4 /*yield*/, this.patientSvc.getCarePlansAsync(this.patientId, {})];
                            case 2:
                                carePlans = _a.sent();
                                carePlan = _.find(carePlans, function (cp) { return cp.Id === _this.carePlanId; });
                                return [4 /*yield*/, this.therapyApiSvc.getCareRequestTemplateAsync(carePlan.TherapyId, carePlan.Id)];
                            case 3:
                                crc = _a.sent();
                                this.showGrid = _(crc.CareRequestSetup.CareRequestParts).any(function (crp) {
                                    return (crp.Type.Id === RemeCare.Shared.Contract.Code.CareRequestPartType.MedicationSchemaOther ||
                                        crp.Type.Id ===
                                            RemeCare.Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction) &&
                                        crp.ShowInPatientFile;
                                });
                                if (!this.showGrid) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.doSearchAsync(query)];
                            case 4: return [2 /*return*/, _a.sent()];
                            case 5: return [2 /*return*/, {
                                    Total: 0,
                                    Items: [],
                                }];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 8];
                            case 8: return [3 /*break*/, 13];
                            case 9:
                                this.showGrid = true;
                                _a.label = 10;
                            case 10:
                                _a.trys.push([10, 12, , 13]);
                                return [4 /*yield*/, this.doSearchAsync(query)];
                            case 11: return [2 /*return*/, _a.sent()];
                            case 12:
                                e_6 = _a.sent();
                                this.toaster.error(e_6);
                                return [3 /*break*/, 13];
                            case 13: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.doSearchAsync = function (query) {
                return __awaiter(this, void 0, void 0, function () {
                    var data, productIds, medications_2, prescriptions, e_7;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.getPatientPrescriptionsAsync(this.patientId, query)];
                            case 1:
                                data = _a.sent();
                                productIds = _(data.Items).map(function (p) { return p.Medication.Id; });
                                return [4 /*yield*/, this.medicationSearchSvc.getMedicationsAsync(productIds)];
                            case 2:
                                medications_2 = _a.sent();
                                prescriptions = _(data.Items).map(function (prescription) {
                                    var medication = _.find(medications_2, function (m) { return m.ProductId === prescription.Medication.Id; });
                                    return new Patient.Model.Prescription(prescription, medication, _this.$translate, _this.isPatientFile);
                                });
                                return [2 /*return*/, {
                                        Total: data.Total,
                                        Items: prescriptions,
                                    }];
                            case 3:
                                e_7 = _a.sent();
                                this.toaster.error(e_7);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionOverviewController.prototype.showPrescriptionDetails = function (prescription) {
                this.editPrescriptionAsync(prescription, true);
            };
            PrescriptionOverviewController.prototype.modifyPrescriptionAsync = function (prescription) {
                return __awaiter(this, void 0, void 0, function () {
                    var originalPrescription, prescriptionStartsInFuture, p_1, schema, existingPrescription, e_8, e_9;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                originalPrescription = prescription;
                                prescription = angular.copy(prescription);
                                prescriptionStartsInFuture = !moment(prescription.validFromDate).isBefore(RemeCare.Shared.DateHelper.tomorrow());
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 11, , 12]);
                                return [4 /*yield*/, this.editPrescriptionAsync(prescription, false)];
                            case 2:
                                p_1 = _a.sent();
                                _a.label = 3;
                            case 3:
                                _a.trys.push([3, 9, , 10]);
                                if (!(p_1 == null)) return [3 /*break*/, 4];
                                return [2 /*return*/, null];
                            case 4:
                                if (!this.multiSave) return [3 /*break*/, 6];
                                return [4 /*yield*/, this.patientSvc.tokenizeMedicationSchemaAsync(_(p_1.periodicities).map(function (per) { return per.toWriteModel(); }))];
                            case 5:
                                schema = _a.sent();
                                p_1.renderMedicationSchema(schema, this.$translate);
                                existingPrescription = _.find(this.prescriptionsGrid.getData(), function (pr) {
                                    return pr.id === p_1.id &&
                                        pr.validFromDate.valueOf() !== p_1.validFromDate.valueOf() &&
                                        moment(pr.validFromDate).isBefore(RemeCare.Shared.DateHelper.tomorrow());
                                });
                                if (existingPrescription &&
                                    existingPrescription.isFollowedUp &&
                                    !prescriptionStartsInFuture) {
                                    existingPrescription.validUntilDate = moment(p_1.validFromDate)
                                        .add(-1, 'days')
                                        .toDate();
                                    this.prescriptionsGrid.addRow(p_1);
                                    if (this.onPrescriptionChange) {
                                        this.onPrescriptionChange({ prescription: p_1 });
                                    }
                                    return [2 /*return*/, existingPrescription];
                                }
                                else {
                                    if (this.onPrescriptionChange) {
                                        this.onPrescriptionChange({ prescription: p_1 });
                                    }
                                    return [2 /*return*/, p_1];
                                }
                                return [3 /*break*/, 8];
                            case 6: return [4 /*yield*/, this.patientSvc.updatePatientPrescriptionAsync(p_1.toWriteModel())];
                            case 7:
                                _a.sent();
                                this.prescriptionsGrid.search();
                                return [2 /*return*/, p_1];
                            case 8: return [3 /*break*/, 10];
                            case 9:
                                e_8 = _a.sent();
                                this.toaster.error(e_8);
                                this.prescriptionsGrid.search();
                                return [2 /*return*/, originalPrescription];
                            case 10: return [3 /*break*/, 12];
                            case 11:
                                e_9 = _a.sent();
                                return [3 /*break*/, 12];
                            case 12: return [2 /*return*/];
                        }
                    });
                });
            };
            return PrescriptionOverviewController;
        }());
        Patient.remeCarePatientModule.component('rcPrescriptionOverview', {
            controller: PrescriptionOverviewController,
            templateUrl: 'views/patient/medication/prescriptionOverview.html',
            bindings: {
                patientId: '<',
                carePlanId: '<?',
                isMonitoring: '<?',
                isPatientFile: '<?',
                multiSave: '<?',
                onPrescriptionChange: '&?',
                onPrescriptionDeleted: '&?',
                prescriptions: '<?',
                guidanceIconLocation: '<',
                uniqueId: '@',
                isAction: '<?',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
