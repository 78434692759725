var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ContactPerson = RemeCare.Model.ContactPersonPerson;
        var EditContactPersonComponent = /** @class */ (function () {
            function EditContactPersonComponent($stateParams, $state, $translate, masterdataSvc, toaster, partyApiSvc, patientSvc) {
                this.$stateParams = $stateParams;
                this.$state = $state;
                this.$translate = $translate;
                this.masterdataSvc = masterdataSvc;
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
                this.patientSvc = patientSvc;
            }
            EditContactPersonComponent.prototype.$onInit = function () {
                this.patientId = this.$stateParams.patientId;
                if (this.$stateParams.contactPerson) {
                    this.contactPerson = this.$stateParams.contactPerson;
                }
                else {
                    this.loadContactPersonAsync(this.$stateParams.contactPersonId);
                }
                this.isReadonly();
                this.retrieveMasterDataAsync();
            };
            EditContactPersonComponent.prototype.reload = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var p, temp, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.getPersonDetailsAsync(this.contactPerson.partyId, true)];
                            case 1:
                                p = _a.sent();
                                temp = angular.copy(this.contactPerson);
                                temp.updateContactPoints(p);
                                this.contactPerson = temp;
                                this.isReadonly();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            EditContactPersonComponent.prototype.onCancel = function () {
                this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                    patientId: this.$stateParams.patientId,
                });
            };
            EditContactPersonComponent.prototype.confirm = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var registeredContactPerson, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.personalInfoForm.$setSubmitted();
                                if (this.personalInfoForm.$invalid) {
                                    this.personalInfoForm.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                registeredContactPerson = this.contactPerson.toRegisterContactPerson();
                                registeredContactPerson.ContactPoints = [];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.createContactPersonAsync(this.patientId, registeredContactPerson)];
                            case 2:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                                this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                                    patientId: this.$stateParams.patientId,
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            EditContactPersonComponent.prototype.loadContactPersonAsync = function (partyId) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, personDetail, contactPerson, e_3;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.partyApiSvc.getPersonDetailsAsync(partyId, true),
                                        this.patientSvc.getContactPersonAsync(this.patientId, partyId, { includeInactive: false }),
                                    ])];
                            case 1:
                                _a = _b.sent(), personDetail = _a[0], contactPerson = _a[1];
                                this.contactPerson = new ContactPerson(personDetail, contactPerson);
                                this.isReadonly();
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            EditContactPersonComponent.prototype.retrieveMasterDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_4;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getTitlesAsync(),
                                        this.masterdataSvc.getCommLanguagesAsync(),
                                        this.masterdataSvc.getLanguagesAsync(),
                                        this.masterdataSvc.getNationalitiesAsync(),
                                        this.masterdataSvc.getGendersAsync(),
                                        this.masterdataSvc.getMaritalStatussesAsync(),
                                        this.masterdataSvc.getEducationsAsync(),
                                        this.masterdataSvc.getTechnologicalAffinitiesAsync(),
                                        this.masterdataSvc.getContactPersonTypesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.personTitles = _a[0], this.communicationLanguages = _a[1], this.languages = _a[2], this.nationalities = _a[3], this.genders = _a[4], this.maritalStatuses = _a[5], this.educationLevels = _a[6], this.technologicalAffinities = _a[7], this.contactPersonTypes = _a[8];
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _b.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            EditContactPersonComponent.prototype.isReadonly = function () {
                this.readonly = true;
                if (this.contactPerson) {
                    this.readonly = _(this.contactPerson.partyRoles).any(function (pr) {
                        return pr.PartyRoleType.Id !== RemeCare.Shared.Contract.Code.PartyRoleType.ContactPerson &&
                            pr.PartyRoleType.Id !== RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional;
                    });
                }
            };
            return EditContactPersonComponent;
        }());
        Patient.remeCarePatientModule.component('rcEditContactPerson', {
            templateUrl: 'views/patient/patientInfo/contactPersons/editContactPerson.html',
            controller: EditContactPersonComponent,
            bindings: {},
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
