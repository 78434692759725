namespace RemeCare.Patient {
    import SearchResult = Shared.Contract.ISearchResult;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import IContactPerson = Contract.HealthCareParty.Read.IContactPerson;
    import ContactPerson = RemeCare.Model.ContactPersonPerson;
    import Guid = Shared.Contract.Guid;
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;

    class ContactPersonsOverviewController implements ng.IComponentController {
        public contactPersonsGrid: Shared.Framework.Grid.Grid<IContactPerson>;
        public contactPerson: ContactPerson;
        public patientId: Guid;
        public countries: EnumCodeTranslation[];
        public contactPersonRole: IContactPerson;
        public contactPersonUserInfo: Shared.Contract.Read.IUserStatus;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams: RemeCare.Patient.ContactPersonOverviewStateParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly securityApiSvc: Shared.Framework.Service.SecurityApiSvc
        ) {}

        public async $onInit(): Promise<void> {
            this.patientId = this.$stateParams.patientId;
            this.buildGrids();
            try {
                this.countries = await this.masterdataSvc.getCountriesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public $onChanges(): void {
            this.buildGrids();
        }

        public edit(): void {
            this.$state.go('patients.patientfile.patientInfo.contactPersons.editContactPerson', {
                contactPerson: this.contactPerson,
                patientId: this.$stateParams.patientId,
                contactPersonId: this.contactPerson.id,
            } as RemeCare.Patient.EditContactPersonStateParams);
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: Contract.HealthCareParty.Read.Query.ISearchContactPersonsQuery
        ): Promise<SearchResult<IContactPerson>> {
            criteria.page = page;
            criteria.pageSize = pageSize;
            criteria.sortField = sortField;
            criteria.sortOrder = sortOrder;
            try {
                const result = await this.patientSvc.getContactPersonsAsync(this.$stateParams.patientId, criteria);
                return result;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrids(): void {
            this.contactPersonsGrid = this.gridBuilderSvc
                .createGridBuilder<IContactPerson>((p, ps, sf, sd, c) => this.executeSearchAsync(p, ps, sf, sd, c))
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('Relationship.Text', 'General.Type')
                .addBoolColumn('IsHealthCareNonProfessional', 'General.HealthCareNonProfessional')
                .addColumn('PhoneNumber', 'General.PhoneNumber', { cellFilter: 'phone' })
                .addColumn('MobileNumber', 'General.Mobile', { cellFilter: 'phone' })
                .addBoolColumn('IsActive', 'General.Active')
                .addShowDetailButtonColumn(c => this.showContactPersonDetailsAsync(c))
                .build();
            this.contactPersonsGrid.search();
        }

        private async showContactPersonDetailsAsync(contactPerson: IContactPerson): Promise<void> {
            try {
                this.contactPersonRole = contactPerson;
                const personDetails = await this.partyApiSvc.getPersonDetailsAsync(contactPerson.PartyId, true);
                this.contactPerson = new ContactPerson(personDetails, contactPerson);
            } catch (e) {
                this.toaster.error(e);
            }
            if (this.authservice.hasRight(AuthContext.patientInternal, AuthRight.Read)) {
                try {
                    this.contactPersonUserInfo = await this.securityApiSvc.isUserBlocked(contactPerson.PartyId);
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }
    }

    remeCarePatientModule.component('rcContactPersonsOverview', {
        templateUrl: 'views/patient/patientInfo/contactPersons/contactPersonOverview.html',
        controller: ContactPersonsOverviewController,
        bindings: {},
    });
}
