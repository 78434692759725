namespace RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import DateHelper = Shared.DateHelper;

    export class AgendaAction {
        public plannedDateTimeFrom: Date;
        public plannedDateTimeUntil: Date;
        public patient: string;
        public patientId: Guid;
        public name: string;
        public status: RemeCare.Contract.Core.Codes.CarePlanActionStatus;
        public therapies: Shared.Contract.IEntityTranslation[];
        public actors: Shared.Contract.IEntityTranslation[];
        public id: Guid;
        public address: string;
        public canBeEdited: boolean;
        public canBeViewed: boolean;
        public patientNumber: string;

        constructor(agendaAction: Contract.CarePlanAction.Read.IAgendaAction, persons: Contract.Party.Read.IPerson[]) {
            const patientInfo = _(persons).find((x: Contract.Party.Read.IPerson) =>
                _(x.PartyRoles).any(pr => pr.Id === agendaAction.Patient.Id)
            );
            this.actors = [];
            if (agendaAction.PartyRole) {
                this.actors.push(agendaAction.PartyRole);
            } else {
                this.actors = agendaAction.ActorRoles;
            }

            this.id = agendaAction.Id;
            this.plannedDateTimeFrom = DateHelper.serverDateStringToDateTime(agendaAction.PlannedDateTimeFrom);
            this.plannedDateTimeUntil = DateHelper.serverDateStringToDateTime(agendaAction.PlannedDateTimeUntil);
            this.patient = agendaAction.Patient.Text;
            this.patientId = agendaAction.Patient.Id;
            this.patientNumber = agendaAction.PatientNumber;
            this.address = patientInfo != null ? `${patientInfo.ZipCode || ''} ${patientInfo.City || ''}` : '';
            this.name = agendaAction.Name;
            this.status = agendaAction.Status;
            this.therapies = agendaAction.Therapies;
            this.canBeEdited = (agendaAction.MaxExecutionRight & Shared.Framework.AuthRight.Create) !== 0;
            this.canBeViewed = (agendaAction.MaxExecutionRight & Shared.Framework.AuthRight.Read) !== 0;
        }
    }

    export class PersonalAgendaAction extends AgendaAction {
        public actionTemplateId: Guid;
        public suggestedDateTime: Date;
        public executionDateTime: Date;
        public modificationDateTime: Date;
        public openEventWindowDateTime: Date;
        public closeEventWindowDateTime: Date;
        public eventDateTimeFrom: Date;
        public eventDateTimeUntil: Date;
        public showAsToDo: boolean;

        constructor(agendaAction: Contract.CarePlanAction.Read.IPersonalAgendaAction) {
            super(agendaAction, null);
            this.showAsToDo = agendaAction.ShowAsToDo;
            this.suggestedDateTime = DateHelper.serverDateStringToDateTime(agendaAction.SuggestedDateTime);
            this.executionDateTime = DateHelper.serverDateStringToDateTime(agendaAction.ExecutionDateTime);
            this.modificationDateTime = DateHelper.serverDateStringToDateTime(agendaAction.ModificationDateTime);
            this.openEventWindowDateTime = DateHelper.serverDateStringToDateTime(agendaAction.OpenEventWindowDateTime);
            this.closeEventWindowDateTime = DateHelper.serverDateStringToDateTime(
                agendaAction.CloseEventWindowDateTime
            );
            this.eventDateTimeFrom = DateHelper.serverDateStringToDateTime(agendaAction.EventDateTimeFrom);
            this.eventDateTimeUntil = DateHelper.serverDateStringToDateTime(agendaAction.EventDateTimeUntil);
        }
    }
}
