var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var CountryIso2 = RemeCare.Shared.Contract.Code.CountryIso2;
        var PhoneNumber = /** @class */ (function () {
            function PhoneNumber(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.countryPrefix = serverObject.CountryPrefix;
                    this.areaCode = serverObject.AreaCode;
                    this.localNumber = serverObject.LocalNumber;
                }
            }
            PhoneNumber.fromString = function (phoneNumber) {
                var result = new PhoneNumber();
                if (!phoneNumber) {
                    return result;
                }
                var split = phoneNumber.split(' ');
                result.countryPrefix = split[0] ? split[0].replace('+', '') : null;
                result.areaCode = split[1] ? split[1].replace('(', '').replace(')', '') : null;
                result.localNumber = '';
                for (var i = 2; i < split.length; i++) {
                    result.localNumber += split[i];
                }
                return result;
            };
            PhoneNumber.fromObject = function (phoneNumber) {
                var result = new PhoneNumber();
                if (!phoneNumber) {
                    return result;
                }
                if (phoneNumber.countryPrefix) {
                    result.countryPrefix = phoneNumber.countryPrefix;
                }
                if (phoneNumber.areaCode) {
                    result.areaCode = phoneNumber.areaCode;
                }
                if (phoneNumber.localNumber) {
                    result.localNumber = phoneNumber.localNumber;
                }
                return result;
            };
            PhoneNumber.parse = function (phoneNumber, regionCode) {
                if (regionCode === void 0) { regionCode = CountryIso2.Belgium; }
                if (!phoneNumber) {
                    return new PhoneNumber();
                }
                return this.fromE164(phoneUtils.formatE164(phoneNumber, regionCode), regionCode);
            };
            PhoneNumber.fromE164 = function (phoneNumber, regionCode) {
                if (regionCode === void 0) { regionCode = CountryIso2.Belgium; }
                if (!phoneNumber) {
                    return new PhoneNumber();
                }
                var o = new PhoneNumber();
                o.countryPrefix = phoneUtils.getCountryCodeForRegion(phoneUtils.getRegionCodeForNumber(phoneNumber, regionCode));
                if (o.countryPrefix) {
                    // Ensure it is a string
                    o.countryPrefix = '' + o.countryPrefix;
                }
                // Note: international numbering standards (e.g. E.164 and E.123) only make a clear-cut distintion between country codes and subscriber numbers
                // 'local number' and 'area code' are therefore not stricly defined, the code below is a best effort
                var nn = phoneUtils.formatNational(phoneNumber, regionCode).split(' '); // National formatting is our best guess to split a number in a meaningful way
                o.areaCode = this.sanitize(nn.shift(), true); // Assume the first group is some form of area code, drop leading zeroes
                o.localNumber = this.sanitize(nn.join()); // Force whatever remains into the local part, don't drop zeroes
                return o;
            };
            PhoneNumber.sanitize = function (dirty, dropLeadingZeroes) {
                if (dropLeadingZeroes === void 0) { dropLeadingZeroes = false; }
                if (dropLeadingZeroes) {
                    return dirty.replace(/\D|^(0+)/g, '');
                }
                else {
                    return dirty.replace(/\D/g, '');
                }
            };
            PhoneNumber.addSpaces = function (str) {
                var result = '';
                if (str.length % 2 === 0) {
                    for (var i = 0; i < str.length; i++) {
                        result += str[i];
                        if (i % 2 === 1) {
                            result += ' ';
                        }
                    }
                }
                else {
                    for (var i = 0; i < str.length; i++) {
                        result += str[i];
                        if (i % 2 === 0 && i !== 0) {
                            result += ' ';
                        }
                    }
                }
                return result;
            };
            PhoneNumber.prototype.toString = function () {
                var countryPrefix = this.countryPrefix ? "+" + this.countryPrefix + " " : '';
                var areaCode = this.areaCode ? "(" + this.areaCode + ") " : '';
                var localNumber = this.localNumber ? this.addSpaces(this.localNumber) : '';
                return "" + countryPrefix + areaCode + localNumber;
            };
            PhoneNumber.prototype.isValid = function () {
                return ((_.isEmpty(this.countryPrefix) && _.isEmpty(this.areaCode) && _.isEmpty(this.localNumber)) ||
                    (!_.isEmpty(this.countryPrefix) && !_.isEmpty(this.areaCode) && !_.isEmpty(this.localNumber)));
            };
            PhoneNumber.prototype.isEmpty = function () {
                return _.isEmpty(this.countryPrefix) && _.isEmpty(this.areaCode) && _.isEmpty(this.localNumber);
            };
            PhoneNumber.prototype.isFilled = function () {
                return !_.isEmpty(this.countryPrefix) && !_.isEmpty(this.areaCode) && !_.isEmpty(this.localNumber);
            };
            PhoneNumber.prototype.toServerWrite = function () {
                return {
                    Id: this.id,
                    CountryPrefix: this.countryPrefix,
                    AreaCode: this.areaCode,
                    LocalNumber: this.localNumber,
                };
            };
            PhoneNumber.prototype.isSame = function (toCompare) {
                return (this.countryPrefix === toCompare.countryPrefix &&
                    this.areaCode === toCompare.areaCode &&
                    this.localNumber === toCompare.localNumber);
            };
            PhoneNumber.prototype.addSpaces = function (str) {
                return PhoneNumber.addSpaces(str);
            };
            return PhoneNumber;
        }());
        Model.PhoneNumber = PhoneNumber;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
