module RemeCare.Framework {
    import Guid = RemeCare.Shared.Contract.Guid;

    export class PhoneNumber {
        id: Guid;
        countryPrefix: string;
        areaCode: string;
        localNumber: string;

        constructor(phoneNumber?: RemeCare.Contract.Core.ITelephoneNumber) {
            if (phoneNumber != null) {
                this.id = phoneNumber.Id;
                this.countryPrefix = phoneNumber.CountryPrefix;
                this.areaCode = phoneNumber.AreaCode;
                this.localNumber = phoneNumber.LocalNumber;
            }
        }

        public isValid(): boolean {
            return this.countryPrefix != null
                && this.countryPrefix != ''
                && this.areaCode != null
                && this.areaCode != ''
                && this.localNumber != null
                && this.localNumber != '';
        }

        public toString(): string {
            return (this.countryPrefix ? '+' + this.countryPrefix + ' ' : '') +
                (this.areaCode ? '(' + this.areaCode + ') ' : '') +
                (this.localNumber ? this.addSpaces(this.localNumber) : '');
        }

        public toServerPhone(): RemeCare.Contract.Core.ITelephoneNumber {
            if (this.countryPrefix == null || this.areaCode == null || this.localNumber == null) return null;
            var serverPhone: RemeCare.Contract.Core.ITelephoneNumber = {
                Id: this.id,
                CountryPrefix: this.countryPrefix,
                AreaCode: this.areaCode,
                LocalNumber: this.localNumber
            }
            return serverPhone;
        }

        private addSpaces(string): string {
            var result = '';
            for (var i = 0; i < string.length; i++) {
                result += string[i];
                if (i % 2 == 1)
                    result += ' ';
            }
            return result;
        }
    }
}