namespace RemeCare.Patient {
    import CarePlanParameterValue = Contract.Patient.Read.ICarePlanParameterValue;
    import RegistrationCodeSetValue = Contract.CareRequest.Read.IRegistrationCodeSetValue;
    import RegistrationMultiCodeSetValue = Contract.CareRequest.Read.IRegistrationMultiCodeSetValue;
    import RegistrationPhysicalQuantity = Contract.CareRequest.Read.IRegistrationPhysicalQuantity;

    class CarePlanDetailsController implements ng.IComponentController {
        // bindings
        public carePlanDetails: CarePlanParameterValue[];
        public collapsed: boolean;

        public $onInit(): void {
            this.collapsed = true;
        }

        public toggleShowDetails(): void {
            this.collapsed = !this.collapsed;
        }

        public getRegistrationValue(detail: CarePlanParameterValue): string {
            if (detail.RegistrationValue == null) {
                return '';
            }

            if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue
            ) {
                return (detail.RegistrationValue as RegistrationCodeSetValue).ChoiceListOption.Name;
            } else if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity
            ) {
                const unit = detail.Unit != null ? ` ${detail.Unit.Text}` : '';
                return `${(detail.RegistrationValue as RegistrationPhysicalQuantity).Amount}${unit}`;
            } else if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue
            ) {
                return _((detail.RegistrationValue as RegistrationMultiCodeSetValue).MultiChoiceListOptions)
                    .chain()
                    .sortBy(x => x.Sequence)
                    .map(x => x.Name)
                    .value()
                    .toString();
            }
            return '';
        }
    }

    remeCarePatientModule.component('rcCarePlanDetails', {
        bindings: {
            carePlanDetails: '<',
        },
        controller: CarePlanDetailsController,
        templateUrl: 'views/patient/monitoring/dashboard/carePlanDetail.html',
    });
}
