/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var BoxplotDataType = RemeCare.Shared.Contract.Code.BoxplotDataType;
        var BoxplotController = /** @class */ (function (_super) {
            __extends(BoxplotController, _super);
            // @ngInject
            function BoxplotController($rootScope, $locale, dateHelper, spinnerSvc, $q, carePlanApiSvc, $translate) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.$q = $q;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.$translate = $translate;
                return _this;
            }
            BoxplotController.prototype.init = function () {
                var parameters = _(this.monitoringPart.monitoringPartSources).filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; });
                this.minParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.Minimum; });
                this.firstQuartileParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.FirstQuartile; });
                this.medianParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.Median; });
                this.thirdQuartileParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.LastQuartile; });
                this.maxParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.Maximum; });
                this.meanParameter = _.find(parameters, function (p) { return p.sequence === BoxplotDataType.Mean; });
                this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
                this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();
                this.chartConfigs = [
                    {
                        options: {
                            chart: {
                                animation: false,
                                type: 'line',
                                alignTicks: true,
                                height: 200,
                                zoomType: 'xy',
                                spacingTop: 40,
                                marginLeft: 37,
                                marginRight: 37,
                            },
                            xAxis: this.getXAxisConfig(null, true),
                            credits: {
                                enabled: false,
                            },
                            exporting: {
                                enabled: false,
                            },
                            tooltip: {
                                xDateFormat: this.getDateFormat(this.showMultiplePerDay),
                                shared: true,
                            },
                            legend: {
                                enabled: this.showLegend,
                            },
                        },
                        series: [],
                        title: {
                            text: null,
                        },
                    },
                ];
                return this.$q.resolve();
            };
            BoxplotController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, oneMonthEarlier, pointsPromise, referencePromise, graphPoints, referencePoints, min, first, med, third, max, meanGraph, mean, graph, references;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                oneMonthEarlier = moment(this.dateInfo.untilDate);
                                oneMonthEarlier.subtract(1, 'months');
                                this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
                                this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(this.showMultiplePerDay);
                                pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(this.carePlanIds, this.quantitativeMeasuringPoints, this.dateInfo.fromDate, this.getUntilDate(), this.showMultiplePerDay);
                                referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(this.carePlanIds, this.quantitativeReferencePoints, this.dateInfo.fromDate, this.getUntilDate());
                                return [4 /*yield*/, Promise.all([pointsPromise, referencePromise])];
                            case 1:
                                _a = _b.sent(), graphPoints = _a[0], referencePoints = _a[1];
                                min = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.minParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.minParameter.unit == null) ||
                                            (g.Scale != null && _this.minParameter.unit != null && g.Scale.Id === _this.minParameter.unit.Id));
                                });
                                first = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.firstQuartileParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.firstQuartileParameter.unit == null) ||
                                            (g.Scale != null &&
                                                _this.firstQuartileParameter.unit != null &&
                                                g.Scale.Id === _this.firstQuartileParameter.unit.Id));
                                });
                                med = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.medianParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.medianParameter.unit == null) ||
                                            (g.Scale != null &&
                                                _this.medianParameter.unit != null &&
                                                g.Scale.Id === _this.medianParameter.unit.Id));
                                });
                                third = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.thirdQuartileParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.thirdQuartileParameter.unit == null) ||
                                            (g.Scale != null &&
                                                _this.thirdQuartileParameter.unit != null &&
                                                g.Scale.Id === _this.thirdQuartileParameter.unit.Id));
                                });
                                max = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.maxParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.maxParameter.unit == null) ||
                                            (g.Scale != null && _this.maxParameter.unit != null && g.Scale.Id === _this.maxParameter.unit.Id));
                                });
                                meanGraph = null;
                                if (this.meanParameter) {
                                    mean = _.find(graphPoints, function (g) {
                                        return g.Subject.Id === _this.meanParameter.characteristic.Id &&
                                            ((g.Scale == null && _this.meanParameter.unit == null) ||
                                                (g.Scale != null &&
                                                    _this.meanParameter.unit != null &&
                                                    g.Scale.Id === _this.meanParameter.unit.Id));
                                    });
                                    meanGraph = new Patient.Model.NumberParameterGraph(mean);
                                }
                                graph = new Patient.Model.Boxplot(min, first, med, third, max);
                                references = _(referencePoints).map(function (g) { return new Patient.Model.NumberGraph(g, true, true); });
                                this.configureChart(graph, references, meanGraph);
                                return [2 /*return*/];
                        }
                    });
                });
            };
            BoxplotController.prototype.containsData = function (config) {
                return !_(config.series).all(function (s) {
                    return _(s.data).all(function (d) { return d.high == null && d.low == null; });
                });
            };
            BoxplotController.prototype.configureChart = function (graph, references, meanGraph) {
                var _this = this;
                this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
                var yAxis = this.getYAxis(graph, references, meanGraph);
                this.chartConfigs[0].options.yAxis = yAxis;
                var series = _(references).map(function (r) { return _this.getLineDataSeries(r, true, yAxis); });
                if (meanGraph) {
                    series = series.concat(this.getLineDataSeries(meanGraph, false, yAxis));
                }
                series.unshift(this.getBoxplotSeries(graph));
                this.chartConfigs[0].series = series;
                this.chartConfigs[0].loading = false;
            };
            BoxplotController.prototype.getYAxis = function (graph, references, meanGraph) {
                var _this = this;
                var maxes = _(references)
                    .chain()
                    .map(function (r) { return _this.getMaxYScaleValue(r); })
                    .filter(function (m) { return m != null; })
                    .value();
                maxes.push(this.getMaxYScaleValue(graph));
                var mins = _(references)
                    .chain()
                    .map(function (r) { return _this.getMinYScaleValue(r); })
                    .filter(function (m) { return m != null; })
                    .value();
                mins.push(this.getMinYScaleValue(graph));
                if (meanGraph) {
                    maxes.push(this.getMaxYScaleValue(meanGraph));
                    mins.push(this.getMinYScaleValue(meanGraph));
                }
                var max = _(maxes).max();
                var min = _(mins).min();
                return {
                    title: {
                        align: 'high',
                        offset: 0,
                        rotation: 0,
                        text: graph.scale != null ? graph.scale.Text : null,
                        y: -20,
                        x: -27,
                        textAlign: 'left',
                    },
                    max: max,
                    min: min,
                    id: graph.scale != null ? graph.scale.Id : 'unscaled',
                    startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                    endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                    labels: {
                        align: 'right',
                        x: 0,
                        y: -2,
                    },
                };
            };
            BoxplotController.prototype.getBoxplotSeries = function (graph) {
                var _this = this;
                var monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) {
                    return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale);
                });
                var boxplotRep = monitoringPartSource.sourceParameterRepresentation;
                return {
                    type: 'boxplot',
                    animation: false,
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataBoxplot(gp); })
                        .sortBy(function (gp) { return gp.x; })
                        .value(),
                    color: boxplotRep.colour,
                    name: graph.subject.Text,
                    dataLabels: {
                        xHigh: -2,
                        xLow: -2,
                        yLow: -13,
                        yHigh: 13,
                        defer: true,
                    },
                    tooltip: {
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                            this.$translate.instant('Configuration.Therapies.Monitoring.Maximum') +
                            ': {point.high}<br/>' +
                            this.$translate.instant('Configuration.Therapies.Monitoring.ThirdQuartile') +
                            ': {point.q3}<br/>' +
                            this.$translate.instant('Configuration.Therapies.Monitoring.Median') +
                            ': {point.median}<br/>' +
                            this.$translate.instant('Configuration.Therapies.Monitoring.FirstQuartile') +
                            ': {point.q1}<br/>' +
                            this.$translate.instant('Configuration.Therapies.Monitoring.Minimum') +
                            ': {point.low}<br/>',
                    },
                    pointPlacement: 'on',
                };
            };
            BoxplotController.prototype.getLineDataSeries = function (graph, isReference, yAxis) {
                var _this = this;
                var monitoringPartSource;
                if (isReference) {
                    monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) {
                        return (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                            mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                            mps.hasObjective(graph.subject) ||
                            mps.hasRuleThreshold(graph.subject);
                    });
                }
                else {
                    monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) {
                        return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                            mps.hasCharacteristicAndUnit(graph.subject, graph.scale);
                    });
                }
                var chartLine = monitoringPartSource.sourceParameterRepresentation;
                return {
                    animation: false,
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataPoint(gp); })
                        .sortBy(function (gp) { return gp[0]; })
                        .value(),
                    color: chartLine.colour,
                    name: graph.subject.Text,
                    lineWidth: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Thin ? 1 : 2,
                    dashStyle: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                    yAxis: yAxis.id,
                    step: isReference,
                    tooltip: {
                        xDateFormat: this.getDateFormat(this.showMultiplePerDay && !isReference),
                    },
                    marker: {
                        enabled: !isReference,
                        symbol: isReference ? 'circle' : null,
                        radius: isReference ? 1 : 4,
                    },
                };
            };
            BoxplotController.prototype.getDataBoxplot = function (graphPoint) {
                var minExceedsThreshold = graphPoint.y.min != null && graphPoint.y.min.ExceedsThreshold;
                var firstExceedsThreshold = graphPoint.y.firstQ != null && graphPoint.y.firstQ.ExceedsThreshold;
                var medExceedsThreshold = graphPoint.y.median != null && graphPoint.y.median.ExceedsThreshold;
                var thirdExceedsThreshold = graphPoint.y.thirdQ != null && graphPoint.y.thirdQ.ExceedsThreshold;
                var maxExceedsThreshold = graphPoint.y.max != null && graphPoint.y.max.ExceedsThreshold;
                var warning = '<i class="fa fa-exclamation-triangle"></i>';
                return {
                    x: moment(graphPoint.x).valueOf(),
                    low: graphPoint.y.min != null ? graphPoint.y.min.Value : null,
                    q1: graphPoint.y.firstQ != null ? graphPoint.y.firstQ.Value : null,
                    median: graphPoint.y.median != null ? graphPoint.y.median.Value : null,
                    q3: graphPoint.y.thirdQ != null ? graphPoint.y.thirdQ.Value : null,
                    high: graphPoint.y.max != null ? graphPoint.y.max.Value : null,
                    dataLabels: minExceedsThreshold ||
                        firstExceedsThreshold ||
                        medExceedsThreshold ||
                        thirdExceedsThreshold ||
                        maxExceedsThreshold
                        ? {
                            enabled: true,
                            // ReSharper disable SuspiciousThisUsage
                            // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                            formatter: function () {
                                if (this.y === this.point.low && minExceedsThreshold) {
                                    return warning;
                                }
                                else if (this.y === this.point.q1 && firstExceedsThreshold) {
                                    return warning;
                                }
                                else if (this.y === this.point.median && medExceedsThreshold) {
                                    return warning;
                                }
                                else if (this.y === this.point.q3 && thirdExceedsThreshold) {
                                    return warning;
                                }
                                else if (this.y === this.point.high && maxExceedsThreshold) {
                                    return warning;
                                }
                                return '';
                            },
                            // ReSharper restore SuspiciousThisUsage
                            style: {
                                color: 'red',
                            },
                            useHTML: true,
                        }
                        : null,
                };
            };
            BoxplotController.prototype.getDataPoint = function (graphPoint) {
                var y;
                var exceedsThreshold;
                if (_.isNumber(graphPoint.y)) {
                    y = graphPoint.y;
                    exceedsThreshold = false;
                }
                else {
                    y = graphPoint.y.Value;
                    exceedsThreshold = graphPoint.y.ExceedsThreshold;
                }
                return {
                    x: moment(graphPoint.x).valueOf(),
                    y: y,
                    marker: exceedsThreshold
                        ? {
                            symbol: 'text:\uf071',
                            fillColor: '#d9534f',
                        }
                        : null,
                };
            };
            return BoxplotController;
        }(Patient.ChartMonitoringPartController));
        var BoxplotComponent = /** @class */ (function (_super) {
            __extends(BoxplotComponent, _super);
            function BoxplotComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = BoxplotController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return BoxplotComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringBoxplot', new BoxplotComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
