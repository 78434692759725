var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var HealthCareProfessionalController = /** @class */ (function (_super) {
            __extends(HealthCareProfessionalController, _super);
            function HealthCareProfessionalController($scope, $translate, toaster) {
                return _super.call(this, $scope, $translate, toaster) || this;
            }
            HealthCareProfessionalController.prototype.$onInit = function () {
                this.$scope.currentRelations = this.$scope.person.getCurrentRelations();
                this.$scope.oldRelations = this.$scope.person.getOldRelations();
            };
            HealthCareProfessionalController.$inject = ['$scope', '$translate', 'toaster'];
            return HealthCareProfessionalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('healthCareProfessionalCtrl', HealthCareProfessionalController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
