var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var ContactPointType = RemeCare.Shared.Contract.Code.ContactPointType;
        var ContactPointFactory = /** @class */ (function () {
            function ContactPointFactory() {
            }
            ContactPointFactory.create = function (def) {
                return this.createContactPoint(def.Type.Id, def);
            };
            ContactPointFactory.createFromType = function (type) {
                var result = this.createContactPoint(type);
                result.type = type;
                return result;
            };
            ContactPointFactory.createContactPoint = function (type, def) {
                switch (type) {
                    case ContactPointType.Address:
                        return new Address(def);
                    case ContactPointType.Telephone:
                        return new TelephoneNumber(def);
                    case ContactPointType.MobilePhone:
                        return new TelephoneNumber(def);
                    case ContactPointType.Fax:
                        return new TelephoneNumber(def);
                    case ContactPointType.Email:
                        return new Email(def);
                    case ContactPointType.Website:
                        return new Website(def);
                    default:
                        throw new Error('Unkown contact point type');
                }
            };
            return ContactPointFactory;
        }());
        Model.ContactPointFactory = ContactPointFactory;
        var ContactPoint = /** @class */ (function () {
            function ContactPoint(def) {
                if (def != null) {
                    this.id = def.Id;
                    this.remark = def.Remark;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(def.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(def.ValidUntilDate);
                    this.type = def.Type.Id;
                    this.contactPointUsageType = def.ContactPointUsageType ? def.ContactPointUsageType.Id : null;
                    this.isReadOnly = def.IsReadOnly;
                }
            }
            ContactPoint.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    remark: this.remark,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                    type: this.type,
                    contactPointUsageType: this.contactPointUsageType,
                };
            };
            ContactPoint.prototype.toContactPointParameters = function () {
                return {
                    type: this.type,
                    usageType: this.contactPointUsageType,
                };
            };
            return ContactPoint;
        }());
        Model.ContactPoint = ContactPoint;
        var Address = /** @class */ (function (_super) {
            __extends(Address, _super);
            function Address(def) {
                var _this = _super.call(this, def) || this;
                if (def != null) {
                    _this.addressLine1 = def.AddressLine1;
                    _this.addressLine2 = def.AddressLine2;
                    _this.addressLine3 = def.AddressLine3;
                    _this.city = def.City;
                    _this.zipCode = def.ZipCode;
                    _this.country = def.Country;
                }
                return _this;
            }
            Address.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.addressLine1 = this.addressLine1;
                result.addressLine2 = this.addressLine2;
                result.addressLine3 = this.addressLine3;
                result.city = this.city;
                result.zipCode = this.zipCode;
                result.countryId = this.country;
                return result;
            };
            Address.prototype.toContactPointParameters = function () {
                var result = _super.prototype.toContactPointParameters.call(this);
                result.addressLine1 = this.addressLine1;
                result.addressLine2 = this.addressLine2;
                result.addressLine3 = this.addressLine3;
                result.city = this.city;
                result.zipCode = this.zipCode;
                result.country = this.country;
                return result;
            };
            Address.prototype.toString = function () {
                return this.addressLine1 + " " + this.zipCode + " " + this.city;
            };
            Address.prototype.isEmpty = function () {
                return (_.isEmpty(this.addressLine1) &&
                    _.isEmpty(this.addressLine2) &&
                    _.isEmpty(this.addressLine3) &&
                    _.isEmpty(this.city) &&
                    _.isEmpty(this.zipCode) &&
                    !this.country);
            };
            return Address;
        }(ContactPoint));
        Model.Address = Address;
        var TelephoneNumber = /** @class */ (function (_super) {
            __extends(TelephoneNumber, _super);
            function TelephoneNumber(def) {
                var _this = _super.call(this, def) || this;
                _this.phoneNumber = new Model.PhoneNumber(def);
                return _this;
            }
            Object.defineProperty(TelephoneNumber.prototype, "countryPrefix", {
                get: function () {
                    return this.phoneNumber.countryPrefix;
                },
                set: function (value) {
                    this.phoneNumber.countryPrefix = value;
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(TelephoneNumber.prototype, "areaCode", {
                get: function () {
                    return this.phoneNumber.areaCode;
                },
                set: function (value) {
                    this.phoneNumber.areaCode = value;
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(TelephoneNumber.prototype, "localNumber", {
                get: function () {
                    return this.phoneNumber.localNumber;
                },
                set: function (value) {
                    this.phoneNumber.localNumber = value;
                },
                enumerable: true,
                configurable: true
            });
            TelephoneNumber.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.countryPrefix = this.countryPrefix;
                result.areaCode = this.areaCode;
                result.localNumber = this.localNumber;
                return result;
            };
            TelephoneNumber.prototype.toContactPointParameters = function () {
                var result = _super.prototype.toContactPointParameters.call(this);
                result.countryPrefix = this.countryPrefix;
                result.areaCode = this.areaCode;
                result.localNumber = this.localNumber;
                return result;
            };
            TelephoneNumber.prototype.toString = function () {
                return this.phoneNumber.toString();
            };
            TelephoneNumber.prototype.isValid = function () {
                return this.phoneNumber.isValid();
            };
            TelephoneNumber.prototype.isEmpty = function () {
                return this.phoneNumber.isEmpty();
            };
            return TelephoneNumber;
        }(ContactPoint));
        Model.TelephoneNumber = TelephoneNumber;
        var Email = /** @class */ (function (_super) {
            __extends(Email, _super);
            function Email(def) {
                var _this = _super.call(this, def) || this;
                if (def != null) {
                    _this.email = def.Email;
                }
                return _this;
            }
            Email.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.email = this.email;
                return result;
            };
            Email.prototype.toContactPointParameters = function () {
                var result = _super.prototype.toContactPointParameters.call(this);
                result.email = this.email;
                return result;
            };
            Email.prototype.toString = function () {
                return this.email;
            };
            Email.prototype.isEmpty = function () {
                return _.isEmpty(this.email);
            };
            return Email;
        }(ContactPoint));
        Model.Email = Email;
        var Website = /** @class */ (function (_super) {
            __extends(Website, _super);
            function Website(def) {
                var _this = _super.call(this, def) || this;
                if (def != null) {
                    _this.url = def.Url;
                }
                return _this;
            }
            Website.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.url = this.url;
                return result;
            };
            Website.prototype.toString = function () {
                return this.url;
            };
            Website.prototype.isEmpty = function () {
                return _.isEmpty(this.url);
            };
            return Website;
        }(ContactPoint));
        Model.Website = Website;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
