namespace RemeCare.Patient {
    interface IPersonalisedStructuralTherapyActionScope
        extends Shared.Framework.IBaseScope,
            ng.ui.bootstrap.IModalScope {
        carePlanId: Shared.Contract.Guid;
        carePlanStartDate: Date;

        structuralTherapyAction: Contract.Therapy.Read.IStructuralTherapyActionSummary;

        personalisedAction: StructuralTherapyActionPersonalisation;
        previousSelectedConfiguredTherapyAction: ConfiguredTherapyAction;
        selectedConfiguredTherapyAction: ConfiguredTherapyAction;
        configuredTherapyActionGrid: Shared.Framework.Grid.Grid<ConfiguredTherapyAction>;
        form: ng.IFormController;

        minDate: Date;

        nextDate: string;
        lastDate: string;
        readableDurationOfAction: string;
        actions: {
            reload(): void;
        };
        editRights: {
            readOnly: boolean;
        };

        modifyConfiguration(configuration?: ConfiguredTherapyAction): void;
        canEdit(configuration: ConfiguredTherapyAction): boolean;
        cancel(): void;
        confirm(): void;
    }

    class PersonalisedStructuralTherapyActionController extends Shared.Framework.ControllerBase<
        IPersonalisedStructuralTherapyActionScope
    > {
        constructor(
            protected $scope: IPersonalisedStructuralTherapyActionScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.modifyConfiguration = (c) => this.modifyConfiguration(c);
            $scope.canEdit = (c) => this.canEdit(c);
        }

        public $onInit(): void {
            this.buildGrids();
            this.$scope.$watch(
                (s: IPersonalisedStructuralTherapyActionScope) => s.personalisedAction,
                (newValue, oldValue) => {
                    if (newValue !== oldValue && newValue != null) {
                        this.setData();
                    }
                }
            );
            if (this.$scope.personalisedAction) {
                this.setData();
            }
        }

        public getReadableTime(date: Date): string {
            return Shared.DateHelper.timeHumanReadible(date);
        }

        private setData(): void {
            const firstValue = _(this.$scope.personalisedAction.configuredTherapyActions)
                .chain()
                .sortBy((t) => t.validFromDate)
                .first()
                .value();

            if (firstValue && moment(firstValue.validFromDate).isBefore(this.$scope.carePlanStartDate)) {
                firstValue.validFromDate = this.$scope.carePlanStartDate;
            }

            this.$scope.configuredTherapyActionGrid.setData(this.$scope.personalisedAction.configuredTherapyActions);
            this.$scope.selectedConfiguredTherapyAction = _(this.$scope.personalisedAction.configuredTherapyActions)
                .chain()
                .filter((cta) => cta.validFromDate <= Shared.DateHelper.today())
                .sortBy((cta) => cta.validFromDate)
                .last()
                .value();
            this.setNextAndLastDate();
            this.$scope.readableDurationOfAction = this.getReadableTime(
                this.$scope.selectedConfiguredTherapyAction.durationOfAction
            );
        }

        private async setNextAndLastDate(): Promise<void> {
            this.$scope.lastDate = null;
            this.$scope.nextDate = null;

            // The nextDate & lastdate cannot be calculated starting from sequence 2
            if (this.$scope.structuralTherapyAction.Sequence !== 1) {
                return;
            }

            try {
                if (this.$scope.selectedConfiguredTherapyAction.periodicities.length > 0) {
                    if (
                        moment(this.$scope.selectedConfiguredTherapyAction.validUntilDate)
                            .startOf('day')
                            .isBefore(moment().startOf('day'))
                    ) {
                        // Previous version
                        const dates = await this.carePlanApiSvc.getNextAndLastScheduledDateAsync(
                            this.$scope.structuralTherapyAction.Id,
                            this.$scope.carePlanId,
                            Shared.DateHelper.toServerDateString(
                                moment(this.$scope.selectedConfiguredTherapyAction.validUntilDate)
                                    .add(1, 'days')
                                    .toDate()
                            )
                        );
                        const lastDateTime = Shared.DateHelper.serverDateStringToDateTime(dates.Last);
                        if (
                            lastDateTime != null &&
                            moment(lastDateTime).isAfter(
                                moment(this.$scope.selectedConfiguredTherapyAction.validFromDate)
                            )
                        ) {
                            this.$scope.lastDate = Shared.DateHelper.dateTimeHumanReadible(lastDateTime);
                        }
                    } else {
                        if (moment(this.$scope.selectedConfiguredTherapyAction.validFromDate).isAfter(moment())) {
                            // Future version
                            const dates = await this.carePlanApiSvc.getNextAndLastScheduledDateAsync(
                                this.$scope.structuralTherapyAction.Id,
                                this.$scope.carePlanId,
                                Shared.DateHelper.toServerDateString(
                                    this.$scope.selectedConfiguredTherapyAction.validFromDate
                                )
                            );
                            const nextDateTime = Shared.DateHelper.serverDateStringToDateTime(dates.Next);
                            if (nextDateTime != null) {
                                this.$scope.nextDate = Shared.DateHelper.dateTimeHumanReadible(nextDateTime);
                            }
                        } else {
                            // Current version
                            const dates = await this.carePlanApiSvc.getNextAndLastScheduledDateAsync(
                                this.$scope.structuralTherapyAction.Id,
                                this.$scope.carePlanId,
                                Shared.DateHelper.momentToServerDateTimeString(moment())
                            );
                            const nextDateTime = Shared.DateHelper.serverDateStringToDateTime(dates.Next);
                            if (nextDateTime != null) {
                                this.$scope.nextDate = Shared.DateHelper.dateTimeHumanReadible(nextDateTime);
                            }
                        }
                    }
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private calcMinDate(): void {
            const today = moment(Shared.DateHelper.today());
            let fromDate: moment.Moment;
            if (this.$scope.selectedConfiguredTherapyAction.id) {
                const index = this.$scope.personalisedAction.configuredTherapyActions.length - 1;
                fromDate =
                    index >= 0
                        ? moment(this.$scope.personalisedAction.configuredTherapyActions[index].validFromDate)
                        : today;
            } else {
                fromDate = moment(_(this.$scope.personalisedAction.configuredTherapyActions).last().validFromDate);
            }
            this.$scope.minDate = fromDate.isAfter(today)
                ? fromDate.add(1, 'days').toDate()
                : today.add(1, 'days').toDate();
        }

        private buildGrids(): void {
            const template = `<div ng-if= "(row.entity.durationOfAction != null || row.entity.possibleActionDurationOptionId != null) && row.entity.isPersonalised" class="text-center vertical-center">  <span class="fa-stack fa-sm" > <i class="fa fa-calendar-o fa-stack-2x" style="font-size:17px; margin-top:4px;"></i> <i class="fa fa-clock-o fa-stack-1x" style="font-size:12px; margin:4px"></i></span></div>`;

            this.$scope.configuredTherapyActionGrid = this.gridBuilderSvc
                .createGridBuilder<ConfiguredTherapyAction>()
                .addColumn('validFromDate', 'General.Versions.ValidFrom', { cellFilter: 'date: "shortDate"' })
                .addColumn('validUntilDate', 'General.Versions.ValidUntil', { cellFilter: 'date: "shortDate"' })
                .addIconColumn(
                    'Views.PatientFile.CarePlans.PersonalisedExcluded',
                    (cta) => (cta.isRemovedFromCarePlan ? 'eye-slash' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addIconColumn(
                    'Views.PatientFile.CarePlans.PeriodicityPersonalised',
                    (cta) => (cta.isPeriodicitiesPersonalised ? 'calendar' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addIconColumn(
                    'Views.PatientFile.CarePlans.IntradayTimingPersonalised',
                    (cta) => (cta.isIntradayTimingPersonalised ? 'clock-o' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addCustomColumn('Views.PatientFile.CarePlans.Planning', template, () => {}, { width: '100' })
                .addAction((cta) => {
                    this.$scope.selectedConfiguredTherapyAction = cta;
                    this.setNextAndLastDate();
                    this.$scope.readableDurationOfAction = this.getReadableTime(
                        this.$scope.selectedConfiguredTherapyAction.durationOfAction
                    );
                })
                .addConditionalDeleteButtonColumn(
                    (cta) => !this.canEdit(cta) || this.$scope.editRights.readOnly,
                    (cta) => this.delete(cta),
                    (cta) => this.confirmDelete(cta)
                )
                .setSorting(false)
                .build();
            this.$scope.configuredTherapyActionGrid.setData([]);
        }

        private canEdit(configuredTherapyAction: ConfiguredTherapyAction): boolean {
            if (!configuredTherapyAction) {
                return false;
            }
            const lastPersonal = _(this.$scope.personalisedAction.configuredTherapyActions)
                .chain()
                // .filter(cta => cta.isPersonalised)
                .last()
                .value();
            return (
                lastPersonal != null &&
                configuredTherapyAction.id === lastPersonal.id &&
                configuredTherapyAction.canBeEdited()
            );
        }

        private delete(configuredTherapyAction: ConfiguredTherapyAction): void {
            this.$scope.actions.reload();
        }

        private confirmDelete(configuredTherapyAction: ConfiguredTherapyAction): Promise<boolean> {
            return new Promise<boolean>((resolve) => {
                this.$dialog.confirmBox(
                    'General.Versions.DeleteVersionConfirm',
                    'General.Versions.DeleteVersionConfirmMessage',
                    async () => {
                        try {
                            await this.carePlanApiSvc.deletePersonalisedTherapyActionAsync(
                                this.$scope.carePlanId,
                                this.$scope.structuralTherapyAction.Id,
                                configuredTherapyAction.validFromDate
                            );
                            resolve(true);
                        } catch (e) {
                            this.errorCallback(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private modifyConfiguration(configuration?: ConfiguredTherapyAction): Promise<ConfiguredTherapyAction> {
            return new Promise<ConfiguredTherapyAction>((resolve) => {
                const newConfiguration = configuration ? configuration.copy() : new ConfiguredTherapyAction();
                if (configuration == null) {
                    newConfiguration.periodicities = _(this.$scope.selectedConfiguredTherapyAction.periodicities).map(
                        (p) => p.copy()
                    );
                }
                const lastValue = _(this.$scope.personalisedAction.configuredTherapyActions)
                    .chain()
                    .sortBy((t) => t.validFromDate)
                    .last()
                    .value();
                newConfiguration.isPersonalised = true;
                newConfiguration.resetToDefault = false;
                this.calcMinDate();
                this.modalBuilderFactory
                    .createModalBuilder<ConfiguredTherapyAction>()
                    .setController('editActionCtrl')
                    .setTemplateUrl('views/patient/carePlans/carePlanActions/editAction.html')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        configuredTherapyAction: newConfiguration,
                        minDate: this.$scope.minDate,
                        omitEnabled:
                            this.$scope.structuralTherapyAction.Sequence === 1 &&
                            !this.$scope.structuralTherapyAction.IsMandatoryInCarePlan,
                        carePlanId: this.$scope.carePlanId,
                        structuralTherapyActionId: this.$scope.structuralTherapyAction.Id,
                        defaultConfigurations: this.$scope.personalisedAction.defaultConfigurations,
                        canPersonalisePeriodicity: this.$scope.structuralTherapyAction.CanPersonalisePeriodicity,
                        canPersonaliseDeviationPlanning: this.$scope.structuralTherapyAction
                            .CanPersonaliseDeviationPlanning,
                        previousPersonalised: lastValue.isPersonalised,
                        name: this.$scope.structuralTherapyAction.StructuralTherapyActionName,
                        hasActionDurationOption: this.$scope.structuralTherapyAction.HasActionDurationOption,
                        possibleActionDurationOptions: this.$scope.structuralTherapyAction
                            .PossibleActionDurationOptions,
                    })
                    .setResultCallBack((r) => {
                        this.$scope.actions.reload();
                        resolve(r);
                    })
                    .build();
            });
        }
    }

    remeCarePatientModule.controller(
        'personalisedStructuralTherapyActionController',
        ($scope, $translate, toaster, $dialog, gridBuilderSvc, carePlanApiSvc, modalBuilderFactory) =>
            new PersonalisedStructuralTherapyActionController(
                $scope,
                $translate,
                toaster,
                $dialog,
                gridBuilderSvc,
                carePlanApiSvc,
                modalBuilderFactory
            )
    );
}
