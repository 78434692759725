var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestPartHeaderController = /** @class */ (function () {
                function CareRequestPartHeaderController($translate) {
                    this.$translate = $translate;
                }
                CareRequestPartHeaderController.prototype.$onInit = function () { };
                return CareRequestPartHeaderController;
            }());
            RemeCare.CareRequest.remeCareCareRequestModule.component('rcCarerequestPartHeader', {
                controller: CareRequestPartHeaderController,
                bindings: {
                    careRequestPart: '=',
                },
                templateUrl: 'views/careRequest/directive/careRequestPartHeaderComponent.html'
            });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
