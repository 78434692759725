namespace RemeCare.CarePlanAction {
    export interface IComponentScope extends ng.IScope {
        component: ComponentDescriptor;
        showErrors: boolean;
        readOnly: boolean;
        fullSize: boolean;
        carePlanAction: RemeCare.Model.ActionBase;
        therapyActionPartId: Shared.Contract.Guid;
        uniqueId: Shared.Contract.Guid;
        isActive(): boolean;
        isRequired(): boolean;
    }

    export abstract class ComponentControllerBase<TScope extends IComponentScope> implements ng.IController {
        private componentsWithConditions: QualitativeObservationDescriptor[];
        private determiningRequiredComponent: CareActDescriptor;
        private item: RemeCare.Shared.Contract.IGuidanceItem;

        constructor(
            protected $scope: TScope,
            private readonly guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            $scope.isActive = () => this.isActive();
            $scope.isRequired = () => this.isRequired();
        }

        public $onInit(): void {
            this.$scope.component = _.find(
                this.$scope.carePlanAction.components,
                (c) => c.id === this.$scope.therapyActionPartId
            );
            this.componentsWithConditions = _(this.$scope.carePlanAction.components).filter(
                (c) =>
                    c.actionTypeCode === Shared.Contract.Code.ActionType.QualitativeObservation &&
                    _((c as QualitativeObservationDescriptor).answerConditions).any(
                        (a) => a.GoToId === this.$scope.component.id
                    )
            ) as QualitativeObservationDescriptor[];
            this.determiningRequiredComponent = _(this.$scope.carePlanAction.components)
                .filter((c) => c.actionTypeCode === Shared.Contract.Code.ActionType.CareAct)
                .pop() as CareActDescriptor;

            this.item = this.guidanceApiSvc.getGuidanceItem(this.$scope.uniqueId);
            this.init();
        }

        protected abstract init(): void;

        private isActive(): boolean {
            if (this.componentsWithConditions.length === 0) {
                return true;
            }
            const active = _(this.componentsWithConditions).any(
                (c) =>
                    _.find(
                        c.answerConditions,
                        (a) => a.GoToId === this.$scope.component.id && _(c.value).contains(a.AnswerCodeSetItem.Id)
                    ) != null
            );
            if (!active) {
                this.$scope.component.clearValue();
                if (this.item !== undefined) {
                    this.guidanceApiSvc.toggleItem(this.item.Id, false);
                }
            }
            return active;
        }

        private isRequired(): boolean {
            // see also careplanaction.ts:setAllUnrequired() for override action with linked actions
            if (this.$scope.component.requiredOverriden) {
                if (this.$scope.component === this.determiningRequiredComponent) {
                    return this.$scope.component.required; 
                }
                return (
                    this.$scope.component.requiredForCodeSetItems.filter(
                        (csi) => csi === this.determiningRequiredComponent.value
                    ).length > 0
                );
            } else {
                return this.$scope.component.required;
            }
        }
    }

    export class ComponentDirectiveBase implements ng.IDirective {
        public restrict = 'E';

        public scope: { [boundProperty: string]: string } = {
            therapyActionPartId: '@',
            showErrors: '=',
            readOnly: '=',
            fullSize: '=',
            carePlanAction: '=',
            uniqueId: '@',
        };
    }
}
