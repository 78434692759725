var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var MonitoringPartControllerBase = /** @class */ (function () {
            function MonitoringPartControllerBase(dateHelper, spinnerSvc) {
                this.dateHelper = dateHelper;
                this.spinnerSvc = spinnerSvc;
            }
            MonitoringPartControllerBase.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.initPrivate();
                                this.loaded = false;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.init()];
                            case 2:
                                _a.sent();
                                this.dateChanged(true);
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.loaded = true;
                                this.onPartLoaded();
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            MonitoringPartControllerBase.prototype.initPrivate = function () {
                this.dateInfo = {
                    untilDate: this.globalUntil != null ? this.globalUntil : this.dateHelper.today(),
                    fromDate: this.globalFrom != null ? this.globalFrom :
                        this.dateHelper.subtractDuration(this.dateHelper.today(), this.monitoringPart.defaultPeriod),
                    minFromDate: this.dateHelper.today()
                };
            };
            MonitoringPartControllerBase.prototype.fromChanged = function () {
                this.dateChanged();
                this.onFromChanged();
            };
            MonitoringPartControllerBase.prototype.untilChanged = function () {
                this.dateChanged();
                var today = RemeCare.Shared.DateHelper.today();
                if (this.dateInfo.untilDate && this.dateInfo.untilDate.valueOf() < today.valueOf()) {
                    this.dateInfo.minFromDate = this.dateInfo.untilDate;
                }
                else {
                    this.dateInfo.minFromDate = today;
                }
                this.onUntilChanged();
            };
            MonitoringPartControllerBase.prototype.dateChanged = function (isInitialLoad) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (moment(this.previousFromDate).isSame(this.dateInfo.fromDate) &&
                                    moment(this.previousUntilDate).isSame(this.dateInfo.untilDate)) {
                                    // nothing has changed
                                    return [2 /*return*/];
                                }
                                this.previousFromDate = this.dateInfo.fromDate;
                                this.previousUntilDate = this.dateInfo.untilDate;
                                this.spinnerSvc.show(this.monitoringPart.id);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, , 3, 4]);
                                return [4 /*yield*/, this.onDateChange()];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                this.spinnerSvc.hide(this.monitoringPart.id);
                                if (isInitialLoad) {
                                    this.loaded = true;
                                    this.onPartLoaded();
                                }
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            MonitoringPartControllerBase.prototype.getQuantitativeMeasuringPoints = function () {
                return _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; })
                    .map(function (mps) { return mps; })
                    .map(function (mps) {
                    return {
                        characteristicId: mps.characteristic.Id,
                        unitId: mps.unit ? mps.unit.Id : null
                    };
                })
                    .value();
            };
            MonitoringPartControllerBase.prototype.getQuantitativeReferencePoints = function () {
                return _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis; })
                    .map(function (mps) { return mps; })
                    .map(function (mps) {
                    return {
                        characteristicId: mps.characteristic.Id,
                        unitId: mps.unit ? mps.unit.Id : null
                    };
                })
                    .union(_(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.ReferenceParameterObjective; })
                    .map(function (mps) { return mps; })
                    .map(function (mps) {
                    return {
                        objectiveId: mps.objective.Id
                    };
                }).value())
                    .union(_(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.ReferenceParameterThreshold; })
                    .map(function (mps) { return mps; })
                    .map(function (mps) {
                    return {
                        ruleThresholdId: mps.ruleThreshold.Id
                    };
                }).value())
                    .value();
            };
            MonitoringPartControllerBase.prototype.getUntilDate = function () {
                return moment(this.dateInfo.untilDate).startOf('day').add(1, 'days').toDate();
            };
            return MonitoringPartControllerBase;
        }());
        Patient.MonitoringPartControllerBase = MonitoringPartControllerBase;
        Highcharts.setOptions({
            global: {
                useUTC: false,
            }
        });
        var HighChartsMonitoringPartController = /** @class */ (function (_super) {
            __extends(HighChartsMonitoringPartController, _super);
            function HighChartsMonitoringPartController($rootScope, $locale, dateHelper, spinnerSvc) {
                var _this = _super.call(this, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.$rootScope.$on(RemeCare.Shared.AppEvents.verticalScrollBar, function () {
                    _.each(_this.chartConfigs, function (config) {
                        if (config.getHighcharts) {
                            try {
                                config.getHighcharts().reflow();
                            }
                            catch (e) {
                                // reflow fails when chart hasn't been drawn yet
                            }
                        }
                    });
                });
                Highcharts.setOptions({
                    lang: {
                        months: $locale.DATETIME_FORMATS.MONTH,
                        weekdays: $locale.DATETIME_FORMATS.DAY,
                        shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH
                    }
                });
                return _this;
            }
            HighChartsMonitoringPartController.prototype.$onChanges = function (onChangesObj) {
                if (onChangesObj['showLegend']) {
                    _.each(this.chartConfigs, function (config) {
                        if (config.getHighcharts) {
                            config.getHighcharts().legendSetVisibility(onChangesObj['showLegend'].currentValue);
                        }
                    });
                }
            };
            HighChartsMonitoringPartController.prototype.hasData = function () {
                var _this = this;
                return _.any(this.chartConfigs, function (cc) { return _this.containsData(cc); });
            };
            HighChartsMonitoringPartController.prototype.getDateFormat = function (includeTime) {
                return this.convertDateFormat(includeTime
                    ? this.$locale.DATETIME_FORMATS.short
                    : this.$locale.DATETIME_FORMATS.shortDate);
            };
            HighChartsMonitoringPartController.prototype.containsData = function (config) {
                return !_(config.series).all(function (s) { return !_(s.data).any() || s.isReference; });
            };
            HighChartsMonitoringPartController.prototype.getXAxisConfig = function (title, noTime) {
                var options = {
                    type: 'datetime',
                    min: moment(this.dateInfo.fromDate).startOf('day').subtract(12, 'hours').valueOf(),
                    max: moment(this.dateInfo.untilDate).endOf('day').valueOf(),
                    title: {
                        text: title,
                        align: 'high'
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPixelInterval: 105,
                    plotLines: [{
                            value: moment().valueOf(),
                            color: '#ccd6eb',
                            dashStyle: 'solid',
                            width: 1
                        }]
                };
                if (noTime) {
                    options.minTickInterval = 24 * 3600 * 1000;
                }
                return options;
            };
            HighChartsMonitoringPartController.prototype.convertDateFormat = function (dateForm) {
                return dateForm
                    .replace('dd', 'd').replace('d', '%d')
                    .replace('mm', 'm').replace('m', '%M')
                    .replace('MM', 'M').replace('M', '%m')
                    .replace('yyyy', 'yy').replace('yy', '%Y')
                    .replace('HH', 'H').replace('H', '%H')
                    .replace('ss', 's').replace('s', '%S');
            };
            return HighChartsMonitoringPartController;
        }(MonitoringPartControllerBase));
        Patient.HighChartsMonitoringPartController = HighChartsMonitoringPartController;
        var ChartMonitoringPartController = /** @class */ (function (_super) {
            __extends(ChartMonitoringPartController, _super);
            function ChartMonitoringPartController() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            ChartMonitoringPartController.prototype.getMaxYScaleValue = function (graph) {
                return this.getLimitedMaxYValue(this.monitoringPart, graph.getMaxYValue());
            };
            ChartMonitoringPartController.prototype.getLimitedMaxYValue = function (monitoringPartChart, value) {
                if (value == null)
                    return null;
                var max;
                if (monitoringPartChart.ordinateMaxValue != null) {
                    max = monitoringPartChart.ordinateMaxValue;
                }
                else {
                    var percentage = monitoringPartChart.ordinatePercentageAboveHighest != null
                        ? monitoringPartChart.ordinatePercentageAboveHighest
                        : 5;
                    var maxValue = value;
                    max = maxValue + (maxValue * percentage / 100);
                }
                return max;
            };
            ChartMonitoringPartController.prototype.getMinYScaleValue = function (graph) {
                var min;
                if (this.monitoringPart.ordinateMinValue != null) {
                    min = this.monitoringPart.ordinateMinValue;
                }
                else {
                    var percentage = this.monitoringPart.ordinatePercentageBelowLowest != null
                        ? this.monitoringPart.ordinatePercentageBelowLowest
                        : 5;
                    var minValue = graph.getMinYValue();
                    if (minValue == null)
                        return null;
                    min = minValue - (minValue * percentage / 100);
                }
                return min;
            };
            return ChartMonitoringPartController;
        }(HighChartsMonitoringPartController));
        Patient.ChartMonitoringPartController = ChartMonitoringPartController;
        var MonitoringPartComponentBase = /** @class */ (function () {
            function MonitoringPartComponentBase() {
                this.bindings = {
                    carePlanIds: '<',
                    globalFrom: '<',
                    globalUntil: '<',
                    hideWhenNoData: '<',
                    monitoringPart: '<',
                    onFromChanged: '&',
                    onPartLoaded: '&',
                    onUntilChanged: '&',
                    patientCode: '<',
                    patientId: '<',
                    showLegend: '<',
                    widthClass: '@',
                };
            }
            return MonitoringPartComponentBase;
        }());
        Patient.MonitoringPartComponentBase = MonitoringPartComponentBase;
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
