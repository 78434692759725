namespace RemeCare.Patient {
    import Patient = Contract.Patient.Read.IPatient;

    interface IPatientSimpleSearchScope extends Shared.Framework.IBaseScope {
        data: { patient: Patient; patientNumber: string };
        patientName: string;
        isSelectedFromModal: boolean;
        isInternalUser: boolean;
        searchPatient(): void;
        clearPatient(): void;
    }

    class PatientSimpleSearchController extends Shared.Framework.ControllerBase<IPatientSimpleSearchScope> {
        // @ngInject
        constructor(
            $scope: IPatientSimpleSearchScope,
            $translate,
            toaster,
            private authservice: Shared.Framework.AuthService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.searchPatient = () => this.searchPatient();
            $scope.clearPatient = () => this.clearPatient();
            this.$onInit();
        }

        public $onInit(): void {
            this.$scope.isSelectedFromModal = false;
            this.$scope.isInternalUser = this.authservice.hasRight(
                Shared.Framework.AuthContext.patientInternal,
                Shared.Framework.AuthRight.Read
            );
            this.$scope.data = { patient: null, patientNumber: null };
        }

        private searchPatient(): void {
            this.modalBuilderFactory
                .createModalBuilder<Patient>()
                .setController('inboxPatientSearchController as searchCtrl')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setTemplateUrl('views/inbox/alarms/patientSearch.html')
                .setResultCallBack(r => {
                    this.selectPatient(r);
                })
                .build();
        }

        private selectPatient(patient: Patient): void {
            this.$scope.isSelectedFromModal = true;
            this.$scope.data.patient = patient;
            this.$scope.data.patientNumber = patient.PatientNumber.toString();
        }

        private clearPatient(): void {
            this.$scope.isSelectedFromModal = false;
            this.$scope.data.patient = null;
            this.$scope.data.patientNumber = null;
        }
    }

    class PatientSimpleSearchDirective implements ng.IDirective {
        public restrict = 'E';

        public templateUrl = 'views/patient/patientSimpleSearch.html';

        public scope: { [boundProperty: string]: string } = {
            data: '=ngModel',
            patientNumber: '=patientNumber',
            patientNumberSearch: '=patientNumberSearch',
        };

        public controller = PatientSimpleSearchController;
    }

    remeCarePatientModule.directive('rcPatientSimpleSearch', () => new PatientSimpleSearchDirective());
}
