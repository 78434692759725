'use strict';

namespace RemeCare.Agenda {
    class RemeCareAgendaConfiguration extends Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.addRoute(
                'agenda',
                '/Agenda', // Be careful changing this route as this is being referenced in the integration API
                'views/baseView.html',
                null,
                null,
                null,
                null,
                null,
                {
                    redirectTo: 'agenda.lastAgenda',
                }
            );

            this.add(
                'agenda.lastAgenda',
                {
                    url: '/LastAgenda',
                    component: 'rcLastAgenda',
                },
                ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring']
            );

            this.add(
                'agenda.myagenda',
                {
                    url: '/MyAgenda?patientData',
                    component: 'rcMyAgenda',
                },
                ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'],
                {
                    context: Shared.Framework.AuthContext.agendaOwn,
                    right: Shared.Framework.AuthRight.Read,
                }
            );

            this.add(
                'agenda.teamagenda',
                {
                    url: ' / TeamAgenda',
                    component: 'rcTeamAgenda',
                },
                ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'],
                {
                    context: Shared.Framework.AuthContext.agendaTeam,
                    right: Shared.Framework.AuthRight.Read,
                }
            );

            this.add(
                'agenda.colleagueagenda',
                {
                    url: ' / ColleagueAgenda',
                    component: 'rcColleagueAgenda',
                },
                ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'],
                {
                    context: Shared.Framework.AuthContext.agendaColleague,
                    right: Shared.Framework.AuthRight.Read,
                }
            );
        }
    }

    angular.module('RemeCare.Agenda').config(($stateProvider) => new RemeCareAgendaConfiguration($stateProvider));
}
