var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var VersionValidityChange = RemeCare.Shared.Framework.Model.VersionValidityChange;
        var CarePlanDetailComponentControllerBase = /** @class */ (function () {
            function CarePlanDetailComponentControllerBase($dialog, patientSvc, toaster, therapyApiSvc, authservice, gridBuilderSvc, modalBuilderFactory) {
                this.$dialog = $dialog;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
                this.therapyApiSvc = therapyApiSvc;
                this.authservice = authservice;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            CarePlanDetailComponentControllerBase.prototype.$onInit = function () {
                this.loadDataAsync();
                this.buildVersionsGrid();
            };
            CarePlanDetailComponentControllerBase.prototype.addVersionAsync = function (detail) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, new Promise(function (resolve) {
                                var minVersion = _(_this.versionsGrid.getData())
                                    .chain()
                                    .sortBy(function (x) { return x.validFromDate; })
                                    .reverse()
                                    .first()
                                    .value();
                                var minDate = minVersion ? moment(minVersion.validFromDate) : moment();
                                var scope = {
                                    definition: {
                                        Id: _this.definitionId,
                                        Text: _this.title,
                                    },
                                    carePlanId: _this.carePlanId,
                                    minDate: minDate.add(1, 'day'),
                                };
                                _this.extendAddScope(scope, detail);
                                _this.modalBuilderFactory
                                    .createModalBuilder()
                                    .setController(_this.getAddController())
                                    .setTemplateUrl(_this.getAddTemplateUrl())
                                    .setScope(scope)
                                    .setResultCallBack(function (r) {
                                    resolve(r);
                                    _this.setVersionGridData();
                                })
                                    .build();
                            })];
                    });
                });
            };
            CarePlanDetailComponentControllerBase.prototype.isVersionEditDisabled = function (version) {
                // If version is in the future, everyone can edit
                if (!moment().isAfter(moment(version.validFromDate))) {
                    return false;
                }
                // Otherwise, if user doesn't have patientCarePlanCorrectParameterValue right, cannot edit
                if (!this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientCarePlanCorrectParameterValue, RemeCare.Shared.Framework.AuthRight.Write)) {
                    return true;
                }
                // Otherwise, the very first version (in time) can be edited. See SPEC-88
                if (_.last(this.versionsGrid.getData()) !== version) {
                    return true;
                }
                return false;
            };
            CarePlanDetailComponentControllerBase.prototype.buildVersionsGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc.createGridBuilder();
                gridBuilder = this.addGridRows(gridBuilder);
                this.versionsGrid = gridBuilder
                    .addColumn('validFromDate', 'General.ValidFrom', { cellFilter: 'date: "shortDate"' })
                    .addColumn('validUntilDate', 'General.ValidUntil', { cellFilter: 'date: "shortDate"' })
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (v) { return _this.editVersionAsync(v); }, function (v) { return _this.isVersionEditDisabled(v); })
                    .addConditionalDeleteButtonColumn(function (v) {
                    return (moment().isAfter(moment(v.validFromDate), 'day') ||
                        moment().isSame(moment(v.validFromDate), 'day'));
                }, function () { return _this.setVersionGridData(); }, function (v) { return _this.deleteVersionAsync(v); })
                    .setSorting(false)
                    .build();
                this.setVersionGridData();
            };
            CarePlanDetailComponentControllerBase.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var carePlans, carePlan, e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getCarePlansAsync(this.patientId)];
                            case 1:
                                carePlans = _a.sent();
                                carePlan = _.find(carePlans, function (cp) { return cp.Id === _this.carePlanId; });
                                this.getPartAsync(carePlan.TherapyId);
                                this.getRightAsync(carePlan.Id);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanDetailComponentControllerBase.prototype.getPartAsync = function (therapyId) {
                return __awaiter(this, void 0, void 0, function () {
                    var p, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.therapyApiSvc.getCareRequestPartAsync(therapyId, this.definitionId)];
                            case 1:
                                p = _a.sent();
                                this.part = p;
                                this.onPartRetrieved();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanDetailComponentControllerBase.prototype.getRightAsync = function (carePlanId) {
                return __awaiter(this, void 0, void 0, function () {
                    var rights, right, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getTherapyBusinessContextRightsAsync(this.patientId, {
                                        carePlanId: carePlanId,
                                    })];
                            case 1:
                                rights = _a.sent();
                                right = _.find(rights, function (r) {
                                    return r.TherapyBusinessContext ===
                                        RemeCare.Shared.Contract.Code.TherapyBusinessContextCode.CarePlanConfiguration;
                                });
                                this.editRight =
                                    // tslint:disable-next-line:no-bitwise
                                    right != null ? (right.ExecutionRight & RemeCare.Shared.Framework.AuthRight.Write) !== 0 : false;
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanDetailComponentControllerBase.prototype.deleteVersionAsync = function (detail) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, new Promise(function (resolve) {
                                _this.$dialog.confirmBox('General.Versions.DeleteVersionConfirm', 'General.Versions.DeleteVersionConfirmMessage', function () { return __awaiter(_this, void 0, void 0, function () {
                                    var fromDate, e_4;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                fromDate = RemeCare.Shared.DateHelper.toServerDateString(moment(detail.validFromDate).toDate());
                                                _a.label = 1;
                                            case 1:
                                                _a.trys.push([1, 3, , 4]);
                                                return [4 /*yield*/, this.deleteAsync(fromDate)];
                                            case 2:
                                                _a.sent();
                                                resolve(true);
                                                return [3 /*break*/, 4];
                                            case 3:
                                                e_4 = _a.sent();
                                                this.toaster.error(e_4);
                                                resolve(false);
                                                return [3 /*break*/, 4];
                                            case 4: return [2 /*return*/];
                                        }
                                    });
                                }); }, function () {
                                    resolve(false);
                                });
                            })];
                    });
                });
            };
            CarePlanDetailComponentControllerBase.prototype.editVersionAsync = function (detail) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        if (moment().isAfter(detail.validFromDate)) {
                            return [2 /*return*/, this.addVersionAsync(detail)];
                        }
                        return [2 /*return*/, new Promise(function (resolve) {
                                _this.modalBuilderFactory
                                    .createModalBuilder()
                                    .setController('changeVersionValidityCtrl')
                                    .setTemplateUrl('views/changeVersionValidity.html')
                                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                                    .setScope({
                                    change: new VersionValidityChange({
                                        ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(detail.validFromDate),
                                    }),
                                    versions: _(_this.versionsGrid.getData()).map(function (x) {
                                        return {
                                            ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(x.validFromDate),
                                            ValidUntilDate: RemeCare.Shared.DateHelper.toServerDateString(x.validUntilDate),
                                        };
                                    }),
                                })
                                    .setResultCallBack(function (r) { return __awaiter(_this, void 0, void 0, function () {
                                    var e_5;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                _a.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, this.changeValidityAsync(r)];
                                            case 1:
                                                _a.sent();
                                                resolve(detail);
                                                this.setVersionGridData();
                                                return [3 /*break*/, 3];
                                            case 2:
                                                e_5 = _a.sent();
                                                this.toaster.error(e_5);
                                                return [3 /*break*/, 3];
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); })
                                    .build();
                            })];
                    });
                });
            };
            return CarePlanDetailComponentControllerBase;
        }());
        Patient.CarePlanDetailComponentControllerBase = CarePlanDetailComponentControllerBase;
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
