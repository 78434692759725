var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var PartyRoleType = RemeCare.Shared.Contract.Code.PartyRoleType;
        var RelatedPersonsController = /** @class */ (function (_super) {
            __extends(RelatedPersonsController, _super);
            function RelatedPersonsController($scope, $translate, toaster) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                $scope.getPatientContacts = function () { return _this.getPatientContacts(); };
                $scope.getHealthCareNonProfessionalContacts = function () { return _this.getHealthCareNonProfessionalContacts(); };
                $scope.getHealthCareProfessionalContacts = function () { return _this.getHealthCareProfessionalContacts(); };
                return _this;
            }
            RelatedPersonsController.prototype.$onInit = function () { };
            RelatedPersonsController.prototype.getContactPartyRoles = function () {
                if (this.$scope.person == null)
                    return [];
                return _(this.$scope.person.partyRoles)
                    .filter(function (pr) { return pr.PartyRoleType.Id === PartyRoleType.ContactPerson; });
            };
            RelatedPersonsController.prototype.getPatientContacts = function () {
                return _(this.getContactPartyRoles())
                    .filter(function (pr) { return pr.ContactFor.PartyRoleType.Id === PartyRoleType.Patient; });
            };
            RelatedPersonsController.prototype.getHealthCareNonProfessionalContacts = function () {
                if (this.$scope.person == null)
                    return [];
                return _(this.$scope.person.partyRoles)
                    .filter(function (pr) { return pr.PartyRoleType.Id === PartyRoleType.HealthCareNonProfessional; });
            };
            RelatedPersonsController.prototype.getHealthCareProfessionalContacts = function () {
                return _(this.getContactPartyRoles())
                    .filter(function (pr) { return pr.ContactFor.PartyRoleType.Id === PartyRoleType.HealthCareProfessional; });
            };
            return RelatedPersonsController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('relatedPersonsCtrl', function ($scope, $translate, toaster) { return new RelatedPersonsController($scope, $translate, toaster); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
