namespace RemeCare.Patient {
    import SearchResult = Shared.Contract.ISearchResult;
    import ReadPerson = Contract.Party.Read.IPerson;
    import Person = RemeCare.Model.Person;
    import ContactPerson = RemeCare.Model.ContactPersonPerson;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import Guid = Shared.Contract.Guid;

    class AddContactPersonController implements ng.IComponentController {
        public countries: EnumCodeTranslation[];
        public communicationLanguages: EnumCodeTranslation[];
        public languages: EnumCodeTranslation[];
        public genders: EnumTranslation[];
        public contactPersonsGrid: Shared.Framework.Grid.Grid<ReadPerson>;
        public person: Person;
        public contactPerson: ReadPerson;
        public patientId: Guid;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams: RemeCare.Patient.AddContactPersonStateParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public $onInit(): void {
            this.patientId = this.$stateParams.patientId;
            this.buildGrids();
            this.loadDataAsync();
        }

        public $onChanges(): void {
            this.buildGrids();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [this.countries, this.communicationLanguages, this.languages, this.genders] = await Promise.all([
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getLanguagesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): Promise<SearchResult<ReadPerson>> {
            const query: Contract.Party.Read.Query.IFindPersonsQuery = {
                page,
                pageSize,
                sortField,
                sortOrder,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                address: criteria.address,
                zipCode: criteria.zipCode,
                city: criteria.city,
                country: criteria.country,
                communicationLanguage: criteria.communicationLanguage,
                language: criteria.language,
                gender: criteria.gender,
                birthDate: Shared.DateHelper.toServerDateString(criteria.birthDate),
                identificationNumber: criteria.identificationNumber,
                phoneNumber: criteria.phoneNumber ? criteria.phoneNumber.toServerWrite() : null,
                email: criteria.email,
            };

            try {
                const r = await this.partyApiSvc.findPersonsAsync(query);
                return r;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrids(): void {
            this.contactPersonsGrid = this.gridBuilderSvc
                .createGridBuilder<ReadPerson>((p, ps, sf, sd, c) => this.executeSearchAsync(p, ps, sf, sd, c))
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addDateColumn('DateOfBirth', 'General.DateOfBirth', 'shortDate')
                .addColumn('ZipCity', 'General.City', { width: '**' })
                .addSelectButtonColumn(selected => this.personSelectedAsync(selected))
                .setRowDetailsTemplate('views/patient/patientInfo/contactPersons/contactPersonDetail.html', 80)
                .setSearchCriteria(c => {
                    c.phoneNumber =
                        c.phoneNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.phoneNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.phoneNumber);
                    c.mobileNumber =
                        c.mobileNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.mobileNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.mobileNumber);
                })
                .build();
        }

        private async personSelectedAsync(result: ReadPerson): Promise<void> {
            try {
                const p = await this.partyApiSvc.getPersonDetailsAsync(result.PartyId, true);
                const contactPerson = new ContactPerson(p, null);
                this.$state.go('patients.patientfile.patientInfo.contactPersons.editContactPerson', {
                    contactPerson,
                    patientId: this.$stateParams.patientId,
                    contactPersonId: contactPerson.id,
                } as RemeCare.Patient.EditContactPersonStateParams);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.component('addContactPerson', {
        templateUrl: 'views/patient/patientInfo/contactPersons/addContactPerson.html',
        controller: AddContactPersonController,
        bindings: {},
    });
}
