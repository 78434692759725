module RemeCare.Management {
    import Guid = Shared.Contract.Guid;
    import ApplicationProfileAssignment = Shared.Contract.Read.IApplicationProfileAssignment;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    
    interface IRevokeApplicationProfileScope extends ng.ui.bootstrap.IModalScope, Shared.Framework.IBaseScope {
        fixedProfile: boolean;
        profile: EnumTranslation;
        reason: string;
        applicationProfiles: Array<EnumTranslation>;
        form: ng.IFormController;
        confirm(): void;
    }

    class RevokeApplicationProfileController extends Shared.Framework.ControllerBase<IRevokeApplicationProfileScope> {

        constructor(
            $scope: IRevokeApplicationProfileScope,
            $translate,
            toaster,
            private partyId: Guid,
            private toRevoke: EnumTranslation,
            private securityApiSvc: Shared.Framework.Service.SecurityApiSvc) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.profile = this.toRevoke;
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.securityApiSvc.revokeProfile(this.partyId, this.$scope.profile.Id, this.$scope.reason)
                .success(() => this.$scope.$close())
                .error(e => this.errorCallback(e));
        }
    }

    remeCareManagementModule.controller('revokeApplicationProfileCtrl', (
        $scope,
        $translate,
        toaster,
        partyId,
        toRevoke,
        securityApiSvc
    ) => new RevokeApplicationProfileController(
        $scope,
        $translate,
        toaster,
        partyId,
        toRevoke,
        securityApiSvc
    ));
}