var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var GlobalActorRoleService = /** @class */ (function (_super) {
            __extends(GlobalActorRoleService, _super);
            function GlobalActorRoleService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            /** @deprecated */
            GlobalActorRoleService.prototype.searchSummaries = function (query) {
                return this.get('GlobalActorRoles', query, 'globalActorRoles.search');
            };
            GlobalActorRoleService.prototype.searchSummariesAsync = function (query) {
                return this.getPromise('GlobalActorRoles', query, 'globalActorRoles.search');
            };
            GlobalActorRoleService.prototype.createGlobalActorRoleAsync = function (globalActorRole) {
                return this.postPromise('GlobalActorRoles', globalActorRole);
            };
            GlobalActorRoleService.prototype.deleteGlobalActorRoleAsync = function (id) {
                return this.deletePromise("GlobalActorRoles/" + id);
            };
            GlobalActorRoleService.prototype.getGlobalActorRoleAsync = function (id) {
                return this.getPromise("GlobalActorRoles/" + id);
            };
            GlobalActorRoleService.prototype.addPartyRole = function (id, partyRoleId, partyRoleType) {
                return this.postPromise("GlobalActorRoles/" + id + "/PartyRoles/" + partyRoleType + "/" + partyRoleId, null);
            };
            GlobalActorRoleService.prototype.deletePartyRoleAsync = function (id, partyRoleId) {
                return this.deletePromise("GlobalActorRoles/" + id + "/PartyRoles/" + partyRoleId);
            };
            GlobalActorRoleService.prototype.saveRemarkAsync = function (id, remark) {
                return this.postPromise("GlobalActorRoles/" + id + "/Remark", {
                    remark: remark,
                });
            };
            GlobalActorRoleService.prototype.saveTranslationsAsync = function (id, translations) {
                return this.postPromise("GlobalActorRoles/" + id + "/Translations", {
                    translations: translations,
                }, 'globalActorRoleTranslations');
            };
            GlobalActorRoleService.prototype.setActiveAsync = function (id, isActive) {
                return this.postPromise("GlobalActorRoles/" + id + "/Active/" + isActive, null);
            };
            GlobalActorRoleService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return GlobalActorRoleService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        Management.GlobalActorRoleService = GlobalActorRoleService;
        Management.remeCareManagementModule.service('globalActorRoleSvc', GlobalActorRoleService);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
