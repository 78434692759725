var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        // @ngInject
        var PatientCareTeamController = /** @class */ (function () {
            function PatientCareTeamController(toaster, $stateParams, patientSvc, authservice, gridBuilderSvc) {
                this.toaster = toaster;
                this.$stateParams = $stateParams;
                this.patientSvc = patientSvc;
                this.authservice = authservice;
                this.gridBuilderSvc = gridBuilderSvc;
                this.onlyActiveTeamMembers = true;
            }
            PatientCareTeamController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.patientId = this.$stateParams.patientId;
                                _a = this;
                                return [4 /*yield*/, this.getCarePlansCanEdit()];
                            case 1:
                                _a.careTeamsCanEdit = _b.sent();
                                this.editRight = this.careTeamsCanEdit.length > 0;
                                this.buildInformalsGrid();
                                if (this.editRight) {
                                    this.patientSvc
                                        .getPossibleActorRoles(this.patientId, true)
                                        .success(function (r) {
                                        var actorRoles = _(r)
                                            .chain()
                                            .map(function (tar) {
                                            return _(tar.ActorRoles).map(function (ar) {
                                                ar.Therapy = tar.Therapy;
                                                ar.Text = ar.Name + " (" + ar.Therapy.Text + ")";
                                                return ar;
                                            });
                                        })
                                            .flatten()
                                            .filter(function (ar) { return _(_this.careTeamsCanEdit).any(function (tbc) { return tbc.Therapy === ar.Therapy.Id; }); })
                                            .value();
                                        _this.professionalActorRoles = _(actorRoles).filter(function (ar) { return ar.PartyType === RemeCare.Shared.Contract.Code.PartyType.Person; });
                                        _this.organisationActorRoles = _(actorRoles).filter(function (ar) { return ar.PartyType === RemeCare.Shared.Contract.Code.PartyType.Organisation; });
                                    })
                                        .error(function (e) { return _this.toaster.error(e); });
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientCareTeamController.prototype.getCarePlansCanEdit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var businessContextRights, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getTherapyBusinessContextRightsAsync(this.patientId)];
                            case 1:
                                businessContextRights = _a.sent();
                                return [2 /*return*/, _(businessContextRights).filter(function (t) {
                                        return t.TherapyBusinessContext ===
                                            RemeCare.Shared.Contract.Code.TherapyBusinessContextCode.CarePlanCareTeamChange &&
                                            t.ExecutionRight === 15;
                                    })];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientCareTeamController.prototype.buildInformalsGrid = function () {
                var _this = this;
                this.informalsGrid = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, sd, c) { return _this.searchInformals(p, ps, sf, sd, c); })
                    .addColumn('LastName', 'General.LastName', { visibleWhenSmall: true })
                    .addColumn('FirstName', 'General.FirstName', { visibleWhenSmall: true })
                    .addColumn('Relationship.Text', 'Views.PatientFile.CareTeam.RelationshipToPatient', {
                    visibleWhenSmall: true,
                })
                    .addColumn('PhoneNumber', 'General.Phone', {
                    cellFilter: 'phone',
                    enableSorting: false,
                    visibleWhenSmall: false,
                })
                    .addColumn('MobileNumber', 'General.Mobile', {
                    cellFilter: 'phone',
                    enableSorting: false,
                    visibleWhenSmall: false,
                })
                    .addDateColumn('ValidFromDate', 'General.ValidFrom', 'shortDate', { visibleWhenSmall: false })
                    .addDateColumn('ValidUntilDate', 'General.ValidUntilIncluding', 'shortDate', {
                    visibleWhenSmall: false,
                })
                    .build();
                this.informalsGrid.pagingOptions.pageSize = 5;
                this.informalsGrid.search();
            };
            PatientCareTeamController.prototype.searchInformals = function (page, pageSize, sortField, sortOrder, criteria) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.patientSvc
                    .getInformals(this.patientId, {
                    page: page,
                    pageSize: pageSize,
                    sortField: sortField,
                    sortOrder: sortOrder,
                })
                    .success(function (r) { return deferred.resolve(r); })
                    .error(function (e) {
                    _this.toaster.error(e);
                    deferred.reject();
                });
                return deferred.promise();
            };
            return PatientCareTeamController;
        }());
        Patient.remeCarePatientModule.component('rcCareTeam', {
            controller: PatientCareTeamController,
            templateUrl: 'views/patient/careTeam/careTeam.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
