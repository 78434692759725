namespace RemeCare.Guidance {
    import GuidanceApiService = RemeCare.Shared.Framework.Service.GuidanceApiService;

    class GuidanceIconController implements ng.IComponentController {
        public uniqueId: string;
        public item: RemeCare.Shared.Contract.IGuidanceItem;

        // @ngInject
        constructor(private readonly guidanceApiSvc: GuidanceApiService) {}

        public $onInit(): void {
            this.item = this.guidanceApiSvc.getGuidanceItem(this.uniqueId);
        }

        public showGuidance(): void {
            this.guidanceApiSvc.toggleItem(this.item.Id);
        }
    }

    remeCareGuidanceModule.component('rcGuidanceIcon', {
        bindings: {
            component: '<',
            iconFunc: '<',
            uniqueId: '@',
        },
        controller: GuidanceIconController,
        templateUrl: 'views/guidance/directive/guidanceIcon.html',
    });
}
