namespace RemeCare {
    export class ValidationService {
        constructor(private readonly nationalNumberSvc: NationalNumberService) {}

        public verifyEmail(email: string): boolean {
            if (!email) {
                return true;
            }

            const validMail = email.match(
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
            );

            return validMail != null;
        }
    }

    remeCareAppModule.service('validationSvc', ValidationService);
}
