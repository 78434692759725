namespace RemeCare.Patient {
    import Address = RemeCare.Model.Address;
    import ContactPoint = RemeCare.Model.ContactPoint;

    class PersonalInfoController implements ng.IComponentController {
        public person: RemeCare.Model.PatientPerson;
        public addressesGrid: Shared.Framework.Grid.Grid<Address>;
        public contactPointsGrid: Shared.Framework.Grid.Grid<ContactPoint>;
        public onAddressesChange: () => void;
        public onContactPointsChange: () => void;
        public readonly: boolean;

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly $timeout: ng.ITimeoutService
        ) {}

        public $onInit(): void {
            this.buildGrids();
            this.setData();
        }

        public $onChanges(onChangesObj: angular.IOnChangesObject): void {
            if (onChangesObj.person) {
                this.$timeout(() => {
                    this.setData();
                });
            }
        }

        public async addAddress(): Promise<void> {
            await this.editAddress();
        }

        public async addContact(): Promise<void> {
            await this.editContact();
        }

        private setData(): void {
            if (this.person != null) {
                this.addressesGrid.setData(this.person.addresses);
                const contactPoints = (this.person.getAllNumbers() as ContactPoint[]).concat(
                    this.person.emailAddresses
                );
                this.contactPointsGrid.setData(contactPoints);
            } else {
                this.addressesGrid.setData([]);
                this.contactPointsGrid.setData([]);
            }
        }

        private buildGrids(): void {
            this.addressesGrid = this.gridBuilderSvc
                .createGridBuilder<Address>()
                .addEnumColumn(
                    'contactPointUsageType',
                    'General.Type',
                    Shared.Framework.ReferenceDataTypes.contactPointUsageType
                )
                .addColumn('addressLine1', 'General.Address')
                .addColumn('zipCode', 'General.Zipcode')
                .addColumn('city', 'General.City')
                .addEnumColumn('country', 'General.Country', Shared.Framework.ReferenceDataTypes.country)
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    (a) => this.editAddress(a),
                    (a) => this.editAddress(a),
                    (a) => a.isReadOnly || this.readonly
                )
                .setExternalSorting(false)
                .build();

            this.contactPointsGrid = this.gridBuilderSvc
                .createGridBuilder<ContactPoint>()
                .addEnumColumn('type', 'General.ContactPoint', Shared.Framework.ReferenceDataTypes.contactPointType)
                .addEnumColumn(
                    'contactPointUsageType',
                    'General.Type',
                    Shared.Framework.ReferenceDataTypes.contactPointUsageType
                )
                .addColumn('toString()', 'General.Number')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    (c) => this.editContact(c),
                    (c) => this.editContact(c),
                    (c) => c.isReadOnly || this.readonly
                )
                .setExternalSorting(false)
                .build();
        }

        private async editAddress(address?: Address): Promise<Address> {
            const result = new Promise<Address>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<Address>()
                    .setController('editAddressCtrl as addressCtrl')
                    .setTemplateUrl('views/shared/editAddress.html')
                    .setResolve({
                        address: () =>
                            address
                                ? angular.copy(address)
                                : RemeCare.Model.ContactPointFactory.createFromType(
                                      Shared.Contract.Code.ContactPointType.Address
                                  ),
                        partyId: () => this.person.partyId,
                        currentAddresses: () =>
                            address
                                ? this.person.addresses.filter((ad) => ad.id !== address.id)
                                : this.person.addresses,
                        isReadOnly: () => this.readonly,
                    })
                    .setLarge()
                    .setResultCallBack((r) => {
                        r ? resolve(r) : resolve(address);
                    })
                    .build();
            });

            this.onAddressesChange();

            return result;
        }

        private async editContact(contact?: ContactPoint): Promise<ContactPoint> {
            const result = await new Promise<ContactPoint>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<ContactPoint>()
                    .setController('editContactCtrl as contactCtrl')
                    .setTemplateUrl('views/shared/editContact.html')
                    .setResolve({
                        contactPoint: () => angular.copy(contact),
                        partyId: () => this.person.partyId,
                        currentEmails: () =>
                            contact
                                ? this.person.emailAddresses.filter((cp) => cp.id !== contact.id)
                                : this.person.emailAddresses,
                        currentPhoneNumbers: () =>
                            contact
                                ? this.person.telephoneNumbers.filter((cp) => cp.id !== contact.id)
                                : this.person.telephoneNumbers,
                        currentFaxNumbers: () =>
                            contact
                                ? this.person.faxNumbers.filter((cp) => cp.id !== contact.id)
                                : this.person.faxNumbers,
                        currentMobileNumbers: () =>
                            contact
                                ? this.person.mobileNumbers.filter((cp) => cp.id !== contact.id)
                                : this.person.mobileNumbers,
                        isReadOnly: () => this.readonly,
                    })
                    .setResultCallBack((r) => {
                        r ? resolve(r) : resolve(contact);
                    })
                    .build();
            });

            this.onContactPointsChange();

            return result;
        }
    }

    remeCarePatientModule.component('rcContactInfo', {
        templateUrl: 'views/shared/editPersonInfo.html',
        controller: PersonalInfoController,
        bindings: {
            person: '<',
            onContactPointsChange: '&',
            onAddressesChange: '&',
            readonly: '=ngDisabled',
        },
    });
}
