/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import TranslateService = ng.translate.ITranslateService;
    import SentencePart = Contract.Patient.Read.ISentencePart;

    interface IMedicationSchemaScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.MedicationSchemaDefinition;
        medicationConfigurations: Array<{
            name: string;
            grid: Shared.Framework.Grid.Grid<CareRequestMedication>;
            addMedication(): void;
        }>;
    }

    class MedicationSchemaController extends CareRequestPartControllerBase<IMedicationSchemaScope> {
        public static $inject = [
            '$scope',
            '$translate',
            'toaster',
            'therapyApiSvc',
            'gridBuilderSvc',
            'medicationSearchSvc',
            'modalBuilderFactory',
            'patientSvc',
            'careRequestSvc',
        ];
        private isForTherapyAction: boolean;
        constructor(
            $scope: IMedicationSchemaScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly medicationSearchSvc: Shared.Service.MedicationSearchSvc,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientService: Patient.PatientService,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.isForTherapyAction =
                this.$scope.careRequestPart.type.Id ===
                Shared.Contract.Code.CareRequestPartType.MedicationSchemaTherapyAction;

            const productIds = _(this.$scope.careRequest.careRequestMedications)
                .chain()
                .filter(
                    (crm) =>
                        (this.isForTherapyAction && crm.structuralTherapyActionId != null) ||
                        (!this.isForTherapyAction && crm.structuralTherapyActionId == null)
                )
                .map((crm) => crm.productId)
                .value();
            if (productIds.length > 0) {
                // No need to await this
                this.initProducts(productIds);
            } else {
                this.buildGrids();
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(
                this.$scope.careRequestPart,
                this.$scope.careRequestTemplate,
                this.$scope.careRequest
            );
        }

        private async initProducts(productIds: Shared.Contract.Guid[]): Promise<void> {
            try {
                const medications = await this.medicationSearchSvc.getMedicationsAsync(productIds);
                _(medications).forEach((p) =>
                    _(this.$scope.careRequest.careRequestMedications).forEach((crm) => {
                        if (crm.productId === p.ProductId) {
                            crm.medication = p;
                            if (!crm.medication.IsActive) {
                                crm.medication.MedicationName +=
                                    ' (' + this.$translate.instant('General.Inactive') + ')';
                            }
                        }
                    })
                );
                this.buildGrids();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async buildGrids(): Promise<void> {
            if (this.isForTherapyAction) {
                try {
                    const result = await this.therapyApiSvc.getMedicationActions(
                        this.$scope.careRequest.therapyId,
                        this.$scope.careRequest.id,
                        this.$scope.careRequestPart.medicationActionId
                    );
                    this.$scope.medicationConfigurations = _(result).map((stap) => {
                        const grid = this.buildGrid(stap.StartTypeAdHoc);
                        _(this.$scope.careRequest.careRequestMedications)
                            .filter((c) => c.structuralTherapyActionId === stap.Id)
                            .forEach(async (m) => {
                                const res = await this.texualizeSchema(m);
                                m.medicationSchema = res;
                                grid.addRow(m);
                            });
                        return {
                            name: stap.Name,
                            grid: grid,
                            addMedication: () => this.addMedication(grid, stap.Id, stap.StartTypeAdHoc),
                        };
                    });
                } catch (e) {
                    this.toaster.error(e);
                }
            } else {
                const grid = this.buildGrid();
                _(this.$scope.careRequest.careRequestMedications)
                    .filter((c) => c.structuralTherapyActionId == null)
                    .forEach(async (m) => {
                        const res = await this.texualizeSchema(m);
                        m.medicationSchema = res;
                        grid.addRow(m);
                    });
                this.$scope.medicationConfigurations = [
                    {
                        name: null,
                        grid: grid,
                        addMedication: () => this.addMedication(grid),
                    },
                ];
            }
        }

        private async texualizeSchema(careRequestMedication: CareRequestMedication): Promise<string> {
            if (
                careRequestMedication.periodicities.length === 1 &&
                careRequestMedication.periodicities[0].unstructuredPeriodicity
            ) {
                const result = careRequestMedication.periodicities[0].unstructuredPeriodicity;
                return result;
            } else {
                try {
                    const tokens = await this.patientService.tokenizeMedicationSchemaAsync(
                        _(careRequestMedication.periodicities).map((p) => p.toWriteModel())
                    );
                    return this.renderMedicationSchema(
                        tokens,
                        this.$translate,
                        careRequestMedication.medication.UnitName
                    );
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }

        private renderMedicationSchema(tokens: SentencePart[], $translate: TranslateService, unitName: string): string {
            const renderPipe: string[] = new Array<string>();
            angular.forEach(tokens, (t) => {
                const params = {};
                for (let i = 0; i < t.Parameters.length; i++) {
                    params['value' + i] = t.Parameters[i];
                }
                if (t.TemplateResourceKey === 'General.Periodicity.Tokens.DayTimingDose') {
                    params['value' + 1] = unitName;
                }
                let text;
                if (t.TemplateResourceKey.toString().indexOf('General.') !== -1) {
                    text = $translate.instant(t.TemplateResourceKey, params);
                } else {
                    text = t.TemplateResourceKey.toString();
                }
                renderPipe.push(text);
            });
            return renderPipe.join('');
        }

        private buildGrid(isAdHoc?: boolean): Shared.Framework.Grid.Grid<CareRequestMedication> {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<CareRequestMedication>()
                .addConditionallyStyledColumn(
                    'medication.MedicationName',
                    'General.MedicationName',
                    'medication.IsActive',
                    {
                        red: false,
                    }
                )
                .addColumn('medication.ActiveSubstance', 'General.ActiveSubstanceDose')
                .addColumn('medicationSchema', 'General.Schema')
                .addColumn('validFromDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                .addColumn('validUntilDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' });
            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn((crm) => this.editMedication(crm, isAdHoc))
                    .addDeleteButtonColumn(
                        (d) =>
                            (this.$scope.careRequest.careRequestMedications = _(
                                this.$scope.careRequest.careRequestMedications
                            ).without(d))
                    );
            } else {
                gridBuilder = gridBuilder.addEditButtonWithPromiseFunctionColumn(
                    (crm) => this.editMedication(crm, isAdHoc),
                    'eye-open'
                );
            }
            return gridBuilder.build();
        }

        private addMedication(
            grid: Shared.Framework.Grid.Grid<CareRequestMedication>,
            structuralTherapyActionId?: Shared.Contract.Guid,
            isAdHoc?: boolean
        ) {
            if (isAdHoc == null) {
                isAdHoc = false;
            }

            this.modalBuilderFactory
                .createModalBuilder<CareRequestMedication>()
                .setTemplateUrl('views/careRequest/directive/medication/careRequestMedication.html')
                .setController('careRequestMedicationCtrl')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    readOnly: false,
                    patientLang:
                        this.$scope.careRequest.patient != null
                            ? this.$scope.careRequest.patient.communicationLanguage
                            : null,
                    prescription: null,
                    hideIntraDayConfig: true,
                    isAdHoc: isAdHoc,
                    structuralTherapyActionId: structuralTherapyActionId,
                })
                .setResultCallBack(async (m) => {
                    try {
                        if (!m) {
                            return;
                        }
                        const res = await this.texualizeSchema(m);
                        m.medicationSchema = res;
                        grid.addRow(m);
                        this.$scope.careRequest.careRequestMedications.push(m);
                    } catch (e) {
                        this.toaster.error(e);
                    }
                })
                .build();
        }

        private editMedication(medication: CareRequestMedication, isAdHoc?: boolean): Promise<CareRequestMedication> {
            return new Promise<CareRequestMedication>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<CareRequestMedication>()
                    .setTemplateUrl('views/careRequest/directive/medication/careRequestMedication.html')
                    .setController('careRequestMedicationCtrl')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        readOnly: false,
                        patientLang:
                            this.$scope.careRequest.patient != null
                                ? this.$scope.careRequest.patient.communicationLanguage
                                : null,
                        prescription: angular.copy(medication),
                        hideIntraDayConfig: true,
                        isAdHoc: isAdHoc || medication.periodicities[0].unstructuredPeriodicity != null,
                        structuralTherapyActionId: medication.structuralTherapyActionId,
                    })
                    .setResultCallBack(async (m) => {
                        if (!m) {
                            return;
                        }
                        try {
                            const res = await this.texualizeSchema(m);
                            m.medicationSchema = res;
                            resolve(m);
                            this.$scope.careRequest.careRequestMedications = _(
                                this.$scope.careRequest.careRequestMedications
                            ).without(medication);
                            this.$scope.careRequest.careRequestMedications.push(m);
                        } catch (e) {
                            this.toaster.error(e);
                        }
                    })
                    .build();
            });
        }
    }

    class MedicationSchemaDirective extends CareRequestPartDirectiveBase {
        public controller = MedicationSchemaController;
        public templateUrl = 'views/careRequest/directive/medication/medicationSchema.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestMedicationSchema', () => new MedicationSchemaDirective());
}
