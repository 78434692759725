var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        Patient.remeCarePatientModule.component('rcPictureDocument', {
            bindings: {
                alternativeImage: '<',
                document: '<',
                onCloseButtonClicked: '&',
            },
            templateUrl: 'patient/controllers/mediaDocuments/followUpDocuments/pictureDocument/pictureDocument.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
