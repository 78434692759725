var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var MyAgendaFilterController = /** @class */ (function () {
            function MyAgendaFilterController(actorRolesApiSvc, authservice, masterdataSvc, therapyApiSvc, toaster) {
                this.actorRolesApiSvc = actorRolesApiSvc;
                this.authservice = authservice;
                this.masterdataSvc = masterdataSvc;
                this.therapyApiSvc = therapyApiSvc;
                this.toaster = toaster;
            }
            MyAgendaFilterController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, actorRoles, globalActorRoles, allActorRoles_1, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                actorRoles = void 0;
                                globalActorRoles = void 0;
                                return [4 /*yield*/, Promise.all([
                                        this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true }),
                                        this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager ||
                                            this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager
                                            ? this.actorRolesApiSvc.findGlobalActorRolesAsync()
                                            : Promise.resolve([]),
                                        this.masterdataSvc.getReferenceDataAsync(RemeCare.Shared.Framework.ReferenceDataTypes.actionAssignmentType),
                                        this.masterdataSvc.getCarePlanActionStatuses(),
                                        this.masterdataSvc.getCarePlanActionTypes(),
                                        this.therapyApiSvc.getTherapiesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), actorRoles = _a[0], globalActorRoles = _a[1], this.actionAssignmentTypes = _a[2], this.actionStatuses = _a[3], this.actionTypes = _a[4], this.therapies = _a[5];
                                allActorRoles_1 = _(actorRoles).map(function (ar) { return ({
                                    Id: ar.Id,
                                    Text: ar.IsGlobal ? ar.Text : ar.Text + " (" + ar.Therapy.Text + ")",
                                }); });
                                _(globalActorRoles)
                                    .filter(function (ga) { return _.find(allActorRoles_1, function (ar) { return ar.Id === ga.Id; }) == null; })
                                    .forEach(function (ga) { return allActorRoles_1.push(ga); });
                                this.actorRoles = _(allActorRoles_1).sortBy(function (ar) { return ar.Text; });
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            MyAgendaFilterController.prototype.clearFilter = function () {
                this.filter.patientData.patient = null;
                this.filter.actionTypes = [];
                this.filter.statuses = [];
                this.filter.therapyIds = [];
            };
            return MyAgendaFilterController;
        }());
        Agenda.remeCareAgendaModule.component('rcMyAgendaFilter', {
            bindings: {
                $close: '&',
                $dismiss: '&',
                filter: '<',
            },
            controller: MyAgendaFilterController,
            templateUrl: 'agenda/myAgenda/myAgendaFilter/myAgendaFilter.html',
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
