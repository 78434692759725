namespace RemeCare.CareRequest.Directive {
    import GroupAssignment = Management.GroupAssignment;
    import Framework = Shared.Framework;
    import PartyType = Shared.Contract.Code.PartyType;
    import ActorRoleInclusionOption = Shared.Framework.Model.ActorRoleInclusionOption;

    export interface ICareRequestExtendedRoleConfiguration extends Shared.Framework.Model.CareRequestRoleConfiguration {
        possibleGroupAssignments: GroupAssignment[];
        selected: GroupAssignment;
        displayName: string;
    }

    class CareRequestRolesController extends CareRequestPartComponentControllerBase implements ng.IComponentController {
        public careRequestPart: Shared.Framework.Model.CareRequestRoleDefinition;
        public grid: Shared.Framework.Grid.Grid<CareRequestRole>;
        public membersGrid: Shared.Framework.Grid.Grid<GroupAssignment>;
        public translationValues: {
            x: number;
        };
        public groups: Shared.Contract.IEntityTranslation[];
        public selectedGroup: Shared.Contract.Guid;
        public useGroups: boolean; // Slider on UI
        public canUseGroupsWithSelect: boolean;
        public canUseGroupsWithSearch: boolean;
        public allGroupMembers: Management.GroupAssignment[];
        public groupMembers: Management.GroupAssignment[];
        public careRequest: CareRequest;
        public stepType: Shared.Contract.Code.CareRequestStepType;
        public careRequestTemplate: CareRequestTemplate;
        public readOnly: boolean;
        public careRequestRoleConfigurations: ICareRequestExtendedRoleConfiguration[];
        public collapseGrid: boolean;
        public careRequestInitiatorPerson: Contract.Party.Read.IPerson;
        public careRequestRequestorPerson: Contract.Party.Read.IPerson;

        constructor(
            private readonly $timeout: ng.ITimeoutService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Framework.Toaster,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly $rootScope: ng.IRootScopeService
        ) {
            super();
            this.$rootScope.$on('CAREREQUEST_ROLE_SELECT', (event, role: IApplyForRole) => {
                if (role.rolePart !== RolePart.CareTeamRole) {
                    return;
                }
                if (
                    this.careRequestRoleConfigurations.filter((con) => con.role.actorRoleId === role.actorRoleId)
                        .length > 0
                ) {
                    switch (role.partyRoleType) {
                        case PartyRoleType.HealthCareProfessional:
                            const party = RequestPerson.createFromHealthCareProfessional(
                                role.party as Contract.HealthCareParty.Read.IHealthCareProfessionalParty
                            );
                            const careRequestRole = CareRequestRole.createForParty(role.actorRoleId, party);
                            careRequestRole.partyRoleId = role.party.PartyRoleId;
                            if (!this.gridAlreadyContainsRole(careRequestRole)) {
                                this.grid.addRow(careRequestRole);
                            }
                            break;
                        case PartyRoleType.HealthCareOrganisation:
                            const organisation = RequestOrganisation.createFromHealthCareOrganisation(
                                role.party as Contract.HealthCareParty.Read.IHealthCareOrganisationParty
                            );
                            const careRequestRoleOrg = CareRequestRole.createForParty(role.actorRoleId, organisation);
                            careRequestRoleOrg.partyRoleId = role.party.PartyRoleId;
                            if (!this.gridAlreadyContainsRole(careRequestRoleOrg)) {
                                this.grid.addRow(careRequestRoleOrg);
                            }
                            break;
                    }
                }
            });
            this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECT', (event, role: IApplyForRole) => {
                if (role.rolePart !== RolePart.CareTeamRole) {
                    return;
                }
                if (
                    this.careRequestRoleConfigurations.filter((con) => con.role.actorRoleId === role.actorRoleId)
                        .length > 0
                ) {
                    const newData = this.grid
                        .getData()
                        .filter((crr) => crr.roleId !== role.actorRoleId || crr.partyRoleId !== role.party.PartyRoleId);
                    this.careRequest.careRequestRoles = newData;
                    this.grid.setData(this.careRequest.careRequestRoles);
                }
            });
        }

        public async $onInit(): Promise<void> {
            this.buildGrid();
            this.useGroups = false;

            this.careRequestTemplate.careRequestSetup.careRequestParts.forEach((crp) => {
                if (crp.type.Id === Shared.Contract.Code.CareRequestPartType.CareRequestRole) {
                    const rolePart = crp as Shared.Framework.Model.CareRequestRoleDefinition;
                    rolePart.careRequestRoleConfigurations.forEach((crc) => {
                        crc.isVisible = !this.maxReached(crc);
                    });
                }
            });

            this.careRequestRoleConfigurations = this.careRequestPart.careRequestRoleConfigurations.map((crc) =>
                this.map(crc)
            );

            this.careRequestRoleConfigurations.map((crc) => this.completeDisplayNameConfiguration(crc));

            const ids = _(this.careRequest.careRequestRoles)
                .chain()
                .map((crr) => crr.partyRoleId)
                .filter((id) => id != null)
                .value();
            if (ids.length > 0) {
                const persons = await this.partyApiSvc.getPersonsAsync(ids);
                _(persons).forEach((professional) => {
                    const role = _.find(this.careRequest.careRequestRoles, (crr) => {
                        const personPartyRoleIds = _(professional.PartyRoles).map((pr) => pr.Id);
                        return _(personPartyRoleIds).contains(crr.partyRoleId);
                    });
                    role.requestParty = RequestPerson.createFromPerson(professional);
                });
                const healthCareOrganisation = await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync(
                    ids
                );
                _(healthCareOrganisation).forEach((organisation) => {
                    const role = _.find(
                        this.careRequest.careRequestRoles,
                        (crr) => crr.partyRoleId === organisation.PartyRoleId
                    );
                    role.requestParty = RequestOrganisation.createFromHealthCareOrganisation(organisation);
                });
            }
            this.groups = await this.partyApiSvc.getRelatedGroupsAsync(null);

            this.canUseGroupsWithSelect =
                (this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                    this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.TherapyProvider) &&
                this.groups.length > 0;

            this.canUseGroupsWithSearch =
                this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager;

            this.careRequestRoleConfigurations.map((crc) => this.completeDisplayNameConfiguration(crc));
        }

        public async preferredGroupFirstName(): Promise<string> {
            const person = await this.determineSearchPersonSuggestion();
            return person !== undefined ? person.FirstName : '';
        }

        public async preferredGroupLastName(): Promise<string> {
            const person = await this.determineSearchPersonSuggestion();
            return person !== undefined ? person.LastName : '';
        }

        public groupsChanged(): void {
            this.$timeout(async () => {
                this.membersGrid.setData(null);
                this.collapseGrid = true;
                if (this.selectedGroup != null && this.useGroups) {
                    const groups = await this.partyApiSvc.getGroupsAsync([this.selectedGroup]);
                    this.groupMembers = groups.map(
                        (garm) => new Management.GroupAssignment(garm, this.mapToGroupName(garm.GroupId))
                    );
                    this.allGroupMembers = this.groupMembers;
                    if (this.useGroups) {
                        this.membersGrid.setData(this.groupMembers);
                        this.collapseGrid = this.membersGrid.getData().length > 10;
                    }
                }
            });
        }

        public isRoleRequired(config: Shared.Framework.Model.CareRequestRoleConfiguration): boolean {
            const min = this.getMinimumNumber(config.role);
            return min > 0;
        }

        public maxReached(config: Shared.Framework.Model.CareRequestRoleConfiguration): boolean {
            const max = this.getMaximalNumber(config.role);
            if (max == null) {
                return false;
            }
            const roles = _(this.careRequest.careRequestRoles).filter((crr) => crr.roleId === config.role.actorRoleId);
            return roles.length >= max;
        }

        public isValidConfig(config: Shared.Framework.Model.CareRequestRoleConfiguration): boolean {
            const roles = _(this.careRequest.careRequestRoles).filter((crr) => crr.roleId === config.role.actorRoleId);
            const minimal = this.getMinimumNumber(config.role);
            const maximal = this.getMaximalNumber(config.role);
            if (roles.length < minimal) {
                return false;
            }
            if (maximal != null && roles.length > maximal) {
                return false;
            }
            return true;
        }

        public clicked(config: ICareRequestExtendedRoleConfiguration): void {
            if (this.useGroups) {
                config.possibleGroupAssignments = this.getGroupAssignmentsThatMatchConfiguration(config);
            }

            if (
                this.useGroups &&
                config.possibleGroupAssignments != null &&
                config.possibleGroupAssignments.length > 0
            ) {
                this.modalBuilderFactory
                    .createModalBuilder<{ groupMembers: GroupAssignment[]; needsMemberOutOfGroup: boolean }>()
                    .setController('careRequestRoleController')
                    .setTemplateUrl('views/careRequest/directive/careRequestRoleModal.html')
                    .setScope({
                        config: config,
                    })
                    .setResultCallBack((r) => {
                        r.groupMembers.forEach((gm) => this.groupAssignmentSelectedAsync(gm, config));
                        if (r.needsMemberOutOfGroup) {
                            this.addRole(config);
                        }
                    })
                    .build();
            } else {
                this.addRole(config);
            }
        }

        private gridAlreadyContainsRole(role: CareRequestRole): boolean {
            if (!role) {
                return false;
            }

            const currentData = this.grid.getData();

            if (!currentData || currentData.length === 0) {
                return false;
            }

            const filteredData = currentData.filter(
                (crr) =>
                    crr.requestParty.id === role.requestParty.id &&
                    crr.roleId === role.roleId &&
                    crr.partyRoleId === role.partyRoleId
            );

            if (!filteredData || filteredData.length === 0) {
                return false;
            }

            return true;
        }

        private async determineSearchPersonSuggestion(): Promise<Contract.Party.Read.IPerson> {
            // Workaround bug with Acceptance step type
            if (this.careRequestTemplate.careRequestStepSequence === this.careRequestTemplate.totalSteps) {
                // Scenario: Single step or jumped immediate to acceptance, then use Requestor
                if (this.careRequest.CareRequestInitiator === undefined) {
                    await this.loadRequestorPerson();
                    if (this.careRequestRequestorPerson !== undefined) {
                        return this.careRequestRequestorPerson;
                    }
                } else {
                    // Scenario: If last step and continuing from an Application phase, then use Initiator
                    await this.loadInitiatorPerson();
                    if (this.careRequestInitiatorPerson !== undefined) {
                        return this.careRequestInitiatorPerson;
                    }
                }
            }
            // Other scenarios
            return undefined;
        }

        private async loadInitiatorPerson(): Promise<void> {
            // Load only once per careRequest
            if (this.careRequestInitiatorPerson === undefined) {
                const persons = await this.partyApiSvc.getPersonsAsync([this.careRequest.CareRequestInitiator]);
                if (persons.length !== 0) {
                    this.careRequestInitiatorPerson = persons[0];
                }
            }
        }

        private async loadRequestorPerson(): Promise<void> {
            // Load every time
            if (this.careRequest.careRequestor.healthCareProfessionalId === undefined) {
                this.careRequestRequestorPerson = undefined;
                return;
            }
            const persons = await this.partyApiSvc.getPersonsAsync([
                this.careRequest.careRequestor.healthCareProfessionalId,
            ]);
            if (persons.length !== 0) {
                this.careRequestRequestorPerson = persons[0];
            }
        }

        private async groupAssignmentSelectedAsync(
            ga: GroupAssignment,
            config: ICareRequestExtendedRoleConfiguration
        ): Promise<void> {
            if (this.groupMembers !== undefined) {
                this.groupMembers = this.groupMembers.filter((gm) => gm.id !== ga.id);
            }

            const actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
            const partyRoleIds = actorRoleInclusionDetails
                .map((arid) => arid.partyRoleId)
                .filter((id) => id != null)
                .value();

            if (config.role.partyType === PartyType.Person) {
                const requestPerson = RequestPerson.createFromPerson(ga.person);
                requestPerson.group = ga;
                const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, requestPerson);
                switch (config.role.radioSelection) {
                    case ActorRoleInclusionOption.Individuals:
                        careRequestRole.partyRoleId = _.find(ga.person.PartyRoles, (pr) =>
                            _.contains(partyRoleIds, pr.Id)
                        ).Id;
                        break;
                    case ActorRoleInclusionOption.Patient:
                        careRequestRole.partyRoleId = _.find(
                            ga.person.PartyRoles,
                            (pr) => pr.Type.Id === Contract.Core.Codes.PartyRoleTypeCode.Patient
                        ).Id;
                        break;
                    case ActorRoleInclusionOption.Specialties:
                        careRequestRole.partyRoleId = _.find(ga.person.PartyRoles, (pr) =>
                            _.some(
                                actorRoleInclusionDetails.value(),
                                (arid) =>
                                    pr.HealthCareProfessionalType.Id === arid.healthCareProfessionalTypeId &&
                                    (!arid.healthCareSpecialtyProfessionId ||
                                        _.contains(
                                            _.map(pr.HealthCareProffesionalSpecialties, (hcps) => hcps.Id),
                                            arid.healthCareSpecialtyProfessionId
                                        ))
                            )
                        ).Id;
                        break;
                }
                this.grid.addRow(careRequestRole);
            } else {
                let partyRoleId;
                switch (config.role.radioSelection) {
                    case ActorRoleInclusionOption.Organisations:
                        partyRoleId = _.find(ga.organisation.PartyRoles, (pr) =>
                            _.contains(partyRoleIds, pr.PartyRoleId)
                        ).PartyRoleId;
                        break;
                    case ActorRoleInclusionOption.OrganisationTypes:
                        partyRoleId = ga.organisation.PartyRoles[0].PartyRoleId;
                        break;
                }
                try {
                    const hco = await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([
                        partyRoleId,
                    ]);
                    const party = RequestOrganisation.createFromHealthCareOrganisation(hco[0]);
                    party.group = ga;
                    const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, party);
                    careRequestRole.partyRoleId = partyRoleId;
                    this.grid.addRow(careRequestRole);
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }

        private completeDisplayNameConfiguration(config: ICareRequestExtendedRoleConfiguration): void {
            const maxNumber = this.getMaximalNumber(config.role);
            const minNumber = this.getMinimumNumber(config.role);
            config.displayName = config.role.name;

            if (this.isRequestorRole(config.role)) {
                config.displayName = config.displayName.concat(
                    ` (${this.$translate.instant('Views.CareRequest.Details.BesidesRequestor')} ) `
                );
            }

            if (minNumber === 0 || (maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber) {
                config.displayName = config.displayName.concat(' ( ');
            }

            if (minNumber === 0) {
                config.displayName = config.displayName.concat(
                    this.$translate.instant('Views.CareRequest.Details.NotRequired')
                );
            }
            if (minNumber === 0 && ((maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber)) {
                config.displayName = config.displayName.concat(' , ');
            }

            if (maxNumber != null && maxNumber !== minNumber) {
                config.displayName = config.displayName.concat(
                    `${this.$translate.instant('Views.CareRequest.Details.Maximal')}  ${maxNumber}`
                );
            }
            if (maxNumber === minNumber) {
                config.displayName = config.displayName.concat(
                    `${this.$translate.instant('Views.CareRequest.Details.XRequired', { minimalNumber: minNumber })}  `
                );
            }

            if (minNumber === 0 || (maxNumber != null && maxNumber !== minNumber) || maxNumber === minNumber) {
                config.displayName = config.displayName.concat(' ) ');
            }
        }

        private mapToGroupName(id: Shared.Contract.Guid): string {
            return _.find(this.groups, (gr) => gr.Id === id).Text;
        }

        private careRequestRoleDeleted(careRequestRole: CareRequestRole): void {
            this.$rootScope.$emit('CAREREQUEST_ROLE_UNSELECTED', careRequestRole, RolePart.CareTeamRole);
            if (careRequestRole.requestParty.group === null) {
                return;
            }
            const gmToAdd = _.find(this.allGroupMembers, (gm) => gm.id === careRequestRole.requestParty.group.id);
            if (gmToAdd != null) {
                this.groupMembers = this.groupMembers.concat([gmToAdd]);
            }
        }

        private buildGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<CareRequestRole>()
                .addColumn('getName()', 'General.Name')
                .addActionOnScopeColumn('$ctrl.parent.getRoleName', 'General.Role')
                .addBoolColumn('partyRoleId', 'General.Linked')
                .setRowDetailsTemplate('views/careRequest/directive/contactDetails.html', 150);

            if (!this.readOnly) {
                gridBuilder = gridBuilder
                    .addDeleteButtonColumn((crr) => this.careRequestRoleDeleted(crr))
                    .addEditButtonWithPromiseFunctionColumn((r) => this.editCareRequestRoleAsync(r));
            }

            this.grid = gridBuilder.build();
            this.grid.setData(this.careRequest.careRequestRoles);

            const memberGridBuilder = this.gridBuilderSvc
                .createGridBuilder<GroupAssignment>()
                .addColumn('name', 'General.Name')
                .addColumn('type', 'General.Type', { cellFilter: 'delimitedDisplay:", "', enableSorting: false })
                .addColumn('person.specialties', 'General.Specialty', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false,
                });
            this.membersGrid = memberGridBuilder.build();
        }

        // Used in grid above, do not remove
        // tslint:disable-next-line:typedef
        private getRoleName(careRequestRole: CareRequestRole) {
            const config = _.find(
                this.careRequestRoleConfigurations,
                (c) => c.role.actorRoleId === careRequestRole.roleId
            );
            return config != null ? config.role.name : '';
        }

        private addRole(config: Shared.Framework.Model.CareRequestRoleConfiguration): void {
            const actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
            const partyRoleIds = actorRoleInclusionDetails
                .map((arid) => arid.partyRoleId)
                .filter((id) => id != null)
                .value();
            if (config.role.partyType === Shared.Contract.Code.PartyType.Person) {
                if (partyRoleIds.length > 0) {
                    this.showProfessionalSelectorAsync(config, actorRoleInclusionDetails.value());
                    return;
                } else if (config.canBeSearchedByExternal) {
                    this.showProfessionalSearchModal(config);
                } else {
                    this.addCareRequestRoleAsync(config);
                }
            } else {
                if (partyRoleIds.length > 0) {
                    this.showOrganisationSelectorAsync(config, partyRoleIds);
                    return;
                } else {
                    this.showOrganisationSearchModal(config);
                }
            }
        }

        private getGroupAssignmentsThatMatchConfiguration(
            config: Shared.Framework.Model.CareRequestRoleConfiguration
        ): GroupAssignment[] {
            const actorRoleInclusionDetails = this.getActorRoleInclusionDetails(config);
            const partyRoleIds = actorRoleInclusionDetails
                .map((arid) => arid.partyRoleId)
                .filter((id) => id != null)
                .value();

            if (this.selectedGroup == null) {
                return [];
            }

            switch (config.role.radioSelection) {
                case ActorRoleInclusionOption.Patient:
                    return this.groupMembers.filter(
                        (gm) =>
                            gm.person &&
                            gm.person.partyRoleTypes.some(
                                (prt) => prt.Id === Contract.Core.Codes.PartyRoleTypeCode.Patient
                            )
                    );
                case ActorRoleInclusionOption.Individuals:
                    return this.groupMembers.filter(
                        (gm) =>
                            gm.person &&
                            gm.person.PartyRoles.map((pr) => pr.Id).some((prId) => _.contains(partyRoleIds, prId))
                    );
                case ActorRoleInclusionOption.Specialties:
                    return this.groupMembers.filter(
                        (gm) =>
                            gm.person &&
                            _.some(
                                actorRoleInclusionDetails.value(),
                                (arid) =>
                                    _.contains(
                                        _.map(gm.person.healthCareProfessionalTypes, (hpt) => hpt.Id),
                                        arid.healthCareProfessionalTypeId
                                    ) &&
                                    (!arid.healthCareSpecialtyProfessionId ||
                                        _.contains(
                                            _.map(gm.person.specialties, (sp) => sp.Id),
                                            arid.healthCareSpecialtyProfessionId
                                        ))
                            )
                    );
                case ActorRoleInclusionOption.Organisations:
                    return this.groupMembers.filter(
                        (gm) =>
                            gm.organisation &&
                            gm.organisation.PartyRoles.map((pr) => pr.PartyRoleId).some((prId) =>
                                _.contains(partyRoleIds, prId)
                            )
                    );
                case ActorRoleInclusionOption.OrganisationTypes:
                    return this.groupMembers.filter(
                        (gm) =>
                            gm.organisation &&
                            _.some(
                                actorRoleInclusionDetails.value(),
                                (arid) =>
                                    arid.healthCareOrganisationTypeId === gm.organisation.HealthCareOrganisationType.Id
                            )
                    );
            }
        }

        private getActorRoleInclusionDetails(
            config: Shared.Framework.Model.CareRequestRoleConfiguration
        ): _Chain<Shared.Framework.Model.ActorRoleInclusionDetail> {
            return _(config.role.actorRoleInclusions)
                .chain()
                .map((ari) => ari.actorRoleInclusionDetails)
                .flatten();
        }

        private getExistingPartyRoleIds(
            config: Shared.Framework.Model.CareRequestRoleConfiguration
        ): Shared.Contract.Guid[] {
            return _(this.grid.getData())
                .chain()
                .filter((crr) => crr.roleId === config.role.actorRoleId)
                .map((crr) => crr.partyRoleId)
                .value();
        }

        private async showProfessionalSelectorAsync(
            config: Shared.Framework.Model.CareRequestRoleConfiguration,
            professional: Shared.Framework.Model.ActorRoleInclusionDetail[]
        ): Promise<void> {
            const existingPartyRoleIds = this.getExistingPartyRoleIds(config);
            professional = _(professional).filter((p) => !_(existingPartyRoleIds).contains(p.partyRoleId));
            try {
                let pr: Shared.Framework.Model.ActorRoleInclusionDetail;
                try {
                    pr = await this.modalBuilderFactory.showSelectorAsync<
                        Shared.Framework.Model.ActorRoleInclusionDetail
                    >(professional, ['partyName'], ' ', config.role.name);
                } catch (e) {
                    return;
                }
                const r = await this.partyApiSvc.getPersonsAsync([pr.partyRoleId]);
                const person = RequestPerson.createFromPerson(r[0]);
                const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, person);
                careRequestRole.partyRoleId = pr.partyRoleId;
                if (
                    _.any(this.grid.getData(), (x) => {
                        return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                    })
                ) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                    return;
                }
                this.grid.addRow(careRequestRole);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async showOrganisationSelectorAsync(
            config: Shared.Framework.Model.CareRequestRoleConfiguration,
            organisationIds: Shared.Contract.Guid[]
        ): Promise<void> {
            const existingPartyRoleIds = this.getExistingPartyRoleIds(config);
            organisationIds = _(organisationIds).filter((o) => !_(existingPartyRoleIds).contains(o));
            try {
                const r =
                    organisationIds.length === 0
                        ? []
                        : await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync(organisationIds);
                let org: Contract.HealthCareParty.Read.IHealthCareOrganisationParty;
                try {
                    org = await this.modalBuilderFactory.showSelectorAsync<
                        Contract.HealthCareParty.Read.IHealthCareOrganisationParty
                    >(r, ['Name'], '', config.role.name);
                } catch (e) {
                    return;
                }
                const organisation = RequestOrganisation.createFromHealthCareOrganisation(org);
                const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, organisation);
                careRequestRole.partyRoleId = org.PartyRoleId;
                if (
                    _.any(this.grid.getData(), (x) => {
                        return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                    })
                ) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                    return;
                }
                this.grid.addRow(careRequestRole);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private showProfessionalSearchModal(config: Shared.Framework.Model.CareRequestRoleConfiguration): void {
            const specialties = this.convertToTypeAndSpecialties(this.getActorRoleInclusionDetails(config));
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>()
                .setController('searchHealthCareProfessionalPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                .setScope({
                    title: config.role.name,
                    typeAndSpecialties: specialties,
                })
                .setResultCallBack((r) => {
                    const party = RequestPerson.createFromHealthCareProfessional(r);
                    const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, party);
                    careRequestRole.partyRoleId = r.PartyRoleId;
                    if (
                        _.any(this.grid.getData(), (x) => {
                            return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                        })
                    ) {
                        this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                        return;
                    }
                    this.grid.addRow(careRequestRole);
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private showOrganisationSearchModal(config: Shared.Framework.Model.CareRequestRoleConfiguration): void {
            const orgTypes = this.getActorRoleInclusionDetails(config)
                .map((arid) => arid.healthCareOrganisationTypeId)
                .filter((id) => id != null)
                .value();
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>()
                .setController('searchHealthCareOrganisationPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                .setScope({
                    title: config.role.name,
                    organisationTypes: orgTypes,
                })
                .setResultCallBack((r) => {
                    const party = RequestOrganisation.createFromHealthCareOrganisation(r);
                    const careRequestRole = CareRequestRole.createForParty(config.role.actorRoleId, party);
                    careRequestRole.partyRoleId = r.PartyRoleId;
                    if (
                        _.any(this.grid.getData(), (x) => {
                            return x.partyRoleId === careRequestRole.partyRoleId && x.roleId === careRequestRole.roleId;
                        })
                    ) {
                        this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                        return;
                    }
                    this.grid.addRow(careRequestRole);
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private editCareRequestRoleAsync(careRequestRole: CareRequestRole): Promise<CareRequestRole> {
            const config = _.find(
                this.careRequestPart.careRequestRoleConfigurations,
                (c) => c.role.actorRoleId === careRequestRole.roleId
            );
            return this.showFreeTextModalAsync(config, careRequestRole);
        }

        private async addCareRequestRoleAsync(
            config: Shared.Framework.Model.CareRequestRoleConfiguration
        ): Promise<void> {
            const careRequestRole = CareRequestRole.create(
                config.role.actorRoleId,
                Shared.Contract.Code.PartyType.Person
            );
            try {
                const r = await this.showFreeTextModalAsync(config, careRequestRole);
                if (
                    _.any(this.grid.getData(), (x) => {
                        return r.partyRoleId && x.partyRoleId === r.partyRoleId && x.roleId === r.roleId;
                    })
                ) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.AlreadyUsed'));
                    return;
                }
                this.grid.addRow(r);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private showFreeTextModalAsync(
            config: Shared.Framework.Model.CareRequestRoleConfiguration,
            careRequestRole: CareRequestRole
        ): Promise<CareRequestRole> {
            return new Promise<CareRequestRole>((resolve, reject) => {
                const specialties = this.convertToTypeAndSpecialties(this.getActorRoleInclusionDetails(config));
                this.modalBuilderFactory
                    .createModalBuilder<CareRequestRole>()
                    .setController('careRequestRoleCtrl')
                    .setTemplateUrl('views/careRequest/directive/careRequestRole.html')
                    .setScope({
                        typeAndSpecialties: specialties,
                        configuration: config,
                        careRequestPart: this.careRequestPart,
                        careRequestRole: angular.copy(careRequestRole),
                        title: config.role.name,
                        inAcceptance: this.stepType === Shared.Contract.Code.CareRequestStepType.Acceptance,
                    })
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setResultCallBack((r) => resolve(r))
                    .build();
            });
        }

        private getCareRequestorPart(): Shared.Framework.Model.CareRequestorDefinition {
            return _.find(
                this.careRequestTemplate.careRequestSetup.careRequestParts,
                (p) => p.type.Id === Shared.Contract.Code.CareRequestPartType.CareRequestor
            ) as Shared.Framework.Model.CareRequestorDefinition;
        }

        private getMinimumNumber(role: Shared.Framework.Model.ActorRole): number {
            return this.getBoundary(role, role.minimalNumber);
        }

        private getMaximalNumber(role: Shared.Framework.Model.ActorRole): number {
            return this.getBoundary(role, role.maximalNumber);
        }

        private getBoundary(role: Shared.Framework.Model.ActorRole, normalBoundary: number): number {
            const careRequestorPart = this.getCareRequestorPart();
            if (careRequestorPart == null) {
                return normalBoundary;
            }

            if (
                careRequestorPart.roleRequestingHealthCareProfessional != null &&
                careRequestorPart.roleRequestingHealthCareProfessional.actorRoleId === role.actorRoleId &&
                this.isPresent(
                    Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional,
                    careRequestorPart
                )
            ) {
                // If this is the same professional role as the one requested in the care request part, lower the boundary
                if (normalBoundary > 0) {
                    return normalBoundary - 1;
                }
                return normalBoundary;
            }

            if (
                careRequestorPart.roleRequestingHealthCareOrganisation != null &&
                careRequestorPart.roleRequestingHealthCareOrganisation.actorRoleId === role.actorRoleId &&
                this.isPresent(
                    Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation,
                    careRequestorPart
                )
            ) {
                // If this is the same organisation role as the one requested in the care request part, lower the boundary
                if (normalBoundary > 0) {
                    return normalBoundary - 1;
                }
                return normalBoundary;
            }

            return normalBoundary;
        }

        private isRequestorRole(role: Shared.Framework.Model.ActorRole): boolean {
            const requestorPart = this.getCareRequestorPart();
            if (requestorPart == null) {
                return false;
            }
            return (
                (requestorPart.roleRequestingHealthCareProfessional != null &&
                    requestorPart.roleRequestingHealthCareProfessional.actorRoleId === role.actorRoleId &&
                    this.isPresent(
                        Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional,
                        requestorPart
                    )) ||
                (requestorPart.roleRequestingHealthCareOrganisation != null &&
                    requestorPart.roleRequestingHealthCareOrganisation.actorRoleId === role.actorRoleId &&
                    this.isPresent(
                        Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation,
                        requestorPart
                    ))
            );
        }

        private map(
            config: Shared.Framework.Model.CareRequestRoleConfiguration
        ): ICareRequestExtendedRoleConfiguration {
            const result = config as ICareRequestExtendedRoleConfiguration;
            result.possibleGroupAssignments = [];
            return result;
        }
    }

    remeCareCareRequestModule.component('rcCareRequestRoles', {
        controller: CareRequestRolesController,
        bindings: {
            careRequestTemplate: '=',
            careRequestPart: '=',
            careRequest: '=',
            showErrors: '=',
            stepType: '=',
            readOnly: '=',
            onMatchedPatientChanged: '&',
        },
        templateUrl: 'views/careRequest/directive/careRequestRoles.html',
    });
}
