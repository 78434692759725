module RemeCare.Patient {

    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import ContactPointType = Shared.Contract.Code.ContactPointType;
    import ContactPointUsageType = Shared.Contract.Code.ContactPointUsageType;
    import Guid = Shared.Contract.Guid;

    class EditContactController {
        contactPointTypes: Array<EnumTranslation>;
        contactPointUsageTypes: Array<EnumTranslation>;
        contactPointInfo: {
            contactPointType?: ContactPointType;
        };
        form: Shared.Framework.Directive.IFormController;

        constructor(
            private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            private contactPoint: RemeCare.Model.ContactPoint,
            private toaster: Shared.Framework.Toaster,
            private partyId: Guid,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private partyApiSvc: Core.Services.PartyApiService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private currentEmails: Array<RemeCare.Model.Email>,
            private currentPhoneNumbers: Array<RemeCare.Model.TelephoneNumber>,
            private currentFaxNumbers: Array<RemeCare.Model.TelephoneNumber>,
            private currentMobileNumbers: Array<RemeCare.Model.TelephoneNumber>,
            private isReadOnly: boolean) {
        }

        public $onInit(): void {
            this.contactPointInfo = {}
            this.masterdataSvc.getContactPointTypes()
                .success(r => this.contactPointTypes = _(r).filter(cpt =>
                    _([
                        ContactPointType.Email, ContactPointType.Fax, ContactPointType.MobilePhone,
                        ContactPointType.Telephone
                    ])
                        .contains(cpt.Id)))
                .error(e => this.toaster.error(e));

            if (this.contactPoint != null) {
                this.contactPointInfo.contactPointType = this.contactPoint.type;
                this.masterdataSvc.getContactPointUsageTypes(Shared.Contract.Code.PartyType.Person,
                    this.contactPointInfo.contactPointType)
                    .success(r => this.contactPointUsageTypes = r)
                    .error(e => this.toaster.error(e));
                this.isReadOnly = this.isReadOnly || this.contactPoint.isReadOnly;
            }
        }

        private contactPointTypeChange(c: ContactPointType): void {
            this.contactPoint = RemeCare.Model.ContactPointFactory.createFromType(c);
            this.masterdataSvc.getContactPointUsageTypes(Shared.Contract.Code.PartyType.Person, c)
                .success(r => this.contactPointUsageTypes = r)
                .error(e => this.toaster.error(e));
        }

        private overlapBetweenContactPoints(cp: RemeCare.Model.ContactPoint, currentCp: RemeCare.Model.ContactPoint):
            boolean {

            return (currentCp.validUntilDate == null ||
                moment(cp.validFromDate).isSameOrBefore(currentCp.validUntilDate)) &&
                (cp.validUntilDate == null ||
                    moment(currentCp.validFromDate).isSameOrBefore(cp.validUntilDate));

        }

        private buildWarningModal(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setTemplateUrl('views/shared/overlapContactPointsModal.html')
                .build();
        }

        private buildDateGapWarningModal(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setTemplateUrl('views/shared/noActiveContactPointModal.html')
                .build();
        }


        private checkForOverlapSameCp(cpType: ContactPointType): boolean {
            var canSave = true;
            var arrayToCheck;

            switch (cpType) {
                case ContactPointType.Email:
                    arrayToCheck = this.currentEmails;
                    break;
                case ContactPointType.Fax:
                    arrayToCheck = this.currentFaxNumbers;
                    break;
                case ContactPointType.MobilePhone:
                    arrayToCheck = this.currentMobileNumbers;
                    break;
                case ContactPointType.Telephone:
                    arrayToCheck = this.currentPhoneNumbers;
                    break;
                default:
                    return false;
            }

            arrayToCheck.forEach(cp => {
                if (cp.contactPointUsageType === this.contactPoint.contactPointUsageType) {
                    if (this.overlapBetweenContactPoints(cp, this.contactPoint)) {
                        canSave = false;
                        return;
                    }
                }
            });

            return canSave;
        }

        private checkForDateGapInCps(cpType: ContactPointType): void {
            let hasDateGap: boolean = false;
            let arrayToCheck;

            switch (cpType) {
                case ContactPointType.Email:
                    arrayToCheck = this.currentEmails;
                    break;
                case ContactPointType.Fax:
                    arrayToCheck = this.currentFaxNumbers;
                    break;
                case ContactPointType.MobilePhone:
                    arrayToCheck = this.currentMobileNumbers;
                    break;
                case ContactPointType.Telephone:
                    arrayToCheck = this.currentPhoneNumbers;
                    break;
                default:
                    throw new Error('ContactPointType not found!');
            }

            arrayToCheck.push(this.contactPoint);
            let sortedArray = arrayToCheck.sort((a, b) => a.validFromDate - b.validFromDate);

            for (let i = 1; i < sortedArray.length; i++) {
                let beginningOfGap = moment(sortedArray[i - 1].validUntilDate);
                let endOfGap = moment(sortedArray[i].validFromDate);
                if (beginningOfGap != null && endOfGap.diff(beginningOfGap, 'days') > 1) {
                    hasDateGap = true;
                    break;
                }
            }

            if (hasDateGap) {
                this.buildDateGapWarningModal();
            }
        }

        private confirm(): void {
            var canSave = true;
            if (this.form.$invalid) {
                this.form.showValidationErrorMessage();
                this.form.$setSubmitted();
                return;
            }

            if (this.contactPoint.contactPointUsageType === ContactPointUsageType.Domicile ||
                this.contactPoint.contactPointUsageType === ContactPointUsageType.Default) {
                canSave = this.checkForOverlapSameCp(this.contactPoint.type);


            }

            if (canSave) {
                this.partyApiSvc.saveContactPoint(this.partyId, this.contactPoint.toServerWrite())
                    .success(id => {
                        this.contactPoint.id = id || this.contactPoint.id;
                        this.$uibModalInstance.close(this.contactPoint);
                        this.checkForDateGapInCps(this.contactPoint.type);
                    })
                    .error(e => this.toaster.error(e));
            } else {
                this.buildWarningModal();
                this.$uibModalInstance.close();
            }
        }

    }

    remeCarePatientModule.controller('editContactCtrl', EditContactController);
}