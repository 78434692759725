module RemeCare.Patient.Model {
    export class SolventToAddInformation {
        public solventToAddQuantity: number;
        public solventToAddType: string;
        public solventToAddUnit: number;

        constructor(serverObject: Contract.Patient.Read.ISolventToAddInformation) {
            if (serverObject != null) {
                this.solventToAddUnit = serverObject.SolventToAddUnit;
                this.solventToAddQuantity = serverObject.SolventToAddQuantity;
                this.solventToAddType = serverObject.SolventToAddType;
            }
        }

        toServerWrite(): Contract.Patient.Write.ISolventToAddInformation {
            return (this.solventToAddQuantity != null &&
                this.solventToAddUnit != null) ||
                this.solventToAddType != null
                ? < Contract.Patient.Write.ISolventToAddInformation > {
                    SolventToAddUnit: this.solventToAddUnit,
                    SolventToAddQuantity: this.solventToAddQuantity,
                    SolventToAddType: this.solventToAddType
                }
                : null;
        }

        clear(): void {
            this.solventToAddType = null;
            this.solventToAddQuantity = null;
            this.solventToAddUnit = null;
        }
    }

} 