namespace RemeCare.Connections {
    class FitbitCallbackController implements ng.IComponentController {
        /**
         * Whether or not an error has occured while handling the callback
         */
        public error: boolean;

        constructor(
            private readonly $log: ng.ILogService,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly $stateParams: IFitbitCallbackParams,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly connectionsApi: ConnectionsApiService
        ) {}

        public async $onInit(): Promise<void> {
            let patientId;
            try {
                // Get params from query string
                const fitbitAuthCode = this.$stateParams.code;
                const state = this.$stateParams.state;

                // Get code verifier from local storage
                const codeVerifier = this.storageSvc.get<string>(
                    Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier,
                    false
                );

                patientId = Shared.Base64Helper.base64urldecode(state);

                if (!codeVerifier) {
                    this.$log.error(`Failed to get codeVerifier from local storage`);
                    this.error = true;
                    return;
                }

                if (!patientId) {
                    this.$log.error(`Failed to get patientId from state ${state}`);
                    this.error = true;
                    return;
                }

                // Add the new fitbit connection to the database
                await this.connectionsApi.postConnection(
                    patientId,
                    fitbitAuthCode,
                    codeVerifier,
                    Shared.Contract.Code.ConnectionType.Fitbit
                );

                this.storageSvc.remove(Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier, false);

                // Redirect to the patient file page where we can see the fitbit connection
                this.$state.go('patients.patientfile.patientInfo.personalInfo', { patientId });
            } catch (e) {
                this.$log.error(e);
                this.toaster.error(e);
                this.error = true;
                if (patientId) {
                    this.$state.go('patients.patientfile.patientInfo.personalInfo', { patientId });
                }
            }
        }
    }

    remeCareConnectionsModule.component('rcFitbitCallback', {
        controller: FitbitCallbackController,
        templateUrl: 'connections/component/fitbit/fitbitCallback.html',
    });
}
