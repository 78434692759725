module RemeCare.Patient {

    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QuantitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint;
    import QuantitativeReferencePoint = Contract.CarePlan.Read.Query.IQuantitativeReferencePoint;
    import QuantitativeReferenceParameterAnamnesis = Shared.Framework.Model.QuantitativeReferenceParameterAnamnesis;
    import ReferenceParameterThreshold = Shared.Framework.Model.ReferenceParameterThreshold;
    import ReferenceParameterObjective = Shared.Framework.Model.ReferenceParameterObjective;
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import MonitoringPart = Shared.Framework.Model.MonitoringPart

    export interface IMonitoringPartBaseScope extends Shared.Framework.IBaseScope {

    }

    export abstract class MonitoringPartControllerBase<TMonitoringPart extends MonitoringPart> implements ng.IComponentController {
        // bindings
        public carePlanIds: Shared.Contract.Guid[];
        public globalFrom: Date;
        public globalUntil: Date;
        public hideWhenNoData: boolean;
        public monitoringPart: TMonitoringPart;
        public onFromChanged: () => void;
        public onPartLoaded: () => void;
        public onUntilChanged: () => void;
        public patientCode: string;
        public patientId: Shared.Contract.Guid;
        public showLegend: boolean;
        public loaded: boolean;

        private previousFromDate: Date;
        private previousUntilDate: Date;

        public dateInfo: {
            minFromDate: Date;
            fromDate: Date;
            untilDate: Date;
        };

        constructor(
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService) {
        }

        public async $onInit(): Promise<void> {
            this.initPrivate();
            this.loaded = false;
            try {
                await this.init();
                this.dateChanged(true);
            } catch (e) {
                this.loaded = true;
                this.onPartLoaded();
            }
        }

        protected abstract init(): Promise<void>;

        protected abstract hasData(): boolean;

        private initPrivate(): void {
            this.dateInfo = {
                untilDate: this.globalUntil != null ? this.globalUntil : this.dateHelper.today(),
                fromDate: this.globalFrom != null ? this.globalFrom :
                    this.dateHelper.subtractDuration(this.dateHelper.today(), this.monitoringPart.defaultPeriod),
                minFromDate: this.dateHelper.today()
            }
        }

        public fromChanged(): void {
            this.dateChanged();
            this.onFromChanged();
        }

        public untilChanged(): void {
            this.dateChanged();
            const today = Shared.DateHelper.today();
            if (this.dateInfo.untilDate && this.dateInfo.untilDate.valueOf() < today.valueOf()) {
                this.dateInfo.minFromDate = this.dateInfo.untilDate;
            } else {
                this.dateInfo.minFromDate = today;
            }
            this.onUntilChanged();
        }

        protected async dateChanged(isInitialLoad?: boolean): Promise<void> {
            if (moment(this.previousFromDate).isSame(this.dateInfo.fromDate) &&
                moment(this.previousUntilDate).isSame(this.dateInfo.untilDate)) {
                // nothing has changed
                return;
            }
            this.previousFromDate = this.dateInfo.fromDate;
            this.previousUntilDate = this.dateInfo.untilDate;
            this.spinnerSvc.show(<string>this.monitoringPart.id);
            try {
                await this.onDateChange();
            } finally {
                this.spinnerSvc.hide(this.monitoringPart.id as string);
                if (isInitialLoad) {
                    this.loaded = true;
                    this.onPartLoaded();
                }
            }
        }

        protected abstract onDateChange(): Promise<void>;

        protected getQuantitativeMeasuringPoints(): Array<QuantitativeMeasuringPoint> {
            return _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter(mps => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter)
                .map(mps => <QuantitativeMeasuringPointParameter>mps)
                .map(mps => {
                    return <QuantitativeMeasuringPoint>{
                        characteristicId: mps.characteristic.Id,
                        unitId: mps.unit ? mps.unit.Id : null
                    }
                })
                .value();
        }

        protected getQuantitativeReferencePoints(): Array<QuantitativeReferencePoint> {
            return _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter(mps => mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis)
                .map(mps => <QuantitativeReferenceParameterAnamnesis>mps)
                .map(mps => {
                    return <QuantitativeReferencePoint>{
                        characteristicId: mps.characteristic.Id,
                        unitId: mps.unit ? mps.unit.Id : null
                    }
                })
                .union(_(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(mps => mps.type === MonitoringPartSourceType.ReferenceParameterObjective)
                    .map(mps => <ReferenceParameterObjective>mps)
                    .map(mps => {
                        return <QuantitativeReferencePoint>{
                            objectiveId: mps.objective.Id
                        }
                    }).value())
                .union(_(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(mps => mps.type === MonitoringPartSourceType.ReferenceParameterThreshold)
                    .map(mps => <ReferenceParameterThreshold>mps)
                    .map(mps => {
                        return <QuantitativeReferencePoint>{
                            ruleThresholdId: mps.ruleThreshold.Id
                        }
                    }).value())
                .value();
        }

        protected getUntilDate(): Date {
            return moment(this.dateInfo.untilDate).startOf('day').add(1, 'days').toDate();
        }
    }

    Highcharts.setOptions({
        global: {
            useUTC: false,
        }
    });

    export abstract class HighChartsMonitoringPartController<TMonitoringPart extends MonitoringPart> extends MonitoringPartControllerBase<TMonitoringPart> {

        public chartConfigs: Array<HighChartsNGConfig>;

        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService) {
            super(dateHelper, spinnerSvc);

            this.$rootScope.$on(Shared.AppEvents.verticalScrollBar, () => {
                _.each(this.chartConfigs, (config: HighChartsNGChart) => {
                    if (config.getHighcharts) {
                        try {
                            config.getHighcharts().reflow();
                        } catch (e) {
                            // reflow fails when chart hasn't been drawn yet
                        }
                    }
                });
            });

            Highcharts.setOptions({
                lang: {
                    months: $locale.DATETIME_FORMATS.MONTH,
                    weekdays: $locale.DATETIME_FORMATS.DAY,
                    shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH
                }
            });
        }

        public $onChanges(onChangesObj: angular.IOnChangesObject): void {
            if (onChangesObj['showLegend']) {
                _.each(this.chartConfigs, (config: HighChartsNGChart) => {
                    if (config.getHighcharts) {
                        config.getHighcharts().legendSetVisibility(onChangesObj['showLegend'].currentValue);
                    }
                });
            }
        }

        public hasData(): boolean {
            return _.any(this.chartConfigs, (cc) => this.containsData(cc));
        }

        protected getDateFormat(includeTime: boolean): string {
            return this.convertDateFormat(includeTime
                ? this.$locale.DATETIME_FORMATS.short
                : this.$locale.DATETIME_FORMATS.shortDate);
        }

        protected containsData(config: HighChartsNGConfig): boolean {
            return !_(config.series).all(s => !_(s.data).any() || (<any>s).isReference);
        }

        protected getXAxisConfig(title?: string, noTime?: boolean): HighchartsAxisOptions {
            const options = <HighchartsAxisOptions>{
                type: 'datetime',
                min: moment(this.dateInfo.fromDate).startOf('day').subtract(12, 'hours').valueOf(), // subtract 12 hours to prevent columns on fromDate to be only shown half
                max: moment(this.dateInfo.untilDate).endOf('day').valueOf(),
                title: {
                    text: title,
                    align: 'high'
                },
                startOnTick: false,
                endOnTick: false,
                tickPixelInterval: 105,
                plotLines: [{
                    value: moment().valueOf(),
                    color: '#ccd6eb',
                    dashStyle: 'solid',
                    width: 1
                }]
            };
            if (noTime) {
                options.minTickInterval = 24 * 3600 * 1000;
            }
            return options;
        }

        private convertDateFormat(dateForm: string): string {
            return dateForm
                .replace('dd', 'd').replace('d', '%d')
                .replace('mm', 'm').replace('m', '%M')
                .replace('MM', 'M').replace('M', '%m')
                .replace('yyyy', 'yy').replace('yy', '%Y')
                .replace('HH', 'H').replace('H', '%H')
                .replace('ss', 's').replace('s', '%S');
        }
    }

    export abstract class ChartMonitoringPartController extends HighChartsMonitoringPartController<Shared.Framework.Model.MonitoringPartChart> {

        public errorMessage: string;

        protected getMaxYScaleValue(graph: Model.IHasMinMaxY): number {
            return this.getLimitedMaxYValue(this.monitoringPart, graph.getMaxYValue());
        }

        protected getLimitedMaxYValue(monitoringPartChart: Shared.Framework.Model.MonitoringPartChart, value: number) {
            if (value == null) return null;
            let max: number;
            if (monitoringPartChart.ordinateMaxValue != null) {
                max = monitoringPartChart.ordinateMaxValue;
            } else {
                const percentage = monitoringPartChart.ordinatePercentageAboveHighest != null
                    ? monitoringPartChart.ordinatePercentageAboveHighest
                    : 5;
                const maxValue = value;

                max = maxValue + (maxValue * percentage / 100);
            }
            return max;
        }

        protected getMinYScaleValue(graph: Model.IHasMinMaxY): number {
            let min: number;
            if (this.monitoringPart.ordinateMinValue != null) {
                min = this.monitoringPart.ordinateMinValue;
            } else {
                const percentage = this.monitoringPart.ordinatePercentageBelowLowest != null
                    ? this.monitoringPart.ordinatePercentageBelowLowest
                    : 5;
                const minValue = graph.getMinYValue();
                if (minValue == null) return null;
                min = minValue - (minValue * percentage / 100);
            }
            return min;
        }
    }

    export class MonitoringPartComponentBase implements ng.IComponentOptions {

        public bindings: { [index: string]: string; } = {
            carePlanIds: '<',
            globalFrom: '<',
            globalUntil: '<',
            hideWhenNoData: '<',
            monitoringPart: '<',
            onFromChanged: '&',
            onPartLoaded: '&',
            onUntilChanged: '&',
            patientCode: '<',
            patientId: '<',
            showLegend: '<',
            widthClass: '@',
        };
    }
}