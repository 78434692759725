/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestUiControlType = RemeCare.Shared.Contract.Code.CareRequestUIControlType;
            var CareRequestorAcceptanceModalController = /** @class */ (function (_super) {
                __extends(CareRequestorAcceptanceModalController, _super);
                // @ngInject
                function CareRequestorAcceptanceModalController($scope, $translate, toaster, healthCareProfessionalSearchSvc, healthCareOrganisationSearchSvc, modalBuilderFactory, therapyApiSvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.healthCareProfessionalSearchSvc = healthCareProfessionalSearchSvc;
                    _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.therapyApiSvc = therapyApiSvc;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.atLeastOneNumberCareRequestor = function () { return _this.atLeastOneNumberCareRequestor(); };
                    $scope.atLeastOneNumberCareRequestorContact = function () { return _this.atLeastOneNumberCareRequestorContact(); };
                    $scope.clearSelectedProfessional = function () { return _this.clearSelectedProfessional(); };
                    $scope.searchProfessional = function () { return _this.searchProfessional(); };
                    $scope.searchOrganisation = function () { return _this.searchOrganisation(); };
                    $scope.clearProfessional = function () { return _this.clearProfessional(); };
                    $scope.clearOrganisation = function () { return _this.clearOrganisation(); };
                    $scope.isMaxReached = function (a) { return _this.isMaxReached(a); };
                    $scope.confirm = function () { return _this.confirmAsync(); };
                    $scope.requestorProfessionalRequired = function () { return _this.requestorProfessionalRequired(); };
                    return _this;
                }
                CareRequestorAcceptanceModalController.prototype.$onInit = function () {
                    var _this = this;
                    if (this.$scope.careRequestPart.roleRequestingHealthCareProfessional) {
                        this.loadProfessionalsAsync();
                    }
                    this.$scope.intermediaryInfo = !this.$scope.careRequestPart.roleRequestingHealthCareOrganisation
                        ? { organisation: false }
                        : { organisation: null };
                    if (this.$scope.careRequest.careRequestor.healthCareOrganisationId != null) {
                        this.loadOrganisationAsync();
                    }
                    if (this.$scope.careRequest.careRequestor.healthCareProfessionalId != null) {
                        this.loadProfessionalAsync();
                    }
                    this.$scope.$watch(function (s) { return s.intermediaryInfo.organisation; }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            if (newValue) {
                                _this.$scope.careRequest.intermediarySpecialtyId = null;
                                _this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = _this.$scope.organisation.HealthCareOrganisationType.Id;
                            }
                            else {
                                _this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = null;
                            }
                        }
                    });
                    if (this.$scope.isPresent(CareRequestUiControlType.CareRequestorHCOrganisation) &&
                        this.$scope.careRequestPart.roleRequestingHealthCareOrganisation) {
                        this.loadOrganisationsAsync();
                    }
                };
                CareRequestorAcceptanceModalController.prototype.loadOrganisationsAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, e_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    _a = this.$scope;
                                    return [4 /*yield*/, this.therapyApiSvc.getPossiblePartiesAsync(this.$scope.careRequest.therapyId, this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleId)];
                                case 1:
                                    _a.organisations = _b.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _b.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceModalController.prototype.loadProfessionalAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var result, e_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.healthCareProfessionalSearchSvc.getHealthCareProfessional(this.$scope.careRequest.careRequestor.healthCareProfessionalId)];
                                case 1:
                                    result = _a.sent();
                                    if (result) {
                                        this.$scope.professional = new RemeCare.Model.HealthCareProfessional(result);
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_2 = _a.sent();
                                    this.toaster.error(e_2);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceModalController.prototype.loadOrganisationAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, e_3;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    _a = this.$scope;
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([
                                            this.$scope.careRequest.careRequestor.healthCareOrganisationId,
                                        ])];
                                case 1:
                                    _a.organisation = (_b.sent())[0];
                                    this.$scope.organisationName = this.$scope.organisation.Name;
                                    if (this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId != null) {
                                        this.$scope.intermediaryInfo.organisation = true;
                                    }
                                    else if (this.$scope.careRequest.intermediarySpecialtyId != null) {
                                        this.$scope.intermediaryInfo.organisation = false;
                                    }
                                    else if (this.$scope.careRequest.careRequestor.healthCareOrganisationId != null) {
                                        this.$scope.intermediaryInfo.organisation = true;
                                        this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = this.$scope.organisation.HealthCareOrganisationType.Id;
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_3 = _b.sent();
                                    this.toaster.error(e_3);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceModalController.prototype.loadProfessionalsAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var professionals, e_4;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.therapyApiSvc.getPossiblePartiesAsync(this.$scope.careRequest.therapyId, this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId)];
                                case 1:
                                    professionals = _a.sent();
                                    this.$scope.professionals = professionals;
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_4 = _a.sent();
                                    this.toaster.error(e_4);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceModalController.prototype.requestorProfessionalRequired = function () {
                    return (this.isRequired(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional) ||
                        (this.$scope.careRequestPart.roleRequestingHealthCareProfessional &&
                            this.$scope.careRequestPart.roleRequestingHealthCareProfessional.minimalNumber > 0));
                };
                CareRequestorAcceptanceModalController.prototype.clearProfessional = function () {
                    this.$scope.professional = null;
                    this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
                };
                CareRequestorAcceptanceModalController.prototype.atLeastOneNumberCareRequestor = function () {
                    if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorTelephoneMobile)) {
                        return true;
                    }
                    return (this.$scope.careRequest.careRequestor.responsible.contactInfo.telephone.isFilled() ||
                        this.$scope.careRequest.careRequestor.responsible.contactInfo.mobile.isFilled());
                };
                CareRequestorAcceptanceModalController.prototype.atLeastOneNumberCareRequestorContact = function () {
                    if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorContactTelephoneMobile)) {
                        return true;
                    }
                    return (this.$scope.careRequest.careRequestor.contactPerson.contactInfo.telephone.isFilled() ||
                        this.$scope.careRequest.careRequestor.contactPerson.contactInfo.mobile.isFilled());
                };
                CareRequestorAcceptanceModalController.prototype.clearSelectedProfessional = function () {
                    this.$scope.professional = null;
                    this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
                };
                CareRequestorAcceptanceModalController.prototype.searchProfessional = function () {
                    var _this = this;
                    var typeAndSpecialties = this.convertToTypeAndSpecialties(_(this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleInclusions)
                        .chain()
                        .map(function (ari) { return ari.actorRoleInclusionDetails; })
                        .flatten());
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('searchHealthCareProfessionalPartyCtrl')
                        .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                        .setScope({
                        title: this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name,
                        typeAndSpecialties: typeAndSpecialties,
                        prefill: this.$scope.careRequestPart.canBeSearchedByExternal
                            ? null
                            : {
                                FirstName: this.$scope.careRequest.careRequestor.responsible.firstName,
                                LastName: this.$scope.careRequest.careRequestor.responsible.lastName,
                                Email: this.$scope.careRequest.careRequestor.responsible.contactInfo.email,
                                IdentificationNumber: this.$scope.careRequest.careRequestor.responsible
                                    .identificationNumber,
                                ZipCode: this.$scope.careRequest.careRequestor.responsible.contactInfo.address &&
                                    this.$scope.careRequest.careRequestor.responsible.contactInfo.address.addressLine1,
                                City: this.$scope.careRequest.careRequestor.responsible.contactInfo.address &&
                                    this.$scope.careRequest.careRequestor.responsible.contactInfo.address.city,
                            },
                    })
                        .setResultCallBack(function (r) {
                        _this.$scope.professional = new RemeCare.Model.HealthCareProfessional(r);
                        _this.$scope.careRequest.careRequestor.healthCareProfessionalId = r.PartyRoleId;
                    })
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .build();
                };
                CareRequestorAcceptanceModalController.prototype.searchOrganisation = function () {
                    var _this = this;
                    var orgTypes = _(this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleInclusions)
                        .chain()
                        .map(function (ari) { return ari.actorRoleInclusionDetails; })
                        .flatten()
                        .map(function (arid) { return arid.healthCareOrganisationTypeId; })
                        .value();
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('searchHealthCareOrganisationPartyCtrl')
                        .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                        .setScope({
                        title: this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name,
                        organisationTypes: orgTypes,
                    })
                        .setResultCallBack(function (r) {
                        _this.$scope.careRequest.careRequestor.healthCareOrganisationId = r.PartyRoleId;
                        _this.$scope.organisation = r;
                        _this.$scope.organisationName = r.Name;
                    })
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .build();
                };
                CareRequestorAcceptanceModalController.prototype.clearOrganisation = function () {
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
                    this.$scope.organisation = null;
                    this.$scope.organisationName = null;
                };
                CareRequestorAcceptanceModalController.prototype.isMaxReached = function (role) {
                    if (!role.maximalNumber) {
                        return false;
                    }
                    var requestRoles = _(this.$scope.careRequest.careRequestRoles).filter(function (crr) { return crr.roleId === role.actorRoleId; });
                    return requestRoles.length >= role.maximalNumber;
                };
                CareRequestorAcceptanceModalController.prototype.confirmAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            this.$scope.showErrors = true;
                            this.$scope.careRequestorForm.$setSubmitted();
                            if (this.$scope.careRequestorForm.$invalid) {
                                this.$scope.careRequestorForm.showValidationErrorMessage();
                                return [2 /*return*/];
                            }
                            this.$scope.$close();
                            return [2 /*return*/];
                        });
                    });
                };
                return CareRequestorAcceptanceModalController;
            }(Directive.CareRequestPartControllerBase));
            CareRequest.remeCareCareRequestModule.controller('rcCareRequestCareRequestorAcceptanceModalController', CareRequestorAcceptanceModalController);
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
