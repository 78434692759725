namespace RemeCare.Core.Services {
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class ActorRolesApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = ['baseUrl', '$http', 'spinnerSvc'];

        public findActorRolesAsync(
            query: RemeCare.Contract.Core.Read.Query.IFindActorRolesQuery
        ): Promise<Contract.Therapy.Read.IActorRoleSummary[]> {
            return this.getPromise('ActorRoles', query, 'actorRoles');
        }

        public findGlobalActorRolesAsync(): Promise<EntityTranslation[]> {
            let query = ({
                PageSize: 50,
            } as any) as Shared.Contract.ISearchQuery;
            return this.getPromise('GlobalActorRoles', query, 'globalActorRoles', null, (r) => r.Items);
        }
    }

    remeCareAppModule.service('actorRolesApiSvc', ActorRolesApiService);
}
