var RemeCare;
(function (RemeCare) {
    var Framework;
    (function (Framework) {
        var PhoneNumber = /** @class */ (function () {
            function PhoneNumber(phoneNumber) {
                if (phoneNumber != null) {
                    this.id = phoneNumber.Id;
                    this.countryPrefix = phoneNumber.CountryPrefix;
                    this.areaCode = phoneNumber.AreaCode;
                    this.localNumber = phoneNumber.LocalNumber;
                }
            }
            PhoneNumber.prototype.isValid = function () {
                return this.countryPrefix != null
                    && this.countryPrefix != ''
                    && this.areaCode != null
                    && this.areaCode != ''
                    && this.localNumber != null
                    && this.localNumber != '';
            };
            PhoneNumber.prototype.toString = function () {
                return (this.countryPrefix ? '+' + this.countryPrefix + ' ' : '') +
                    (this.areaCode ? '(' + this.areaCode + ') ' : '') +
                    (this.localNumber ? this.addSpaces(this.localNumber) : '');
            };
            PhoneNumber.prototype.toServerPhone = function () {
                if (this.countryPrefix == null || this.areaCode == null || this.localNumber == null)
                    return null;
                var serverPhone = {
                    Id: this.id,
                    CountryPrefix: this.countryPrefix,
                    AreaCode: this.areaCode,
                    LocalNumber: this.localNumber
                };
                return serverPhone;
            };
            PhoneNumber.prototype.addSpaces = function (string) {
                var result = '';
                for (var i = 0; i < string.length; i++) {
                    result += string[i];
                    if (i % 2 == 1)
                        result += ' ';
                }
                return result;
            };
            return PhoneNumber;
        }());
        Framework.PhoneNumber = PhoneNumber;
    })(Framework = RemeCare.Framework || (RemeCare.Framework = {}));
})(RemeCare || (RemeCare = {}));
