namespace RemeCare.CarePlanAction {
    class CarePlanActionHistoryController implements ng.IComponentController {
        // bindings
        public grid: Shared.Framework.Grid.Grid<CarePlanActionHistory>;
        public actionId: Shared.Contract.Guid;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private partyApiSvc: Core.Services.PartyApiService
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadDataAsync();
        }

        private buildGrid(): void {
            this.grid = this.gridBuilderSvc
                .createGridBuilder<CarePlanActionHistory>()
                .addTranslateColumn('auditAction', 'Views.Action.History.Action', { width: 80 })
                .addUtcDateColumn('auditDate', 'Views.Action.History.On', 'short', { width: 100 })
                .addColumn('modificationUser', 'General.By', { width: 110 })
                .addUtcDateColumn('plannedDateTimeFrom', 'Views.Action.Planning.From', 'short', { width: 100 })
                .addUtcDateColumn('plannedDateTimeUntil', 'Views.Action.Planning.Until', 'short', { width: 100 })
                .addUtcDateColumn('eventDateTimeFrom', 'Views.Action.History.RealizedFrom', 'short', { width: 100 })
                .addUtcDateColumn('eventDateTimeUntil', 'Views.Action.History.RealizedUntil', 'short', { width: 100 })
                .addColumn('locationString', 'Views.Action.Location.Location')
                .addEnumColumn('status', 'General.Status', Shared.Framework.ReferenceDataTypes.carePlanActionStatus, {
                    width: '80',
                    enableSorting: false,
                })
                .addTranslateColumn('statusChangedRemark', 'General.Reason')
                .build();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                const histories = await this.carePlanActionApiSvc.getCarePlanActionHistoryAsync(this.actionId);
                const mapped = _.sortBy(
                    _.map(histories, h => new CarePlanActionHistory(h)),
                    h => h.auditDate
                ).reverse();

                mapped.forEach(element => {
                    this.updateLocationAsync(element);
                });

                this.grid.setData(mapped);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async updateLocationAsync(history: CarePlanActionHistory): Promise<void> {
            if (!history.location || !history.location.ContactPointUsageId) {
                return;
            }

            try {
                const address = await this.partyApiSvc.findAddressAsync({
                    contactPointUsageId: history.location.ContactPointUsageId,
                });
                if (!address || !address.Items || address.Items.length === 0) {
                    return;
                }
                history.setAddress(address.Items[0]);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareCarePlanActionModule.component('rcCarePlanActionHistory', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            actionId: '=',
        },
        controller: CarePlanActionHistoryController,
        templateUrl: 'carePlanAction/components/carePlanActionHistory/carePlanActionHistory.html',
    });
}
