/// <reference path="componentDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var AdHocAdministrationController = /** @class */ (function (_super) {
            __extends(AdHocAdministrationController, _super);
            // @ngInject
            function AdHocAdministrationController($scope, toaster, masterdataSvc, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.toaster = toaster;
                _this.masterdataSvc = masterdataSvc;
                $scope.medicationDoseChanged = function () { return _this.medicationDoseChanged(); };
                return _this;
            }
            AdHocAdministrationController.prototype.init = function () {
                this.loadData();
            };
            AdHocAdministrationController.prototype.loadData = function () {
                var _this = this;
                var pointInTime = this.$scope.carePlanAction.processVersionDate;
                if (pointInTime === undefined) {
                    pointInTime = moment.utc().toDate();
                }
                this.masterdataSvc
                    .getCodeSetItems(this.$scope.component.value.ChoiceListId, RemeCare.Shared.DateHelper.toServerDateString(pointInTime))
                    .success(function (o) { return (_this.$scope.options = o); })
                    .error(function (m) { return _this.toaster.error(m); });
            };
            AdHocAdministrationController.prototype.medicationDoseChanged = function () {
                var _this = this;
                this.$scope.medicationDose = _(this.$scope.component.medicationDoses)
                    .chain()
                    .filter(function (md) { return md.personalisedTherapyActionId === _this.$scope.component.personalisedTherapyActionId; })
                    .first()
                    .value();
            };
            return AdHocAdministrationController;
        }(CarePlanAction.ComponentControllerBase));
        var AdHocAdministrationDirective = /** @class */ (function (_super) {
            __extends(AdHocAdministrationDirective, _super);
            function AdHocAdministrationDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/adHocAdministration.html';
                _this.controller = AdHocAdministrationController;
                return _this;
            }
            return AdHocAdministrationDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcAdministrationAdhoc', function () { return new AdHocAdministrationDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
