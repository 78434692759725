var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ObjectiveDefinition = RemeCare.Shared.Framework.Model.ObjectiveDefinition;
        var PatientFileObjectiveOverviewController = /** @class */ (function (_super) {
            __extends(PatientFileObjectiveOverviewController, _super);
            function PatientFileObjectiveOverviewController($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.$scope.addObjectiveValue = function () { return _this.addObjectiveValue(); };
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileObjectiveOverviewController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var firstValue;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.$scope.patientId = this.$stateParams.patientId;
                                firstValue = _(this.$scope.objective.objectiveValues)
                                    .chain()
                                    .sortBy(function (t) { return t.validFromDate; })
                                    .first()
                                    .value();
                                if (moment(firstValue.validFromDate).isBefore(this.$scope.carePlan.startDate)) {
                                    firstValue.validFromDate = this.$scope.carePlan.startDate;
                                }
                                this.buildVersionsGrid();
                                return [4 /*yield*/, this.getDefaultObjective()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectiveOverviewController.prototype.confirm = function () {
                this.$scope.$close(this.$scope.objective);
            };
            PatientFileObjectiveOverviewController.prototype.getDefaultObjective = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, _b, e_1;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                _a = this.$scope;
                                _b = ObjectiveDefinition.bind;
                                return [4 /*yield*/, this.therapyApiSvc.getObjectiveAsync(this.$scope.carePlan.therapyId, this.$scope.objective.id)];
                            case 1:
                                _a.defaultObjective = new (_b.apply(ObjectiveDefinition, [void 0, _c.sent()]))();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _c.sent();
                                this.errorCallback(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectiveOverviewController.prototype.buildVersionsGrid = function () {
                var _this = this;
                this.$scope.versionsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('amount', 'Views.PatientFile.CarePlans.ObjectiveValue')
                    .addColumn('validFromDate', 'General.ValidFrom', { cellFilter: 'date: "shortDate"' })
                    .addColumn('validUntilDate', 'General.ValidUntil', { cellFilter: 'date: "shortDate"' })
                    .addBoolColumn('isPersonalised', 'Views.PatientFile.CarePlans.Personalised')
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (v) { return _this.editObjectiveValue(v); }, function (v) {
                    return ((_this.$scope.objective.getPreviousObjectiveValue(v) === null ||
                        !_this.$scope.objective.getPreviousObjectiveValue(v).isPersonalised) &&
                        (!v.isPersonalised || _this.$scope.readOnly || moment().isAfter(moment(v.validFromDate))));
                })
                    .addConditionalDeleteButtonColumn(function (v) {
                    var personalised = _(_(_this.$scope.objective.objectiveValues).filter(function (x) { return x.isPersonalised; })).sortBy(function (x) { return x.validFromDate; });
                    return (_(personalised).size() === 0 ||
                        v.validFromDate !== _(personalised).last().validFromDate ||
                        !v.isPersonalised ||
                        _this.$scope.readOnly);
                }, function (v) { return _this.deleteValue(v); })
                    .setSorting(false)
                    .build();
                this.setVersionsGridData();
            };
            PatientFileObjectiveOverviewController.prototype.setVersionsGridData = function () {
                this.$scope.versionsGrid.setData(this.$scope.objective.objectiveValues);
            };
            PatientFileObjectiveOverviewController.prototype.addObjectiveValue = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('PatientFileAddObjectiveValueModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/addObjectiveValueModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    objective: this.$scope.objective,
                    defaultValue: this.$scope.defaultObjective.getCurrentObjectiveValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: false,
                })
                    .setResultCallBack(function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.getObjective(this.$scope.objective.id)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .build();
            };
            PatientFileObjectiveOverviewController.prototype.editObjectiveValue = function (value) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('PatientFileEditObjectiveValueModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/editValueModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    objective: this.$scope.objective,
                    defaultValue: this.$scope.defaultObjective.getCurrentObjectiveValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: value,
                })
                    .setResultCallBack(function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.getObjective(this.$scope.objective.id)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); })
                    .build();
                return deferred.promise();
            };
            PatientFileObjectiveOverviewController.prototype.deleteValue = function (value) {
                return __awaiter(this, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, this.carePlanApiSvc.deletePersonalisedObjectiveValueAsync(this.$scope.carePlan.id, this.$scope.objective.id, value.id)];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, this.getObjective(this.$scope.objective.id)];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.errorCallback(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectiveOverviewController.prototype.getObjective = function (objectiveId) {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getObjectiveAsync(this.$scope.carePlan.id, objectiveId)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.objective = new ObjectiveDefinition(ags);
                                this.setVersionsGridData();
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.errorCallback(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return PatientFileObjectiveOverviewController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular
            .module('RemeCare.Patient')
            .controller('PatientFileObjectiveOverviewCtrl', function ($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc) {
            return new PatientFileObjectiveOverviewController($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
