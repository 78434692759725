namespace RemeCare.Patient {
    import DateFilterType = Contract.Core.Codes.DateFilterTypeCode;
    import FindRegisteredActionsQuery = Contract.CarePlanAction.Read.IFindRegisteredActionsQuery;
    import RegisteredAction = Contract.CarePlanAction.Read.IRegisteredAction;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import CarePlanStatus = Shared.Contract.Code.CarePlanStatus;

    class PatientFileRegistrationActionsController extends PatientRegistrationsBaseController {
        // bindings
        public $transition$;

        public grid: Shared.Framework.Grid.Grid<RegisteredAction>;
        public editRight: boolean;
        public activeTab: number;
        public isPatient: boolean;
        public structuralTherapyActions: EntityTranslation[];
        private allActorRoles: RemeCare.Contract.Therapy.Read.IActorRoleSummary[];
        private forPatient: boolean;
        private forNonPatient: boolean;

        constructor(
            protected $stateParams: PatientFileStateParams,
            protected $filter: ng.IFilterService,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: Patient.PatientService,
            protected masterdataSvc: Shared.Framework.MasterdataService,
            protected actorRolesApiSvc: Core.Services.ActorRolesApiService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly dateHelper: Shared.DateHelper
        ) {
            super($stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc);
        }

        public careProvidersTabSelected(): void {
            this.clearGrid();
            this.forPatient = false;
            this.forNonPatient = true;
            this.grid.search();
        }

        public patientsTabSelected(): void {
            this.clearGrid();
            this.forPatient = true;
            this.forNonPatient = false;
            this.grid.search();
        }

        public allTabSelected(): void {
            this.clearGrid();
            this.forPatient = false;
            this.forNonPatient = false;
            this.grid.search();
        }

        public newSearch(): void {
            this.clearGrid();
            this.grid.search();
        }

        protected init(): void {
            this.isPatient = this.authservice.isPatient();
            this.activeTab = 0;
            this.editRight = true;
            this.buildGrid();
            this.grid.searchCriteria.selectedDateFilter = DateFilterType.PlannedDate;
            this.grid.searchCriteria.fromDate = this.dateHelper.subtractDuration(this.dateHelper.today(), {
                Quantity: 3,
                Unit: Shared.Contract.Code.DurationUnit.Months,
            });
            this.grid.pagingOptions.currentPage = 1;
            if (this.isPatient) {
                this.grid.search();
            }
        }

        protected dataLoaded(): void {
            if (!this.isPatient) {
                this.allActorRoles = this.actorRoles;
                this.careProvidersTabSelected();
            }
        }

        private clearGrid(): void {
            this.grid.setData([]);
            this.grid.totalItems = 0;
            this.grid.pagingOptions.currentPage = 1;
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<RegisteredAction>(
                    (page, pageSize, sortField, sortDirection, criteria, count, nextPage) =>
                        this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria, count, nextPage)
                )
                .setIsPrevNextGrid()
                .addColumn('Name', 'General.Name')
                .addUtcDateColumn('PlannedDateTimeFrom', 'Views.PatientFile.Monitoring.PlannedDate', 'short', {
                    sort: { direction: 'desc' },
                })
                .addUtcDateColumn('ExecutionDateTime', 'Views.PatientFile.Monitoring.ExecutionDate', 'short')
                .addUtcDateColumn('ModificationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', 'short')
                .addColumn('RegisteredBy', 'Views.PatientFile.Monitoring.RegisteredBy')
                .addEnumColumn('Status', 'General.Status', Shared.Framework.ReferenceDataTypes.carePlanActionStatus)
                .addConditionalShowEditNoButtonFunctionColumn(
                    (x) => this.viewCarePlanAction(x),
                    (x) => x.ReadOnly,
                    (x) => !this.canViewItem(x)
                );

            this.grid = gridBuilder.build();
            this.grid.pagingOptions.pageSize = 20;
        }

        private canViewItem(selected: RegisteredAction): boolean {
            return (Shared.Framework.AuthRight.Read & selected.MaxExecutionRight) !== 0;
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any,
            count: boolean,
            nextPage: boolean
        ): Promise<Shared.Contract.ISearchResult<RegisteredAction>> {
            let actorRoleIds = criteria.actorRoleIds;
            if (this.actorRoles && actorRoleIds && this.allActorRoles.length === actorRoleIds.length) {
                // If all actor roles are selected, deselect all
                actorRoleIds = [];
            }

            const query: FindRegisteredActionsQuery = {
                patientId: this.$transition$.params().patientId,
                carePlanId: criteria.carePlanId,
                actionTemplateIds: criteria.selectedActions,
                registeredBy: criteria.selectedPersons,
                statuses: criteria.selectedStatuses,
                actorRoleIds: this.insertAllPatientRoles(actorRoleIds),
                dateFilterType: criteria.selectedDateFilter,
                startDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                endDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                includeCount: count,
                includeHasNextPage: nextPage,
                forPatient: this.forPatient,
                forNonPatient: this.forNonPatient,
            };
            try {
                // Don't await loading of filter
                this.loadStructuralTherapyActionsFilterAsync(query);

                const result = await this.carePlanActionApiSvc.findRegistrationsAsync(query);
                return result;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private async loadStructuralTherapyActionsFilterAsync(
            registrationsQuery: FindRegisteredActionsQuery
        ): Promise<void> {
            const query: RemeCare.Contract.CarePlanAction.Read.IFindPossibleRegistrationActionsQuery = {
                patientId: registrationsQuery.patientId,
                carePlanId: registrationsQuery.carePlanId,
                registeredBy: registrationsQuery.registeredBy,
                statuses: registrationsQuery.statuses,
                actorRoleIds: registrationsQuery.actorRoleIds,
                dateFilterTypes: registrationsQuery.dateFilterType ? [registrationsQuery.dateFilterType] : [],
                startDate: registrationsQuery.startDate,
                endDate: registrationsQuery.endDate,
                forPatient: registrationsQuery.forPatient,
                forNonPatient: registrationsQuery.forNonPatient,
            };

            const result = await this.carePlanActionApiSvc.findPossibleRegistrationActionsFilterAsync(query);
            const oldFilterValues = this.structuralTherapyActions;
            this.structuralTherapyActions = _.sortBy(result, (sta) => (sta.Text ? sta.Text.toLowerCase() : ''));

            if (!this.grid.searchCriteria.selectedActions || !this.grid.searchCriteria.selectedActions.length) {
                return;
            }

            // Take all actions that were selected
            const alreadySelected = _.filter(oldFilterValues, (fv) =>
                _.contains(this.grid.searchCriteria.selectedActions, fv.Id)
            );

            // Add already selected values to the new list of possible filter values
            this.structuralTherapyActions.push(...alreadySelected);
            // Filter duplicates
            this.structuralTherapyActions = _.uniq(this.structuralTherapyActions, (sta) => sta.Id);
        }

        private async viewCarePlanAction(cpa: RegisteredAction): Promise<void> {
            this.modalBuilderFactory
                .createModalBuilder<CarePlanAction.CarePlanAction>()
                .setController('carePlanActionDetailModalCtrl')
                .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    actionId: cpa.Id,
                    editRight: !cpa.ReadOnly,
                })
                .setResultCallBack((a: CarePlanAction.CarePlanAction) => {
                    if (a != null) {
                        const tempData = this.grid.getData();
                        _.findWhere(tempData, { Id: a.id }).Status = a.status;
                        this.grid.setData(tempData);
                    }
                })
                .build();
        }
    }

    remeCarePatientModule.component('rcPatientFileRegistrationActions', {
        bindings: {
            $transition$: '<',
        },
        controller: PatientFileRegistrationActionsController,
        templateUrl: 'patient/components/monitoring/registrations/registrationActions/registrationActions.html',
    });
}
