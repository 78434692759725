/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var DateController = /** @class */ (function (_super) {
                __extends(DateController, _super);
                function DateController($scope, $translate, toaster, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.careRequestSvc = careRequestSvc;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                DateController.prototype.$onInit = function () {
                    var _this = this;
                    this.$scope.hideStartDate =
                        // This is currently not available due to a bug in the stepType
                        // this.$scope.careRequest.stepType.Id === Shared.Contract.Code.CareRequestStepType.Application &&
                        this.$scope.careRequestTemplate.totalSteps > this.$scope.careRequest.stepSequence &&
                            this.$scope.careRequestPart.isInternalCarePlanStartDate;
                    this.$scope.careRequestDate = _.find(this.$scope.careRequest.careRequestDates, function (t) { return t.careRequestPartLocalIdentity === _this.$scope.careRequestPart.localIdentity; });
                    if (this.$scope.careRequestDate == null) {
                        this.$scope.careRequestDate = new CareRequest.CareRequestDate();
                        this.$scope.careRequestDate.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                        this.$scope.careRequest.careRequestDates.push(this.$scope.careRequestDate);
                    }
                    if (this.$scope.careRequestPart.isInternalCarePlanStartDate &&
                        (!this.$scope.careRequest.careRequestStatus ||
                            this.$scope.careRequest.careRequestStatus.Id < RemeCare.Shared.Contract.Code.CareRequestStatus.Final)) {
                        this.$scope.minDate = RemeCare.Shared.DateHelper.today();
                        this.$scope.careRequestDate.date = this.$scope.minDate;
                        this.$scope.startDate = moment(this.$scope.careRequestDate.date).format('DD/MM/YYYY');
                        this.$scope.showStartDateInfo = true;
                    }
                    else {
                        this.$scope.minDate = null;
                        this.$scope.showStartDateInfo = false;
                    }
                    this.$scope.toggleStartDate = function () {
                        this.$parent.showStartDateInfo = !this.$parent.showStartDateInfo;
                    };
                };
                DateController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                DateController.prototype.isRequired = function (controlType) {
                    return _super.prototype.isRequired.call(this, controlType) || this.$scope.careRequestPart.isInternalCarePlanStartDate;
                };
                return DateController;
            }(Directive.CareRequestPartControllerBase));
            var DateDirective = /** @class */ (function (_super) {
                __extends(DateDirective, _super);
                function DateDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        'careRequestSvc',
                        function ($scope, $translate, toaster, careRequestSvc) { return new DateController($scope, $translate, toaster, careRequestSvc); },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/date.html';
                    return _this;
                }
                return DateDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestDate', function () { return new DateDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
