/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var ColourQualitativeController = /** @class */ (function (_super) {
            __extends(ColourQualitativeController, _super);
            // @ngInject
            function ColourQualitativeController($rootScope, $locale, dateHelper, spinnerSvc, toaster, carePlanApiSvc, masterdataSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                return _this;
            }
            ColourQualitativeController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var codeSetIds, _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.qualitativeMeasuringPoints = _(this.monitoringPart.monitoringPartSources)
                                    .chain()
                                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QualitativeMeasuringPointParameter; })
                                    .map(function (mps) { return mps; })
                                    .map(function (mps) {
                                    return {
                                        observableEntityId: mps.observableEntity.Id,
                                        codeSetId: mps.codeSet.Id,
                                    };
                                })
                                    .value();
                                codeSetIds = _(this.qualitativeMeasuringPoints).map(function (qmp) { return qmp.codeSetId; });
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                _a = this;
                                return [4 /*yield*/, this.masterdataSvc.findCodeSetTypes(codeSetIds)];
                            case 2:
                                _a.codeSets = _b.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                throw e_1;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            ColourQualitativeController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var oneMonthEarlier, dateFormat_1, graphPoints, graphs, error_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                oneMonthEarlier = moment(this.dateInfo.untilDate);
                                oneMonthEarlier.subtract(1, 'months');
                                this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
                                dateFormat_1 = this.getDateFormat(this.showMultiplePerDay);
                                _.each(this.chartConfigs, function (chartConfig) {
                                    chartConfig.options.tooltip.xDateFormat = dateFormat_1;
                                });
                                return [4 /*yield*/, this.carePlanApiSvc.findQualitativeGraphPointsAsync(this.carePlanIds, this.qualitativeMeasuringPoints, this.dateInfo.fromDate, this.getUntilDate(), this.showMultiplePerDay)];
                            case 1:
                                graphPoints = _a.sent();
                                graphs = _.map(graphPoints, function (g) { return new Patient.Model.QualitativeGraph(g, !_this.showMultiplePerDay); });
                                this.configureCharts(_.sortBy(graphs, function (graph) { return _this.findSequence(graph); }));
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                this.toaster.error(error_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            ColourQualitativeController.prototype.containsData = function (config) {
                return !_(config.series).all(function (s) { return _(s.data).all(function (d) { return d[1] == null; }); });
            };
            ColourQualitativeController.prototype.findSequence = function (graph) {
                var observableEntityId = graph.subject.Id;
                var codeSetId = graph.scale.Id;
                var monitoringPartSource = _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QualitativeMeasuringPointParameter; })
                    .map(function (mps) { return mps; })
                    .find(function (mps) { return mps.observableEntity.Id === observableEntityId && mps.codeSet.Id === codeSetId; })
                    .value();
                var sequence = monitoringPartSource == null ? 0 : monitoringPartSource.sequence;
                return sequence;
            };
            ColourQualitativeController.prototype.configureCharts = function (graphs) {
                var _this = this;
                var chartConfigs = _(graphs).map(function (g) {
                    return {
                        options: {
                            chart: {
                                animation: false,
                                type: 'column',
                                height: 170,
                                zoomType: 'x',
                                marginLeft: 37,
                                marginRight: 37,
                            },
                            xAxis: _this.getXAxisConfig(null, true),
                            yAxis: {
                                title: {
                                    text: '<i></i>',
                                },
                                labels: {
                                    enabled: false,
                                },
                                gridLineWidth: 0,
                                minorGridLineWidth: 0,
                                visible: true,
                                startOnTick: false,
                                endOnTick: false,
                            },
                            credits: {
                                enabled: false,
                            },
                            exporting: {
                                enabled: false,
                            },
                            tooltip: {
                                xDateFormat: _this.getDateFormat(_this.showMultiplePerDay),
                                pointFormat: '<span style="color: {point.color}">\u25CF</span> {series.name}<br/>',
                            },
                            title: {
                                text: null,
                            },
                            subtitle: {
                                text: g.subject.Text,
                                style: {
                                    fontWeight: 'bold',
                                    fontSize: 'larger',
                                },
                            },
                            plotOptions: {
                                column: {
                                    animation: false,
                                    stacking: 'percentage',
                                },
                            },
                            legend: {
                                enabled: _this.showLegend,
                            },
                        },
                        series: _this.mapDataPoints(g),
                    };
                });
                this.chartConfigs = chartConfigs;
            };
            ColourQualitativeController.prototype.mapDataPoints = function (graph) {
                var _this = this;
                var colourQualitative = _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .map(function (mps) { return mps; })
                    .find(function (mps) {
                    return mps.observableEntity &&
                        mps.observableEntity.Id === graph.subject.Id &&
                        mps.codeSet &&
                        graph.scale &&
                        mps.codeSet.Id === graph.scale.Id;
                })
                    .value().sourceParameterRepresentation;
                var cstcsis = _(this.codeSets).find(function (cs) { return cs.Id === graph.scale.Id; }).CodeSetTypeCodeSetItems;
                var series = _(cstcsis)
                    .chain()
                    .map(function (cstcsi) { return ({
                    codeSetItem: cstcsi,
                    codeSetColor: _.find(colourQualitative.codeSetColours, function (csc) { return csc.codeSetItemId === cstcsi.CodeSetItemId; }),
                }); })
                    .sortBy(function (csccsi) { return csccsi.codeSetItem.Sequence; })
                    .map(function (csc) {
                    return {
                        animation: false,
                        name: csc.codeSetItem.Name,
                        color: (csc.codeSetColor && csc.codeSetColor.colour) || ColourQualitativeController.black,
                        data: _(graph.graphPoints).map(function (gp) {
                            return [gp.x.valueOf(), _this.hasDataPoint(gp, csc.codeSetItem.CodeSetItemId) ? 1 : null];
                        }),
                        maxPointWidth: 20,
                        pointPlacement: 'on',
                    };
                })
                    .value();
                return series;
            };
            ColourQualitativeController.prototype.hasDataPoint = function (graphPoint, id) {
                var ids = _(graphPoint.y).map(function (y) { return y.Id; });
                return _(ids).contains(id);
            };
            ColourQualitativeController.black = '#000000';
            return ColourQualitativeController;
        }(Patient.HighChartsMonitoringPartController));
        var ColourQualitativeComponent = /** @class */ (function (_super) {
            __extends(ColourQualitativeComponent, _super);
            function ColourQualitativeComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = ColourQualitativeController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return ColourQualitativeComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringColourQualitative', new ColourQualitativeComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
