var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        // tslint:disable: no-bitwise
        var RemarkRegistration = /** @class */ (function () {
            function RemarkRegistration(serverObject, isSuperUser) {
                this.actionId = serverObject.ActionId;
                this.actionName = serverObject.ActionName;
                this.executionDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);
                this.groups = _.chain(serverObject.Remarks)
                    .groupBy(function (r) { return r.TherapyActionPartId; })
                    .map(function (r) { return new Model.RemarkGroup(r[0], _.map(r, function (rr) { return new Model.Remark(rr); })); })
                    .value();
                this.readOnly = serverObject.ReadOnly;
                this.title = RemeCare.Shared.DateHelper.dateTimeHumanReadible(this.executionDateTime) + ": " + this.actionName;
            }
            return RemarkRegistration;
        }());
        Model.RemarkRegistration = RemarkRegistration;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
