module RemeCare.Management {

    import PartyRoleType = Shared.Contract.Code.PartyRoleType;

    interface IRelatedPersonsScope extends Shared.Framework.IBaseScope {
        person: Model.Person;
        getPatientContacts(): Array<Contract.Party.Read.IPartyRole>;
        getHealthCareNonProfessionalContacts(): Array<Contract.Party.Read.IPartyRole>;
        getHealthCareProfessionalContacts(): Array<Contract.Party.Read.IPartyRole>;
    }

    class RelatedPersonsController extends Shared.Framework.ControllerBase<IRelatedPersonsScope> {
        
        constructor(
            $scope: IRelatedPersonsScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
            $scope.getPatientContacts = () => this.getPatientContacts();
            $scope.getHealthCareNonProfessionalContacts = () => this.getHealthCareNonProfessionalContacts();
            $scope.getHealthCareProfessionalContacts = () => this.getHealthCareProfessionalContacts();
        }

        public $onInit(): void {}

        private getContactPartyRoles(): Array<Contract.Party.Read.IContactPerson> {
            if (this.$scope.person == null) return [];
            return <Array<Contract.Party.Read.IContactPerson>> _(this.$scope.person.partyRoles)
                .filter(pr => pr.PartyRoleType.Id === PartyRoleType.ContactPerson);
        }

        private getPatientContacts(): Array<Contract.Party.Read.IContactPerson> {
            return _(this.getContactPartyRoles())
                .filter(pr => pr.ContactFor.PartyRoleType.Id === PartyRoleType.Patient);
        }

        private getHealthCareNonProfessionalContacts(): Array<Contract.Party.Read.IHealthCareNonProfessional> {
            if (this.$scope.person == null) return [];
            return <Array<Contract.Party.Read.IHealthCareNonProfessional>> _(this.$scope.person.partyRoles)
                .filter(pr => pr.PartyRoleType.Id === PartyRoleType.HealthCareNonProfessional);
        }

        private getHealthCareProfessionalContacts(): Array<Contract.Party.Read.IContactPerson> {
            return _(this.getContactPartyRoles())
                .filter(pr => pr.ContactFor.PartyRoleType.Id === PartyRoleType.HealthCareProfessional);
        }
    }

    remeCareManagementModule.controller('relatedPersonsCtrl', (
        $scope,
        $translate,
        toaster
    ) => new RelatedPersonsController(
        $scope,
        $translate,
        toaster
    ));
}