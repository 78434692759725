module RemeCare.CareRequest.Directive {

    interface IReferenceDateScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        referenceDate: Date;
        form: ng.IFormController;
        confirm(): void;
    }

    class ReferenceDateController extends Shared.Framework.ControllerBase<IReferenceDateScope> {
        
        constructor(
            $scope: IReferenceDateScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {}

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.referenceDate);
        }
    }

    remeCareAppModule.controller('referenceDateCtrl', (
        $scope,
        $translate,
        toaster
    ) => new ReferenceDateController(
        $scope,
        $translate,
        toaster));
}