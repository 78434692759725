var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var QuantitativeObservationController = /** @class */ (function (_super) {
            __extends(QuantitativeObservationController, _super);
            // @ngInject
            function QuantitativeObservationController($scope, guidanceApiSvc) {
                return _super.call(this, $scope, guidanceApiSvc) || this;
            }
            QuantitativeObservationController.prototype.init = function () { };
            return QuantitativeObservationController;
        }(CarePlanAction.ComponentControllerBase));
        var QuantitativeObservationDirective = /** @class */ (function (_super) {
            __extends(QuantitativeObservationDirective, _super);
            function QuantitativeObservationDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/quantitativeObservation.html';
                _this.controller = QuantitativeObservationController;
                return _this;
            }
            return QuantitativeObservationDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcQuantitativeObservation', function () { return new QuantitativeObservationDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
