var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var RegistrationValueFactory = /** @class */ (function () {
            function RegistrationValueFactory() {
            }
            RegistrationValueFactory.createRegistrationValueFromServer = function (serverObject) {
                return this.createRegistrationValue(serverObject.Type, serverObject);
            };
            RegistrationValueFactory.createRegistrationValueFromType = function (type) {
                return this.createRegistrationValue(type);
            };
            RegistrationValueFactory.createRegistrationValue = function (type, serverObject) {
                switch (type) {
                    case RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity:
                        return new RegistrationPhysicalQuantity(serverObject);
                    case RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue:
                        return new RegistrationCodeSetValue(serverObject);
                    case RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue:
                        return new RegistrationMultiCodeSetValue(serverObject);
                    default:
                        throw new Error();
                }
            };
            return RegistrationValueFactory;
        }());
        Model.RegistrationValueFactory = RegistrationValueFactory;
        var RegistrationValue = /** @class */ (function () {
            function RegistrationValue(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.type = serverObject.Type;
                    this.registrationDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.RegistrationDateTime);
                }
            }
            RegistrationValue.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: this.type,
                };
            };
            return RegistrationValue;
        }());
        Model.RegistrationValue = RegistrationValue;
        var RegistrationPhysicalQuantity = /** @class */ (function (_super) {
            __extends(RegistrationPhysicalQuantity, _super);
            function RegistrationPhysicalQuantity(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.amount = serverObject.Amount;
                }
                return _this;
            }
            RegistrationPhysicalQuantity.prototype.getValueString = function () {
                return this.amount.toString();
            };
            RegistrationPhysicalQuantity.prototype.getValueIds = function () {
                return [];
            };
            RegistrationPhysicalQuantity.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.amount = this.amount;
                return result;
            };
            return RegistrationPhysicalQuantity;
        }(RegistrationValue));
        Model.RegistrationPhysicalQuantity = RegistrationPhysicalQuantity;
        var RegistrationCodeSetValue = /** @class */ (function (_super) {
            __extends(RegistrationCodeSetValue, _super);
            function RegistrationCodeSetValue(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.codeSetItem =
                        serverObject.ChoiceListOption != null
                            ? {
                                Id: serverObject.ChoiceListOption.Id,
                                Text: serverObject.ChoiceListOption.Name,
                            }
                            : null;
                }
                return _this;
            }
            RegistrationCodeSetValue.prototype.getValueString = function () {
                return this.codeSetItem.Text;
            };
            RegistrationCodeSetValue.prototype.getValueIds = function () {
                if (this.codeSetItem) {
                    return [this.codeSetItem.Id];
                }
                return [];
            };
            RegistrationCodeSetValue.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.choiceListOptionId = this.codeSetItem ? this.codeSetItem.Id : null;
                return result;
            };
            return RegistrationCodeSetValue;
        }(RegistrationValue));
        Model.RegistrationCodeSetValue = RegistrationCodeSetValue;
        var RegistrationMultiCodeSetValue = /** @class */ (function (_super) {
            __extends(RegistrationMultiCodeSetValue, _super);
            function RegistrationMultiCodeSetValue(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.multiCodeSetItems = [];
                _this.multiCodeSetItemsTranslations = [];
                if (serverObject != null) {
                    _this.multiCodeSetItems = _(serverObject.MultiChoiceListOptions).map(function (x) { return x.Id; });
                    _this.multiCodeSetItemsTranslations = _(serverObject.MultiChoiceListOptions)
                        .chain()
                        .sortBy(function (mclo) { return mclo.Sequence; })
                        .map(function (mclo) {
                        return {
                            Id: mclo.Id,
                            Text: mclo.Name,
                        };
                    })
                        .value();
                }
                return _this;
            }
            RegistrationMultiCodeSetValue.prototype.getValueString = function () {
                return _(this.multiCodeSetItemsTranslations)
                    .map(function (x) { return x.Text; })
                    .toString();
            };
            RegistrationMultiCodeSetValue.prototype.getValueIds = function () {
                return this.multiCodeSetItems;
            };
            RegistrationMultiCodeSetValue.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.multiChoiceListOptionIds = this.multiCodeSetItems;
                return result;
            };
            return RegistrationMultiCodeSetValue;
        }(RegistrationValue));
        Model.RegistrationMultiCodeSetValue = RegistrationMultiCodeSetValue;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
