var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Inbox;
    (function (Inbox) {
        var AuthContext = RemeCare.Shared.Framework.AuthContext;
        var AuthRight = RemeCare.Shared.Framework.AuthRight;
        var RemeCareInboxConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareInboxConfiguration, _super);
            function RemeCareInboxConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareInboxConfiguration.prototype.config = function () {
                this.addComponentRoute('inbox', '/Inbox', 'inbox', ['Views_Inbox', 'Views_Alarms'], null, null, {
                    context: AuthContext.inbox,
                    right: AuthRight.Read
                });
                this.addRoute('inbox.alarmdetail', '/Alarms/:alarmId', 'views/inbox/alarms/detail.html', 'inboxAlarmDetailCtrl', ['Views_Inbox', 'Views_Alarms'], null, null, {
                    context: AuthContext.inboxAlarms,
                    right: AuthRight.Read
                }, {
                    params: {
                        alarmId: null
                    }
                });
            };
            return RemeCareInboxConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        Inbox.remeCareInboxModule.config(function ($stateProvider) {
            return new RemeCareInboxConfiguration($stateProvider);
        });
    })(Inbox = RemeCare.Inbox || (RemeCare.Inbox = {}));
})(RemeCare || (RemeCare = {}));
