var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var PatientFileForExternalSvc = /** @class */ (function (_super) {
            __extends(PatientFileForExternalSvc, _super);
            function PatientFileForExternalSvc() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            PatientFileForExternalSvc.prototype.getPatientFileForExternalAsync = function () {
                return this.getPromise("PatientFileForExternalRequest/Requests", null);
            };
            PatientFileForExternalSvc.prototype.getLastPatientFileForExternalAsync = function () {
                return this.getPromise("PatientFileForExternalRequest/Requests/LastRequest", null);
            };
            PatientFileForExternalSvc.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return PatientFileForExternalSvc;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        CareRequest.PatientFileForExternalSvc = PatientFileForExternalSvc;
        CareRequest.remeCareCareRequestModule.service('patientFileForExternalSvc', PatientFileForExternalSvc);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
