var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var TelephoneNumberDirective = /** @class */ (function () {
                function TelephoneNumberDirective() {
                    this.restrict = 'E';
                    this.scope = {
                        telephoneNumber: '=?',
                        showErrors: '=',
                        showUnrequired: '=',
                        required: '=ngRequired',
                        readOnly: '=',
                        label: '@'
                    };
                    this.templateUrl = 'views/shared/telephoneNumber.html';
                    this.link = function (scope, element, attrs, formCtrl) {
                        scope.formCtrl = formCtrl;
                        scope.validate = function () {
                            scope.valid = scope.telephoneNumber && scope.telephoneNumber.isValid();
                        };
                        scope.validate();
                    };
                }
                return TelephoneNumberDirective;
            }());
            RemeCare.remeCareAppModule.directive('rcTelephoneNumber', function () { return new TelephoneNumberDirective(); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
