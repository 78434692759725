var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var DateFilterType = RemeCare.Contract.Core.Codes.DateFilterTypeCode;
        var PatientFileRegistrationActionsController = /** @class */ (function (_super) {
            __extends(PatientFileRegistrationActionsController, _super);
            function PatientFileRegistrationActionsController($stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc, carePlanActionApiSvc, gridBuilderSvc, modalBuilderFactory, authservice, dateHelper) {
                var _this = _super.call(this, $stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc) || this;
                _this.$stateParams = $stateParams;
                _this.$filter = $filter;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.patientSvc = patientSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.actorRolesApiSvc = actorRolesApiSvc;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                _this.dateHelper = dateHelper;
                return _this;
            }
            PatientFileRegistrationActionsController.prototype.careProvidersTabSelected = function () {
                this.clearGrid();
                this.forPatient = false;
                this.forNonPatient = true;
                this.grid.search();
            };
            PatientFileRegistrationActionsController.prototype.patientsTabSelected = function () {
                this.clearGrid();
                this.forPatient = true;
                this.forNonPatient = false;
                this.grid.search();
            };
            PatientFileRegistrationActionsController.prototype.allTabSelected = function () {
                this.clearGrid();
                this.forPatient = false;
                this.forNonPatient = false;
                this.grid.search();
            };
            PatientFileRegistrationActionsController.prototype.newSearch = function () {
                this.clearGrid();
                this.grid.search();
            };
            PatientFileRegistrationActionsController.prototype.init = function () {
                this.isPatient = this.authservice.isPatient();
                this.activeTab = 0;
                this.editRight = true;
                this.buildGrid();
                this.grid.searchCriteria.selectedDateFilter = DateFilterType.PlannedDate;
                this.grid.searchCriteria.fromDate = this.dateHelper.subtractDuration(this.dateHelper.today(), {
                    Quantity: 3,
                    Unit: RemeCare.Shared.Contract.Code.DurationUnit.Months,
                });
                this.grid.pagingOptions.currentPage = 1;
                if (this.isPatient) {
                    this.grid.search();
                }
            };
            PatientFileRegistrationActionsController.prototype.dataLoaded = function () {
                if (!this.isPatient) {
                    this.allActorRoles = this.actorRoles;
                    this.careProvidersTabSelected();
                }
            };
            PatientFileRegistrationActionsController.prototype.clearGrid = function () {
                this.grid.setData([]);
                this.grid.totalItems = 0;
                this.grid.pagingOptions.currentPage = 1;
            };
            PatientFileRegistrationActionsController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria, count, nextPage) {
                    return _this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria, count, nextPage);
                })
                    .setIsPrevNextGrid()
                    .addColumn('Name', 'General.Name')
                    .addUtcDateColumn('PlannedDateTimeFrom', 'Views.PatientFile.Monitoring.PlannedDate', 'short', {
                    sort: { direction: 'desc' },
                })
                    .addUtcDateColumn('ExecutionDateTime', 'Views.PatientFile.Monitoring.ExecutionDate', 'short')
                    .addUtcDateColumn('ModificationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', 'short')
                    .addColumn('RegisteredBy', 'Views.PatientFile.Monitoring.RegisteredBy')
                    .addEnumColumn('Status', 'General.Status', RemeCare.Shared.Framework.ReferenceDataTypes.carePlanActionStatus)
                    .addConditionalShowEditNoButtonFunctionColumn(function (x) { return _this.viewCarePlanAction(x); }, function (x) { return x.ReadOnly; }, function (x) { return !_this.canViewItem(x); });
                this.grid = gridBuilder.build();
                this.grid.pagingOptions.pageSize = 20;
            };
            PatientFileRegistrationActionsController.prototype.canViewItem = function (selected) {
                return (RemeCare.Shared.Framework.AuthRight.Read & selected.MaxExecutionRight) !== 0;
            };
            PatientFileRegistrationActionsController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortDirection, criteria, count, nextPage) {
                return __awaiter(this, void 0, void 0, function () {
                    var actorRoleIds, query, result, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                actorRoleIds = criteria.actorRoleIds;
                                if (this.actorRoles && actorRoleIds && this.allActorRoles.length === actorRoleIds.length) {
                                    // If all actor roles are selected, deselect all
                                    actorRoleIds = [];
                                }
                                query = {
                                    patientId: this.$transition$.params().patientId,
                                    carePlanId: criteria.carePlanId,
                                    actionTemplateIds: criteria.selectedActions,
                                    registeredBy: criteria.selectedPersons,
                                    statuses: criteria.selectedStatuses,
                                    actorRoleIds: this.insertAllPatientRoles(actorRoleIds),
                                    dateFilterType: criteria.selectedDateFilter,
                                    startDate: criteria.selectedDateFilter ? RemeCare.Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                                    endDate: criteria.selectedDateFilter ? RemeCare.Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    includeCount: count,
                                    includeHasNextPage: nextPage,
                                    forPatient: this.forPatient,
                                    forNonPatient: this.forNonPatient,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                // Don't await loading of filter
                                this.loadStructuralTherapyActionsFilterAsync(query);
                                return [4 /*yield*/, this.carePlanActionApiSvc.findRegistrationsAsync(query)];
                            case 2:
                                result = _a.sent();
                                return [2 /*return*/, result];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                throw e_1;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileRegistrationActionsController.prototype.loadStructuralTherapyActionsFilterAsync = function (registrationsQuery) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, query, result, oldFilterValues, alreadySelected;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                query = {
                                    patientId: registrationsQuery.patientId,
                                    carePlanId: registrationsQuery.carePlanId,
                                    registeredBy: registrationsQuery.registeredBy,
                                    statuses: registrationsQuery.statuses,
                                    actorRoleIds: registrationsQuery.actorRoleIds,
                                    dateFilterTypes: registrationsQuery.dateFilterType ? [registrationsQuery.dateFilterType] : [],
                                    startDate: registrationsQuery.startDate,
                                    endDate: registrationsQuery.endDate,
                                    forPatient: registrationsQuery.forPatient,
                                    forNonPatient: registrationsQuery.forNonPatient,
                                };
                                return [4 /*yield*/, this.carePlanActionApiSvc.findPossibleRegistrationActionsFilterAsync(query)];
                            case 1:
                                result = _b.sent();
                                oldFilterValues = this.structuralTherapyActions;
                                this.structuralTherapyActions = _.sortBy(result, function (sta) { return (sta.Text ? sta.Text.toLowerCase() : ''); });
                                if (!this.grid.searchCriteria.selectedActions || !this.grid.searchCriteria.selectedActions.length) {
                                    return [2 /*return*/];
                                }
                                alreadySelected = _.filter(oldFilterValues, function (fv) {
                                    return _.contains(_this.grid.searchCriteria.selectedActions, fv.Id);
                                });
                                // Add already selected values to the new list of possible filter values
                                (_a = this.structuralTherapyActions).push.apply(_a, alreadySelected);
                                // Filter duplicates
                                this.structuralTherapyActions = _.uniq(this.structuralTherapyActions, function (sta) { return sta.Id; });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileRegistrationActionsController.prototype.viewCarePlanAction = function (cpa) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        this.modalBuilderFactory
                            .createModalBuilder()
                            .setController('carePlanActionDetailModalCtrl')
                            .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                            .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                            .setScope({
                            actionId: cpa.Id,
                            editRight: !cpa.ReadOnly,
                        })
                            .setResultCallBack(function (a) {
                            if (a != null) {
                                var tempData = _this.grid.getData();
                                _.findWhere(tempData, { Id: a.id }).Status = a.status;
                                _this.grid.setData(tempData);
                            }
                        })
                            .build();
                        return [2 /*return*/];
                    });
                });
            };
            return PatientFileRegistrationActionsController;
        }(Patient.PatientRegistrationsBaseController));
        Patient.remeCarePatientModule.component('rcPatientFileRegistrationActions', {
            bindings: {
                $transition$: '<',
            },
            controller: PatientFileRegistrationActionsController,
            templateUrl: 'patient/components/monitoring/registrations/registrationActions/registrationActions.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
