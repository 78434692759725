var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var MediaForAction = /** @class */ (function () {
                function MediaForAction(serverObjects) {
                    this.action = serverObjects[0].Action;
                    this.actionPart = serverObjects[0].ActionPart;
                    this.name = this.action.Text;
                    if (this.actionPart.Text) {
                        this.name += ' - ' + this.actionPart.Text;
                    }
                    var lastProvidedSequence = _(serverObjects).max(function (x) { return moment(x.LastTimeProvided).toDate(); }).Sequence;
                    var maxSequence = _(serverObjects).max(function (x) { return x.Sequence; }).Sequence;
                    this.pastMedia = _(serverObjects)
                        .chain()
                        .filter(function (x) { return x.LastTimeProvided != null && x.LastTimeProvided !== ''; })
                        .map(function (x) { return new PastCarePlanMediaLibItem(x); })
                        .sortBy(function (x) { return -x.lastTimeProvided; })
                        .value();
                    this.futureMedia = _(serverObjects)
                        .chain()
                        .map(function (x) { return new FutureCarePlanMediaLibItem(x, lastProvidedSequence, maxSequence); })
                        .sortBy(function (x) { return -x.relativeSequence; })
                        .value();
                    this.readOnly = serverObjects[0].ReadOnly;
                }
                return MediaForAction;
            }());
            Model.MediaForAction = MediaForAction;
            var CarePlanMediaLibItem = /** @class */ (function () {
                function CarePlanMediaLibItem(serverObject) {
                    this.id = serverObject.Id;
                    this.carePlanId = serverObject.CarePlanId;
                    this.name = serverObject.Name;
                    this.type = serverObject.Type;
                }
                return CarePlanMediaLibItem;
            }());
            Model.CarePlanMediaLibItem = CarePlanMediaLibItem;
            var PastCarePlanMediaLibItem = /** @class */ (function (_super) {
                __extends(PastCarePlanMediaLibItem, _super);
                function PastCarePlanMediaLibItem(serverObject) {
                    var _this = _super.call(this, serverObject) || this;
                    _this.lastTimeProvided = moment(serverObject.LastTimeProvided).toDate();
                    return _this;
                }
                return PastCarePlanMediaLibItem;
            }(CarePlanMediaLibItem));
            Model.PastCarePlanMediaLibItem = PastCarePlanMediaLibItem;
            var FutureCarePlanMediaLibItem = /** @class */ (function (_super) {
                __extends(FutureCarePlanMediaLibItem, _super);
                function FutureCarePlanMediaLibItem(serverObject, lastProvidedSequence, maxSequence) {
                    var _this = _super.call(this, serverObject) || this;
                    _this.hasLastProvided = !!lastProvidedSequence;
                    lastProvidedSequence = lastProvidedSequence || 0;
                    _this.sequence = serverObject.Sequence;
                    _this.relativeSequence = _this.sequence - lastProvidedSequence - 1;
                    if (_this.relativeSequence < 0) {
                        _this.relativeSequence += maxSequence;
                    }
                    return _this;
                }
                return FutureCarePlanMediaLibItem;
            }(CarePlanMediaLibItem));
            Model.FutureCarePlanMediaLibItem = FutureCarePlanMediaLibItem;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
