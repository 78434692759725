module RemeCare.Agenda {
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;

    class LastAgendaController implements ng.IComponentController {

        constructor(
            private $state: ng.ui.IStateService,
            private storageSvc: Shared.Framework.Service.StorageService) {
        }

        public $onInit(): void {
            let knownAgenda = this.storageSvc.get<string>(StorageServiceConstants.agendaKey);
            if (!knownAgenda) {
                //if we don't have a last value we want to be redirected to myAgenda
                knownAgenda = StorageServiceConstants.myAgenda;
            }
            this.$state.go(knownAgenda);
        }
    }

    remeCareAgendaModule.component('rcLastAgenda',
        {
            controller: LastAgendaController
        });
}