module RemeCare {

    class RemeCareHeaderController implements ng.IComponentController {

        public hasIntegrationMonitor: boolean = false;

        constructor(
            private authservice: Shared.Framework.AuthService,
            private applicationConfigurationService: Shared.Framework.ApplicationConfigurationService,
            private readonly $rootScope: ng.IRootScopeService,) {

        }

        public async $onInit(): Promise<void> {
            this.$rootScope.$on(RemeCare.Shared.AppEvents.claimsChanged, async () => {
                await this.setHasIntegrationMonitor();
            });
        }

        public isPatient(): boolean {
            return this.authservice.isPatient();
        }

        public userPartyRoleId(): Shared.Contract.Guid {
            return this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId);
        }

        public async setHasIntegrationMonitor(): Promise<void> {
            if (this.authservice.isAuthenticated()) {
                this.hasIntegrationMonitor = await this.applicationConfigurationService.getFeatureFlagIntegrationMonitor();
            }
        }
    }

    remeCareAppModule.component('rcRemeCareHeader',
        {
            controller: RemeCareHeaderController,
            templateUrl: 'views/shared/remeCareHeader.html',
        });
}