namespace RemeCare.CarePlanAction {
    interface IRequestInformationObjectScope extends IComponentScope {
        component: RequestInformationObjectDescriptor;
        advancedColumns: string[];
        onDocumentAdded(d: Shared.Contract.IDocument): void;
        getAdvancedColumnData(id: Shared.Contract.Guid): void;
    }

    class RequestInformationObjectController extends ComponentControllerBase<IRequestInformationObjectScope> {
        // @ngInject
        constructor(
            protected $scope: IRequestInformationObjectScope,
            private readonly $filter: ng.IFilterService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly documentSvc: Shared.Service.DocumentService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
            this.$scope.getAdvancedColumnData = (id: Shared.Contract.Guid) => this.getAdvancedColumnData(id);
            this.$scope.onDocumentAdded = (d: Shared.Contract.IDatedDocument) => this.onDocumentAdded(d);
        }

        protected init(): void {
            this.$scope.advancedColumns = this.$scope.component.hasReferenceDate
                ? ['General.DocumentObservationDate']
                : [];
        }

        private getAdvancedColumnData(id: Shared.Contract.Guid): string[] {
            if (!this.$scope.component.hasReferenceDate) {
                return [];
            }
            const patientFileDocument = _.find(this.$scope.component.value, (c) => c.Id === id);
            if (patientFileDocument == null) {
                return [''];
            }
            return [
                this.$filter('date')(
                    Shared.DateHelper.serverDateStringToDate(patientFileDocument.ReferenceDate),
                    'shortDate'
                ),
            ];
        }

        private async onDocumentAdded(d: Shared.Contract.IDatedDocument): Promise<void> {
            if (this.$scope.component.hasReferenceDate) {
                const date = await this.showReferenceDateModal();
                if (date) {
                    d.ReferenceDate = Shared.DateHelper.toServerDateString(date);
                } else {
                    this.documentSvc.deleteDocument(d.Id);
                    const index = this.$scope.component.value.indexOf(d);
                    const newArray = this.$scope.component.value.slice();
                    newArray.splice(index, 1);
                    this.$scope.component.value = newArray;
                }
            }
        }

        private async showReferenceDateModal(): Promise<Date> {
            return new Promise<Date>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder<Date>()
                    .setController('referenceDateCtrl')
                    .setTemplateUrl('views/shared/referenceDate.html')
                    .setResultCallBack((r) => resolve(r))
                    .setDismissCallBack(() => resolve(null))
                    .build();
            });
        }
    }

    class RequestInformationObjectDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/requestInformationObject.html';

        public controller = RequestInformationObjectController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcRequestInformationObject', () => new RequestInformationObjectDirective());
}
