module RemeCare.Patient {

    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import GenderCode = Contract.Core.Codes.GenderCode;
    import IEnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import Nationality = Shared.Contract.Code.Nationality;

    class PersonalInformationEditController implements ng.IComponentController {
        public personTitles: Array<EnumTranslation>;
        public communicationLanguages: Array<EnumTranslation>;
        public languages: Array<EnumTranslation>;
        public nationalities: Array<IEnumCodeTranslation>;
        public genders: Array<EnumTranslation>;
        public maritalStatuses: Array<EnumTranslation>;
        public educationLevels: Array<EnumTranslation>;
        public technologicalAffinities: Array<EnumTranslation>;
        public personalInfoForm: Shared.Framework.Directive.IFormController;
        public person: RemeCare.Model.PatientPerson;
        public onSavePatient: (params: {
            person: RemeCare.Model.PatientPerson;
        }) => void;

        constructor(
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly nationalNumberSvc: NationalNumberService,
            private readonly toaster: Shared.Framework.Toaster) {
        }

        public $onInit(): void {
            this.masterdataSvc.getTitles()
                .success(r => this.personTitles = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getCommLanguages()
                .success(r => this.communicationLanguages = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getLanguages()
                .success(r => this.languages = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getNationalities()
                .success(r => this.nationalities = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getGenders()
                .success(r => this.genders = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getMaritalStatusses()
                .success(r => this.maritalStatuses = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getEducations()
                .success(r => this.educationLevels = r)
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getTechnologicalAffinities()
                .success(r => this.technologicalAffinities = r)
                .error(e => this.toaster.error(e));
        }

        public confirm(): void {
            this.personalInfoForm.$setSubmitted();
            if (this.personalInfoForm.$invalid) {
                this.personalInfoForm.showValidationErrorMessage();
                return;
            }
            this.onSavePatient({
                person: this.person
            });
        }

        public registerDateOfDeath(): void {
            this.modalBuilderFactory
                .createModalBuilder<Date>()
                .setController('registerDateOfDeathCtrl')
                .setTemplateUrl('views/patient/patientInfo/personalInfo/registerDateOfDeath.html')
                .setResultCallBack(r => this.person.dateOfDeath = r)
                .setResolve({
                    partyId: () => this.person.partyId
                })
                .build();
        }

        public validNationalNumber(value: string): boolean {
            return !value
                || !this.person.nationality
                || this.person.nationality.Id !== Nationality.Belgian
                || this.nationalNumberSvc.isValidNationalNumber(value);
        }

        public nationalNumberChanged(): void {
            this.validateIdentificationAndSetProperties(true, true, true, true);
        }

        public isBirthDateValid(): boolean {
            return this.nationalNumberSvc.isValidDateOfBirth(
                this.person.dateOfBirth,
                this.person.nationalNumber);
        }

        public dateOfBirthChanged(): void {
            this.validateIdentificationAndSetProperties(true, false, false);
        }

        public isGenderValid(): boolean {
            return this.nationalNumberSvc.isValidGender(
                this.person.gender && this.person.gender.Id,
                this.person.nationalNumber);
        }

        public genderChanged(): void {
            this.validateIdentificationAndSetProperties(false, true, false);
        }

        public isNationalityValid(): boolean {
            return this.nationalNumberSvc.isValidNationality(
                this.person.nationality && this.person.nationality.Id,
                this.person.nationalNumber);
        }

        public nationalityChanged(): void {
            this.validateIdentificationAndSetProperties(false, false, true);
        }

        private validateIdentificationAndSetProperties(
            checkDateOfBirth: boolean,
            checkGender: boolean,
            checkNationality: boolean,
            overwrite?: boolean
        ): void {
            const dateOfBirthGetterSetter =
                checkDateOfBirth
                    ? (dateOfBirth?: Date) => {
                        if (dateOfBirth) {
                            this.person.dateOfBirth = dateOfBirth;
                        }
                        return this.person.dateOfBirth;
                    }
                    : null;
            const genderGetterSetter =
                checkGender
                    ? (gender?: GenderCode) => {
                        if (gender) {
                            this.person.gender = _.find(this.genders, (g) => g.Id === gender);
                        }
                        return this.person.gender && this.person.gender.Id;
                    }
                    : null;
            const nationalityGetterSetter =
                checkNationality
                    ? (nationality?: number) => {
                        if (nationality) {
                            this.person.nationality = _.find(this.nationalities, (n) => n.Id === nationality);
                        }
                        return this.person.nationality && this.person.nationality.Id;
                    }
                    : null;
            this.nationalNumberSvc.validateAndSetProperties(
                this.person.nationalNumber,
                dateOfBirthGetterSetter,
                genderGetterSetter,
                nationalityGetterSetter,
                null,
                overwrite
            );
        }
    }

    remeCarePatientModule.component('editPatient',
    {
        controller: PersonalInformationEditController,
        templateUrl: 'patient/controllers/patientInfo/personalInfo/editPatient/editPatient.html',
        bindings: {
            person: '<',
            onSavePatient: '&',
            onCancel: '&'
        },
        transclude: true
    });
}