var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        var TeamAgendaController = /** @class */ (function () {
            function TeamAgendaController($state, actorRolesApiSvc, authservice, carePlanActionApiSvc, gridBuilderSvc, masterdataSvc, partyApiSvc, spinnerSvc, therapyApiSvc, toaster, storageSvc, uiGridConstants) {
                this.$state = $state;
                this.actorRolesApiSvc = actorRolesApiSvc;
                this.authservice = authservice;
                this.carePlanActionApiSvc = carePlanActionApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.masterdataSvc = masterdataSvc;
                this.partyApiSvc = partyApiSvc;
                this.spinnerSvc = spinnerSvc;
                this.therapyApiSvc = therapyApiSvc;
                this.toaster = toaster;
                this.storageSvc = storageSvc;
                this.uiGridConstants = uiGridConstants;
            }
            TeamAgendaController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                // we store this as the last chosen agenda
                                this.storageSvc.store(StorageServiceConstants.agendaKey, StorageServiceConstants.teamAgenda);
                                this.currentState = this.$state.current;
                                this.buildAgendaGrid();
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getReferenceDataAsync(RemeCare.Shared.Framework.ReferenceDataTypes.actionAssignmentType),
                                        this.masterdataSvc.getCarePlanActionStatuses(),
                                        this.masterdataSvc.getCarePlanActionTypes(),
                                    ])];
                            case 2:
                                _a = _b.sent(), this.actionAssignmentTypes = _a[0], this.actionStatuses = _a[1], this.actionTypes = _a[2];
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            TeamAgendaController.prototype.buildAgendaGrid = function () {
                var _this = this;
                this.gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria, count, nextPage) {
                    return _this.executeSearch(page, pageSize, sortField, sortDirection, criteria, count, nextPage);
                })
                    .setIsPrevNextGrid()
                    .setBindToUrl()
                    .setSearchWhenReady()
                    .addDateColumn('plannedDateTimeFrom', 'Views.Agenda.Search.ExecutionFrom', 'short', {
                    sort: { direction: this.uiGridConstants.ASC },
                })
                    .addDateColumn('plannedDateTimeUntil', 'Views.Agenda.Search.ExecutionUntil', 'short')
                    .addColumn('patient', 'Views.Agenda.Search.Patient', { width: '*' });
                if (this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientInternal, RemeCare.Shared.Framework.AuthRight.Read)) {
                    this.gridBuilder = this.gridBuilder.addColumn('patientNumber', 'Patient.PatientNumber');
                }
                this.gridBuilder = this.gridBuilder
                    .addColumn('address', 'General.Place', { width: '*', enableSorting: false })
                    .addColumn('name', 'General.Action', { width: '**' })
                    .addEnumColumn('status', 'General.Status', RemeCare.Shared.Framework.ReferenceDataTypes.carePlanActionStatus, {
                    width: '*',
                    enableSorting: false,
                })
                    .addColumn('therapies', 'General.Therapy', {
                    width: '*',
                    enableSorting: false,
                    cellFilter: 'delimitedDisplay',
                })
                    .addColumn('actors', 'Views.Agenda.Search.Actor', {
                    width: '**',
                    enableSorting: false,
                    cellFilter: 'delimitedDisplay',
                });
                this.gridBuilder = this.gridBuilder.addConditionalShowEditNoButtonNavigationColumn(function (x) { return !x.canBeEdited; }, function (x) { return !x.canBeViewed; }, 'editAction', { actionIds: 'id', referringState: 'currentState' });
                this.setSearchCriteria();
            };
            TeamAgendaController.prototype.setSearchCriteria = function () {
                var _this = this;
                // we first get all the persistently stored values for the filter
                var knownInformation = this.storageSvc.get(StorageServiceConstants.teamAgendaKey);
                this.agendaGrid = this.gridBuilder
                    .setSearchCriteria(function (c) {
                    var empty = _(c).isEmpty();
                    c.fromDate = c.fromDate || (knownInformation && knownInformation.knownFrom);
                    c.untilDate = c.untilDate || (knownInformation && knownInformation.knownUntil);
                    c.therapyIds = c.therapyIds || (knownInformation && knownInformation.knownTherapies) || [];
                    c.actionTypes = c.actionTypes || [];
                    c.statuses = c.statuses || [];
                    c.actionAssignmentTypes = c.actionAssignmentTypes || [];
                    c.actorRoles = c.actorRoles || (knownInformation && knownInformation.knownActorRoles) || [];
                    if (empty) {
                        c.statuses = [RemeCare.Contract.Core.Codes.CarePlanActionStatus.Open];
                        c.actionAssignmentTypes = [
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Me,
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.OtherInActorRole,
                            RemeCare.Shared.Contract.Code.ActionAssignmentType.Unassigned,
                        ];
                    }
                    _this.setDelayedCriteria(c);
                })
                    .build();
            };
            TeamAgendaController.prototype.setDelayedCriteria = function (criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, actorRoles, globalActorRoles, therapies, allActorRoles;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, Promise.all([
                                    this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true }),
                                    this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager ||
                                        this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager
                                        ? this.actorRolesApiSvc.findGlobalActorRolesAsync()
                                        : Promise.resolve([]),
                                    this.therapyApiSvc.getTherapiesAsync()
                                ])];
                            case 1:
                                _a = _b.sent(), actorRoles = _a[0], globalActorRoles = _a[1], therapies = _a[2];
                                allActorRoles = _(actorRoles).map(function (ar) {
                                    return {
                                        Id: ar.Id,
                                        Text: ar.IsGlobal ? ar.Text : ar.Text + " (" + ar.Therapy.Text + ")",
                                    };
                                });
                                if (criteria) {
                                    // Select all actor roles when empty
                                    if (criteria.actorRoles.length === 0) {
                                        _(actorRoles).forEach(function (ar) { return criteria.actorRoles.push(ar.Id); });
                                    }
                                }
                                _(globalActorRoles)
                                    .filter(function (ga) { return _.find(allActorRoles, function (ar) { return ar.Id === ga.Id; }) == null; })
                                    .forEach(function (ga) { return allActorRoles.push(ga); });
                                this.actorRoles = _(allActorRoles).sortBy(function (ar) { return ar.Text; });
                                // Set Therapies
                                if (criteria) {
                                    if (criteria.therapyIds.length !== 0) {
                                        criteria.therapyIds = criteria.therapyIds.filter(function (item) { return therapies.map(function (t) { return t.Id; }).indexOf(item) > -1; });
                                    }
                                }
                                this.therapies = therapies;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            TeamAgendaController.prototype.handleFilters = function (criteria) {
                var therapyIdsNew = null;
                var fromNew = null;
                var untilNew = null;
                var actorRolesNew = null;
                if (criteria.therapyIds) {
                    therapyIdsNew = criteria.therapyIds;
                }
                if (criteria.fromDate) {
                    fromNew = criteria.fromDate;
                }
                if (criteria.untilDate) {
                    untilNew = criteria.untilDate;
                }
                if (criteria.actorRoles) {
                    actorRolesNew = criteria.actorRoles;
                }
                var knownFilters = {
                    knownFrom: fromNew,
                    knownUntil: untilNew,
                    knownActorRoles: actorRolesNew,
                    knownTherapies: therapyIdsNew,
                };
                this.storageSvc.store(StorageServiceConstants.teamAgendaKey, knownFilters);
            };
            TeamAgendaController.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria, includeCount, includeHasNextPage) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, data, actions, partyRoleIds, persons_1, agendaItems, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    includeCount: includeCount,
                                    includeHasNextPage: includeHasNextPage,
                                    dateFilterTypes: [RemeCare.Contract.Core.Codes.DateFilterTypeCode.PlannedDate],
                                    startDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.fromDate),
                                    endDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.untilDate),
                                    patientId: criteria.patientData != null && criteria.patientData.patient != null
                                        ? criteria.patientData.patient.Id
                                        : null,
                                    patientNumber: (criteria.patientData == null || criteria.patientData.patient == null) &&
                                        criteria.patientData.patientNumber != null
                                        ? criteria.patientData.patientNumber
                                        : null,
                                    statuses: criteria.statuses,
                                    actionAssignmentTypes: criteria.actionAssignmentTypes,
                                    actionTypes: criteria.actionTypes,
                                    actorRoles: criteria.actorRoles,
                                    therapyIds: criteria.therapyIds,
                                };
                                this.handleFilters(criteria);
                                this.spinnerSvc.show('agendasearch');
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 6, 7, 8]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.findAgendaActions(query)];
                            case 2:
                                data = _a.sent();
                                actions = data.Items;
                                partyRoleIds = _(data.Items).map(function (x) { return x.Patient.Id; });
                                if (!(_(partyRoleIds).size() !== 0)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.partyApiSvc.getPersonsAsync(partyRoleIds)];
                            case 3:
                                persons_1 = _a.sent();
                                agendaItems = _(actions).map(function (x) {
                                    var agendaItem = new RemeCare.Model.AgendaAction(x, persons_1);
                                    agendaItem.currentState = _this.currentState;
                                    return agendaItem;
                                });
                                return [2 /*return*/, {
                                        Total: data.Total,
                                        Items: agendaItems,
                                        HasNextPage: data.HasNextPage,
                                    }];
                            case 4:
                                this.spinnerSvc.hide('agendasearch');
                                return [2 /*return*/, {
                                        Total: data.Total,
                                        Items: null,
                                    }];
                            case 5: return [3 /*break*/, 8];
                            case 6:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 8];
                            case 7:
                                this.spinnerSvc.hide('agendasearch');
                                return [7 /*endfinally*/];
                            case 8: return [2 /*return*/];
                        }
                    });
                });
            };
            return TeamAgendaController;
        }());
        Agenda.remeCareAgendaModule.component('rcTeamAgenda', {
            controller: TeamAgendaController,
            templateUrl: 'agenda/teamAgenda/teamAgenda.html',
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
