namespace RemeCare.Shared.Component {
    interface IGroupSimpleSearchScope extends Shared.Framework.IBaseScope {
        groupId: Shared.Contract.Guid;
        group: RemeCare.Contract.Management.Read.IGroup;
        preferredGroupFirstName: () => string;
        preferredGroupLastName: () => string;
        searchGroup(): void;
        clearGroup(): void;
    }

    class GroupSimpleSearchController extends Shared.Framework.ControllerBase<IGroupSimpleSearchScope> {
        public changed: () => void;
        public preferredGroupLastName: string;

        // @ngInject
        constructor(
            $scope: IGroupSimpleSearchScope,
            $translate,
            toaster,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.searchGroup = () => this.searchGroup();
            $scope.clearGroup = () => this.clearGroup();
            this.$onInit();
        }

        public $onInit(): void {
        }

        public onChange(): void {
            if (this.$scope.changed) {
                this.$scope.changed();
            }
        }

        private async searchGroup(): Promise<void> {
            this.modalBuilderFactory
                .createModalBuilder<RemeCare.Contract.Management.Read.IGroup>()
                .setController('groupSearchController as searchCtrl')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setTemplateUrl('shared/components/groupSearch/groupSearch.html')
                .setResultCallBack(r => {
                    this.selectGroup(r);
                })
                .setScope({
                    preferredGroupFirstName: await this.$scope.preferredGroupFirstName(),
                    preferredGroupLastName: await this.$scope.preferredGroupLastName()
                })
                .build();
        }

        private selectGroup(group: RemeCare.Contract.Management.Read.IGroup): void {
            this.$scope.group = group;
            this.$scope.groupId = group.Id;
            this.onChange();
        }

        private clearGroup(): void {
            this.$scope.group = null;
            this.$scope.groupId = null;
            this.onChange();
        }
    }

    class GroupSimpleSearchDirective implements ng.IDirective {
        public restrict = 'E';

        public templateUrl = 'views/shared/groupSimpleSearch.html';

        public scope: { [boundProperty: string]: string } = {
            groupId: '=ngModel',
            changed: '&?ngChange',
            preferredGroupFirstName: '&',
            preferredGroupLastName: '&',
        };

        public controller = GroupSimpleSearchController;
    }

    remeCareAppModule.directive('rcGroupSimpleSearch', () => new GroupSimpleSearchDirective());
}
