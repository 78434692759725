var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var ActionBase = /** @class */ (function () {
            function ActionBase(authservice, actionTemplateId, serverObject) {
                if (serverObject) {
                    var cFactory_1 = new RemeCare.CarePlanAction.ComponentDescriptorFactory();
                    this.components = _(serverObject.ActionParts).map(function (c) { return cFactory_1.createComponentDescriptor(authservice, c); });
                    this.customScreen = serverObject.CustomScreen;
                    this.id = serverObject.Id;
                    this.actionTemplateId = actionTemplateId;
                    this.name = serverObject.Name;
                    this.textRegistration = serverObject.TextRegistration;
                    this.textFooter = serverObject.TextFooter;
                    this.includesEventDate = serverObject.IncludesEventDate;
                    this.includesEventTime = serverObject.IncludesEventTime;
                    this.isEventDateTimeRange = serverObject.IsEventDateTimeRange;
                    this.eventDateTimeLabel = serverObject.EventDateTimeLabel;
                }
            }
            ActionBase.prototype.getEventDateTimeFrom = function () {
                var result = moment(this.eventDateFrom)
                    .startOf('day')
                    .toDate();
                if (this.includesEventTime) {
                    RemeCare.Shared.DateHelper.setTime(result, this.eventTimeFrom);
                }
                return result;
            };
            ActionBase.prototype.getEventDateTimeUntil = function () {
                var result = moment(this.eventDateUntil)
                    .startOf('day')
                    .toDate();
                if (this.includesEventTime) {
                    RemeCare.Shared.DateHelper.setTime(result, this.eventTimeUntil);
                }
                return result;
            };
            ActionBase.prototype.hasCareAct = function () {
                return _(this.components).any(function (c) { return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.CareAct; });
            };
            return ActionBase;
        }());
        Model.ActionBase = ActionBase;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
