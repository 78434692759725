var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var TodosController = /** @class */ (function () {
            function TodosController(authservice) {
                this.authservice = authservice;
            }
            TodosController.prototype.$onInit = function () {
                this.isPatient = this.authservice.isPatient();
            };
            TodosController.prototype.$onChanges = function (changes) {
                this.mapTodos(changes['agendaItems'].currentValue);
            };
            TodosController.prototype.showTodo = function (todoItem) {
                return this.filter.showAllTodos ||
                    _(todoItem.actions).any(function (a) { return a.status == RemeCare.Contract.Core.Codes.CarePlanActionStatus.Open; });
            };
            TodosController.prototype.mapTodos = function (actions) {
                var _this = this;
                this.todoItems = _(actions).chain()
                    .filter(function (a) { return a.showAsToDo; })
                    .groupBy(function (a) { return _this.getTodoDate(a); })
                    .map(function (ag) {
                    return {
                        date: RemeCare.Shared.DateHelper.dayOfDate(_this.getTodoDate(ag[0])),
                        actions: _(ag).chain()
                            .forEach(function (a) { return a.name = _this.authservice.isPatient() ? a.name : a.name + " - " + a.patient; })
                            .sortBy(function (a) { return _this.getTodoDate(a); }).value()
                    };
                })
                    .sortBy(function (ag) { return ag.date; })
                    .filter(function (ag) { return !moment(_this.filter.fromDate).isAfter(ag.date); })
                    .value();
            };
            TodosController.prototype.getTodoDate = function (todo) {
                if (todo.status < RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed) {
                    if (todo.plannedDateTimeFrom !== todo.plannedDateTimeUntil) {
                        var result = this.getMinMaxDate(todo.plannedDateTimeUntil, todo.plannedDateTimeFrom, RemeCare.Shared.DateHelper.today());
                        return result;
                    }
                    if (todo.openEventWindowDateTime && todo.closeEventWindowDateTime
                        && todo.openEventWindowDateTime !== todo.closeEventWindowDateTime) {
                        var result = this.getMinMaxDate(todo.closeEventWindowDateTime, todo.openEventWindowDateTime, RemeCare.Shared.DateHelper.today());
                        return result;
                    }
                    return RemeCare.Shared.DateHelper.dayOfDate(todo.plannedDateTimeFrom);
                }
                else {
                    if (todo.plannedDateTimeFrom !== todo.plannedDateTimeUntil) {
                        var result = this.getMinMaxDate(todo.plannedDateTimeUntil, todo.plannedDateTimeFrom, todo.executionDateTime);
                        return result;
                    }
                    if (todo.openEventWindowDateTime && todo.closeEventWindowDateTime
                        && todo.openEventWindowDateTime !== todo.closeEventWindowDateTime) {
                        var result = this.getMinMaxDate(todo.closeEventWindowDateTime, todo.openEventWindowDateTime, todo.executionDateTime);
                        return result;
                    }
                    return RemeCare.Shared.DateHelper.dayOfDate(todo.plannedDateTimeFrom);
                }
            };
            TodosController.prototype.getMinMaxDate = function (min, max1, max2) {
                return RemeCare.Shared.DateHelper.dayOfDate(moment.min(moment(min), moment.max(moment(max1), moment(max2))).toDate());
            };
            return TodosController;
        }());
        Agenda.remeCareAgendaModule.component('rcAgendaTodos', {
            controller: TodosController,
            controllerAs: 'todosCtrl',
            templateUrl: 'views/agenda/todos.html',
            bindings: {
                agendaItems: '<',
                filter: '=',
                selectedActions: '='
            }
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
