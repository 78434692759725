var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var AdministrationMeansCode;
                (function (AdministrationMeansCode) {
                    AdministrationMeansCode[AdministrationMeansCode["Oral"] = 1] = "Oral";
                    AdministrationMeansCode[AdministrationMeansCode["Subcutaneous"] = 2] = "Subcutaneous";
                    AdministrationMeansCode[AdministrationMeansCode["Intramuscular"] = 3] = "Intramuscular";
                    AdministrationMeansCode[AdministrationMeansCode["Intravenous"] = 4] = "Intravenous";
                    AdministrationMeansCode[AdministrationMeansCode["Transdermal"] = 5] = "Transdermal";
                    AdministrationMeansCode[AdministrationMeansCode["Inhalation"] = 6] = "Inhalation";
                    AdministrationMeansCode[AdministrationMeansCode["Enteral"] = 7] = "Enteral";
                    AdministrationMeansCode[AdministrationMeansCode["Nasal"] = 8] = "Nasal";
                    AdministrationMeansCode[AdministrationMeansCode["Ocular"] = 9] = "Ocular";
                    AdministrationMeansCode[AdministrationMeansCode["Vaginal"] = 10] = "Vaginal";
                    AdministrationMeansCode[AdministrationMeansCode["Cutaneous"] = 11] = "Cutaneous";
                    AdministrationMeansCode[AdministrationMeansCode["Ear"] = 12] = "Ear";
                    AdministrationMeansCode[AdministrationMeansCode["Anal"] = 13] = "Anal";
                    AdministrationMeansCode[AdministrationMeansCode["Intracavernal"] = 14] = "Intracavernal";
                    AdministrationMeansCode[AdministrationMeansCode["Chirurgical"] = 15] = "Chirurgical";
                    AdministrationMeansCode[AdministrationMeansCode["Buccal"] = 16] = "Buccal";
                    AdministrationMeansCode[AdministrationMeansCode["Other"] = 17] = "Other";
                })(AdministrationMeansCode = Codes.AdministrationMeansCode || (Codes.AdministrationMeansCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
