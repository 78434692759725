module RemeCare.Shared.Filter {
    remeCareAppModule
        .filter('phone', () => {
                return (phoneNr: RemeCare.Contract.Core.ITelephoneNumber) => {
                    return new Model.PhoneNumber(phoneNr).toString();
                };
            });
}



