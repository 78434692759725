namespace RemeCare.CareRequest {
    import CareRequestPartType = Shared.Contract.Code.CareRequestPartType;

    enum MissingAutoCreateUserFields {
        Any,
        BirthDate,
        Email,
    }

    interface IPatientSummary {
        firstName: string;
        id: Shared.Contract.Guid;
        lastName: string;
    }

    class CareRequestController implements ng.IComponentController {
        // bindings
        public $transition$;
        public missingFieldsForAutoCreateUser: boolean = false;
        public missingBirthDateForAutoCreateUser: boolean = false;
        public missingEmailForAutoCreateUser: boolean = false;
        public addForbidden: boolean;
        public carePlanExists: boolean;
        public careRequestTemplate: CareRequestTemplate;
        public careRequest: CareRequest;
        public patchPersonInfo: PatchPersonInfo;
        public comorbidities: Shared.Contract.Read.ITherapyDiseaseReadModel[];
        public treatedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[];
        public errorMessage: string;
        public form: Shared.Framework.Directive.IFormController;
        public patient: IPatientSummary;

        public validateNationalNumber: boolean = false;
        private therapyId: string;
        private externalPatientDataUsed: boolean = false;
        private externalPatientDataUsedTelemonitoring: boolean = false;
        private emailAddress: string;
        private dateOfBirth: string;

        constructor(
            private readonly $timeout: ng.ITimeoutService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly appConfig: IAppConfig,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly careRequestSvc: CareRequestSvc,
            private readonly $state: ng.ui.IStateService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly patientSvc: Patient.PatientService,
            private readonly patientFileForExternalSvc: CareRequest.PatientFileForExternalSvc,
            private readonly spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly validationSvc: ValidationService
        ) {}

        public $onInit(): void {
            this.therapyId = this.$transition$.params().therapyId;
            this.loadDataAsync();
            this.patchPersonInfo = new PatchPersonInfo();
        }

        public async retrieveMatchedPatient(): Promise<void> {
            this.missingFieldsForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                MissingAutoCreateUserFields.Any
            );
            this.missingBirthDateForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                MissingAutoCreateUserFields.BirthDate
            );
            this.missingEmailForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                MissingAutoCreateUserFields.Email
            );

            if (this.careRequest.matchedPatientId != null) {
                try {
                    const ags = await this.patientSvc.getPatientNameAsync(this.careRequest.matchedPatientId);
                    this.patient = {
                        firstName: _(ags).first().FirstName,
                        id: _(ags).first().Id,
                        lastName: _(ags).first().LastName,
                    };
                    this.getPatientCarePlansAsync(this.careRequest.matchedPatientId);
                } catch (e) {
                    this.toaster.error(e);
                }
            } else {
                this.carePlanExists = false;
                this.addForbidden = false;
            }
        }

        public async _missingFieldsForAutoCreateUser(
            fields: MissingAutoCreateUserFields = MissingAutoCreateUserFields.Any
        ): Promise<boolean> {
            if (!this.careRequestTemplate || !this.careRequestTemplate.careRequestSetup) {
                return false;
            }

            // Only when flag autoCreateUserForPatient is set in configuration
            if (!this.careRequestTemplate.careRequestSetup.autoCreateUserForPatient) {
                return false;
            }

            // Only when we are working with an existing party
            if (this.careRequest.matchedPartyId != null) {
                const person = await this.partyApiSvc.getPersonAsync(this.careRequest.matchedPartyId);
                this.dateOfBirth = person.DateOfBirth;
                this.emailAddress = person.Email;
            } else if (this.careRequest.matchedPatientId != null) {
                await this.patientSvc
                    .getPatient(this.careRequest.matchedPatientId)
                    .success((p) => {
                        this.dateOfBirth = p.DateOfBirth;
                        this.emailAddress = p.EmailAddresses[0].Email;
                    })
                    .catch((e) => {
                        this.toaster.error(e);
                        throw e;
                    });
            } else {
                return false;
            }

            if (
                (fields === MissingAutoCreateUserFields.Any || fields === MissingAutoCreateUserFields.Email) &&
                (!this.emailAddress || !this.validationSvc.verifyEmail(this.emailAddress))
            ) {
                return true;
            }

            if (
                (fields === MissingAutoCreateUserFields.Any || fields === MissingAutoCreateUserFields.BirthDate) &&
                !this.dateOfBirth
            ) {
                return true;
            }

            return false;
        }

        public async savePersonInfo(): Promise<{}> {
            if (!this.missingFieldsForAutoCreateUser) {
                return Promise.resolve({});
            }

            return this.partyApiSvc.patchPersonInfo(this.careRequest.matchedPartyId, {
                partyId: this.careRequest.matchedPartyId,
                birthDate: Shared.DateHelper.toServerDateString(this.patchPersonInfo.birthDate),
                email: this.patchPersonInfo.email,
            });
        }

        public saveCareRequest(permanent: boolean): void {
            this.validateNationalNumber = permanent;

            this.$timeout(async () => {
                if (permanent) {
                    this.form.$setSubmitted();
                    if (this.form.$invalid) {
                        this.form.showValidationErrorMessage();
                        return;
                    }
                }

                this.filterOutInActiveValues();
                this.filterOutInvisibleValues();
                const careRequest = this.careRequest.toServerWrite(permanent, this.comorbidities, this.treatedDiseases);
                this.spinnerSvc.show('loader');

                try {
                    await this.savePersonInfo()
                        .then(async () => {
                            await this.careRequestSvc.saveCareRequestAsync(careRequest);
                            if (
                                this.careRequestTemplate.careRequestStepType.Id ===
                                    Shared.Contract.Code.CareRequestStepType.Application &&
                                permanent
                            ) {
                                const followUpMessage = this.$translate.instant(
                                    'Views.CareRequest.Details.SavedFollowUp',
                                    {
                                        remedusInfoMail: `<a href="mailto:${this.appConfig.remedusInfoMail}">${
                                            this.appConfig.remedusInfoMail
                                        }</a>`,
                                        remedusPhone: `<span class="text-nowrap">${this.appConfig.remedusPhone}</span>`,
                                    }
                                );
                                this.toaster.success(
                                    this.$translate.instant('Views.CareRequest.Details.Saved'),
                                    followUpMessage,
                                    true
                                );
                            } else {
                                this.toaster.success(this.$translate.instant('Views.CareRequest.Details.SavedLong'));
                            }
                            this.goBack(permanent);
                        })
                        .catch((e) => this.toaster.error(e));
                } catch (e) {
                    this.toaster.error(e);
                } finally {
                    this.spinnerSvc.hide('loader');
                }
            });
        }

        public cancelCareRequest(): void {
            this.$dialog.confirmBox(
                'Views.CareRequest.Details.Cancel.Title',
                'Views.CareRequest.Details.Cancel.Text',
                async () => {
                    try {
                        this.spinnerSvc.show('loader');
                        await this.careRequestSvc.cancelCareRequestAsync(this.careRequest.id);
                        this.toaster.success(this.$translate.instant('Views.CareRequest.Details.CareRequestCancelled'));
                        this.$state.go('careRequests');
                    } catch (e) {
                        this.toaster.error(e);
                    } finally {
                        this.spinnerSvc.hide('loader');
                    }
                }
            );
        }

        private filterOutInvisibleValues(): void {
            // filter out invisible fields due to conditionality
            this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach((part) => {
                if (
                    !this.careRequestSvc.isVisibleWithConditionality(part, this.careRequestTemplate, this.careRequest)
                ) {
                    this.deleteInvisibleConditionalValues(part);
                }
            });
        }

        private deleteInvisibleConditionalValues(careRequestPart: Shared.Framework.Model.CareRequestPart) {
            switch (careRequestPart.type.Id) {
                case CareRequestPartType.Patient:
                    this.careRequest.patient = null;
                    break;
                case CareRequestPartType.CareRequestor:
                    this.careRequest.careRequestor = null;
                    break;
                case CareRequestPartType.CareRequestRole:
                    this.careRequest.careRequestRoles = [];
                    break;
                case (CareRequestPartType.QuantitativeAnamnesis, CareRequestPartType.QualitativeAnamnesis):
                    const filteredAnamneses = this.careRequest.anamneses.filter(
                        (a) => a.careRequestPartLocalIdentity !== careRequestPart.localIdentity
                    );
                    this.careRequest.anamneses = filteredAnamneses;
                    break;
                case CareRequestPartType.TherapyOption:
                    const filteredOptions = this.careRequest.therapyOptions.filter(
                        (to) => to.careRequestPartLocalIdentity !== careRequestPart.localIdentity
                    );
                    this.careRequest.therapyOptions = filteredOptions;
                    break;
                case CareRequestPartType.Date:
                    const filteredDates = this.careRequest.careRequestDates.filter(
                        (d) => d.careRequestPartLocalIdentity !== careRequestPart.localIdentity
                    );
                    this.careRequest.careRequestDates = filteredDates;
                    break;
                case CareRequestPartType.String:
                    const filteredStrings = this.careRequest.careRequestStrings.filter(
                        (s) => s.careRequestPartLocalIdentity !== careRequestPart.localIdentity
                    );
                    this.careRequest.careRequestStrings = filteredStrings;
                    break;
                case CareRequestPartType.RequestDocument:
                    const filteredDocs = this.careRequest.careRequestDocuments.filter(
                        (d) => d.id !== careRequestPart.id
                    );
                    this.careRequest.careRequestDocuments = filteredDocs;
                    break;
                case CareRequestPartType.Hospitalisation:
                    const filteredHospi = this.careRequest.hospitalisations.filter((h) => h.id !== careRequestPart.id);
                    this.careRequest.hospitalisations = filteredHospi;
                    break;
                case CareRequestPartType.Consultation:
                    const filteredConsult = this.careRequest.consultations.filter((c) => c.id !== careRequestPart.id);
                    this.careRequest.consultations = filteredConsult;
                    break;
                case (CareRequestPartType.MedicationSchemaTherapyAction, CareRequestPartType.MedicationSchemaOther):
                    const filteredMedic = this.careRequest.careRequestMedications.filter(
                        (m) => m.id !== careRequestPart.id
                    );
                    this.careRequest.careRequestMedications = filteredMedic;
                    break;
                case CareRequestPartType.Comorbidities:
                    const filteredComor = this.careRequest.comorbidities.filter(
                        (c) => c !== careRequestPart.localIdentity
                    );
                    this.careRequest.comorbidities = filteredComor;
                    break;
                case CareRequestPartType.TreatedDiseases:
                    const filteredTreatedDis = this.careRequest.treatedDiseases.filter(
                        (t) => t !== careRequestPart.localIdentity
                    );
                    this.careRequest.treatedDiseases = filteredTreatedDis;
                    break;
            }
        }

        private filterOutInActiveValues() {
            this.careRequest.anamneses = _(this.careRequest.anamneses).filter((a) =>
                _(_(this.careRequestTemplate.careRequestSetup.careRequestParts).map((p) => p.localIdentity)).contains(
                    a.careRequestPartLocalIdentity
                )
            );
        }

        private async loadDataAsync(): Promise<void> {
            const careRequestId = this.$transition$.params().careRequestId;
            try {
                // Load all allowed comordibities and treated diseases for this therapy
                const [comorbidities, treatedDiseases]: [
                    Shared.Contract.Read.ITherapyDiseaseReadModel[],
                    Shared.Contract.Read.ITherapyDiseaseReadModel[]
                ] = await Promise.all([
                    this.therapyApiSvc.getTherapyDiseasesAsync(
                        this.therapyId,
                        Shared.Contract.Code.DiseaseType.Comorbidity
                    ),
                    this.therapyApiSvc.getTherapyDiseasesAsync(
                        this.therapyId,
                        Shared.Contract.Code.DiseaseType.TreatedDisease
                    ),
                ]);
                this.comorbidities = comorbidities;
                this.treatedDiseases = treatedDiseases;
                // Load care request
                if (careRequestId) {
                    const [careRequestTemplate, careRequest]: [
                        Contract.CareRequest.Read.ICareRequestTemplate,
                        Contract.CareRequest.Read.ICareRequest
                    ] = await Promise.all([
                        this.therapyApiSvc.getCareRequestTemplateAsync(this.therapyId, careRequestId),
                        this.careRequestSvc.getCareRequestAsync(careRequestId),
                    ]);

                    await this.prepareCareRequestAsync(careRequestTemplate, careRequest);
                } else {
                    const careRequestTemplate = await this.therapyApiSvc.getCareRequestTemplateAsync(this.therapyId);
                    await this.prepareCareRequestAsync(careRequestTemplate);
                }

                this.careRequest.isModified = !this.careRequestTemplate.careRequestSetup
                    .isLatestValidCareRequestSetupVersion;
                this.checkInvalidConfig();

                if (!this.careRequest.isReadOnly() && !this.careRequest.id) {
                    const patientFileForExternal = await this.patientFileForExternalSvc.getLastPatientFileForExternalAsync();
                    if (patientFileForExternal.length === 1) {
                        this.useExternalPatientData(new PatientFileForExternal(patientFileForExternal[0]));
                    }
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private checkInvalidConfig() {
            const goToIds = Array<Shared.Contract.Guid>();
            const partIds = Array<Shared.Contract.Guid>();
            this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach((part) => {
                partIds.push(part.id);
                if (part.answerConditions.length > 0) {
                    part.answerConditions.forEach((ac) => {
                        goToIds.push(ac.goToCareRequestPartId);
                    });
                }
            });

            goToIds.forEach((goToId) => {
                if (partIds.indexOf(goToId) === -1) {
                    this.careRequest.isModified = true;
                }
            });
        }

        private async getPatientCarePlansAsync(patientId: Shared.Contract.Guid): Promise<void> {
            try {
                const ags = await this.patientSvc.getCarePlansAsync(patientId);
                const existingCarePlans = _(ags).filter(
                    (x) =>
                        x.TherapyId === this.careRequest.therapyId &&
                        (this.careRequest.id == null || this.careRequest.id !== x.Id)
                );
                if (existingCarePlans != null && _(existingCarePlans).size() !== 0) {
                    this.carePlanExists = true;
                    let resource: string;
                    if (_(existingCarePlans).any((cp) => cp.Status.Id === Shared.Contract.Code.CarePlanStatus.Open)) {
                        resource = 'Views.CareRequest.Details.DuplicateCarePlanActive';
                        this.addForbidden = true;
                    } else if (
                        _(existingCarePlans).any((cp) => cp.Status.Id === Shared.Contract.Code.CarePlanStatus.OnHold)
                    ) {
                        resource = 'Views.CareRequest.Details.DuplicateCarePlanOnHold';
                        this.addForbidden = true;
                    } else {
                        resource = 'Views.CareRequest.Details.DuplicateCarePlanStopped';
                    }
                    this.errorMessage = this.$translate.instant(resource, {
                        therapyName: _(existingCarePlans).first().Name,
                        patientName: this.patient.lastName + ' ' + this.patient.firstName,
                    });
                } else {
                    this.carePlanExists = false;
                    this.addForbidden = false;
                    this.errorMessage = '';
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async prepareCareRequestAsync(
            careRequestTemplate: Contract.CareRequest.Read.ICareRequestTemplate,
            careRequest?: Contract.CareRequest.Read.ICareRequest
        ): Promise<void> {
            this.careRequestTemplate = new CareRequestTemplate(careRequestTemplate);
            this.careRequest = new CareRequest(
                careRequest,
                careRequestTemplate,
                this.comorbidities,
                this.treatedDiseases
            );
            if (careRequest == null) {
                this.careRequest.stepSequence = this.careRequestTemplate.careRequestStepSequence;
                this.careRequest.stepType = this.careRequestTemplate.careRequestStepType;
                this.careRequest.therapyId = this.therapyId;
            }
            const existingPatient = this.$transition$.params().existingPatient;
            if (existingPatient != null) {
                this.careRequest.matchedPatientId = existingPatient;
                this.careRequest.patient = null;
                this.missingFieldsForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                    MissingAutoCreateUserFields.Any
                );
                this.missingBirthDateForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                    MissingAutoCreateUserFields.BirthDate
                );
                this.missingEmailForAutoCreateUser = await this._missingFieldsForAutoCreateUser(
                    MissingAutoCreateUserFields.Email
                );
            }
            this.enrichCareRequestRolesAsync();
        }

        private async enrichCareRequestRolesAsync(): Promise<void> {
            const partyRoleIds = _(this.careRequest.careRequestRoles)
                .select((x) => x.partyRoleId != null)
                .map((x) => x.partyRoleId);
            if (partyRoleIds.length === 0) {
                return;
            }
            const query: Contract.Party.Read.Query.IFindPersonsQuery = {
                partyRoleIds,
                page: 1,
                pageSize: partyRoleIds.length,
            };

            try {
                const r = await this.partyApiSvc.findPersonsAsync(query);
                _(this.careRequest.careRequestRoles).each((crr) => {
                    if (crr.partyRoleId == null) {
                        return;
                    }
                    crr.matchedParty = RequestPerson.createFromPerson(
                        _.find(r.Items, (x) =>
                            _(x.PartyRoles).any(
                                (pr: Contract.Party.Read.IPartyRoleSummary) => pr.Id === crr.partyRoleId
                            )
                        )
                    );
                });
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private goBack(savedCareRequest: boolean): void {
            const referringState = this.$transition$.params().referringState;
            if (referringState) {
                this.$state.go(referringState.name, referringState.params);
            } else {
                this.$state.go('careRequests.search', {
                    savedCareRequest: savedCareRequest,
                } as ICareRequestSearchStateParams);
            }
        }

        private useExternalPatientData(patientFileForExternal: PatientFileForExternal): void {
            // Timeout to allow components to be ready to pick up changes.
            // If brave enough to remove, retest PF-3139
            this.$timeout(() => {
                const telemonitoringId = _(patientFileForExternal.externalReferences).filter(
                    (r) => r.System === 'TMPortal' && r.Attribute === 'telemonitoringId'
                );
                if (telemonitoringId && telemonitoringId.length) {
                    this.externalPatientDataUsedTelemonitoring = true;
                } else {
                    this.externalPatientDataUsed = true;
                }

                this.careRequest.patient.patientFileForExternalRequestId = patientFileForExternal.id;

                this.careRequest.patient.firstName = `${patientFileForExternal.patientParameters.FirstName ||
                    ''} ${patientFileForExternal.patientParameters.SecondName || ''}`;
                this.careRequest.patient.lastName = patientFileForExternal.patientParameters.LastName;
                this.careRequest.patient.birthDate = patientFileForExternal.patientParameters.BirthdateDate;
                this.careRequest.patient.externalReferenceNumber = patientFileForExternal.patientParameters.ExtRef;
                this.careRequest.patient.nationality = patientFileForExternal.patientParameters.Nationality;
                this.careRequest.patient.gender = patientFileForExternal.patientParameters.NumericGenderCode;
                this.careRequest.patient.identificationNumber = patientFileForExternal.patientParameters.CitizenId;
                this.careRequest.patient.communicationLanguage =
                    patientFileForExternal.patientParameters.PreferredLanguage;

                for (const ref of patientFileForExternal.patientParameters.ExternalReferences) {
                    this.careRequest.patient.externalReferences.push(ref);
                }

                this.careRequest.patient.contactInfo.email = patientFileForExternal.patientParameters.Email;
                this.careRequest.patient.contactInfo.telephone = patientFileForExternal.patientParameters.PhoneParsed;
                this.careRequest.patient.contactInfo.mobile = patientFileForExternal.patientParameters.GsmParsed;

                this.careRequest.patient.contactInfo.address.addressLine1 =
                    patientFileForExternal.patientParameters.Address;
                this.careRequest.patient.contactInfo.address.city = patientFileForExternal.patientParameters.City;
                this.careRequest.patient.contactInfo.address.zipCode = patientFileForExternal.patientParameters.Zipcode;
                this.careRequest.patient.contactInfo.address.country =
                    patientFileForExternal.patientParameters.NumericCountryCode;

                for (const ref of patientFileForExternal.externalReferences) {
                    this.careRequest.externalReferences.push(ref);
                }
            }, 100);
        }
    }

    remeCareCareRequestModule.component('rcCareRequest', {
        bindings: {
            $transition$: '<',
        },
        controller: CareRequestController,
        templateUrl: 'views/careRequest/careRequest.html',
    });
}
