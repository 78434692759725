module RemeCare.Patient {
    import ContactPerson = RemeCare.Model.ContactPersonPerson;

    class ContactPersonRolesController implements ng.IComponentController {
        person: ContactPerson;
        patientId: Shared.Contract.Guid;

        constructor(
            private toaster: Shared.Framework.Toaster,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory) {
        }

        $onInit(): void {
        }
    }

    remeCarePatientModule.component('rcPartyRoles',
        {
            templateUrl: 'views/patient/patientInfo/contactPersons/contactPersonRolesComponent.html',
            controller: ContactPersonRolesController,
            controllerAs: 'contactPersonRolesCtrl',
            bindings: {
                contactPerson: '<',
                patientId: '<'
            }
        });
}