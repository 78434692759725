var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var Periodicity = RemeCare.Shared.Framework.Model.Periodicity;
            var Prescription = /** @class */ (function () {
                function Prescription(serverObject, medication, $translate, carePlanView) {
                    this.medication = medication;
                    this.$translate = $translate;
                    this.carePlanView = carePlanView;
                    if (serverObject) {
                        this.id = serverObject.Id;
                        this.patientId = serverObject.PatientId;
                        this.isFollowedUp = serverObject.IsFollowedUp;
                        this.isDefault = serverObject.IsDefault;
                        this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                        this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                        this.carePlans = serverObject.CarePlans;
                        var periodicityFactory_1 = new RemeCare.Shared.Framework.Factory.PeriodicityFactory();
                        this.periodicities = _(serverObject.Periodicities).map(function (p) { return periodicityFactory_1.createPeriodicity(p); });
                        this.isMultiplyable = serverObject.IsMultiplyable;
                        this.executionRight = serverObject.ExecutionRight;
                        this.setCarePlans(serverObject.CarePlans);
                        this.solventToAddInformation = new Model.SolventToAddInformation(serverObject.SolventToAddInformation);
                        this.prescribedAdministrationInformation = new Model.PrescribedAdministrationInformation(serverObject.PrescribedAdministrationInformation);
                        this.isAdHoc = this.isAdHocPrescription();
                        this.isSimplePrescription = this.canBeShownAsSimplePrescription();
                        this.renderMedicationSchema(serverObject.TokenizedMedicationSchema, $translate);
                    }
                    else {
                        this.isNew = true;
                        this.isMultiplyable = true;
                        this.periodicities = [];
                        this.carePlans = [];
                        this.solventToAddInformation = new Model.SolventToAddInformation(null);
                        this.prescribedAdministrationInformation = new Model.PrescribedAdministrationInformation(null);
                    }
                }
                Prescription.prototype.getName = function () {
                    if (!this.medication) {
                        return '';
                    }
                    var name = this.medication.BrandName || this.medication.MedicationName;
                    if (!this.medication.IsActive) {
                        name += ' (' + this.$translate.instant('General.Inactive') + ')';
                    }
                    return name;
                };
                Prescription.prototype.getComposition = function () {
                    return this.medication
                        ? this.medication.ActiveSubstance ||
                            (this.medication.ContentQuantity || '') + " " + (this.medication.ContentQuantity ? this.medication.ContentUnitName : '') + " " + (this.medication.ContentQuantity && this.medication.EnergyValue ? '-' : '') + " " + (this.medication
                                .EnergyValue || '') + " " + (this.medication.EnergyValue ? this.medication.EnergyUnitName : '')
                        : '';
                };
                Prescription.prototype.toWriteModel = function () {
                    var toReturn = {
                        id: this.id,
                        patientId: this.patientId,
                        productId: this.medication.ProductId,
                        validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                        validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                        periodicities: _(this.periodicities).map(function (p) { return p.toWriteModel(); }),
                        carePlanIds: _(_(this.carePlans).filter(function (cp) { return cp !== null; })).map(function (cp) { return cp.Id; }),
                    };
                    if (this.solventToAddInformation != null) {
                        toReturn.solventToAddInformation = this.solventToAddInformation.toServerWrite();
                    }
                    else {
                        toReturn.solventToAddInformation = null;
                    }
                    if (this.prescribedAdministrationInformation != null) {
                        toReturn.prescribedAdministrationInformation = this.prescribedAdministrationInformation.toServerWrite();
                    }
                    else {
                        toReturn.prescribedAdministrationInformation = null;
                    }
                    return toReturn;
                };
                Prescription.prototype.setCarePlans = function (carePlans) {
                    // Array contains null values...
                    carePlans = carePlans ? carePlans.filter(function (cp) { return !!cp; }) : [];
                    this.carePlans = carePlans;
                    if (carePlans.length > 0) {
                        var activeCarePlans = carePlans.filter(function (cp) { return cp.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open; });
                        var inactiveCarePlans = carePlans.filter(function (cp) { return cp.Status.Id !== RemeCare.Shared.Contract.Code.CarePlanStatus.Open; });
                        // Only show as followed up if at least 1 careplan is still active
                        this.isFollowedUp = activeCarePlans.length > 0;
                        if (this.carePlanView) {
                            this.followUpText =
                                this.$translate.instant(inactiveCarePlans.length > 0
                                    ? 'Views.Patient.Medication.NotFollowedUpAnymore'
                                    : 'Views.Patient.Medication.FollowedUp') +
                                    ' ' +
                                    this.$translate.instant('Views.Patient.Medication.ThisCarePlan');
                        }
                        else {
                            var followedUpText = activeCarePlans.length
                                ? this.$translate.instant('Views.Patient.Medication.FollowedUp') +
                                    ' ' +
                                    activeCarePlans.map(function (cp) { return cp.Name; }).join(', ')
                                : '';
                            var noLongerFollowUpText = inactiveCarePlans.length
                                ? this.$translate.instant('Views.Patient.Medication.NotFollowedUpAnymore') +
                                    ' ' +
                                    inactiveCarePlans.map(function (cp) { return cp.Name; }).join(', ')
                                : '';
                            var joinText = activeCarePlans.length && inactiveCarePlans.length ? '<br>' : '';
                            this.followUpText = followedUpText + joinText + noLongerFollowUpText;
                        }
                    }
                    else {
                        this.isFollowedUp = false;
                        this.followUpText =
                            this.$translate.instant('Views.Patient.Medication.NotFollowedUp') +
                                ' ' +
                                this.$translate.instant(this.carePlanView ? 'Views.Patient.Medication.ThisCarePlan' : 'General.RemeCare');
                    }
                };
                Prescription.prototype.renderMedicationSchema = function (tokens, $translate) {
                    var _this = this;
                    var renderPipe = new Array();
                    angular.forEach(tokens, function (t) {
                        var params = {};
                        for (var i = 0; i < t.Parameters.length; i++) {
                            params['value' + i] = t.Parameters[i];
                        }
                        if (t.TemplateResourceKey === 'General.Periodicity.Tokens.DayTimingDose') {
                            params.value1 = _this.medication ? _this.medication.UnitName : '';
                        }
                        var text;
                        if (t.TemplateResourceKey.toString().indexOf('General.') !== -1) {
                            text = $translate.instant(t.TemplateResourceKey, params);
                        }
                        else {
                            text = t.TemplateResourceKey.toString();
                        }
                        renderPipe.push(text);
                    });
                    this.medicationSchema = renderPipe.join('');
                };
                Prescription.prototype.startsInThePast = function () {
                    return this.validFromDate <= RemeCare.Shared.DateHelper.today();
                };
                Prescription.prototype.canDelete = function () {
                    return (this.isNew || (this.canEdit() && (this.validFromDate > RemeCare.Shared.DateHelper.today() || !this.isFollowedUp)));
                };
                Prescription.prototype.canEdit = function () {
                    if (this.isNew) {
                        return true;
                    }
                    if (!this.isFollowedUp) {
                        return true;
                    }
                    return (!this.isDefault &&
                        this.isMultiplyable &&
                        (this.validUntilDate == null || this.validUntilDate > RemeCare.Shared.DateHelper.today()));
                };
                Prescription.prototype.canBeShownAsSimplePrescription = function () {
                    if (this.periodicities != null && this.periodicities.length === 1) {
                        var periodicity = this.periodicities[0];
                        if (_(periodicity.intraDayTimings).any(function (idt) {
                            return idt.noEatingWindowBefore != null ||
                                idt.noEatingWindowAfter != null ||
                                moment(idt.preferredTime).minutes() !== 0;
                        })) {
                            return false;
                        }
                        if (periodicity.hasDuration) {
                            return false;
                        }
                        if (periodicity.recurrence != null &&
                            periodicity.recurrence.Quantity === 1 &&
                            periodicity.recurrence.Unit === RemeCare.Shared.Contract.Code.DurationUnit.Days) {
                            return (this.prescribedAdministrationInformation == null ||
                                this.prescribedAdministrationInformation.prescribedAdministrationMeans == null ||
                                this.prescribedAdministrationInformation.prescribedAdministrationMeans < 1);
                        }
                    }
                    return false;
                };
                Prescription.prototype.makeAdHoc = function (patientLang, masterdataSvc) {
                    var periodicity = new Periodicity();
                    periodicity.unstructuredTargetQuantity = 1;
                    this.isAdHoc = true;
                    this.isSimplePrescription = false;
                    this.periodicities = [periodicity];
                    if (!this.isFollowedUp) {
                        return;
                    }
                    // Set 'If needed' as text for followed up ad hoc medication
                    if (patientLang) {
                        masterdataSvc.getTranslation(patientLang, 'Views.Patient.Medication.IfNeeded').success(function (trans) {
                            var ifNeededTrans = trans['Views.Patient.Medication.IfNeeded'];
                            periodicity.unstructuredPeriodicity = ifNeededTrans;
                        });
                    }
                    else {
                        var ifNeededTrans = this.$translate.instant('Views.Patient.Medication.IfNeeded');
                        periodicity.unstructuredPeriodicity = ifNeededTrans;
                    }
                };
                Prescription.prototype.makeDefault = function () {
                    var factory = new RemeCare.Shared.Framework.Factory.PeriodicityFactory();
                    var periodicity = factory.createPeriodicityFromDurationUnit(RemeCare.Shared.Contract.Code.DurationUnit.Days);
                    periodicity.recurrence.Quantity = 1;
                    this.periodicities = [periodicity];
                    this.isAdHoc = false;
                    this.isSimplePrescription = true;
                };
                Prescription.prototype.isAdHocPrescription = function () {
                    if (this.periodicities != null && this.periodicities.length === 1) {
                        var periodicity = this.periodicities[0];
                        if (periodicity.type === RemeCare.Shared.Contract.Code.PeriodicityType.Periodicity) {
                            return true;
                        }
                    }
                    return false;
                };
                return Prescription;
            }());
            Model.Prescription = Prescription;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
