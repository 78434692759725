var RemeCare;
(function (RemeCare) {
    var Framework;
    (function (Framework) {
        var Helpers = /** @class */ (function () {
            function Helpers() {
            }
            Helpers.calculateShowActionDuration = function (hasActionDurationOption, defaultDurationOfAction, possibleActionDurationOptions) {
                // Check if the dropdown should be used or not, if not check if a time is set
                if (!hasActionDurationOption) {
                    // Check if durationOfAction exists
                    if (defaultDurationOfAction != null &&
                        RemeCare.Shared.DateHelper.timeHumanReadible(defaultDurationOfAction) !== '00:00') {
                        return true;
                    }
                    return false;
                }
                else {
                    if (possibleActionDurationOptions != null && possibleActionDurationOptions.length > 0) {
                        return true;
                    }
                }
                return false;
            };
            return Helpers;
        }());
        Framework.Helpers = Helpers;
    })(Framework = RemeCare.Framework || (RemeCare.Framework = {}));
})(RemeCare || (RemeCare = {}));
