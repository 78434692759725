namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import ObjectiveDefinition = Shared.Framework.Model.ObjectiveDefinition;
    import RuleThreshold = Shared.Framework.Model.RuleThresholdDefinition;

    interface IPatientFileObjectivesAndThresholdsScope extends Shared.Framework.IBaseScope {
        carePlan: CarePlan;
        patientId: Guid;

        objectives: ObjectiveDefinition[];
        objectivesGrid: Shared.Framework.Grid.Grid<ObjectiveDefinition>;

        thresholds: RuleThreshold[];
        thresholdsGrid: Shared.Framework.Grid.Grid<RuleThreshold>;

        editRight: boolean;
    }

    class PatientFileObjectivesAndThresholdsController extends Shared.Framework.ControllerBase<
        IPatientFileObjectivesAndThresholdsScope
    > {
        constructor(
            protected $scope: IPatientFileObjectivesAndThresholdsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super($scope, $translate, toaster);
        }

        public async $onInit(): Promise<void> {
            this.$scope.patientId = this.$stateParams.patientId;
            this.buildObjectivesGrid();
            this.buildThresholdsGrid();
            if (this.$scope.carePlan) {
                await this.getObjectivesAsync();
                await this.getThresholdsAsync();
                this.$scope.editRight = this.$scope.carePlan.statusOpen;
            }

            this.$scope.$watch('carePlan', async (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== null) {
                    await this.getObjectivesAsync();
                    await this.getThresholdsAsync();
                    this.$scope.editRight = this.$scope.carePlan.statusOpen;
                }
            });
        }

        private async getObjectivesAsync(): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getObjectivesAsync(this.$scope.carePlan.id);
                this.$scope.objectives = _(ags).map(x => new ObjectiveDefinition(x));
                this.setObjectivesGridData();
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private async getThresholdsAsync(): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getRuleThresholdsAsync(this.$scope.carePlan.id);
                this.$scope.thresholds = _(ags).map(x => new RuleThreshold(x));
                this.setThresholdsGridData();
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private buildObjectivesGrid(): void {
            this.$scope.objectivesGrid = this.gridBuilderSvc
                .createGridBuilder<ObjectiveDefinition>()
                .addColumn('name', 'General.Name')
                .addColumn('characteristic.Text', 'Configuration.Therapies.Monitoring.Characteristic')
                .addColumn('period', 'Views.PatientFile.CarePlans.Duration', { cellFilter: 'rcDurationDisplay' })
                .addColumn('getCurrentObjectiveValue().amount', 'Views.PatientFile.CarePlans.ObjectiveValue')
                .addColumn('type.Text', 'Views.PatientFile.CarePlans.CalculationMethod')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    o => this.editObjectiveAsync(o),
                    o => this.editObjectiveAsync(o),
                    o => !o.isPersonalisable || o.readOnly || !this.$scope.editRight
                )
                .setExternalSorting(false)
                .build();

            this.setObjectivesGridData();
        }

        private buildThresholdsGrid(): void {
            this.$scope.thresholdsGrid = this.gridBuilderSvc
                .createGridBuilder<RuleThreshold>()
                .addColumn('name', 'General.Name')
                .addColumn('getCurrentRuleThresholdValueString()', 'Views.PatientFile.CarePlans.RuleThresholdValue')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    t => this.editThresholdAsync(t),
                    t => this.editThresholdAsync(t),
                    t => !t.isPersonalisable || t.readOnly || !this.$scope.editRight
                )
                .setExternalSorting(false)
                .build();

            this.setThresholdsGridData();
        }

        private setThresholdsGridData(): void {
            this.$scope.thresholdsGrid.setData(this.$scope.thresholds);
        }

        private editThresholdAsync(threshold: RuleThreshold): Promise<RuleThreshold> {
            return new Promise<RuleThreshold>(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<RuleThreshold>()
                    .setController('PatientFileRuleThresholdOverviewCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/ruleThresholdOverview.html')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        threshold: threshold,
                        carePlan: this.$scope.carePlan,
                        readOnly: !threshold.isPersonalisable || threshold.readOnly || !this.$scope.editRight,
                    })
                    .setResultCallBack(r => resolve(r))
                    .build();
            });
        }

        private setObjectivesGridData(): void {
            this.$scope.objectivesGrid.setData(this.$scope.objectives);
        }

        private editObjectiveAsync(objective: ObjectiveDefinition): Promise<ObjectiveDefinition> {
            return new Promise<ObjectiveDefinition>(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<ObjectiveDefinition>()
                    .setController('PatientFileObjectiveOverviewCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/objectiveOverview.html')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        objective: objective,
                        carePlan: this.$scope.carePlan,
                        readOnly: !objective.isPersonalisable || objective.readOnly || !this.$scope.editRight,
                    })
                    .setResultCallBack(o => resolve(o))
                    .build();
            });
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'PatientFileObjectivesAndThresholdsCtrl',
            (
                $scope,
                $translate,
                toaster,
                $stateParams,
                carePlanApiSvc: Core.Services.CarePlanApiService,
                modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
                gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
            ) =>
                new PatientFileObjectivesAndThresholdsController(
                    $scope,
                    $translate,
                    toaster,
                    $stateParams,
                    carePlanApiSvc,
                    modalBuilderFactory,
                    gridBuilderSvc
                )
        );
}
