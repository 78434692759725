module RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import ContactPointType = Shared.Contract.Code.ContactPointType;

    export class ContactPointFactory {
        public static create(def: Contract.Party.Read.IContactPoint): ContactPoint {
            return this.createContactPoint(def.Type.Id, def);
        }

        public static createFromType(type: ContactPointType): ContactPoint {
            const result = this.createContactPoint(type);
            result.type = type;
            return result;
        }

        private static createContactPoint(
            type: ContactPointType,
            def?: Contract.Party.Read.IContactPoint
        ): ContactPoint {
            switch (type) {
                case ContactPointType.Address:
                    return new Address(<Contract.Party.Read.IAddress>def);
                case ContactPointType.Telephone:
                    return new TelephoneNumber(<Contract.Party.Read.ITelephoneNumber>def);
                case ContactPointType.MobilePhone:
                    return new TelephoneNumber(<Contract.Party.Read.ITelephoneNumber>def);
                case ContactPointType.Fax:
                    return new TelephoneNumber(<Contract.Party.Read.ITelephoneNumber>def);
                case ContactPointType.Email:
                    return new Email(<Contract.Party.Read.IEmail>def);
                case ContactPointType.Website:
                    return new Website(<Contract.Party.Read.IWebsite>def);
                default:
                    throw new Error('Unkown contact point type');
            }
        }
    }

    export abstract class ContactPoint {
        public id: Guid;
        public remark: string;
        public validFromDate: Date;
        public validUntilDate: Date;
        public type: Shared.Contract.Code.ContactPointType;
        public contactPointUsageType: Shared.Contract.Code.ContactPointUsageType;
        public isReadOnly: boolean;

        constructor(def?: Contract.Party.Read.IContactPoint) {
            if (def != null) {
                this.id = def.Id;
                this.remark = def.Remark;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(def.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(def.ValidUntilDate);
                this.type = def.Type.Id;
                this.contactPointUsageType = def.ContactPointUsageType ? def.ContactPointUsageType.Id : null;
                this.isReadOnly = def.IsReadOnly;
            }
        }

        public toServerWrite(): Contract.Party.Write.IContactPoint {
            return {
                id: this.id,
                remark: this.remark,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
                type: this.type,
                contactPointUsageType: this.contactPointUsageType,
            };
        }

        public toContactPointParameters(): Contract.Patient.Write.IContactPointParameter {
            return {
                type: this.type,
                usageType: this.contactPointUsageType,
            };
        }

        public abstract isEmpty(): boolean;

        public abstract toString(): string;
    }

    export class Address extends ContactPoint {
        public addressLine1: string;
        public addressLine2: string;
        public addressLine3: string;
        public city: string;
        public zipCode: string;
        public country: number;

        constructor(def?: Contract.Party.Read.IAddress) {
            super(def);
            if (def != null) {
                this.addressLine1 = def.AddressLine1;
                this.addressLine2 = def.AddressLine2;
                this.addressLine3 = def.AddressLine3;
                this.city = def.City;
                this.zipCode = def.ZipCode;
                this.country = def.Country;
            }
        }

        public toServerWrite(): Contract.Party.Write.IAddress {
            const result = <Contract.Party.Write.IAddress>super.toServerWrite();
            result.addressLine1 = this.addressLine1;
            result.addressLine2 = this.addressLine2;
            result.addressLine3 = this.addressLine3;
            result.city = this.city;
            result.zipCode = this.zipCode;
            result.countryId = this.country;
            return result;
        }

        public toContactPointParameters(): Contract.Patient.Write.IAddress {
            const result = <Contract.Patient.Write.IAddress>super.toContactPointParameters();
            result.addressLine1 = this.addressLine1;
            result.addressLine2 = this.addressLine2;
            result.addressLine3 = this.addressLine3;
            result.city = this.city;
            result.zipCode = this.zipCode;
            result.country = this.country;
            return result;
        }

        public toString(): string {
            return `${this.addressLine1} ${this.zipCode} ${this.city}`;
        }

        public isEmpty(): boolean {
            return (
                _.isEmpty(this.addressLine1) &&
                _.isEmpty(this.addressLine2) &&
                _.isEmpty(this.addressLine3) &&
                _.isEmpty(this.city) &&
                _.isEmpty(this.zipCode) &&
                !this.country
            );
        }
    }

    export class TelephoneNumber extends ContactPoint implements Shared.Directive.ITelephoneNumber {
        private readonly phoneNumber: PhoneNumber;
        public get countryPrefix() {
            return this.phoneNumber.countryPrefix;
        }
        public set countryPrefix(value: string) {
            this.phoneNumber.countryPrefix = value;
        }
        public get areaCode() {
            return this.phoneNumber.areaCode;
        }
        public set areaCode(value: string) {
            this.phoneNumber.areaCode = value;
        }
        public get localNumber() {
            return this.phoneNumber.localNumber;
        }
        public set localNumber(value: string) {
            this.phoneNumber.localNumber = value;
        }

        constructor(def?: Contract.Party.Read.ITelephoneNumber) {
            super(def);
            this.phoneNumber = new PhoneNumber(def);
        }

        public toServerWrite(): Contract.Party.Write.ITelephoneNumber {
            const result = <Contract.Party.Write.ITelephoneNumber>super.toServerWrite();
            result.countryPrefix = this.countryPrefix;
            result.areaCode = this.areaCode;
            result.localNumber = this.localNumber;
            return result;
        }

        public toContactPointParameters(): Contract.Patient.Write.ITelephoneNumber {
            const result = <Contract.Patient.Write.ITelephoneNumber>super.toContactPointParameters();
            result.countryPrefix = this.countryPrefix;
            result.areaCode = this.areaCode;
            result.localNumber = this.localNumber;
            return result;
        }

        public toString(): string {
            return this.phoneNumber.toString();
        }

        public isValid(): boolean {
            return this.phoneNumber.isValid();
        }

        public isEmpty(): boolean {
            return this.phoneNumber.isEmpty();
        }
    }

    export class Email extends ContactPoint {
        public email: string;

        constructor(def?: Contract.Party.Read.IEmail) {
            super(def);
            if (def != null) {
                this.email = def.Email;
            }
        }

        public toServerWrite(): Contract.Party.Write.IEmail {
            const result = <Contract.Party.Write.IEmail>super.toServerWrite();
            result.email = this.email;
            return result;
        }

        public toContactPointParameters(): Contract.Patient.Write.IEmailAddress {
            const result = <Contract.Patient.Write.IEmailAddress>super.toContactPointParameters();
            result.email = this.email;
            return result;
        }

        public toString(): string {
            return this.email;
        }

        public isEmpty(): boolean {
            return _.isEmpty(this.email);
        }
    }

    export class Website extends ContactPoint {
        public url: string;

        constructor(def?: Contract.Party.Read.IWebsite) {
            super(def);
            if (def != null) {
                this.url = def.Url;
            }
        }

        public toServerWrite(): Contract.Party.Write.IWebsite {
            const result = <Contract.Party.Write.IWebsite>super.toServerWrite();
            result.url = this.url;
            return result;
        }

        public toString(): string {
            return this.url;
        }

        public isEmpty(): boolean {
            return _.isEmpty(this.url);
        }
    }
}
