'use strict';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var RemeCareAgendaConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareAgendaConfiguration, _super);
            function RemeCareAgendaConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareAgendaConfiguration.prototype.config = function () {
                this.addRoute('agenda', '/Agenda', // Be careful changing this route as this is being referenced in the integration API
                'views/baseView.html', null, null, null, null, null, {
                    redirectTo: 'agenda.lastAgenda',
                });
                this.add('agenda.lastAgenda', {
                    url: '/LastAgenda',
                    component: 'rcLastAgenda',
                }, ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring']);
                this.add('agenda.myagenda', {
                    url: '/MyAgenda?patientData',
                    component: 'rcMyAgenda',
                }, ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'], {
                    context: RemeCare.Shared.Framework.AuthContext.agendaOwn,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('agenda.teamagenda', {
                    url: ' / TeamAgenda',
                    component: 'rcTeamAgenda',
                }, ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'], {
                    context: RemeCare.Shared.Framework.AuthContext.agendaTeam,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('agenda.colleagueagenda', {
                    url: ' / ColleagueAgenda',
                    component: 'rcColleagueAgenda',
                }, ['Views_Agenda', 'Views_Inbox', 'Views_PatientFile_Monitoring'], {
                    context: RemeCare.Shared.Framework.AuthContext.agendaColleague,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
            };
            return RemeCareAgendaConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        angular.module('RemeCare.Agenda').config(function ($stateProvider) { return new RemeCareAgendaConfiguration($stateProvider); });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
