var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var IntegrationMessage = /** @class */ (function () {
            function IntegrationMessage(def) {
                if (def != null) {
                    this.id = def.Id;
                    this.patientId = def.PatientId;
                    this.patientNumber = def.PatientNumber;
                    this.patientReference = def.PatientReference;
                    this.patientNationalNumber = def.PatientNationalNumber;
                    this.patientName = def.PatientName;
                    this.sender = def.Sender;
                    this.receivedDateTime = def.ReceivedDateTime;
                    this.statusName = def.StatusName;
                    this.statusTranslation = def.StatusTranslation;
                    this.errorCode = def.ErrorCode;
                    this.errorGuidance = def.ErrorGuidance;
                    this.fields = def.Fields;
                    this.message = def.Message;
                    this.error = def.Error;
                    this.formattedError = JSON.stringify(this.error, null, '\t');
                    this.isFailed = def.StatusName === "FAILED" /* FAILED */;
                    this.isProcessed = def.StatusName === "PROCESSED" /* PROCESSED */;
                    this.isArchiveable = def.StatusName !== "ARCHIVED" /* ARCHIVED */ && this.isFailed;
                    this.isUnarchiveable = def.StatusName === "ARCHIVED" /* ARCHIVED */;
                    this.isRetryable = this.isFailed && def.StatusName !== "ARCHIVED" /* ARCHIVED */;
                }
            }
            return IntegrationMessage;
        }());
        Model.IntegrationMessage = IntegrationMessage;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
