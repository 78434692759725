var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var RolePart;
        (function (RolePart) {
            RolePart[RolePart["CareRequestorRole"] = 1] = "CareRequestorRole";
            RolePart[RolePart["CareTeamRole"] = 2] = "CareTeamRole";
        })(RolePart = CareRequest.RolePart || (CareRequest.RolePart = {}));
        var PartyRoleType;
        (function (PartyRoleType) {
            PartyRoleType[PartyRoleType["HealthCareProfessional"] = 1] = "HealthCareProfessional";
            PartyRoleType[PartyRoleType["HealthCareOrganisation"] = 2] = "HealthCareOrganisation";
        })(PartyRoleType = CareRequest.PartyRoleType || (CareRequest.PartyRoleType = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
