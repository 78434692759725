namespace RemeCare.IntegrationMonitor {
    import ApiServiceBase = Shared.Framework.ApiServiceBase;

    export class IntegrationMessageSearchService extends ApiServiceBase {
        public static $inject = ApiServiceBase.injectionParameters;

        public findIntegrationMessagesAsync(
            query: Contract.IntegrationMonitor.Read.Query.IFindIntegrationMessagesQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.IntegrationMonitor.Read.IIntegrationMessageSummary>> {
            const url = 'IntegrationMessages';
            return this.getPromise(url, query, 'integrationMessages.search');
        }

        public getSendersAsync(): Promise<Array<Contract.IntegrationMonitor.Read.IIntegrationMessageSender>> {
            const url = 'IntegrationMessages/Senders';
            return this.getPromise(url, null, 'integrationMessages.search');
        }

        public getStatusesAsync(): Promise<Array<Contract.IntegrationMonitor.Read.IIntegrationMessageStatus>> {
            const url = 'IntegrationMessages/Message-statuses';
            return this.getPromise(url, null, 'integrationMessages.search');
        }

        public getErrorCodesAsync(): Promise<Array<Contract.IntegrationMonitor.Read.IIntegrationMessageErrorCode>> {
            const url = 'IntegrationMessages/Error-codes';
            return this.getPromise(url, null, 'integrationMessages.search');
        }
    }

    remeCareIntegrationMonitorModule.service('integrationMessageSearchSvc', IntegrationMessageSearchService);
}