namespace RemeCare.CarePlanAction {
    class RemeCareActionConfiguration extends RemeCare.Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.addComponentRoute(
                'editAction',
                '/Actions?actionIds',
                'action',
                ['Views_Action', 'Views_Patient_Medication', 'Views_PatientFile'],
                null,
                null,
                {
                    context: Shared.Framework.AuthContext.action,
                    right: Shared.Framework.AuthRight.Read,
                },
                {
                    params: {
                        referringState: null,
                    },
                }
            );

            this.addRoute(
                'viewAction',
                '/Actions/:actionId',
                null,
                [
                    '$state',
                    '$stateParams',
                    ($state, $stateParams) => {
                        $state.transitionTo('editAction', { actionIds: $stateParams.actionId }, { location: false });
                    },
                ],
                ['Views_Action', 'Views_Patient_Medication', 'Views_PatientFile'],
                null,
                null,
                {
                    context: Shared.Framework.AuthContext.action,
                    right: Shared.Framework.AuthRight.Read,
                },
                {
                    params: {
                        actionId: null,
                    },
                }
            );
        }
    }

    angular.module('RemeCare.CarePlanAction').config($stateProvider => new RemeCareActionConfiguration($stateProvider));
}
