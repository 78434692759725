'use strict';
var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var CarePlanAction;
        (function (CarePlanAction) {
            var Read;
            (function (Read) {
                var TherapyActionPartGroupCode;
                (function (TherapyActionPartGroupCode) {
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["Appointment"] = 1] = "Appointment";
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["Execution"] = 2] = "Execution";
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["ExternalReport"] = 4] = "ExternalReport";
                })(TherapyActionPartGroupCode = Read.TherapyActionPartGroupCode || (Read.TherapyActionPartGroupCode = {}));
            })(Read = CarePlanAction.Read || (CarePlanAction.Read = {}));
        })(CarePlanAction = Contract.CarePlanAction || (Contract.CarePlanAction = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
