var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var CarePlanStatus = RemeCare.Shared.Contract.Code.CarePlanStatus;
        var Careplan = /** @class */ (function () {
            function Careplan(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.patientId = serverObject.PatientId;
                    this.therapyId = serverObject.TherapyId;
                    this.status = serverObject.Status.Text;
                    this.statusId = serverObject.Status.Id;
                    this.name = serverObject.Name;
                    this.startDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.StartDate);
                    this.statusOpen = serverObject.Status.Id === CarePlanStatus.Open;
                    this.statusChangedDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.StatusChangedDate);
                    this.statusChangedReason = serverObject.StatusChangedReason;
                    this.externalReferences = serverObject.ExternalReferences;
                }
            }
            Careplan.getSortedOpenCloseCareplans = function (careplans) {
                var open = _(careplans)
                    .chain()
                    .filter(function (x) { return x.Status.Id === CarePlanStatus.Open; })
                    .map(function (x) { return new Careplan(x); })
                    .sortBy(function (x) { return x.startDate; })
                    .value();
                var closed = _(careplans)
                    .chain()
                    .filter(function (x) { return x.Status.Id !== CarePlanStatus.Open; })
                    .map(function (x) { return new Careplan(x); })
                    .sortBy(function (x) { return x.statusChangedDate; })
                    .value();
                return open.concat(closed);
            };
            Careplan.prototype.getFormattedStartDate = function () {
                return moment(this.startDate).format('DD/MM/YYYY');
            };
            Careplan.prototype.getFormattedStatusChangedDate = function () {
                if (this.statusChangedDate) {
                    return moment(this.statusChangedDate).format('DD/MM/YYYY');
                }
                return null;
            };
            Careplan.prototype.getSummary = function (translate, filter) {
                return {
                    Id: this.id,
                    Text: this.statusOpen
                        ? this.name +
                            translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                                CarePlanStatus: this.status,
                                StatusDate: filter('date')(this.startDate, 'shortDate'),
                            })
                        : this.name +
                            translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                                CarePlanStatus: this.status,
                                StatusDate: filter('date')(this.statusChangedDate, 'shortDate'),
                            }),
                };
            };
            return Careplan;
        }());
        Model.Careplan = Careplan;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
