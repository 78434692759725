var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var EditMutualityScope = /** @class */ (function (_super) {
            __extends(EditMutualityScope, _super);
            function EditMutualityScope($scope, $translate, toaster, patientId, masterdataSvc, patientSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.patientId = patientId;
                _this.masterdataSvc = masterdataSvc;
                _this.patientSvc = patientSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            EditMutualityScope.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getHealthInsuranceFunds()
                    .success(function (r) { return _this.$scope.mutualities = r; })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            EditMutualityScope.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    this.$scope.showErrors = true;
                    return;
                }
                if (this.$scope.onlyValidFromDate) {
                    this.patientSvc.changeMutalityValidity(this.patientId, this.$scope.mutuality.id, this.$scope.mutuality.validFromDate, this.$scope.mutuality.validUntilDate)
                        .success(function () { return _this.$scope.$close(_this.$scope.mutuality); })
                        .error(function (e) { return _this.errorCallback(e); });
                }
                else {
                    this.patientSvc.createMutuality(this.patientId, this.$scope.mutuality.toServerWrite())
                        .success(function () { return _this.$scope.$close(_this.$scope.mutuality); })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            return EditMutualityScope;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('editMutualityCtrl', function ($scope, $translate, toaster, patientId, masterdataSvc, patientSvc) { return new EditMutualityScope($scope, $translate, toaster, patientId, masterdataSvc, patientSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
