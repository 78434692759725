var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var RuleThresholdValue = RemeCare.Shared.Framework.Model.RuleThresholdValue;
        var PatientFileAddRuleThresholdValueModalController = /** @class */ (function (_super) {
            __extends(PatientFileAddRuleThresholdValueModalController, _super);
            function PatientFileAddRuleThresholdValueModalController($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.$scope.formatCodeSetItem = function (csi) { return _this.formatCodeSetItem(csi); };
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileAddRuleThresholdValueModalController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                var minDate = moment().add(1, 'days').startOf('day').toDate();
                this.$scope.previousValue = _(_(_(this.$scope.threshold.ruleThresholdValues).filter(function (v) { return v.isPersonalised; })).sortBy(function (v) { return v.validFromDate; })).last();
                this.$scope.minDate = this.$scope.previousValue && moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() > minDate ? moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() : minDate;
                this.$scope.newValue = new RuleThresholdValue();
                this.$scope.displayValue = this.$scope.defaultValue.getValueString();
                if (this.$scope.threshold.codeSet) {
                    this.$scope.codeSetItems = [];
                    this.masterdataSvc.getCodeSetItems(this.$scope.threshold.codeSet.Id)
                        .success(function (ags) {
                        _this.$scope.codeSetItems = ags;
                    })
                        .error(function (m) { return _this.errorCallback(m); });
                }
                else {
                    if (this.$scope.threshold.unit)
                        this.$scope.displayValue += ' ' + this.$scope.threshold.unit.Text;
                }
            };
            PatientFileAddRuleThresholdValueModalController.prototype.formatCodeSetItem = function (csi) {
                return csi.Name + ' (' + csi.NumericValue + ')';
            };
            PatientFileAddRuleThresholdValueModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.backToDefault) {
                    this.$dialog.confirmBox('Views.PatientFile.CarePlans.BackToDefault', 'Views.PatientFile.CarePlans.BackToDefaultConfirmMessage', function () {
                        _this.$scope.previousValue.validUntilDate = moment(_this.$scope.newValidFromDate).add(-1, 'day').toDate();
                        _this.carePlanApiSvc.updatePersonalisedRuleThresholdValue(_this.$scope.carePlan.id, _this.$scope.threshold.id, _this.$scope.previousValue.toServerWrite())
                            .success(function () { _this.$scope.$close(); })
                            .error(function (m) { return _this.errorCallback(m); });
                    });
                }
                else {
                    this.$scope.newValue.validFromDate = this.$scope.newValidFromDate;
                    this.carePlanApiSvc.savePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.newValue.toServerWrite())
                        .success(function () { _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
            };
            return PatientFileAddRuleThresholdValueModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('PatientFileAddRuleThresholdValueModalCtrl', function ($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc, masterdataSvc) { return new PatientFileAddRuleThresholdValueModalController($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc, masterdataSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
