module RemeCare.Patient.Model {
    import Guid = RemeCare.Shared.Contract.Guid;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class PatientFileSufferedDisease {
        disease: EntityTranslation;
        patientId: Guid;
        diagnosisDate: Date;
        remark: string;

        sufferedDiseaseTherapies: Array<EntityTranslation>;

        constructor(serverObject?: Contract.Patient.Read.IPatientFileSufferedDisease) {
            if (serverObject != null) {
                this.patientId = serverObject.PatientId;
                this.disease = serverObject.Disease;
                this.remark = serverObject.Remark;
                this.diagnosisDate = Shared.DateHelper.serverDateStringToDate(serverObject.DiagnosisDate);
                this.sufferedDiseaseTherapies = _(serverObject.SufferedDiseaseTherapies)
                    .chain().map(x => x.Therapy).uniq(x => x.Id).value();
            } else {
                this.sufferedDiseaseTherapies = [];
            }
        }

        public getTherapyList(): string {
            return _(this.sufferedDiseaseTherapies).map(x => x.Text).join(", ");
        }

        public addTherapyLink(therapy: EntityTranslation): void {
            if (!_(this.sufferedDiseaseTherapies).chain().map(x => x.Id).contains(therapy.Id).value()) {
                this.sufferedDiseaseTherapies.push(therapy);
            }
        }

        public toServerWrite(): Contract.Patient.Write.IPatientFileSufferedDisease {
            return <Contract.Patient.Write.IPatientFileSufferedDisease>{
                DiseaseId: this.disease.Id,
                PatientId: this.patientId,
                DiagnosisDate: this.diagnosisDate !== null ? Shared.DateHelper.toServerDateString(this.diagnosisDate) : null,
                Remark: this.remark,
                SufferedDiseaseTherapies: _(this.sufferedDiseaseTherapies).map(x => x.Id)
            };
        }
    }
}