namespace RemeCare.Agenda {
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;

    interface ITeamAgendaFilter {
        knownTherapies: Shared.Contract.Guid[];
        knownFrom: Date;
        knownUntil: Date;
        knownActorRoles: Shared.Contract.Guid[];
    }

    class TeamAgendaController implements ng.IComponentController {
        public agendaGrid: Shared.Framework.Grid.Grid<Model.AgendaAction>;
        public actionAssignmentTypes: EnumTranslation[];
        public actionStatuses: EnumTranslation[];
        public actionTypes: EnumTranslation[];
        public actorRoles: EntityTranslation[];
        public therapies: EntityTranslation[];
        public currentState: ng.ui.IState;
        public patientFilter: Contract.Patient.Read.IPatient;
        private gridBuilder: Shared.Framework.Grid.SearchGridBuilder<Model.AgendaAction>;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly actorRolesApiSvc: Core.Services.ActorRolesApiService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly uiGridConstants: uiGrid.IUiGridConstants
        ) {}

        public async $onInit(): Promise<void> {
            // we store this as the last chosen agenda
            this.storageSvc.store(StorageServiceConstants.agendaKey, StorageServiceConstants.teamAgenda);
            this.currentState = this.$state.current;
            this.buildAgendaGrid();
            
            try {
                [this.actionAssignmentTypes, this.actionStatuses, this.actionTypes] = await Promise.all(
                    [
                        this.masterdataSvc.getReferenceDataAsync(
                            Shared.Framework.ReferenceDataTypes.actionAssignmentType
                        ),
                        this.masterdataSvc.getCarePlanActionStatuses(),
                        this.masterdataSvc.getCarePlanActionTypes(),
                    ]
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildAgendaGrid(): void {
            this.gridBuilder = this.gridBuilderSvc
                .createGridBuilder<Model.AgendaAction>(
                    (page, pageSize, sortField, sortDirection, criteria, count, nextPage) =>
                        this.executeSearch(page, pageSize, sortField, sortDirection, criteria, count, nextPage)
                )
                .setIsPrevNextGrid()
                .setBindToUrl()
                .setSearchWhenReady()
                .addDateColumn('plannedDateTimeFrom', 'Views.Agenda.Search.ExecutionFrom', 'short', {
                    sort: { direction: this.uiGridConstants.ASC },
                })
                .addDateColumn('plannedDateTimeUntil', 'Views.Agenda.Search.ExecutionUntil', 'short')
                .addColumn('patient', 'Views.Agenda.Search.Patient', { width: '*' });

            if (
                this.authservice.hasRight(Shared.Framework.AuthContext.patientInternal, Shared.Framework.AuthRight.Read)
            ) {
                this.gridBuilder = this.gridBuilder.addColumn('patientNumber', 'Patient.PatientNumber');
            }

            this.gridBuilder = this.gridBuilder
                .addColumn('address', 'General.Place', { width: '*', enableSorting: false })
                .addColumn('name', 'General.Action', { width: '**' })
                .addEnumColumn('status', 'General.Status', Shared.Framework.ReferenceDataTypes.carePlanActionStatus, {
                    width: '*',
                    enableSorting: false,
                })
                .addColumn('therapies', 'General.Therapy', {
                    width: '*',
                    enableSorting: false,
                    cellFilter: 'delimitedDisplay',
                })
                .addColumn('actors', 'Views.Agenda.Search.Actor', {
                    width: '**',
                    enableSorting: false,
                    cellFilter: 'delimitedDisplay',
                });

                this.gridBuilder = this.gridBuilder.addConditionalShowEditNoButtonNavigationColumn(
                (x) => !x.canBeEdited,
                (x) => !x.canBeViewed,
                'editAction',
                { actionIds: 'id', referringState: 'currentState' }
            );

            this.setSearchCriteria();
        }

        private setSearchCriteria() : void {
            // we first get all the persistently stored values for the filter
            const knownInformation = this.storageSvc.get<ITeamAgendaFilter>(StorageServiceConstants.teamAgendaKey);

            this.agendaGrid = this.gridBuilder
                .setSearchCriteria((c) => {
                    const empty = _(c).isEmpty();
                    c.fromDate = c.fromDate || (knownInformation && knownInformation.knownFrom);
                    c.untilDate = c.untilDate || (knownInformation && knownInformation.knownUntil);
                    c.therapyIds = c.therapyIds || (knownInformation && knownInformation.knownTherapies) || [];
                    c.actionTypes = c.actionTypes || [];
                    c.statuses = c.statuses || [];
                    c.actionAssignmentTypes = c.actionAssignmentTypes || [];
                    c.actorRoles = c.actorRoles || (knownInformation && knownInformation.knownActorRoles) || [];
                    if (empty) {
                        c.statuses = [Contract.Core.Codes.CarePlanActionStatus.Open];
                        c.actionAssignmentTypes = [
                            Shared.Contract.Code.ActionAssignmentType.Me,
                            Shared.Contract.Code.ActionAssignmentType.OtherInActorRole,
                            Shared.Contract.Code.ActionAssignmentType.Unassigned,
                        ];
                    }
                    this.setDelayedCriteria(c);
                })
                .build();
        }


        private async setDelayedCriteria(criteria?: IAgendaFilter): Promise<void>
        {
            const [actorRoles, globalActorRoles, therapies]: [
                Contract.Therapy.Read.IActorRoleSummary[],
                EntityTranslation[],
                EntityTranslation[],
            ] = await Promise.all([
                this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true }),
                this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager
                    ? this.actorRolesApiSvc.findGlobalActorRolesAsync()
                    : Promise.resolve([]),
                this.therapyApiSvc.getTherapiesAsync()
            ]);

            // Set actor roles

            const allActorRoles = _(actorRoles).map((ar) => {
                return {
                    Id: ar.Id,
                    Text: ar.IsGlobal ? ar.Text : `${ar.Text} (${ar.Therapy.Text})`,
                };
            });

            if (criteria) {
                // Select all actor roles when empty
                if (criteria.actorRoles.length === 0) {
                    _(actorRoles).forEach((ar) => criteria.actorRoles.push(ar.Id));
                }
            }

            _(globalActorRoles)
                .filter((ga) => _.find(allActorRoles, (ar) => ar.Id === ga.Id) == null)
                .forEach((ga) => allActorRoles.push(ga));
            this.actorRoles = _(allActorRoles).sortBy((ar) => ar.Text);
            
            // Set Therapies
            if (criteria) {
                if (criteria.therapyIds.length !== 0)
                {
                    criteria.therapyIds = criteria.therapyIds.filter(item => therapies.map(t => t.Id).indexOf(item) > -1);
                }
            }

            this.therapies = therapies;
        }

        private handleFilters(criteria: IAgendaFilter): void {
            let therapyIdsNew = null;
            let fromNew = null;
            let untilNew = null;
            let actorRolesNew = null;

            if (criteria.therapyIds) {
                therapyIdsNew = criteria.therapyIds;
            }

            if (criteria.fromDate) {
                fromNew = criteria.fromDate;
            }

            if (criteria.untilDate) {
                untilNew = criteria.untilDate;
            }

            if (criteria.actorRoles) {
                actorRolesNew = criteria.actorRoles;
            }

            const knownFilters: ITeamAgendaFilter = {
                knownFrom: fromNew,
                knownUntil: untilNew,
                knownActorRoles: actorRolesNew,
                knownTherapies: therapyIdsNew,
            };

            this.storageSvc.store(StorageServiceConstants.teamAgendaKey, knownFilters);
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: IAgendaFilter,
            includeCount: boolean,
            includeHasNextPage: boolean
        ): Promise<Shared.Contract.ISearchResult<Model.AgendaAction>> {
            const query: Contract.CarePlanAction.Read.IFindAgendaActionsQuery = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                includeCount: includeCount,
                includeHasNextPage: includeHasNextPage,
                dateFilterTypes: [Contract.Core.Codes.DateFilterTypeCode.PlannedDate],
                startDate: Shared.DateHelper.toServerDateString(criteria.fromDate),
                endDate: Shared.DateHelper.toServerDateString(criteria.untilDate),
                patientId:
                    criteria.patientData != null && criteria.patientData.patient != null
                        ? criteria.patientData.patient.Id
                        : null,
                patientNumber:
                    (criteria.patientData == null || criteria.patientData.patient == null) &&
                    criteria.patientData.patientNumber != null
                        ? criteria.patientData.patientNumber
                        : null,
                statuses: criteria.statuses,
                actionAssignmentTypes: criteria.actionAssignmentTypes,
                actionTypes: criteria.actionTypes,
                actorRoles: criteria.actorRoles,
                therapyIds: criteria.therapyIds,
            };

            this.handleFilters(criteria);
            this.spinnerSvc.show('agendasearch');
            try {
                const data = await this.carePlanActionApiSvc.findAgendaActions(query);
                const actions = data.Items;
                const partyRoleIds = _(data.Items).map((x) => x.Patient.Id);
                if (_(partyRoleIds).size() !== 0) {
                    const persons = await this.partyApiSvc.getPersonsAsync(partyRoleIds);
                    const agendaItems = _(actions).map((x) => {
                        const agendaItem = new Model.AgendaAction(x, persons);
                        (agendaItem as any).currentState = this.currentState;
                        return agendaItem;
                    });
                    return {
                        Total: data.Total,
                        Items: agendaItems,
                        HasNextPage: data.HasNextPage,
                    };
                } else {
                    this.spinnerSvc.hide('agendasearch');
                    return {
                        Total: data.Total,
                        Items: null,
                    };
                }
            } catch (e) {
                this.toaster.error(e);
            } finally {
                this.spinnerSvc.hide('agendasearch');
            }
        }
    }

    remeCareAgendaModule.component('rcTeamAgenda', {
        controller: TeamAgendaController,
        templateUrl: 'agenda/teamAgenda/teamAgenda.html',
    });
}
