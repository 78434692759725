/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanActionStatus = RemeCare.Contract.Core.Codes.CarePlanActionStatus;
        var ActionTableController = /** @class */ (function (_super) {
            __extends(ActionTableController, _super);
            // @ngInject
            function ActionTableController(dateHelper, spinnerSvc, $q, toaster, idService, carePlanActionApiSvc, gridBuilderSvc) {
                var _this = _super.call(this, dateHelper, spinnerSvc) || this;
                _this.$q = $q;
                _this.toaster = toaster;
                _this.idService = idService;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                return _this;
            }
            ActionTableController.prototype.hasData = function () {
                return this.grid && this.grid.hasData();
            };
            ActionTableController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.buildGrid();
                        this.dateInfo.untilDate = this.globalUntil || null;
                        return [2 /*return*/, this.$q.resolve()];
                    });
                });
            };
            ActionTableController.prototype.buildGrid = function () {
                var _this = this;
                var dateFormat = this.monitoringPart.showTime
                    ? 'short'
                    : 'shortDate';
                var gridBuilder = this.gridBuilderSvc.createGridBuilder(function (p, ps, sf, sd, c) { return _this.executeSearch(p, ps, sf, sd, c); })
                    .addColumn('Name', 'General.Action');
                if (this.monitoringPart.showPlannedDates) {
                    gridBuilder = gridBuilder.addDateRangeColumn('PlannedDateTimeFrom', 'PlannedDateTimeUntil', 'Views.PatientFile.Monitoring.PlannedDate', dateFormat);
                }
                if (this.monitoringPart.showEventDates) {
                    gridBuilder = gridBuilder.addDateRangeColumn('EventDateTimeFrom', 'EventDateTimeUntil', 'Views.PatientFile.Monitoring.ObservationDate', dateFormat);
                }
                if (this.monitoringPart.showRegistrationDate) {
                    gridBuilder = gridBuilder.addDateColumn('ExecutionDateTime', 'Views.PatientFile.Monitoring.ExecutionDate', dateFormat);
                }
                if (this.monitoringPart.showModificationDate) {
                    gridBuilder = gridBuilder.addDateColumn('ModificationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', dateFormat);
                }
                this.gridId = this.idService.generateId();
                this.grid = gridBuilder
                    .setSorting(false)
                    .setMultiLineWithTodayLine(this.gridId, 'SuggestedDateTime')
                    .build();
                this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
            };
            ActionTableController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.grid.pagingOptions.currentPage = 1;
                                return [4 /*yield*/, this.grid.search()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            ActionTableController.prototype.executeSearch = function (page, pageSize, sortField, sortDirection, criteria) {
                var _this = this;
                return new Promise(function (resolve, reject) {
                    var dateFilterTypes = [];
                    if (_this.monitoringPart.showRegistrationDate) {
                        dateFilterTypes.push(RemeCare.Contract.Core.Codes.DateFilterTypeCode.ExecutionDate);
                    }
                    if (_this.monitoringPart.showPlannedDates) {
                        dateFilterTypes.push(RemeCare.Contract.Core.Codes.DateFilterTypeCode.PlannedDate);
                    }
                    if (_this.monitoringPart.showEventDates) {
                        dateFilterTypes.push(RemeCare.Contract.Core.Codes.DateFilterTypeCode.EventDate);
                    }
                    if (_this.monitoringPart.showModificationDate) {
                        dateFilterTypes.push(RemeCare.Contract.Core.Codes.DateFilterTypeCode.ModificationDate);
                    }
                    var query = {
                        page: page,
                        pageSize: pageSize,
                        sortField: 'suggesteddatetime',
                        sortOrder: 'DESC',
                        patientId: _this.patientId,
                        actionTemplateIds: _(_this.monitoringPart.monitoringPartSources)
                            .map(function (mps) { return mps.structuralTherapyActionId; }),
                        statusIds: [
                            CarePlanActionStatus.Open,
                            CarePlanActionStatus.Overdue,
                            CarePlanActionStatus.Completed
                        ],
                        dateFilterTypes: dateFilterTypes,
                        startDate: RemeCare.Shared.DateHelper.toServerDateString(_this.dateInfo.fromDate),
                        endDate: RemeCare.Shared.DateHelper.toServerDateString(_this.dateInfo.untilDate)
                    };
                    _this.carePlanActionApiSvc.findActionTimingInformations(query)
                        .then(function (r) {
                        if (_this.dateInfo.untilDate == null && _this.globalUntil == null) {
                            var first = r.Items[0];
                            if (first != null) {
                                _this.dateInfo.untilDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(first.SuggestedDateTime);
                            }
                        }
                        resolve(r);
                    }).catch(function (e) {
                        _this.toaster.error(e);
                        reject();
                    });
                });
            };
            return ActionTableController;
        }(Patient.MonitoringPartControllerBase));
        var ActionTableComponent = /** @class */ (function (_super) {
            __extends(ActionTableComponent, _super);
            function ActionTableComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = ActionTableController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
                return _this;
            }
            return ActionTableComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringActionTable', new ActionTableComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
