var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var AnamnesisInfo = Patient.Model.AnamnesisInfo;
        var SufferedDisease = Patient.Model.PatientFileSufferedDisease;
        var PatientFileMedicalHistoryOverviewController = /** @class */ (function (_super) {
            __extends(PatientFileMedicalHistoryOverviewController, _super);
            function PatientFileMedicalHistoryOverviewController($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.$dialog = $dialog;
                _this.patientSvc = patientSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                _this.$scope.addAnamnesisInfo = function () { return _this.addAnamnesisInfo(); };
                _this.$scope.addSufferedDisease = function () { return _this.addSufferedDisease(); };
                _this.$scope.canAddDisease = function () { return _this.canAddDisease(); };
                return _this;
            }
            PatientFileMedicalHistoryOverviewController.prototype.$onInit = function () {
                this.$scope.sufferedDiseases = [];
                this.$scope.anamnesisInfos = [];
                this.buildAnamnesisInfoGrid();
                this.buildSufferedDiseaseGrid();
                this.getAnamnesisInfos();
                this.getSufferedDiseases();
                this.getPossibleDiseases();
                this.$scope.deleteRight = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedical, RemeCare.Shared.Framework.AuthRight.Delete);
                this.$scope.editRight = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedical, RemeCare.Shared.Framework.AuthRight.Write);
            };
            PatientFileMedicalHistoryOverviewController.prototype.canAddDisease = function () {
                var _this = this;
                return _(this.$scope.possibleDiseases).any(function (x) { return !_(_this.$scope.sufferedDiseases).any(function (sd) { return sd.disease.Id === x.Id; }); });
            };
            PatientFileMedicalHistoryOverviewController.prototype.getPossibleDiseases = function () {
                var _this = this;
                this.patientSvc.getPossibleDiseases(this.$stateParams.patientId)
                    .success(function (ags) {
                    _this.$scope.possibleDiseases = ags;
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileMedicalHistoryOverviewController.prototype.getAnamnesisInfos = function () {
                var _this = this;
                this.patientSvc.getAnamnesisInfos(this.$stateParams.patientId)
                    .success(function (ags) {
                    _this.$scope.anamnesisInfos = _(ags).map(function (x) { return new AnamnesisInfo(x); });
                    _this.$scope.anamnesisInfosGrid.setData(_this.$scope.anamnesisInfos);
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileMedicalHistoryOverviewController.prototype.getSufferedDiseases = function () {
                var _this = this;
                this.patientSvc.getPatientFileSufferedDiseases(this.$stateParams.patientId)
                    .success(function (ags) {
                    _this.$scope.sufferedDiseases = _(ags).map(function (x) { return new SufferedDisease(x); });
                    _this.$scope.sufferedDiseasesGrid.setData(_this.$scope.sufferedDiseases);
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileMedicalHistoryOverviewController.prototype.buildAnamnesisInfoGrid = function () {
                var _this = this;
                this.$scope.anamnesisInfosGrid = this.gridBuilderSvc.createGridBuilder()
                    .addColumn("text", "Views.PatientFile.PatientInfo.AnamnesisInfo", { width: '***' })
                    .addColumn("observationDate", "General.ObservationDate", { cellFilter: 'date: "shortDate"' })
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (h) { return _this.editAnamnesisInfo(h); }, function () { return !_this.$scope.editRight; })
                    .addConditionalDeleteButtonColumn(function () { return !_this.$scope.deleteRight; }, null, function (c) { return _this.deleteAnamnesisInfo(c); })
                    .setMultiLine('anamnesisGrid')
                    .build();
            };
            PatientFileMedicalHistoryOverviewController.prototype.buildSufferedDiseaseGrid = function () {
                var _this = this;
                this.$scope.sufferedDiseasesGrid = this.gridBuilderSvc.createGridBuilder()
                    .addColumn("disease.Text", "Views.PatientFile.PatientInfo.Pathology")
                    .addColumn("diagnosisDate", "General.DiagnosisDate", { cellFilter: 'date: "shortDate"' })
                    .addColumn("remark", "General.Remark")
                    .addColumn("getTherapyList()", "Views.PatientFile.PatientInfo.TreatedBy")
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (h) { return _this.editSufferedDisease(h, false); }, function () { return !_this.$scope.editRight; })
                    .addConditionalDeleteButtonColumn(function () { return !_this.$scope.deleteRight; }, null, function (c) { return _this.deleteSufferedDisease(c); })
                    .setMultiLine('anamnesisGrid')
                    .build();
            };
            PatientFileMedicalHistoryOverviewController.prototype.addAnamnesisInfo = function () {
                var anamnesisInfo = new AnamnesisInfo();
                anamnesisInfo.patientId = this.$stateParams.patientId;
                this.editAnamnesisInfo(anamnesisInfo);
            };
            PatientFileMedicalHistoryOverviewController.prototype.addSufferedDisease = function () {
                var sufferedDisease = new SufferedDisease();
                sufferedDisease.patientId = this.$stateParams.patientId;
                this.editSufferedDisease(sufferedDisease, true);
            };
            PatientFileMedicalHistoryOverviewController.prototype.editAnamnesisInfo = function (anamnesisInfo) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.modalBuilderFactory.createModalBuilder()
                    .setController('patientFileAddAnamnesisInfoModalCtrl')
                    .setTemplateUrl('views/patient/patientInfo/medicalHistory/addAnamnesisInfoModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    anamnesisInfo: angular.copy(anamnesisInfo)
                })
                    .setResultCallBack(function (r) {
                    _this.getAnamnesisInfos();
                    deferred.resolve(r);
                })
                    .build();
                return deferred.promise();
            };
            PatientFileMedicalHistoryOverviewController.prototype.editSufferedDisease = function (sufferedDisease, diseaseEditable) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.modalBuilderFactory.createModalBuilder()
                    .setController('patientFileAddSufferedDiseaseModalCtrl')
                    .setTemplateUrl('views/patient/patientInfo/medicalHistory/addSufferedDiseaseModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    diseaseEditable: diseaseEditable,
                    sufferedDisease: angular.copy(sufferedDisease),
                    diseases: _(this.$scope.possibleDiseases).filter(function (x) { return (sufferedDisease.disease && x.Id === sufferedDisease.disease.Id) || !_(_this.$scope.sufferedDiseases).any(function (sd) { return sd.disease.Id === x.Id; }); })
                })
                    .setResultCallBack(function (r) {
                    _this.getSufferedDiseases();
                    deferred.resolve(r);
                })
                    .build();
                return deferred.promise();
            };
            PatientFileMedicalHistoryOverviewController.prototype.deleteAnamnesisInfo = function (anamnesisInfo) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveAnamnesisInfo', 'Views.PatientFile.PatientInfo.RemoveAnamnesisInfoText', function () {
                    _this.patientSvc.deleteAnamnesisInfo(_this.$stateParams.patientId, anamnesisInfo.id)
                        .success(function () { return deferred.resolve(true); })
                        .error(function (e) {
                        _this.errorCallback(e);
                        deferred.resolve(false);
                    });
                }, function () {
                    deferred.resolve(false);
                });
                return deferred.promise();
            };
            PatientFileMedicalHistoryOverviewController.prototype.deleteSufferedDisease = function (sufferedDisease) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveSufferedDisease', 'Views.PatientFile.PatientInfo.RemoveSufferedDiseaseText', function () {
                    _this.patientSvc.deletePatientFileSufferedDisease(_this.$stateParams.patientId, sufferedDisease.disease.Id)
                        .success(function () { return deferred.resolve(true); })
                        .error(function (e) {
                        _this.errorCallback(e);
                        deferred.resolve(false);
                    });
                }, function () {
                    deferred.resolve(false);
                });
                return deferred.promise();
            };
            return PatientFileMedicalHistoryOverviewController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('patientFileMedicalHistoryOverviewCtrl', function ($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice) { return new PatientFileMedicalHistoryOverviewController($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
