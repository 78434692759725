var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ContactPointType = RemeCare.Shared.Contract.Code.ContactPointType;
        var ContactPointUsageType = RemeCare.Shared.Contract.Code.ContactPointUsageType;
        var EditContactController = /** @class */ (function () {
            function EditContactController($uibModalInstance, contactPoint, toaster, partyId, masterdataSvc, partyApiSvc, modalBuilderFactory, currentEmails, currentPhoneNumbers, currentFaxNumbers, currentMobileNumbers, isReadOnly) {
                this.$uibModalInstance = $uibModalInstance;
                this.contactPoint = contactPoint;
                this.toaster = toaster;
                this.partyId = partyId;
                this.masterdataSvc = masterdataSvc;
                this.partyApiSvc = partyApiSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.currentEmails = currentEmails;
                this.currentPhoneNumbers = currentPhoneNumbers;
                this.currentFaxNumbers = currentFaxNumbers;
                this.currentMobileNumbers = currentMobileNumbers;
                this.isReadOnly = isReadOnly;
            }
            EditContactController.prototype.$onInit = function () {
                var _this = this;
                this.contactPointInfo = {};
                this.masterdataSvc.getContactPointTypes()
                    .success(function (r) { return _this.contactPointTypes = _(r).filter(function (cpt) {
                    return _([
                        ContactPointType.Email, ContactPointType.Fax, ContactPointType.MobilePhone,
                        ContactPointType.Telephone
                    ])
                        .contains(cpt.Id);
                }); })
                    .error(function (e) { return _this.toaster.error(e); });
                if (this.contactPoint != null) {
                    this.contactPointInfo.contactPointType = this.contactPoint.type;
                    this.masterdataSvc.getContactPointUsageTypes(RemeCare.Shared.Contract.Code.PartyType.Person, this.contactPointInfo.contactPointType)
                        .success(function (r) { return _this.contactPointUsageTypes = r; })
                        .error(function (e) { return _this.toaster.error(e); });
                    this.isReadOnly = this.isReadOnly || this.contactPoint.isReadOnly;
                }
            };
            EditContactController.prototype.contactPointTypeChange = function (c) {
                var _this = this;
                this.contactPoint = RemeCare.Model.ContactPointFactory.createFromType(c);
                this.masterdataSvc.getContactPointUsageTypes(RemeCare.Shared.Contract.Code.PartyType.Person, c)
                    .success(function (r) { return _this.contactPointUsageTypes = r; })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            EditContactController.prototype.overlapBetweenContactPoints = function (cp, currentCp) {
                return (currentCp.validUntilDate == null ||
                    moment(cp.validFromDate).isSameOrBefore(currentCp.validUntilDate)) &&
                    (cp.validUntilDate == null ||
                        moment(currentCp.validFromDate).isSameOrBefore(cp.validUntilDate));
            };
            EditContactController.prototype.buildWarningModal = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setTemplateUrl('views/shared/overlapContactPointsModal.html')
                    .build();
            };
            EditContactController.prototype.buildDateGapWarningModal = function () {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setTemplateUrl('views/shared/noActiveContactPointModal.html')
                    .build();
            };
            EditContactController.prototype.checkForOverlapSameCp = function (cpType) {
                var _this = this;
                var canSave = true;
                var arrayToCheck;
                switch (cpType) {
                    case ContactPointType.Email:
                        arrayToCheck = this.currentEmails;
                        break;
                    case ContactPointType.Fax:
                        arrayToCheck = this.currentFaxNumbers;
                        break;
                    case ContactPointType.MobilePhone:
                        arrayToCheck = this.currentMobileNumbers;
                        break;
                    case ContactPointType.Telephone:
                        arrayToCheck = this.currentPhoneNumbers;
                        break;
                    default:
                        return false;
                }
                arrayToCheck.forEach(function (cp) {
                    if (cp.contactPointUsageType === _this.contactPoint.contactPointUsageType) {
                        if (_this.overlapBetweenContactPoints(cp, _this.contactPoint)) {
                            canSave = false;
                            return;
                        }
                    }
                });
                return canSave;
            };
            EditContactController.prototype.checkForDateGapInCps = function (cpType) {
                var hasDateGap = false;
                var arrayToCheck;
                switch (cpType) {
                    case ContactPointType.Email:
                        arrayToCheck = this.currentEmails;
                        break;
                    case ContactPointType.Fax:
                        arrayToCheck = this.currentFaxNumbers;
                        break;
                    case ContactPointType.MobilePhone:
                        arrayToCheck = this.currentMobileNumbers;
                        break;
                    case ContactPointType.Telephone:
                        arrayToCheck = this.currentPhoneNumbers;
                        break;
                    default:
                        throw new Error('ContactPointType not found!');
                }
                arrayToCheck.push(this.contactPoint);
                var sortedArray = arrayToCheck.sort(function (a, b) { return a.validFromDate - b.validFromDate; });
                for (var i = 1; i < sortedArray.length; i++) {
                    var beginningOfGap = moment(sortedArray[i - 1].validUntilDate);
                    var endOfGap = moment(sortedArray[i].validFromDate);
                    if (beginningOfGap != null && endOfGap.diff(beginningOfGap, 'days') > 1) {
                        hasDateGap = true;
                        break;
                    }
                }
                if (hasDateGap) {
                    this.buildDateGapWarningModal();
                }
            };
            EditContactController.prototype.confirm = function () {
                var _this = this;
                var canSave = true;
                if (this.form.$invalid) {
                    this.form.showValidationErrorMessage();
                    this.form.$setSubmitted();
                    return;
                }
                if (this.contactPoint.contactPointUsageType === ContactPointUsageType.Domicile ||
                    this.contactPoint.contactPointUsageType === ContactPointUsageType.Default) {
                    canSave = this.checkForOverlapSameCp(this.contactPoint.type);
                }
                if (canSave) {
                    this.partyApiSvc.saveContactPoint(this.partyId, this.contactPoint.toServerWrite())
                        .success(function (id) {
                        _this.contactPoint.id = id || _this.contactPoint.id;
                        _this.$uibModalInstance.close(_this.contactPoint);
                        _this.checkForDateGapInCps(_this.contactPoint.type);
                    })
                        .error(function (e) { return _this.toaster.error(e); });
                }
                else {
                    this.buildWarningModal();
                    this.$uibModalInstance.close();
                }
            };
            return EditContactController;
        }());
        Patient.remeCarePatientModule.controller('editContactCtrl', EditContactController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
