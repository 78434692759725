module RemeCare.Shared.Directive {

    interface IStandardPhraseTextScope extends ng.IScope {
        addPhrase(p: string): void;
        readonly: boolean;
        maxlength: number;
        text: string;
        addTimestamp(): void;
    }

    class StandardPhraseTextDirective implements ng.IDirective {

        constructor(private $filter: ng.IFilterService,
            private authservice: Shared.Framework.AuthService) {
        }

        restrict = 'EA';
        replace = true;
        scope: { [boundProperty: string]: string } = {
            phrases: '=',
            text: '=ngModel',
            required: '=ngRequired',
            maxlength: '=ngMaxlength',
            readonly: '=ngReadonly',
            id: '@',
            name: '@',
            timestamp: '='
        };
        link = (scope: IStandardPhraseTextScope, element) => {
            var getCursorPosition = (textArea) => {
                var iCaretPos = 0;

                if ((<any>document).selection) {
                    textArea.focus();
                    var oSel = (<any>document).selection.createRange();
                    oSel.moveStart('character', -textArea.value.length);
                    iCaretPos = oSel.text.length;
                } else if (textArea.selectionStart || textArea.selectionStart == '0') {
                    iCaretPos = textArea.selectionStart;
                }
                return iCaretPos;
            }

            scope.addPhrase = (phrase) => {
                if (scope.readonly) return;
                var textArea = <HTMLTextAreaElement>element.find('textarea')[0];
                var cursorPos = getCursorPosition(textArea);
                var text = textArea.value || '';
                text = text.slice(0, cursorPos) +
                    phrase.replace(/<br\/>/g, '\n') +
                    ' ' +
                    text.slice(cursorPos, text.length);
                if (scope.maxlength && scope.maxlength < text.length) {
                    text = text.slice(0, scope.maxlength);
                }
                scope.text = text;
                textArea.focus();
            }

            scope.addTimestamp = () => {
                if (scope.readonly) return;
                var textArea = <HTMLTextAreaElement>element.find('textarea')[0];
                var cursorPos = getCursorPosition(textArea);
                var timeStamp = this.$filter('date')(new Date(), 'short');
                var text = textArea.value;
                text = text.slice(0, cursorPos) + '[' + this.authservice.getClaim(Shared.Framework.ClaimTypes.firstname) +
                    ' ' + this.authservice.getClaim(Shared.Framework.ClaimTypes.lastname) + ' ' + timeStamp + '] ' + text.slice(cursorPos, text.length);
                if (text.length > 3000) {
                    text = text.slice(0, 3000);
                }
                scope.text = text;

                textArea.focus();
            }
        };
        templateUrl = 'views/shared/standardPhraseText.html';
    }
    remeCareAppModule.directive('standardPhraseText', ($filter, authservice) => new StandardPhraseTextDirective($filter, authservice));
}