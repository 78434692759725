module RemeCare.SelfService {
    export class SelfServiceApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public activateUserAsync(
            activationKey: string,
            birthDate: Date,
            userId: string,
            password: string
        ): Promise<{}> {
            return this.postPromise(
                'Security/ActivateUser',
                {
                    key: activationKey,
                    birthDate: Shared.DateHelper.toServerDateString(birthDate),
                    userId: userId,
                    password: password
                },
                'security.activateUser'
            );
        }

        public resetPasswordAsync(resetKey: string, birthDate: Date, userId: string, password: string): Promise<{}> {
            return this.postPromise(
                'Security/ResetPassword',
                {
                    key: resetKey,
                    birthDate: Shared.DateHelper.toServerDateString(birthDate),
                    userId: userId,
                    password: password
                },
                'security.resetPassword'
            );
        }

        public getExternalIdentityProvidersAsync(
            activationKey: string,
            userId: string,
            activationType: Contract.Security.Codes.ActivationType
        ): Promise<Contract.Security.IExternalIdentityProvider[]> {
            return this.getPromise<Contract.Security.IExternalIdentityProvider[]>(
                'Security/ExternalIdentityProviders',
                {
                    key: activationKey,
                    userId: userId,
                    activationType
                },
                'security.externalidentityproviders'
            ).then(eip => {
                _.each(eip, provider => {
                    const baseUrl =
                        provider.RemeCareBaseUrl[provider.RemeCareBaseUrl.length - 1] === '/'
                            ? provider.RemeCareBaseUrl.substring(0, provider.RemeCareBaseUrl.length - 1)
                            : provider.RemeCareBaseUrl;
                    provider.RemeCareBaseUrl = baseUrl;
                });
                return eip;
            });
        }

        public activateExternalIdentityProviderAsync(activationKey: string, userId: string): Promise<{}> {
            return this.postPromise('Security/ExternalIdentityProviders/ActivateUser', {
                key: activationKey,
                userId: userId
            });
        }

        public resetExternalIdentityProviderAsync(activationKey: string, userId: string): Promise<{}> {
            return this.postPromise('Security/ExternalIdentityProviders/ResetPassword', {
                key: activationKey,
                userId: userId
            });
        }

        public requestNewPasswordAsync(userId: string): Promise<{}> {
            return this.postPromise(
                'Security/ForgotPassword',
                {
                    userId: userId
                },
                'security.requestNewPassword'
            );
        }

        public requestAccountInfoAsync(userInformation: IRequestAccountInformation): Promise<{}> {
            return this.postPromise('Security/RequestAccountInfo', userInformation, 'security.requestAccountInfo');
        }

        public requestUserAccountAsync(userAccountInformation: IRequestUserAccountInformation): Promise<{}> {
            return this.postPromise(
                'Security/RequestUserAccount',
                userAccountInformation,
                'security.requestUserAccount'
            );
        }
    }

    remeCareSelfServiceModule.service('selfServiceApi', SelfServiceApiService);
}
