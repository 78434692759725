/// <reference path="careTeamPartyBaseController.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CareTeamOrganisationsController = /** @class */ (function (_super) {
            __extends(CareTeamOrganisationsController, _super);
            // @ngInject
            function CareTeamOrganisationsController($scope, $translate, $filter, toaster, patientSvc, authservice, partyApiSvc, modalBuilderFactory, gridBuilderSvc, healthCareOrganisationSearchSvc) {
                var _this = _super.call(this, $scope, $translate, $filter, toaster, patientSvc, authservice, partyApiSvc, modalBuilderFactory, gridBuilderSvc) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.$filter = $filter;
                _this.toaster = toaster;
                _this.patientSvc = patientSvc;
                _this.authservice = authservice;
                _this.partyApiSvc = partyApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                return _this;
            }
            CareTeamOrganisationsController.prototype.showCareTeamPartyDetail = function (partyRoleId) {
                return __awaiter(this, void 0, void 0, function () {
                    var r, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([partyRoleId])];
                            case 1:
                                r = _a.sent();
                                this.showCareTeamOrganisationModal(r[0]);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CareTeamOrganisationsController.prototype.init = function () {
                this.buildGrids();
            };
            CareTeamOrganisationsController.prototype.buildOverviewGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, sd, c) {
                    return _this.searchCareTeamAsync(p, ps, sf, sd, c, RemeCare.Shared.Contract.Code.PartyType.Organisation, function (cp) {
                        if (_this.onlyActive) {
                            return _(cp.Assignments).any(_this.isActiveAssignment);
                        }
                        else {
                            return true;
                        }
                    });
                })
                    .addColumn('Name', 'General.Name', { visibleWhenSmall: true })
                    .addActionColumn('info-sign', function (p) { return _this.showCareTeamPartyDetail(p.PartyRoleId); })
                    .addActionOnScopeColumn('$ctrl.parent.getActiveCarePlans', 'General.CarePlans.Active')
                    .addShowDetailButtonColumn(function (p) {
                    return _this.showPartyAssignments(p, RemeCare.Shared.Contract.Code.PartyType.Organisation);
                });
                this.partyOverviewGrid = builder.build();
                this.partyOverviewGrid.pagingOptions.pageSize = 50;
                this.partyOverviewGrid.search();
            };
            CareTeamOrganisationsController.prototype.showPartySelector = function (actorRole, organisation) {
                return __awaiter(this, void 0, void 0, function () {
                    var pr, r, careTeamParty, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, this.modalBuilderFactory.showSelectorAsync(organisation, ['PartyName'], ' ', actorRole.Name)];
                            case 1:
                                pr = _a.sent();
                                return [4 /*yield*/, this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([pr.PartyRoleId])];
                            case 2:
                                r = _a.sent();
                                careTeamParty = this.createOrganisationCareTeamParty(actorRole, r[0]);
                                this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], r[0].PartyRoleId, null, r[0].Name);
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            CareTeamOrganisationsController.prototype.showPartySearchModal = function (actorRole) {
                var _this = this;
                var orgTypes = this.getActorRoleInclusionDetails(actorRole)
                    .map(function (arid) { return arid.HealthCareOrganisationTypeId; })
                    .filter(function (id) { return id != null; })
                    .value();
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('searchHealthCareOrganisationPartyCtrl')
                    .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                    .setScope({
                    title: actorRole.Name,
                    organisationTypes: orgTypes,
                })
                    .setResultCallBack(function (r) {
                    var careTeamParty = _this.createOrganisationCareTeamParty(actorRole, r);
                    _this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], r.PartyRoleId, null, r.Name);
                })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            CareTeamOrganisationsController.prototype.showCareTeamOrganisationModal = function (healthCareParty) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/patient/careTeam/careTeamOrganisationDetail.html')
                        .setScope({
                        healthCareParty: healthCareParty,
                    })
                        .setResultCallBack(function () {
                        resolve();
                    })
                        .build();
                });
            };
            CareTeamOrganisationsController.prototype.createOrganisationCareTeamParty = function (actorRole, hcOrganisation) {
                var result = this.createCareTeamParty(actorRole, hcOrganisation);
                result.Name = hcOrganisation.Name;
                return result;
            };
            return CareTeamOrganisationsController;
        }(Patient.CareTeamPartyBaseController));
        Patient.remeCarePatientModule.component('rcCareTeamOrganisations', {
            bindings: {
                actorRoles: '<',
                patientId: '<',
                editRight: '<',
                onlyActive: '<',
                careTeamsCanEdit: '<',
            },
            controller: CareTeamOrganisationsController,
            templateUrl: 'views/patient/careTeam/careTeamOrganisations.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
