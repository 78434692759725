/// <reference path="careTeamPartyBaseController.ts"/>

namespace RemeCare.Patient {
    import CareTeamParty = Contract.HealthCareParty.Read.ICareTeamParty;

    class CareTeamOrganisationsController extends CareTeamPartyBaseController {
        // @ngInject
        constructor(
            protected $scope: ng.IScope,
            protected $translate: ng.translate.ITranslateService,
            protected $filter: ng.IFilterService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: PatientService,
            protected authservice: Shared.Framework.AuthService,
            protected partyApiSvc: Core.Services.PartyApiService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc
        ) {
            super(
                $scope,
                $translate,
                $filter,
                toaster,
                patientSvc,
                authservice,
                partyApiSvc,
                modalBuilderFactory,
                gridBuilderSvc
            );
        }

        public async showCareTeamPartyDetail(partyRoleId: Shared.Contract.Guid): Promise<void> {
            try {
                const r = await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([partyRoleId]);
                this.showCareTeamOrganisationModal(r[0]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected init(): void {
            this.buildGrids();
        }

        protected buildOverviewGrid(): void {
            const builder = this.gridBuilderSvc
                .createGridBuilder<CareTeamParty>((p, ps, sf, sd, c) =>
                    this.searchCareTeamAsync(p, ps, sf, sd, c, Shared.Contract.Code.PartyType.Organisation, (cp) => {
                        if (this.onlyActive) {
                            return _(cp.Assignments).any(this.isActiveAssignment);
                        } else {
                            return true;
                        }
                    })
                )
                .addColumn('Name', 'General.Name', { visibleWhenSmall: true })
                .addActionColumn('info-sign', (p) => this.showCareTeamPartyDetail(p.PartyRoleId))
                .addActionOnScopeColumn('$ctrl.parent.getActiveCarePlans', 'General.CarePlans.Active')
                .addShowDetailButtonColumn((p) =>
                    this.showPartyAssignments(p, Shared.Contract.Code.PartyType.Organisation)
                );

            this.partyOverviewGrid = builder.build();
            this.partyOverviewGrid.pagingOptions.pageSize = 50;
            this.partyOverviewGrid.search();
        }

        protected async showPartySelector(
            actorRole: ITherapyActorRole,
            organisation: Shared.Contract.Read.IActorRoleInclusionDetail[]
        ): Promise<void> {
            try {
                const pr = await this.modalBuilderFactory.showSelectorAsync<
                    Shared.Contract.Read.IActorRoleInclusionDetail
                >(organisation, ['PartyName'], ' ', actorRole.Name);
                const r = await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([pr.PartyRoleId]);
                const careTeamParty = this.createOrganisationCareTeamParty(actorRole, r[0]);
                this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], r[0].PartyRoleId, null, r[0].Name);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected showPartySearchModal(actorRole: ITherapyActorRole): void {
            const orgTypes = this.getActorRoleInclusionDetails(actorRole)
                .map((arid) => arid.HealthCareOrganisationTypeId)
                .filter((id) => id != null)
                .value();
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>()
                .setController('searchHealthCareOrganisationPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                .setScope({
                    title: actorRole.Name,
                    organisationTypes: orgTypes,
                })
                .setResultCallBack((r) => {
                    const careTeamParty = this.createOrganisationCareTeamParty(actorRole, r);
                    this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], r.PartyRoleId, null, r.Name);
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private showCareTeamOrganisationModal(
            healthCareParty: Contract.HealthCareParty.Read.IHealthCareOrganisationParty
        ) {
            return new Promise<void>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/careTeam/careTeamOrganisationDetail.html')
                    .setScope({
                        healthCareParty: healthCareParty,
                    })
                    .setResultCallBack(() => {
                        resolve();
                    })
                    .build();
            });
        }

        private createOrganisationCareTeamParty(
            actorRole: ITherapyActorRole,
            hcOrganisation: Contract.HealthCareParty.Read.IHealthCareOrganisationParty
        ): CareTeamParty {
            const result = this.createCareTeamParty(actorRole, hcOrganisation);
            result.Name = hcOrganisation.Name;
            return result;
        }
    }

    remeCarePatientModule.component('rcCareTeamOrganisations', {
        bindings: {
            actorRoles: '<',
            patientId: '<',
            editRight: '<',
            onlyActive: '<',
            careTeamsCanEdit: '<',
        },
        controller: CareTeamOrganisationsController,
        templateUrl: 'views/patient/careTeam/careTeamOrganisations.html',
    });
}
