module RemeCare.Management {
    import IOrganisation = Contract.Party.Read.IOrganisation;
    import DateHelper = Shared.DateHelper;

    export interface IDates {
        validFromDate: Date;
        validUntilDate: Date;
    }

    class GroupController implements ng.IComponentController {
        public $transition$;
        public infoForm: Shared.Framework.Directive.IFormController;
        public dateForm: Shared.Framework.Directive.IFormController;
        public group: Management.Group;
        public organisationAdd: boolean;
        public personAdd: boolean;
        public organisationsGrid: Shared.Framework.Grid.Grid<GroupAssignment>;
        public personsGrid: Shared.Framework.Grid.Grid<GroupAssignment>;
        public isReadOnly: boolean;
        public linkedTherapies;
        public linkedCarePlans;
        public carePlansLinkedToGroup: Contract.Patient.Read.ICareplan[];

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly $state: ng.ui.IStateService
        ) {}

        public async $onInit(): Promise<void> {
            const groupId = this.$transition$.params().groupId;
            this.buildGrids();
            if (groupId !== null) {
                this.isReadOnly = true;
                const groupDetails = await this.partyApiSvc.getGroupDetailsAsync(groupId);
                this.group = new Group(groupDetails);
                this.organisationsGrid.setData(this.group.organisations);
                this.personsGrid.setData(this.group.persons);
                const carePlansForGroup = await this.carePlanApiSvc.getGroupRelatedCarePlansAsync(groupId);
                this.carePlansLinkedToGroup = carePlansForGroup;
                this.linkedCarePlans = _.chain(this.carePlansLinkedToGroup)
                    .groupBy(x => x.Id)
                    .size()
                    .value();
                this.linkedTherapies = _.chain(this.carePlansLinkedToGroup)
                    .groupBy(x => x.TherapyId)
                    .size()
                    .value();
            } else {
                this.group = new Group();
                this.group.validFromDate = Shared.DateHelper.today();
            }
        }

        public getNumberOfTherapies(): string {
            if (this.linkedTherapies != null && this.linkedCarePlans) {
                return this.linkedTherapies.toString().concat(` ${this.$translate.instant('General.Therapies')}`);
            }
        }

        public getNumberOfCarePlans(): string {
            if (this.linkedCarePlans != null && this.linkedCarePlans) {
                return this.linkedCarePlans.toString().concat(` ${this.$translate.instant('General.CarePlans')}`);
            }
        }

        public searchOrganisation(): void {
            this.personAdd = false;
            this.organisationAdd = true;
        }

        public searchPerson(): void {
            this.organisationAdd = false;
            this.personAdd = true;
        }

        private buildGrids(): void {
            const builder = this.gridBuilderSvc
                .createGridBuilder<GroupAssignment>()
                .addColumn('organisation.Name', 'General.Name')
                .addColumn('organisation.HealthCareOrganisationType.Text', 'General.Type')
                .addColumn('organisation.City', 'General.City')
                .addDateColumn('validFromDate', 'General.StartDate', 'shortDate')
                .addDateColumn('validUntilDate', 'General.EndDate', 'shortDate')
                .addCheckBoxColumnFunction(
                    'Views.Management.Active',
                    'isActive',
                    x => this.canNotEditItem(x),
                    ga => this.stopGroupAssignment(ga)
                )
                .addConditionalShowDeleteButtonColumn(
                    x => !this.canNotDeleteItem(x),
                    x => this.removeOrganisation(x.partyId)
                );

            this.organisationsGrid = builder.build();

            const personBuilder = this.gridBuilderSvc
                .createGridBuilder<GroupAssignment>()
                .addColumn('person.LastName', 'General.LastName')
                .addColumn('person.FirstName', 'General.FirstName')
                .addColumn('person.partyRoleTypes', 'General.Capacity', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                .addColumn('person.healthCareProfessionalTypes', 'General.Type', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                .addColumn('person.specialties', 'General.Specialty', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                .addDateColumn('validFromDate', 'General.StartDate', 'shortDate')
                .addDateColumn('validUntilDate', 'General.EndDate', 'shortDate')
                .addCheckBoxColumnFunction(
                    'Views.Management.Active',
                    'isActive',
                    x => this.canNotEditItem(x),
                    ga => this.stopGroupAssignment(ga)
                )
                .addConditionalShowDeleteButtonColumn(
                    x => !this.canNotDeleteItem(x),
                    x => this.removeOrganisation(x.partyId)
                );

            this.personsGrid = personBuilder.build();
        }

        private stopGroupAssignment(ga: GroupAssignment): GroupAssignment {
            if (ga.validUntilDate === null) {
                this.$dialog
                    .warningMessageBox(
                        this.$translate.instant('General.Cancel'),
                        this.$translate.instant('Views.Management.StopMemberWarning'),
                        [
                            { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                            { result: 'yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                        ]
                    )
                    .then(result => {
                        if (result === 'yes') {
                            this.partyApiSvc
                                .stopGroupAssignment(this.group.id, ga.id)
                                .success(() => {
                                    ga.isActive = false;
                                    ga.isNew = false;
                                    return (ga.validUntilDate = Shared.DateHelper.today());
                                })
                                .error(e => this.toaster.error(e));
                        }
                    });
                ga.isActive = true;
                return ga;
            } else {
                this.$dialog
                    .warningMessageBox(
                        this.$translate.instant('General.Cancel'),
                        this.$translate.instant('Views.Management.ReActivateMemberWarning'),
                        [
                            { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                            { result: 'yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                        ]
                    )
                    .then(result => {
                        if (result === 'yes') {
                            this.partyApiSvc
                                .reActivateGroupMember(this.group.id, ga.id)
                                .success(guid => {
                                    let newGa = new GroupAssignment();
                                    newGa.id = guid;
                                    newGa.validFromDate = Shared.DateHelper.today();
                                    newGa.validUntilDate = null;
                                    newGa.groupId = ga.groupId;
                                    newGa.partyId = ga.partyId;
                                    newGa.party = ga.party;
                                    newGa.organisation = ga.organisation;
                                    newGa.person = ga.person;
                                    newGa.name = ga.name;
                                    newGa.groupName = ga.groupName;
                                    newGa.nameAndGroup = ga.nameAndGroup;
                                    newGa.type = ga.type;
                                    newGa.isActive = true;
                                    newGa.isNew = false;
                                    if (newGa.organisation === null) {
                                        this.group.persons.push(newGa);
                                        this.personsGrid.setData(_.sortBy(this.group.persons, x => x.person.LastName));
                                    } else {
                                        this.group.organisations.push(newGa);
                                        this.organisationsGrid.setData(
                                            _.sortBy(this.group.organisations, x => x.organisation.Name)
                                        );
                                    }
                                })
                                .error(e => this.toaster.error(e));
                        }
                    });
                ga.isActive = false;
                return ga;
            }
        }

        private canNotEditItem(selected: GroupAssignment): boolean {
            if (selected.validUntilDate !== null) {
                const doesOrganisationAlreadyexistWithoutValidityDate = _.some(this.group.organisations, x => {
                    return x.partyId === selected.partyId && x.validUntilDate === null;
                });
                const doesPersonAlreadyExistWithoutValidityDate = _.some(this.group.persons, x => {
                    return x.partyId === selected.partyId && x.validUntilDate === null;
                });
                return doesOrganisationAlreadyexistWithoutValidityDate || doesPersonAlreadyExistWithoutValidityDate;
            }
            return selected.isNew;
        }

        private canNotDeleteItem(selected: GroupAssignment): boolean {
            return selected.isNew;
        }

        private removeOrganisation(id: RemeCare.Shared.Contract.Guid): void {
            this.group.organisations = this.group.organisations.filter(ga => ga.partyId !== id);
        }

        public addOrganisation(organisation: IOrganisation): void {
            if (
                _(this.group.organisations).some(
                    gm =>
                        gm.organisation.Id === organisation.Id &&
                        (gm.validUntilDate === null ||
                            moment(gm.validUntilDate).isSame(DateHelper.endOfTime()) ||
                            moment(DateHelper.today()).isBefore(gm.validUntilDate))
                )
            ) {
                this.toaster.error(this.$translate.instant('Views.Management.OverlapMembers'));
                return;
            }

            let groupAssignment = new GroupAssignment();
            groupAssignment.partyId = organisation.Id;
            groupAssignment.validFromDate = Shared.DateHelper.today();
            groupAssignment.validUntilDate = null;
            groupAssignment.organisation = organisation;
            this.group.organisations.push(groupAssignment);
            this.organisationAdd = false;
            this.organisationsGrid.setData(_.sortBy(this.group.organisations, x => x.organisation.Name));
        }

        public addPerson(person: IPerson): void {
            if (
                _(this.group.persons).some(
                    gm =>
                        gm.person.PartyId === person.PartyId &&
                        (gm.validUntilDate === null ||
                            moment(gm.validUntilDate).isSame(DateHelper.endOfTime()) ||
                            moment(DateHelper.today()).isBefore(gm.validUntilDate))
                )
            ) {
                this.toaster.error(this.$translate.instant('Views.Management.OverlapMembers'));
                return;
            }
            let groupAssignment = new GroupAssignment();
            groupAssignment.partyId = person.PartyId;
            groupAssignment.validFromDate = Shared.DateHelper.today();
            groupAssignment.validUntilDate = null;
            groupAssignment.person = person;
            this.personAdd = false;
            this.group.persons.push(groupAssignment);
            this.personsGrid.setData(_.sortBy(this.group.persons, x => x.person.LastName));
        }

        public async confirm(): Promise<void> {
            if (this.group.persons.length === 0 && this.group.organisations.length === 0) {
                this.toaster.error(this.$translate.instant('Views.Management.NoMembersError'));
                return;
            }

            if (this.group.validFromDate === null) {
                this.group.validFromDate = Shared.DateHelper.today();
            }

            if (
                _.some(this.group.organisations, o => o.validFromDate < this.group.validFromDate) ||
                _.some(this.group.persons, p => p.validFromDate < this.group.validFromDate)
            ) {
                this.toaster.error(this.$translate.instant('Views.Management.MemberBefore'));
                return;
            }

            this.infoForm.$setSubmitted();
            if (this.infoForm.$invalid) {
                this.infoForm.showValidationErrorMessage();
                return;
            }
            try {
                await this.partyApiSvc.createGroupAsync(this.group.toServerWrite());
                this.$state.go('management.groups');
            } catch (err) {
                this.toaster.error(err);
            }
        }

        public cancel(): void {
            this.$dialog
                .warningMessageBox(
                    this.$translate.instant('General.Cancel'),
                    this.$translate.instant('Views.Management.CancelWarning'),
                    [
                        { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                        { result: 'Yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                    ]
                )
                .then(result => {
                    if (result === 'Yes') {
                        this.$state.go('management.groups.search');
                    }
                });
        }

        public async endGroup(): Promise<void> {
            const result = await this.$dialog.warningMessageBox(
                this.$translate.instant('Views.Management.StopGroup'),
                this.$translate.instant('Views.Management.StopGroupWarning'),
                [
                    { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                    { result: 'Yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                ]
            );
            if (result === 'Yes') {
                try {
                    await this.partyApiSvc.stopGroupAsync(this.group.id);
                    this.toaster.success(this.$translate.instant('Views.Management.StopGroupSuccess'));
                    this.$state.go('management.groups.search');
                } catch (e) {
                    this.toaster.error(e);
                }
            }
        }
    }

    remeCareManagementModule.component('rcGroup', {
        controller: GroupController,
        bindings: {
            $transition$: '<'
        },
        templateUrl: 'management/controllers/groups/groups/group.html'
    });
}
