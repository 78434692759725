namespace RemeCare.Patient {

    remeCarePatientModule.component('rcPictureDocument',
        {
            bindings: {
                alternativeImage: '<',
                document: '<',
                onCloseButtonClicked: '&',
            },
            templateUrl: 'patient/controllers/mediaDocuments/followUpDocuments/pictureDocument/pictureDocument.html', 
        });
}