module RemeCare.OneTimeLogin {

    export class OneTimeLoginApiService extends Shared.Framework.ApiServiceBase {

        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public login(oneTimeKey: string): ng.IPromise<IOneTimeLoginResult> {
            return this.post('OneTimeUrl/Login', {
                key: oneTimeKey,
            }, 'onetimelogin', true).then((httpResponse: ng.IHttpPromiseCallbackArg<IOneTimeLoginResult>) => {
                return httpResponse.data;
            }).catch((httpResponse: ng.IHttpPromiseCallbackArg<any>) => {
                if (httpResponse.status === 401) {
                    return this.$q.resolve(httpResponse.data);
                }
                return this.$q.reject(httpResponse.data);
            });
        }
    }

    remeCareOneTimeLoginModule.service('oneTimeLoginApi', OneTimeLoginApiService);
}