namespace RemeCare.CarePlanAction {
    interface IDateScope extends IComponentScope {
        component: DateDescriptor;
        showTime: boolean;
        showUntil: boolean;
    }

    class DateController extends ComponentControllerBase<IDateScope> {
        // @ngInject
        constructor($scope: IDateScope, guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {
            this.$scope.showTime = this.$scope.component.hasTime;
            this.$scope.showUntil = this.$scope.component.dateType === Shared.Contract.Code.DateTypeCode.Range;
        }
    }

    class DateDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/date.html';

        public controller = DateController;
    }

    angular.module('RemeCare.CarePlanAction').directive('rcDate', () => new DateDirective());
}
