module RemeCare.SelfService {
    class ManageIdentityController implements ng.IComponentController {
        // bindings
        public activating: boolean;
        public loadExternalIdentityProviders: () => Promise<Contract.Security.IExternalIdentityProvider[]>;
        public onPasswordSet: (args: { birthDate: Date; password: string }) => Promise<string>;
        public onProviderActivated: () => Promise<string>;
        public onProviderSelected: (args: { provider: Contract.Security.IExternalIdentityProvider }) => void;

        public activated: boolean;
        public hasExternalOptions: boolean;
        public externalIdentityProviders: Contract.Security.IExternalIdentityProvider[];
        public loaded: boolean;
        public errorMessage: string;
        public successMessage: string;
        public isSetPasswordActivated: boolean = false;

        constructor(private readonly $timeout: ng.ITimeoutService, private readonly $window: ng.IWindowService) {}

        public $onInit(): void {
            if (this.activating) {
                this.activateProvider();
            } else {
                this.retrieveExternalIdentityProvidersAsync();
            }
        }

        public setPassword(birthDate: Date, password: string): void {
            this.onPasswordSet({ birthDate: birthDate, password: password }).then(message =>
                this.activateSuccess(message)
            );
        }

        public selectProvider(provider: Contract.Security.IExternalIdentityProvider): void {
            this.onProviderSelected({ provider: provider });
        }

        private async retrieveExternalIdentityProvidersAsync(): Promise<void> {
            try {
                const eip = await this.loadExternalIdentityProviders();
                this.externalIdentityProviders = eip;
                this.hasExternalOptions = this.externalIdentityProviders && this.externalIdentityProviders.length > 0;
            } catch (exception) {
                this.errorMessage = exception;
            } finally {
                this.loaded = true;
            }
        }

        private async activateProvider(): Promise<void> {
            try {
                const message = await this.onProviderActivated();
                this.activateSuccess(message);
            } catch (exception) {
                this.errorMessage = exception;
            } finally {
                this.loaded = true;
            }
        }

        private activateSuccess(successMessage: string): void {
            this.successMessage = successMessage;
            this.activated = true;
            this.$timeout(() => {
                this.$window.location.href = this.$window.location.origin;
            }, 5000);
        }
    }

    remeCareSelfServiceModule.component('rcManageIdentity', {
        bindings: {
            activating: '<',
            confirmLabel: '@',
            externalExplanation: '@',
            externalButtonExplanation: '@',
            header: '@',
            internalExplanation: '@',
            loadExternalIdentityProviders: '&',
            onPasswordSet: '&',
            onProviderActivated: '&',
            onProviderSelected: '&',
            passwordLoader: '@',
            userId: '<'
        },
        controller: ManageIdentityController,
        templateUrl: 'views/selfService/manageIdentity.html'
    });
}
