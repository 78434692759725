var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var SignatureController = /** @class */ (function (_super) {
            __extends(SignatureController, _super);
            // @ngInject
            function SignatureController($scope, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                $scope.signatureUpdated = function (newModel) { return ($scope.component.signatureImage = newModel); };
                return _this;
            }
            SignatureController.prototype.init = function () { };
            return SignatureController;
        }(CarePlanAction.ComponentControllerBase));
        var SignatureDirective = /** @class */ (function (_super) {
            __extends(SignatureDirective, _super);
            function SignatureDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/signature.html';
                _this.controller = SignatureController;
                return _this;
            }
            return SignatureDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular.module('RemeCare.CarePlanAction').directive('rcSignature', function () { return new SignatureDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
