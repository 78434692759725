namespace RemeCare.Connections {
    export class ConnectionsApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public async postConnection(
            patientId: string,
            code: string,
            codeVerifier: string,
            connection: Shared.Contract.Code.ConnectionType
        ): Promise<void> {
            await this.postPromise(
                `Patients/${patientId}/Connections/${connection}`,
                {
                    codeVerifier,
                    code,
                },
                'postConnection'
            );
        }
    }

    remeCareConnectionsModule.service('connectionsApi', ConnectionsApiService);
}
