module RemeCare.Contract.Core.Codes {
    
    export enum ContactPersonTypeCode {
        Partner = 1,
        Parent = 2,
        Child = 3,
        Neighbour = 4,
        Friend = 5,
        Other = 6
    }
} 