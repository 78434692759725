var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var CarePlanActionStatus;
                (function (CarePlanActionStatus) {
                    CarePlanActionStatus[CarePlanActionStatus["Open"] = 1] = "Open";
                    CarePlanActionStatus[CarePlanActionStatus["Overdue"] = 2] = "Overdue";
                    CarePlanActionStatus[CarePlanActionStatus["Completed"] = 3] = "Completed";
                    CarePlanActionStatus[CarePlanActionStatus["Cancelled"] = 4] = "Cancelled";
                })(CarePlanActionStatus = Codes.CarePlanActionStatus || (Codes.CarePlanActionStatus = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
