var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var ActionTemplate = /** @class */ (function (_super) {
                __extends(ActionTemplate, _super);
                function ActionTemplate(authservice, patientId, serverObject) {
                    var _this = _super.call(this, authservice, serverObject && serverObject.Id, serverObject) || this;
                    _this.patientId = patientId;
                    if (serverObject != null) {
                        _this.therapy = serverObject.Therapy;
                        _this.carePlanId = serverObject.CarePlanId;
                        _this.duplicates = serverObject.Duplicates;
                        _this.hasTemporarySave = serverObject.HasTemporarySave;
                    }
                    return _this;
                }
                ActionTemplate.prototype.isActiveComponent = function (componentId) {
                    var componentsWithConditions = _(this.components).filter(function (c) {
                        return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.QualitativeObservation &&
                            _(c.answerConditions).any(function (a) { return a.GoToId === componentId; });
                    });
                    if (componentsWithConditions.length === 0) {
                        return true;
                    }
                    return _(componentsWithConditions).any(function (c) {
                        return _.find(c.answerConditions, function (a) { return a.GoToId === componentId && _(c.value).contains(a.AnswerCodeSetItem.Id); }) != null;
                    });
                };
                ActionTemplate.prototype.toServerWrite = function (isPermanent) {
                    var _this = this;
                    var now = new Date();
                    var adHocAdministration = _(this.components)
                        .chain()
                        .filter(function (c) {
                        return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.Administration &&
                            c.medicationDoses.length > 1;
                    })
                        .first()
                        .value();
                    var registrationValues = [];
                    _(this.components).forEach(function (c) {
                        if (_this.isActiveComponent(c.id)) {
                            var values = c.createRegistration(now, _this);
                            registrationValues = registrationValues.concat(values);
                        }
                    });
                    var result = {
                        eventDateTimeFrom: this.includesEventDate
                            ? RemeCare.Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeFrom())
                            : null,
                        eventDateTimeUntil: this.includesEventDate && this.isEventDateTimeRange
                            ? RemeCare.Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeUntil())
                            : null,
                        registrationValues: registrationValues,
                        personalisedTherapyActionId: adHocAdministration != null ? adHocAdministration.personalisedTherapyActionId : null,
                        patientId: this.patientId,
                        duplicates: this.duplicates,
                        isPermanent: isPermanent,
                    };
                    return result;
                };
                return ActionTemplate;
            }(RemeCare.Model.ActionBase));
            Model.ActionTemplate = ActionTemplate;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
