namespace RemeCare.CarePlanAction {
    export interface IScheduleResult {
        address: Contract.CarePlanAction.Read.ILocation;
        carePlanAction: CarePlanAction;
    }
    class RescheduleActionController implements ng.IComponentController {
        public dateInfo: {
            date: Date;
            from: Date;
            until: Date;
        };
        public partyRole: Shared.Contract.IEntityTranslation;
        public possibleActors: Shared.Contract.IEntityTranslation[];
        public possibleActionDurationOptions: Shared.Contract.IEntityTranslation[];
        public showActionDuration: boolean;
        public hasActionDurationOption: boolean;
        public durationOfAction: Date;
        public possibleActionDurationOptionId: Shared.Contract.Guid;
        public hasLocation: boolean;
        public hasExecutor: boolean;
        public includesTimeNoWindow: boolean;
        public includesTimeWindow: boolean;
        public placeholder: string;
        public reason: string;
        public infoMessage: string;
        public rescheduleForm: ng.IFormController;
        public address: Contract.CarePlanAction.Read.ILocation;
        public carePlanAction: CarePlanAction;
        public component: CareActDescriptor;
        public $close: (r: { result: IScheduleResult }) => void;
        private location: Contract.CarePlanAction.Read.ILocation;

        constructor(
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $locale: ng.ILocaleService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) { }

        public $onInit(): void {
            this.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
        }

        public $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj.carePlanAction) {
                if (this.carePlanAction.id != null) {
                    this.loadDataAsync();
                    this.setInfoMessageAsync(this.carePlanAction.id);
                    this.dateInfo = {
                        date: this.carePlanAction.plannedDateTimeFrom
                            ? new Date(this.carePlanAction.plannedDateTimeFrom.valueOf())
                            : null,
                        from: this.carePlanAction.plannedDateTimeFrom
                            ? new Date(this.carePlanAction.plannedDateTimeFrom.valueOf())
                            : null,
                        until: this.carePlanAction.plannedDateTimeUntil
                            ? new Date(this.carePlanAction.plannedDateTimeUntil.valueOf())
                            : null,
                    };
                    this.partyRole = angular.copy(this.carePlanAction.partyRole);
                    this.includesTimeNoWindow = this.carePlanAction.includesEventTime;
                    if (this.carePlanAction.isEventDateTimeRange) {
                        this.includesTimeWindow = true;
                        this.includesTimeNoWindow = false;
                    }
                    this.possibleActionDurationOptionId = this.carePlanAction.possibleActionDurationOptionId;

                    this.durationOfAction = this.carePlanAction.durationOfAction;
                }
            }
            this.showActionDuration = false;
            if (onChangesObj.component) {
                this.hasLocation = this.component.hasLocation;
                if (this.hasLocation === true && this.carePlanAction.location) {
                    this.location = {
                        ContactPointUsageId: this.carePlanAction.location.ContactPointUsageId,
                        Street: this.carePlanAction.location.Street,
                        Name: this.carePlanAction.location.Name,
                        City: this.carePlanAction.location.City,
                        ZipCode: this.carePlanAction.location.ZipCode,
                        Remark: this.carePlanAction.location.Remark,
                    };
                }
                this.hasExecutor = this.component.hasExecutor;
                this.hasActionDurationOption = this.component.hasActionDurationOption;
                this.possibleActionDurationOptions = this.component.possibleActionDurationOptions;
                this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(
                    this.hasActionDurationOption,
                    this.durationOfAction,
                    this.possibleActionDurationOptions
                );
            }
            if (onChangesObj.address) {
                this.address = angular.copy(this.address);
            }
        }

        public changeLocation(): void {
            this.modalBuilderFactory
                .createModalBuilder<Contract.Party.Read.IAddress>()
                .setController('locationCtrl')
                .setTemplateUrl('views/carePlanAction/location/locationModal.html')
                .setScope({
                    patientId: this.carePlanAction.patientId,
                })
                .setResultCallBack(r => this.setLocation(r))
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        public dateChanged(): void {
            if (this.dateInfo.from == null) {
                return;
            }
            if (this.dateInfo.until == null) {
                this.dateInfo.until = new Date(this.dateInfo.from.getTime());
            } else {
                this.dateInfo.until.setFullYear(
                    this.dateInfo.from.getFullYear(),
                    this.dateInfo.from.getMonth(),
                    this.dateInfo.from.getDate()
                );
            }
        }

        public async confirmAsync(): Promise<void> {
            this.rescheduleForm.$setSubmitted();
            if (this.rescheduleForm.$invalid) {
                return;
            }

            const from = new Date(this.dateInfo.date.getTime());
            const until = new Date(this.dateInfo.date.getTime());

            // If includesTimeWindow, then the timestamps are set on dateInfo.from and dateInfo.until
            // Otherwise date/time are both set on dateInfo.date
            if (this.includesTimeWindow) {
                from.setHours(this.dateInfo.from.getHours());
                from.setMinutes(this.dateInfo.from.getMinutes());
                from.setSeconds(this.dateInfo.from.getSeconds());

                until.setHours(this.dateInfo.until.getHours());
                until.setMinutes(this.dateInfo.until.getMinutes());
                until.setSeconds(this.dateInfo.until.getSeconds());
            }

            this.filterLocationProperties();

            const params: Contract.CarePlanAction.Write.ISchedule = {
                from: Shared.DateHelper.toServerDateTimeString(from),
                until: Shared.DateHelper.toServerDateTimeString(until),
                actor: this.partyRole != null && this.hasExecutor ? this.partyRole.Id : null,
                location: this.hasLocation ? this.location : null,
                type: Shared.Contract.Code.ActionType.CareAct,
                reason: this.reason,
                durationOfAction: Shared.DateHelper.toServerTimeString(this.durationOfAction),
                possibleActionDurationOptionId: this.possibleActionDurationOptionId,
            };

            try {
                await this.carePlanActionApiSvc.rescheduleAsync(this.carePlanAction.carePlanActionId, params);
                this.toaster.success(this.$translate.instant('Views.Action.CareAct.RescheduleSuccess'));
                this.carePlanAction.plannedDateTimeFrom = from;
                this.carePlanAction.plannedDateTimeUntil = until;
                this.carePlanAction.eventDateFrom = from;
                this.carePlanAction.eventDateUntil = until;
                this.carePlanAction.eventTimeFrom = from;
                this.carePlanAction.eventTimeUntil = until;
                this.carePlanAction.partyRole = this.partyRole;
                this.carePlanAction.location = this.location;
                this.carePlanAction.durationOfAction = this.durationOfAction;
                this.carePlanAction.possibleActionDurationOptionId = this.possibleActionDurationOptionId;
                this.$close({ result: { address: this.address, carePlanAction: this.carePlanAction } });
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private setLocation(address: Contract.Party.Read.IAddress): void {
            const idLocation: Contract.CarePlanAction.Read.ILocation = {
                ContactPointUsageId: address.Id,
            };
            const customLocation: Contract.CarePlanAction.Read.ILocation = {
                Name: address.AddressLine2,
                Street: address.AddressLine1,
                ZipCode: address.ZipCode,
                City: address.City,
                Remark: address.Remark,
            };
            this.address = customLocation;
            this.location = address.Id != null ? idLocation : customLocation;
        }

        private filterLocationProperties() {
            if (this.location == null) {
                this.location = {
                    ContactPointUsageId: null,
                    Street: null,
                    Name: null,
                    City: null,
                    ZipCode: null,
                    Remark: null,
                };
            }
            const idLocation: Contract.CarePlanAction.Read.ILocation = {
                ContactPointUsageId: this.location.ContactPointUsageId,
            };
            const customLocation: Contract.CarePlanAction.Read.ILocation = {
                Name: this.location.Name,
                Street: this.location.Street,
                ZipCode: this.location.ZipCode,
                City: this.location.City,
                Remark: this.location.Remark,
            };
            this.location = this.location.ContactPointUsageId != null ? idLocation : customLocation;
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.possibleActors = await this.carePlanApiSvc.findAssignablePartiesAsync(
                    this.carePlanAction.patientId,
                    this.carePlanAction.actionTemplateId
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async setInfoMessageAsync(actionId: Shared.Contract.Guid): Promise<void> {
            try {
                const result = await this.carePlanActionApiSvc.getCarePlanActionConfigurationInformationAsync(actionId);
                if (result.HasPeriodicity && result.CanPersonalisePeriodicity) {
                    this.infoMessage = 'Views.Action.CareAct.Reschedule.InfoCanPersonalisePeriodicity';
                } else if (result.HasPeriodicity) {
                    this.infoMessage = 'Views.Action.CareAct.Reschedule.InfoNoPersonalisePeriodicity';
                } else {
                    this.infoMessage = '';
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareCarePlanActionModule.component('rescheduleActionCtrl', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            address: '<',
            carePlanAction: '<',
            component: '<',
        },
        controller: RescheduleActionController,
        templateUrl: 'views/carePlanAction/directive/rescheduleAction.html',
    });
}
