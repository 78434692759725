var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var TherapyApiService = /** @class */ (function (_super) {
                __extends(TherapyApiService, _super);
                function TherapyApiService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                /** @deprecated */
                TherapyApiService.prototype.getCareRequestTemplate = function (therapyId, careRequestId) {
                    return this.get("Therapies/" + therapyId + "/CareRequestTemplate", {
                        careRequestId: careRequestId,
                    }, 'carerequest');
                };
                TherapyApiService.prototype.getCareRequestTemplateAsync = function (therapyId, careRequestId) {
                    return this.getPromise("Therapies/" + therapyId + "/CareRequestTemplate", {
                        careRequestId: careRequestId,
                    }, 'carerequest');
                };
                TherapyApiService.prototype.getTherapiesAsync = function () {
                    return this.getPromise('Therapies', null, 'therapies');
                };
                TherapyApiService.prototype.getTherapyDiseasesAsync = function (therapyId, diseaseType) {
                    return this.getPromise("Therapies/" + therapyId + "/Diseases", {
                        DiseaseType: diseaseType,
                    });
                };
                TherapyApiService.prototype.getHospitalisationReasons = function (therapyId) {
                    return _super.prototype.get.call(this, 'Therapies/' + therapyId + '/HospitalisationReasons');
                };
                TherapyApiService.prototype.getPossiblePartiesAsync = function (therapyId, actorRoleId) {
                    return this.getPromise("Therapies/" + therapyId + "/ActorRoles/" + actorRoleId + "/PossibleParties");
                };
                TherapyApiService.prototype.getMedicationActions = function (therapyId, careRequestId, medicationActionId) {
                    var params = {
                        careRequestId: careRequestId,
                        medicationActionId: medicationActionId,
                    };
                    return this.getPromise("Therapies/" + therapyId + "/MedicationActions", params);
                };
                TherapyApiService.prototype.getCareRequestPartAsync = function (therapyId, careRequestPartId) {
                    return this.getPromise("Therapies/" + therapyId + "/CareRequestConfiguration/CareRequestPart/" + careRequestPartId);
                };
                TherapyApiService.prototype.getObjectiveAsync = function (therapyId, objectiveId) {
                    return this.getPromise("TherapyConfiguration/" + therapyId + "/Objectives/" + objectiveId);
                };
                TherapyApiService.prototype.getRuleThreshold = function (therapyId, ruleThresholdId) {
                    return this.get("TherapyConfiguration/" + therapyId + "/RuleThresholds/" + ruleThresholdId);
                };
                TherapyApiService.prototype.getStructuralTherapyActions = function (therapyId) {
                    return this.get("TherapyConfiguration/" + therapyId + "/StructuralTherapyActions");
                };
                TherapyApiService.prototype.getCarePlanStatusReasons = function (therapyId, careplanstatustype) {
                    var params = { carePlanStatus: careplanstatustype };
                    return this.get("Therapies/" + therapyId + "/CarePlanStatusReasons", params);
                };
                TherapyApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
                return TherapyApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.TherapyApiService = TherapyApiService;
            RemeCare.remeCareAppModule.service('therapyApiSvc', TherapyApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
