/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import ReferenceParameterObjective = Shared.Framework.Model.ReferenceParameterObjective;
    import ObjectiveDefinition = Shared.Contract.Read.IObjectiveDefinition;
    import IObjectiveScoreDetail = Contract.CarePlan.Read.IObjectiveScoreDetail;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    class ObjectiveScoreTableController extends MonitoringPartControllerBase<
        Shared.Framework.Model.MonitoringPartTable
    > {
        public grid: Shared.Framework.Grid.Grid<IObjectiveScoreDetail>;
        public objectivePeriodUnit: EnumTranslation;
        private referenceParameterObjective: ReferenceParameterObjective;

        // @ngInject
        constructor(
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super(dateHelper, spinnerSvc);
        }

        public hasData(): boolean {
            return this.grid && this.grid.hasData();
        }

        protected async init(): Promise<void> {
            this.referenceParameterObjective = _.find(
                this.monitoringPart.monitoringPartSources,
                (mps) => mps.type === Shared.Contract.Code.MonitoringPartSourceType.ReferenceParameterObjective
            ) as ReferenceParameterObjective;

            try {
                const [objective, units]: [
                    Shared.Contract.Read.IObjectiveDefinition,
                    EnumTranslation[]
                ] = await Promise.all([
                    this.carePlanApiSvc.getObjectiveAsync(
                        this.carePlanIds[0],
                        this.referenceParameterObjective.objective.Id
                    ),
                    this.masterdataSvc.getDurationUnitsAsync(),
                ]);
                this.objectivePeriodUnit = _.find(units, (u) => u.Id === objective.Period.Unit);
                this.buildGrid(objective);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        protected async onDateChange(): Promise<void> {
            this.grid.pagingOptions.currentPage = 1;
            await this.grid.search();
        }

        private buildGrid(objective: ObjectiveDefinition): void {
            const totalHeader =
                `${objective.Characteristic.Text} - ${this.$translate.instant('General.Total')} (${
                    objective.Type.Text
                }) ` +
                `${this.$translate.instant('General.Per')} ${objective.Period.Quantity} ${
                    this.objectivePeriodUnit.Text
                }`;
            const scoreHeader = `${objective.Name} - ${this.$translate.instant('General.Score')}`;
            this.grid = this.gridBuilderSvc
                .createGridBuilder<IObjectiveScoreDetail>((page, pageSize) => this.executeSearch(page, pageSize))
                .addDateColumn('UntilDate', 'General.Date', 'shortDate')
                .addColumnWithTooltipHeader('PeriodicTotalValue', totalHeader, { cellFilter: 'numberEx : 2' })
                .addColumnWithTooltipHeader('ScorePercentage', scoreHeader, { cellFilter: 'numberEx : 2 | percentage' })
                .build();
            this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
        }

        private executeSearch(
            page: number,
            pageSize: number
        ): Promise<Shared.Contract.ISearchResult<IObjectiveScoreDetail>> {
            return new Promise<Shared.Contract.ISearchResult<IObjectiveScoreDetail>>((resolve, reject) => {
                this.carePlanApiSvc
                    .getObjectiveScoreAsync(
                        this.carePlanIds[0],
                        this.referenceParameterObjective.objective.Id,
                        this.dateInfo.fromDate,
                        this.dateInfo.untilDate,
                        page,
                        pageSize
                    )
                    .then((r) => {
                        resolve(r);
                    })
                    .catch((e) => {
                        this.toaster.error(e);
                        reject();
                    });
            });
        }
    }

    class ObjectiveScoreTableComponent extends MonitoringPartComponentBase {
        public controller = ObjectiveScoreTableController;

        public templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
    }

    remeCarePatientModule.component('rcMonitoringObjectiveScoreTable', new ObjectiveScoreTableComponent());
}
