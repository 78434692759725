namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import StructuralTherapyAction = Contract.Therapy.Read.IStructuralTherapyActionSummary;

    interface IExtendedStructuralTherapyAction extends StructuralTherapyAction {
        IsPersonalised: boolean;
        IsRemovedFromCarePlan: boolean;
        IsPeriodicitiesPersonalised: boolean;
        IsIntradayTimingPersonalised: boolean;
        HasPersonalisedActionParts: boolean;
        IsDeviationPlanningPersonalised: boolean;
    }

    interface IPatientFileCarePlanActionsScope extends Shared.Framework.IBaseScope {
        carePlan: CarePlan;
        patientId: Guid;
        therapyActions: IExtendedStructuralTherapyAction[];
        actionsGrid: Shared.Framework.Grid.Grid<StructuralTherapyAction>;
        editRight: boolean;
    }

    class PatientFileCarePlanActionsController extends Shared.Framework.ControllerBase<
        IPatientFileCarePlanActionsScope
    > {
        constructor(
            protected $scope: IPatientFileCarePlanActionsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            if (this.$scope.carePlan) {
                this.getPersonalisableStructuralTherapyActions();
            }

            this.buildActionsGrid();

            this.$scope.$watch('carePlan', (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== null) {
                    this.getPersonalisableStructuralTherapyActions();
                }
            });
        }

        private async updateIfPersonalisedList(stas: IExtendedStructuralTherapyAction[]): Promise<void> {
            await Promise.all(_(stas).map(sta => this.updateIfPersonalised(sta)));
        }

        private async updateIfPersonalised(sta: IExtendedStructuralTherapyAction): Promise<void> {
            try {
                const pta = await this.carePlanApiSvc.getPersonalisedActionAsync(this.$scope.carePlan.id, sta.Id);
                const nowOrFuture = _.filter(pta.ConfiguredTherapyActions, cta => {
                    if (!cta.ValidUntilDate) {
                        // Valid until infinity
                        return true;
                    }

                    const until = Shared.DateHelper.serverDateStringToDate(cta.ValidUntilDate);
                    return moment(Shared.DateHelper.today()).isSameOrBefore(until);
                });

                sta.IsPersonalised = _(nowOrFuture).any(cta => cta.IsPersonalised);
                sta.IsRemovedFromCarePlan = _(nowOrFuture).any(cta => cta.IsRemovedFromCarePlan);
                sta.IsPeriodicitiesPersonalised = _(nowOrFuture).any(cta => cta.IsPeriodicitiesPersonalised);
                sta.IsIntradayTimingPersonalised = _(nowOrFuture).any(cta => cta.IsIntradayTimingPersonalised);
                sta.HasPersonalisedActionParts = _(nowOrFuture).any(cta => cta.HasPersonalisedActionParts);
                sta.IsDeviationPlanningPersonalised = _(nowOrFuture).any(
                    cta =>
                        (cta.DurationOfAction != null || cta.PossibleActionDurationOptionId != null) &&
                        cta.IsPersonalised
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getPersonalisableStructuralTherapyActions(): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getPersonalisableStructuralTherapyActionsAsync(
                    this.$scope.carePlan.id
                );
                this.$scope.therapyActions = _(ags).map(sta => sta as IExtendedStructuralTherapyAction);

                // Don't await this, it can be updated in the background
                this.updateIfPersonalisedList(this.$scope.therapyActions);
                this.setActionsGridData();
            } catch (e) {
                this.errorCallback(e);
            }
            this.$scope.editRight = this.$scope.carePlan.statusOpen;
        }

        private buildActionsGrid(): void {
            const template = `<div ng-if= "row.entity.IsDeviationPlanningPersonalised" class="text-center vertical-center"> <span class="fa-stack fa-sm" > <i class="fa fa-calendar-o fa-stack-2x" style="font-size:17px; margin-top:4px;"></i> <i class="fa fa-clock-o fa-stack-1x" style="font-size:12px; margin:4px"></i></span></div>`;

            this.$scope.actionsGrid = this.gridBuilderSvc
                .createGridBuilder<IExtendedStructuralTherapyAction>()
                .addColumn('StructuralTherapyActionName', 'General.Name')
                .addIconColumn(
                    'Views.PatientFile.CarePlans.PersonalisedExcluded',
                    cta => (cta.IsRemovedFromCarePlan ? 'eye-slash' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addIconColumn(
                    'Views.PatientFile.CarePlans.PeriodicityPersonalised',
                    cta => (cta.IsPeriodicitiesPersonalised ? 'calendar' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addIconColumn(
                    'Views.PatientFile.CarePlans.IntradayTimingPersonalised',
                    cta => (cta.IsIntradayTimingPersonalised ? 'clock-o' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addCustomColumn('Views.PatientFile.CarePlans.Planning', template, () => {}, { width: '100' })
                .addIconColumn(
                    'Views.PatientFile.CarePlans.HasPersonalisedActionParts',
                    cta => (cta.HasPersonalisedActionParts ? 'th-large' : ''),
                    null,
                    RemeCare.Shared.Framework.Grid.IconSize.small
                )
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    v => this.editAction(v),
                    v => this.editAction(v),
                    v => v.ReadOnly || !this.$scope.editRight
                )
                .setSorting(false)
                .build();

            this.setActionsGridData();
        }

        private setActionsGridData(): void {
            this.$scope.actionsGrid.setData(this.$scope.therapyActions);
        }

        private editAction(action: IExtendedStructuralTherapyAction): Promise<IExtendedStructuralTherapyAction> {
            return new Promise(resolve => {
                this.modalBuilderFactory
                    .createModalBuilder<StructuralTherapyAction>()
                    .setController('personalisedActionController')
                    .setTemplateUrl('views/patient/carePlans/carePlanActions/personalisedAction.html')
                    .setSize(Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                        carePlanId: this.$scope.carePlan.id,
                        carePlanStartDate: this.$scope.carePlan.startDate,
                        structuralTherapyAction: action,
                        readOnly: action.ReadOnly || !this.$scope.editRight,
                    })
                    .setResultCallBack(async () => {
                        await this.updateIfPersonalised(action);
                        resolve(action);
                    })
                    .build();
            });
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller('PatientFileCarePlanActionsCtrl', PatientFileCarePlanActionsController);
}
