namespace RemeCare.Patient {
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    interface IAddCarePlanTherapyOptionModalController
        extends Shared.Framework.IBaseScope,
            ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;

        carePlanId: Shared.Contract.Guid;
        definition: EntityTranslation;
        carePlanTherapyOption: Patient.Model.CarePlanTherapyOption;
        minDate: Date;
        dateEditable: boolean;

        confirm(): void;
    }

    class AddCarePlanTherapyOptionModalController extends Shared.Framework.ControllerBase<
        IAddCarePlanTherapyOptionModalController
    > {
        constructor(
            protected $scope: IAddCarePlanTherapyOptionModalController,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirmAsync();
        }

        public $onInit(): void {
            this.$scope.dateEditable = !moment().isAfter(this.$scope.carePlanTherapyOption.validFromDate);
            if (moment(this.$scope.minDate).isBefore(Shared.DateHelper.today())) {
                this.$scope.minDate = moment(Shared.DateHelper.today())
                    .add('day', 1)
                    .toDate();
            }
            if (!this.$scope.dateEditable) {
                this.$scope.minDate = null;
            }
        }

        private async confirmAsync(): Promise<void> {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.carePlanTherapyOption.validUntilDate = null;
            this.$scope.carePlanTherapyOption.carePlanId = this.$scope.carePlanId;
            this.$scope.carePlanTherapyOption.definition = this.$scope.definition;
            try {
                await this.carePlanApiSvc.saveCarePlanTherapyOptionAsync(
                    this.$scope.carePlanId,
                    this.$scope.definition.Id,
                    this.$scope.carePlanTherapyOption.toServerWrite()
                );
                this.$scope.$close(this.$scope.carePlanTherapyOption);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.controller('addCarePlanTherapyOptionModalCtrl', AddCarePlanTherapyOptionModalController);
}
