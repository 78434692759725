var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Security;
        (function (Security) {
            var Codes;
            (function (Codes) {
                var ActivationType;
                (function (ActivationType) {
                    ActivationType[ActivationType["Activation"] = 0] = "Activation";
                    ActivationType[ActivationType["NewPassword"] = 1] = "NewPassword";
                })(ActivationType = Codes.ActivationType || (Codes.ActivationType = {}));
            })(Codes = Security.Codes || (Security.Codes = {}));
        })(Security = Contract.Security || (Contract.Security = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
