/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var InverserObjectiveHelper = /** @class */ (function () {
            function InverserObjectiveHelper(name, parameters) {
                this.name = name;
                this.parameters = parameters;
            }
            InverserObjectiveHelper.prototype.getValue = function (index) {
                return this.parameters[index];
            };
            return InverserObjectiveHelper;
        }());
        var ObjectiveScoreTableInvertedController = /** @class */ (function (_super) {
            __extends(ObjectiveScoreTableInvertedController, _super);
            // @ngInject
            function ObjectiveScoreTableInvertedController(dateHelper, spinnerSvc, toaster, $translate, $filter, carePlanApiSvc, masterdataSvc, gridBuilderSvc) {
                var _this = _super.call(this, dateHelper, spinnerSvc) || this;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.$translate = $translate;
                _this.$filter = $filter;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                return _this;
            }
            ObjectiveScoreTableInvertedController.prototype.hasData = function () {
                return this.grid && this.grid.hasData();
            };
            ObjectiveScoreTableInvertedController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, objective_1, units, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.referenceParameterObjective = _.find(this.monitoringPart.monitoringPartSources, function (mps) { return mps.type === RemeCare.Shared.Contract.Code.MonitoringPartSourceType.ReferenceParameterObjective; });
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, Promise.all([
                                        this.carePlanApiSvc.getObjectiveAsync(this.carePlanIds[0], this.referenceParameterObjective.objective.Id),
                                        this.masterdataSvc.getDurationUnitsAsync(),
                                    ])];
                            case 2:
                                _a = _b.sent(), objective_1 = _a[0], units = _a[1];
                                this.objectivePeriodUnit = _.find(units, function (u) { return u.Id === objective_1.Period.Unit; });
                                this.totalHeader =
                                    objective_1.Characteristic.Text + " - " + this.$translate.instant('General.Total') + " (" + objective_1.Type.Text + ") " +
                                        (this.$translate.instant('General.Per') + " " + objective_1.Period.Quantity + " " + this.objectivePeriodUnit.Text);
                                this.scoreHeader = objective_1.Name + " - " + this.$translate.instant('General.Score');
                                this.buildInverseGrid();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                throw e_1;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            ObjectiveScoreTableInvertedController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.grid.pagingOptions.currentPage = 1;
                                return [4 /*yield*/, this.grid.search()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            ObjectiveScoreTableInvertedController.prototype.buildInverseGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize) { return _this.executeSearch(page, pageSize); })
                    .addColumn('name', '');
                // Prepare all possible columns. We will set their header name and visibility in executeSearch based on the data
                for (var i = 0; i < this.monitoringPart.maxRows; i++) {
                    var customTemplate = '<div class="ui-grid-cell-contents" bs-tooltip data-container="body" ' +
                        " data-delay=\"500,100\" " +
                        ("\"> {{ row.entity.getValue(" + i + ") }} </div>");
                    gridBuilder = gridBuilder.addCustomColumn('', customTemplate, null, null, true, "date : 'shortDate'");
                }
                this.grid = gridBuilder.setSorting(false).build();
                this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
            };
            ObjectiveScoreTableInvertedController.prototype.executeSearch = function (page, pageSize) {
                var _this = this;
                return new Promise(function (resolve, reject) {
                    _this.carePlanApiSvc
                        .getObjectiveScoreAsync(_this.carePlanIds[0], _this.referenceParameterObjective.objective.Id, _this.dateInfo.fromDate, _this.dateInfo.untilDate, page, pageSize)
                        .then(function (r) {
                        var row1Data = _(r.Items).map(function (o) { return _this.$filter('numberEx')(o.PeriodicTotalValue, 2); });
                        var row2Data = _(r.Items).map(function (o) {
                            return _this.$filter('percentage')(_this.$filter('numberEx')(o.ScorePercentage, 2));
                        });
                        var results = [];
                        results.push(new InverserObjectiveHelper(_this.totalHeader, row1Data));
                        results.push(new InverserObjectiveHelper(_this.scoreHeader, row2Data));
                        var result = {
                            Items: r.Items.length ? results : [],
                            Total: r.Total,
                        };
                        for (var i = 0; i < _this.monitoringPart.maxRows; i++) {
                            var element = r.Items[i];
                            // First column is parameter name
                            var columnIndex = i + 1;
                            if (!element) {
                                _this.grid.setColumnVisibility(columnIndex, false, false);
                            }
                            else {
                                _this.grid.setColumnVisibility(columnIndex, true, false);
                                _this.grid.changeColumnName(columnIndex, RemeCare.Shared.DateHelper.serverDateStringToDateTime(element.UntilDate), false);
                            }
                        }
                        _this.grid.notifyColumnsChanged();
                        resolve(result);
                    })
                        .catch(function (e) {
                        _this.toaster.error(e);
                        reject();
                    });
                });
            };
            return ObjectiveScoreTableInvertedController;
        }(Patient.MonitoringPartControllerBase));
        var ObjectiveScoreTableInvertedComponent = /** @class */ (function (_super) {
            __extends(ObjectiveScoreTableInvertedComponent, _super);
            function ObjectiveScoreTableInvertedComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = ObjectiveScoreTableInvertedController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
                return _this;
            }
            return ObjectiveScoreTableInvertedComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringObjectiveScoreTableInverted', new ObjectiveScoreTableInvertedComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
