module RemeCare.Shared.Component {

    import Nationality = Shared.Contract.Code.Nationality;
    import NationalityIso2 = Shared.Contract.Code.NationalityIso2;

    class InputNationalNumberController implements ng.IComponentController {

        // bindings
        public changed: () => void;
        public infoMessage: string;
        public label: string;
        public nationality: number | string;
        public validate: boolean;

        constructor(
            private readonly $timeout: ng.ITimeoutService,
            private readonly nationalNumberSvc: NationalNumberService
        ) { }

        public $onInit(): void {
            this.infoMessage = this.infoMessage || 'General.NationalNumber.InfoMessage';
            this.label = this.label || 'General.NationalNumber';
            this.validate = !(this.validate === false);
        }

        public onChange(): void {
            this.$timeout(() => {
                this.changed && this.changed();
            });
        }

        public validateNationalNumber(value: string): boolean {
            return !this.validate
                || !value
                || (this.nationality !== NationalityIso2.Belgian && this.nationality !== Nationality.Belgian)
                || this.nationalNumberSvc.isValidNationalNumber(value);
        }
    }

    remeCareSharedModule.component('rcInputNationalNumber',
        {
            bindings: {
                changed: '&?ngChange',
                disabled: '<?ngDisabled',
                infoMessage: '@',
                model: '=ngModel',
                label: '@',
                nationality: '<?',
                required: '<?ngRequired',
                validate: '<?'
            },
            controller: InputNationalNumberController,
            require: {
                formCtrl: '^form',
            },
            templateUrl: 'shared/components/inputNationalNumber/inputNationalNumber.html',
        });
}