var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var Group = /** @class */ (function () {
            function Group(serverObject) {
                if (serverObject) {
                    this.id = serverObject.Id;
                    this.name = serverObject.Name;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    this.organisations = _(serverObject.Organisations)
                        .map(function (o) { return new Management.GroupAssignment(o); });
                    this.persons = _(serverObject.Persons)
                        .map(function (p) { return new Management.GroupAssignment(p); });
                }
                else {
                    this.organisations = [];
                    this.persons = [];
                }
            }
            Group.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    name: this.name,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                    persons: _(this.persons).map(function (p) { return p.toServerWrite(); }),
                    organisations: _(this.organisations).map(function (o) { return o.toServerWrite(); })
                };
            };
            return Group;
        }());
        Management.Group = Group;
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
