module RemeCare.Model {
    import Guid = Shared.Contract.Guid;

    export class Organisation {
        id: Guid
        name: string;
        addresses: Array<Address> = [];
        telephoneNumbers: Array<TelephoneNumber> = [];
        mobileNumbers: Array<TelephoneNumber> = [];
        faxNumbers: Array<TelephoneNumber> = [];
        emailAddresses: Array<Email> = [];
        partyRoles: Array<Contract.Party.Read.IPartyRole> = [];

        constructor(def?: Contract.Party.Read.IOrganisationDetail) {
            if (def != null) {
                this.id = def.Id;
                this.name = def.Name;
                this.addresses = _(def.Addresses).map(a => new Address(a));
                this.telephoneNumbers = _(def.TelephoneNumbers).map(t => new TelephoneNumber(t));
                this.mobileNumbers = _(def.MobileNumbers).map(m => new TelephoneNumber(m));
                this.faxNumbers = _(def.FaxNumbers).map(f => new TelephoneNumber(f));
                this.emailAddresses = _(def.EmailAddresses).map(e => new Email(e));
                this.partyRoles = def.PartyRoles || [];
            }
        }

        getAllNumbers(): Array<TelephoneNumber> {
            return _.union(this.telephoneNumbers, this.mobileNumbers, this.faxNumbers);
        }

        getHealthCareOrganisationPartyRole(): Contract.Party.Read.IHealthCareOrganisation {
            return <Contract.Party.Read.IHealthCareOrganisation>_(this.partyRoles)
                .find(pr => pr.PartyRoleType.Id == Shared.Contract.Code.PartyRoleType.HealthCareOrganisation);
        }
    }
}