module RemeCare.CareRequest.Directive {
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;
    import ModalityType = Shared.Contract.Code.ModalityType;
    import TypeAndSpecialty = Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;
    import CareRequestPartType = Shared.Contract.Code.CareRequestPartType;

    export interface ICareRequestPartBaseScope extends Shared.Framework.IBaseScope {
        careRequestPart: Shared.Framework.Model.CareRequestPart;
        careRequest: CareRequest;
        stepType: Shared.Contract.Code.CareRequestStepType;
        careRequestTemplate: CareRequestTemplate;
        readOnly: boolean;

        isPresent(t: CareRequestUIControlType): boolean;
        isRequired(t: CareRequestUIControlType): boolean;
    }

    export abstract class CareRequestPartControllerBase<T extends ICareRequestPartBaseScope> extends Shared.Framework
        .ControllerBase<T> {
        constructor(
            protected $scope: T,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster);
            $scope.isPresent = t => this.isPresent(t);
            $scope.isRequired = t => this.isRequired(t);
        }

        protected convertToTypeAndSpecialties(
            inclusions: _Chain<Shared.Framework.Model.ActorRoleInclusionDetail>
        ): Array<TypeAndSpecialty> {
            return inclusions
                .filter(i => i.healthCareProfessionalTypeId != null)
                .map(
                    i =>
                        <TypeAndSpecialty>{
                            healthCareProfessionalTypeId: i.healthCareProfessionalTypeId,
                            healthCareSpecialtyProfessionId: i.healthCareSpecialtyProfessionId
                        }
                )
                .value();
        }

        protected isPresent(controlType: CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id !== ModalityType.Not;
            }
        }

        protected isRequired(
            controlType: CareRequestUIControlType,
            part?: Shared.Framework.Model.CareRequestPart
        ): boolean {
            part = part || this.$scope.careRequestPart;
            if (part == null) {
                return false;
            }

            if(this.$scope.careRequestTemplate !== undefined && this.$scope.careRequest !== undefined) {
                if(this.careRequestSvc.isVisibleWithConditionality(part, this.$scope.careRequestTemplate, this.$scope.careRequest) === false) {
                    return false;
                }
            }
            
            var control = _.find(part.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id === ModalityType.Mandatory;
            }
        }

        protected removeDeletedDiseasesFromAnswerConditions(
            diseases: Shared.Contract.Read.ITherapyDiseaseReadModel[], 
            answerConditions: Shared.Framework.Model.AnswerConditionDefinition[]
        ): void {
            answerConditions.forEach(function(ac, index){
                const diseaseIsPresent = diseases.filter(d => d.TherapyDiseaseId === ac.AnswerTherapyDiseaseId).length > 0;
                if(!diseaseIsPresent) {
                    answerConditions.splice(index, 1);
                }
            });
            
            this.$scope.careRequestPart.answerConditions = answerConditions;
        }
    }

    export class CareRequestPartDirectiveBase implements ng.IDirective {
        restrict = 'E';

        scope: { [boundProperty: string]: string } = {
            careRequestTemplate: '=',
            careRequestPart: '=',
            careRequest: '=',
            showErrors: '=',
            stepType: '=',
            readOnly: '=',
            onMatchedPatientChanged: '&',
            validateNationalNumber: '='
        };
    }
}
