'use strict';

module RemeCare.Contract.CarePlanAction.Read {
    import Guid = RemeCare.Shared.Contract.Guid;
    import Document = RemeCare.Shared.Contract.IDocument;

    export interface IExternalReport {
        CarePlanActionId: Guid;
        Report: string;
        Documents: Document[];
        Editable: boolean;
        PhoneCall: boolean;
    }
}