module RemeCare.Management {
    class RelatedGroupsComponent implements ng.IComponentController {
        public person: Model.Person;
        public groups: Shared.Contract.IEntityTranslation[];

        constructor(private readonly partyApiSvc: Core.Services.PartyApiService) {}

        public $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['person']) {
                this.getRelatedGroupsAsync();
            }
        }

        private async getRelatedGroupsAsync(): Promise<void> {
            if (this.person) {
                this.groups = await this.partyApiSvc.getRelatedGroupsAsync(this.person.partyId);
            }
        }
    }

    remeCareManagementModule.component('rcRelatedGroups', {
        controller: RelatedGroupsComponent,
        bindings: {
            person: '<'
        },
        templateUrl: 'views/management/persons/relatedGroups.html'
    });
}
