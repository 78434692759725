/// <reference path="../../contract/core/codes/genderCode.ts"/>
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var GenderCode = RemeCare.Contract.Core.Codes.GenderCode;
        var CountryCode = RemeCare.Shared.Contract.Code.Country;
        var NationalityCode = RemeCare.Shared.Contract.Code.Nationality;
        var CountryIso2 = RemeCare.Shared.Contract.Code.CountryIso2;
        var NationalityIso2 = RemeCare.Shared.Contract.Code.NationalityIso2;
        var PhoneNumber = RemeCare.Model.PhoneNumber;
        var PatientFileForExternal = /** @class */ (function () {
            function PatientFileForExternal(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    var context = JSON.parse(serverObject.ParametersJson);
                    if (context.Patient) {
                        this.patientParameters = context.Patient;
                        this.responsibleHealthCareProfessional = context.ResponsibleHealthCareProfessional;
                        this.externalReferences = context.ExternalReferences || [];
                    }
                    else {
                        // Fallback to old logic
                        this.patientParameters = JSON.parse(serverObject.ParametersJson);
                        this.externalReferences = [];
                    }
                    this.patientParameters.ExternalReferences = this.patientParameters.ExternalReferences || [];
                    this.patientParameters.PreferredLanguage = this.patientParameters.PreferredLanguage
                        ? this.patientParameters.PreferredLanguage.toLowerCase()
                        : null;
                    this.patientParameters.NumericGenderCode = this.mapGender(this.patientParameters.Gender);
                    this.patientParameters.NumericNationalityCode = this.mapNationality(this.patientParameters.Nationality);
                    this.patientParameters.NumericCountryCode = this.mapCountry(this.patientParameters.Country);
                    try {
                        var d = moment(this.patientParameters.Birthdate); // Passing null here will result in a valid moment object
                        this.patientParameters.BirthdateDate =
                            this.patientParameters.Birthdate && d.isValid() ? d.toDate() : null;
                    }
                    catch (error) {
                        this.patientParameters.BirthdateDate = null;
                    }
                    try {
                        this.patientParameters.PhoneParsed = RemeCare.Model.PhoneNumber.parse(this.patientParameters.Phone);
                    }
                    catch (error) {
                        this.patientParameters.PhoneParsed = new PhoneNumber();
                    }
                    try {
                        this.patientParameters.GsmParsed = RemeCare.Model.PhoneNumber.parse(this.patientParameters.Gsm);
                    }
                    catch (error) {
                        this.patientParameters.GsmParsed = new PhoneNumber();
                    }
                }
            }
            PatientFileForExternal.prototype.mapGender = function (gender) {
                if (!gender) {
                    return null;
                }
                switch (gender.toUpperCase()) {
                    case 'M':
                        return GenderCode.Male;
                    case 'F':
                        return GenderCode.Female;
                    default:
                        return null;
                }
            };
            PatientFileForExternal.prototype.mapNationality = function (source) {
                if (!source) {
                    return null;
                }
                var iso = source.toUpperCase();
                try {
                    return NationalityCode[_.invert(NationalityIso2)[iso]];
                }
                catch (error) {
                    return null;
                }
            };
            PatientFileForExternal.prototype.mapCountry = function (source) {
                if (!source) {
                    return null;
                }
                var iso = source.toUpperCase();
                try {
                    return CountryCode[_.invert(CountryIso2)[iso]];
                }
                catch (error) {
                    return null;
                }
            };
            return PatientFileForExternal;
        }());
        CareRequest.PatientFileForExternal = PatientFileForExternal;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
