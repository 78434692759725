namespace RemeCare.Framework {
    export class Helpers {
        public static calculateShowActionDuration(
            hasActionDurationOption: boolean,
            defaultDurationOfAction: Date,
            possibleActionDurationOptions: object[]
        ): boolean {
            // Check if the dropdown should be used or not, if not check if a time is set
            if (!hasActionDurationOption) {
                // Check if durationOfAction exists
                if (
                    defaultDurationOfAction != null &&
                    Shared.DateHelper.timeHumanReadible(defaultDurationOfAction) !== '00:00'
                ) {
                    return true;
                }

                return false;
            } else {
                if (possibleActionDurationOptions != null && possibleActionDurationOptions.length > 0) {
                    return true;
                }
            }

            return false;
        }
    }
}
