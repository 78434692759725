var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var PatchPersonInfo = /** @class */ (function () {
            function PatchPersonInfo() {
            }
            return PatchPersonInfo;
        }());
        CareRequest.PatchPersonInfo = PatchPersonInfo;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
