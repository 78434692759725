var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var RequestInformationObjectController = /** @class */ (function (_super) {
            __extends(RequestInformationObjectController, _super);
            // @ngInject
            function RequestInformationObjectController($scope, $filter, modalBuilderFactory, documentSvc, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$scope = $scope;
                _this.$filter = $filter;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.documentSvc = documentSvc;
                _this.$scope.getAdvancedColumnData = function (id) { return _this.getAdvancedColumnData(id); };
                _this.$scope.onDocumentAdded = function (d) { return _this.onDocumentAdded(d); };
                return _this;
            }
            RequestInformationObjectController.prototype.init = function () {
                this.$scope.advancedColumns = this.$scope.component.hasReferenceDate
                    ? ['General.DocumentObservationDate']
                    : [];
            };
            RequestInformationObjectController.prototype.getAdvancedColumnData = function (id) {
                if (!this.$scope.component.hasReferenceDate) {
                    return [];
                }
                var patientFileDocument = _.find(this.$scope.component.value, function (c) { return c.Id === id; });
                if (patientFileDocument == null) {
                    return [''];
                }
                return [
                    this.$filter('date')(RemeCare.Shared.DateHelper.serverDateStringToDate(patientFileDocument.ReferenceDate), 'shortDate'),
                ];
            };
            RequestInformationObjectController.prototype.onDocumentAdded = function (d) {
                return __awaiter(this, void 0, void 0, function () {
                    var date, index, newArray;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!this.$scope.component.hasReferenceDate) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.showReferenceDateModal()];
                            case 1:
                                date = _a.sent();
                                if (date) {
                                    d.ReferenceDate = RemeCare.Shared.DateHelper.toServerDateString(date);
                                }
                                else {
                                    this.documentSvc.deleteDocument(d.Id);
                                    index = this.$scope.component.value.indexOf(d);
                                    newArray = this.$scope.component.value.slice();
                                    newArray.splice(index, 1);
                                    this.$scope.component.value = newArray;
                                }
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                });
            };
            RequestInformationObjectController.prototype.showReferenceDateModal = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        return [2 /*return*/, new Promise(function (resolve) {
                                _this.modalBuilderFactory
                                    .createModalBuilder()
                                    .setController('referenceDateCtrl')
                                    .setTemplateUrl('views/shared/referenceDate.html')
                                    .setResultCallBack(function (r) { return resolve(r); })
                                    .setDismissCallBack(function () { return resolve(null); })
                                    .build();
                            })];
                    });
                });
            };
            return RequestInformationObjectController;
        }(CarePlanAction.ComponentControllerBase));
        var RequestInformationObjectDirective = /** @class */ (function (_super) {
            __extends(RequestInformationObjectDirective, _super);
            function RequestInformationObjectDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/requestInformationObject.html';
                _this.controller = RequestInformationObjectController;
                return _this;
            }
            return RequestInformationObjectDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcRequestInformationObject', function () { return new RequestInformationObjectDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
