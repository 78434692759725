namespace RemeCare.Core.Services {
    import Guid = Shared.Contract.Guid;
    import SearchResult = Shared.Contract.ISearchResult;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    export class PartyApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /** @deprecated */
        public findPersons(
            query: Contract.Party.Read.Query.IFindPersonsQuery
        ): ng.IHttpPromise<SearchResult<Contract.Party.Read.IPerson>> {
            return this.get('Party/PersonsV1', query, 'person.search');
        }

        public findPersonsAsync(
            query: Contract.Party.Read.Query.IFindPersonsQuery
        ): Promise<SearchResult<Contract.Party.Read.IPerson>> {
            return this.postPromise('Party/Persons', query, 'person.search');
        }

        public findGroups(
            query: Contract.Management.Read.Query.IFindGroupsQuery
        ): ng.IHttpPromise<SearchResult<Contract.Management.Read.IGroup>> {
            return this.get('Party/Groups', query, 'group.search');
        }

        public findGroupsAsync(
            query: Contract.Management.Read.Query.IFindGroupsQuery
        ): Promise<SearchResult<Contract.Management.Read.IGroup>> {
            return this.getPromise('Party/Groups', query, 'group.search');
        }

        public getGroupDetailsAsync(id: Shared.Contract.Guid): Promise<Contract.Management.Read.IGroup> {
            return this.getPromise(`Party/Groups/${id}`, null, 'groupDetails');
        }

        public findPersonMergeCandidates(
            query: Contract.Party.Read.Query.IFindPersonMergeCandidatesQuery
        ): ng.IHttpPromise<Contract.Party.Read.IMergeCandidatePerson[]> {
            return this.get('Party/PersonMergeCandidates', query, 'personMergeCandidates.Search');
        }

        public findPartyNamesAsync(partyIds: Guid[]): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise('Party/Names', {
                partyIds: partyIds,
            });
        }

        public getGroupsAsync(ids: Guid[]): Promise<Contract.Management.Read.IGroupAssignment[]> {
            return this.getPromise('Party/Groups/GroupAssignments', { groupIds: ids }, 'groupAssignments');
        }

        public getPersonsAsync(partyRoleIds: Guid[]): Promise<Contract.Party.Read.IPerson[]> {
            return this.postPromise('Party/Persons', {
                page: 1,
                pageSize: partyRoleIds.length,
                partyRoleIds: partyRoleIds,
            } as Contract.Party.Read.Query.IFindPersonsQuery).then((r: any) => r.Items);
        }

        public getPersonAsync(id: Guid): Promise<Contract.Party.Read.IPerson> {
            return this.getPromise(`Party/Persons/${id}`);
        }

        /** @deprecated */
        public getPersonDetails(
            id: Guid,
            includeAllContactPoints: boolean
        ): ng.IHttpPromise<Contract.Party.Read.IPersonDetail> {
            const query = {
                includeAllContactPoints: includeAllContactPoints,
            };
            return this.get(`Party/Persons/${id}/Details`, query, 'personDetails');
        }

        public getPersonDetailsAsync(
            id: Guid,
            includeAllContactPoints: boolean
        ): Promise<Contract.Party.Read.IPersonDetail> {
            const query = {
                includeAllContactPoints: includeAllContactPoints,
            };
            return this.getPromise(`Party/Persons/${id}/Details`, query, 'personDetails');
        }

        public findAddressAsync(
            query: Contract.Party.Read.Query.IFindAddressesQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.Party.Read.IAddress>> {
            return this.getPromise('Party/Addresses', query, 'addresses');
        }

        public findPartyRoleTypes(partyId: Guid): ng.IHttpPromise<EnumTranslation[]> {
            return this.get(`Party/Persons/${partyId}/PartyRoleTypes`);
        }

        public modifyPersonalInfo(
            partyId: Guid,
            personalInfo: Contract.Party.Write.IPersonalInfo
        ): ng.IHttpPromise<{}> {
            return this.post(`Party/Persons/${partyId}/PersonalInfo`, personalInfo, 'personalInfo.save');
        }

        public patchPersonInfo(
            partyId: Guid,
            personalInfo: Contract.Party.Write.IPatchPersonCommand
        ): Promise<{}> {
            return this.patchPromise(`Party/Persons/${partyId}/Info`, personalInfo, 'personalInfo.save');
        }

        public registerDateOfDeath(partyId: Guid, dateOfDeath: Date): ng.IHttpPromise<{}> {
            return this.post(`Party/Persons/${partyId}/DateOfDeath`, {
                dateOfDeath: Shared.DateHelper.toServerDateString(dateOfDeath),
            });
        }

        public saveContactPoint(
            partyId: Guid,
            contactPoint: Contract.Party.Write.IContactPoint
        ): ng.IHttpPromise<Guid> {
            if (contactPoint.id == null) {
                return this.post(
                    `Party/Persons/${partyId}/ContactPoints`,
                    contactPoint,
                    'contactpoint'
                ) as ng.IHttpPromise<Guid>;
            } else {
                return this.put(
                    `Party/Persons/${partyId}/ContactPoints/${contactPoint.id}`,
                    contactPoint,
                    'contactpoint'
                ) as ng.IHttpPromise<Guid>;
            }
        }

        public mergePersons(partyId: Guid, mergePersonsInfo: Guid[]): ng.IHttpPromise<{}> {
            return this.post(`Party/Persons/${partyId}/MergePersons`, mergePersonsInfo, 'mergePersons');
        }

        public findPersonSummaryAsync(partyRoleId: Guid): Promise<Contract.Party.Read.IPersonSummary> {
            return this.getPromise(`Party/PersonSummary/${partyRoleId}`);
        }

        public getContactPersons(
            partyRoleId: Shared.Contract.Guid
        ): ng.IHttpPromise<Contract.Party.Read.IContactPerson[]> {
            return this.get(`Party/PartyRoles/${partyRoleId}/ContactPersons`);
        }

        public getOrganisationAsync(id: Guid): Promise<Contract.Party.Read.IOrganisation> {
            return this.getPromise(`Party/Organisations/${id}`);
        }

        public findOrganisationsAsync(
            query: Contract.Party.Read.Query.IFindOrganisationsQuery
        ): Promise<SearchResult<Contract.Party.Read.IOrganisation>> {
            return this.getPromise('Party/Organisations', query, 'organisation.search');
        }

        /** @deprecated */
        public getOrganisationDetails(id: Guid): ng.IHttpPromise<Contract.Party.Read.IOrganisationDetail> {
            return this.get(`Party/Organisations/${id}/Details`, null, 'organisation.details');
        }

        public getOrganisationDetailsAsync(id: Guid): Promise<Contract.Party.Read.IOrganisationDetail> {
            return this.getPromise(`Party/Organisations/${id}/Details`, null, 'organisation.details');
        }

        public createPatient(partyId: Guid) {
            return this.post(`Party/Persons/${partyId}/CreatePatient`, null, 'party.createPatient');
        }

        public createContactPersonAsync(
            patientId: Guid,
            contactPersonInfo: Contract.Party.Write.IContactPersonInfo
        ): Promise<{}> {
            return this.postPromise(
                `Party/PartyRoles/${patientId}/ContactPerson`,
                contactPersonInfo,
                'contactPerson.create'
            );
        }

        public createGroupAsync(group: Contract.Management.Write.IGroup): Promise<{}> {
            return this.postPromise('Party/Group/CreateGroup', group, 'group.create');
        }

        public stopGroupAsync(groupId: Shared.Contract.Guid): Promise<{}> {
            return this.postPromise(`Party/Group/${groupId}/StopGroup/`, null, 'group.stop');
        }

        public stopGroupAssignment(
            groupId: Shared.Contract.Guid,
            groupAssignmentId: Shared.Contract.Guid
        ): ng.IHttpPromise<{}> {
            return this.post(
                `Party/Group/${groupId}/StopAssignment/${groupAssignmentId}`,
                null,
                'groupAssignment.stop'
            );
        }

        public reActivateGroupMember(
            groupId: Shared.Contract.Guid,
            groupAssignmentId: Shared.Contract.Guid
        ): ng.IHttpPromise<Guid> {
            return this.post(
                `Party/Group/${groupId}/reActivateAssignment/${groupAssignmentId}`,
                null
            ) as ng.IHttpPromise<Guid>;
        }

        public getPossibleExternalIdentityProviders(): ng.IHttpPromise<Contract.Security.IExternalIdentityProvider[]> {
            return this.get(`Security/ExternalIdentityProviders`, null, 'organisation.identityProviders');
        }

        /** @deprecated */
        public getLinkedExternalIdentityProviders(
            organisationId: Guid
        ): ng.IHttpPromise<Contract.Security.IExternalIdentityProvider[]> {
            return this.get(
                `Party/Organisations/${organisationId}/ExternalIdentityProviders`,
                null,
                'organisation.identityProviders'
            );
        }

        public getLinkedExternalIdentityProvidersAsync(
            organisationId: Guid
        ): Promise<Contract.Security.IExternalIdentityProvider[]> {
            return this.getPromise(
                `Party/Organisations/${organisationId}/ExternalIdentityProviders`,
                null,
                'organisation.identityProviders'
            );
        }

        public linkExternalIdentityProvider(
            organisationId: Guid,
            externalIdentityProviderId: Guid
        ): ng.IHttpPromise<{}> {
            return this.post(`Party/Organisations/${organisationId}/ExternalIdentityProviders`, {
                externalIdentityProviderId: externalIdentityProviderId,
            });
        }

        public unlinkExternalIdentityProvider(
            organisationId: Guid,
            externalIdentityProviderId: Guid
        ): ng.IHttpPromise<{}> {
            return this.delete(
                `Party/Organisations/${organisationId}/ExternalIdentityProviders/${externalIdentityProviderId}`
            );
        }

        public getExternalIdentityProvidersForPersonAsync(
            personId: Guid
        ): Promise<Contract.Security.ILinkedExternalIdentityProvider[]> {
            return this.getPromise(`Party/Persons/${personId}/ExternalIdentityProviders`);
        }

        public unlinkPersonFromExternalIdentityProviderAsync(
            personId: Guid,
            externalIdentityProviderId: Guid
        ): Promise<{}> {
            return this.deletePromise(
                `Party/Persons/${personId}/ExternalIdentityProviders/${externalIdentityProviderId}`
            );
        }

        public getRelatedGroupsAsync(partyId: Guid): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise(`Party/RelatedGroups`, { PartyId: partyId }, 'person.relatedGroups');
        }
    }

    remeCareAppModule.service('partyApiSvc', PartyApiService);
}
