namespace RemeCare.Patient {
    import ApplicationProfileType = Shared.Contract.Code.ApplicationProfileType;

    class InternalRemarkModalController implements ng.IComponentController {
        public patientId: Shared.Contract.Guid;
        public remark: string;
        public editDisabled: boolean;

        constructor(private authservice: Shared.Framework.AuthService) {}

        public async $onInit(): Promise<void> {
            const appProfile = this.authservice.getClaim(Shared.Framework.ClaimTypes.applicationProfile);
            this.editDisabled =
                appProfile !== ApplicationProfileType.Superuser.toString() &&
                appProfile !== ApplicationProfileType.AdministrativeManager.toString() &&
                appProfile !== ApplicationProfileType.CareManager.toString();
        }
    }

    Patient.remeCarePatientModule.component('rcInternalRemarkModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            remark: '<',
            patientId: '<',
            patientName: '<',
        },
        controller: InternalRemarkModalController,
        templateUrl: 'patient/components/internalRemarkModal/internalRemarkModal.html',
    });
}
