var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var AddCarePlanTherapyOptionModalController = /** @class */ (function (_super) {
            __extends(AddCarePlanTherapyOptionModalController, _super);
            function AddCarePlanTherapyOptionModalController($scope, $translate, toaster, $stateParams, carePlanApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.$scope.confirm = function () { return _this.confirmAsync(); };
                return _this;
            }
            AddCarePlanTherapyOptionModalController.prototype.$onInit = function () {
                this.$scope.dateEditable = !moment().isAfter(this.$scope.carePlanTherapyOption.validFromDate);
                if (moment(this.$scope.minDate).isBefore(RemeCare.Shared.DateHelper.today())) {
                    this.$scope.minDate = moment(RemeCare.Shared.DateHelper.today())
                        .add('day', 1)
                        .toDate();
                }
                if (!this.$scope.dateEditable) {
                    this.$scope.minDate = null;
                }
            };
            AddCarePlanTherapyOptionModalController.prototype.confirmAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.$scope.form.$invalid) {
                                    this.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                this.$scope.carePlanTherapyOption.validUntilDate = null;
                                this.$scope.carePlanTherapyOption.carePlanId = this.$scope.carePlanId;
                                this.$scope.carePlanTherapyOption.definition = this.$scope.definition;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.carePlanApiSvc.saveCarePlanTherapyOptionAsync(this.$scope.carePlanId, this.$scope.definition.Id, this.$scope.carePlanTherapyOption.toServerWrite())];
                            case 2:
                                _a.sent();
                                this.$scope.$close(this.$scope.carePlanTherapyOption);
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return AddCarePlanTherapyOptionModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('addCarePlanTherapyOptionModalCtrl', AddCarePlanTherapyOptionModalController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
