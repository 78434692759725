var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ActionType = RemeCare.Shared.Contract.Code.ActionType;
        var PatientFileDocumentsController = /** @class */ (function () {
            function PatientFileDocumentsController($dialog, toaster, fileIconSvc, patientSvc, masterdataSvc, gridBuilderSvc, uiGridConstants, authservice, baseUrl) {
                this.$dialog = $dialog;
                this.toaster = toaster;
                this.fileIconSvc = fileIconSvc;
                this.patientSvc = patientSvc;
                this.masterdataSvc = masterdataSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.uiGridConstants = uiGridConstants;
                this.authservice = authservice;
                this.baseUrl = baseUrl;
            }
            PatientFileDocumentsController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, structuralTherapyActionQuery, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.buildGrid();
                                structuralTherapyActionQuery = {
                                    actionTypeCodes: [ActionType.RequestInformationObject],
                                };
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, Promise.all([
                                        this.patientSvc.getCarePlansAsync(this.patientId),
                                        this.masterdataSvc.getReferenceDataAsync(RemeCare.Shared.Framework.ReferenceDataTypes.patientFileDocumentSource),
                                        this.patientSvc.getStructuralTherapyActionsAsync(this.patientId, structuralTherapyActionQuery),
                                    ])];
                            case 2:
                                _a = _b.sent(), this.carePlans = _a[0], this.sources = _a[1], this.structuralTherapyActions = _a[2];
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileDocumentsController.prototype.executeSearch = function (page, pageSize, sortField, sortOrder, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = __assign({}, criteria, { page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortOrder, fromDate: criteria.fromDate ? RemeCare.Shared.DateHelper.toServerDateString(criteria.fromDate) : null, untilDate: criteria.untilDate ? RemeCare.Shared.DateHelper.toServerDateString(criteria.untilDate) : null });
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.findDocumentsAsync(this.patientId, query)];
                            case 2: return [2 /*return*/, _a.sent()];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                throw e_2;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileDocumentsController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, so, c) { return _this.executeSearch(p, ps, sf, so, c); })
                    .setBindToUrl()
                    .setSearchWhenReady()
                    .addColumn('Name', 'General.Name')
                    .addColumn('CarePlan', 'General.CarePlan')
                    .addColumn('DocumentCategories', 'General.Category', { cellFilter: 'delimitedDisplay : ", "' })
                    .addEnumColumn('Source', 'General.Source', RemeCare.Shared.Framework.ReferenceDataTypes.patientFileDocumentSource, null, function (pfd) { return _this.getActionText(pfd); })
                    .addDateColumn('UploadDateTime', 'Document.CreationDate', 'short', {
                    sort: { direction: this.uiGridConstants.DESC },
                })
                    .addColumn('Uploader', 'Document.UploadedBy')
                    .addIconRefColumn(function (pfd) { return _this.getComposedUrl(pfd); }, null, function (pfd) { return _this.getIcon(pfd); });
                if (this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientDocuments, RemeCare.Shared.Framework.AuthRight.Write)) {
                    gridBuilder = gridBuilder.addNavigationColumn('pencil', 'patients.patientfile.mediaDocuments.documents', { documentId: 'Id' });
                }
                if (this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser) {
                    gridBuilder = gridBuilder.addConditionalDeleteButtonColumn(function (pfd) { return pfd.Source === RemeCare.Contract.Core.Codes.PatientFileDocumentSource.Action; }, null, function (pfd) { return _this.deleteDocument(pfd); });
                }
                this.grid = gridBuilder.build();
            };
            PatientFileDocumentsController.prototype.getComposedUrl = function (patientFileDocument) {
                return RemeCare.Shared.DocumentHelper.fixDocumentUrl(patientFileDocument.Url, this.baseUrl, patientFileDocument.Name);
            };
            PatientFileDocumentsController.prototype.deleteDocument = function (patientFileDocument) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.$dialog.confirmBox(patientFileDocument.Name, 'Document.DeleteDocumentConfirmation', function () { return __awaiter(_this, void 0, void 0, function () {
                        var e_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.patientSvc.deleteDocumentAsync(this.patientId, patientFileDocument.Id)];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_3 = _a.sent();
                                    this.toaster.error(e_3);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, function () {
                        resolve(false);
                    });
                });
            };
            PatientFileDocumentsController.prototype.getActionText = function (patientFileDocument) {
                if (patientFileDocument.Action) {
                    return ": " + patientFileDocument.Action;
                }
                return '';
            };
            PatientFileDocumentsController.prototype.getIcon = function (patientFileDocument) {
                var extension = patientFileDocument.Name ? patientFileDocument.Name.split('.').pop() : null;
                return this.fileIconSvc.getFileIcon(extension);
            };
            PatientFileDocumentsController.injectionParameters = ['baseUrl'];
            return PatientFileDocumentsController;
        }());
        Patient.remeCarePatientModule.component('patientFileDocuments', {
            controller: PatientFileDocumentsController,
            templateUrl: 'views/patient/mediaDocuments/patientFileDocuments.html',
            bindings: {
                patientId: '<',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
