var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var InformationObjectType = RemeCare.Shared.Contract.Code.InformationObjectType;
        var PatientFileMediaController = /** @class */ (function () {
            function PatientFileMediaController(fileIconSvc, gridBuilderSvc, masterdataSvc, modalBuilderFactory, patientSvc, toaster, uiGridConstants, baseUrl) {
                this.fileIconSvc = fileIconSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.masterdataSvc = masterdataSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
                this.uiGridConstants = uiGridConstants;
                this.baseUrl = baseUrl;
            }
            PatientFileMediaController.prototype.$onInit = function () {
                this.loadDataAsync();
                this.buildGrid();
            };
            PatientFileMediaController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.patientSvc.getCarePlansAsync(this.patientId),
                                        this.masterdataSvc.getReferenceDataAsync(RemeCare.Shared.Framework.ReferenceDataTypes.informationObjectType),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.carePlans = _a[0], this.informationObjectTypes = _a[1];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMediaController.prototype.buildGrid = function () {
                var _this = this;
                this.grid = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, so, c) { return _this.executeSearchAsync(p, ps, sf, so, c); })
                    .addColumn('InformationMessage.Name', 'General.Name')
                    .addEnumColumn('InformationMessage.InformationObjectType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.informationObjectType)
                    .addDateColumn('TimeProvided', 'Media.TimeProvided', 'short', {
                    width: 180,
                    sort: { direction: this.uiGridConstants.DESC },
                })
                    .addColumn('ProvidedTo', 'Media.ProvidedTo', { cellFilter: 'delimitedDisplay:", "' })
                    .addColumn('CarePlan', 'General.CarePlan', { enableSorting: false })
                    .addIconColumn('', function (c) { return _this.getIcon(c); }, function (c) { return _this.showMedia(c); })
                    .build();
                this.grid.searchCriteria.types = [InformationObjectType.LinkToFile, InformationObjectType.InScreenVideo];
                this.grid.search();
            };
            PatientFileMediaController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortOrder, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortOrder,
                                    carePlans: criteria.carePlans,
                                    types: criteria.types,
                                    fromDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.fromDate),
                                    untilDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.untilDate),
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.findCarePlanMediaAsync(this.patientId, query)];
                            case 2: return [2 /*return*/, _a.sent()];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                throw e_2;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMediaController.prototype.getIcon = function (carePlanMedia) {
                if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.LinkToFile) {
                    var extension = carePlanMedia.InformationMessage.Name
                        ? carePlanMedia.InformationMessage.Name.split('.').pop()
                        : null;
                    return this.fileIconSvc.getFileIcon(extension);
                }
                else if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.InScreenVideo) {
                    return 'video-camera';
                }
                else {
                    return 'comment-o';
                }
            };
            PatientFileMediaController.prototype.showMedia = function (carePlanMedia) {
                if (carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.LinkToFile) {
                    window.open(RemeCare.Shared.DocumentHelper.fixDocumentUrl(carePlanMedia.InformationMessage.Url, this.baseUrl), '_blank');
                }
                else {
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/patient/mediaDocuments/informationMessage.html')
                        .setScope({
                        message: carePlanMedia.InformationMessage,
                    })
                        .setSize(carePlanMedia.InformationMessage.InformationObjectType === InformationObjectType.Text
                        ? RemeCare.Shared.Framework.Helper.ModalSize.medium
                        : RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .build();
                }
            };
            return PatientFileMediaController;
        }());
        Patient.remeCarePatientModule.component('patientFileMedia', {
            controller: PatientFileMediaController,
            templateUrl: 'views/patient/mediaDocuments/patientFileMedia.html',
            bindings: {
                patientId: '<',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
