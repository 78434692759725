var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Nationality = RemeCare.Shared.Contract.Code.Nationality;
        var PersonalInformationEditController = /** @class */ (function () {
            function PersonalInformationEditController(masterdataSvc, modalBuilderFactory, nationalNumberSvc, toaster) {
                this.masterdataSvc = masterdataSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.nationalNumberSvc = nationalNumberSvc;
                this.toaster = toaster;
            }
            PersonalInformationEditController.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getTitles()
                    .success(function (r) { return _this.personTitles = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getCommLanguages()
                    .success(function (r) { return _this.communicationLanguages = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getLanguages()
                    .success(function (r) { return _this.languages = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getNationalities()
                    .success(function (r) { return _this.nationalities = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getGenders()
                    .success(function (r) { return _this.genders = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getMaritalStatusses()
                    .success(function (r) { return _this.maritalStatuses = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getEducations()
                    .success(function (r) { return _this.educationLevels = r; })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getTechnologicalAffinities()
                    .success(function (r) { return _this.technologicalAffinities = r; })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            PersonalInformationEditController.prototype.confirm = function () {
                this.personalInfoForm.$setSubmitted();
                if (this.personalInfoForm.$invalid) {
                    this.personalInfoForm.showValidationErrorMessage();
                    return;
                }
                this.onSavePatient({
                    person: this.person
                });
            };
            PersonalInformationEditController.prototype.registerDateOfDeath = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('registerDateOfDeathCtrl')
                    .setTemplateUrl('views/patient/patientInfo/personalInfo/registerDateOfDeath.html')
                    .setResultCallBack(function (r) { return _this.person.dateOfDeath = r; })
                    .setResolve({
                    partyId: function () { return _this.person.partyId; }
                })
                    .build();
            };
            PersonalInformationEditController.prototype.validNationalNumber = function (value) {
                return !value
                    || !this.person.nationality
                    || this.person.nationality.Id !== Nationality.Belgian
                    || this.nationalNumberSvc.isValidNationalNumber(value);
            };
            PersonalInformationEditController.prototype.nationalNumberChanged = function () {
                this.validateIdentificationAndSetProperties(true, true, true, true);
            };
            PersonalInformationEditController.prototype.isBirthDateValid = function () {
                return this.nationalNumberSvc.isValidDateOfBirth(this.person.dateOfBirth, this.person.nationalNumber);
            };
            PersonalInformationEditController.prototype.dateOfBirthChanged = function () {
                this.validateIdentificationAndSetProperties(true, false, false);
            };
            PersonalInformationEditController.prototype.isGenderValid = function () {
                return this.nationalNumberSvc.isValidGender(this.person.gender && this.person.gender.Id, this.person.nationalNumber);
            };
            PersonalInformationEditController.prototype.genderChanged = function () {
                this.validateIdentificationAndSetProperties(false, true, false);
            };
            PersonalInformationEditController.prototype.isNationalityValid = function () {
                return this.nationalNumberSvc.isValidNationality(this.person.nationality && this.person.nationality.Id, this.person.nationalNumber);
            };
            PersonalInformationEditController.prototype.nationalityChanged = function () {
                this.validateIdentificationAndSetProperties(false, false, true);
            };
            PersonalInformationEditController.prototype.validateIdentificationAndSetProperties = function (checkDateOfBirth, checkGender, checkNationality, overwrite) {
                var _this = this;
                var dateOfBirthGetterSetter = checkDateOfBirth
                    ? function (dateOfBirth) {
                        if (dateOfBirth) {
                            _this.person.dateOfBirth = dateOfBirth;
                        }
                        return _this.person.dateOfBirth;
                    }
                    : null;
                var genderGetterSetter = checkGender
                    ? function (gender) {
                        if (gender) {
                            _this.person.gender = _.find(_this.genders, function (g) { return g.Id === gender; });
                        }
                        return _this.person.gender && _this.person.gender.Id;
                    }
                    : null;
                var nationalityGetterSetter = checkNationality
                    ? function (nationality) {
                        if (nationality) {
                            _this.person.nationality = _.find(_this.nationalities, function (n) { return n.Id === nationality; });
                        }
                        return _this.person.nationality && _this.person.nationality.Id;
                    }
                    : null;
                this.nationalNumberSvc.validateAndSetProperties(this.person.nationalNumber, dateOfBirthGetterSetter, genderGetterSetter, nationalityGetterSetter, null, overwrite);
            };
            return PersonalInformationEditController;
        }());
        Patient.remeCarePatientModule.component('editPatient', {
            controller: PersonalInformationEditController,
            templateUrl: 'patient/controllers/patientInfo/personalInfo/editPatient/editPatient.html',
            bindings: {
                person: '<',
                onSavePatient: '&',
                onCancel: '&'
            },
            transclude: true
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
