/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ParametersHelper = /** @class */ (function () {
            function ParametersHelper(serverObject) {
                this.date = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.Date);
                this.parameters = serverObject.Parameters;
            }
            ParametersHelper.prototype.getValue = function (index) {
                var parameter = this.parameters[index];
                if (parameter == null) {
                    return '';
                }
                var param = parameter.Value;
                if (_(param).isArray()) {
                    return param.join(', ');
                }
                return param;
            };
            return ParametersHelper;
        }());
        var ParametersTableController = /** @class */ (function (_super) {
            __extends(ParametersTableController, _super);
            // @ngInject
            function ParametersTableController(dateHelper, spinnerSvc, toaster, carePlanApiSvc, gridBuilderSvc) {
                var _this = _super.call(this, dateHelper, spinnerSvc) || this;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                return _this;
            }
            ParametersTableController.prototype.hasData = function () {
                return this.grid && this.grid.hasData();
            };
            ParametersTableController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        this.monitoringPartSources = _(this.monitoringPart.monitoringPartSources).sortBy(function (mps) { return mps.sequence; });
                        this.parameters = [];
                        this.monitoringPartSources.forEach(function (mps) {
                            var parameter = {
                                sourceType: mps.sourceType,
                            };
                            if (mps.characteristic) {
                                parameter.charId = mps.characteristic.Id;
                            }
                            if (mps.unit) {
                                parameter.unitId = mps.unit.Id;
                            }
                            if (mps.observableEntity) {
                                parameter.entityId = mps.observableEntity.Id;
                            }
                            if (mps.codeSet) {
                                parameter.codeSetTypeId = mps.codeSet.Id;
                            }
                            if (mps.ruleThreshold) {
                                parameter.ruleThresholdId = mps.ruleThreshold.Id;
                            }
                            if (mps.objective) {
                                parameter.objectiveId = mps.objective.Id;
                            }
                            _this.parameters.push(parameter);
                        });
                        this.buildGrid();
                        return [2 /*return*/];
                    });
                });
            };
            ParametersTableController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.grid.pagingOptions.currentPage = 1;
                                return [4 /*yield*/, this.grid.search()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            ParametersTableController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize) { return _this.executeSearch(page, pageSize); })
                    .addColumn('date', 'General.Date', {
                    cellFilter: 'date : "short' + (this.monitoringPart.showTime ? '' : 'Date') + '"',
                });
                for (var i = 0; i < this.monitoringPartSources.length; i++) {
                    var sourceType = this.monitoringPartSources[i].type;
                    var useTextRegistration = false;
                    if ((sourceType === RemeCare.Shared.Contract.Code.MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        this.monitoringPartSources[i]
                            .useTherapyActionPartTextRegistration) ||
                        (sourceType === RemeCare.Shared.Contract.Code.MonitoringPartSourceType.QualitativeMeasuringPointParameter &&
                            this.monitoringPartSources[i]
                                .useTherapyActionPartTextRegistration)) {
                        useTextRegistration = true;
                    }
                    var customTemplate = '<div class="ui-grid-cell-contents" bs-tooltip data-container="body"' +
                        (" data-delay=\"500,100\" data-title=\"{{row.entity.getValue(" + i + ")}}") +
                        ("{{" + useTextRegistration + " && row.entity.parameters[" + i + "].TherapyActionPart.Text ? ' - ' + row.entity.parameters[" + i + "].TherapyActionPart.Text : ''}}") +
                        ("\"> {{ row.entity.getValue(" + i + ") }} </div>");
                    gridBuilder = gridBuilder.addCustomColumn(this.monitoringPartSources[i].getName(), customTemplate, null, {
                        cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                            if (row.entity.parameters[colRenderIndex - 1].ExceedsThreshold) {
                                return 'background-red';
                            }
                        },
                    }, true);
                }
                this.grid = gridBuilder.setSorting(false).build();
                this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
            };
            ParametersTableController.prototype.executeSearch = function (page, pageSize) {
                return __awaiter(this, void 0, void 0, function () {
                    var r, result, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.findParametersAsync(this.carePlanIds, this.parameters, page, pageSize, this.dateInfo.fromDate, this.getUntilDate())];
                            case 1:
                                r = _a.sent();
                                result = {
                                    Items: _(r.Items).map(function (i) { return new ParametersHelper(i); }),
                                    Total: r.Total,
                                };
                                return [2 /*return*/, result];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                throw e_1;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return ParametersTableController;
        }(Patient.MonitoringPartControllerBase));
        var ParametersTableComponent = /** @class */ (function (_super) {
            __extends(ParametersTableComponent, _super);
            function ParametersTableComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = ParametersTableController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
                return _this;
            }
            return ParametersTableComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringParametersTable', new ParametersTableComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
