module RemeCare.Patient.Model {
    import Duration = Shared.Contract.IDuration;



    export class PrescribedAdministrationInformation {
        public prescribedAdministrationMeans: number;
        public administrationMethod: number;
        public duration: Duration;
        public speedQuantity: number;
        public speedUnit: number;

        constructor(serverObject: Contract.Patient.Read.IPrescribedAdministrationInformation) {
            if (serverObject != null) {

                this.prescribedAdministrationMeans = serverObject.PrescribedAdministrationMeans;
                this.administrationMethod = serverObject.AdministrationMethod;
                this.speedQuantity = serverObject.SpeedQuantity;
                this.speedUnit = serverObject.SpeedUnit;
                if (serverObject.Duration != null) {
                    this.duration = < Shared.Contract.IDuration>{
                        Quantity: serverObject.Duration.Quantity,
                        Unit: serverObject.Duration.Unit
                    }
                }
            }
        }

        clear(): void {
            this.duration = null;
            this.administrationMethod = null;
            this.speedQuantity = null;
            this.speedUnit = null;
        }

        toServerWrite(): Contract.Patient.Write.IPrescribedAdministrationInformation {
            if (this.duration != null && this.duration.Unit != null) {
          return < Contract.Patient.Write.IPrescribedAdministrationInformation > {
                    PrescribedAdministrationMeans: this.prescribedAdministrationMeans,
                    AdministrationMethod: this.administrationMethod,
                    Duration: <Shared.Contract.IDuration>{
                        Quantity: this.duration.Quantity,
                        Unit: this.duration.Unit,

                    },
                    SpeedQuantity: this.speedQuantity,
                    SpeedUnit: this.speedUnit
                }
            } else {
              return < Contract.Patient.Write.IPrescribedAdministrationInformation > {
                    PrescribedAdministrationMeans: this.prescribedAdministrationMeans,
                    AdministrationMethod: this.administrationMethod,
                    Duration: null,
                    SpeedQuantity: this.speedQuantity,
                    SpeedUnit: this.speedUnit
                }
            }
        }
    }

} 