module RemeCare.CareRequest {
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;
    import ModalityType = Shared.Contract.Code.ModalityType;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    interface IContactPersonApplicationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        contactPerson: PatientContactPerson;
        careRequestPart: Shared.Framework.Model.CareRequestPart;

        countries: Array<EnumTranslation>;
        genders: Array<EnumTranslation>;
        contactPersonTypes: Array<EnumTranslation>;

        contactPersonForm: ng.IFormController;

        isPresent(t: CareRequestUIControlType): boolean;
        isRequired(t: CareRequestUIControlType): boolean;
        atLeastOneNumber(): boolean;

        confirm(): void;
    }

    class ContactPersonApplicationController extends Shared.Framework.ControllerBase<IContactPersonApplicationScope> {
        
        constructor(
            $scope: IContactPersonApplicationScope,
            $translate,
            toaster,
            private masterdataSvc: Shared.Framework.MasterdataService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
            $scope.isPresent = (t) => this.isPresent(t);
            $scope.isRequired = (t) => this.isRequired(t);
            $scope.atLeastOneNumber = () => this.atLeastOneNumber();
        }

        public $onInit(): void {
            this.masterdataSvc.getCountries()
                .success(r => this.$scope.countries = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getGenders()
                .success(r => this.$scope.genders = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getContactPersonTypes()
                .success(r => this.$scope.contactPersonTypes = r)
                .error(e => this.errorCallback(e));
        }

        private confirm(): void {
            if (this.$scope.contactPersonForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.contactPerson);
        }

        private atLeastOneNumber(): boolean {
            if (!this.$scope.isRequired(CareRequestUIControlType.ContactPersonTelephoneMobile)) return true;
            return (this.$scope.contactPerson.person.contactInfo.telephone.isFilled() ||
                this.$scope.contactPerson.person.contactInfo.mobile.isFilled());
        }

        private isPresent(controlType: CareRequestUIControlType): boolean {
            var control = _(this.$scope.careRequestPart.uiControls).find(u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id !== ModalityType.Not;
        }

        private isRequired(controlType: CareRequestUIControlType): boolean {
            var control = _(this.$scope.careRequestPart.uiControls).find(u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id === ModalityType.Mandatory;
        }
    }

    remeCareCareRequestModule.controller('contactPersonApplicationCtrl', (
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ) => new ContactPersonApplicationController (
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ));
}