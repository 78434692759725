'use strict';

module RemeCare.Contract.CarePlanAction.Read {
     
     export enum TherapyActionPartGroupCode {
         Appointment = 1,
         Execution = 2,
         ExternalReport = 4
     }

     export interface ICarePlanActionInfo {
         ActionName: string;
         PatientName: string;
         BloodAnalysis: boolean;
         StructuralTherapyActionId: string;
         TherapyActionPartGroupCode: TherapyActionPartGroupCode;
         HasExtra: boolean;
     }
 }