'use strict';

module RemeCare.Contract.Core {
    
    export interface IMediaLibItemDetail {
        Id: Shared.Contract.Guid;
        Sequence: number;
        Url: string;
        Text: string;
        Questions: Shared.Contract.IMediaLibItemQuestionDetail[];
    }
} 