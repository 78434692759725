module RemeCare.Management {
    
    export class GlobalActorRole {
        id: Shared.Contract.Guid;
        name: string;
        isActive: boolean;
        internalRemark: string;
        translations: { [culture: string]: Shared.Contract.ITranslation } = {};
        parties: Array<Contract.Party.Read.IPerson> = [];

        constructor(serverObject?: Contract.Management.Read.IGlobalActorRole) {
            if (serverObject) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.isActive = serverObject.IsActive;
                this.internalRemark = serverObject.InternalRemark;
                _(serverObject.Translations).each(t => {
                    this.translations[t.Culture] = t;
                });
            }
        }

        toServerWrite(): Contract.Management.Write.IGlobalActorRole {
            return <Contract.Management.Write.IGlobalActorRole> {
                translations: this.getTranslations()
            }
        }

        private getTranslations(): Array<Shared.Contract.ITranslationWrite> {
            var translations: Array<Shared.Contract.ITranslationWrite> = [];
            for (var key in this.translations) {
                if (this.translations.hasOwnProperty(key)) {
                    var trans = this.translations[key];
                    translations.push({
                        culture: key,
                        name: trans.Name
                    });
                }
            }
            return translations;
        }
    }
}