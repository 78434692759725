namespace RemeCare.Connections {
    export interface IFitbitCallbackParams {
        state: string;
        code: string;
    }

    class RemeCareConnectionsConfiguration extends Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.add(
                'connections',
                {
                    url: '/Connections',
                    redirectTo: 'connections.fitbit',
                },
                ['Views_Connections']
            );
            this.addGeneric<IFitbitCallbackParams>(
                'connections.fitbit',
                {
                    url: '/Fitbit?code&state',
                    component: 'rcFitbitCallback',
                    params: {
                        code: null,
                        state: null,
                    },
                },
                []
            );
        }
    }

    remeCareConnectionsModule.config(($stateProvider) => new RemeCareConnectionsConfiguration($stateProvider));
}
