namespace RemeCare.CarePlanAction {
    interface IQualitativeObservationScope extends IComponentScope {
        component: QualitativeObservationDescriptor;
        options: Shared.Contract.IChoiceListOption[];
        carePlanAction: RemeCare.CarePlanAction.CarePlanAction;
    }

    class QualitativeObservationController extends ComponentControllerBase<IQualitativeObservationScope> {
        // @ngInject
        constructor(
            $scope: IQualitativeObservationScope,
            private toaster: Shared.Framework.Toaster,
            private masterdataSvc: Shared.Framework.MasterdataService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {
            this.loadData();
        }

        private loadData(): void {
            let pointInTime = this.$scope.carePlanAction.processVersionDate;
            if (pointInTime === undefined) {
                pointInTime = moment.utc().toDate();
            }
            this.masterdataSvc
                .getCodeSetItems(
                    this.$scope.component.optionGroupId,
                    RemeCare.Shared.DateHelper.toServerDateString(pointInTime)
                )
                .success((o) => (this.$scope.options = o))
                .error((m) => this.toaster.error(m));
        }
    }

    class QualitativeObservationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/qualitativeObservation.html';

        public controller = QualitativeObservationController;
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive('rcQualitativeObservation', () => new QualitativeObservationDirective());
}
