/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import QualitativeMeasuringPointParameter = Shared.Framework.Model.QualitativeMeasuringPointParameter;
    import ReferenceParameterThreshold = Shared.Framework.Model.ReferenceParameterThreshold;
    import ReferenceParameterObjective = Shared.Framework.Model.ReferenceParameterObjective;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import Parameters = Contract.CarePlan.Read.IParameters;

    class ParametersHelper {
        public date: Date;
        public parameters: Array<Contract.CarePlan.Read.IParameter<any>>;

        constructor(serverObject: Parameters) {
            this.date = Shared.DateHelper.serverDateStringToDateTime(serverObject.Date);
            this.parameters = serverObject.Parameters;
        }

        public getValue(index: number): string {
            const parameter = this.parameters[index];
            if (parameter == null) {
                return '';
            }
            const param = parameter.Value;
            if (_(param).isArray()) {
                return (param as string[]).join(', ');
            }
            return param;
        }
    }

    class ParametersTableController extends MonitoringPartControllerBase<Shared.Framework.Model.MonitoringPartTable> {
        public grid: Shared.Framework.Grid.Grid<ParametersHelper>;
        private parameters: Contract.CarePlan.Read.Query.IParametersParameter[];
        private monitoringPartSources: MonitoringPartSourceParameter[];

        // @ngInject
        constructor(
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super(dateHelper, spinnerSvc);
        }

        public hasData(): boolean {
            return this.grid && this.grid.hasData();
        }

        protected async init(): Promise<void> {
            this.monitoringPartSources = _(this.monitoringPart.monitoringPartSources).sortBy(
                (mps) => (mps as MonitoringPartSourceParameter).sequence
            ) as MonitoringPartSourceParameter[];
            this.parameters = [];
            this.monitoringPartSources.forEach((mps) => {
                const parameter: Contract.CarePlan.Read.Query.IParametersParameter = {
                    sourceType: mps.sourceType,
                };
                if ((mps as QuantitativeMeasuringPointParameter).characteristic) {
                    parameter.charId = (mps as QuantitativeMeasuringPointParameter).characteristic.Id;
                }
                if ((mps as QuantitativeMeasuringPointParameter).unit) {
                    parameter.unitId = (mps as QuantitativeMeasuringPointParameter).unit.Id;
                }
                if ((mps as QualitativeMeasuringPointParameter).observableEntity) {
                    parameter.entityId = (mps as QualitativeMeasuringPointParameter).observableEntity.Id;
                }
                if ((mps as QualitativeMeasuringPointParameter).codeSet) {
                    parameter.codeSetTypeId = (mps as QualitativeMeasuringPointParameter).codeSet.Id;
                }
                if ((mps as ReferenceParameterThreshold).ruleThreshold) {
                    parameter.ruleThresholdId = (mps as ReferenceParameterThreshold).ruleThreshold.Id;
                }
                if ((mps as ReferenceParameterObjective).objective) {
                    parameter.objectiveId = (mps as ReferenceParameterObjective).objective.Id;
                }
                this.parameters.push(parameter);
            });
            this.buildGrid();
        }

        protected async onDateChange(): Promise<void> {
            this.grid.pagingOptions.currentPage = 1;
            await this.grid.search();
        }

        private buildGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<ParametersHelper>((page, pageSize) => this.executeSearch(page, pageSize))
                .addColumn('date', 'General.Date', {
                    cellFilter: 'date : "short' + (this.monitoringPart.showTime ? '' : 'Date') + '"',
                });
            for (let i = 0; i < this.monitoringPartSources.length; i++) {
                const sourceType = this.monitoringPartSources[i].type;
                let useTextRegistration = false;
                if (
                    (sourceType === Shared.Contract.Code.MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        (this.monitoringPartSources[i] as QuantitativeMeasuringPointParameter)
                            .useTherapyActionPartTextRegistration) ||
                    (sourceType === Shared.Contract.Code.MonitoringPartSourceType.QualitativeMeasuringPointParameter &&
                        (this.monitoringPartSources[i] as QualitativeMeasuringPointParameter)
                            .useTherapyActionPartTextRegistration)
                ) {
                    useTextRegistration = true;
                }

                const customTemplate =
                    '<div class="ui-grid-cell-contents" bs-tooltip data-container="body"' +
                    ` data-delay="500,100" data-title="{{row.entity.getValue(${i})}}` +
                    `{{${useTextRegistration} && row.entity.parameters[${i}].TherapyActionPart.Text ? ' - ' + row.entity.parameters[${i}].TherapyActionPart.Text : ''}}` +
                    `"> {{ row.entity.getValue(${i}) }} </div>`;

                gridBuilder = gridBuilder.addCustomColumn(
                    this.monitoringPartSources[i].getName(),
                    customTemplate,
                    null,
                    {
                        cellClass: (grid, row, col, rowRenderIndex, colRenderIndex) => {
                            if (row.entity.parameters[colRenderIndex - 1].ExceedsThreshold) {
                                return 'background-red';
                            }
                        },
                    },
                    true
                );
            }

            this.grid = gridBuilder.setSorting(false).build();
            this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
        }

        private async executeSearch(
            page: number,
            pageSize: number
        ): Promise<Shared.Contract.ISearchResult<ParametersHelper>> {
            try {
                const r = await this.carePlanApiSvc.findParametersAsync(
                    this.carePlanIds,
                    this.parameters,
                    page,
                    pageSize,
                    this.dateInfo.fromDate,
                    this.getUntilDate()
                );

                const result = {
                    Items: _(r.Items).map((i) => new ParametersHelper(i)),
                    Total: r.Total,
                };

                return result;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }
    }

    class ParametersTableComponent extends MonitoringPartComponentBase {
        public controller = ParametersTableController;

        public templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
    }

    remeCarePatientModule.component('rcMonitoringParametersTable', new ParametersTableComponent());
}
