namespace RemeCare.CarePlanAction {
    export class ComponentDescriptorFactory {
        public createComponentDescriptor(
            authservice: Shared.Framework.AuthService,
            serverObject: Contract.CarePlanAction.Read.IActionPart
        ): ComponentDescriptor {
            switch (serverObject.ActionType.Id) {
                case Shared.Contract.Code.ActionType.QuantitativeObservation:
                    return new QuantitativeObservationDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.QualitativeObservation:
                    return new QualitativeObservationDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.Administration:
                    return new AdministrationDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.CareAct:
                    return new CareActDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.FixDetails:
                    return new SetDetailsDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.ProvideInformationObject:
                    return new ProvideInformationObjectDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.RequestInformationObject:
                    return new RequestInformationObjectDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.Remark:
                    return new RemarkDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.PreviousRegistration:
                    return new PreviousRegistrationDescriptor(authservice, serverObject);
                case Shared.Contract.Code.ActionType.TelephoneCallPlanning:
                    return new PhoneCallDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.Date:
                    return new DateDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.MissingRegistrations:
                    return new MissingRegistrationsDescriptor(authservice, serverObject);
                case Shared.Contract.Code.ActionType.MedicationSchema:
                    return new MedicationSchemaDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.AberrantRegistrations:
                    return new AberrantRegistrationsDescriptor(serverObject);
                case Shared.Contract.Code.ActionType.Signature:
                    return new SignatureDescriptor(serverObject);
                default:
                    return null;
            }
        }
    }
}
