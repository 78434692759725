var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SearchGroupController = /** @class */ (function () {
            function SearchGroupController(toaster, gridBuilderSvc, partyApiSvc) {
                this.toaster = toaster;
                this.gridBuilderSvc = gridBuilderSvc;
                this.partyApiSvc = partyApiSvc;
            }
            SearchGroupController.prototype.$onInit = function () {
                this.buildGrid();
            };
            SearchGroupController.prototype.buildGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.searchGroups(page, pageSize, sortField, sortDirection, criteria);
                })
                    .setBindToUrl()
                    .addColumn('Name', 'General.Name')
                    .addDateColumn('ValidFromDate', 'General.StartDate', 'shortDate')
                    .addDateColumn('ValidUntilDate', 'General.EndDate', 'shortDate')
                    .addNavigationColumn('pencil', 'management.groups.groupInformation', { readOnly: true, groupId: 'Id' });
                this.grid = builder.build();
            };
            SearchGroupController.prototype.searchGroups = function (page, pageSize, sortField, sortDirection, criteria) {
                var _this = this;
                var query = {
                    page: page,
                    pageSize: pageSize,
                    sortField: sortField,
                    sortOrder: sortDirection,
                    memberFirstName: criteria.memberFirstName,
                    memberLastName: criteria.memberLastName,
                    groupName: criteria.groupName,
                    organisationName: criteria.organisationName,
                    includeExpiredGroups: criteria.includeExpiredGroups
                };
                var deferred = jQuery.Deferred();
                this.partyApiSvc.findGroups(query)
                    .success(function (r) {
                    deferred.resolve(r);
                    _this.searchCollapsed = true;
                })
                    .error(function (e) { return deferred.reject(e); });
                return deferred.promise();
            };
            return SearchGroupController;
        }());
        RemeCare.Management.remeCareManagementModule.component('rcSearchGroups', {
            controller: SearchGroupController,
            controllerAs: '$ctrl',
            bindings: {},
            templateUrl: 'management/controllers/groups/searchGroups/searchGroups.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
