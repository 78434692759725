/// <reference path="../../contract/core/codes/genderCode.ts"/>

namespace RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;
    import GenderCode = Contract.Core.Codes.GenderCode;
    import CountryCode = Shared.Contract.Code.Country;
    import NationalityCode = Shared.Contract.Code.Nationality;
    import CountryIso2 = Shared.Contract.Code.CountryIso2;
    import NationalityIso2 = Shared.Contract.Code.NationalityIso2;
    import PhoneNumber = RemeCare.Model.PhoneNumber;

    export class PatientFileForExternal {
        public id: Guid;
        public patientParameters: IPatientFileForExternal;
        public responsibleHealthCareProfessional: IHealthCareProfessional;
        public externalReferences: RemeCare.Contract.Core.IExternalReference[];

        constructor(serverObject?: Contract.CareRequest.Read.IPatientFileForExternalRequest) {
            if (serverObject != null) {
                this.id = serverObject.Id;

                const context: IContext = JSON.parse(serverObject.ParametersJson);
                if (context.Patient) {
                    this.patientParameters = context.Patient;
                    this.responsibleHealthCareProfessional = context.ResponsibleHealthCareProfessional;
                    this.externalReferences = context.ExternalReferences || [];
                } else {
                    // Fallback to old logic
                    this.patientParameters = JSON.parse(serverObject.ParametersJson);
                    this.externalReferences = [];
                }

                this.patientParameters.ExternalReferences = this.patientParameters.ExternalReferences || [];

                this.patientParameters.PreferredLanguage = this.patientParameters.PreferredLanguage
                    ? this.patientParameters.PreferredLanguage.toLowerCase()
                    : null;

                this.patientParameters.NumericGenderCode = this.mapGender(this.patientParameters.Gender);
                this.patientParameters.NumericNationalityCode = this.mapNationality(this.patientParameters.Nationality);
                this.patientParameters.NumericCountryCode = this.mapCountry(this.patientParameters.Country);

                try {
                    const d = moment(this.patientParameters.Birthdate); // Passing null here will result in a valid moment object
                    this.patientParameters.BirthdateDate =
                        this.patientParameters.Birthdate && d.isValid() ? d.toDate() : null;
                } catch (error) {
                    this.patientParameters.BirthdateDate = null;
                }

                try {
                    this.patientParameters.PhoneParsed = RemeCare.Model.PhoneNumber.parse(this.patientParameters.Phone);
                } catch (error) {
                    this.patientParameters.PhoneParsed = new PhoneNumber();
                }
                try {
                    this.patientParameters.GsmParsed = RemeCare.Model.PhoneNumber.parse(this.patientParameters.Gsm);
                } catch (error) {
                    this.patientParameters.GsmParsed = new PhoneNumber();
                }
            }
        }

        private mapGender(gender: string): GenderCode {
            if (!gender) {
                return null;
            }
            switch (gender.toUpperCase()) {
                case 'M':
                    return GenderCode.Male;
                case 'F':
                    return GenderCode.Female;
                default:
                    return null;
            }
        }

        private mapNationality(source: string): NationalityCode {
            if (!source) {
                return null;
            }
            const iso = source.toUpperCase() as NationalityIso2;
            try {
                return NationalityCode[_.invert(NationalityIso2)[iso] as keyof typeof NationalityCode];
            } catch (error) {
                return null;
            }
        }

        private mapCountry(source: string): CountryCode {
            if (!source) {
                return null;
            }
            const iso = source.toUpperCase() as CountryIso2;
            try {
                return CountryCode[_.invert(CountryIso2)[iso] as keyof typeof CountryCode];
            } catch (error) {
                return null;
            }
        }
    }

    export interface IContext {
        Patient: IPatientFileForExternal;
        ResponsibleHealthCareProfessional: IHealthCareProfessional;
        ExternalReferences: RemeCare.Contract.Core.IExternalReference[];
    }

    export interface IHealthCareProfessional {
        HealthProviderId: string;
        CitizenId: string;
        FirstName: string;
        LastName: string;
    }

    export interface IPatientFileForExternal {
        FirstName: string;
        SecondName: string;
        LastName: string;
        Birthdate: string; // Date in ISO 8601 standard in JSON, e.g. "2020-05-14" or "2020-05-14T09:07:58Z"
        BirthdateDate: Date;
        ExtRef: string;
        Email: string;
        Nationality: string; // ISO 3166 2-letter country codes
        NumericNationalityCode: NationalityCode;
        Gender: string; // M | F | X
        NumericGenderCode: GenderCode;
        CitizenId: string;
        Phone: string;
        PhoneParsed: PhoneNumber;
        Gsm: string;
        GsmParsed: PhoneNumber;
        PreferredLanguage: string; // ISO 639-1 2-letter language codes

        Kg1: string;
        Kg2: string;
        Mutnr: string;
        MutVinr: string;

        Address: string;
        City: string;
        Zipcode: string;
        Country: string; // ISO 3166 2-letter country codes
        NumericCountryCode: CountryCode;
        ExternalReferences: RemeCare.Contract.Core.IExternalReference[];
    }
}
