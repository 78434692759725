module RemeCare.CareRequest {

    interface ICareRequestMedicationAdvancedScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        careRequestMedication: CareRequestMedication;
        medicationForm: ng.IFormController;
        periodicitiesGrid: Shared.Framework.Grid.Grid<Shared.Framework.Model.Periodicity>;
        confirm(): void;
        minEndDate(): Date;
        addPeriodicity: () => void;
        isForTherapyAction: boolean;
        hideIntraDayConfig: boolean;

        isAdHoc: boolean;
        commLanguage: string;
    }

    class CareRequestMedicationAdvancedController extends Shared.Framework.ControllerBase<ICareRequestMedicationAdvancedScope> {
        private today: Date;

        constructor(
            $scope: ICareRequestMedicationAdvancedScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            private $timeout: ng.ITimeoutService,
            private periodicityFactory: Shared.Framework.Factory.PeriodicityFactory,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private daySvc: Shared.Service.DayService
        ) {

            super($scope, $translate, toaster);
            $scope.minEndDate = () => this.minEndDate();
            $scope.confirm = () => this.confirm();

        }

        public $onInit(): void {
            this.$scope.hideIntraDayConfig = true;

            if (this.$scope.isAdHoc) {
               if (this.$scope.commLanguage) {
                    this.masterdataSvc.getInterfaceLanguages()
                        .success(r => {
                            var langCode = _.find(r, x => x.Code === this.$scope.commLanguage).Code;
                            this.masterdataSvc.getTranslation(langCode, 'Views.Patient.Medication.IfNeeded')
                                .success(trans => {
                                    this.createAdHocPeriodicity(trans['Views.Patient.Medication.IfNeeded']);
                                })
                                .error(e => this.errorCallback(e));
                        })
                        .error(e => this.errorCallback(e));
                } else {
                   this.createAdHocPeriodicity(this.$translate.instant('Views.Patient.Medication.IfNeeded'));
                }
            }
        }

        private createAdHocPeriodicity(unStructuredPeriodicity: string) {
            this.$scope.careRequestMedication.periodicities = [];
            this.$scope.careRequestMedication.periodicities.push(this.periodicityFactory.createPeriodicitiesFromAdHoc(unStructuredPeriodicity, 1));
        }

        private minEndDate(): Date {
            return this.$scope.careRequestMedication.id && moment().isAfter(moment(this.$scope.careRequestMedication.validFromDate), 'day')
            ? this.today
            : this.$scope.careRequestMedication.validFromDate;
        }

        private confirm(): void {
            if (this.$scope.medicationForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            if (this.$scope.isForTherapyAction && !this.$scope.isAdHoc
                && (this.$scope.careRequestMedication.periodicities == null || _(this.$scope.careRequestMedication.periodicities).size() === 0)) {
                this.toaster.error(this.$translate.instant('Views.CareRequest.Details.PeriodicityRequired'));
                return;
            }
            if (this.$scope.isForTherapyAction && !this.$scope.isAdHoc
                && _(this.$scope.careRequestMedication.periodicities).any(x => _(x.intraDayTimings).size() === 0)) {
                this.toaster.error(this.$translate.instant('Views.CareRequest.Details.IntraDayTimingRequired'));
                return;
            }

            this.$scope.$close(this.$scope.careRequestMedication);
        }
    }

    remeCareCareRequestModule
        .controller('careRequestMedicationAdvancedCtrl', (
            $scope: ICareRequestMedicationAdvancedScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            $timeout: ng.ITimeoutService,
            periodicityFactory: Shared.Framework.Factory.PeriodicityFactory,
            masterdataSvc: Shared.Framework.MasterdataService,
            daySvc: Shared.Service.DayService
        ) => new CareRequestMedicationAdvancedController(
            $scope,
            $translate,
            toaster,
            $timeout,
            periodicityFactory,
            masterdataSvc,
            daySvc));
}