/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {

    interface ICompareScope extends ng.IScope {
        src: any;
        dest: any;
        getCompareClass(param: string | Array<string>): string;
        getCompareLevel(param: string | Array<string>): number;
        update(): (param: string | Array<string>) => void;
    }

    class CompareDirective implements ng.IDirective {

        transclude = true;

        restrict = 'E';

        scope: { [boundProperty: string]: string } = {
            src: '=',
            dest: '=',
            field: '=',
            update: '&',
            disableUpdate: '=',
            readOnly: '=ngDisabled'
        }

        templateUrl = 'views/careRequest/directive/compare.html';

        private getClass(compareLevel: number) {
            switch (compareLevel) {
                case 1:
                    return 'glyphicon-ok-sign green';
                case 2:
                    return 'glyphicon-question-sign grey';
                case 3:
                    return 'glyphicon-exclamation-sign yellow';
                case 4:
                    return 'glyphicon-alert red';
                default:
                    return '';
            }
        }

        private getDescendantProp(obj, desc): any {
            var arr = desc.split('.');
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }

        private compareFields(field: string, src: any, dest: any): number {
            var srcField = this.getDescendantProp(src, field);
            var destField = this.getDescendantProp(dest, field);
            if (_.isArray(srcField) && _.isArray(destField)) {
                if (srcField.length === 0 && destField.length === 0) return 0;
                if (srcField.length === destField.length &&
                    _(srcField).all(s => _(destField).contains(s)))
                    return 1;
                if (srcField.length === 0 && destField.length > 0) return 2;
                if (srcField.length > 0 && destField.length === 0) return 3;
                return 4;
            }
            if (!srcField && !destField) return 0;
            if (srcField === destField) return 1;
            if (srcField instanceof Date && destField instanceof Date && srcField.getTime() === destField.getTime()) return 1;
            if (!srcField && destField) return 2;
            if (srcField && !destField) return 3;
            return 4;
        }

        link: ng.IDirectiveLinkFn = (scope: ICompareScope) => {
            scope.getCompareLevel = (param: string | Array<string>): number => {
                if (!_.isArray(param)) {
                    return this.compareFields(param, scope.src, scope.dest);
                } else {
                    var maxLevel = 0;
                    _(param).forEach(c => {
                        var level = this.compareFields(c, scope.src, scope.dest);
                        if (level > maxLevel) maxLevel = level;
                    });
                    return maxLevel;
                }
            }

            scope.getCompareClass = (param: string | Array<string>): string => {
                return this.getClass(scope.getCompareLevel(param));
            }
        }
    }

    remeCareCareRequestModule.directive('rcCompare', () => new CompareDirective());
}