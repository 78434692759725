var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var UserAccountHistoryController = /** @class */ (function (_super) {
            __extends(UserAccountHistoryController, _super);
            function UserAccountHistoryController($scope, $translate, toaster, partyId, securityApiSvc, partyApiSvc, gridBuilderSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.partyId = partyId;
                _this.securityApiSvc = securityApiSvc;
                _this.partyApiSvc = partyApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.customTemplate = '<div class="text-center vertical-center">' +
                    '<i ng-if="row.entity.IsBlocked" class="red glyphicon glyphicon-user"></i>' +
                    '<i ng-if="!row.entity.IsBlocked" class="green glyphicon glyphicon-user"></i>' +
                    '</div>';
                return _this;
            }
            UserAccountHistoryController.prototype.$onInit = function () {
                this.buildGrid();
                this.$scope.grid.search();
            };
            UserAccountHistoryController.prototype.executeSearchAsync = function (page, pageSize) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, r, partyIds, parties_1, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 6, , 7]);
                                return [4 /*yield*/, this.securityApiSvc.searchUserAccountHistoryAsync(this.partyId, query)];
                            case 2:
                                r = _a.sent();
                                partyIds = _(r.Items)
                                    .chain()
                                    .map(function (i) { return i.StatusChangeRequestor; })
                                    .filter(function (id) { return id != null; })
                                    .value();
                                if (!(partyIds.length > 0)) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.partyApiSvc.findPartyNamesAsync(partyIds)];
                            case 3:
                                parties_1 = _a.sent();
                                _(r.Items).forEach(function (i) {
                                    var party = _.find(parties_1, function (p) { return p.Id === i.StatusChangeRequestor; });
                                    if (party != null) {
                                        i.StatusChangeRequestorName = party.Text;
                                    }
                                });
                                return [2 /*return*/, r];
                            case 4: return [2 /*return*/, r];
                            case 5: return [3 /*break*/, 7];
                            case 6:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 7];
                            case 7: return [2 /*return*/];
                        }
                    });
                });
            };
            UserAccountHistoryController.prototype.buildGrid = function () {
                var _this = this;
                this.$scope.grid = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps) { return _this.executeSearchAsync(p, ps); })
                    .addCustomColumn('', this.customTemplate, function () { }, { width: 50 })
                    .addColumn('StatusDateTime', 'General.Date', { cellFilter: 'displayUtcDateTimeFilter' })
                    .addColumn('StatusChangeRequestorName', 'General.By')
                    .addTranslateColumn('StatusChangeReason', 'General.Reason')
                    .setSorting(false)
                    .build();
                this.$scope.grid.pagingOptions.currentPage = 1;
                this.$scope.grid.pagingOptions.pageSize = 5;
            };
            return UserAccountHistoryController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('userAccountHistoryCtrl', function ($scope, $translate, toaster, partyId, securityApiSvc, partyApiSvc, gridBuilderSvc) {
            return new UserAccountHistoryController($scope, $translate, toaster, partyId, securityApiSvc, partyApiSvc, gridBuilderSvc);
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
