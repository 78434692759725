var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var PartyApiService = /** @class */ (function (_super) {
                __extends(PartyApiService, _super);
                function PartyApiService() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                /** @deprecated */
                PartyApiService.prototype.findPersons = function (query) {
                    return this.get('Party/PersonsV1', query, 'person.search');
                };
                PartyApiService.prototype.findPersonsAsync = function (query) {
                    return this.postPromise('Party/Persons', query, 'person.search');
                };
                PartyApiService.prototype.findGroups = function (query) {
                    return this.get('Party/Groups', query, 'group.search');
                };
                PartyApiService.prototype.findGroupsAsync = function (query) {
                    return this.getPromise('Party/Groups', query, 'group.search');
                };
                PartyApiService.prototype.getGroupDetailsAsync = function (id) {
                    return this.getPromise("Party/Groups/" + id, null, 'groupDetails');
                };
                PartyApiService.prototype.findPersonMergeCandidates = function (query) {
                    return this.get('Party/PersonMergeCandidates', query, 'personMergeCandidates.Search');
                };
                PartyApiService.prototype.findPartyNamesAsync = function (partyIds) {
                    return this.getPromise('Party/Names', {
                        partyIds: partyIds,
                    });
                };
                PartyApiService.prototype.getGroupsAsync = function (ids) {
                    return this.getPromise('Party/Groups/GroupAssignments', { groupIds: ids }, 'groupAssignments');
                };
                PartyApiService.prototype.getPersonsAsync = function (partyRoleIds) {
                    return this.postPromise('Party/Persons', {
                        page: 1,
                        pageSize: partyRoleIds.length,
                        partyRoleIds: partyRoleIds,
                    }).then(function (r) { return r.Items; });
                };
                PartyApiService.prototype.getPersonAsync = function (id) {
                    return this.getPromise("Party/Persons/" + id);
                };
                /** @deprecated */
                PartyApiService.prototype.getPersonDetails = function (id, includeAllContactPoints) {
                    var query = {
                        includeAllContactPoints: includeAllContactPoints,
                    };
                    return this.get("Party/Persons/" + id + "/Details", query, 'personDetails');
                };
                PartyApiService.prototype.getPersonDetailsAsync = function (id, includeAllContactPoints) {
                    var query = {
                        includeAllContactPoints: includeAllContactPoints,
                    };
                    return this.getPromise("Party/Persons/" + id + "/Details", query, 'personDetails');
                };
                PartyApiService.prototype.findAddressAsync = function (query) {
                    return this.getPromise('Party/Addresses', query, 'addresses');
                };
                PartyApiService.prototype.findPartyRoleTypes = function (partyId) {
                    return this.get("Party/Persons/" + partyId + "/PartyRoleTypes");
                };
                PartyApiService.prototype.modifyPersonalInfo = function (partyId, personalInfo) {
                    return this.post("Party/Persons/" + partyId + "/PersonalInfo", personalInfo, 'personalInfo.save');
                };
                PartyApiService.prototype.patchPersonInfo = function (partyId, personalInfo) {
                    return this.patchPromise("Party/Persons/" + partyId + "/Info", personalInfo, 'personalInfo.save');
                };
                PartyApiService.prototype.registerDateOfDeath = function (partyId, dateOfDeath) {
                    return this.post("Party/Persons/" + partyId + "/DateOfDeath", {
                        dateOfDeath: RemeCare.Shared.DateHelper.toServerDateString(dateOfDeath),
                    });
                };
                PartyApiService.prototype.saveContactPoint = function (partyId, contactPoint) {
                    if (contactPoint.id == null) {
                        return this.post("Party/Persons/" + partyId + "/ContactPoints", contactPoint, 'contactpoint');
                    }
                    else {
                        return this.put("Party/Persons/" + partyId + "/ContactPoints/" + contactPoint.id, contactPoint, 'contactpoint');
                    }
                };
                PartyApiService.prototype.mergePersons = function (partyId, mergePersonsInfo) {
                    return this.post("Party/Persons/" + partyId + "/MergePersons", mergePersonsInfo, 'mergePersons');
                };
                PartyApiService.prototype.findPersonSummaryAsync = function (partyRoleId) {
                    return this.getPromise("Party/PersonSummary/" + partyRoleId);
                };
                PartyApiService.prototype.getContactPersons = function (partyRoleId) {
                    return this.get("Party/PartyRoles/" + partyRoleId + "/ContactPersons");
                };
                PartyApiService.prototype.getOrganisationAsync = function (id) {
                    return this.getPromise("Party/Organisations/" + id);
                };
                PartyApiService.prototype.findOrganisationsAsync = function (query) {
                    return this.getPromise('Party/Organisations', query, 'organisation.search');
                };
                /** @deprecated */
                PartyApiService.prototype.getOrganisationDetails = function (id) {
                    return this.get("Party/Organisations/" + id + "/Details", null, 'organisation.details');
                };
                PartyApiService.prototype.getOrganisationDetailsAsync = function (id) {
                    return this.getPromise("Party/Organisations/" + id + "/Details", null, 'organisation.details');
                };
                PartyApiService.prototype.createPatient = function (partyId) {
                    return this.post("Party/Persons/" + partyId + "/CreatePatient", null, 'party.createPatient');
                };
                PartyApiService.prototype.createContactPersonAsync = function (patientId, contactPersonInfo) {
                    return this.postPromise("Party/PartyRoles/" + patientId + "/ContactPerson", contactPersonInfo, 'contactPerson.create');
                };
                PartyApiService.prototype.createGroupAsync = function (group) {
                    return this.postPromise('Party/Group/CreateGroup', group, 'group.create');
                };
                PartyApiService.prototype.stopGroupAsync = function (groupId) {
                    return this.postPromise("Party/Group/" + groupId + "/StopGroup/", null, 'group.stop');
                };
                PartyApiService.prototype.stopGroupAssignment = function (groupId, groupAssignmentId) {
                    return this.post("Party/Group/" + groupId + "/StopAssignment/" + groupAssignmentId, null, 'groupAssignment.stop');
                };
                PartyApiService.prototype.reActivateGroupMember = function (groupId, groupAssignmentId) {
                    return this.post("Party/Group/" + groupId + "/reActivateAssignment/" + groupAssignmentId, null);
                };
                PartyApiService.prototype.getPossibleExternalIdentityProviders = function () {
                    return this.get("Security/ExternalIdentityProviders", null, 'organisation.identityProviders');
                };
                /** @deprecated */
                PartyApiService.prototype.getLinkedExternalIdentityProviders = function (organisationId) {
                    return this.get("Party/Organisations/" + organisationId + "/ExternalIdentityProviders", null, 'organisation.identityProviders');
                };
                PartyApiService.prototype.getLinkedExternalIdentityProvidersAsync = function (organisationId) {
                    return this.getPromise("Party/Organisations/" + organisationId + "/ExternalIdentityProviders", null, 'organisation.identityProviders');
                };
                PartyApiService.prototype.linkExternalIdentityProvider = function (organisationId, externalIdentityProviderId) {
                    return this.post("Party/Organisations/" + organisationId + "/ExternalIdentityProviders", {
                        externalIdentityProviderId: externalIdentityProviderId,
                    });
                };
                PartyApiService.prototype.unlinkExternalIdentityProvider = function (organisationId, externalIdentityProviderId) {
                    return this.delete("Party/Organisations/" + organisationId + "/ExternalIdentityProviders/" + externalIdentityProviderId);
                };
                PartyApiService.prototype.getExternalIdentityProvidersForPersonAsync = function (personId) {
                    return this.getPromise("Party/Persons/" + personId + "/ExternalIdentityProviders");
                };
                PartyApiService.prototype.unlinkPersonFromExternalIdentityProviderAsync = function (personId, externalIdentityProviderId) {
                    return this.deletePromise("Party/Persons/" + personId + "/ExternalIdentityProviders/" + externalIdentityProviderId);
                };
                PartyApiService.prototype.getRelatedGroupsAsync = function (partyId) {
                    return this.getPromise("Party/RelatedGroups", { PartyId: partyId }, 'person.relatedGroups');
                };
                PartyApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
                return PartyApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.PartyApiService = PartyApiService;
            RemeCare.remeCareAppModule.service('partyApiSvc', PartyApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
