/// <reference path="careRequestPartDirectiveBase.ts"/>
/// <reference path="../../contract/core/codes/genderCode.ts"/>

namespace RemeCare.CareRequest.Directive {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;
    import GenderCode = Contract.Core.Codes.GenderCode;

    interface IPatientApplicationScope extends ICareRequestPartBaseScope {
        noNumberRegex: RegExp;
        commlanguages: EnumCodeTranslation[];
        countries: EnumCodeTranslation[];
        genders: EnumTranslation[];
        maritalStatusses: EnumTranslation[];
        educations: EnumTranslation[];
        technologicalAffinities: EnumTranslation[];
        nationalities: EnumCodeTranslation[];
        contactPersonsGrid: Shared.Framework.Grid.Grid<PatientContactPerson>;
        now: Date;

        addContactPerson(): void;
        identificationNumberChanged(): void;
        birthDateChanged(): void;
        genderChanged(): void;
        nationalityChanged(): void;
        isBirthDateValid(): boolean;
        isGenderValid(): boolean;
        isNationalityValid(): boolean;
    }

    class PatientApplicationController extends CareRequestPartControllerBase<IPatientApplicationScope> {
        // @ngInject
        constructor(
            protected $scope: IPatientApplicationScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly nationalNumberSvc: NationalNumberService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.addContactPerson = () => this.addContactPersonAsync();
            $scope.identificationNumberChanged = () => this.identificationNumberChanged();
            $scope.birthDateChanged = () => this.birthDateChanged();
            $scope.genderChanged = () => this.genderChanged();
            $scope.nationalityChanged = () => this.nationalityChanged();

            $scope.isBirthDateValid = () => this.isBirthDateValid();
            $scope.isGenderValid = () => this.isGenderValid();
            $scope.isNationalityValid = () => this.isNationalityValid();
            $scope.now = new Date();
            this.$onInit();
        }

        public $onInit(): void {
            this.$scope.noNumberRegex = Framework.Regexes.noNumberRegex;
            this.buildGrid();
            this.loadDataAsync();
        }

        public identificationNumberChanged(): void {
            this.validateIdentificationNumberAndSetProperties(true, true, true, true);
        }

        public isBirthDateValid(): boolean {
            return this.nationalNumberSvc.isValidDateOfBirth(
                this.$scope.careRequest.patient.birthDate,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public birthDateChanged(): void {
            this.validateIdentificationNumberAndSetProperties(true, false, false);
        }

        public isGenderValid(): boolean {
            return this.nationalNumberSvc.isValidGender(
                this.$scope.careRequest.patient.gender,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public genderChanged(): void {
            this.validateIdentificationNumberAndSetProperties(false, true, false);
        }

        public isNationalityValid(): boolean {
            return this.nationalNumberSvc.isValidNationalityCode(
                this.$scope.careRequest.patient.nationality,
                this.$scope.careRequest.patient.identificationNumber
            );
        }

        public nationalityChanged(): void {
            this.validateIdentificationNumberAndSetProperties(false, false, true);
        }

        private async loadDataAsync(): Promise<void> {
            try {
                const [
                    commlanguages,
                    countries,
                    genders,
                    maritalStatusses,
                    educations,
                    technologicalAffinities,
                    nationalities,
                ]: [
                    EnumCodeTranslation[],
                    EnumCodeTranslation[],
                    EnumTranslation[],
                    EnumTranslation[],
                    EnumTranslation[],
                    EnumTranslation[],
                    EnumCodeTranslation[]
                ] = await Promise.all([
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.masterdataSvc.getMaritalStatussesAsync(),
                    this.masterdataSvc.getEducationsAsync(),
                    this.masterdataSvc.getTechnologicalAffinitiesAsync(),
                    this.masterdataSvc.getNationalitiesAsync(),
                ]);

                this.$scope.commlanguages = commlanguages;
                this.$scope.countries = countries;
                this.$scope.genders = genders;
                this.$scope.maritalStatusses = maritalStatusses;
                this.$scope.educations = educations;
                this.$scope.technologicalAffinities = technologicalAffinities;
                this.$scope.nationalities = nationalities;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private validateIdentificationNumberAndSetProperties(
            checkDateOfBirth: boolean,
            checkGender: boolean,
            checkNationality: boolean,
            overwrite?: boolean
        ): void {
            const dateOfBirthGetterSetter =
                checkDateOfBirth && this.$scope.isPresent(CareRequestUIControlType.PatientBirthDate)
                    ? (dateOfBirth?: Date) => {
                          if (dateOfBirth) {
                              this.$scope.careRequest.patient.birthDate = dateOfBirth;
                          }
                          return this.$scope.careRequest.patient.birthDate;
                      }
                    : null;
            const genderGetterSetter =
                checkGender && this.$scope.isPresent(CareRequestUIControlType.PatientGender)
                    ? (gender?: GenderCode) => {
                          if (gender) {
                              this.$scope.careRequest.patient.gender = gender;
                          }
                          return this.$scope.careRequest.patient.gender;
                      }
                    : null;
            const nationalityGetterSetter =
                checkNationality && this.$scope.isPresent(CareRequestUIControlType.PatientNationality)
                    ? (nationality?: string) => {
                          if (nationality) {
                              this.$scope.careRequest.patient.nationality = nationality;
                          }
                          return this.$scope.careRequest.patient.nationality;
                      }
                    : null;
            this.nationalNumberSvc.validateAndSetProperties(
                this.$scope.careRequest.patient.identificationNumber,
                dateOfBirthGetterSetter,
                genderGetterSetter,
                null,
                nationalityGetterSetter,
                overwrite
            );
        }

        private buildGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<PatientContactPerson>()
                .addColumn('person.lastName', 'General.LastName')
                .addColumn('person.firstName', 'General.FirstName')
                .addColumn('type.Text', 'Views.CareRequest.Details.CareTeamInformal.SupportType')
                .addBoolColumn('isHCNonProfessional', 'Views.CareRequest.Details.HCNonProf');
            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn(p => this.editContactPersonAsync(p))
                    .addDeleteButtonColumn();
            }
            this.$scope.contactPersonsGrid = gridBuilder.build();
            this.$scope.contactPersonsGrid.setData(this.$scope.careRequest.patientContactPersons);
        }

        private async addContactPersonAsync(): Promise<void> {
            const newPerson = await this.editContactPersonAsync();
            this.$scope.contactPersonsGrid.addRow(newPerson);
        }

        private editContactPersonAsync(contactPerson?: PatientContactPerson): Promise<PatientContactPerson> {
            return new Promise<PatientContactPerson>((resolve, reject) => {
                this.modalBuilderFactory
                    .createModalBuilder<PatientContactPerson>()
                    .setController('contactPersonApplicationCtrl')
                    .setTemplateUrl('views/careRequest/directive/contactPersonApplication.html')
                    .setScope({
                        contactPerson: angular.copy(contactPerson) || new PatientContactPerson(),
                        careRequestPart: this.$scope.careRequestPart,
                    })
                    .setResultCallBack(p => resolve(p))
                    .setSize(Shared.Framework.Helper.ModalSize.medium)
                    .build();
            });
        }
    }

    class PatientApplicationDirective extends CareRequestPartDirectiveBase {
        public controller = PatientApplicationController;

        public templateUrl = 'views/careRequest/directive/patientApplication.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestPatientApplication', () => new PatientApplicationDirective());
}
