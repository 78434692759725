namespace RemeCare.Patient {
    import ActionType = Shared.Contract.Code.ActionType;
    import ApplicationProfileType = Shared.Contract.Code.ApplicationProfileType;
    import CarePlan = Contract.Patient.Read.ICareplan;
    import DateHelper = Shared.DateHelper;
    import Document = Shared.Contract.IMetaDataDocument;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import FormController = Shared.Framework.Directive.IFormController;
    import Guid = Shared.Contract.Guid;
    import PatientFileDocument = Contract.Patient.Read.IPatientFileDocument;

    class PatientFileDocumentController implements ng.IComponentController {
        public categories: EntityTranslation[];
        public carePlans: CarePlan[];
        public documents: Document[];
        public documentId: Guid;
        public form: FormController;
        public patientFileDocument: PatientFileDocument;
        public patientId: Guid;
        public name: string;
        public extension: string;
        public dateFilter: string;
        public actionPlannedDateTimeFrom: Date;
        public actionPlannedDateTimeUntil: Date;
        public showUntil: boolean;
        public referenceDate: Date;
        public referenceDateRequired: boolean;
        public carePlanRequired: boolean;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly patientSvc: PatientService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public async $onInit(): Promise<void> {
            try {
                [this.categories, this.carePlans] = await Promise.all([
                    this.masterdataSvc.getDocumentCategoriesAsync(),
                    this.patientSvc.getCarePlansAsync(this.patientId),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }

            this.patientFileDocument = {} as PatientFileDocument;
            if (this.documentId) {
                try {
                    this.patientFileDocument = await this.patientSvc.getDocumentAsync(this.patientId, this.documentId);
                    this.referenceDate = Shared.DateHelper.serverDateStringToDate(
                        this.patientFileDocument.ReferenceDate
                    );
                    if (this.patientFileDocument.Name) {
                        const nameSplit = this.patientFileDocument.Name.split('.');
                        this.extension = `.${nameSplit.pop()}`;
                        this.name = nameSplit.join('.');
                    }
                    if (this.patientFileDocument.ActionId) {
                        this.loadActionAsync(); // no await
                    }
                } catch (e) {
                    this.toaster.error(e);
                }
            }
            const profile = this.authservice.getProfile();
            this.carePlanRequired =
                profile === ApplicationProfileType.AdministrativeManager ||
                profile === ApplicationProfileType.TherapyProvider;
        }

        public cancel(): void {
            this.$state.go('patients.patientfile.mediaDocuments.overview');
        }

        public async save(): Promise<void> {
            this.form.$setSubmitted();
            if (this.form.$invalid) {
                this.form.showValidationErrorMessage();
                return;
            }
            let id = this.documentId;
            this.patientFileDocument.Name = this.name + this.extension;
            if (!this.documentId) {
                const document = this.documents[0];
                id = document.Id;
                this.patientFileDocument.Name = document.Name;
                this.patientFileDocument.Url = document.Url;
                this.patientFileDocument.Size = document.Size;
            }
            this.patientFileDocument.ReferenceDate = Shared.DateHelper.toServerDateString(this.referenceDate);
            try {
                await this.patientSvc.saveDocumentAsync(this.patientId, id, this.patientFileDocument);
                this.$state.go('patients.patientfile.mediaDocuments.overview');
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadActionAsync(): Promise<void> {
            try {
                const action = await this.carePlanActionApiSvc.getCarePlanActionAsync(
                    this.patientFileDocument.ActionId
                );
                if (action == null) {
                    return;
                }
                this.actionPlannedDateTimeFrom = DateHelper.serverDateStringToDateTime(action.PlannedDateTimeFrom);
                this.actionPlannedDateTimeUntil = DateHelper.serverDateStringToDateTime(action.PlannedDateTimeUntil);
                this.dateFilter =
                    this.actionPlannedDateTimeFrom.getHours() !== 0 ||
                    this.actionPlannedDateTimeFrom.getMinutes() !== 0 ||
                    this.actionPlannedDateTimeUntil.getHours() !== 0 ||
                    this.actionPlannedDateTimeUntil.getMinutes() !== 0
                        ? 'short'
                        : 'shortDate';
                this.showUntil = this.actionPlannedDateTimeFrom.valueOf() !== this.actionPlannedDateTimeUntil.valueOf();
                this.referenceDateRequired = _.find(
                    action.ActionParts,
                    ap => ap.ActionType.Id === ActionType.RequestInformationObject
                ).RequestInfoParameter.HasReferenceDate;
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.component('patientFileDocument', {
        controller: PatientFileDocumentController,
        controllerAs: 'documentCtrl',
        templateUrl: 'views/patient/mediaDocuments/patientFileDocument.html',
        bindings: {
            documentId: '@?',
            patientId: '@',
        },
    });
}
