/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import QuantitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint;
    import QuantitativeReferencePoint = Contract.CarePlan.Read.Query.IQuantitativeReferencePoint;
    import Boxplot = Shared.Framework.Model.Boxplot;
    import ChartLine = Shared.Framework.Model.ChartLine;
    import IParameter = Contract.CarePlan.Read.IParameter;
    import BoxplotDataType = Shared.Contract.Code.BoxplotDataType;

    class BoxplotController extends ChartMonitoringPartController {
        private minParameter: QuantitativeMeasuringPointParameter;
        private firstQuartileParameter: QuantitativeMeasuringPointParameter;
        private medianParameter: QuantitativeMeasuringPointParameter;
        private thirdQuartileParameter: QuantitativeMeasuringPointParameter;
        private maxParameter: QuantitativeMeasuringPointParameter;
        private meanParameter: QuantitativeMeasuringPointParameter;
        private quantitativeMeasuringPoints: QuantitativeMeasuringPoint[];
        private quantitativeReferencePoints: QuantitativeReferencePoint[];
        private showMultiplePerDay: boolean;

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly $q: ng.IQService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly $translate: ng.translate.ITranslateService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            const parameters = _(this.monitoringPart.monitoringPartSources).filter(
                (mps) => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter
            );

            this.minParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.Minimum
            ) as QuantitativeMeasuringPointParameter;
            this.firstQuartileParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.FirstQuartile
            ) as QuantitativeMeasuringPointParameter;
            this.medianParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.Median
            ) as QuantitativeMeasuringPointParameter;
            this.thirdQuartileParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.LastQuartile
            ) as QuantitativeMeasuringPointParameter;
            this.maxParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.Maximum
            ) as QuantitativeMeasuringPointParameter;
            this.meanParameter = _.find(
                parameters,
                (p: MonitoringPartSourceParameter) => p.sequence === BoxplotDataType.Mean
            ) as QuantitativeMeasuringPointParameter;

            this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
            this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();

            this.chartConfigs = [
                {
                    options: {
                        chart: {
                            animation: false,
                            type: 'line',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'xy',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(this.showMultiplePerDay),
                            shared: true,
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: [],
                    title: {
                        text: null,
                    },
                } as HighChartsNGConfig,
            ];
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            const oneMonthEarlier = moment(this.dateInfo.untilDate);
            oneMonthEarlier.subtract(1, 'months');
            this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
            this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(this.showMultiplePerDay);
            const pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(
                this.carePlanIds,
                this.quantitativeMeasuringPoints,
                this.dateInfo.fromDate,
                this.getUntilDate(),
                this.showMultiplePerDay
            );
            const referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(
                this.carePlanIds,
                this.quantitativeReferencePoints,
                this.dateInfo.fromDate,
                this.getUntilDate()
            );
            let graphPoints: Array<Contract.Core.IGraph<IParameter<number>>>;
            let referencePoints: Array<Contract.Core.IGraph<number>>;
            [graphPoints, referencePoints] = await Promise.all([pointsPromise, referencePromise]);
            const min = _.find(
                graphPoints,
                (g) =>
                    g.Subject.Id === this.minParameter.characteristic.Id &&
                    ((g.Scale == null && this.minParameter.unit == null) ||
                        (g.Scale != null && this.minParameter.unit != null && g.Scale.Id === this.minParameter.unit.Id))
            );
            const first = _.find(
                graphPoints,
                (g) =>
                    g.Subject.Id === this.firstQuartileParameter.characteristic.Id &&
                    ((g.Scale == null && this.firstQuartileParameter.unit == null) ||
                        (g.Scale != null &&
                            this.firstQuartileParameter.unit != null &&
                            g.Scale.Id === this.firstQuartileParameter.unit.Id))
            );
            const med = _.find(
                graphPoints,
                (g) =>
                    g.Subject.Id === this.medianParameter.characteristic.Id &&
                    ((g.Scale == null && this.medianParameter.unit == null) ||
                        (g.Scale != null &&
                            this.medianParameter.unit != null &&
                            g.Scale.Id === this.medianParameter.unit.Id))
            );
            const third = _.find(
                graphPoints,
                (g) =>
                    g.Subject.Id === this.thirdQuartileParameter.characteristic.Id &&
                    ((g.Scale == null && this.thirdQuartileParameter.unit == null) ||
                        (g.Scale != null &&
                            this.thirdQuartileParameter.unit != null &&
                            g.Scale.Id === this.thirdQuartileParameter.unit.Id))
            );
            const max = _.find(
                graphPoints,
                (g) =>
                    g.Subject.Id === this.maxParameter.characteristic.Id &&
                    ((g.Scale == null && this.maxParameter.unit == null) ||
                        (g.Scale != null && this.maxParameter.unit != null && g.Scale.Id === this.maxParameter.unit.Id))
            );
            let meanGraph = null;
            if (this.meanParameter) {
                const mean = _.find(
                    graphPoints,
                    (g) =>
                        g.Subject.Id === this.meanParameter.characteristic.Id &&
                        ((g.Scale == null && this.meanParameter.unit == null) ||
                            (g.Scale != null &&
                                this.meanParameter.unit != null &&
                                g.Scale.Id === this.meanParameter.unit.Id))
                );
                meanGraph = new Model.NumberParameterGraph(mean);
            }
            const graph = new Model.Boxplot(min, first, med, third, max);
            const references = _(referencePoints).map((g) => new Model.NumberGraph(g, true, true));
            this.configureChart(graph, references, meanGraph);
        }
        protected containsData(config: HighChartsNGConfig): boolean {
            return !_(config.series).all((s) =>
                _(s.data as HighchartsDataPoint[]).all((d) => d.high == null && d.low == null)
            );
        }

        private configureChart(
            graph: Model.Boxplot,
            references: Model.NumberGraph[],
            meanGraph?: Model.NumberParameterGraph
        ): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
            const yAxis = this.getYAxis(graph, references, meanGraph);
            this.chartConfigs[0].options.yAxis = yAxis;
            let series = _(references).map((r) => this.getLineDataSeries(r, true, yAxis));
            if (meanGraph) {
                series = series.concat(this.getLineDataSeries(meanGraph, false, yAxis));
            }
            series.unshift(this.getBoxplotSeries(graph));
            this.chartConfigs[0].series = series;
            this.chartConfigs[0].loading = false;
        }

        private getYAxis(
            graph: Model.Boxplot,
            references: Model.NumberGraph[],
            meanGraph?: Model.NumberParameterGraph
        ): HighchartsAxisOptions {
            const maxes = _(references)
                .chain()
                .map((r) => this.getMaxYScaleValue(r))
                .filter((m) => m != null)
                .value();
            maxes.push(this.getMaxYScaleValue(graph));
            const mins = _(references)
                .chain()
                .map((r) => this.getMinYScaleValue(r))
                .filter((m) => m != null)
                .value();
            mins.push(this.getMinYScaleValue(graph));
            if (meanGraph) {
                maxes.push(this.getMaxYScaleValue(meanGraph));
                mins.push(this.getMinYScaleValue(meanGraph));
            }
            const max = _(maxes).max();
            const min = _(mins).min();
            return {
                title: {
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    text: graph.scale != null ? graph.scale.Text : null,
                    y: -20,
                    x: -27,
                    textAlign: 'left',
                },
                max: max,
                min: min,
                id: graph.scale != null ? (graph.scale.Id as string) : 'unscaled',
                startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                labels: {
                    align: 'right',
                    x: 0,
                    y: -2,
                },
            } as HighchartsAxisOptions;
        }

        private getBoxplotSeries(graph: Model.Boxplot): HighchartsColumnRangeChartSeriesOptions {
            const monitoringPartSource = _.find(
                this.monitoringPart.monitoringPartSources,
                (mps) =>
                    mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                    mps.hasCharacteristicAndUnit(graph.subject, graph.scale)
            ) as MonitoringPartSourceParameter;
            const boxplotRep = monitoringPartSource.sourceParameterRepresentation as Boxplot;
            return {
                type: 'boxplot',
                animation: false,
                data: _(graph.graphPoints)
                    .chain()
                    .map((gp) => this.getDataBoxplot(gp))
                    .sortBy((gp) => gp.x)
                    .value(),
                color: boxplotRep.colour,
                name: graph.subject.Text,
                dataLabels: {
                    xHigh: -2,
                    xLow: -2,
                    yLow: -13,
                    yHigh: 13,
                    defer: true,
                },
                tooltip: {
                    pointFormat:
                        '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                        this.$translate.instant('Configuration.Therapies.Monitoring.Maximum') +
                        ': {point.high}<br/>' +
                        this.$translate.instant('Configuration.Therapies.Monitoring.ThirdQuartile') +
                        ': {point.q3}<br/>' +
                        this.$translate.instant('Configuration.Therapies.Monitoring.Median') +
                        ': {point.median}<br/>' +
                        this.$translate.instant('Configuration.Therapies.Monitoring.FirstQuartile') +
                        ': {point.q1}<br/>' +
                        this.$translate.instant('Configuration.Therapies.Monitoring.Minimum') +
                        ': {point.low}<br/>',
                },
                pointPlacement: 'on',
            } as HighchartsColumnRangeChartSeriesOptions;
        }

        private getLineDataSeries(
            graph: Model.NumericGraph<number | IParameter<number>>,
            isReference: boolean,
            yAxis: HighchartsAxisOptions
        ): HighchartsLineChartSeriesOptions {
            let monitoringPartSource: MonitoringPartSourceParameter;
            if (isReference) {
                monitoringPartSource = _.find(
                    this.monitoringPart.monitoringPartSources,
                    (mps) =>
                        (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                            mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                        mps.hasObjective(graph.subject) ||
                        mps.hasRuleThreshold(graph.subject)
                ) as MonitoringPartSourceParameter;
            } else {
                monitoringPartSource = _.find(
                    this.monitoringPart.monitoringPartSources,
                    (mps) =>
                        mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale)
                ) as MonitoringPartSourceParameter;
            }
            const chartLine = monitoringPartSource.sourceParameterRepresentation as ChartLine;
            return {
                animation: false,
                data: _(graph.graphPoints)
                    .chain()
                    .map((gp) => this.getDataPoint(gp))
                    .sortBy((gp) => gp[0])
                    .value(),
                color: chartLine.colour,
                name: graph.subject.Text,
                lineWidth: chartLine.lineType.Id === Shared.Contract.Code.LineType.Thin ? 1 : 2,
                dashStyle: chartLine.lineType.Id === Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                yAxis: yAxis.id,
                step: isReference,
                tooltip: {
                    xDateFormat: this.getDateFormat(this.showMultiplePerDay && !isReference),
                },
                marker: {
                    enabled: !isReference,
                    symbol: isReference ? 'circle' : null,
                    radius: isReference ? 1 : 4,
                },
            } as HighchartsLineChartSeriesOptions;
        }

        private getDataBoxplot(
            graphPoint: Model.GraphPoint<
                Date,
                {
                    min: IParameter<number>;
                    firstQ: IParameter<number>;
                    median: IParameter<number>;
                    thirdQ: IParameter<number>;
                    max: IParameter<number>;
                }
            >
        ): HighchartsDataPoint {
            const minExceedsThreshold = graphPoint.y.min != null && graphPoint.y.min.ExceedsThreshold;
            const firstExceedsThreshold = graphPoint.y.firstQ != null && graphPoint.y.firstQ.ExceedsThreshold;
            const medExceedsThreshold = graphPoint.y.median != null && graphPoint.y.median.ExceedsThreshold;
            const thirdExceedsThreshold = graphPoint.y.thirdQ != null && graphPoint.y.thirdQ.ExceedsThreshold;
            const maxExceedsThreshold = graphPoint.y.max != null && graphPoint.y.max.ExceedsThreshold;
            const warning = '<i class="fa fa-exclamation-triangle"></i>';
            return {
                x: moment(graphPoint.x).valueOf(),
                low: graphPoint.y.min != null ? graphPoint.y.min.Value : null,
                q1: graphPoint.y.firstQ != null ? graphPoint.y.firstQ.Value : null,
                median: graphPoint.y.median != null ? graphPoint.y.median.Value : null,
                q3: graphPoint.y.thirdQ != null ? graphPoint.y.thirdQ.Value : null,
                high: graphPoint.y.max != null ? graphPoint.y.max.Value : null,
                dataLabels:
                    minExceedsThreshold ||
                    firstExceedsThreshold ||
                    medExceedsThreshold ||
                    thirdExceedsThreshold ||
                    maxExceedsThreshold
                        ? ({
                              enabled: true,
                              // ReSharper disable SuspiciousThisUsage
                              // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                              formatter: function() {
                                  if (this.y === this.point.low && minExceedsThreshold) {
                                      return warning;
                                  } else if (this.y === this.point.q1 && firstExceedsThreshold) {
                                      return warning;
                                  } else if (this.y === this.point.median && medExceedsThreshold) {
                                      return warning;
                                  } else if (this.y === this.point.q3 && thirdExceedsThreshold) {
                                      return warning;
                                  } else if (this.y === this.point.high && maxExceedsThreshold) {
                                      return warning;
                                  }
                                  return '';
                              },
                              // ReSharper restore SuspiciousThisUsage
                              style: {
                                  color: 'red',
                              },
                              useHTML: true,
                          } as HighchartsRangeDataLabels)
                        : null,
            } as HighchartsDataPoint;
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number | IParameter<number>>): HighchartsDataPoint {
            let y: number;
            let exceedsThreshold: boolean;
            if (_.isNumber(graphPoint.y)) {
                y = (graphPoint as Model.GraphPoint<Date, number>).y;
                exceedsThreshold = false;
            } else {
                y = (graphPoint as Model.GraphPoint<Date, IParameter<number>>).y.Value;
                exceedsThreshold = (graphPoint as Model.GraphPoint<Date, IParameter<number>>).y.ExceedsThreshold;
            }
            return {
                x: moment(graphPoint.x).valueOf(),
                y: y,
                marker: exceedsThreshold
                    ? {
                          symbol: 'text:\uf071',
                          fillColor: '#d9534f',
                      }
                    : null,
            };
        }
    }

    class BoxplotComponent extends MonitoringPartComponentBase {
        public controller = BoxplotController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringBoxplot', new BoxplotComponent());
}
