/// <reference path="careRequestPartDirectiveBase.ts"/>
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CompareDirective = /** @class */ (function () {
                function CompareDirective() {
                    var _this = this;
                    this.transclude = true;
                    this.restrict = 'E';
                    this.scope = {
                        src: '=',
                        dest: '=',
                        field: '=',
                        update: '&',
                        disableUpdate: '=',
                        readOnly: '=ngDisabled'
                    };
                    this.templateUrl = 'views/careRequest/directive/compare.html';
                    this.link = function (scope) {
                        scope.getCompareLevel = function (param) {
                            if (!_.isArray(param)) {
                                return _this.compareFields(param, scope.src, scope.dest);
                            }
                            else {
                                var maxLevel = 0;
                                _(param).forEach(function (c) {
                                    var level = _this.compareFields(c, scope.src, scope.dest);
                                    if (level > maxLevel)
                                        maxLevel = level;
                                });
                                return maxLevel;
                            }
                        };
                        scope.getCompareClass = function (param) {
                            return _this.getClass(scope.getCompareLevel(param));
                        };
                    };
                }
                CompareDirective.prototype.getClass = function (compareLevel) {
                    switch (compareLevel) {
                        case 1:
                            return 'glyphicon-ok-sign green';
                        case 2:
                            return 'glyphicon-question-sign grey';
                        case 3:
                            return 'glyphicon-exclamation-sign yellow';
                        case 4:
                            return 'glyphicon-alert red';
                        default:
                            return '';
                    }
                };
                CompareDirective.prototype.getDescendantProp = function (obj, desc) {
                    var arr = desc.split('.');
                    while (arr.length && (obj = obj[arr.shift()]))
                        ;
                    return obj;
                };
                CompareDirective.prototype.compareFields = function (field, src, dest) {
                    var srcField = this.getDescendantProp(src, field);
                    var destField = this.getDescendantProp(dest, field);
                    if (_.isArray(srcField) && _.isArray(destField)) {
                        if (srcField.length === 0 && destField.length === 0)
                            return 0;
                        if (srcField.length === destField.length &&
                            _(srcField).all(function (s) { return _(destField).contains(s); }))
                            return 1;
                        if (srcField.length === 0 && destField.length > 0)
                            return 2;
                        if (srcField.length > 0 && destField.length === 0)
                            return 3;
                        return 4;
                    }
                    if (!srcField && !destField)
                        return 0;
                    if (srcField === destField)
                        return 1;
                    if (srcField instanceof Date && destField instanceof Date && srcField.getTime() === destField.getTime())
                        return 1;
                    if (!srcField && destField)
                        return 2;
                    if (srcField && !destField)
                        return 3;
                    return 4;
                };
                return CompareDirective;
            }());
            CareRequest.remeCareCareRequestModule.directive('rcCompare', function () { return new CompareDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
