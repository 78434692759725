namespace RemeCare.Core.Services {
    import Guid = Shared.Contract.Guid;
    import VersionValidityChange = Shared.Contract.Write.IVersionValidityChange;
    import IParameter = Contract.CarePlan.Read.IParameter;
    import ILastAndNextDate = Contract.CarePlan.Read.ILastAndNextDate;

    export class CarePlanApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public findAssignablePartiesAsync(
            patientId: Guid,
            actionTemplateId: Guid
        ): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise(`ActionTemplates/${actionTemplateId}/AssignableParties?patientId=${patientId}`);
        }

        public findQuantitativeGraphPointsAsync(
            carePlanIds: Guid[],
            quantitativeMeasuringPoints: Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint[],
            fromDate?: Date,
            untilDate?: Date,
            multiplePerDay?: boolean
        ): Promise<Array<Contract.Core.IGraph<IParameter<number>>>> {
            return this.getInParts('CarePlans/QuantitativeGraphPoints', quantitativeMeasuringPoints, 10, subset => {
                const query: Contract.CarePlan.Read.Query.IFindQuantitativeGraphPointsQuery = {
                    carePlanIds,
                    quantitativeMeasuringPoints: subset,
                    fromDate: Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: Shared.DateHelper.toServerDateString(untilDate),
                    multiplePerDay,
                };
                return query;
            });
        }

        public findQuantitativeReferencePointsAsync(
            carePlanIds: Guid[],
            quantitativeReferencePoints: Contract.CarePlan.Read.Query.IQuantitativeReferencePoint[],
            fromDate?: Date,
            untilDate?: Date
        ): Promise<Array<Contract.Core.IGraph<number>>> {
            return this.getInParts('CarePlans/QuantitativeReferencePoints', quantitativeReferencePoints, 10, subset => {
                const query: Contract.CarePlan.Read.Query.IFindQuantitativeReferencePointsQuery = {
                    carePlanIds,
                    quantitativeReferencePoints: subset,
                    fromDate: Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: Shared.DateHelper.toServerDateString(untilDate),
                };
                return query;
            });
        }

        public findQualitativeGraphPointsAsync(
            carePlanIds: Guid[],
            qualitativeMeasuringPoints: Contract.CarePlan.Read.Query.IQualitativeMeasuringPoint[],
            fromDate?: Date,
            untilDate?: Date,
            multiplePerDay?: boolean
        ): Promise<Array<Contract.Core.IGraph<Shared.Contract.ICodedEntityTranslation[]>>> {
            return this.getInParts('CarePlans/QualitativeGraphPoints', qualitativeMeasuringPoints, 10, subset => {
                const query: Contract.CarePlan.Read.Query.IFindQualitativeGraphPointsQuery = {
                    carePlanIds,
                    qualitativeMeasuringPoints: subset,
                    fromDate: Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: Shared.DateHelper.toServerDateString(untilDate),
                    multiplePerDay,
                };
                return query;
            });
        }

        public findParametersAsync(
            carePlanIds: Guid[],
            parameters: Contract.CarePlan.Read.Query.IParametersParameter[],
            page: number,
            pageSize: number,
            fromDate?: Date,
            untilDate?: Date
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlan.Read.IParameters>> {
            const query: Contract.CarePlan.Read.Query.IFindParametersQuery = {
                page,
                pageSize,
                carePlanIds,
                params: parameters,
                fromDate: Shared.DateHelper.toServerDateString(fromDate),
                untilDate: Shared.DateHelper.toServerDateString(untilDate),
            };
            return this.getPromise<Shared.Contract.ISearchResult<Contract.CarePlan.Read.IParameters>>(
                `CarePlans/Parameters`,
                query
            );
        }

        public getObjectiveScoreAsync(
            carePlanId: Guid,
            objectiveId: Guid,
            fromDate?: Date,
            untilDate?: Date,
            page?: number,
            pageSize?: number
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlan.Read.IObjectiveScoreDetail>> {
            const params = {
                page: page,
                pageSize: pageSize,
                fromDate: Shared.DateHelper.toServerDateString(fromDate),
                untilDate: Shared.DateHelper.toServerDateString(untilDate),
            };
            return this.getPromise<Shared.Contract.ISearchResult<Contract.CarePlan.Read.IObjectiveScoreDetail>>(
                `CarePlans/${carePlanId}/ObjectiveScores/${objectiveId}`,
                params
            );
        }

        public getCarePlanParameterValuesAsync(
            carePlanId: Guid | Guid[]
        ): Promise<Contract.Patient.Read.ICarePlanParameterValue[]> {
            return _.isArray(carePlanId)
                ? this.getPromise(
                      `CarePlans/CarePlanParameterValues`,
                      { carePlanIds: carePlanId },
                      'careplan.parameters'
                  )
                : this.getPromise(`CarePlans/${carePlanId}/CarePlanParameterValues`, null, 'careplan.parameters');
        }

        public getCarePlanStringAsync(
            carePlanId: Guid,
            definitionId: Guid
        ): Promise<Contract.Patient.Read.ICarePlanString[]> {
            return this.getPromise(`CarePlans/${carePlanId}/CarePlanStrings/${definitionId}`);
        }

        public saveCarePlanStringAsync(
            carePlanId: Guid,
            definitionId: Guid,
            carePlanString: Contract.Patient.Write.ICarePlanString
        ): Promise<void> {
            return this.postPromise(
                `CarePlans/${carePlanId}/CarePlanStrings/${definitionId}`,
                carePlanString,
                'saveCarePlanDetail'
            );
        }

        public deleteCarePlanStringAsync(carePlanId: Guid, definitionId: Guid, pointInTime: string): Promise<void> {
            return this.deletePromise(`CarePlans/${carePlanId}/CarePlanStrings/${definitionId}/${pointInTime}`);
        }

        public changeCarePlanStringValidityAsync(
            carePlanId: Guid,
            definitionId: Guid,
            versionValidityChange: VersionValidityChange
        ): Promise<void> {
            const url = `CarePlans/${carePlanId}/CarePlanStrings/${definitionId}/${versionValidityChange.PointInTime}`;
            return this.putPromise(url, versionValidityChange);
        }

        public getCarePlanAnamnesesAsync(
            carePlanId: Guid,
            definitionId: Guid,
            date?: Date
        ): Promise<Contract.Patient.Read.ICarePlanAnamnesis[]> {
            return this.getPromise(`CarePlans/${carePlanId}/CarePlanAnamneses/${definitionId}`, {
                validityDate: Shared.DateHelper.toServerDateString(date),
            });
        }

        public saveCarePlanAnamnesisAsync(
            carePlanId: Guid,
            definitionId: Guid,
            carePlanAnamnesis: Contract.Patient.Write.ICarePlanAnamnesis
        ): Promise<void> {
            return this.postPromise(
                `CarePlans/${carePlanId}/CarePlanAnamneses/${definitionId}`,
                carePlanAnamnesis,
                'saveCarePlanDetail'
            );
        }

        public deleteCarePlanAnamnesisAsync(carePlanId: Guid, definitionId: Guid, pointInTime: string): Promise<void> {
            return this.deletePromise(`CarePlans/${carePlanId}/CarePlanAnamneses/${definitionId}/${pointInTime}`);
        }

        public changeCarePlanAnamnesisValidityAsync(
            carePlanId: Guid,
            definitionId: Guid,
            versionValidityChange: VersionValidityChange
        ): Promise<void> {
            const url = `CarePlans/${carePlanId}/CarePlanAnamneses/${definitionId}/${
                versionValidityChange.PointInTime
            }`;
            return this.putPromise(url, versionValidityChange);
        }

        public getCarePlanDatesAsync(
            carePlanId: Guid,
            definitionId: Guid
        ): Promise<Contract.Patient.Read.ICarePlanDate[]> {
            return this.getPromise(`CarePlans/${carePlanId}/CarePlanDates/${definitionId}`);
        }

        public saveCarePlanDateAsync(
            carePlanId: Guid,
            definitionId: Guid,
            carePlanDate: Contract.Patient.Write.ICarePlanDate
        ): Promise<void> {
            return this.postPromise(
                `CarePlans/${carePlanId}/CarePlanDates/${definitionId}`,
                carePlanDate,
                'saveCarePlanDetail'
            );
        }

        public deleteCarePlanDateAsync(carePlanId: Guid, definitionId: Guid, pointInTime: string): Promise<void> {
            return this.deletePromise(`CarePlans/${carePlanId}/CarePlanDates/${definitionId}/${pointInTime}`);
        }

        public changeCarePlanDateValidityAsync(
            carePlanId: Guid,
            definitionId: Guid,
            versionValidityChange: VersionValidityChange
        ): Promise<void> {
            const url = `CarePlans/${carePlanId}/CarePlanDates/${definitionId}/${versionValidityChange.PointInTime}`;
            return this.putPromise(url, versionValidityChange);
        }

        public getCarePlanTherapyOptionsAsync(
            carePlanId: Guid,
            definitionId: Guid
        ): Promise<Contract.Patient.Read.ICarePlanTherapyOption[]> {
            return this.getPromise(`CarePlans/${carePlanId}/CarePlanTherapyOptions/${definitionId}`);
        }

        public saveCarePlanTherapyOptionAsync(
            carePlanId: Guid,
            definitionId: Guid,
            carePlanTherapyOption: Contract.Patient.Write.ICarePlanTherapyOption
        ): Promise<void> {
            return this.postPromise(
                `CarePlans/${carePlanId}/CarePlanTherapyOptions/${definitionId}`,
                carePlanTherapyOption,
                'saveCarePlanDetail'
            );
        }

        public deleteCarePlanTherapyOptionAsync(
            carePlanId: Guid,
            definitionId: Guid,
            pointInTime: string
        ): Promise<void> {
            return this.deletePromise(`CarePlans/${carePlanId}/CarePlanTherapyOptions/${definitionId}/${pointInTime}`);
        }

        public changeCarePlanTherapyOptionValidityAsync(
            carePlanId: Guid,
            definitionId: Guid,
            versionValidityChange: VersionValidityChange
        ): Promise<void> {
            const url = `CarePlans/${carePlanId}/CarePlanTherapyOptions/${definitionId}/${
                versionValidityChange.PointInTime
            }`;
            return this.putPromise(url, versionValidityChange);
        }

        public getObjectivesAsync(carePlanId: Guid): Promise<Shared.Contract.Read.IObjectiveDefinition[]> {
            return this.getPromise(`CarePlans/${carePlanId}/Objectives`);
        }

        public getObjectiveAsync(
            carePlanId: Guid,
            objectiveId: Guid
        ): Promise<Shared.Contract.Read.IObjectiveDefinition> {
            return this.getPromise(`CarePlans/${carePlanId}/Objectives/${objectiveId}`);
        }

        public savePersonalisedObjectiveValueAsync(
            carePlanId: Guid,
            objectiveId: Guid,
            objectiveValue: Shared.Contract.Write.IObjectiveValue
        ): Promise<{}> {
            return this.postPromise(
                `CarePlans/${carePlanId}/Objectives/${objectiveId}`,
                objectiveValue,
                'saveObjective'
            );
        }

        public updatePersonalisedObjectiveValueAsync(
            carePlanId: Guid,
            objectiveId: Guid,
            objectiveValue: Shared.Contract.Write.IObjectiveValue
        ): Promise<{}> {
            return this.putPromise(
                `CarePlans/${carePlanId}/Objectives/${objectiveId}`,
                objectiveValue,
                'saveObjective'
            );
        }

        public deletePersonalisedObjectiveValueAsync(
            carePlanId: Guid,
            objectiveId: Guid,
            objectiveValueId: Guid
        ): Promise<{}> {
            return this.deletePromise(
                `CarePlans/${carePlanId}/Objectives/${objectiveId}/${objectiveValueId}`,
                'saveObjective'
            );
        }

        public getRuleThresholdsAsync(carePlanId: Guid): Promise<Shared.Contract.Read.IRuleThresholdDefinition[]> {
            return this.getPromise(`CarePlans/${carePlanId}/RuleThresholds`);
        }

        public getRuleThreshold(
            carePlanId: Guid,
            ruleThresholdId: Guid
        ): ng.IHttpPromise<Shared.Contract.Read.IRuleThresholdDefinition> {
            return this.get(`CarePlans/${carePlanId}/RuleThresholds/${ruleThresholdId}`);
        }

        public savePersonalisedRuleThresholdValue(
            carePlanId: Guid,
            ruleThresholdId: Guid,
            ruleThresholdValue: Shared.Contract.Write.IRuleThresholdValue
        ): ng.IHttpPromise<{}> {
            return this.post(
                `CarePlans/${carePlanId}/RuleThresholds/${ruleThresholdId}`,
                ruleThresholdValue,
                'saveRuleThreshold'
            );
        }

        public updatePersonalisedRuleThresholdValue(
            carePlanId: Guid,
            ruleThresholdId: Guid,
            ruleThresholdValue: Shared.Contract.Write.IRuleThresholdValue
        ): ng.IHttpPromise<{}> {
            return this.put(
                `CarePlans/${carePlanId}/RuleThresholds/${ruleThresholdId}`,
                ruleThresholdValue,
                'saveRuleThreshold'
            );
        }

        public deletePersonalisedRuleThresholdValue(
            carePlanId: Guid,
            ruleThresholdId: Guid,
            ruleThresholdValue: Guid
        ): ng.IHttpPromise<{}> {
            return this.delete(
                `CarePlans/${carePlanId}/RuleThresholds/${ruleThresholdId}/${ruleThresholdValue}`,
                'saveRuleThreshold'
            );
        }

        public getMediaLibItemsAsync(carePlanId: Guid): Promise<Contract.Patient.Read.ICarePlanMediaLibItem[]> {
            return this.getPromise(`CarePlans/${carePlanId}/MediaLibItems`);
        }

        public switchMediaLibItemsAsync(carePlanId: Guid, itemId1: Guid, itemId2: Guid): Promise<void> {
            var url = `CarePlans/${carePlanId}/SwitchMediaLibItems`;
            return this.postPromise(
                url,
                {
                    id1: itemId1,
                    id2: itemId2,
                },
                'switchMediaLibItems'
            );
        }

        public getPersonalisedActionAsync(
            carePlanId: Guid,
            structuralTherapyActionId: Guid
        ): Promise<Contract.CarePlan.Read.IStructuralTherapyActionPersonalisation> {
            return this.getPromise(
                `CarePlans/${carePlanId}/StructuralTherapyActionPersonalisation/${structuralTherapyActionId}`,
                null,
                'getPersonalisedTherapyAction'
            );
        }

        public setPersonalisedTherapyActionAsync(
            carePlanId: Guid,
            structuralTherapyActionId: Guid,
            peronalisedTherapyAction: Contract.CarePlan.Write.IPersonalisedTherapyAction
        ): Promise<{}> {
            const url = `CarePlans/${carePlanId}/PersonalisedTherapyAction/${structuralTherapyActionId}`;
            return this.postPromise(url, peronalisedTherapyAction, 'setPersonalisedTherapyAction');
        }

        public deletePersonalisedTherapyActionAsync(
            carePlanId: Guid,
            structuralTherapyActionId: Guid,
            date: Date
        ): Promise<{}> {
            const url = `CarePlans/${carePlanId}/PersonalisedTherapyAction/${structuralTherapyActionId}/${Shared.DateHelper.toServerDateString(
                date
            )}`;
            return this.deletePromise(url);
        }

        public setPersonalisedTherapyActionContextSetting(
            carePlanId: Guid,
            structuralTherapyActionId: Guid,
            personalisedTherapyActionContextSetting: Contract.CarePlan.Write.IPersonalisedTherapyActionContextSetting
        ): ng.IHttpPromise<{}> {
            const url = `CarePlans/${carePlanId}/PersonalisedTherapyActionContextSetting/${structuralTherapyActionId}`;
            return this.post(url, personalisedTherapyActionContextSetting, 'personalisedContextSetting');
        }

        public deletePersonalisedTherapyActionContextSetting(
            carePlanId: Guid,
            structuralTherapyActionId: Guid,
            date: Date
        ): ng.IHttpPromise<{}> {
            const url = `CarePlans/${carePlanId}/PersonalisedTherapyActionContextSetting/${structuralTherapyActionId}/${Shared.DateHelper.toServerDateString(
                date
            )}`;
            return this.delete(url);
        }

        public getPersonalisableStructuralTherapyActionsAsync(
            carePlanId: Guid
        ): Promise<Contract.Therapy.Read.IStructuralTherapyActionSummary[]> {
            return this.getPromise(
                `CarePlans/${carePlanId}/PersonalisableStructuralTherapyActions`,
                null,
                'getPersonalisableStructuralTherapyActions'
            );
        }

        public changeCarePlanStatus(carePlanId: Guid, reasonId: Guid, statusCode: number): ng.IHttpPromise<{}> {
            const params = { carePlanId: carePlanId, reasonId: reasonId, statusCode: statusCode };
            return this.post(`CarePlans/${carePlanId}/Status`, params, 'careplan.status');
        }

        public changeCarePlanStartDate(carePlanId: Guid, newStartDate: string): ng.IHttpPromise<{}> {
            const params = { newStartDate: newStartDate };
            return this.put(`CarePlans/${carePlanId}/StartDate`, params);
        }

        public getGroupRelatedCarePlansAsync(groupId: Guid): Promise<Contract.Patient.Read.ICareplan[]> {
            return this.getPromise(`CarePlans/Groups/${groupId}/CarePlans`, null, 'linkedThroughGroup');
        }

        public getNextAndLastScheduledDateAsync(
            structuralTherapyActionId: Guid,
            carePlanId: Guid,
            perspectiveDateTime: string
        ): Promise<ILastAndNextDate> {
            const params = {
                structuralTherapyActionId: structuralTherapyActionId,
                carePlanId: carePlanId,
                perspectiveDateTime: perspectiveDateTime,
            };
            return this.getPromise(
                `CarePlans/${carePlanId}/StructuralTherapyActions/${structuralTherapyActionId}/NextSchedule`,
                params,
                'nextDate'
            );
        }
    }

    remeCareAppModule.service('carePlanApiSvc', CarePlanApiService);
}
