var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var OneTimeLogin;
    (function (OneTimeLogin) {
        var RemeCareOneTimeLoginConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareOneTimeLoginConfiguration, _super);
            function RemeCareOneTimeLoginConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareOneTimeLoginConfiguration.prototype.config = function () {
                this.add('onetimelogin', {
                    // Be careful changing this route as this is being referenced in the integration API
                    url: '/OneTimeLogin?key',
                    component: 'rcOneTimeLogin',
                }, ['Views_OneTimeLogin']);
            };
            return RemeCareOneTimeLoginConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        OneTimeLogin.remeCareOneTimeLoginModule.config(function ($stateProvider) { return new RemeCareOneTimeLoginConfiguration($stateProvider); });
    })(OneTimeLogin = RemeCare.OneTimeLogin || (RemeCare.OneTimeLogin = {}));
})(RemeCare || (RemeCare = {}));
