var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var GraphPoint = /** @class */ (function () {
                function GraphPoint(x, y) {
                    this.x = x;
                    this.y = y;
                }
                return GraphPoint;
            }());
            Model.GraphPoint = GraphPoint;
            var Graph = /** @class */ (function () {
                function Graph() {
                    this.graphPoints = [];
                }
                Graph.prototype.initialize = function (serverObject) {
                    var _this = this;
                    if (serverObject != null) {
                        this.subject = serverObject.Subject;
                        this.scale = serverObject.Scale;
                        this.graphPoints = _(serverObject.GraphPoints)
                            .chain()
                            .map(function (gp) { return new GraphPoint(_this.map(gp.X), gp.Y); })
                            .sortBy(function (p) { return p.x; })
                            .value();
                    }
                };
                return Graph;
            }());
            Model.Graph = Graph;
            var NumericGraph = /** @class */ (function (_super) {
                __extends(NumericGraph, _super);
                function NumericGraph() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                NumericGraph.prototype.getMaxYValue = function () {
                    var _this = this;
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    return _(this.graphPoints).chain().filter(function (gp) { return gp.y != null; }).map(function (gp) { return _this.getYNumericValue(gp.y); }).max().value();
                };
                NumericGraph.prototype.getMinYValue = function () {
                    var _this = this;
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    return _(this.graphPoints).chain().filter(function (gp) { return gp.y != null; }).map(function (gp) { return _this.getYNumericValue(gp.y); }).min().value();
                };
                return NumericGraph;
            }(Graph));
            Model.NumericGraph = NumericGraph;
            var NumberParameterGraph = /** @class */ (function (_super) {
                __extends(NumberParameterGraph, _super);
                function NumberParameterGraph(serverObject, onlyDate) {
                    var _this = _super.call(this) || this;
                    _this.onlyDate = onlyDate;
                    _this.initialize(serverObject);
                    return _this;
                }
                NumberParameterGraph.prototype.map = function (x) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.serverDateStringToDate(x)
                        : RemeCare.Shared.DateHelper.serverDateStringToDateTime(x);
                };
                NumberParameterGraph.prototype.getYNumericValue = function (y) {
                    return y.Value;
                };
                return NumberParameterGraph;
            }(NumericGraph));
            Model.NumberParameterGraph = NumberParameterGraph;
            var NumberGraph = /** @class */ (function (_super) {
                __extends(NumberGraph, _super);
                function NumberGraph(serverObject, onlyDate, pointPerDay) {
                    var _this = _super.call(this) || this;
                    _this.onlyDate = onlyDate;
                    _this.pointPerDay = pointPerDay;
                    _this.initialize(serverObject);
                    _this.pointPerDay && _this.addPerDayPoints();
                    return _this;
                }
                NumberGraph.prototype.map = function (x) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.serverDateStringToDate(x)
                        : RemeCare.Shared.DateHelper.serverDateStringToDateTime(x);
                };
                NumberGraph.prototype.getYNumericValue = function (y) {
                    return y;
                };
                NumberGraph.prototype.addPerDayPoints = function () {
                    if (_(this.graphPoints).isEmpty()) {
                        return;
                    }
                    var currentPoint = this.graphPoints[0];
                    var date = moment(currentPoint.x).add(1, 'day');
                    var i = 1;
                    var nextPoint = this.graphPoints[i++];
                    var nextDate = nextPoint && moment(nextPoint.x);
                    var points = [currentPoint];
                    while (nextPoint) {
                        if (date.isBefore(nextDate)) {
                            points.push(new GraphPoint(date.toDate(), currentPoint.y));
                        }
                        else {
                            points.push(nextPoint);
                            currentPoint = nextPoint;
                            nextPoint = this.graphPoints[i++];
                            nextDate = nextPoint && moment(nextPoint.x);
                        }
                        date = date.add(1, 'day');
                    }
                    this.graphPoints = points;
                };
                return NumberGraph;
            }(NumericGraph));
            Model.NumberGraph = NumberGraph;
            var BarGraph = /** @class */ (function (_super) {
                __extends(BarGraph, _super);
                function BarGraph(low, high, onlyDate) {
                    var _this = _super.call(this) || this;
                    _this.onlyDate = onlyDate;
                    var combined = _this.combine(new NumberParameterGraph(low, onlyDate), new NumberParameterGraph(high, onlyDate));
                    _this.initialize(combined);
                    return _this;
                }
                BarGraph.prototype.getMaxYValue = function () {
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    var maxHigh = _(this.graphPoints).chain().filter(function (gp) { return gp.y.high != null && gp.y.high.Value != null; }).map(function (gp) { return gp.y.high.Value; }).max().value();
                    var maxLow = _(this.graphPoints).chain().filter(function (gp) { return gp.y.low != null && gp.y.low.Value != null; }).map(function (gp) { return gp.y.low.Value; }).max().value();
                    return maxHigh > maxLow ? maxHigh : maxLow;
                };
                BarGraph.prototype.getMinYValue = function () {
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    var minLow = _(this.graphPoints).chain().filter(function (gp) { return gp.y.low != null && gp.y.low.Value != null; }).map(function (gp) { return gp.y.low.Value; }).min().value();
                    var minHigh = _(this.graphPoints).chain().filter(function (gp) { return gp.y.high != null && gp.y.high.Value != null; }).map(function (gp) { return gp.y.high.Value; }).min().value();
                    return minLow < minHigh ? minLow : minHigh;
                };
                BarGraph.prototype.map = function (x) {
                    return this.getDate(x);
                };
                BarGraph.prototype.getDate = function (date) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.serverDateStringToDate(date)
                        : RemeCare.Shared.DateHelper.serverDateStringToDateTime(date);
                };
                BarGraph.prototype.getDateString = function (date) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.toServerDateString(date)
                        : RemeCare.Shared.DateHelper.toServerDateTimeString(date);
                };
                BarGraph.prototype.combine = function (low, high) {
                    var _this = this;
                    var result = {
                        Scale: low.scale,
                        Subject: low.subject,
                        GraphPoints: []
                    };
                    result.Subject.Text += " - " + high.subject.Text;
                    _(low.graphPoints)
                        .forEach(function (l) {
                        var h;
                        do {
                            h = high.graphPoints.shift();
                            if (!h) {
                                continue;
                            }
                            if (h.x.getTime() === l.x.getTime()) {
                                result.GraphPoints.push({
                                    X: _this.getDateString(l.x),
                                    Y: {
                                        low: {
                                            Value: l.y.Value,
                                            ExceedsThreshold: l.y.ExceedsThreshold,
                                            TherapyActionPart: null
                                        },
                                        high: {
                                            Value: h.y.Value,
                                            ExceedsThreshold: h.y.ExceedsThreshold,
                                            TherapyActionPart: null
                                        }
                                    }
                                });
                            }
                            else if (h.x.getTime() > l.x.getTime()) {
                                high.graphPoints.unshift(h);
                            }
                        } while (high.graphPoints.length > 0 && h.x.getTime() < l.x.getTime());
                    });
                    return result;
                };
                return BarGraph;
            }(Graph));
            Model.BarGraph = BarGraph;
            var QualitativeGraph = /** @class */ (function (_super) {
                __extends(QualitativeGraph, _super);
                function QualitativeGraph(serverObject, onlyDate) {
                    var _this = _super.call(this) || this;
                    _this.onlyDate = onlyDate;
                    _this.initialize(serverObject);
                    _this.graphPoints = _(_this.graphPoints).sortBy(function (g) { return g.x; });
                    return _this;
                }
                QualitativeGraph.prototype.map = function (x) {
                    return this.getDate(x);
                };
                QualitativeGraph.prototype.getDate = function (date) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.serverDateStringToDate(date)
                        : RemeCare.Shared.DateHelper.serverDateStringToDateTime(date);
                };
                return QualitativeGraph;
            }(Graph));
            Model.QualitativeGraph = QualitativeGraph;
            var TherapyComplianceGraph = /** @class */ (function (_super) {
                __extends(TherapyComplianceGraph, _super);
                function TherapyComplianceGraph(serverObject) {
                    var _this = _super.call(this) || this;
                    _this.initialize(serverObject);
                    if (serverObject != null) {
                        _this.preferredTime = _this.getTimeMoment(RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.PreferredTime));
                        _this.reminderTime = _this.getTimeMoment(RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.ReminderTime));
                        if (_this.reminderTime.isBefore(_this.preferredTime)) {
                            _this.reminderTime = _this.reminderTime.add(1, 'days');
                        }
                        _this.registrationFrom = _this.getTimeMoment(RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.RegistrationFrom));
                        if (_this.registrationFrom.isAfter(_this.preferredTime)) {
                            _this.registrationFrom = _this.registrationFrom.subtract(1, 'days');
                        }
                        _this.registrationUntil = _this.getTimeMoment(RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.RegistrationUntil));
                        if (_this.registrationUntil.isBefore(_this.preferredTime)) {
                            _this.registrationUntil = _this.registrationUntil.add(1, 'days');
                        }
                    }
                    return _this;
                }
                TherapyComplianceGraph.prototype.getMaxYValue = function () {
                    var _this = this;
                    var compareDate = this.registrationUntil.isValid() ? this.registrationUntil : this.preferredTime;
                    if (_(this.graphPoints).isEmpty()) {
                        return compareDate;
                    }
                    var max = _(this.graphPoints).max(function (g) { return _this.getYValue(g.y).valueOf(); }).y;
                    var momentMax = moment(this.getYValue(max));
                    if (momentMax.isAfter(compareDate)) {
                        return momentMax;
                    }
                    return compareDate;
                };
                TherapyComplianceGraph.prototype.getMinYValue = function () {
                    var _this = this;
                    var compareDate = this.registrationFrom.isValid() ? this.registrationFrom : this.preferredTime;
                    if (_(this.graphPoints).isEmpty()) {
                        return compareDate;
                    }
                    var min = _(this.graphPoints).min(function (g) { return _this.getYValue(g.y).valueOf(); }).y;
                    var momentMin = moment(this.getYValue(min));
                    if (momentMin.isBefore(compareDate)) {
                        return momentMin;
                    }
                    return compareDate;
                };
                TherapyComplianceGraph.prototype.getYValue = function (y) {
                    if (y.EventDateTime == null) {
                        return this.registrationUntil.isValid() ? this.registrationUntil.toDate() : this.preferredTime.toDate();
                    }
                    var momentDate = this.getTimeMoment(RemeCare.Shared.DateHelper.serverDateStringToDateTime(y.EventDateTime));
                    return momentDate.toDate();
                };
                TherapyComplianceGraph.prototype.map = function (x) {
                    return RemeCare.Shared.DateHelper.serverDateStringToDate(x);
                };
                TherapyComplianceGraph.prototype.getTimeMoment = function (y) {
                    var momentDate = moment(y);
                    return moment({ hour: momentDate.hours(), minute: momentDate.minutes() });
                };
                return TherapyComplianceGraph;
            }(Graph));
            Model.TherapyComplianceGraph = TherapyComplianceGraph;
            var Boxplot = /** @class */ (function (_super) {
                __extends(Boxplot, _super);
                function Boxplot(min, firstQ, median, thirdQ, max, onlyDate) {
                    var _this = _super.call(this) || this;
                    _this.onlyDate = onlyDate;
                    var combined = _this.combine(new NumberParameterGraph(min, onlyDate), new NumberParameterGraph(firstQ, onlyDate), new NumberParameterGraph(median, onlyDate), new NumberParameterGraph(thirdQ, onlyDate), new NumberParameterGraph(max, onlyDate));
                    _this.initialize(combined);
                    return _this;
                }
                Boxplot.prototype.getMaxYValue = function () {
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    var maxHigh = _(this.graphPoints).chain().filter(function (gp) { return gp.y.max != null && gp.y.max.Value != null; }).map(function (gp) { return gp.y.max.Value; }).max().value();
                    var maxLow = _(this.graphPoints).chain().filter(function (gp) { return gp.y.min != null && gp.y.min.Value != null; }).map(function (gp) { return gp.y.min.Value; }).max().value();
                    return maxHigh > maxLow ? maxHigh : maxLow;
                };
                Boxplot.prototype.getMinYValue = function () {
                    if (_(this.graphPoints).isEmpty()) {
                        return null;
                    }
                    var minLow = _(this.graphPoints).chain().filter(function (gp) { return gp.y.min != null && gp.y.min.Value != null; }).map(function (gp) { return gp.y.min.Value; }).min().value();
                    var minHigh = _(this.graphPoints).chain().filter(function (gp) { return gp.y.max != null && gp.y.max.Value != null; }).map(function (gp) { return gp.y.max.Value; }).min().value();
                    return minLow < minHigh ? minLow : minHigh;
                };
                Boxplot.prototype.map = function (x) {
                    return this.getDate(x);
                };
                Boxplot.prototype.getDate = function (date) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.serverDateStringToDate(date)
                        : RemeCare.Shared.DateHelper.serverDateStringToDateTime(date);
                };
                Boxplot.prototype.getDateString = function (date) {
                    return this.onlyDate
                        ? RemeCare.Shared.DateHelper.toServerDateString(date)
                        : RemeCare.Shared.DateHelper.toServerDateTimeString(date);
                };
                Boxplot.prototype.combine = function (min, firstQ, median, thirdQ, max) {
                    var _this = this;
                    var result = {
                        Scale: min.scale,
                        Subject: min.subject,
                        GraphPoints: []
                    };
                    result.Subject.Text += " - " + firstQ.subject.Text + " - " + median.subject.Text + " - " + thirdQ.subject.Text + " - " + max.subject.Text;
                    _(min.graphPoints)
                        .forEach(function (m) {
                        var firstPoint = _(firstQ.graphPoints).find(function (f) { return f.x.getTime() === m.x.getTime(); });
                        var medPoint = _(median.graphPoints).find(function (f) { return f.x.getTime() === m.x.getTime(); });
                        var thirdPoint = _(thirdQ.graphPoints).find(function (f) { return f.x.getTime() === m.x.getTime(); });
                        var maxPoint = _(max.graphPoints).find(function (f) { return f.x.getTime() === m.x.getTime(); });
                        if (firstPoint && medPoint && thirdPoint && maxPoint) {
                            result.GraphPoints.push({
                                X: _this.getDateString(m.x),
                                Y: {
                                    min: {
                                        Value: m.y.Value,
                                        ExceedsThreshold: m.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    },
                                    firstQ: {
                                        Value: firstPoint.y.Value,
                                        ExceedsThreshold: firstPoint.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    },
                                    median: {
                                        Value: medPoint.y.Value,
                                        ExceedsThreshold: medPoint.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    },
                                    thirdQ: {
                                        Value: thirdPoint.y.Value,
                                        ExceedsThreshold: thirdPoint.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    },
                                    max: {
                                        Value: maxPoint.y.Value,
                                        ExceedsThreshold: maxPoint.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    }
                                }
                            });
                        }
                    });
                    return result;
                };
                return Boxplot;
            }(Graph));
            Model.Boxplot = Boxplot;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
