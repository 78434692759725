/// <reference path="./carePlanDetailComponentControllerBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlanAnamnesis = Patient.Model.CarePlanAnamnesis;
        var CarePlanAnamnesisController = /** @class */ (function (_super) {
            __extends(CarePlanAnamnesisController, _super);
            function CarePlanAnamnesisController($dialog, patientSvc, toaster, therapyApiSvc, authservice, gridBuilderSvc, modalBuilderFactory, carePlanApiSvc, masterdataSvc) {
                var _this = _super.call(this, $dialog, patientSvc, toaster, therapyApiSvc, authservice, gridBuilderSvc, modalBuilderFactory) || this;
                _this.$dialog = $dialog;
                _this.patientSvc = patientSvc;
                _this.toaster = toaster;
                _this.therapyApiSvc = therapyApiSvc;
                _this.authservice = authservice;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                return _this;
            }
            CarePlanAnamnesisController.prototype.onPartRetrieved = function () {
                if (this.part.Type.Id === RemeCare.Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis) {
                    this.title = this.part.QualitativeAnamnesis.QualitativeEntity.Text;
                    this.getCodeSetItemsAsync(this.part.QualitativeAnamnesis.CodeSet.Id);
                }
                else {
                    this.title = this.part.QuantitativeAnamnesis.QuantitativeCharacteristic.Name;
                }
            };
            CarePlanAnamnesisController.prototype.addGridRows = function (gridBuilder) {
                return gridBuilder.addColumn('getRegistrationValueString()', 'General.Value');
            };
            CarePlanAnamnesisController.prototype.setVersionGridData = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, versions, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanAnamnesesAsync(this.carePlanId, this.definitionId)];
                            case 1:
                                ags = _a.sent();
                                versions = _(ags).map(function (x) { return new CarePlanAnamnesis(x); });
                                this.versionsGrid.setData(_(versions)
                                    .sortBy(function (x) { return x.validFromDate; })
                                    // Order is important as the versionsGrid edit-button-row disable function depends on this
                                    .reverse());
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanAnamnesisController.prototype.getAddController = function () {
                return 'addCarePlanAnamnesisModalCtrl';
            };
            CarePlanAnamnesisController.prototype.getAddTemplateUrl = function () {
                return 'views/patient/carePlans/carePlanDetails/addCarePlanAnamnesisModal.html';
            };
            CarePlanAnamnesisController.prototype.extendAddScope = function (scope, detail) {
                scope.carePlanAnamnesis = angular.copy(detail) || new CarePlanAnamnesis();
                scope.codeSetItems = this.codeSetItems;
                scope.uiControlType = this.part.QualitativeAnamnesis ? this.part.QualitativeAnamnesis.UIControlType : null;
                scope.unit = this.part.QuantitativeAnamnesis ? this.part.QuantitativeAnamnesis.Unit : null;
                scope.decimals = this.part.QuantitativeAnamnesis ? this.part.QuantitativeAnamnesis.Decimals : null;
            };
            CarePlanAnamnesisController.prototype.changeValidityAsync = function (versionValidityChange) {
                return this.carePlanApiSvc.changeCarePlanAnamnesisValidityAsync(this.carePlanId, this.definitionId, versionValidityChange.toServerWrite());
            };
            CarePlanAnamnesisController.prototype.deleteAsync = function (fromDate) {
                return this.carePlanApiSvc.deleteCarePlanAnamnesisAsync(this.carePlanId, this.definitionId, fromDate);
            };
            CarePlanAnamnesisController.prototype.getCodeSetItemsAsync = function (codeSetType) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this;
                                return [4 /*yield*/, this.masterdataSvc.getCodeSetItemsAsync(codeSetType)];
                            case 1:
                                _a.codeSetItems = _b.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return CarePlanAnamnesisController;
        }(Patient.CarePlanDetailComponentControllerBase));
        Patient.remeCarePatientModule.component('carePlanAnamnesis', {
            controller: CarePlanAnamnesisController,
            bindings: {
                patientId: '@',
                carePlanId: '@',
                definitionId: '@',
            },
            templateUrl: 'views/patient/carePlans/carePlanDetails/carePlanDetail.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
