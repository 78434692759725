/// <reference path="./monitoringPartComponentBase.ts"/>

module RemeCare.Patient {

    import ActionTimingInformation = Contract.CarePlanAction.Read.IActionTimingInformation;
    import MonitoringPartSourceAction = Shared.Framework.Model.MonitoringPartSourceAction;

    class ActionTimeLineController extends HighChartsMonitoringPartController<Shared.Framework.Model.MonitoringPartActionTimeLine> {

        private structuralTherapyActions: Array<Shared.Contract.IEntityTranslation>;

        // @ngInject
        constructor(
            $rootScope: ng.IRootScopeService,
            $locale: ng.ILocaleService,
            dateHelper,
            spinnerSvc: Shared.Framework.Service.SpinnerService,
            private $translate: ng.translate.ITranslateService,
            private toaster: Shared.Framework.Toaster,
            private $q: ng.IQService,
            private $filter: ng.IFilterService,
            private patientSvc: Patient.PatientService,
            private carePlanActionApiSvc: Core.Services.CarePlanActionApiService) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.chartConfigs = [
                <HighChartsNGConfig>{
                    options: {
                        chart: {
                            animation: false,
                            type: 'scatter',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'xy',
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(),
                        credits: {
                            enabled: false
                        },
                        exporting: {
                            enabled: false
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(false)
                            //pointFormatter: function () {
                            //    return `${that.$filter('date')(this.x, 'shortDate')}`;
                            //}
                        },
                        plotOptions: {
                            scatter: {
                                animation: false,
                                marker: {
                                    enabled: true,
                                    symbol: 'circle'
                                }
                            }
                        },
                        legend: {
                            enabled: this.showLegend
                        },
                        colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
                            '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a']
                    },
                    series: [],
                    title: {
                        text: this.monitoringPart.name
                    }
                }
            ];
            var structuralTherapyActionIds = _(this.monitoringPart.monitoringPartSources)
                .map(mps => (<MonitoringPartSourceAction>mps).structuralTherapyActionId);
            return this.patientSvc.getStructuralTherapyActions(this.patientId, {
                structuralTherapyActionIds: structuralTherapyActionIds
            }).success(r => {
                this.structuralTherapyActions = r;
            }).catch((e) => {
                this.toaster.error(e);
                throw e;
            });
        }

        protected async onDateChange(): Promise<void> {
            const dateFilterTypes: Contract.Core.Codes.DateFilterTypeCode[] = [];
            if (this.monitoringPart.showRegistrationDate) {
                dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.ExecutionDate);
            }
            if (this.monitoringPart.showPlannedDates) {
                dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.PlannedDate);
            }
            if (this.monitoringPart.showEventDates) {
                dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.EventDate);
            }
            if (this.monitoringPart.showModificationDate) {
                dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.ModificationDate);
            }
            const query = <Contract.CarePlanAction.Read.IFindActionTimingInformationsQuery>{
                page: 1,
                pageSize: 1000,
                sortField: 'suggesteddatetime',
                sortOrder: 'DESC',
                patientId: this.patientId,
                actionTemplateIds: _(this.structuralTherapyActions).map(sta => sta.Id),
                dateFilterTypes: dateFilterTypes,
                startDate: Shared.DateHelper.toServerDateString(this.dateInfo.fromDate),
                endDate: Shared.DateHelper.toServerDateString(this.getUntilDate())
            };

            const actionTimingsSearchResult = await this.carePlanActionApiSvc.findActionTimingInformations(query);
            this.configureChart(actionTimingsSearchResult.Items);
        }

        private configureChart(carePlanActions: Array<ActionTimingInformation>): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig();
            this.chartConfigs[0].options.yAxis = this.getYAxis();
            let index = 1;
            let colorIndex = 0;
            const series: HighchartsScatterChartSeriesOptions[] = [];
            const executionDateTimeSelector = (ati: ActionTimingInformation) => ati.ExecutionDateTime;
            const eventDateTimeSelector = (ati: ActionTimingInformation) => ati.EventDateTimeFrom;
            const modificationDateTimeSelector = (ati: ActionTimingInformation) => ati.ModificationDateTime;

            _(this.structuralTherapyActions).forEach(sta => {
                var applicableActions = _(carePlanActions).filter(cpa => cpa.ActionTemplateId === sta.Id);
                var color = this.chartConfigs[0].options.colors[colorIndex++];
                var id = sta.Id.toString();
                if (this.monitoringPart.showRegistrationDate) {
                    series.push(this.getDataSeries(applicableActions, sta, index, id, color, true, executionDateTimeSelector, 'Views.PatientFile.Monitoring.ExecutionDate'));
                    if (this.monitoringPart.showPlannedDates) {
                        series.push(this.getErrorBars(applicableActions, sta, index++, id, color, true));
                        if (this.monitoringPart.showEventDates) {
                            series.push(this.getDataSeries(applicableActions, sta, index, id + '_2', color, false, eventDateTimeSelector, 'Views.PatientFile.Monitoring.ObservationDate'));
                            series.push(this.getErrorBars(applicableActions, sta, index++, id + '_2', color, false));
                        }
                    } else if (this.monitoringPart.showEventDates) {
                        series.push(this.getErrorBars(applicableActions, sta, index++, id, color, false));
                    } else {
                        index++;
                    }
                } else {
                    series.push(this.getDataSeries([], sta, index, id, color, true, executionDateTimeSelector, 'Views.PatientFile.Monitoring.ExecutionDate')); // Show empty data series for legend
                    if (this.monitoringPart.showPlannedDates) {
                        series.push(this.getErrorBars(applicableActions, sta, index++, id, color, true));
                    }
                    if (this.monitoringPart.showEventDates) {
                        series.push(this.getErrorBars(applicableActions, sta, index++, id, color, false));
                    }
                }
                if (this.monitoringPart.showModificationDate) {
                    series.push(this.getDataSeries(applicableActions, sta, index++, id, color, false, modificationDateTimeSelector, 'Views.PatientFile.Monitoring.ModificationDate'));
                }
            });
            this.chartConfigs[0].series = series;
            this.chartConfigs[0].loading = false;
        }

        private getYAxis(): HighchartsAxisOptions {
            var max = this.monitoringPart.showPlannedDates && this.monitoringPart.showEventDates
                ? this.structuralTherapyActions.length * 2 + 1
                : this.structuralTherapyActions.length + 1;
            return <HighchartsAxisOptions>{
                title: null,
                max: max,
                min: 0,
                startOnTick: true,
                endOnTick: true,
                labels: {
                    enabled: false
                }
            }
        }

        private getDataSeries(
            carePlanActions: Array<ActionTimingInformation>,
            structuralTherapyAction: Shared.Contract.IEntityTranslation,
            index: number,
            id: string,
            color: string,
            showInLegend: boolean,
            dateSelector: (ati: ActionTimingInformation) => string,
            dateTypeTranslationKey: string
        ): HighchartsScatterChartSeriesOptions {
            const self = this;
            return <HighchartsScatterChartSeriesOptions>{
                animation: false,
                data: _(carePlanActions).chain()
                .map(cpa => dateSelector(cpa))
                    .filter(dateString => dateString != null)
                    .map(dateString => Shared.DateHelper.serverDateStringToDateTime(dateString).valueOf())
                    .sortBy()
                    .map(date => {
                        return [date, index];
                    })
                    .value(),
                name: structuralTherapyAction.Text,
                showInLegend: showInLegend,
                tooltip: {
// ReSharper disable SuspiciousThisUsage
// Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                    pointFormatter: function () {
                        return `<span style="color:${this.color}">\u25CF</span> ${self.$filter('date')(this.x, 'shortDate')}`;
                    },
// ReSharper restore SuspiciousThisUsage
                    headerFormat: `<span style="font-size:10px">${`${structuralTherapyAction.Text} - ${this.$translate.instant(dateTypeTranslationKey)}`}</span><br/>`
                },
                color: color,
                id: id,
                marker: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        }

        private getErrorBars(
            carePlanActions: Array<ActionTimingInformation>,
            structuralTherapyAction: Shared.Contract.IEntityTranslation,
            index: number,
            id: string,
            color: string,
            isPlannedDate: boolean
        ): HighchartsScatterChartSeriesOptions {
            const self = this;
            return <HighchartsScatterChartSeriesOptions>{
                animation: false,
                pointPlacement: 'on',
                type: 'error_bar',
                format: 'x',
                linkedTo: id,
                color: color,
                whiskerLength: 3,
                whiskerWidth: 5,
                barDashStyle: isPlannedDate ? 'solid' : 'dot',
                name: structuralTherapyAction.Text,
                lineWidth: 2,
                tooltip: {
// ReSharper disable SuspiciousThisUsage
// Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                    pointFormatter: function () {
                        var leftValue = self.$filter('date')(this.left, 'shortDate');
                        var rightValue = self.$filter('date')(this.right, 'shortDate');
                        var value = leftValue !== rightValue ? leftValue + '  -  ' + rightValue : leftValue;
                        return '<span style="color:' + this.color + '">\u25CF</span> ' + value;
                    },
// ReSharper restore SuspiciousThisUsage
                    headerFormat: '<span style="font-size:10px">' +
                    `${structuralTherapyAction.Text} - ${this.$translate.instant(isPlannedDate
                        ? 'Views.PatientFile.Monitoring.PlannedDate'
                        : 'Views.PatientFile.Monitoring.ObservationDate')}` +
                    '</span><br/>'
                },
                data: _(carePlanActions).chain()
                    .map(cpa => {
                        return {
                            fromDate: Shared.DateHelper
                                .serverDateStringToDateTime(isPlannedDate
                                    ? cpa.PlannedDateTimeFrom
                                    : cpa.EventDateTimeFrom),
                            untilDate: Shared.DateHelper
                                .serverDateStringToDateTime(isPlannedDate
                                    ? cpa.PlannedDateTimeUntil
                                    : cpa.EventDateTimeUntil)
                        }
                    })
                    .filter(dates => dates.fromDate != null)
                    .sortBy(dates => dates.fromDate)
                    .map(dates => {
                        return [
                            dates.fromDate.valueOf(), index, dates.fromDate.valueOf(), dates.untilDate.valueOf()
                        ];
                    }).value()
            }
        }
    }

    class ActionTimeLineComponent extends MonitoringPartComponentBase {
        public controller = ActionTimeLineController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringActionTimeLine', new ActionTimeLineComponent());
}