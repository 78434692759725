module RemeCare.Core.Controllers {

    export interface IHasName {
        name: string;
    }

    export interface IDropDownSelectModalScope<TItem extends IHasName> extends ng.ui.bootstrap.IModalScope, Shared.Framework.IBaseScope
    {
        modalTitle: string;
        selectInfo: {
            items: TItem[];
            selectedItem: TItem;
        }
        select(): void;
    }
    
    class DropDownSelectModalCtrl<TItem extends IHasName> extends Shared.Framework.ControllerBase<IDropDownSelectModalScope<TItem>> {
        static $inject = ['$scope', '$translate', 'toaster', '$uibModalInstance', 'selectItems'];
        constructor(
            $scope: IDropDownSelectModalScope<TItem>,
            $translate,
            toaster: Shared.Framework.Toaster,
            private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            private selectItems: TItem[]) {

            super($scope, $translate, toaster);
            $scope.select = () => this.select();
        }

        public $onInit(): void {
            if (this.$scope.modalTitle == null) {
                this.$scope.modalTitle = 'General.Select';
            }
            var selectedItem: TItem = (this.selectItems.length > 0) ? this.selectItems[0] : null;
            this.$scope.selectInfo = {
                items: this.selectItems,
                selectedItem: selectedItem
            };
        }

        select(): void {
            this.$uibModalInstance.close(this.$scope.selectInfo.selectedItem);
        }
    }

    remeCareAppModule.controller('dropDownSelectModalCtrl', DropDownSelectModalCtrl);
}