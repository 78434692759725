module RemeCare.Patient.Model {
    import Guid = RemeCare.Shared.Contract.Guid;

    export class AnamnesisInfo {
        id: Guid;
        patientId: Guid;
        text : string;
        observationDate: Date;

        constructor(serverObject?: Contract.Patient.Read.IAnamnesisInfo) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.patientId = serverObject.PatientId;
                this.text = serverObject.Text;
                this.observationDate = Shared.DateHelper.serverDateStringToDate(serverObject.ObservationDate);
            }
        }

        public toServerWrite(): Contract.Patient.Write.IAnamnesisInfo {
            return <Contract.Patient.Write.IAnamnesisInfo>{
                Id: this.id,
                PatientId: this.patientId,
                Text: this.text,
                ObservationDate: this.observationDate !== null ? Shared.DateHelper.toServerDateString(this.observationDate) : null
            };
        }
    }
}