module RemeCare.CareRequest {

    interface IAddHospitalisationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        //title: string;

        careTypes: Array<Shared.Contract.ICodedEntityTranslation>;
        reasons: Array<Shared.Contract.ICodedEntityTranslation>;

        newHospitalisationForm: ng.IFormController;

        hospitalisation: Model.Hospitalisation;
        now: Date;

        getDuration(): number;
        setOther(): void;
        validHospitalisationStartDate(): boolean;
        validHospitalisationEndDate(): boolean;

        confirm(): void;
    }

    class AddHospitalisationCtrl extends Shared.Framework.ControllerBase<IAddHospitalisationScope> {
        constructor(
            $scope: IAddHospitalisationScope,
            $translate,        
            toaster: Shared.Framework.Toaster,
            //private title: string,
            private careTypes: Array<Shared.Contract.ICodedEntityTranslation>,
            private reasons: Array<Shared.Contract.ICodedEntityTranslation>                       
            ) {

            super($scope, $translate, toaster);
            
            //$scope.title = title;
            $scope.careTypes = careTypes;                        
            $scope.reasons = reasons;
            this.$scope.now = new Date();

            $scope.hospitalisation = new Model.Hospitalisation();
            $scope.getDuration = () => this.getDuration();
            $scope.setOther = () => this.setOther();
            $scope.validHospitalisationStartDate = () => this.validHospitalisationStartDate();
            $scope.validHospitalisationEndDate = () => this.validHospitalisationEndDate();
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {}

        confirm(): void {
            this.$scope.showErrors = true;
            if (this.$scope.newHospitalisationForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.hospitalisation);            
        }

        getDuration(): number {
            return this.$scope.hospitalisation.getDuration();
        }

        validHospitalisationStartDate(): boolean {
            var today = new Date();
            var dateWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            if (this.$scope.hospitalisation.startDate != null) {

                if (this.$scope.hospitalisation.startDate > dateWithoutTime)
                    return false;
                else
                    return true;
            }
            else
                return false;            
        }

        validHospitalisationEndDate(): boolean {           

            if (this.$scope.hospitalisation.startDate != null && this.$scope.hospitalisation.endDate) {

                if (this.$scope.hospitalisation.endDate >= this.$scope.hospitalisation.startDate)
                    return true;
                else
                    return false;
            }
            else
                return false;
        }

        setOther(): void {
            //display div other only if other reason is selected
            var divOtherReason = document.getElementById("OtherReason");

            if (this.$scope.hospitalisation.hospitalisationReason.Id.toUpperCase() !== 'E09020E8-4645-481F-A709-BC5DE3ACF14C')
                divOtherReason.style.display = "none";
            else
                divOtherReason.style.display = "block";
        }
    }

    angular.module('RemeCare.CareRequest')
        .controller('addHospitalisationCtrl', (
                $scope: IAddHospitalisationScope,
                $translate,
                toaster: Shared.Framework.Toaster,
                careTypes: Array<Shared.Contract.ICodedEntityTranslation>,
                reasons: Array<Shared.Contract.ICodedEntityTranslation>) => new AddHospitalisationCtrl(
                $scope,
                $translate,
                toaster,
                careTypes,
                reasons));
} 