var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Controllers;
        (function (Controllers) {
            var DropDownSelectModalCtrl = /** @class */ (function (_super) {
                __extends(DropDownSelectModalCtrl, _super);
                function DropDownSelectModalCtrl($scope, $translate, toaster, $uibModalInstance, selectItems) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.$uibModalInstance = $uibModalInstance;
                    _this.selectItems = selectItems;
                    $scope.select = function () { return _this.select(); };
                    return _this;
                }
                DropDownSelectModalCtrl.prototype.$onInit = function () {
                    if (this.$scope.modalTitle == null) {
                        this.$scope.modalTitle = 'General.Select';
                    }
                    var selectedItem = (this.selectItems.length > 0) ? this.selectItems[0] : null;
                    this.$scope.selectInfo = {
                        items: this.selectItems,
                        selectedItem: selectedItem
                    };
                };
                DropDownSelectModalCtrl.prototype.select = function () {
                    this.$uibModalInstance.close(this.$scope.selectInfo.selectedItem);
                };
                DropDownSelectModalCtrl.$inject = ['$scope', '$translate', 'toaster', '$uibModalInstance', 'selectItems'];
                return DropDownSelectModalCtrl;
            }(RemeCare.Shared.Framework.ControllerBase));
            RemeCare.remeCareAppModule.controller('dropDownSelectModalCtrl', DropDownSelectModalCtrl);
        })(Controllers = Core.Controllers || (Core.Controllers = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
