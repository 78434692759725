var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var PatientFileDocumentSource;
                (function (PatientFileDocumentSource) {
                    PatientFileDocumentSource[PatientFileDocumentSource["Action"] = 1] = "Action";
                    PatientFileDocumentSource[PatientFileDocumentSource["CarePlan"] = 2] = "CarePlan";
                    PatientFileDocumentSource[PatientFileDocumentSource["PatientFile"] = 3] = "PatientFile";
                })(PatientFileDocumentSource = Codes.PatientFileDocumentSource || (Codes.PatientFileDocumentSource = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
