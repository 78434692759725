namespace RemeCare.Patient {
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    interface IAddCarePlanDateModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;

        carePlanId: Shared.Contract.Guid;
        definition: EntityTranslation;
        carePlanDate: Patient.Model.CarePlanDate;
        minDate: Date;
        dateEditable: boolean;

        confirm(): void;
    }

    class AddCarePlanDateModalController extends Shared.Framework.ControllerBase<IAddCarePlanDateModalController> {
        constructor(
            protected $scope: IAddCarePlanDateModalController,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirmAsync();
        }

        public $onInit(): void {
            this.$scope.dateEditable = !moment().isAfter(this.$scope.carePlanDate.validFromDate);
            if (moment(this.$scope.minDate).isBefore(Shared.DateHelper.today())) {
                this.$scope.minDate = moment(Shared.DateHelper.today())
                    .add('day', 1)
                    .toDate();
            }
            if (!this.$scope.dateEditable) {
                this.$scope.minDate = null;
            }
        }

        private async confirmAsync(): Promise<void> {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.carePlanDate.validUntilDate = null;
            this.$scope.carePlanDate.carePlanId = this.$scope.carePlanId;
            this.$scope.carePlanDate.definition = this.$scope.definition;

            try {
                await this.carePlanApiSvc.saveCarePlanDateAsync(
                    this.$scope.carePlanId,
                    this.$scope.definition.Id,
                    this.$scope.carePlanDate.toServerWrite()
                );
                this.$scope.$close(this.$scope.carePlanDate);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    angular.module('RemeCare.Patient').controller('addCarePlanDateModalCtrl', AddCarePlanDateModalController);
}
