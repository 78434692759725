/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestorAcceptanceController = /** @class */ (function (_super) {
                __extends(CareRequestorAcceptanceController, _super);
                // @ngInject
                function CareRequestorAcceptanceController($scope, $rootScope, $translate, toaster, gridBuilderSvc, healthCareProfessionalSearchSvc, healthCareOrganisationSearchSvc, modalBuilderFactory, partyApiSvc, healthCarePartySvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$rootScope = $rootScope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.healthCareProfessionalSearchSvc = healthCareProfessionalSearchSvc;
                    _this.healthCareOrganisationSearchSvc = healthCareOrganisationSearchSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.partyApiSvc = partyApiSvc;
                    _this.healthCarePartySvc = healthCarePartySvc;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.clicked = function () { return _this.clicked(); };
                    $scope.requestorRequired = function () { return _this.requestorRequired(); };
                    $scope.isProfessionalValid = function () { return _this.isProfessionalValid(); };
                    $scope.isOrganisationValid = function () { return _this.isOrganisationValid(); };
                    $scope.getName = function () { return _this.getName(); };
                    _this.$rootScope.$on('CAREREQUEST_ROLE_SELECT', function (event, role) {
                        if (role.rolePart !== CareRequest.RolePart.CareRequestorRole ||
                            _this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId !== role.actorRoleId) {
                            return;
                        }
                        _this.$scope.careRequest.careRequestor.healthCareProfessionalId = role.party.PartyRoleId;
                        if (role.organization) {
                            _this.$scope.careRequest.careRequestor.healthCareOrganisationId = role.organization.PartyRoleId;
                        }
                        _this.recalculateCareRequestorGridData();
                    });
                    _this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECT', function (event, role) {
                        if (role.rolePart !== CareRequest.RolePart.CareRequestorRole ||
                            _this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId !== role.actorRoleId) {
                            return;
                        }
                        _this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
                        _this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
                        _this.recalculateCareRequestorGridData();
                    });
                    _this.$onInit();
                    return _this;
                }
                CareRequestorAcceptanceController.prototype.$onInit = function () {
                    this.loadDataAsync();
                    this.buildGrid();
                    this.recalculateCareRequestorGridData();
                };
                CareRequestorAcceptanceController.prototype.recalculateCareRequestorGridData = function () {
                    if (this.$scope.careRequest.careRequestor.healthCareProfessionalId != null) {
                        this.loadProfessionalAsync();
                    }
                    else {
                        this.setCareRequestorInfoAsync();
                    }
                };
                CareRequestorAcceptanceController.prototype.loadDataAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, e_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    _a = this.$scope;
                                    return [4 /*yield*/, this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync()];
                                case 1:
                                    _a.specialties = _b.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _b.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceController.prototype.loadProfessionalAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, _b, _c, e_2;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    _d.trys.push([0, 2, , 3]);
                                    _a = this.$scope;
                                    _c = (_b = RemeCare.Model.HealthCareProfessional).bind;
                                    return [4 /*yield*/, this.healthCareProfessionalSearchSvc.getHealthCareProfessional(this.$scope.careRequest.careRequestor.healthCareProfessionalId)];
                                case 1:
                                    _a.professional = new (_c.apply(_b, [void 0, _d.sent()]))();
                                    this.setCareRequestorInfoAsync();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_2 = _d.sent();
                                    this.toaster.error(e_2);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceController.prototype.setCareRequestorInfoAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var careRequestorInfos, professional, organisation;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    careRequestorInfos = [];
                                    if (!this.$scope.careRequestPart.roleRequestingHealthCareProfessional) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.getCareRequestorInfoProfessional()];
                                case 1:
                                    professional = _a.sent();
                                    if (professional) {
                                        careRequestorInfos.push(professional);
                                    }
                                    _a.label = 2;
                                case 2:
                                    if (!this.$scope.careRequestPart.roleRequestingHealthCareOrganisation) return [3 /*break*/, 4];
                                    return [4 /*yield*/, this.getCareRequestorInfoOrganisation()];
                                case 3:
                                    organisation = _a.sent();
                                    if (organisation) {
                                        careRequestorInfos.push(organisation);
                                    }
                                    _a.label = 4;
                                case 4:
                                    this.$scope.grid.setData(careRequestorInfos);
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceController.prototype.getCareRequestorInfoOrganisation = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var toReturn, organisation;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    toReturn = null;
                                    if (!this.$scope.careRequest.careRequestor.healthCareOrganisationId) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                                            partyRoleId: this.$scope.careRequest.careRequestor.healthCareOrganisationId,
                                        })];
                                case 1:
                                    organisation = _a.sent();
                                    if (organisation.Items && organisation.Items.length > 0) {
                                        toReturn = new CareRequest.CareRequestorInfo(this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name, true, null, null, null, organisation.Items[0]);
                                    }
                                    _a.label = 2;
                                case 2: return [2 /*return*/, toReturn];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceController.prototype.getCareRequestorInfoProfessional = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var toReturn, result;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    toReturn = null;
                                    if (!this.$scope.careRequest.careRequestor.healthCareProfessionalId) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.partyApiSvc.findPersonsAsync({
                                            partyRoleIds: [this.$scope.careRequest.careRequestor.healthCareProfessionalId],
                                        })];
                                case 1:
                                    result = _a.sent();
                                    if (result.Items && result.Items.length > 0) {
                                        toReturn = new CareRequest.CareRequestorInfo(this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name, true, result.Items[0]);
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    if (this.$scope.careRequest.careRequestor.responsible.firstName ||
                                        this.$scope.careRequest.careRequestor.responsible.lastName) {
                                        toReturn = new CareRequest.CareRequestorInfo(this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name, false, null, null, this.$scope.careRequest.careRequestor.responsible);
                                    }
                                    _a.label = 3;
                                case 3: return [2 /*return*/, toReturn];
                            }
                        });
                    });
                };
                CareRequestorAcceptanceController.prototype.isProfessionalValid = function () {
                    return (!this.requestorProfessionalRequired() ||
                        !!this.$scope.careRequest.careRequestor.healthCareProfessionalId);
                };
                CareRequestorAcceptanceController.prototype.isOrganisationValid = function () {
                    return (!this.requestorOrganisationRequired() ||
                        !!this.$scope.careRequest.careRequestor.healthCareOrganisationId);
                };
                CareRequestorAcceptanceController.prototype.getName = function () {
                    if (this.$scope.careRequestPart.roleRequestingHealthCareProfessional) {
                        return this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name;
                    }
                    return this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name;
                };
                CareRequestorAcceptanceController.prototype.requestorRequired = function () {
                    return this.requestorProfessionalRequired() || this.requestorOrganisationRequired();
                };
                CareRequestorAcceptanceController.prototype.requestorProfessionalRequired = function () {
                    return (this.isRequired(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional) ||
                        (this.$scope.careRequestPart.roleRequestingHealthCareProfessional &&
                            this.$scope.careRequestPart.roleRequestingHealthCareProfessional.minimalNumber > 0 &&
                            this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional)));
                };
                CareRequestorAcceptanceController.prototype.requestorOrganisationRequired = function () {
                    return (this.isRequired(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation) ||
                        (this.$scope.careRequestPart.roleRequestingHealthCareOrganisation &&
                            this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.minimalNumber > 0 &&
                            this.isPresent(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation)));
                };
                CareRequestorAcceptanceController.prototype.buildGrid = function () {
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('name', 'General.Name')
                        .addColumn('roleName', 'General.Role')
                        .addBoolColumn('linked', 'General.Linked')
                        .setRowDetailsTemplate('views/careRequest/directive/contactDetailsRequestor.html', 150);
                    this.$scope.grid = gridBuilder.build();
                };
                CareRequestorAcceptanceController.prototype.clicked = function () {
                    var _this = this;
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('rcCareRequestCareRequestorAcceptanceModalController')
                        .setTemplateUrl('views/careRequest/directive/careRequestor/careRequestorAcceptanceModal.html')
                        .setScope({
                        careRequestPart: this.$scope.careRequestPart,
                        professional: this.$scope.professional,
                        organisations: this.$scope.organisations,
                        organisation: this.$scope.organisations,
                        organisationName: this.$scope.organisationName,
                        specialties: this.$scope.specialties,
                        intermediaryInfo: this.$scope.intermediaryInfo,
                        careRequest: this.$scope.careRequest,
                    })
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setResultCallBack(function () {
                        _this.setCareRequestorInfoAsync();
                    })
                        .build();
                };
                return CareRequestorAcceptanceController;
            }(Directive.CareRequestPartControllerBase));
            var CareRequestorAcceptanceDirective = /** @class */ (function (_super) {
                __extends(CareRequestorAcceptanceDirective, _super);
                function CareRequestorAcceptanceDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = CareRequestorAcceptanceController;
                    _this.templateUrl = 'views/careRequest/directive/careRequestor/careRequestorAcceptance.html';
                    return _this;
                }
                return CareRequestorAcceptanceDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestCareRequestorAcceptance', function () { return new CareRequestorAcceptanceDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
