var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlan = RemeCare.Model.Careplan;
        var DateFilterType = RemeCare.Contract.Core.Codes.DateFilterTypeCode;
        var PatientFileRegistrationParametersController = /** @class */ (function () {
            function PatientFileRegistrationParametersController($filter, $translate, toaster, patientSvc, masterdataSvc, gridBuilderSvc, modalBuilderFactory) {
                this.$filter = $filter;
                this.$translate = $translate;
                this.toaster = toaster;
                this.patientSvc = patientSvc;
                this.masterdataSvc = masterdataSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            PatientFileRegistrationParametersController.prototype.$onInit = function () {
                this.buildGrid();
                this.loadDataAsync();
                this.editRight = true;
                this.search();
            };
            PatientFileRegistrationParametersController.prototype.addAllCarePlansOption = function (carePlans) {
                carePlans.unshift({
                    Id: null,
                    Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
                });
            };
            PatientFileRegistrationParametersController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, dateFilterTypes, retrievedCarePlans, carePlans, e_1;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getDateFilterTypesAsync(),
                                        this.patientSvc.getCarePlansAsync(this.patientId),
                                        this.patientSvc.getTherapyActionPartParametersAsync(this.patientId),
                                    ])];
                            case 1:
                                _a = _b.sent(), dateFilterTypes = _a[0], retrievedCarePlans = _a[1], this.therapyActions = _a[2];
                                this.dateFilterTypes = _(dateFilterTypes).filter(function (x) {
                                    return x.Id === DateFilterType.PlannedDate ||
                                        x.Id === DateFilterType.RegistrationDate ||
                                        x.Id === DateFilterType.ObservationDate;
                                });
                                this.grid.searchCriteria.selectedDateFilter = DateFilterType.PlannedDate;
                                carePlans = CarePlan.getSortedOpenCloseCareplans(retrievedCarePlans);
                                this.carePlans = _(carePlans).map(function (x) { return x.getSummary(_this.$translate, _this.$filter); });
                                this.addAllCarePlansOption(this.carePlans);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileRegistrationParametersController.prototype.search = function () {
                this.grid.pagingOptions.currentPage = 1;
                this.grid.search();
            };
            PatientFileRegistrationParametersController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria);
                })
                    .addColumn('name', 'General.Name')
                    .addConditionallyStyledColumn('value.getValueString()', 'General.Value', "exceedsThreshold", {
                    red: true,
                })
                    .addUtcDateColumn('plannedDate', 'Views.PatientFile.Monitoring.PlannedDate', 'short', {
                    sort: { direction: 'desc' },
                })
                    .addUtcDateColumn('registrationDate', 'Views.PatientFile.Monitoring.RegistrationDate', 'short')
                    .addColumn('registrationSource', 'General.Source')
                    .addConditionalShowEditNoButtonFunctionColumn(function (x) { return _this.viewCarePlanAction(x); }, function (x) { return !_this.canEditItem(x); }, function (x) { return !_this.canViewItem(x); });
                this.grid = gridBuilder.setSorting(false).build();
                this.grid.pagingOptions.pageSize = 20;
            };
            PatientFileRegistrationParametersController.prototype.canEditItem = function (selected) {
                return (((RemeCare.Shared.Framework.AuthRight.Create | RemeCare.Shared.Framework.AuthRight.Write) & selected.executionRight) !== 0);
            };
            PatientFileRegistrationParametersController.prototype.canViewItem = function (selected) {
                return (RemeCare.Shared.Framework.AuthRight.Read & selected.executionRight) !== 0;
            };
            PatientFileRegistrationParametersController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, r, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    carePlanId: criteria.carePlanId,
                                    therapyActionPartParameters: criteria.selectedParameters,
                                    dateFilterType: criteria.selectedDateFilter,
                                    startDate: criteria.selectedDateFilter ? RemeCare.Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                                    endDate: criteria.selectedDateFilter ? RemeCare.Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.getRegistrationValuesAsync(this.patientId, query)];
                            case 2:
                                r = _a.sent();
                                return [2 /*return*/, {
                                        Items: _(r.Items).map(function (x) { return new Patient.Model.RegistrationValueOverview(x); }),
                                        Total: r.Total,
                                    }];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileRegistrationParametersController.prototype.viewCarePlanAction = function (rv) {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('carePlanActionDetailModalCtrl')
                    .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                    actionId: rv.carePlanActionId,
                    editRight: this.canEditItem(rv),
                })
                    .setResultCallBack(function () {
                    _this.grid.search();
                })
                    .build();
            };
            return PatientFileRegistrationParametersController;
        }());
        Patient.remeCarePatientModule.component('patientFileRegistrationParameters', {
            controller: PatientFileRegistrationParametersController,
            templateUrl: 'views/patient/monitoring/registrations/parameters.html',
            bindings: {
                patientId: '@',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
