namespace RemeCare.Patient.Model {
    import IParameter = Contract.CarePlan.Read.IParameter;

    export interface IHasMinMaxY {
        getMaxYValue(): number;
        getMinYValue(): number;
    }

    export interface IHighLowPoint<T> {
        high: T;
        low: T;
    }

    export interface IBoxPlotPoint<T> {
        min: T;
        firstQ: T;
        median: T;
        thirdQ: T;
        max: T;
    }

    export class GraphPoint<TX, TY> {
        public x: TX;
        public y: TY;

        constructor(x: TX, y: TY) {
            this.x = x;
            this.y = y;
        }
    }

    export abstract class Graph<TX, TY> {
        public subject: Shared.Contract.IEntityTranslation;
        public scale: Shared.Contract.IEntityTranslation;
        public graphPoints: Array<GraphPoint<TX, TY>>;

        protected constructor() {
            this.graphPoints = [];
        }

        protected initialize(serverObject?: Contract.Core.IGraph<TY>) {
            if (serverObject != null) {
                this.subject = serverObject.Subject;
                this.scale = serverObject.Scale;
                this.graphPoints = _(serverObject.GraphPoints)
                    .chain()
                    .map(gp => new GraphPoint<TX, TY>(this.map(gp.X), gp.Y))
                    .sortBy(p => p.x)
                    .value();
            }
        }

        protected abstract map(x: string): TX;
    }

    export abstract class NumericGraph<TY> extends Graph<Date, TY> implements IHasMinMaxY {

        public getMaxYValue(): number {
            if (_(this.graphPoints).isEmpty()) { return null; }
            return _(this.graphPoints).chain().filter(gp => gp.y != null).map(gp => this.getYNumericValue(gp.y)).max().value();
        }

        public getMinYValue(): number {
            if (_(this.graphPoints).isEmpty()) { return null; }
            return _(this.graphPoints).chain().filter(gp => gp.y != null).map(gp => this.getYNumericValue(gp.y)).min().value();
        }

        protected abstract getYNumericValue(y: TY): number;
    }

    export class NumberParameterGraph extends NumericGraph<IParameter<number>> {

        constructor(
            serverObject?: Contract.Core.IGraph<IParameter<number>>,
            private readonly onlyDate?: boolean
        ) {
            super();
            this.initialize(serverObject);
        }

        protected map(x: string): Date {
            return this.onlyDate
                ? Shared.DateHelper.serverDateStringToDate(x)
                : Shared.DateHelper.serverDateStringToDateTime(x);
        }

        protected getYNumericValue(y: IParameter<number>): number {
            return y.Value;
        }
    }

    export class NumberGraph extends NumericGraph<number> {

        constructor(
            serverObject?: Contract.Core.IGraph<number>,
            private readonly onlyDate?: boolean,
            private readonly pointPerDay?: boolean
        ) {
            super();
            this.initialize(serverObject);
            this.pointPerDay && this.addPerDayPoints();
        }

        protected map(x: string): Date {
            return this.onlyDate
                ? Shared.DateHelper.serverDateStringToDate(x)
                : Shared.DateHelper.serverDateStringToDateTime(x);
        }

        protected getYNumericValue(y: number): number {
            return y;
        }

        protected addPerDayPoints(): void {
            if (_(this.graphPoints).isEmpty()) {
                return;
            }
            let currentPoint = this.graphPoints[0];
            let date = moment(currentPoint.x).add(1, 'day');
            let i = 1;
            let nextPoint = this.graphPoints[i++];
            let nextDate = nextPoint && moment(nextPoint.x);
            const points = [currentPoint];
            while (nextPoint) {
                if (date.isBefore(nextDate)) {
                    points.push(new GraphPoint<Date, number>(date.toDate(), currentPoint.y));
                } else {
                    points.push(nextPoint);
                    currentPoint = nextPoint;
                    nextPoint = this.graphPoints[i++];
                    nextDate = nextPoint && moment(nextPoint.x);
                }
                date = date.add(1, 'day');
            }
            this.graphPoints = points;
        }
    }

    export class BarGraph extends Graph<Date, IHighLowPoint<IParameter<number>>> implements IHasMinMaxY {

        constructor(
            low?: Contract.Core.IGraph<IParameter<number>>,
            high?: Contract.Core.IGraph<IParameter<number>>,
            private readonly onlyDate?: boolean
        ) {
            super();
            const combined = this.combine(
                new NumberParameterGraph(low, onlyDate),
                new NumberParameterGraph(high, onlyDate));
            this.initialize(combined);
        }

        public getMaxYValue(): number {
            if (_(this.graphPoints).isEmpty()) { return null; }
            const maxHigh = _(this.graphPoints).chain().filter(gp => gp.y.high != null && gp.y.high.Value != null).map(gp => gp.y.high.Value).max().value();
            const maxLow = _(this.graphPoints).chain().filter(gp => gp.y.low != null && gp.y.low.Value != null).map(gp => gp.y.low.Value).max().value();
            return maxHigh > maxLow ? maxHigh : maxLow;
        }

        public getMinYValue(): number {
            if (_(this.graphPoints).isEmpty()) { return null; }
            const minLow = _(this.graphPoints).chain().filter(gp => gp.y.low != null && gp.y.low.Value != null).map(gp => gp.y.low.Value).min().value();
            const minHigh = _(this.graphPoints).chain().filter(gp => gp.y.high != null && gp.y.high.Value != null).map(gp => gp.y.high.Value).min().value();
            return minLow < minHigh ? minLow : minHigh;
        }

        protected map(x: string): Date {
            return this.getDate(x);
        }

        private getDate(date: string): Date {
            return this.onlyDate
                ? Shared.DateHelper.serverDateStringToDate(date)
                : Shared.DateHelper.serverDateStringToDateTime(date);
        }

        private getDateString(date: Date): string {
            return this.onlyDate
                ? Shared.DateHelper.toServerDateString(date)
                : Shared.DateHelper.toServerDateTimeString(date);
        }

        private combine(
            low: NumberParameterGraph,
            high: NumberParameterGraph,
        ): Contract.Core.IGraph<IHighLowPoint<IParameter<number>>> {
            const result = {
                Scale: low.scale,
                Subject: low.subject,
                GraphPoints: []
            } as Contract.Core.IGraph<IHighLowPoint<IParameter<number>>>;
            result.Subject.Text += ` - ${high.subject.Text}`;

            _(low.graphPoints)
                .forEach(l => {
                    let h: GraphPoint<Date, IParameter<number>>;
                    do {
                        h = high.graphPoints.shift();
                        if (!h) {
                            continue;
                        }
                        if (h.x.getTime() === l.x.getTime()) {
                            result.GraphPoints.push({
                                X: this.getDateString(l.x),
                                Y: {
                                    low: {
                                        Value: l.y.Value,
                                        ExceedsThreshold: l.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    },
                                    high: {
                                        Value: h.y.Value,
                                        ExceedsThreshold: h.y.ExceedsThreshold,
                                        TherapyActionPart: null
                                    }
                                }
                            });
                        } else if (h.x.getTime() > l.x.getTime()) {
                            high.graphPoints.unshift(h);
                        }
                    } while (high.graphPoints.length > 0 && h.x.getTime() < l.x.getTime())
                });

            return result;
        }
    }

    export class QualitativeGraph extends Graph<Date, Shared.Contract.ICodedEntityTranslation[]> {

        constructor(
            serverObject?: Contract.Core.IGraph<Shared.Contract.ICodedEntityTranslation[]>,
            private readonly onlyDate?: boolean
        ) {
            super();
            this.initialize(serverObject);
            this.graphPoints = _(this.graphPoints).sortBy(g => g.x);
        }

        protected map(x: string): Date {
            return this.getDate(x);
        }

        private getDate(date: string): Date {
            return this.onlyDate
                ? Shared.DateHelper.serverDateStringToDate(date)
                : Shared.DateHelper.serverDateStringToDateTime(date);
        }
    }

    export class TherapyComplianceGraph extends Graph<Date, Contract.CarePlan.Read.ICodeSetItemRegistration> {
        public reminderTime: moment.Moment;
        public preferredTime: moment.Moment;
        public registrationFrom: moment.Moment;
        public registrationUntil: moment.Moment;

        constructor(serverObject?: Contract.CarePlan.Read.ITherapyComplianceGraph) {
            super();
            this.initialize(serverObject);
            if (serverObject != null) {
                this.preferredTime = this.getTimeMoment(Shared.DateHelper.serverTimeToDate(serverObject.PreferredTime));

                this.reminderTime = this.getTimeMoment(Shared.DateHelper.serverTimeToDate(serverObject.ReminderTime));
                if (this.reminderTime.isBefore(this.preferredTime)) {
                    this.reminderTime = this.reminderTime.add(1, 'days');
                }

                this.registrationFrom = this.getTimeMoment(Shared.DateHelper.serverTimeToDate(serverObject.RegistrationFrom));
                if (this.registrationFrom.isAfter(this.preferredTime)) {
                    this.registrationFrom = this.registrationFrom.subtract(1, 'days');
                }

                this.registrationUntil = this.getTimeMoment(Shared.DateHelper.serverTimeToDate(serverObject.RegistrationUntil));
                if (this.registrationUntil.isBefore(this.preferredTime)) {
                    this.registrationUntil = this.registrationUntil.add(1, 'days');
                }
            }
        }

        public getMaxYValue(): moment.Moment {
            const compareDate = this.registrationUntil.isValid() ? this.registrationUntil : this.preferredTime;
            if (_(this.graphPoints).isEmpty()) {
                return compareDate;
            }

            const max = _(this.graphPoints).max(g => this.getYValue(g.y).valueOf()).y;
            const momentMax = moment(this.getYValue(max));
            if (momentMax.isAfter(compareDate)) {
                return momentMax;
            }
            return compareDate;
        }

        public getMinYValue(): moment.Moment {
            const compareDate = this.registrationFrom.isValid() ? this.registrationFrom : this.preferredTime;
            if (_(this.graphPoints).isEmpty()) {
                return compareDate;
            }

            const min = _(this.graphPoints).min(g => this.getYValue(g.y).valueOf()).y;
            const momentMin = moment(this.getYValue(min));
            if (momentMin.isBefore(compareDate)) {
                return momentMin;
            }
            return compareDate;
        }

        public getYValue(y: Contract.CarePlan.Read.ICodeSetItemRegistration): Date {
            if (y.EventDateTime == null) {
                return this.registrationUntil.isValid() ? this.registrationUntil.toDate() : this.preferredTime.toDate();
            }
            const momentDate = this.getTimeMoment(Shared.DateHelper.serverDateStringToDateTime(y.EventDateTime));
            return momentDate.toDate();
        }

        protected map(x: string): Date {
            return Shared.DateHelper.serverDateStringToDate(x);
        }

        private getTimeMoment(y: Date): moment.Moment {
            const momentDate = moment(y);
            return moment({ hour: momentDate.hours(), minute: momentDate.minutes() });
        }
    }

    export class Boxplot extends Graph<Date, IBoxPlotPoint<IParameter<number>>> implements IHasMinMaxY {

        constructor(
            min?: Contract.Core.IGraph<IParameter<number>>,
            firstQ?: Contract.Core.IGraph<IParameter<number>>,
            median?: Contract.Core.IGraph<IParameter<number>>,
            thirdQ?: Contract.Core.IGraph<IParameter<number>>,
            max?: Contract.Core.IGraph<IParameter<number>>,
            private readonly onlyDate?: boolean) {
            super();

            const combined = this.combine(
                new NumberParameterGraph(min, onlyDate),
                new NumberParameterGraph(firstQ, onlyDate),
                new NumberParameterGraph(median, onlyDate),
                new NumberParameterGraph(thirdQ, onlyDate),
                new NumberParameterGraph(max, onlyDate)
            );
            this.initialize(combined);
        }

        public getMaxYValue(): number {
            if (_(this.graphPoints).isEmpty()) {
                return null;
            }
            const maxHigh = _(this.graphPoints).chain().filter(gp => gp.y.max != null && gp.y.max.Value != null).map(gp => gp.y.max.Value).max().value();
            const maxLow = _(this.graphPoints).chain().filter(gp => gp.y.min != null && gp.y.min.Value != null).map(gp => gp.y.min.Value).max().value();
            return maxHigh > maxLow ? maxHigh : maxLow;
        }

        public getMinYValue(): number {
            if (_(this.graphPoints).isEmpty()) {
                return null;
            }
            const minLow = _(this.graphPoints).chain().filter(gp => gp.y.min != null && gp.y.min.Value != null).map(gp => gp.y.min.Value).min().value();
            const minHigh = _(this.graphPoints).chain().filter(gp => gp.y.max != null && gp.y.max.Value != null).map(gp => gp.y.max.Value).min().value();
            return minLow < minHigh ? minLow : minHigh;
        }

        protected map(x: string): Date {
            return this.getDate(x);
        }

        private getDate(date: string): Date {
            return this.onlyDate
                ? Shared.DateHelper.serverDateStringToDate(date)
                : Shared.DateHelper.serverDateStringToDateTime(date);
        }

        private getDateString(date: Date): string {
            return this.onlyDate
                ? Shared.DateHelper.toServerDateString(date)
                : Shared.DateHelper.toServerDateTimeString(date);
        }

        private combine(
            min: NumberParameterGraph,
            firstQ: NumberParameterGraph,
            median: NumberParameterGraph,
            thirdQ: NumberParameterGraph,
            max: NumberParameterGraph
        ): Contract.Core.IGraph<IBoxPlotPoint<IParameter<number>>> {
            const result = {
                Scale: min.scale,
                Subject: min.subject,
                GraphPoints: []
            } as Contract.Core.IGraph<IBoxPlotPoint<IParameter<number>>>;
            result.Subject.Text += ` - ${firstQ.subject.Text} - ${median.subject.Text} - ${thirdQ.subject.Text} - ${max.subject.Text}`;

            _(min.graphPoints)
                .forEach(m => {
                    const firstPoint = _(firstQ.graphPoints).find<GraphPoint<Date, IParameter<number>>>(f => f.x.getTime() === m.x.getTime());
                    const medPoint = _(median.graphPoints).find<GraphPoint<Date, IParameter<number>>>(f => f.x.getTime() === m.x.getTime());
                    const thirdPoint = _(thirdQ.graphPoints).find<GraphPoint<Date, IParameter<number>>>(f => f.x.getTime() === m.x.getTime());
                    const maxPoint = _(max.graphPoints).find<GraphPoint<Date, IParameter<number>>>(f => f.x.getTime() === m.x.getTime());
                    if (firstPoint && medPoint && thirdPoint && maxPoint) {
                        result.GraphPoints.push({
                            X: this.getDateString(m.x),
                            Y: {
                                min: {
                                    Value: m.y.Value,
                                    ExceedsThreshold: m.y.ExceedsThreshold,
                                    TherapyActionPart: null
                                },
                                firstQ: {
                                    Value: firstPoint.y.Value,
                                    ExceedsThreshold: firstPoint.y.ExceedsThreshold,
                                    TherapyActionPart: null
                                },
                                median: {
                                    Value: medPoint.y.Value,
                                    ExceedsThreshold: medPoint.y.ExceedsThreshold,
                                    TherapyActionPart: null
                                },
                                thirdQ: {
                                    Value: thirdPoint.y.Value,
                                    ExceedsThreshold: thirdPoint.y.ExceedsThreshold,
                                    TherapyActionPart: null
                                },
                                max: {
                                    Value: maxPoint.y.Value,
                                    ExceedsThreshold: maxPoint.y.ExceedsThreshold,
                                    TherapyActionPart: null
                                }
                            }
                        });
                    }
                });

            return result;
        }
    }
}