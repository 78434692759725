var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var EditActionController = /** @class */ (function (_super) {
            __extends(EditActionController, _super);
            function EditActionController($scope, $translate, toaster, carePlanApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.carePlanApiSvc = carePlanApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                $scope.checkValidFromDate = function () { return _this.checkValidFromDate(); };
                $scope.omitChanged = function () { return _this.omitChanged(); };
                $scope.resetToDefaultChanged = function () { return _this.resetToDefaultChanged(); };
                $scope.periodicityChanged = function () { return _this.periodicityChanged(); };
                $scope.deviationPlanningChanged = function () { return _this.deviationPlanningChanged(); };
                $scope.periodicityChanged = function () { return _this.periodicityChanged(); };
                $scope.periodicityAndDeviationPlanningChanged = function () { return _this.periodicityAndDeviationPlanningChanged(); };
                return _this;
            }
            EditActionController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.personalisations = {
                    periodicity: false,
                    periodicityAndDeviationPlanning: false,
                    deviationPlanning: false
                };
                this.setRadioButtonOptionsCorrect();
                this.$scope.$watch(function (s) { return s.configuredTherapyAction.resetToDefault; }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue) {
                            _this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                            var defaultConfiguration = _this.getDefaultConfiguration(_this.$scope.configuredTherapyAction.validFromDate);
                            _this.setDefaultConfigurationPeriodicity(defaultConfiguration);
                            _this.setDefaultConfigurationDuration(defaultConfiguration);
                        }
                    }
                });
            };
            EditActionController.prototype.setRadioButtonOptionsCorrect = function () {
                if (this.$scope.configuredTherapyAction.isPeriodicitiesPersonalised &&
                    (this.$scope.configuredTherapyAction.possibleActionDurationOptionId != null ||
                        this.$scope.configuredTherapyAction.durationOfAction != null)) {
                    this.$scope.personalisations.periodicityAndDeviationPlanning = true;
                }
                else {
                    if (this.$scope.configuredTherapyAction.isPeriodicitiesPersonalised) {
                        this.$scope.personalisations.periodicity = true;
                    }
                    if (this.$scope.configuredTherapyAction.possibleActionDurationOptionId != null ||
                        this.$scope.configuredTherapyAction.durationOfAction != null) {
                        this.$scope.personalisations.deviationPlanning = true;
                    }
                }
            };
            EditActionController.prototype.setDefaultConfigurationToShow = function () {
                var date = RemeCare.Shared.DateHelper.today();
                if (this.$scope.configuredTherapyAction.validFromDate != null) {
                    date = this.$scope.configuredTherapyAction.validFromDate;
                }
                var defaultConfiguration = this.getDefaultConfiguration(date);
                this.setDefaultConfigurationPeriodicity(defaultConfiguration);
                this.setDefaultConfigurationDuration(defaultConfiguration);
            };
            EditActionController.prototype.personalisationsUncheck = function () {
                this.$scope.personalisations.periodicity = false;
                this.$scope.personalisations.deviationPlanning = false;
                this.$scope.personalisations.periodicityAndDeviationPlanning = false;
            };
            EditActionController.prototype.omitChanged = function () {
                this.personalisationsUncheck();
                this.$scope.configuredTherapyAction.resetToDefault = false;
            };
            EditActionController.prototype.resetToDefaultChanged = function () {
                this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                this.personalisationsUncheck();
                this.setDefaultConfigurationToShow();
            };
            EditActionController.prototype.periodicityChanged = function () {
                this.$scope.configuredTherapyAction.resetToDefault = false;
                this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                this.$scope.personalisations.periodicityAndDeviationPlanning = false;
                this.$scope.personalisations.deviationPlanning = false;
            };
            EditActionController.prototype.deviationPlanningChanged = function () {
                this.$scope.configuredTherapyAction.resetToDefault = false;
                this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                this.$scope.personalisations.periodicityAndDeviationPlanning = false;
                this.$scope.personalisations.periodicity = false;
            };
            EditActionController.prototype.periodicityAndDeviationPlanningChanged = function () {
                this.$scope.configuredTherapyAction.resetToDefault = false;
                this.$scope.configuredTherapyAction.isRemovedFromCarePlan = false;
                this.$scope.personalisations.deviationPlanning = false;
                this.$scope.personalisations.periodicity = false;
            };
            EditActionController.prototype.getDefaultConfiguration = function (date) {
                date = date ? RemeCare.Shared.DateHelper.dayOfDate(date) : RemeCare.Shared.DateHelper.today();
                var defaultConfiguration = _.find(this.$scope.defaultConfigurations, function (dc) { return dc.validUntilDate == null || moment(dc.validUntilDate).isAfter(date); });
                return defaultConfiguration;
            };
            EditActionController.prototype.setDefaultConfigurationPeriodicity = function (defaultConfiguration) {
                this.$scope.configuredTherapyAction.periodicities = _(defaultConfiguration.periodicities).map(function (p) { return p.copy(); });
            };
            EditActionController.prototype.setDefaultConfigurationDuration = function (defaultConfiguration) {
                this.$scope.configuredTherapyAction.durationOfAction = defaultConfiguration.durationOfAction;
                this.$scope.configuredTherapyAction.possibleActionDurationOptionId =
                    defaultConfiguration.possibleActionDurationOptionId;
                this.$scope.configuredTherapyAction.possibleActionDurationOptionName =
                    defaultConfiguration.possibleActionDurationOptionName;
            };
            EditActionController.prototype.checkValidFromDate = function () {
                if (this.$scope.configuredTherapyAction.resetToDefault) {
                    var defaultConfiguration = this.getDefaultConfiguration(this.$scope.configuredTherapyAction.validFromDate);
                    this.setDefaultConfigurationPeriodicity(defaultConfiguration);
                    this.setDefaultConfigurationDuration(defaultConfiguration);
                }
            };
            EditActionController.prototype.confirm = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var personalisedTherapyAction, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.$scope.form.$setSubmitted();
                                if (this.$scope.form.$invalid) {
                                    this.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                personalisedTherapyAction = this.$scope.configuredTherapyAction.toServerWrite();
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.carePlanApiSvc.setPersonalisedTherapyActionAsync(this.$scope.carePlanId, this.$scope.structuralTherapyActionId, personalisedTherapyAction)];
                            case 2:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                                this.$scope.$close();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.errorCallback(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return EditActionController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('editActionCtrl', function ($scope, $translate, toaster, carePlanApiSvc) {
            return new EditActionController($scope, $translate, toaster, carePlanApiSvc);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
