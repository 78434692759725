(function (H) {
    function symbolWrap(proceed, symbol, x, y, w, h, options) {
        if (symbol.indexOf('text:') === 0) {
            var text = symbol.split(':')[1],
                svgElem = this.text(text, x, y)
                    .attr({
                        translateY: h,
                        translateX: -4
                    })
                    .css({
                        fontFamily: 'FontAwesome',
                        fontSize: h * 2
                    });

            if (svgElem.renderer.isVML) {
                svgElem.fillSetter = function (value, key, element) {
                    element.style.color = (<HighchartsGradient>H.Color(value)).get('rgb');
                };
            }
            return svgElem;
        }
        return proceed.apply(this, [].slice.call(arguments, 1));
    }
    (<any>H).wrap((<any>H).SVGRenderer.prototype, 'symbol', symbolWrap);
    if ((<any>H).VMLRenderer) {
        (<any>H).wrap((<any>H).VMLRenderer.prototype, 'symbol', symbolWrap);
    }
} (Highcharts));