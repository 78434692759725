namespace RemeCare.Management {
    import Person = Contract.Party.Read.IPerson;
    import Framework = Shared.Framework;
    import SearchFunction = Shared.Framework.Grid.SearchFunction;

    interface IGlobalActorRoleDetailsScope extends Shared.Framework.IBaseScope {
        globalActorRole: GlobalActorRole;
        globalActorRolePartyIds: Shared.Contract.Guid[];
        canEdit: boolean;
        grid: Shared.Framework.Grid.Grid<Person>;
        editTranslations(): void;
        saveRemark(): void;
        handleActive(): void;
        addPartyRole(): void;
    }

    class GlobalActorRoleDetailsController extends Shared.Framework.ControllerBase<IGlobalActorRoleDetailsScope> {
        constructor(
            protected $scope: IGlobalActorRoleDetailsScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly $stateParams,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly globalActorRoleSvc: GlobalActorRoleService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.editTranslations = () => this.editTranslations();
            $scope.saveRemark = () => this.saveRemarkAsync();
            $scope.handleActive = () => this.handleActiveAsync();
            $scope.addPartyRole = () => this.addPartyRoleAsync();
        }

        public async $onInit(): Promise<void> {
            this.$scope.canEdit = this.authservice.hasRight(
                Shared.Framework.AuthContext.managementActorRoles,
                Shared.Framework.AuthRight.Write
            );
            this.buildGrid();
            this.getGlobalActorRoleAsync();
        }

        private async getGlobalActorRoleAsync(): Promise<void> {
            try {
                const globalActorRoleData = await this.globalActorRoleSvc.getGlobalActorRoleAsync(
                    this.$stateParams.actorRoleId
                );
                this.$scope.globalActorRole = new GlobalActorRole(globalActorRoleData);

                if (_(globalActorRoleData.PartyRoleIds).any()) {
                    this.$scope.globalActorRolePartyIds = globalActorRoleData.PartyRoleIds;
                    this.$scope.grid.search();
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private searchFunction: SearchFunction<Person> = (page, pageSize, sortField, sortOrder, criteria) => {
            const query: Contract.Party.Read.Query.IFindPersonsQuery = {
                partyRoleIds: this.$scope.globalActorRolePartyIds,
                sortOrder,
                pageSize,
                page,
                sortField,
            };
            return this.partyApiSvc.findPersonsAsync(query).then((persons) => {
                if (persons.Items != null) {
                    this.$scope.globalActorRole.parties = persons.Items;
                }
                return persons;
            });
        };

        private buildGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<Person>(this.searchFunction)
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('PartyRoles[0].PartyRoleType.Text', 'General.Type')
                .addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date: "shortDate"' })
                .addActionColumn('eye-open', (p) => this.navigateToPerson(p));
            if (this.$scope.canEdit) {
                builder = builder.addDeleteButtonColumn(null, (p) => this.deletePartyRoleAsync(p));
            }
            this.$scope.grid = builder.build();
        }

        private navigateToPerson(person: Person): void {
            this.$state.go('management.persons.detail', { partyId: person.PartyId });
        }

        private deletePartyRoleAsync(person: Person): Promise<boolean> {
            const text = this.$translate.instant('Views.Management.GlobalActorRoles.RemoveMemberText', {
                firstName: person.FirstName,
                lastName: person.LastName,
                actorRole: this.$scope.globalActorRole.name,
            });
            return new Promise<boolean>((resolve) => {
                this.$dialog.confirmBox(
                    'Views.Management.GlobalActorRoles.RemoveMember',
                    text,
                    async () => {
                        try {
                            await this.globalActorRoleSvc.deletePartyRoleAsync(
                                this.$scope.globalActorRole.id,
                                person.PartyRoles[0].Id
                            );
                            this.generalSuccess();
                            await this.getGlobalActorRoleAsync();
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private async saveRemarkAsync(): Promise<void> {
            try {
                await this.globalActorRoleSvc.saveRemarkAsync(
                    this.$scope.globalActorRole.id,
                    this.$scope.globalActorRole.internalRemark
                );
                this.generalSuccess();
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private async handleActiveAsync(): Promise<void> {
            try {
                await this.globalActorRoleSvc.setActiveAsync(
                    this.$scope.globalActorRole.id,
                    this.$scope.globalActorRole.isActive
                );
                this.generalSuccess();
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private async addPartyRoleAsync(): Promise<void> {
            try {
                const person = await this.modalBuilderFactory.showSearchModalAsync<Person>(
                    'Views.Management.GlobalActorRoles.SearchEmployeesAndHealthCareProfessionals',
                    'views/management/globalActorRoles/searchPersonsCriteria.html',
                    (q: Contract.Party.Read.Query.IFindPersonsQuery) => {
                        if (q.partyRoleType == null) {
                            q.partyRoleTypes = [
                                Shared.Contract.Code.PartyRoleType.Employee,
                                Shared.Contract.Code.PartyRoleType.HealthCareProfessional,
                            ];
                        } else {
                            q.partyRoleTypes = null;
                        }
                        return this.partyApiSvc.findPersonsAsync(q);
                    },
                    [
                        {
                            displayName: 'General.LastName',
                            field: 'LastName',
                        },
                        {
                            displayName: 'General.FirstName',
                            field: 'FirstName',
                        },
                        {
                            displayName: 'General.DateOfBirth',
                            field: 'DateOfBirth',
                            options: {
                                cellFilter: 'date: "shortDate"',
                            },
                        },
                        {
                            displayName: 'General.City',
                            field: 'City',
                        },
                    ],
                    'views/management/globalActorRoles/searchPersonsDetails.html',
                    120
                );

                let partyRole = _.find(
                    person.PartyRoles,
                    (pr) => pr.Type.Id === Shared.Contract.Code.PartyRoleType.Employee
                );
                if (partyRole == null) {
                    partyRole = _.find(
                        person.PartyRoles,
                        (pr) => pr.Type.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional
                    );
                }
                await this.globalActorRoleSvc.addPartyRole(
                    this.$scope.globalActorRole.id,
                    partyRole.Id,
                    partyRole.Type.Id
                );
                this.generalSuccess();
                await this.getGlobalActorRoleAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private editTranslations(): void {
            this.modalBuilderFactory
                .createModalBuilder<GlobalActorRole>()
                .setController('globalActorRoleTranslationsCtrl')
                .setTemplateUrl('views/management/globalActorRoles/globalActorRoleTranslations.html')
                .setScope({
                    globalActorRole: this.copy(this.$scope.globalActorRole),
                    editMode: true,
                })
                .setResultCallBack(async (gar) => {
                    try {
                        await this.globalActorRoleSvc.saveTranslationsAsync(gar.id, gar.toServerWrite().translations);
                        this.getGlobalActorRoleAsync();
                        this.generalSuccess();
                    } catch (e) {
                        this.toaster.error(e);
                    }
                })
                .build();
        }
    }

    remeCareManagementModule.controller('globalActorRoleDetailsCtrl', GlobalActorRoleDetailsController);
}
