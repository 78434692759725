var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var StandardPhraseTextDirective = /** @class */ (function () {
                function StandardPhraseTextDirective($filter, authservice) {
                    var _this = this;
                    this.$filter = $filter;
                    this.authservice = authservice;
                    this.restrict = 'EA';
                    this.replace = true;
                    this.scope = {
                        phrases: '=',
                        text: '=ngModel',
                        required: '=ngRequired',
                        maxlength: '=ngMaxlength',
                        readonly: '=ngReadonly',
                        id: '@',
                        name: '@',
                        timestamp: '='
                    };
                    this.link = function (scope, element) {
                        var getCursorPosition = function (textArea) {
                            var iCaretPos = 0;
                            if (document.selection) {
                                textArea.focus();
                                var oSel = document.selection.createRange();
                                oSel.moveStart('character', -textArea.value.length);
                                iCaretPos = oSel.text.length;
                            }
                            else if (textArea.selectionStart || textArea.selectionStart == '0') {
                                iCaretPos = textArea.selectionStart;
                            }
                            return iCaretPos;
                        };
                        scope.addPhrase = function (phrase) {
                            if (scope.readonly)
                                return;
                            var textArea = element.find('textarea')[0];
                            var cursorPos = getCursorPosition(textArea);
                            var text = textArea.value || '';
                            text = text.slice(0, cursorPos) +
                                phrase.replace(/<br\/>/g, '\n') +
                                ' ' +
                                text.slice(cursorPos, text.length);
                            if (scope.maxlength && scope.maxlength < text.length) {
                                text = text.slice(0, scope.maxlength);
                            }
                            scope.text = text;
                            textArea.focus();
                        };
                        scope.addTimestamp = function () {
                            if (scope.readonly)
                                return;
                            var textArea = element.find('textarea')[0];
                            var cursorPos = getCursorPosition(textArea);
                            var timeStamp = _this.$filter('date')(new Date(), 'short');
                            var text = textArea.value;
                            text = text.slice(0, cursorPos) + '[' + _this.authservice.getClaim(Shared.Framework.ClaimTypes.firstname) +
                                ' ' + _this.authservice.getClaim(Shared.Framework.ClaimTypes.lastname) + ' ' + timeStamp + '] ' + text.slice(cursorPos, text.length);
                            if (text.length > 3000) {
                                text = text.slice(0, 3000);
                            }
                            scope.text = text;
                            textArea.focus();
                        };
                    };
                    this.templateUrl = 'views/shared/standardPhraseText.html';
                }
                return StandardPhraseTextDirective;
            }());
            RemeCare.remeCareAppModule.directive('standardPhraseText', function ($filter, authservice) { return new StandardPhraseTextDirective($filter, authservice); });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
