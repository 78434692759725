namespace RemeCare.Inbox {
    import Patient = Contract.Patient.Read.IPatient;

    class InboxPatientSearchController {
        public patientGrid: Shared.Framework.Grid.Grid<Patient>;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly patientSearchSvc: Patient.PatientSearchService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance
        ) {}

        public $onInit(): void {
            this.buildGrid();
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<Patient>> {
            sortField = sortField || 'lastName';
            sortDirection = sortDirection || 'asc';

            const query = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                firstName: criteria.firstname,
                lastName: criteria.lastname,
                patientNumber: criteria.patientnumber,
            } as Contract.Party.Read.Query.IFindPatientsQuery;

            try {
                const searchResult = await this.patientSearchSvc.findPatientsAsync(query);
                return searchResult;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private selectPatient(patient: Patient): void {
            this.$uibModalInstance.close(patient);
        }

        private buildGrid() {
            this.patientGrid = this.gridBuilderSvc
                .createGridBuilder<Patient>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date: "shortDate" ' })
                .addSelectButtonColumn(r => this.selectPatient(r))
                .build();
        }
    }

    remeCareInboxModule.controller('inboxPatientSearchController', InboxPatientSearchController);
}
