namespace RemeCare.Model {
    import Guid = Shared.Contract.Guid;

    export class HealthCareParty {
        public partyRoleId: Guid;
        public address: string;
        public city: string;
        public zipCode: string;
        public country: string;

        constructor(serverParty?: Contract.HealthCareParty.Read.IHealthCareParty) {
            if (serverParty != null) {
                this.partyRoleId = serverParty.PartyRoleId;
                this.address = serverParty.AddressLine;
                this.city = serverParty.City;
                this.zipCode = serverParty.ZipCode;
                this.country = null;
            }
        }
    }

    export class HealthCareProfessional extends HealthCareParty {
        public lastName: string;
        public firstName: string;
        public email: string;
        public identificationNumber: string;
        public phoneNumber: Model.PhoneNumber = new Model.PhoneNumber();
        public mobileNumber: Model.PhoneNumber = new Model.PhoneNumber();
        public healthCareSpecialtyProfessions: Shared.Contract.IEnumTranslation[];

        constructor(serverParty?: Contract.HealthCareParty.Read.IHealthCareProfessionalParty) {
            super(serverParty);
            if (serverParty != null) {
                this.lastName = serverParty.LastName;
                this.firstName = serverParty.FirstName;
                this.email = serverParty.Email;
                this.identificationNumber = serverParty.IdentificationNumber;
                this.phoneNumber = new Model.PhoneNumber(serverParty.PhoneNumber);
                this.mobileNumber = new Model.PhoneNumber(serverParty.MobileNumber);
                this.healthCareSpecialtyProfessions = serverParty.HealthCareSpecialtyProfessions;
            }
        }

        public getName(): string {
            return `${this.lastName != null ? this.lastName : ''} ${this.firstName != null ? this.firstName : ''}`;
        }
    }
}
