var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        var ApiServiceBase = RemeCare.Shared.Framework.ApiServiceBase;
        var IntegrationMessageSearchService = /** @class */ (function (_super) {
            __extends(IntegrationMessageSearchService, _super);
            function IntegrationMessageSearchService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            IntegrationMessageSearchService.prototype.findIntegrationMessagesAsync = function (query) {
                var url = 'IntegrationMessages';
                return this.getPromise(url, query, 'integrationMessages.search');
            };
            IntegrationMessageSearchService.prototype.getSendersAsync = function () {
                var url = 'IntegrationMessages/Senders';
                return this.getPromise(url, null, 'integrationMessages.search');
            };
            IntegrationMessageSearchService.prototype.getStatusesAsync = function () {
                var url = 'IntegrationMessages/Message-statuses';
                return this.getPromise(url, null, 'integrationMessages.search');
            };
            IntegrationMessageSearchService.prototype.getErrorCodesAsync = function () {
                var url = 'IntegrationMessages/Error-codes';
                return this.getPromise(url, null, 'integrationMessages.search');
            };
            IntegrationMessageSearchService.$inject = ApiServiceBase.injectionParameters;
            return IntegrationMessageSearchService;
        }(ApiServiceBase));
        IntegrationMonitor.IntegrationMessageSearchService = IntegrationMessageSearchService;
        IntegrationMonitor.remeCareIntegrationMonitorModule.service('integrationMessageSearchSvc', IntegrationMessageSearchService);
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
