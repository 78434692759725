namespace RemeCare.Agenda {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import IEntityTranslation = Shared.Contract.IEntityTranslation;
    import MasterDataService = Shared.Framework.MasterdataService;
    import TherapyApiService = Core.Services.TherapyApiService;

    class MyAgendaFilterController implements ng.IComponentController {
        // bindings
        public filter: IAgendaFilter;

        public actionAssignmentTypes: EnumTranslation[];
        public actionStatuses: EnumTranslation[];
        public actionTypes: EnumTranslation[];
        public actorRoles: IEntityTranslation[];
        public therapies: IEntityTranslation[];

        constructor(
            private readonly actorRolesApiSvc: Core.Services.ActorRolesApiService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly masterdataSvc: MasterDataService,
            private readonly therapyApiSvc: TherapyApiService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public async $onInit(): Promise<void> {
            try {
                let actorRoles: Contract.Therapy.Read.IActorRoleSummary[];
                let globalActorRoles: IEntityTranslation[];
                [
                    actorRoles,
                    globalActorRoles,
                    this.actionAssignmentTypes,
                    this.actionStatuses,
                    this.actionTypes,
                    this.therapies,
                ] = await Promise.all([
                    this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true }),
                    this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareManager ||
                    this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.AdministrativeManager
                        ? this.actorRolesApiSvc.findGlobalActorRolesAsync()
                        : Promise.resolve([]),
                    this.masterdataSvc.getReferenceDataAsync(Shared.Framework.ReferenceDataTypes.actionAssignmentType),
                    this.masterdataSvc.getCarePlanActionStatuses(),
                    this.masterdataSvc.getCarePlanActionTypes(),
                    this.therapyApiSvc.getTherapiesAsync(),
                ]);
                const allActorRoles = _(actorRoles).map(ar => ({
                    Id: ar.Id,
                    Text: ar.IsGlobal ? ar.Text : `${ar.Text} (${ar.Therapy.Text})`,
                }));
                _(globalActorRoles)
                    .filter(ga => _.find(allActorRoles, ar => ar.Id === ga.Id) == null)
                    .forEach(ga => allActorRoles.push(ga));
                this.actorRoles = _(allActorRoles).sortBy(ar => ar.Text);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public clearFilter(): void {
            this.filter.patientData.patient = null;
            this.filter.actionTypes = [];
            this.filter.statuses = [];
            this.filter.therapyIds = [];
        }
    }

    remeCareAgendaModule.component('rcMyAgendaFilter', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            filter: '<',
        },
        controller: MyAgendaFilterController,
        templateUrl: 'agenda/myAgenda/myAgendaFilter/myAgendaFilter.html',
    });
}
