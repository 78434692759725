namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import MediaForAction = Patient.Model.MediaForAction;
    import CarePlanApiService = Core.Services.CarePlanApiService;
    import FutureCarePlanMediaLibItem = Patient.Model.FutureCarePlanMediaLibItem;

    interface ICarePlanMediaHistoryScope extends Shared.Framework.IBaseScope {
        carePlan: CarePlan;
        hasMedia: boolean;
        mediaForAction: MediaForAction[];
        switchSequence: (item1: FutureCarePlanMediaLibItem, item2: FutureCarePlanMediaLibItem) => Promise<void>;

        readOnly: boolean;
    }

    class CarePlanMediaHistoryController extends Shared.Framework.ControllerBase<ICarePlanMediaHistoryScope> {
        constructor(
            protected $scope: ICarePlanMediaHistoryScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.switchSequence = (item1, item2) => this.switchSequenceAsync(item1, item2);
        }

        public $onInit(): void {
            if (this.$scope.carePlan) {
                this.getMediaItemsAsync();
            }

            this.$scope.$watch('carePlan', (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== null) {
                    this.getMediaItemsAsync();
                }
            });
        }

        private async getMediaItemsAsync(): Promise<void> {
            this.$scope.readOnly = !this.$scope.carePlan.statusOpen;

            try {
                const data = await this.carePlanApiSvc.getMediaLibItemsAsync(this.$scope.carePlan.id);
                this.$scope.mediaForAction = _(data)
                    .chain()
                    .groupBy(x => `${x.Action.Id}${x.ActionPart.Id}`)
                    .map(x => new Patient.Model.MediaForAction(x))
                    .value();
                this.$scope.hasMedia = _(this.$scope.mediaForAction).any();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async switchSequenceAsync(
            item1: FutureCarePlanMediaLibItem,
            item2: FutureCarePlanMediaLibItem
        ): Promise<void> {
            try {
                await this.carePlanApiSvc.switchMediaLibItemsAsync(this.$scope.carePlan.id, item1.id, item2.id);
                const sequence = item1.sequence;
                item1.sequence = item2.sequence;
                item2.sequence = sequence;

                const relativeSequence = item1.relativeSequence;
                item1.relativeSequence = item2.relativeSequence;
                item2.relativeSequence = relativeSequence;

                _(this.$scope.mediaForAction).each(
                    x => (x.futureMedia = _.sortBy(x.futureMedia, y => -y.relativeSequence))
                );
                this.$scope.$apply();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'CarePlanMediaHistoryCtrl',
            ($scope, $translate, toaster, carePlanApiSvc: CarePlanApiService) =>
                new CarePlanMediaHistoryController($scope, $translate, toaster, carePlanApiSvc)
        );
}
