namespace RemeCare.Management {
    import GlobalActorRoleSummary = Contract.Management.Read.IGlobalActorRoleSummary;

    interface ISearchGlobalACtorRolesScope extends Shared.Framework.IBaseScope {
        grid: Shared.Framework.Grid.Grid<Contract.Management.Read.IGlobalActorRoleSummary>;
        searchCollapsed: boolean;
        addGlobalActorRole(): void;
        search(): void;
    }

    class SearchGlobalActorRolesController extends Shared.Framework.ControllerBase<ISearchGlobalACtorRolesScope> {
        private canEdit: boolean;

        constructor(
            protected $scope: ISearchGlobalACtorRolesScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly globalActorRoleSvc: GlobalActorRoleService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster);
            $scope.search = () => this.search();
            $scope.addGlobalActorRole = () => this.addGlobalActorRole();
        }

        public $onInit(): void {
            this.canEdit = this.authservice.hasRight(
                Shared.Framework.AuthContext.managementActorRoles,
                Shared.Framework.AuthRight.Write
            );
            this.buildGrid();
            this.$scope.searchCollapsed = true;
        }

        public addGlobalActorRole(): void {
            this.modalBuilderFactory
                .createModalBuilder<GlobalActorRole>()
                .setController('globalActorRoleTranslationsCtrl')
                .setTemplateUrl('views/management/globalActorRoles/globalActorRoleTranslations.html')
                .setScope({
                    globalActorRole: new GlobalActorRole(),
                    editMode: true,
                })
                .setResultCallBack(async gar => {
                    try {
                        const r = await this.globalActorRoleSvc.createGlobalActorRoleAsync(gar.toServerWrite());
                        this.openDetails(r);
                    } catch (e) {
                        this.toaster.error(e);
                    }
                })
                .build();
        }

        private search(): void {
            this.$scope.grid.pagingOptions.currentPage = 1;
            this.$scope.grid.search();
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<GlobalActorRoleSummary>> {
            const name = criteria.name;
            const query: Contract.Management.Read.Query.IFindGlobalActorRolesQuery = {
                name: name ? name : '',
                partyRoleIds: null,
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
            };

            try {
                return await this.globalActorRoleSvc.searchSummariesAsync(query);
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private openDetails(id: Shared.Contract.Guid): void {
            this.$state.go('management.globalActorRoles.details', { actorRoleId: id });
        }

        private async deleteActorAsync(entity: Contract.Management.Read.IGlobalActorRoleSummary): Promise<boolean> {
            return new Promise<boolean>(resolve => {
                const text = this.$translate.instant('Views.Management.GlobalActorRoles.RemoveGlobalActorRoleText', {
                    actorRole: entity.Text,
                });
                this.$dialog.confirmBox(
                    'Views.Management.GlobalActorRoles.RemoveGlobalActorRole',
                    text,
                    async () => {
                        try {
                            await this.globalActorRoleSvc.deleteGlobalActorRoleAsync(entity.Id);
                            resolve(true);
                        } catch (e) {
                            this.toaster.error(e);
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    }
                );
            });
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<GlobalActorRoleSummary>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .setBindToUrl()
                .setSearchWhenReady()
                .addColumn('Text', 'General.Name', { width: '*' })
                .addConditionalDeleteButtonColumn(x => !x.CanBeDeleted, null, ar => this.deleteActorAsync(ar))
                .addConditionalShowEditNoButtonNavigationColumn(
                    () => !this.canEdit,
                    () => false,
                    'management.globalActorRoles.details',
                    { actorRoleId: 'Id' }
                );
            this.$scope.grid = gridBuilder.build();
        }
    }

    remeCareManagementModule.controller('searchGlobalActorRolesCtrl', SearchGlobalActorRolesController);
}
