var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var AnamnesisInfo = /** @class */ (function () {
                function AnamnesisInfo(serverObject) {
                    if (serverObject != null) {
                        this.id = serverObject.Id;
                        this.patientId = serverObject.PatientId;
                        this.text = serverObject.Text;
                        this.observationDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ObservationDate);
                    }
                }
                AnamnesisInfo.prototype.toServerWrite = function () {
                    return {
                        Id: this.id,
                        PatientId: this.patientId,
                        Text: this.text,
                        ObservationDate: this.observationDate !== null ? RemeCare.Shared.DateHelper.toServerDateString(this.observationDate) : null
                    };
                };
                return AnamnesisInfo;
            }());
            Model.AnamnesisInfo = AnamnesisInfo;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
