var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Inbox;
    (function (Inbox) {
        var InboxController = /** @class */ (function () {
            function InboxController(toaster, $rootScope, $animate, $state, masterdataSvc, therapyApiSvc, gridBuilderSvc, alarmsApiSvc) {
                this.toaster = toaster;
                this.$rootScope = $rootScope;
                this.$animate = $animate;
                this.$state = $state;
                this.masterdataSvc = masterdataSvc;
                this.therapyApiSvc = therapyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.alarmsApiSvc = alarmsApiSvc;
            }
            InboxController.prototype.$onInit = function () {
                var _this = this;
                if (_(this.$state.params).isEmpty()) {
                    this.hideDetails();
                }
                else {
                    this.showDetails();
                }
                this.loadMasterDataAsync();
                this.$rootScope.$on('alarmHandled', function (event, alarmId) { return __awaiter(_this, void 0, void 0, function () {
                    var alarm, a, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                alarm = _.find(this.alarmsGrid.getData(), function (a) { return a.Id === alarmId; });
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.alarmsApiSvc.getAlarmAsync(alarmId)];
                            case 2:
                                a = _a.sent();
                                angular.copy(a, alarm);
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
                this.buildAlarmsGrid();
            };
            InboxController.prototype.loadMasterDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getAlarmLevelsAsync(),
                                        this.therapyApiSvc.getTherapiesAsync()
                                    ])];
                            case 1:
                                _a = _b.sent(), this.alarmLevels = _a[0], this.therapies = _a[1];
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            InboxController.prototype.hideDetails = function () {
                var _this = this;
                var details = angular.element('#inbox-details');
                var overview = angular.element('#inbox-overview');
                var body = angular.element('body');
                body.css('overflow-x', 'hidden');
                this.$animate
                    .animate(details, null, { left: 'calc(100% - 2px)' }, null, {
                    duration: 0.5
                })
                    .then(function () {
                    details.addClass('hide');
                });
                this.$animate
                    .animate(overview, { width: '50%' }, { width: '100%' }, null, {
                    duration: 0.5
                })
                    .then(function () {
                    overview.removeClass('inbox');
                    _this.alarmsGrid.showColumn('Therapy.Text');
                    _this.alarmsGrid.showColumn('Recipients');
                    _this.alarmsGrid.resize();
                    _this.showDetail = false;
                    body.css('overflow-x', 'auto');
                });
            };
            InboxController.prototype.showDetails = function () {
                var _this = this;
                this.showDetail = true;
                var details = angular.element('#inbox-details');
                var overview = angular.element('#inbox-overview');
                var body = angular.element('body');
                body.css('overflow-x', 'hidden');
                this.$animate
                    .animate(details, null, { left: 'calc(50% - 2px)' }, null, {
                    duration: 0.5
                })
                    .then(function () {
                    body.css('overflow-x', 'auto');
                });
                overview.addClass('inbox');
                details.removeClass('hide');
                this.$animate
                    .animate(overview, { width: '100%' }, { width: '50%' }, null, {
                    duration: 0.5
                })
                    .then(function () {
                    _this.alarmsGrid.hideColumn('Therapy.Text');
                    _this.alarmsGrid.hideColumn('Recipients');
                    _this.alarmsGrid.resize();
                });
            };
            InboxController.prototype.search = function () {
                this.alarmsGrid.pagingOptions.currentPage = 1;
                this.alarmsGrid.search();
            };
            InboxController.prototype.executeAlarmsSearchAsync = function (page, pageSize, sortField, sortOrder, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var searchCriteria, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                searchCriteria = angular.copy(criteria);
                                searchCriteria.page = page;
                                searchCriteria.pageSize = pageSize;
                                searchCriteria.sortField = sortField;
                                searchCriteria.sortOrder = sortOrder;
                                searchCriteria.forInbox = true;
                                searchCriteria.patient = null;
                                searchCriteria.startDate = RemeCare.Shared.DateHelper.toServerDateString(criteria.startDate);
                                searchCriteria.endDate = RemeCare.Shared.DateHelper.toServerDateString(criteria.endDate);
                                searchCriteria.dateFilterType = RemeCare.Contract.Core.Codes.DateFilterTypeCode.CreationDate;
                                searchCriteria.patientId = criteria.patient != null ? criteria.patient.Id : null;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.alarmsApiSvc.findAlarmsAsync(searchCriteria)];
                            case 2: return [2 /*return*/, _a.sent()];
                            case 3:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            InboxController.prototype.buildAlarmsGrid = function () {
                var _this = this;
                this.alarmsGrid = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortOrder, criteria) {
                    return _this.executeAlarmsSearchAsync(page, pageSize, sortField, sortOrder, criteria);
                })
                    .addPrioColumn('AlarmLevel.Id', 'Views.Alarms.Priority', 'AlarmLevel.Text')
                    .addColumn('CreationDate', 'General.Date', { cellFilter: 'date: "shortDate"' })
                    .addColumn('Message', 'Views.Alarms.Alarm')
                    .addLinkColumn('Patient.Text', 'General.Patient', '#/Patients/{{row.entity.Patient.Id}}')
                    .addColumn('Therapy.Text', 'General.Therapy')
                    .addColumn('Recipients', 'Views.Alarms.Recipients', { cellFilter: 'delimitedDisplay: ", "' })
                    .addBoolColumn('Status.Id == 3', 'Views.Alarms.Handled', null, false)
                    .addActionColumn('menu-right', function (a) {
                    _this.$state.go('inbox.alarmdetail', { alarmId: a.Id });
                    if (!_this.showDetail) {
                        _this.showDetails();
                    }
                })
                    .build();
                this.alarmsGrid.searchCriteria.includeUnhandled = true;
                this.alarmsGrid.searchCriteria.therapies = [];
                this.alarmsGrid.searchCriteria.alarmLevels = [];
                this.alarmsGrid.sortOptions.direction = 'DESC';
                this.search();
            };
            return InboxController;
        }());
        Inbox.remeCareInboxModule.component('inbox', {
            controller: InboxController,
            templateUrl: 'views/inbox/inbox.html'
        });
    })(Inbox = RemeCare.Inbox || (RemeCare.Inbox = {}));
})(RemeCare || (RemeCare = {}));
