namespace RemeCare.Patient {
    import PatientContactDetail = Contract.Patient.Read.IPatientContactDetails;
    import ProfileRights = Contract.Patient.Read.IProfileRights;
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;
    import PatientInformedConsentStatusCode = Shared.Contract.Code.PatientInformedConsentStatusCode;

    export interface IPatientContactSummary {
        Name: string;
        PatientNumber: string;
        DateOfBirth: string;
        CommLanguage: string;
        Gender: string;
        City: string;
        CellPhone: Contract.Core.ITelephoneNumber;
        Phone: Contract.Core.ITelephoneNumber;
        Email: string;
        Remark: string;
        UserDetails: Shared.Contract.Read.IUserDetails;
    }

    class PatientFileController implements ng.IComponentController {
        public patientId: Shared.Contract.Guid;
        public patientLoaded: boolean;
        public patientInfoString: string;
        public patientSummary: IPatientContactSummary;
        public onlyDashboard: boolean;
        public rights: ProfileRights;
        public informedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public acceptedInformedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsentsToAccept: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public isPatient: boolean;
        public isInternal: boolean;
        public userStatus: Shared.Contract.Read.IUserStatus;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly patientSvc: PatientService,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly sidebarSvc: Shared.Framework.Service.SidebarService
        ) {}

        public async $onInit(): Promise<void> {
            this.isPatient = this.authservice.isPatient();
            this.isInternal = this.authservice.hasRight(AuthContext.patientInternal, AuthRight.Read);
            if (
                this.authservice.hasRight(AuthContext.patientMonitorRegistrations, AuthRight.Read) ||
                this.authservice.hasRight(AuthContext.patientMonitorAlarms, AuthRight.Read)
            ) {
                this.onlyDashboard = false;
            } else {
                this.onlyDashboard = true;
            }
            this.patientSummary = {} as IPatientContactSummary;

            if (this.authservice.hasRight(AuthContext.patientInformedConsents, AuthRight.Read) && !this.isPatient) {
                try {
                    const informedConsentsSearchResult = await this.informedConsentSvc.getInformedConsentSummary(
                        this.patientId
                    );
                    this.informedConsents = informedConsentsSearchResult.Items;
                    this.acceptedInformedConsents = _.filter(this.informedConsents, ic => {
                        return ic.Status === PatientInformedConsentStatusCode.Accepted;
                    });
                    this.informedConsentsToAccept = _.filter(this.informedConsents, ic => {
                        return ic.Status !== PatientInformedConsentStatusCode.Accepted;
                    });
                } catch (e) {
                    this.toaster.error(e);
                }
            }

            try {
                const ags = await this.patientSvc.getProfileRightsForPatientAsync(this.patientId);
                this.rights = ags;
                if (!this.rights.HasMedicalInfoRights && !this.rights.HasPersonalInfoRights) {
                    // no medical info rights and no personal info rights. Redirect to not found
                    this.$state.go('patients.notfound');
                    return;
                }
                const success = await this.patientSvc.getContactDetails(this.patientId);
                const args = success.data;
                this.patientLoaded = true;
                await this.generatePatientSummary(args);
                this.sidebarSvc.setLeftSidebarTitle(this.patientSummary.Name);
                this.userStatus = await this.securityApiSvc.isUserBlocked(args.PartyId);
                if (this.userStatus != null) {
                    try {
                        this.patientSummary.UserDetails = await this.securityApiSvc.getUserDetailsAsync(args.PartyId);
                    } catch (e) {
                        // This is here to avoid showing the error toaster when the call fails if the usr has no rights to request the data.
                    }
                }
                if (this.rights.HasMedicalInfoRights) {
                    if (this.$state.current.name === 'patients.patientfile') {
                        if (this.authservice.hasRight(AuthContext.patientMonitor, AuthRight.Read)) {
                            this.$state.go('patients.patientfile.monitoring', null, { location: 'replace' });
                        } else if (this.authservice.hasRight(AuthContext.patientMedication, AuthRight.Read)) {
                            this.$state.go('patients.patientfile.medication', null, { location: 'replace' });
                        } else if (this.authservice.hasRight(AuthContext.patientCarePlan, AuthRight.Read)) {
                            this.$state.go('patients.patientfile.carePlans', null, { location: 'replace' });
                        } else {
                            this.$state.go('patients.patientfile.patientInfo', { patientId: this.patientId });
                        }
                    }
                    // else do nothing (will transation to original state)
                } else if (this.rights.HasPersonalInfoRights) {
                    if (
                        this.$state.current.name === 'patients.patientfile.carePlans' &&
                        this.authservice.hasRight(AuthContext.patientCarePlan, AuthRight.Read)
                    ) {
                        // Careplans screens is always accessible if the profile has a right to the screen,
                        // not only if they have the rights for one of the patients care plans
                        return;
                    }
                    if (
                        this.$state.current.name.indexOf('patients.patientfile.patientInfo') >= 0 ||
                        this.$state.current.name.indexOf('patients.patientfile.careTeam') >= 0 ||
                        this.$state.current.name.indexOf('patients.patientfile.communication') >= 0 ||
                        this.$state.current.name.indexOf('patients.patientfile.mediaDocuments') >= 0
                    ) {
                        if (this.$state.current.name === 'patients.patientfile') {
                            this.$state.go(
                                'patients.patientfile.patientInfo',
                                { patientId: this.patientId },
                                { location: 'replace' }
                            );
                        }
                        // else do nothing (will transation to original state)
                    } else {
                        this.$state.go(
                            'patients.patientfile.patientInfo',
                            { patientId: this.patientId },
                            { location: 'replace' }
                        );
                    }
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public $onDestroy(): void {
            this.sidebarSvc.clear();
        }

        public userHasInternalRemark(): boolean {
            return this.patientSummary.Remark != null && this.patientSummary.Remark.length > 0;
        }

        public showInternalRemarkModal(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<string>('rcInternalRemarkModal')
                .setBindings({
                    remark: this.patientSummary.Remark,
                    patientId: this.patientId,
                })
                .setResultCallBack(result => {
                    this.patientSummary.Remark = result;
                    this.patientSvc
                        .saveInternalRemark(this.patientId, result)
                        .success(() => {})
                        .error(e => {
                            this.toaster.error(e.data);
                        });
                })
                .setLarge()
                .build();
        }

        public isShowUserInfoModalDisabled(): boolean {
            return !(
                this.authservice.hasRight(AuthContext.management, AuthRight.Read) &&
                this.patientSummary.UserDetails != null
            );
        }

        public showUserInfoModal(): void {
            if (
                this.authservice.hasRight(AuthContext.management, AuthRight.Read) &&
                this.patientSummary.UserDetails != null
            ) {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('patientAuthActivityModalCtrl')
                    .setTemplateUrl('views/patient/patientAuthActivityModal.html')
                    .setScope({
                        userDetails: this.patientSummary.UserDetails,
                    })
                    .build();
            }
        }

        public isShowContactModalDisabled(): boolean {
            if (this.patientSummary == null) {
                return true;
            }

            let dataCount =
                this.patientSummary.CellPhone == null
                    ? 0
                    : new RemeCare.Model.PhoneNumber(this.patientSummary.CellPhone).toString().trim().length;
            dataCount +=
                this.patientSummary.Phone == null
                    ? 0
                    : new RemeCare.Model.PhoneNumber(this.patientSummary.Phone).toString().trim().length;
            dataCount += this.patientSummary.Email == null ? 0 : this.patientSummary.Email.trim().length;

            return dataCount === 0;
        }

        public showContactModal(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('patientContactModalCtrl')
                .setTemplateUrl('views/patient/patientContactModal.html')
                .setScope({
                    name: this.patientSummary.Name,
                    cellPhone: new RemeCare.Model.PhoneNumber(this.patientSummary.CellPhone).toString(),
                    phoneNumber: new RemeCare.Model.PhoneNumber(this.patientSummary.Phone).toString(),
                    email: this.patientSummary.Email,
                })
                .build();
        }

        public showInformedConsentModal(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<void>('rcInformedConsentModal')
                .setBindings({
                    acceptedInformedConsents: this.acceptedInformedConsents,
                    informedConsentsToAccept: this.informedConsentsToAccept,
                    informedConsents: this.informedConsents,
                    patientId: this.patientId,
                    patientName: this.patientSummary.Name,
                })
                .setDismissCallBack(async () => {
                    try {
                        const informedConsentsSearchResult = await this.informedConsentSvc.getInformedConsentSummary(
                            this.patientId
                        );
                        this.informedConsents = informedConsentsSearchResult.Items;
                        this.acceptedInformedConsents = _.filter(this.informedConsents, ic => {
                            return ic.Status === PatientInformedConsentStatusCode.Accepted;
                        });
                        this.informedConsentsToAccept = _.filter(this.informedConsents, ic => {
                            return ic.Status !== PatientInformedConsentStatusCode.Accepted;
                        });
                    } catch (e) {
                        this.toaster.error(e);
                    }
                })
                .setLarge()
                .build();
        }

        private async generatePatientSummary(patientDetails: PatientContactDetail): Promise<void> {
            try {
                const ags = await this.masterdataSvc.getGendersAsync();
                const gender = _.find(ags, x => x.Id === patientDetails.GenderId);
                this.patientSummary.Gender = gender ? gender.Text : '';

                const com = await this.masterdataSvc.getCommLanguagesAsync();

                const commLang = _.find(com, x => x.Id === patientDetails.CommunicationLanguageId);
                this.patientSummary.CommLanguage = commLang ? commLang.Text : '';
            } catch (e) {
                this.toaster.error(e);
            }
            this.patientSummary.Name = (patientDetails.FirstName || '-') + ' ' + (patientDetails.LastName || '-');
            this.patientSummary.PatientNumber = patientDetails.PatientNumber;

            if (patientDetails.City) {
                this.patientSummary.City = patientDetails.City;
            }

            if (patientDetails.DateOfBirth) {
                this.patientSummary.DateOfBirth = moment(patientDetails.DateOfBirth).format('DD/MM/YYYY');
            }

            this.patientSummary.CellPhone = patientDetails.CellPhone;
            this.patientSummary.Phone = patientDetails.Phone;
            this.patientSummary.Remark = patientDetails.Remark;
            this.patientSummary.Email = patientDetails.Email;
        }
    }

    remeCarePatientModule.component('rcPatientFile', {
        controller: PatientFileController,
        templateUrl: 'views/patient/patientFile.html',
        bindings: {
            patientId: '<rcPatientId',
        },
    });
}
