namespace RemeCare.Patient {
    interface IPatientAuthActivityModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        userDetails: Shared.Contract.Read.IUserDetails;

        confirm(): void;
    }

    class PatientAuthActivityModalController extends Shared.Framework.ControllerBase<
        IPatientAuthActivityModalController
    > {
        constructor(
            $scope: IPatientAuthActivityModalController,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {}
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'patientAuthActivityModalCtrl',
            (
                $scope: IPatientAuthActivityModalController,
                $translate,
                toaster,
                $stateParams,
                $state: ng.ui.IStateService
            ) => new PatientAuthActivityModalController($scope, $translate, toaster, $stateParams, $state)
        );
}
