/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    interface IExternalReferencesScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.StringDefinition;
        externalReferencesGrid: Shared.Framework.Grid.Grid<RemeCare.Contract.Core.IExternalReference>;
        addExternalReference(): void;
    }

    class ExternalReferencesController extends CareRequestPartControllerBase<IExternalReferencesScope> {
        constructor(
            $scope: IExternalReferencesScope,
            $translate: ng.translate.ITranslateService,
            toaster: Shared.Framework.Toaster,
            careRequestSvc: CareRequestSvc,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
            $scope.addExternalReference = () => this.addExternalReference();
        }

        public $onInit(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<RemeCare.Contract.Core.IExternalReference>()
                .addColumn('System', 'General.System')
                .addColumn('Attribute', 'General.Attribute')
                .addColumn('Value', 'General.Value');

            if (!this.$scope.readOnly) {
                gridBuilder = gridBuilder
                    .addEditButtonWithPromiseFunctionColumn((c) => this.editExternalReference(c))
                    .addDeleteButtonColumn();
            }
            this.$scope.externalReferencesGrid = gridBuilder.build();
            this.$scope.externalReferencesGrid.setData(this.$scope.careRequest.patient.externalReferences);
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(
                this.$scope.careRequestPart,
                this.$scope.careRequestTemplate,
                this.$scope.careRequest
            );
        }

        private async addExternalReference(): Promise<void> {
            const reference: RemeCare.Contract.Core.IExternalReference = {
                Attribute: '',
                System: '',
                Value: '',
            };
            const result = await this.editExternalReference(reference);
            this.$scope.externalReferencesGrid.addRow(result);
        }

        private editExternalReference(
            externalReference: RemeCare.Contract.Core.IExternalReference
        ): Promise<RemeCare.Contract.Core.IExternalReference> {
            return this.modalBuilderFactory
                .createComponentModalBuilder<RemeCare.Contract.Core.IExternalReference>('rcExternalReferenceModal')
                .setBindings({
                    externalReference: angular.copy(externalReference),
                })
                .build().result;
        }
    }

    class ExternalReferencesDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'careRequestSvc',
            'gridBuilderSvc',
            'modalBuilderFactory',
            ($scope, $translate, toaster, careRequestSvc, gridBuilderSvc, modalBuilderFactory) =>
                new ExternalReferencesController(
                    $scope,
                    $translate,
                    toaster,
                    careRequestSvc,
                    gridBuilderSvc,
                    modalBuilderFactory
                ),
        ];

        public templateUrl = 'views/careRequest/directive/externalReferences.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestExternalReferences', () => new ExternalReferencesDirective());
}
