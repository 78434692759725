namespace RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;

    export class PatientFileForExternalSvc extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public getPatientFileForExternalAsync(): Promise<Contract.CareRequest.Read.IPatientFileForExternalRequest[]> {
            return this.getPromise(`PatientFileForExternalRequest/Requests`, null);
        }

        public getLastPatientFileForExternalAsync(): Promise<
            Contract.CareRequest.Read.IPatientFileForExternalRequest[]
        > {
            return this.getPromise(`PatientFileForExternalRequest/Requests/LastRequest`, null);
        }
    }

    remeCareCareRequestModule.service('patientFileForExternalSvc', PatientFileForExternalSvc);
}
