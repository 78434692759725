module RemeCare.Custom {

    import Guid = Shared.Contract.Guid;

    export class CardioCoachConstants {
        public static uptitrationProposal = '3ee83cba-4313-42e2-97b2-ad5f2a8903b1';
        public static currentDailyDose = 'd77ea984-53e1-43c9-9229-0a9599780c7d';
        public static nextLevelDose = '22dbad33-30bc-4618-87d5-073383c389d0';

        public static uptitrationToNextLevel = '9374a32a-7251-4a9c-84b8-f5e113ce3e41';
        public static noUptirationNoMedication = '65ebde11-7720-41be-9c1e-50ec6c3c5047';
        public static callPatient = 'df3553a7-46f3-4183-87e8-fe3e144c88fc';

        public static uptitrationProposalAnamnesis = 'ed2d4cd8-4874-4f54-976e-d43b3924a9c0';
        public static currentDailyDoseAnamnesis = 'e44eb0e1-db50-402e-8b54-76af8b53893c';
        public static nextLevelDoseAnamnesis = '1c240b6d-fc48-433d-a65c-4d6127adcfd1';
    }
}