namespace RemeCare.IntegrationMonitor {
    import IIntegrationMessageSummary = RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageSummary;
    import IIntegrationMessageFilter = RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageFilter;
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;
    export const FAILED_STATUS = 'FAILED';
    class IntegrationMessagesSearchController implements ng.IComponentController {
        public bindToUrl: boolean;
        public IntegrationMessagesGrid: Shared.Framework.Grid.Grid<IIntegrationMessageSummary>;
        public searchCollapsed: boolean;
        public possibleSenders: Contract.IntegrationMonitor.Read.IIntegrationMessageSender[];
        public possibleErrorCodes: Contract.IntegrationMonitor.Read.IIntegrationMessageErrorCode[];
        public possibleStatuses: Contract.IntegrationMonitor.Read.IIntegrationMessageStatus[];
        private isBuildSearch: boolean;
        constructor(
            private readonly integrationMessageSearchSvc: IntegrationMonitor.IntegrationMessageSearchService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly storageSvc: Shared.Framework.Service.StorageService
        ) { }

        public async $onInit(): Promise<void> {
            await this.loadDataAsync();
            this.buildGrid();
            this.isBuildSearch = true;
            this.IntegrationMessagesGrid.search();
            this.isBuildSearch = false;
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<IIntegrationMessageSummary>(
                    (page, pageSize, sortField, sortDirection, criteria, count, nextPage) => {
                        criteria.Page = (this.isBuildSearch && criteria.Page) || page; // case that page larger than number of pages is handled in backend (latest page is then shown)
                        criteria.PageSize = pageSize;

                        return this.executeSearchAsync(criteria);
                    }
                )
                .addColumn('PatientNationalNumber', 'General.NationalNumber', { enableSorting: false, width: 175 })
                .addColumn('Sender', 'Views.IntegrationMonitor.Sender', { enableSorting: false })
                .addDateColumn('ReceivedDateTime', 'Views.IntegrationMonitor.ReceivedOn', 'short', {
                    enableSorting: false,
                    width: 200,
                })
                .addDateColumn('PlannedDateTimeFrom', 'Views.IntegrationMonitor.PlannedDateAndTime', 'short', {
                    enableSorting: false,
                    width: 200,
                })
                .addColumn('ErrorCode', 'Views.IntegrationMonitor.IntegrationMessage.ErrorCode', {
                    enableSorting: false,
                })
                .addColumn('Status', 'General.Status', { enableSorting: false, width: 150 })
                .addNavigationColumn('folder-open', 'integrationMonitor.message', { messageId: 'Id' });

            const knownInformation = this.storageSvc.get<IIntegrationMessageFilter>(
                StorageServiceConstants.integrationMonitorKey
            );

            this.IntegrationMessagesGrid = gridBuilder
                .setSearchCriteria((c: IIntegrationMessageFilter) => {
                    c.Page = c.Page || (knownInformation && knownInformation.Page);
                    c.PageSize = c.PageSize || (knownInformation && knownInformation.PageSize);
                    c.NationalNumber = c.NationalNumber || (knownInformation && knownInformation.NationalNumber);
                    c.Sender = c.Sender || (knownInformation && knownInformation.Sender);
                    c.ErrorCode = c.ErrorCode || (knownInformation && knownInformation.ErrorCode);
                    c.Status = c.Status || (knownInformation && knownInformation.Status);
                    c.ReceivedDateTimeFrom =
                        c.ReceivedDateTimeFrom || (knownInformation && knownInformation.ReceivedDateTimeFrom);
                    c.ReceivedDateTimeUntil =
                        c.ReceivedDateTimeUntil || (knownInformation && knownInformation.ReceivedDateTimeUntil);
                    c.PlannedDateTimeFrom =
                        c.PlannedDateTimeFrom || (knownInformation && knownInformation.PlannedDateTimeFrom);
                    c.PlannedDateTimeUntil =
                        c.PlannedDateTimeUntil || (knownInformation && knownInformation.PlannedDateTimeUntil);
                    this.checkFilterCriteria(c);
                })
                .setBindToUrl(this.bindToUrl)
                .build();
            this.IntegrationMessagesGrid.pagingOptions.currentPage =
                (knownInformation && knownInformation.Page) || this.IntegrationMessagesGrid.pagingOptions.currentPage;
            this.IntegrationMessagesGrid.pagingOptions.pageSize = 20;
        }

        private checkFilterCriteria(criteria: IIntegrationMessageFilter): void {
            // Check if Status, Sender and ErrorCode are still valid
            if (criteria.Status && this.possibleStatuses.map((x) => x.Name).indexOf(criteria.Status) === -1) {
                criteria.Status = null;
            }

            if (criteria.Sender && this.possibleSenders.map((x) => x.Name).indexOf(criteria.Sender) === -1) {
                criteria.Sender = null;
            }

            if (criteria.ErrorCode && this.possibleErrorCodes.map((x) => x.Name).indexOf(criteria.ErrorCode) === -1) {
                criteria.ErrorCode = null;
            }

            // Set default values
            if (!criteria.Sender) {
                criteria.Sender = this.possibleSenders[0].Name;
            }

            if (!criteria.Status) {
                criteria.Status = this.possibleStatuses.filter((s) => s.Name === FAILED_STATUS)[0].Name;
            }
        }

        private async executeSearchAsync(
            criteria: IIntegrationMessageFilter
        ): Promise<Shared.Contract.ISearchResult<IIntegrationMessageSummary>> {
            const query: Contract.IntegrationMonitor.Read.Query.IFindIntegrationMessagesQuery = {
                page: criteria.Page,
                pageSize: criteria.PageSize,
                NationalNumber: criteria.NationalNumber,
                Sender: criteria.Sender,
                ErrorCode: criteria.ErrorCode,
                Status: criteria.Status,
                ReceivedDateTimeFrom: Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeFrom),
                ReceivedDateTimeUntil: Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeUntil),
                PlannedDateTimeFrom: Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeFrom),
                PlannedDateTimeUntil: Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeUntil),
            };

            try {
                const searchResult = await this.integrationMessageSearchSvc.findIntegrationMessagesAsync(query);
                this.searchCollapsed = true;
                this.saveFiltersAndMessageIdsToStorage(
                    criteria,
                    searchResult.Items.map((x) => x.Id),
                    searchResult.HasNextPage
                );
                return searchResult;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                const [senders, errorCodes, statuses]: [
                    Contract.IntegrationMonitor.Read.IIntegrationMessageSender[],
                    Contract.IntegrationMonitor.Read.IIntegrationMessageErrorCode[],
                    Contract.IntegrationMonitor.Read.IIntegrationMessageStatus[]
                ] = await Promise.all([
                    this.integrationMessageSearchSvc.getSendersAsync(),
                    this.integrationMessageSearchSvc.getErrorCodesAsync(),
                    this.integrationMessageSearchSvc.getStatusesAsync(),
                ]);

                this.possibleSenders = _(senders).sortBy((s) => s.Name);
                this.possibleErrorCodes = _(errorCodes).sortBy((ec) => ec.Translation);
                this.possibleStatuses = statuses;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private saveFiltersAndMessageIdsToStorage(
            criteria: RemeCare.Contract.IntegrationMonitor.Read.IIntegrationMessageFilter,
            messageIds: string[],
            hasNextPage: boolean
        ): void {
            const page = criteria.Page || null;
            const pageSize = criteria.PageSize || null;
            const nationalNumberNew = criteria.NationalNumber || null;
            const senderNew = criteria.Sender || null;
            const errorCodeNew = criteria.ErrorCode || null;
            const statusNew = criteria.Status || null;
            const receivedDateTimeFromNew = criteria.ReceivedDateTimeFrom || null;
            const receivedDateTimeUntilNew = criteria.ReceivedDateTimeUntil || null;
            const plannedDateTimeFromNew = criteria.PlannedDateTimeFrom || null;
            const plannedDateTimeUntilNew = criteria.PlannedDateTimeUntil || null;

            const knownFilters: IIntegrationMessageFilter = {
                Page: page,
                PageSize: pageSize,
                NationalNumber: nationalNumberNew,
                Sender: senderNew,
                ErrorCode: errorCodeNew,
                Status: statusNew,
                ReceivedDateTimeFrom: receivedDateTimeFromNew,
                ReceivedDateTimeUntil: receivedDateTimeUntilNew,
                PlannedDateTimeFrom: plannedDateTimeFromNew,
                PlannedDateTimeUntil: plannedDateTimeUntilNew,
                MessageIds: messageIds, // quick and dirty way to pass message ids to details page (PF-1066)
                HasNextPage: hasNextPage,
            };

            this.storageSvc.store(StorageServiceConstants.integrationMonitorKey, knownFilters);
        }
    }

    remeCareIntegrationMonitorModule.component('rcIntegrationMessagesSearch', {
        bindings: {
            bindToUrl: '@?',
        },
        controller: IntegrationMessagesSearchController,
        templateUrl: 'views/integrationMonitor/integrationMessagesSearch.html',
    });
}
