var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestRoleController = /** @class */ (function (_super) {
            __extends(CareRequestRoleController, _super);
            function CareRequestRoleController($scope, $translate, toaster, modalBuilderFactory, healthCarePartySvc, healthCareProfessionalSearchSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.healthCarePartySvc = healthCarePartySvc;
                _this.healthCareProfessionalSearchSvc = healthCareProfessionalSearchSvc;
                $scope.isPresent = function (t) { return _this.isPresent(t); };
                $scope.isRequired = function (t) { return _this.isRequired(t); };
                $scope.atLeastOneNumber = function () { return _this.atLeastOneNumber(); };
                $scope.confirm = function () { return _this.confirm(); };
                $scope.clearprofessional = function () { return _this.clearprofessional(); };
                $scope.searchProfessional = function () { return _this.searchProfessional(); };
                return _this;
            }
            CareRequestRoleController.prototype.$onInit = function () {
                var _this = this;
                if (this.$scope.careRequestRole.partyRoleId != null) {
                    this.healthCareProfessionalSearchSvc.searchProfessionals({
                        partyRoleId: this.$scope.careRequestRole.partyRoleId
                    })
                        .success(function (r) { return _this.setMatchedParty(r.Items[0]); })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            CareRequestRoleController.prototype.isPresent = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id !== RemeCare.Shared.Contract.Code.ModalityType.Not;
            };
            CareRequestRoleController.prototype.isRequired = function (controlType) {
                var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                if (control == null)
                    return false;
                else
                    return control.modalityType.Id === RemeCare.Shared.Contract.Code.ModalityType.Mandatory;
            };
            CareRequestRoleController.prototype.confirm = function () {
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.careRequestRole);
            };
            CareRequestRoleController.prototype.atLeastOneNumber = function () {
                if (!this.$scope.isRequired(RemeCare.Shared.Contract.Code.CareRequestUIControlType.CareRequestRoleTelephoneMobile))
                    return true;
                return (this.$scope.careRequestRole.requestParty.contactInfo.telephone.isFilled() ||
                    this.$scope.careRequestRole.requestParty.contactInfo.mobile.isFilled());
            };
            CareRequestRoleController.prototype.clearprofessional = function () {
                this.$scope.careRequestRole.partyRoleId = null;
                this.$scope.careRequestRole.matchedParty = null;
            };
            CareRequestRoleController.prototype.searchProfessional = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('searchHealthCareProfessionalPartyCtrl')
                    .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                    .setScope({
                    title: this.$scope.configuration.role.name,
                    typeAndSpecialties: this.$scope.typeAndSpecialties,
                    prefill: {
                        FirstName: this.$scope.careRequestRole.requestParty.firstName,
                        LastName: this.$scope.careRequestRole.requestParty.lastName,
                        Email: this.$scope.careRequestRole.requestParty.contactInfo.email,
                        IdentificationNumber: this.$scope.careRequestRole.requestParty.identificationNumber,
                        ZipCode: this.$scope.careRequestRole.requestParty.contactInfo.address && this.$scope.careRequestRole.requestParty.contactInfo.address.zipCode,
                        City: this.$scope.careRequestRole.requestParty.contactInfo.address && this.$scope.careRequestRole.requestParty.contactInfo.address.city,
                    }
                })
                    .setResultCallBack(function (r) { return _this.setMatchedParty(r); })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            CareRequestRoleController.prototype.setMatchedParty = function (healthCareProfessional) {
                this.$scope.careRequestRole.matchedParty = CareRequest.RequestPerson.createFromHealthCareProfessional(healthCareProfessional);
                this.$scope.careRequestRole.partyRoleId = healthCareProfessional.PartyRoleId;
            };
            CareRequestRoleController.$inject = ['$scope', '$translate', 'toaster', 'modalBuilderFactory', 'healthCarePartySvc', 'healthCareProfessionalSearchSvc'];
            return CareRequestRoleController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule.controller('careRequestRoleCtrl', CareRequestRoleController);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
