var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var GlobalActorRole = /** @class */ (function () {
            function GlobalActorRole(serverObject) {
                var _this = this;
                this.translations = {};
                this.parties = [];
                if (serverObject) {
                    this.id = serverObject.Id;
                    this.name = serverObject.Name;
                    this.isActive = serverObject.IsActive;
                    this.internalRemark = serverObject.InternalRemark;
                    _(serverObject.Translations).each(function (t) {
                        _this.translations[t.Culture] = t;
                    });
                }
            }
            GlobalActorRole.prototype.toServerWrite = function () {
                return {
                    translations: this.getTranslations()
                };
            };
            GlobalActorRole.prototype.getTranslations = function () {
                var translations = [];
                for (var key in this.translations) {
                    if (this.translations.hasOwnProperty(key)) {
                        var trans = this.translations[key];
                        translations.push({
                            culture: key,
                            name: trans.Name
                        });
                    }
                }
                return translations;
            };
            return GlobalActorRole;
        }());
        Management.GlobalActorRole = GlobalActorRole;
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
