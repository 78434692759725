namespace RemeCare.Patient {
    interface ISearchMedicationCriteria {
        medicationName?: string;
        activeSubstance?: string;
    }

    interface ISearchMedicationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        searchMedicationGrid: Shared.Framework.Grid.Grid<Shared.Contract.Read.IMedication>;
        isBusy: boolean;
        criteriaCollapsed: boolean;
        totalItems: number;
        activeSubstance: string;
        structuralTherapyActionId?: Shared.Contract.Guid;
        prescriptionId?: Shared.Contract.Guid;
        clearSearchParams: () => void;
        toggleCriteria: () => void;
        search: () => void;
    }

    class SearchMedicationCtrl extends Shared.Framework.ControllerBase<ISearchMedicationScope> {
        constructor(
            $scope: ISearchMedicationScope,
            protected $translate: ng.translate.ITranslateService,
            toaster: Shared.Framework.Toaster,
            private productClassIds: Shared.Contract.Guid[],
            private productIds: Shared.Contract.Guid[],
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private uiGridConstants,
            // private title: string,
            private medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) {
            super($scope, $translate, toaster);
            this.$scope.clearSearchParams = () => this.clearSearchParams();
            this.$scope.toggleCriteria = () => this.toggleCriteria();
            this.$scope.search = () => this.search();
        }

        public $onInit(): void {
            this.buildGrid();

            this.$scope.searchMedicationGrid.sortOptions = {
                field: 'MedicationName',
                direction: this.uiGridConstants.ASC,
            };
            this.$scope.searchMedicationGrid.searchCriteria = {};
            this.$scope.criteriaCollapsed = false;

            if (this.$scope.activeSubstance) {
                this.search();
            }
        }

        public clearSearchParams(): void {
            this.$scope.searchMedicationGrid.searchCriteria = {};
        }

        public toggleCriteria(): void {
            this.$scope.criteriaCollapsed = !this.$scope.criteriaCollapsed;
        }

        public search(): void {
            this.$scope.searchMedicationGrid.pagingOptions.currentPage = 1;
            this.$scope.searchMedicationGrid.search();
        }

        private async executeSearch(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: ISearchMedicationCriteria
        ): Promise<Shared.Contract.ISearchResult<Shared.Contract.Read.IMedication>> {
            this.$scope.isBusy = true;
            const query: Shared.Contract.Read.Query.ISearchMedicationQuery = {
                page: page,
                pageSize: pageSize,
                medicationName: criteria.medicationName,
                activeSubstance: this.$scope.activeSubstance ? this.$scope.activeSubstance : criteria.activeSubstance,
                productClassIds: this.productClassIds,
                productIds: this.productIds,
                structuralTherapyActionId: this.$scope.structuralTherapyActionId,
                prescriptionId: this.$scope.prescriptionId,
                isActive: true,
            };

            try {
                const data = await this.medicationSearchSvc.searchMedicationsAsync(query);
                this.$scope.totalItems = data.Total;
                this.$scope.isBusy = false;
                this.$scope.criteriaCollapsed = true;
                return data;
            } catch (error) {
                this.errorCallback(error);
            }
        }

        private buildGrid(): void {
            this.$scope.searchMedicationGrid = this.gridBuilderSvc
                .createGridBuilder<Shared.Contract.Read.IMedication>(
                    (
                        page: number,
                        pageSize: number,
                        sortField: string,
                        sortDirection: string,
                        criteria: ISearchMedicationCriteria
                    ) => this.executeSearch(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('MedicationName', 'General.MedicationName', { enableSorting: false })
                .addColumn('ActiveSubstance', 'General.ActiveSubstance', { enableSorting: false })
                .addColumn('GalenicForm', 'General.GalenicForm', { enableSorting: false })
                .addColumn('Manufacturer', 'General.Manufacturer', { enableSorting: false })
                .addColumn('MedicationTypes', 'General.MedicationType', {
                    enableSorting: false,
                    cellFilter: 'delimitedDisplay : ", "',
                })
                .addSelectButtonColumn((selected) => this.$scope.$close(selected))
                .build();
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'searchMedicationCtrl',
            (
                $scope: ISearchMedicationScope,
                $translate,
                toaster: Shared.Framework.Toaster,
                productClassIds: Shared.Contract.Guid[],
                productIds: Shared.Contract.Guid[],
                gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
                uiGridConstants,
                medicationSearchSvc: Shared.Service.MedicationSearchSvc
            ) =>
                new SearchMedicationCtrl(
                    $scope,
                    $translate,
                    toaster,
                    productClassIds,
                    productIds,
                    gridBuilderSvc,
                    uiGridConstants,
                    medicationSearchSvc
                )
        );
}
