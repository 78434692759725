module RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;

    interface ICarePlanStartDateModalController extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlan: CarePlan;

        form: ng.IFormController;

        minDate: Date;
        newStartDate: Date;

        confirm: () => void;
    }


    class CarePlanStartDateModalController extends Shared.Framework.ControllerBase<ICarePlanStartDateModalController> {
        constructor(
            $scope: ICarePlanStartDateModalController,
            $translate,
            toaster,
            private $stateParams,
            private $state: ng.ui.IStateService,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private $dialog
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit() {
            this.$scope.minDate = Shared.DateHelper.today();
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.carePlanApiSvc.changeCarePlanStartDate(this.$scope.carePlan.id, Shared.DateHelper.toServerDateString(this.$scope.newStartDate))
                .success(() => {
                    this.$scope.$close();
                    this.$state.reload();
                })
                .error(m => this.errorCallback(m));
        }
    }

    angular.module('RemeCare.Patient').controller('carePlanStartDateModalCtrl', (
        $scope: ICarePlanStartDateModalController,
        $translate,
        toaster,
        $stateParams,
        $state: ng.ui.IStateService,
        carePlanApiSvc: Core.Services.CarePlanApiService,
        $dialog
    ) => new CarePlanStartDateModalController(
        $scope,
        $translate,
        toaster,
        $stateParams,
        $state,
        carePlanApiSvc,
        $dialog
    ));

}