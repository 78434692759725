module RemeCare.CareRequest {
    import TypeAndSpecialty = Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;

    interface ICareRequestRoleScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        careRequestRole: CareRequestRole;
        configuration: Shared.Framework.Model.CareRequestRoleConfiguration;
        typeAndSpecialties: Array<TypeAndSpecialty>;
        form: ng.IFormController;
        careRequestPart: Shared.Framework.Model.CareRequestRoleDefinition;
        inAcceptance: boolean;
        isPresent(t: Shared.Contract.Code.CareRequestUIControlType): boolean;
        isRequired(t: Shared.Contract.Code.CareRequestUIControlType): boolean;
        atLeastOneNumber(): boolean;
        confirm(): void;
        clearprofessional(): void;
        searchProfessional(): void;
    }

    class CareRequestRoleController extends Shared.Framework.ControllerBase<ICareRequestRoleScope> {
        static $inject = ['$scope', '$translate', 'toaster', 'modalBuilderFactory', 'healthCarePartySvc', 'healthCareProfessionalSearchSvc'];
        constructor(
            $scope: ICareRequestRoleScope,
            $translate,
            toaster,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private healthCarePartySvc: Shared.Framework.HealthCarePartyService,
            private healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc) {
            super($scope, $translate, toaster);

            $scope.isPresent = t => this.isPresent(t);
            $scope.isRequired = t => this.isRequired(t);
            $scope.atLeastOneNumber = () => this.atLeastOneNumber();
            $scope.confirm = () => this.confirm();
            $scope.clearprofessional = () => this.clearprofessional();
            $scope.searchProfessional = () => this.searchProfessional();
        }

        public $onInit(): void {
            if (this.$scope.careRequestRole.partyRoleId != null) {
                this.healthCareProfessionalSearchSvc.searchProfessionals({
                    partyRoleId: this.$scope.careRequestRole.partyRoleId
                })
                    .success(r => this.setMatchedParty(r.Items[0]))
                    .error(e => this.errorCallback(e));
            }
        }

        private isPresent(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id !== Shared.Contract.Code.ModalityType.Not;
        }

        private isRequired(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id === Shared.Contract.Code.ModalityType.Mandatory;
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.$close(this.$scope.careRequestRole);
        }

        private atLeastOneNumber(): boolean {
            if (!this.$scope.isRequired(Shared.Contract.Code.CareRequestUIControlType.CareRequestRoleTelephoneMobile)) return true;
            return (this.$scope.careRequestRole.requestParty.contactInfo.telephone.isFilled() ||
                this.$scope.careRequestRole.requestParty.contactInfo.mobile.isFilled());
        }

        private clearprofessional(): void {
            this.$scope.careRequestRole.partyRoleId = null;
            this.$scope.careRequestRole.matchedParty = null;
        }

        private searchProfessional(): void {
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>()
                .setController('searchHealthCareProfessionalPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                .setScope({
                    title: this.$scope.configuration.role.name,
                    typeAndSpecialties: this.$scope.typeAndSpecialties,
                    prefill: {
                        FirstName: (<RequestPerson>this.$scope.careRequestRole.requestParty).firstName,
                        LastName: (<RequestPerson>this.$scope.careRequestRole.requestParty).lastName,
                        Email: this.$scope.careRequestRole.requestParty.contactInfo.email,
                        IdentificationNumber: (<RequestPerson>this.$scope.careRequestRole.requestParty).identificationNumber,
                        ZipCode: this.$scope.careRequestRole.requestParty.contactInfo.address && this.$scope.careRequestRole.requestParty.contactInfo.address.zipCode,
                        City: this.$scope.careRequestRole.requestParty.contactInfo.address && this.$scope.careRequestRole.requestParty.contactInfo.address.city,
                    }
                })
                .setResultCallBack(r => this.setMatchedParty(r))
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private setMatchedParty(healthCareProfessional: Contract.HealthCareParty.Read.IHealthCareProfessionalParty): void {
            this.$scope.careRequestRole.matchedParty = RequestPerson.createFromHealthCareProfessional(healthCareProfessional);
            this.$scope.careRequestRole.partyRoleId = healthCareProfessional.PartyRoleId;
        }
    }

    remeCareCareRequestModule.controller('careRequestRoleCtrl', CareRequestRoleController);
}