var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var GenderCode;
                (function (GenderCode) {
                    GenderCode[GenderCode["Male"] = 1] = "Male";
                    GenderCode[GenderCode["Female"] = 2] = "Female";
                    GenderCode[GenderCode["Unknown"] = 3] = "Unknown";
                })(GenderCode = Codes.GenderCode || (Codes.GenderCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
