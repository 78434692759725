/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CumulativeObjectiveGraphController = /** @class */ (function (_super) {
            __extends(CumulativeObjectiveGraphController, _super);
            // @ngInject
            function CumulativeObjectiveGraphController($rootScope, $locale, dateHelper, spinnerSvc, toaster, $q, carePlanApiSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.$q = $q;
                _this.carePlanApiSvc = carePlanApiSvc;
                return _this;
            }
            CumulativeObjectiveGraphController.prototype.init = function () {
                this.referenceParameterObjective = _.find(this.monitoringPart.monitoringPartSources, function (mps) { return mps.type === RemeCare.Shared.Contract.Code.MonitoringPartSourceType.ReferenceParameterObjective; });
                this.quantitativeMeasuringPointParameter = _.find(this.monitoringPart.monitoringPartSources, function (mps) { return mps.type === RemeCare.Shared.Contract.Code.MonitoringPartSourceType.QuantitativeMeasuringPointParameter; });
                return this.$q.resolve();
            };
            CumulativeObjectiveGraphController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var objectiveScoreSearchResult, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getObjectiveScoreAsync(this.carePlanIds[0], this.referenceParameterObjective.objective.Id, this.dateInfo.fromDate, this.dateInfo.untilDate)];
                            case 1:
                                objectiveScoreSearchResult = _a.sent();
                                this.configureChart(objectiveScoreSearchResult.Items);
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                this.toaster.error(error_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CumulativeObjectiveGraphController.prototype.getMinYScaleValue = function (graph) {
                return 0;
            };
            CumulativeObjectiveGraphController.prototype.containsData = function (config) {
                return !_(config.series).all(function (s) { return _(s.data).all(function (d) { return d[1] === 0; }) || s.isReference; });
            };
            CumulativeObjectiveGraphController.prototype.createGraph = function (objectiveScoreDetails) {
                var _this = this;
                var result = new Patient.Model.NumberGraph();
                result.subject = this.referenceParameterObjective.objective;
                result.scale = this.quantitativeMeasuringPointParameter.unit;
                result.graphPoints = _(objectiveScoreDetails)
                    .chain()
                    .map(function (osd) { return osd.DayValues; })
                    .flatten()
                    .map(function (dv) { return new Patient.Model.GraphPoint(_this.dateHelper.serverDateStringToDate(dv.Date), dv.ValueToDate); })
                    .sortBy(function (gp) { return gp.x; })
                    .value();
                return result;
            };
            CumulativeObjectiveGraphController.prototype.configureChart = function (objectiveScoreDetails) {
                var graph = this.createGraph(objectiveScoreDetails);
                var chartConfig = {
                    options: {
                        chart: {
                            animation: false,
                            type: 'column',
                            height: 200,
                            zoomType: 'xy',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        yAxis: {
                            startOnTick: false,
                            endOnTick: false,
                            title: {
                                align: 'high',
                                offset: 0,
                                rotation: 0,
                                text: graph.scale ? graph.scale.Text : null,
                                y: -20,
                                x: -27,
                                textAlign: 'left',
                            },
                            max: this.getMaxY(graph, objectiveScoreDetails),
                            min: this.getMinYScaleValue(graph),
                            labels: {
                                align: 'right',
                                x: 0,
                                y: -2,
                            },
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(false),
                            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} {point.series.yAxis.options.title.text}</b><br/>',
                        },
                        title: {
                            text: null,
                        },
                        exporting: {
                            enabled: false,
                        },
                        credits: {
                            enabled: false,
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: this.mapDataPoints(graph, objectiveScoreDetails),
                };
                this.chartConfigs = [chartConfig];
            };
            CumulativeObjectiveGraphController.prototype.getMaxY = function (graph, objectiveScoreDetails) {
                var graphMax = this.getMaxYScaleValue(graph);
                var objectiveMax = _(objectiveScoreDetails).max(function (osd) { return osd.ObjectiveValue; }).ObjectiveValue;
                objectiveMax = this.getLimitedMaxYValue(this.monitoringPart, objectiveMax);
                return Math.max(graphMax, objectiveMax);
            };
            CumulativeObjectiveGraphController.prototype.mapDataPoints = function (graph, objectiveScore) {
                var _this = this;
                var columns = {
                    animation: false,
                    type: 'column',
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataPoint(gp); })
                        .sortBy(function (gp) { return gp[0]; })
                        .value(),
                    marker: {
                        enabled: false,
                    },
                    name: this.quantitativeMeasuringPointParameter.characteristic.Text,
                    color: this.referenceParameterObjective.sourceParameterRepresentation.colour,
                    maxPointWidth: 20,
                    pointPlacement: 'on',
                };
                var line = {
                    animation: false,
                    type: 'line',
                    data: this.getLinePoints(objectiveScore),
                    step: true,
                    isReference: true,
                    color: this.quantitativeMeasuringPointParameter.sourceParameterRepresentation.colour,
                    lineWidth: this.quantitativeMeasuringPointParameter.sourceParameterRepresentation.lineType
                        .Id === RemeCare.Shared.Contract.Code.LineType.Thin
                        ? 1
                        : 2,
                    dashStyle: this.quantitativeMeasuringPointParameter.sourceParameterRepresentation.lineType
                        .Id === RemeCare.Shared.Contract.Code.LineType.Dashed
                        ? 'Dash'
                        : 'Solid',
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 1,
                    },
                    name: this.referenceParameterObjective.objective.Text,
                    tooltip: {
                        xDateFormat: this.getDateFormat(false),
                    },
                };
                return [columns, line];
            };
            CumulativeObjectiveGraphController.prototype.getDataPoint = function (graphPoint) {
                return [graphPoint.x.valueOf(), graphPoint.y];
            };
            CumulativeObjectiveGraphController.prototype.getLinePoints = function (objectiveScoreDetails) {
                var _this = this;
                objectiveScoreDetails = _(objectiveScoreDetails).sortBy(function (osd) {
                    return _this.dateHelper.serverDateStringToDate(osd.FromDate).valueOf();
                });
                var points = _(objectiveScoreDetails).map(function (osd) {
                    return {
                        x: _this.dateHelper.serverDateStringToDate(osd.FromDate).valueOf(),
                        y: osd.ObjectiveValue,
                    };
                });
                var last = _(objectiveScoreDetails).last();
                if (last != null) {
                    points.push({
                        x: this.dateHelper.serverDateStringToDate(last.UntilDate).valueOf(),
                        y: last.ObjectiveValue,
                    });
                }
                return points;
            };
            return CumulativeObjectiveGraphController;
        }(Patient.ChartMonitoringPartController));
        var CumulativeObjectiveGraphComponent = /** @class */ (function (_super) {
            __extends(CumulativeObjectiveGraphComponent, _super);
            function CumulativeObjectiveGraphComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = CumulativeObjectiveGraphController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return CumulativeObjectiveGraphComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringCumulativeObjectiveGraph', new CumulativeObjectiveGraphComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
