var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Component;
        (function (Component) {
            var UserAccountController = /** @class */ (function () {
                function UserAccountController() {
                }
                UserAccountController.prototype.$onInit = function () { };
                return UserAccountController;
            }());
            RemeCare.remeCareAppModule.component('rcUserAccountInfo', {
                templateUrl: 'views/shared/userAccountInfo.html',
                controller: UserAccountController,
                controllerAs: 'userAccountCtrl',
                bindings: {
                    userAccount: '<',
                    partyId: '<'
                }
            });
        })(Component = Shared.Component || (Shared.Component = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
