var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        var RemeCareSelfServiceConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareSelfServiceConfiguration, _super);
            function RemeCareSelfServiceConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareSelfServiceConfiguration.prototype.config = function () {
                this.addComponentRoute('selfservice', '/SelfService', 'rcSelfService', ['Views_SelfService']);
                this.add('selfservice.activate', {
                    url: '/Activate?userId&key&activating',
                    views: {
                        '@^.^': {
                            component: 'rcActivateLogin'
                        }
                    },
                    reloadOnSearch: false
                }, ['Views_SelfService']);
                this.add('selfservice.resetPassword', {
                    url: '/ResetPassword?userId&key&activating',
                    views: {
                        '@^.^': {
                            component: 'rcResetPassword'
                        }
                    },
                    reloadOnSearch: false
                }, ['Views_SelfService']);
                this.add('selfservice.requestNewPassword', {
                    url: '/RequestNewPassword',
                    views: {
                        '@^.^': {
                            component: 'rcRequestNewPassword'
                        }
                    }
                }, ['Views_SelfService']);
                this.add('selfservice.requestUserAccount', {
                    url: '/RequestUserAccount',
                    views: {
                        '@^.^': {
                            component: 'rcRequestUserAccount'
                        }
                    }
                }, ['Views_SelfService']);
                this.add('selfservice.requestAccountInfo', {
                    url: '/ForgotUserName',
                    views: {
                        '@^.^': {
                            component: 'rcRequestAccountInfo'
                        }
                    }
                }, ['Views_SelfService']);
            };
            return RemeCareSelfServiceConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        SelfService.remeCareSelfServiceModule.config(function ($stateProvider) { return new RemeCareSelfServiceConfiguration($stateProvider); });
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
