namespace RemeCare.Patient.Model {
    import Guid = Shared.Contract.Guid;
    import InformationObjectType = RemeCare.Shared.Contract.Code.InformationObjectType;
    import EntityTranslation = RemeCare.Shared.Contract.IEntityTranslation;

    export class MediaForAction {
        public action: EntityTranslation;
        public actionPart: EntityTranslation;
        public pastMedia: PastCarePlanMediaLibItem[];
        public futureMedia: FutureCarePlanMediaLibItem[];
        public readOnly: boolean;
        public name: string;

        constructor(serverObjects: Contract.Patient.Read.ICarePlanMediaLibItem[]) {
            this.action = serverObjects[0].Action;
            this.actionPart = serverObjects[0].ActionPart;

            this.name = this.action.Text;
            if (this.actionPart.Text) {
                this.name += ' - ' + this.actionPart.Text;
            }

            const lastProvidedSequence = _(serverObjects).max(x => moment(x.LastTimeProvided).toDate()).Sequence;
            const maxSequence = _(serverObjects).max(x => x.Sequence).Sequence;

            this.pastMedia = _(serverObjects)
                .chain()
                .filter(x => x.LastTimeProvided != null && x.LastTimeProvided !== '')
                .map(x => new PastCarePlanMediaLibItem(x))
                .sortBy(x => -x.lastTimeProvided)
                .value();

            this.futureMedia = _(serverObjects)
                .chain()
                .map(x => new FutureCarePlanMediaLibItem(x, lastProvidedSequence, maxSequence))
                .sortBy(x => -x.relativeSequence)
                .value();

            this.readOnly = serverObjects[0].ReadOnly;
        }
    }

    export abstract class CarePlanMediaLibItem {
        public id: Guid;
        public carePlanId: Guid;
        public name: string;
        public type: InformationObjectType;

        constructor(serverObject: Contract.Patient.Read.ICarePlanMediaLibItem) {
            this.id = serverObject.Id;
            this.carePlanId = serverObject.CarePlanId;
            this.name = serverObject.Name;
            this.type = serverObject.Type;
        }
    }

    export class PastCarePlanMediaLibItem extends CarePlanMediaLibItem {
        public lastTimeProvided: Date;

        constructor(serverObject: Contract.Patient.Read.ICarePlanMediaLibItem) {
            super(serverObject);

            this.lastTimeProvided = moment(serverObject.LastTimeProvided).toDate();
        }
    }

    export class FutureCarePlanMediaLibItem extends CarePlanMediaLibItem {
        public sequence: number;
        public relativeSequence: number;
        public hasLastProvided: boolean;

        constructor(
            serverObject: Contract.Patient.Read.ICarePlanMediaLibItem,
            lastProvidedSequence: number,
            maxSequence: number
        ) {
            super(serverObject);
            this.hasLastProvided = !!lastProvidedSequence;
            lastProvidedSequence = lastProvidedSequence || 0;
            this.sequence = serverObject.Sequence;
            this.relativeSequence = this.sequence - lastProvidedSequence - 1;
            if (this.relativeSequence < 0) {
                this.relativeSequence += maxSequence;
            }
        }
    }
}
