module RemeCare.CarePlanAction {

    interface IRelatedCarePlanActionScope extends ng.IScope {
        carePlanAction: CarePlanAction;
    }

    class RelatedCarePlanActionDirective implements ng.IDirective {

        restrict = 'E';

        scope: { [boundProperty: string]: string } = {
            carePlanAction: '=',
            showErrors: '=',
            readOnly: '=',
            isActive: '=',
            fullSize: '='
        }

        templateUrl = 'views/carePlanAction/directive/relatedCarePlanAction.html';
    }

    angular.module('RemeCare.CarePlanAction').directive('rcRelatedCarePlanAction', () => new RelatedCarePlanActionDirective());
} 