var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var SetDetailsController = /** @class */ (function (_super) {
            __extends(SetDetailsController, _super);
            // @ngInject
            function SetDetailsController($scope, $locale, toaster, partyApiSvc, carePlanApiSvc, modalBuilderFactory, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$scope = $scope;
                _this.$locale = $locale;
                _this.toaster = toaster;
                _this.partyApiSvc = partyApiSvc;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                $scope.changeLocation = function () { return _this.changeLocation(); };
                $scope.onTimeChanged = function () { return _this.onTimeChanged(); };
                return _this;
            }
            SetDetailsController.prototype.onTimeChanged = function () {
                if (this.$scope.component.hasTime && !this.$scope.component.isWindow) {
                    this.$scope.component.value.plannedTimeUntil = this.$scope.component.value.plannedTimeFrom;
                }
            };
            SetDetailsController.prototype.init = function () {
                this.onTimeChanged();
                this.$scope.minDate = moment()
                    .startOf('day')
                    .toDate();
                this.$scope.placeholder = this.$locale.DATETIME_FORMATS.shortTime;
                if (this.$scope.component.hasLocation) {
                    if (this.$scope.component.value.hasLocation()) {
                        if (this.$scope.component.value.location.ContactPointUsageId != null) {
                            this.findAddressAsync({
                                contactPointUsageId: this.$scope.component.value.location.ContactPointUsageId,
                            });
                        }
                        else {
                            this.$scope.address = {
                                Street: this.$scope.component.value.location.Street,
                                ZipCode: this.$scope.component.value.location.ZipCode,
                                City: this.$scope.component.value.location.City,
                            };
                        }
                    }
                    else if (this.$scope.component.defaultLocation != null) {
                        if (this.$scope.component.defaultLocation.ContactPointUsageTypeId != null) {
                            this.findAddressAndSetLocationAsync({
                                contactPointUsageTypeId: this.$scope.component.defaultLocation.ContactPointUsageTypeId,
                                partyRoleId: this.$scope.carePlanAction.patientId,
                            });
                        }
                        else if (this.$scope.component.defaultLocation.HealthCareOrganisationId != null) {
                            this.findAddressAndSetLocationAsync({
                                partyRoleId: this.$scope.component.defaultLocation.HealthCareOrganisationId,
                            });
                        }
                    }
                }
                if (this.$scope.component.hasExecutor) {
                    this.findAssignablePartiesAsync();
                }
            };
            SetDetailsController.prototype.findAddressAsync = function (query) {
                return __awaiter(this, void 0, void 0, function () {
                    var addressResult, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.findAddressAsync(query)];
                            case 1:
                                addressResult = _a.sent();
                                this.$scope.address = addressResult.Items[0];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SetDetailsController.prototype.findAddressAndSetLocationAsync = function (query) {
                return __awaiter(this, void 0, void 0, function () {
                    var addressResult, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.findAddressAsync(query)];
                            case 1:
                                addressResult = _a.sent();
                                this.setLocation(addressResult.Items[0]);
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SetDetailsController.prototype.findAssignablePartiesAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_3;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this.$scope;
                                return [4 /*yield*/, this.carePlanApiSvc.findAssignablePartiesAsync(this.$scope.carePlanAction.patientId, this.$scope.component.relatedStructuralTherapyAction.Id)];
                            case 1:
                                _a.possibleActors = _b.sent();
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SetDetailsController.prototype.changeLocation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('locationCtrl')
                    .setTemplateUrl('views/carePlanAction/location/locationModal.html')
                    .setScope({
                    patientId: this.$scope.carePlanAction.patientId,
                })
                    .setResultCallBack(function (r) { return _this.setLocation(r); })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            SetDetailsController.prototype.setLocation = function (address) {
                this.$scope.address = address;
                this.$scope.component.value.location = {};
                if (address == null) {
                    return;
                }
                if (address.Id != null) {
                    this.$scope.component.value.location.ContactPointUsageId = address.Id;
                }
                else {
                    this.$scope.component.value.location.Name = address.AddressLine2;
                    this.$scope.component.value.location.Street = address.AddressLine1;
                    this.$scope.component.value.location.ZipCode = address.ZipCode;
                    this.$scope.component.value.location.City = address.City;
                    this.$scope.component.value.location.Remark = address.Remark;
                    this.$scope.address = this.$scope.component.value.location;
                }
            };
            return SetDetailsController;
        }(CarePlanAction.ComponentControllerBase));
        var SetDetailsDirective = /** @class */ (function (_super) {
            __extends(SetDetailsDirective, _super);
            function SetDetailsDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/setDetails.html';
                _this.controller = SetDetailsController;
                return _this;
            }
            return SetDetailsDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular.module('RemeCare.CarePlanAction').directive('rcSetDetails', function () { return new SetDetailsDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
