var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        // Only used to visualize the carerequestor in the carerequest
        var CareRequestorInfo = /** @class */ (function () {
            function CareRequestorInfo(roleName, linked, person, healthCareProfessional, requestPerson, organisation) {
                this.roleName = roleName;
                this.linked = linked;
                if (person) {
                    this.name =
                        RemeCare.Shared.StringHelper.orEmpty(person.LastName) + ' ' + RemeCare.Shared.StringHelper.orEmpty(person.FirstName);
                    this.riziv = person.RizivNumber;
                    this.phone = person.Telephone ? new RemeCare.Model.PhoneNumber(person.Telephone).toString() : '';
                    this.mobile = person.Mobile ? new RemeCare.Model.PhoneNumber(person.Mobile).toString() : '';
                    this.email = person.Email;
                }
                if (healthCareProfessional) {
                    this.name =
                        RemeCare.Shared.StringHelper.orEmpty(healthCareProfessional.lastName) +
                            ' ' +
                            RemeCare.Shared.StringHelper.orEmpty(healthCareProfessional.firstName);
                    this.riziv = healthCareProfessional.identificationNumber;
                    this.phone = healthCareProfessional.phoneNumber ? healthCareProfessional.phoneNumber.toString() : '';
                    this.mobile = healthCareProfessional.mobileNumber ? healthCareProfessional.mobileNumber.toString() : '';
                    this.email = healthCareProfessional.email;
                }
                if (requestPerson) {
                    this.name = requestPerson.getName();
                    this.riziv = requestPerson.identificationNumber;
                    this.phone = requestPerson.contactInfo.telephone ? requestPerson.contactInfo.telephone.toString() : '';
                    this.mobile = requestPerson.contactInfo.mobile ? requestPerson.contactInfo.mobile.toString() : '';
                    this.email = requestPerson.contactInfo.email;
                }
                if (organisation) {
                    this.name = organisation.Name;
                    this.riziv = organisation.IdentificationNumber;
                    this.phone = organisation.PhoneNumber ? new RemeCare.Model.PhoneNumber(organisation.PhoneNumber).toString() : '';
                    this.mobile = '';
                    this.email = organisation.Email;
                }
            }
            return CareRequestorInfo;
        }());
        CareRequest.CareRequestorInfo = CareRequestorInfo;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
