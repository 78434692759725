module RemeCare.CareRequest {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Person = Contract.Party.Read.IPerson;
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;

    interface IContactPersonAcceptanceScope extends IAcceptanceControllerScope, ng.ui.bootstrap.IModalScope {
        contactPerson: PatientContactPerson;
        careRequestPart: Shared.Framework.Model.CareRequestPart;

        countries: Array<EnumTranslation>;
        genders: Array<EnumTranslation>;
        contactPersonTypes: Array<EnumTranslation>;

        contactPersonForm: ng.IFormController;

        newPerson: boolean;

        confirm(): void;
        isNewPersonRequired(CareRequestUIControlType): boolean;
        isRequired(CareRequestUIControlType): boolean;
    }

    class ContactPersonAcceptanceController extends AcceptanceControllerBase<IContactPersonAcceptanceScope> {

        private matchedPartyId: Shared.Contract.Guid;

        constructor(
            $scope: IContactPersonAcceptanceScope,
            $translate,
            toaster,
            gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            partyApiSvc: Core.Services.PartyApiService,
            private masterdataSvc: Shared.Framework.MasterdataService) {

            super($scope, $translate, toaster, gridBuilderSvc, partyApiSvc);

            $scope.atLeastOneNumber = () => this.atLeastOneNumber(this.$scope.contactPerson.person, CareRequestUIControlType.ContactPersonTelephoneMobile);
            $scope.copyToSearch = (src, dest) => this.copyToSearch(src, dest, this.$scope.contactPerson.person);
            $scope.updateField = p => this.updateField(p, this.$scope.contactPerson.person);
            $scope.confirm = () => this.confirm();
            $scope.personSelected = p => this.personSelected(p);
            $scope.isNewPersonRequired = (v) => this.isNewPersonRequired(v);
        }

        public $onInit(): void {
            super.$onInit();
            this.observeMatchedParty();

            this.masterdataSvc.getCountries()
                .success(r => this.$scope.countries = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getGenders()
                .success(r => this.$scope.genders = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getContactPersonTypes()
                .success(r => this.$scope.contactPersonTypes = r)
                .error(e => this.errorCallback(e));
        }

        private isNewPersonRequired(type?: CareRequestUIControlType): boolean {
            if (type == null)
                return (this.$scope.newPerson || this.$scope.newPerson == null);
            return this.$scope.isRequired(type) && (this.$scope.newPerson || this.$scope.newPerson == null);
        }

        private observeMatchedParty(): void {

            this.$scope.newPerson = this.$scope.contactPerson.matchedPartyId ? false : null;
            this.matchedPartyId = this.$scope.contactPerson.matchedPartyId;
            if (this.$scope.contactPerson.matchedParty != null) {
                this.$scope.selectedPerson = this.$scope.contactPerson.matchedParty;
                this.$scope.selectedPerson.updatePerson(this.$scope.contactPerson.person);
            }
            this.$scope.$watch('newPerson',(newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue) {
                        this.$scope.contactPerson.matchedPartyId = null;
                        this.$scope.contactPerson.matchedParty = null;
                    } else {
                        this.$scope.contactPerson.matchedPartyId = this.matchedPartyId;
                        this.$scope.contactPerson.matchedParty = this.$scope.selectedPerson;
                    }
                }
            });
        }

        private personSelected(person: Person): void {
            this.$scope.searchOpen = false;
            this.$scope.selectedPerson = RequestPerson.createFromPerson(person);
            this.$scope.contactPerson.person.update = new RequestPersonUpdate();
            this.$scope.contactPerson.person.contactInfo.update = new RequestContactInfoUpdate();
            this.matchedPartyId = person.PartyId;

            if (!this.$scope.newPerson) {
                this.$scope.contactPerson.matchedPartyId = person.PartyId;
                this.$scope.contactPerson.matchedParty = this.$scope.selectedPerson;
            }
        }

        private confirm(): void {
            if (this.$scope.contactPersonForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.$scope.$close(this.$scope.contactPerson);
        }
    }

    remeCareCareRequestModule.controller('contactPersonAcceptanceCtrl', ContactPersonAcceptanceController);
}