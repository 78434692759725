namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Guid = Shared.Contract.Guid;
    import DateFilterType = Contract.Core.Codes.DateFilterTypeCode;
    import RegistrationValuesQuery = Contract.Patient.Read.IFindRegistrationValuesQuery;
    import RegistrationValueOverview = Patient.Model.RegistrationValueOverview;

    class PatientFileRegistrationParametersController implements ng.IComponentController {
        public grid: Shared.Framework.Grid.Grid<RegistrationValueOverview>;
        public carePlans: EntityTranslation[];
        public therapyActions: EntityTranslation[];
        public dateFilterTypes: EnumTranslation[];
        public editRight: boolean;
        public patientId: Guid;

        constructor(
            private readonly $filter: ng.IFilterService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadDataAsync();
            this.editRight = true;
            this.search();
        }

        protected addAllCarePlansOption(carePlans: EntityTranslation[]): void {
            carePlans.unshift({
                Id: null,
                Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
            } as EntityTranslation);
        }

        private async loadDataAsync(): Promise<void> {
            let dateFilterTypes: Shared.Contract.IEnumTranslation[];
            let retrievedCarePlans: Contract.Patient.Read.ICareplan[];

            try {
                [dateFilterTypes, retrievedCarePlans, this.therapyActions] = await Promise.all([
                    this.masterdataSvc.getDateFilterTypesAsync(),
                    this.patientSvc.getCarePlansAsync(this.patientId),
                    this.patientSvc.getTherapyActionPartParametersAsync(this.patientId),
                ]);

                this.dateFilterTypes = _(dateFilterTypes).filter(
                    x =>
                        x.Id === DateFilterType.PlannedDate ||
                        x.Id === DateFilterType.RegistrationDate ||
                        x.Id === DateFilterType.ObservationDate
                );
                this.grid.searchCriteria.selectedDateFilter = DateFilterType.PlannedDate;

                const carePlans = CarePlan.getSortedOpenCloseCareplans(retrievedCarePlans);
                this.carePlans = _(carePlans).map(x => x.getSummary(this.$translate, this.$filter));
                this.addAllCarePlansOption(this.carePlans);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private search(): void {
            this.grid.pagingOptions.currentPage = 1;
            this.grid.search();
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<RegistrationValueOverview>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('name', 'General.Name')
                .addConditionallyStyledColumn('value.getValueString()', 'General.Value', `exceedsThreshold`, {
                    red: true,
                })
                .addUtcDateColumn('plannedDate', 'Views.PatientFile.Monitoring.PlannedDate', 'short', {
                    sort: { direction: 'desc' },
                })
                .addUtcDateColumn('registrationDate', 'Views.PatientFile.Monitoring.RegistrationDate', 'short')
                .addColumn('registrationSource', 'General.Source')
                .addConditionalShowEditNoButtonFunctionColumn(
                    x => this.viewCarePlanAction(x),
                    x => !this.canEditItem(x),
                    x => !this.canViewItem(x)
                );
            this.grid = gridBuilder.setSorting(false).build();
            this.grid.pagingOptions.pageSize = 20;
        }

        private canEditItem(selected: RegistrationValueOverview): boolean {
            return (
                ((Shared.Framework.AuthRight.Create | Shared.Framework.AuthRight.Write) & selected.executionRight) !== 0
            );
        }

        private canViewItem(selected: RegistrationValueOverview): boolean {
            return (Shared.Framework.AuthRight.Read & selected.executionRight) !== 0;
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<RegistrationValueOverview>> {
            const query: RegistrationValuesQuery = {
                carePlanId: criteria.carePlanId,
                therapyActionPartParameters: criteria.selectedParameters,
                dateFilterType: criteria.selectedDateFilter,
                startDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.fromDate) : null,
                endDate: criteria.selectedDateFilter ? Shared.DateHelper.toServerDateString(criteria.untilDate) : null,
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
            };

            try {
                const r = await this.patientSvc.getRegistrationValuesAsync(this.patientId, query);
                return {
                    Items: _(r.Items).map(x => new Patient.Model.RegistrationValueOverview(x)),
                    Total: r.Total,
                };
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private viewCarePlanAction(rv: RegistrationValueOverview): void {
            this.modalBuilderFactory
                .createModalBuilder<RegistrationValueOverview>()
                .setController('carePlanActionDetailModalCtrl')
                .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    actionId: rv.carePlanActionId,
                    editRight: this.canEditItem(rv),
                })
                .setResultCallBack(() => {
                    this.grid.search();
                })
                .build();
        }
    }

    remeCarePatientModule.component('patientFileRegistrationParameters', {
        controller: PatientFileRegistrationParametersController,
        templateUrl: 'views/patient/monitoring/registrations/parameters.html',
        bindings: {
            patientId: '@',
        },
    });
}
