module RemeCare.Management {

    interface IManagePersonScope extends Shared.Framework.IBaseScope {
        partyId: Shared.Contract.Guid;
        person: Model.Person;
        addressGrid: Shared.Framework.Grid.Grid<Model.Address>;
        phoneFaxGrid: Shared.Framework.Grid.Grid<Model.TelephoneNumber>;
        emailGrid: Shared.Framework.Grid.Grid<Model.Email>;
        globalActorRolesGrid: Shared.Framework.Grid.Grid<Contract.Management.Read.IGlobalActorRoleSummary>;
        contactPersons: Array<Contract.Party.Read.IContactPerson>;
        patientCreated(): void;
        showGroups():boolean;
    }

    class ManagePersonController extends Shared.Framework.ControllerBase<IManagePersonScope> {

        constructor(
            $scope,
            $translate,
            toaster,
            private $stateParams,
            private partyApiSvc: Core.Services.PartyApiService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private authservice: Shared.Framework.AuthService,
            private globalActorRoleSvc: GlobalActorRoleService) {
            super($scope, $translate, toaster);
            this.$scope.patientCreated = () => this.patientCreated();
            this.$scope.showGroups = () => this.showGroups();
        }

        public $onInit(): void {
            this.$scope.partyId = this.$stateParams.partyId;
            this.buildGrids();
            this.partyApiSvc.getPersonDetails(this.$stateParams.partyId, false)
                .success(r => {
                    this.$scope.person = new Model.Person(r);
                    this.loadHealthCareProfessionalContactPersons(this.$scope.person);
                    this.setGridData();
                })
                .error(e => this.errorCallback(e));
        }

        public showGroups(): boolean {
            const profile = this.authservice.getProfile();
            return (profile === Shared.Contract.Code.ApplicationProfileType.Superuser ||
                profile === Shared.Contract.Code.ApplicationProfileType.CareManager);
        }

        private loadHealthCareProfessionalContactPersons(person: Model.Person): void {
            if (person.isHealthCareProfessional()) {
                this.partyApiSvc.getContactPersons(person.getHealthCareProfessionalPartyRole().PartyRoleId)
                    .success(r => this.$scope.contactPersons = r)
                    .error(e => this.errorCallback(e));
            }
        }

        private findGlobalActorRoles(person: Model.Person): void {
            var query = <Contract.Management.Read.Query.IFindGlobalActorRolesQuery>{
                name: null,
                partyRoleIds: _(person.partyRoles).map<Shared.Contract.Guid>(pr => pr.PartyRoleId)
            };
            this.globalActorRoleSvc.searchSummaries(query)
                .success(r => this.$scope.globalActorRolesGrid.setData(r.Items))
                .error(e => this.errorCallback(e));
        }

        private buildGrids(): void {
            this.$scope.addressGrid = this.gridBuilderSvc
                .createGridBuilder<Model.Address>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('addressLine1', 'General.StreetAndNumber')
                .addColumn('city', 'General.City')
                .addEnumColumn('country', 'General.Country', Shared.Framework.ReferenceDataTypes.country)
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.$scope.phoneFaxGrid = this.gridBuilderSvc
                .createGridBuilder<Model.TelephoneNumber>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addEnumColumn('type', '', Shared.Framework.ReferenceDataTypes.contactPointType)
                .addColumn('toString()', 'General.Number')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.$scope.emailGrid = this.gridBuilderSvc
                .createGridBuilder<Model.Email>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('email', 'General.Email')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();

            this.$scope.globalActorRolesGrid = this.gridBuilderSvc
                .createGridBuilder<Contract.Management.Read.IGlobalActorRoleSummary>()
                .addColumn('Text', 'General.Name')
                .addNavigationColumn('eye-open', 'management.globalActorRoles.details', { actorRoleId: 'Id' })
                .build();
        }

        private setGridData(): void {
            this.$scope.addressGrid.setData(this.$scope.person.addresses);
            this.$scope.phoneFaxGrid.setData(this.$scope.person.getAllNumbers());
            this.$scope.emailGrid.setData(this.$scope.person.emailAddresses);
            this.findGlobalActorRoles(this.$scope.person);
        }

        patientCreated(): void {
            this.$onInit();
        }
    }

    remeCareManagementModule.controller('managePersonCtrl', ManagePersonController);
}