var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var RemeCareManagementConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareManagementConfiguration, _super);
            function RemeCareManagementConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareManagementConfiguration.prototype.config = function () {
                this.addRoute('management', '/Management', 'views/management/management.html', 'managementCtrl', ['Views_Management', 'Views_InformedConsent'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.management,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.persons' });
                this.addRoute('management.persons', '/Persons', 'views/baseView.html', null, ['Views_Management', 'Views_UserAccount'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementPersons,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.persons.search' });
                this.addRoute('management.persons.search', '/Search', 'views/management/persons/searchPersons.html', null, ['Views_Management', 'Views_Patient_General'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementPersons,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.addComponentRoute('management.persons.createpatient', '/CreatePatient', 'createPatient', ['Patient'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementPatient,
                    right: RemeCare.Shared.Framework.AuthRight.Create,
                });
                this.addRoute('management.persons.detail', '/:partyId', 'views/management/persons/personDetail.html', 'managePersonCtrl', ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementPersons,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, {
                    params: {
                        partyId: null,
                    },
                });
                this.addRoute('management.organisations', '/Organisations', 'views/baseView.html', null, ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementOrganisations,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.organisations.search' });
                this.addRoute('management.organisations.search', '/Search', 'views/management/organisations/searchOrganisations.html', null, ['Views_Management', 'Views_Patient_General'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementOrganisations,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('management.organisations.detail', {
                    url: '/:partyId',
                    component: 'rcManageOrganisationDetail',
                    params: {
                        partyId: null,
                    },
                }, ['Views_Management'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementOrganisations,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.addRoute('management.globalActorRoles', '/GlobalActorRoles', 'views/baseView.html', null, ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementActorRoles,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.globalActorRoles.search' });
                this.addRoute('management.globalActorRoles.search', '/Search?page&pageSize&sortOrder&sortField&name', 'views/management/globalActorRoles/globalActorRoles.html', 'searchGlobalActorRolesCtrl', ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementActorRoles,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.addRoute('management.globalActorRoles.details', '/:actorRoleId', 'views/management/globalActorRoles/globalActorRoleDetails.html', 'globalActorRoleDetailsCtrl', ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementActorRoles,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, {
                    params: {
                        actorRoleId: null,
                    },
                });
                this.addRoute('management.groups', '/Groups', 'views/baseView.html', null, ['Views_Management'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementGroups,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.groups.search' });
                this.add('management.groups.search', {
                    component: 'rcSearchGroups',
                    url: '/Search',
                }, ['Views_Management'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementGroups,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('management.groups.groupInformation', {
                    component: 'rcGroup',
                    url: '/:groupId',
                    params: {
                        groupId: null,
                    },
                }, ['Views_Management'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementGroups,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.addRoute('management.informedConsents', '/InformedConsents', 'views/baseView.html', null, ['Views_InformedConsent'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.managementInformedConsents,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, { redirectTo: 'management.informedConsents.overview' });
                this.add('management.informedConsents.overview', {
                    component: 'rcInformedConsentsOverview',
                    url: '/Overview',
                    params: {
                        // RoutePrefix is used so that the code in Shared knows whether to reroute to managment or to the therapy routing
                        routePrefix: 'management.',
                    },
                }, ['Views_InformedConsent'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementInformedConsents,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                });
                this.add('management.informedConsents.addOrUpdateInformedConsent', {
                    component: 'rcAddOrUpdateInformedConsent',
                    url: '/AddOrUpdate',
                    params: {
                        // RoutePrefix is used so that the code in Shared knows whether to reroute to managment or to the therapy routing
                        routePrefix: 'management.',
                    },
                }, ['Views_InformedConsent'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementInformedConsents,
                    right: RemeCare.Shared.Framework.AuthRight.Write,
                });
                this.add('management.informedConsents.informedConsentVersion', {
                    component: 'rcInformedConsentVersion',
                    params: {
                        informedConsentId: null,
                        // RoutePrefix is used so that the code in Shared knows whether to reroute to managment or to the therapy routing
                        routePrefix: 'management.',
                    },
                    url: '/:informedConsentId',
                }, ['Views_InformedConsent'], {
                    context: RemeCare.Shared.Framework.AuthContext.managementInformedConsents,
                    right: RemeCare.Shared.Framework.AuthRight.Write,
                });
            };
            return RemeCareManagementConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        Management.remeCareManagementModule.config(function ($stateProvider) { return new RemeCareManagementConfiguration($stateProvider); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
