var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SearchPersonsCriteriaController = /** @class */ (function (_super) {
            __extends(SearchPersonsCriteriaController, _super);
            function SearchPersonsCriteriaController($scope, $translate, toaster, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.masterdataSvc = masterdataSvc;
                return _this;
            }
            SearchPersonsCriteriaController.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getCountries()
                    .success(function (r) { return _this.$scope.countries = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getCommLanguages()
                    .success(function (r) { return _this.$scope.communicationLanguages = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getLanguages()
                    .success(function (r) { return _this.$scope.languages = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getGenders()
                    .success(function (r) { return _this.$scope.genders = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getPartyRoleTypes(RemeCare.Shared.Contract.Code.PartyType.Person)
                    .success(function (r) { return _this.$scope.partyRoleTypes = _(r)
                    .filter(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee
                    || prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; }); })
                    .error(function (e) { return _this.errorCallback(e); });
                this.$scope.grid.searchCriteria.phoneNumber = new RemeCare.Model.PhoneNumber();
                this.$scope.grid.searchCriteria.mobileNumber = new RemeCare.Model.PhoneNumber();
            };
            return SearchPersonsCriteriaController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('searchPersonsCriteriaCtrl', function ($scope, $translate, toaster, masterdataSvc) { return new SearchPersonsCriteriaController($scope, $translate, toaster, masterdataSvc); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
