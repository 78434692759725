namespace RemeCare.Patient {
    import CarePlanDate = Patient.Model.CarePlanDate;
    import GridBuilder = Shared.Framework.Grid.SearchGridBuilder;
    import VersionValidityChange = Shared.Framework.Model.VersionValidityChange;

    class CarePlanDateController extends CarePlanDetailComponentControllerBase<CarePlanDate> {
        constructor(
            protected $dialog: Shared.Service.DialogService,
            protected patientSvc: PatientService,
            protected toaster: Shared.Framework.Toaster,
            protected therapyApiSvc: Core.Services.TherapyApiService,
            protected authservice: Shared.Framework.AuthService,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($dialog, patientSvc, toaster, therapyApiSvc, authservice, gridBuilderSvc, modalBuilderFactory);
        }

        protected onPartRetrieved(): void {
            this.title = this.part.Name;
        }

        protected addGridRows(gridBuilder: GridBuilder<CarePlanDate>): GridBuilder<CarePlanDate> {
            return gridBuilder.addColumn('getFormattedDateValue()', 'General.Value');
        }

        protected async setVersionGridData(): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getCarePlanDatesAsync(this.carePlanId, this.definitionId);
                const versions = _(ags).map(x => new CarePlanDate(x));
                this.versionsGrid.setData(
                    _(versions)
                        .sortBy(x => x.validFromDate)
                        // Order is important as the versionsGrid edit-button-row disable function depends on this
                        .reverse()
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected getAddController(): string {
            return 'addCarePlanDateModalCtrl';
        }

        protected getAddTemplateUrl(): string {
            return 'views/patient/carePlans/carePlanDetails/addCarePlanDateModal.html';
        }

        protected extendAddScope(scope: any, detail: CarePlanDate): void {
            scope.carePlanDate = angular.copy(detail) || new CarePlanDate();
        }

        protected changeValidityAsync(versionValidityChange: VersionValidityChange): Promise<void> {
            return this.carePlanApiSvc.changeCarePlanDateValidityAsync(
                this.carePlanId,
                this.definitionId,
                versionValidityChange.toServerWrite()
            );
        }

        protected deleteAsync(fromDate: string): Promise<void> {
            return this.carePlanApiSvc.deleteCarePlanDateAsync(this.carePlanId, this.definitionId, fromDate);
        }
    }

    remeCarePatientModule.component('carePlanDate', {
        controller: CarePlanDateController,
        bindings: {
            patientId: '@',
            carePlanId: '@',
            definitionId: '@',
        },
        templateUrl: 'views/patient/carePlans/carePlanDetails/carePlanDetail.html',
    });
}
