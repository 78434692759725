/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import CareRequestPartType = Shared.Contract.Code.CareRequestPartType;
    import IHealthCareOrganisationParty = Contract.HealthCareParty.Read.IHealthCareOrganisationParty;
    import IHealthCareProfessionalParty = Contract.HealthCareParty.Read.IHealthCareProfessionalParty;
    import ActorRoleInclusionOption = Shared.Framework.Model.ActorRoleInclusionOption;
    import IPersonDetail = Contract.Party.Read.IPersonDetail;
    import ActorRole = RemeCare.Shared.Framework.Model.ActorRole;

    // https://stackoverflow.com/questions/48215950/exclude-property-from-type
    type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

    interface ICareRequestAutoAssignRoleScope extends ICareRequestPartBaseScope {
        autoAssignResults: IAutoAssignResult[];
        toggleRole(role: IApplyForRole): void;
    }

    class CareRequestAutoAssignRoleController extends CareRequestPartControllerBase<ICareRequestAutoAssignRoleScope> {
        constructor(
            protected $scope: ICareRequestAutoAssignRoleScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private $rootScope: ng.IRootScopeService,
            protected careRequestSvc: CareRequestSvc,
            private readonly healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly patientFileForExternalSvc: CareRequest.PatientFileForExternalSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);

            // Emit to careRequestor and careRequestRoles components so they can update their state & grids
            $scope.toggleRole = (role: IApplyForRole): void => {
                role.isSelected
                    ? this.$rootScope.$emit('CAREREQUEST_ROLE_SELECT', role)
                    : this.$rootScope.$emit('CAREREQUEST_ROLE_UNSELECT', role);
            };

            // When roles are removed from careRequest/careRequestRoles components, we want to update their checkboxes here
            this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECTED', (event, role: CareRequestRole, rolePart: RolePart) => {
                this.$scope.autoAssignResults.forEach((res) => {
                    res.matchedRoles.forEach((r) => {
                        if (r.actorRoleId === role.roleId && r.rolePart === rolePart) {
                            r.isSelected = false;
                        }
                    });
                });
            });
        }

        public async $onInit(): Promise<void> {
            this.$scope.autoAssignResults = [];
            if (
                this.$scope.careRequest.isReadOnly() ||
                this.authservice.getProfile() !== Shared.Contract.Code.ApplicationProfileType.CareProvider
            ) {
                return;
            }
            const autocreateroles = 'autocreateroles';
            this.spinnerSvc.show(autocreateroles);

            try {
                let tryAssignCareRequestor = true;
                const responsibleHcp = await this.getResponsibleHealthCareProfessionalAsync();
                if (responsibleHcp) {
                    const responsibleHcpResult = await this.autoAssignRoles(
                        responsibleHcp.PartyId as string,
                        responsibleHcp.PartyRoleId as string,
                        tryAssignCareRequestor,
                        this.$translate.instant('Views.CareRequest.AutoAssignRole.NamedMessage', {
                            name: responsibleHcp.FirstName + ' ' + responsibleHcp.LastName,
                        })
                    );
                    this.$scope.autoAssignResults.push(responsibleHcpResult);
                    tryAssignCareRequestor = !responsibleHcpResult.careRequestorAssigned;
                }

                const partyRoleId = this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId);
                const partyId = this.authservice.getClaim(Shared.Framework.ClaimTypes.partyId);
                this.$scope.autoAssignResults.push(
                    await this.autoAssignRoles(
                        partyId,
                        partyRoleId,
                        tryAssignCareRequestor,
                        this.$translate.instant('Views.CareRequest.AutoAssignRole.Message')
                    )
                );
            } finally {
                this.spinnerSvc.hide(autocreateroles);
            }
        }

        private async getResponsibleHealthCareProfessionalAsync(): Promise<IHealthCareProfessionalParty> {
            const searchResult = await this.patientFileForExternalSvc.getLastPatientFileForExternalAsync();
            if (searchResult.length !== 1) {
                return;
            }

            const responsibleHcp = new PatientFileForExternal(searchResult[0]).responsibleHealthCareProfessional;
            if (!responsibleHcp) {
                return;
            }

            // First try find match by RIZIV
            if (responsibleHcp.HealthProviderId) {
                const healthCareProfessionalSearchResult = await this.healthCareProfessionalSearchSvc.searchProfessionalsAsync(
                    { strictIdentificationNumber: true, identificationNumber: responsibleHcp.HealthProviderId }
                );
                if (healthCareProfessionalSearchResult.Items.length === 1) {
                    return healthCareProfessionalSearchResult.Items[0];
                }
            }

            // If not found, try find match by INSZ
            if (responsibleHcp.CitizenId) {
                const healthCareProfessionalSearchResult = await this.healthCareProfessionalSearchSvc.searchProfessionalsAsync(
                    { nationalNumber: responsibleHcp.CitizenId }
                );
                if (healthCareProfessionalSearchResult.Items.length === 1) {
                    return healthCareProfessionalSearchResult.Items[0];
                }
            }
        }

        private async autoAssignRoles(
            partyId: string,
            partyRoleId: string,
            includeRequestor: boolean,
            infoMessage: string
        ): Promise<IAutoAssignResult> {
            const autoAssignResult = await this.calculateMatchedRoles(
                partyId,
                partyRoleId,
                includeRequestor,
                infoMessage
            );
            // If there is only one possible organisation role, we should select it by default
            const organisationRoles = autoAssignResult.matchedRoles.filter(
                (mr) => mr.partyRoleType === PartyRoleType.HealthCareOrganisation
            );
            if (organisationRoles.length === 1) {
                const firstOrganisationRole = organisationRoles[0];
                firstOrganisationRole.isSelected = true;
                this.$scope.toggleRole(firstOrganisationRole);
                autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(
                    (r) => r !== firstOrganisationRole
                );
            }

            // If the current user can be the requestor, whe should select it by default
            const requestorRoles = autoAssignResult.matchedRoles.filter(
                (r) => r.rolePart === RolePart.CareRequestorRole
            );
            if (requestorRoles.length === 1) {
                const requestorRole = requestorRoles[0];
                autoAssignResult.careRequestorAssigned = true;
                requestorRole.isSelected = true;
                this.$scope.toggleRole(requestorRole);

                // Remove all matched roles with this actor role id, also the ones from careteam
                autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(
                    (r) => r.actorRoleId !== requestorRole.actorRoleId
                );
            }

            // If there is only one possible professional role, we should select it by default
            const professionalRoles = autoAssignResult.matchedRoles.filter(
                (mr) => mr.partyRoleType === PartyRoleType.HealthCareProfessional
            );
            if (!autoAssignResult.careRequestorAssigned && professionalRoles.length === 1) {
                const firstProfessionalRole = professionalRoles[0];
                firstProfessionalRole.isSelected = true;
                this.$scope.toggleRole(firstProfessionalRole);
                autoAssignResult.matchedRoles = autoAssignResult.matchedRoles.filter(
                    (r) => r !== firstProfessionalRole
                );
            }

            // When re-opening a temp saved care request, go over the matched roles to see if they are already selected
            if (this.$scope.careRequest.careRequestRoles && autoAssignResult.matchedRoles) {
                autoAssignResult.matchedRoles.forEach((matchedRole) => {
                    const foundRoles = this.$scope.careRequest.careRequestRoles.filter(
                        (crr) =>
                            crr.roleId === matchedRole.actorRoleId && crr.partyRoleId === matchedRole.party.PartyRoleId
                    );
                    if (foundRoles && foundRoles.length > 0) {
                        matchedRole.isSelected = true;
                    }
                });
            }

            return autoAssignResult;
        }

        private async calculateMatchedRoles(
            partyId: string,
            partyRoleId: string,
            includeRequestor: boolean,
            infoMessage: string
        ): Promise<IAutoAssignResult> {
            const parts = this.$scope.careRequestTemplate.careRequestSetup.careRequestParts;

            const personDetail = await this.partyApiSvc.getPersonDetailsAsync(partyId, false);
            const personOrganisation = await this.getCurrentHealthCareOrganization(personDetail, partyRoleId);
            let matchedRoles: IApplyForRole[] = [];
            for (const part of parts) {
                if (includeRequestor && part.type.Id === CareRequestPartType.CareRequestor) {
                    matchedRoles.push(
                        await this.addRequestorRole(
                            part as Shared.Framework.Model.CareRequestorDefinition,
                            partyRoleId,
                            personOrganisation,
                            personDetail
                        )
                    );
                } else if (part.type.Id === CareRequestPartType.CareRequestRole) {
                    matchedRoles.push(
                        ...(await this.addCareTeamRoles(
                            part as Shared.Framework.Model.CareRequestRoleDefinition,
                            partyRoleId,
                            personOrganisation,
                            personDetail
                        ))
                    );
                }
            }

            matchedRoles = matchedRoles.filter((r) => !!r);
            return {
                personDetail,
                personOrganisation,
                matchedRoles,
                careRequestorAssigned: false,
                infoMessage,
            };
        }

        private async addRequestorRole(
            part: Shared.Framework.Model.CareRequestorDefinition,
            partyRoleId: string,
            personOrg: IHealthCareOrganisationParty,
            personDetail: IPersonDetail
        ): Promise<IApplyForRole> {
            const actorRoleInclusionDetails = this.getActorRoleInclusionDetails(
                part.roleRequestingHealthCareProfessional.actorRoleInclusions
            );
            const partyRoleIds = actorRoleInclusionDetails
                .map((arid) => arid.partyRoleId)
                .filter((id) => id != null)
                .value();
            const roleName = (part as Shared.Framework.Model.CareRequestorDefinition)
                .roleRequestingHealthCareProfessional.name;

            switch (part.roleRequestingHealthCareProfessional.radioSelection) {
                case ActorRoleInclusionOption.Individuals:
                    if (!_.contains(partyRoleIds, partyRoleId)) {
                        return;
                    }
                    const org = await this.actorMeetsOrganisationRole(
                        (part as Shared.Framework.Model.CareRequestorDefinition).roleRequestingHealthCareOrganisation,
                        personOrg
                    );
                    return this.createMatchedRole({
                        roleName: roleName,
                        displayName: roleName + ' (' + this.$translate.instant('Views.Patient.Search.Requestor') + ')',
                        partyRoleType: PartyRoleType.HealthCareProfessional,
                        rolePart: RolePart.CareRequestorRole,
                        actorRoleId: (part as Shared.Framework.Model.CareRequestorDefinition)
                            .roleRequestingHealthCareProfessional.actorRoleId,
                        party: this.getFromPersonDetail(personDetail, partyRoleId),
                        organization: org,
                    });
                    break;
                case ActorRoleInclusionOption.Specialties:
                    const ts = [];
                    part.roleRequestingHealthCareProfessional.actorRoleInclusions.forEach((role) => {
                        role.actorRoleInclusionDetails.forEach((detail) => {
                            ts.push({
                                healthCareProfessionalTypeId: detail.healthCareProfessionalTypeId,
                                healthCareSpecialtyProfessionId: detail.healthCareSpecialtyProfessionId,
                            });
                        });
                    });
                    if (ts.length === 0) {
                        return;
                    }
                    const query: Contract.HealthCareParty.Read.Query.ISearchHealthCareProfessionalPartyQuery = {
                        partyRoleId: partyRoleId,
                        page: 1,
                        pageSize: 50,
                        typeAndSpecialties: ts,
                    };
                    const orgRole = await this.actorMeetsOrganisationRole(
                        (part as Shared.Framework.Model.CareRequestorDefinition).roleRequestingHealthCareOrganisation,
                        personOrg
                    );
                    return this.addHealthCareProfessionalIfConditionsAreMet(
                        query,
                        roleName,
                        roleName + ' (' + this.$translate.instant('Views.Patient.Search.Requestor') + ')',
                        RolePart.CareRequestorRole,
                        (part as Shared.Framework.Model.CareRequestorDefinition).roleRequestingHealthCareProfessional
                            .actorRoleId,
                        orgRole
                    );
                    break;
            }
        }

        private async addCareTeamRoles(
            part: Shared.Framework.Model.CareRequestRoleDefinition,
            partyRoleId: string,
            personOrg: IHealthCareOrganisationParty,
            personDetail: IPersonDetail
        ): Promise<IApplyForRole[]> {
            const result: IApplyForRole[] = [];
            for (const conf of part.careRequestRoleConfigurations) {
                if (!conf.isVisible) {
                    continue;
                }

                const actorRoleInclusionDetails = this.getActorRoleInclusionDetails(conf.role.actorRoleInclusions);
                const partyRoleIds = actorRoleInclusionDetails
                    .map((arid) => arid.partyRoleId)
                    .filter((id) => id != null)
                    .value();

                switch (conf.role.radioSelection) {
                    case ActorRoleInclusionOption.Individuals:
                        if (_.contains(partyRoleIds, partyRoleId)) {
                            result.push(
                                this.createMatchedRole({
                                    roleName: conf.role.name,
                                    displayName: conf.role.name,
                                    partyRoleType: PartyRoleType.HealthCareProfessional,
                                    rolePart: RolePart.CareTeamRole,
                                    actorRoleId: conf.role.actorRoleId,
                                    party: this.getFromPersonDetail(personDetail, partyRoleId),
                                    organization: personOrg,
                                })
                            );
                        }

                        break;
                    case ActorRoleInclusionOption.OrganisationTypes:
                        const org = await this.actorMeetsOrganisationRole(conf.role, personOrg);
                        if (org) {
                            result.push(
                                this.createMatchedRole({
                                    roleName: conf.role.name,
                                    displayName: conf.role.name,
                                    partyRoleType: PartyRoleType.HealthCareOrganisation,
                                    rolePart: RolePart.CareTeamRole,
                                    actorRoleId: conf.role.actorRoleId,
                                    party: personOrg,
                                    organization: org,
                                })
                            );
                        }
                        break;
                    case ActorRoleInclusionOption.Specialties:
                        const ts = [];
                        conf.role.actorRoleInclusions
                            .filter(
                                (inclusion) =>
                                    inclusion.partyRoleType ===
                                    Shared.Contract.Code.PartyRoleType.HealthCareProfessional
                            )
                            .forEach((inclusion) => {
                                inclusion.actorRoleInclusionDetails.forEach((detail) => {
                                    ts.push({
                                        healthCareProfessionalTypeId: detail.healthCareProfessionalTypeId,
                                        healthCareSpecialtyProfessionId: detail.healthCareSpecialtyProfessionId,
                                    });
                                });
                            });
                        if (ts.length === 0) {
                            return;
                        }
                        const query: Contract.HealthCareParty.Read.Query.ISearchHealthCareProfessionalPartyQuery = {
                            partyRoleId: partyRoleId,
                            page: 1,
                            pageSize: 50,
                            typeAndSpecialties: ts,
                        };
                        result.push(
                            await this.addHealthCareProfessionalIfConditionsAreMet(
                                query,
                                conf.role.name,
                                conf.role.name,
                                RolePart.CareTeamRole,
                                conf.role.actorRoleId,
                                personOrg
                            )
                        );
                        break;
                }
            }
            return result;
        }

        private async getCurrentHealthCareOrganization(
            personDetails: Contract.Party.Read.IPersonDetail,
            partyRoleId: string
        ): Promise<IHealthCareOrganisationParty> {
            const partyRole = personDetails.PartyRoles.filter(
                (role) => role.PartyRoleId === partyRoleId && 'CareProviderRelations' in role
            );

            if (partyRole.length !== 1) {
                return null;
            }

            const currentRelation = (partyRole[0] as Contract.Party.Read.IHealthCareProfessional).CareProviderRelations.filter(
                (relation) =>
                    moment(relation.ValidFromDate).isBefore(Shared.DateHelper.today()) &&
                    (relation.ValidUntilDate == null ||
                        moment(relation.ValidUntilDate).isAfter(Shared.DateHelper.today()))
            );

            if (currentRelation.length !== 1) {
                return null;
            }

            const result = await this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                partyRoleId: currentRelation[0].HealthCareOrganisation.PartyRoleIds[0],
            });
            if (result.Total === 1) {
                return result.Items[0];
            } else {
                return null;
            }
        }

        private async actorMeetsOrganisationRole(
            role: ActorRole,
            healthCareOrganisationParty: IHealthCareOrganisationParty
        ): Promise<IHealthCareOrganisationParty> {
            if (!healthCareOrganisationParty) {
                return null;
            }

            if (healthCareOrganisationParty.PartyRoleId === null) {
                return null;
            }

            let orgTypes: Shared.Contract.Guid[];

            if (role !== null && role.actorRoleInclusions !== null) {
                orgTypes = _(role.actorRoleInclusions)
                    .chain()
                    .map((ari) => ari.actorRoleInclusionDetails)
                    .flatten()
                    .map((arid) => {
                        if (arid.healthCareOrganisationTypeId === null) {
                            return null;
                        } else {
                            return arid.healthCareOrganisationTypeId.toString() as Shared.Contract.Guid;
                        }
                    })
                    .value()
                    .filter((item) => {
                        return item != null;
                    });
            }

            const results = await this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                organisationTypes: orgTypes,
                partyRoleId: healthCareOrganisationParty.PartyRoleId,
            });
            if (results.Total === 1) {
                return results.Items[0];
            }
            return null;
        }

        private getActorRoleInclusionDetails(
            inclusions: Shared.Framework.Model.ActorRoleInclusion[]
        ): _Chain<Shared.Framework.Model.ActorRoleInclusionDetail> {
            return _(inclusions)
                .chain()
                .map((ari) => ari.actorRoleInclusionDetails)
                .flatten();
        }

        private getFromPersonDetail(
            personDetail: IPersonDetail,
            partyRoleId: Shared.Contract.Guid
        ): IHealthCareProfessionalParty {
            return {
                FirstName: personDetail.FirstName,
                LastName: personDetail.LastName,
                PartyRoleId: partyRoleId,
                MobileNumber: personDetail.MobileNumbers[0],
                PhoneNumber: personDetail.TelephoneNumbers[0],
                Email: (personDetail.EmailAddresses[0] && personDetail.EmailAddresses[0].Email) || null,
                AddressLine: (personDetail.Addresses[0] && personDetail.Addresses[0].AddressLine1) || null,
                City: (personDetail.Addresses[0] && personDetail.Addresses[0].City) || null,
                HealthCareSpecialtyProfessions: [],
                IdentificationNumber: personDetail.NationalNumber,
                ZipCode: (personDetail.Addresses[0] && personDetail.Addresses[0].ZipCode) || null,
                PartyId: personDetail.Id,
            };
        }

        private async addHealthCareProfessionalIfConditionsAreMet(
            query: Contract.HealthCareParty.Read.Query.ISearchHealthCareProfessionalPartyQuery,
            roleName: string,
            displayName: string,
            rolePart: RolePart,
            actorRoleId: Shared.Contract.Guid,
            personOrg: IHealthCareOrganisationParty
        ): Promise<IApplyForRole> {
            // Check if the current user is a healthcare professional with correct type and specialty
            const prof1 = await this.healthCareProfessionalSearchSvc.searchProfessionalsAsync(query);
            if (prof1.Items.length > 0) {
                return this.createMatchedRole({
                    roleName: roleName,
                    displayName: displayName,
                    partyRoleType: PartyRoleType.HealthCareProfessional,
                    rolePart: rolePart,
                    actorRoleId: actorRoleId,
                    party: prof1.Items[0],
                    organization: personOrg,
                });
            }
        }

        private createMatchedRole(role: Omit<IApplyForRole, 'isSelected'>): IApplyForRole {
            return { ...role, isSelected: false };
        }
    }

    class CareRequestAutoAssignRoleDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            '$rootScope',
            'careRequestSvc',
            'healthCareProfessionalSearchSvc',
            'healthCareOrganisationSearchSvc',
            'partyApiSvc',
            'spinnerSvc',
            'authservice',
            'patientFileForExternalSvc',
            (
                $scope,
                $translate,
                toaster,
                $rootScope,
                careRequestSvc,
                healthCareProfessionalSearchSvc,
                healthCareOrganisationSearchSvc,
                partyApiSvc,
                spinnerSvc,
                authservice,
                patientFileForExternalSvc
            ) =>
                new CareRequestAutoAssignRoleController(
                    $scope,
                    $translate,
                    toaster,
                    $rootScope,
                    careRequestSvc,
                    healthCareProfessionalSearchSvc,
                    healthCareOrganisationSearchSvc,
                    partyApiSvc,
                    spinnerSvc,
                    authservice,
                    patientFileForExternalSvc
                ),
        ];

        public templateUrl = 'views/careRequest/directive/careRequestAutoAssignRole.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestAutoAssignRole', () => new CareRequestAutoAssignRoleDirective());
}
