var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var MergePersonCandidate = /** @class */ (function () {
            function MergePersonCandidate(serverObject) {
                this.personId = serverObject.PersonId;
                this.partyRoles = serverObject.PartyRoles;
            }
            return MergePersonCandidate;
        }());
        Model.MergePersonCandidate = MergePersonCandidate;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
