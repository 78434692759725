var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var HealthCareProfessionalSearchSvc = /** @class */ (function (_super) {
                __extends(HealthCareProfessionalSearchSvc, _super);
                function HealthCareProfessionalSearchSvc() {
                    return _super !== null && _super.apply(this, arguments) || this;
                }
                /**@deprecated*/
                HealthCareProfessionalSearchSvc.prototype.searchProfessionals = function (query) {
                    var url = 'HealthCareProfessionals';
                    return _super.prototype.get.call(this, url, query, 'professionalsSearch');
                };
                HealthCareProfessionalSearchSvc.prototype.searchProfessionalsAsync = function (query) {
                    var url = 'HealthCareProfessionals';
                    return _super.prototype.getPromise.call(this, url, query, 'professionalsSearch');
                };
                HealthCareProfessionalSearchSvc.prototype.getHealthCareProfessional = function (id) {
                    return this.getPromise("HealthCareProfessionals/" + id);
                };
                HealthCareProfessionalSearchSvc.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
                return HealthCareProfessionalSearchSvc;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.HealthCareProfessionalSearchSvc = HealthCareProfessionalSearchSvc;
            RemeCare.remeCareAppModule.service('healthCareProfessionalSearchSvc', HealthCareProfessionalSearchSvc);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
