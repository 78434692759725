var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ContactPerson = RemeCare.Model.ContactPersonPerson;
        var AddContactPersonController = /** @class */ (function () {
            function AddContactPersonController($state, $stateParams, toaster, partyApiSvc, masterdataSvc, gridBuilderSvc) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
                this.masterdataSvc = masterdataSvc;
                this.gridBuilderSvc = gridBuilderSvc;
            }
            AddContactPersonController.prototype.$onInit = function () {
                this.patientId = this.$stateParams.patientId;
                this.buildGrids();
                this.loadDataAsync();
            };
            AddContactPersonController.prototype.$onChanges = function () {
                this.buildGrids();
            };
            AddContactPersonController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getCountriesAsync(),
                                        this.masterdataSvc.getCommLanguagesAsync(),
                                        this.masterdataSvc.getLanguagesAsync(),
                                        this.masterdataSvc.getGendersAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.countries = _a[0], this.communicationLanguages = _a[1], this.languages = _a[2], this.genders = _a[3];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            AddContactPersonController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortOrder, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, r, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortOrder,
                                    firstName: criteria.firstName,
                                    lastName: criteria.lastName,
                                    address: criteria.address,
                                    zipCode: criteria.zipCode,
                                    city: criteria.city,
                                    country: criteria.country,
                                    communicationLanguage: criteria.communicationLanguage,
                                    language: criteria.language,
                                    gender: criteria.gender,
                                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.birthDate),
                                    identificationNumber: criteria.identificationNumber,
                                    phoneNumber: criteria.phoneNumber ? criteria.phoneNumber.toServerWrite() : null,
                                    email: criteria.email,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.findPersonsAsync(query)];
                            case 2:
                                r = _a.sent();
                                return [2 /*return*/, r];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            AddContactPersonController.prototype.buildGrids = function () {
                var _this = this;
                this.contactPersonsGrid = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, sd, c) { return _this.executeSearchAsync(p, ps, sf, sd, c); })
                    .addColumn('LastName', 'General.LastName')
                    .addColumn('FirstName', 'General.FirstName')
                    .addDateColumn('DateOfBirth', 'General.DateOfBirth', 'shortDate')
                    .addColumn('ZipCity', 'General.City', { width: '**' })
                    .addSelectButtonColumn(function (selected) { return _this.personSelectedAsync(selected); })
                    .setRowDetailsTemplate('views/patient/patientInfo/contactPersons/contactPersonDetail.html', 80)
                    .setSearchCriteria(function (c) {
                    c.phoneNumber =
                        c.phoneNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.phoneNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.phoneNumber);
                    c.mobileNumber =
                        c.mobileNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.mobileNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.mobileNumber);
                })
                    .build();
            };
            AddContactPersonController.prototype.personSelectedAsync = function (result) {
                return __awaiter(this, void 0, void 0, function () {
                    var p, contactPerson, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.getPersonDetailsAsync(result.PartyId, true)];
                            case 1:
                                p = _a.sent();
                                contactPerson = new ContactPerson(p, null);
                                this.$state.go('patients.patientfile.patientInfo.contactPersons.editContactPerson', {
                                    contactPerson: contactPerson,
                                    patientId: this.$stateParams.patientId,
                                    contactPersonId: contactPerson.id,
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return AddContactPersonController;
        }());
        Patient.remeCarePatientModule.component('addContactPerson', {
            templateUrl: 'views/patient/patientInfo/contactPersons/addContactPerson.html',
            controller: AddContactPersonController,
            bindings: {},
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
