var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        var SelfServiceApiService = /** @class */ (function (_super) {
            __extends(SelfServiceApiService, _super);
            function SelfServiceApiService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            SelfServiceApiService.prototype.activateUserAsync = function (activationKey, birthDate, userId, password) {
                return this.postPromise('Security/ActivateUser', {
                    key: activationKey,
                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(birthDate),
                    userId: userId,
                    password: password
                }, 'security.activateUser');
            };
            SelfServiceApiService.prototype.resetPasswordAsync = function (resetKey, birthDate, userId, password) {
                return this.postPromise('Security/ResetPassword', {
                    key: resetKey,
                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(birthDate),
                    userId: userId,
                    password: password
                }, 'security.resetPassword');
            };
            SelfServiceApiService.prototype.getExternalIdentityProvidersAsync = function (activationKey, userId, activationType) {
                return this.getPromise('Security/ExternalIdentityProviders', {
                    key: activationKey,
                    userId: userId,
                    activationType: activationType
                }, 'security.externalidentityproviders').then(function (eip) {
                    _.each(eip, function (provider) {
                        var baseUrl = provider.RemeCareBaseUrl[provider.RemeCareBaseUrl.length - 1] === '/'
                            ? provider.RemeCareBaseUrl.substring(0, provider.RemeCareBaseUrl.length - 1)
                            : provider.RemeCareBaseUrl;
                        provider.RemeCareBaseUrl = baseUrl;
                    });
                    return eip;
                });
            };
            SelfServiceApiService.prototype.activateExternalIdentityProviderAsync = function (activationKey, userId) {
                return this.postPromise('Security/ExternalIdentityProviders/ActivateUser', {
                    key: activationKey,
                    userId: userId
                });
            };
            SelfServiceApiService.prototype.resetExternalIdentityProviderAsync = function (activationKey, userId) {
                return this.postPromise('Security/ExternalIdentityProviders/ResetPassword', {
                    key: activationKey,
                    userId: userId
                });
            };
            SelfServiceApiService.prototype.requestNewPasswordAsync = function (userId) {
                return this.postPromise('Security/ForgotPassword', {
                    userId: userId
                }, 'security.requestNewPassword');
            };
            SelfServiceApiService.prototype.requestAccountInfoAsync = function (userInformation) {
                return this.postPromise('Security/RequestAccountInfo', userInformation, 'security.requestAccountInfo');
            };
            SelfServiceApiService.prototype.requestUserAccountAsync = function (userAccountInformation) {
                return this.postPromise('Security/RequestUserAccount', userAccountInformation, 'security.requestUserAccount');
            };
            SelfServiceApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return SelfServiceApiService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        SelfService.SelfServiceApiService = SelfServiceApiService;
        SelfService.remeCareSelfServiceModule.service('selfServiceApi', SelfServiceApiService);
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
