'use strict';

module RemeCare.Contract.Core {
    
    export interface ITelephoneNumber {
        Id?: Shared.Contract.Guid;
        CountryPrefix: string;
        AreaCode: string;
        LocalNumber: string;
    }
}