var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var EditCareTeamPartyController = /** @class */ (function (_super) {
            __extends(EditCareTeamPartyController, _super);
            function EditCareTeamPartyController($scope, $translate, toaster, patientId, patientSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.patientId = patientId;
                _this.patientSvc = patientSvc;
                $scope.minDate = function () { return _this.minDate(); };
                $scope.validFromInPast = function () { return _this.validFromInPast(); };
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            EditCareTeamPartyController.prototype.$onInit = function () {
                this.$scope.dateInfo = {
                    validFromDate: RemeCare.Shared.DateHelper.serverDateStringToDate(this.$scope.careTeamParty.ValidFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.serverDateStringToDate(this.$scope.careTeamParty.ValidUntilDate)
                };
                this.$scope.today = RemeCare.Shared.DateHelper.today();
            };
            EditCareTeamPartyController.prototype.validFromInPast = function () {
                return this.$scope.dateInfo.validFromDate != null
                    && moment(this.$scope.dateInfo.validFromDate).isBefore(this.$scope.today);
            };
            EditCareTeamPartyController.prototype.minDate = function () {
                if (this.$scope.dateInfo.validFromDate != null
                    && moment(this.$scope.dateInfo.validFromDate).isAfter(this.$scope.today)) {
                    return this.$scope.dateInfo.validFromDate;
                }
                return this.$scope.today;
            };
            EditCareTeamPartyController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.patientSvc.assignActorRole(this.patientId, this.$scope.careTeamParty.CarePlan.TherapyId, this.$scope.careTeamParty.Id, this.$scope.careTeamParty.ActorRole.Id, this.$scope.partyRoleId, this.$scope.dateInfo.validFromDate, this.$scope.dateInfo.validUntilDate)
                    .success(function () { return _this.$scope.$close(); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return EditCareTeamPartyController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('editCareTeamPartyCtrl', function ($scope, $translate, toaster, patientId, patientSvc) { return new EditCareTeamPartyController($scope, $translate, toaster, patientId, patientSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
