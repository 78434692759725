var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Framework = RemeCare.Shared.Framework;
        var PrescriptionController = /** @class */ (function (_super) {
            __extends(PrescriptionController, _super);
            function PrescriptionController($scope, $translate, toaster, modalBuilderFactory, masterdataSvc, medicationSearchSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.masterdataSvc = masterdataSvc;
                _this.medicationSearchSvc = medicationSearchSvc;
                $scope.clearMedication = function () { return _this.clearMedication(); };
                $scope.searchMedication = function () { return _this.searchMedication(); };
                $scope.save = function () { return _this.save(); };
                $scope.setDateRanges = function () { return _this.setDateRanges(); };
                $scope.prescriptionTypeChanged = function () { return _this.prescriptionTypeChanged(); };
                $scope.changeFollowedUpSchema = function () { return _this.changeFollowedUpSchema(); };
                $scope.getMedications = function (m) { return _this.getMedicationsAsync(m); };
                $scope.evaluateMedication = function (m) { return _this.evaluateMedication(m); };
                $scope.addAdministrationInformation = function () { return _this.addAdministrationInformation(); };
                $scope.hasAdministrationInformation = function () { return _this.hasAdministrationInformation(); };
                return _this;
            }
            PrescriptionController.prototype.$onInit = function () {
                this.loadDataAsync();
                this.$scope.medicationInfo = { medication: null };
                if (this.$scope.prescription == null) {
                    this.$scope.isNew = true;
                    this.$scope.canChangeMedication = true;
                    this.$scope.prescription = new CareRequest.CareRequestMedication(null);
                    this.$scope.prescription.makeDefault();
                }
                else {
                    this.$scope.isNew = false;
                    this.$scope.prescription.onlyIntakeMoments = false;
                    this.loadMedicationAsync();
                }
                this.$scope.prescription.structuralTherapyActionId = this.$scope.structuralTherapyActionId;
                this.$scope.prescription.isFollowedUp = this.$scope.structuralTherapyActionId != null;
                this.$scope.prescription.isAdHoc = this.$scope.isAdHoc;
                this.$scope.enableAdHoc = this.$scope.isAdHoc;
                this.$scope.enablePeriodicity = !this.$scope.isAdHoc;
                if (this.$scope.enableAdHoc && !this.$scope.enablePeriodicity) {
                    this.$scope.prescription.makeAdHoc(this.$scope.patientLang, this.masterdataSvc, this.$translate);
                }
                else if (this.$scope.enablePeriodicity && !this.$scope.enableAdHoc && this.$scope.prescription.isAdHoc) {
                    this.$scope.prescription.makeDefault();
                }
                if (this.$scope.patientLang) {
                    this.loadInterfaceLanguagesAsync();
                }
                else {
                    this.$scope.patientLang = this.$translate.preferredLanguage();
                }
                this.setDateRanges();
                this.watchDuration();
            };
            PrescriptionController.prototype.addAdministrationInformation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/medication/extraAdministrationInformation.html')
                    .setController('AdministrationInformationModalCtrl')
                    .setScope({
                    prescription: angular.copy(this.$scope.prescription),
                    medicationInfo: angular.copy(this.$scope.medicationInfo),
                })
                    .setSize(Framework.Helper.ModalSize.large)
                    .setResultCallBack(function (prescription) {
                    (_this.$scope.prescription.prescribedAdministrationInformation =
                        prescription.prescribedAdministrationInformation),
                        (_this.$scope.prescription.solventToAddInformation = prescription.solventToAddInformation);
                })
                    .build();
            };
            PrescriptionController.prototype.hasAdministrationInformation = function () {
                return this.$scope.prescription
                    ? this.$scope.prescription.prescribedAdministrationInformation
                        ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans
                            ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans > 0
                            : false
                        : false
                    : false;
            };
            PrescriptionController.prototype.loadMedicationAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this.$scope.medicationInfo;
                                return [4 /*yield*/, this.medicationSearchSvc.getMedicationAsync(this.$scope.prescription.medication.ProductId)];
                            case 1:
                                _a.medication = _b.sent();
                                this.$scope.canChangeMedication = !!this.$scope.medicationInfo.medication.ActiveSubstance;
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.loadInterfaceLanguagesAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.masterdataSvc.getInterfaceLanguagesAsync()];
                            case 1:
                                result = _a.sent();
                                this.$scope.patientLang = _(result).find(function (x) { return x.Code === _this.$scope.patientLang; }).Code;
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_3;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getDurationUnitsAsync(),
                                        this.masterdataSvc.getAdministrationMethodsAsync(),
                                        this.masterdataSvc.getSpeedUnitsAsync(),
                                        this.masterdataSvc.getSolventToAddUnitsAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.$scope.durations = _a[0], this.$scope.administrationMethods = _a[1], this.$scope.speedUnits = _a[2], this.$scope.solventToAddUnits = _a[3];
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.getMedicationsAsync = function (medicationName) {
                return __awaiter(this, void 0, void 0, function () {
                    var result, e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.medicationSearchSvc.searchMedicationsAsync({
                                        medicationName: medicationName,
                                        structuralTherapyActionId: this.$scope.structuralTherapyActionId,
                                        isActive: true,
                                        page: 1,
                                        pageSize: 1000,
                                    })];
                            case 1:
                                result = _a.sent();
                                return [2 /*return*/, result.Items];
                            case 2:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PrescriptionController.prototype.setDateRanges = function () {
                var minUntilDate = this.$scope.prescription.validFromDate != null
                    ? new Date(this.$scope.prescription.validFromDate.valueOf())
                    : null;
                this.$scope.dateInfo = {
                    minDate: null,
                    minUntilDate: minUntilDate,
                    fromDateDisabled: false,
                };
            };
            PrescriptionController.prototype.watchDuration = function () {
                var _this = this;
                this.$scope.$watch(function (s) { return s.prescription.periodicities[0].duration; }, function (newValue, oldValue) {
                    if (!angular.equals(newValue, oldValue)) {
                        if (newValue == null || newValue.Quantity == null || newValue.Unit == null) {
                            _this.$scope.prescription.validUntilDate = null;
                        }
                        else if (newValue.Quantity != null && newValue.Unit != null) {
                            var validUntilDate = RemeCare.Shared.DateHelper.addDuration(_this.$scope.prescription.validFromDate, newValue);
                            validUntilDate = RemeCare.Shared.DateHelper.addDuration(validUntilDate, {
                                Quantity: -1,
                                Unit: RemeCare.Shared.Contract.Code.DurationUnit.Days,
                            });
                            _this.$scope.prescription.validUntilDate = validUntilDate;
                        }
                    }
                }, true);
            };
            PrescriptionController.prototype.clearMedication = function () {
                this.$scope.medicationInfo.medication = null;
                this.$scope.prescription.medication = null;
            };
            PrescriptionController.prototype.searchMedication = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/medication/searchMedication.html')
                    .setController('searchMedicationCtrl')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setResultCallBack(function (m) {
                    _this.evaluateMedication(m);
                })
                    .setResolve({
                    productClassIds: function () { return null; },
                    productIds: function () { return null; },
                })
                    .setDismissCallBack(function () {
                    if (_this.$scope.prescription.medication == null) {
                        _this.$scope.$close();
                    }
                })
                    .setScope({
                    structuralTherapyActionId: this.$scope.structuralTherapyActionId,
                })
                    .build();
            };
            PrescriptionController.prototype.evaluateMedication = function (medication) {
                this.$scope.medicationInfo.medication = medication;
                this.$scope.prescription.medication = medication;
            };
            PrescriptionController.prototype.save = function () {
                if (this.$scope.prescriptionForm.$invalid) {
                    this.$scope.prescriptionForm.$setSubmitted();
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.prescription.isFollowedUp &&
                    !this.$scope.prescription.isAdHoc &&
                    (this.$scope.prescription.periodicities == null ||
                        _(this.$scope.prescription.periodicities).size() === 0)) {
                    this.toaster.error(this.$translate.instant('Periodicity.PeriodicityRequired'));
                    return;
                }
                if (this.$scope.prescription.isFollowedUp &&
                    !this.$scope.prescription.isAdHoc &&
                    _(this.$scope.prescription.periodicities).any(function (x) { return _(x.intraDayTimings).size() === 0; })) {
                    this.toaster.error(this.$translate.instant('Periodicity.IntraDayTimingRequired'));
                    return;
                }
                this.$scope.$close(this.$scope.prescription);
            };
            PrescriptionController.prototype.prescriptionTypeChanged = function () {
                if (this.$scope.prescription.isAdHoc) {
                    this.$scope.prescription.makeAdHoc(this.$scope.patientLang, this.masterdataSvc, this.$translate);
                }
                else {
                    this.$scope.prescription.makeDefault();
                }
            };
            PrescriptionController.prototype.changeFollowedUpSchema = function () { };
            return PrescriptionController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule.controller('careRequestMedicationCtrl', PrescriptionController);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
