var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ManageOrganisationControlller = /** @class */ (function () {
            function ManageOrganisationControlller($q, $timeout, authservice, toaster, partyApiSvc, gridBuilderSvc) {
                this.$q = $q;
                this.$timeout = $timeout;
                this.authservice = authservice;
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
            }
            ManageOrganisationControlller.prototype.$onInit = function () {
                var _this = this;
                this.buildGrids();
                this.partyApiSvc.getOrganisationDetails(this.$transition$.params().partyId)
                    .success(function (r) {
                    _this.organisation = new RemeCare.Model.Organisation(r);
                    _this.setGridData();
                })
                    .error(function (e) { return _this.toaster.error(e); });
                this.retrieveExternalIdentityProviders();
            };
            ManageOrganisationControlller.prototype.externalIdentityProviderLinkedChanged = function (provider) {
                var _this = this;
                provider.loading = true;
                var organisationId = this.$transition$.params().partyId;
                var promise = provider.linked
                    ? this.partyApiSvc.linkExternalIdentityProvider(organisationId, provider.id)
                    : this.partyApiSvc.unlinkExternalIdentityProvider(organisationId, provider.id);
                promise.error(function (e) {
                    _this.toaster.error(e);
                    _this.$timeout(function () {
                        provider.toggleLinked();
                    });
                }).finally(function () { return provider.loading = false; });
            };
            ManageOrganisationControlller.prototype.retrieveExternalIdentityProviders = function () {
                var _this = this;
                if (!this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.managementOrganisationsExternalIdentity, RemeCare.Shared.Framework.AuthRight.Read)) {
                    return;
                }
                this.$q.all([
                    this.partyApiSvc.getPossibleExternalIdentityProviders(),
                    this.partyApiSvc.getLinkedExternalIdentityProviders(this.$transition$.params().partyId)
                ]).then(function (results) {
                    _this.externalIdentityProviders = _.map(results[0].data, function (eip) {
                        var provider = new Management.ExternalIdentityProvider(eip);
                        provider.linked = _.any(results[1].data, function (leip) { return leip.Id === eip.Id; });
                        return provider;
                    });
                });
            };
            ManageOrganisationControlller.prototype.buildGrids = function () {
                this.addressGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('addressLine1', 'General.StreetAndNumber')
                    .addColumn('city', 'General.City')
                    .addEnumColumn('country', 'General.Country', RemeCare.Shared.Framework.ReferenceDataTypes.country)
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.phoneFaxGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addEnumColumn('type', '', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointType)
                    .addColumn('toString()', 'General.Number')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.emailGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('email', 'General.Email')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.relatedCareProvidersGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('HealthCareProfessional.Title', 'General.PersonTitle')
                    .addColumn('HealthCareProfessional.FirstName', 'General.FirstName')
                    .addColumn('HealthCareProfessional.LastName', 'General.LastName')
                    .addColumn('FunctionInOrganisation', 'General.Function')
                    .addDateColumn('ValidFromDate', 'General.From', 'shortDate')
                    .addDateColumn('ValidUntilDate', 'General.Until', 'shortDate')
                    .addNavigationColumn('eye-open', 'management.persons.detail', { partyId: 'HealthCareProfessional.PartyId' })
                    .setExternalSorting(false)
                    .setSorting(true)
                    .build();
            };
            ManageOrganisationControlller.prototype.setGridData = function () {
                this.addressGrid.setData(this.organisation.addresses);
                this.phoneFaxGrid.setData(this.organisation.getAllNumbers());
                this.emailGrid.setData(this.organisation.emailAddresses);
                var relations = _(this.organisation.getHealthCareOrganisationPartyRole().CareProviderRelations)
                    .chain()
                    .sortBy(function (cpr) { return cpr.HealthCareProfessional.LastName; })
                    .sortBy(function (cpr) { return cpr.HealthCareProfessional.FirstName; })
                    .value();
                this.relatedCareProvidersGrid.setData(relations);
            };
            return ManageOrganisationControlller;
        }());
        Management.remeCareManagementModule.component('rcManageOrganisationDetail', {
            bindings: {
                $transition$: '<'
            },
            controller: ManageOrganisationControlller,
            templateUrl: 'views/management/organisations/organisationDetail.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
