var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        var AuthContext = RemeCare.Shared.Framework.AuthContext;
        var AuthRight = RemeCare.Shared.Framework.AuthRight;
        var RemeCareIntegrationMonitorConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareIntegrationMonitorConfiguration, _super);
            function RemeCareIntegrationMonitorConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareIntegrationMonitorConfiguration.prototype.config = function () {
                this.addGeneric('integrationMonitor', {
                    url: '/IntegrationMessages',
                    redirectTo: 'integrationMonitor.search',
                }, ['Views_IntegrationMonitor'], {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                });
                this.addGeneric('integrationMonitor.search', {
                    url: '/Search',
                    component: 'rcSearchIntegrationMessages'
                }, ['Patient', 'Views_IntegrationMonitor'], {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                });
                this.addGeneric('integrationMonitor.message', {
                    component: 'rcIntegrationMessage',
                    params: {
                        messageId: null,
                    },
                    url: '/:messageId'
                }, ['Patient', 'Views_IntegrationMonitor', 'Views_IntegrationMonitor_IntegrationMessage'], {
                    context: AuthContext.integrationMonitor,
                    right: AuthRight.Read,
                });
            };
            return RemeCareIntegrationMonitorConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        IntegrationMonitor.remeCareIntegrationMonitorModule.config(function ($stateProvider) { return new RemeCareIntegrationMonitorConfiguration($stateProvider); });
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
