namespace RemeCare.CarePlanAction {
    import GuidanceApiService = RemeCare.Shared.Framework.Service.GuidanceApiService;

    interface ICarePlanActionScope extends ng.IScope {
        carePlanAction: RemeCare.Model.ActionBase;
        showEventDateTime: boolean;
        showErrors: boolean;
        readOnly: boolean;
        fullSize: boolean;
    }

    class CarePlanActionDirective implements ng.IDirective {
        public restrict: string = 'E';

        public scope: { [boundProperty: string]: string } = {
            carePlanAction: '=',
            showErrors: '=',
            readOnly: '=',
            fullSize: '=',
        };
        constructor(
            private readonly $templateCache: ng.ITemplateCacheService,
            private readonly $compile: ng.ICompileService,
            private readonly uuid4,
            private readonly guidanceApiSvc: GuidanceApiService
        ) {}

        public link: ng.IDirectiveLinkFn = (scope: ICarePlanActionScope, element: ng.IAugmentedJQuery) => {
            this.configure(scope);
            this.render(scope, element);
            scope.$watch('carePlanAction', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.render(scope, element);
                }
            });
        };

        private configure(scope: ICarePlanActionScope): void {
            scope.showEventDateTime = !_(scope.carePlanAction.components).any(
                (c) =>
                    c.actionTypeCode === Shared.Contract.Code.ActionType.CareAct ||
                    c.actionTypeCode === Shared.Contract.Code.ActionType.Administration
            );
        }

        private render(scope: ICarePlanActionScope, element: ng.IAugmentedJQuery): void {
            let currentWidth = 0;
            let html = scope.showEventDateTime
                ? (this.$templateCache.get('views/carePlanAction/directive/eventDateTime.html') as string)
                : '';

            if (scope.carePlanAction != null) {
                scope.carePlanAction.components = _.sortBy(
                    scope.carePlanAction.components,
                    (component: ComponentDescriptor) => {
                        return component.sequence;
                    }
                );
                for (const currentComponent of scope.carePlanAction.components) {
                    const result = this.getDirectiveHtml(currentComponent, currentWidth);
                    html += result.html;
                    currentWidth = result.currentWidth;
                }
            }
            element.html(html).show();
            this.$compile(element.contents())(scope);
        }

        private getDirectiveHtml(component: ComponentDescriptor, currentWidth: number): CarePlanActionPartsHtmlResult {
            let directiveName: string;
            if (component.hidden) {
                return new CarePlanActionPartsHtmlResult(' ', currentWidth);
            }
            switch (component.actionTypeCode) {
                case Shared.Contract.Code.ActionType.QuantitativeObservation:
                    directiveName = 'quantitative-observation';
                    break;
                case Shared.Contract.Code.ActionType.QualitativeObservation:
                    directiveName = 'qualitative-observation';
                    break;
                case Shared.Contract.Code.ActionType.Administration:
                    if ((component as AdministrationDescriptor).medicationDoses.length > 1) {
                        directiveName = 'administration-adhoc';
                    } else {
                        directiveName = 'administration';
                    }
                    break;
                case Shared.Contract.Code.ActionType.CareAct:
                    directiveName = 'care-act';
                    break;
                case Shared.Contract.Code.ActionType.FixDetails:
                    directiveName = 'set-details';
                    break;
                case Shared.Contract.Code.ActionType.ProvideInformationObject:
                    directiveName = 'provide-information-object';
                    break;
                case Shared.Contract.Code.ActionType.RequestInformationObject:
                    directiveName = 'request-information-object';
                    break;
                case Shared.Contract.Code.ActionType.Remark:
                    directiveName = 'remark';
                    break;
                case Shared.Contract.Code.ActionType.PreviousRegistration:
                    directiveName = 'previous-registration';
                    break;
                case Shared.Contract.Code.ActionType.TelephoneCallPlanning:
                    directiveName = 'phone-call';
                    break;
                case Shared.Contract.Code.ActionType.Date:
                    directiveName = 'date';
                    break;
                case Shared.Contract.Code.ActionType.MissingRegistrations:
                    directiveName = 'missing-registrations';
                    break;
                case Shared.Contract.Code.ActionType.MedicationSchema:
                    directiveName = 'medication-schema';
                    break;
                case Shared.Contract.Code.ActionType.AberrantRegistrations:
                    directiveName = 'aberrant-registrations';
                    break;
                case Shared.Contract.Code.ActionType.Signature:
                    directiveName = 'signature';
                    break;
                default:
                    return new CarePlanActionPartsHtmlResult('<p>Part not implemented</p>', currentWidth);
            }

            let widthClass: string;
            let widthValue: number;
            switch (component.width) {
                case Shared.Contract.Code.PartWidth.Full:
                    widthClass = 'col-md-12';
                    widthValue = 12;
                    break;
                case Shared.Contract.Code.PartWidth.Half:
                    widthClass = 'col-md-6';
                    widthValue = 6;
                    break;
                case Shared.Contract.Code.PartWidth.Third:
                    widthClass = 'col-md-4';
                    widthValue = 4;
                    break;
                case Shared.Contract.Code.PartWidth.TwoThird:
                    widthClass = 'col-md-8';
                    widthValue = 8;
                    break;
                default:
                    widthClass = 'col-md-12';
                    widthValue = 12;
                    break;
            }

            currentWidth += widthValue;

            const uniqueId = this.uuid4.generate();

            if (component.guidanceEnabled) {
                this.guidanceApiSvc.createGuidanceItem(uniqueId, component.guidanceCode);
            }

            let directiveHtml = [
                `<div class="row">`,
                `<div class="col-xs-12 ${widthClass}" >`,
                `<rc-${directiveName} therapy-action-part-id="${
                    component.id
                }" care-plan-action="carePlanAction" unique-id="${uniqueId}" show-errors="showErrors" read-only="readOnly" full-size="fullSize"></rc-${directiveName}>`,
                '</div>',
                '</div>',
                // `<div class="row" id="${uniqueId}" style="display: none;">`,
                `<div class="col-xs-12 ${widthClass}" >`,
                `<rc-guidance-part unique-id="${uniqueId}"></rc-guidance-part>`,
                '</div>',
                // '</div>',
            ].join('');

            if (currentWidth > 12) {
                directiveHtml = `<div class="clearfix"></div>${directiveHtml}`;
                currentWidth = widthValue;
            }

            const result = new CarePlanActionPartsHtmlResult(directiveHtml, currentWidth);
            return result;
        }
    }

    class CarePlanActionPartsHtmlResult {
        constructor(public html: string, public currentWidth: number) {}
    }

    angular
        .module('RemeCare.CarePlanAction')
        .directive(
            'rcCarePlanAction',
            ($templateCache, $compile, uuid4, guidanceApiSvc) =>
                new CarePlanActionDirective($templateCache, $compile, uuid4, guidanceApiSvc)
        );
}
