var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var HistoryItem = /** @class */ (function () {
            function HistoryItem() {
            }
            HistoryItem.fromHistoryListItem = function (historyListItem, translate) {
                var historyItem = new HistoryItem();
                historyItem.registrationDate = historyListItem.RegistrationDate;
                historyItem.action = translate(historyListItem.Action);
                historyItem.registeredBy = historyListItem.RegisteredBy;
                historyItem.status = historyListItem.Status;
                switch (historyListItem.RelatedAction) {
                    case 'careRequest':
                        historyItem.url = '#/CareRequest/' + historyListItem.RelatedActionId;
                        break;
                    case 'careRequest/Cardio':
                        historyItem.url = '#/CareRequest/Cardio/' + historyListItem.RelatedActionId;
                        break;
                    case 'extracare.evaluation':
                        historyItem.url = '#/Agenda/CoachingEvaluation/' + historyListItem.RelatedActionId;
                        break;
                    case 'extracare.media':
                        historyItem.url = '#/Agenda/Visit/' + historyListItem.RelatedActionId;
                        break;
                    case 'coardiocoach.uptitrationEvaluation':
                        historyItem.url = '#/Actions/UptitrationEvaluation/' + historyListItem.RelatedActionId;
                        break;
                    case 'visit':
                        historyItem.url = '#/Agenda/Visit/' + historyListItem.RelatedActionId;
                        break;
                    default:
                        historyItem.url = '#/Actions/' + historyListItem.RelatedActionId;
                        break;
                }
                return historyItem;
            };
            return HistoryItem;
        }());
        Model.HistoryItem = HistoryItem;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
