'use strict';

module RemeCare.Contract.CarePlanAction.Read {
    import Guid = Shared.Contract.Guid;
    import MediaLibItemDetail = Contract.Core.IMediaLibItemDetail;
    
    export interface ICarePlanActionDetail {
        Id: Guid;
        Editable: boolean;
        MediaLibItems: MediaLibItemDetail[];
    }
}