/// <reference path="../../../../carePlanAction/model/carePlanAction.ts"/>

namespace RemeCare.Patient {
    import Action = CarePlanAction.IAction;
    import Guid = Shared.Contract.Guid;
    import RegistrationValue = Contract.Patient.Read.IRegistrationValueSummary;

    interface ICarePlanActionDetailModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        // From bindings
        actionId: Guid;
        editRight: boolean;

        // Added
        panelCollapsed: boolean;
        readOnly: boolean;
        fullSize: boolean;

        action: CarePlanAction.CarePlanAction;

        registrationValues: RegistrationValue[];
        registrationsGrid: Shared.Framework.Grid.Grid<RegistrationValue>;
        patientName: string;

        hasRegistrationParts: boolean;
        canBeCancelled: boolean;

        showHistory: () => void;
        registerFunctions: (functions: RemeCare.CarePlanAction.ICarePlanActionModalFunctions) => void;

        functionsRegistered: boolean;
        cancelRegistrations: () => void;
        register: (permanent: boolean) => void;
        cancel(): void;
        planNewContact(includeParts: boolean): void;
        hasPhoneCall(): boolean;
    }

    class CarePlanActionDetailModalController extends Shared.Framework.ControllerBase<ICarePlanActionDetailModalScope> {
        constructor(
            protected $scope: ICarePlanActionDetailModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService
        ) {
            super($scope, $translate, toaster);
            this.$scope.showHistory = () => this.showHistory();
            this.$scope.registerFunctions = (functions: RemeCare.CarePlanAction.ICarePlanActionModalFunctions) =>
                this.registerFunctions(functions);
        }

        public $onInit(): void {
            this.$scope.panelCollapsed = true;
            this.$scope.fullSize = true;
            this.buildRegistrationValueGrid();
            this.getCarePlanActionRegistrationsAsync();
            this.getCarePlanActionAsync(null);
        }

        public registerFunctions(functions: RemeCare.CarePlanAction.ICarePlanActionModalFunctions): void {
            this.$scope.cancelRegistrations = () => functions.cancelRegistrations();
            this.$scope.register = isPermanent => functions.register(isPermanent);
            this.$scope.cancel = () => functions.cancel();
            this.$scope.planNewContact = includeParts => functions.planNewContact(includeParts);
            this.$scope.hasPhoneCall = () => functions.hasPhoneCall();
            this.$scope.functionsRegistered = true;
        }

        private async getCarePlanActionRegistrationsAsync(): Promise<void> {
            try {
                this.$scope.registrationValues = await this.carePlanActionApiSvc.getCarePlanActionRegistrationsAsync(
                    this.$scope.actionId
                );
                this.$scope.registrationsGrid.setData(this.$scope.registrationValues);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getCarePlanActionAsync(pointInTime: string): Promise<void> {
            const query: Contract.Core.Read.IDateQuery = {
                pointInTime: pointInTime,
            };

            try {
                const carePlanAction = await this.carePlanActionApiSvc.getCarePlanActionAsync(
                    this.$scope.actionId,
                    query
                );
                if (carePlanAction.Patient != null) {
                    const persons = await this.partyApiSvc.getPersonsAsync([carePlanAction.Patient.Id]);
                    this.$scope.action = new CarePlanAction.CarePlanAction(this.authservice, carePlanAction, persons[0]);
                    this.$scope.canBeCancelled =
                        this.$scope.action.status !== RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed && this.$scope.action.status !==
                        RemeCare.Contract.Core.Codes.CarePlanActionStatus.Cancelled;
                    this.$scope.patientName = `${persons[0].FirstName} ${persons[0].LastName}`;
                    this.configure();
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private configure(): void {
            this.$scope.hasRegistrationParts =
                _(this.$scope.action.components).filter(
                    c =>
                        c.actionTypeCode === Shared.Contract.Code.ActionType.ProvideInformationObject &&
                        (c as RemeCare.CarePlanAction.ProvideInformationObjectDescriptor).questions.length === 0
                ).length < this.$scope.action.components.length;
        }

        private buildRegistrationValueGrid(): void {
            this.$scope.registrationsGrid = this.gridBuilderSvc
                .createGridBuilder<RegistrationValue>()
                .addColumn('RegistrationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', {
                    cellFilter: 'displayUtcDateTimeFilter',
                })
                .addColumn('RegisteredBy', 'Views.PatientFile.Monitoring.RegisteredBy')
                .addConditionalShowEditButtonWithPromiseFunctionColumn(
                    rv => this.showCarePlanAction(rv.RegistrationDateTime),
                    rv => this.editCarePlanAction(rv),
                    () => !this.$scope.editRight
                )
                .setExternalSorting(false)
                .build();
        }

        private showCarePlanAction(pointInTime: string): void {
            this.getCarePlanActionAsync(pointInTime);
        }

        private async editCarePlanAction(registrationValue: RegistrationValue): Promise<RegistrationValue> {
            this.getCarePlanActionAsync(registrationValue.RegistrationDateTime);
            return registrationValue;
        }

        private showHistory(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<void>('rcCarePlanActionHistory')
                .setBindings({
                    actionId: this.$scope.actionId,
                })
                .setSize('xl')
                .build();
        }
    }

    angular.module('RemeCare.Patient').controller('carePlanActionDetailModalCtrl', CarePlanActionDetailModalController);
}
