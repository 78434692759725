var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var ComponentControllerBase = /** @class */ (function () {
            function ComponentControllerBase($scope, guidanceApiSvc) {
                var _this = this;
                this.$scope = $scope;
                this.guidanceApiSvc = guidanceApiSvc;
                $scope.isActive = function () { return _this.isActive(); };
                $scope.isRequired = function () { return _this.isRequired(); };
            }
            ComponentControllerBase.prototype.$onInit = function () {
                var _this = this;
                this.$scope.component = _.find(this.$scope.carePlanAction.components, function (c) { return c.id === _this.$scope.therapyActionPartId; });
                this.componentsWithConditions = _(this.$scope.carePlanAction.components).filter(function (c) {
                    return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.QualitativeObservation &&
                        _(c.answerConditions).any(function (a) { return a.GoToId === _this.$scope.component.id; });
                });
                this.determiningRequiredComponent = _(this.$scope.carePlanAction.components)
                    .filter(function (c) { return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.CareAct; })
                    .pop();
                this.item = this.guidanceApiSvc.getGuidanceItem(this.$scope.uniqueId);
                this.init();
            };
            ComponentControllerBase.prototype.isActive = function () {
                var _this = this;
                if (this.componentsWithConditions.length === 0) {
                    return true;
                }
                var active = _(this.componentsWithConditions).any(function (c) {
                    return _.find(c.answerConditions, function (a) { return a.GoToId === _this.$scope.component.id && _(c.value).contains(a.AnswerCodeSetItem.Id); }) != null;
                });
                if (!active) {
                    this.$scope.component.clearValue();
                    if (this.item !== undefined) {
                        this.guidanceApiSvc.toggleItem(this.item.Id, false);
                    }
                }
                return active;
            };
            ComponentControllerBase.prototype.isRequired = function () {
                var _this = this;
                // see also careplanaction.ts:setAllUnrequired() for override action with linked actions
                if (this.$scope.component.requiredOverriden) {
                    if (this.$scope.component === this.determiningRequiredComponent) {
                        return this.$scope.component.required;
                    }
                    return (this.$scope.component.requiredForCodeSetItems.filter(function (csi) { return csi === _this.determiningRequiredComponent.value; }).length > 0);
                }
                else {
                    return this.$scope.component.required;
                }
            };
            return ComponentControllerBase;
        }());
        CarePlanAction.ComponentControllerBase = ComponentControllerBase;
        var ComponentDirectiveBase = /** @class */ (function () {
            function ComponentDirectiveBase() {
                this.restrict = 'E';
                this.scope = {
                    therapyActionPartId: '@',
                    showErrors: '=',
                    readOnly: '=',
                    fullSize: '=',
                    carePlanAction: '=',
                    uniqueId: '@',
                };
            }
            return ComponentDirectiveBase;
        }());
        CarePlanAction.ComponentDirectiveBase = ComponentDirectiveBase;
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
