var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var RegistrationValueFactory = RemeCare.Model.RegistrationValueFactory;
            var RegistrationValueOverview = /** @class */ (function () {
                function RegistrationValueOverview(serverObject) {
                    if (serverObject != null) {
                        this.id = serverObject.Id;
                        this.name = serverObject.Name;
                        this.carePlanActionId = serverObject.CarePlanActionId;
                        this.exceedsThreshold = serverObject.ExceedsThreshold;
                        this.plannedDate = serverObject.PlannedDate;
                        this.registrationDate = serverObject.RegistrationDate;
                        this.registrationSource = serverObject.RegistrationSource;
                        this.value = RegistrationValueFactory.createRegistrationValueFromServer(serverObject.Value);
                        this.executionRight = serverObject.ExecutionRight;
                    }
                }
                return RegistrationValueOverview;
            }());
            Model.RegistrationValueOverview = RegistrationValueOverview;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
