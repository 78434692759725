module RemeCare.Patient {
    
    interface IRegisterDateOfDeathScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        dateOfDeath: Date;
        form: ng.IFormController;
        confirm(): void;
        maxDate: Date;
    }

    class RegisterDateOfDeathController extends Shared.Framework.ControllerBase<IRegisterDateOfDeathScope> {
        
        constructor(
            $scope: IRegisterDateOfDeathScope,
            $translate,
            toaster,
            private partyId: Shared.Contract.Guid,
            private partyApiSvc: Core.Services.PartyApiService) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.maxDate = moment().toDate();
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.partyApiSvc.registerDateOfDeath(this.partyId, this.$scope.dateOfDeath)
                .success(() => this.$scope.$close(this.$scope.dateOfDeath))
                .error(e => this.errorCallback(e));
        }
    }

    remeCarePatientModule.controller('registerDateOfDeathCtrl', (
        $scope,
        $translate,
        toaster,
        partyId,
        partyApiSvc
    ) => new RegisterDateOfDeathController(
        $scope,
        $translate,
        toaster,
        partyId,
        partyApiSvc
    ));
}