var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        var CarePlanActionController = /** @class */ (function () {
            function CarePlanActionController($window, $timeout, toaster, carePlanActionApiSvc, partyApiSvc, $stateParams, storageSvc, patientSvc, modalBuilderFactory, $state, authservice) {
                this.$window = $window;
                this.$timeout = $timeout;
                this.toaster = toaster;
                this.carePlanActionApiSvc = carePlanActionApiSvc;
                this.partyApiSvc = partyApiSvc;
                this.$stateParams = $stateParams;
                this.storageSvc = storageSvc;
                this.patientSvc = patientSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.$state = $state;
                this.authservice = authservice;
                this.actionIndex = 1;
                this.actionIds = !_($stateParams.actionIds).isArray()
                    ? [$stateParams.actionIds]
                    : $stateParams.actionIds;
            }
            CarePlanActionController.prototype.$onInit = function () {
                if (this.$window.innerWidth < 1200) {
                    this.showAction();
                }
                else {
                    var knownFilters = this.storageSvc.get(StorageServiceConstants.carePlanActionKey);
                    if (knownFilters && !knownFilters.showDashboard) {
                        this.showAction();
                    }
                    else {
                        if (knownFilters && !knownFilters.showAction) {
                            this.showDashboard();
                        }
                        else {
                            this.showActionAndDashboard();
                        }
                    }
                }
                this.actionSelectionChanged();
                this.startIndex = 0;
            };
            CarePlanActionController.prototype.registerFunctions = function (functions) {
                this.cancelRegistrations = function () { return functions.cancelRegistrations(); };
                this.register = function (isPermanent) { return functions.register(isPermanent); };
                this.planNewContact = function (includeParts) { return functions.planNewContact(includeParts); };
                this.hasPhoneCall = function () { return functions.hasPhoneCall(); };
                this.functionsRegistered = true;
            };
            CarePlanActionController.prototype.togglePatientDetails = function (event) {
                this.showPatientDetails = !this.showPatientDetails;
                event.stopPropagation();
                event.preventDefault();
            };
            CarePlanActionController.prototype.showInternalRemarkModal = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcInternalRemarkModal')
                    .setBindings({
                    remark: this.patient.PatientRemark,
                    patientId: this.patientId,
                })
                    .setResultCallBack(function (result) {
                    _this.patient.PatientRemark = result;
                    _this.patientSvc
                        .saveInternalRemark(_this.patientId, result)
                        .success(function () { })
                        .error(function (e) {
                        _this.toaster.error(e.data);
                    });
                })
                    .setLarge()
                    .build();
            };
            CarePlanActionController.prototype.showActionInFlow = function (carePlanAction) {
                var _this = this;
                // Set action to null to trigger the ng-if and redraw the <dynamic-action-controller>
                this.action = null;
                this.functionsRegistered = false;
                this.$timeout(function () { return _this.getCarePlanActionAsync(carePlanAction.Id); });
            };
            CarePlanActionController.prototype.moveActionsInFlowLeft = function () {
                var newIndex = this.startIndex - 1;
                newIndex = Math.max(0, newIndex);
                this.startIndex = newIndex;
            };
            CarePlanActionController.prototype.moveActionsInFlowRight = function () {
                var newIndex = this.startIndex + 1;
                newIndex = Math.min(this.actionsInFlow.length - 4, newIndex);
                this.startIndex = newIndex;
            };
            CarePlanActionController.prototype.actionSelectionChanged = function () {
                var _this = this;
                // Set action to null to trigger the ng-if and redraw the <dynamic-action-controller>
                this.action = null;
                this.functionsRegistered = false;
                this.$timeout(function () {
                    var actionId = _this.actionIds[_this.actionIndex - 1];
                    _this.findActionsInFlowAsync(actionId);
                    _this.getCarePlanActionAsync(actionId);
                });
            };
            CarePlanActionController.prototype.getActionButtonStyle = function (action) {
                if (action.Id === this.mainAction.id) {
                    return 'btn-primary';
                }
                if (action.Id === this.action.id) {
                    return 'btn-remedus-blue';
                }
                return 'btn-default';
            };
            CarePlanActionController.prototype.getActionClass = function () {
                if (this.showingAction) {
                    return this.showingDashboard ? 'col-sm-6' : 'col-sm-12';
                }
                return 'ng-hide';
            };
            CarePlanActionController.prototype.getDashboardClass = function () {
                if (this.showingDashboard) {
                    return this.showingAction ? 'col-sm-6' : 'col-sm-12';
                }
                return 'ng-hide';
            };
            CarePlanActionController.prototype.showAction = function () {
                this.showingAction = true;
                this.showingDashboard = false;
                this.triggerResizeEvent();
                this.storeKeys();
            };
            CarePlanActionController.prototype.showActionAndDashboard = function () {
                this.showingAction = true;
                this.showingDashboard = true;
                this.triggerResizeEvent();
                this.storeKeys();
            };
            CarePlanActionController.prototype.showDashboard = function () {
                this.showingAction = false;
                this.showingDashboard = true;
                this.triggerResizeEvent();
                this.storeKeys();
            };
            CarePlanActionController.prototype.goBack = function () {
                if (this.$stateParams.referringState) {
                    this.$state.go(this.$stateParams.referringState.name, this.$stateParams.referringState.params);
                }
                else {
                    this.$state.go('agenda');
                }
            };
            CarePlanActionController.prototype.registered = function () {
                if (this.actionIndex >= this.actionIds.length) {
                    this.$state.go('agenda');
                }
                else {
                    this.actionIndex++;
                    this.actionSelectionChanged();
                }
            };
            CarePlanActionController.prototype.cancel = function () {
                this.$state.go('agenda');
            };
            CarePlanActionController.prototype.getCarePlanActionAsync = function (actionId) {
                return __awaiter(this, void 0, void 0, function () {
                    var carePlanAction, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionAsync(actionId)];
                            case 1:
                                carePlanAction = _a.sent();
                                this.configureMainActionAsync(carePlanAction);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanActionController.prototype.findActionsInFlowAsync = function (actionId) {
                return __awaiter(this, void 0, void 0, function () {
                    var inFlowResult, index, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.findActionsInFlowAsync(actionId)];
                            case 1:
                                inFlowResult = _a.sent();
                                this.actionsInFlow = _.sortBy(inFlowResult, function (a) { return a.PlannedDateTimeFrom; });
                                index = _.findIndex(this.actionsInFlow, function (a) { return a.Id === actionId; });
                                index = Math.max(0, index - 3);
                                this.startIndex = index;
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanActionController.prototype.configureMainActionAsync = function (mainAction) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_3;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                this.patientId = mainAction.Patient.Id;
                                _a = this;
                                return [4 /*yield*/, this.partyApiSvc.getPersonsAsync([this.patientId])];
                            case 1:
                                _a.patient = (_b.sent())[0];
                                this.mainAction = new CarePlanAction.CarePlanAction(this.authservice, mainAction, this.patient);
                                this.configureAction(mainAction);
                                this.canEdit = this.mainAction.canEditAction;
                                return [3 /*break*/, 3];
                            case 2:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CarePlanActionController.prototype.configureAction = function (carePlanAction) {
                this.action = new CarePlanAction.CarePlanAction(this.authservice, carePlanAction, this.patient);
                this.hasRegistrationParts =
                    _(this.action.components).filter(function (c) {
                        return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.ProvideInformationObject &&
                            c.questions.length === 0;
                    }).length < this.action.components.length;
            };
            CarePlanActionController.prototype.storeKeys = function () {
                var filters = {
                    showDashboard: this.showingDashboard,
                    showAction: this.showingAction,
                };
                this.storageSvc.store(StorageServiceConstants.carePlanActionKey, filters);
            };
            CarePlanActionController.prototype.triggerResizeEvent = function () {
                // simple $(window).resize() does not trigger the charts reflow
                this.$timeout(function () {
                    var evt = document.createEvent('HTMLEvents');
                    evt.initEvent('resize', false, true);
                    window.dispatchEvent(evt);
                });
            };
            return CarePlanActionController;
        }());
        CarePlanAction.remeCareCarePlanActionModule.component('action', {
            controller: CarePlanActionController,
            templateUrl: 'views/carePlanAction/carePlanAction.html',
        });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
