var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var ModalityType = RemeCare.Shared.Contract.Code.ModalityType;
            var CareRequestPartComponentControllerBase = /** @class */ (function () {
                function CareRequestPartComponentControllerBase() {
                }
                CareRequestPartComponentControllerBase.prototype.convertToTypeAndSpecialties = function (inclusions) {
                    return inclusions
                        .filter(function (i) { return i.healthCareProfessionalTypeId != null; })
                        .map(function (i) {
                        return ({
                            healthCareProfessionalTypeId: i.healthCareProfessionalTypeId,
                            healthCareSpecialtyProfessionId: i.healthCareSpecialtyProfessionId
                        });
                    })
                        .value();
                };
                CareRequestPartComponentControllerBase.prototype.isPresent = function (controlType, part) {
                    if (!part) {
                        part = this.careRequestPart;
                    }
                    var control = _.find(part.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                    if (control == null) {
                        return false;
                    }
                    else {
                        return control.modalityType.Id !== ModalityType.Not;
                    }
                };
                CareRequestPartComponentControllerBase.prototype.isRequired = function (controlType, part) {
                    part = part || this.careRequestPart;
                    if (part == null) {
                        return false;
                    }
                    var control = _.find(part.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                    if (control == null) {
                        return false;
                    }
                    else {
                        return control.modalityType.Id === ModalityType.Mandatory;
                    }
                };
                return CareRequestPartComponentControllerBase;
            }());
            Directive.CareRequestPartComponentControllerBase = CareRequestPartComponentControllerBase;
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
