module RemeCare.Management {

    export class Group {
        public id: Shared.Contract.Guid;
        public name: string;
        public validFromDate: Date;
        public validUntilDate: Date;
        public organisations: Array<Management.GroupAssignment>;
        public persons: Array<Management.GroupAssignment>;

        constructor(serverObject?: Contract.Management.Read.IGroup) {
            if (serverObject) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.organisations = _(serverObject.Organisations)
                    .map(o => new GroupAssignment(o));
                this.persons = _(serverObject.Persons)
                    .map(p => new GroupAssignment(p));
            } else {
                this.organisations = [];
                this.persons = [];
            }
        }

        toServerWrite(): RemeCare.Contract.Management.Write.IGroup {
            return <RemeCare.Contract.Management.Write.IGroup>{
                id: this.id,
                name: this.name,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
                persons: _(this.persons).map(p => p.toServerWrite()),
                organisations: _(this.organisations).map(o => o.toServerWrite())
            };
        }
    }
}
