module RemeCare.Patient {
    import Person = RemeCare.Model.Person;
    import Address = RemeCare.Model.Address;
    import TelephoneNumber = RemeCare.Model.TelephoneNumber;
    import Email = RemeCare.Model.Email;

    class PersonalInformationComponent implements ng.IComponentController {
        person: Person;

        constructor(
            private $state: ng.ui.IStateService,
            private $stateParams,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private toaster: Shared.Framework.Toaster) {
        }

        public $onInit(): void {}

        private buildAddressGrid(): Shared.Framework.Grid.Grid<Address> {
            return this.gridBuilderSvc
                .createGridBuilder<Address>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('addressLine1', 'General.Address')
                .addColumn('zipCode', 'General.Zipcode')
                .addColumn('city', 'General.City')
                .addEnumColumn('country', 'General.Country', Shared.Framework.ReferenceDataTypes.country)
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }

        private buildEmailGrid(): Shared.Framework.Grid.Grid<Email> {
            return this.gridBuilderSvc
                .createGridBuilder<Email>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('email', 'General.Email')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }


        private buildPhoneNumbersGrid(): Shared.Framework.Grid.Grid<TelephoneNumber> {
            return this.gridBuilderSvc
                .createGridBuilder<TelephoneNumber>()
                .addEnumColumn('contactPointUsageType', 'General.Type', Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                .addColumn('toString()', 'General.Number')
                .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                .addColumn('remark', 'General.Remark')
                .setExternalSorting(false)
                .build();
        }

        private showAddresses(): void {
            var grid = this.buildAddressGrid();
            grid.setData(this.person.addresses);
            this.showContactPointsModal('Views.PatientFile.DetailAddresses', grid);
        }

        private showPhoneNumbers(): void {
            var grid = this.buildPhoneNumbersGrid();
            grid.setData(this.person.telephoneNumbers);
            this.showContactPointsModal('Views.PatientFile.DetailPhone', grid);
        }

        private showMobileNumbers(): void {
            var grid = this.buildPhoneNumbersGrid();
            grid.setData(this.person.mobileNumbers);
            this.showContactPointsModal('Views.PatientFile.DetailMobile', grid);
        }

        private showEmails(): void {
            var grid = this.buildEmailGrid();
            grid.setData(this.person.emailAddresses);
            this.showContactPointsModal('Views.PatientFile.DetailEmail', grid);
        }
        private showContactPointsModal(title: string, grid: Shared.Framework.Grid.Grid<RemeCare.Model.ContactPoint>): void {
            this.modalBuilderFactory.createModalBuilder()
                .setTemplateUrl('views/shared/contactPointDetail.html')
                .setScope({
                    title: title,
                    grid: grid
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }
    }


    remeCarePatientModule.component('rcPersonalInformation',
        {
            templateUrl: 'views/patient/patientInfo/contactPersons/personInformationComponent.html',
            controller: PersonalInformationComponent,
            controllerAs: 'personalInformationCtrl',
            bindings: {
                person: '='
            }
        });
}