var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var LocationController = /** @class */ (function (_super) {
            __extends(LocationController, _super);
            function LocationController($scope, $translate, toaster, gridBuilderSvc, partyApiSvc, masterdataSvc, healthCarePartySvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.partyApiSvc = partyApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.healthCarePartySvc = healthCarePartySvc;
                $scope.searchAddresses = function () { return _this.searchAddresses(); };
                return _this;
            }
            LocationController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                this.buildPatientAddressesGrid();
                                this.buildSearchGrid();
                                this.$scope.tabs = [
                                    {
                                        heading: 'Views.Action.Location.PatientAddresses',
                                        src: 'views/carePlanAction/location/patientAddress.html'
                                    },
                                    {
                                        heading: 'Views.Action.Location.SearchAddress',
                                        src: 'views/carePlanAction/location/searchAddress.html'
                                    },
                                    {
                                        heading: 'Views.Action.Location.CustomAddress',
                                        src: 'views/carePlanAction/location/customAddress.html'
                                    }
                                ];
                                this.$scope.oneTimeAddress = {};
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, Promise.all([
                                        this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                                        this.masterdataSvc.getPartyRoleTypesAsync(),
                                        this.healthCarePartySvc.getHealthCareOrganisationTypesAsync()
                                    ])];
                            case 2:
                                _a = _b.sent(), this.$scope.specialties = _a[0], this.$scope.partyRoleTypes = _a[1], this.$scope.organisationTypes = _a[2];
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4:
                                this.$scope.$watch(function (s) { return s.searchGrid.searchCriteria.partyRoleType; }, function (newValue, oldValue) {
                                    if (newValue !== oldValue) {
                                        if (newValue === RemeCare.Shared.Contract.Code.PartyRoleType.Patient ||
                                            newValue === RemeCare.Shared.Contract.Code.PartyRoleType.ContactPerson) {
                                            _this.$scope.searchGrid.searchCriteria.partyRoleId = _this.$scope.patientId;
                                        }
                                        else {
                                            _this.$scope.searchGrid.searchCriteria.partyRoleId = null;
                                        }
                                        if (newValue !== RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareOrganisation) {
                                            _this.$scope.searchGrid.searchCriteria.organisationTypeId = null;
                                        }
                                        if (newValue !== RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional) {
                                            _this.$scope.searchGrid.searchCriteria.specialtyId = null;
                                        }
                                    }
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            LocationController.prototype.searchAddresses = function () {
                this.$scope.searchGrid.pagingOptions.currentPage = 1;
                this.$scope.searchGrid.search();
            };
            LocationController.prototype.searchAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, address, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    partyRoleId: criteria.partyRoleId,
                                    partyRoleType: criteria.partyRoleType,
                                    organisationTypeId: criteria.organisationTypeId,
                                    specialtyId: criteria.specialtyId,
                                    name: criteria.name,
                                    firstName: criteria.firstName,
                                    street: criteria.street,
                                    zipCode: criteria.zipCode,
                                    city: criteria.city
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.findAddressAsync(query)];
                            case 2:
                                address = _a.sent();
                                address.Items = _(address.Items).map(function (i) {
                                    i.ZipCity = (i.ZipCode || '') + " " + (i.City || '');
                                    return i;
                                });
                                return [2 /*return*/, address];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            LocationController.prototype.buildSearchGrid = function () {
                var _this = this;
                this.$scope.searchGrid = this.buildAddressesGrid(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.searchAsync(page, pageSize, sortField, sortDirection, criteria);
                });
            };
            LocationController.prototype.buildPatientAddressesGrid = function () {
                var _this = this;
                this.$scope.patientAddressesGrid = this.buildAddressesGrid(function (page, pageSize, sortField, sortDirection) {
                    return _this.searchAsync(page, pageSize, sortField, sortDirection, {
                        partyRoleId: _this.$scope.patientId
                    });
                });
                this.$scope.patientAddressesGrid.search();
            };
            LocationController.prototype.buildAddressesGrid = function (searchFunction) {
                var _this = this;
                var grid = this.gridBuilderSvc
                    .createGridBuilder(searchFunction)
                    .addColumn('ContactPointUsageType.Text', 'General.AddressType', { width: 100 })
                    .addColumn('Name', 'General.Name', { width: '**' })
                    .addColumn('AddressLine1', 'General.AddressLine1', { width: '***' })
                    .addColumn('ZipCity', 'General.City', { width: '**' })
                    .addSelectButtonColumn(function (r) { return _this.$scope.$close(r); })
                    .setRowDetailsTemplate('views/carePlanAction/location/locationDetails.html', 160)
                    .build();
                grid.pagingOptions.pageSize = 5;
                return grid;
            };
            return LocationController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.CarePlanAction').controller('locationCtrl', LocationController);
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
