var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var DateHelper = RemeCare.Shared.DateHelper;
        var AgendaAction = /** @class */ (function () {
            function AgendaAction(agendaAction, persons) {
                var patientInfo = _(persons).find(function (x) {
                    return _(x.PartyRoles).any(function (pr) { return pr.Id === agendaAction.Patient.Id; });
                });
                this.actors = [];
                if (agendaAction.PartyRole) {
                    this.actors.push(agendaAction.PartyRole);
                }
                else {
                    this.actors = agendaAction.ActorRoles;
                }
                this.id = agendaAction.Id;
                this.plannedDateTimeFrom = DateHelper.serverDateStringToDateTime(agendaAction.PlannedDateTimeFrom);
                this.plannedDateTimeUntil = DateHelper.serverDateStringToDateTime(agendaAction.PlannedDateTimeUntil);
                this.patient = agendaAction.Patient.Text;
                this.patientId = agendaAction.Patient.Id;
                this.patientNumber = agendaAction.PatientNumber;
                this.address = patientInfo != null ? (patientInfo.ZipCode || '') + " " + (patientInfo.City || '') : '';
                this.name = agendaAction.Name;
                this.status = agendaAction.Status;
                this.therapies = agendaAction.Therapies;
                this.canBeEdited = (agendaAction.MaxExecutionRight & RemeCare.Shared.Framework.AuthRight.Create) !== 0;
                this.canBeViewed = (agendaAction.MaxExecutionRight & RemeCare.Shared.Framework.AuthRight.Read) !== 0;
            }
            return AgendaAction;
        }());
        Model.AgendaAction = AgendaAction;
        var PersonalAgendaAction = /** @class */ (function (_super) {
            __extends(PersonalAgendaAction, _super);
            function PersonalAgendaAction(agendaAction) {
                var _this = _super.call(this, agendaAction, null) || this;
                _this.showAsToDo = agendaAction.ShowAsToDo;
                _this.suggestedDateTime = DateHelper.serverDateStringToDateTime(agendaAction.SuggestedDateTime);
                _this.executionDateTime = DateHelper.serverDateStringToDateTime(agendaAction.ExecutionDateTime);
                _this.modificationDateTime = DateHelper.serverDateStringToDateTime(agendaAction.ModificationDateTime);
                _this.openEventWindowDateTime = DateHelper.serverDateStringToDateTime(agendaAction.OpenEventWindowDateTime);
                _this.closeEventWindowDateTime = DateHelper.serverDateStringToDateTime(agendaAction.CloseEventWindowDateTime);
                _this.eventDateTimeFrom = DateHelper.serverDateStringToDateTime(agendaAction.EventDateTimeFrom);
                _this.eventDateTimeUntil = DateHelper.serverDateStringToDateTime(agendaAction.EventDateTimeUntil);
                return _this;
            }
            return PersonalAgendaAction;
        }(AgendaAction));
        Model.PersonalAgendaAction = PersonalAgendaAction;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
