var RemeCare;
(function (RemeCare) {
    var OneTimeLogin;
    (function (OneTimeLogin) {
        var OneTimeLoginController = /** @class */ (function () {
            function OneTimeLoginController($window, appConfig, toaster, storageSvc, oneTimeLoginApi) {
                this.$window = $window;
                this.appConfig = appConfig;
                this.toaster = toaster;
                this.storageSvc = storageSvc;
                this.oneTimeLoginApi = oneTimeLoginApi;
            }
            OneTimeLoginController.prototype.$onInit = function () {
                var _this = this;
                this.loginUrl = this.appConfig.oneTimeLogin.loginUrl;
                this.remedusInfoMail = this.appConfig.remedusInfoMail;
                this.remedusPhone = this.appConfig.remedusPhone;
                var oneTimeKey = this.$transition$.params().key;
                this.oneTimeLoginApi
                    .login(oneTimeKey)
                    .then(function (result) {
                    if (result.PreferredApplicationProfile) {
                        _this.storageSvc.store('preferredApplicationProfile', result.PreferredApplicationProfile, false);
                    }
                    if (result.RedirectUrl) {
                        _this.$window.location.href = result.RedirectUrl;
                    }
                    else {
                        _this.error = result.Reason;
                    }
                })
                    .catch(function (error) {
                    _this.toaster.error(error);
                });
            };
            return OneTimeLoginController;
        }());
        OneTimeLogin.remeCareOneTimeLoginModule.component('rcOneTimeLogin', {
            bindings: {
                $transition$: '<',
            },
            controller: OneTimeLoginController,
            templateUrl: 'views/oneTimeLogin/oneTimeLogin.html',
        });
    })(OneTimeLogin = RemeCare.OneTimeLogin || (RemeCare.OneTimeLogin = {}));
})(RemeCare || (RemeCare = {}));
