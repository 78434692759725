module RemeCare.Management {

    import CareProviderRelation = Contract.Party.Read.ICareProviderRelation;

    interface IHealthCareProfessionalScope extends Shared.Framework.IBaseScope {
        person: Model.Person;
        currentRelations: Array<CareProviderRelation>;
        oldRelations: Array<CareProviderRelation>;
    }

    class HealthCareProfessionalController extends Shared.Framework.ControllerBase<IHealthCareProfessionalScope> {

        static $inject = ['$scope', '$translate', 'toaster'];
        constructor(
            $scope: IHealthCareProfessionalScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {
            this.$scope.currentRelations = this.$scope.person.getCurrentRelations();
            this.$scope.oldRelations = this.$scope.person.getOldRelations();
        }
    }

    remeCareManagementModule.controller('healthCareProfessionalCtrl', HealthCareProfessionalController);
}