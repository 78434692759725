var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var HealthCareParty;
        (function (HealthCareParty) {
            var ActorRole = /** @class */ (function () {
                function ActorRole() {
                }
                ActorRole.treatingHospital = 'DC90B5D8-9A9F-476A-B875-8D2A60D558EE';
                return ActorRole;
            }());
            HealthCareParty.ActorRole = ActorRole;
        })(HealthCareParty = Contract.HealthCareParty || (Contract.HealthCareParty = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
