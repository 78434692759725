/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    interface ISignatureScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.StringDefinition;
        careRequestSignature: CareRequestSignature;
        signatureUpdated: (newModel: string) => void;
    }

    class SignatureController extends CareRequestPartControllerBase<ISignatureScope> {
        constructor(
            $scope: ISignatureScope,
            $translate: ng.translate.ITranslateService,
            toaster: Shared.Framework.Toaster,
            careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
            $scope.signatureUpdated = (newModel: string) => ($scope.careRequestSignature.signatureImage = newModel);
        }

        public $onInit(): void {
            this.$scope.careRequestSignature = _.find(
                this.$scope.careRequest.careRequestSignatures,
                (t) => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity
            );
            if (this.$scope.careRequestSignature == null) {
                this.$scope.careRequestSignature = new CareRequestSignature();
                this.$scope.careRequestSignature.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.$scope.careRequest.careRequestSignatures.push(this.$scope.careRequestSignature);
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(
                this.$scope.careRequestPart,
                this.$scope.careRequestTemplate,
                this.$scope.careRequest
            );
        }
    }

    class SignatureDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'careRequestSvc',
            ($scope, $translate, toaster, careRequestSvc) =>
                new SignatureController($scope, $translate, toaster, careRequestSvc),
        ];

        public templateUrl = 'views/careRequest/directive/signature.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestSignature', () => new SignatureDirective());
}
