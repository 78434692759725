module RemeCare.Patient {

    export interface IMedicationOverviewScope extends Shared.Framework.IBaseScope {
        patientId: Shared.Contract.Guid;
    }

    class MedicationOverviewController extends Shared.Framework.ControllerBase<IMedicationOverviewScope> {

        constructor(
            $scope: IMedicationOverviewScope,
            $translate,
            toaster,
            private $stateParams
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit() {
            this.$scope.patientId = this.$stateParams.patientId;
        }
    }

    angular.module('RemeCare.Patient').controller('medicationOverviewCtrl', (
        $scope: IMedicationOverviewScope,
        $translate,
        toaster,
        $stateParams
    ) => new MedicationOverviewController(
        $scope,
        $translate,
        toaster,
        $stateParams
    ));
}