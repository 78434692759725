module RemeCare.Core.Services {

    export class FileIconService
    {
        getFileIcon(extension: string): string {
            switch (extension.trim().toLowerCase()) {
                case 'doc':
                case 'docx':
                    return 'file-word-o';
                case 'ppt':
                case 'pptx':
                    return 'file-powerpoint-o';
                case 'xls':
                case 'xlsx':
                    return 'file-excel-o';
                case 'txt':
                    return 'file-text-o';
                case 'pdf':
                    return 'file-pdf-o';
                case 'zip':
                    return 'file-archive-o';
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'png':
                    return 'file-image-o';
                case 'eml':
                    return 'envelope-o';
                case 'ehealth':
                    return 'lock';
                default:
                    return 'file-o';
            }
        }
    }

    remeCareAppModule.service('fileIconSvc', FileIconService);
}