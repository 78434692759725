/// <reference path="componentDirectiveBase.ts" />
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var ProvideInformationObjectController = /** @class */ (function (_super) {
            __extends(ProvideInformationObjectController, _super);
            // @ngInject
            function ProvideInformationObjectController($scope, baseUrl, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.baseUrl = baseUrl;
                $scope.getDocumentUrl = function (component) { return _this.getDocumentUrl(component); };
                return _this;
            }
            ProvideInformationObjectController.prototype.getDocumentUrl = function (component) {
                return this.baseUrl + 'Documents/' + component.documentId + '/' + component.name.replace(/[/\\*&=+%]/g, '');
            };
            ProvideInformationObjectController.prototype.init = function () { };
            return ProvideInformationObjectController;
        }(CarePlanAction.ComponentControllerBase));
        var ProvideInformationObjectDirective = /** @class */ (function (_super) {
            __extends(ProvideInformationObjectDirective, _super);
            function ProvideInformationObjectDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/provideInformationObject.html';
                _this.controller = ProvideInformationObjectController;
                return _this;
            }
            return ProvideInformationObjectDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcProvideInformationObject', function () { return new ProvideInformationObjectDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
