'use strict';
var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var AnswerParameters = /** @class */ (function () {
                function AnswerParameters() {
                }
                return AnswerParameters;
            }());
            Core.AnswerParameters = AnswerParameters;
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
