var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientService = /** @class */ (function (_super) {
            __extends(PatientService, _super);
            function PatientService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            PatientService.prototype.getPatient = function (patientId) {
                return this.get("Patients/" + patientId);
            };
            /** @deprecated */
            PatientService.prototype.getContactDetails = function (patientId) {
                return this.get("Patients/" + patientId + "/Contact");
            };
            PatientService.prototype.getContactDetailsAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Contact");
            };
            /** @deprecated */
            PatientService.prototype.getCarePlans = function (patientId, query) {
                return this.get("Patients/" + patientId + "/CarePlans", query);
            };
            PatientService.prototype.getCarePlansAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/CarePlans", query);
            };
            PatientService.prototype.getCareTeamAsync = function (patientId, partyType, query) {
                var spinnerSuffix = partyType === RemeCare.Shared.Contract.Code.PartyType.Person ? 'Person' : 'Organisation';
                return this.getPromise("Patients/" + patientId + "/CareTeam/" + partyType, query, "careTeam" + spinnerSuffix);
            };
            PatientService.prototype.getInformals = function (patientId, query) {
                return this.get("Patients/" + patientId + "/Informals", query, 'informals');
            };
            PatientService.prototype.getContactPersonsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/ContactPersons", query, 'contactPersons');
            };
            PatientService.prototype.getContactPersonAsync = function (patientId, contactPersonId, query) {
                return this.getPromise("Patients/" + patientId + "/ContactPersons/" + contactPersonId, query, 'contactPersons');
            };
            PatientService.prototype.getPatientPrescriptionsAsync = function (patientId, query) {
                var url = "Patients/" + patientId + "/Prescriptions";
                return this.getPromise(url, query, 'prescriptions');
            };
            /** @deprecated */
            PatientService.prototype.tokenizeMedicationSchema = function (schema) {
                var url = 'Tokenization/MedicationSchema';
                return this.post(url, schema);
            };
            PatientService.prototype.tokenizeMedicationSchemaAsync = function (schema) {
                var url = 'Tokenization/MedicationSchema';
                return this.postPromise(url, schema);
            };
            PatientService.prototype.savePatientPrescriptionAsync = function (prescription) {
                var url = "Patients/" + prescription.patientId + "/Prescriptions";
                return this.postPromise(url, prescription, 'prescription');
            };
            PatientService.prototype.updatePatientPrescriptionAsync = function (prescription) {
                var url = "Patients/" + prescription.patientId + "/Prescriptions/" + prescription.id;
                return this.putPromise(url, prescription, 'prescription');
            };
            PatientService.prototype.deletePatientPrescriptionAsync = function (patientId, prescriptionId) {
                var url = "Patients/" + patientId + "/Prescriptions/" + prescriptionId;
                return this.deletePromise(url, 'prescription');
            };
            PatientService.prototype.getMonitoringConfigurationAsync = function (patientId, query) {
                var url = "Patients/" + patientId + "/MonitoringParts";
                return this.getPromise(url, query, 'patient.monitoringParts');
            };
            PatientService.prototype.getPersonsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/Persons", query);
            };
            PatientService.prototype.getActionExecutorsAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Actions/Executors");
            };
            /** @deprecated */
            PatientService.prototype.getStructuralTherapyActions = function (patientId, query) {
                return this.get("Patients/" + patientId + "/StructuralTherapyActions", query);
            };
            PatientService.prototype.getStructuralTherapyActionsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/StructuralTherapyActions", query);
            };
            PatientService.prototype.getTherapyActionPartParametersAsync = function (patientId, therapyId) {
                var params = {
                    therapyId: therapyId,
                };
                return this.getPromise("Patients/" + patientId + "/TherapyActionPartParameters", params);
            };
            PatientService.prototype.getRegistrationValuesAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/RegistrationValues", query, 'registrationvalues');
            };
            PatientService.prototype.getAlarmDefinitionsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/AlarmDefinitions", query);
            };
            PatientService.prototype.getAlarmsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/Alarms", query, 'alarms');
            };
            PatientService.prototype.getPatientNameAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Name");
            };
            PatientService.prototype.findPartyRoles = function (query) {
                return this.get('Party/PartyRoles', query);
            };
            PatientService.prototype.getProfileRightsForPatientAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/ProfileRights");
            };
            PatientService.prototype.saveAlarmHandlingAsync = function (patientId, alarmId) {
                return this.postPromise("Patients/" + patientId + "/Alarms/" + alarmId + "/Handled", {});
            };
            /** @deprecated */
            PatientService.prototype.getTherapyBusinessContextRights = function (patientId, query) {
                return this.get("Patients/" + patientId + "/TherapyBusinessContextRights", query, 'rights');
            };
            PatientService.prototype.getTherapyBusinessContextRightsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/TherapyBusinessContextRights", query, 'rights');
            };
            PatientService.prototype.getPatientFileHospitalisationsAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Hospitalisations");
            };
            PatientService.prototype.getHospitalisationReasons = function (patientId) {
                return this.get("Patients/" + patientId + "/HospitalisationReasons");
            };
            PatientService.prototype.getPatientFileConsultationsAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Consultations");
            };
            PatientService.prototype.savePatientFileHospitalisation = function (patientId, hospitalisation) {
                return this.post("Patients/" + patientId + "/Hospitalisations", hospitalisation);
            };
            PatientService.prototype.updatePatientFileHospitalisation = function (patientId, hospitalisationId, hospitalisation) {
                return this.put("Patients/" + patientId + "/Hospitalisations/" + hospitalisationId, hospitalisation);
            };
            PatientService.prototype.deletePatientFileHospitalisationAsync = function (patientId, hospitalisationId) {
                return this.deletePromise("Patients/" + patientId + "/Hospitalisations/" + hospitalisationId);
            };
            PatientService.prototype.savePatientFileConsultationAsync = function (patientId, consultation) {
                return this.postPromise("Patients/" + patientId + "/Consultations", consultation, 'saveConsultation');
            };
            PatientService.prototype.updatePatientFileConsultationAsync = function (patientId, consultationId, consultation) {
                return this.putPromise("Patients/" + patientId + "/Consultations/" + consultationId, consultation, 'saveConsultation');
            };
            PatientService.prototype.deletePatientFileConsultationAsync = function (patientId, consultationId) {
                return this.deletePromise("Patients/" + patientId + "/Consultations/" + consultationId);
            };
            PatientService.prototype.getNonPlannedActionTemplatesAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/NonPlannedActionTemplates", null, 'actiontemplates');
            };
            PatientService.prototype.registerNonPlannedRegistration = function (patientId, templateId, registration) {
                return this.post("Patients/" + patientId + "/NonPlannedActionTemplates/" + templateId + "/NonPlannedRegistrations", registration, 'actions');
            };
            PatientService.prototype.getAnamnesisInfos = function (patientId) {
                return this.get("Patients/" + patientId + "/AnamnesisInfos");
            };
            PatientService.prototype.saveAnamnesisInfo = function (patientId, anamnesisInfo) {
                return this.post("Patients/" + patientId + "/AnamnesisInfos", anamnesisInfo);
            };
            PatientService.prototype.updateAnamnesisInfo = function (patientId, anamnesisInfoId, anamnesisInfo) {
                return this.put("Patients/" + patientId + "/AnamnesisInfos/" + anamnesisInfoId, anamnesisInfo);
            };
            PatientService.prototype.deleteAnamnesisInfo = function (patientId, anamnesisInfoId) {
                return this.delete("Patients/" + patientId + "/AnamnesisInfos/" + anamnesisInfoId);
            };
            PatientService.prototype.getPatientFileSufferedDiseases = function (patientId) {
                return this.get("Patients/" + patientId + "/SufferedDiseases");
            };
            PatientService.prototype.savePatientFileSufferedDisease = function (patientId, sufferedDisease) {
                return this.post("Patients/" + patientId + "/SufferedDiseases", sufferedDisease);
            };
            PatientService.prototype.deletePatientFileSufferedDisease = function (patientId, diseaseId) {
                return this.delete("Patients/" + patientId + "/SufferedDiseases/" + diseaseId);
            };
            PatientService.prototype.getPossibleDiseases = function (patientId) {
                return this.get("Patients/" + patientId + "/PossibleDiseases");
            };
            PatientService.prototype.saveInternalRemark = function (patientId, remark) {
                return this.put("Patients/" + patientId + "/InternalRemark", {
                    remark: remark,
                });
            };
            PatientService.prototype.createMutuality = function (patientId, mutality) {
                return this.post("Patients/" + patientId + "/Mutualities", mutality);
            };
            PatientService.prototype.changeMutalityValidity = function (patientId, id, newValidFrom, newValidUntil) {
                var newFrom = RemeCare.Shared.DateHelper.toServerDateString(newValidFrom);
                var newUntil = RemeCare.Shared.DateHelper.toServerDateString(newValidUntil);
                return this.post("Patients/" + patientId + "/Mutualities/" + id, {
                    newValidFromDate: newFrom,
                    newValidUntilDate: newUntil,
                });
            };
            PatientService.prototype.setExternalReference = function (patientId, externalReference) {
                return this.post("Patients/" + patientId + "/ExternalReferences", externalReference);
            };
            PatientService.prototype.assignActorRole = function (patientId, therapyId, id, actorRoleId, partyRoleId, validFromDate, validUntilDate) {
                var url = "Patients/" + patientId + "/Therapies/" + therapyId + "/ActorRoleAssignments";
                if (id != null) {
                    url += "/" + id;
                }
                return this.post(url, {
                    actorRoleId: actorRoleId,
                    partyRoleId: partyRoleId,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(validFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(validUntilDate),
                }, 'actorrole.assign');
            };
            PatientService.prototype.getPossibleActorRoles = function (patientId, excludeHealthCareNonProfessional) {
                return this.get("Patients/" + patientId + "/PossibleActorRoleAssignments?excludeHealthCareNonProfessional=" + excludeHealthCareNonProfessional);
            };
            PatientService.prototype.removeActorRoleAssignmentAsync = function (patientId, therapyId, id) {
                return this.deletePromise("Patients/" + patientId + "/Therapies/" + therapyId + "/ActorRoleAssignments/" + id);
            };
            PatientService.prototype.registerPatientAsync = function (patient) {
                return this.postPromise('Patients', patient, 'patient.create');
            };
            PatientService.prototype.getDocumentAsync = function (patientId, documentId) {
                return this.getPromise("Patients/" + patientId + "/Documents/" + documentId, null, 'patient.documents');
            };
            PatientService.prototype.findFollowUpDocumentsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/FollowUpDocuments", query, 'patient.followUpDocuments');
            };
            PatientService.prototype.findDocumentsAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/Documents", query, 'patient.documents');
            };
            PatientService.prototype.saveDocumentAsync = function (patientId, documentId, patientFileDocument) {
                return this.putPromise("Patients/" + patientId + "/Documents/" + documentId, patientFileDocument, 'patient.documents.save');
            };
            PatientService.prototype.findCarePlanMediaAsync = function (patientId, query) {
                return this.getPromise("Patients/" + patientId + "/CarePlanMedia", query, 'patient.media');
            };
            PatientService.prototype.deleteDocumentAsync = function (patientId, documentId) {
                return this.deletePromise("Patients/" + patientId + "/Documents/" + documentId, 'patient.documents.delete');
            };
            PatientService.prototype.getTherapyComplianceGraphAsync = function (patientId, structuralTherapyActionIds, fromDate, untilDate) {
                return this.getPromise("Patients/" + patientId + "/TherapyComplianceGraphs", {
                    structuralTherapyActionIds: structuralTherapyActionIds,
                    fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                });
            };
            PatientService.prototype.getMedicationLevelGraphsAsync = function (patientId, medicationFilters, fromDate, untilDate) {
                var params = {
                    medicationFilters: medicationFilters,
                    fromDate: RemeCare.Shared.DateHelper.toServerDateString(fromDate),
                    untilDate: RemeCare.Shared.DateHelper.toServerDateString(untilDate),
                };
                return this.getPromise("Patients/" + patientId + "/MedicationLevelGraphs", params);
            };
            PatientService.prototype.getConnectionsAsync = function (patientId) {
                return this.getPromise("Patients/" + patientId + "/Connections");
            };
            PatientService.prototype.deleteConnectionAsync = function (patientId, connectionId) {
                return this.deletePromise("Patients/" + patientId + "/Connections/" + connectionId, 'fitbit-connection');
            };
            PatientService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return PatientService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        Patient.PatientService = PatientService;
        Patient.remeCarePatientModule.service('patientSvc', PatientService);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
