module RemeCare.Management {

    class PersonPatientController implements ng.IComponentController {
        person: Model.Person;
        onPatientCreated: () => any;

        constructor(
            private $dialog: Shared.Service.DialogService,
            private $translate: ng.translate.ITranslateService,
            private toaster: Shared.Framework.Toaster,
            private partyApiSvc: Core.Services.PartyApiService) { }

        public $onInit(): void {}

        createPatient(): void {
            this.$dialog.confirmBox('Patient.AddAsPatient',
                'Patient.AddAsPatientWarning',
                () => {
                    this.partyApiSvc.createPatient(this.person.partyId)
                        .success(() => {
                            this.toaster.success(this.$translate.instant('Patient.PatientCreated'));
                            if (this.onPatientCreated) {
                                this.onPatientCreated();
                            }
                        })
                        .error(e => this.toaster.error(e));
                });
        }
    }

    remeCareManagementModule.component('rcPersonPatient',
    {
        controller: PersonPatientController,
        controllerAs: 'patientCtrl',
        bindings: {
            person: '<',
            onPatientCreated: '&?'
        },
        templateUrl: 'views/management/persons/patientFile.html'
    });
}