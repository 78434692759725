namespace RemeCare.Patient.Model {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    export class ActionTemplate extends RemeCare.Model.ActionBase {
        public therapy: EntityTranslation;
        public carePlanId: Guid;
        public duplicates: Guid[];
        public hasTemporarySave: boolean;

        constructor(
            authservice: Shared.Framework.AuthService,
            patientId: Guid,
            serverObject?: Contract.Patient.Read.IActionTemplate
        ) {
            super(authservice, serverObject && serverObject.Id, serverObject);
            this.patientId = patientId;
            if (serverObject != null) {
                this.therapy = serverObject.Therapy;
                this.carePlanId = serverObject.CarePlanId;
                this.duplicates = serverObject.Duplicates;
                this.hasTemporarySave = serverObject.HasTemporarySave;
            }
        }

        public isActiveComponent(componentId: Guid): boolean {
            const componentsWithConditions = _(this.components).filter(
                (c) =>
                    c.actionTypeCode === Shared.Contract.Code.ActionType.QualitativeObservation &&
                    _((c as CarePlanAction.QualitativeObservationDescriptor).answerConditions).any(
                        (a) => a.GoToId === componentId
                    )
            ) as CarePlanAction.QualitativeObservationDescriptor[];

            if (componentsWithConditions.length === 0) {
                return true;
            }

            return _(componentsWithConditions).any(
                (c) =>
                    _.find(
                        c.answerConditions,
                        (a) => a.GoToId === componentId && _(c.value).contains(a.AnswerCodeSetItem.Id)
                    ) != null
            );
        }

        public toServerWrite(isPermanent: boolean): Contract.CarePlanAction.Write.IMakeAdHocRegistration {
            const now = new Date();

            const adHocAdministration = _(this.components)
                .chain()
                .filter(
                    (c) =>
                        c.actionTypeCode === Shared.Contract.Code.ActionType.Administration &&
                        (c as CarePlanAction.AdministrationDescriptor).medicationDoses.length > 1
                )
                .first()
                .value() as CarePlanAction.AdministrationDescriptor;

            let registrationValues = [];
            _(this.components).forEach((c) => {
                if (this.isActiveComponent(c.id)) {
                    const values = c.createRegistration(now, this);
                    registrationValues = registrationValues.concat(values);
                }
            });

            const result = {
                eventDateTimeFrom: this.includesEventDate
                    ? Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeFrom())
                    : null,
                eventDateTimeUntil:
                    this.includesEventDate && this.isEventDateTimeRange
                        ? Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeUntil())
                        : null,
                registrationValues: registrationValues,
                personalisedTherapyActionId:
                    adHocAdministration != null ? adHocAdministration.personalisedTherapyActionId : null,
                patientId: this.patientId,
                duplicates: this.duplicates,
                isPermanent: isPermanent,
            } as Contract.CarePlanAction.Write.IMakeAdHocRegistration;

            return result;
        }
    }
}
