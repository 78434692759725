module RemeCare.CareRequest {
    import Toaster = Shared.Framework.Toaster;
    import Person = Contract.Party.Read.IPerson;
    import SearchResult = Shared.Contract.ISearchResult;
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;

    export interface IAcceptanceControllerScope extends Shared.Framework.IBaseScope {
        careRequestPart: Shared.Framework.Model.CareRequestPart;
        personSearchGrid: Shared.Framework.Grid.Grid<Contract.Party.Read.IPerson>;
        searchOpen: boolean;
        selectedPerson: RequestPerson;
        copyToSearch(src: string, dest: string): void;
        updateField(field: string | Array<string>): void;
        openSearch(): void;
        personSelected(person: Person): void;
        atLeastOneNumber(): boolean;
        isPresent(controlType: CareRequestUIControlType): boolean;
        isRequired(controlType: CareRequestUIControlType): boolean;
    }

    export class AcceptanceControllerBase<T extends IAcceptanceControllerScope> extends Shared.Framework.ControllerBase<T> {

        constructor(
            $scope: T,
            $translate,
            toaster: Toaster,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected partyApiSvc: Core.Services.PartyApiService) {
            super($scope, $translate, toaster);

            this.gridBuilderSvc = gridBuilderSvc;
            this.$scope.openSearch = () => this.openSearch();
            this.$scope.isPresent = ct => this.isPresent(ct);
            this.$scope.isRequired = ct => this.isRequired(ct);
        }

        public $onInit(): void {
            this.buildSearchGrid();
        }
        
        private isPresent(controlType: CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id !== Shared.Contract.Code.ModalityType.Not;
        }

        private isRequired(controlType: CareRequestUIControlType): boolean {
            var control = _.find(this.$scope.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) return false;
            else return control.modalityType.Id === Shared.Contract.Code.ModalityType.Mandatory;
        }
        
        protected atLeastOneNumber(requestPerson: RequestPerson, type: CareRequestUIControlType): boolean {
            if (!this.$scope.isRequired(type)) return true;
            return (requestPerson.contactInfo.telephone.isFilled() ||
                requestPerson.contactInfo.mobile.isFilled());
        }

        private buildSearchGrid(): void {
            this.$scope.personSearchGrid = this.gridBuilderSvc
                .createGridBuilder<Person>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.searchPersons(page, pageSize, sortField, sortDirection, criteria))
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date:"shortDate"' })
                .addSelectButtonColumn(s => this.$scope.personSelected(s))
                .setRowDetailsTemplate('views/careRequest/directive/patientAcceptance/personDetails.html', 265)
                .build();
        }

        private searchPersons(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: any):
            JQueryPromise<SearchResult<Person>> {
            var deferred = jQuery.Deferred<SearchResult<Person>>();
            var query = <Contract.Party.Read.Query.IFindPersonsQuery> {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                identificationNumber: criteria.identificationNumber,
                birthDate: Shared.DateHelper.toServerDateString(criteria.birthDate),
                communicationLanguage: criteria.communicationLanguage,
                gender: criteria.gender,
                address: criteria.address,
                zipCode: criteria.zipCode,
                city: criteria.city,
                country: criteria.country,
                nationality: criteria.nationality,
                email: criteria.email
            }
            this.partyApiSvc.findPersons(query)
                .success(r => deferred.resolve(r))
                .error(e => deferred.reject(e));
            return deferred.promise();
        }

        protected copyToSearch(src: string, dest: string, obj: any): void {
            var value = this.getDescendantProp(obj, src);
            this.$scope.personSearchGrid.searchCriteria[dest] = angular.copy(value);
        }

        private getDescendantProp(obj, desc): any {
            var arr = desc.split('.');
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }

        private setDescendantProp(obj, desc, val): void {
            var arr = desc.split('.');
            while (arr.length > 1 && (obj = obj[arr.shift()]));
            obj[arr.shift()] = val;
        }
        
        private openSearch(): void {
            this.$scope.searchOpen = true;
        }

        protected updateField(field: string | Array<string>, requestPerson: RequestPerson): void {
            if (_.isArray(field)) {
                _(field).forEach(f => this.updateField(f, requestPerson));
                return;
            }

            var srcField = this.getDescendantProp(requestPerson, field);
            this.setDescendantProp(this.$scope.selectedPerson, field, srcField);
            switch (field) {
                case 'lastName':
                    requestPerson.update.hasUpdatedLastName = true;
                    break;
                case 'firstName':
                    requestPerson.update.hasUpdatedFirstName = true;
                    break;
                case 'identificationNumber':
                    requestPerson.update.hasUpdatedIdentificationNumber = true;
                    break;
                case 'birthDate':
                    requestPerson.update.hasUpdatedBirthDate = true;
                    break;
                case 'communicationLanguage':
                    requestPerson.update.hasUpdatedCommunicationLanguage = true;
                    break;
                case 'gender':
                    requestPerson.update.hasUpdatedGender = true;
                    break;
                case 'contactInfo.address.addressLine1':
                    requestPerson.contactInfo.update.hasUpdatedAddress = true;
                    break;
                case 'contactInfo.address.zipCode':
                    requestPerson.contactInfo.update.hasUpdatedPostalCode = true;
                    break;
                case 'contactInfo.address.city':
                    requestPerson.contactInfo.update.hasUpdatedCity = true;
                    break;
                case 'contactInfo.address.country':
                    requestPerson.contactInfo.update.hasUpdatedCountry = true;
                    break;
                case 'nationality':
                    requestPerson.update.hasUpdatedNationality = true;
                    break;
                case 'contactInfo.telephone.countryPrefix':
                    requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                    break;
                case 'contactInfo.telephone.areaCode':
                    requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                    break;
                case 'contactInfo.telephone.localNumber':
                    requestPerson.contactInfo.update.hasUpdatedTelephone = true;
                    break;
                case 'contactInfo.mobile.countryPrefix':
                    requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                    break;
                case 'contactInfo.mobile.areaCode':
                    requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                    break;
                case 'contactInfo.mobile.localNumber':
                    requestPerson.contactInfo.update.hasUpdatedMobilePhone = true;
                    break;
                case 'contactInfo.email':
                    requestPerson.contactInfo.update.hasUpdatedEmail = true;
                    break;
                case 'maritalStatus':
                    requestPerson.update.hasUpdatedMaritalStatus = true;
                    break;
                case 'technologyAffinities':
                    requestPerson.update.hasUpdatedTechnologyAffinity = true;
                    break;
                case 'educationLevel':
                    requestPerson.update.hasUpdatedEducationLevel = true;
                    break;
            }
        }
    }
}
