namespace RemeCare.Patient {

    import MonitoringPart = Shared.Framework.Model.MonitoringPart;
    import MonitoringPartChart = Shared.Framework.Model.MonitoringPartChart;
    import MonitoringPartActionTable = Shared.Framework.Model.MonitoringPartActionTable;
    import MonitoringPartActionTimeLine = Shared.Framework.Model.MonitoringPartActionTimeLine;
    import MonitoringPartSource = Shared.Framework.Model.MonitoringPartSource;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import MonitoringPartTable = Shared.Framework.Model.MonitoringPartTable;
    import MonitoringPartTimeLine = Shared.Framework.Model.MonitoringPartTimeLine;
    import MonitoringPartType = Shared.Contract.Code.MonitoringPartType;
    import PartWidth = Shared.Contract.Code.PartWidth;

    export class MonitoringPartMergeService {

        private merger: Merger;

        constructor() {
            this.merger = new LineChartNumericMerger()
                .addMerger(new TableNumericQualitativeMerger())
                .addMerger(new CumulativeBarChartMerger())
                .addMerger(new FloatingBarChartMerger())
                .addMerger(new ColourQualitativeTimeLineMerger())
                .addMerger(new MedicationAdherenceChartMerger())
                .addMerger(new MedicationDoseChartMerger())
                .addMerger(new ActionTableMerger())
                .addMerger(new ActionTimeLineMerger())
                .addMerger(new CumulativeObjectiveChartMerger())
                .addMerger(new ObjectiveScoreTableMerger())
                .addMerger(new LineChartNumericExternalMerger())
                .addMerger(new BoxplotMerger());
        }

        public mergeMonitoringParts(monitoringParts: ICarePlanMonitoringPart[]): ICarePlanMonitoringPart[] {
            let toBeMerged = monitoringParts.slice();
            const merged = [] as ICarePlanMonitoringPart[];
            for (let i = 0; i < toBeMerged.length; i++) {
                const target = toBeMerged[i];
                const mergeCandidates = toBeMerged.slice(i + 1);
                if (target.monitoringPart.isMergeable) {
                    for (let j = 0; j < mergeCandidates.length; j++) {
                        if (this.tryMerge(target, mergeCandidates[j])) {
                            toBeMerged.splice(i + j + 1, 1);
                        }
                    }
                }
                merged.push(target);
            }
            return merged;
        }

        private tryMerge(target: ICarePlanMonitoringPart, source: ICarePlanMonitoringPart): boolean {
            const merged = this.merger.tryMerge(target.monitoringPart, source.monitoringPart);
            if (merged) {
                _.each(source.carePlanIds, (carePlanId) => {
                    if (!_.contains(target.carePlanIds, carePlanId)) {
                        target.carePlanIds.push(carePlanId);
                    }
                });
            }
            return merged;
        }
    }

    remeCarePatientModule.service('monitoringPartMergeSvc', MonitoringPartMergeService);

    abstract class Merger {

        private next: Merger;

        public addMerger(merger: Merger): Merger {
            if (this.next) {
                this.next.addMerger(merger);
            } else {
                this.next = merger;
            }
            return this;
        }

        public tryMerge(target: MonitoringPart, source: MonitoringPart): boolean {
            if (!target.isMergeable
                || !source.isMergeable
                || target.type !== source.type) {
                return false;
            }

            if (this.canHandle(target)) {
                if (!this.canMerge(target, source)) {
                    return false;
                }

                this.mergeMonitoringPartSources(target, source);

                // take longest name of target and source
                if (target.name && source.name && target.name.length < source.name.length
                    || !target.name && source.name) {
                    target.name = source.name;
                }

                // take largest width of target and source
                if (target.width === PartWidth.Third) {
                    target.width = source.width;
                } else if (target.width === PartWidth.Half && source.width !== PartWidth.Third) {
                    target.width = source.width;
                } else if (target.width === PartWidth.TwoThird && source.width === PartWidth.Full) {
                    target.width = source.width;
                }

                return true;
            }

            if (this.next) {
                return this.next.tryMerge(target, source);
            }

            return false;
        }

        protected abstract canHandle(target: MonitoringPart): boolean;

        protected abstract canMerge(target: MonitoringPart, source: MonitoringPart): boolean;

        protected anyMonitoringPartSourcesEqual(target: MonitoringPart, source: MonitoringPart): boolean {
            return _.any(target.monitoringPartSources,
                (targetMonitoringPartSource) => {
                    return _.any(source.monitoringPartSources,
                        (sourceMonitoringPartSource) => {
                            return targetMonitoringPartSource.isEqual(sourceMonitoringPartSource);
                        });
                });
        }

        protected mergeMonitoringPartSources(target: MonitoringPart, source: MonitoringPart): void {
            _.each(source.monitoringPartSources,
                (sourceMonitoringPartSource) => {
                    if (!_.any(target.monitoringPartSources,
                        (targetMonitoringPartSource) => {
                            return targetMonitoringPartSource.isEqual(sourceMonitoringPartSource);
                        })) {
                        target.monitoringPartSources.push(sourceMonitoringPartSource);
                    }
                });
        }
    }

    class LineChartNumericMerger extends Merger {

        protected canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.LineChartNumeric;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            return target.isPeriodConfigEqual(source)
                && target.isAxisConfigEqual(source)
                && this.anyMonitoringPartSourcesEqual(target, source);
        }
    }

    class TableNumericQualitativeMerger extends Merger {

        protected canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.TableNumericQualitative;
        }

        protected canMerge(target: MonitoringPartTable, source: MonitoringPartTable): boolean {
            return target.isPeriodConfigEqual(source)
                && target.maxRows === source.maxRows
                && this.anyMonitoringPartSourcesEqual(target, source);
        }
    }

    class CumulativeBarChartMerger extends Merger {

        protected canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.CumulativeBarChart;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            return target.isPeriodConfigEqual(source)
                && target.isAxisConfigEqual(source)
                && this.anyMonitoringPartSourcesEqual(target, source);
        }
    }

    class FloatingBarChartMerger extends Merger {

        protected canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.FloatingBarChart;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            return target.isPeriodConfigEqual(source)
                && target.isAxisConfigEqual(source)
                && this.areGraphPointSourcesEqual(target, source);
        }

        private areGraphPointSourcesEqual(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            const targetParameters = this.getQuantitativeMeasuringPointParameter(target);
            const sourceParameters = this.getQuantitativeMeasuringPointParameter(source);

            for (let i = 0; i < targetParameters.length; i++) {
                if (!targetParameters[i].isEqual(sourceParameters[i])) {
                    return false;
                }
            }
            return true;
        }

        private getQuantitativeMeasuringPointParameter(chart: MonitoringPartChart): MonitoringPartSource[] {
            return _.chain(chart.monitoringPartSources)
                .filter(mps => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter)
                .sortBy(mps => (mps as MonitoringPartSourceParameter).sequence)
                .value();
        }
    }

    class ColourQualitativeTimeLineMerger extends Merger {

        protected canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.ColourQualitativeTimeLine;
        }

        protected canMerge(target: MonitoringPartTimeLine, source: MonitoringPartTimeLine): boolean {
            return target.isPeriodConfigEqual(source)
                && this.anyMonitoringPartSourcesEqual(target, source);
        }
    }

    class MedicationAdherenceChartMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.MedicationAdherenceChart;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            // always merge
            return true;
        }
    }

    class MedicationDoseChartMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.MedicationDoseChart;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            // always merge
            return true;
        }
    }

    class ActionTableMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.ActionTable;
        }

        protected canMerge(target: MonitoringPartActionTable, source: MonitoringPartActionTable): boolean {
            // never merge
            return false;
        }
    }

    class ActionTimeLineMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.ActionTimeLine;
        }

        protected canMerge(target: MonitoringPartActionTimeLine, source: MonitoringPartActionTimeLine): boolean {
            // never merge
            return false;
        }
    }

    class CumulativeObjectiveChartMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.CumulativeObjectiveChart;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            // never merge
            return false;
        }
    }

    class ObjectiveScoreTableMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.ObjectiveScoreTable;
        }

        protected canMerge(target: MonitoringPartTable, source: MonitoringPartTable): boolean {
            // never merge
            return false;
        }
    }

    class LineChartNumericExternalMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.LineChartNumericExternal;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            return target.isPeriodConfigEqual(source)
                && target.isAxisConfigEqual(source)
                && this.anyMonitoringPartSourcesEqual(target, source);
        }
    }

    class BoxplotMerger extends Merger {

        public canHandle(target: MonitoringPart): boolean {
            return target.type === MonitoringPartType.Boxplot;
        }

        protected canMerge(target: MonitoringPartChart, source: MonitoringPartChart): boolean {
            const targetParameters = this.getQuantitativeMeasuringPointParameter(target);
            const sourceParameters = this.getQuantitativeMeasuringPointParameter(source);

            for (let i = 0; i < targetParameters.length; i++) {
                if (!targetParameters[i].isEqual(sourceParameters[i])) {
                    return false;
                }
            }
            return true;
        }

        private getQuantitativeMeasuringPointParameter(chart: MonitoringPartChart): MonitoringPartSource[] {
            return _.chain(chart.monitoringPartSources)
                .filter(mps => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter)
                .sortBy(mps => (mps as MonitoringPartSourceParameter).sequence)
                .value();
        }
    }
}