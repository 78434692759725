var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ExternalIdentityProvider = /** @class */ (function () {
            function ExternalIdentityProvider(serverObject) {
                if (serverObject) {
                    this.id = serverObject.Id;
                    this.name = serverObject.Name;
                    this.remeCareBaseUrl = serverObject.RemeCareBaseUrl;
                    this.icon = serverObject.Icon;
                }
            }
            ExternalIdentityProvider.prototype.toggleLinked = function () {
                this.linked = !this.linked;
            };
            return ExternalIdentityProvider;
        }());
        Management.ExternalIdentityProvider = ExternalIdentityProvider;
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
