/// <reference path="./carePlanDetailComponentControllerBase.ts"/>

namespace RemeCare.Patient {
    import CarePlanAnamnesis = Patient.Model.CarePlanAnamnesis;
    import GridBuilder = Shared.Framework.Grid.SearchGridBuilder;
    import VersionValidityChange = Shared.Framework.Model.VersionValidityChange;

    class CarePlanAnamnesisController extends CarePlanDetailComponentControllerBase<CarePlanAnamnesis> {
        public codeSetItems: Shared.Contract.IChoiceListOption[];

        constructor(
            protected $dialog: Shared.Service.DialogService,
            protected patientSvc: PatientService,
            protected toaster: Shared.Framework.Toaster,
            protected therapyApiSvc: Core.Services.TherapyApiService,
            protected authservice: Shared.Framework.AuthService,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($dialog, patientSvc, toaster, therapyApiSvc, authservice, gridBuilderSvc, modalBuilderFactory);
        }

        protected onPartRetrieved(): void {
            if (this.part.Type.Id === Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis) {
                this.title = this.part.QualitativeAnamnesis.QualitativeEntity.Text;
                this.getCodeSetItemsAsync(this.part.QualitativeAnamnesis.CodeSet.Id);
            } else {
                this.title = this.part.QuantitativeAnamnesis.QuantitativeCharacteristic.Name;
            }
        }

        protected addGridRows(gridBuilder: GridBuilder<CarePlanAnamnesis>): GridBuilder<CarePlanAnamnesis> {
            return gridBuilder.addColumn('getRegistrationValueString()', 'General.Value');
        }

        protected async setVersionGridData(): Promise<void> {
            try {
                const ags = await this.carePlanApiSvc.getCarePlanAnamnesesAsync(this.carePlanId, this.definitionId);
                const versions = _(ags).map(x => new CarePlanAnamnesis(x));
                this.versionsGrid.setData(
                    _(versions)
                        .sortBy(x => x.validFromDate)
                        // Order is important as the versionsGrid edit-button-row disable function depends on this
                        .reverse()
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected getAddController(): string {
            return 'addCarePlanAnamnesisModalCtrl';
        }

        protected getAddTemplateUrl(): string {
            return 'views/patient/carePlans/carePlanDetails/addCarePlanAnamnesisModal.html';
        }

        protected extendAddScope(scope: any, detail: CarePlanAnamnesis): void {
            scope.carePlanAnamnesis = angular.copy(detail) || new CarePlanAnamnesis();
            scope.codeSetItems = this.codeSetItems;
            scope.uiControlType = this.part.QualitativeAnamnesis ? this.part.QualitativeAnamnesis.UIControlType : null;
            scope.unit = this.part.QuantitativeAnamnesis ? this.part.QuantitativeAnamnesis.Unit : null;
            scope.decimals = this.part.QuantitativeAnamnesis ? this.part.QuantitativeAnamnesis.Decimals : null;
        }

        protected changeValidityAsync(versionValidityChange: VersionValidityChange): Promise<void> {
            return this.carePlanApiSvc.changeCarePlanAnamnesisValidityAsync(
                this.carePlanId,
                this.definitionId,
                versionValidityChange.toServerWrite()
            );
        }

        protected deleteAsync(fromDate: string): Promise<void> {
            return this.carePlanApiSvc.deleteCarePlanAnamnesisAsync(this.carePlanId, this.definitionId, fromDate);
        }

        private async getCodeSetItemsAsync(codeSetType: Shared.Contract.Guid): Promise<void> {
            try {
                this.codeSetItems = await this.masterdataSvc.getCodeSetItemsAsync(codeSetType);
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.component('carePlanAnamnesis', {
        controller: CarePlanAnamnesisController,
        bindings: {
            patientId: '@',
            carePlanId: '@',
            definitionId: '@',
        },
        templateUrl: 'views/patient/carePlans/carePlanDetails/carePlanDetail.html',
    });
}
