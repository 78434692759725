/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import QuantitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint;
    import QuantitativeReferencePoint = Contract.CarePlan.Read.Query.IQuantitativeReferencePoint;
    import ChartLine = Shared.Framework.Model.ChartLine;
    import ChartBar = Shared.Framework.Model.ChartBar;
    import IParameter = Contract.CarePlan.Read.IParameter;

    class FloatingBarGraphController extends ChartMonitoringPartController {
        private lowParameter: QuantitativeMeasuringPointParameter;
        private highParameter: QuantitativeMeasuringPointParameter;
        private quantitativeMeasuringPoints: QuantitativeMeasuringPoint[];
        private quantitativeReferencePoints: QuantitativeReferencePoint[];
        private showMultiplePerDay: boolean;

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.lowParameter = _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter((mps) => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter)
                .sortBy((p: QuantitativeMeasuringPointParameter) => p.sequence)
                .first()
                .value() as QuantitativeMeasuringPointParameter;
            this.highParameter = _(this.monitoringPart.monitoringPartSources)
                .chain()
                .filter((mps) => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter)
                .sortBy((p: QuantitativeMeasuringPointParameter) => p.sequence)
                .last()
                .value() as QuantitativeMeasuringPointParameter;
            this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
            this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();

            this.chartConfigs = [
                {
                    options: {
                        chart: {
                            animation: false,
                            type: 'line',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'x',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(false),
                            shared: true,
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: [],
                    title: {
                        text: null,
                    },
                } as HighChartsNGConfig,
            ];
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            const oneMonthEarlier = moment(this.dateInfo.untilDate);
            oneMonthEarlier.subtract(1, 'months');
            this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
            this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(false);
            const pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(
                this.carePlanIds,
                this.quantitativeMeasuringPoints,
                this.dateInfo.fromDate,
                this.getUntilDate(),
                this.showMultiplePerDay
            );
            const referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(
                this.carePlanIds,
                this.quantitativeReferencePoints,
                this.dateInfo.fromDate,
                this.getUntilDate()
            );
            try {
                let graphPoints: Array<Contract.Core.IGraph<Contract.CarePlan.Read.IParameter<number>>>;
                let referencePoints: Array<Contract.Core.IGraph<number>>;
                [graphPoints, referencePoints] = await Promise.all([pointsPromise, referencePromise]);
                const low = _.find(
                    graphPoints,
                    (g) =>
                        g.Subject.Id === this.lowParameter.characteristic.Id &&
                        ((g.Scale == null && this.lowParameter.unit == null) ||
                            (g.Scale != null &&
                                this.lowParameter.unit != null &&
                                g.Scale.Id === this.lowParameter.unit.Id))
                );
                const high = _.find(
                    graphPoints,
                    (g) =>
                        g.Subject.Id === this.highParameter.characteristic.Id &&
                        ((g.Scale == null && this.highParameter.unit == null) ||
                            (g.Scale != null &&
                                this.highParameter.unit != null &&
                                g.Scale.Id === this.highParameter.unit.Id))
                );
                const graph = new Model.BarGraph(low, high, !this.showMultiplePerDay);
                const references = _(referencePoints).map((g) => new Model.NumberGraph(g, true, true));
                this.configureChart(graph, references);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        protected containsData(config: HighChartsNGConfig): boolean {
            return !_(config.series).all((s) =>
                _(s.data).all((d) => (d as HighchartsDataPoint).high == null && (d as HighchartsDataPoint).low == null)
            );
        }

        private configureChart(graph: Model.BarGraph, references: Model.NumberGraph[]): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
            this.chartConfigs[0].options.yAxis = this.getYAxis(graph, references);
            const series = _(references).map((r) => this.getLineDataSeries(r));
            series.unshift(this.getBarDataSeries(graph));
            this.chartConfigs[0].series = series;
            this.chartConfigs[0].loading = false;
        }

        private getYAxis(graph: Model.BarGraph, references: Model.NumberGraph[]): HighchartsAxisOptions {
            const maxes = _(references)
                .chain()
                .map((r) => this.getMaxYScaleValue(r))
                .filter((m) => m != null)
                .value();
            maxes.push(this.getMaxYScaleValue(graph));
            const max = _(maxes).max();
            const mins = _(references)
                .chain()
                .map((r) => this.getMinYScaleValue(r))
                .filter((m) => m != null)
                .value();
            mins.push(this.getMinYScaleValue(graph));
            const min = _(mins).min();
            return {
                title: {
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    text: graph.scale != null ? graph.scale.Text : null,
                    y: -20,
                    x: -27,
                    textAlign: 'left',
                },
                max: max,
                min: min,
                id: graph.scale != null ? (graph.scale.Id as string) : 'unscaled',
                startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                labels: {
                    align: 'right',
                    x: 0,
                    y: -2,
                },
            } as HighchartsAxisOptions;
        }

        private getBarDataSeries(graph: Model.BarGraph): HighchartsColumnRangeChartSeriesOptions {
            const monitoringPartSource = _.find(
                this.monitoringPart.monitoringPartSources,
                (mps) =>
                    mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                    mps.hasCharacteristicAndUnit(graph.subject, graph.scale)
            ) as MonitoringPartSourceParameter;
            const chartBar = monitoringPartSource.sourceParameterRepresentation as ChartBar;
            return {
                animation: false,
                type: 'columnrange',
                data: _(graph.graphPoints)
                    .chain()
                    .map((gp) => this.getDataBar(gp))
                    .sortBy((gp) => gp.x)
                    .value(),
                color: chartBar.colour,
                name: graph.subject.Text,
                dataLabels: {
                    xHigh: -2,
                    xLow: -2,
                    yLow: -13,
                    yHigh: 13,
                    defer: true,
                },
                maxPointWidth: 20,
                pointPlacement: 'on',
            } as HighchartsColumnRangeChartSeriesOptions;
        }

        private getLineDataSeries(graph: Model.NumberGraph): HighchartsLineChartSeriesOptions {
            const monitoringPartSource = _.find(
                this.monitoringPart.monitoringPartSources,
                (mps) =>
                    (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                    mps.hasObjective(graph.subject) ||
                    mps.hasRuleThreshold(graph.subject)
            ) as MonitoringPartSourceParameter;

            const chartLine = monitoringPartSource.sourceParameterRepresentation as ChartLine;
            return {
                animation: false,
                type: 'line',
                data: _(graph.graphPoints)
                    .chain()
                    .map((gp) => this.getDataPoint(gp))
                    .sortBy((gp) => gp[0])
                    .value(),
                color: chartLine.colour,
                name: graph.subject.Text,
                lineWidth: chartLine.lineType.Id === Shared.Contract.Code.LineType.Thin ? 1 : 2,
                dashStyle: chartLine.lineType.Id === Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                step: true,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 1,
                },
                tooltip: {
                    xDateFormat: this.getDateFormat(false),
                },
            } as HighchartsLineChartSeriesOptions;
        }

        private getDataBar(
            graphPoint: Model.GraphPoint<Date, { low: IParameter<number>; high: IParameter<number> }>
        ): HighchartsDataPoint {
            const lowExceedsThreshold = graphPoint.y.low != null && graphPoint.y.low.ExceedsThreshold;
            const highExceedsThreshold = graphPoint.y.high != null && graphPoint.y.high.ExceedsThreshold;
            const warning = '<i class="fa fa-exclamation-triangle"></i>';
            return {
                x: moment(graphPoint.x).valueOf(),
                low: graphPoint.y.low != null ? graphPoint.y.low.Value : null,
                high: graphPoint.y.high != null ? graphPoint.y.high.Value : null,
                dataLabels:
                    lowExceedsThreshold || highExceedsThreshold
                        ? ({
                              enabled: true,
                              // ReSharper disable SuspiciousThisUsage
                              // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                              formatter: function() {
                                  if (this.y === this.point.low && lowExceedsThreshold) {
                                      return warning;
                                  } else if (this.y === this.point.high && highExceedsThreshold) {
                                      return warning;
                                  }
                                  return '';
                              },
                              // ReSharper restore SuspiciousThisUsage
                              style: {
                                  color: 'red',
                              },
                              useHTML: true,
                          } as HighchartsRangeDataLabels)
                        : null,
            } as HighchartsDataPoint;
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number>): number[] {
            return [moment(graphPoint.x).valueOf(), graphPoint.y];
        }
    }

    class FloatingBarGraphComponent extends MonitoringPartComponentBase {
        public controller = FloatingBarGraphController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringFloatingBarGraph', new FloatingBarGraphComponent());
}
