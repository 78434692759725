var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var HealthInsuranceFund = RemeCare.Model.HealthInsuranceFundAssignment;
        var PersonalInformationContactsController = /** @class */ (function () {
            function PersonalInformationContactsController(toaster, masterdataSvc, gridBuilderSvc, modalBuilderFactory) {
                this.toaster = toaster;
                this.masterdataSvc = masterdataSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            PersonalInformationContactsController.prototype.$onInit = function () { };
            PersonalInformationContactsController.prototype.$onChanges = function () {
                this.buildGrids();
            };
            PersonalInformationContactsController.prototype.addExternalReference = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var externalRef, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.editExternalReference()];
                            case 1:
                                externalRef = _a.sent();
                                this.externalReferencesGrid.addRow(externalRef);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PersonalInformationContactsController.prototype.addMutuality = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var mutuality, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.editMutuality()];
                            case 1:
                                mutuality = _a.sent();
                                this.mutualitiesGrid.addRow(mutuality);
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PersonalInformationContactsController.prototype.buildGrids = function () {
                var _this = this;
                this.externalReferencesGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('System', 'General.System')
                    .addColumn('Attribute', 'General.Attribute')
                    .addColumn('Value', 'General.Value')
                    .addEditButtonWithPromiseFunctionColumn(function (e) { return _this.editExternalReference(e); })
                    .build();
                this.mutualitiesGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('getCodeAndName()', 'General.Mutuality')
                    .addColumn('healthInsuranceRiskCode', 'General.RiskCode')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addEditButtonWithPromiseFunctionColumn(function (h) { return _this.editMutuality(h); })
                    .build();
                if (this.person != null) {
                    this.externalReferencesGrid.setData(this.person.externalReferences);
                    this.mutualitiesGrid.setData(this.person.healthInsuranceFunds);
                }
            };
            PersonalInformationContactsController.prototype.editExternalReference = function (externalReference) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('editExternalReferenceCtrl')
                        .setTemplateUrl('views/patient/patientInfo/personalInfo/editExternalReference.html')
                        .setScope({
                        externalReference: angular.copy(externalReference),
                    })
                        .setResolve({
                        patientId: function () { return _this.person.id; },
                    })
                        .setResultCallBack(function (r) { return resolve(r); })
                        .build();
                });
            };
            PersonalInformationContactsController.prototype.editMutuality = function (mutuality) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('editMutualityCtrl')
                        .setTemplateUrl('views/patient/patientInfo/personalInfo/editMutuality.html')
                        .setScope({
                        mutuality: mutuality != null ? angular.copy(mutuality) : new HealthInsuranceFund(),
                        onlyValidFromDate: mutuality != null,
                    })
                        .setResolve({
                        patientId: function () { return _this.person.id; },
                    })
                        .setResultCallBack(function (r) { return resolve(r); })
                        .build();
                });
            };
            return PersonalInformationContactsController;
        }());
        Patient.remeCarePatientModule.component('patientInfoPersonalInformationContacts', {
            templateUrl: 'views/patient/patientInfo/personalInfo/contactPoints.html',
            controller: PersonalInformationContactsController,
            bindings: {
                person: '<',
                onContactPointsChange: '&',
                onAddressesChange: '&',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
