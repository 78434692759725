var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ManagementController = /** @class */ (function () {
            // @ngInject
            function ManagementController($scope, sidebarSvc) {
                this.$scope = $scope;
                this.sidebarSvc = sidebarSvc;
            }
            ManagementController.prototype.$onInit = function () {
                var _this = this;
                this.sidebarSvc.setLeftSidebarTitle('General.ManagementModule');
                this.sidebarSvc.setLeftSidebarIcon('menu-hamburger');
                this.$scope.$on('$destroy', function () {
                    _this.sidebarSvc.clear();
                });
            };
            return ManagementController;
        }());
        Management.ManagementController = ManagementController;
        Management.remeCareManagementModule.controller('managementCtrl', ManagementController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
