var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ContactPointType = RemeCare.Shared.Contract.Code.ContactPointType;
        var ContactPointUsageType = RemeCare.Shared.Contract.Code.ContactPointUsageType;
        var ContactPerson = RemeCare.Model.ContactPersonPerson;
        var CreateContactPersonController = /** @class */ (function () {
            function CreateContactPersonController($state, $stateParams, toaster, $translate, masterdataSvc, partyApiSvc) {
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.toaster = toaster;
                this.$translate = $translate;
                this.masterdataSvc = masterdataSvc;
                this.partyApiSvc = partyApiSvc;
            }
            CreateContactPersonController.prototype.$onInit = function () {
                // Don't await
                this.loadDataAsync();
                this.patientId = this.$stateParams.patientId;
                this.contactPerson = new ContactPerson();
                this.homeAddress = RemeCare.Model.ContactPointFactory.createFromType(ContactPointType.Address);
                this.homeAddress.contactPointUsageType = ContactPointUsageType.Domicile;
                this.homeTelephone = RemeCare.Model.ContactPointFactory.createFromType(ContactPointType.Telephone);
                this.homeTelephone.contactPointUsageType = ContactPointUsageType.Domicile;
                this.defaultMobile = RemeCare.Model.ContactPointFactory.createFromType(ContactPointType.MobilePhone);
                this.defaultMobile.contactPointUsageType = ContactPointUsageType.Default;
                this.defaultEmail = RemeCare.Model.ContactPointFactory.createFromType(ContactPointType.Email);
                this.defaultEmail.contactPointUsageType = ContactPointUsageType.Default;
            };
            CreateContactPersonController.prototype.atLeastOneNumber = function () {
                var result = !this.defaultMobile.isEmpty() || !this.homeTelephone.isEmpty();
                return result;
            };
            CreateContactPersonController.prototype.saveContactPerson = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var registeredContactPerson, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.contactPersonForm.$setSubmitted();
                                if (this.contactPersonForm.$invalid) {
                                    this.contactPersonForm.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                this.contactPerson.addresses = [this.homeAddress];
                                this.contactPerson.telephoneNumbers = [this.homeTelephone, this.defaultMobile];
                                this.contactPerson.emailAddresses = [this.defaultEmail];
                                registeredContactPerson = this.contactPerson.toRegisterContactPerson();
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.createContactPersonAsync(this.patientId, registeredContactPerson)];
                            case 2:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                                this.$state.go('patients.patientfile.patientInfo.contactPersons.overview', {
                                    patientId: this.$stateParams.patientId,
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            CreateContactPersonController.prototype.cancel = function () {
                this.$state.go('patients.patientfile.patientInfo.contactPersons', {
                    patientId: this.$stateParams.patientId,
                });
            };
            CreateContactPersonController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getCountriesAsync(),
                                        this.masterdataSvc.getCommLanguagesAsync(),
                                        this.masterdataSvc.getLanguagesAsync(),
                                        this.masterdataSvc.getGendersAsync(),
                                        this.masterdataSvc.getContactPersonTypesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.countries = _a[0], this.communicationLanguages = _a[1], this.languages = _a[2], this.genders = _a[3], this.contactPersonTypes = _a[4];
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return CreateContactPersonController;
        }());
        Management.remeCareManagementModule.component('createContactPerson', {
            controller: CreateContactPersonController,
            templateUrl: 'views/patient/patientInfo/contactPersons/createContactPerson.html',
            bindings: {
                partyRoleId: '<',
            },
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
