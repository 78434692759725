/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    interface ITreatedDiseasesScope extends ICareRequestPartBaseScope {
        diseases: Shared.Contract.Read.ITherapyDiseaseReadModel[];
    }

    class TreatedDiseasesController extends CareRequestPartControllerBase<ITreatedDiseasesScope> {
        constructor(
            protected $scope: ITreatedDiseasesScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            // Don't await
            this.initDiseasesAsync();
        }

        private async initDiseasesAsync(): Promise<void> {
            try {
                this.$scope.diseases = await this.therapyApiSvc.getTherapyDiseasesAsync(
                    this.$scope.careRequest.therapyId,
                    Shared.Contract.Code.DiseaseType.TreatedDisease
                )
                .then(d => d.filter(d => d.IsDeleted === false));

                this.removeDeletedDiseasesFromAnswerConditions(this.$scope.diseases, this.$scope.careRequestPart.answerConditions);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }
    }

    class TreatedDiseasesDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'therapyApiSvc',
            'careRequestSvc',
            ($scope, $translate, toaster, therapyApiSvc, careRequestSvc) =>
                new TreatedDiseasesController($scope, $translate, toaster, therapyApiSvc, careRequestSvc),
        ];

        public templateUrl = 'views/careRequest/directive/treatedDiseases.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestTreatedDiseases', () => new TreatedDiseasesDirective());
}
