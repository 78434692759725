namespace RemeCare.Core.Services {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import PartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;
    import ITherapyDiseaseReadModel = Shared.Contract.Read.ITherapyDiseaseReadModel;

    export class TherapyApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /** @deprecated */
        public getCareRequestTemplate(
            therapyId: Guid,
            careRequestId?: Guid
        ): ng.IHttpPromise<Contract.CareRequest.Read.ICareRequestTemplate> {
            return this.get(
                `Therapies/${therapyId}/CareRequestTemplate`,
                {
                    careRequestId: careRequestId,
                },
                'carerequest'
            );
        }

        public getCareRequestTemplateAsync(
            therapyId: Guid,
            careRequestId?: Guid
        ): Promise<Contract.CareRequest.Read.ICareRequestTemplate> {
            return this.getPromise(
                `Therapies/${therapyId}/CareRequestTemplate`,
                {
                    careRequestId: careRequestId,
                },
                'carerequest'
            );
        }

        public getTherapiesAsync(): Promise<EntityTranslation[]> {
            return this.getPromise('Therapies', null, 'therapies');
        }

        public getTherapyDiseasesAsync(
            therapyId: Guid,
            diseaseType?: Shared.Contract.Code.DiseaseType
        ): Promise<ITherapyDiseaseReadModel[]> {
            return this.getPromise(`Therapies/${therapyId}/Diseases`, {
                DiseaseType: diseaseType,
            });
        }

        public getHospitalisationReasons(therapyId: Guid): ng.IHttpPromise<Shared.Contract.IEntityTranslation[]> {
            return super.get('Therapies/' + therapyId + '/HospitalisationReasons');
        }

        public getPossiblePartiesAsync(therapyId: Guid, actorRoleId: Guid): Promise<PartyRoleSummary[]> {
            return this.getPromise(`Therapies/${therapyId}/ActorRoles/${actorRoleId}/PossibleParties`);
        }

        getMedicationActions(
            therapyId: Guid,
            careRequestId?: Guid,
            medicationActionId?: Guid
        ): Promise<Contract.Therapy.Read.IStructuralTherapyActionProduct[]> {
            const params = {
                careRequestId: careRequestId,
                medicationActionId: medicationActionId,
            };
            return this.getPromise(`Therapies/${therapyId}/MedicationActions`, params);
        }

        public getCareRequestPartAsync(
            therapyId: Guid,
            careRequestPartId: Guid
        ): Promise<Shared.Contract.Read.ICareRequestPart> {
            return this.getPromise(
                `Therapies/${therapyId}/CareRequestConfiguration/CareRequestPart/${careRequestPartId}`
            );
        }

        public getObjectiveAsync(
            therapyId: Guid,
            objectiveId: Guid
        ): Promise<Shared.Contract.Read.IObjectiveDefinition> {
            return this.getPromise(`TherapyConfiguration/${therapyId}/Objectives/${objectiveId}`);
        }

        public getRuleThreshold(
            therapyId: Guid,
            ruleThresholdId: Guid
        ): ng.IHttpPromise<Shared.Contract.Read.IRuleThresholdDefinition> {
            return this.get(`TherapyConfiguration/${therapyId}/RuleThresholds/${ruleThresholdId}`);
        }

        public getStructuralTherapyActions(therapyId: Guid): ng.IHttpPromise<EntityTranslation[]> {
            return this.get(`TherapyConfiguration/${therapyId}/StructuralTherapyActions`);
        }

        public getCarePlanStatusReasons(
            therapyId,
            careplanstatustype
        ): ng.IHttpPromise<Shared.Contract.IEntityTranslation[]> {
            const params = {carePlanStatus: careplanstatustype};
            return this.get(`Therapies/${therapyId}/CarePlanStatusReasons`, params);
        }
    }

    remeCareAppModule.service('therapyApiSvc', TherapyApiService);
}
