module RemeCare.CareRequest {


    interface ICareRequestMedicationSimplifiedScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        careRequestMedication: CareRequestMedication;
        simplifiedRecurrences: Array<Shared.Contract.IEnumTranslation>;
        selectedSimplifiedRecurrence: Shared.Contract.IEnumTranslation;
        medicationForm: ng.IFormController;
        days: Array<Shared.Service.IDay>;
        confirm(): void;
        simplifiedRecurrenceChanged(simplifiedRecurrence: Shared.Contract.IEnumTranslation): void;
        minEndDate(): Date;
        addIntraDayTiming(array: Array<Shared.Framework.Model.IntraDayTiming>): void;
        isForTherapyAction: boolean;

        isAdHoc: boolean;
        commLanguage: string;
    }

    class CareRequestMedicationSimplifiedController extends Shared.Framework.ControllerBase<ICareRequestMedicationSimplifiedScope> {
        private today: Date;

        constructor(
            $scope: ICareRequestMedicationSimplifiedScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            private $timeout: ng.ITimeoutService,
            private periodicityFactory: Shared.Framework.Factory.PeriodicityFactory,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private daySvc: Shared.Service.DayService
        ) {

            super($scope, $translate, toaster);
            $scope.minEndDate = () => this.minEndDate();
            $scope.addIntraDayTiming = (a) => this.addIntraDayTiming(a);
            $scope.simplifiedRecurrenceChanged = (sr) => this.simplifiedRecurrenceChanged(sr);
            $scope.confirm = () => this.confirm();

        }

        public $onInit(): void {
            this.$scope.selectedSimplifiedRecurrence = this.$scope.careRequestMedication.periodicities[0] != null
                ? this.$scope.careRequestMedication.periodicities[0].simplifiedRecurrence
                : null;
            this.$scope.days = this.daySvc.getDays();
            if (this.$scope.isAdHoc) {
                this.$scope.simplifiedRecurrences = [];

                if (this.$scope.commLanguage) {
                    this.masterdataSvc.getInterfaceLanguages()
                        .success(r => {
                            var langCode = _.find(r, x => x.Code === this.$scope.commLanguage).Code;
                            this.masterdataSvc.getTranslation(langCode, 'Views.Patient.Medication.IfNeeded')
                                .success(trans => {
                                    this.createAdHocPeriodicity(trans['Views.Patient.Medication.IfNeeded']);
                                    this.$scope.simplifiedRecurrences.push(<Shared.Contract.IEnumTranslation>{
                                        Id: Shared.Contract.Code.SimplifiedRecurrence.AdHoc,
                                        Text: trans['Views.Patient.Medication.IfNeeded']
                                    });
                                    this.$scope.selectedSimplifiedRecurrence = _(this.$scope.simplifiedRecurrences).first();
                                })
                                .error(e => this.errorCallback(e));
                        })
                        .error(e => this.errorCallback(e));
                } else {
                    this.createAdHocPeriodicity(this.$translate.instant('Views.Patient.Medication.IfNeeded'));
                    this.$scope.simplifiedRecurrences.push(<Shared.Contract.IEnumTranslation>{
                        Id: Shared.Contract.Code.SimplifiedRecurrence.AdHoc,
                        Text: this.$translate.instant('Views.Patient.Medication.IfNeeded')
                    });
                    this.$scope.selectedSimplifiedRecurrence = _(this.$scope.simplifiedRecurrences).first();
                }
                
            } else {
                this.masterdataSvc.getSimplifiedRecurrences()
                    .success(r => this.$scope.simplifiedRecurrences = r)
                    .error(e => this.errorCallback(e));
            }
        }

        private createAdHocPeriodicity(unStructuredPeriodicity: string) {
            this.$scope.careRequestMedication.periodicities = [];
            this.$scope.careRequestMedication.periodicities.push(this.periodicityFactory.createPeriodicitiesFromAdHoc(unStructuredPeriodicity, 1));
        }

        private minEndDate(): Date {
            return this.$scope.careRequestMedication.id && moment().isAfter(moment(this.$scope.careRequestMedication.validFromDate), 'day')
            ? this.today
            : this.$scope.careRequestMedication.validFromDate;
        }

        private simplifiedRecurrenceChanged(simplifiedRecurrence: Shared.Contract.IEnumTranslation): void {
            this.$scope.careRequestMedication.periodicities =
            this.periodicityFactory.createPeriodicitiesFromSimplifiedRecurrence(simplifiedRecurrence);
        }

        private addIntraDayTiming(array: Array<Shared.Framework.Model.IntraDayTiming>): void {
            var intraDay = new Shared.Framework.Model.IntraDayTimingAdministration();
            intraDay.targetValues.push(new Shared.Framework.Model.TargetDose());
            array.push(intraDay);
        }

        private confirm(): void {
            if (this.$scope.medicationForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            this.$scope.$close(this.$scope.careRequestMedication);
        }
    }

    remeCareCareRequestModule
        .controller('careRequestMedicationSimplifiedCtrl', (
            $scope: ICareRequestMedicationSimplifiedScope,
            $translate,
            toaster: Shared.Framework.Toaster,
            $timeout: ng.ITimeoutService,
            periodicityFactory: Shared.Framework.Factory.PeriodicityFactory,
            masterdataSvc: Shared.Framework.MasterdataService,
            daySvc: Shared.Service.DayService
        ) => new CareRequestMedicationSimplifiedController(
            $scope,
            $translate,
            toaster,
            $timeout,
            periodicityFactory,
            masterdataSvc,
            daySvc));
}