var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var PartyRoleTypeCode;
                (function (PartyRoleTypeCode) {
                    PartyRoleTypeCode[PartyRoleTypeCode["Patient"] = 1] = "Patient";
                    PartyRoleTypeCode[PartyRoleTypeCode["HealthCareOrganisation"] = 2] = "HealthCareOrganisation";
                    PartyRoleTypeCode[PartyRoleTypeCode["HealthCareProfessional"] = 3] = "HealthCareProfessional";
                    PartyRoleTypeCode[PartyRoleTypeCode["HealthCareNonProfessional"] = 4] = "HealthCareNonProfessional";
                    PartyRoleTypeCode[PartyRoleTypeCode["ContactPerson"] = 5] = "ContactPerson";
                    PartyRoleTypeCode[PartyRoleTypeCode["Employee"] = 6] = "Employee";
                })(PartyRoleTypeCode = Codes.PartyRoleTypeCode || (Codes.PartyRoleTypeCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
