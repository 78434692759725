var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var RegistrationValueFactory = RemeCare.Model.RegistrationValueFactory;
        var Anamnesis = /** @class */ (function () {
            function Anamnesis(serverObject) {
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                    this.registrationValue = RegistrationValueFactory.createRegistrationValueFromServer(serverObject.RegistrationValue);
                }
            }
            Anamnesis.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: this.registrationValue.type === RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity ?
                        RemeCare.Shared.Contract.Code.CareRequestPartType.QuantitativeAnamnesis : RemeCare.Shared.Contract.Code.CareRequestPartType.QualitativeAnamnesis,
                    definitionId: this.careRequestPartLocalIdentity,
                    registrationValue: this.registrationValue.toServerWrite()
                };
            };
            return Anamnesis;
        }());
        CareRequest.Anamnesis = Anamnesis;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
