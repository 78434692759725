var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var PartyTypeCode;
                (function (PartyTypeCode) {
                    PartyTypeCode[PartyTypeCode["Person"] = 1] = "Person";
                    PartyTypeCode[PartyTypeCode["Organisation"] = 2] = "Organisation";
                })(PartyTypeCode = Codes.PartyTypeCode || (Codes.PartyTypeCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
