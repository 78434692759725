module RemeCare.Model {
    export const enum IntegrationMessageStatus {
        FAILED = 'FAILED',
        PROCESSED = 'PROCESSED',
        FILTERED = 'FILTERED',
        ARCHIVED = 'ARCHIVED'
    }

    export class IntegrationMessage {
        public id: string
        public patientId?: string;
        public patientNumber?: string;
        public patientReference?: string;
        public patientNationalNumber?: string;
        public patientName?: string;
        public receivedDateTime: string;
        public sender: string;
        public statusName: string;
        public statusTranslation: string;
        public errorCode?: string;
        public errorGuidance?: string;
        public fields?: { [key: string]: object };
        public message?: string;
        public error?: { [key: string]: object };


        public formattedError: string;
        public isFailed: boolean;
        public isProcessed: boolean;
        public isArchiveable: boolean;
        public isUnarchiveable: boolean;
        public isRetryable: boolean;


        constructor(def?: Contract.IntegrationMonitor.Read.IIntegrationMessageDetail) {
            if (def != null) {
                this.id = def.Id;
                this.patientId = def.PatientId;
                this.patientNumber = def.PatientNumber;
                this.patientReference = def.PatientReference;
                this.patientNationalNumber = def.PatientNationalNumber;
                this.patientName = def.PatientName;
                this.sender = def.Sender;
                this.receivedDateTime = def.ReceivedDateTime;
                this.statusName = def.StatusName;
                this.statusTranslation = def.StatusTranslation;
                this.errorCode = def.ErrorCode;
                this.errorGuidance = def.ErrorGuidance;
                this.fields = def.Fields;
                this.message = def.Message;
                this.error = def.Error;
                this.formattedError = JSON.stringify(this.error, null, '\t');
                this.isFailed = def.StatusName === IntegrationMessageStatus.FAILED;
                this.isProcessed = def.StatusName === IntegrationMessageStatus.PROCESSED;
                this.isArchiveable = def.StatusName !== IntegrationMessageStatus.ARCHIVED && this.isFailed;
                this.isUnarchiveable = def.StatusName === IntegrationMessageStatus.ARCHIVED;
                this.isRetryable = this.isFailed && def.StatusName !== IntegrationMessageStatus.ARCHIVED;
            }
        }
    }
}