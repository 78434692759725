namespace RemeCare.Custom {
    interface IUptitrationEvaluationScope extends CarePlanAction.IDefaultCarePlanActionScope {
        medicationName: string;
        proposal: CarePlanAction.QualitativeObservationDescriptor;
        currentDose: CarePlanAction.QuantitativeObservationDescriptor;
        nextDose: CarePlanAction.QuantitativeObservationDescriptor;
        showDoses: boolean;
        options: Shared.Contract.IChoiceListOption[];
    }

    class UptitrationEvaluationController extends CarePlanAction.DefaultCarePlanActionController<
        IUptitrationEvaluationScope
    > {
        constructor(
            protected $scope: IUptitrationEvaluationScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected $rootScope: ng.IRootScopeService,
            protected $state: ng.ui.IStateService,
            protected $dialog: Shared.Service.DialogService,
            protected $window: ng.IWindowService,
            protected carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly cardioCoachSvc: CardioCoachService,
        ) {
            super(
                $scope,
                $translate,
                toaster,
                $rootScope,
                $state,
                $dialog,
                $window,
                carePlanActionApiSvc,
                modalBuilderFactory
            );
            this.initAsync();
        }

        public getValueText(): string {
            const selectedOptions = _(this.$scope.proposal.value)
                .chain()
                .map((v) => _(this.$scope.options).find((o) => o.Id === v))
                .map((o) => o.Name)
                .value();
            return selectedOptions.join(', ');
        }

        protected makeRegistrationAsync(
            params: Contract.CarePlanAction.Write.IMakeRegistration,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<void> {
            return this.cardioCoachSvc.evaluateUptitrationAsync(params, errorCallback);
        }

        private async initMedicationNameAsync(): Promise<void> {
            try {
                const r = await this.carePlanActionApiSvc.getCarePlanActionMedicationAsync(
                    this.$scope.action.carePlanActionId
                );
                this.$scope.medicationName = r.BrandName;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async initCodeSetItemsAsync(): Promise<void> {
            try {
                let pointInTime = this.$scope.action.processVersionDate;
                if (pointInTime === undefined) {
                    pointInTime = moment.utc().toDate();
                }
                this.$scope.options = await this.masterdataSvc.getCodeSetItemsAsync(
                    this.$scope.proposal.optionGroupId,
                    RemeCare.Shared.DateHelper.toServerDateString(pointInTime)
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async initAsync(): Promise<void> {
            this.initMedicationNameAsync();
            this.$scope.proposal = _(this.$scope.action.components).find(
                (c) =>
                    (c as CarePlanAction.QualitativeObservationDescriptor).observableEntity != null &&
                    (c as CarePlanAction.QualitativeObservationDescriptor).observableEntity.Id ===
                    CardioCoachConstants.uptitrationProposal
            ) as CarePlanAction.QualitativeObservationDescriptor;
            this.$scope.currentDose = _(this.$scope.action.components).find(
                (c) =>
                    (c as CarePlanAction.QuantitativeObservationDescriptor).characteristicId ===
                    CardioCoachConstants.currentDailyDose
            ) as CarePlanAction.QuantitativeObservationDescriptor;
            this.$scope.nextDose = _(this.$scope.action.components).find(
                (c) =>
                    (c as CarePlanAction.QuantitativeObservationDescriptor).characteristicId ===
                    CardioCoachConstants.nextLevelDose
            ) as CarePlanAction.QuantitativeObservationDescriptor;
            this.$scope.proposal.hidden = true;
            this.$scope.currentDose.hidden = true;
            this.$scope.nextDose.hidden = true;

            this.initCodeSetItemsAsync();

            if (
                this.$scope.action.status !== Contract.Core.Codes.CarePlanActionStatus.Completed &&
                this.$scope.action.status !== Contract.Core.Codes.CarePlanActionStatus.Cancelled
            ) {
                if (this.$scope.proposal.value == null || this.$scope.proposal.value.length === 0) {
                    try {
                        const result = await this.carePlanApiSvc.getCarePlanAnamnesesAsync(
                            this.$scope.action.carePlanIds[0],
                            CardioCoachConstants.uptitrationProposalAnamnesis,
                            this.$scope.action.plannedDateTimeFrom
                        );
                        const registration = _(result)
                            .chain()
                            .map((a) => a.RegistrationValue)
                            .filter((r) => r != null)
                            .sortBy((r) => Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime))
                            .last()
                            .value() as Contract.CareRequest.Read.IRegistrationCodeSetValue;
                        this.$scope.proposal.value = registration != null ? [registration.ChoiceListOption.Id] : null;
                        this.setDoseIndicators();
                    } catch (e) {
                        this.toaster.error(e);
                    }
                } else {
                    this.setDoseIndicators();
                }
                if (this.$scope.currentDose.value == null) {
                    try {
                        const result = await this.carePlanApiSvc.getCarePlanAnamnesesAsync(
                            this.$scope.action.carePlanIds[0],
                            CardioCoachConstants.currentDailyDoseAnamnesis,
                            this.$scope.action.plannedDateTimeFrom
                        );
                        const registration = _(result)
                            .chain()
                            .map((a) => a.RegistrationValue)
                            .filter((r) => r != null)
                            .sortBy((r) => Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime))
                            .last()
                            .value() as Contract.CareRequest.Read.IRegistrationPhysicalQuantity;
                        this.$scope.currentDose.value = registration != null ? registration.Amount : null;
                    } catch (e) {
                        this.toaster.error(e);
                    }
                }
                if (this.$scope.nextDose.value == null) {
                    try {
                        const result = await this.carePlanApiSvc.getCarePlanAnamnesesAsync(
                            this.$scope.action.carePlanIds[0],
                            CardioCoachConstants.nextLevelDoseAnamnesis,
                            this.$scope.action.plannedDateTimeFrom
                        );
                        const registration = _(result)
                            .chain()
                            .map((a) => a.RegistrationValue)
                            .filter((r) => r != null)
                            .sortBy((r) => Shared.DateHelper.serverDateStringToDateTime(r.RegistrationDateTime))
                            .last()
                            .value() as Contract.CareRequest.Read.IRegistrationPhysicalQuantity;
                        this.$scope.nextDose.value = registration != null ? registration.Amount : null;
                    } catch (e) { }
                }
            } else {
                this.setDoseIndicators();
            }
        }

        private setDoseIndicators(): void {
            this.$scope.showDoses = !_(this.$scope.proposal.value).contains(
                CardioCoachConstants.noUptirationNoMedication
            );
        }
    }

    remeCareCustomModule.controller('cardioCoachUptitrationEvaluationCtrl', UptitrationEvaluationController);
}
