var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Connections;
    (function (Connections) {
        var RemeCareConnectionsConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareConnectionsConfiguration, _super);
            function RemeCareConnectionsConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareConnectionsConfiguration.prototype.config = function () {
                this.add('connections', {
                    url: '/Connections',
                    redirectTo: 'connections.fitbit',
                }, ['Views_Connections']);
                this.addGeneric('connections.fitbit', {
                    url: '/Fitbit?code&state',
                    component: 'rcFitbitCallback',
                    params: {
                        code: null,
                        state: null,
                    },
                }, []);
            };
            return RemeCareConnectionsConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        Connections.remeCareConnectionsModule.config(function ($stateProvider) { return new RemeCareConnectionsConfiguration($stateProvider); });
    })(Connections = RemeCare.Connections || (RemeCare.Connections = {}));
})(RemeCare || (RemeCare = {}));
