module RemeCare.Management {
    import remeCareCareRequestModule = CareRequest.remeCareCareRequestModule;
    import ICareRequestExtendedRoleConfiguration = CareRequest.Directive.ICareRequestExtendedRoleConfiguration;

    interface ICareRequestRoleScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        config: ICareRequestExtendedRoleConfiguration
        confirm(): void;
        useWithGroup():void;
    }

    class CareRequestRoleController extends Shared.Framework.ControllerBase<ICareRequestRoleScope> {

        static $inject = ['$scope', '$translate', 'toaster'];

        constructor(
            $scope: ICareRequestRoleScope,
            $translate,
            toaster) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
            this.$scope.useWithGroup = () => this.useWithGroup();
        }

        public $onInit(): void {
            this.$scope.config.possibleGroupAssignments.forEach(x => x.isActive = true);
        }

        public confirm(): void {
            const groupMembers = _.filter(this.$scope.config.possibleGroupAssignments, ga => ga.isActive);
            if (this.$scope.config.role.maximalNumber != null && groupMembers.length > this.$scope.config.role.maximalNumber) {
                this.toaster.error(this.$translate.instant('Views.CareRequest.Details.VolationMaximumRequirement'));
                return;
            }
            const params = {
                groupMembers: groupMembers,
                needsMemberOutOfGroup: false,
            }
            this.$scope.$close(params);
        }

        public useWithGroup(): void {
            const groupMembers = _.filter(this.$scope.config.possibleGroupAssignments, ga => ga.isActive);
            if (this.$scope.config.role.maximalNumber != null && groupMembers.length >= this.$scope.config.role.maximalNumber) {
                this.toaster.error(this.$translate.instant('Views.CareRequest.Details.VolationMaximumRequirement'));
                return;
            }
            const params = {
                groupMembers: groupMembers,
                needsMemberOutOfGroup: true,
            }
            this.$scope.$close(params);
        }
    }
    remeCareCareRequestModule.controller('careRequestRoleController', CareRequestRoleController);
}