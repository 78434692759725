module RemeCare.Patient {

    import Guid = Shared.Contract.Guid;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Address = RemeCare.Model.Address;
    import ContactPointUsageType = Shared.Contract.Code.ContactPointUsageType;


    class EditAddressController {
        contactPointUsageTypes: Array<EnumTranslation>;
        countries: Array<EnumTranslation>;
        form: Shared.Framework.Directive.IFormController;

        constructor(
            private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            private address: Address,
            private toaster: Shared.Framework.Toaster,
            private partyId: Guid,
            private currentAddresses: Array<Address>,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private partyApiSvc: Core.Services.PartyApiService,
            private isReadOnly: boolean) {
        }

        public $onInit(): void {
            if (this.address != null) {
                this.isReadOnly = this.isReadOnly || this.address.isReadOnly;
            }

            this.masterdataSvc
                .getContactPointUsageTypes(Shared.Contract.Code.PartyType.Person, Shared.Contract.Code.ContactPointType.Address)
                .success(r => this.contactPointUsageTypes = _(r).filter(cput => cput.Id !== ContactPointUsageType.Default))
                .error(e => this.toaster.error(e));
            this.masterdataSvc.getCountries()
                .success(r => this.countries = r)
                .error(e => this.toaster.error(e));
        }


        private overlapBetweenContactPoints(address: RemeCare.Model.Address, currentAddress: RemeCare.Model.Address): boolean {

            return (currentAddress.validUntilDate == null ||
                moment(address.validFromDate).isSameOrBefore(currentAddress.validUntilDate)) &&
                (address.validUntilDate == null ||
                    moment(currentAddress.validFromDate).isSameOrBefore(address.validUntilDate));

        }

        private confirm(): void {
            var canSave = true;
            if (this.form.$invalid) {
                this.form.$setSubmitted();
                this.form.showValidationErrorMessage();
                return;
            }

            if (this.address.contactPointUsageType === ContactPointUsageType.Domicile ||
                this.address.contactPointUsageType === ContactPointUsageType.Postal ||
                this.address.contactPointUsageType === ContactPointUsageType.Invoicing) {
                this.currentAddresses.forEach(address => {
                    if (address.contactPointUsageType === this.address.contactPointUsageType) {
                        if (this.overlapBetweenContactPoints(address, this.address)) {
                            canSave = false;
                            return;
                        }
                    }
                });
            };

            if (canSave) {
                this.partyApiSvc.saveContactPoint(this.partyId, this.address.toServerWrite())
                    .success(id => {
                        this.address.id = id || this.address.id;
                        this.$uibModalInstance.close(this.address);
                    })
                    .error(e => this.toaster.error(e));
            } else {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setSize(Shared.Framework.Helper.ModalSize.medium)
                    .setTemplateUrl('views/shared/overlapAddressesModal.html')
                    .build();
                this.$uibModalInstance.close();
            }
        }
    }

    remeCarePatientModule.controller('editAddressCtrl', EditAddressController);
}