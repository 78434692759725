namespace RemeCare {
    class RemeCareAppConfig {
        constructor(
            private readonly $stateProvider,
            private readonly $urlServiceProvider,
            private readonly $translateProvider,
            private readonly $httpProvider,
            private readonly baseUrl,
            private readonly tmhDynamicLocaleProvider
        ) {
            this.init();
        }

        private init(): void {
            this.$stateProvider.state('home', {
                url: '/',
                controller: 'homeCtrl',
                template: '',
            });
            this.$urlServiceProvider.rules.otherwise({
                state: 'home',
            });

            this.$translateProvider.useLoaderCache(true);
            this.$translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: this.baseUrl + 'Localization/{lang}/{part}',
            });

            let $cookies: ng.cookies.ICookiesService;
            angular.injector(['ngCookies']).invoke([
                '$cookies',
                (_$cookies_) => {
                    $cookies = _$cookies_;
                },
            ]);

            let language = $cookies.get('applicationLanguage');
            language = language || (navigator.language ? navigator.language : (navigator as any).userLanguage);
            if (language.indexOf('fr') > -1) {
                language = 'fr';
            } else if (language.indexOf('en') > -1) {
                language = 'en';
            } else {
                language = 'nl';
            }

            this.$translateProvider.use(language);
            this.$translateProvider.preferredLanguage(language);

            this.$translateProvider.useSanitizeValueStrategy(null);

            // Set default Accept-Language header
            this.$httpProvider.defaults.headers.common['Accept-Language'] = language;

            delete this.$httpProvider.defaults.headers.common['X-Requested-With'];

            this.tmhDynamicLocaleProvider.localeLocationPattern('lib/i18n/angular-locale_{{locale}}.js');
        }
    }

    remeCareAppModule.config(
        ($stateProvider, $urlServiceProvider, $translateProvider, $httpProvider, baseUrl, tmhDynamicLocaleProvider) =>
            new RemeCareAppConfig(
                $stateProvider,
                $urlServiceProvider,
                $translateProvider,
                $httpProvider,
                baseUrl,
                tmhDynamicLocaleProvider
            )
    );

    class RemeCareApp {
        constructor(
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $http: ng.IHttpService,
            private readonly $cookies: ng.cookies.ICookiesService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly $locale: ng.ILocaleService,
            private readonly tmhDynamicLocale,
            private readonly userTrackSvc: Shared.Framework.Service.UserTrackService,
            private readonly spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly translationResolver: Shared.Framework.TranslationResolver
        ) {
            this.setRootScope();

            this.translationResolver.setParts(['General', 'Views_InformedConsent']);

            this.userTrackSvc.startTracking();

            this.configure();
            if (!('ontouchstart' in document.documentElement)) {
                (document.documentElement as HTMLElement).className += ' no-touch';
            }
        }

        private setRootScope(): void {
            this.$rootScope.$on('$translateLoadingStart', (e) => {
                // Show a loading message until translations are loaded
                this.spinnerSvc.show('loader');
            });
            this.$rootScope.$on('$translateLoadingEnd', (e) => {
                // Hide loading message
                this.spinnerSvc.hide('loader');
            });
        }

        private configure(): void {
            let language = this.$cookies.get('applicationLanguage');
            language = language || (navigator.language ? navigator.language : (navigator as any).userLanguage);
            if (language.indexOf('fr') > -1) {
                language = 'fr';
            } else if (language.indexOf('en') > -1) {
                language = 'en';
            } else {
                language = 'nl';
            }
            this.$http.defaults.headers.common['Accept-Language'] = language;
            this.$translate.use(language);
            moment.locale(language);
            this.tmhDynamicLocale.set(language);
            this.$locale.id = language;
        }
    }

    remeCareAppModule.run(
        (
            $rootScope,
            $http,
            $cookies,
            $translate,
            $locale,
            tmhDynamicLocale,
            userTrackSvc,
            spinnerSvc,
            translationResolver
        ) =>
            new RemeCareApp(
                $rootScope,
                $http,
                $cookies,
                $translate,
                $locale,
                tmhDynamicLocale,
                userTrackSvc,
                spinnerSvc,
                translationResolver
            )
    );

    class HomeController implements ng.IController {
        constructor(
            private readonly authservice: Shared.Framework.AuthService,
            private readonly $state: ng.ui.IStateService,
            private readonly $window: ng.IWindowService
        ) {}

        public $onInit(): void {
            const profile = this.authservice.getClaim(Shared.Framework.ClaimTypes.applicationProfile);
            if (!profile) {
                this.$state.go('checkApplicationProfile');
                return;
            }

            this.redirect(profile);
        }

        private redirect(profile: string): void {
            const profileCode = parseInt(profile);
            switch (profileCode) {
                case Shared.Contract.Code.ApplicationProfileType.Superuser:
                    this.$state.go('management');
                    break;
                case Shared.Contract.Code.ApplicationProfileType.CareManager:
                case Shared.Contract.Code.ApplicationProfileType.AdministrativeManager:
                case Shared.Contract.Code.ApplicationProfileType.CareProvider:
                case Shared.Contract.Code.ApplicationProfileType.TherapyProvider:
                case Shared.Contract.Code.ApplicationProfileType.InformalCareProvider:
                case Shared.Contract.Code.ApplicationProfileType.Patient:
                    this.$state.go('agenda');
                    break;
                case Shared.Contract.Code.ApplicationProfileType.Configurator:
                    this.$window.location.href = '/remeConfig';
                    break;
                case Shared.Contract.Code.ApplicationProfileType.UnknownCareProvider:
                    this.$window.history.back();
                    break;
                default:
                    this.$state.go('checkApplicationProfile');
                    break;
            }
        }
    }

    remeCareAppModule.controller(
        'homeCtrl',
        (authservice, $state, $window) => new HomeController(authservice, $state, $window)
    );
}
