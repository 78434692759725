module RemeCare.Agenda {

    interface ITodoItem {
        date: Date;
        actions: Array<Model.PersonalAgendaAction>;
    }

    class TodosController {
        private filter: IAgendaFilter;
        todoItems: Array<ITodoItem>;
        isPatient: boolean;

        constructor(
            private authservice: Shared.Framework.AuthService) {
        }

        public $onInit(): void {
            this.isPatient = this.authservice.isPatient();
        }

        public $onChanges(changes: { [property: string]: ng.IChangesObject<Array<Model.PersonalAgendaAction>> }): void {
            this.mapTodos(changes['agendaItems'].currentValue);
        }

        private showTodo(todoItem: ITodoItem): boolean {
            return this.filter.showAllTodos ||
                _(todoItem.actions).any(a => a.status == Contract.Core.Codes.CarePlanActionStatus.Open);
        }

        private mapTodos(actions: Array<Model.PersonalAgendaAction>): void {
            this.todoItems = _(actions).chain()
                .filter(a => a.showAsToDo)
                .groupBy(a => this.getTodoDate(a))
                .map(ag => {
                    return {
                        date: Shared.DateHelper.dayOfDate(this.getTodoDate(ag[0])),
                        actions: _(ag).chain()
                            .forEach(a => a.name = this.authservice.isPatient() ? a.name : `${a.name} - ${a.patient}`)
                            .sortBy(a => this.getTodoDate(a)).value()
                    };
                })
                .sortBy(ag => ag.date)
                .filter(ag => !moment(this.filter.fromDate).isAfter(ag.date))
                .value();
        }

        private getTodoDate(todo: Model.PersonalAgendaAction): Date {
            if (todo.status < Contract.Core.Codes.CarePlanActionStatus.Completed) {
                if (todo.plannedDateTimeFrom !== todo.plannedDateTimeUntil) {
                    let result = this.getMinMaxDate(todo.plannedDateTimeUntil, todo.plannedDateTimeFrom, Shared.DateHelper.today());
                    return result;
                }
                if (todo.openEventWindowDateTime && todo.closeEventWindowDateTime
                    && todo.openEventWindowDateTime !== todo.closeEventWindowDateTime) {
                    let result = this.getMinMaxDate(todo.closeEventWindowDateTime, todo.openEventWindowDateTime, Shared.DateHelper.today());
                    return result;
                }
                return Shared.DateHelper.dayOfDate(todo.plannedDateTimeFrom);
            } else {
                if (todo.plannedDateTimeFrom !== todo.plannedDateTimeUntil) {
                    let result = this.getMinMaxDate(todo.plannedDateTimeUntil, todo.plannedDateTimeFrom, todo.executionDateTime);
                    return result;
                }
                if (todo.openEventWindowDateTime && todo.closeEventWindowDateTime
                    && todo.openEventWindowDateTime !== todo.closeEventWindowDateTime) {
                    let result = this.getMinMaxDate(todo.closeEventWindowDateTime, todo.openEventWindowDateTime, todo.executionDateTime);
                    return result;
                }
                return Shared.DateHelper.dayOfDate(todo.plannedDateTimeFrom);
            }
        }

        private getMinMaxDate(min: Date, max1: Date, max2: Date): Date {
            return Shared.DateHelper.dayOfDate(moment.min(moment(min), moment.max(moment(max1), moment(max2))).toDate());
        }
    }

    remeCareAgendaModule.component('rcAgendaTodos',
        {
            controller: TodosController,
            controllerAs: 'todosCtrl',
            templateUrl: 'views/agenda/todos.html',
            bindings: {
                agendaItems: '<',
                filter: '=',
                selectedActions: '='
            }
        });
}