/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var Hospitalisation = RemeCare.Model.Hospitalisation;
            var HospitalisationsController = /** @class */ (function (_super) {
                __extends(HospitalisationsController, _super);
                function HospitalisationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.addHospitalisation = function () { return _this.addHospitalisation(); };
                    _this.$onInit();
                    return _this;
                }
                HospitalisationsController.prototype.$onInit = function () {
                    this.buildGrid();
                };
                HospitalisationsController.prototype.buildGrid = function () {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('startDate', 'Views.CareRequest.Details.StartDateHospitalisation', { cellFilter: 'date:"shortDate"' })
                        .addColumn('getDuration()', 'Views.CareRequest.Details.DurationHospitalisation')
                        .addColumn('departmentType.Text', 'Views.CareRequest.Details.CareType')
                        .addColumn('hospitalisationReason.Text', 'Views.CareRequest.Details.Reason');
                    if (!this.$scope.readOnly) {
                        gridBuilder = gridBuilder
                            .addEditButtonWithPromiseFunctionColumn(function (c) { return _this.editHospitalisation(c); })
                            .addDeleteButtonColumn();
                    }
                    this.$scope.grid = gridBuilder.build();
                    this.$scope.grid.setData(this.$scope.careRequest.hospitalisations);
                };
                HospitalisationsController.prototype.addHospitalisation = function () {
                    var _this = this;
                    var hospitalisation = new Hospitalisation();
                    this.editHospitalisation(hospitalisation)
                        .done(function (r) { return _this.$scope.careRequest.hospitalisations.push(r); });
                };
                HospitalisationsController.prototype.editHospitalisation = function (hospitalisation) {
                    var deferred = jQuery.Deferred();
                    this.modalBuilderFactory.createModalBuilder()
                        .setTemplateUrl('views/careRequest/directive/hospitalisationModal.html')
                        .setController('hospitalisationCtrl')
                        .setScope({
                        hospitalisation: angular.copy(hospitalisation),
                        careRequestPart: this.$scope.careRequestPart,
                        therapyId: this.$scope.careRequest.therapyId
                    })
                        .setResultCallBack(function (r) { return deferred.resolve(r); })
                        .setDismissCallBack(function () { return deferred.reject(); })
                        .build();
                    return deferred.promise();
                };
                return HospitalisationsController;
            }(Directive.CareRequestPartControllerBase));
            var HospitalisationsDirective = /** @class */ (function (_super) {
                __extends(HospitalisationsDirective, _super);
                function HospitalisationsDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = ['$scope', '$translate', 'toaster', 'gridBuilderSvc', 'modalBuilderFactory', 'careRequestSvc',
                        function ($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc) {
                            return new HospitalisationsController($scope, $translate, toaster, gridBuilderSvc, modalBuilderFactory, careRequestSvc);
                        }];
                    _this.templateUrl = 'views/careRequest/directive/hospitalisations.html';
                    return _this;
                }
                return HospitalisationsDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestHospitalisations', function () { return new HospitalisationsDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
