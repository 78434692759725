var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var DateHelper = RemeCare.Shared.DateHelper;
        var GroupAssignment = /** @class */ (function () {
            function GroupAssignment(serverObject, groupName) {
                this.isActive = false;
                this.isNew = true;
                if (serverObject) {
                    this.id = serverObject.Id;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    this.groupId = serverObject.GroupId;
                    this.partyId = serverObject.PartyId;
                    this.organisation = serverObject.Organisation;
                    this.person = serverObject.Person ? this.map(serverObject.Person) : null;
                    this.name = this.person
                        ? this.person.LastName.concat("  " + this.person.FirstName)
                        : this.organisation.Name;
                    this.groupName = groupName;
                    this.nameAndGroup = this.name.concat(" - " + this.groupName);
                    this.isActive = this.validUntilDate == null || moment(this.validUntilDate).isAfter(DateHelper.today());
                    this.type = serverObject.Person
                        ? this.person.healthCareProfessionalTypes
                        : [this.organisation.HealthCareOrganisationType];
                    this.isNew = false;
                }
            }
            GroupAssignment.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    validUntilDate: RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate),
                    groupId: this.groupId,
                    partyId: this.partyId,
                };
            };
            GroupAssignment.prototype.map = function (person) {
                var result = person;
                result.partyRoleTypes = _(person.PartyRoles).map(function (pr) { return pr.Type; });
                result.healthCareProfessionalTypes = result.PartyRoles.map(function (pr) { return pr.HealthCareProfessionalType; }).filter(function (x) { return x != null; });
                result.specialties = _.flatten(result.PartyRoles.map(function (pr) { return pr.HealthCareProffesionalSpecialties; }).filter(function (x) { return x != null; }), true);
                return result;
            };
            return GroupAssignment;
        }());
        Management.GroupAssignment = GroupAssignment;
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
