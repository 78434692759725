module RemeCare.Framework {

    class BackButtonController implements ng.IComponentController {
        goBack: () => void;
        text: string;

        constructor(private $window: ng.IWindowService) {
        }

        $onInit(): void {
            if (!this.goBack) {
                this.goBack = () => {
                    this.$window.history.back();
                }
            }
        }
    }

    remeCareAppModule.component('rcBackButton',
    {
        controller: BackButtonController,
        controllerAs: 'backCtrl',
        templateUrl: 'views/framework/backButton.html',
        bindings: {
            text: '@',
            goBack: '&?'
        }
    });
} 