module RemeCare.SelfService {

    class RemeCareSelfServiceConfiguration extends Shared.Framework.RouteConfiguration {
        protected config(): void {

            this.addComponentRoute('selfservice',
                '/SelfService',
                'rcSelfService',
                ['Views_SelfService']);

            this.add('selfservice.activate',
                {
                    url: '/Activate?userId&key&activating',
                    views: {
                        '@^.^': {
                            component: 'rcActivateLogin'
                        }
                    },
                    reloadOnSearch: false
                }, ['Views_SelfService']);

            this.add('selfservice.resetPassword',
                {
                    url: '/ResetPassword?userId&key&activating',
                    views: {
                        '@^.^': {
                            component: 'rcResetPassword'
                        }
                    },
                    reloadOnSearch: false
                }, ['Views_SelfService']);

            this.add('selfservice.requestNewPassword',
                {
                    url: '/RequestNewPassword',
                    views: {
                        '@^.^': {
                            component: 'rcRequestNewPassword'
                        }
                    }
                }, ['Views_SelfService']);

            this.add('selfservice.requestUserAccount',
                {
                    url: '/RequestUserAccount',
                    views: {
                        '@^.^': {
                            component: 'rcRequestUserAccount'
                        }
                    }
                }, ['Views_SelfService']);

            this.add('selfservice.requestAccountInfo',
                {
                    url: '/ForgotUserName',
                    views: {
                        '@^.^': {
                            component: 'rcRequestAccountInfo'
                        }
                    }
                }, ['Views_SelfService']);
        }
    }

    remeCareSelfServiceModule.config((
        $stateProvider
    ) => new RemeCareSelfServiceConfiguration(
        $stateProvider
    ));
}