/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import ReferenceParameterObjective = Shared.Framework.Model.ReferenceParameterObjective;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    class InverserObjectiveHelper {
        constructor(public name: string, public parameters: string[]) {}

        public getValue(index: number): string {
            return this.parameters[index];
        }
    }

    class ObjectiveScoreTableInvertedController extends MonitoringPartControllerBase<
        Shared.Framework.Model.MonitoringPartTable
    > {
        public grid: Shared.Framework.Grid.Grid<InverserObjectiveHelper>;
        public objectivePeriodUnit: EnumTranslation;
        private referenceParameterObjective: ReferenceParameterObjective;
        private totalHeader: string;
        private scoreHeader: string;

        // @ngInject
        constructor(
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly $filter,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super(dateHelper, spinnerSvc);
        }

        public hasData(): boolean {
            return this.grid && this.grid.hasData();
        }

        protected async init(): Promise<void> {
            this.referenceParameterObjective = _.find(
                this.monitoringPart.monitoringPartSources,
                (mps) => mps.type === Shared.Contract.Code.MonitoringPartSourceType.ReferenceParameterObjective
            ) as ReferenceParameterObjective;

            try {
                const [objective, units]: [
                    Shared.Contract.Read.IObjectiveDefinition,
                    EnumTranslation[]
                ] = await Promise.all([
                    this.carePlanApiSvc.getObjectiveAsync(
                        this.carePlanIds[0],
                        this.referenceParameterObjective.objective.Id
                    ),
                    this.masterdataSvc.getDurationUnitsAsync(),
                ]);
                this.objectivePeriodUnit = _.find(units, (u) => u.Id === objective.Period.Unit);
                this.totalHeader =
                    `${objective.Characteristic.Text} - ${this.$translate.instant('General.Total')} (${
                        objective.Type.Text
                    }) ` +
                    `${this.$translate.instant('General.Per')} ${objective.Period.Quantity} ${
                        this.objectivePeriodUnit.Text
                    }`;
                this.scoreHeader = `${objective.Name} - ${this.$translate.instant('General.Score')}`;
                this.buildInverseGrid();
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        protected async onDateChange(): Promise<void> {
            this.grid.pagingOptions.currentPage = 1;
            await this.grid.search();
        }

        private buildInverseGrid(): void {
            let gridBuilder = this.gridBuilderSvc
                .createGridBuilder<InverserObjectiveHelper>((page, pageSize) => this.executeSearch(page, pageSize))
                .addColumn('name', '');

            // Prepare all possible columns. We will set their header name and visibility in executeSearch based on the data
            for (let i = 0; i < this.monitoringPart.maxRows; i++) {
                const customTemplate =
                    '<div class="ui-grid-cell-contents" bs-tooltip data-container="body" ' +
                    ` data-delay="500,100" ` +
                    `"> {{ row.entity.getValue(${i}) }} </div>`;

                gridBuilder = gridBuilder.addCustomColumn('', customTemplate, null, null, true, `date : 'shortDate'`);
            }

            this.grid = gridBuilder.setSorting(false).build();
            this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
        }

        private executeSearch(
            page: number,
            pageSize: number
        ): Promise<Shared.Contract.ISearchResult<InverserObjectiveHelper>> {
            return new Promise<Shared.Contract.ISearchResult<InverserObjectiveHelper>>((resolve, reject) => {
                this.carePlanApiSvc
                    .getObjectiveScoreAsync(
                        this.carePlanIds[0],
                        this.referenceParameterObjective.objective.Id,
                        this.dateInfo.fromDate,
                        this.dateInfo.untilDate,
                        page,
                        pageSize
                    )
                    .then((r) => {
                        const row1Data = _(r.Items).map((o) => this.$filter('numberEx')(o.PeriodicTotalValue, 2));
                        const row2Data = _(r.Items).map((o) =>
                            this.$filter('percentage')(this.$filter('numberEx')(o.ScorePercentage, 2))
                        );

                        const results: InverserObjectiveHelper[] = [];
                        results.push(new InverserObjectiveHelper(this.totalHeader, row1Data));
                        results.push(new InverserObjectiveHelper(this.scoreHeader, row2Data));

                        const result = {
                            Items: r.Items.length ? results : [],
                            Total: r.Total,
                        };

                        for (let i = 0; i < this.monitoringPart.maxRows; i++) {
                            const element = r.Items[i];

                            // First column is parameter name
                            const columnIndex = i + 1;
                            if (!element) {
                                this.grid.setColumnVisibility(columnIndex, false, false);
                            } else {
                                this.grid.setColumnVisibility(columnIndex, true, false);
                                this.grid.changeColumnName(
                                    columnIndex,
                                    Shared.DateHelper.serverDateStringToDateTime(element.UntilDate),
                                    false
                                );
                            }
                        }

                        this.grid.notifyColumnsChanged();
                        resolve(result);
                    })
                    .catch((e) => {
                        this.toaster.error(e);
                        reject();
                    });
            });
        }
    }

    class ObjectiveScoreTableInvertedComponent extends MonitoringPartComponentBase {
        public controller = ObjectiveScoreTableInvertedController;

        public templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
    }

    remeCarePatientModule.component(
        'rcMonitoringObjectiveScoreTableInverted',
        new ObjectiveScoreTableInvertedComponent()
    );
}
