/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var TherapyOptionController = /** @class */ (function (_super) {
                __extends(TherapyOptionController, _super);
                function TherapyOptionController($scope, $translate, toaster, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                TherapyOptionController.prototype.$onInit = function () {
                    var _this = this;
                    this.$scope.therapyOption = _.find(this.$scope.careRequest.therapyOptions, function (t) { return t.careRequestPartLocalIdentity === _this.$scope.careRequestPart.localIdentity; });
                    if (this.$scope.therapyOption == null) {
                        this.$scope.therapyOption = new CareRequest.TherapyOption();
                        this.$scope.therapyOption.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                        this.$scope.careRequest.therapyOptions.push(this.$scope.therapyOption);
                    }
                };
                TherapyOptionController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                return TherapyOptionController;
            }(Directive.CareRequestPartControllerBase));
            var TherapyOptionDirective = /** @class */ (function (_super) {
                __extends(TherapyOptionDirective, _super);
                function TherapyOptionDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = ['$scope', '$translate', 'toaster', 'careRequestSvc',
                        function ($scope, $translate, toaster, careRequestSvc) { return new TherapyOptionController($scope, $translate, toaster, careRequestSvc); }];
                    _this.templateUrl = 'views/careRequest/directive/therapyOption.html';
                    return _this;
                }
                return TherapyOptionDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestTherapyOption', function () { return new TherapyOptionDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
