module RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    interface ISearchPersonsCriteriaScope extends Shared.Framework.IBaseScope {
        countries: Array<EnumTranslation>;
        communicationLanguages: Array<EnumTranslation>;
        languages: Array<EnumTranslation>;
        genders: Array<EnumTranslation>;
        partyRoleTypes: Array<EnumTranslation>;
        grid: Shared.Framework.Grid.Grid<Contract.Party.Read.IPerson>;
    }

    class SearchPersonsCriteriaController extends Shared.Framework.ControllerBase<ISearchPersonsCriteriaScope> {

        constructor(
            $scope: ISearchPersonsCriteriaScope,
            $translate,
            toaster,
            private masterdataSvc: Shared.Framework.MasterdataService) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {
            this.masterdataSvc.getCountries()
                .success(r => this.$scope.countries = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getCommLanguages()
                .success(r => this.$scope.communicationLanguages = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getLanguages()
                .success(r => this.$scope.languages = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getGenders()
                .success(r => this.$scope.genders = r)
                .error(e => this.errorCallback(e));
            this.masterdataSvc.getPartyRoleTypes(Shared.Contract.Code.PartyType.Person)
                .success(r => this.$scope.partyRoleTypes = _(r)
                    .filter(prt => prt.Id === Shared.Contract.Code.PartyRoleType.Employee
                        || prt.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional))
                .error(e => this.errorCallback(e));
            this.$scope.grid.searchCriteria.phoneNumber = new Model.PhoneNumber();
            this.$scope.grid.searchCriteria.mobileNumber = new Model.PhoneNumber();
        }
    }

    remeCareManagementModule.controller('searchPersonsCriteriaCtrl', (
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ) => new SearchPersonsCriteriaController(
        $scope,
        $translate,
        toaster,
        masterdataSvc
    ));
}