module RemeCare.Patient {

    class PatientNotFoundController implements ng.IComponentController {
        public remedusInfoMail: string;

        constructor(private appConfig: Shared.Contract.IAppConfig) {
        }

        public $onInit(): void {
            this.remedusInfoMail = this.appConfig.remedusInfoMail;
        }
    }
    
    remeCarePatientModule.component('rcPatientNotFound',
        {
            controller: PatientNotFoundController,
            templateUrl: 'patient/controllers/patientNotFound/patientNotFound.html',
        });
}