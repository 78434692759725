module RemeCare.SelfService {
    import ContactPointType = Shared.Contract.Code.ContactPointType;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import Framework = Shared.Framework;

    class RequestAccountInfoComponent implements ng.IComponentController {
        public requestForm: Shared.Framework.Directive.IFormController;
        public requestAccountInfo: IRequestAccountInformation;
        public telephoneNumber: Model.TelephoneNumber;
        public languages: Array<EnumCodeTranslation>;
        public requestSuccess: boolean;

        constructor(
            private readonly selfServiceApi: SelfServiceApiService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Framework.MasterdataService,
            private readonly languageSvc: Shared.Framework.Service.LanguageService,
            private readonly $translate: ng.translate.ITranslateService
        ) {}

        public async $onInit(): Promise<void> {
            this.telephoneNumber = <Model.TelephoneNumber>(
                Model.ContactPointFactory.createFromType(ContactPointType.MobilePhone)
            );
            try {
                const languages = await this.masterdataSvc.getInterfaceLanguagesAsync();
                this.languages = _.sortBy(languages, lang => {
                    if (lang.Code === 'nl') {
                        return 1;
                    }
                    return -1;
                });
            } catch (exception) {
                this.toaster.error(exception);
            }
        }

        public changeLanguage(lang: string): void {
            this.languageSvc.setLang(lang, false);
        }

        public async requestAccountInformation(): Promise<void> {
            if (this.requestForm.$invalid) {
                this.requestForm.showValidationErrorMessage();
                return;
            }
            const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRegEx.test(String(this.requestAccountInfo.email).toLowerCase())) {
                this.toaster.error('General.Validation.EmailAddress.Invalid');
                return;
            }
            this.requestAccountInfo.phone = this.telephoneNumber.toString();
            this.requestAccountInfo.languageISO2Code = this.$translate.preferredLanguage();
            try {
                await this.selfServiceApi.requestAccountInfoAsync(this.requestAccountInfo);
                this.requestSuccess = true;
            } catch (exception) {
                this.toaster.error(exception);
            }
        }
    }

    remeCareSelfServiceModule.component('rcRequestAccountInfo', {
        controller: RequestAccountInfoComponent,
        templateUrl: 'views/selfService/requestAccountInfo.html'
    });
}
