namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import CarePlanQuery = Contract.Patient.Read.ICarePlanQuery;
    import CarePlanStatus = Shared.Contract.Code.CarePlanStatus;

    interface IPatientFileCarePlanScope extends Shared.Framework.IBaseScope {
        patientId: Shared.Contract.Guid;
        carePlans: CarePlan[];
        carePlanSummary: EntityTranslation[];

        carePlanSelected: (carePlan: EntityTranslation) => void;
        addAllCarePlansOption: () => void;
        therapies: EntityTranslation[];
        carePlan: CarePlan;
        telemonitoringId?: string;
        state: ng.ui.IState;
        allowChangeStatus: boolean;
        allowChangeStartDate: boolean;

        editCarePlanStatus: () => void;
        editCarePlanStartDate: () => void;
        viewCareRequest: () => void;
        createCareRequest: (therapy: EntityTranslation) => void;
    }

    class PatientFileCarePlanController extends Shared.Framework.ControllerBase<IPatientFileCarePlanScope> {
        constructor(
            $scope: IPatientFileCarePlanScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private $stateParams: PatientFileStateParams,
            private patientSvc: PatientService,
            private careRequestSearchSvc: CareRequest.CareRequestSearchService,
            private $state: ng.ui.IStateService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private authservice: Shared.Framework.AuthService
        ) {
            super($scope, $translate, toaster);

            this.$scope.editCarePlanStatus = () => this.editCarePlanStatus();
            this.$scope.editCarePlanStartDate = () => this.editCarePlanStartDate();
            this.$scope.viewCareRequest = () => this.viewCareRequest();
            this.$scope.createCareRequest = (therapy: EntityTranslation) => this.createCareRequest(therapy);
            this.$scope.carePlanSelected = (carePlan: EntityTranslation) => this.carePlanSelected(carePlan);
            this.$scope.addAllCarePlansOption = () => this.addAllCarePlansOption();
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            this.patientSvc
                .getCarePlans(this.$scope.patientId)
                .success((ags) => {
                    const open = _(
                        _(ags)
                            .filter((x) => x.Status.Id === CarePlanStatus.Open)
                            .map((x) => new CarePlan(x))
                    ).sortBy((x) => x.startDate);
                    const closed = _(
                        _(ags)
                            .filter((x) => x.Status.Id !== CarePlanStatus.Open)
                            .map((x) => new CarePlan(x))
                    ).sortBy((x) => x.statusChangedDate);
                    this.$scope.carePlans = open.concat(closed);
                    this.$scope.carePlanSummary = _(this.$scope.carePlans).map((x) => ({
                        Id: x.id,
                        Text: x.statusOpen
                            ? x.name +
                              this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                                  CarePlanStatus: x.status,
                                  StatusDate: x.getFormattedStartDate(),
                              })
                            : x.name +
                              this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                                  CarePlanStatus: x.status,
                                  StatusDate: x.getFormattedStatusChangedDate(),
                              }),
                    }));
                    this.$scope.carePlanSelected(_(this.$scope.carePlanSummary).first());
                    this.$scope.addAllCarePlansOption();
                })
                .error((m) => this.errorCallback(m));
            this.$scope.state = this.$state.current;
            this.$scope.state.params = angular.copy(this.$state.params);
            this.$scope.therapies = [];
            if (
                this.authservice.hasRight(Shared.Framework.AuthContext.careRequest, Shared.Framework.AuthRight.Create)
            ) {
                this.careRequestSearchSvc
                    .getPossibleTherapies()
                    .success((t) => {
                        this.$scope.therapies = t;
                    })
                    .error((e) => this.errorCallback(e));
            }
        }

        public editCarePlanStatus(): void {
            this.modalBuilderFactory
                .createModalBuilder<{}>()
                .setController('carePlanStatusModalCtrl')
                .setTemplateUrl('views/patient/carePlans/carePlanStatusModal.html')
                .setScope({
                    carePlan: this.$scope.carePlan,
                })
                .setResultCallBack(() => {})
                .build();
        }

        public editCarePlanStartDate(): void {
            this.modalBuilderFactory
                .createModalBuilder<{}>()
                .setController('carePlanStartDateModalCtrl')
                .setTemplateUrl('views/patient/carePlans/carePlanStartDateModal.html')
                .setScope({
                    carePlan: this.$scope.carePlan,
                })
                .setResultCallBack(() => {})
                .build();
        }

        public createCareRequest(therapy: EntityTranslation): void {
            this.$state.go('careRequests.careRequest', {
                therapyId: therapy.Id,
                existingPatient: this.$stateParams.patientId,
            });
        }

        public viewCareRequest(): void {
            this.modalBuilderFactory
                .createModalBuilder<{}>()
                .setController('careRequestModalCtrl')
                .setTemplateUrl('views/patient/carePlans/careRequestModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    carePlan: this.$scope.carePlan,
                })
                .setResultCallBack(() => {})
                .build();
        }

        protected carePlanSelected(carePlan: EntityTranslation): void {
            this.$scope.carePlan = _.find(this.$scope.carePlans, (x) => x.id === carePlan.Id);
            const telemonitoringId = _(this.$scope.carePlan.externalReferences).filter(
                (r) => r.System === 'TMPortal' && r.Attribute === 'telemonitoringId'
            );
            this.$scope.telemonitoringId =
                telemonitoringId && telemonitoringId.length ? telemonitoringId[0].Value : undefined;
            this.getRights();
            this.navigateToCarePlanDetails();
        }

        protected addAllCarePlansOption(): void {
            // Select all CarePlans is not allowed
        }

        private getRights(): void {
            if (!this.$scope.carePlan) {
                return;
            }

            const query: CarePlanQuery = {
                carePlanId: this.$scope.carePlan.id,
            };

            this.patientSvc
                .getTherapyBusinessContextRights(this.$stateParams.patientId, query)
                .success((ags) => {
                    const maxRight = _(
                        _(ags).filter(
                            (t) =>
                                t.TherapyBusinessContext ===
                                    Shared.Contract.Code.TherapyBusinessContextCode.CarePlanStatusChange &&
                                t.CarePlan === this.$scope.carePlan.id
                        )
                    ).max((t) => t.ExecutionRight).ExecutionRight;
                    this.$scope.allowChangeStatus =
                        (maxRight & Shared.Framework.AuthRight.Write) !== 0 && this.$scope.carePlan.statusOpen;
                    this.$scope.allowChangeStartDate =
                        (maxRight & Shared.Framework.AuthRight.Write) !== 0 &&
                        moment().isBefore(this.$scope.carePlan.startDate, 'day');
                })
                .error((m) => this.errorCallback(m));
        }

        private navigateToCarePlanDetails(): void {
            if (this.$scope.carePlans.length > 0 && this.$state.current.name === 'patients.patientfile.carePlans') {
                this.$state.go('patients.patientfile.carePlans.detail', null, {
                    location: 'replace',
                });
            }
        }
    }

    remeCarePatientModule.controller('PatientFileCarePlanCtrl', PatientFileCarePlanController);
}
