'use strict';
var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var SimplifiedRecurrenceStatusses = /** @class */ (function () {
                function SimplifiedRecurrenceStatusses() {
                }
                SimplifiedRecurrenceStatusses.xTimesDay = "5756866b-1eac-4b57-b653-2e6e2d071954";
                SimplifiedRecurrenceStatusses.xTimesOnYDays = "1ecb7150-dffe-4cc5-abdf-8b8636af6b5f";
                SimplifiedRecurrenceStatusses.alternateDay = "a25d8618-613b-4639-859c-4c318dc1ff84";
                SimplifiedRecurrenceStatusses.weekschedule = "d1708c43-c9cc-4526-bc34-5ebdbb5c3e62";
                SimplifiedRecurrenceStatusses.other = "70b6e8a4-7564-4fec-8506-708c1882055d";
                return SimplifiedRecurrenceStatusses;
            }());
            Core.SimplifiedRecurrenceStatusses = SimplifiedRecurrenceStatusses;
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
