module RemeCare.Inbox {
    import Alarm = Contract.Alarms.Read.IAlarm;
    import SearchResult = Shared.Contract.ISearchResult;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    class InboxController implements ng.IComponentController {
        alarmsGrid: Shared.Framework.Grid.Grid<Alarm>;
        showDetail: boolean;
        alarmLevels: Array<EnumTranslation>;
        therapies: Array<EntityTranslation>;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $animate: ng.animate.IAnimateService,
            private readonly $state: ng.ui.IStateService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly alarmsApiSvc: Core.Services.AlarmsApiService
        ) {}

        public $onInit(): void {
            if (_(this.$state.params).isEmpty()) {
                this.hideDetails();
            } else {
                this.showDetails();
            }
            this.loadMasterDataAsync();
            this.$rootScope.$on('alarmHandled', async (event, alarmId: Shared.Contract.Guid) => {
                const alarm = _.find(this.alarmsGrid.getData(), a => a.Id === alarmId);
                try {
                    const a = await this.alarmsApiSvc.getAlarmAsync(alarmId);
                    angular.copy(a, alarm);
                } catch (e) {
                    this.toaster.error(e);
                }
            });
            this.buildAlarmsGrid();
        }

        private async loadMasterDataAsync(): Promise<void> {
            try {
                [this.alarmLevels, this.therapies] = await Promise.all([
                    this.masterdataSvc.getAlarmLevelsAsync(),
                    this.therapyApiSvc.getTherapiesAsync()
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private hideDetails(): void {
            const details = angular.element('#inbox-details');
            const overview = angular.element('#inbox-overview');
            const body = angular.element('body');
            body.css('overflow-x', 'hidden');
            this.$animate
                .animate(details, null, { left: 'calc(100% - 2px)' }, null, {
                    duration: 0.5
                })
                .then(() => {
                    details.addClass('hide');
                });
            this.$animate
                .animate(overview, { width: '50%' }, { width: '100%' }, null, {
                    duration: 0.5
                })
                .then(() => {
                    overview.removeClass('inbox');
                    this.alarmsGrid.showColumn('Therapy.Text');
                    this.alarmsGrid.showColumn('Recipients');
                    this.alarmsGrid.resize();
                    this.showDetail = false;
                    body.css('overflow-x', 'auto');
                });
        }

        private showDetails(): void {
            this.showDetail = true;
            const details = angular.element('#inbox-details');
            const overview = angular.element('#inbox-overview');
            const body = angular.element('body');
            body.css('overflow-x', 'hidden');
            this.$animate
                .animate(details, null, { left: 'calc(50% - 2px)' }, null, {
                    duration: 0.5
                })
                .then(() => {
                    body.css('overflow-x', 'auto');
                });
            overview.addClass('inbox');
            details.removeClass('hide');
            this.$animate
                .animate(overview, { width: '100%' }, { width: '50%' }, null, {
                    duration: 0.5
                })
                .then(() => {
                    this.alarmsGrid.hideColumn('Therapy.Text');
                    this.alarmsGrid.hideColumn('Recipients');
                    this.alarmsGrid.resize();
                });
        }

        private search(): void {
            this.alarmsGrid.pagingOptions.currentPage = 1;
            this.alarmsGrid.search();
        }

        private async executeAlarmsSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): Promise<SearchResult<Alarm>> {
            const searchCriteria = angular.copy(criteria);
            searchCriteria.page = page;
            searchCriteria.pageSize = pageSize;
            searchCriteria.sortField = sortField;
            searchCriteria.sortOrder = sortOrder;
            searchCriteria.forInbox = true;
            searchCriteria.patient = null;
            searchCriteria.startDate = Shared.DateHelper.toServerDateString(<Date>criteria.startDate);
            searchCriteria.endDate = Shared.DateHelper.toServerDateString(<Date>criteria.endDate);
            searchCriteria.dateFilterType = Contract.Core.Codes.DateFilterTypeCode.CreationDate;
            searchCriteria.patientId = criteria.patient != null ? criteria.patient.Id : null;

            try {
                return await this.alarmsApiSvc.findAlarmsAsync(searchCriteria);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildAlarmsGrid(): void {
            this.alarmsGrid = this.gridBuilderSvc
                .createGridBuilder<Alarm>((page, pageSize, sortField, sortOrder, criteria) =>
                    this.executeAlarmsSearchAsync(page, pageSize, sortField, sortOrder, criteria)
                )
                .addPrioColumn('AlarmLevel.Id', 'Views.Alarms.Priority', 'AlarmLevel.Text')
                .addColumn('CreationDate', 'General.Date', { cellFilter: 'date: "shortDate"' })
                .addColumn('Message', 'Views.Alarms.Alarm')
                .addLinkColumn('Patient.Text', 'General.Patient', '#/Patients/{{row.entity.Patient.Id}}')
                .addColumn('Therapy.Text', 'General.Therapy')
                .addColumn('Recipients', 'Views.Alarms.Recipients', { cellFilter: 'delimitedDisplay: ", "' })
                .addBoolColumn('Status.Id == 3', 'Views.Alarms.Handled', null, false)
                .addActionColumn('menu-right', a => {
                    this.$state.go('inbox.alarmdetail', { alarmId: a.Id });
                    if (!this.showDetail) {
                        this.showDetails();
                    }
                })
                .build();
            this.alarmsGrid.searchCriteria.includeUnhandled = true;
            this.alarmsGrid.searchCriteria.therapies = [];
            this.alarmsGrid.searchCriteria.alarmLevels = [];
            this.alarmsGrid.sortOptions.direction = 'DESC';
            this.search();
        }
    }

    remeCareInboxModule.component('inbox', {
        controller: InboxController,
        templateUrl: 'views/inbox/inbox.html'
    });
}
