namespace RemeCare.Shared.Component {
    class GroupSearchController {
        public groupGrid: Shared.Framework.Grid.Grid<RemeCare.Contract.Management.Read.IGroup>;

        constructor(
            protected $scope: any,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.groupGrid.setCriteria({
                memberFirstName: this.$scope.preferredGroupFirstName,
                memberLastName: this.$scope.preferredGroupLastName,
            });
            this.groupGrid.search();
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<RemeCare.Contract.Management.Read.IGroup>> {
            sortField = sortField || 'groupName';
            sortDirection = sortDirection || 'asc';

            const query = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                memberFirstName: criteria.memberFirstName,
                memberLastName: criteria.memberLastName,
                groupName: criteria.groupName,
                organisationName: criteria.organisationName,
                includeExpiredGroups: false,
            } as RemeCare.Contract.Management.Read.Query.IFindGroupsQuery;

            try {
                const searchResult = await this.partyApiSvc.findGroupsAsync(query);
                return searchResult;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        private selectGroup(group: RemeCare.Contract.Management.Read.IGroup): void {
            this.$uibModalInstance.close(group);
        }

        private buildGrid() {
            this.groupGrid = this.gridBuilderSvc
                .createGridBuilder<RemeCare.Contract.Management.Read.IGroup>(
                    (page, pageSize, sortField, sortDirection, criteria) =>
                        this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .addColumn('Name', 'General.Name')
                .addDateColumn('ValidFromDate', 'General.StartDate', 'shortDate')
                .addDateColumn('ValidUntilDate', 'General.EndDate', 'shortDate')
                .addSelectButtonColumn(r => this.selectGroup(r))
                .build();
        }
    }

    remeCareAppModule.controller('groupSearchController', GroupSearchController);
}
