namespace RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;

    export class CareRequestSvc extends Shared.Framework.ApiServiceBase {

        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        /** @deprecated */
        public getCareRequest(id: Guid): ng.IHttpPromise<Contract.CareRequest.Read.ICareRequest> {
            return this.get(`CareRequests/${id}`, null, 'carerequest');
        }

        public getCareRequestAsync(id: Guid): Promise<Contract.CareRequest.Read.ICareRequest> {
            return this.getPromise(`CareRequests/${id}`, null, 'carerequest');
        }

        public saveCareRequestAsync(careRequest: Contract.CareRequest.Write.ICareRequest): Promise<void> {
            return this.postPromise('CareRequests', careRequest);
        }

        public cancelCareRequestAsync(careRequestId: Guid): Promise<void> {
            return super.deletePromise(`CareRequests/${careRequestId}`);
        }

        public isVisibleWithConditionality(careRequestPart: Shared.Framework.Model.CareRequestPart, careRequestTemplate: CareRequestTemplate, careRequest: CareRequest): boolean {
            let returnValue: boolean = false;
            
           // Get all the parts that have the current careRequestPart as a condition (GoToPart)
            let partsWithMatchingGoToId = Array<Shared.Framework.Model.CareRequestPart>();
            careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive.forEach(part => {
                if(part.answerConditions.length > 0) {
                    part.answerConditions.forEach(ac => {
                        if(ac.goToCareRequestPartId == careRequestPart.id) {
                            partsWithMatchingGoToId.push(part);
                        }
                    });
                }
            });

            // If there is no matching part, there is no condition specified for the current CareRequestPart => just show the part
            if(partsWithMatchingGoToId.length === 0) {
                return true;
            }

            // If there is at least one matchingPart, this means that default the current CareRequestPart should be hidden, except if the condition is fullfilled
            // Check if the condition is fulfilled
            partsWithMatchingGoToId.forEach(part => {
                part.answerConditions.forEach(ac => {
                    if(ac.goToCareRequestPartId === careRequestPart.id) {
                        if(ac.AnswerTherapyDiseaseId != null) {
                            if(careRequest.comorbidities.indexOf(ac.AnswerTherapyDiseaseId) > -1
                            || careRequest.treatedDiseases.indexOf(ac.AnswerTherapyDiseaseId) > -1) {
                                returnValue = true;
                            }
                        }
                        if(ac.answerBoolean != null) {
                            const currentOptions = careRequest.therapyOptions.filter(to => to.careRequestPartLocalIdentity == part.localIdentity);
                            currentOptions.forEach(co => {
                                if(co.option == ac.answerBoolean) {
                                    returnValue = true;
                                }
                            });
                        }
                        if(ac.answerCodeSetItemId != null) {
                            const currentAnamneseValues = careRequest.anamneses.filter(a => a.careRequestPartLocalIdentity == part.localIdentity).map(a => a.registrationValue.getValueIds());
                            const currentAnamneses = [].concat(...currentAnamneseValues);
    
                            currentAnamneses.forEach(ca => {
                                if(ca == ac.answerCodeSetItemId) {
                                    returnValue = true;
                                }
                            });
                        }
                    }
                });
            });
            
            return returnValue;
        }
    }

    remeCareCareRequestModule.service('careRequestSvc', CareRequestSvc);
}
