module RemeCare.Shared.Directive {

    class PersonSearchController implements ng.IComponentController {
        partyRoleTypes: Array<Shared.Contract.Code.PartyRoleType>;

        constructor(
            private partyApiSvc: Core.Services.PartyApiService
        ) {
        }

        public $onInit(): void { }

        searchParties(name: string) {
            return this.partyApiSvc.findPersons({
                page: 1,
                pageSize: 20,
                partyRoleTypes: this.partyRoleTypes,
                name: name
            } as Shared.Contract.ISearchQuery)
                .then(r => r.data.Items);
        }

        getDisplayText(person: RemeCare.Contract.Party.Read.IPerson): string {
            if (!person) return null;
            return `${person.FirstName} ${person.LastName}`;
        }
    }

    remeCareAppModule.component('rcPersonSimpleSearch',
        {
            controller: PersonSearchController,
            controllerAs: 'personSearchCtrl',
            templateUrl: 'views/shared/personSimpleSearch.html',
            bindings: {
                ngModel: '=',
                label: '@',
                partyRoleTypes: '<'
            }
        });
}