namespace RemeCare.CareRequest {
    import IHealthCareProfessionalParty = Contract.HealthCareParty.Read.IHealthCareProfessionalParty;
    import IHealthCareOrganisationParty = Contract.HealthCareParty.Read.IHealthCareOrganisationParty;

    export enum RolePart {
        CareRequestorRole = 1,
        CareTeamRole = 2,
    }

    export enum PartyRoleType {
        HealthCareProfessional = 1,
        HealthCareOrganisation = 2,
    }

    export interface IApplyForRole {
        roleName: string;
        displayName: string;
        rolePart: RolePart;
        actorRoleId: Shared.Contract.Guid;
        partyRoleType: PartyRoleType;
        party: IHealthCareProfessionalParty | IHealthCareOrganisationParty;
        organization: IHealthCareOrganisationParty;
        isSelected: boolean;
    }

    export interface IAutoAssignResult {
        matchedRoles: IApplyForRole[];
        personDetail: Contract.Party.Read.IPersonDetail;
        personOrganisation?: IHealthCareOrganisationParty;
        careRequestorAssigned: boolean;
        infoMessage: string;
    }
}
