var RemeCare;
(function (RemeCare) {
    var Shared;
    (function (Shared) {
        var Directive;
        (function (Directive) {
            var PersonSearchController = /** @class */ (function () {
                function PersonSearchController(partyApiSvc) {
                    this.partyApiSvc = partyApiSvc;
                }
                PersonSearchController.prototype.$onInit = function () { };
                PersonSearchController.prototype.searchParties = function (name) {
                    return this.partyApiSvc.findPersons({
                        page: 1,
                        pageSize: 20,
                        partyRoleTypes: this.partyRoleTypes,
                        name: name
                    })
                        .then(function (r) { return r.data.Items; });
                };
                PersonSearchController.prototype.getDisplayText = function (person) {
                    if (!person)
                        return null;
                    return person.FirstName + " " + person.LastName;
                };
                return PersonSearchController;
            }());
            RemeCare.remeCareAppModule.component('rcPersonSimpleSearch', {
                controller: PersonSearchController,
                controllerAs: 'personSearchCtrl',
                templateUrl: 'views/shared/personSimpleSearch.html',
                bindings: {
                    ngModel: '=',
                    label: '@',
                    partyRoleTypes: '<'
                }
            });
        })(Directive = Shared.Directive || (Shared.Directive = {}));
    })(Shared = RemeCare.Shared || (RemeCare.Shared = {}));
})(RemeCare || (RemeCare = {}));
