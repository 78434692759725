var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        var IntegrationMessageController = /** @class */ (function () {
            function IntegrationMessageController($stateParams, $state, $translate, storageSvc, integrationMessageSvc, integrationMessageSearchSvc, toaster) {
                this.$stateParams = $stateParams;
                this.$state = $state;
                this.$translate = $translate;
                this.storageSvc = storageSvc;
                this.integrationMessageSvc = integrationMessageSvc;
                this.integrationMessageSearchSvc = integrationMessageSearchSvc;
                this.toaster = toaster;
                this.isRetrying = false;
                this.isArchiving = false;
                this.isUnarchiving = false;
                this.isFirstMessageOfPage = false;
                this.isLastMessageOfPage = false;
            }
            IntegrationMessageController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.messageId = this.$stateParams.messageId;
                                this.knownFiltersAndMessageIds = this.storageSvc.get(StorageServiceConstants.integrationMonitorKey);
                                return [4 /*yield*/, this.setNextAndPreviousMessageIds()];
                            case 1:
                                _a.sent();
                                this.initTabs();
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, 4, , 5]);
                                return [4 /*yield*/, this.getIntegrationMessageDetailAsync()];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.goBack = function () {
                this.$state.go('integrationMonitor.search');
            };
            IntegrationMessageController.prototype.goToNextMessage = function () {
                if (this.isLastMessageOfPage) {
                    this.saveFiltersAndMessageIdsToStorage(this.getNextPageFilters(), this.nextPageMessageIds, this.nextHasNextPage);
                }
                this.$state.go('integrationMonitor.message', { messageId: this.nextMessageId });
            };
            IntegrationMessageController.prototype.goToPreviousMessage = function () {
                if (this.isFirstMessageOfPage) {
                    this.saveFiltersAndMessageIdsToStorage(this.getPreviousPageFilters(), this.previousPageMessageIds, this.previousHasNextPage);
                }
                this.$state.go('integrationMonitor.message', { messageId: this.previousMessageId });
            };
            IntegrationMessageController.prototype.retryMessage = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.isRetrying = true;
                                this.message.isRetryable = false; // user will have to refresh themselves 
                                this.message.isArchiveable = false;
                                this.message.isFailed = false;
                                this.message.statusTranslation = this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.InProgressStatus');
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.integrationMessageSvc.retryIntegrationMessageAsync(this.messageId)];
                            case 2:
                                _a.sent();
                                setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                    var e_3;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                _a.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, this.getIntegrationMessageDetailAsync()];
                                            case 1:
                                                _a.sent();
                                                this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyRetried'));
                                                return [3 /*break*/, 3];
                                            case 2:
                                                e_3 = _a.sent();
                                                this.toaster.error(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyRetriedButNotYetAvailableForRefresh'));
                                                return [3 /*break*/, 3];
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); }, 2000);
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4:
                                this.isRetrying = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.archiveMessage = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.isArchiving = true;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, this.integrationMessageSvc.archiveIntegrationMessageAsync(this.messageId)];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, this.getIntegrationMessageDetailAsync()];
                            case 3:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyArchived'));
                                return [3 /*break*/, 5];
                            case 4:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 5];
                            case 5:
                                this.isArchiving = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.unarchiveMessage = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var e_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.isUnarchiving = true;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, this.integrationMessageSvc.unarchiveIntegrationMessageAsync(this.messageId)];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, this.getIntegrationMessageDetailAsync()];
                            case 3:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.SuccessfullyUnarchived'));
                                return [3 /*break*/, 5];
                            case 4:
                                e_5 = _a.sent();
                                this.toaster.error(e_5);
                                return [3 /*break*/, 5];
                            case 5:
                                this.isUnarchiving = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.initTabs = function () {
                this.tabs = [{
                        heading: this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.Message'),
                        src: 'views/integrationMonitor/integrationMessageInformation.html',
                        active: true
                    }, {
                        heading: this.$translate.instant('Views.IntegrationMonitor.IntegrationMessage.TechnicalInformation'),
                        src: 'views/integrationMonitor/integrationMessageTechnicalInformation.html',
                        active: false
                    }];
            };
            IntegrationMessageController.prototype.getIntegrationMessageDetailAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.integrationMessageSvc.getIntegrationMessageDetailAsync(this.messageId)];
                            case 1:
                                result = _a.sent();
                                this.message = new RemeCare.Model.IntegrationMessage(result);
                                return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.setNextAndPreviousMessageIds = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var messageIds, indexOfMessageId;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                messageIds = this.knownFiltersAndMessageIds.MessageIds;
                                indexOfMessageId = messageIds.indexOf(this.messageId);
                                this.isLastMessageOfPage = indexOfMessageId === messageIds.length - 1;
                                this.isFirstMessageOfPage = indexOfMessageId === 0;
                                if (!(this.isLastMessageOfPage && this.isFirstMessageOfPage)) return [3 /*break*/, 2];
                                this.nextMessageId = null;
                                return [4 /*yield*/, this.setPreviousMessageParametersFromPreviousPage()];
                            case 1:
                                _a.sent();
                                return [3 /*break*/, 7];
                            case 2:
                                if (!this.isLastMessageOfPage) return [3 /*break*/, 4];
                                this.previousMessageId = messageIds[messageIds.length - 2];
                                return [4 /*yield*/, this.setNextMessageParametersFromNextPage()];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 7];
                            case 4:
                                if (!this.isFirstMessageOfPage) return [3 /*break*/, 6];
                                this.nextMessageId = messageIds[1];
                                return [4 /*yield*/, this.setPreviousMessageParametersFromPreviousPage()];
                            case 5:
                                _a.sent();
                                return [3 /*break*/, 7];
                            case 6:
                                this.nextMessageId = messageIds[indexOfMessageId + 1];
                                this.previousMessageId = messageIds[indexOfMessageId - 1];
                                _a.label = 7;
                            case 7: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.setNextMessageParametersFromNextPage = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var nextPageFilters, searchResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!!this.knownFiltersAndMessageIds.HasNextPage) return [3 /*break*/, 1];
                                this.nextMessageId = null;
                                return [3 /*break*/, 3];
                            case 1:
                                nextPageFilters = this.getNextPageFilters();
                                return [4 /*yield*/, this.executeSearchAsync(nextPageFilters)];
                            case 2:
                                searchResult = _a.sent();
                                this.nextPageMessageIds = searchResult.Items.map(function (message) { return message.Id; });
                                this.nextHasNextPage = searchResult.HasNextPage;
                                this.nextMessageId = this.nextPageMessageIds[0];
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.setPreviousMessageParametersFromPreviousPage = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var previousPageFilters, searchResult;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(this.knownFiltersAndMessageIds.Page === 1)) return [3 /*break*/, 1];
                                this.previousMessageId = null;
                                return [3 /*break*/, 3];
                            case 1:
                                previousPageFilters = this.getPreviousPageFilters();
                                return [4 /*yield*/, this.executeSearchAsync(previousPageFilters)];
                            case 2:
                                searchResult = _a.sent();
                                this.previousHasNextPage = searchResult.HasNextPage;
                                this.previousPageMessageIds = searchResult.Items.map(function (message) { return message.Id; });
                                this.previousMessageId = this.previousPageMessageIds[this.previousPageMessageIds.length - 1];
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.getPreviousPageFilters = function () {
                return __assign({}, this.knownFiltersAndMessageIds, { Page: this.knownFiltersAndMessageIds.Page - 1 });
            };
            IntegrationMessageController.prototype.getNextPageFilters = function () {
                return __assign({}, this.knownFiltersAndMessageIds, { Page: this.knownFiltersAndMessageIds.Page + 1 });
            };
            IntegrationMessageController.prototype.executeSearchAsync = function (criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, e_6;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: criteria.Page,
                                    pageSize: criteria.PageSize,
                                    NationalNumber: criteria.NationalNumber,
                                    Sender: criteria.Sender,
                                    ErrorCode: criteria.ErrorCode,
                                    Status: criteria.Status,
                                    ReceivedDateTimeFrom: RemeCare.Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeFrom),
                                    ReceivedDateTimeUntil: RemeCare.Shared.DateHelper.toServerDateString(criteria.ReceivedDateTimeUntil),
                                    PlannedDateTimeFrom: RemeCare.Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeFrom),
                                    PlannedDateTimeUntil: RemeCare.Shared.DateHelper.toServerDateString(criteria.PlannedDateTimeUntil),
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.integrationMessageSearchSvc.findIntegrationMessagesAsync(query)];
                            case 2: return [2 /*return*/, _a.sent()];
                            case 3:
                                e_6 = _a.sent();
                                this.toaster.error(e_6);
                                throw e_6;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            IntegrationMessageController.prototype.saveFiltersAndMessageIdsToStorage = function (criteria, messageIds, hasNextPage) {
                var page = criteria.Page || null;
                var pageSize = criteria.PageSize || null;
                var nationalNumberNew = criteria.NationalNumber || null;
                var senderNew = criteria.Sender || null;
                var errorCodeNew = criteria.ErrorCode || null;
                var statusNew = criteria.Status || null;
                var receivedDateTimeFromNew = criteria.ReceivedDateTimeFrom || null;
                var receivedDateTimeUntilNew = criteria.ReceivedDateTimeUntil || null;
                var plannedDateTimeFromNew = criteria.PlannedDateTimeFrom || null;
                var plannedDateTimeUntilNew = criteria.PlannedDateTimeUntil || null;
                var knownFilters = {
                    Page: page,
                    PageSize: pageSize,
                    NationalNumber: nationalNumberNew,
                    Sender: senderNew,
                    ErrorCode: errorCodeNew,
                    Status: statusNew,
                    ReceivedDateTimeFrom: receivedDateTimeFromNew,
                    ReceivedDateTimeUntil: receivedDateTimeUntilNew,
                    PlannedDateTimeFrom: plannedDateTimeFromNew,
                    PlannedDateTimeUntil: plannedDateTimeUntilNew,
                    MessageIds: messageIds,
                    HasNextPage: hasNextPage
                };
                this.storageSvc.store(StorageServiceConstants.integrationMonitorKey, knownFilters);
            };
            return IntegrationMessageController;
        }());
        IntegrationMonitor.remeCareIntegrationMonitorModule.component('rcIntegrationMessage', {
            controller: IntegrationMessageController,
            templateUrl: 'views/integrationMonitor/integrationMessage.html',
        });
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
