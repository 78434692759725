module RemeCare.Patient {
    import ApiServiceBase = Shared.Framework.ApiServiceBase;
    import IFindPatientsQuery = Contract.Party.Read.Query.IFindPatientsQuery;
    import IPatient = Contract.Patient.Read.IPatient;
    import ISearchResult = Shared.Contract.ISearchResult;

    export class PatientSearchService extends ApiServiceBase {
        public static $inject = ApiServiceBase.injectionParameters;

        public findPatientsAsync(query: IFindPatientsQuery): Promise<ISearchResult<IPatient>> {
            return this.getPromise<ISearchResult<IPatient>>('Patients', query, 'patients.search');
        }
    }

    remeCarePatientModule.service('patientSearchSvc', PatientSearchService);
}
