namespace RemeCare.CareRequest {

    export interface ICareRequestSearchStateParams {
        savedCareRequest?: boolean
    }

    class RemeCareCareRequestConfiguration extends RemeCare.Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.addRoute(
                'careRequests',
                '/CareRequests', // Be careful changing this route as this is being referenced in the integration API
                'views/shared/general.html',
                null,
                ['Views_CareRequest', 'Views_Patient', 'Periodicity'],
                null,
                null,
                {
                    context: Shared.Framework.AuthContext.careRequest,
                    right: Shared.Framework.AuthRight.Read,
                },
                { redirectTo: 'careRequests.search' }
            );

            this.add(
                'careRequests.search',
                {
                    component: 'careRequestSearch',
                    params: {
                        savedCareRequest: null
                    },
                    url: '/Search', // Be careful changing this route as this is being referenced in the integration API
                },
                ['Views_CareRequest', 'Views_Patient'],
                {
                    context: Shared.Framework.AuthContext.careRequest,
                    right: Shared.Framework.AuthRight.Read,
                }
            );

            this.add(
                'careRequests.careRequest',
                {
                    component: 'rcCareRequest',
                    params: {
                        careRequestId: null,
                        existingPatient: null,
                        referringState: null,
                    },
                    url: '/:careRequestId?therapyId',
                },
                ['Views_CareRequest', 'Views_Patient', 'Periodicity'],
                {
                    context: Shared.Framework.AuthContext.careRequest,
                    right: Shared.Framework.AuthRight.Read,
                }
            );
        }
    }

    angular
        .module('RemeCare.CareRequest')
        .config($stateProvider => new RemeCareCareRequestConfiguration($stateProvider));
}
