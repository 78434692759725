var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var RelatedCarePlanActionDirective = /** @class */ (function () {
            function RelatedCarePlanActionDirective() {
                this.restrict = 'E';
                this.scope = {
                    carePlanAction: '=',
                    showErrors: '=',
                    readOnly: '=',
                    isActive: '=',
                    fullSize: '='
                };
                this.templateUrl = 'views/carePlanAction/directive/relatedCarePlanAction.html';
            }
            return RelatedCarePlanActionDirective;
        }());
        angular.module('RemeCare.CarePlanAction').directive('rcRelatedCarePlanAction', function () { return new RelatedCarePlanActionDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
