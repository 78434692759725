namespace RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import Framework = Shared.Framework;
    import Model = Patient.Model;

    interface ICareRequestMedicationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        medicationInfo: { medication: Shared.Contract.Read.IMedication };
        prescription: CareRequestMedication;
        dateInfo: {
            minDate: Date;
            minUntilDate: Date;
            fromDateDisabled: boolean;
        };
        isNew: boolean;
        readOnly: boolean;
        canChangeMedication: boolean;
        enableAdHoc: boolean;
        enablePeriodicity: boolean;
        isFollowUpSchema: boolean;
        durations: EnumTranslation[];
        speedUnits: EnumTranslation[];
        solventToAddUnits: EnumTranslation[];
        administrationMethods: EnumTranslation[];

        prescriptionForm: ng.IFormController;
        patientLang: string;

        structuralTherapyActionId?: Guid;
        isAdHoc: boolean;

        clearMedication(): void;
        searchMedication(): void;
        save(): void;
        setDateRanges(): void;
        prescriptionTypeChanged(): void;
        changeFollowedUpSchema(): void;
        getMedications(medicationName: string): Promise<Shared.Contract.Read.IMedication[]>;
        evaluateMedication(medication: Shared.Contract.Read.IMedication): void;
        addAdministrationInformation(): void;
        hasAdministrationInformation(): boolean;
    }

    class PrescriptionController extends Shared.Framework.ControllerBase<ICareRequestMedicationScope> {
        constructor(
            protected $scope: ICareRequestMedicationScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly medicationSearchSvc: Shared.Service.MedicationSearchSvc
        ) {
            super($scope, $translate, toaster);
            $scope.clearMedication = () => this.clearMedication();
            $scope.searchMedication = () => this.searchMedication();
            $scope.save = () => this.save();
            $scope.setDateRanges = () => this.setDateRanges();
            $scope.prescriptionTypeChanged = () => this.prescriptionTypeChanged();
            $scope.changeFollowedUpSchema = () => this.changeFollowedUpSchema();
            $scope.getMedications = (m) => this.getMedicationsAsync(m);
            $scope.evaluateMedication = (m) => this.evaluateMedication(m);
            $scope.addAdministrationInformation = () => this.addAdministrationInformation();
            $scope.hasAdministrationInformation = () => this.hasAdministrationInformation();
        }

        public $onInit(): void {
            this.loadDataAsync();
            this.$scope.medicationInfo = { medication: null };

            if (this.$scope.prescription == null) {
                this.$scope.isNew = true;
                this.$scope.canChangeMedication = true;
                this.$scope.prescription = new CareRequestMedication(null);
                this.$scope.prescription.makeDefault();
            } else {
                this.$scope.isNew = false;
                this.$scope.prescription.onlyIntakeMoments = false;
                this.loadMedicationAsync();
            }

            this.$scope.prescription.structuralTherapyActionId = this.$scope.structuralTherapyActionId;

            this.$scope.prescription.isFollowedUp = this.$scope.structuralTherapyActionId != null;
            this.$scope.prescription.isAdHoc = this.$scope.isAdHoc;

            this.$scope.enableAdHoc = this.$scope.isAdHoc;
            this.$scope.enablePeriodicity = !this.$scope.isAdHoc;
            if (this.$scope.enableAdHoc && !this.$scope.enablePeriodicity) {
                this.$scope.prescription.makeAdHoc(this.$scope.patientLang, this.masterdataSvc, this.$translate);
            } else if (this.$scope.enablePeriodicity && !this.$scope.enableAdHoc && this.$scope.prescription.isAdHoc) {
                this.$scope.prescription.makeDefault();
            }

            if (this.$scope.patientLang) {
                this.loadInterfaceLanguagesAsync();
            } else {
                this.$scope.patientLang = this.$translate.preferredLanguage();
            }

            this.setDateRanges();
            this.watchDuration();
        }

        public addAdministrationInformation(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setTemplateUrl('views/patient/medication/extraAdministrationInformation.html')
                .setController('AdministrationInformationModalCtrl')
                .setScope({
                    prescription: angular.copy(this.$scope.prescription),
                    medicationInfo: angular.copy(this.$scope.medicationInfo),
                })
                .setSize(Framework.Helper.ModalSize.large)
                .setResultCallBack((prescription: Model.Prescription) => {
                    (this.$scope.prescription.prescribedAdministrationInformation =
                        prescription.prescribedAdministrationInformation),
                        (this.$scope.prescription.solventToAddInformation = prescription.solventToAddInformation);
                })
                .build();
        }

        public hasAdministrationInformation(): boolean {
            return this.$scope.prescription
                ? this.$scope.prescription.prescribedAdministrationInformation
                    ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans
                        ? this.$scope.prescription.prescribedAdministrationInformation.prescribedAdministrationMeans > 0
                        : false
                    : false
                : false;
        }

        private async loadMedicationAsync(): Promise<void> {
            try {
                this.$scope.medicationInfo.medication = await this.medicationSearchSvc.getMedicationAsync(
                    this.$scope.prescription.medication.ProductId
                );

                this.$scope.canChangeMedication = !!this.$scope.medicationInfo.medication.ActiveSubstance;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadInterfaceLanguagesAsync(): Promise<void> {
            try {
                const result = await this.masterdataSvc.getInterfaceLanguagesAsync();
                this.$scope.patientLang = _(result).find<Shared.Contract.IEnumCodeTranslation>(
                    (x) => x.Code === this.$scope.patientLang
                ).Code;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [
                    this.$scope.durations,
                    this.$scope.administrationMethods,
                    this.$scope.speedUnits,
                    this.$scope.solventToAddUnits,
                ] = await Promise.all([
                    this.masterdataSvc.getDurationUnitsAsync(),
                    this.masterdataSvc.getAdministrationMethodsAsync(),
                    this.masterdataSvc.getSpeedUnitsAsync(),
                    this.masterdataSvc.getSolventToAddUnitsAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async getMedicationsAsync(medicationName: string): Promise<Shared.Contract.Read.IMedication[]> {
            try {
                const result = await this.medicationSearchSvc.searchMedicationsAsync({
                    medicationName: medicationName,
                    structuralTherapyActionId: this.$scope.structuralTherapyActionId,
                    isActive: true,
                    page: 1,
                    pageSize: 1000,
                });
                return result.Items;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private setDateRanges(): void {
            const minUntilDate =
                this.$scope.prescription.validFromDate != null
                    ? new Date(this.$scope.prescription.validFromDate.valueOf())
                    : null;
            this.$scope.dateInfo = {
                minDate: null,
                minUntilDate: minUntilDate,
                fromDateDisabled: false,
            };
        }

        private watchDuration(): void {
            this.$scope.$watch(
                (s: ICareRequestMedicationScope) => s.prescription.periodicities[0].duration,
                (newValue, oldValue) => {
                    if (!angular.equals(newValue, oldValue)) {
                        if (newValue == null || newValue.Quantity == null || newValue.Unit == null) {
                            this.$scope.prescription.validUntilDate = null;
                        } else if (newValue.Quantity != null && newValue.Unit != null) {
                            let validUntilDate = Shared.DateHelper.addDuration(
                                this.$scope.prescription.validFromDate,
                                newValue
                            );
                            validUntilDate = Shared.DateHelper.addDuration(validUntilDate, {
                                Quantity: -1,
                                Unit: Shared.Contract.Code.DurationUnit.Days,
                            });
                            this.$scope.prescription.validUntilDate = validUntilDate;
                        }
                    }
                },
                true
            );
        }

        private clearMedication(): void {
            this.$scope.medicationInfo.medication = null;
            this.$scope.prescription.medication = null;
        }

        private searchMedication(): void {
            this.modalBuilderFactory
                .createModalBuilder<Shared.Contract.Read.IMedication>()
                .setTemplateUrl('views/patient/medication/searchMedication.html')
                .setController('searchMedicationCtrl')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setResultCallBack((m: Shared.Contract.Read.IMedication) => {
                    this.evaluateMedication(m);
                })
                .setResolve({
                    productClassIds: () => null,
                    productIds: () => null,
                })
                .setDismissCallBack(() => {
                    if (this.$scope.prescription.medication == null) {
                        this.$scope.$close();
                    }
                })
                .setScope({
                    structuralTherapyActionId: this.$scope.structuralTherapyActionId,
                })
                .build();
        }

        private evaluateMedication(medication: Shared.Contract.Read.IMedication): void {
            this.$scope.medicationInfo.medication = medication;
            this.$scope.prescription.medication = medication;
        }

        private save(): void {
            if (this.$scope.prescriptionForm.$invalid) {
                this.$scope.prescriptionForm.$setSubmitted();
                this.showValidationErrorMessage();
                return;
            }

            if (
                this.$scope.prescription.isFollowedUp &&
                !this.$scope.prescription.isAdHoc &&
                (this.$scope.prescription.periodicities == null ||
                    _(this.$scope.prescription.periodicities).size() === 0)
            ) {
                this.toaster.error(this.$translate.instant('Periodicity.PeriodicityRequired'));
                return;
            }
            if (
                this.$scope.prescription.isFollowedUp &&
                !this.$scope.prescription.isAdHoc &&
                _(this.$scope.prescription.periodicities).any((x) => _(x.intraDayTimings).size() === 0)
            ) {
                this.toaster.error(this.$translate.instant('Periodicity.IntraDayTimingRequired'));
                return;
            }

            this.$scope.$close(this.$scope.prescription);
        }

        private prescriptionTypeChanged(): void {
            if (this.$scope.prescription.isAdHoc) {
                this.$scope.prescription.makeAdHoc(this.$scope.patientLang, this.masterdataSvc, this.$translate);
            } else {
                this.$scope.prescription.makeDefault();
            }
        }

        private changeFollowedUpSchema(): void {}
    }

    remeCareCareRequestModule.controller('careRequestMedicationCtrl', PrescriptionController);
}
