namespace RemeCare.Custom {
    export class CardioCoachService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public evaluateUptitrationAsync(
            registrations: Contract.CarePlanAction.Write.IMakeRegistration,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<void> {
            const params = registrations;
            return this.postPromise(
                `CardioCoach/${registrations.actionId}/UptitrationEvaluation`,
                params,
                null,
                null,
                errorCallback
            );
        }
    }

    remeCareCustomModule.service('cardioCoachSvc', CardioCoachService);
}
