var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ApiServiceBase = RemeCare.Shared.Framework.ApiServiceBase;
        var PatientSearchService = /** @class */ (function (_super) {
            __extends(PatientSearchService, _super);
            function PatientSearchService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            PatientSearchService.prototype.findPatientsAsync = function (query) {
                return this.getPromise('Patients', query, 'patients.search');
            };
            PatientSearchService.$inject = ApiServiceBase.injectionParameters;
            return PatientSearchService;
        }(ApiServiceBase));
        Patient.PatientSearchService = PatientSearchService;
        Patient.remeCarePatientModule.service('patientSearchSvc', PatientSearchService);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
