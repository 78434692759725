var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var RemarkController = /** @class */ (function (_super) {
            __extends(RemarkController, _super);
            // @ngInject
            function RemarkController($scope, $element, toaster, $filter, authservice, carePlanActionApiSvc, masterdataSvc, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$element = $element;
                _this.toaster = toaster;
                _this.$filter = $filter;
                _this.authservice = authservice;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.masterdataSvc = masterdataSvc;
                $scope.loadStandardPhrases = function (langCode) { return _this.loadStandardPhrases(langCode); };
                $scope.addTimestamp = function () { return _this.addTimestamp(); };
                return _this;
            }
            RemarkController.prototype.addTimestamp = function () {
                if (!this.$scope.component.enabled || this.$scope.readOnly) {
                    return;
                }
                var textArea = this.$element.find('textarea')[0];
                var cursorPos = this.getCursorPosition(textArea);
                var timeStamp = this.$filter('date')(new Date(), 'short');
                var text = textArea.value;
                text =
                    text.slice(0, cursorPos) +
                        '[' +
                        this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.firstname) +
                        ' ' +
                        this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.lastname) +
                        ' ' +
                        timeStamp +
                        '] ' +
                        text.slice(cursorPos, text.length);
                if (text.length > 3000) {
                    text = text.slice(0, 3000);
                }
                this.$scope.component.value = text;
                textArea.focus();
            };
            RemarkController.prototype.init = function () {
                var _this = this;
                this.$scope.hasStandardPhrases = this.$scope.component.hasStandardPhrases;
                this.masterdataSvc
                    .getInterfaceLanguages()
                    .success(function (r) {
                    _this.$scope.languages = r;
                })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            RemarkController.prototype.getCursorPosition = function (textArea) {
                var iCaretPos = 0;
                if (document.selection) {
                    textArea.focus();
                    var oSel = document.selection.createRange();
                    oSel.moveStart('character', -textArea.value.length);
                    iCaretPos = oSel.text.length;
                }
                else if (textArea.selectionStart || textArea.selectionStart === 0) {
                    iCaretPos = textArea.selectionStart;
                }
                return iCaretPos;
            };
            RemarkController.prototype.loadStandardPhrases = function (langCode) {
                var _this = this;
                this.carePlanActionApiSvc
                    .getStandardPhrases(this.$scope.carePlanAction.actionTemplateId, langCode)
                    .success(function (ags) {
                    _this.$scope.standardPhrases = _(ags).uniq();
                })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            return RemarkController;
        }(CarePlanAction.ComponentControllerBase));
        var RemarkDirective = /** @class */ (function (_super) {
            __extends(RemarkDirective, _super);
            function RemarkDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/remark.html';
                _this.controller = RemarkController;
                return _this;
            }
            return RemarkDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular.module('RemeCare.CarePlanAction').directive('rcRemark', function () { return new RemarkDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
