namespace RemeCare.Patient {
    class PatientConnectionsController implements ng.IComponentController {
        public fitbitToggle: boolean;
        public fitbitConnectionId: string;
        public loading: boolean;

        constructor(
            protected $stateParams: PatientFileStateParams,
            private readonly $window: ng.IWindowService,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly appConfig: IAppConfig,
            private readonly patientSvc: PatientService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public async $onInit(): Promise<void> {
            this.loading = true;
            this.fitbitToggle = false;
            this.loadConnections();
        }

        public onFitbitToggled(): void {
            if (this.fitbitToggle === false) {
                this.$dialog.confirmBox(
                    'Views.Management.Persons.Connections.Fitbit.RemoveDialog.Title',
                    'Views.Management.Persons.Connections.Fitbit.RemoveDialog.Body',
                    () => this.deleteFitbitConnection(),
                    () => (this.fitbitToggle = true)
                );

                return;
            }

            this.$dialog
                .messageBox(
                    this.$translate.instant('Views.Management.Persons.Connections.Fitbit.RedirectDialog.Title'),
                    this.$translate.instant('Views.Management.Persons.Connections.Fitbit.RedirectDialog.Body'),
                    [
                        { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                        { result: 'yes', label: this.$translate.instant('General.Continue'), cssClass: 'btn-primary' },
                    ]
                )
                .then((result) => {
                    if (result === 'yes') {
                        this.redirectToFitbitAuth();
                    } else {
                        this.fitbitToggle = false;
                    }
                });
        }

        private async loadConnections(): Promise<void> {
            const connections = await this.patientSvc.getConnectionsAsync(this.$stateParams.patientId as string);
            if (connections) {
                const fitbitConnection = _(connections)
                    .chain()
                    .filter((c) => c.Provider === Shared.Contract.Code.ConnectionType.Fitbit)
                    .first()
                    .value();
                if (fitbitConnection) {
                    this.fitbitToggle = true;
                    this.fitbitConnectionId = fitbitConnection.Id;
                }
            }
            this.loading = false;
        }

        private async redirectToFitbitAuth(): Promise<void> {
            const pkcePair = await Shared.PkceHelper.generatePkcePair();
            this.storageSvc.store(
                Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier,
                pkcePair.codeVerifier,
                false
            );

            const base64PatientId = Shared.Base64Helper.base64urlencode(this.$stateParams.patientId as string);
            const fitbitAuthUrl =
                `https://www.fitbit.com/oauth2/authorize` +
                `?response_type=code` +
                `&code_challenge_method=S256` +
                `&code_challenge=${pkcePair.codeChallenge}` +
                `&scope=activity+heartrate` +
                `&state=${base64PatientId}` +
                `&client_id=${this.appConfig.fitbitClientId}` +
                `&prompt=login consent`;
            this.$window.location.href = fitbitAuthUrl;
        }

        private deleteFitbitConnection(): void {
            this.patientSvc
                .deleteConnectionAsync(this.$stateParams.patientId as string, this.fitbitConnectionId)
                .catch((r) => {
                    this.toaster.error(r);
                    this.fitbitToggle = true;
                });
        }
    }

    Patient.remeCarePatientModule.component('rcPatientConnections', {
        controller: PatientConnectionsController,
        templateUrl: 'patient/components/patientInfo/personalInfo/connections/patientConnections.html',
    });
}
