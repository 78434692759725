'use strict';

module RemeCare.Contract.CarePlanAction.Read {
    import Guid = RemeCare.Shared.Contract.Guid;
    import MediaLibItemQuestionDetail = RemeCare.Shared.Contract.IMediaLibItemQuestionDetail;
    import Realization = RemeCare.Contract.Core.IRealization;

    export interface IExecutionInfo {
        CarePlanActionId: Guid;
        Editable: boolean;
        Questions: MediaLibItemQuestionDetail[];
        ProfileQuestion: MediaLibItemQuestionDetail;
        RealizationStartDate: Date;
        RealizationEndDate: Date;
        Visit: Realization;
        BloodAnalysis: Realization;
        IsTelephoneCall: boolean;
    }
}