/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import CareRequestUIControlType = Shared.Contract.Code.CareRequestUIControlType;

    interface IDateScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.DateDefinition;
        careRequestDate: CareRequestDate;
        minDate: Date;
        showStartDateInfo: boolean;
        startDate: string;
        hideStartDate: boolean;
    }

    class DateController extends CareRequestPartControllerBase<IDateScope> {
        constructor($scope: IDateScope, $translate, toaster, protected careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.$scope.hideStartDate =
                // This is currently not available due to a bug in the stepType
                // this.$scope.careRequest.stepType.Id === Shared.Contract.Code.CareRequestStepType.Application &&
                this.$scope.careRequestTemplate.totalSteps > this.$scope.careRequest.stepSequence &&
                this.$scope.careRequestPart.isInternalCarePlanStartDate;

            this.$scope.careRequestDate = _.find(
                this.$scope.careRequest.careRequestDates,
                t => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity
            );
            if (this.$scope.careRequestDate == null) {
                this.$scope.careRequestDate = new CareRequestDate();
                this.$scope.careRequestDate.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.$scope.careRequest.careRequestDates.push(this.$scope.careRequestDate);
            }

            if (
                this.$scope.careRequestPart.isInternalCarePlanStartDate &&
                (!this.$scope.careRequest.careRequestStatus ||
                    this.$scope.careRequest.careRequestStatus.Id < Shared.Contract.Code.CareRequestStatus.Final)
            ) {
                this.$scope.minDate = Shared.DateHelper.today();
                this.$scope.careRequestDate.date = this.$scope.minDate;
                this.$scope.startDate = moment(this.$scope.careRequestDate.date).format('DD/MM/YYYY');
                this.$scope.showStartDateInfo = true;
            } else {
                this.$scope.minDate = null;
                this.$scope.showStartDateInfo = false;
            }

            this.$scope.toggleStartDate = function(): void {
                this.$parent.showStartDateInfo = !this.$parent.showStartDateInfo;
            };
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }

        protected isRequired(controlType: CareRequestUIControlType): boolean {
            return super.isRequired(controlType) || this.$scope.careRequestPart.isInternalCarePlanStartDate;
        }
    }

    class DateDirective extends CareRequestPartDirectiveBase {
        public controller = [
            '$scope',
            '$translate',
            'toaster',
            'careRequestSvc',
            ($scope, $translate, toaster, careRequestSvc) => new DateController($scope, $translate, toaster, careRequestSvc),
        ];

        public templateUrl = 'views/careRequest/directive/date.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestDate', () => new DateDirective());
}
