var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SendResetMailController = /** @class */ (function (_super) {
            __extends(SendResetMailController, _super);
            function SendResetMailController($scope, $translate, toaster, partyId, securityApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.partyId = partyId;
                _this.securityApiSvc = securityApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            SendResetMailController.prototype.$onInit = function () {
                this.$scope.mailToOptions = [
                    {
                        Id: true,
                        Text: this.$translate.instant('General.User')
                    }, {
                        Id: false,
                        Text: this.$translate.instant('Views.Management.Persons.RemecareMail')
                    }
                ];
            };
            SendResetMailController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.securityApiSvc.sendResetMail(this.partyId, this.$scope.sendToUser)
                    .success(function () {
                    _this.$scope.$close();
                    _this.generalSuccess();
                })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return SendResetMailController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('sendResetMailCtrl', function ($scope, $translate, toaster, partyId, securityApiSvc) { return new SendResetMailController($scope, $translate, toaster, partyId, securityApiSvc); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
