/// <reference path="careTeamPartyBaseController.ts"/>

namespace RemeCare.Patient {
    import CareTeamParty = Contract.HealthCareParty.Read.ICareTeamParty;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import TypeAndSpecialty = RemeCare.Contract.HealthCareParty.Read.Query.ITypeAndSpecialty;
    import CareTeamPartyAssignment = Contract.HealthCareParty.Read.ICareTeamPartyAssignment;

    class CareTeamProfessionalsController extends CareTeamPartyBaseController {
        private specialityTypes: EnumTranslation[];
        private professionalTypes: EnumTranslation[];

        // @ngInject
        constructor(
            protected $scope: ng.IScope,
            protected $translate: ng.translate.ITranslateService,
            protected $filter: ng.IFilterService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: PatientService,
            protected authservice: Shared.Framework.AuthService,
            protected partyApiSvc: Core.Services.PartyApiService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService
        ) {
            super(
                $scope,
                $translate,
                $filter,
                toaster,
                patientSvc,
                authservice,
                partyApiSvc,
                modalBuilderFactory,
                gridBuilderSvc
            );
        }

        public getActiveCarePlans(party: CareTeamParty): string {
            return _(
                party.Assignments.filter(
                    (as) => as.CarePlan.Status.Id === Shared.Contract.Code.CarePlanStatus.Open
                ).map((a) => a.CarePlan.Name)
            )
                .unique()
                .join(', ');
        }

        public toggleActiveTeamMembers(): void {
            this.partyOverviewGrid.search();
        }

        public async showCareTeamPartyDetail(partyRoleId: Shared.Contract.Guid): Promise<void> {
            try {
                const personSummaries = await this.partyApiSvc.findPersonSummaryAsync(partyRoleId);
                const healthCareParty: Contract.HealthCareParty.Read.IHealthCareProfessionalParty = {
                    FirstName: personSummaries.FirstName,
                    LastName: personSummaries.LastName,
                    PartyRoleId: personSummaries.PartyRoleId,
                    ZipCode: personSummaries.ZipCode,
                    City: personSummaries.City,
                    AddressLine: personSummaries.AddressLine,
                    Email: personSummaries.Email,
                    IdentificationNumber: personSummaries.NationalNumber,
                    PhoneNumber: personSummaries.Telephone,
                    MobileNumber: personSummaries.Mobile,
                    HealthCareSpecialtyProfessions: undefined,
                    PartyId: personSummaries.PartyId,
                };
                this.showCareTeamProfessionalDetailModalAsync(healthCareParty);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected async init(): Promise<void> {
            try {
                [this.professionalTypes, this.specialityTypes] = await Promise.all([
                    this.healthCarePartySvc.findHealthCareProfessionalTypesAsync(),
                    this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                ]);
                this.buildGrids();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected buildOverviewGrid(): void {
            const builder = this.gridBuilderSvc
                .createGridBuilder<CareTeamParty>((p, ps, sf, sd, c) =>
                    this.searchCareTeamAsync(
                        p,
                        ps,
                        sf,
                        sd,
                        c,
                        Shared.Contract.Code.PartyType.Person,
                        (cp: CareTeamParty) => {
                            if (this.onlyActive) {
                                return _(cp.Assignments).any(this.isActiveAssignment);
                            } else {
                                return true;
                            }
                        }
                    )
                )
                .addColumn('LastName', 'General.LastName')
                .addColumn('Name', 'General.FirstName')
                .addActionColumn('info-sign', (p) => this.showCareTeamPartyDetail(p.PartyRoleId))
                .addActionOnScopeColumn('$ctrl.parent.getTypeAndSpecialities', 'General.Type')
                .addActionOnScopeColumn('$ctrl.parent.getActiveCarePlans', 'General.CarePlans.Active')
                .addShowDetailButtonColumn((p) => this.showPartyAssignments(p, Shared.Contract.Code.PartyType.Person));

            this.partyOverviewGrid = builder.build();
            this.partyOverviewGrid.pagingOptions.pageSize = 50;
            this.partyOverviewGrid.search();
        }

        protected async showPartySelector(
            actorRole: ITherapyActorRole,
            professional: Shared.Contract.Read.IActorRoleInclusionDetail[]
        ): Promise<void> {
            this.modalBuilderFactory
                .showSelector<Shared.Contract.Read.IActorRoleInclusionDetail>(
                    professional,
                    ['PartyName'],
                    ' ',
                    actorRole.Name
                )
                .done(async (pr) => {
                    try {
                        const personSummary = await this.partyApiSvc.findPersonSummaryAsync(pr.PartyRoleId);
                        const healthCareParty: Contract.HealthCareParty.Read.IHealthCareProfessionalParty = {
                            FirstName: personSummary.FirstName,
                            LastName: personSummary.LastName,
                            PartyRoleId: personSummary.PartyRoleId,
                            ZipCode: personSummary.ZipCode,
                            City: personSummary.City,
                            AddressLine: personSummary.AddressLine,
                            Email: personSummary.Email,
                            IdentificationNumber: personSummary.NationalNumber,
                            PhoneNumber: personSummary.Telephone,
                            MobileNumber: personSummary.Mobile,
                            HealthCareSpecialtyProfessions: undefined,
                            PartyId: personSummary.PartyId,
                        };
                        const careTeamParty = this.createProfessionalCareTeamParty(actorRole, healthCareParty);
                        this.showCareTeamAssignmentModalAsync(
                            careTeamParty.Assignments[0],
                            healthCareParty.PartyRoleId,
                            healthCareParty.FirstName,
                            healthCareParty.LastName
                        );
                    } catch (e) {
                        this.toaster.error(e);
                    }
                });
        }

        protected showPartySearchModal(actorRole: ITherapyActorRole): void {
            const specialties = this.convertToTypeAndSpecialties(this.getActorRoleInclusionDetails(actorRole));

            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>()
                .setController('searchHealthCareProfessionalPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                .setScope({
                    title: actorRole.Name,
                    typeAndSpecialties: specialties,
                })
                .setResultCallBack((r) => {
                    const careTeamParty = this.createProfessionalCareTeamParty(actorRole, r);
                    this.showCareTeamAssignmentModalAsync(
                        careTeamParty.Assignments[0],
                        r.PartyRoleId,
                        r.FirstName,
                        r.LastName
                    );
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        // Used in addActionOnScopeColumn above
        private getTypeAndSpecialities(party: CareTeamParty): string {
            let res = '';

            if (party.HealthCareProfessionalTypeId) {
                res += _(this.professionalTypes.filter((t) => t.Id === party.HealthCareProfessionalTypeId)).first()
                    .Text;
            }

            if (party.SpecialityTypeIds && party.SpecialityTypeIds.length !== 0) {
                res += ` (${party.SpecialityTypeIds.map(
                    (s) => _(this.specialityTypes.filter((t) => t.Id === s)).first().Text
                ).join(', ')})`;
            }

            return res;
        }

        private showCareTeamProfessionalDetailModalAsync(
            healthCareParty: Contract.HealthCareParty.Read.IHealthCareProfessionalParty
        ): Promise<void> {
            return new Promise<void>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/careTeam/careTeamProfessionalDetail.html')
                    .setScope({
                        healthCareParty,
                    })
                    .setResultCallBack(() => resolve())
                    .build();
            });
        }

        private convertToTypeAndSpecialties(
            inclusions: _Chain<Shared.Contract.Read.IActorRoleInclusionDetail>
        ): TypeAndSpecialty[] {
            return inclusions
                .filter((i) => i.HealthCareProfessionalTypeId != null)
                .map((i) => {
                    return {
                        healthCareProfessionalTypeId: i.HealthCareProfessionalTypeId,
                        healthCareSpecialtyProfessionId: i.HealthCareSpecialtyProfessionId,
                    };
                })
                .value();
        }

        private createProfessionalCareTeamParty(
            actorRole: ITherapyActorRole,
            hcProfessional: Contract.HealthCareParty.Read.IHealthCareProfessionalParty
        ): CareTeamParty {
            const result = this.createCareTeamParty(actorRole, hcProfessional);
            result.Name = hcProfessional.FirstName;
            result.LastName = hcProfessional.LastName;
            return result;
        }
    }

    remeCarePatientModule.component('rcCareTeamProfessionals', {
        bindings: {
            actorRoles: '<',
            patientId: '<',
            editRight: '<',
            onlyActive: '<',
            careTeamsCanEdit: '<',
        },
        controller: CareTeamProfessionalsController,
        templateUrl: 'views/patient/careTeam/careTeamProfessionals.html',
    });
}
