var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var AddHospitalisationCtrl = /** @class */ (function (_super) {
            __extends(AddHospitalisationCtrl, _super);
            function AddHospitalisationCtrl($scope, $translate, toaster, 
            //private title: string,
            careTypes, reasons) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.careTypes = careTypes;
                _this.reasons = reasons;
                //$scope.title = title;
                $scope.careTypes = careTypes;
                $scope.reasons = reasons;
                _this.$scope.now = new Date();
                $scope.hospitalisation = new RemeCare.Model.Hospitalisation();
                $scope.getDuration = function () { return _this.getDuration(); };
                $scope.setOther = function () { return _this.setOther(); };
                $scope.validHospitalisationStartDate = function () { return _this.validHospitalisationStartDate(); };
                $scope.validHospitalisationEndDate = function () { return _this.validHospitalisationEndDate(); };
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            AddHospitalisationCtrl.prototype.$onInit = function () { };
            AddHospitalisationCtrl.prototype.confirm = function () {
                this.$scope.showErrors = true;
                if (this.$scope.newHospitalisationForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.hospitalisation);
            };
            AddHospitalisationCtrl.prototype.getDuration = function () {
                return this.$scope.hospitalisation.getDuration();
            };
            AddHospitalisationCtrl.prototype.validHospitalisationStartDate = function () {
                var today = new Date();
                var dateWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                if (this.$scope.hospitalisation.startDate != null) {
                    if (this.$scope.hospitalisation.startDate > dateWithoutTime)
                        return false;
                    else
                        return true;
                }
                else
                    return false;
            };
            AddHospitalisationCtrl.prototype.validHospitalisationEndDate = function () {
                if (this.$scope.hospitalisation.startDate != null && this.$scope.hospitalisation.endDate) {
                    if (this.$scope.hospitalisation.endDate >= this.$scope.hospitalisation.startDate)
                        return true;
                    else
                        return false;
                }
                else
                    return false;
            };
            AddHospitalisationCtrl.prototype.setOther = function () {
                //display div other only if other reason is selected
                var divOtherReason = document.getElementById("OtherReason");
                if (this.$scope.hospitalisation.hospitalisationReason.Id.toUpperCase() !== 'E09020E8-4645-481F-A709-BC5DE3ACF14C')
                    divOtherReason.style.display = "none";
                else
                    divOtherReason.style.display = "block";
            };
            return AddHospitalisationCtrl;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.CareRequest')
            .controller('addHospitalisationCtrl', function ($scope, $translate, toaster, careTypes, reasons) { return new AddHospitalisationCtrl($scope, $translate, toaster, careTypes, reasons); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
