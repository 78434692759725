var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var DateController = /** @class */ (function (_super) {
            __extends(DateController, _super);
            // @ngInject
            function DateController($scope, guidanceApiSvc) {
                return _super.call(this, $scope, guidanceApiSvc) || this;
            }
            DateController.prototype.init = function () {
                this.$scope.showTime = this.$scope.component.hasTime;
                this.$scope.showUntil = this.$scope.component.dateType === RemeCare.Shared.Contract.Code.DateTypeCode.Range;
            };
            return DateController;
        }(CarePlanAction.ComponentControllerBase));
        var DateDirective = /** @class */ (function (_super) {
            __extends(DateDirective, _super);
            function DateDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/date.html';
                _this.controller = DateController;
                return _this;
            }
            return DateDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        angular.module('RemeCare.CarePlanAction').directive('rcDate', function () { return new DateDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
