var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var MedicationType;
                (function (MedicationType) {
                    MedicationType[MedicationType["Other"] = 0] = "Other";
                    MedicationType[MedicationType["Ace"] = 1] = "Ace";
                    MedicationType[MedicationType["Arb"] = 2] = "Arb";
                    MedicationType[MedicationType["BetaBlocker"] = 3] = "BetaBlocker";
                    MedicationType[MedicationType["Diuretic"] = 4] = "Diuretic";
                })(MedicationType = Codes.MedicationType || (Codes.MedicationType = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
