var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SearchPersonsController = /** @class */ (function () {
            function SearchPersonsController(toaster, masterdataSvc, healthCarePartySvc, partyApiSvc, gridBuilderSvc, authservice) {
                this.toaster = toaster;
                this.masterdataSvc = masterdataSvc;
                this.healthCarePartySvc = healthCarePartySvc;
                this.partyApiSvc = partyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.authservice = authservice;
            }
            SearchPersonsController.prototype.$onInit = function () {
                this.buildGrid();
                this.loadDataAsync();
            };
            SearchPersonsController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                                        this.masterdataSvc.getCountriesAsync(),
                                        this.masterdataSvc.getCommLanguagesAsync(),
                                        this.masterdataSvc.getLanguagesAsync(),
                                        this.masterdataSvc.getApplicationProfilesAsync(),
                                        this.masterdataSvc.getGendersAsync(),
                                        this.masterdataSvc.getPartyRoleTypesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.specialties = _a[0], this.countries = _a[1], this.communicationLanguages = _a[2], this.languages = _a[3], this.applicationProfiles = _a[4], this.genders = _a[5], this.partyRoleTypes = _a[6];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SearchPersonsController.prototype.buildGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.searchPersonsAsync(page, pageSize, sortField, sortDirection, criteria);
                })
                    .setBindToUrl(this.bindToUrl)
                    .addColumn('LastName', 'General.LastName')
                    .addColumn('FirstName', 'General.FirstName')
                    .setSearchCriteria(function (c) {
                    c.phoneNumber =
                        c.phoneNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.phoneNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.phoneNumber);
                });
                if (this.onPersonSelected == null) {
                    builder = builder.addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date:"shortDate"' });
                    if (this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientInternal, RemeCare.Shared.Framework.AuthRight.Read)) {
                        builder = builder.addColumn('PatientNumber', 'Patient.PatientNumber');
                    }
                    builder = builder
                        .addColumn('partyRoleTypes', 'General.Capacity', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                        .addColumn('applicationProfiles', 'Views.Management.Persons.ApplicationProfile', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                        .addNavigationColumn('eye-open', 'management.persons.detail', { partyId: 'PartyId' });
                }
                else {
                    builder = builder.addColumn('partyRoleTypes', 'General.Capacity', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    });
                    if (this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientInternal, RemeCare.Shared.Framework.AuthRight.Read)) {
                        builder = builder.addColumn('PatientNumber', 'Patient.PatientNumber');
                    }
                    builder = builder
                        .addColumn('healthCareProfessionalTypes', 'General.Type', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                        .addColumn('specialties', 'General.Specialty', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                        .addSelectButtonColumn(function (o) { return _this.onPersonSelected({ person: o }); });
                }
                this.grid = builder.build();
            };
            SearchPersonsController.prototype.searchPersonsAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, persons, contractPerson, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    firstName: criteria.firstName,
                                    lastName: criteria.lastName,
                                    identificationNumber: criteria.identificationNumber,
                                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(criteria.birthDate),
                                    communicationLanguage: criteria.communicationLanguage,
                                    language: criteria.language,
                                    gender: criteria.gender,
                                    address: criteria.address,
                                    zipCode: criteria.zipCode,
                                    city: criteria.city,
                                    country: criteria.country,
                                    nationality: criteria.nationality,
                                    email: criteria.email,
                                    isUser: criteria.isUser,
                                    applicationProfile: criteria.applicationProfile,
                                    phoneNumber: criteria.phoneNumber ? criteria.phoneNumber.toServerWrite() : null,
                                    partyRoleType: criteria.partyRoleType,
                                    healthCareSpecialtyProfessionId: criteria.partyRoleType === 3 ? criteria.healthCareSpecialtyProfessionId : null,
                                    patientNumber: criteria.patientNumber,
                                    includeRemovedUser: criteria.includeRemovedUser,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.findPersonsAsync(query)];
                            case 2:
                                persons = _a.sent();
                                contractPerson = {
                                    Items: _(persons.Items).map(function (i) { return _this.map(i); }),
                                    Total: persons.Total,
                                };
                                this.searchCollapsed = true;
                                return [2 /*return*/, contractPerson];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            SearchPersonsController.prototype.map = function (person) {
                var result = person;
                var partyRoleTypes = _(person.PartyRoles).map(function (pr) { return pr.Type; });
                var applicationProfiles = _(this.applicationProfiles).filter(function (ap) {
                    return _(person.ApplicationProfiles).contains(ap.Id);
                });
                result.partyRoleTypes = partyRoleTypes;
                result.applicationProfiles = applicationProfiles;
                result.healthCareProfessionalTypes = result.PartyRoles.map(function (pr) { return pr.HealthCareProfessionalType; }).filter(function (x) { return x != null; });
                result.specialties = _.flatten(result.PartyRoles.map(function (pr) { return pr.HealthCareProffesionalSpecialties; }).filter(function (x) { return x != null; }), true);
                return result;
            };
            return SearchPersonsController;
        }());
        Management.remeCareManagementModule.component('rcPersonSearch', {
            controller: SearchPersonsController,
            templateUrl: 'views/management/persons/personSearch.html',
            bindings: {
                onPersonSelected: '&?',
                bindToUrl: '@?',
            },
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
