module RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import SearchResult = Shared.Contract.ISearchResult;
    import IOrganisation = Contract.Party.Read.IOrganisation;

    class SearchOrganisationsController implements ng.IComponentController {
        public organisationTypes: Array<EnumTranslation>;
        public countries: Array<EnumTranslation>;
        public grid: Shared.Framework.Grid.Grid<IOrganisation>;
        public searchCollapsed: boolean;
        public bindToUrl: boolean;
        public onOrganisationSelected: (params: { organisation: IOrganisation }) => void;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [this.countries, this.organisationTypes] = await Promise.all([
                    this.masterdataSvc.getCountriesAsync(),
                    this.healthCarePartySvc.getHealthCareOrganisationTypesAsync()
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<IOrganisation>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.searchPersonsAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .setBindToUrl(this.bindToUrl)
                .addColumn('Name', 'General.Name')
                .addColumn('HealthCareOrganisationType.Text', 'General.Type')
                .addColumn('City', 'General.City')
                .setSearchCriteria(c => {
                    c.phoneNumber = new Model.PhoneNumber();
                });

            if (this.onOrganisationSelected == null) {
                builder = builder.addNavigationColumn('eye-open', 'management.organisations.detail', { partyId: 'Id' });
            } else {
                builder = builder.addSelectButtonColumn(o => this.onOrganisationSelected({ organisation: o }));
            }
            this.grid = builder.build();
        }

        private async searchPersonsAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<SearchResult<IOrganisation>> {
            const query = <Contract.Party.Read.Query.IFindOrganisationsQuery>_.clone(criteria);
            query.page = page;
            query.pageSize = pageSize;
            query.sortField = sortField;
            query.sortOrder = sortDirection;
            try {
                const result = await this.partyApiSvc.findOrganisationsAsync(query);
                this.searchCollapsed = true;
                return result;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }
    }

    remeCareManagementModule.component('rcOrganisationSearch', {
        controller: SearchOrganisationsController,
        templateUrl: 'views/management/organisations/organisationSearch.html',
        bindings: {
            onOrganisationSelected: '&?',
            bindToUrl: '@?'
        }
    });
}
