var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ObjectiveDefinition = RemeCare.Shared.Framework.Model.ObjectiveDefinition;
        var RuleThreshold = RemeCare.Shared.Framework.Model.RuleThresholdDefinition;
        var PatientFileObjectivesAndThresholdsController = /** @class */ (function (_super) {
            __extends(PatientFileObjectivesAndThresholdsController, _super);
            function PatientFileObjectivesAndThresholdsController($scope, $translate, toaster, $stateParams, carePlanApiSvc, modalBuilderFactory, gridBuilderSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                return _this;
            }
            PatientFileObjectivesAndThresholdsController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.$scope.patientId = this.$stateParams.patientId;
                                this.buildObjectivesGrid();
                                this.buildThresholdsGrid();
                                if (!this.$scope.carePlan) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.getObjectivesAsync()];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, this.getThresholdsAsync()];
                            case 2:
                                _a.sent();
                                this.$scope.editRight = this.$scope.carePlan.statusOpen;
                                _a.label = 3;
                            case 3:
                                this.$scope.$watch('carePlan', function (newValue, oldValue) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!(newValue !== oldValue && newValue !== null)) return [3 /*break*/, 3];
                                                return [4 /*yield*/, this.getObjectivesAsync()];
                                            case 1:
                                                _a.sent();
                                                return [4 /*yield*/, this.getThresholdsAsync()];
                                            case 2:
                                                _a.sent();
                                                this.$scope.editRight = this.$scope.carePlan.statusOpen;
                                                _a.label = 3;
                                            case 3: return [2 /*return*/];
                                        }
                                    });
                                }); });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectivesAndThresholdsController.prototype.getObjectivesAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getObjectivesAsync(this.$scope.carePlan.id)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.objectives = _(ags).map(function (x) { return new ObjectiveDefinition(x); });
                                this.setObjectivesGridData();
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.errorCallback(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectivesAndThresholdsController.prototype.getThresholdsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getRuleThresholdsAsync(this.$scope.carePlan.id)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.thresholds = _(ags).map(function (x) { return new RuleThreshold(x); });
                                this.setThresholdsGridData();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.errorCallback(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileObjectivesAndThresholdsController.prototype.buildObjectivesGrid = function () {
                var _this = this;
                this.$scope.objectivesGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('name', 'General.Name')
                    .addColumn('characteristic.Text', 'Configuration.Therapies.Monitoring.Characteristic')
                    .addColumn('period', 'Views.PatientFile.CarePlans.Duration', { cellFilter: 'rcDurationDisplay' })
                    .addColumn('getCurrentObjectiveValue().amount', 'Views.PatientFile.CarePlans.ObjectiveValue')
                    .addColumn('type.Text', 'Views.PatientFile.CarePlans.CalculationMethod')
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (o) { return _this.editObjectiveAsync(o); }, function (o) { return _this.editObjectiveAsync(o); }, function (o) { return !o.isPersonalisable || o.readOnly || !_this.$scope.editRight; })
                    .setExternalSorting(false)
                    .build();
                this.setObjectivesGridData();
            };
            PatientFileObjectivesAndThresholdsController.prototype.buildThresholdsGrid = function () {
                var _this = this;
                this.$scope.thresholdsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('name', 'General.Name')
                    .addColumn('getCurrentRuleThresholdValueString()', 'Views.PatientFile.CarePlans.RuleThresholdValue')
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (t) { return _this.editThresholdAsync(t); }, function (t) { return _this.editThresholdAsync(t); }, function (t) { return !t.isPersonalisable || t.readOnly || !_this.$scope.editRight; })
                    .setExternalSorting(false)
                    .build();
                this.setThresholdsGridData();
            };
            PatientFileObjectivesAndThresholdsController.prototype.setThresholdsGridData = function () {
                this.$scope.thresholdsGrid.setData(this.$scope.thresholds);
            };
            PatientFileObjectivesAndThresholdsController.prototype.editThresholdAsync = function (threshold) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('PatientFileRuleThresholdOverviewCtrl')
                        .setTemplateUrl('views/patient/carePlans/objectivesThresholds/ruleThresholdOverview.html')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        threshold: threshold,
                        carePlan: _this.$scope.carePlan,
                        readOnly: !threshold.isPersonalisable || threshold.readOnly || !_this.$scope.editRight,
                    })
                        .setResultCallBack(function (r) { return resolve(r); })
                        .build();
                });
            };
            PatientFileObjectivesAndThresholdsController.prototype.setObjectivesGridData = function () {
                this.$scope.objectivesGrid.setData(this.$scope.objectives);
            };
            PatientFileObjectivesAndThresholdsController.prototype.editObjectiveAsync = function (objective) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('PatientFileObjectiveOverviewCtrl')
                        .setTemplateUrl('views/patient/carePlans/objectivesThresholds/objectiveOverview.html')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        objective: objective,
                        carePlan: _this.$scope.carePlan,
                        readOnly: !objective.isPersonalisable || objective.readOnly || !_this.$scope.editRight,
                    })
                        .setResultCallBack(function (o) { return resolve(o); })
                        .build();
                });
            };
            return PatientFileObjectivesAndThresholdsController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular
            .module('RemeCare.Patient')
            .controller('PatientFileObjectivesAndThresholdsCtrl', function ($scope, $translate, toaster, $stateParams, carePlanApiSvc, modalBuilderFactory, gridBuilderSvc) {
            return new PatientFileObjectivesAndThresholdsController($scope, $translate, toaster, $stateParams, carePlanApiSvc, modalBuilderFactory, gridBuilderSvc);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
