module RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import RuleThreshold = Shared.Framework.Model.RuleThresholdDefinition;
    import RuleThresholdValue = Shared.Framework.Model.RuleThresholdValue;
    import ChoiceListOption = Shared.Contract.IChoiceListOption;


    interface IPatientFileAddRuleThresholdValueModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        carePlan: CarePlan;

        threshold: RuleThreshold;
        defaultValue: RuleThresholdValue;
        displayValue: string;

        previousValue: RuleThresholdValue;

        newValue: RuleThresholdValue;

        newValidFromDate: Date;
        backToDefault: boolean;

        form: ng.IFormController;

        codeSetItems: Array<ChoiceListOption>;

        minDate: Date;
        maxDate: Date;

        editRight: boolean;
        
        formatCodeSetItem(csi: ChoiceListOption): string;
        confirm: () => void;
    }

    class PatientFileAddRuleThresholdValueModalController extends Shared.Framework.ControllerBase<IPatientFileAddRuleThresholdValueModalScope> {

        constructor(
            $scope: IPatientFileAddRuleThresholdValueModalScope,
            $translate,
            toaster,
            private $dialog,
            private $stateParams,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private masterdataSvc: Shared.Framework.MasterdataService 
        ) {
            super($scope, $translate, toaster);
            this.$scope.formatCodeSetItem = (csi: ChoiceListOption) => this.formatCodeSetItem(csi);
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            var minDate = moment().add(1, 'days').startOf('day').toDate();
            this.$scope.previousValue = _(_(_(this.$scope.threshold.ruleThresholdValues).filter(v => v.isPersonalised)).sortBy(v => v.validFromDate)).last();
            this.$scope.minDate = this.$scope.previousValue && moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() > minDate ? moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() : minDate;

            this.$scope.newValue = new RuleThresholdValue();

            this.$scope.displayValue = this.$scope.defaultValue.getValueString();
            if (this.$scope.threshold.codeSet) {
                this.$scope.codeSetItems = [];
                this.masterdataSvc.getCodeSetItems(this.$scope.threshold.codeSet.Id)
                    .success((ags) => {
                        this.$scope.codeSetItems = ags;
                    })
                    .error((m) => this.errorCallback(m));
            } else {
                if (this.$scope.threshold.unit)
                    this.$scope.displayValue += ' ' + this.$scope.threshold.unit.Text;
            }
        }

        private formatCodeSetItem(csi: ChoiceListOption): string {
            return csi.Name + ' (' + csi.NumericValue + ')';
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            if (this.$scope.backToDefault) {
                this.$dialog.confirmBox('Views.PatientFile.CarePlans.BackToDefault', 'Views.PatientFile.CarePlans.BackToDefaultConfirmMessage',
                    () => {
                        this.$scope.previousValue.validUntilDate = moment(this.$scope.newValidFromDate).add(-1, 'day').toDate();
                        this.carePlanApiSvc.updatePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.previousValue.toServerWrite())
                            .success(() => { this.$scope.$close() })
                            .error((m) => this.errorCallback(m));
                    }
                );
            } else {
                this.$scope.newValue.validFromDate = this.$scope.newValidFromDate;
                this.carePlanApiSvc.savePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.newValue.toServerWrite())
                    .success(() => { this.$scope.$close() })
                    .error((m) => this.errorCallback(m));
            }
        }
    }

    angular.module('RemeCare.Patient').controller('PatientFileAddRuleThresholdValueModalCtrl', (
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        carePlanApiSvc: Core.Services.CarePlanApiService,
        masterdataSvc: Shared.Framework.MasterdataService
    ) => new PatientFileAddRuleThresholdValueModalController(
            $scope,
            $translate,
            toaster,
            $dialog,
            $stateParams,
            carePlanApiSvc,
            masterdataSvc
    ));
}