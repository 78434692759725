var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var StructuralTherapyActionPersonalisation = /** @class */ (function () {
            function StructuralTherapyActionPersonalisation(serverObject) {
                var _this = this;
                this.configuredTherapyActions = [];
                this.contextSettingVersions = [];
                this.personalisableContextSettings = [];
                this.defaultConfigurations = [];
                if (serverObject) {
                    this.configuredTherapyActions = _(serverObject.ConfiguredTherapyActions)
                        .chain()
                        .map(function (cta) { return new ConfiguredTherapyAction(cta); })
                        .sortBy(function (cta) { return cta.validFromDate; })
                        .value();
                    this.personalisableContextSettings = _(serverObject.PersonalisableContextSettings)
                        .chain()
                        .map(function (ptapcs) { return new PersonalisableTherapyActionPartContextSetting(ptapcs); })
                        .sortBy(function (ptapcs) { return ptapcs.sequence; })
                        .value();
                    this.contextSettingVersions = _(serverObject.ContextSettingVersions)
                        .chain()
                        .map(function (ptacs) {
                        return new PersonalisedTherapyActionContextSetting(ptacs, _this.personalisableContextSettings);
                    })
                        .sortBy(function (ptacs) { return ptacs.validFromDate; })
                        .value();
                    this.defaultConfigurations = _(serverObject.DefaultConfigurations)
                        .chain()
                        .map(function (dc) { return new ConfiguredTherapyAction(dc); })
                        .sortBy(function (dc) { return dc.validFromDate; })
                        .value();
                }
            }
            return StructuralTherapyActionPersonalisation;
        }());
        Patient.StructuralTherapyActionPersonalisation = StructuralTherapyActionPersonalisation;
        var ConfiguredTherapyAction = /** @class */ (function () {
            function ConfiguredTherapyAction(serverObject) {
                this.periodicities = [];
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    this.isPersonalised = serverObject.IsPersonalised;
                    this.isRemovedFromCarePlan = serverObject.IsRemovedFromCarePlan;
                    var periodicityFactory_1 = new RemeCare.Shared.Framework.Factory.PeriodicityFactory();
                    this.periodicities = _(serverObject.Periodicities).map(function (p) { return periodicityFactory_1.createPeriodicity(p); });
                    this.isPeriodicitiesPersonalised = serverObject.IsPeriodicitiesPersonalised;
                    this.isIntradayTimingPersonalised = serverObject.IsIntradayTimingPersonalised;
                    this.possibleActionDurationOptionName = serverObject.PossibleActionDurationOptionName;
                    this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                    this.durationOfAction = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
                }
            }
            ConfiguredTherapyAction.prototype.canBeEdited = function () {
                return !this.validFromDate || moment(this.validFromDate).isAfter(moment());
            };
            ConfiguredTherapyAction.prototype.copy = function () {
                var result = new ConfiguredTherapyAction();
                result.id = this.id;
                result.validFromDate = this.validFromDate;
                result.validUntilDate = this.validUntilDate;
                result.isPersonalised = this.isPersonalised;
                result.isPeriodicitiesPersonalised = this.isPeriodicitiesPersonalised;
                result.isRemovedFromCarePlan = this.isRemovedFromCarePlan;
                result.periodicities = _(this.periodicities).map(function (p) { return p.copy(); });
                result.resetToDefault = this.resetToDefault;
                result.isIntradayTimingPersonalised = this.isIntradayTimingPersonalised;
                result.durationOfAction = this.durationOfAction;
                result.possibleActionDurationOptionName = this.possibleActionDurationOptionName;
                result.possibleActionDurationOptionId = this.possibleActionDurationOptionId;
                return result;
            };
            ConfiguredTherapyAction.prototype.toServerWrite = function () {
                return {
                    personalisedTherapyActionId: this.id,
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    isRemovedFromCarePlan: this.isRemovedFromCarePlan,
                    periodicities: _(this.periodicities).map(function (p) { return p.toWriteModel(); }),
                    resetToDefault: this.resetToDefault,
                    possibleActionDurationOptionId: this.possibleActionDurationOptionId,
                    durationOfAction: RemeCare.Shared.DateHelper.toServerTimeString(this.durationOfAction),
                };
            };
            return ConfiguredTherapyAction;
        }());
        Patient.ConfiguredTherapyAction = ConfiguredTherapyAction;
        var PersonalisedTherapyActionContextSetting = /** @class */ (function () {
            function PersonalisedTherapyActionContextSetting(serverObject, personalisableContextSettings) {
                this.contextSettings = [];
                this.personalisableContextSettings = [];
                if (serverObject != null) {
                    this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                    this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    this.setContextSettings(_(serverObject.ContextSettings).map(function (cs) { return new PersonalisedTherapyActionPartContextSetting(cs, personalisableContextSettings); }));
                }
            }
            PersonalisedTherapyActionContextSetting.prototype.setContextSettings = function (personalisedTherapyActionPartContextSetting) {
                this.contextSettings = personalisedTherapyActionPartContextSetting;
                this.personalisableContextSettings = _(personalisedTherapyActionPartContextSetting).filter(function (cs) { return cs.canPersonaliseToHidePart || cs.canPersonaliseTelemonitoringDeactivation; });
            };
            PersonalisedTherapyActionContextSetting.prototype.canBeEdited = function () {
                return !this.validFromDate || moment(this.validFromDate).isAfter(moment());
            };
            PersonalisedTherapyActionContextSetting.prototype.toServerWrite = function () {
                return {
                    validFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                    personalisedTherapyActionPartContextSettings: _(this.contextSettings).map(function (cs) { return cs.toServerWrite(); }),
                };
            };
            return PersonalisedTherapyActionContextSetting;
        }());
        Patient.PersonalisedTherapyActionContextSetting = PersonalisedTherapyActionContextSetting;
        var PersonalisedTherapyActionPartContextSetting = /** @class */ (function () {
            function PersonalisedTherapyActionPartContextSetting(serverObject, personalisableContextSettings) {
                var _this = this;
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.isHidden = serverObject.IsHidden;
                    this.usesNoTelemonitoring = serverObject.UsesNoTelemonitoring;
                    this.therapyActionPartId = serverObject.TherapyActionPartId;
                    var contextSetting = _(personalisableContextSettings).find(function (pcs) { return pcs.therapyActionPartId === _this.therapyActionPartId; });
                    this.canPersonaliseToHidePart = contextSetting.canPersonaliseToHidePart;
                    this.canPersonaliseTelemonitoringDeactivation = contextSetting.canPersonaliseTelemonitoringDeactivation;
                    this.sequence = contextSetting.sequence;
                    this.name = contextSetting.name;
                }
            }
            PersonalisedTherapyActionPartContextSetting.prototype.toServerWrite = function () {
                return {
                    personalisedTherapyActionPartContextSettingId: this.id,
                    therapyActionPartId: this.therapyActionPartId,
                    isHidden: this.isHidden,
                    usesNoTelemonitoring: this.usesNoTelemonitoring,
                };
            };
            return PersonalisedTherapyActionPartContextSetting;
        }());
        Patient.PersonalisedTherapyActionPartContextSetting = PersonalisedTherapyActionPartContextSetting;
        var PersonalisableTherapyActionPartContextSetting = /** @class */ (function () {
            function PersonalisableTherapyActionPartContextSetting(serverObject) {
                if (serverObject != null) {
                    this.therapyActionPartId = serverObject.TherapyActionPartId;
                    this.canPersonaliseToHidePart = serverObject.CanPersonaliseToHidePart;
                    this.canPersonaliseTelemonitoringDeactivation = serverObject.CanPersonaliseTelemonitoringDeactivation;
                    this.sequence = serverObject.Sequence;
                    this.name = serverObject.Name;
                }
            }
            return PersonalisableTherapyActionPartContextSetting;
        }());
        Patient.PersonalisableTherapyActionPartContextSetting = PersonalisableTherapyActionPartContextSetting;
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
