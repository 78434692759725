namespace RemeCare.Patient {
    interface IPersonalisedActionScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        readOnly: boolean;
        personalisedAction: StructuralTherapyActionPersonalisation;
        carePlanId: Shared.Contract.Guid;
        structuralTherapyAction: Contract.Therapy.Read.IStructuralTherapyActionSummary;
        tabs: Array<{
            heading: string;
            src: string;
        }>;
        actions: {
            reload(): void;
        };
        editRights: {
            readOnly: boolean;
        };
    }

    class PersonalisedActionController extends Shared.Framework.ControllerBase<IPersonalisedActionScope> {
        constructor(
            protected $scope: IPersonalisedActionScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            $scope.actions = {
                reload: () => this.reload(),
            };
        }

        public async $onInit(): Promise<void> {
            this.$scope.editRights = {
                readOnly: this.$scope.readOnly,
            };
            this.$scope.tabs = [];

            try {
                const r = await this.carePlanApiSvc.getPersonalisedActionAsync(
                    this.$scope.carePlanId,
                    this.$scope.structuralTherapyAction.Id
                );
                this.$scope.personalisedAction = new StructuralTherapyActionPersonalisation(r);
                if (
                    this.$scope.structuralTherapyAction.CanPersonalisePeriodicity ||
                    this.$scope.structuralTherapyAction.CanPersonaliseDeviationPlanning ||
                    (this.$scope.structuralTherapyAction.Sequence === 1 &&
                        !this.$scope.structuralTherapyAction.IsMandatoryInCarePlan) ||
                    _(this.$scope.personalisedAction.configuredTherapyActions).any(cta => cta.isPersonalised)
                ) {
                    this.$scope.tabs.push({
                        heading: 'General.Action',
                        src: 'views/patient/carePlans/carePlanActions/action.html',
                    });
                }
                if (
                    _(this.$scope.personalisedAction.personalisableContextSettings).any(
                        pcs => pcs.canPersonaliseTelemonitoringDeactivation || pcs.canPersonaliseToHidePart
                    )
                ) {
                    this.$scope.tabs.push({
                        heading: 'General.ActionPart',
                        src: 'views/patient/carePlans/carePlanActions/actionPart.html',
                    });
                }
            } catch (e) {
                this.errorCallback(e);
            }
        }

        private async reload(): Promise<void> {
            try {
                this.$scope.personalisedAction = new StructuralTherapyActionPersonalisation(
                    await this.carePlanApiSvc.getPersonalisedActionAsync(
                        this.$scope.carePlanId,
                        this.$scope.structuralTherapyAction.Id
                    )
                );
            } catch (e) {
                this.errorCallback(e);
            }
        }
    }

    remeCarePatientModule.controller(
        'personalisedActionController',
        ($scope, $translate, toaster, carePlanApiSvc) =>
            new PersonalisedActionController($scope, $translate, toaster, carePlanApiSvc)
    );
}
