module RemeCare.Management {

    class LinkedExternalIdentitiyProviderController implements ng.IComponentController {

        // bindings
        public buttonLabel: string;
        public linkedProvider: Contract.Security.ILinkedExternalIdentityProvider;
        public onButtonClicked: (args: { provider: Contract.Security.ILinkedExternalIdentityProvider }) => void;

        public $onInit(): void { }

        public buttonClicked(): void {
            this.onButtonClicked({
                provider: this.linkedProvider
            });
        }
    }

    remeCareManagementModule.component('rcLinkedExternalIdentityProvider', {
        bindings: {
            buttonLabel: '@',
            linkedProvider: '<',
            onButtonClicked: '&',
        },
        controller: LinkedExternalIdentitiyProviderController,
        templateUrl: 'views/management/persons/userDetails/linkedExternalIdentityProvider.html',
    });
}