var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var DynamicActionControllerDirective = /** @class */ (function () {
            function DynamicActionControllerDirective($compile, $templateCache) {
                var _this = this;
                this.$compile = $compile;
                this.$templateCache = $templateCache;
                this.restict = 'E';
                this.scope = {
                    action: '=',
                    registeredCallback: '&',
                    cancelledCallback: '&',
                    registerFunctions: '&',
                    fullSize: '=',
                    editRight: '=',
                };
                this.link = function (scope, element) {
                    _this.configure(scope);
                    _this.render(scope, element);
                    scope.$watch('action', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            _this.configure(scope);
                            _this.render(scope, element);
                        }
                    });
                };
            }
            DynamicActionControllerDirective.prototype.configure = function (scope) {
                if (scope.action == null) {
                    return;
                }
                scope.phoneNumber =
                    scope.action.patient != null
                        ? new RemeCare.Framework.PhoneNumber(scope.action.patient.Telephone).toString()
                        : '';
                scope.cellPhoneNumber =
                    scope.action.patient != null ? new RemeCare.Framework.PhoneNumber(scope.action.patient.Mobile).toString() : '';
            };
            DynamicActionControllerDirective.prototype.render = function (scope, element) {
                var html = '';
                if (scope.action != null) {
                    var controller = 'defaultCarePlanActionCtrl';
                    var view = 'views/carePlanAction/carePlanActionDetail.html';
                    if (scope.action.customScreen) {
                        var split = scope.action.customScreen.split('/');
                        controller = split[0];
                        for (var i = 1; i < split.length; i++) {
                            split[i] = "" + split[i].charAt(0).toUpperCase() + split[i].substr(1);
                            controller += split[i];
                        }
                        controller += 'Ctrl';
                        view = "views/custom/" + scope.action.customScreen + ".html";
                    }
                    scope.actionView = view;
                    html = this.$templateCache.get('views/carePlanAction/dynamicActionController.html').replace('$$controller$$', controller);
                }
                element.html(html).show();
                this.$compile(element.contents())(scope);
            };
            return DynamicActionControllerDirective;
        }());
        CarePlanAction.remeCareCarePlanActionModule.directive('dynamicActionController', function ($compile, $templateCache) {
            return new DynamicActionControllerDirective($compile, $templateCache);
        });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
