var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var DateHelper = RemeCare.Shared.DateHelper;
        var GroupController = /** @class */ (function () {
            function GroupController(toaster, partyApiSvc, gridBuilderSvc, $dialog, $translate, carePlanApiSvc, $state) {
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.$dialog = $dialog;
                this.$translate = $translate;
                this.carePlanApiSvc = carePlanApiSvc;
                this.$state = $state;
            }
            GroupController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var groupId, groupDetails, carePlansForGroup;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                groupId = this.$transition$.params().groupId;
                                this.buildGrids();
                                if (!(groupId !== null)) return [3 /*break*/, 3];
                                this.isReadOnly = true;
                                return [4 /*yield*/, this.partyApiSvc.getGroupDetailsAsync(groupId)];
                            case 1:
                                groupDetails = _a.sent();
                                this.group = new Management.Group(groupDetails);
                                this.organisationsGrid.setData(this.group.organisations);
                                this.personsGrid.setData(this.group.persons);
                                return [4 /*yield*/, this.carePlanApiSvc.getGroupRelatedCarePlansAsync(groupId)];
                            case 2:
                                carePlansForGroup = _a.sent();
                                this.carePlansLinkedToGroup = carePlansForGroup;
                                this.linkedCarePlans = _.chain(this.carePlansLinkedToGroup)
                                    .groupBy(function (x) { return x.Id; })
                                    .size()
                                    .value();
                                this.linkedTherapies = _.chain(this.carePlansLinkedToGroup)
                                    .groupBy(function (x) { return x.TherapyId; })
                                    .size()
                                    .value();
                                return [3 /*break*/, 4];
                            case 3:
                                this.group = new Management.Group();
                                this.group.validFromDate = RemeCare.Shared.DateHelper.today();
                                _a.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            GroupController.prototype.getNumberOfTherapies = function () {
                if (this.linkedTherapies != null && this.linkedCarePlans) {
                    return this.linkedTherapies.toString().concat(" " + this.$translate.instant('General.Therapies'));
                }
            };
            GroupController.prototype.getNumberOfCarePlans = function () {
                if (this.linkedCarePlans != null && this.linkedCarePlans) {
                    return this.linkedCarePlans.toString().concat(" " + this.$translate.instant('General.CarePlans'));
                }
            };
            GroupController.prototype.searchOrganisation = function () {
                this.personAdd = false;
                this.organisationAdd = true;
            };
            GroupController.prototype.searchPerson = function () {
                this.organisationAdd = false;
                this.personAdd = true;
            };
            GroupController.prototype.buildGrids = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('organisation.Name', 'General.Name')
                    .addColumn('organisation.HealthCareOrganisationType.Text', 'General.Type')
                    .addColumn('organisation.City', 'General.City')
                    .addDateColumn('validFromDate', 'General.StartDate', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.EndDate', 'shortDate')
                    .addCheckBoxColumnFunction('Views.Management.Active', 'isActive', function (x) { return _this.canNotEditItem(x); }, function (ga) { return _this.stopGroupAssignment(ga); })
                    .addConditionalShowDeleteButtonColumn(function (x) { return !_this.canNotDeleteItem(x); }, function (x) { return _this.removeOrganisation(x.partyId); });
                this.organisationsGrid = builder.build();
                var personBuilder = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('person.LastName', 'General.LastName')
                    .addColumn('person.FirstName', 'General.FirstName')
                    .addColumn('person.partyRoleTypes', 'General.Capacity', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                    .addColumn('person.healthCareProfessionalTypes', 'General.Type', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                    .addColumn('person.specialties', 'General.Specialty', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false
                })
                    .addDateColumn('validFromDate', 'General.StartDate', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.EndDate', 'shortDate')
                    .addCheckBoxColumnFunction('Views.Management.Active', 'isActive', function (x) { return _this.canNotEditItem(x); }, function (ga) { return _this.stopGroupAssignment(ga); })
                    .addConditionalShowDeleteButtonColumn(function (x) { return !_this.canNotDeleteItem(x); }, function (x) { return _this.removeOrganisation(x.partyId); });
                this.personsGrid = personBuilder.build();
            };
            GroupController.prototype.stopGroupAssignment = function (ga) {
                var _this = this;
                if (ga.validUntilDate === null) {
                    this.$dialog
                        .warningMessageBox(this.$translate.instant('General.Cancel'), this.$translate.instant('Views.Management.StopMemberWarning'), [
                        { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                        { result: 'yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                    ])
                        .then(function (result) {
                        if (result === 'yes') {
                            _this.partyApiSvc
                                .stopGroupAssignment(_this.group.id, ga.id)
                                .success(function () {
                                ga.isActive = false;
                                ga.isNew = false;
                                return (ga.validUntilDate = RemeCare.Shared.DateHelper.today());
                            })
                                .error(function (e) { return _this.toaster.error(e); });
                        }
                    });
                    ga.isActive = true;
                    return ga;
                }
                else {
                    this.$dialog
                        .warningMessageBox(this.$translate.instant('General.Cancel'), this.$translate.instant('Views.Management.ReActivateMemberWarning'), [
                        { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                        { result: 'yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                    ])
                        .then(function (result) {
                        if (result === 'yes') {
                            _this.partyApiSvc
                                .reActivateGroupMember(_this.group.id, ga.id)
                                .success(function (guid) {
                                var newGa = new Management.GroupAssignment();
                                newGa.id = guid;
                                newGa.validFromDate = RemeCare.Shared.DateHelper.today();
                                newGa.validUntilDate = null;
                                newGa.groupId = ga.groupId;
                                newGa.partyId = ga.partyId;
                                newGa.party = ga.party;
                                newGa.organisation = ga.organisation;
                                newGa.person = ga.person;
                                newGa.name = ga.name;
                                newGa.groupName = ga.groupName;
                                newGa.nameAndGroup = ga.nameAndGroup;
                                newGa.type = ga.type;
                                newGa.isActive = true;
                                newGa.isNew = false;
                                if (newGa.organisation === null) {
                                    _this.group.persons.push(newGa);
                                    _this.personsGrid.setData(_.sortBy(_this.group.persons, function (x) { return x.person.LastName; }));
                                }
                                else {
                                    _this.group.organisations.push(newGa);
                                    _this.organisationsGrid.setData(_.sortBy(_this.group.organisations, function (x) { return x.organisation.Name; }));
                                }
                            })
                                .error(function (e) { return _this.toaster.error(e); });
                        }
                    });
                    ga.isActive = false;
                    return ga;
                }
            };
            GroupController.prototype.canNotEditItem = function (selected) {
                if (selected.validUntilDate !== null) {
                    var doesOrganisationAlreadyexistWithoutValidityDate = _.some(this.group.organisations, function (x) {
                        return x.partyId === selected.partyId && x.validUntilDate === null;
                    });
                    var doesPersonAlreadyExistWithoutValidityDate = _.some(this.group.persons, function (x) {
                        return x.partyId === selected.partyId && x.validUntilDate === null;
                    });
                    return doesOrganisationAlreadyexistWithoutValidityDate || doesPersonAlreadyExistWithoutValidityDate;
                }
                return selected.isNew;
            };
            GroupController.prototype.canNotDeleteItem = function (selected) {
                return selected.isNew;
            };
            GroupController.prototype.removeOrganisation = function (id) {
                this.group.organisations = this.group.organisations.filter(function (ga) { return ga.partyId !== id; });
            };
            GroupController.prototype.addOrganisation = function (organisation) {
                if (_(this.group.organisations).some(function (gm) {
                    return gm.organisation.Id === organisation.Id &&
                        (gm.validUntilDate === null ||
                            moment(gm.validUntilDate).isSame(DateHelper.endOfTime()) ||
                            moment(DateHelper.today()).isBefore(gm.validUntilDate));
                })) {
                    this.toaster.error(this.$translate.instant('Views.Management.OverlapMembers'));
                    return;
                }
                var groupAssignment = new Management.GroupAssignment();
                groupAssignment.partyId = organisation.Id;
                groupAssignment.validFromDate = RemeCare.Shared.DateHelper.today();
                groupAssignment.validUntilDate = null;
                groupAssignment.organisation = organisation;
                this.group.organisations.push(groupAssignment);
                this.organisationAdd = false;
                this.organisationsGrid.setData(_.sortBy(this.group.organisations, function (x) { return x.organisation.Name; }));
            };
            GroupController.prototype.addPerson = function (person) {
                if (_(this.group.persons).some(function (gm) {
                    return gm.person.PartyId === person.PartyId &&
                        (gm.validUntilDate === null ||
                            moment(gm.validUntilDate).isSame(DateHelper.endOfTime()) ||
                            moment(DateHelper.today()).isBefore(gm.validUntilDate));
                })) {
                    this.toaster.error(this.$translate.instant('Views.Management.OverlapMembers'));
                    return;
                }
                var groupAssignment = new Management.GroupAssignment();
                groupAssignment.partyId = person.PartyId;
                groupAssignment.validFromDate = RemeCare.Shared.DateHelper.today();
                groupAssignment.validUntilDate = null;
                groupAssignment.person = person;
                this.personAdd = false;
                this.group.persons.push(groupAssignment);
                this.personsGrid.setData(_.sortBy(this.group.persons, function (x) { return x.person.LastName; }));
            };
            GroupController.prototype.confirm = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var err_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.group.persons.length === 0 && this.group.organisations.length === 0) {
                                    this.toaster.error(this.$translate.instant('Views.Management.NoMembersError'));
                                    return [2 /*return*/];
                                }
                                if (this.group.validFromDate === null) {
                                    this.group.validFromDate = RemeCare.Shared.DateHelper.today();
                                }
                                if (_.some(this.group.organisations, function (o) { return o.validFromDate < _this.group.validFromDate; }) ||
                                    _.some(this.group.persons, function (p) { return p.validFromDate < _this.group.validFromDate; })) {
                                    this.toaster.error(this.$translate.instant('Views.Management.MemberBefore'));
                                    return [2 /*return*/];
                                }
                                this.infoForm.$setSubmitted();
                                if (this.infoForm.$invalid) {
                                    this.infoForm.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.createGroupAsync(this.group.toServerWrite())];
                            case 2:
                                _a.sent();
                                this.$state.go('management.groups');
                                return [3 /*break*/, 4];
                            case 3:
                                err_1 = _a.sent();
                                this.toaster.error(err_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            GroupController.prototype.cancel = function () {
                var _this = this;
                this.$dialog
                    .warningMessageBox(this.$translate.instant('General.Cancel'), this.$translate.instant('Views.Management.CancelWarning'), [
                    { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                    { result: 'Yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                ])
                    .then(function (result) {
                    if (result === 'Yes') {
                        _this.$state.go('management.groups.search');
                    }
                });
            };
            GroupController.prototype.endGroup = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.$dialog.warningMessageBox(this.$translate.instant('Views.Management.StopGroup'), this.$translate.instant('Views.Management.StopGroupWarning'), [
                                    { result: 'No', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                                    { result: 'Yes', label: this.$translate.instant('General.Ok'), cssClass: 'btn-primary' }
                                ])];
                            case 1:
                                result = _a.sent();
                                if (!(result === 'Yes')) return [3 /*break*/, 5];
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, 4, , 5]);
                                return [4 /*yield*/, this.partyApiSvc.stopGroupAsync(this.group.id)];
                            case 3:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.Management.StopGroupSuccess'));
                                this.$state.go('management.groups.search');
                                return [3 /*break*/, 5];
                            case 4:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            return GroupController;
        }());
        Management.remeCareManagementModule.component('rcGroup', {
            controller: GroupController,
            bindings: {
                $transition$: '<'
            },
            templateUrl: 'management/controllers/groups/groups/group.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
