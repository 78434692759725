var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Connections;
    (function (Connections) {
        var FitbitCallbackController = /** @class */ (function () {
            function FitbitCallbackController($log, storageSvc, $stateParams, toaster, $state, connectionsApi) {
                this.$log = $log;
                this.storageSvc = storageSvc;
                this.$stateParams = $stateParams;
                this.toaster = toaster;
                this.$state = $state;
                this.connectionsApi = connectionsApi;
            }
            FitbitCallbackController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var patientId, fitbitAuthCode, state, codeVerifier, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                fitbitAuthCode = this.$stateParams.code;
                                state = this.$stateParams.state;
                                codeVerifier = this.storageSvc.get(RemeCare.Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier, false);
                                patientId = RemeCare.Shared.Base64Helper.base64urldecode(state);
                                if (!codeVerifier) {
                                    this.$log.error("Failed to get codeVerifier from local storage");
                                    this.error = true;
                                    return [2 /*return*/];
                                }
                                if (!patientId) {
                                    this.$log.error("Failed to get patientId from state " + state);
                                    this.error = true;
                                    return [2 /*return*/];
                                }
                                // Add the new fitbit connection to the database
                                return [4 /*yield*/, this.connectionsApi.postConnection(patientId, fitbitAuthCode, codeVerifier, RemeCare.Shared.Contract.Code.ConnectionType.Fitbit)];
                            case 1:
                                // Add the new fitbit connection to the database
                                _a.sent();
                                this.storageSvc.remove(RemeCare.Shared.Framework.Service.StorageServiceConstants.fitbitPkceVerifier, false);
                                // Redirect to the patient file page where we can see the fitbit connection
                                this.$state.go('patients.patientfile.patientInfo.personalInfo', { patientId: patientId });
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.$log.error(e_1);
                                this.toaster.error(e_1);
                                this.error = true;
                                if (patientId) {
                                    this.$state.go('patients.patientfile.patientInfo.personalInfo', { patientId: patientId });
                                }
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return FitbitCallbackController;
        }());
        Connections.remeCareConnectionsModule.component('rcFitbitCallback', {
            controller: FitbitCallbackController,
            templateUrl: 'connections/component/fitbit/fitbitCallback.html',
        });
    })(Connections = RemeCare.Connections || (RemeCare.Connections = {}));
})(RemeCare || (RemeCare = {}));
