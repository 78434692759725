var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var CalendarController = /** @class */ (function () {
            function CalendarController($compile, $locale, $scope, $state, $timeout, authservice, uiCalendarConfig) {
                this.$compile = $compile;
                this.$locale = $locale;
                this.$scope = $scope;
                this.$state = $state;
                this.$timeout = $timeout;
                this.authservice = authservice;
                this.uiCalendarConfig = uiCalendarConfig;
                this.calendarItems = [];
            }
            CalendarController.prototype.$onInit = function () {
                var _this = this;
                this.currentState = this.$state.current;
                this.$timeout(function () {
                    _this.configureCalendar();
                    _this.configureDateSelectors();
                    _this.highlightDates();
                });
            };
            CalendarController.prototype.$onChanges = function (changes) {
                this.mapCalendarItems(changes['agendaItems'].currentValue);
            };
            CalendarController.prototype.configureDateSelectors = function () {
                this.dateSelector = $('#dateSelector');
                this.datePopoverSelector = $('#datePopoverSelector');
                this.configureDateSelector(this.dateSelector);
                this.configureDateSelector(this.datePopoverSelector);
            };
            CalendarController.prototype.configureDateSelector = function (selector) {
                var _this = this;
                if (selector.length === 0)
                    return;
                selector = selector
                    .datetimepicker({
                    inline: true,
                    calendarWeeks: true,
                    locale: this.$locale.id,
                    format: 'DD/MM/YYYY'
                });
                selector.on('dp.change', function (e) { return _this.onDateSelected(e); });
                selector.on('dp.update', function () { return _this.highlightDates(); });
            };
            CalendarController.prototype.toggleDateSelector = function () {
                var _this = this;
                this.showDateSelector = !this.showDateSelector;
                this.$timeout(function () {
                    if (_this.showDateSelector) {
                        _this.configureDateSelectors();
                        _this.highlightDates();
                    }
                });
            };
            CalendarController.prototype.onDateSelected = function (e) {
                this.getCalendar().fullCalendar('gotoDate', e.date);
                this.showDateSelector = false;
            };
            CalendarController.prototype.getCalendarStartDate = function () {
                return this.getCalendar().fullCalendar('getView').intervalStart.toDate();
            };
            CalendarController.prototype.getCalendarEndDate = function () {
                return this.getCalendar().fullCalendar('getView').intervalEnd.toDate();
            };
            CalendarController.prototype.highlightDates = function () {
                this.setInactive(this.dateSelector);
                this.setInactive(this.datePopoverSelector);
                var start = moment(this.getCalendarStartDate());
                var end = moment(this.getCalendarEndDate());
                do {
                    var formatted = start.format('L');
                    this.setActive(this.dateSelector, formatted);
                    this.setActive(this.datePopoverSelector, formatted);
                    start.add(1, 'days');
                } while (start.startOf('day').isBefore(end.startOf('day')));
            };
            CalendarController.prototype.setInactive = function (selector) {
                selector.find('td.day').removeClass('active');
            };
            CalendarController.prototype.setActive = function (selector, formatted) {
                selector.find("td.day[data-day=\"" + formatted + "\"]").addClass('active');
            };
            CalendarController.prototype.configureCalendar = function () {
                var _this = this;
                this.calendar = {
                    editable: false,
                    header: false,
                    eventRender: function (ev, el) { return _this.eventRender(ev, el); },
                    eventAfterRender: function (ev, el) { return _this.eventAfterRender(ev, el); },
                    lang: this.$locale.id,
                    viewRender: function (v) { return _this.viewRender(v); },
                    allDaySlot: false,
                    slotEventOverlap: false,
                    defaultView: 'agendaWeek',
                    fixedWeekCount: false,
                    height: 'auto'
                };
            };
            CalendarController.prototype.getCalendar = function () {
                return this.uiCalendarConfig.calendars.agenda;
            };
            CalendarController.prototype.changeView = function (view) {
                this.getCalendar().fullCalendar('changeView', view);
            };
            CalendarController.prototype.goToToday = function () {
                this.getCalendar().fullCalendar('today');
            };
            CalendarController.prototype.goToPrevious = function () {
                this.getCalendar().fullCalendar('prev');
            };
            CalendarController.prototype.goToNext = function () {
                this.getCalendar().fullCalendar('next');
            };
            CalendarController.prototype.viewRender = function (view) {
                this.currentView = view.name;
                if (view.name === 'month') {
                    this.getCalendar().fullCalendar('option', 'height', 'auto');
                }
                else {
                    this.getCalendar().fullCalendar('option', 'height', null);
                }
                this.filter.fromDate = RemeCare.Shared.DateHelper.dayOfDate(view.start.toDate());
                // calendar component takes end as not-inclusive, so we have to subtract one day
                this.filter.untilDate = RemeCare.Shared.DateHelper.dayOfDate(view.end.add(-1, 'days').toDate());
                if (this.dateSelector) {
                    this.dateSelector.data('DateTimePicker').date(view.intervalStart.startOf('day'));
                    this.highlightDates();
                }
                this.search();
            };
            CalendarController.prototype.eventRender = function (event, element) {
                if (element.hasClass('fc-not-start')) {
                    element.html("<a ng-if=\"action.canBeViewed\" ui-sref=\"editAction({actionIds:[action.id], referringState: calendarCtrl.currentState})\" class=\"agenda-item\">" + event.title + "</a>" +
                        ("<span ng-if=\"!action.canBeViewed\">" + event.title + "</span>"));
                }
                else {
                    element.html("<a ng-if=\"action.canBeViewed\" ui-sref=\"editAction({actionIds:[action.id], referringState: calendarCtrl.currentState})\" class=\"agenda-item\">" + event.title + "</a>" +
                        ("<span ng-if=\"!action.canBeViewed\">" + event.title + "</span>"));
                    element.prepend("<span ng-if=\"action.canBeEdited\" ng-show=\"action.status < " + RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed + "\"><input checklist-model=\"calendarCtrl.selectedActions\" checklist-value=\"action.id\" type=\"checkbox\"></input></span>");
                    element.prepend("<i ng-show=\"action.status == " + RemeCare.Contract.Core.Codes.CarePlanActionStatus.Overdue + "\" class=\"glyphicon glyphicon-exclamation-sign red\"></i> ");
                    element.prepend("<i ng-show=\"action.status == " + RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed + "\" class=\"glyphicon glyphicon-ok green\"></i> ");
                }
                var scope = this.$scope.$new();
                scope.action = event.data;
                this.$compile(element)(scope);
            };
            CalendarController.prototype.eventAfterRender = function (event, element) {
                element.off('click');
            };
            CalendarController.prototype.mapCalendarItems = function (actions) {
                var _this = this;
                this.calendarItems.pop();
                var calendarActions = _(actions).filter(function (a) { return !a.showAsToDo; });
                switch (this.currentView) {
                    case 'month':
                        this.calendarHasTooManyItems = calendarActions.length > 500;
                        break;
                    case 'agendaWeek':
                        this.calendarHasTooManyItems = calendarActions.length > 100;
                        break;
                    default:
                        this.calendarHasTooManyItems = calendarActions.length > 20;
                        break;
                }
                if (this.calendarHasTooManyItems)
                    return;
                var calendarItems = _(calendarActions).map(function (a) { return _this.mapCalendarItem(a); });
                this.calendarItems.push(calendarItems);
            };
            CalendarController.prototype.mapCalendarItem = function (action) {
                var start;
                var end;
                if (action.status === RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed
                    && action.eventDateTimeFrom != null && action.eventDateTimeUntil != null
                    && action.eventDateTimeFrom.valueOf() !== action.eventDateTimeUntil.valueOf()) {
                    start = action.eventDateTimeFrom;
                    end = action.eventDateTimeUntil;
                }
                else {
                    if (action.plannedDateTimeFrom.valueOf() !== action.plannedDateTimeUntil.valueOf()) {
                        start = action.plannedDateTimeFrom;
                        end = action.plannedDateTimeUntil;
                    }
                    else if (action.openEventWindowDateTime != null && action.closeEventWindowDateTime != null
                        && action.openEventWindowDateTime.valueOf() !== action.closeEventWindowDateTime.valueOf()) {
                        start = action.openEventWindowDateTime;
                        end = action.closeEventWindowDateTime;
                    }
                    else {
                        start = end = action.suggestedDateTime;
                    }
                }
                return {
                    title: this.authservice.isPatient() ? action.name : action.name + " - " + action.patient,
                    start: start,
                    end: end,
                    data: action
                };
            };
            return CalendarController;
        }());
        Agenda.remeCareAgendaModule.component('rcAgendaCalendar', {
            controller: CalendarController,
            controllerAs: 'calendarCtrl',
            templateUrl: 'views/agenda/calendar.html',
            bindings: {
                agendaItems: '<',
                filter: '=',
                search: '&',
                selectedActions: '='
            }
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
