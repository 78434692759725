namespace RemeCare.CarePlanAction {
    export class CarePlanActionHistory {
        public auditId: number;
        public auditAction: string;
        public auditDate: string;
        public modificationUser: string;
        public plannedDateTimeFrom: string;
        public plannedDateTimeUntil: string;
        public eventDateTimeFrom?: string;
        public eventDateTimeUntil?: string;
        public location: Contract.CarePlanAction.Read.ILocation;
        public locationString: string;
        public status: number;
        public statusChangedRemark: string;

        constructor(serverObject: Contract.CarePlanAction.Read.ICarePlanActionHistory) {
            this.auditId = serverObject.AuditId;
            this.auditAction = this.mapAction(serverObject.AuditAction);
            this.auditDate = serverObject.AuditDate;
            this.modificationUser = serverObject.ModificationUser;
            this.plannedDateTimeFrom = serverObject.PlannedDateTimeFrom;
            this.plannedDateTimeUntil = serverObject.PlannedDateTimeUntil;
            this.eventDateTimeFrom = serverObject.EventDateTimeFrom;
            this.eventDateTimeUntil = serverObject.EventDateTimeUntil;
            this.status = serverObject.Status;
            this.statusChangedRemark = serverObject.StatusChangedRemark;
            this.location = serverObject.Location;
            this.locationString = this.mapLocation(serverObject.Location);
        }

        public setAddress(address: Contract.Party.Read.IAddress): void {
            this.locationString = this.mapAddress(address);
        }

        private mapAddress(address: Contract.Party.Read.IAddress): string {
            let mapped = '';
            if (address) {
                if (address.AddressLine1) {
                    mapped += address.AddressLine1 + ' ';
                }
                if (address.ZipCode) {
                    mapped += address.ZipCode + ' ';
                }
                if (address.City) {
                    mapped += address.City;
                }
            }

            return mapped;
        }

        private mapLocation(location: Contract.CarePlanAction.Read.ILocation): string {
            let mapped = '';
            if (location) {
                if (location.Street) {
                    mapped += location.Street + ' ';
                }
                if (location.ZipCode) {
                    mapped += location.ZipCode + ' ';
                }
                if (location.City) {
                    mapped += location.City;
                }
            }

            return mapped;
        }

        private mapAction(auditAction: string): string {
            switch (auditAction) {
                case 'I':
                    return 'General.Added';
                case 'U':
                    return 'General.Updated';
                case 'D':
                    return 'General.Deleted';
                default:
                    return auditAction;
            }
        }
    }
}
