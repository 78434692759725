module RemeCare.Management {

    class OrganisationExternalIdentityProviders implements ng.IComponentController {

        public $onInit(): void {

        }
    }

    remeCareManagementModule.component('rcOrganisationExternalIdentityProviders',
        {
            bindings: {
                providers: '<',
                linkedChanged: '&'
            },
            templateUrl: 'views/management/organisations/externalIdentityProviders.html'
        });
}