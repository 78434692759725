var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestMedicationAdvancedController = /** @class */ (function (_super) {
            __extends(CareRequestMedicationAdvancedController, _super);
            function CareRequestMedicationAdvancedController($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$timeout = $timeout;
                _this.periodicityFactory = periodicityFactory;
                _this.masterdataSvc = masterdataSvc;
                _this.daySvc = daySvc;
                $scope.minEndDate = function () { return _this.minEndDate(); };
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            CareRequestMedicationAdvancedController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.hideIntraDayConfig = true;
                if (this.$scope.isAdHoc) {
                    if (this.$scope.commLanguage) {
                        this.masterdataSvc.getInterfaceLanguages()
                            .success(function (r) {
                            var langCode = _.find(r, function (x) { return x.Code === _this.$scope.commLanguage; }).Code;
                            _this.masterdataSvc.getTranslation(langCode, 'Views.Patient.Medication.IfNeeded')
                                .success(function (trans) {
                                _this.createAdHocPeriodicity(trans['Views.Patient.Medication.IfNeeded']);
                            })
                                .error(function (e) { return _this.errorCallback(e); });
                        })
                            .error(function (e) { return _this.errorCallback(e); });
                    }
                    else {
                        this.createAdHocPeriodicity(this.$translate.instant('Views.Patient.Medication.IfNeeded'));
                    }
                }
            };
            CareRequestMedicationAdvancedController.prototype.createAdHocPeriodicity = function (unStructuredPeriodicity) {
                this.$scope.careRequestMedication.periodicities = [];
                this.$scope.careRequestMedication.periodicities.push(this.periodicityFactory.createPeriodicitiesFromAdHoc(unStructuredPeriodicity, 1));
            };
            CareRequestMedicationAdvancedController.prototype.minEndDate = function () {
                return this.$scope.careRequestMedication.id && moment().isAfter(moment(this.$scope.careRequestMedication.validFromDate), 'day')
                    ? this.today
                    : this.$scope.careRequestMedication.validFromDate;
            };
            CareRequestMedicationAdvancedController.prototype.confirm = function () {
                if (this.$scope.medicationForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.isForTherapyAction && !this.$scope.isAdHoc
                    && (this.$scope.careRequestMedication.periodicities == null || _(this.$scope.careRequestMedication.periodicities).size() === 0)) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.PeriodicityRequired'));
                    return;
                }
                if (this.$scope.isForTherapyAction && !this.$scope.isAdHoc
                    && _(this.$scope.careRequestMedication.periodicities).any(function (x) { return _(x.intraDayTimings).size() === 0; })) {
                    this.toaster.error(this.$translate.instant('Views.CareRequest.Details.IntraDayTimingRequired'));
                    return;
                }
                this.$scope.$close(this.$scope.careRequestMedication);
            };
            return CareRequestMedicationAdvancedController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule
            .controller('careRequestMedicationAdvancedCtrl', function ($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc) { return new CareRequestMedicationAdvancedController($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
