namespace RemeCare.Patient {
    import ActionTemplate = Contract.Patient.Read.IActionTemplate;
    import Grid = Shared.Framework.Grid.Grid;

    class AdhocRegistrationsController implements ng.IComponentController {

        // bindings
        public $transition$;

        public templatesGrid: Grid<ActionTemplate>;

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientSvc: Patient.PatientService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly authservice: Shared.Framework.AuthService
            ) {}

        public async $onInit(): Promise<void> {
            try {
                const nonPlannedActionTemplates = await this.patientSvc.getNonPlannedActionTemplatesAsync(this.$transition$.params().patientId)
                this.buildGrid(nonPlannedActionTemplates);
            } catch(e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(templates: ActionTemplate[]): void {
            this.templatesGrid = this.gridBuilderSvc.createGridBuilder<ActionTemplate>()
                .addColumn('Name', 'Views.PatientFile.Registration')
                .addColumn('Therapy.Text', 'General.CarePlan')
                .addActionColumn('pencil', t => this.makeAdhocRegistration(t))
                .build();
            this.templatesGrid.setData(templates);
        }

        private makeAdhocRegistration(template: ActionTemplate): void {
            this.modalBuilderFactory.createModalBuilder<any>()
                .setController('adhocRegistrationModalCtrl')
                .setTemplateUrl('views/patient/monitoring/registrations/adhocRegistrationModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    action: new Patient.Model.ActionTemplate(this.authservice, this.$transition$.params().patientId, angular.copy(template))
                })
                .build();
        }
    }

    remeCarePatientModule.component('rcAdhocRegistrations', {
        bindings: {
            $transition$: '<',
        },
        controller: AdhocRegistrationsController,
        templateUrl: 'patient/components/monitoring/registrations/adhocRegistrations/adhocRegistrations.html',
    });
}