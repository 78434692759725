/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MonitoringPartSourceType = RemeCare.Shared.Contract.Code.MonitoringPartSourceType;
        var FloatingBarGraphController = /** @class */ (function (_super) {
            __extends(FloatingBarGraphController, _super);
            // @ngInject
            function FloatingBarGraphController($rootScope, $locale, dateHelper, spinnerSvc, toaster, $q, carePlanApiSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.toaster = toaster;
                _this.$q = $q;
                _this.carePlanApiSvc = carePlanApiSvc;
                return _this;
            }
            FloatingBarGraphController.prototype.init = function () {
                this.lowParameter = _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; })
                    .sortBy(function (p) { return p.sequence; })
                    .first()
                    .value();
                this.highParameter = _(this.monitoringPart.monitoringPartSources)
                    .chain()
                    .filter(function (mps) { return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter; })
                    .sortBy(function (p) { return p.sequence; })
                    .last()
                    .value();
                this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
                this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();
                this.chartConfigs = [
                    {
                        options: {
                            chart: {
                                animation: false,
                                type: 'line',
                                alignTicks: true,
                                height: 200,
                                zoomType: 'x',
                                spacingTop: 40,
                                marginLeft: 37,
                                marginRight: 37,
                            },
                            xAxis: this.getXAxisConfig(null, true),
                            credits: {
                                enabled: false,
                            },
                            exporting: {
                                enabled: false,
                            },
                            tooltip: {
                                xDateFormat: this.getDateFormat(false),
                                shared: true,
                            },
                            legend: {
                                enabled: this.showLegend,
                            },
                        },
                        series: [],
                        title: {
                            text: null,
                        },
                    },
                ];
                return this.$q.resolve();
            };
            FloatingBarGraphController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, oneMonthEarlier, pointsPromise, referencePromise, graphPoints, referencePoints, low, high, graph, references, error_1;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                oneMonthEarlier = moment(this.dateInfo.untilDate);
                                oneMonthEarlier.subtract(1, 'months');
                                this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
                                this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(false);
                                pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(this.carePlanIds, this.quantitativeMeasuringPoints, this.dateInfo.fromDate, this.getUntilDate(), this.showMultiplePerDay);
                                referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(this.carePlanIds, this.quantitativeReferencePoints, this.dateInfo.fromDate, this.getUntilDate());
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                graphPoints = void 0;
                                referencePoints = void 0;
                                return [4 /*yield*/, Promise.all([pointsPromise, referencePromise])];
                            case 2:
                                _a = _b.sent(), graphPoints = _a[0], referencePoints = _a[1];
                                low = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.lowParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.lowParameter.unit == null) ||
                                            (g.Scale != null &&
                                                _this.lowParameter.unit != null &&
                                                g.Scale.Id === _this.lowParameter.unit.Id));
                                });
                                high = _.find(graphPoints, function (g) {
                                    return g.Subject.Id === _this.highParameter.characteristic.Id &&
                                        ((g.Scale == null && _this.highParameter.unit == null) ||
                                            (g.Scale != null &&
                                                _this.highParameter.unit != null &&
                                                g.Scale.Id === _this.highParameter.unit.Id));
                                });
                                graph = new Patient.Model.BarGraph(low, high, !this.showMultiplePerDay);
                                references = _(referencePoints).map(function (g) { return new Patient.Model.NumberGraph(g, true, true); });
                                this.configureChart(graph, references);
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _b.sent();
                                this.toaster.error(error_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            FloatingBarGraphController.prototype.containsData = function (config) {
                return !_(config.series).all(function (s) {
                    return _(s.data).all(function (d) { return d.high == null && d.low == null; });
                });
            };
            FloatingBarGraphController.prototype.configureChart = function (graph, references) {
                var _this = this;
                this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
                this.chartConfigs[0].options.yAxis = this.getYAxis(graph, references);
                var series = _(references).map(function (r) { return _this.getLineDataSeries(r); });
                series.unshift(this.getBarDataSeries(graph));
                this.chartConfigs[0].series = series;
                this.chartConfigs[0].loading = false;
            };
            FloatingBarGraphController.prototype.getYAxis = function (graph, references) {
                var _this = this;
                var maxes = _(references)
                    .chain()
                    .map(function (r) { return _this.getMaxYScaleValue(r); })
                    .filter(function (m) { return m != null; })
                    .value();
                maxes.push(this.getMaxYScaleValue(graph));
                var max = _(maxes).max();
                var mins = _(references)
                    .chain()
                    .map(function (r) { return _this.getMinYScaleValue(r); })
                    .filter(function (m) { return m != null; })
                    .value();
                mins.push(this.getMinYScaleValue(graph));
                var min = _(mins).min();
                return {
                    title: {
                        align: 'high',
                        offset: 0,
                        rotation: 0,
                        text: graph.scale != null ? graph.scale.Text : null,
                        y: -20,
                        x: -27,
                        textAlign: 'left',
                    },
                    max: max,
                    min: min,
                    id: graph.scale != null ? graph.scale.Id : 'unscaled',
                    startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                    endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                    labels: {
                        align: 'right',
                        x: 0,
                        y: -2,
                    },
                };
            };
            FloatingBarGraphController.prototype.getBarDataSeries = function (graph) {
                var _this = this;
                var monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) {
                    return mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale);
                });
                var chartBar = monitoringPartSource.sourceParameterRepresentation;
                return {
                    animation: false,
                    type: 'columnrange',
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataBar(gp); })
                        .sortBy(function (gp) { return gp.x; })
                        .value(),
                    color: chartBar.colour,
                    name: graph.subject.Text,
                    dataLabels: {
                        xHigh: -2,
                        xLow: -2,
                        yLow: -13,
                        yHigh: 13,
                        defer: true,
                    },
                    maxPointWidth: 20,
                    pointPlacement: 'on',
                };
            };
            FloatingBarGraphController.prototype.getLineDataSeries = function (graph) {
                var _this = this;
                var monitoringPartSource = _.find(this.monitoringPart.monitoringPartSources, function (mps) {
                    return (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                        mps.hasObjective(graph.subject) ||
                        mps.hasRuleThreshold(graph.subject);
                });
                var chartLine = monitoringPartSource.sourceParameterRepresentation;
                return {
                    animation: false,
                    type: 'line',
                    data: _(graph.graphPoints)
                        .chain()
                        .map(function (gp) { return _this.getDataPoint(gp); })
                        .sortBy(function (gp) { return gp[0]; })
                        .value(),
                    color: chartLine.colour,
                    name: graph.subject.Text,
                    lineWidth: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Thin ? 1 : 2,
                    dashStyle: chartLine.lineType.Id === RemeCare.Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                    step: true,
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 1,
                    },
                    tooltip: {
                        xDateFormat: this.getDateFormat(false),
                    },
                };
            };
            FloatingBarGraphController.prototype.getDataBar = function (graphPoint) {
                var lowExceedsThreshold = graphPoint.y.low != null && graphPoint.y.low.ExceedsThreshold;
                var highExceedsThreshold = graphPoint.y.high != null && graphPoint.y.high.ExceedsThreshold;
                var warning = '<i class="fa fa-exclamation-triangle"></i>';
                return {
                    x: moment(graphPoint.x).valueOf(),
                    low: graphPoint.y.low != null ? graphPoint.y.low.Value : null,
                    high: graphPoint.y.high != null ? graphPoint.y.high.Value : null,
                    dataLabels: lowExceedsThreshold || highExceedsThreshold
                        ? {
                            enabled: true,
                            // ReSharper disable SuspiciousThisUsage
                            // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                            formatter: function () {
                                if (this.y === this.point.low && lowExceedsThreshold) {
                                    return warning;
                                }
                                else if (this.y === this.point.high && highExceedsThreshold) {
                                    return warning;
                                }
                                return '';
                            },
                            // ReSharper restore SuspiciousThisUsage
                            style: {
                                color: 'red',
                            },
                            useHTML: true,
                        }
                        : null,
                };
            };
            FloatingBarGraphController.prototype.getDataPoint = function (graphPoint) {
                return [moment(graphPoint.x).valueOf(), graphPoint.y];
            };
            return FloatingBarGraphController;
        }(Patient.ChartMonitoringPartController));
        var FloatingBarGraphComponent = /** @class */ (function (_super) {
            __extends(FloatingBarGraphComponent, _super);
            function FloatingBarGraphComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = FloatingBarGraphController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/charts.html';
                return _this;
            }
            return FloatingBarGraphComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringFloatingBarGraph', new FloatingBarGraphComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
