namespace RemeCare.CareRequest.Directive {
    import CareRequestPartType = Shared.Contract.Code.CareRequestPartType;
    import ApplicationProfileType = Shared.Contract.Code.ApplicationProfileType;

    interface ICareRequestScope extends ng.IScope {
        careRequestTemplate: CareRequestTemplate;
        careRequest: CareRequest;
    }

    class AutoAssignRole extends Shared.Framework.Model.CareRequestPart {
        public static PartType = -1;
    }
    class NumTranslation implements RemeCare.Shared.Contract.IItemTranslation<number> {
        constructor(public Id: number, public Text: string) {}
    }

    class CareRequestController implements ng.IComponentController {
        // bindings
        public careRequest: CareRequest;
        public careRequestTemplate: CareRequestTemplate;
        public onMatchedPatientChanged: () => void;
        public showErrors: boolean;
        public showInactivePartsReadonly: boolean;
        public careRequestPartsToUse: RemeCare.Shared.Framework.Model.CareRequestPart[];

        constructor(
            private readonly $compile: ng.ICompileService,
            private readonly $element: ng.IAugmentedJQuery,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly $scope: ng.IScope
        ) {}

        public $onInit(): void {
            this.render();
        }

        public $onChanges(changes: ng.IOnChangesObject) {
            if (changes.careRequestTemplate && !changes.careRequestTemplate.isFirstChange()) {
                this.render();
            }
        }

        private addAutoAssignRole(parts: Shared.Framework.Model.CareRequestPart[]): void {
            // Only for care providers
            const appProfile = this.authservice.getClaim(Shared.Framework.ClaimTypes.applicationProfile);
            if (appProfile !== ApplicationProfileType.CareProvider.toString()) {
                return;
            }
            // Determine position of requestor part or careteam part
            let insertAtIndex = _.findIndex(parts, (part) => part.type.Id === CareRequestPartType.CareRequestor);
            if (insertAtIndex < 0) {
                insertAtIndex = _.findIndex(parts, (part) => part.type.Id === CareRequestPartType.CareRequestRole);
            }
            // If one of the above parts is found, insert the auto assign role part before it
            if (insertAtIndex >= 0) {
                const autoAssignRole = new AutoAssignRole();
                autoAssignRole.type = new NumTranslation(AutoAssignRole.PartType, 'AutoAssignRole');
                parts.splice(insertAtIndex, 0, autoAssignRole);
            }
        }

        private render(): void {
            let html = '';
            if (this.careRequestTemplate != null) {
                if (this.showInactivePartsReadonly) {
                    this.careRequestPartsToUse = _(
                        this.careRequestTemplate.careRequestSetup.careRequestPartsIncludingInactive
                    ).filter(
                        (crp) =>
                            crp.isActive ||
                            crp instanceof RemeCare.Shared.Framework.Model.QualitativeAnamnesisDefinition
                    ); // Only for qualitative anamneses we want to show inactives
                } else {
                    this.careRequestPartsToUse = this.careRequestTemplate.careRequestSetup.careRequestParts;
                }
                this.careRequestPartsToUse = _(this.careRequestPartsToUse).sortBy((c) => c.sequence);
                this.addAutoAssignRole(this.careRequestPartsToUse);
                _(this.careRequestPartsToUse).forEach((part, index) => {
                    html += this.getDirectiveHtml(part.type.Id, index, this.careRequestTemplate, this.careRequest);
                });
            }
            this.$element.html(html).show();
            this.$compile(this.$element.contents())(this.$scope.$new());
        }

        private getDirectiveHtml(
            partType: CareRequestPartType,
            index: number,
            template: CareRequestTemplate,
            careRequest: CareRequest
        ): string {
            let directiveName: string;
            switch (partType) {
                case CareRequestPartType.Patient:
                    if (careRequest.matchedPatientId != null && careRequest.patient == null) {
                        directiveName = 'patient-name';
                    } else {
                        directiveName =
                            template.careRequestStepType.Id === Shared.Contract.Code.CareRequestStepType.Application
                                ? 'patient-application'
                                : 'patient-acceptance';
                    }
                    break;
                case CareRequestPartType.CareRequestor:
                    directiveName =
                        template.careRequestStepType.Id === Shared.Contract.Code.CareRequestStepType.Application
                            ? 'care-requestor-application'
                            : 'care-requestor-acceptance';
                    break;
                case CareRequestPartType.CareRequestRole:
                    directiveName = 'roles';
                    break;
                case CareRequestPartType.QuantitativeAnamnesis:
                    directiveName = 'quantitative-anamnesis';
                    break;
                case CareRequestPartType.QualitativeAnamnesis:
                    directiveName = 'qualitative-anamnesis';
                    break;
                case CareRequestPartType.TherapyOption:
                    directiveName = 'therapy-option';
                    break;
                case CareRequestPartType.Date:
                    directiveName = 'date';
                    break;
                case CareRequestPartType.String:
                    directiveName = 'string';
                    break;
                case CareRequestPartType.RequestDocument:
                    directiveName = 'document';
                    break;
                case CareRequestPartType.Hospitalisation:
                    directiveName = 'hospitalisations';
                    break;
                case CareRequestPartType.Consultation:
                    directiveName = 'consultations';
                    break;
                case CareRequestPartType.MedicationSchemaTherapyAction:
                    directiveName = 'medication-schema';
                    break;
                case CareRequestPartType.MedicationSchemaOther:
                    directiveName = 'medication-schema';
                    break;
                case CareRequestPartType.Comorbidities:
                    directiveName = 'comorbidities';
                    break;
                case CareRequestPartType.TreatedDiseases:
                    directiveName = 'treated-diseases';
                    break;
                case CareRequestPartType.Signature:
                    directiveName = 'signature';
                    break;
                case AutoAssignRole.PartType:
                    directiveName = 'auto-assign-role';
                    break;
                default:
                    return '<p>Part not implemented</p>';
            }
            const directiveHtml = `<rc-care-request-${directiveName} care-request-template="$ctrl.careRequestTemplate" care-request-part="$ctrl.careRequestPartsToUse[${index}]" care-request="$ctrl.careRequest" show-errors="$ctrl.showErrors" step-type="${
                template.careRequestStepType.Id
            }" read-only="$ctrl.careRequest.isReadOnly()" on-matched-patient-changed="$ctrl.onMatchedPatientChanged()" validate-national-number="$ctrl.validateNationalNumber"></rc-care-request-${directiveName}>`;
            return directiveHtml;
        }
    }

    remeCareCareRequestModule.component('rcCareRequestCompiler', {
        bindings: {
            careRequestTemplate: '<',
            careRequest: '=',
            showErrors: '=',
            onMatchedPatientChanged: '&',
            validateNationalNumber: '<',
            showInactivePartsReadonly: '<',
        },
        controller: CareRequestController,
    } as ng.IComponentOptions);
}
