namespace RemeCare.Patient {
    import DateFilterType = Contract.Core.Codes.DateFilterTypeCode;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import Guid = Shared.Contract.Guid;

    // The search criteria that will be used in the html
    // Differs from the actual query sent, for example dates have type 'Date' here, while the server requires strings
    class FindRemarkHtmlSearchCriteria {
        public carePlanId?: Guid;
        public actionTemplateIds?: Guid[];
        public startDate?: Date;
        public endDate?: Date;
    }

    class PatientJourneyController extends PatientRegistrationsBaseController {
        public searchCriteria: FindRemarkHtmlSearchCriteria;
        public remarkRegistrations: RemeCare.Model.RemarkRegistration[];
        public structuralTherapyActions: EntityTranslation[];
        public hideCriteria: boolean;
        private hasMoreResults: boolean;
        private currentPage: number;
        private currentPageSize: number;

        constructor(
            protected $stateParams: PatientFileStateParams,
            protected $filter: ng.IFilterService,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: Patient.PatientService,
            protected masterdataSvc: Shared.Framework.MasterdataService,
            protected actorRolesApiSvc: Core.Services.ActorRolesApiService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly authservice: Shared.Framework.AuthService
        ) {
            super($stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc);
        }

        /**
         * Performs a new search with the set criteria
         * Starts again at page 1
         */
        public search(): void {
            this.remarkRegistrations = [];
            this.hasMoreResults = true;
            this.currentPage = 1;
            this.hideCriteria = false;
            this.searchRemarkRegistrationsAsync();
        }

        /**
         * Retrieves the next page of results
         */
        public retrieveMoreResults(): void {
            this.currentPage++;
            this.searchRemarkRegistrationsAsync();
        }

        public clearCriteria(): void {
            this.searchCriteria = {};
            this.currentPage = 1;
        }

        public openAction(remarkRegistration: RemeCare.Model.RemarkRegistration): void {
            this.modalBuilderFactory
                .createModalBuilder<void>()
                .setController('carePlanActionDetailModalCtrl')
                .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    actionId: remarkRegistration.actionId,
                    editRight: !remarkRegistration.readOnly,
                })
                .setResultCallBack(() => {
                    this.search();
                })
                .build();
        }

        protected init(): void {
            this.currentPageSize = 10;
            this.clearCriteria();
            this.remarkRegistrations = [];
            this.hasMoreResults = true;
            this.searchRemarkRegistrationsAsync();
        }

        protected dataLoaded(): void {}

        private async searchRemarkRegistrationsAsync(): Promise<void> {
            try {
                if (!this.hasMoreResults) {
                    return;
                }

                const query: Contract.CarePlanAction.Read.IFindRemarkRegistrationsQuery = {
                    carePlanId: this.searchCriteria.carePlanId,
                    patientId: this.$stateParams.patientId,
                    actionTemplateIds: this.searchCriteria.actionTemplateIds,
                    startDate: Shared.DateHelper.toServerDateString(this.searchCriteria.startDate),
                    endDate: Shared.DateHelper.toServerDateString(this.searchCriteria.endDate),
                    page: this.currentPage,
                    pageSize: this.currentPageSize,
                    includeCount: false,
                    includeHasNextPage: false,
                };

                // Don't await loading of filter
                this.loadStructuralTherapyActionsFilterAsync(query);
                const result = await this.carePlanActionApiSvc.findRemarkRegistrationsAsync(query);
                if (result.Items.length < query.pageSize) {
                    this.hasMoreResults = false;
                }

                const isSuperUser =
                    this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.Superuser;
                const mapped = _.map(result.Items, i => new RemeCare.Model.RemarkRegistration(i, isSuperUser));
                this.remarkRegistrations.push(...mapped);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadStructuralTherapyActionsFilterAsync(
            registrationsQuery: RemeCare.Contract.CarePlanAction.Read.IFindRemarkRegistrationsQuery
        ): Promise<void> {
            const query: RemeCare.Contract.CarePlanAction.Read.IFindPossibleRemarkRegistrationActionsQuery = {
                carePlanId: registrationsQuery.carePlanId,
                patientId: registrationsQuery.patientId,
                startDate: registrationsQuery.startDate,
                endDate: registrationsQuery.endDate,
            };

            const result = await this.carePlanActionApiSvc.findPossibleRemarkRegistrationActionsFilterAsync(query);
            const oldFilterValues = this.structuralTherapyActions;
            this.structuralTherapyActions = _.sortBy(result, sta => (sta.Text ? sta.Text.toLowerCase() : ''));

            if (!this.searchCriteria.actionTemplateIds || !this.searchCriteria.actionTemplateIds.length) {
                return;
            }

            // Take all actions that were selected
            const alreadySelected = _.filter(oldFilterValues, fv =>
                _.contains(this.searchCriteria.actionTemplateIds, fv.Id)
            );

            // Add already selected values to the new list of possible filter values
            this.structuralTherapyActions.push(...alreadySelected);
            // Filter duplicates
            this.structuralTherapyActions = _.uniq(this.structuralTherapyActions, sta => sta.Id);
        }
    }

    RemeCare.Patient.remeCarePatientModule.component('rcPatientFilePatientJourney', {
        bindings: {
            $close: '&',
            $dismiss: '&',
        },
        controller: PatientJourneyController,
        templateUrl: 'patient/components/monitoring/registrations/patientJourney/patientJourney.html',
    });
}
