module RemeCare.Shared.Component {
    import Guid = Shared.Contract.Guid;
    import UserStatus = Shared.Contract.Read.IUserStatus;

    class UserAccountController implements ng.IComponentController {
        userAccount: UserStatus;
        partyId: Guid;

        public $onInit(): void { }
    }

    remeCareAppModule.component('rcUserAccountInfo',
    {
        templateUrl: 'views/shared/userAccountInfo.html',
        controller: UserAccountController,
        controllerAs: 'userAccountCtrl',
        bindings: {
            userAccount: '<',
            partyId: '<'    
        }
    });
}