/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var SignatureController = /** @class */ (function (_super) {
                __extends(SignatureController, _super);
                function SignatureController($scope, $translate, toaster, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    $scope.signatureUpdated = function (newModel) { return ($scope.careRequestSignature.signatureImage = newModel); };
                    return _this;
                }
                SignatureController.prototype.$onInit = function () {
                    var _this = this;
                    this.$scope.careRequestSignature = _.find(this.$scope.careRequest.careRequestSignatures, function (t) { return t.careRequestPartLocalIdentity === _this.$scope.careRequestPart.localIdentity; });
                    if (this.$scope.careRequestSignature == null) {
                        this.$scope.careRequestSignature = new CareRequest.CareRequestSignature();
                        this.$scope.careRequestSignature.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                        this.$scope.careRequest.careRequestSignatures.push(this.$scope.careRequestSignature);
                    }
                };
                SignatureController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                return SignatureController;
            }(Directive.CareRequestPartControllerBase));
            var SignatureDirective = /** @class */ (function (_super) {
                __extends(SignatureDirective, _super);
                function SignatureDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        'careRequestSvc',
                        function ($scope, $translate, toaster, careRequestSvc) {
                            return new SignatureController($scope, $translate, toaster, careRequestSvc);
                        },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/signature.html';
                    return _this;
                }
                return SignatureDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestSignature', function () { return new SignatureDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
