namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import ObjectiveDefinition = Shared.Framework.Model.ObjectiveDefinition;
    import ObjectiveValue = Shared.Framework.Model.ObjectiveValue;

    interface IPatientFileAddObjectiveValueModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        carePlan: CarePlan;

        objective: ObjectiveDefinition;
        defaultValue: ObjectiveValue;

        previousValue: ObjectiveValue;

        newAmount: number;
        newValidFromDate: Date;
        backToDefault: boolean;

        form: ng.IFormController;

        minDate: Date;
        maxDate: Date;

        editRight: boolean;

        confirm: () => void;
    }

    class PatientFileAddObjectiveValueModalController extends Shared.Framework.ControllerBase<
        IPatientFileAddObjectiveValueModalScope
    > {
        constructor(
            protected $scope: IPatientFileAddObjectiveValueModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            const minDate = moment()
                .add(1, 'days')
                .startOf('day')
                .toDate();
            this.$scope.previousValue = _(
                _(_(this.$scope.objective.objectiveValues).filter(v => v.isPersonalised)).sortBy(v => v.validFromDate)
            ).last();
            this.$scope.minDate =
                this.$scope.previousValue &&
                moment(this.$scope.previousValue.validFromDate)
                    .add(1, 'day')
                    .toDate() > minDate
                    ? moment(this.$scope.previousValue.validFromDate)
                          .add(1, 'day')
                          .toDate()
                    : minDate;
        }

        private async confirm(): Promise<void> {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            if (this.$scope.backToDefault) {
                this.$dialog.confirmBox(
                    'Views.PatientFile.CarePlans.BackToDefault',
                    'Views.PatientFile.CarePlans.BackToDefaultConfirmMessage',
                    async () => {
                        this.$scope.previousValue.validUntilDate = moment(this.$scope.newValidFromDate)
                            .add(-1, 'day')
                            .toDate();

                        try {
                            await this.carePlanApiSvc.updatePersonalisedObjectiveValueAsync(
                                this.$scope.carePlan.id,
                                this.$scope.objective.id,
                                this.$scope.previousValue.toServerWrite()
                            );
                            this.$scope.$close();
                        } catch (e) {
                            this.errorCallback(e);
                        }
                    }
                );
            } else {
                const newValue = new ObjectiveValue();
                newValue.amount = this.$scope.newAmount;
                newValue.validFromDate = this.$scope.newValidFromDate;
                try {
                    await this.carePlanApiSvc.savePersonalisedObjectiveValueAsync(
                        this.$scope.carePlan.id,
                        this.$scope.objective.id,
                        newValue.toServerWrite()
                    );
                    this.$scope.$close();
                } catch (e) {
                    this.errorCallback(e);
                }
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'PatientFileAddObjectiveValueModalCtrl',
            ($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc: Core.Services.CarePlanApiService) =>
                new PatientFileAddObjectiveValueModalController(
                    $scope,
                    $translate,
                    toaster,
                    $dialog,
                    $stateParams,
                    carePlanApiSvc
                )
        );
}
