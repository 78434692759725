module RemeCare.Patient {
    import AnamnesisInfo = Patient.Model.AnamnesisInfo;
    import SufferedDisease = Patient.Model.PatientFileSufferedDisease;

    interface IPatientFileMedicalHistoryScope extends Shared.Framework.IBaseScope {
        anamnesisInfos: Array<AnamnesisInfo>;
        anamnesisInfosGrid: Shared.Framework.Grid.Grid<AnamnesisInfo>;

        sufferedDiseases: Array<SufferedDisease>;
        possibleDiseases: Array<Contract.Patient.Read.IDisease>;
        sufferedDiseasesGrid: Shared.Framework.Grid.Grid<SufferedDisease>;

        addAnamnesisInfo: () => void;
        addSufferedDisease: () => void;
        canAddDisease: () => boolean;

        deleteRight: boolean;
        editRight: boolean;
    }

    class PatientFileMedicalHistoryOverviewController extends Shared.Framework.ControllerBase<IPatientFileMedicalHistoryScope> {

        constructor(
            $scope: IPatientFileMedicalHistoryScope,
            $translate,
            toaster,
            private $stateParams,
            private $dialog,
            private patientSvc: Patient.PatientService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private authservice: Shared.Framework.AuthService) {
            super($scope, $translate, toaster);
            this.$scope.addAnamnesisInfo = () => this.addAnamnesisInfo();
            this.$scope.addSufferedDisease = () => this.addSufferedDisease();
            this.$scope.canAddDisease = () => this.canAddDisease();
        }

        public $onInit(): void {
            this.$scope.sufferedDiseases = [];
            this.$scope.anamnesisInfos = [];

            this.buildAnamnesisInfoGrid();
            this.buildSufferedDiseaseGrid();

            this.getAnamnesisInfos();
            this.getSufferedDiseases();
            this.getPossibleDiseases();

            this.$scope.deleteRight = this.authservice.hasRight(Shared.Framework.AuthContext.patientMedical, Shared.Framework.AuthRight.Delete);
            this.$scope.editRight = this.authservice.hasRight(Shared.Framework.AuthContext.patientMedical, Shared.Framework.AuthRight.Write);
        }

        private canAddDisease(): boolean {
            return _(this.$scope.possibleDiseases).any(x => !_(this.$scope.sufferedDiseases).any(sd => sd.disease.Id === x.Id));
        }

        private getPossibleDiseases(): void {
            this.patientSvc.getPossibleDiseases(this.$stateParams.patientId)
                .success((ags) => {
                    this.$scope.possibleDiseases = ags;
                })
                .error((m) => this.errorCallback(m));
        }

        private getAnamnesisInfos(): void {
            this.patientSvc.getAnamnesisInfos(this.$stateParams.patientId)
                .success((ags) => {
                    this.$scope.anamnesisInfos = _(ags).map(x => new AnamnesisInfo(x));
                    this.$scope.anamnesisInfosGrid.setData(this.$scope.anamnesisInfos);
                })
                .error((m) => this.errorCallback(m));
        }

        private getSufferedDiseases(): void {
            this.patientSvc.getPatientFileSufferedDiseases(this.$stateParams.patientId)
                .success((ags) => {
                    this.$scope.sufferedDiseases = _(ags).map(x => new SufferedDisease(x));
                    this.$scope.sufferedDiseasesGrid.setData(this.$scope.sufferedDiseases);
                })
                .error((m) => this.errorCallback(m));
        }

        private buildAnamnesisInfoGrid(): void {
            this.$scope.anamnesisInfosGrid = this.gridBuilderSvc.createGridBuilder<AnamnesisInfo>()
                .addColumn("text", "Views.PatientFile.PatientInfo.AnamnesisInfo", { width: '***' })
                .addColumn("observationDate", "General.ObservationDate", { cellFilter: 'date: "shortDate"' })
                .addConditionalEditButtonWithPromiseFunctionColumn(h => this.editAnamnesisInfo(h), () => !this.$scope.editRight)
                .addConditionalDeleteButtonColumn(() => !this.$scope.deleteRight, null, c => this.deleteAnamnesisInfo(c))
                .setMultiLine('anamnesisGrid')
                .build();
        }

        private buildSufferedDiseaseGrid(): void {
            this.$scope.sufferedDiseasesGrid = this.gridBuilderSvc.createGridBuilder<SufferedDisease>()
                .addColumn("disease.Text", "Views.PatientFile.PatientInfo.Pathology")
                .addColumn("diagnosisDate", "General.DiagnosisDate", { cellFilter: 'date: "shortDate"' })
                .addColumn("remark", "General.Remark")
                .addColumn("getTherapyList()", "Views.PatientFile.PatientInfo.TreatedBy")
                .addConditionalEditButtonWithPromiseFunctionColumn(h => this.editSufferedDisease(h, false), () => !this.$scope.editRight)
                .addConditionalDeleteButtonColumn(() => !this.$scope.deleteRight, null, c => this.deleteSufferedDisease(c))
                .setMultiLine('anamnesisGrid')
                .build();
        }

        private addAnamnesisInfo(): void {
            var anamnesisInfo = new AnamnesisInfo();
            anamnesisInfo.patientId = this.$stateParams.patientId;
            this.editAnamnesisInfo(anamnesisInfo);
        }

        private addSufferedDisease(): void {
            var sufferedDisease = new SufferedDisease();
            sufferedDisease.patientId = this.$stateParams.patientId;
            this.editSufferedDisease(sufferedDisease, true);
        }

        private editAnamnesisInfo(anamnesisInfo: AnamnesisInfo): JQueryPromise<AnamnesisInfo> {
            var deferred = jQuery.Deferred<AnamnesisInfo>();
            this.modalBuilderFactory.createModalBuilder<AnamnesisInfo>()
                .setController('patientFileAddAnamnesisInfoModalCtrl')
                .setTemplateUrl('views/patient/patientInfo/medicalHistory/addAnamnesisInfoModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    anamnesisInfo: angular.copy(anamnesisInfo)
                })
                .setResultCallBack(r => {
                    this.getAnamnesisInfos();
                    deferred.resolve(r);
                })
                .build();
            return deferred.promise();
        }

        private editSufferedDisease(sufferedDisease: SufferedDisease, diseaseEditable: boolean): JQueryPromise<SufferedDisease> {
            var deferred = jQuery.Deferred<SufferedDisease>();
            this.modalBuilderFactory.createModalBuilder<SufferedDisease>()
                .setController('patientFileAddSufferedDiseaseModalCtrl')
                .setTemplateUrl('views/patient/patientInfo/medicalHistory/addSufferedDiseaseModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.medium)
                .setScope({
                    diseaseEditable: diseaseEditable,
                    sufferedDisease: angular.copy(sufferedDisease),
                    diseases: _(this.$scope.possibleDiseases).filter(x => (sufferedDisease.disease && x.Id === sufferedDisease.disease.Id) || !_(this.$scope.sufferedDiseases).any(sd => sd.disease.Id === x.Id))
                })
                .setResultCallBack(r => {
                    this.getSufferedDiseases();
                    deferred.resolve(r);
                })
                .build();
            return deferred.promise();
        }

        private deleteAnamnesisInfo(anamnesisInfo: AnamnesisInfo): JQueryPromise<boolean> {
            var deferred = jQuery.Deferred<boolean>();
            this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveAnamnesisInfo', 'Views.PatientFile.PatientInfo.RemoveAnamnesisInfoText',
                () => {
                    this.patientSvc.deleteAnamnesisInfo(this.$stateParams.patientId, anamnesisInfo.id)
                        .success(() => deferred.resolve(true))
                        .error(e => {
                            this.errorCallback(e);
                            deferred.resolve(false);
                        });
                },
                () => {
                    deferred.resolve(false);
                }
            );
            return deferred.promise();
        }

        private deleteSufferedDisease(sufferedDisease: SufferedDisease): JQueryPromise<boolean> {
            var deferred = jQuery.Deferred<boolean>();
            this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveSufferedDisease', 'Views.PatientFile.PatientInfo.RemoveSufferedDiseaseText',
                () => {
                    this.patientSvc.deletePatientFileSufferedDisease(this.$stateParams.patientId, sufferedDisease.disease.Id)
                        .success(() => deferred.resolve(true))
                        .error(e => {
                            this.errorCallback(e);
                            deferred.resolve(false);
                        });
                },
                () => {
                    deferred.resolve(false);
                }
            );
            return deferred.promise();
        }
    }

    angular.module('RemeCare.Patient').controller('patientFileMedicalHistoryOverviewCtrl', (
        $scope,
        $translate,
        toaster,
        $stateParams,
        $dialog,
        patientSvc: Patient.PatientService,
        gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
        modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
        authservice: Shared.Framework.AuthService
    ) => new PatientFileMedicalHistoryOverviewController(
        $scope,
        $translate,
        toaster,
        $stateParams,
        $dialog,
        patientSvc,
        gridBuilderSvc,
        modalBuilderFactory,
        authservice
    ));
}