var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestSearchService = /** @class */ (function (_super) {
            __extends(CareRequestSearchService, _super);
            function CareRequestSearchService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            CareRequestSearchService.prototype.searchCareRequestsAsync = function (query) {
                var url = 'CareRequests';
                return this.getPromise(url, query, 'carerequests');
            };
            /** @deprecated */
            CareRequestSearchService.prototype.getPossibleTherapies = function () {
                return this.get('CareRequests/PossibleTherapies', null, 'careRequests.possibleTherapies');
            };
            CareRequestSearchService.prototype.getPossibleTherapiesAsync = function () {
                return this.getPromise('CareRequests/PossibleTherapies', null, 'careRequests.possibleTherapies');
            };
            CareRequestSearchService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return CareRequestSearchService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        CareRequest.CareRequestSearchService = CareRequestSearchService;
        CareRequest.remeCareCareRequestModule.service('careRequestSearchSvc', CareRequestSearchService);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
