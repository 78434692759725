/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {
    import RegistrationValueFactory = Model.RegistrationValueFactory;
    
    interface IQuantitativeAnamnesisScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.QuantitativeAnamnesisDefinition;
        anamnesis: Anamnesis;
    }

    class QuantitativeAnamnesisController extends CareRequestPartControllerBase<IQuantitativeAnamnesisScope> {
        
        constructor(
            $scope: IQuantitativeAnamnesisScope,
            $translate,
            toaster,
            careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.$scope.anamnesis = _.find(this.$scope.careRequest.anamneses,
                t => t.careRequestPartLocalIdentity === this.$scope.careRequestPart.localIdentity);
            if (this.$scope.anamnesis == null) {
                this.$scope.anamnesis = new Anamnesis();
                this.$scope.anamnesis.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                this.$scope.anamnesis.registrationValue = RegistrationValueFactory
                    .createRegistrationValueFromType(Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity);
                this.$scope.careRequest.anamneses.push(this.$scope.anamnesis);
            }
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }
    }

    class QuantitativeAnamnesisDirective extends CareRequestPartDirectiveBase {
        
        controller = ['$scope', '$translate', 'toaster', 'careRequestSvc',
            ($scope, $translate, toaster, careRequestSvc) => new QuantitativeAnamnesisController($scope, $translate, toaster, careRequestSvc)];

        templateUrl = 'views/careRequest/directive/quantitativeAnamnesis.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestQuantitativeAnamnesis', () => new QuantitativeAnamnesisDirective());
}