module RemeCare.Agenda {

    class CalendarController {
        private agendaItems: Array<Model.PersonalAgendaAction>;
        private search: () => void;
        private currentView: string;
        private dateSelector: JQuery;
        private datePopoverSelector: JQuery;
        private filter: IAgendaFilter;
        public calendarHasTooManyItems: boolean;
        public calendarItems: Array<any>;
        public calendar: any;
        public showDateSelector: boolean;
        public currentState: ng.ui.IState;

        constructor(
            private $compile: ng.ICompileService,
            private $locale: ng.ILocaleService,
            private $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private authservice: Shared.Framework.AuthService,
            private uiCalendarConfig) {
            this.calendarItems = [];
        }

        public $onInit(): void {
            this.currentState = this.$state.current;
            this.$timeout(() => {
                this.configureCalendar();
                this.configureDateSelectors();
                this.highlightDates();
            });
        }

        public $onChanges(changes: { [property: string]: ng.IChangesObject<Array<Model.PersonalAgendaAction>>}): void {
            this.mapCalendarItems(changes['agendaItems'].currentValue);
        }

        private configureDateSelectors(): void {
            this.dateSelector = $('#dateSelector');
            this.datePopoverSelector = $('#datePopoverSelector');
            this.configureDateSelector(this.dateSelector);
            this.configureDateSelector(this.datePopoverSelector);
        }

        private configureDateSelector(selector: JQuery): void {
            if (selector.length === 0) return;
            selector = selector
                .datetimepicker({
                    inline: true,
                    calendarWeeks: true,
                    locale: this.$locale.id,
                    format: 'DD/MM/YYYY'
                });
            selector.on('dp.change', e => this.onDateSelected(e));
            selector.on('dp.update', () => this.highlightDates());
        }

        public toggleDateSelector(): void {
            this.showDateSelector = !this.showDateSelector;
            this.$timeout(() => {
                if (this.showDateSelector) {
                    this.configureDateSelectors();
                    this.highlightDates();
                }
            });
        }

        private onDateSelected(e): void {
            this.getCalendar().fullCalendar('gotoDate', e.date);
            this.showDateSelector = false;
        }

        getCalendarStartDate(): Date {
            return this.getCalendar().fullCalendar('getView').intervalStart.toDate();
        }

        getCalendarEndDate(): Date {
            return this.getCalendar().fullCalendar('getView').intervalEnd.toDate();
        }

        private highlightDates(): void {
            this.setInactive(this.dateSelector);
            this.setInactive(this.datePopoverSelector);
            var start: moment.Moment = moment(this.getCalendarStartDate());
            var end: moment.Moment = moment(this.getCalendarEndDate());
            do {
                var formatted = start.format('L');
                this.setActive(this.dateSelector, formatted);
                this.setActive(this.datePopoverSelector, formatted);
                start.add(1, 'days');
            } while (start.startOf('day').isBefore(end.startOf('day')))
        }

        private setInactive(selector: JQuery): void {
            selector.find('td.day').removeClass('active');
        }

        private setActive(selector: JQuery, formatted: string): void {
            selector.find(`td.day[data-day="${formatted}"]`).addClass('active');
        }

        private configureCalendar(): void {
            this.calendar = {
                editable: false,
                header: false,
                eventRender: (ev, el) => this.eventRender(ev, el),
                eventAfterRender: (ev, el) => this.eventAfterRender(ev, el),
                lang: this.$locale.id,
                viewRender: v => this.viewRender(v),
                allDaySlot: false,
                slotEventOverlap: false,
                defaultView: 'agendaWeek',
                fixedWeekCount: false,
                height: 'auto'
            };
        }

        private getCalendar(): any {
            return this.uiCalendarConfig.calendars.agenda;
        }

        public changeView(view: string): void {
            this.getCalendar().fullCalendar('changeView', view);
        }

        public goToToday(): void {
            this.getCalendar().fullCalendar('today');
        }

        public goToPrevious(): void {
            this.getCalendar().fullCalendar('prev');
        }

        public goToNext(): void {
            this.getCalendar().fullCalendar('next');
        }

        private viewRender(view): void {
            this.currentView = view.name;
            if (view.name === 'month') {
                this.getCalendar().fullCalendar('option', 'height', 'auto');
            } else {
                this.getCalendar().fullCalendar('option', 'height', null);
            }
            this.filter.fromDate = Shared.DateHelper.dayOfDate((<moment.Moment>view.start).toDate());
            // calendar component takes end as not-inclusive, so we have to subtract one day
            this.filter.untilDate = Shared.DateHelper.dayOfDate((<moment.Moment>view.end).add(-1, 'days').toDate());
            if (this.dateSelector) {
                this.dateSelector.data('DateTimePicker').date(view.intervalStart.startOf('day'));
                this.highlightDates();
            }
            this.search();
        }

        private eventRender(event, element: JQuery): void {
            if (element.hasClass('fc-not-start')) {
                element.html(
                    `<a ng-if="action.canBeViewed" ui-sref="editAction({actionIds:[action.id], referringState: calendarCtrl.currentState})" class="agenda-item">${event.title}</a>` +
                    `<span ng-if="!action.canBeViewed">${event.title}</span>`);
            } else {
                element.html(
                    `<a ng-if="action.canBeViewed" ui-sref="editAction({actionIds:[action.id], referringState: calendarCtrl.currentState})" class="agenda-item">${event.title}</a>` +
                    `<span ng-if="!action.canBeViewed">${event.title}</span>`);
                element.prepend(`<span ng-if="action.canBeEdited" ng-show="action.status < ${Contract.Core.Codes.CarePlanActionStatus.Completed}"><input checklist-model="calendarCtrl.selectedActions" checklist-value="action.id" type="checkbox"></input></span>`);
                element.prepend(`<i ng-show="action.status == ${Contract.Core.Codes.CarePlanActionStatus.Overdue}" class="glyphicon glyphicon-exclamation-sign red"></i> `);
                element.prepend(`<i ng-show="action.status == ${Contract.Core.Codes.CarePlanActionStatus.Completed}" class="glyphicon glyphicon-ok green"></i> `);
            }
            var scope = this.$scope.$new();
            (<any>scope).action = event.data;

            this.$compile(element)(scope);
        }

        private eventAfterRender(event, element: JQuery): void {
            element.off('click');
        }

        private mapCalendarItems(actions: Array<Model.PersonalAgendaAction>): void {
            this.calendarItems.pop();
            let calendarActions = _(actions).filter(a => !a.showAsToDo);
            switch (this.currentView) {
                case 'month':
                    this.calendarHasTooManyItems = calendarActions.length > 500;
                    break;
                case 'agendaWeek':
                    this.calendarHasTooManyItems = calendarActions.length > 100;
                    break;
                default:
                    this.calendarHasTooManyItems = calendarActions.length > 20;
                    break;

            }
            if (this.calendarHasTooManyItems) return;
            let calendarItems = _(calendarActions).map(a => this.mapCalendarItem(a));
            this.calendarItems.push(calendarItems);
        }

        private mapCalendarItem(action: Model.PersonalAgendaAction): any {
            var start: Date;
            var end: Date;
            if (action.status === Contract.Core.Codes.CarePlanActionStatus.Completed
                && action.eventDateTimeFrom != null && action.eventDateTimeUntil != null
                && action.eventDateTimeFrom.valueOf() !== action.eventDateTimeUntil.valueOf()) {
                start = action.eventDateTimeFrom;
                end = action.eventDateTimeUntil;
            } else {
                if (action.plannedDateTimeFrom.valueOf() !== action.plannedDateTimeUntil.valueOf()) {
                    start = action.plannedDateTimeFrom;
                    end = action.plannedDateTimeUntil;
                } else if (action.openEventWindowDateTime != null && action.closeEventWindowDateTime != null
                    && action.openEventWindowDateTime.valueOf() !== action.closeEventWindowDateTime.valueOf()) {
                    start = action.openEventWindowDateTime;
                    end = action.closeEventWindowDateTime;
                } else {
                    start = end = action.suggestedDateTime;
                }
            }
            return {
                title: this.authservice.isPatient() ? action.name : `${action.name} - ${action.patient}`,
                start: start,
                end: end,
                data: action
            }
        }
    }

    remeCareAgendaModule.component('rcAgendaCalendar',
    {
        controller: CalendarController,
        controllerAs: 'calendarCtrl',
        templateUrl: 'views/agenda/calendar.html',
        bindings: {
            agendaItems: '<',
            filter: '=',
            search: '&',
            selectedActions: '='
        }
    });
}