namespace RemeCare.Agenda {
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;

    export interface IAgendaFilter {
        fromDate?: Date;
        untilDate?: Date;
        showAllTodos?: boolean;
        patientData?: { patient?: Contract.Patient.Read.IPatient; patientNumber?: string };
        statuses?: Contract.Core.Codes.CarePlanActionStatus[];
        actionAssignmentTypes?: Shared.Contract.Code.ActionAssignmentType[];
        actionTypes?: string[];
        actorRoles?: Shared.Contract.Guid[];
        therapyIds?: Shared.Contract.Guid[];
        actor?: Contract.Party.Read.IPerson;
    }

    interface IActionTemplateExtension extends Contract.Patient.Read.IActionTemplate {
        displayName?: string;
    }
    class MyAgendaController implements ng.IComponentController {
        public agendaItems: Model.PersonalAgendaAction[];
        public isTodoListVisible: boolean;
        public filter: IAgendaFilter;
        public selectedActions: Shared.Contract.Guid[];
        public isPatient: boolean;
        public actionTemplates: IActionTemplateExtension[];
        public loaded: boolean;
        private urlBinder: Shared.Framework.Factory.UrlBinder;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly $animate: ng.animate.IAnimateService,
            private readonly actorRolesApiSvc: Core.Services.ActorRolesApiService,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly patientSvc: Patient.PatientService,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly urlBinderFactory: Shared.Framework.Factory.UrlBinderFactory,
            private readonly patientSearchSvc: Patient.PatientSearchService
        ) {}

        public async $onInit(): Promise<void> {
            this.storageSvc.store(StorageServiceConstants.agendaKey, StorageServiceConstants.myAgenda);
            this.isTodoListVisible = true;
            this.selectedActions = [];
            this.filter = {};
            this.urlBinder = this.urlBinderFactory.createUrlBinder(this.filter);
            this.urlBinder.bindAllFromUrl();
            if (this.urlBinder.isEmpty()) {
                this.setDefaultFilters();
            } else {
                await this.enrichFilterDataAsync();
            }

            try {
                const actorRolesPromise = this.actorRolesApiSvc.findActorRolesAsync({ ForUser: true });
                const nonPlannedActionTemplatesPromise = this.isPatient
                    ? this.patientSvc.getNonPlannedActionTemplatesAsync(
                          this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId)
                      )
                    : Promise.resolve([]);
                const [actorRoles, nonPlannedActionTemplates]: [
                    Contract.Therapy.Read.IActorRoleSummary[],
                    Contract.Patient.Read.IActionTemplate[]
                ] = await Promise.all([actorRolesPromise, nonPlannedActionTemplatesPromise]);
                this.filter.actorRoles = _(actorRoles).map((r) => r.Id);
                this.loaded = true;
                this.actionTemplates = nonPlannedActionTemplates;
                this.actionTemplates.forEach((at) => (at.displayName = at.Name || ''));
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public makeAdhocRegistration(template: Contract.Patient.Read.IActionTemplate): void {
            this.modalBuilderFactory
                .createModalBuilder<any>()
                .setController('adhocRegistrationModalCtrl')
                .setTemplateUrl('views/patient/monitoring/registrations/adhocRegistrationModal.html')
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setScope({
                    action: new Patient.Model.ActionTemplate(
                        this.authservice,
                        this.authservice.getClaim(Shared.Framework.ClaimTypes.partyRoleId),
                        template
                    ),
                })
                .build();
        }

        public isFiltered(): boolean {
            return (
                (this.filter.patientData != null && this.filter.patientData.patient != null) ||
                (this.filter.actionTypes != null && this.filter.actionTypes.length > 0) ||
                (this.filter.statuses != null && this.filter.statuses.length > 0) ||
                (this.filter.therapyIds != null && this.filter.therapyIds.length > 0) ||
                (this.filter.actionAssignmentTypes != null && this.filter.actionAssignmentTypes.length > 0) ||
                (this.filter.actorRoles != null && this.filter.actorRoles.length > 0)
            );
        }

        public async searchItems(): Promise<void> {
            const query: Contract.CarePlanAction.Read.IFindPersonalAgendaActionsQuery = {
                startDate: Shared.DateHelper.toServerDateString(this.filter.fromDate),
                endDate: Shared.DateHelper.toServerDateString(this.filter.untilDate),
                dateFilterTypes: [
                    Contract.Core.Codes.DateFilterTypeCode.PlannedDate,
                    Contract.Core.Codes.DateFilterTypeCode.EventDate,
                ],
                statuses: this.filter.statuses,
                actionTypes: this.filter.actionTypes,
                therapyIds: this.filter.therapyIds,
                patientId:
                    this.filter.patientData && this.filter.patientData.patient
                        ? this.filter.patientData.patient.Id
                        : null,
                actorRoles: this.filter.actorRoles,
                actionAssignmentTypes: this.filter.actionAssignmentTypes,
            };
            try {
                const personalAgendaActions = await this.carePlanActionApiSvc.findPersonalAgendaActions(query);
                this.agendaItems = _(personalAgendaActions).map((x) => new Model.PersonalAgendaAction(x));
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public toggleTodoList(): void {
            if (this.isTodoListVisible) {
                this.hideTodoList();
            } else {
                this.showTodoList();
            }
        }

        public registerAdHoc(): void {
            if (this.filter.patientData != null && this.filter.patientData.patient != null) {
                this.$state.go('patients.patientfile.monitoring.registrations.adhoc', {
                    patientId: this.filter.patientData.patient.Id,
                });
                return;
            }
            this.modalBuilderFactory
                .createModalBuilder<Contract.Patient.Read.IPatient>()
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setTemplateUrl('views/patient/searchPatientModal.html')
                .setResultCallBack((p) => {
                    this.$state.go('patients.patientfile.monitoring.registrations.adhoc', { patientId: p.Id });
                })
                .build();
        }

        public showFilters(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<IAgendaFilter>('rcMyAgendaFilter')
                .setBindings({
                    filter: angular.copy(this.filter),
                })
                .setResultCallBack((r) => {
                    angular.copy(r, this.filter);
                    this.urlBinder.reflectInUrl(true);
                    this.searchItems();
                })
                .build();
        }

        private setDefaultFilters(): void {
            this.filter.showAllTodos = false;
            this.filter.actionAssignmentTypes = [];
            this.filter.actionTypes = [];
            this.filter.actorRoles = [];
            this.filter.statuses = [
                Contract.Core.Codes.CarePlanActionStatus.Open,
                Contract.Core.Codes.CarePlanActionStatus.Overdue,
                Contract.Core.Codes.CarePlanActionStatus.Completed,
            ];
            this.filter.therapyIds = [];

            switch (this.authservice.getProfile()) {
                case Shared.Contract.Code.ApplicationProfileType.CareManager:
                case Shared.Contract.Code.ApplicationProfileType.AdministrativeManager:
                    this.filter.actionAssignmentTypes = [Shared.Contract.Code.ActionAssignmentType.Me];
                    break;
                case Shared.Contract.Code.ApplicationProfileType.CareProvider:
                case Shared.Contract.Code.ApplicationProfileType.TherapyProvider:
                    this.filter.actionAssignmentTypes = [
                        Shared.Contract.Code.ActionAssignmentType.Me,
                        Shared.Contract.Code.ActionAssignmentType.Unassigned,
                    ];
                    break;
                case Shared.Contract.Code.ApplicationProfileType.Patient:
                    this.isPatient = true;
                    this.filter.actionAssignmentTypes = [
                        Shared.Contract.Code.ActionAssignmentType.Me,
                        Shared.Contract.Code.ActionAssignmentType.OtherInActorRole,
                        Shared.Contract.Code.ActionAssignmentType.Unassigned,
                    ];
                    break;
            }
        }

        private async enrichFilterDataAsync(): Promise<void> {
            if (
                !this.filter ||
                !this.filter.patientData ||
                !this.filter.patientData.patient ||
                !this.filter.patientData.patient.Id
            ) {
                this.setDefaultFilters();
                return;
            }

            if (this.filter.patientData.patient.FirstName && this.filter.patientData.patient.LastName) {
                // Relevant patientdata already present
                return;
            }

            const searchResult = await this.patientSearchSvc.findPatientsAsync({
                patientIds: [this.filter.patientData.patient.Id],
            });
            if (!searchResult.Items || !searchResult.Items.length) {
                this.setDefaultFilters();
                return;
            }

            this.filter.patientData.patient = searchResult.Items[0];
            this.filter.patientData.patientNumber = '' + this.filter.patientData.patient.PatientNumber;
            this.urlBinder.reflectInUrl(true);
        }

        private showTodoList(): void {
            this.isTodoListVisible = true;
            const calendar = angular.element('#agenda-calendar');
            const todoList = angular.element('#agenda-todo-list');
            this.$animate.addClass(calendar, 'col-offset-right-300', {
                duration: 0.5,
            });
            this.$animate.animate(todoList, null, { right: '0px' }, null, { duration: 0.5 }).then(() => {
                $(window).resize();
            });
        }

        private hideTodoList(): void {
            this.isTodoListVisible = false;
            const calendar = angular.element('#agenda-calendar');
            const todoList = angular.element('#agenda-todo-list');
            this.$animate.animate(todoList, null, { right: '-300px' }, null, { duration: 0.5 });
            this.$animate
                .removeClass(calendar, 'col-offset-right-300', {
                    duration: 0.5,
                })
                .then(() => {
                    $(window).resize();
                });
        }
    }

    remeCareAgendaModule.component('rcMyAgenda', {
        controller: MyAgendaController,
        templateUrl: 'agenda/myAgenda/myAgenda.html',
    });
}
