namespace RemeCare.CarePlanAction {
    import StorageServiceConstants = Shared.Framework.Service.StorageServiceConstants;
    import RegisteredAction = Contract.CarePlanAction.Read.IRegisteredAction;

    interface ICarePlanActionParams {
        actionIds: Shared.Contract.Guid | Shared.Contract.Guid[];
        referringState: ng.ui.IState;
    }

    interface ICarePlanActionFilters {
        showDashboard: boolean;
        showAction: boolean;
    }

    class CarePlanActionController implements ng.IComponentController {
        public action: CarePlanAction;
        public mainAction: CarePlanAction;
        public actionIndex = 1;
        public actionsInFlow: Contract.CarePlanAction.Read.IActionInfo[];
        public showingAction: boolean;
        public showingDashboard: boolean;
        public startIndex: number;
        public canEdit: boolean;
        public showPatientDetails: boolean;

        public functionsRegistered: boolean;
        public cancelRegistrations: () => void;
        public register: (permanent: boolean) => void;
        public planNewContact: (includeParts: boolean) => void;
        public hasPhoneCall: () => boolean;
        public hasRegistrationParts: boolean;
        public actionIds: Shared.Contract.Guid[];
        public patient: Contract.Party.Read.IPerson;
        public patientId: Shared.Contract.Guid;

        constructor(
            private readonly $window: ng.IWindowService,
            private readonly $timeout: ng.ITimeoutService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly $stateParams: ICarePlanActionParams,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private readonly patientSvc: RemeCare.Patient.PatientService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly $state: ng.ui.IStateService,
            private readonly authservice: Shared.Framework.AuthService,
        ) {
            this.actionIds = !_($stateParams.actionIds).isArray()
                ? [$stateParams.actionIds as Shared.Contract.Guid]
                : ($stateParams.actionIds as Shared.Contract.Guid[]);
        }

        public $onInit(): void {
            if (this.$window.innerWidth < 1200) {
                this.showAction();
            } else {
                const knownFilters = this.storageSvc.get<ICarePlanActionFilters>(
                    StorageServiceConstants.carePlanActionKey
                );
                if (knownFilters && !knownFilters.showDashboard) {
                    this.showAction();
                } else {
                    if (knownFilters && !knownFilters.showAction) {
                        this.showDashboard();
                    } else {
                        this.showActionAndDashboard();
                    }
                }
            }
            this.actionSelectionChanged();
            this.startIndex = 0;
        }

        public registerFunctions(functions: RemeCare.CarePlanAction.ICarePlanActionModalFunctions): void {
            this.cancelRegistrations = () => functions.cancelRegistrations();
            this.register = isPermanent => functions.register(isPermanent);
            this.planNewContact = includeParts => functions.planNewContact(includeParts);
            this.hasPhoneCall = () => functions.hasPhoneCall();
            this.functionsRegistered = true;
        }

        public togglePatientDetails(event: ng.IAngularEvent): void {
            this.showPatientDetails = !this.showPatientDetails;
            event.stopPropagation();
            event.preventDefault();
        }

        public showInternalRemarkModal(): void {
            this.modalBuilderFactory
                .createComponentModalBuilder<string>('rcInternalRemarkModal')
                .setBindings({
                    remark: this.patient.PatientRemark,
                    patientId: this.patientId,
                })
                .setResultCallBack(result => {
                    this.patient.PatientRemark = result;
                    this.patientSvc
                        .saveInternalRemark(this.patientId, result)
                        .success(() => { })
                        .error(e => {
                            this.toaster.error(e.data);
                        });
                })
                .setLarge()
                .build();
        }

        public showActionInFlow(carePlanAction: Contract.CarePlanAction.Read.IActionInfo): void {
            // Set action to null to trigger the ng-if and redraw the <dynamic-action-controller>
            this.action = null;
            this.functionsRegistered = false;
            this.$timeout(() => this.getCarePlanActionAsync(carePlanAction.Id));
        }

        public moveActionsInFlowLeft(): void {
            let newIndex = this.startIndex - 1;
            newIndex = Math.max(0, newIndex);
            this.startIndex = newIndex;
        }

        public moveActionsInFlowRight(): void {
            let newIndex = this.startIndex + 1;
            newIndex = Math.min(this.actionsInFlow.length - 4, newIndex);
            this.startIndex = newIndex;
        }

        public actionSelectionChanged(): void {
            // Set action to null to trigger the ng-if and redraw the <dynamic-action-controller>
            this.action = null;
            this.functionsRegistered = false;
            this.$timeout(() => {
                const actionId = this.actionIds[this.actionIndex - 1];
                this.findActionsInFlowAsync(actionId);
                this.getCarePlanActionAsync(actionId);
            });
        }

        public getActionButtonStyle(action: Contract.CarePlanAction.Read.IActionInfo): string {
            if (action.Id === this.mainAction.id) {
                return 'btn-primary';
            }
            if (action.Id === this.action.id) {
                return 'btn-remedus-blue';
            }

            return 'btn-default';
        }

        public getActionClass(): string {
            if (this.showingAction) {
                return this.showingDashboard ? 'col-sm-6' : 'col-sm-12';
            }
            return 'ng-hide';
        }

        public getDashboardClass(): string {
            if (this.showingDashboard) {
                return this.showingAction ? 'col-sm-6' : 'col-sm-12';
            }
            return 'ng-hide';
        }

        public showAction(): void {
            this.showingAction = true;
            this.showingDashboard = false;
            this.triggerResizeEvent();
            this.storeKeys();
        }

        public showActionAndDashboard(): void {
            this.showingAction = true;
            this.showingDashboard = true;
            this.triggerResizeEvent();
            this.storeKeys();
        }

        public showDashboard(): void {
            this.showingAction = false;
            this.showingDashboard = true;
            this.triggerResizeEvent();
            this.storeKeys();
        }

        public goBack(): void {
            if (this.$stateParams.referringState) {
                this.$state.go(this.$stateParams.referringState.name, this.$stateParams.referringState.params);
            } else {
                this.$state.go('agenda');
            }
        }

        public registered(): void {
            if (this.actionIndex >= this.actionIds.length) {
                this.$state.go('agenda');
            } else {
                this.actionIndex++;
                this.actionSelectionChanged();
            }
        }

        public cancel(): void {
            this.$state.go('agenda');
        }

        private async getCarePlanActionAsync(actionId: Shared.Contract.Guid): Promise<void> {
            try {
                const carePlanAction = await this.carePlanActionApiSvc.getCarePlanActionAsync(actionId);
                this.configureMainActionAsync(carePlanAction);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async findActionsInFlowAsync(actionId: Shared.Contract.Guid): Promise<void> {
            try {
                const inFlowResult = await this.carePlanActionApiSvc.findActionsInFlowAsync(actionId);
                this.actionsInFlow = _.sortBy(inFlowResult, a => a.PlannedDateTimeFrom);

                let index = _.findIndex(this.actionsInFlow, a => a.Id === actionId);
                index = Math.max(0, index - 3);
                this.startIndex = index;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async configureMainActionAsync(
            mainAction: Contract.CarePlanAction.Read.ICarePlanAction
        ): Promise<void> {
            try {
                this.patientId = mainAction.Patient.Id;
                this.patient = (await this.partyApiSvc.getPersonsAsync([this.patientId]))[0];
                this.mainAction = new CarePlanAction(this.authservice, mainAction, this.patient);
                this.configureAction(mainAction);
                this.canEdit = this.mainAction.canEditAction;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private configureAction(carePlanAction: Contract.CarePlanAction.Read.ICarePlanAction): void {
            this.action = new CarePlanAction(this.authservice, carePlanAction, this.patient);
            this.hasRegistrationParts =
                _(this.action.components).filter(
                    c =>
                        c.actionTypeCode === Shared.Contract.Code.ActionType.ProvideInformationObject &&
                        (c as RemeCare.CarePlanAction.ProvideInformationObjectDescriptor).questions.length === 0
                ).length < this.action.components.length;
        }

        private storeKeys(): void {
            const filters: ICarePlanActionFilters = {
                showDashboard: this.showingDashboard,
                showAction: this.showingAction,
            };
            this.storageSvc.store(StorageServiceConstants.carePlanActionKey, filters);
        }

        private triggerResizeEvent(): void {
            // simple $(window).resize() does not trigger the charts reflow
            this.$timeout(() => {
                const evt = document.createEvent('HTMLEvents');
                evt.initEvent('resize', false, true);
                window.dispatchEvent(evt);
            });
        }
    }

    remeCareCarePlanActionModule.component('action', {
        controller: CarePlanActionController,
        templateUrl: 'views/carePlanAction/carePlanAction.html',
    });
}
