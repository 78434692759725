module RemeCare.Management {

    interface ISendResetMailScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        form: ng.IFormController;
        sendToUser: boolean;
        mailToOptions: Array<Shared.Contract.IItemTranslation<boolean>>;
        confirm(): void;
    }

    class SendResetMailController extends Shared.Framework.ControllerBase<ISendResetMailScope> {

        constructor(
            $scope: ISendResetMailScope,
            $translate,
            toaster,
            private partyId: Shared.Contract.Guid,
            private securityApiSvc: Shared.Framework.Service.SecurityApiSvc) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.mailToOptions = [
                {
                    Id: true,
                    Text: this.$translate.instant('General.User')
                }, {
                    Id: false,
                    Text: this.$translate.instant('Views.Management.Persons.RemecareMail')
                }
            ];
        }

        confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            this.securityApiSvc.sendResetMail(this.partyId, this.$scope.sendToUser)
                .success(() => {
                    this.$scope.$close();
                    this.generalSuccess();
                })
                .error(e => this.errorCallback(e));
        }
    }

    remeCareManagementModule.controller('sendResetMailCtrl', (
        $scope,
        $translate,
        toaster,
        partyId,
        securityApiSvc
    ) => new SendResetMailController(
        $scope,
        $translate,
        toaster,
        partyId,
        securityApiSvc
    ));
}