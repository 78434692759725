namespace RemeCare.Management {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import SearchResult = Shared.Contract.ISearchResult;
    import AuthRight = RemeCare.Shared.Framework.AuthRight;

    export interface IPerson extends Contract.Party.Read.IPerson {
        partyRoleTypes: EnumTranslation[];
        applicationProfiles: EnumTranslation[];
        healthCareProfessionalTypes: EnumTranslation[];
        specialties: EnumTranslation[];
        patientNumber: string;
    }

    class SearchPersonsController implements ng.IComponentController {
        public countries: EnumCodeTranslation[];
        public communicationLanguages: EnumCodeTranslation[];
        public languages: EnumCodeTranslation[];
        public applicationProfiles: EnumTranslation[];
        public genders: EnumTranslation[];
        public partyRoleTypes: EnumTranslation[];
        public specialties: EnumTranslation[];
        public grid: Shared.Framework.Grid.Grid<IPerson>;
        public searchCollapsed: boolean;
        public bindToUrl: boolean;
        public onPersonSelected: (params: { person: IPerson }) => void;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private authservice: Shared.Framework.AuthService
        ) {}

        public $onInit(): void {
            this.buildGrid();
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [
                    this.specialties,
                    this.countries,
                    this.communicationLanguages,
                    this.languages,
                    this.applicationProfiles,
                    this.genders,
                    this.partyRoleTypes,
                ] = await Promise.all([
                    this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                    this.masterdataSvc.getCountriesAsync(),
                    this.masterdataSvc.getCommLanguagesAsync(),
                    this.masterdataSvc.getLanguagesAsync(),
                    this.masterdataSvc.getApplicationProfilesAsync(),
                    this.masterdataSvc.getGendersAsync(),
                    this.masterdataSvc.getPartyRoleTypesAsync(),
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<IPerson>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.searchPersonsAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .setBindToUrl(this.bindToUrl)
                .addColumn('LastName', 'General.LastName')
                .addColumn('FirstName', 'General.FirstName')
                .setSearchCriteria(c => {
                    c.phoneNumber =
                        c.phoneNumber instanceof String || !c.phoneNumber
                            ? RemeCare.Model.PhoneNumber.fromString(c.phoneNumber)
                            : RemeCare.Model.PhoneNumber.fromObject(c.phoneNumber);
                });

            if (this.onPersonSelected == null) {
                builder = builder.addColumn('DateOfBirth', 'General.DateOfBirth', { cellFilter: 'date:"shortDate"' });
                if (
                    this.authservice.hasRight(
                        Shared.Framework.AuthContext.patientInternal,
                        Shared.Framework.AuthRight.Read
                    )
                ) {
                    builder = builder.addColumn('PatientNumber', 'Patient.PatientNumber');
                }
                builder = builder
                    .addColumn('partyRoleTypes', 'General.Capacity', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                    .addColumn('applicationProfiles', 'Views.Management.Persons.ApplicationProfile', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                    .addNavigationColumn('eye-open', 'management.persons.detail', { partyId: 'PartyId' });
            } else {
                builder = builder.addColumn('partyRoleTypes', 'General.Capacity', {
                    cellFilter: 'delimitedDisplay:", "',
                    enableSorting: false,
                });
                if (
                    this.authservice.hasRight(
                        Shared.Framework.AuthContext.patientInternal,
                        Shared.Framework.AuthRight.Read
                    )
                ) {
                    builder = builder.addColumn('PatientNumber', 'Patient.PatientNumber');
                }
                builder = builder
                    .addColumn('healthCareProfessionalTypes', 'General.Type', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                    .addColumn('specialties', 'General.Specialty', {
                        cellFilter: 'delimitedDisplay:", "',
                        enableSorting: false,
                    })
                    .addSelectButtonColumn(o => this.onPersonSelected({ person: o }));
            }
            this.grid = builder.build();
        }

        private async searchPersonsAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<SearchResult<IPerson>> {
            const query : Contract.Party.Read.Query.IFindPersonsQuery = {
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                firstName: criteria.firstName,
                lastName: criteria.lastName,
                identificationNumber: criteria.identificationNumber,
                birthDate: Shared.DateHelper.toServerDateString(criteria.birthDate),
                communicationLanguage: criteria.communicationLanguage,
                language: criteria.language,
                gender: criteria.gender,
                address: criteria.address,
                zipCode: criteria.zipCode,
                city: criteria.city,
                country: criteria.country,
                nationality: criteria.nationality,
                email: criteria.email,
                isUser: criteria.isUser,
                applicationProfile: criteria.applicationProfile,
                phoneNumber: criteria.phoneNumber ? criteria.phoneNumber.toServerWrite() : null,
                partyRoleType: criteria.partyRoleType,
                healthCareSpecialtyProfessionId:
                    criteria.partyRoleType === 3 ? criteria.healthCareSpecialtyProfessionId : null,
                patientNumber: criteria.patientNumber,
                includeRemovedUser: criteria.includeRemovedUser,
            };
            
            try {
                const persons = await this.partyApiSvc.findPersonsAsync(query);
                const contractPerson = {
                    Items: _(persons.Items).map(i => this.map(i)),
                    Total: persons.Total,
                } as SearchResult<IPerson>;
                this.searchCollapsed = true;
                return contractPerson;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private map(person: Contract.Party.Read.IPerson): IPerson {
            const result = person as IPerson;
            const partyRoleTypes = _(person.PartyRoles).map(pr => pr.Type);
            const applicationProfiles = _(this.applicationProfiles).filter(ap =>
                _(person.ApplicationProfiles).contains(ap.Id)
            );
            result.partyRoleTypes = partyRoleTypes;
            result.applicationProfiles = applicationProfiles;
            result.healthCareProfessionalTypes = result.PartyRoles.map(pr => pr.HealthCareProfessionalType).filter(
                x => x != null
            );
            result.specialties = _.flatten(
                result.PartyRoles.map(pr => pr.HealthCareProffesionalSpecialties).filter(x => x != null),
                true
            );
            return result;
        }
    }

    remeCareManagementModule.component('rcPersonSearch', {
        controller: SearchPersonsController,
        templateUrl: 'views/management/persons/personSearch.html',
        bindings: {
            onPersonSelected: '&?',
            bindToUrl: '@?',
        },
    });
}
