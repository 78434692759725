module RemeCare.Patient.Model {
    import Guid = RemeCare.Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import Version = Shared.Framework.Model.Version;
    import RegistrationValue = RemeCare.Model.RegistrationValue;
    import RegistrationPhysicalQuantityValue = RemeCare.Model.RegistrationPhysicalQuantity;
    import RegistrationCodeSetValue = RemeCare.Model.RegistrationCodeSetValue;
    import RegistrationMultiCodeSetValue = RemeCare.Model.RegistrationMultiCodeSetValue;
    import RegistrationType = Shared.Contract.Code.AnamnesisRegistrationType;


    export class CarePlanDetail extends Version {
        id: Guid;

        carePlanId: Guid;
        definition: EntityTranslation;

        constructor() {
            super();
        }
    }

    export class CarePlanString extends CarePlanDetail {

        stringValue: string;

        constructor(serverObject?: Contract.Patient.Read.ICarePlanString) {
            super();
            if (serverObject != null) {
                this.definition = serverObject.StringDefinition;
                this.stringValue = serverObject.StringValue;
                this.id = serverObject.Id;
                this.carePlanId = serverObject.CarePlanId;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
            }
        }

        public toServerWrite(): Contract.Patient.Write.ICarePlanString {
            return <Contract.Patient.Write.ICarePlanString>{
                Id: this.id,
                CarePlanId: this.carePlanId,
                CareRequestPartLocalIdentity: this.definition.Id,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                ValidUntilDate: this.validUntilDate !== null ? Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                StringValue: this.stringValue
            };
        }
    }

    export class CarePlanAnamnesis extends CarePlanDetail {

        registrationValue: RegistrationValue;

        constructor(serverObject?: Contract.Patient.Read.ICarePlanAnamnesis) {
            super();
            if (serverObject != null) {
                this.definition = serverObject.AnamnesisDefinition;
                if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationPhysicalQuantity) {
                    this.registrationValue = new RegistrationPhysicalQuantityValue(<Contract.CareRequest.Read.IRegistrationPhysicalQuantity>serverObject.RegistrationValue);
                } else if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationCodeSetValue) {
                    this.registrationValue = new RegistrationCodeSetValue(<Contract.CareRequest.Read.IRegistrationCodeSetValue>serverObject.RegistrationValue);
                } else if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationMultiCodeSetValue) {
                    this.registrationValue = new RegistrationMultiCodeSetValue(<Contract.CareRequest.Read.IRegistrationMultiCodeSetValue>serverObject.RegistrationValue);
                }
                this.id = serverObject.Id;
                this.carePlanId = serverObject.CarePlanId;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
            }
        }

        public getRegistrationValueString(): string {
            if (this.registrationValue.type === RegistrationType.RegistrationPhysicalQuantity) {
                return (<RegistrationPhysicalQuantityValue>this.registrationValue).amount.toString();
            } else if (this.registrationValue.type === RegistrationType.RegistrationCodeSetValue) {
                return (<RegistrationCodeSetValue>this.registrationValue).codeSetItem.Text;
            } else if (this.registrationValue.type === RegistrationType.RegistrationMultiCodeSetValue) {
                var translations = _((<RegistrationMultiCodeSetValue>this.registrationValue).multiCodeSetItemsTranslations).map(x => x.Text);
                return translations.toString();
            }

            return null;
        }

        public toServerWrite(): Contract.Patient.Write.ICarePlanAnamnesis {
            return <Contract.Patient.Write.ICarePlanAnamnesis>{
                Id: this.id,
                CarePlanId: this.carePlanId,
                CareRequestPartLocalIdentity: this.definition.Id,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                ValidUntilDate: this.validUntilDate !== null ? Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                RegistrationValue: this.registrationValue.toServerWrite()
            };
        }

    }

    export class CarePlanDate extends CarePlanDetail {

        dateValue: Date;

        constructor(serverObject?: Contract.Patient.Read.ICarePlanDate) {
            super();
            if (serverObject != null) {
                this.definition = serverObject.DateDefinition;
                this.dateValue = Shared.DateHelper.serverDateStringToDate(serverObject.DateValue);
                this.id = serverObject.Id;
                this.carePlanId = serverObject.CarePlanId;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
            }
        }

        public getFormattedDateValue(): string {
            return moment(this.dateValue).format("DD/MM/YYYY");
        }

        public toServerWrite(): Contract.Patient.Write.ICarePlanDate {
            return <Contract.Patient.Write.ICarePlanDate>{
                Id: this.id,
                CarePlanId: this.carePlanId,
                CareRequestPartLocalIdentity: this.definition.Id,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                ValidUntilDate: this.validUntilDate !== null
                    ? Shared.DateHelper.toServerDateString(this.validUntilDate)
                    : null,
                DateValue: Shared.DateHelper.toServerDateString(this.dateValue)
            };
        }
    }


    export class CarePlanTherapyOption extends CarePlanDetail {

        option: boolean;

        constructor(serverObject?: Contract.Patient.Read.ICarePlanTherapyOption) {
            super();
            if (serverObject != null) {
                this.definition = serverObject.OptionDefinition;
                this.option = serverObject.Option;
                this.id = serverObject.Id;
                this.carePlanId = serverObject.CarePlanId;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
            }
        }

        public toServerWrite(): Contract.Patient.Write.ICarePlanTherapyOption {
            return <Contract.Patient.Write.ICarePlanTherapyOption>{
                Id: this.id,
                CarePlanId: this.carePlanId,
                CareRequestPartLocalIdentity: this.definition.Id,
                ValidFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                ValidUntilDate: this.validUntilDate !== null ? Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                Option: this.option
            };
        }
    }
}