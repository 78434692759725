namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import ObjectiveDefinition = Shared.Framework.Model.ObjectiveDefinition;
    import ObjectiveValue = Shared.Framework.Model.ObjectiveValue;

    interface IPatientFileEditObjectiveValueModalScope
        extends Shared.Framework.IBaseScope,
            ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        carePlan: CarePlan;

        objective: ObjectiveDefinition;
        editValue: ObjectiveValue;
        previousValue: ObjectiveValue;

        newValidFromDate: Date;

        minDate: Date;
        maxDate: Date;

        confirm: () => void;
    }

    class PatientFileEditObjectiveValueModalController extends Shared.Framework.ControllerBase<
        IPatientFileEditObjectiveValueModalScope
    > {
        constructor(
            protected $scope: IPatientFileEditObjectiveValueModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            this.$scope.newValidFromDate = this.$scope.editValue.validFromDate;

            const minDate = moment()
                .add(1, 'days')
                .startOf('day')
                .toDate();
            this.$scope.previousValue = _(
                _(
                    _(this.$scope.objective.objectiveValues).filter(
                        v => v.isPersonalised && moment(v.validFromDate).isBefore(this.$scope.editValue.validFromDate)
                    )
                ).sortBy(v => v.validFromDate)
            ).last();
            this.$scope.minDate =
                this.$scope.previousValue &&
                moment(this.$scope.previousValue.validFromDate)
                    .add(1, 'day')
                    .toDate() > minDate
                    ? moment(this.$scope.previousValue.validFromDate)
                          .add(1, 'day')
                          .toDate()
                    : minDate;

            this.$scope.maxDate = this.$scope.editValue.validUntilDate;
        }

        private async confirm(): Promise<void> {
            if (!this.$scope.editValue.isPersonalised) {
                this.$scope.previousValue.validUntilDate = moment(this.$scope.newValidFromDate)
                    .add(-1, 'day')
                    .toDate();

                try {
                    await this.carePlanApiSvc.updatePersonalisedObjectiveValueAsync(
                        this.$scope.carePlan.id,
                        this.$scope.objective.id,
                        this.$scope.previousValue.toServerWrite()
                    );
                    this.$scope.$close();
                } catch (e) {
                    this.errorCallback(e);
                }
            } else {
                this.$scope.editValue.validFromDate = this.$scope.newValidFromDate;
                try {
                    await this.carePlanApiSvc.savePersonalisedObjectiveValueAsync(
                        this.$scope.carePlan.id,
                        this.$scope.objective.id,
                        this.$scope.editValue.toServerWrite()
                    );
                    this.$scope.$close();
                } catch (e) {
                    this.errorCallback(e);
                }
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'PatientFileEditObjectiveValueModalCtrl',
            ($scope, $translate, toaster, $stateParams, carePlanApiSvc: Core.Services.CarePlanApiService) =>
                new PatientFileEditObjectiveValueModalController(
                    $scope,
                    $translate,
                    toaster,
                    $stateParams,
                    carePlanApiSvc
                )
        );
}
