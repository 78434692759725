module RemeCare.Core.Services {
    import Guid = Shared.Contract.Guid;
    import SearchResult = Shared.Contract.ISearchResult;

    export class AlarmsApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = Shared.Framework.ApiServiceBase.injectionParameters;

        public findAlarmsAsync(
            query: Contract.Alarms.Read.IFindAlarmsQuery
        ): Promise<SearchResult<Contract.Alarms.Read.IAlarm>> {
            return this.getPromise('Alarms', query, 'alarms');
        }

        public getAlarmAsync(id: Guid): Promise<Contract.Alarms.Read.IAlarm> {
            return this.getPromise(`Alarms/${id}`);
        }

        public getAlarmDefinitionAsync(alarmId: Guid): Promise<Shared.Contract.Read.IAlarmDefinition> {
            return this.getPromise(`Alarms/${alarmId}/AlarmDefinition`);
        }
    }

    remeCareAppModule.service('alarmsApiSvc', AlarmsApiService);
}
