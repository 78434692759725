var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var PersonPatientController = /** @class */ (function () {
            function PersonPatientController($dialog, $translate, toaster, partyApiSvc) {
                this.$dialog = $dialog;
                this.$translate = $translate;
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
            }
            PersonPatientController.prototype.$onInit = function () { };
            PersonPatientController.prototype.createPatient = function () {
                var _this = this;
                this.$dialog.confirmBox('Patient.AddAsPatient', 'Patient.AddAsPatientWarning', function () {
                    _this.partyApiSvc.createPatient(_this.person.partyId)
                        .success(function () {
                        _this.toaster.success(_this.$translate.instant('Patient.PatientCreated'));
                        if (_this.onPatientCreated) {
                            _this.onPatientCreated();
                        }
                    })
                        .error(function (e) { return _this.toaster.error(e); });
                });
            };
            return PersonPatientController;
        }());
        Management.remeCareManagementModule.component('rcPersonPatient', {
            controller: PersonPatientController,
            controllerAs: 'patientCtrl',
            bindings: {
                person: '<',
                onPatientCreated: '&?'
            },
            templateUrl: 'views/management/persons/patientFile.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
