module RemeCare.Management {

    import Guid = Shared.Contract.Guid;

    interface IToggleUserStateScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        userDetails: Shared.Contract.Read.IUserDetails;
        form: ng.IFormController;
        confirm(): void;
    }

    class ToggleUserStateController extends Shared.Framework.ControllerBase<IToggleUserStateScope> {

        constructor(
            $scope: IToggleUserStateScope,
            $translate,
            toaster,
            private partyId: Guid,
            private securityApiSvc: Shared.Framework.Service.SecurityApiSvc) {
            super($scope, $translate, toaster);
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {}

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            if (this.$scope.userDetails.IsBlocked) {
                this.securityApiSvc.unblockUser(this.partyId, this.$scope.userDetails.StatusChangeReason)
                    .success(() => {
                        this.$scope.userDetails.IsBlocked = false;
                        this.$scope.userDetails.LastStateChange = Shared.DateHelper.toServerDateTimeString(new Date());
                        this.$scope.$close(this.$scope.userDetails);
                    })
                    .error(e => this.errorCallback(e));
            } else {
                this.securityApiSvc.blockUser(this.partyId, this.$scope.userDetails.StatusChangeReason)
                    .success(() => {
                        this.$scope.userDetails.IsBlocked = true;
                        this.$scope.userDetails.LastStateChange = Shared.DateHelper.toServerDateTimeString(new Date());
                        this.$scope.$close(this.$scope.userDetails);
                    })
                    .error(e => this.errorCallback(e));
            }
        }
    }

    remeCareManagementModule.controller('toggleUserStateCtrl', (
        $scope,
        $translate,
        toaster,
        partyId: Guid,
        securityApiSvc: Shared.Framework.Service.SecurityApiSvc
    ) => new ToggleUserStateController(
        $scope,
        $translate,
        toaster,
        partyId,
        securityApiSvc
    ));
}