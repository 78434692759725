namespace RemeCare.CarePlanAction {
    interface IAdministrationScope extends IComponentScope {
        component: AdministrationDescriptor;
        options: Shared.Contract.IChoiceListOption[];
        carePlanAction: RemeCare.CarePlanAction.CarePlanAction;
    }

    class AdministrationController extends ComponentControllerBase<IAdministrationScope> {
        // @ngInject
        constructor(
            protected $scope: IAdministrationScope,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            guidanceApiSvc: RemeCare.Shared.Framework.Service.GuidanceApiService
        ) {
            super($scope, guidanceApiSvc);
        }

        protected init(): void {
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                if (this.$scope.component.value) {
                    let pointInTime = this.$scope.carePlanAction.processVersionDate;
                    if (pointInTime === undefined) {
                        pointInTime = moment.utc().toDate();
                    }
                    this.$scope.options = await this.masterdataSvc.getCodeSetItemsAsync(
                        this.$scope.component.value.ChoiceListId,
                        RemeCare.Shared.DateHelper.toServerDateString(pointInTime)
                    );
                } else {
                    this.$scope.options = [];
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    class AdministrationDirective extends ComponentDirectiveBase {
        public templateUrl = 'views/carePlanAction/directive/administration.html';

        public controller = AdministrationController;
    }

    angular.module('RemeCare.CarePlanAction').directive('rcAdministration', () => new AdministrationDirective());
}
