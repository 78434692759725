var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PersonalInfoController = /** @class */ (function () {
            function PersonalInfoController(gridBuilderSvc, modalBuilderFactory, $timeout) {
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.$timeout = $timeout;
            }
            PersonalInfoController.prototype.$onInit = function () {
                this.buildGrids();
                this.setData();
            };
            PersonalInfoController.prototype.$onChanges = function (onChangesObj) {
                var _this = this;
                if (onChangesObj.person) {
                    this.$timeout(function () {
                        _this.setData();
                    });
                }
            };
            PersonalInfoController.prototype.addAddress = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.editAddress()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PersonalInfoController.prototype.addContact = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.editContact()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PersonalInfoController.prototype.setData = function () {
                if (this.person != null) {
                    this.addressesGrid.setData(this.person.addresses);
                    var contactPoints = this.person.getAllNumbers().concat(this.person.emailAddresses);
                    this.contactPointsGrid.setData(contactPoints);
                }
                else {
                    this.addressesGrid.setData([]);
                    this.contactPointsGrid.setData([]);
                }
            };
            PersonalInfoController.prototype.buildGrids = function () {
                var _this = this;
                this.addressesGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('addressLine1', 'General.Address')
                    .addColumn('zipCode', 'General.Zipcode')
                    .addColumn('city', 'General.City')
                    .addEnumColumn('country', 'General.Country', RemeCare.Shared.Framework.ReferenceDataTypes.country)
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (a) { return _this.editAddress(a); }, function (a) { return _this.editAddress(a); }, function (a) { return a.isReadOnly || _this.readonly; })
                    .setExternalSorting(false)
                    .build();
                this.contactPointsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('type', 'General.ContactPoint', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointType)
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('toString()', 'General.Number')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (c) { return _this.editContact(c); }, function (c) { return _this.editContact(c); }, function (c) { return c.isReadOnly || _this.readonly; })
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInfoController.prototype.editAddress = function (address) {
                return __awaiter(this, void 0, void 0, function () {
                    var result;
                    var _this = this;
                    return __generator(this, function (_a) {
                        result = new Promise(function (resolve) {
                            _this.modalBuilderFactory
                                .createModalBuilder()
                                .setController('editAddressCtrl as addressCtrl')
                                .setTemplateUrl('views/shared/editAddress.html')
                                .setResolve({
                                address: function () {
                                    return address
                                        ? angular.copy(address)
                                        : RemeCare.Model.ContactPointFactory.createFromType(RemeCare.Shared.Contract.Code.ContactPointType.Address);
                                },
                                partyId: function () { return _this.person.partyId; },
                                currentAddresses: function () {
                                    return address
                                        ? _this.person.addresses.filter(function (ad) { return ad.id !== address.id; })
                                        : _this.person.addresses;
                                },
                                isReadOnly: function () { return _this.readonly; },
                            })
                                .setLarge()
                                .setResultCallBack(function (r) {
                                r ? resolve(r) : resolve(address);
                            })
                                .build();
                        });
                        this.onAddressesChange();
                        return [2 /*return*/, result];
                    });
                });
            };
            PersonalInfoController.prototype.editContact = function (contact) {
                return __awaiter(this, void 0, void 0, function () {
                    var result;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, new Promise(function (resolve) {
                                    _this.modalBuilderFactory
                                        .createModalBuilder()
                                        .setController('editContactCtrl as contactCtrl')
                                        .setTemplateUrl('views/shared/editContact.html')
                                        .setResolve({
                                        contactPoint: function () { return angular.copy(contact); },
                                        partyId: function () { return _this.person.partyId; },
                                        currentEmails: function () {
                                            return contact
                                                ? _this.person.emailAddresses.filter(function (cp) { return cp.id !== contact.id; })
                                                : _this.person.emailAddresses;
                                        },
                                        currentPhoneNumbers: function () {
                                            return contact
                                                ? _this.person.telephoneNumbers.filter(function (cp) { return cp.id !== contact.id; })
                                                : _this.person.telephoneNumbers;
                                        },
                                        currentFaxNumbers: function () {
                                            return contact
                                                ? _this.person.faxNumbers.filter(function (cp) { return cp.id !== contact.id; })
                                                : _this.person.faxNumbers;
                                        },
                                        currentMobileNumbers: function () {
                                            return contact
                                                ? _this.person.mobileNumbers.filter(function (cp) { return cp.id !== contact.id; })
                                                : _this.person.mobileNumbers;
                                        },
                                        isReadOnly: function () { return _this.readonly; },
                                    })
                                        .setResultCallBack(function (r) {
                                        r ? resolve(r) : resolve(contact);
                                    })
                                        .build();
                                })];
                            case 1:
                                result = _a.sent();
                                this.onContactPointsChange();
                                return [2 /*return*/, result];
                        }
                    });
                });
            };
            return PersonalInfoController;
        }());
        Patient.remeCarePatientModule.component('rcContactInfo', {
            templateUrl: 'views/shared/editPersonInfo.html',
            controller: PersonalInfoController,
            bindings: {
                person: '<',
                onContactPointsChange: '&',
                onAddressesChange: '&',
                readonly: '=ngDisabled',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
