var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        // The search criteria that will be used in the html
        // Differs from the actual query sent, for example dates have type 'Date' here, while the server requires strings
        var FindRemarkHtmlSearchCriteria = /** @class */ (function () {
            function FindRemarkHtmlSearchCriteria() {
            }
            return FindRemarkHtmlSearchCriteria;
        }());
        var PatientJourneyController = /** @class */ (function (_super) {
            __extends(PatientJourneyController, _super);
            function PatientJourneyController($stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc, carePlanActionApiSvc, modalBuilderFactory, authservice) {
                var _this = _super.call(this, $stateParams, $filter, $translate, toaster, patientSvc, masterdataSvc, actorRolesApiSvc) || this;
                _this.$stateParams = $stateParams;
                _this.$filter = $filter;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.patientSvc = patientSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.actorRolesApiSvc = actorRolesApiSvc;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                return _this;
            }
            /**
             * Performs a new search with the set criteria
             * Starts again at page 1
             */
            PatientJourneyController.prototype.search = function () {
                this.remarkRegistrations = [];
                this.hasMoreResults = true;
                this.currentPage = 1;
                this.hideCriteria = false;
                this.searchRemarkRegistrationsAsync();
            };
            /**
             * Retrieves the next page of results
             */
            PatientJourneyController.prototype.retrieveMoreResults = function () {
                this.currentPage++;
                this.searchRemarkRegistrationsAsync();
            };
            PatientJourneyController.prototype.clearCriteria = function () {
                this.searchCriteria = {};
                this.currentPage = 1;
            };
            PatientJourneyController.prototype.openAction = function (remarkRegistration) {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('carePlanActionDetailModalCtrl')
                    .setTemplateUrl('views/patient/monitoring/registrations/carePlanActionDetailModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .setScope({
                    actionId: remarkRegistration.actionId,
                    editRight: !remarkRegistration.readOnly,
                })
                    .setResultCallBack(function () {
                    _this.search();
                })
                    .build();
            };
            PatientJourneyController.prototype.init = function () {
                this.currentPageSize = 10;
                this.clearCriteria();
                this.remarkRegistrations = [];
                this.hasMoreResults = true;
                this.searchRemarkRegistrationsAsync();
            };
            PatientJourneyController.prototype.dataLoaded = function () { };
            PatientJourneyController.prototype.searchRemarkRegistrationsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, query, result, isSuperUser_1, mapped, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                if (!this.hasMoreResults) {
                                    return [2 /*return*/];
                                }
                                query = {
                                    carePlanId: this.searchCriteria.carePlanId,
                                    patientId: this.$stateParams.patientId,
                                    actionTemplateIds: this.searchCriteria.actionTemplateIds,
                                    startDate: RemeCare.Shared.DateHelper.toServerDateString(this.searchCriteria.startDate),
                                    endDate: RemeCare.Shared.DateHelper.toServerDateString(this.searchCriteria.endDate),
                                    page: this.currentPage,
                                    pageSize: this.currentPageSize,
                                    includeCount: false,
                                    includeHasNextPage: false,
                                };
                                // Don't await loading of filter
                                this.loadStructuralTherapyActionsFilterAsync(query);
                                return [4 /*yield*/, this.carePlanActionApiSvc.findRemarkRegistrationsAsync(query)];
                            case 1:
                                result = _b.sent();
                                if (result.Items.length < query.pageSize) {
                                    this.hasMoreResults = false;
                                }
                                isSuperUser_1 = this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser;
                                mapped = _.map(result.Items, function (i) { return new RemeCare.Model.RemarkRegistration(i, isSuperUser_1); });
                                (_a = this.remarkRegistrations).push.apply(_a, mapped);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientJourneyController.prototype.loadStructuralTherapyActionsFilterAsync = function (registrationsQuery) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, query, result, oldFilterValues, alreadySelected;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                query = {
                                    carePlanId: registrationsQuery.carePlanId,
                                    patientId: registrationsQuery.patientId,
                                    startDate: registrationsQuery.startDate,
                                    endDate: registrationsQuery.endDate,
                                };
                                return [4 /*yield*/, this.carePlanActionApiSvc.findPossibleRemarkRegistrationActionsFilterAsync(query)];
                            case 1:
                                result = _b.sent();
                                oldFilterValues = this.structuralTherapyActions;
                                this.structuralTherapyActions = _.sortBy(result, function (sta) { return (sta.Text ? sta.Text.toLowerCase() : ''); });
                                if (!this.searchCriteria.actionTemplateIds || !this.searchCriteria.actionTemplateIds.length) {
                                    return [2 /*return*/];
                                }
                                alreadySelected = _.filter(oldFilterValues, function (fv) {
                                    return _.contains(_this.searchCriteria.actionTemplateIds, fv.Id);
                                });
                                // Add already selected values to the new list of possible filter values
                                (_a = this.structuralTherapyActions).push.apply(_a, alreadySelected);
                                // Filter duplicates
                                this.structuralTherapyActions = _.uniq(this.structuralTherapyActions, function (sta) { return sta.Id; });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            return PatientJourneyController;
        }(Patient.PatientRegistrationsBaseController));
        RemeCare.Patient.remeCarePatientModule.component('rcPatientFilePatientJourney', {
            bindings: {
                $close: '&',
                $dismiss: '&',
            },
            controller: PatientJourneyController,
            templateUrl: 'patient/components/monitoring/registrations/patientJourney/patientJourney.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
