var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var AdhocRegistrationModalController = /** @class */ (function (_super) {
            __extends(AdhocRegistrationModalController, _super);
            function AdhocRegistrationModalController($scope, $translate, toaster, $rootScope, $stateParams, patientSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$rootScope = $rootScope;
                _this.$stateParams = $stateParams;
                _this.patientSvc = patientSvc;
                _this.$scope.register = function (isPermanent) { return _this.register(isPermanent); };
                _this.$scope.cancel = function () { return _this.cancel(); };
                return _this;
            }
            AdhocRegistrationModalController.prototype.$onInit = function () {
                this.$scope.showEventDateTime = !_(this.$scope.action.components)
                    .any(function (c) { return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.CareAct
                    || c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.Administration; });
                this.$scope.customView = "views/custom/" + this.$scope.action.customScreen + ".html";
            };
            AdhocRegistrationModalController.prototype.register = function (isPermanent) {
                var _this = this;
                if (isPermanent && this.$scope.registrationForm.$invalid) {
                    this.$scope.showErrors = true;
                    this.showValidationErrorMessage();
                    return;
                }
                var registrations = this.$scope.action.toServerWrite(isPermanent);
                this.patientSvc.registerNonPlannedRegistration(this.$scope.action.patientId, this.$scope.action.id, registrations)
                    .success(function () {
                    _this.generalSuccess();
                    _this.$scope.$close(registrations);
                })
                    .catch(function (e) {
                    _this.toaster.error(e.data);
                    _this.$rootScope.$broadcast('actionSaveError', e.headers('X-Errors'));
                });
            };
            AdhocRegistrationModalController.prototype.cancel = function () {
                this.$scope.$dismiss();
            };
            return AdhocRegistrationModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('adhocRegistrationModalCtrl', AdhocRegistrationModalController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
