var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var DurationUnitCode;
                (function (DurationUnitCode) {
                    DurationUnitCode[DurationUnitCode["Hours"] = 1] = "Hours";
                    DurationUnitCode[DurationUnitCode["Days"] = 2] = "Days";
                    DurationUnitCode[DurationUnitCode["Weeks"] = 3] = "Weeks";
                    DurationUnitCode[DurationUnitCode["Months"] = 4] = "Months";
                    DurationUnitCode[DurationUnitCode["Years"] = 5] = "Years";
                    DurationUnitCode[DurationUnitCode["Minutes"] = 6] = "Minutes";
                })(DurationUnitCode = Codes.DurationUnitCode || (Codes.DurationUnitCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
