var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileRegistrationsMonitorController = /** @class */ (function (_super) {
            __extends(PatientFileRegistrationsMonitorController, _super);
            function PatientFileRegistrationsMonitorController($scope, $translate, toaster, $state, carePlanApiSvc, masterdataSvc, therapyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$state = $state;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.$scope.registerAdhocRegistration = function () { return _this.registerAdhocRegistration(); };
                return _this;
            }
            PatientFileRegistrationsMonitorController.prototype.$onInit = function () { };
            PatientFileRegistrationsMonitorController.prototype.registerAdhocRegistration = function () {
                this.$state.go('patients.patientfile.monitoring.registrations.adhoc');
            };
            return PatientFileRegistrationsMonitorController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('patientFileRegistrationsMonitorCtrl', function ($scope, $translate, toaster, $state, carePlanApiSvc, masterdataSvc, therapyApiSvc) { return new PatientFileRegistrationsMonitorController($scope, $translate, toaster, $state, carePlanApiSvc, masterdataSvc, therapyApiSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
