namespace RemeCare.CareRequest {
    import CareRequestSummary = Contract.CareRequest.Read.ICareRequestSummary;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    interface ICareRequest {
        id: Shared.Contract.Guid;
        therapy: EntityTranslation;
        creationDate: string;
        patientName: string;
        patientId: string;
        canSeePatientFile: boolean;
        requestorName: string;
        startDate: string;
        createdBy: string;
        status: string;
        readOnly: boolean;
        withinOneDay: boolean;
    }

    enum TabsIdentification {
        ToDoByMe = 1,
        ToDoByOthers = 2,
        CreatedByMe = 3,
        AllCareRequests = 4,
        CreatedByMyTeams = 5,
    }

    class CareRequestSearchController implements ng.IComponentController {
        public searchCollapsed: boolean;
        public careRequestGrid: Shared.Framework.Grid.Grid<ICareRequest>;
        public therapies: EntityTranslation[];
        public filterTypes: EnumTranslation[];
        public careRequestForm: Shared.Framework.Directive.IFormController;
        public activeTab: number;
        public tabs: RemeCare.Shared.Framework.Grid.SearchGridTab[];
        public ongoingTranslation: string;

        constructor(
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $state: ng.ui.IStateService,
            private readonly careRequestSearchSvc: CareRequestSearchService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly authservice: Shared.Framework.AuthService,
            private readonly $stateParams: ICareRequestSearchStateParams,
            private readonly dateHelper: Shared.DateHelper
        ) {}

        public $onInit(): void {
            this.activeTab = 0;
            // Determine available tabs
            this.tabs = [];
            this.tabs.push({
                heading: this.$translate.instant('Views.CareRequest.Search.ToDoByMe'),
                tabActivated: () => this.tabActivated(TabsIdentification.ToDoByMe),
            });
            if (!this.authservice.isInternalUser()) {
                this.tabs.push({
                    heading: this.$translate.instant('Views.CareRequest.Search.CreatedByMe'),
                    tabActivated: () => this.tabActivated(TabsIdentification.CreatedByMe),
                });

                if (this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareProvider) {
                    // Select the tab that you just pushed as 'ActiveTab'
                    // If care request was saved, open CreatedByMe
                    if (this.$stateParams.savedCareRequest === true) {
                        this.activeTab = this.tabs.length - 1;
                    }
                }
            }
            if (this.authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.CareProvider) {
                this.tabs.push({
                    heading: this.$translate.instant('Views.CareRequest.Search.CreatedByMyTeams'),
                    tabActivated: () => this.tabActivated(TabsIdentification.CreatedByMyTeams),
                });
            }
            if (this.authservice.isInternalUser()) {
                this.tabs.push({
                    heading: this.$translate.instant('Views.CareRequest.Search.ToDoByOthers'),
                    tabActivated: () => this.tabActivated(TabsIdentification.ToDoByOthers),
                });
            }
            if (this.authservice.isInternalUser()) {
                this.tabs.push({
                    heading: this.$translate.instant('Views.CareRequest.Search.AllRequests'),
                    tabActivated: () => this.tabActivated(TabsIdentification.AllCareRequests),
                });
            }

            this.buildGrid();
            this.loadDataAsync();
        }

        public tabActivated(tabIdentification: TabsIdentification): void {
            switch (tabIdentification) {
                case TabsIdentification.ToDoByMe:
                    this.careRequestGrid.searchCriteria.filterTypeId =
                        Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe;
                    break;
                case TabsIdentification.CreatedByMyTeams:
                    this.careRequestGrid.searchCriteria.filterTypeId =
                        Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMyTeams;
                    break;
                case TabsIdentification.ToDoByOthers:
                    this.careRequestGrid.searchCriteria.filterTypeId =
                        Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers;
                    break;
                case TabsIdentification.CreatedByMe:
                    this.careRequestGrid.searchCriteria.filterTypeId =
                        Shared.Contract.Code.CareRequestFilterTypeCode.RequestedByMe;
                    break;
                default:
                    this.careRequestGrid.searchCriteria.filterTypeId = undefined; // Not a special filter type
                    if (this.careRequestGrid.searchCriteria.creationDateFrom === undefined) {
                        this.careRequestGrid.searchCriteria.creationDateFrom = this.dateHelper.subtractDuration(
                            this.dateHelper.today(),
                            { Quantity: 3, Unit: Shared.Contract.Code.DurationUnit.Months }
                        );
                    }
                    break;
            }
            this.careRequestGrid.search();
        }

        public createCareRequest(therapy: EntityTranslation): void {
            this.$state.go('careRequests.careRequest', { therapyId: therapy.Id });
        }

        public fixStatus(): boolean {
            return (
                this.careRequestGrid.searchCriteria.filterTypeId ===
                    Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe ||
                this.careRequestGrid.searchCriteria.filterTypeId ===
                    Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers
            );
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [this.therapies, this.filterTypes] = await Promise.all([
                    this.careRequestSearchSvc.getPossibleTherapiesAsync(),
                    this.masterdataSvc.findCareRequestFilterTypesAsync(),
                ]);
                this.ongoingTranslation = this.filterTypes.filter(
                    (ft) => ft.Id === Shared.Contract.Code.CareRequestFilterTypeCode.OnGoing
                )[0].Text;
                // Remove items handled by tabs
                this.filterTypes = this.filterTypes.filter(
                    (ft) =>
                        ft.Id !== Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers &&
                        ft.Id !== Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }
        private canShowNavigationButton(cr: ICareRequest): boolean {
            return cr.patientId !== null && cr.canSeePatientFile === true;
        }
        private buildGrid(): void {
            this.careRequestGrid = this.gridBuilderSvc
                .createGridBuilder<ICareRequest>((page, pageSize, sortField, sortDirection, criteria) =>
                    this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria)
                )
                .addDateColumn('creationDate', 'Views.CareRequest.Search.CreationDate', 'short')
                .addColumn('patientName', 'Views.CareRequest.Search.PatientName')
                .addColumn('therapy.Text', 'General.CarePlan')
                .addConditionallyStyledColumn(
                    'startDate',
                    'General.StartDate',
                    `withinOneDay`,
                    { red: true },
                    { cellFilter: 'date: "shortDate"' }
                )
                .addColumn('createdBy', 'Views.CareRequest.Search.CreatedBy')
                .addColumn('status', 'General.Status')
                .addConditionalNavigationButtonAndEditButtonColumn(
                    this.canShowNavigationButton,
                    (cr) => cr.readOnly,
                    'patients.patientfile',
                    {
                        patientId: 'patientId',
                    },
                    'careRequests.careRequest',
                    {
                        careRequestId: 'id',
                        therapyId: 'therapy.Id',
                    }
                )
                .build();
            this.careRequestGrid.pagingOptions.pageSize = 20;
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<ICareRequest>> {
            sortField = sortField || 'creationDate';
            sortDirection = sortDirection || 'desc';

            const query: Contract.CareRequest.Read.ICareRequestQuery = {
                pageSize,
                page,
                sortField,
                sortOrder: sortDirection,
                filterType: criteria.filterTypeId,
                status: criteria.statusId,
                therapyId: criteria.therapyId,
                firstNamePatient: criteria.patientFirstName,
                lastNamePatient: criteria.patientLastName,
                firstNameRequestor: criteria.requestorFirstName,
                lastNameRequestor: criteria.requestorLastName,
                creationDateUntil: criteria.creationDateUntil
                    ? Shared.DateHelper.toServerDateString(criteria.creationDateUntil)
                    : criteria.creationDateUntil,
                creationDateFrom: criteria.creationDateFrom
                    ? Shared.DateHelper.toServerDateString(criteria.creationDateFrom)
                    : criteria.creationDateFrom,
                startDateFrom: criteria.startDateFrom
                    ? Shared.DateHelper.toServerDateString(criteria.startDateFrom)
                    : criteria.startDateFrom,
                startDateUntil: criteria.startDateUntil
                    ? Shared.DateHelper.toServerDateString(criteria.startDateUntil)
                    : criteria.startDateUntil,
            };

            try {
                const data = await this.careRequestSearchSvc.searchCareRequestsAsync(query);
                const items: ICareRequest[] = _(data.Items).map((serverObject: CareRequestSummary) => {
                    return {
                        id: serverObject.Id,
                        therapy: serverObject.Therapy,
                        creationDate: serverObject.CreationDate,
                        patientName: [serverObject.PatientLastName, serverObject.PatientFirstName].join(' '),
                        patientId: serverObject.PatientId,
                        canSeePatientFile: serverObject.CanSeePatientFile,
                        startDate: serverObject.StartDate,
                        createdBy: serverObject.CreatedBy,
                        status:
                            serverObject.Status.Id !== Shared.Contract.Code.CareRequestStatus.Final &&
                            serverObject.Status.Id !== Shared.Contract.Code.CareRequestStatus.Cancelled
                                ? this.$translate.instant('Views.CareRequest.Search.CurrentStep', {
                                      currentStep: serverObject.CurrentStep,
                                      totalSteps: serverObject.TotalSteps,
                                  })
                                : serverObject.Status.Text,
                        readOnly: serverObject.ReadOnly,
                        withinOneDay:
                            (moment(Shared.DateHelper.tomorrow()).isAfter(
                                Shared.DateHelper.serverDateStringToDate(serverObject.StartDate)
                            ) ||
                                moment(Shared.DateHelper.tomorrow()).isSame(
                                    Shared.DateHelper.serverDateStringToDate(serverObject.StartDate),
                                    'day'
                                )) &&
                            (serverObject.Status.Id === Shared.Contract.Code.CareRequestStatus.Open ||
                                serverObject.Status.Id === Shared.Contract.Code.CareRequestStatus.ToValidate),
                        requestorName: '',
                    };
                });

                this.searchCollapsed = true;

                return {
                    Items: items,
                    Total: data.Total,
                };
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareCareRequestModule.component('careRequestSearch', {
        controller: CareRequestSearchController,
        templateUrl: 'views/careRequest/careRequestsearch.html',
    });
}
