var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ManagePersonController = /** @class */ (function (_super) {
            __extends(ManagePersonController, _super);
            function ManagePersonController($scope, $translate, toaster, $stateParams, partyApiSvc, gridBuilderSvc, authservice, globalActorRoleSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.partyApiSvc = partyApiSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.authservice = authservice;
                _this.globalActorRoleSvc = globalActorRoleSvc;
                _this.$scope.patientCreated = function () { return _this.patientCreated(); };
                _this.$scope.showGroups = function () { return _this.showGroups(); };
                return _this;
            }
            ManagePersonController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.partyId = this.$stateParams.partyId;
                this.buildGrids();
                this.partyApiSvc.getPersonDetails(this.$stateParams.partyId, false)
                    .success(function (r) {
                    _this.$scope.person = new RemeCare.Model.Person(r);
                    _this.loadHealthCareProfessionalContactPersons(_this.$scope.person);
                    _this.setGridData();
                })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            ManagePersonController.prototype.showGroups = function () {
                var profile = this.authservice.getProfile();
                return (profile === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser ||
                    profile === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager);
            };
            ManagePersonController.prototype.loadHealthCareProfessionalContactPersons = function (person) {
                var _this = this;
                if (person.isHealthCareProfessional()) {
                    this.partyApiSvc.getContactPersons(person.getHealthCareProfessionalPartyRole().PartyRoleId)
                        .success(function (r) { return _this.$scope.contactPersons = r; })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            ManagePersonController.prototype.findGlobalActorRoles = function (person) {
                var _this = this;
                var query = {
                    name: null,
                    partyRoleIds: _(person.partyRoles).map(function (pr) { return pr.PartyRoleId; })
                };
                this.globalActorRoleSvc.searchSummaries(query)
                    .success(function (r) { return _this.$scope.globalActorRolesGrid.setData(r.Items); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            ManagePersonController.prototype.buildGrids = function () {
                this.$scope.addressGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('addressLine1', 'General.StreetAndNumber')
                    .addColumn('city', 'General.City')
                    .addEnumColumn('country', 'General.Country', RemeCare.Shared.Framework.ReferenceDataTypes.country)
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.$scope.phoneFaxGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addEnumColumn('type', '', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointType)
                    .addColumn('toString()', 'General.Number')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.$scope.emailGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('email', 'General.Email')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
                this.$scope.globalActorRolesGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('Text', 'General.Name')
                    .addNavigationColumn('eye-open', 'management.globalActorRoles.details', { actorRoleId: 'Id' })
                    .build();
            };
            ManagePersonController.prototype.setGridData = function () {
                this.$scope.addressGrid.setData(this.$scope.person.addresses);
                this.$scope.phoneFaxGrid.setData(this.$scope.person.getAllNumbers());
                this.$scope.emailGrid.setData(this.$scope.person.emailAddresses);
                this.findGlobalActorRoles(this.$scope.person);
            };
            ManagePersonController.prototype.patientCreated = function () {
                this.$onInit();
            };
            return ManagePersonController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('managePersonCtrl', ManagePersonController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
