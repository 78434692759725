namespace RemeCare.Core.Services {
    import FindAgendaActionsQuery = Contract.CarePlanAction.Read.IFindAgendaActionsQuery;
    import FindPersonalAgendaActionsQuery = Contract.CarePlanAction.Read.IFindPersonalAgendaActionsQuery;
    import FindRegisteredActionsQuery = Contract.CarePlanAction.Read.IFindRegisteredActionsQuery;
    import FindActionTimingInformationsQuery = Contract.CarePlanAction.Read.IFindActionTimingInformationsQuery;

    interface ICacheEntry {
        status: number;
        added: Date;
    }

    export class CarePlanActionApiService extends Shared.Framework.ApiServiceBase {
        public static $inject = ['baseUrl', '$http', 'spinnerSvc'];

        private cachedStatusList: { [id: string]: ICacheEntry } = {};
        private cacheTimeout: number = 60000;

        public findActionsInFlowAsync(
            actionId: Shared.Contract.Guid
        ): Promise<Contract.CarePlanAction.Read.IActionInfo[]> {
            return this.getPromise(`Actions/${actionId}/ActionsInFlow`);
        }

        public findAgendaActions(
            query: FindAgendaActionsQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IAgendaAction>> {
            return new Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IAgendaAction>>(
                (resolve, reject) => {
                    this.getPromise('Actions/Agenda', query, 'actions')
                        .then((result: Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IAgendaAction>) => {
                            result.Items.map((x) => {
                                const item = this.cachedStatusList[x.Id.toString()];
                                if (item != null) {
                                    const passed = new Date().getTime() - item.added.getTime();
                                    if (passed < this.cacheTimeout) {
                                        x.Status = item.status;
                                    } else {
                                        delete this.cachedStatusList[x.Id.toString()];
                                    }
                                }

                                return x;
                            });

                            resolve(result);
                        })
                        .catch((e) => reject(e));
                }
            );
        }

        public findPersonalAgendaActions(
            query: FindPersonalAgendaActionsQuery
        ): Promise<Contract.CarePlanAction.Read.IPersonalAgendaAction[]> {
            if (query && query.actorRoles && query.actorRoles.length) {
                return this.getInParts(
                    'Actions/PersonalAgenda',
                    query.actorRoles,
                    50,
                    (subset) =>
                        ({
                            ...query,
                            actorRoles: subset,
                        } as FindPersonalAgendaActionsQuery)
                ).then((result: Contract.CarePlanAction.Read.IPersonalAgendaAction[]) =>
                    this.handlePersonalAgendaActions(result)
                );
            }
            return this.getPromise('Actions/PersonalAgenda', query, 'actions').then(
                (result: Contract.CarePlanAction.Read.IPersonalAgendaAction[]) =>
                    this.handlePersonalAgendaActions(result)
            );
        }

        public findRegistrationsAsync(
            query: FindRegisteredActionsQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IRegisteredAction>> {
            return new Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IRegisteredAction>>(
                (resolve, reject) => {
                    this.getPromise(
                        'Actions/Registrations',
                        query,
                        query.includeCount === true ? 'actionsCount' : 'actions'
                    )
                        .then(
                            (result: Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IRegisteredAction>) => {
                                result.Items.map((x) => {
                                    const item = this.cachedStatusList[x.Id.toString()];
                                    if (item != null) {
                                        const passed = new Date().getTime() - item.added.getTime();
                                        if (passed < this.cacheTimeout) {
                                            x.Status = item.status;
                                        } else {
                                            delete this.cachedStatusList[x.Id.toString()];
                                        }
                                    }
                                });

                                resolve(result);
                            }
                        )
                        .catch((e) => reject(e));
                }
            );
        }

        public findPossibleRegistrationActionsFilterAsync(
            query: RemeCare.Contract.CarePlanAction.Read.IFindPossibleRegistrationActionsQuery
        ): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise('Actions/PossibleRegistrationActions', query, 'registrationActionsFilter');
        }

        public findRemarkRegistrationsAsync(
            query: RemeCare.Contract.CarePlanAction.Read.IFindRemarkRegistrationsQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IRemarkRegistration>> {
            return this.getPromise('Actions/RemarkRegistrations', query, 'remarks');
        }

        public findPossibleRemarkRegistrationActionsFilterAsync(
            query: RemeCare.Contract.CarePlanAction.Read.IFindPossibleRemarkRegistrationActionsQuery
        ): Promise<Shared.Contract.IEntityTranslation[]> {
            return this.getPromise(
                'Actions/PossibleRemarkRegistrationActions',
                query,
                'remarkRegistrationActionsFilter'
            );
        }

        public findActionTimingInformations(
            query: FindActionTimingInformationsQuery
        ): Promise<Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IActionTimingInformation>> {
            return this.getPromise<
                Shared.Contract.ISearchResult<Contract.CarePlanAction.Read.IActionTimingInformation>
            >('Actions/Timings', query, 'actionsTimings');
        }

        public getCarePlanActionAsync(
            actionId: Shared.Contract.Guid,
            query?: Contract.Core.Read.IDateQuery
        ): Promise<Contract.CarePlanAction.Read.ICarePlanAction> {
            return this.getPromise(`Actions/${actionId}`, query, 'actions');
        }

        public getCarePlanActionHistoryAsync(
            actionId: Shared.Contract.Guid
        ): Promise<Contract.CarePlanAction.Read.ICarePlanActionHistory[]> {
            return this.getPromise(`Actions/${actionId}/History`, null, 'actionHistory');
        }

        public getCarePlanActionConfigurationInformationAsync(
            actionId: Shared.Contract.Guid
        ): Promise<Contract.CarePlanAction.Read.IActionConfigurationInformation> {
            return this.getPromise(`Actions/${actionId}/ConfigurationInformation`);
        }

        public cancelCarePlanActionsAsync(actionIds: Shared.Contract.Guid[]): Promise<void> {
            return this.postPromise('Actions/Cancel', { actionIds: actionIds }, 'actions');
        }

        public makeRegistrationsAsync(
            registrations: Contract.CarePlanAction.Write.IMakeRegistration,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<void> {
            const params = registrations;

            // If the action registration is permanent, cache it as being executed
            if (params.permanent) {
                this.cachedStatusList[params.actionId.toString()] = { status: 3, added: new Date() };
            }

            return this.postPromise(
                `Actions/${registrations.actionId}/Registrations`,
                params,
                `actions-${registrations.actionId}`,
                null,
                errorCallback
            );
        }

        // TODO: This should be controlled with header Accept-Language
        public getStandardPhrases(actionTemplateId, langCode): ng.IHttpPromise<string[]> {
            const url = 'ActionTemplates/' + actionTemplateId + '/StandardPhrases?langCode=' + langCode;
            return this.get(url);
        }

        public getCarePlanActionRegistrationsAsync(
            actionId: Shared.Contract.Guid
        ): Promise<RemeCare.Contract.Patient.Read.IRegistrationValueSummary[]> {
            return this.getPromise(`Actions/${actionId}/RegistrationValues`);
        }

        public getCarePlanActionMedicationAsync(
            actionId: Shared.Contract.Guid
        ): Promise<RemeCare.Contract.Patient.Read.IMedication> {
            return this.getPromise(`Actions/${actionId}/MedicationInfo`);
        }

        public rescheduleAsync(
            actionId: Shared.Contract.Guid,
            params: Contract.CarePlanAction.Write.ISchedule
        ): Promise<{}> {
            return this.putPromise(`Actions/${actionId}/Schedule`, params, 'reschedule');
        }

        public getAberrantRegistrationInformation(
            aberrantRegistrationValueIds: Shared.Contract.Guid[]
        ): Promise<Contract.CarePlanAction.Read.IAberrantRegistration[]> {
            return this.getInParts(
                'Actions/AberrantRegistrationInformation',
                aberrantRegistrationValueIds,
                10,
                (subset) => {
                    return { RegistrationValueIds: subset };
                }
            );
        }

        private handlePersonalAgendaActions(
            items: Contract.CarePlanAction.Read.IPersonalAgendaAction[]
        ): Promise<Contract.CarePlanAction.Read.IPersonalAgendaAction[]> {
            return new Promise<Contract.CarePlanAction.Read.IPersonalAgendaAction[]>((resolve, reject) => {
                items.map((x) => {
                    const item = this.cachedStatusList[x.Id.toString()];
                    if (item != null) {
                        const passed = new Date().getTime() - item.added.getTime();
                        if (passed < this.cacheTimeout) {
                            x.Status = item.status;
                        } else {
                            delete this.cachedStatusList[x.Id.toString()];
                        }
                    }

                    return x;
                });

                resolve(items);
            });
        }
    }

    RemeCare.remeCareAppModule.service('carePlanActionApiSvc', CarePlanActionApiService);
}
