var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileAddAnamnesisInfoModalController = /** @class */ (function (_super) {
            __extends(PatientFileAddAnamnesisInfoModalController, _super);
            function PatientFileAddAnamnesisInfoModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.$stateParams = $stateParams;
                _this.patientSvc = patientSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileAddAnamnesisInfoModalController.prototype.$onInit = function () {
                this.$scope.patientId = this.$stateParams.patientId;
            };
            PatientFileAddAnamnesisInfoModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.anamnesisInfo.id === null || this.$scope.anamnesisInfo.id === undefined) {
                    this.patientSvc.saveAnamnesisInfo(this.$scope.patientId, this.$scope.anamnesisInfo.toServerWrite())
                        .success(function () { return _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
                else {
                    this.patientSvc.updateAnamnesisInfo(this.$scope.patientId, this.$scope.anamnesisInfo.id, this.$scope.anamnesisInfo.toServerWrite())
                        .success(function () { return _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
            };
            return PatientFileAddAnamnesisInfoModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('patientFileAddAnamnesisInfoModalCtrl', function ($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc) { return new PatientFileAddAnamnesisInfoModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
