var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ActionType = RemeCare.Shared.Contract.Code.ActionType;
        var ApplicationProfileType = RemeCare.Shared.Contract.Code.ApplicationProfileType;
        var DateHelper = RemeCare.Shared.DateHelper;
        var PatientFileDocumentController = /** @class */ (function () {
            function PatientFileDocumentController($state, authservice, carePlanActionApiSvc, masterdataSvc, patientSvc, toaster) {
                this.$state = $state;
                this.authservice = authservice;
                this.carePlanActionApiSvc = carePlanActionApiSvc;
                this.masterdataSvc = masterdataSvc;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
            }
            PatientFileDocumentController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1, _b, nameSplit, e_2, profile;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getDocumentCategoriesAsync(),
                                        this.patientSvc.getCarePlansAsync(this.patientId),
                                    ])];
                            case 1:
                                _a = _c.sent(), this.categories = _a[0], this.carePlans = _a[1];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _c.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3:
                                this.patientFileDocument = {};
                                if (!this.documentId) return [3 /*break*/, 7];
                                _c.label = 4;
                            case 4:
                                _c.trys.push([4, 6, , 7]);
                                _b = this;
                                return [4 /*yield*/, this.patientSvc.getDocumentAsync(this.patientId, this.documentId)];
                            case 5:
                                _b.patientFileDocument = _c.sent();
                                this.referenceDate = RemeCare.Shared.DateHelper.serverDateStringToDate(this.patientFileDocument.ReferenceDate);
                                if (this.patientFileDocument.Name) {
                                    nameSplit = this.patientFileDocument.Name.split('.');
                                    this.extension = "." + nameSplit.pop();
                                    this.name = nameSplit.join('.');
                                }
                                if (this.patientFileDocument.ActionId) {
                                    this.loadActionAsync(); // no await
                                }
                                return [3 /*break*/, 7];
                            case 6:
                                e_2 = _c.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 7];
                            case 7:
                                profile = this.authservice.getProfile();
                                this.carePlanRequired =
                                    profile === ApplicationProfileType.AdministrativeManager ||
                                        profile === ApplicationProfileType.TherapyProvider;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileDocumentController.prototype.cancel = function () {
                this.$state.go('patients.patientfile.mediaDocuments.overview');
            };
            PatientFileDocumentController.prototype.save = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var id, document_1, e_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.form.$setSubmitted();
                                if (this.form.$invalid) {
                                    this.form.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                id = this.documentId;
                                this.patientFileDocument.Name = this.name + this.extension;
                                if (!this.documentId) {
                                    document_1 = this.documents[0];
                                    id = document_1.Id;
                                    this.patientFileDocument.Name = document_1.Name;
                                    this.patientFileDocument.Url = document_1.Url;
                                    this.patientFileDocument.Size = document_1.Size;
                                }
                                this.patientFileDocument.ReferenceDate = RemeCare.Shared.DateHelper.toServerDateString(this.referenceDate);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.saveDocumentAsync(this.patientId, id, this.patientFileDocument)];
                            case 2:
                                _a.sent();
                                this.$state.go('patients.patientfile.mediaDocuments.overview');
                                return [3 /*break*/, 4];
                            case 3:
                                e_3 = _a.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileDocumentController.prototype.loadActionAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var action, e_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.getCarePlanActionAsync(this.patientFileDocument.ActionId)];
                            case 1:
                                action = _a.sent();
                                if (action == null) {
                                    return [2 /*return*/];
                                }
                                this.actionPlannedDateTimeFrom = DateHelper.serverDateStringToDateTime(action.PlannedDateTimeFrom);
                                this.actionPlannedDateTimeUntil = DateHelper.serverDateStringToDateTime(action.PlannedDateTimeUntil);
                                this.dateFilter =
                                    this.actionPlannedDateTimeFrom.getHours() !== 0 ||
                                        this.actionPlannedDateTimeFrom.getMinutes() !== 0 ||
                                        this.actionPlannedDateTimeUntil.getHours() !== 0 ||
                                        this.actionPlannedDateTimeUntil.getMinutes() !== 0
                                        ? 'short'
                                        : 'shortDate';
                                this.showUntil = this.actionPlannedDateTimeFrom.valueOf() !== this.actionPlannedDateTimeUntil.valueOf();
                                this.referenceDateRequired = _.find(action.ActionParts, function (ap) { return ap.ActionType.Id === ActionType.RequestInformationObject; }).RequestInfoParameter.HasReferenceDate;
                                return [3 /*break*/, 3];
                            case 2:
                                e_4 = _a.sent();
                                this.toaster.error(e_4);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            return PatientFileDocumentController;
        }());
        Patient.remeCarePatientModule.component('patientFileDocument', {
            controller: PatientFileDocumentController,
            controllerAs: 'documentCtrl',
            templateUrl: 'views/patient/mediaDocuments/patientFileDocument.html',
            bindings: {
                documentId: '@?',
                patientId: '@',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
