var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CareTeamPartyBaseController = /** @class */ (function () {
            // @ngInject
            function CareTeamPartyBaseController($scope, $translate, $filter, toaster, patientSvc, authservice, partyApiSvc, modalBuilderFactory, gridBuilderSvc) {
                this.$scope = $scope;
                this.$translate = $translate;
                this.$filter = $filter;
                this.toaster = toaster;
                this.patientSvc = patientSvc;
                this.authservice = authservice;
                this.partyApiSvc = partyApiSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.gridBuilderSvc = gridBuilderSvc;
            }
            CareTeamPartyBaseController.prototype.$onInit = function () {
                var _this = this;
                this.init();
                this.$scope.$watch(function () { return _this.onlyActive; }, function () {
                    _this.partyOverviewGrid.search();
                });
            };
            CareTeamPartyBaseController.prototype.hasActorRoles = function () {
                return _(this.actorRoles).any();
            };
            CareTeamPartyBaseController.prototype.addCareTeamParty = function (ar) {
                var partyRoleIds = this.getPartyRoleIds(ar);
                if (partyRoleIds.length > 0) {
                    this.showPartySelector(ar, this.getActorRoleInclusionDetails(ar).value());
                }
                else {
                    this.showPartySearchModal(ar);
                }
            };
            CareTeamPartyBaseController.prototype.criteriaChanged = function () {
                if (!this.partyOverviewGrid) {
                    return;
                }
                this.partyOverviewGrid.pagingOptions.currentPage = 1;
                this.partyOverviewGrid.search();
            };
            CareTeamPartyBaseController.prototype.getActiveCarePlans = function (party) {
                return _(party.Assignments.filter(function (as) { return as.CarePlan.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open; }).map(function (a) { return a.CarePlan.Name; }))
                    .unique()
                    .join(', ');
            };
            CareTeamPartyBaseController.prototype.buildGrids = function () {
                this.buildOverviewGrid();
            };
            CareTeamPartyBaseController.prototype.isActiveAssignment = function (assignment) {
                return (assignment.CarePlan.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open &&
                    ((moment(assignment.ValidFromDate).isBefore(moment()) && assignment.ValidUntilDate == null) ||
                        moment(assignment.ValidUntilDate).isAfter(moment())));
            };
            CareTeamPartyBaseController.prototype.showPartyAssignments = function (party, partyType) {
                this.selectedParty = party;
                if (party) {
                    this.modalBuilderFactory
                        .createComponentModalBuilder('rcPartyAssigmentsModal')
                        .setBindings({
                        party: party,
                        partyType: partyType,
                        patientId: this.patientId,
                        editRight: this.editRight,
                        careTeamsCanEdit: this.careTeamsCanEdit,
                    })
                        .setResultCallBack(function (r) { })
                        .setLarge()
                        .build();
                }
            };
            CareTeamPartyBaseController.prototype.searchCareTeamAsync = function (page, pageSize, sortField, sortOrder, criteria, partyType, filterFun) {
                var _this = this;
                return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                    var query, careTeam, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                query = {
                                    page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortOrder,
                                    excludeStopped: false,
                                    excludeHealthCareNonProfessional: true,
                                };
                                return [4 /*yield*/, this.patientSvc.getCareTeamAsync(this.patientId, partyType, query)];
                            case 1:
                                careTeam = _a.sent();
                                if (filterFun) {
                                    careTeam.Items = careTeam.Items.filter(filterFun);
                                }
                                careTeam.Total = careTeam.Items.length;
                                this.hasMoreParties = careTeam.Total > 1;
                                resolve(careTeam);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                reject();
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            };
            CareTeamPartyBaseController.prototype.showCareTeamAssignmentModalAsync = function (careTeamPartyAssignment, partyRoleId, firstName, lastName) {
                var _this = this;
                return new Promise(function (resolve) {
                    var nameArray = [];
                    if (firstName != null) {
                        nameArray.push(firstName);
                    }
                    if (lastName != null) {
                        nameArray.push(lastName);
                    }
                    var name = nameArray.join(' ');
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('editCareTeamPartyCtrl')
                        .setTemplateUrl('views/patient/careTeam/editCareTeamPartyAssignment.html')
                        .setResolve({
                        patientId: function () { return _this.patientId; },
                    })
                        .setScope({
                        partyRoleId: partyRoleId,
                        name: name,
                        careTeamParty: angular.copy(careTeamPartyAssignment),
                        readOnly: !(careTeamPartyAssignment.CanEdit && _this.editRight),
                    })
                        .setResultCallBack(function () {
                        _this.partyOverviewGrid.search();
                        resolve(careTeamPartyAssignment);
                    })
                        .build();
                });
            };
            CareTeamPartyBaseController.prototype.deleteCareTeamPartyAsync = function (careTeamPartyAssignment) {
                var _this = this;
                return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.removeActorRoleAssignmentAsync(this.patientId, careTeamPartyAssignment.CarePlan.TherapyId, careTeamPartyAssignment.Id)];
                            case 1:
                                _a.sent();
                                resolve(true);
                                this.removeSelected();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                resolve(false);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            };
            CareTeamPartyBaseController.prototype.removeSelected = function () {
                this.selectedParty = null;
                this.partyAssignmentGrid.setData(null);
            };
            CareTeamPartyBaseController.prototype.getPartyRoleIds = function (actorRole) {
                return this.getActorRoleInclusionDetails(actorRole)
                    .map(function (arid) { return arid.PartyRoleId; })
                    .filter(function (id) { return id != null; })
                    .value();
            };
            CareTeamPartyBaseController.prototype.getActorRoleInclusionDetails = function (actorRole) {
                return _(actorRole.ActorRoleInclusions)
                    .chain()
                    .map(function (ari) { return ari.ActorRoleInclusionDetails; })
                    .flatten();
            };
            CareTeamPartyBaseController.prototype.createCareTeamParty = function (actorRole, hcParty) {
                return {
                    PartyRoleId: hcParty.PartyRoleId,
                    Assignments: [
                        {
                            CarePlan: {
                                TherapyId: actorRole.Therapy.Id,
                                Name: actorRole.Therapy.Text,
                            },
                            ActorRole: {
                                Id: actorRole.ActorRoleId,
                                Text: actorRole.Name,
                            },
                            CanEdit: true,
                        },
                    ],
                };
            };
            return CareTeamPartyBaseController;
        }());
        Patient.CareTeamPartyBaseController = CareTeamPartyBaseController;
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
