/// <reference path="../../contract/core/codes/genderCode.ts"/>

namespace RemeCare {
    import GenderCode = Contract.Core.Codes.GenderCode;
    import Nationality = Shared.Contract.Code.Nationality;
    import NationalityIso2 = Shared.Contract.Code.NationalityIso2;

    export class NationalNumberService {
        public constructor(private readonly toaster: Shared.Framework.Toaster) {}

        public isValidNationalNumber(nationalNumber: string): boolean {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationalNumber || !/^\d{11}$/.test(nationalNumber)) {
                return false;
            }

            return this.isNumberFromBefore2000(nationalNumber) || this.isNumberFrom2000Onwards(nationalNumber);
        }

        public isValidDateOfBirth(dateOfBirth: Date, nationalNumber: string): boolean {
            nationalNumber = this.sanitize(nationalNumber);
            if (!dateOfBirth || !nationalNumber) {
                return true;
            }
            const dateOfBirthByNationalNumber = this.getDateOfBirth(nationalNumber);
            return !dateOfBirthByNationalNumber || moment(dateOfBirth).isSame(dateOfBirthByNationalNumber, 'day');
        }

        public getDateOfBirth(nationalNumber: string): Date {
            nationalNumber = this.sanitize(nationalNumber);
            if (!this.isValidNationalNumber(nationalNumber)) {
                return null;
            }
            const century = this.isNumberFromBefore2000(nationalNumber) ? 1900 : 2000;
            const year = parseInt(nationalNumber.substring(0, 2));

            // If national number without entry in national register,
            // 20 or 40 is added to month
            let month = parseInt(nationalNumber.substring(2, 4));
            if (month - 40 >= 0) {
                month = month - 40;
            } else if (month - 20 >= 0) {
                month = month - 20;
            }
            const day = parseInt(nationalNumber.substring(4, 6));

            if (day === 0 || month === 0) {
                return null;
            }

            return new Date(century + year, month - 1, day);
        }

        public isValidGender(gender: GenderCode, nationalNumber: string): boolean {
            nationalNumber = this.sanitize(nationalNumber);
            if (!gender || !nationalNumber) {
                return true;
            }
            const genderByNationalNumber = this.getGender(nationalNumber);
            return (
                !genderByNationalNumber ||
                genderByNationalNumber === GenderCode.Unknown ||
                gender === genderByNationalNumber
            );
        }

        public getGender(nationalNumber: string): GenderCode {
            nationalNumber = this.sanitize(nationalNumber);
            if (!this.isValidNationalNumber(nationalNumber)) {
                return null;
            }

            // If national number without entry in national register,
            // 20 is added to month if gender is unknown, otherwise 40 is added
            const month = parseInt(nationalNumber.substring(2, 4));
            const genderUnknown = month - 20 >= 0 && month - 40 < 0;
            if (genderUnknown) {
                return GenderCode.Unknown;
            }
            const sequenceNumber = parseInt(nationalNumber.substring(6, 9));
            return sequenceNumber % 2 === 0 ? GenderCode.Female : GenderCode.Male;
        }

        public isValidNationality(nationality: number, nationalNumber: string) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationality || !nationalNumber) {
                return true;
            }
            const nationalityByNationalNumber = this.getNationality(nationalNumber);
            return !nationalityByNationalNumber || nationality === nationalityByNationalNumber;
        }

        public getNationality(nationalNumber: string): number {
            nationalNumber = this.sanitize(nationalNumber);
            return this.isBelgian(nationalNumber) ? Nationality.Belgian : null;
        }

        public isValidNationalityCode(nationality: string, nationalNumber: string) {
            nationalNumber = this.sanitize(nationalNumber);
            if (!nationality || !nationalNumber) {
                return true;
            }
            const nationalityByNationalNumber = this.getNationalityCode(nationalNumber) as string;
            return !nationalityByNationalNumber || nationality === nationalityByNationalNumber;
        }

        public getNationalityCode(nationalNumber: string): NationalityIso2 {
            nationalNumber = this.sanitize(nationalNumber);
            return this.isBelgian(nationalNumber) ? NationalityIso2.Belgian : null;
        }

        public validateAndSetProperties(
            nationalNumber: string,
            dateOfBirthGetterSetter: (dateOfBirth?: Date) => Date,
            genderGetterSetter: (gender?: GenderCode) => GenderCode,
            nationalityGetterSetter: (nationality?: number) => number,
            nationalityCodeGetterSetter: (nationality?: string) => string,
            overwrite: boolean
        ): void {
            nationalNumber = this.sanitize(nationalNumber);
            if (this.isValidNationalNumber(nationalNumber)) {
                if (dateOfBirthGetterSetter) {
                    const dateOfBirth = this.getDateOfBirth(nationalNumber);
                    if (dateOfBirth) {
                        if (!dateOfBirthGetterSetter()) {
                            overwrite && dateOfBirthGetterSetter(dateOfBirth);
                        } else if (!this.isValidDateOfBirth(dateOfBirthGetterSetter(), nationalNumber)) {
                            this.toaster.warning('General.Validation.DateOfBirthDoesNotMatchNationalNumber');
                        }
                    }
                }

                if (genderGetterSetter) {
                    const gender = this.getGender(nationalNumber);
                    if (!genderGetterSetter()) {
                        overwrite && genderGetterSetter(gender);
                    } else if (!this.isValidGender(genderGetterSetter(), nationalNumber)) {
                        this.toaster.warning('General.Validation.GenderDoesNotMatchNationalNumber');
                    }
                }

                if (nationalityGetterSetter) {
                    const nationality = this.getNationality(nationalNumber);
                    if (nationality) {
                        if (!nationalityGetterSetter()) {
                            overwrite && nationalityGetterSetter(nationality);
                        } else if (!this.isValidNationality(nationalityGetterSetter(), nationalNumber)) {
                            this.toaster.warning('General.Validation.NationalityDoesNotMatchNationalNumber');
                        }
                    }
                }

                if (nationalityCodeGetterSetter) {
                    const nationality = this.getNationalityCode(nationalNumber);
                    if (!nationalityCodeGetterSetter()) {
                        overwrite && nationalityCodeGetterSetter(nationality);
                    } else if (!this.isValidNationalityCode(nationalityCodeGetterSetter(), nationalNumber)) {
                        this.toaster.warning('General.Validation.NationalityDoesNotMatchNationalNumber');
                    }
                }
            }
        }

        private sanitize(nationalNumber: string): string {
            if (!nationalNumber) {
                return nationalNumber;
            }

            return nationalNumber.replace(/\D/g, '');
        }

        private isBelgian(nationalNumber): boolean {
            if (!this.isValidNationalNumber(nationalNumber)) {
                return false;
            }
            const month = parseInt(nationalNumber.substring(2, 4));

            // If national number without entry in national register,
            // 20 or 40 is added to month. In this case we can't derive nationality
            return month - 20 < 0;
        }

        private isNumberFromBefore2000(nationalNumber: string): boolean {
            const number = parseInt(nationalNumber.substring(0, 9));
            const modulo = 97 - (number % 97);

            return modulo === parseInt(nationalNumber.substring(9));
        }

        private isNumberFrom2000Onwards(nationalNumber: string): boolean {
            const number = parseInt(`2${nationalNumber.substring(0, 9)}`);
            const modulo = 97 - (number % 97);

            return modulo === parseInt(nationalNumber.substring(9));
        }
    }

    remeCareAppModule.service('nationalNumberSvc', NationalNumberService);
}
