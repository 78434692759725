var RemeCare;
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        var StorageServiceConstants = RemeCare.Shared.Framework.Service.StorageServiceConstants;
        var LastAgendaController = /** @class */ (function () {
            function LastAgendaController($state, storageSvc) {
                this.$state = $state;
                this.storageSvc = storageSvc;
            }
            LastAgendaController.prototype.$onInit = function () {
                var knownAgenda = this.storageSvc.get(StorageServiceConstants.agendaKey);
                if (!knownAgenda) {
                    //if we don't have a last value we want to be redirected to myAgenda
                    knownAgenda = StorageServiceConstants.myAgenda;
                }
                this.$state.go(knownAgenda);
            };
            return LastAgendaController;
        }());
        Agenda.remeCareAgendaModule.component('rcLastAgenda', {
            controller: LastAgendaController
        });
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
