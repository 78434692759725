module RemeCare.Management {
    import IEnumTranslation = Shared.Contract.IEnumTranslation;
    import ContactPointType = Shared.Contract.Code.ContactPointType;
    import ContactPointUsageType = Shared.Contract.Code.ContactPointUsageType;

    class CreatePatientController implements ng.IComponentController {
        public patient: Model.PatientPerson;
        public homeAddress: Model.Address;
        public homeTelephone: Model.TelephoneNumber;
        public defaultMobile: Model.TelephoneNumber;
        public defaultEmail: Model.Email;
        public countries: Array<IEnumTranslation>;

        constructor(
            private readonly $state: ng.ui.IStateService,
            private readonly $translate: ng.translate.ITranslateService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly patientSvc: Patient.PatientService
        ) {}

        public $onInit(): void {
            this.patient = new Model.PatientPerson();
            this.loadDataAsync();
            this.homeAddress = <Model.Address>Model.ContactPointFactory.createFromType(ContactPointType.Address);
            this.homeAddress.contactPointUsageType = ContactPointUsageType.Domicile;
            this.homeTelephone = <Model.TelephoneNumber>(
                Model.ContactPointFactory.createFromType(ContactPointType.Telephone)
            );
            this.homeTelephone.contactPointUsageType = ContactPointUsageType.Domicile;
            this.defaultMobile = <Model.TelephoneNumber>(
                Model.ContactPointFactory.createFromType(ContactPointType.MobilePhone)
            );
            this.defaultMobile.contactPointUsageType = ContactPointUsageType.Default;
            this.defaultEmail = <Model.Email>Model.ContactPointFactory.createFromType(ContactPointType.Email);
            this.defaultEmail.contactPointUsageType = ContactPointUsageType.Default;
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.countries = await this.masterdataSvc.getCountriesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public async savePatient(person: Model.PatientPerson): Promise<void> {
            this.patient.addresses = [this.homeAddress];
            this.patient.telephoneNumbers = [this.homeTelephone, this.defaultMobile];
            this.patient.emailAddresses = [this.defaultEmail];
            try {
                const id = await this.patientSvc.registerPatientAsync(person.toRegisterPatient());
                this.toaster.success(this.$translate.instant('Patient.PatientCreated'));
                this.$state.go('management.persons.detail', {
                    partyId: id,
                });
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public cancel(): void {
            this.$state.go('management.persons.search');
        }
    }

    remeCareManagementModule.component('createPatient', {
        controller: CreatePatientController,
        templateUrl: 'management/controllers/persons/createPatient/createPatient.html',
        bindings: {},
    });
}
