//module RemeCare.Contract.CareRequest.Read {
//    import Guid = RemeCare.Shared.Contract.Guid;
//    import TelephoneNumber = RemeCare.Contract.Core.ITelephoneNumber;
//    import Document = RemeCare.Shared.Contract.IDocument;
//    export interface ICardioCareRequest {
//        Id: Guid;
//        Editable: boolean;
//        FirstName: string;
//        LastName: string;
//        DateOfBirth: Date;
//        NationalNumber: string;
//        CommunicationLanguageId?: Guid;
//        MaritalStatusId?: Guid;
//        EducationId?: Guid;
//        GenderId?: Guid;
//        Address: string;
//        Zipcode: string;
//        City: string;
//        CountryId?: Guid;
//        Phone: TelephoneNumber;
//        CellPhone: TelephoneNumber;
//        Email: string;
//        Documents: Document[];
//        DateStartTitrage: Date;
//        GeneralPractitionarAgreeBlood: boolean;
//        IsInterventionGroup: boolean;
//        // TherapyDiseases
//        Randomisation: string;
//        UniqueStudyNumber: string;
//        DateFollowUp: Date;
//        DatePhoneContactGp: Date;
//        // People mapping
//        CareRequestorOrganisationId: Guid;
//        CareRequestorProfessionalId: Guid;
//        Anamneses: IAnamnesisCardio[];
//        CareTeam: RemeCare.Contract.CareRequest.Read.ICareTeamCardio[];
//        CareTeamInformal: RemeCare.Contract.CareRequest.Read.ICareTeamInformalCardio[];
//        Hospitalisations: RemeCare.Contract.HealthCareParty.Read.IHospitalisation[];
//        TechnologicalAffinities: any;
//        TherapyDiseases: any;
//    }
//    export interface ICareTeamCardio {
//        ActorRoleId: Guid;
//        ActorRoleTranslation: string;
//        PartyRoleId: Guid;
//        FirstName: string;
//        LastName: string;
//        Email: string;
//        Phone: TelephoneNumber;
//        Mobile: TelephoneNumber;
//        Address: string;
//        City: string;
//        ZipCode: string;
//        Riziv: string;
//        CountryId: Guid;
//    }
//    export interface ICareTeamInformalCardio {
//        PartyRoleId: Guid;
//        FirstName: string;
//        LastName: string;
//        Email: string;
//        Phone: TelephoneNumber;
//        Mobile: TelephoneNumber;
//        SupportType: string;
//        Relationship: string;
//        RelationshipCode: Core.Codes.ContactPersonTypeCode;
//        Address: string;
//        City: string;
//        ZipCode: string;
//        Riziv: string;
//        CountryId: Guid;
//    }
//    export interface IAnamnesisCardio {
//        AnamnesisDefinitionId: Guid;
//        AnamnesisValue: number;
//        AnamnesisGuid: Guid;
//    }
//}  
