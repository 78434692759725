var RemeCare;
(function (RemeCare) {
    var Contract;
    (function (Contract) {
        var Core;
        (function (Core) {
            var Codes;
            (function (Codes) {
                var TherapyActionPartGroupCode;
                (function (TherapyActionPartGroupCode) {
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["Appointment"] = 1] = "Appointment";
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["Execution"] = 2] = "Execution";
                    TherapyActionPartGroupCode[TherapyActionPartGroupCode["ExternalReport"] = 4] = "ExternalReport";
                })(TherapyActionPartGroupCode = Codes.TherapyActionPartGroupCode || (Codes.TherapyActionPartGroupCode = {}));
            })(Codes = Core.Codes || (Core.Codes = {}));
        })(Core = Contract.Core || (Contract.Core = {}));
    })(Contract = RemeCare.Contract || (RemeCare.Contract = {}));
})(RemeCare || (RemeCare = {}));
