module RemeCare.Inbox {
    interface IInboxAlarmDetailScope extends Shared.Framework.IBaseScope {
        alarm: Contract.Alarms.Read.IAlarm;
        alarmDefinition: Shared.Contract.Read.IAlarmDefinition;
        handleAlarm(): void;
    }

    class InboxAlarmDetailController extends Shared.Framework.ControllerBase<IInboxAlarmDetailScope> {
        constructor(
            protected $scope: IInboxAlarmDetailScope,
            protected $translate,
            protected toaster,
            private readonly $rootScope: ng.IRootScopeService,
            private readonly $stateParams,
            private readonly alarmsApiSvc: Core.Services.AlarmsApiService,
            private readonly patientSvc: Patient.PatientService
        ) {
            super($scope, $translate, toaster);
            $scope.handleAlarm = () => this.handleAlarmAsync();
        }

        public $onInit(): void {
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                [this.$scope.alarm, this.$scope.alarmDefinition] = await Promise.all([
                    this.alarmsApiSvc.getAlarmAsync(this.$stateParams.alarmId),
                    this.alarmsApiSvc.getAlarmDefinitionAsync(this.$stateParams.alarmId)
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async handleAlarmAsync(): Promise<void> {
            try {
                await this.patientSvc.saveAlarmHandlingAsync(this.$scope.alarm.Patient.Id, this.$scope.alarm.Id);
                this.$onInit();
                this.$rootScope.$emit('alarmHandled', this.$stateParams.alarmId);
            } catch (e) {
                this.$scope.alarm.Handled = false;
                this.toaster.error(e);
            }
        }
    }

    remeCareInboxModule.controller(
        'inboxAlarmDetailCtrl',
        ($scope, $translate, toaster, $rootScope: ng.IRootScopeService, $stateParams, alarmsApiSvc, patientSvc) =>
            new InboxAlarmDetailController(
                $scope,
                $translate,
                toaster,
                $rootScope,
                $stateParams,
                alarmsApiSvc,
                patientSvc
            )
    );
}
