/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QuantitativeMeasuringPointParameter = Shared.Framework.Model.QuantitativeMeasuringPointParameter;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import QuantitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint;
    import QuantitativeReferencePoint = Contract.CarePlan.Read.Query.IQuantitativeReferencePoint;
    import ChartLine = Shared.Framework.Model.ChartLine;
    import ChartBar = Shared.Framework.Model.ChartBar;

    class CumulativeBarGraphController extends ChartMonitoringPartController {
        private quantitativeMeasuringPoints: QuantitativeMeasuringPoint[];
        private quantitativeReferencePoints: QuantitativeReferencePoint[];
        private showMultiplePerDay: boolean;

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
            this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();

            this.chartConfigs = [
                {
                    options: {
                        chart: {
                            animation: false,
                            type: 'column',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'x',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(this.showMultiplePerDay),
                            shared: true,
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: [],
                    title: {
                        text: null,
                    },
                } as HighChartsNGConfig,
            ];
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            const oneMonthEarlier = moment(this.dateInfo.untilDate);
            oneMonthEarlier.subtract(1, 'months');
            this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
            this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(this.showMultiplePerDay);
            const pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(
                this.carePlanIds,
                this.quantitativeMeasuringPoints,
                this.dateInfo.fromDate,
                this.getUntilDate(),
                this.showMultiplePerDay
            );
            const referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(
                this.carePlanIds,
                this.quantitativeReferencePoints,
                this.dateInfo.fromDate,
                this.getUntilDate()
            );
            try {
                let graphPoints: Array<Contract.Core.IGraph<Contract.CarePlan.Read.IParameter<number>>>;
                let referencePoints: Array<Contract.Core.IGraph<number>>;
                [graphPoints, referencePoints] = await Promise.all([pointsPromise, referencePromise]);
                const graphs = _(graphPoints)
                    .chain()
                    .map(
                        g =>
                            new Model.NumberGraph(
                                {
                                    GraphPoints: _(g.GraphPoints).map(gp => {
                                        return {
                                            X: gp.X,
                                            Y: gp.Y.Value,
                                        };
                                    }),
                                    Scale: g.Scale,
                                    Subject: g.Subject,
                                },
                                !this.showMultiplePerDay
                            )
                    )
                    .sortBy(
                        g =>
                            _(this.monitoringPart.monitoringPartSources)
                                .chain()
                                .filter(
                                    mps => mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter
                                )
                                .map(mps => mps as QuantitativeMeasuringPointParameter)
                                .find<QuantitativeMeasuringPointParameter>(mps =>
                                    mps.hasCharacteristicAndUnit(g.subject, g.scale)
                                )
                                .value().sequence
                    )
                    .value();
                const references = _(referencePoints).map(g => new Model.NumberGraph(g, true, true));
                this.configureChart(graphs, references);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        private configureChart(graphs: Model.NumberGraph[], references: Model.NumberGraph[]): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
            const graphsUnit = _(graphs)
                .chain()
                .map(g => (g.scale != null ? g.scale.Id : null))
                .first()
                .value();
            const yAxes = this.getYAxis(_(graphs).union(references), this.getGraphsMaxY(graphs), graphsUnit);
            this.chartConfigs[0].options.yAxis = yAxes;
            this.chartConfigs[0].series = _(graphs)
                .chain()
                .map(g => this.getBarDataSeries(g, yAxes))
                .union(_(references).map(g => this.getLineDataSeries(g, yAxes)))
                .value();
            this.chartConfigs[0].loading = false;
        }

        private getGraphsMaxY(graphs: Model.NumberGraph[]): number {
            return _(graphs)
                .chain()
                .map(g => g.graphPoints)
                .flatten()
                .groupBy(gp => gp.x.getTime())
                .map(group =>
                    _(group)
                        .chain()
                        .map(gp => gp.y)
                        .reduce<number>((m, point) => m + point)
                        .value()
                )
                .max()
                .value();
        }

        private getYAxis(
            graphs: Model.NumberGraph[],
            maxBars: number,
            graphsUnitId: Shared.Contract.Guid
        ): HighchartsAxisOptions[] {
            const units = _(graphs)
                .chain()
                .map(g => g.scale)
                .uniq(false, s => (s != null ? s.Id : null))
                .value();
            const axes = _(units).map(u => {
                let max = _(graphs)
                    .chain()
                    .filter(
                        g => (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id)
                    )
                    .map(g => this.getMaxYScaleValue(g))
                    .max()
                    .value();
                if (
                    (u == null && graphsUnitId == null) ||
                    (u != null && graphsUnitId != null && u.Id === graphsUnitId)
                ) {
                    max = Math.max(max, maxBars);
                }
                return {
                    title: {
                        text: u != null ? u.Text : null,
                    },
                    max: max,
                    min: 0,
                    id: u != null ? (u.Id as string) : 'unscaled',
                } as HighchartsAxisOptions;
            });
            const groupedAxes = _(axes)
                .chain()
                .groupBy(a => `${a.min};${a.max}`)
                .map(ag => {
                    return {
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            text: _(ag)
                                .chain()
                                .map(a => a.title.text)
                                .filter(t => t != null)
                                .value()
                                .join(' - '),
                            y: -20,
                            x: -27,
                            textAlign: 'left',
                        },
                        max: _(ag).first().max,
                        min: _(ag).first().min,
                        id: _(ag)
                            .map(a => a.id)
                            .join(';'),
                        startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                        endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                        reversedStacks: false,
                    } as HighchartsAxisOptions;
                })
                .each((a, index) => {
                    a.opposite = index % 2 === 1;
                    if (a.opposite) {
                        (a.title as any).textAlign = 'right';
                        a.title.x = 27;
                        a.labels = {
                            align: 'left',
                            x: 0,
                            y: -2,
                        };
                    } else {
                        a.labels = {
                            align: 'right',
                            x: 0,
                            y: -2,
                        };
                    }
                })
                .value();

            if (!groupedAxes[0] || !groupedAxes[0].title.text) {
                this.chartConfigs[0].options.chart.marginLeft = 37;
            }
            if (!groupedAxes[1] || !groupedAxes[1].title.text) {
                this.chartConfigs[0].options.chart.marginRight = 37;
            }

            return groupedAxes;
        }

        private getBarDataSeries(
            graph: Model.NumberGraph,
            yAxes: HighchartsAxisOptions[]
        ): HighchartsColumnChartSeriesOptions {
            const monitoringPartSource = _.find(
                this.monitoringPart.monitoringPartSources,
                mps =>
                    mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                    mps.hasCharacteristicAndUnit(graph.subject, graph.scale)
            ) as MonitoringPartSourceParameter;
            const chartBar = monitoringPartSource.sourceParameterRepresentation as ChartBar;
            const id = graph.scale ? (graph.scale.Id as string) : 'unscaled';
            const yAxis = _.find(yAxes, a => a.id.indexOf(id) >= 0);
            return {
                animation: false,
                stacking: 'normal',
                data: _(graph.graphPoints)
                    .chain()
                    .map(gp => this.getDataPoint(gp))
                    .sortBy(gp => gp[0])
                    .value(),
                color: chartBar.colour,
                name: graph.subject.Text,
                yAxis: yAxis.id,
                maxPointWidth: 20,
                pointPlacement: 'on',
            } as HighchartsColumnChartSeriesOptions;
        }

        private getLineDataSeries(
            graph: Model.NumberGraph,
            yAxes: HighchartsAxisOptions[]
        ): HighchartsLineChartSeriesOptions {
            const monitoringPartSource = _.find(
                this.monitoringPart.monitoringPartSources,
                mps =>
                    (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                    mps.hasObjective(graph.subject) ||
                    mps.hasRuleThreshold(graph.subject)
            ) as MonitoringPartSourceParameter;

            const chartLine = monitoringPartSource.sourceParameterRepresentation as ChartLine;
            const id = graph.scale ? (graph.scale.Id as string) : 'unscaled';
            const yAxis = _.find(yAxes, a => a.id.indexOf(id) >= 0);
            return {
                animation: false,
                type: 'line',
                data: _(graph.graphPoints)
                    .chain()
                    .map(gp => this.getDataPoint(gp))
                    .sortBy(gp => gp[0])
                    .value(),
                color: chartLine.colour,
                name: graph.subject.Text,
                lineWidth: chartLine.lineType.Id === Shared.Contract.Code.LineType.Thin ? 1 : 2,
                dashStyle: chartLine.lineType.Id === Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                yAxis: yAxis.id,
                step: true,
                isReference: true,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 1,
                },
                tooltip: {
                    xDateFormat: this.getDateFormat(false),
                },
            } as HighchartsLineChartSeriesOptions;
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number>): number[] {
            return [moment(graphPoint.x).valueOf(), graphPoint.y];
        }
    }

    class CumulativeBarGraphComponent extends MonitoringPartComponentBase {
        public controller = CumulativeBarGraphController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringCumulativeBarGraph', new CumulativeBarGraphComponent());
}
