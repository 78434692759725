/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var RegistrationValueFactory = RemeCare.Model.RegistrationValueFactory;
            var QualitativeAnamnesisController = /** @class */ (function (_super) {
                __extends(QualitativeAnamnesisController, _super);
                function QualitativeAnamnesisController($scope, $translate, toaster, masterdataSvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.masterdataSvc = masterdataSvc;
                    _this.careRequestSvc = careRequestSvc;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                QualitativeAnamnesisController.prototype.$onInit = function () {
                    var _this = this;
                    this.initializeAnamneses();
                    this.loadDataAsync().then(function (r) {
                        // Find missing values, removed from the latest answer list
                        if (_this.$scope.careRequestPart.uiControlType.Id === RemeCare.Shared.Contract.Code.UIControlType.Checkbox ||
                            _this.$scope.careRequestPart.uiControlType.Id === RemeCare.Shared.Contract.Code.UIControlType.Dropdown ||
                            _this.$scope.careRequestPart.uiControlType.Id === RemeCare.Shared.Contract.Code.UIControlType.Radio ||
                            _this.$scope.careRequestPart.uiControlType.Id === RemeCare.Shared.Contract.Code.UIControlType.Slider) {
                            // single model
                            var registrationValue = _this.$scope.anamnesis
                                .registrationValue;
                            _this.$scope.hasRegistrationValue = registrationValue.codeSetItem != null;
                            if (registrationValue.codeSetItem && registrationValue.codeSetItem.Id) {
                                if (!_(_this.codeSetItemsInternal)
                                    .chain()
                                    .map(function (csi) { return csi.Id; })
                                    .contains(registrationValue.codeSetItem.Id)) {
                                    _this.$scope.missingValues = registrationValue.codeSetItem.Text;
                                    registrationValue.codeSetItem = null;
                                }
                            }
                        }
                        else {
                            if (_this.$scope.careRequestPart.uiControlType.Id ===
                                RemeCare.Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                                _this.$scope.careRequestPart.uiControlType.Id ===
                                    RemeCare.Shared.Contract.Code.UIControlType.DropdownListMultiple) {
                                // multi-model
                                var registrationValue_1 = _this.$scope.anamnesis
                                    .registrationValue;
                                _this.$scope.hasRegistrationValue = registrationValue_1.multiCodeSetItems.length > 0;
                                var missingValuesList_1 = [];
                                _(registrationValue_1.multiCodeSetItems).forEach(function (mcsi) {
                                    var csiList = _(_this.codeSetItemsInternal).map(function (csi) { return csi.Id; });
                                    if (!_(csiList).any(function (csi) { return csi === mcsi; })) {
                                        var text = _(registrationValue_1.multiCodeSetItemsTranslations).filter(function (trans) { return trans.Id === mcsi; })[0].Text;
                                        missingValuesList_1.push({
                                            Id: mcsi,
                                            Name: text,
                                        });
                                    }
                                });
                                _this.$scope.missingValues = missingValuesList_1.map(function (mvl) { return mvl.Name; }).join(', ');
                            }
                            else {
                                _this.toaster.error('Invalud UI Control Type');
                            }
                        }
                    });
                    this.checkForModifications();
                };
                QualitativeAnamnesisController.prototype.checkForModifications = function () {
                    if (!this.$scope.careRequestPart.isActive && this.$scope.hasRegistrationValue) {
                        this.$scope.careRequest.isModified = true;
                    }
                    if (this.$scope.careRequestPart.isActive && this.$scope.missingValues && this.$scope.missingValues.length > 0 && !this.$scope.readOnly) {
                        this.$scope.careRequest.isModified = true;
                    }
                };
                QualitativeAnamnesisController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                QualitativeAnamnesisController.prototype.initializeAnamneses = function () {
                    var _this = this;
                    this.$scope.anamnesis = _.find(this.$scope.careRequest.anamneses, function (t) { return t.careRequestPartLocalIdentity === _this.$scope.careRequestPart.localIdentity; });
                    this.$scope.hasRegistrationValue = false;
                    if (this.$scope.anamnesis == null) {
                        this.$scope.anamnesis = new CareRequest.Anamnesis();
                        this.$scope.anamnesis.careRequestPartLocalIdentity = this.$scope.careRequestPart.localIdentity;
                        this.initDefaultValues();
                        this.$scope.careRequest.anamneses.push(this.$scope.anamnesis);
                    }
                };
                QualitativeAnamnesisController.prototype.initDefaultValues = function () {
                    if (this.$scope.careRequestPart.uiControlType.Id ===
                        RemeCare.Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                        this.$scope.careRequestPart.uiControlType.Id ===
                            RemeCare.Shared.Contract.Code.UIControlType.DropdownListMultiple) {
                        this.$scope.anamnesis.registrationValue = RegistrationValueFactory.createRegistrationValueFromType(RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue);
                    }
                    else {
                        this.$scope.anamnesis.registrationValue = RegistrationValueFactory.createRegistrationValueFromType(RemeCare.Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue);
                    }
                };
                QualitativeAnamnesisController.prototype.loadDataAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, _b, e_1;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 5, , 6]);
                                    if (!((this.$scope.careRequest.careRequestStatus && this.$scope.careRequest.careRequestStatus.Id === 3) ||
                                        !this.$scope.careRequestPart.isActive)) return [3 /*break*/, 2];
                                    // if CareRequest is final or the part is inactive, load code set items at registration date time
                                    _a = this;
                                    return [4 /*yield*/, this.masterdataSvc.getCodeSetItemsAsync(this.$scope.careRequestPart.codeSet.Id, RemeCare.Shared.DateHelper.toServerDateString(this.$scope.anamnesis.registrationValue.registrationDateTime))];
                                case 1:
                                    // if CareRequest is final or the part is inactive, load code set items at registration date time
                                    _a.codeSetItemsInternal = _c.sent();
                                    return [3 /*break*/, 4];
                                case 2:
                                    // if CareRequest is NOT final, load code set items valid today
                                    _b = this;
                                    return [4 /*yield*/, this.masterdataSvc.getCodeSetItemsAsync(this.$scope.careRequestPart.codeSet.Id)];
                                case 3:
                                    // if CareRequest is NOT final, load code set items valid today
                                    _b.codeSetItemsInternal = _c.sent();
                                    _c.label = 4;
                                case 4:
                                    this.$scope.codeSetItems = angular.copy(this.codeSetItemsInternal);
                                    return [3 /*break*/, 6];
                                case 5:
                                    e_1 = _c.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    });
                };
                return QualitativeAnamnesisController;
            }(Directive.CareRequestPartControllerBase));
            var QualitativeAnamnesisDirective = /** @class */ (function (_super) {
                __extends(QualitativeAnamnesisDirective, _super);
                function QualitativeAnamnesisDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        'masterdataSvc',
                        'careRequestSvc',
                        function ($scope, $translate, toaster, masterdataSvc, careRequestSvc) {
                            return new QualitativeAnamnesisController($scope, $translate, toaster, masterdataSvc, careRequestSvc);
                        },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/qualitativeAnamnesis.html';
                    return _this;
                }
                return QualitativeAnamnesisDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestQualitativeAnamnesis', function () { return new QualitativeAnamnesisDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
