var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestUIControlType = RemeCare.Shared.Contract.Code.CareRequestUIControlType;
        var ContactPersonAcceptanceController = /** @class */ (function (_super) {
            __extends(ContactPersonAcceptanceController, _super);
            function ContactPersonAcceptanceController($scope, $translate, toaster, gridBuilderSvc, partyApiSvc, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster, gridBuilderSvc, partyApiSvc) || this;
                _this.masterdataSvc = masterdataSvc;
                $scope.atLeastOneNumber = function () { return _this.atLeastOneNumber(_this.$scope.contactPerson.person, CareRequestUIControlType.ContactPersonTelephoneMobile); };
                $scope.copyToSearch = function (src, dest) { return _this.copyToSearch(src, dest, _this.$scope.contactPerson.person); };
                $scope.updateField = function (p) { return _this.updateField(p, _this.$scope.contactPerson.person); };
                $scope.confirm = function () { return _this.confirm(); };
                $scope.personSelected = function (p) { return _this.personSelected(p); };
                $scope.isNewPersonRequired = function (v) { return _this.isNewPersonRequired(v); };
                return _this;
            }
            ContactPersonAcceptanceController.prototype.$onInit = function () {
                var _this = this;
                _super.prototype.$onInit.call(this);
                this.observeMatchedParty();
                this.masterdataSvc.getCountries()
                    .success(function (r) { return _this.$scope.countries = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getGenders()
                    .success(function (r) { return _this.$scope.genders = r; })
                    .error(function (e) { return _this.errorCallback(e); });
                this.masterdataSvc.getContactPersonTypes()
                    .success(function (r) { return _this.$scope.contactPersonTypes = r; })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            ContactPersonAcceptanceController.prototype.isNewPersonRequired = function (type) {
                if (type == null)
                    return (this.$scope.newPerson || this.$scope.newPerson == null);
                return this.$scope.isRequired(type) && (this.$scope.newPerson || this.$scope.newPerson == null);
            };
            ContactPersonAcceptanceController.prototype.observeMatchedParty = function () {
                var _this = this;
                this.$scope.newPerson = this.$scope.contactPerson.matchedPartyId ? false : null;
                this.matchedPartyId = this.$scope.contactPerson.matchedPartyId;
                if (this.$scope.contactPerson.matchedParty != null) {
                    this.$scope.selectedPerson = this.$scope.contactPerson.matchedParty;
                    this.$scope.selectedPerson.updatePerson(this.$scope.contactPerson.person);
                }
                this.$scope.$watch('newPerson', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue) {
                            _this.$scope.contactPerson.matchedPartyId = null;
                            _this.$scope.contactPerson.matchedParty = null;
                        }
                        else {
                            _this.$scope.contactPerson.matchedPartyId = _this.matchedPartyId;
                            _this.$scope.contactPerson.matchedParty = _this.$scope.selectedPerson;
                        }
                    }
                });
            };
            ContactPersonAcceptanceController.prototype.personSelected = function (person) {
                this.$scope.searchOpen = false;
                this.$scope.selectedPerson = CareRequest.RequestPerson.createFromPerson(person);
                this.$scope.contactPerson.person.update = new CareRequest.RequestPersonUpdate();
                this.$scope.contactPerson.person.contactInfo.update = new CareRequest.RequestContactInfoUpdate();
                this.matchedPartyId = person.PartyId;
                if (!this.$scope.newPerson) {
                    this.$scope.contactPerson.matchedPartyId = person.PartyId;
                    this.$scope.contactPerson.matchedParty = this.$scope.selectedPerson;
                }
            };
            ContactPersonAcceptanceController.prototype.confirm = function () {
                if (this.$scope.contactPersonForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.contactPerson);
            };
            return ContactPersonAcceptanceController;
        }(CareRequest.AcceptanceControllerBase));
        CareRequest.remeCareCareRequestModule.controller('contactPersonAcceptanceCtrl', ContactPersonAcceptanceController);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
