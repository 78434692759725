var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Model;
        (function (Model) {
            var Version = RemeCare.Shared.Framework.Model.Version;
            var RegistrationPhysicalQuantityValue = RemeCare.Model.RegistrationPhysicalQuantity;
            var RegistrationCodeSetValue = RemeCare.Model.RegistrationCodeSetValue;
            var RegistrationMultiCodeSetValue = RemeCare.Model.RegistrationMultiCodeSetValue;
            var RegistrationType = RemeCare.Shared.Contract.Code.AnamnesisRegistrationType;
            var CarePlanDetail = /** @class */ (function (_super) {
                __extends(CarePlanDetail, _super);
                function CarePlanDetail() {
                    return _super.call(this) || this;
                }
                return CarePlanDetail;
            }(Version));
            Model.CarePlanDetail = CarePlanDetail;
            var CarePlanString = /** @class */ (function (_super) {
                __extends(CarePlanString, _super);
                function CarePlanString(serverObject) {
                    var _this = _super.call(this) || this;
                    if (serverObject != null) {
                        _this.definition = serverObject.StringDefinition;
                        _this.stringValue = serverObject.StringValue;
                        _this.id = serverObject.Id;
                        _this.carePlanId = serverObject.CarePlanId;
                        _this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                        _this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    }
                    return _this;
                }
                CarePlanString.prototype.toServerWrite = function () {
                    return {
                        Id: this.id,
                        CarePlanId: this.carePlanId,
                        CareRequestPartLocalIdentity: this.definition.Id,
                        ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                        ValidUntilDate: this.validUntilDate !== null ? RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                        StringValue: this.stringValue
                    };
                };
                return CarePlanString;
            }(CarePlanDetail));
            Model.CarePlanString = CarePlanString;
            var CarePlanAnamnesis = /** @class */ (function (_super) {
                __extends(CarePlanAnamnesis, _super);
                function CarePlanAnamnesis(serverObject) {
                    var _this = _super.call(this) || this;
                    if (serverObject != null) {
                        _this.definition = serverObject.AnamnesisDefinition;
                        if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationPhysicalQuantity) {
                            _this.registrationValue = new RegistrationPhysicalQuantityValue(serverObject.RegistrationValue);
                        }
                        else if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationCodeSetValue) {
                            _this.registrationValue = new RegistrationCodeSetValue(serverObject.RegistrationValue);
                        }
                        else if (serverObject.RegistrationValue.Type === RegistrationType.RegistrationMultiCodeSetValue) {
                            _this.registrationValue = new RegistrationMultiCodeSetValue(serverObject.RegistrationValue);
                        }
                        _this.id = serverObject.Id;
                        _this.carePlanId = serverObject.CarePlanId;
                        _this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                        _this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    }
                    return _this;
                }
                CarePlanAnamnesis.prototype.getRegistrationValueString = function () {
                    if (this.registrationValue.type === RegistrationType.RegistrationPhysicalQuantity) {
                        return this.registrationValue.amount.toString();
                    }
                    else if (this.registrationValue.type === RegistrationType.RegistrationCodeSetValue) {
                        return this.registrationValue.codeSetItem.Text;
                    }
                    else if (this.registrationValue.type === RegistrationType.RegistrationMultiCodeSetValue) {
                        var translations = _(this.registrationValue.multiCodeSetItemsTranslations).map(function (x) { return x.Text; });
                        return translations.toString();
                    }
                    return null;
                };
                CarePlanAnamnesis.prototype.toServerWrite = function () {
                    return {
                        Id: this.id,
                        CarePlanId: this.carePlanId,
                        CareRequestPartLocalIdentity: this.definition.Id,
                        ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                        ValidUntilDate: this.validUntilDate !== null ? RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                        RegistrationValue: this.registrationValue.toServerWrite()
                    };
                };
                return CarePlanAnamnesis;
            }(CarePlanDetail));
            Model.CarePlanAnamnesis = CarePlanAnamnesis;
            var CarePlanDate = /** @class */ (function (_super) {
                __extends(CarePlanDate, _super);
                function CarePlanDate(serverObject) {
                    var _this = _super.call(this) || this;
                    if (serverObject != null) {
                        _this.definition = serverObject.DateDefinition;
                        _this.dateValue = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.DateValue);
                        _this.id = serverObject.Id;
                        _this.carePlanId = serverObject.CarePlanId;
                        _this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                        _this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    }
                    return _this;
                }
                CarePlanDate.prototype.getFormattedDateValue = function () {
                    return moment(this.dateValue).format("DD/MM/YYYY");
                };
                CarePlanDate.prototype.toServerWrite = function () {
                    return {
                        Id: this.id,
                        CarePlanId: this.carePlanId,
                        CareRequestPartLocalIdentity: this.definition.Id,
                        ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                        ValidUntilDate: this.validUntilDate !== null
                            ? RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate)
                            : null,
                        DateValue: RemeCare.Shared.DateHelper.toServerDateString(this.dateValue)
                    };
                };
                return CarePlanDate;
            }(CarePlanDetail));
            Model.CarePlanDate = CarePlanDate;
            var CarePlanTherapyOption = /** @class */ (function (_super) {
                __extends(CarePlanTherapyOption, _super);
                function CarePlanTherapyOption(serverObject) {
                    var _this = _super.call(this) || this;
                    if (serverObject != null) {
                        _this.definition = serverObject.OptionDefinition;
                        _this.option = serverObject.Option;
                        _this.id = serverObject.Id;
                        _this.carePlanId = serverObject.CarePlanId;
                        _this.validFromDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                        _this.validUntilDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                    }
                    return _this;
                }
                CarePlanTherapyOption.prototype.toServerWrite = function () {
                    return {
                        Id: this.id,
                        CarePlanId: this.carePlanId,
                        CareRequestPartLocalIdentity: this.definition.Id,
                        ValidFromDate: RemeCare.Shared.DateHelper.toServerDateString(this.validFromDate),
                        ValidUntilDate: this.validUntilDate !== null ? RemeCare.Shared.DateHelper.toServerDateString(this.validUntilDate) : null,
                        Option: this.option
                    };
                };
                return CarePlanTherapyOption;
            }(CarePlanDetail));
            Model.CarePlanTherapyOption = CarePlanTherapyOption;
        })(Model = Patient.Model || (Patient.Model = {}));
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
