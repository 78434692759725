var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var CarePlanActionDirective = /** @class */ (function () {
            function CarePlanActionDirective($templateCache, $compile, uuid4, guidanceApiSvc) {
                var _this = this;
                this.$templateCache = $templateCache;
                this.$compile = $compile;
                this.uuid4 = uuid4;
                this.guidanceApiSvc = guidanceApiSvc;
                this.restrict = 'E';
                this.scope = {
                    carePlanAction: '=',
                    showErrors: '=',
                    readOnly: '=',
                    fullSize: '=',
                };
                this.link = function (scope, element) {
                    _this.configure(scope);
                    _this.render(scope, element);
                    scope.$watch('carePlanAction', function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            _this.render(scope, element);
                        }
                    });
                };
            }
            CarePlanActionDirective.prototype.configure = function (scope) {
                scope.showEventDateTime = !_(scope.carePlanAction.components).any(function (c) {
                    return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.CareAct ||
                        c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.Administration;
                });
            };
            CarePlanActionDirective.prototype.render = function (scope, element) {
                var currentWidth = 0;
                var html = scope.showEventDateTime
                    ? this.$templateCache.get('views/carePlanAction/directive/eventDateTime.html')
                    : '';
                if (scope.carePlanAction != null) {
                    scope.carePlanAction.components = _.sortBy(scope.carePlanAction.components, function (component) {
                        return component.sequence;
                    });
                    for (var _i = 0, _a = scope.carePlanAction.components; _i < _a.length; _i++) {
                        var currentComponent = _a[_i];
                        var result = this.getDirectiveHtml(currentComponent, currentWidth);
                        html += result.html;
                        currentWidth = result.currentWidth;
                    }
                }
                element.html(html).show();
                this.$compile(element.contents())(scope);
            };
            CarePlanActionDirective.prototype.getDirectiveHtml = function (component, currentWidth) {
                var directiveName;
                if (component.hidden) {
                    return new CarePlanActionPartsHtmlResult(' ', currentWidth);
                }
                switch (component.actionTypeCode) {
                    case RemeCare.Shared.Contract.Code.ActionType.QuantitativeObservation:
                        directiveName = 'quantitative-observation';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.QualitativeObservation:
                        directiveName = 'qualitative-observation';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.Administration:
                        if (component.medicationDoses.length > 1) {
                            directiveName = 'administration-adhoc';
                        }
                        else {
                            directiveName = 'administration';
                        }
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.CareAct:
                        directiveName = 'care-act';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.FixDetails:
                        directiveName = 'set-details';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.ProvideInformationObject:
                        directiveName = 'provide-information-object';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.RequestInformationObject:
                        directiveName = 'request-information-object';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.Remark:
                        directiveName = 'remark';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.PreviousRegistration:
                        directiveName = 'previous-registration';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning:
                        directiveName = 'phone-call';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.Date:
                        directiveName = 'date';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.MissingRegistrations:
                        directiveName = 'missing-registrations';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.MedicationSchema:
                        directiveName = 'medication-schema';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.AberrantRegistrations:
                        directiveName = 'aberrant-registrations';
                        break;
                    case RemeCare.Shared.Contract.Code.ActionType.Signature:
                        directiveName = 'signature';
                        break;
                    default:
                        return new CarePlanActionPartsHtmlResult('<p>Part not implemented</p>', currentWidth);
                }
                var widthClass;
                var widthValue;
                switch (component.width) {
                    case RemeCare.Shared.Contract.Code.PartWidth.Full:
                        widthClass = 'col-md-12';
                        widthValue = 12;
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.Half:
                        widthClass = 'col-md-6';
                        widthValue = 6;
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.Third:
                        widthClass = 'col-md-4';
                        widthValue = 4;
                        break;
                    case RemeCare.Shared.Contract.Code.PartWidth.TwoThird:
                        widthClass = 'col-md-8';
                        widthValue = 8;
                        break;
                    default:
                        widthClass = 'col-md-12';
                        widthValue = 12;
                        break;
                }
                currentWidth += widthValue;
                var uniqueId = this.uuid4.generate();
                if (component.guidanceEnabled) {
                    this.guidanceApiSvc.createGuidanceItem(uniqueId, component.guidanceCode);
                }
                var directiveHtml = [
                    "<div class=\"row\">",
                    "<div class=\"col-xs-12 " + widthClass + "\" >",
                    "<rc-" + directiveName + " therapy-action-part-id=\"" + component.id + "\" care-plan-action=\"carePlanAction\" unique-id=\"" + uniqueId + "\" show-errors=\"showErrors\" read-only=\"readOnly\" full-size=\"fullSize\"></rc-" + directiveName + ">",
                    '</div>',
                    '</div>',
                    // `<div class="row" id="${uniqueId}" style="display: none;">`,
                    "<div class=\"col-xs-12 " + widthClass + "\" >",
                    "<rc-guidance-part unique-id=\"" + uniqueId + "\"></rc-guidance-part>",
                    '</div>',
                ].join('');
                if (currentWidth > 12) {
                    directiveHtml = "<div class=\"clearfix\"></div>" + directiveHtml;
                    currentWidth = widthValue;
                }
                var result = new CarePlanActionPartsHtmlResult(directiveHtml, currentWidth);
                return result;
            };
            return CarePlanActionDirective;
        }());
        var CarePlanActionPartsHtmlResult = /** @class */ (function () {
            function CarePlanActionPartsHtmlResult(html, currentWidth) {
                this.html = html;
                this.currentWidth = currentWidth;
            }
            return CarePlanActionPartsHtmlResult;
        }());
        angular
            .module('RemeCare.CarePlanAction')
            .directive('rcCarePlanAction', function ($templateCache, $compile, uuid4, guidanceApiSvc) {
            return new CarePlanActionDirective($templateCache, $compile, uuid4, guidanceApiSvc);
        });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
