namespace RemeCare.CareRequest {
    import CareRequestPart = Shared.Framework.Model.CareRequestPart;

    class ConsultationModalController implements ng.IComponentController {
        public careRequestPart: CareRequestPart;
        public careProviderTypes: Shared.Contract.IEnumTranslation[];
        public consultation: Model.Consultation;
        public now: Date;
        public form: Shared.Framework.Directive.IFormController;
        public $close: (r: { result: Model.Consultation }) => void;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {}

        public $onInit(): void {
            this.now = new Date();
            this.loadDataAsync();
        }

        public confirm(): void {
            if (this.form.$invalid) {
                this.form.showValidationErrorMessage();
                return;
            }
            this.$close({ result: this.consultation });
        }

        public isPresent(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            const control = _.find(this.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id !== Shared.Contract.Code.ModalityType.Not;
            }
        }

        public isRequired(controlType: Shared.Contract.Code.CareRequestUIControlType): boolean {
            const control = _.find(this.careRequestPart.uiControls, u => u.uiControlTypeId === controlType);
            if (control == null) {
                return false;
            } else {
                return control.modalityType.Id === Shared.Contract.Code.ModalityType.Mandatory;
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.careProviderTypes = await this.masterdataSvc.getConsultationCareProviderTypesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareCareRequestModule.component('rcConsultationModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            consultation: '=',
            careRequestPart: '=',
        },
        controller: ConsultationModalController,
        templateUrl: 'careRequest/components/consultationModal/consultationModal.html',
    });
}
