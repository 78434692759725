module RemeCare.Patient {

    interface IPersonalisedContextSettingScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        carePlanId: Shared.Contract.Guid;
        carePlanStartDate: Date;
        structuralTherapyAction: Contract.Therapy.Read.IStructuralTherapyActionSummary;

        personalisedAction: StructuralTherapyActionPersonalisation;
        previousSelectedContextSetting: PersonalisedTherapyActionContextSetting;
        selectedContextSetting: PersonalisedTherapyActionContextSetting;
        personalisedContextSettingsGrid: Shared.Framework.Grid.Grid<PersonalisedTherapyActionContextSetting>;
        form: ng.IFormController;
        editMode: boolean;
        minDate: Date;

        modifyContextSetting(contextSetting?: PersonalisedTherapyActionContextSetting): void;
        canEdit(contextSetting: PersonalisedTherapyActionContextSetting): boolean;
        cancel(): void;
        confirm(): void;
        actions: {
            reload(): void;
        }
        editRights: {
            readOnly: boolean;
        }
    }

    class PersonalisedContextSettingController extends Shared.Framework.ControllerBase<IPersonalisedContextSettingScope> {

        constructor(
            $scope: IPersonalisedContextSettingScope,
            $translate,
            toaster,
            private $dialog,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private carePlanApiSvc: Core.Services.CarePlanApiService) {
            super($scope, $translate, toaster);
            $scope.modifyContextSetting = c => this.modifyContextSetting(c);
            $scope.canEdit = cs => this.canEdit(cs);
            $scope.cancel = () => this.cancel();
            $scope.confirm = () => this.confirm();
        }

        public $onInit(): void {
            this.buildGrid();
            this.$scope.$watch((s: IPersonalisedContextSettingScope) => s.personalisedAction, (newValue, oldValue) => {
                if (newValue !== oldValue && newValue != null) {
                    this.setData();
                }
            });
            if (this.$scope.personalisedAction) {
                this.setData();
            }
        }

        private setData(): void {
            var firstValue = _(this.$scope.personalisedAction.contextSettingVersions).chain()
                .sortBy(t => t.validFromDate)
                .first()
                .value();

            if (firstValue && moment(firstValue.validFromDate).isBefore(this.$scope.carePlanStartDate)) {
                firstValue.validFromDate = this.$scope.carePlanStartDate;
            }
            this.$scope.personalisedContextSettingsGrid.setData(this.$scope.personalisedAction.contextSettingVersions);
            this.$scope.selectedContextSetting = _(this.$scope.personalisedAction.contextSettingVersions)
                .chain()
                .filter(cta => cta.validFromDate <= Shared.DateHelper.today())
                .sortBy(cta => cta.validFromDate)
                .last()
                .value();
        }

        private calcMinDate(): void {
            var today = moment(Shared.DateHelper.today());
            var fromDate: moment.Moment;
            if (_(this.$scope.selectedContextSetting.contextSettings).any(cs => cs.id != null)) {
                var index = this.$scope.personalisedAction.contextSettingVersions.length - 2;
                fromDate = index >= 0
                    ? moment(this.$scope.personalisedAction.contextSettingVersions[index].validFromDate)
                    : today;
            } else {
                var lastVersion = _(this.$scope.personalisedAction.contextSettingVersions).last();
                fromDate = lastVersion ? moment(lastVersion.validFromDate) : today;
            }
            this.$scope.minDate = fromDate.isAfter(today)
                ? fromDate.add(1, 'days').toDate()
                : today.add(1, 'days').toDate();
        }

        private buildGrid(): void {
            this.$scope.personalisedContextSettingsGrid = this.gridBuilderSvc.createGridBuilder<PersonalisedTherapyActionContextSetting>()
                .addColumn('validFromDate', 'General.Versions.ValidFrom', { cellFilter: 'date: "shortDate"' })
                .addColumn('validUntilDate', 'General.Versions.ValidUntil', { cellFilter: 'date: "shortDate"' })
                .addActionColumn('eye-open', ptacs => {
                    this.$scope.selectedContextSetting = ptacs;
                    this.$scope.editMode = false;
                })
                .addConditionalDeleteButtonColumn(cs => !this.canEdit(cs) || this.$scope.editRights.readOnly, cs => this.delete(cs), cs => this.confirmDelete(cs))
                .setSorting(false)
                .build();
            this.$scope.personalisedContextSettingsGrid.setData([]);
        }

        private canEdit(contextSetting: PersonalisedTherapyActionContextSetting): boolean {
            if (!contextSetting) return false;
            var lastContextSetting = _(this.$scope.personalisedAction.contextSettingVersions).last();
            return lastContextSetting != null
                && contextSetting.validFromDate === lastContextSetting.validFromDate
                && contextSetting.canBeEdited();
        }

        private delete(contextSetting: PersonalisedTherapyActionContextSetting): void {
            this.$scope.actions.reload();
        }

        private confirmDelete(contextSetting: PersonalisedTherapyActionContextSetting): JQueryPromise<boolean> {
            var deferred = jQuery.Deferred<boolean>();
            this.$dialog.confirmBox('General.Versions.DeleteVersionConfirm', 'General.Versions.DeleteVersionConfirmMessage',
                () => {
                    this.carePlanApiSvc
                        .deletePersonalisedTherapyActionContextSetting(this.$scope.carePlanId,
                            this.$scope.structuralTherapyAction.Id, contextSetting.validFromDate)
                        .success(() => deferred.resolve(true))
                        .error(e => {
                            this.errorCallback(e);
                            deferred.resolve(false);
                        });
                }, () => {
                    deferred.resolve(false);
                }
            );
            return deferred.promise();
        }

        private modifyContextSetting(contextSetting?: PersonalisedTherapyActionContextSetting): void {
            this.$scope.previousSelectedContextSetting = this.$scope.selectedContextSetting;
            if (contextSetting) {
                contextSetting = this.$scope.copy(contextSetting);
            } else {
                contextSetting = new PersonalisedTherapyActionContextSetting();
                var contextSettings = _(this.$scope.personalisedAction.personalisableContextSettings)
                    .map(pcs => {
                        var ptapcs = new PersonalisedTherapyActionPartContextSetting();
                        ptapcs.therapyActionPartId = pcs.therapyActionPartId;
                        ptapcs.canPersonaliseTelemonitoringDeactivation = pcs.canPersonaliseTelemonitoringDeactivation;
                        ptapcs.canPersonaliseToHidePart = pcs.canPersonaliseToHidePart;
                        ptapcs.name = pcs.name;
                        ptapcs.sequence = pcs.sequence;
                        return ptapcs;
                    });
                contextSetting.setContextSettings(contextSettings);
            }
            this.$scope.selectedContextSetting = contextSetting;
            this.$scope.editMode = true;
            this.calcMinDate();
        }

        private cancel(): void {
            this.$scope.editMode = false;
            this.$scope.selectedContextSetting = this.$scope.previousSelectedContextSetting;
        }

        private confirm(): void {
            this.$scope.form.$setSubmitted();
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            var personalisedTherapyActionContextSetting = this.$scope.selectedContextSetting.toServerWrite();
            this.carePlanApiSvc.setPersonalisedTherapyActionContextSetting(this.$scope.carePlanId, this.$scope.structuralTherapyAction.Id,
                    personalisedTherapyActionContextSetting)
                .success(() => {
                    this.$scope.editMode = false;
                    this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                    this.$scope.actions.reload();
                })
                .error(e => this.errorCallback(e));
        }
    }

    remeCarePatientModule.controller('personalisedContextSettingController', (
        $scope,
        $translate,
        toaster,
        $dialog,
        gridBuilderSvc,
        carePlanApiSvc
    ) => new PersonalisedContextSettingController(
        $scope,
        $translate,
        toaster,
        $dialog,
        gridBuilderSvc,
        carePlanApiSvc
    ));
}