namespace RemeCare.Patient {
    import InformalParty = Contract.HealthCareParty.Read.IInformalParty;
    import SearchResult = Shared.Contract.ISearchResult;
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;

    interface ITherapyActorRole extends Shared.Contract.Read.IActorRole {
        Therapy: Shared.Contract.IEntityTranslation;
        Text: string;
    }

    interface IPatientCareTeamParams {
        patientId: Shared.Contract.Guid;
    }

    // @ngInject
    class PatientCareTeamController implements ng.IComponentController {
        public patientId: Shared.Contract.Guid;

        public informalsGrid: Shared.Framework.Grid.Grid<InformalParty>;

        public professionalActorRoles: ITherapyActorRole[];
        public organisationActorRoles: ITherapyActorRole[];

        public careTeamsCanEdit: Contract.Core.Read.ITherapyBusinessContextRightConfiguration[];

        private editRight: boolean;
        private onlyActiveTeamMembers: boolean = true;

        constructor(
            private toaster: Shared.Framework.Toaster,
            private $stateParams: IPatientCareTeamParams,
            private patientSvc: PatientService,
            private authservice: Shared.Framework.AuthService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public async $onInit(): Promise<void> {
            this.patientId = this.$stateParams.patientId;

            this.careTeamsCanEdit = await this.getCarePlansCanEdit();

            this.editRight = this.careTeamsCanEdit.length > 0;

            this.buildInformalsGrid();

            if (this.editRight) {
                this.patientSvc
                    .getPossibleActorRoles(this.patientId, true)
                    .success((r) => {
                        const actorRoles = _(r)
                            .chain()
                            .map((tar) =>
                                _(tar.ActorRoles).map((ar: ITherapyActorRole) => {
                                    ar.Therapy = tar.Therapy;
                                    ar.Text = `${ar.Name} (${ar.Therapy.Text})`;
                                    return ar;
                                })
                            )
                            .flatten()
                            .filter((ar) => _(this.careTeamsCanEdit).any((tbc) => tbc.Therapy === ar.Therapy.Id))
                            .value();
                        this.professionalActorRoles = _(actorRoles).filter(
                            (ar) => ar.PartyType === Shared.Contract.Code.PartyType.Person
                        );
                        this.organisationActorRoles = _(actorRoles).filter(
                            (ar) => ar.PartyType === Shared.Contract.Code.PartyType.Organisation
                        );
                    })
                    .error((e) => this.toaster.error(e));
            }
        }

        private async getCarePlansCanEdit(): Promise<Contract.Core.Read.ITherapyBusinessContextRightConfiguration[]> {
            try {
                const businessContextRights = await this.patientSvc.getTherapyBusinessContextRightsAsync(
                    this.patientId
                );
                return _(businessContextRights).filter(
                    (t) =>
                        t.TherapyBusinessContext ===
                            Shared.Contract.Code.TherapyBusinessContextCode.CarePlanCareTeamChange &&
                        (t.ExecutionRight as number) === 15
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildInformalsGrid(): void {
            this.informalsGrid = this.gridBuilderSvc
                .createGridBuilder<InformalParty>((p, ps, sf, sd, c) => this.searchInformals(p, ps, sf, sd, c))
                .addColumn('LastName', 'General.LastName', { visibleWhenSmall: true })
                .addColumn('FirstName', 'General.FirstName', { visibleWhenSmall: true })
                .addColumn('Relationship.Text', 'Views.PatientFile.CareTeam.RelationshipToPatient', {
                    visibleWhenSmall: true,
                })
                .addColumn('PhoneNumber', 'General.Phone', {
                    cellFilter: 'phone',
                    enableSorting: false,
                    visibleWhenSmall: false,
                })
                .addColumn('MobileNumber', 'General.Mobile', {
                    cellFilter: 'phone',
                    enableSorting: false,
                    visibleWhenSmall: false,
                })
                .addDateColumn('ValidFromDate', 'General.ValidFrom', 'shortDate', { visibleWhenSmall: false })
                .addDateColumn('ValidUntilDate', 'General.ValidUntilIncluding', 'shortDate', {
                    visibleWhenSmall: false,
                })
                .build();
            this.informalsGrid.pagingOptions.pageSize = 5;
            this.informalsGrid.search();
        }

        private searchInformals(
            page: number,
            pageSize: number,
            sortField: string,
            sortOrder: string,
            criteria: any
        ): JQueryPromise<SearchResult<InformalParty>> {
            const deferred = jQuery.Deferred<SearchResult<InformalParty>>();
            this.patientSvc
                .getInformals(this.patientId, {
                    page: page,
                    pageSize: pageSize,
                    sortField: sortField,
                    sortOrder: sortOrder,
                })
                .success((r) => deferred.resolve(r))
                .error((e) => {
                    this.toaster.error(e);
                    deferred.reject();
                });
            return deferred.promise();
        }
    }

    remeCarePatientModule.component('rcCareTeam', {
        controller: PatientCareTeamController,
        templateUrl: 'views/patient/careTeam/careTeam.html',
    });
}
