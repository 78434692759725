module RemeCare.Management {
    import Guid = Shared.Contract.Guid;

    class UserDetailsController implements ng.IComponentController {
        // bindings
        public partyId: Guid;

        public linkedProviders: Contract.Security.ILinkedExternalIdentityProvider[];
        public possibleProviders: Contract.Security.IExternalIdentityProvider[];
        public person: Model.Person;
        public user: Shared.Contract.Read.IUserDetails;
        public userLoaded: boolean;
        public providersLoading: boolean;
        public isEditingUserName: boolean;
        public newUserName: string;
        public userNameProposal: string;
        public userNameForm: ng.IFormController;
        public changeUsernameSendMailToUser: boolean;

        constructor(
            private readonly $dialog: Shared.Service.DialogService,
            private readonly $state: ng.ui.IStateService,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $translate: ng.translate.ITranslateService,
        ) { }

        public $onInit(): void {
            this.userLoaded = false;
            this.isEditingUserName = false;
            this.newUserName = "";
            this.loadDataAsync();
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.user = await this.securityApiSvc.getUserDetailsAsync(this.partyId);
                this.userLoaded = true;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public async $onChanges(onChangesObj: angular.IOnChangesObject): Promise<void> {
            if (onChangesObj['person'] && onChangesObj['person'].currentValue != null) {
                this.providersLoading = true;
                try {
                    await this.getExternalIdentityProvidersAsync();
                } finally {
                    this.providersLoading = false;
                }
            }
        }

        public async unlinkProvider(provider: Contract.Security.ILinkedExternalIdentityProvider): Promise<void> {
            try {
                await this.$dialog.confirm(
                    'Views.Management.Persons.UnlinkProvider',
                    'Views.Management.Persons.UnlinkProviderConfirm',
                    {
                        isWarning: true,
                        messageValues: {
                            providerName: provider.ExternalIdentityProvider.Name,
                        },
                    }
                );
                this.providersLoading = true;
                await this.partyApiSvc.unlinkPersonFromExternalIdentityProviderAsync(
                    this.partyId,
                    provider.ExternalIdentityProvider.Id
                );
                await this.getExternalIdentityProvidersAsync();
            } catch (e) {
                this.toaster.error(e);
            }
            this.providersLoading = false;
        }

        public addUser(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('addUserCtrl')
                .setTemplateUrl('views/management/persons/userDetails/addUser.html')
                .setResultCallBack(r => this.$state.reload())
                .setResolve({
                    partyId: () => this.partyId,
                    firstName: () => this.person.firstName,
                    lastName: () => this.person.lastName,
                })
                .build();
        }

        public manageApplicationProfiles(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('applicationProfileAssignmentsCtrl')
                .setTemplateUrl('views/management/persons/userDetails/applicationProfileAssignments.html')
                .setResultCallBack(() => this.$onInit())
                .setResolve({
                    person: () => this.person,
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        public sendResetMail(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('sendResetMailCtrl')
                .setTemplateUrl('views/management/persons/userDetails/sendResetMail.html')
                .setResolve({
                    partyId: () => this.partyId,
                })
                .build();
        }

        public toggleUserState(): void {
            const userDetails = angular.copy(this.user);
            userDetails.StatusChangeReason = null;
            this.modalBuilderFactory
                .createModalBuilder<Shared.Contract.Read.IUserDetails>()
                .setController('toggleUserStateCtrl')
                .setTemplateUrl('views/management/persons/userDetails/toggleUserState.html')
                .setScope({
                    userDetails: userDetails,
                })
                .setResolve({
                    partyId: () => this.partyId,
                })
                .setResultCallBack(userDetails => (this.user = userDetails))
                .build();
        }

        public showStatusHistory(): void {
            this.modalBuilderFactory
                .createModalBuilder()
                .setController('userAccountHistoryCtrl')
                .setTemplateUrl('views/management/persons/userDetails/userAccountHistory.html')
                .setScope({
                    name: `${this.person.firstName} ${this.person.lastName}`,
                })
                .setResolve({
                    partyId: () => this.partyId,
                })
                .build();
        }

        public changeUserName(): void {
            this.isEditingUserName = true;
        }

        public saveUserName(): void {
            if (!this.userNameForm.$valid) {
                this.userNameForm.username.$setDirty();
                return;
            }

            this.securityApiSvc.checkUserNameExists(this.newUserName)
                .success(r => {
                    if (r !== this.newUserName) {
                        this.userNameProposal = r;
                    } else {
                        this.userNameProposal = undefined;
                        this.securityApiSvc.changeUsername(this.partyId, this.newUserName, this.changeUsernameSendMailToUser)
                            .success(() => {
                                this.isEditingUserName = false;
                                this.user.UserName = this.newUserName;
                                this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                            })
                            .error(e =>
                                this.toaster.error(e)
                            );
                    }
                })
                .error(e =>
                    this.toaster.error(e)
                );
        }

        private async getExternalIdentityProvidersAsync(): Promise<void> {
            const externalProviderPromises = this.person
                .getCurrentRelations()
                .map(cr => cr.HealthCareOrganisation.PartyId)
                .map(orgId => this.partyApiSvc.getLinkedExternalIdentityProvidersAsync(orgId));

            try {
                const retrievedProviders = await Promise.all(externalProviderPromises);
                this.linkedProviders = await this.partyApiSvc.getExternalIdentityProvidersForPersonAsync(this.partyId);
                this.possibleProviders = _.chain(retrievedProviders)
                    .flatten()
                    .filter(
                        provider => !_.any(this.linkedProviders, lp => lp.ExternalIdentityProvider.Id === provider.Id)
                    )
                    .value();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCareManagementModule.component('rcUserDetails', {
        bindings: {
            partyId: '<',
            person: '<',
        },
        controller: UserDetailsController,
        templateUrl: 'views/management/persons/userDetails/userDetails.html',
    });
}
