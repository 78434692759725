module RemeCare.Patient {

    interface IPatientFileRegistrationsMonitorScope extends Shared.Framework.IBaseScope {
        registerAdhocRegistration(): void;
    }

    class PatientFileRegistrationsMonitorController extends Shared.Framework.ControllerBase<IPatientFileRegistrationsMonitorScope> {

        constructor(
            $scope: IPatientFileRegistrationsMonitorScope,
            $translate,
            toaster,
            private $state: ng.ui.IStateService,
            private carePlanApiSvc: Core.Services.CarePlanApiService,
            private masterdataSvc: Shared.Framework.MasterdataService,
            private therapyApiSvc: Core.Services.TherapyApiService) {
            super($scope, $translate, toaster);
            this.$scope.registerAdhocRegistration = () => this.registerAdhocRegistration();
        }

        public $onInit(): void {}

        registerAdhocRegistration(): void {
            this.$state.go('patients.patientfile.monitoring.registrations.adhoc');
        }
    }

    angular.module('RemeCare.Patient').controller('patientFileRegistrationsMonitorCtrl', (
        $scope,
        $translate,
        toaster,
        $state: ng.ui.IStateService,
        carePlanApiSvc: Core.Services.CarePlanApiService,
        masterdataSvc: Shared.Framework.MasterdataService,
        therapyApiSvc: Core.Services.TherapyApiService
    ) => new PatientFileRegistrationsMonitorController(
            $scope,
            $translate,
            toaster,
            $state,
            carePlanApiSvc,
            masterdataSvc,
            therapyApiSvc
    ));
}