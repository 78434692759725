namespace RemeCare.CareRequest {
    // Only used to visualize the carerequestor in the carerequest
    export class CareRequestorInfo {
        public name: string;
        public roleName: string;
        public linked: boolean;
        public riziv: string;
        public phone: string;
        public mobile: string;
        public email: string;

        constructor(
            roleName: string,
            linked: boolean,
            person?: Contract.Party.Read.IPerson,
            healthCareProfessional?: Model.HealthCareProfessional,
            requestPerson?: RequestPerson,
            organisation?: Contract.HealthCareParty.Read.IHealthCareOrganisationParty
        ) {
            this.roleName = roleName;
            this.linked = linked;
            if (person) {
                this.name =
                    Shared.StringHelper.orEmpty(person.LastName) + ' ' + Shared.StringHelper.orEmpty(person.FirstName);
                this.riziv = person.RizivNumber;
                this.phone = person.Telephone ? new Model.PhoneNumber(person.Telephone).toString() : '';
                this.mobile = person.Mobile ? new Model.PhoneNumber(person.Mobile).toString() : '';
                this.email = person.Email;
            }
            if (healthCareProfessional) {
                this.name =
                    Shared.StringHelper.orEmpty(healthCareProfessional.lastName) +
                    ' ' +
                    Shared.StringHelper.orEmpty(healthCareProfessional.firstName);
                this.riziv = healthCareProfessional.identificationNumber;
                this.phone = healthCareProfessional.phoneNumber ? healthCareProfessional.phoneNumber.toString() : '';
                this.mobile = healthCareProfessional.mobileNumber ? healthCareProfessional.mobileNumber.toString() : '';
                this.email = healthCareProfessional.email;
            }
            if (requestPerson) {
                this.name = requestPerson.getName();
                this.riziv = requestPerson.identificationNumber;
                this.phone = requestPerson.contactInfo.telephone ? requestPerson.contactInfo.telephone.toString() : '';
                this.mobile = requestPerson.contactInfo.mobile ? requestPerson.contactInfo.mobile.toString() : '';
                this.email = requestPerson.contactInfo.email;
            }
            if (organisation) {
                this.name = organisation.Name;
                this.riziv = organisation.IdentificationNumber;
                this.phone = organisation.PhoneNumber ? new Model.PhoneNumber(organisation.PhoneNumber).toString() : '';
                this.mobile = '';
                this.email = organisation.Email;
            }
        }
    }
}
