var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Hospitalisation = RemeCare.Model.Hospitalisation;
        var PatientFileAddHospitalisationModalController = /** @class */ (function (_super) {
            __extends(PatientFileAddHospitalisationModalController, _super);
            function PatientFileAddHospitalisationModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.$stateParams = $stateParams;
                _this.patientSvc = patientSvc;
                _this.masterdataSvc = masterdataSvc;
                _this.$scope.confirm = function () { return _this.confirm(); };
                _this.$scope.checkReason = function () { return _this.checkReason(); };
                return _this;
            }
            PatientFileAddHospitalisationModalController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                this.masterdataSvc.getCareTypes()
                    .success(function (ags) {
                    _this.$scope.careTypes = ags;
                })
                    .error(function (m) { return _this.errorCallback(m); });
                this.patientSvc.getHospitalisationReasons(this.$scope.patientId)
                    .success(function (ags) {
                    _this.$scope.hospitalisationReasons = ags;
                })
                    .error(function (m) { return _this.errorCallback(m); });
                if (this.$scope.hospitalisation.hospitalisationReason != null && this.$scope.hospitalisation.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                    this.$scope.allowOtherReason = true;
                }
                else {
                    this.$scope.allowOtherReason = false;
                }
            };
            PatientFileAddHospitalisationModalController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                if (this.$scope.hospitalisation.id === null || this.$scope.hospitalisation.id === undefined) {
                    this.patientSvc.savePatientFileHospitalisation(this.$scope.patientId, this.$scope.hospitalisation.toServerWrite())
                        .success(function () { return _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
                else {
                    this.patientSvc.updatePatientFileHospitalisation(this.$scope.patientId, this.$scope.hospitalisation.id, this.$scope.hospitalisation.toServerWrite())
                        .success(function () { return _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
            };
            PatientFileAddHospitalisationModalController.prototype.checkReason = function () {
                if (this.$scope.hospitalisation.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                    this.$scope.allowOtherReason = true;
                }
                else {
                    this.$scope.allowOtherReason = false;
                }
            };
            return PatientFileAddHospitalisationModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('patientFileAddHospitalisationModalCtrl', function ($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc) { return new PatientFileAddHospitalisationModalController($scope, $translate, toaster, $dialog, $stateParams, patientSvc, masterdataSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
