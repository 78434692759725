var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var MedicationOverviewController = /** @class */ (function (_super) {
            __extends(MedicationOverviewController, _super);
            function MedicationOverviewController($scope, $translate, toaster, $stateParams) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                return _this;
            }
            MedicationOverviewController.prototype.$onInit = function () {
                this.$scope.patientId = this.$stateParams.patientId;
            };
            return MedicationOverviewController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('medicationOverviewCtrl', function ($scope, $translate, toaster, $stateParams) { return new MedicationOverviewController($scope, $translate, toaster, $stateParams); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
