module RemeCare.Patient {
    import Medication = Shared.Contract.Read.IMedication;

    interface IMedicationModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        medicationInfo: {
            medication: Medication;
        }
    }

    class MedicationModalController extends Shared.Framework.ControllerBase<IMedicationModalScope> {
        constructor(
            $scope: IMedicationModalScope,
            $translate,
            toaster: Shared.Framework.Toaster
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit() {}
    }

    angular.module('RemeCare.Patient').controller('MedicationModalCtrl', MedicationModalController);
}