var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        var ManageIdentityController = /** @class */ (function () {
            function ManageIdentityController($timeout, $window) {
                this.$timeout = $timeout;
                this.$window = $window;
                this.isSetPasswordActivated = false;
            }
            ManageIdentityController.prototype.$onInit = function () {
                if (this.activating) {
                    this.activateProvider();
                }
                else {
                    this.retrieveExternalIdentityProvidersAsync();
                }
            };
            ManageIdentityController.prototype.setPassword = function (birthDate, password) {
                var _this = this;
                this.onPasswordSet({ birthDate: birthDate, password: password }).then(function (message) {
                    return _this.activateSuccess(message);
                });
            };
            ManageIdentityController.prototype.selectProvider = function (provider) {
                this.onProviderSelected({ provider: provider });
            };
            ManageIdentityController.prototype.retrieveExternalIdentityProvidersAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var eip, exception_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, 3, 4]);
                                return [4 /*yield*/, this.loadExternalIdentityProviders()];
                            case 1:
                                eip = _a.sent();
                                this.externalIdentityProviders = eip;
                                this.hasExternalOptions = this.externalIdentityProviders && this.externalIdentityProviders.length > 0;
                                return [3 /*break*/, 4];
                            case 2:
                                exception_1 = _a.sent();
                                this.errorMessage = exception_1;
                                return [3 /*break*/, 4];
                            case 3:
                                this.loaded = true;
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            ManageIdentityController.prototype.activateProvider = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var message, exception_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, 3, 4]);
                                return [4 /*yield*/, this.onProviderActivated()];
                            case 1:
                                message = _a.sent();
                                this.activateSuccess(message);
                                return [3 /*break*/, 4];
                            case 2:
                                exception_2 = _a.sent();
                                this.errorMessage = exception_2;
                                return [3 /*break*/, 4];
                            case 3:
                                this.loaded = true;
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            ManageIdentityController.prototype.activateSuccess = function (successMessage) {
                var _this = this;
                this.successMessage = successMessage;
                this.activated = true;
                this.$timeout(function () {
                    _this.$window.location.href = _this.$window.location.origin;
                }, 5000);
            };
            return ManageIdentityController;
        }());
        SelfService.remeCareSelfServiceModule.component('rcManageIdentity', {
            bindings: {
                activating: '<',
                confirmLabel: '@',
                externalExplanation: '@',
                externalButtonExplanation: '@',
                header: '@',
                internalExplanation: '@',
                loadExternalIdentityProviders: '&',
                onPasswordSet: '&',
                onProviderActivated: '&',
                onProviderSelected: '&',
                passwordLoader: '@',
                userId: '<'
            },
            controller: ManageIdentityController,
            templateUrl: 'views/selfService/manageIdentity.html'
        });
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
