/// <reference path="careTeamPartyBaseController.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CareTeamProfessionalsController = /** @class */ (function (_super) {
            __extends(CareTeamProfessionalsController, _super);
            // @ngInject
            function CareTeamProfessionalsController($scope, $translate, $filter, toaster, patientSvc, authservice, partyApiSvc, modalBuilderFactory, gridBuilderSvc, healthCarePartySvc) {
                var _this = _super.call(this, $scope, $translate, $filter, toaster, patientSvc, authservice, partyApiSvc, modalBuilderFactory, gridBuilderSvc) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.$filter = $filter;
                _this.toaster = toaster;
                _this.patientSvc = patientSvc;
                _this.authservice = authservice;
                _this.partyApiSvc = partyApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.healthCarePartySvc = healthCarePartySvc;
                return _this;
            }
            CareTeamProfessionalsController.prototype.getActiveCarePlans = function (party) {
                return _(party.Assignments.filter(function (as) { return as.CarePlan.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open; }).map(function (a) { return a.CarePlan.Name; }))
                    .unique()
                    .join(', ');
            };
            CareTeamProfessionalsController.prototype.toggleActiveTeamMembers = function () {
                this.partyOverviewGrid.search();
            };
            CareTeamProfessionalsController.prototype.showCareTeamPartyDetail = function (partyRoleId) {
                return __awaiter(this, void 0, void 0, function () {
                    var personSummaries, healthCareParty, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.findPersonSummaryAsync(partyRoleId)];
                            case 1:
                                personSummaries = _a.sent();
                                healthCareParty = {
                                    FirstName: personSummaries.FirstName,
                                    LastName: personSummaries.LastName,
                                    PartyRoleId: personSummaries.PartyRoleId,
                                    ZipCode: personSummaries.ZipCode,
                                    City: personSummaries.City,
                                    AddressLine: personSummaries.AddressLine,
                                    Email: personSummaries.Email,
                                    IdentificationNumber: personSummaries.NationalNumber,
                                    PhoneNumber: personSummaries.Telephone,
                                    MobileNumber: personSummaries.Mobile,
                                    HealthCareSpecialtyProfessions: undefined,
                                    PartyId: personSummaries.PartyId,
                                };
                                this.showCareTeamProfessionalDetailModalAsync(healthCareParty);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CareTeamProfessionalsController.prototype.init = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.healthCarePartySvc.findHealthCareProfessionalTypesAsync(),
                                        this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.professionalTypes = _a[0], this.specialityTypes = _a[1];
                                this.buildGrids();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CareTeamProfessionalsController.prototype.buildOverviewGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, sd, c) {
                    return _this.searchCareTeamAsync(p, ps, sf, sd, c, RemeCare.Shared.Contract.Code.PartyType.Person, function (cp) {
                        if (_this.onlyActive) {
                            return _(cp.Assignments).any(_this.isActiveAssignment);
                        }
                        else {
                            return true;
                        }
                    });
                })
                    .addColumn('LastName', 'General.LastName')
                    .addColumn('Name', 'General.FirstName')
                    .addActionColumn('info-sign', function (p) { return _this.showCareTeamPartyDetail(p.PartyRoleId); })
                    .addActionOnScopeColumn('$ctrl.parent.getTypeAndSpecialities', 'General.Type')
                    .addActionOnScopeColumn('$ctrl.parent.getActiveCarePlans', 'General.CarePlans.Active')
                    .addShowDetailButtonColumn(function (p) { return _this.showPartyAssignments(p, RemeCare.Shared.Contract.Code.PartyType.Person); });
                this.partyOverviewGrid = builder.build();
                this.partyOverviewGrid.pagingOptions.pageSize = 50;
                this.partyOverviewGrid.search();
            };
            CareTeamProfessionalsController.prototype.showPartySelector = function (actorRole, professional) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        this.modalBuilderFactory
                            .showSelector(professional, ['PartyName'], ' ', actorRole.Name)
                            .done(function (pr) { return __awaiter(_this, void 0, void 0, function () {
                            var personSummary, healthCareParty, careTeamParty, e_3;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.partyApiSvc.findPersonSummaryAsync(pr.PartyRoleId)];
                                    case 1:
                                        personSummary = _a.sent();
                                        healthCareParty = {
                                            FirstName: personSummary.FirstName,
                                            LastName: personSummary.LastName,
                                            PartyRoleId: personSummary.PartyRoleId,
                                            ZipCode: personSummary.ZipCode,
                                            City: personSummary.City,
                                            AddressLine: personSummary.AddressLine,
                                            Email: personSummary.Email,
                                            IdentificationNumber: personSummary.NationalNumber,
                                            PhoneNumber: personSummary.Telephone,
                                            MobileNumber: personSummary.Mobile,
                                            HealthCareSpecialtyProfessions: undefined,
                                            PartyId: personSummary.PartyId,
                                        };
                                        careTeamParty = this.createProfessionalCareTeamParty(actorRole, healthCareParty);
                                        this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], healthCareParty.PartyRoleId, healthCareParty.FirstName, healthCareParty.LastName);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_3 = _a.sent();
                                        this.toaster.error(e_3);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                    });
                });
            };
            CareTeamProfessionalsController.prototype.showPartySearchModal = function (actorRole) {
                var _this = this;
                var specialties = this.convertToTypeAndSpecialties(this.getActorRoleInclusionDetails(actorRole));
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('searchHealthCareProfessionalPartyCtrl')
                    .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                    .setScope({
                    title: actorRole.Name,
                    typeAndSpecialties: specialties,
                })
                    .setResultCallBack(function (r) {
                    var careTeamParty = _this.createProfessionalCareTeamParty(actorRole, r);
                    _this.showCareTeamAssignmentModalAsync(careTeamParty.Assignments[0], r.PartyRoleId, r.FirstName, r.LastName);
                })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            // Used in addActionOnScopeColumn above
            CareTeamProfessionalsController.prototype.getTypeAndSpecialities = function (party) {
                var _this = this;
                var res = '';
                if (party.HealthCareProfessionalTypeId) {
                    res += _(this.professionalTypes.filter(function (t) { return t.Id === party.HealthCareProfessionalTypeId; })).first()
                        .Text;
                }
                if (party.SpecialityTypeIds && party.SpecialityTypeIds.length !== 0) {
                    res += " (" + party.SpecialityTypeIds.map(function (s) { return _(_this.specialityTypes.filter(function (t) { return t.Id === s; })).first().Text; }).join(', ') + ")";
                }
                return res;
            };
            CareTeamProfessionalsController.prototype.showCareTeamProfessionalDetailModalAsync = function (healthCareParty) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/patient/careTeam/careTeamProfessionalDetail.html')
                        .setScope({
                        healthCareParty: healthCareParty,
                    })
                        .setResultCallBack(function () { return resolve(); })
                        .build();
                });
            };
            CareTeamProfessionalsController.prototype.convertToTypeAndSpecialties = function (inclusions) {
                return inclusions
                    .filter(function (i) { return i.HealthCareProfessionalTypeId != null; })
                    .map(function (i) {
                    return {
                        healthCareProfessionalTypeId: i.HealthCareProfessionalTypeId,
                        healthCareSpecialtyProfessionId: i.HealthCareSpecialtyProfessionId,
                    };
                })
                    .value();
            };
            CareTeamProfessionalsController.prototype.createProfessionalCareTeamParty = function (actorRole, hcProfessional) {
                var result = this.createCareTeamParty(actorRole, hcProfessional);
                result.Name = hcProfessional.FirstName;
                result.LastName = hcProfessional.LastName;
                return result;
            };
            return CareTeamProfessionalsController;
        }(Patient.CareTeamPartyBaseController));
        Patient.remeCarePatientModule.component('rcCareTeamProfessionals', {
            bindings: {
                actorRoles: '<',
                patientId: '<',
                editRight: '<',
                onlyActive: '<',
                careTeamsCanEdit: '<',
            },
            controller: CareTeamProfessionalsController,
            templateUrl: 'views/patient/careTeam/careTeamProfessionals.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
