var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var SearchOrganisationsController = /** @class */ (function () {
            function SearchOrganisationsController(toaster, masterdataSvc, healthCarePartySvc, partyApiSvc, gridBuilderSvc) {
                this.toaster = toaster;
                this.masterdataSvc = masterdataSvc;
                this.healthCarePartySvc = healthCarePartySvc;
                this.partyApiSvc = partyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
            }
            SearchOrganisationsController.prototype.$onInit = function () {
                this.buildGrid();
                this.loadDataAsync();
            };
            SearchOrganisationsController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getCountriesAsync(),
                                        this.healthCarePartySvc.getHealthCareOrganisationTypesAsync()
                                    ])];
                            case 1:
                                _a = _b.sent(), this.countries = _a[0], this.organisationTypes = _a[1];
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            SearchOrganisationsController.prototype.buildGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.searchPersonsAsync(page, pageSize, sortField, sortDirection, criteria);
                })
                    .setBindToUrl(this.bindToUrl)
                    .addColumn('Name', 'General.Name')
                    .addColumn('HealthCareOrganisationType.Text', 'General.Type')
                    .addColumn('City', 'General.City')
                    .setSearchCriteria(function (c) {
                    c.phoneNumber = new RemeCare.Model.PhoneNumber();
                });
                if (this.onOrganisationSelected == null) {
                    builder = builder.addNavigationColumn('eye-open', 'management.organisations.detail', { partyId: 'Id' });
                }
                else {
                    builder = builder.addSelectButtonColumn(function (o) { return _this.onOrganisationSelected({ organisation: o }); });
                }
                this.grid = builder.build();
            };
            SearchOrganisationsController.prototype.searchPersonsAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, result, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = _.clone(criteria);
                                query.page = page;
                                query.pageSize = pageSize;
                                query.sortField = sortField;
                                query.sortOrder = sortDirection;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.partyApiSvc.findOrganisationsAsync(query)];
                            case 2:
                                result = _a.sent();
                                this.searchCollapsed = true;
                                return [2 /*return*/, result];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                throw e_2;
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return SearchOrganisationsController;
        }());
        Management.remeCareManagementModule.component('rcOrganisationSearch', {
            controller: SearchOrganisationsController,
            templateUrl: 'views/management/organisations/organisationSearch.html',
            bindings: {
                onOrganisationSelected: '&?',
                bindToUrl: '@?'
            }
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
