var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ActionType = RemeCare.Shared.Contract.Code.ActionType;
        var AuthContext = RemeCare.Shared.Framework.AuthContext;
        var AuthRight = RemeCare.Shared.Framework.AuthRight;
        var FollowUpDocumentsController = /** @class */ (function () {
            function FollowUpDocumentsController(authservice, gridBuilderSvc, patientSvc, toaster, uiGridConstants, baseUrl) {
                this.authservice = authservice;
                this.gridBuilderSvc = gridBuilderSvc;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
                this.uiGridConstants = uiGridConstants;
                this.baseUrl = baseUrl;
            }
            FollowUpDocumentsController.prototype.$onInit = function () {
                var _this = this;
                this.loadFilters();
                this.grid = this.buildGrid();
                this.grid.search().then(function (result) {
                    if (result.Items.length > 0) {
                        result.Items[0].selected = true;
                        _this.documentSelectionChanged(result.Items[0]);
                    }
                });
            };
            FollowUpDocumentsController.prototype.closeDocument = function (document) {
                document.selected = false;
                this.documentSelectionChanged(document);
                this.setDocumentSelected(this.grid.getData());
            };
            FollowUpDocumentsController.prototype.buildGrid = function () {
                var _this = this;
                var gridBuilder = this.gridBuilderSvc
                    .createGridBuilder(function (p, ps, sf, so, c) {
                    return _this.executeSearch(p, ps, sf, so, c);
                })
                    .addColumn('Name', 'General.Name')
                    .addColumn('CarePlan', 'General.CarePlan')
                    .addDateColumn('ReferenceDate', 'General.DocumentObservationDate', 'shortDate', {
                    sort: { direction: this.uiGridConstants.DESC },
                });
                if (this.authservice.hasRight(AuthContext.patientDocuments, AuthRight.Write)) {
                    gridBuilder = gridBuilder.addNavigationColumn('pencil', 'patients.patientfile.mediaDocuments.documents', {
                        documentId: 'Id',
                    });
                }
                gridBuilder = gridBuilder.addCheckBoxColumnFunction('General.Compare', 'selected', function (pfd) { return !_this.canToggleDocument(pfd); }, function (pfd) { return _this.documentSelectionChanged(pfd); });
                return gridBuilder.build();
            };
            FollowUpDocumentsController.prototype.canToggleDocument = function (document) {
                return !this.oldestDocument || document.selected;
            };
            FollowUpDocumentsController.prototype.documentSelectionChanged = function (document) {
                if (document.selected) {
                    if (this.newestDocument) {
                        if (moment(this.newestDocument.ReferenceDate).isAfter(moment(document.ReferenceDate))) {
                            this.oldestDocument = document;
                        }
                        else {
                            this.oldestDocument = this.newestDocument;
                            this.newestDocument = document;
                        }
                    }
                    else {
                        this.newestDocument = document;
                    }
                }
                else {
                    if (this.newestDocument && this.newestDocument.Id === document.Id) {
                        this.newestDocument = this.oldestDocument;
                    }
                    this.oldestDocument = null;
                }
                return document;
            };
            FollowUpDocumentsController.prototype.executeSearch = function (page, pageSize, sortField, sortOrder, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, searchResult, _i, _a, doc, error_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                query = __assign({}, criteria, { page: page,
                                    pageSize: pageSize,
                                    sortField: sortField,
                                    sortOrder: sortOrder, referenceFromDate: criteria.referenceFromDate
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.referenceFromDate)
                                        : null, referenceUntilDate: criteria.referenceUntilDate
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.referenceUntilDate)
                                        : null });
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.findFollowUpDocumentsAsync(this.patientId, query)];
                            case 2:
                                searchResult = _b.sent();
                                for (_i = 0, _a = searchResult.Items; _i < _a.length; _i++) {
                                    doc = _a[_i];
                                    doc.Url = RemeCare.Shared.DocumentHelper.fixDocumentUrl(doc.Url, this.baseUrl, doc.Name);
                                }
                                if (searchResult.Items.length === 0 && !this.grid.isSearchFiltered()) {
                                    this.noFollowUpDocuments = true;
                                    return [2 /*return*/, searchResult];
                                }
                                this.noFollowUpDocuments = false;
                                this.setDocumentSelected(searchResult.Items);
                                return [2 /*return*/, searchResult];
                            case 3:
                                error_1 = _b.sent();
                                this.toaster.error(error_1);
                                return [2 /*return*/, Promise.reject(error_1)];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            FollowUpDocumentsController.prototype.setDocumentSelected = function (documents) {
                var _this = this;
                _.each(documents, function (doc) {
                    doc.selected =
                        (_this.newestDocument && _this.newestDocument.Id === doc.Id) ||
                            (_this.oldestDocument && _this.oldestDocument.Id === doc.Id);
                });
            };
            FollowUpDocumentsController.prototype.loadFilters = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, structuralTherapyActionQuery, _b, e_1;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                _c.trys.push([0, 3, , 4]);
                                _a = this;
                                return [4 /*yield*/, this.patientSvc.getCarePlansAsync(this.patientId)];
                            case 1:
                                _a.carePlans = _c.sent();
                                structuralTherapyActionQuery = {
                                    actionTypeCodes: [ActionType.RequestInformationObject],
                                    hasFollowedUpDocumentPart: true,
                                };
                                _b = this;
                                return [4 /*yield*/, this.patientSvc.getStructuralTherapyActionsAsync(this.patientId, structuralTherapyActionQuery)];
                            case 2:
                                _b.structuralTherapyActions = _c.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _c.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            FollowUpDocumentsController.prototype.getActionText = function (patientFileDocument) {
                if (patientFileDocument.Action) {
                    return ": " + patientFileDocument.Action;
                }
                return '';
            };
            return FollowUpDocumentsController;
        }());
        Patient.remeCarePatientModule.component('rcFollowUpDocuments', {
            bindings: {
                patientId: '<',
            },
            controller: FollowUpDocumentsController,
            templateUrl: 'patient/controllers/mediaDocuments/followUpDocuments/followUpDocuments.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
