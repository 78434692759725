module RemeCare.Patient {
    import Alarm = Contract.Alarms.Read.IAlarm;

    interface IPatientFileAlarmDetailModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        alarm: Alarm;
        handleAlarm: () => void;
    }

    class PatientFileAlarmDetailModalController extends Shared.Framework.ControllerBase<
        IPatientFileAlarmDetailModalScope
    > {
        constructor(
            protected $scope: IPatientFileAlarmDetailModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly patientSvc: Patient.PatientService
        ) {
            super($scope, $translate, toaster);
            this.$scope.handleAlarm = () => this.handleAlarmAsync();
        }

        public $onInit(): void {}

        private async handleAlarmAsync(): Promise<void> {
            try {
                await this.patientSvc.saveAlarmHandlingAsync(this.$stateParams.patientId, this.$scope.alarm.Id);
                this.$scope.$close();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    angular
        .module('RemeCare.Patient')
        .controller(
            'patientFileAlarmDetailModalCtrl',
            ($scope, $translate, toaster, $stateParams, patientSvc: Patient.PatientService) =>
                new PatientFileAlarmDetailModalController($scope, $translate, toaster, $stateParams, patientSvc)
        );
}
