/// <reference path="./monitoringPartComponentBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var TherapyComplianceGraphController = /** @class */ (function (_super) {
            __extends(TherapyComplianceGraphController, _super);
            // @ngInject
            function TherapyComplianceGraphController($rootScope, $locale, dateHelper, spinnerSvc, $translate, toaster, $q, $filter, patientSvc) {
                var _this = _super.call(this, $rootScope, $locale, dateHelper, spinnerSvc) || this;
                _this.$rootScope = $rootScope;
                _this.$locale = $locale;
                _this.dateHelper = dateHelper;
                _this.spinnerSvc = spinnerSvc;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$q = $q;
                _this.$filter = $filter;
                _this.patientSvc = patientSvc;
                _this.registrationPeriod = 'registrationPeriod';
                _this.reminderTime = 'reminderTime';
                _this.configToGraph = {};
                return _this;
            }
            TherapyComplianceGraphController.prototype.init = function () {
                this.structuralTherapyActionIds = _.map(this.monitoringPart.monitoringPartSources, function (mps) { return mps.structuralTherapyActionId; });
                if (!this.structuralTherapyActionIds.length) {
                    this.toaster.error('Views.PatientFile.Monitoring.TherapyCompliance.ConfigError');
                }
                Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
                    return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
                };
                if (Highcharts.VMLRenderer) {
                    Highcharts.VMLRenderer.prototype.symbols.cross = Highcharts.SVGRenderer.prototype.symbols.cross;
                }
                return this.$q.resolve();
            };
            TherapyComplianceGraphController.prototype.onDateChange = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var therapyComplianceGraphs, graphs, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                if (!this.structuralTherapyActionIds.length) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.patientSvc.getTherapyComplianceGraphAsync(this.patientId, this.structuralTherapyActionIds, this.dateInfo.fromDate, this.getUntilDate())];
                            case 1:
                                therapyComplianceGraphs = _a.sent();
                                graphs = _(therapyComplianceGraphs)
                                    .chain()
                                    .map(function (g) { return new Patient.Model.TherapyComplianceGraph(g); })
                                    .groupBy(function (g) { return g.subject.Id; })
                                    .value();
                                this.configureCharts(graphs);
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _a.sent();
                                this.toaster.error(error_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            TherapyComplianceGraphController.prototype.configureCharts = function (graphs) {
                var _this = this;
                var chartConfigs = _(graphs).map(function (g) {
                    var config = {
                        options: {
                            chart: {
                                animation: false,
                                height: 200,
                                zoomType: 'xy',
                                marginLeft: 37,
                                marginRight: 37,
                            },
                            title: {
                                text: g[0].subject.Text,
                            },
                            credits: {
                                enabled: false,
                            },
                            exporting: {
                                enabled: false,
                            },
                            xAxis: _this.getXAxisConfig(null, true),
                            yAxis: {
                                type: 'datetime',
                                dateTimeLabelFormats: {
                                    second: '%H',
                                    minute: '%H',
                                    hour: '%H',
                                    day: '%H',
                                    week: '%H',
                                    month: '%H',
                                    year: '%H',
                                },
                                min: _this.getMinY(g),
                                max: _this.getMaxY(g),
                                startOnTick: false,
                                endOnTick: false,
                                tickInterval: 3600 * 1000,
                                title: {
                                    text: null,
                                },
                                plotBands: _this.getPlotBands(g),
                                plotLines: _this.getPlotLines(g),
                                reversed: true,
                            },
                            plotOptions: {
                                animation: false,
                                series: {
                                    marker: {
                                        enabled: true,
                                    },
                                    events: {
                                        legendItemClick: function () {
                                            return false;
                                        },
                                    },
                                },
                            },
                            legend: {
                                enabled: _this.showLegend,
                            },
                        },
                        series: _this.getSeries(g),
                    };
                    _this.configToGraph[config.options.title.text] = g;
                    return config;
                });
                this.chartConfigs = chartConfigs;
            };
            TherapyComplianceGraphController.prototype.getMinY = function (graph) {
                var min = _(graph)
                    .chain()
                    .map(function (g) { return g.getMinYValue(); })
                    .min(function (m) { return m.valueOf(); })
                    .value()
                    .clone();
                min = min.subtract(1, 'hours');
                return min.valueOf();
            };
            TherapyComplianceGraphController.prototype.getMaxY = function (graph) {
                var max = _(graph)
                    .chain()
                    .map(function (g) { return g.getMaxYValue(); })
                    .max(function (m) { return m.valueOf(); })
                    .value()
                    .clone();
                max = max.add(1, 'hours');
                return max.valueOf();
            };
            TherapyComplianceGraphController.prototype.getPlotBands = function (graphs) {
                var _this = this;
                var result = [];
                _(graphs).forEach(function (graph) {
                    if (graph.registrationFrom && graph.registrationUntil) {
                        result.push({
                            color: 'rgba(255,0,0,0.04)',
                            from: graph.registrationFrom.valueOf(),
                            to: graph.registrationUntil.valueOf(),
                            id: _this.registrationPeriod,
                        });
                    }
                });
                return result;
            };
            TherapyComplianceGraphController.prototype.getPlotLines = function (graphs) {
                var _this = this;
                var result = [];
                _(graphs).forEach(function (graph) {
                    if (graph.reminderTime) {
                        result.push({
                            color: 'orange',
                            value: graph.reminderTime.valueOf(),
                            dashStyle: 'longDash',
                            width: 2,
                            id: _this.reminderTime,
                        });
                    }
                    if (graph.preferredTime) {
                        result.push({
                            color: 'green',
                            value: graph.preferredTime.valueOf(),
                            width: 2,
                            id: _this.reminderTime,
                        });
                    }
                });
                return result;
            };
            TherapyComplianceGraphController.prototype.getLegendSeries = function () {
                return [
                    {
                        animation: false,
                        type: 'scatter',
                        marker: {
                            symbol: 'circle',
                        },
                        color: 'blue',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.RegisteredByPatient'),
                    },
                    {
                        animation: false,
                        type: 'scatter',
                        marker: {
                            symbol: 'circle',
                        },
                        color: 'orange',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.RegisteredByMonitor'),
                    },
                    {
                        animation: false,
                        type: 'scatter',
                        marker: {
                            symbol: 'cross',
                            lineColor: '#d9534f',
                            lineWidth: 2,
                        },
                        color: '#d9534f',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.NotTaken'),
                    },
                    {
                        animation: false,
                        type: 'scatter',
                        marker: {
                            symbol: 'triangle',
                        },
                        color: 'orange',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.NoRegistration'),
                    },
                    {
                        animation: false,
                        type: 'line',
                        marker: {
                            enabled: false,
                        },
                        color: 'green',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.PreferredTime'),
                        lineWidth: 2,
                    },
                    {
                        animation: false,
                        type: 'line',
                        marker: {
                            enabled: false,
                        },
                        color: 'orange',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.ReminderTime'),
                        lineWidth: 2,
                        dashStyle: 'Dash',
                    },
                    {
                        animation: false,
                        type: 'column',
                        color: 'rgba(255,0,0,0.1)',
                        name: this.$translate.instant('Views.PatientFile.Monitoring.RegistrationWindow'),
                        pointPlacement: 'on',
                    },
                ];
            };
            TherapyComplianceGraphController.prototype.getSeries = function (graphs) {
                var _this = this;
                var that = this;
                var series = this.getLegendSeries();
                _(graphs).forEach(function (graph) {
                    series.push({
                        animation: false,
                        data: _(graph.graphPoints).map(function (gp) {
                            return ({
                                x: moment(gp.x).valueOf(),
                                y: graph.getYValue(gp.y).valueOf(),
                                name: gp.y.CodeSetItem
                                    ? gp.y.CodeSetItem.Text
                                    : _this.$translate.instant('Views.PatientFile.Monitoring.NoRegistration'),
                                color: _this.getColour(gp.y),
                                marker: {
                                    symbol: _this.getSymbol(gp.y),
                                    lineColor: _this.getColour(gp.y),
                                    lineWidth: 2,
                                },
                            });
                        }),
                        type: 'line',
                        showInLegend: false,
                        tooltip: {
                            headerFormat: '',
                            // ReSharper disable SuspiciousThisUsage
                            // Using 'this' in a formatter function (not arrow function!) is how HighCharts API is supposed to be used
                            pointFormatter: function () {
                                return (that.$filter('date')(this.x, 'shortDate') + " - " + that.$filter('date')(this.y, 'shortTime') + "<br/>" + ("<b>" + this.name + "</b>"));
                            },
                            // ReSharper restore SuspiciousThisUsage
                            dateTimeLabelFormats: {
                                millisecond: '%A, %b %e, %H:%M:%S.%L',
                                second: '%A, %b %e, %H:%M:%S',
                                minute: '%A, %b %e, %H:%M',
                                hour: '%A, %b %e, %H:%M',
                                day: '%A, %b %e, %Y',
                                week: 'Week from %A, %b %e, %Y',
                                month: '%B %Y',
                                year: '%Y',
                            },
                            xDateFormat: '%Y',
                        },
                        turboThreshold: 0,
                    });
                });
                return series;
            };
            TherapyComplianceGraphController.prototype.getColour = function (registration) {
                if (registration.CodeSetItem == null) {
                    return 'orange';
                }
                if (registration.CodeSetItem.NumericValue === 0) {
                    return 'red';
                }
                if (registration.CodeSetItem.NumericValue === 1 && registration.RegisteredByPatient) {
                    return 'blue';
                }
                return 'orange';
            };
            TherapyComplianceGraphController.prototype.getSymbol = function (registration) {
                if (registration.CodeSetItem == null) {
                    return 'triangle';
                }
                if (registration.CodeSetItem.NumericValue === 0) {
                    return 'cross';
                }
                if (registration.CodeSetItem.NumericValue === 1) {
                    return 'circle';
                }
                return 'triangle';
            };
            return TherapyComplianceGraphController;
        }(Patient.ChartMonitoringPartController));
        var TherapyComplianceGraphComponent = /** @class */ (function (_super) {
            __extends(TherapyComplianceGraphComponent, _super);
            function TherapyComplianceGraphComponent() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.controller = TherapyComplianceGraphController;
                _this.templateUrl = 'views/patient/monitoring/dashboard/therapyComplianceGraphs.html';
                return _this;
            }
            return TherapyComplianceGraphComponent;
        }(Patient.MonitoringPartComponentBase));
        Patient.remeCarePatientModule.component('rcMonitoringTherapyComplianceGraph', new TherapyComplianceGraphComponent());
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
