var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CareRequestMedicationSimplifiedController = /** @class */ (function (_super) {
            __extends(CareRequestMedicationSimplifiedController, _super);
            function CareRequestMedicationSimplifiedController($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$timeout = $timeout;
                _this.periodicityFactory = periodicityFactory;
                _this.masterdataSvc = masterdataSvc;
                _this.daySvc = daySvc;
                $scope.minEndDate = function () { return _this.minEndDate(); };
                $scope.addIntraDayTiming = function (a) { return _this.addIntraDayTiming(a); };
                $scope.simplifiedRecurrenceChanged = function (sr) { return _this.simplifiedRecurrenceChanged(sr); };
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            CareRequestMedicationSimplifiedController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.selectedSimplifiedRecurrence = this.$scope.careRequestMedication.periodicities[0] != null
                    ? this.$scope.careRequestMedication.periodicities[0].simplifiedRecurrence
                    : null;
                this.$scope.days = this.daySvc.getDays();
                if (this.$scope.isAdHoc) {
                    this.$scope.simplifiedRecurrences = [];
                    if (this.$scope.commLanguage) {
                        this.masterdataSvc.getInterfaceLanguages()
                            .success(function (r) {
                            var langCode = _.find(r, function (x) { return x.Code === _this.$scope.commLanguage; }).Code;
                            _this.masterdataSvc.getTranslation(langCode, 'Views.Patient.Medication.IfNeeded')
                                .success(function (trans) {
                                _this.createAdHocPeriodicity(trans['Views.Patient.Medication.IfNeeded']);
                                _this.$scope.simplifiedRecurrences.push({
                                    Id: RemeCare.Shared.Contract.Code.SimplifiedRecurrence.AdHoc,
                                    Text: trans['Views.Patient.Medication.IfNeeded']
                                });
                                _this.$scope.selectedSimplifiedRecurrence = _(_this.$scope.simplifiedRecurrences).first();
                            })
                                .error(function (e) { return _this.errorCallback(e); });
                        })
                            .error(function (e) { return _this.errorCallback(e); });
                    }
                    else {
                        this.createAdHocPeriodicity(this.$translate.instant('Views.Patient.Medication.IfNeeded'));
                        this.$scope.simplifiedRecurrences.push({
                            Id: RemeCare.Shared.Contract.Code.SimplifiedRecurrence.AdHoc,
                            Text: this.$translate.instant('Views.Patient.Medication.IfNeeded')
                        });
                        this.$scope.selectedSimplifiedRecurrence = _(this.$scope.simplifiedRecurrences).first();
                    }
                }
                else {
                    this.masterdataSvc.getSimplifiedRecurrences()
                        .success(function (r) { return _this.$scope.simplifiedRecurrences = r; })
                        .error(function (e) { return _this.errorCallback(e); });
                }
            };
            CareRequestMedicationSimplifiedController.prototype.createAdHocPeriodicity = function (unStructuredPeriodicity) {
                this.$scope.careRequestMedication.periodicities = [];
                this.$scope.careRequestMedication.periodicities.push(this.periodicityFactory.createPeriodicitiesFromAdHoc(unStructuredPeriodicity, 1));
            };
            CareRequestMedicationSimplifiedController.prototype.minEndDate = function () {
                return this.$scope.careRequestMedication.id && moment().isAfter(moment(this.$scope.careRequestMedication.validFromDate), 'day')
                    ? this.today
                    : this.$scope.careRequestMedication.validFromDate;
            };
            CareRequestMedicationSimplifiedController.prototype.simplifiedRecurrenceChanged = function (simplifiedRecurrence) {
                this.$scope.careRequestMedication.periodicities =
                    this.periodicityFactory.createPeriodicitiesFromSimplifiedRecurrence(simplifiedRecurrence);
            };
            CareRequestMedicationSimplifiedController.prototype.addIntraDayTiming = function (array) {
                var intraDay = new RemeCare.Shared.Framework.Model.IntraDayTimingAdministration();
                intraDay.targetValues.push(new RemeCare.Shared.Framework.Model.TargetDose());
                array.push(intraDay);
            };
            CareRequestMedicationSimplifiedController.prototype.confirm = function () {
                if (this.$scope.medicationForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.careRequestMedication);
            };
            return CareRequestMedicationSimplifiedController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule
            .controller('careRequestMedicationSimplifiedCtrl', function ($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc) { return new CareRequestMedicationSimplifiedController($scope, $translate, toaster, $timeout, periodicityFactory, masterdataSvc, daySvc); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
