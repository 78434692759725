/// <reference path="./monitoringPartComponentBase.ts"/>

module RemeCare.Patient {

    import CarePlanActionStatus = Contract.Core.Codes.CarePlanActionStatus;
    import MonitoringPartSourceAction = Shared.Framework.Model.MonitoringPartSourceAction;
    import ActionTimingInformation = Contract.CarePlanAction.Read.IActionTimingInformation;

    class ActionTableController extends MonitoringPartControllerBase<Shared.Framework.Model.MonitoringPartActionTable> {

        public grid: Shared.Framework.Grid.Grid<ActionTimingInformation>;
        public gridId: string;

        // @ngInject
        constructor(
            dateHelper,
            spinnerSvc: Shared.Framework.Service.SpinnerService,
            private $q: ng.IQService,
            private toaster: Shared.Framework.Toaster,
            private idService: Shared.Framework.Service.IIdService,
            private carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            private gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory) {
            super(dateHelper, spinnerSvc);
        }

        public hasData(): boolean {
            return this.grid && this.grid.hasData();
        }

        protected async init(): Promise<void> {
            this.buildGrid();
            this.dateInfo.untilDate = this.globalUntil || null;
            return this.$q.resolve();
        }

        private buildGrid(): void {
            var dateFormat = this.monitoringPart.showTime
                ? 'short'
                : 'shortDate';

            var gridBuilder = this.gridBuilderSvc.createGridBuilder<ActionTimingInformation>(
                (p, ps, sf, sd, c) => this.executeSearch(p, ps, sf, sd, c))
                .addColumn('Name', 'General.Action');

            if (this.monitoringPart.showPlannedDates) {
                gridBuilder = gridBuilder.addDateRangeColumn('PlannedDateTimeFrom', 'PlannedDateTimeUntil', 'Views.PatientFile.Monitoring.PlannedDate', dateFormat);
            }
            if (this.monitoringPart.showEventDates) {
                gridBuilder = gridBuilder.addDateRangeColumn('EventDateTimeFrom', 'EventDateTimeUntil', 'Views.PatientFile.Monitoring.ObservationDate', dateFormat);
            }
            if (this.monitoringPart.showRegistrationDate) {
                gridBuilder = gridBuilder.addDateColumn('ExecutionDateTime', 'Views.PatientFile.Monitoring.ExecutionDate', dateFormat);
            }
            if (this.monitoringPart.showModificationDate) {
                gridBuilder = gridBuilder.addDateColumn('ModificationDateTime', 'Views.PatientFile.Monitoring.ModificationDate', dateFormat);
            }
            this.gridId = this.idService.generateId();
            this.grid = gridBuilder
                .setSorting(false)
                .setMultiLineWithTodayLine(this.gridId, 'SuggestedDateTime')
                .build();

            this.grid.pagingOptions.pageSize = this.monitoringPart.maxRows;
        }

        protected async onDateChange(): Promise<void> {
            this.grid.pagingOptions.currentPage = 1;
            await this.grid.search();
        }

        private executeSearch(page: number, pageSize: number, sortField: string, sortDirection: string, criteria: any):
            Promise<Shared.Contract.ISearchResult<ActionTimingInformation>> {
            return new Promise<Shared.Contract.ISearchResult<ActionTimingInformation>>((resolve, reject) => {
                var dateFilterTypes = [];
                if (this.monitoringPart.showRegistrationDate) {
                    dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.ExecutionDate);
                }
                if (this.monitoringPart.showPlannedDates) {
                    dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.PlannedDate);
                }
                if (this.monitoringPart.showEventDates) {
                    dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.EventDate);
                }
                if (this.monitoringPart.showModificationDate) {
                    dateFilterTypes.push(Contract.Core.Codes.DateFilterTypeCode.ModificationDate);
                }
                var query = <Contract.CarePlanAction.Read.IFindActionTimingInformationsQuery>{
                    page: page,
                    pageSize: pageSize,
                    sortField: 'suggesteddatetime',
                    sortOrder: 'DESC',
                    patientId: this.patientId,
                    actionTemplateIds: _(this.monitoringPart.monitoringPartSources)
                        .map(mps => (<MonitoringPartSourceAction>mps).structuralTherapyActionId),
                    statusIds: [
                        CarePlanActionStatus.Open,
                        CarePlanActionStatus.Overdue,
                        CarePlanActionStatus.Completed
                    ],
                    dateFilterTypes: dateFilterTypes,
                    startDate: Shared.DateHelper.toServerDateString(this.dateInfo.fromDate),
                    endDate: Shared.DateHelper.toServerDateString(this.dateInfo.untilDate)
                };
                this.carePlanActionApiSvc.findActionTimingInformations(query)
                    .then(r => {
                        if (this.dateInfo.untilDate == null && this.globalUntil == null) {
                            var first = r.Items[0];
                            if (first != null) {
                                this.dateInfo.untilDate = Shared.DateHelper.serverDateStringToDateTime(first.SuggestedDateTime);
                            }
                        }
                        resolve(r);
                    }).catch(e => {
                        this.toaster.error(e);
                        reject();
                    });
            });
        }

    }

    class ActionTableComponent extends MonitoringPartComponentBase {

        public controller = ActionTableController;

        public templateUrl = 'views/patient/monitoring/dashboard/searchTable.html';
    }

    remeCarePatientModule.component('rcMonitoringActionTable', new ActionTableComponent());
}