var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var RegisterDateOfDeathController = /** @class */ (function (_super) {
            __extends(RegisterDateOfDeathController, _super);
            function RegisterDateOfDeathController($scope, $translate, toaster, partyId, partyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.partyId = partyId;
                _this.partyApiSvc = partyApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            RegisterDateOfDeathController.prototype.$onInit = function () {
                this.$scope.maxDate = moment().toDate();
            };
            RegisterDateOfDeathController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.partyApiSvc.registerDateOfDeath(this.partyId, this.$scope.dateOfDeath)
                    .success(function () { return _this.$scope.$close(_this.$scope.dateOfDeath); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return RegisterDateOfDeathController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('registerDateOfDeathCtrl', function ($scope, $translate, toaster, partyId, partyApiSvc) { return new RegisterDateOfDeathController($scope, $translate, toaster, partyId, partyApiSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
