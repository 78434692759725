var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var RemeCareActionConfiguration = /** @class */ (function (_super) {
            __extends(RemeCareActionConfiguration, _super);
            function RemeCareActionConfiguration() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            RemeCareActionConfiguration.prototype.config = function () {
                this.addComponentRoute('editAction', '/Actions?actionIds', 'action', ['Views_Action', 'Views_Patient_Medication', 'Views_PatientFile'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.action,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, {
                    params: {
                        referringState: null,
                    },
                });
                this.addRoute('viewAction', '/Actions/:actionId', null, [
                    '$state',
                    '$stateParams',
                    function ($state, $stateParams) {
                        $state.transitionTo('editAction', { actionIds: $stateParams.actionId }, { location: false });
                    },
                ], ['Views_Action', 'Views_Patient_Medication', 'Views_PatientFile'], null, null, {
                    context: RemeCare.Shared.Framework.AuthContext.action,
                    right: RemeCare.Shared.Framework.AuthRight.Read,
                }, {
                    params: {
                        actionId: null,
                    },
                });
            };
            return RemeCareActionConfiguration;
        }(RemeCare.Shared.Framework.RouteConfiguration));
        angular.module('RemeCare.CarePlanAction').config(function ($stateProvider) { return new RemeCareActionConfiguration($stateProvider); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
