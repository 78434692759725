var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var TabsIdentification;
        (function (TabsIdentification) {
            TabsIdentification[TabsIdentification["ToDoByMe"] = 1] = "ToDoByMe";
            TabsIdentification[TabsIdentification["ToDoByOthers"] = 2] = "ToDoByOthers";
            TabsIdentification[TabsIdentification["CreatedByMe"] = 3] = "CreatedByMe";
            TabsIdentification[TabsIdentification["AllCareRequests"] = 4] = "AllCareRequests";
            TabsIdentification[TabsIdentification["CreatedByMyTeams"] = 5] = "CreatedByMyTeams";
        })(TabsIdentification || (TabsIdentification = {}));
        var CareRequestSearchController = /** @class */ (function () {
            function CareRequestSearchController($translate, toaster, $state, careRequestSearchSvc, gridBuilderSvc, masterdataSvc, authservice, $stateParams, dateHelper) {
                this.$translate = $translate;
                this.toaster = toaster;
                this.$state = $state;
                this.careRequestSearchSvc = careRequestSearchSvc;
                this.gridBuilderSvc = gridBuilderSvc;
                this.masterdataSvc = masterdataSvc;
                this.authservice = authservice;
                this.$stateParams = $stateParams;
                this.dateHelper = dateHelper;
            }
            CareRequestSearchController.prototype.$onInit = function () {
                var _this = this;
                this.activeTab = 0;
                // Determine available tabs
                this.tabs = [];
                this.tabs.push({
                    heading: this.$translate.instant('Views.CareRequest.Search.ToDoByMe'),
                    tabActivated: function () { return _this.tabActivated(TabsIdentification.ToDoByMe); },
                });
                if (!this.authservice.isInternalUser()) {
                    this.tabs.push({
                        heading: this.$translate.instant('Views.CareRequest.Search.CreatedByMe'),
                        tabActivated: function () { return _this.tabActivated(TabsIdentification.CreatedByMe); },
                    });
                    if (this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider) {
                        // Select the tab that you just pushed as 'ActiveTab'
                        // If care request was saved, open CreatedByMe
                        if (this.$stateParams.savedCareRequest === true) {
                            this.activeTab = this.tabs.length - 1;
                        }
                    }
                }
                if (this.authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider) {
                    this.tabs.push({
                        heading: this.$translate.instant('Views.CareRequest.Search.CreatedByMyTeams'),
                        tabActivated: function () { return _this.tabActivated(TabsIdentification.CreatedByMyTeams); },
                    });
                }
                if (this.authservice.isInternalUser()) {
                    this.tabs.push({
                        heading: this.$translate.instant('Views.CareRequest.Search.ToDoByOthers'),
                        tabActivated: function () { return _this.tabActivated(TabsIdentification.ToDoByOthers); },
                    });
                }
                if (this.authservice.isInternalUser()) {
                    this.tabs.push({
                        heading: this.$translate.instant('Views.CareRequest.Search.AllRequests'),
                        tabActivated: function () { return _this.tabActivated(TabsIdentification.AllCareRequests); },
                    });
                }
                this.buildGrid();
                this.loadDataAsync();
            };
            CareRequestSearchController.prototype.tabActivated = function (tabIdentification) {
                switch (tabIdentification) {
                    case TabsIdentification.ToDoByMe:
                        this.careRequestGrid.searchCriteria.filterTypeId =
                            RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe;
                        break;
                    case TabsIdentification.CreatedByMyTeams:
                        this.careRequestGrid.searchCriteria.filterTypeId =
                            RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMyTeams;
                        break;
                    case TabsIdentification.ToDoByOthers:
                        this.careRequestGrid.searchCriteria.filterTypeId =
                            RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers;
                        break;
                    case TabsIdentification.CreatedByMe:
                        this.careRequestGrid.searchCriteria.filterTypeId =
                            RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.RequestedByMe;
                        break;
                    default:
                        this.careRequestGrid.searchCriteria.filterTypeId = undefined; // Not a special filter type
                        if (this.careRequestGrid.searchCriteria.creationDateFrom === undefined) {
                            this.careRequestGrid.searchCriteria.creationDateFrom = this.dateHelper.subtractDuration(this.dateHelper.today(), { Quantity: 3, Unit: RemeCare.Shared.Contract.Code.DurationUnit.Months });
                        }
                        break;
                }
                this.careRequestGrid.search();
            };
            CareRequestSearchController.prototype.createCareRequest = function (therapy) {
                this.$state.go('careRequests.careRequest', { therapyId: therapy.Id });
            };
            CareRequestSearchController.prototype.fixStatus = function () {
                return (this.careRequestGrid.searchCriteria.filterTypeId ===
                    RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe ||
                    this.careRequestGrid.searchCriteria.filterTypeId ===
                        RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers);
            };
            CareRequestSearchController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, Promise.all([
                                        this.careRequestSearchSvc.getPossibleTherapiesAsync(),
                                        this.masterdataSvc.findCareRequestFilterTypesAsync(),
                                    ])];
                            case 1:
                                _a = _b.sent(), this.therapies = _a[0], this.filterTypes = _a[1];
                                this.ongoingTranslation = this.filterTypes.filter(function (ft) { return ft.Id === RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.OnGoing; })[0].Text;
                                // Remove items handled by tabs
                                this.filterTypes = this.filterTypes.filter(function (ft) {
                                    return ft.Id !== RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByOthers &&
                                        ft.Id !== RemeCare.Shared.Contract.Code.CareRequestFilterTypeCode.HandleByMe;
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            CareRequestSearchController.prototype.canShowNavigationButton = function (cr) {
                return cr.patientId !== null && cr.canSeePatientFile === true;
            };
            CareRequestSearchController.prototype.buildGrid = function () {
                var _this = this;
                this.careRequestGrid = this.gridBuilderSvc
                    .createGridBuilder(function (page, pageSize, sortField, sortDirection, criteria) {
                    return _this.executeSearchAsync(page, pageSize, sortField, sortDirection, criteria);
                })
                    .addDateColumn('creationDate', 'Views.CareRequest.Search.CreationDate', 'short')
                    .addColumn('patientName', 'Views.CareRequest.Search.PatientName')
                    .addColumn('therapy.Text', 'General.CarePlan')
                    .addConditionallyStyledColumn('startDate', 'General.StartDate', "withinOneDay", { red: true }, { cellFilter: 'date: "shortDate"' })
                    .addColumn('createdBy', 'Views.CareRequest.Search.CreatedBy')
                    .addColumn('status', 'General.Status')
                    .addConditionalNavigationButtonAndEditButtonColumn(this.canShowNavigationButton, function (cr) { return cr.readOnly; }, 'patients.patientfile', {
                    patientId: 'patientId',
                }, 'careRequests.careRequest', {
                    careRequestId: 'id',
                    therapyId: 'therapy.Id',
                })
                    .build();
                this.careRequestGrid.pagingOptions.pageSize = 20;
            };
            CareRequestSearchController.prototype.executeSearchAsync = function (page, pageSize, sortField, sortDirection, criteria) {
                return __awaiter(this, void 0, void 0, function () {
                    var query, data, items, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                sortField = sortField || 'creationDate';
                                sortDirection = sortDirection || 'desc';
                                query = {
                                    pageSize: pageSize,
                                    page: page,
                                    sortField: sortField,
                                    sortOrder: sortDirection,
                                    filterType: criteria.filterTypeId,
                                    status: criteria.statusId,
                                    therapyId: criteria.therapyId,
                                    firstNamePatient: criteria.patientFirstName,
                                    lastNamePatient: criteria.patientLastName,
                                    firstNameRequestor: criteria.requestorFirstName,
                                    lastNameRequestor: criteria.requestorLastName,
                                    creationDateUntil: criteria.creationDateUntil
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.creationDateUntil)
                                        : criteria.creationDateUntil,
                                    creationDateFrom: criteria.creationDateFrom
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.creationDateFrom)
                                        : criteria.creationDateFrom,
                                    startDateFrom: criteria.startDateFrom
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.startDateFrom)
                                        : criteria.startDateFrom,
                                    startDateUntil: criteria.startDateUntil
                                        ? RemeCare.Shared.DateHelper.toServerDateString(criteria.startDateUntil)
                                        : criteria.startDateUntil,
                                };
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.careRequestSearchSvc.searchCareRequestsAsync(query)];
                            case 2:
                                data = _a.sent();
                                items = _(data.Items).map(function (serverObject) {
                                    return {
                                        id: serverObject.Id,
                                        therapy: serverObject.Therapy,
                                        creationDate: serverObject.CreationDate,
                                        patientName: [serverObject.PatientLastName, serverObject.PatientFirstName].join(' '),
                                        patientId: serverObject.PatientId,
                                        canSeePatientFile: serverObject.CanSeePatientFile,
                                        startDate: serverObject.StartDate,
                                        createdBy: serverObject.CreatedBy,
                                        status: serverObject.Status.Id !== RemeCare.Shared.Contract.Code.CareRequestStatus.Final &&
                                            serverObject.Status.Id !== RemeCare.Shared.Contract.Code.CareRequestStatus.Cancelled
                                            ? _this.$translate.instant('Views.CareRequest.Search.CurrentStep', {
                                                currentStep: serverObject.CurrentStep,
                                                totalSteps: serverObject.TotalSteps,
                                            })
                                            : serverObject.Status.Text,
                                        readOnly: serverObject.ReadOnly,
                                        withinOneDay: (moment(RemeCare.Shared.DateHelper.tomorrow()).isAfter(RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.StartDate)) ||
                                            moment(RemeCare.Shared.DateHelper.tomorrow()).isSame(RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.StartDate), 'day')) &&
                                            (serverObject.Status.Id === RemeCare.Shared.Contract.Code.CareRequestStatus.Open ||
                                                serverObject.Status.Id === RemeCare.Shared.Contract.Code.CareRequestStatus.ToValidate),
                                        requestorName: '',
                                    };
                                });
                                this.searchCollapsed = true;
                                return [2 /*return*/, {
                                        Items: items,
                                        Total: data.Total,
                                    }];
                            case 3:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            return CareRequestSearchController;
        }());
        CareRequest.remeCareCareRequestModule.component('careRequestSearch', {
            controller: CareRequestSearchController,
            templateUrl: 'views/careRequest/careRequestsearch.html',
        });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
