module RemeCare.Shared.Directive {
    import IFindOrganisationsQuery = RemeCare.Contract.Party.Read.Query.IFindOrganisationsQuery;
    import IOrganisation = RemeCare.Contract.Party.Read.IOrganisation;

    class OrganisationSimpleSearchController implements ng.IComponentController {
        constructor(
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly toaster: Shared.Framework.Toaster
        ) {}

        public $onInit(): void {}

        public async searchParties(name: string): Promise<IOrganisation[]> {
            try {
                const searchResult = await this.partyApiSvc.findOrganisationsAsync({
                    page: 1,
                    pageSize: 20,
                    name: name
                } as IFindOrganisationsQuery);
                return searchResult.Items;
            } catch (e) {
                this.toaster.error(e);
                throw e;
            }
        }

        public getDisplayText(organisation: IOrganisation): string {
            return organisation && organisation.Name;
        }
    }

    remeCareAppModule.component('rcOrganisationSimpleSearch', {
        bindings: {
            ngModel: '=',
            label: '@'
        },
        controller: OrganisationSimpleSearchController,
        templateUrl: 'shared/components/organisationSimpleSearch/organisationSimpleSearch.html'
    });
}
