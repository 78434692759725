var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        Management.remeCareManagementModule.component('rcPossibleExternalIdentityProvider', {
            bindings: {
                provider: '<'
            },
            templateUrl: 'views/management/persons/userDetails/possibleExternalIdentityProvider.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
