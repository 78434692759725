var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileCarePlanActionsController = /** @class */ (function (_super) {
            __extends(PatientFileCarePlanActionsController, _super);
            function PatientFileCarePlanActionsController($scope, $translate, toaster, $stateParams, carePlanApiSvc, modalBuilderFactory, gridBuilderSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                return _this;
            }
            PatientFileCarePlanActionsController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                if (this.$scope.carePlan) {
                    this.getPersonalisableStructuralTherapyActions();
                }
                this.buildActionsGrid();
                this.$scope.$watch('carePlan', function (newValue, oldValue) {
                    if (newValue !== oldValue && newValue !== null) {
                        _this.getPersonalisableStructuralTherapyActions();
                    }
                });
            };
            PatientFileCarePlanActionsController.prototype.updateIfPersonalisedList = function (stas) {
                return __awaiter(this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Promise.all(_(stas).map(function (sta) { return _this.updateIfPersonalised(sta); }))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileCarePlanActionsController.prototype.updateIfPersonalised = function (sta) {
                return __awaiter(this, void 0, void 0, function () {
                    var pta, nowOrFuture, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getPersonalisedActionAsync(this.$scope.carePlan.id, sta.Id)];
                            case 1:
                                pta = _a.sent();
                                nowOrFuture = _.filter(pta.ConfiguredTherapyActions, function (cta) {
                                    if (!cta.ValidUntilDate) {
                                        // Valid until infinity
                                        return true;
                                    }
                                    var until = RemeCare.Shared.DateHelper.serverDateStringToDate(cta.ValidUntilDate);
                                    return moment(RemeCare.Shared.DateHelper.today()).isSameOrBefore(until);
                                });
                                sta.IsPersonalised = _(nowOrFuture).any(function (cta) { return cta.IsPersonalised; });
                                sta.IsRemovedFromCarePlan = _(nowOrFuture).any(function (cta) { return cta.IsRemovedFromCarePlan; });
                                sta.IsPeriodicitiesPersonalised = _(nowOrFuture).any(function (cta) { return cta.IsPeriodicitiesPersonalised; });
                                sta.IsIntradayTimingPersonalised = _(nowOrFuture).any(function (cta) { return cta.IsIntradayTimingPersonalised; });
                                sta.HasPersonalisedActionParts = _(nowOrFuture).any(function (cta) { return cta.HasPersonalisedActionParts; });
                                sta.IsDeviationPlanningPersonalised = _(nowOrFuture).any(function (cta) {
                                    return (cta.DurationOfAction != null || cta.PossibleActionDurationOptionId != null) &&
                                        cta.IsPersonalised;
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileCarePlanActionsController.prototype.getPersonalisableStructuralTherapyActions = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.carePlanApiSvc.getPersonalisableStructuralTherapyActionsAsync(this.$scope.carePlan.id)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.therapyActions = _(ags).map(function (sta) { return sta; });
                                // Don't await this, it can be updated in the background
                                this.updateIfPersonalisedList(this.$scope.therapyActions);
                                this.setActionsGridData();
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.errorCallback(e_2);
                                return [3 /*break*/, 3];
                            case 3:
                                this.$scope.editRight = this.$scope.carePlan.statusOpen;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileCarePlanActionsController.prototype.buildActionsGrid = function () {
                var _this = this;
                var template = "<div ng-if= \"row.entity.IsDeviationPlanningPersonalised\" class=\"text-center vertical-center\"> <span class=\"fa-stack fa-sm\" > <i class=\"fa fa-calendar-o fa-stack-2x\" style=\"font-size:17px; margin-top:4px;\"></i> <i class=\"fa fa-clock-o fa-stack-1x\" style=\"font-size:12px; margin:4px\"></i></span></div>";
                this.$scope.actionsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('StructuralTherapyActionName', 'General.Name')
                    .addIconColumn('Views.PatientFile.CarePlans.PersonalisedExcluded', function (cta) { return (cta.IsRemovedFromCarePlan ? 'eye-slash' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addIconColumn('Views.PatientFile.CarePlans.PeriodicityPersonalised', function (cta) { return (cta.IsPeriodicitiesPersonalised ? 'calendar' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addIconColumn('Views.PatientFile.CarePlans.IntradayTimingPersonalised', function (cta) { return (cta.IsIntradayTimingPersonalised ? 'clock-o' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addCustomColumn('Views.PatientFile.CarePlans.Planning', template, function () { }, { width: '100' })
                    .addIconColumn('Views.PatientFile.CarePlans.HasPersonalisedActionParts', function (cta) { return (cta.HasPersonalisedActionParts ? 'th-large' : ''); }, null, RemeCare.Shared.Framework.Grid.IconSize.small)
                    .addConditionalShowEditButtonWithPromiseFunctionColumn(function (v) { return _this.editAction(v); }, function (v) { return _this.editAction(v); }, function (v) { return v.ReadOnly || !_this.$scope.editRight; })
                    .setSorting(false)
                    .build();
                this.setActionsGridData();
            };
            PatientFileCarePlanActionsController.prototype.setActionsGridData = function () {
                this.$scope.actionsGrid.setData(this.$scope.therapyActions);
            };
            PatientFileCarePlanActionsController.prototype.editAction = function (action) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('personalisedActionController')
                        .setTemplateUrl('views/patient/carePlans/carePlanActions/personalisedAction.html')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                        .setScope({
                        carePlanId: _this.$scope.carePlan.id,
                        carePlanStartDate: _this.$scope.carePlan.startDate,
                        structuralTherapyAction: action,
                        readOnly: action.ReadOnly || !_this.$scope.editRight,
                    })
                        .setResultCallBack(function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.updateIfPersonalised(action)];
                                case 1:
                                    _a.sent();
                                    resolve(action);
                                    return [2 /*return*/];
                            }
                        });
                    }); })
                        .build();
                });
            };
            return PatientFileCarePlanActionsController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular
            .module('RemeCare.Patient')
            .controller('PatientFileCarePlanActionsCtrl', PatientFileCarePlanActionsController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
