module RemeCare.Inbox {

    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;

    class RemeCareInboxConfiguration extends Shared.Framework.RouteConfiguration {

        protected config(): void {
            this.addComponentRoute('inbox', '/Inbox',
                'inbox',
                ['Views_Inbox', 'Views_Alarms'], null, null, {
                    context: AuthContext.inbox,
                    right: AuthRight.Read
                });

            this.addRoute('inbox.alarmdetail', '/Alarms/:alarmId',
                'views/inbox/alarms/detail.html',
                'inboxAlarmDetailCtrl',
                ['Views_Inbox', 'Views_Alarms'], null, null, {
                    context: AuthContext.inboxAlarms,
                    right: AuthRight.Read
                }, {
                    params: {
                        alarmId: null
                    }
                });
        }
    }

    remeCareInboxModule.config(($stateProvider) =>
        new RemeCareInboxConfiguration($stateProvider));
}