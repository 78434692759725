var RemeCare;
(function (RemeCare) {
    var Framework;
    (function (Framework) {
        var BackButtonController = /** @class */ (function () {
            function BackButtonController($window) {
                this.$window = $window;
            }
            BackButtonController.prototype.$onInit = function () {
                var _this = this;
                if (!this.goBack) {
                    this.goBack = function () {
                        _this.$window.history.back();
                    };
                }
            };
            return BackButtonController;
        }());
        RemeCare.remeCareAppModule.component('rcBackButton', {
            controller: BackButtonController,
            controllerAs: 'backCtrl',
            templateUrl: 'views/framework/backButton.html',
            bindings: {
                text: '@',
                goBack: '&?'
            }
        });
    })(Framework = RemeCare.Framework || (RemeCare.Framework = {}));
})(RemeCare || (RemeCare = {}));
