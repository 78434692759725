var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var DefaultCarePlanActionController = /** @class */ (function (_super) {
            __extends(DefaultCarePlanActionController, _super);
            function DefaultCarePlanActionController($scope, $translate, toaster, $rootScope, $state, $dialog, $window, carePlanActionApiSvc, modalBuilderFactory) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$rootScope = $rootScope;
                _this.$state = $state;
                _this.$dialog = $dialog;
                _this.$window = $window;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.$scope.registerFunctions({
                    functions: {
                        register: function (permanent) { return _this.registerAsync(permanent); },
                        cancelRegistrations: function () { return _this.cancelRegistrationsAsync(); },
                        cancel: function () { return _this.cancel(); },
                        hasPhoneCall: function () { return _this.hasPhoneCall(); },
                        planNewContact: function (i) { return _this.planNewContact(i); },
                    },
                });
                return _this;
            }
            DefaultCarePlanActionController.prototype.$onInit = function () { };
            DefaultCarePlanActionController.prototype.canEdit = function () {
                return this.$scope.action.canEditAction;
            };
            DefaultCarePlanActionController.prototype.makeRegistrationAsync = function (params, errorCallback) {
                return this.carePlanActionApiSvc.makeRegistrationsAsync(params, errorCallback);
            };
            DefaultCarePlanActionController.prototype.cancel = function () {
                if (this.$scope.cancelledCallback) {
                    this.$scope.cancelledCallback();
                }
            };
            DefaultCarePlanActionController.prototype.cancelRegistrationsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var result, carePlanActionIds, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.$dialog.messageBox(this.$translate.instant('Views.Action.General.CancelRegistrations'), this.$translate.instant('Views.Action.General.CancelRegistrationsConfirm'), [
                                    { result: 'cancel', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                                    { result: 'yes', label: this.$translate.instant('General.Yes'), cssClass: 'btn-primary' },
                                ])];
                            case 1:
                                result = _a.sent();
                                if (result === 'cancel') {
                                    return [2 /*return*/];
                                }
                                carePlanActionIds = _.map(this.$scope.action.flattenActions(), function (carePlanAction) {
                                    return carePlanAction.carePlanActionId;
                                });
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, 4, , 5]);
                                return [4 /*yield*/, this.carePlanActionApiSvc.cancelCarePlanActionsAsync(carePlanActionIds)];
                            case 3:
                                _a.sent();
                                this.toaster.success(this.$translate.instant('Views.Action.General.CancelRegistrationsConfirmed'));
                                this.callBackFunction();
                                return [3 /*break*/, 5];
                            case 4:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            DefaultCarePlanActionController.prototype.callBackFunction = function () {
                if (this.$scope.registeredCallback && _.isFunction(this.$scope.registeredCallback)) {
                    var callBackFunc = this.$scope.registeredCallback();
                    if (_.isFunction(callBackFunc)) {
                        callBackFunc(this.$scope.action);
                    }
                }
            };
            DefaultCarePlanActionController.prototype.registerAsync = function (permanent, reschedule) {
                return __awaiter(this, void 0, void 0, function () {
                    var params, e_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (this.$scope.readOnly) {
                                    return [2 /*return*/];
                                }
                                this.$scope.registrationForm.$setSubmitted();
                                this.$scope.showErrors = true;
                                if (permanent && this.$scope.registrationForm.$invalid) {
                                    this.showValidationErrorMessage();
                                    return [2 /*return*/];
                                }
                                params = this.$scope.action.toServerWrite(permanent);
                                params.reschedule = reschedule;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.makeRegistrationAsync(params, function (e) { return _this.makeRegistrationErrorCallback(e); })];
                            case 2:
                                _a.sent();
                                if (permanent) {
                                    this.toaster.success(this.$translate.instant('Views.Action.General.RegistrationSuccess'));
                                    this.$scope.action.status = RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed;
                                }
                                else if (reschedule) {
                                    this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                                }
                                else {
                                    this.toaster.success(this.$translate.instant('Views.Action.General.RegistrationTemporarySaved'));
                                }
                                this.callBackFunction();
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _a.sent();
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            DefaultCarePlanActionController.prototype.makeRegistrationErrorCallback = function (httpError) {
                this.toaster.error(httpError.data);
                this.$rootScope.$broadcast('actionSaveError', httpError.headers('X-Errors'));
            };
            DefaultCarePlanActionController.prototype.hasPhoneCall = function () {
                return this.getPhoneCallComponent() != null;
            };
            DefaultCarePlanActionController.prototype.getPhoneCallComponent = function () {
                return _.find(this.$scope.action.components, function (c) { return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning; });
            };
            DefaultCarePlanActionController.prototype.planNewContact = function (includeParts) {
                var _this = this;
                var phoneCall = this.getPhoneCallComponent();
                if (!phoneCall) {
                    return;
                }
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('planNewContactCtrl')
                    .setTemplateUrl('views/carePlanAction/contact/planNewContactModal.html')
                    .setScope({
                    remark: phoneCall.value,
                    hasTemporarySave: includeParts,
                    actionName: this.$scope.action.name,
                })
                    .setResultCallBack(function (r) {
                    if (!includeParts) {
                        _this.$scope.action.components = _(_this.$scope.action.components).filter(function (c) {
                            return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning ||
                                c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.AberrantRegistrations;
                        });
                    }
                    phoneCall.value = r.remark;
                    _this.registerAsync(false, {
                        from: r.from,
                        until: r.until,
                        type: RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning,
                        reason: r.remark,
                        durationOfAction: null,
                        possibleActionDurationOptionId: null,
                    });
                })
                    .build();
            };
            return DefaultCarePlanActionController;
        }(RemeCare.Shared.Framework
            .ControllerBase));
        CarePlanAction.DefaultCarePlanActionController = DefaultCarePlanActionController;
        CarePlanAction.remeCareCarePlanActionModule.controller('defaultCarePlanActionCtrl', DefaultCarePlanActionController);
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
