var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var MedicationSchemaController = /** @class */ (function (_super) {
            __extends(MedicationSchemaController, _super);
            // @ngInject
            function MedicationSchemaController($scope, $timeout, guidanceApiSvc) {
                var _this = _super.call(this, $scope, guidanceApiSvc) || this;
                _this.$scope = $scope;
                _this.$timeout = $timeout;
                _this.$scope.prescriptionChanged = function (p) { return _this.prescriptionChanged(p); };
                _this.$scope.prescriptionDeleted = function (p) { return _this.prescriptionDeleted(p); };
                return _this;
            }
            MedicationSchemaController.prototype.init = function () {
                var _this = this;
                this.$scope.$on('actionSaveError', function (event, data) {
                    if ((_.isString(data) && data === 'Care.Validation.Medication.SchemaModified') ||
                        (_.isArray(data) && _(data).any(function (d) { return d === 'Care.Validation.Medication.SchemaModified'; }))) {
                        // make the medication schema read only and then editable again
                        // so the prescription overview directive will re-initialize
                        _this.$scope.readOnly = true;
                        _this.$timeout(function () {
                            _this.$scope.readOnly = false;
                        }, 0, true);
                    }
                });
            };
            MedicationSchemaController.prototype.prescriptionChanged = function (prescription) {
                this.$scope.component.addOrReplace(prescription);
            };
            MedicationSchemaController.prototype.prescriptionDeleted = function (prescription) {
                this.$scope.component.delete(prescription);
            };
            return MedicationSchemaController;
        }(CarePlanAction.ComponentControllerBase));
        var MedicationSchemaDirective = /** @class */ (function (_super) {
            __extends(MedicationSchemaDirective, _super);
            function MedicationSchemaDirective() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.templateUrl = 'views/carePlanAction/directive/medicationSchema.html';
                _this.controller = MedicationSchemaController;
                return _this;
            }
            return MedicationSchemaDirective;
        }(CarePlanAction.ComponentDirectiveBase));
        CarePlanAction.remeCareCarePlanActionModule.directive('rcMedicationSchema', function () { return new MedicationSchemaDirective(); });
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
