var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ApplicationProfileType = RemeCare.Shared.Contract.Code.ApplicationProfileType;
        var PersonalInfoController = /** @class */ (function (_super) {
            __extends(PersonalInfoController, _super);
            function PersonalInfoController($scope, $translate, toaster, $stateParams, $state, patientSvc, modalBuilderFactory, gridBuilderSvc, authservice, partyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.$state = $state;
                _this.patientSvc = patientSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.authservice = authservice;
                _this.partyApiSvc = partyApiSvc;
                $scope.showPhoneNumbers = function () { return _this.showPhoneNumbers(); };
                $scope.showMobileNumbers = function () { return _this.showMobileNumbers(); };
                $scope.showEmails = function () { return _this.showEmails(); };
                $scope.showAddresses = function () { return _this.showAddresses(); };
                $scope.edit = function () { return _this.edit(); };
                $scope.saveRemark = function () { return _this.saveRemark(); };
                $scope.reload = function () { return _this.reload(); };
                $scope.savePatient = function (p) { return _this.savePatient(p); };
                return _this;
            }
            PersonalInfoController.prototype.$onInit = function () {
                this.$scope.editInfo = {
                    editMode: false
                };
                var appProfile = this.authservice.getClaim(RemeCare.Shared.Framework.ClaimTypes.applicationProfile);
                this.$scope.editDisabled = (appProfile !== ApplicationProfileType.Superuser.toString())
                    && (appProfile !== ApplicationProfileType.AdministrativeManager.toString())
                    && (appProfile !== ApplicationProfileType.CareManager.toString());
                this.$scope.maxDate = moment().toDate();
                this.reload();
            };
            PersonalInfoController.prototype.reload = function () {
                var _this = this;
                this.patientSvc.getPatient(this.$stateParams.patientId)
                    .success(function (r) { return _this.$scope.person = new RemeCare.Model.PatientPerson(r); })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            PersonalInfoController.prototype.edit = function () {
                this.$scope.editInfo.person = this.copy(this.$scope.person);
                this.$scope.editInfo.editMode = true;
            };
            PersonalInfoController.prototype.buildPhoneNumbersGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('toString()', 'General.Number')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInfoController.prototype.buildEmailGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('email', 'General.Email')
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInfoController.prototype.buildAddressGrid = function () {
                return this.gridBuilderSvc
                    .createGridBuilder()
                    .addEnumColumn('contactPointUsageType', 'General.Type', RemeCare.Shared.Framework.ReferenceDataTypes.contactPointUsageType)
                    .addColumn('addressLine1', 'General.Address')
                    .addColumn('zipCode', 'General.Zipcode')
                    .addColumn('city', 'General.City')
                    .addEnumColumn('country', 'General.Country', RemeCare.Shared.Framework.ReferenceDataTypes.country)
                    .addDateColumn('validFromDate', 'General.ValidFrom', 'shortDate')
                    .addDateColumn('validUntilDate', 'General.ValidUntilIncluding', 'shortDate')
                    .addColumn('remark', 'General.Remark')
                    .setExternalSorting(false)
                    .build();
            };
            PersonalInfoController.prototype.showPhoneNumbers = function () {
                var grid = this.buildPhoneNumbersGrid();
                grid.setData(this.$scope.person.telephoneNumbers);
                this.showContactPointsModal('Views.PatientFile.DetailPhone', grid);
            };
            PersonalInfoController.prototype.showMobileNumbers = function () {
                var grid = this.buildPhoneNumbersGrid();
                grid.setData(this.$scope.person.mobileNumbers);
                this.showContactPointsModal('Views.PatientFile.DetailMobile', grid);
            };
            PersonalInfoController.prototype.showEmails = function () {
                var grid = this.buildEmailGrid();
                grid.setData(this.$scope.person.emailAddresses);
                this.showContactPointsModal('Views.PatientFile.DetailEmail', grid);
            };
            PersonalInfoController.prototype.showAddresses = function () {
                var grid = this.buildAddressGrid();
                grid.setData(this.$scope.person.addresses);
                this.showContactPointsModal('Views.PatientFile.DetailAddresses', grid);
            };
            PersonalInfoController.prototype.showContactPointsModal = function (title, grid) {
                this.modalBuilderFactory.createModalBuilder()
                    .setTemplateUrl('views/shared/contactPointDetail.html')
                    .setScope({
                    title: title,
                    grid: grid
                })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            PersonalInfoController.prototype.saveRemark = function () {
                var _this = this;
                this.patientSvc.saveInternalRemark(this.$scope.editInfo.person.id, this.$scope.editInfo.person.remark)
                    .success(function () {
                    _this.$scope.person.remark = _this.$scope.editInfo.person.remark;
                    _this.generalSuccess();
                })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            PersonalInfoController.prototype.savePatient = function (person) {
                var _this = this;
                this.partyApiSvc.modifyPersonalInfo(person.partyId, person.getPersonalInfo())
                    .success(function () { return _this.$state.reload(); })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            return PersonalInfoController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Patient.remeCarePatientModule.controller('patientInfoPersonalInfoCtrl', PersonalInfoController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
