namespace RemeCare.Model {
    // tslint:disable: no-bitwise
    export class RemarkRegistration {
        public actionId: Shared.Contract.Guid;
        public actionName: string;
        public executionDateTime: Date;
        public groups: RemarkGroup[];
        public readOnly: boolean;

        public title: string;

        constructor(serverObject: Contract.CarePlanAction.Read.IRemarkRegistration, isSuperUser: boolean) {
            this.actionId = serverObject.ActionId;
            this.actionName = serverObject.ActionName;
            this.executionDateTime = Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);

            this.groups = _.chain(serverObject.Remarks)
                .groupBy(r => r.TherapyActionPartId)
                .map(r => new RemarkGroup(r[0], _.map(r, rr => new Remark(rr))))
                .value();
            this.readOnly = serverObject.ReadOnly;
            this.title = `${Shared.DateHelper.dateTimeHumanReadible(this.executionDateTime)}: ${this.actionName}`;
        }
    }
}
