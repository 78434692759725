module RemeCare.Shared.Directive {

    export interface ITelephoneNumber {
        countryPrefix: string;
        areaCode: string;
        localNumber: string;
        toString(): string;
        isValid(): boolean;
    }
    
    interface ITelephoneNumberScope extends ng.IScope {
        telephoneNumber: ITelephoneNumber;
        showErrors: boolean;
        showUnrequired: boolean;
        required: boolean;
        readOnly: boolean;
        label: string;
        valid: boolean;
        formCtrl: Shared.Framework.Directive.IFormController;
        validate: () => void;
    }

    class TelephoneNumberDirective implements ng.IDirective {
        
        public restrict = 'E';

        public scope: { [boundProperty: string]: string } = {
            telephoneNumber: '=?',
            showErrors: '=',
            showUnrequired: '=',
            required: '=ngRequired',
            readOnly: '=',
            label: '@'
        }

        require: '^form';

        public templateUrl: string = 'views/shared/telephoneNumber.html';

        public link: ng.IDirectiveLinkFn = (scope: ITelephoneNumberScope, element, attrs, formCtrl: Shared.Framework.Directive.IFormController) => {
            scope.formCtrl = formCtrl;
            scope.validate = () => {
                scope.valid = scope.telephoneNumber && scope.telephoneNumber.isValid();
            }
            scope.validate();
        }
    }

    remeCareAppModule.directive('rcTelephoneNumber', () => new TelephoneNumberDirective());
} 