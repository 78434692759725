var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var CarePlan = RemeCare.Model.Careplan;
        var CarePlanStatus = RemeCare.Shared.Contract.Code.CarePlanStatus;
        var DateRangeFilterCode = RemeCare.Contract.Core.Codes.DateRangeFilterCode;
        var PatientFileMonitoringController = /** @class */ (function () {
            function PatientFileMonitoringController($translate, carePlanApiSvc, loadMeasuringSvc, masterdataSvc, monitoringPartFactory, monitoringPartMergeSvc, patientSvc, toaster) {
                this.$translate = $translate;
                this.carePlanApiSvc = carePlanApiSvc;
                this.loadMeasuringSvc = loadMeasuringSvc;
                this.masterdataSvc = masterdataSvc;
                this.monitoringPartFactory = monitoringPartFactory;
                this.monitoringPartMergeSvc = monitoringPartMergeSvc;
                this.patientSvc = patientSvc;
                this.toaster = toaster;
            }
            PatientFileMonitoringController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, open_1, closed_1, e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.dateInfo = {
                                    fromDate: null,
                                    untilDate: null,
                                    range: null,
                                };
                                this.dateFilterOptions = [
                                    {
                                        amountOfTime: 7,
                                        translateKey: 'Views.PatientFile.Monitoring.LastWeek',
                                        translateLongKey: 'Views.PatientFile.Monitoring.LastWeekLong',
                                        unitOfTime: 'days',
                                        value: DateRangeFilterCode.LastWeek,
                                    },
                                    {
                                        amountOfTime: 14,
                                        translateKey: 'Views.PatientFile.Monitoring.LastTwoWeeks',
                                        translateLongKey: 'Views.PatientFile.Monitoring.LastTwoWeeksLong',
                                        unitOfTime: 'days',
                                        value: DateRangeFilterCode.LastTwoWeeks,
                                    },
                                    {
                                        amountOfTime: 1,
                                        translateKey: 'Views.PatientFile.Monitoring.LastMonth',
                                        translateLongKey: 'Views.PatientFile.Monitoring.LastMonthLong',
                                        unitOfTime: 'month',
                                        value: DateRangeFilterCode.LastMonth,
                                    },
                                    {
                                        amountOfTime: 3,
                                        translateKey: 'Views.PatientFile.Monitoring.LastThreeMonths',
                                        translateLongKey: 'Views.PatientFile.Monitoring.LastThreeMonthsLong',
                                        unitOfTime: 'months',
                                        value: DateRangeFilterCode.LastThreeMonths,
                                    },
                                    {
                                        amountOfTime: 1,
                                        translateKey: 'Views.PatientFile.Monitoring.LastYear',
                                        translateLongKey: 'Views.PatientFile.Monitoring.LastYearLong',
                                        unitOfTime: 'year',
                                        value: DateRangeFilterCode.LastYear,
                                    },
                                ];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.patientSvc.getCarePlansAsync(this.patientId)];
                            case 2:
                                ags = _a.sent();
                                open_1 = _.chain(ags)
                                    .filter(function (x) { return x.Status.Id === CarePlanStatus.Open; })
                                    .map(function (x) { return new CarePlan(x); })
                                    .sortBy(function (x) { return x.startDate; })
                                    .value();
                                closed_1 = _.chain(ags)
                                    .filter(function (x) { return x.Status.Id !== CarePlanStatus.Open; })
                                    .map(function (x) { return new CarePlan(x); })
                                    .sortBy(function (x) { return x.statusChangedDate; })
                                    .value();
                                this.carePlans = open_1.concat(closed_1);
                                this.carePlanSummary = _(this.carePlans).map(function (x) {
                                    return {
                                        Id: x.id,
                                        Text: x.statusOpen
                                            ? x.name +
                                                ("<span class=\"text-nowrap\">" + _this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStartDate() }) + "</span>")
                                            : x.name +
                                                ("<span class=\"text-nowrap\">" + _this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStatusChangedDate() }) + "</span>"),
                                    };
                                });
                                this.addAllCarePlansOption();
                                this.carePlanSelected(_.find(this.carePlanSummary, function (cp) { return cp.Id == null; }), true);
                                return [3 /*break*/, 4];
                            case 3:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMonitoringController.prototype.toggleDateEdit = function () {
                this.dateEdit = !this.dateEdit;
            };
            PatientFileMonitoringController.prototype.carePlanSelected = function (carePlan, initial) {
                return __awaiter(this, void 0, void 0, function () {
                    var carePlans, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!initial) {
                                    this.loadMeasuringSvc.reset();
                                }
                                this.carePlan = carePlan;
                                carePlans = [];
                                if (carePlan == null || carePlan.Id == null) {
                                    carePlans = _(this.carePlans).map(function (cp) {
                                        return {
                                            Id: cp.id,
                                            Text: cp.name,
                                        };
                                    });
                                }
                                else {
                                    carePlans.push(carePlan);
                                }
                                if (!(carePlans.length !== 0)) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.loadCarePlanDetails(carePlans)];
                            case 1:
                                _b.sent();
                                _a = this;
                                return [4 /*yield*/, this.loadMonitoringParts(carePlans)];
                            case 2:
                                _a.carePlanMonitoringParts = _b.sent();
                                _b.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMonitoringController.prototype.fromChanged = function () {
                this.loadMeasuringSvc.reset();
                this.dateInfo.range = null;
                if (this.dateInfo.fromDate && !this.dateInfo.untilDate) {
                    this.dateInfo.untilDate = moment
                        .min(moment(this.dateInfo.fromDate).add(3, 'months'), moment(RemeCare.Shared.DateHelper.today()))
                        .toDate();
                }
            };
            PatientFileMonitoringController.prototype.untilChanged = function () {
                this.loadMeasuringSvc.reset();
                this.dateInfo.range = null;
            };
            PatientFileMonitoringController.prototype.clearFromDate = function () {
                this.loadMeasuringSvc.stopMeasuring();
                this.dateInfo.fromDate = null;
                this.dateInfo.range = null;
            };
            PatientFileMonitoringController.prototype.clearUntilDate = function () {
                this.loadMeasuringSvc.stopMeasuring();
                this.dateInfo.untilDate = null;
                this.dateInfo.range = null;
            };
            PatientFileMonitoringController.prototype.setDateInfo = function (range) {
                this.dateInfo.range = range;
                this.loadMeasuringSvc.reset();
                this.dateInfo.fromDate = moment(RemeCare.Shared.DateHelper.today())
                    .subtract(this.dateInfo.range.amountOfTime, this.dateInfo.range.unitOfTime)
                    .toDate();
                this.dateInfo.untilDate = RemeCare.Shared.DateHelper.today();
            };
            PatientFileMonitoringController.prototype.addAllCarePlansOption = function () {
                this.carePlanSummary.splice(0, 0, {
                    Id: null,
                    Text: this.$translate.instant('Views.PatientFile.AllCarePlans'),
                });
            };
            PatientFileMonitoringController.prototype.loadCarePlanDetails = function (carePlans) {
                return __awaiter(this, void 0, void 0, function () {
                    var carePlanIds, _a, e_2;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                carePlanIds = _.map(carePlans, function (cp) { return cp.Id; });
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                _a = this;
                                return [4 /*yield*/, this.carePlanApiSvc.getCarePlanParameterValuesAsync(carePlanIds)];
                            case 2:
                                _a.carePlanDetails = _b.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                e_2 = _b.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMonitoringController.prototype.loadMonitoringParts = function (carePlans) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, therapyIds, query, groups, therapyMonitoringParts, carePlanMonitoringParts, monitoringPartsList, e_3;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 3, , 4]);
                                therapyIds = _.filter(this.carePlans, function (cp) {
                                    return _.any(carePlans, function (icp) { return icp.Id === cp.id; });
                                }).map(function (cp) { return cp.therapyId; });
                                query = {
                                    therapyIds: therapyIds,
                                };
                                groups = void 0;
                                therapyMonitoringParts = void 0;
                                return [4 /*yield*/, Promise.all([
                                        this.masterdataSvc.getMonitoringPartGroupsAsync(),
                                        this.patientSvc.getMonitoringConfigurationAsync(this.patientId, query),
                                    ])];
                            case 1:
                                _a = _b.sent(), groups = _a[0], therapyMonitoringParts = _a[1];
                                return [4 /*yield*/, Promise.all(_.map(therapyMonitoringParts, function (tmp) {
                                        try {
                                            return _this.mapTherapyMonitoringPartsToCarePlanAsync(tmp, carePlans);
                                        }
                                        catch (e) {
                                            _this.toaster.error(e);
                                        }
                                    }))];
                            case 2:
                                carePlanMonitoringParts = _b.sent();
                                monitoringPartsList = _.flatten(carePlanMonitoringParts);
                                monitoringPartsList = this.sortMonitoringParts(monitoringPartsList, groups);
                                monitoringPartsList = this.monitoringPartMergeSvc.mergeMonitoringParts(monitoringPartsList);
                                return [2 /*return*/, monitoringPartsList];
                            case 3:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileMonitoringController.prototype.sortMonitoringParts = function (monitoringParts, groups) {
                var groupedParts = _.groupBy(monitoringParts, function (cpmp) { return cpmp.monitoringPart.assignedGroup && cpmp.monitoringPart.assignedGroup.Id; });
                var groupedList = _.map(groups, function (g) { return groupedParts[g.Id]; });
                groupedList = _.filter(groupedList, function (cpmps) { return cpmps != null; });
                if (groupedParts[null]) {
                    groupedList.push(groupedParts[null]);
                }
                return _.flatten(groupedList);
            };
            PatientFileMonitoringController.prototype.mapTherapyMonitoringPartsToCarePlanAsync = function (therapyMonitoringParts, selectedCarePlans) {
                return __awaiter(this, void 0, void 0, function () {
                    var monitoringParts, carePlanIds, sortedMonitoringParts;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.monitoringPartFactory.createAsync(therapyMonitoringParts.TherapyId, therapyMonitoringParts.MonitoringParts)];
                            case 1:
                                monitoringParts = _a.sent();
                                carePlanIds = _(this.carePlans)
                                    .chain()
                                    .filter(function (cp) {
                                    return cp.therapyId === therapyMonitoringParts.TherapyId &&
                                        selectedCarePlans.some(function (sc) { return sc.Id === cp.id; });
                                })
                                    .map(function (cp) { return cp.id; })
                                    .value();
                                sortedMonitoringParts = _.sortBy(monitoringParts, function (mp) { return mp.sequence; });
                                return [2 /*return*/, _.map(sortedMonitoringParts, function (mp) {
                                        try {
                                            return {
                                                carePlanIds: carePlanIds,
                                                monitoringPart: mp,
                                            };
                                        }
                                        catch (e) {
                                            _this.toaster.error(e);
                                        }
                                    })];
                        }
                    });
                });
            };
            return PatientFileMonitoringController;
        }());
        Patient.remeCarePatientModule.component('patientFileMonitoring', {
            controller: PatientFileMonitoringController,
            templateUrl: 'views/patient/monitoring/dashboard/monitoring.html',
            bindings: {
                patientId: '<',
            },
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
