module RemeCare.Management {
    import Guid = Shared.Contract.Guid;

    interface IUserAccountHistoryScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        grid: Shared.Framework.Grid.Grid<Shared.Contract.Read.IUserAccountHistory>;
        name: string;
    }

    interface IUserAccountHistory extends Shared.Contract.Read.IUserAccountHistory {
        StatusChangeRequestorName: string;
    }

    class UserAccountHistoryController extends Shared.Framework.ControllerBase<IUserAccountHistoryScope> {
        constructor(
            protected $scope: IUserAccountHistoryScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly partyId: Guid,
            private readonly securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {
            super($scope, $translate, toaster);
        }

        public $onInit(): void {
            this.buildGrid();
            this.$scope.grid.search();
        }

        private async executeSearchAsync(
            page: number,
            pageSize: number
        ): Promise<Shared.Contract.ISearchResult<IUserAccountHistory>> {
            const query = <Shared.Contract.ISearchQuery>{
                page: page,
                pageSize: pageSize,
            };

            try {
                const r = await this.securityApiSvc.searchUserAccountHistoryAsync(this.partyId, query);
                const partyIds = _(r.Items)
                    .chain()
                    .map(i => i.StatusChangeRequestor)
                    .filter(id => id != null)
                    .value();
                if (partyIds.length > 0) {
                    const parties = await this.partyApiSvc.findPartyNamesAsync(partyIds);
                    _(r.Items).forEach(i => {
                        const party = _.find(parties, p => p.Id === i.StatusChangeRequestor);
                        if (party != null) {
                            (<IUserAccountHistory>i).StatusChangeRequestorName = party.Text;
                        }
                    });
                    return <Shared.Contract.ISearchResult<IUserAccountHistory>>r;
                } else {
                    return <Shared.Contract.ISearchResult<IUserAccountHistory>>r;
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private customTemplate =
            '<div class="text-center vertical-center">' +
            '<i ng-if="row.entity.IsBlocked" class="red glyphicon glyphicon-user"></i>' +
            '<i ng-if="!row.entity.IsBlocked" class="green glyphicon glyphicon-user"></i>' +
            '</div>';

        private buildGrid(): void {
            this.$scope.grid = this.gridBuilderSvc
                .createGridBuilder<IUserAccountHistory>((p, ps) => this.executeSearchAsync(p, ps))
                .addCustomColumn('', this.customTemplate, () => {}, { width: 50 })
                .addColumn('StatusDateTime', 'General.Date', { cellFilter: 'displayUtcDateTimeFilter' })
                .addColumn('StatusChangeRequestorName', 'General.By')
                .addTranslateColumn('StatusChangeReason', 'General.Reason')
                .setSorting(false)
                .build();
            this.$scope.grid.pagingOptions.currentPage = 1;
            this.$scope.grid.pagingOptions.pageSize = 5;
        }
    }

    remeCareManagementModule.controller(
        'userAccountHistoryCtrl',
        (
            $scope,
            $translate,
            toaster,
            partyId: Guid,
            securityApiSvc: Shared.Framework.Service.SecurityApiSvc,
            partyApiSvc: Core.Services.PartyApiService,
            gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) =>
            new UserAccountHistoryController(
                $scope,
                $translate,
                toaster,
                partyId,
                securityApiSvc,
                partyApiSvc,
                gridBuilderSvc
            )
    );
}
