namespace RemeCare.Patient.Model {
    import Guid = Shared.Contract.Guid;
    import Periodicity = Shared.Framework.Model.Periodicity;
    import TranslateService = ng.translate.ITranslateService;
    import SentencePart = RemeCare.Contract.Patient.Read.ISentencePart;
    import Careplan = Contract.Patient.Read.ICareplan;
    import EnumTranslation = Shared.Contract.IEnumTranslation;

    export class Prescription {
        public id: Guid;
        public patientId: Guid;
        public isFollowedUp: boolean;
        public isDefault: boolean;
        public followUpText: string;
        public validFromDate: Date;
        public validUntilDate: Date;
        public periodicities: Periodicity[];
        public medicationSchema: string;
        public carePlans: Careplan[];
        public isMultiplyable: boolean;
        public executionRight: Shared.Framework.AuthRight;
        public onlyIntakeMoments: boolean;
        public isAdHoc: boolean;
        public isSimplePrescription: boolean;
        public solventToAddInformation: SolventToAddInformation;
        public prescribedAdministrationInformation: PrescribedAdministrationInformation;
        private isNew: boolean;

        constructor(
            serverObject?: Contract.Patient.Read.IPrescription,
            public medication?: Shared.Contract.Read.IMedication,
            private $translate?: TranslateService,
            private carePlanView?: boolean
        ) {
            if (serverObject) {
                this.id = serverObject.Id;
                this.patientId = serverObject.PatientId;
                this.isFollowedUp = serverObject.IsFollowedUp;
                this.isDefault = serverObject.IsDefault;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.carePlans = serverObject.CarePlans;
                const periodicityFactory = new Shared.Framework.Factory.PeriodicityFactory();
                this.periodicities = _(serverObject.Periodicities).map((p) => periodicityFactory.createPeriodicity(p));
                this.isMultiplyable = serverObject.IsMultiplyable;
                this.executionRight = serverObject.ExecutionRight;
                this.setCarePlans(serverObject.CarePlans);
                this.solventToAddInformation = new SolventToAddInformation(serverObject.SolventToAddInformation);
                this.prescribedAdministrationInformation = new PrescribedAdministrationInformation(
                    serverObject.PrescribedAdministrationInformation
                );
                this.isAdHoc = this.isAdHocPrescription();
                this.isSimplePrescription = this.canBeShownAsSimplePrescription();
                this.renderMedicationSchema(serverObject.TokenizedMedicationSchema, $translate);
            } else {
                this.isNew = true;
                this.isMultiplyable = true;
                this.periodicities = [];
                this.carePlans = [];
                this.solventToAddInformation = new SolventToAddInformation(null);
                this.prescribedAdministrationInformation = new PrescribedAdministrationInformation(null);
            }
        }

        public getName(): string {
            if (!this.medication) {
                return '';
            }

            let name = this.medication.BrandName || this.medication.MedicationName;
            if (!this.medication.IsActive) {
                name += ' (' + this.$translate.instant('General.Inactive') + ')';
            }
            return name;
        }

        public getComposition(): string {
            return this.medication
                ? this.medication.ActiveSubstance ||
                      `${this.medication.ContentQuantity || ''} ${
                          this.medication.ContentQuantity ? this.medication.ContentUnitName : ''
                      } ${this.medication.ContentQuantity && this.medication.EnergyValue ? '-' : ''} ${this.medication
                          .EnergyValue || ''} ${this.medication.EnergyValue ? this.medication.EnergyUnitName : ''}`
                : '';
        }

        public toWriteModel(): Contract.Patient.Write.IPrescription {
            const toReturn = {
                id: this.id,
                patientId: this.patientId,
                productId: this.medication.ProductId,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
                periodicities: _(this.periodicities).map((p) => p.toWriteModel()),
                carePlanIds: _(_(this.carePlans).filter((cp) => cp !== null)).map((cp) => cp.Id),

                // onlyIntakeMoments: this.onlyIntakeMoments
            } as Contract.Patient.Write.IPrescription;
            if (this.solventToAddInformation != null) {
                toReturn.solventToAddInformation = this.solventToAddInformation.toServerWrite();
            } else {
                toReturn.solventToAddInformation = null;
            }
            if (this.prescribedAdministrationInformation != null) {
                toReturn.prescribedAdministrationInformation = this.prescribedAdministrationInformation.toServerWrite();
            } else {
                toReturn.prescribedAdministrationInformation = null;
            }
            return toReturn;
        }

        public setCarePlans(carePlans: Careplan[]): void {
            // Array contains null values...
            carePlans = carePlans ? carePlans.filter((cp) => !!cp) : [];
            this.carePlans = carePlans;

            if (carePlans.length > 0) {
                const activeCarePlans = carePlans.filter(
                    (cp) => cp.Status.Id === Shared.Contract.Code.CarePlanStatus.Open
                );
                const inactiveCarePlans = carePlans.filter(
                    (cp) => cp.Status.Id !== Shared.Contract.Code.CarePlanStatus.Open
                );

                // Only show as followed up if at least 1 careplan is still active
                this.isFollowedUp = activeCarePlans.length > 0;

                if (this.carePlanView) {
                    this.followUpText =
                        this.$translate.instant(
                            inactiveCarePlans.length > 0
                                ? 'Views.Patient.Medication.NotFollowedUpAnymore'
                                : 'Views.Patient.Medication.FollowedUp'
                        ) +
                        ' ' +
                        this.$translate.instant('Views.Patient.Medication.ThisCarePlan');
                } else {
                    const followedUpText = activeCarePlans.length
                        ? this.$translate.instant('Views.Patient.Medication.FollowedUp') +
                          ' ' +
                          activeCarePlans.map((cp) => cp.Name).join(', ')
                        : '';
                    const noLongerFollowUpText = inactiveCarePlans.length
                        ? this.$translate.instant('Views.Patient.Medication.NotFollowedUpAnymore') +
                          ' ' +
                          inactiveCarePlans.map((cp) => cp.Name).join(', ')
                        : '';
                    const joinText = activeCarePlans.length && inactiveCarePlans.length ? '<br>' : '';
                    this.followUpText = followedUpText + joinText + noLongerFollowUpText;
                }
            } else {
                this.isFollowedUp = false;
                this.followUpText =
                    this.$translate.instant('Views.Patient.Medication.NotFollowedUp') +
                    ' ' +
                    this.$translate.instant(
                        this.carePlanView ? 'Views.Patient.Medication.ThisCarePlan' : 'General.RemeCare'
                    );
            }
        }

        public renderMedicationSchema(tokens: SentencePart[], $translate: TranslateService): void {
            const renderPipe: string[] = new Array<string>();

            angular.forEach(tokens, (t) => {
                const params: any = {};

                for (let i = 0; i < t.Parameters.length; i++) {
                    params['value' + i] = t.Parameters[i];
                }

                if (t.TemplateResourceKey === 'General.Periodicity.Tokens.DayTimingDose') {
                    params.value1 = this.medication ? this.medication.UnitName : '';
                }

                let text;
                if (t.TemplateResourceKey.toString().indexOf('General.') !== -1) {
                    text = $translate.instant(t.TemplateResourceKey, params);
                } else {
                    text = t.TemplateResourceKey.toString();
                }

                renderPipe.push(text);
            });

            this.medicationSchema = renderPipe.join('');
        }

        public startsInThePast(): boolean {
            return this.validFromDate <= Shared.DateHelper.today();
        }

        public canDelete(): boolean {
            return (
                this.isNew || (this.canEdit() && (this.validFromDate > Shared.DateHelper.today() || !this.isFollowedUp))
            );
        }

        public canEdit(): boolean {
            if (this.isNew) {
                return true;
            }

            if (!this.isFollowedUp) {
                return true;
            }
            return (
                !this.isDefault &&
                this.isMultiplyable &&
                (this.validUntilDate == null || this.validUntilDate > Shared.DateHelper.today())
            );
        }

        public canBeShownAsSimplePrescription(): boolean {
            if (this.periodicities != null && this.periodicities.length === 1) {
                const periodicity = this.periodicities[0];
                if (
                    _(periodicity.intraDayTimings).any(
                        (idt: Shared.Framework.Model.IntraDayTimingAdministration) =>
                            idt.noEatingWindowBefore != null ||
                            idt.noEatingWindowAfter != null ||
                            moment(idt.preferredTime).minutes() !== 0
                    )
                ) {
                    return false;
                }
                if (periodicity.hasDuration) {
                    return false;
                }
                if (
                    periodicity.recurrence != null &&
                    periodicity.recurrence.Quantity === 1 &&
                    periodicity.recurrence.Unit === Shared.Contract.Code.DurationUnit.Days
                ) {
                    return (
                        this.prescribedAdministrationInformation == null ||
                        this.prescribedAdministrationInformation.prescribedAdministrationMeans == null ||
                        this.prescribedAdministrationInformation.prescribedAdministrationMeans < 1
                    );
                }
            }
            return false;
        }

        public makeAdHoc(patientLang: string, masterdataSvc: Shared.Framework.MasterdataService): void {
            const periodicity = new Periodicity();
            periodicity.unstructuredTargetQuantity = 1;
            this.isAdHoc = true;
            this.isSimplePrescription = false;
            this.periodicities = [periodicity];

            if (!this.isFollowedUp) {
                return;
            }
            // Set 'If needed' as text for followed up ad hoc medication
            if (patientLang) {
                masterdataSvc.getTranslation(patientLang, 'Views.Patient.Medication.IfNeeded').success((trans) => {
                    const ifNeededTrans = trans['Views.Patient.Medication.IfNeeded'];
                    periodicity.unstructuredPeriodicity = ifNeededTrans;
                });
            } else {
                const ifNeededTrans = this.$translate.instant('Views.Patient.Medication.IfNeeded');
                periodicity.unstructuredPeriodicity = ifNeededTrans;
            }
        }

        public makeDefault(): void {
            const factory = new Shared.Framework.Factory.PeriodicityFactory();
            const periodicity = factory.createPeriodicityFromDurationUnit(Shared.Contract.Code.DurationUnit.Days);
            periodicity.recurrence.Quantity = 1;
            this.periodicities = [periodicity];
            this.isAdHoc = false;
            this.isSimplePrescription = true;
        }

        private isAdHocPrescription(): boolean {
            if (this.periodicities != null && this.periodicities.length === 1) {
                const periodicity = this.periodicities[0];
                if (periodicity.type === Shared.Contract.Code.PeriodicityType.Periodicity) {
                    return true;
                }
            }
            return false;
        }
    }
}
