var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var AddUserController = /** @class */ (function (_super) {
            __extends(AddUserController, _super);
            function AddUserController($scope, $translate, toaster, $q, partyId, firstName, lastName, securityApiSvc, partyApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$q = $q;
                _this.partyId = partyId;
                _this.firstName = firstName;
                _this.lastName = lastName;
                _this.securityApiSvc = securityApiSvc;
                _this.partyApiSvc = partyApiSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            AddUserController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.userNameRegex = /^[^"\/\\\[\]:;|=,+*?<>]{1,20}$/;
                this.$scope.maxLength = 18;
                this.$scope.mailToOptions = [
                    {
                        Id: true,
                        Text: this.$translate.instant('General.User')
                    }, {
                        Id: false,
                        Text: this.$translate.instant('Views.Management.Persons.RemecareMail')
                    }
                ];
                var userName = this.firstName + "." + this.lastName;
                userName = userName.replace(/\s/g, "");
                userName = userName.substr(0, 18);
                userName = userName.trim();
                this.$scope.newUser = {
                    userName: userName,
                    partyId: this.partyId
                };
                this.$q.all([
                    this.securityApiSvc.findAssignableApplicationProfiles(),
                    this.partyApiSvc.findPartyRoleTypes(this.partyId)
                ])
                    .then(function (r) {
                    var profiles = r[0].data;
                    var partyRoleTypes = r[1].data;
                    profiles = profiles.filter(function (ap) {
                        if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser ||
                            ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Configurator) {
                            return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee; });
                        }
                        else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareManager ||
                            ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.AdministrativeManager ||
                            ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.CareProvider ||
                            ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.TherapyProvider) {
                            return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Employee ||
                                prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional; });
                        }
                        else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.Patient) {
                            return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.Patient; });
                        }
                        else if (ap.Id === RemeCare.Shared.Contract.Code.ApplicationProfileType.InformalCareProvider) {
                            return _(partyRoleTypes).any(function (prt) { return prt.Id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional; });
                        }
                        return false;
                    });
                    _this.$scope.applicationProfiles = profiles;
                })
                    .catch(function (e) { return _this.errorCallback(e); });
            };
            AddUserController.prototype.confirm = function () {
                var _this = this;
                if (this.$scope.newUser.userName.length > this.$scope.maxLength) {
                    this.toaster.error(this.$translate.instant('Views.Management.Persons.UserNameTooLong'));
                    return;
                }
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.securityApiSvc.checkUserNameExists(this.$scope.newUser.userName)
                    .success(function (r) {
                    if (r !== _this.$scope.newUser.userName) {
                        _this.$scope.userNameProposal = r;
                    }
                    else {
                        _this.securityApiSvc.createUser(_this.$scope.newUser)
                            .success(function () { return _this.$scope.$close(); })
                            .error(function (e) { return _this.errorCallback(e); });
                    }
                })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            return AddUserController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('addUserCtrl', AddUserController);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
