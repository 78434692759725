'use strict';

module RemeCare.Contract.CarePlanAction.Read {
    import Guid = RemeCare.Shared.Contract.Guid;
    import TelephoneNumber = RemeCare.Contract.Core.ITelephoneNumber;

    export interface IGeneralInfo {
        CarePlanActionId: Guid;
        Address: string;
        ZipCode: string;
        City: string;
        PhoneNumber: TelephoneNumber;
        MobileNumber: TelephoneNumber;
        Therapy: string;
        InternalRemark: string;
        GeneralRemark: string;
        PatientId: Guid;
    }
}