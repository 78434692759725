var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var TelephoneNumber = RemeCare.Model.PhoneNumber;
        var RequestContactInfo = /** @class */ (function () {
            function RequestContactInfo(serverObject) {
                this.telephone = new TelephoneNumber();
                this.mobile = new TelephoneNumber();
                this.update = new RequestContactInfoUpdate();
                this.address = new RemeCare.Model.Address();
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.address.addressLine1 = serverObject.Address;
                    this.address.zipCode = serverObject.ZipCode;
                    this.address.city = serverObject.City;
                    this.address.country = serverObject.Country;
                    this.telephone = new TelephoneNumber(serverObject.Telephone);
                    this.mobile = new TelephoneNumber(serverObject.Mobile);
                    this.email = serverObject.Email;
                    this.update = new RequestContactInfoUpdate(serverObject.Update);
                }
            }
            RequestContactInfo.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    address: this.address && this.address.addressLine1,
                    zipCode: this.address && this.address.zipCode,
                    city: this.address && this.address.city,
                    country: this.address && this.address.country,
                    telephone: this.telephone.toServerWrite(),
                    mobile: this.mobile.toServerWrite(),
                    email: this.email,
                    update: this.update.toServerWrite(),
                };
            };
            RequestContactInfo.prototype.updateContactInfo = function (update) {
                if (update.update.hasUpdatedAddress) {
                    this.address = update.address;
                }
                if (update.update.hasUpdatedCity) {
                    this.address.city = update.address.city;
                }
                if (update.update.hasUpdatedCountry) {
                    this.address.country = update.address.country;
                }
                if (update.update.hasUpdatedEmail) {
                    this.email = update.email;
                }
                if (update.update.hasUpdatedMobilePhone) {
                    this.mobile = angular.copy(update.mobile);
                }
                if (update.update.hasUpdatedPostalCode) {
                    this.address.zipCode = update.address.zipCode;
                }
                if (update.update.hasUpdatedTelephone) {
                    this.telephone = angular.copy(update.telephone);
                }
            };
            return RequestContactInfo;
        }());
        CareRequest.RequestContactInfo = RequestContactInfo;
        var RequestContactInfoUpdate = /** @class */ (function () {
            function RequestContactInfoUpdate(serverObject) {
                if (serverObject != null) {
                    this.hasUpdatedAddress = serverObject.HasUpdatedAddress;
                    this.hasUpdatedCity = serverObject.HasUpdatedCity;
                    this.hasUpdatedCountry = serverObject.HasUpdatedCountry;
                    this.hasUpdatedEmail = serverObject.HasUpdatedEmail;
                    this.hasUpdatedMobilePhone = serverObject.HasUpdatedMobilePhone;
                    this.hasUpdatedPostalCode = serverObject.HasUpdatedPostalCode;
                    this.hasUpdatedTelephone = serverObject.HasUpdatedTelephone;
                }
            }
            RequestContactInfoUpdate.prototype.toServerWrite = function () {
                return {
                    hasUpdatedAddress: this.hasUpdatedAddress,
                    hasUpdatedCity: this.hasUpdatedCity,
                    hasUpdatedCountry: this.hasUpdatedCountry,
                    hasUpdatedEmail: this.hasUpdatedEmail,
                    hasUpdatedMobilePhone: this.hasUpdatedMobilePhone,
                    hasUpdatedPostalCode: this.hasUpdatedPostalCode,
                    hasUpdatedTelephone: this.hasUpdatedTelephone,
                };
            };
            return RequestContactInfoUpdate;
        }());
        CareRequest.RequestContactInfoUpdate = RequestContactInfoUpdate;
        var RequestParty = /** @class */ (function () {
            function RequestParty(serverObject) {
                this.contactInfo = new RequestContactInfo();
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.contactInfo = new RequestContactInfo(serverObject.ContactInfo);
                }
            }
            RequestParty.prototype.getName = function () {
                return '';
            };
            RequestParty.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    contactInfo: this.contactInfo.toServerWrite(),
                    partyType: this.partyType,
                };
            };
            return RequestParty;
        }());
        CareRequest.RequestParty = RequestParty;
        var RequestPerson = /** @class */ (function (_super) {
            __extends(RequestPerson, _super);
            function RequestPerson(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.technologyAffinities = [];
                _this.update = new RequestPersonUpdate();
                _this.externalReferences = [];
                _this.partyType = RemeCare.Shared.Contract.Code.PartyType.Person;
                if (serverObject != null) {
                    _this.firstName = serverObject.FirstName;
                    _this.lastName = serverObject.LastName;
                    _this.identificationNumber = serverObject.IdentificationNumber;
                    _this.birthDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverObject.BirthDate);
                    _this.communicationLanguage = serverObject.CommunicationLanguage;
                    _this.gender = serverObject.Gender;
                    _this.maritalStatus = serverObject.MaritalStatus;
                    _this.educationLevel = serverObject.EducationLevel;
                    _this.patientCode = serverObject.PatientCode;
                    _this.remark = serverObject.Remark;
                    _this.externalReferenceNumber = serverObject.ExternalReferenceNumber;
                    _this.externalReferences = serverObject.ExternalReferences || [];
                    _this.nationality = serverObject.Nationality;
                    _this.technologyAffinities = serverObject.TechnologyAffinities;
                    _this.update = new RequestPersonUpdate(serverObject.Update);
                }
                return _this;
            }
            RequestPerson.createFromHealthCareProfessional = function (professional) {
                var result = new RequestPerson();
                result.firstName = professional.FirstName;
                result.lastName = professional.LastName;
                result.contactInfo.email = professional.Email;
                result.identificationNumber = professional.IdentificationNumber;
                result.contactInfo.telephone = new TelephoneNumber(professional.PhoneNumber);
                result.contactInfo.mobile = new TelephoneNumber(professional.MobileNumber);
                result.contactInfo.address.addressLine1 = professional.AddressLine;
                result.contactInfo.address.zipCode = professional.ZipCode;
                result.contactInfo.address.city = professional.City;
                return result;
            };
            RequestPerson.createFromPerson = function (person) {
                if (person != null) {
                    var result = new RequestPerson();
                    result.firstName = person.FirstName;
                    result.lastName = person.LastName;
                    result.identificationNumber = person.NationalNumber;
                    result.birthDate = RemeCare.Shared.DateHelper.serverDateStringToDate(person.DateOfBirth);
                    result.communicationLanguage = person.CommunicationLanguage && person.CommunicationLanguage.Code;
                    result.gender = person.Gender && person.Gender.Id;
                    result.contactInfo.address = new RemeCare.Model.Address();
                    result.contactInfo.address.addressLine1 = person.Address;
                    result.contactInfo.address.zipCode = person.ZipCode;
                    result.contactInfo.address.city = person.City;
                    result.contactInfo.address.country = person.Country;
                    result.nationality = person.Nationality && person.Nationality.Code;
                    result.contactInfo.email = person.Email;
                    result.contactInfo.telephone = new TelephoneNumber(person.Telephone);
                    result.contactInfo.mobile = new TelephoneNumber(person.Mobile);
                    result.maritalStatus = person.MaritalStatus && person.MaritalStatus.Id;
                    result.educationLevel = person.EducationLevel && person.EducationLevel.Id;
                    result.technologyAffinities = _(person.TechnologyAffinities).map(function (t) { return t.Id; });
                    return result;
                }
                return null;
            };
            RequestPerson.prototype.getName = function () {
                return (this.lastName != null ? this.lastName : '') + " " + (this.firstName != null ? this.firstName : '');
            };
            RequestPerson.prototype.toServerWrite = function () {
                var result = _super.prototype.toServerWrite.call(this);
                result.firstName = this.firstName;
                result.lastName = this.lastName;
                result.birthDate = RemeCare.Shared.DateHelper.toServerDateString(this.birthDate);
                result.communicationLanguage = this.communicationLanguage;
                result.identificationNumber = this.identificationNumber;
                result.gender = this.gender;
                result.maritalStatus = this.maritalStatus;
                result.educationLevel = this.educationLevel;
                result.patientCode = this.patientCode;
                result.remark = this.remark;
                result.externalReferenceNumber = this.externalReferenceNumber;
                result.externalReferences = this.externalReferences;
                result.nationality = this.nationality;
                result.technologyAffinities = this.technologyAffinities;
                result.update = this.update.toServerWrite();
                result.patientFileForExternalRequestId = this.patientFileForExternalRequestId;
                return result;
            };
            RequestPerson.prototype.updatePerson = function (update) {
                if (update.update.hasUpdatedBirthDate) {
                    this.birthDate = angular.copy(update.birthDate);
                }
                if (update.update.hasUpdatedCommunicationLanguage) {
                    this.communicationLanguage = update.communicationLanguage;
                }
                if (update.update.hasUpdatedEducationLevel) {
                    this.educationLevel = update.educationLevel;
                }
                if (update.update.hasUpdatedFirstName) {
                    this.firstName = update.firstName;
                }
                if (update.update.hasUpdatedGender) {
                    this.gender = update.gender;
                }
                if (update.update.hasUpdatedIdentificationNumber) {
                    this.identificationNumber = update.identificationNumber;
                }
                if (update.update.hasUpdatedLastName) {
                    this.lastName = update.lastName;
                }
                if (update.update.hasUpdatedMaritalStatus) {
                    this.maritalStatus = update.maritalStatus;
                }
                if (update.update.hasUpdatedNationality) {
                    this.nationality = update.nationality;
                }
                if (update.update.hasUpdatedTechnologyAffinity) {
                    this.technologyAffinities = angular.copy(update.technologyAffinities);
                }
                this.contactInfo.updateContactInfo(update.contactInfo);
            };
            return RequestPerson;
        }(RequestParty));
        CareRequest.RequestPerson = RequestPerson;
        var RequestPersonUpdate = /** @class */ (function () {
            function RequestPersonUpdate(serverObject) {
                if (serverObject != null) {
                    this.hasUpdatedBirthDate = serverObject.HasUpdatedBirthDate;
                    this.hasUpdatedCommunicationLanguage = serverObject.HasUpdatedCommunicationLanguage;
                    this.hasUpdatedEducationLevel = serverObject.HasUpdatedEducationLevel;
                    this.hasUpdatedFirstName = serverObject.HasUpdatedFirstName;
                    this.hasUpdatedGender = serverObject.HasUpdatedGender;
                    this.hasUpdatedIdentificationNumber = serverObject.HasUpdatedIdentificationNumber;
                    this.hasUpdatedLastName = serverObject.HasUpdatedLastName;
                    this.hasUpdatedMaritalStatus = serverObject.HasUpdatedMaritalStatus;
                    this.hasUpdatedNationality = serverObject.HasUpdatedNationality;
                    this.hasUpdatedTechnologyAffinity = serverObject.HasUpdatedTechnologyAffinity;
                }
            }
            RequestPersonUpdate.prototype.toServerWrite = function () {
                return {
                    hasUpdatedBirthDate: this.hasUpdatedBirthDate,
                    hasUpdatedCommunicationLanguage: this.hasUpdatedCommunicationLanguage,
                    hasUpdatedEducationLevel: this.hasUpdatedEducationLevel,
                    hasUpdatedFirstName: this.hasUpdatedFirstName,
                    hasUpdatedGender: this.hasUpdatedGender,
                    hasUpdatedIdentificationNumber: this.hasUpdatedIdentificationNumber,
                    hasUpdatedLastName: this.hasUpdatedLastName,
                    hasUpdatedMaritalStatus: this.hasUpdatedMaritalStatus,
                    hasUpdatedNationality: this.hasUpdatedNationality,
                    hasUpdatedTechnologyAffinity: this.hasUpdatedTechnologyAffinity,
                };
            };
            return RequestPersonUpdate;
        }());
        CareRequest.RequestPersonUpdate = RequestPersonUpdate;
        var RequestOrganisation = /** @class */ (function (_super) {
            __extends(RequestOrganisation, _super);
            function RequestOrganisation(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.partyType = RemeCare.Shared.Contract.Code.PartyType.Organisation;
                if (serverObject != null) {
                    _this.name = serverObject.Name;
                }
                return _this;
            }
            RequestOrganisation.createFromHealthCareOrganisation = function (organisation) {
                var result = new RequestOrganisation();
                result.name = organisation.Name;
                result.contactInfo.address.addressLine1 = organisation.AddressLine;
                result.contactInfo.address.zipCode = organisation.ZipCode;
                result.contactInfo.address.city = organisation.City;
                result.contactInfo.email = organisation.Email;
                result.contactInfo.telephone = new TelephoneNumber(organisation.PhoneNumber);
                return result;
            };
            RequestOrganisation.prototype.getName = function () {
                return this.name;
            };
            return RequestOrganisation;
        }(RequestParty));
        CareRequest.RequestOrganisation = RequestOrganisation;
        var PatientContactPerson = /** @class */ (function () {
            function PatientContactPerson(serverObject) {
                this.person = new RequestPerson();
                if (serverObject != null) {
                    this.id = serverObject.Id;
                    this.type = serverObject.ContactPersonType;
                    this.isHCNonProfessional = serverObject.IsHCNonProfessional;
                    this.matchedPartyId = serverObject.MatchedPartyId;
                    this.person = new RequestPerson(serverObject.Person);
                }
            }
            PatientContactPerson.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    type: this.type && this.type.Id,
                    isHCNonProfessional: this.isHCNonProfessional,
                    matchedPartyId: this.matchedPartyId,
                    person: this.person.toServerWrite(),
                };
            };
            PatientContactPerson.prototype.firstName = function () {
                if (this.matchedParty != null) {
                    return this.matchedParty.firstName;
                }
                else {
                    return this.person.firstName;
                }
            };
            PatientContactPerson.prototype.lastName = function () {
                if (this.matchedParty != null) {
                    return this.matchedParty.lastName;
                }
                else {
                    return this.person.lastName;
                }
            };
            return PatientContactPerson;
        }());
        CareRequest.PatientContactPerson = PatientContactPerson;
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
