namespace RemeCare.Patient {
    import CarePlan = RemeCare.Model.Careplan;
    import Guid = Shared.Contract.Guid;
    import CarePlanDetail = Contract.Patient.Read.ICarePlanParameterValue;
    import RegistrationCodeSetValue = Contract.CareRequest.Read.IRegistrationCodeSetValue;
    import RegistrationMultiCodeSetValue = Contract.CareRequest.Read.IRegistrationMultiCodeSetValue;
    import RegistrationPhysicalQuantity = Contract.CareRequest.Read.IRegistrationPhysicalQuantity;

    interface IPatientFileCarePlanDetailScope extends Shared.Framework.IBaseScope {
        carePlan: CarePlan;
        patientId: Guid;

        carePlanDetails: CarePlanDetail[];

        editRight: boolean;

        getRegistrationValue: (value: CarePlanDetail) => string;
        getMissingValues: (value: CarePlanDetail) => string;

        careRequestTemplate: Contract.CareRequest.Read.ICareRequestTemplate;

        dataLoaded: boolean;
    }

    interface ICodeSetTypeCacheItem {
        codeSetTypeId: Shared.Contract.Guid;
        codeSetItems: Shared.Contract.IChoiceListOption[];
    }

    class PatientFileCarePlanDetailController extends Shared.Framework.ControllerBase<IPatientFileCarePlanDetailScope> {
        private currentCodeSetTypesCache: ICodeSetTypeCacheItem[] = [];

        constructor(
            protected $scope: IPatientFileCarePlanDetailScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($scope, $translate, toaster);
            this.$scope.getRegistrationValue = (value: CarePlanDetail) => this.getRegistrationValue(value);
            this.$scope.getMissingValues = (value: CarePlanDetail) => this.getMissingValues(value);
        }

        public $onInit() {
            this.$scope.patientId = this.$stateParams.patientId;
            this.$scope.carePlanDetails = [];
            this.$scope.dataLoaded = false;

            if (this.$scope.carePlan) {
                this.getCarePlanDetailsAsync(this.$scope.carePlan.id).then((r) => {
                    this.$scope.dataLoaded = true;
                });
            }

            this.$scope.$watch('carePlan', (newValue, oldValue) => {
                if (newValue !== oldValue && newValue !== null) {
                    this.getCarePlanDetailsAsync(this.$scope.carePlan.id).then((r) => {
                        this.$scope.dataLoaded = true;
                    });
                }
            });
        }

        private async getCarePlanDetailsAsync(carePlanId: Guid): Promise<void> {
            try {
                this.$scope.carePlanDetails = await this.carePlanApiSvc.getCarePlanParameterValuesAsync(carePlanId);
                this.$scope.editRight =
                    _(this.$scope.carePlanDetails).any(
                        (detail) =>
                            (detail.ExecutionRight &
                                (Shared.Framework.AuthRight.Create | Shared.Framework.AuthRight.Write)) !==
                            0
                    ) && this.$scope.carePlan.statusOpen;
                this.$scope.careRequestTemplate = await this.therapyApiSvc.getCareRequestTemplateAsync(
                    this.$scope.carePlan.therapyId,
                    carePlanId
                );

                // Cache the actual code set type items
                for (const crp of this.$scope.careRequestTemplate.CareRequestSetup.CareRequestParts) {
                    if (crp.QualitativeAnamnesis && crp.QualitativeAnamnesis.CodeSet) {
                        const a = crp;
                        const codeSetItems = await this.masterdataSvc.getCodeSetItemsAsync(
                            crp.QualitativeAnamnesis.CodeSet.Id
                        );

                        if (codeSetItems) {
                            this.currentCodeSetTypesCache.push({
                                codeSetTypeId: crp.QualitativeAnamnesis.CodeSet.Id,
                                codeSetItems: codeSetItems,
                            } as ICodeSetTypeCacheItem);
                        }
                    }
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private getRegistrationValue(detail: CarePlanDetail): string {
            if (detail.RegistrationValue == null) {
                return '';
            }
            if (this.getMissingValues(detail) !== '') {
                return ''; // don't show the value if its (partially) incorrect
            }

            if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue
            ) {
                return (detail.RegistrationValue as RegistrationCodeSetValue).ChoiceListOption.Name;
            } else if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationPhysicalQuantity
            ) {
                const unit = detail.Unit != null ? ` ${detail.Unit.Text}` : '';
                return `${(detail.RegistrationValue as RegistrationPhysicalQuantity).Amount}${unit}`;
            } else if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue
            ) {
                return _((detail.RegistrationValue as RegistrationMultiCodeSetValue).MultiChoiceListOptions)
                    .chain()
                    .sortBy((x) => x.Sequence)
                    .map((x) => x.Name)
                    .value()
                    .toString();
            }

            return '';
        }

        private getMissingValues(detail: CarePlanDetail): string {
            if (!this.$scope.dataLoaded) { return ''; }
            if (!detail) { return ''; }
            if (!detail.RegistrationValue) { return '';} // If no registration was done in the past, there are no missing values

            // Enlist the registered values
            const registeredCodeSetItems: Shared.Contract.IChoiceListOption[] = [];
            if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationCodeSetValue
            ) {
                registeredCodeSetItems.push((detail.RegistrationValue as RegistrationCodeSetValue).ChoiceListOption);
            } else if (
                detail.RegistrationValue.Type ===
                Shared.Contract.Code.AnamnesisRegistrationType.RegistrationMultiCodeSetValue
            ) {
                _((detail.RegistrationValue as RegistrationMultiCodeSetValue).MultiChoiceListOptions).forEach((mclo) =>
                    registeredCodeSetItems.push(mclo)
                );
            }

            const part = _(this.$scope.careRequestTemplate.CareRequestSetup.CareRequestParts).filter(
                (crp) => crp.LocalIdentity === detail.Definition.Id
            )[0];

            if (!part) { return '';} // if the part does not exist, we don't bother anymore
            if (!part.QualitativeAnamnesis) { return '';} // if it's not a qualitative anamnesis we don't bother
            if (!part.QualitativeAnamnesis.CodeSet) { return '';}

            // Compare and enlist missing values
                const currentCodeSetItemsList = this.currentCodeSetTypesCache.filter(
                    (cstc) => cstc.codeSetTypeId === part.QualitativeAnamnesis.CodeSet.Id
                )[0].codeSetItems;
                const missingValueList: string[] = [];
                _(registeredCodeSetItems).forEach((rcsi) => {
                    if (!_(currentCodeSetItemsList).any((csi) => csi.Id === rcsi.Id)) {
                        missingValueList.push(rcsi.Name);
                    }
                });
                return missingValueList.join(', ');
        }
    }

    angular.module('RemeCare.Patient').controller('PatientFileCarePlanDetailCtrl', PatientFileCarePlanDetailController);
}
