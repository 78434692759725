var RemeCare;
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        SelfService.remeCareSelfServiceModule.component('rcExternalIdentityProvider', {
            bindings: {
                providers: '<',
                onProviderSelected: '&'
            },
            templateUrl: 'views/selfService/externalIdentityProvider.html'
        });
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
