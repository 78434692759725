namespace RemeCare.Patient {
    import AuthContext = Shared.Framework.AuthContext;
    import AuthRight = Shared.Framework.AuthRight;
    import CareTeamParty = Contract.HealthCareParty.Read.ICareTeamParty;
    import CareTeamPartyAssignment = Contract.HealthCareParty.Read.ICareTeamPartyAssignment;

    class PartyAssignmentsModalController implements ng.IComponentController {
        public party: CareTeamParty;
        public partyType: Shared.Contract.Code.PartyType;
        public patientId: Shared.Contract.Guid;
        public editRight: boolean;
        public partyAssignmentGrid: Shared.Framework.Grid.Grid<CareTeamPartyAssignment>;
        public careTeamsCanEdit: Contract.Core.Read.ITherapyBusinessContextRightConfiguration[];

        constructor(
            protected authservice: Shared.Framework.AuthService,
            protected $translate: ng.translate.ITranslateService,
            protected $filter: ng.IFilterService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            protected toaster: Shared.Framework.Toaster,
            protected patientSvc: PatientService,
            protected partyApiSvc: Core.Services.PartyApiService,
            protected gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory
        ) {}

        public async $onInit(): Promise<void> {
            this.buildAssignmentsGrid();
            this.partyAssignmentGrid.setData(
                _(this.party.Assignments)
                    .sortBy((x) => x.ValidFromDate)
                    .reverse()
            );
        }
        public async showCareTeamPartyDetail(partyRoleId: Shared.Contract.Guid): Promise<void> {
            try {
                const personSummaries = await this.partyApiSvc.findPersonSummaryAsync(partyRoleId);
                const healthCareParty: Contract.HealthCareParty.Read.IHealthCareProfessionalParty = {
                    FirstName: personSummaries.FirstName,
                    LastName: personSummaries.LastName,
                    PartyRoleId: personSummaries.PartyRoleId,
                    ZipCode: personSummaries.ZipCode,
                    City: personSummaries.City,
                    AddressLine: personSummaries.AddressLine,
                    Email: personSummaries.Email,
                    IdentificationNumber: personSummaries.NationalNumber,
                    PhoneNumber: personSummaries.Telephone,
                    MobileNumber: personSummaries.Mobile,
                    HealthCareSpecialtyProfessions: undefined,
                    PartyId: personSummaries.PartyId,
                };
                this.showCareTeamProfessionalDetailModalAsync(healthCareParty);
            } catch (e) {
                this.toaster.error(e);
            }
        }

        protected showCareTeamAssignmentModalAsync(
            careTeamPartyAssignment: CareTeamPartyAssignment,
            partyRoleId: Shared.Contract.Guid,
            firstName: string,
            lastName: string
        ): Promise<CareTeamPartyAssignment> {
            return new Promise<CareTeamPartyAssignment>((resolve) => {
                const name = lastName == null ? `${firstName} ` : `${firstName} ${lastName}`;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('editCareTeamPartyCtrl')
                    .setTemplateUrl('views/patient/careTeam/editCareTeamPartyAssignment.html')
                    .setResolve({
                        patientId: () => careTeamPartyAssignment.CarePlan.PatientId,
                    })
                    .setScope({
                        partyRoleId,
                        name,
                        careTeamParty: angular.copy(careTeamPartyAssignment),
                        readOnly: !careTeamPartyAssignment.CanEdit,
                    })
                    .setResultCallBack((assigment) => {
                        this.reloadParty();
                        resolve(careTeamPartyAssignment);
                    })
                    .build();
            });
        }

        protected getCarePlanStatus(assignment: CareTeamPartyAssignment): string {
            const carePlan = assignment.CarePlan;
            return carePlan.Status.Id === Shared.Contract.Code.CarePlanStatus.Open
                ? carePlan.Name +
                      this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                          CarePlanStatus: carePlan.Status.Text,
                          StatusDate: this.$filter('date')(carePlan.StartDate, 'shortDate'),
                      })
                : carePlan.Name +
                      this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                          CarePlanStatus: carePlan.Status.Text,
                          StatusDate: this.$filter('date')(carePlan.StatusChangedDate, 'shortDate'),
                      });
        }

        protected buildAssignmentsGrid(): void {
            let builder = this.gridBuilderSvc
                .createGridBuilder<CareTeamPartyAssignment>()
                .addColumn('ActorRole.Text', 'General.Role')
                .addActionOnScopeColumn('$ctrl.parent.getCarePlanStatus', 'General.CarePlan')
                .addDateColumn('ValidFromDate', 'General.ValidFrom', 'shortDate', { visibleWhenSmall: false })
                .addDateColumn('ValidUntilDate', 'General.ValidUntilIncluding', 'shortDate', {
                    visibleWhenSmall: false,
                })
                .addConditionalEditButtonWithPromiseFunctionColumn(
                    (p) =>
                        this.showCareTeamAssignmentModalAsync(
                            p,
                            this.party.PartyRoleId,
                            this.party.Name,
                            this.party.LastName
                        ),
                    (p) => !p.CanEdit
                );

            if (this.editRight) {
                builder = builder.addConditionalDeleteButtonColumn(
                    (c) =>
                        moment(c.ValidFromDate).isBefore(moment()) ||
                        !c.CanEdit ||
                        !_(this.careTeamsCanEdit).any((bcr) => bcr.CarePlan === c.CarePlan.Id),
                    () => this.reloadParty(),
                    (c) => this.deleteCareTeamPartyAsync(c)
                );
            }

            this.partyAssignmentGrid = builder.build();
        }

        protected deleteCareTeamPartyAsync(careTeamPartyAssignment: CareTeamPartyAssignment): Promise<boolean> {
            return new Promise<boolean>(async (resolve) => {
                try {
                    await this.patientSvc.removeActorRoleAssignmentAsync(
                        careTeamPartyAssignment.CarePlan.PatientId,
                        careTeamPartyAssignment.CarePlan.TherapyId,
                        careTeamPartyAssignment.Id
                    );
                    resolve(true);
                } catch (e) {
                    this.toaster.error(e);
                    resolve(false);
                }
            });
        }

        private async reloadParty(): Promise<void> {
            const query: Contract.HealthCareParty.Read.Query.ISearchCareTeamQuery = {
                page: 1,
                pageSize: 50,
                excludeStopped: false,
            };
            await this.patientSvc.getCareTeamAsync(this.patientId, this.partyType, query).then((result) => {
                const newParty = result.Items.filter((p) => p.PartyRoleId === this.party.PartyRoleId);
                if (newParty.length > 0) {
                    this.party = newParty[0];
                    this.partyAssignmentGrid.setData(this.party.Assignments);
                }
            });
        }

        private showCareTeamProfessionalDetailModalAsync(
            healthCareParty: Contract.HealthCareParty.Read.IHealthCareProfessionalParty
        ): Promise<void> {
            return new Promise<void>((resolve) => {
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setTemplateUrl('views/patient/careTeam/careTeamProfessionalDetail.html')
                    .setScope({
                        healthCareParty,
                    })
                    .setResultCallBack(() => resolve())
                    .build();
            });
        }
    }

    Patient.remeCarePatientModule.component('rcPartyAssigmentsModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            party: '<',
            partyType: '<',
            patientId: '<',
            editRight: '<',
            careTeamsCanEdit: '<',
        },
        controller: PartyAssignmentsModalController,
        templateUrl: 'patient/components/partyAssignments/partyAssignmentsComponent.html',
    });
}
