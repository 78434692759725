module RemeCare.Patient {
    import IPatientInformedConsentSummary = Shared.Contract.Read.IPatientInformedConsentSummary;
    import InformedConsentHistory = Shared.Framework.Model.InformedConsentHistory;
    import InformedConsentHistoryPart = Shared.Framework.Model.InformedConsentHistoryPart;

    interface IAuditTrail {
        grid: Shared.Framework.Grid.Grid<InformedConsentHistoryPart>;
        name: string;
    }

    class InformedConsentModalController implements ng.IComponentController {
        public informedConsentGrid: Shared.Framework.Grid.Grid<IPatientInformedConsentSummary>;
        public auditTrails: IAuditTrail[];
        public acceptedInformedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsentsToAccept: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public informedConsents: Shared.Contract.Read.IPatientInformedConsentSummary[];
        public patientId: Shared.Contract.Guid;
        public patientName: string;

        constructor(
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly informedConsentSvc: Shared.Framework.Service.InformedConsentService,
            private readonly authservice: Shared.Framework.AuthService
        ) {}

        public async $onInit(): Promise<void> {
            this.buildGrids();
            this.informedConsentGrid.setData(this.acceptedInformedConsents);
            this.initializeAuditTrail();
        }

        private async initializeAuditTrail(): Promise<void> {
            try {
                const informedConsentHistoryReadModels = await this.informedConsentSvc.getInformedConsentsHistory(
                    this.patientId
                );
                const auditTrail = new InformedConsentHistory(informedConsentHistoryReadModels);
                const historyPerInformedConsent = _(auditTrail.parts).groupBy(ich => ich.informedConsentTitle);

                this.auditTrails = [];
                for (const title in historyPerInformedConsent) {
                    const trail = _.sortBy(historyPerInformedConsent[title], h => h.validFrom).reverse();
                    const grid = this.buildAuditGrid();
                    grid.setData(trail);
                    this.auditTrails.push(<IAuditTrail>{
                        grid: grid,
                        name: title
                    });
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private deleteInformedConsentModal(informedConsent: IPatientInformedConsentSummary) {
            this.modalBuilderFactory
                .createComponentModalBuilder<string>('rcDeleteInformedConsentModal')
                .setBindings({
                   patientName: this.patientName,
                })
                .setResultCallBack(async r => {
                    try {
                        await this.informedConsentSvc.revokePatientInformedConsent(
                            this.patientId,
                            informedConsent.InformedConsentId,
                            r
                        );
                        this.informedConsentsToAccept.push(informedConsent);
                        this.initializeAuditTrail();
                    } catch (e) {
                        this.acceptedInformedConsents.push(informedConsent);
                        this.toaster.error(e);
                    }
                })
                .setDismissCallBack(() => this.acceptedInformedConsents.push(informedConsent))
                .build();
        }

        private buildAuditGrid(): Shared.Framework.Grid.Grid<InformedConsentHistoryPart> {
            return this.gridBuilderSvc
                .createGridBuilder<InformedConsentHistoryPart>()
                .addColumn('version', 'General.Version', { width: 70 })
                .addEnumColumn(
                    'patientInformedConsentStateCode',
                    'Views.PatientFile.InformedConsents.Action',
                    Shared.Framework.ReferenceDataTypes.PatientInformedConsentHistoryType,
                    { width: 100 }
                )
                .addColumn('stateSetByPartyRole', 'General.By')
                .addDateColumn('validFrom', 'General.ValidFrom', 'short', { width: 100 })
                .addDateColumn('validUntil', 'General.ValidUntil', 'short', { width: 100 })
                .addColumn('revokedByPartyRole', 'Views.PatientFile.InformedConsents.RevokedBy')
                .addTranslateColumn('revokedRemark', 'General.Remark')
                .build();
        }

        private buildGrids(): void {
            this.informedConsentGrid = this.gridBuilderSvc
                .createGridBuilder<IPatientInformedConsentSummary>()
                .addColumn('TherapyName', 'General.Therapy')
                .addColumn('Title', 'General.Name')
                .addColumn('Version', 'General.Version')
                .addConditionalShowDeleteButtonColumn(
                    ic => this.authservice.getProfile() !== Shared.Contract.Code.ApplicationProfileType.Superuser,
                    ic => this.deleteInformedConsentModal(ic)
                )
                .build();
        }
    }

    Patient.remeCarePatientModule.component('rcInformedConsentModal', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            informedConsentsToAccept: '<',
            acceptedInformedConsents: '<',
            informedConsents: '<',
            patientId: '<',
            patientName: '<',
        },
        controller: InformedConsentModalController,
        templateUrl: 'patient/components/informedConsentModal/informedConsentModal.html'
    });
}
