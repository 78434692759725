/// <reference path="../../../Remedus.RemeCare.Web.Client.Shared/dist/js/remecare-shared.d.ts"/>
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        CarePlanAction.remeCareCarePlanActionModule = angular.module('RemeCare.CarePlanAction', []);
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Agenda;
    (function (Agenda) {
        Agenda.remeCareAgendaModule = angular.module('RemeCare.Agenda', []);
    })(Agenda = RemeCare.Agenda || (RemeCare.Agenda = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        CareRequest.remeCareCareRequestModule = angular.module('RemeCare.CareRequest', []);
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        Patient.remeCarePatientModule = angular.module('RemeCare.Patient', []);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Management;
    (function (Management) {
        Management.remeCareManagementModule = angular.module('RemeCare.Management', []);
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Inbox;
    (function (Inbox) {
        Inbox.remeCareInboxModule = angular.module('RemeCare.Inbox', []);
    })(Inbox = RemeCare.Inbox || (RemeCare.Inbox = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Custom;
    (function (Custom) {
        Custom.remeCareCustomModule = angular.module('RemeCare.Custom', []);
    })(Custom = RemeCare.Custom || (RemeCare.Custom = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var SelfService;
    (function (SelfService) {
        SelfService.remeCareSelfServiceModule = angular.module('RemeCare.SelfService', []);
    })(SelfService = RemeCare.SelfService || (RemeCare.SelfService = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var OneTimeLogin;
    (function (OneTimeLogin) {
        OneTimeLogin.remeCareOneTimeLoginModule = angular.module('RemeCare.OneTimeLogin', []);
    })(OneTimeLogin = RemeCare.OneTimeLogin || (RemeCare.OneTimeLogin = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Connections;
    (function (Connections) {
        Connections.remeCareConnectionsModule = angular.module('RemeCare.Connections', []);
    })(Connections = RemeCare.Connections || (RemeCare.Connections = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var Guidance;
    (function (Guidance) {
        Guidance.remeCareGuidanceModule = angular.module('RemeCare.Guidance', []);
    })(Guidance = RemeCare.Guidance || (RemeCare.Guidance = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    var IntegrationMonitor;
    (function (IntegrationMonitor) {
        IntegrationMonitor.remeCareIntegrationMonitorModule = angular.module('RemeCare.IntegrationMonitor', []);
    })(IntegrationMonitor = RemeCare.IntegrationMonitor || (RemeCare.IntegrationMonitor = {}));
})(RemeCare || (RemeCare = {}));
(function (RemeCare) {
    RemeCare.remeCareAppModule = angular
        .module('RemeCareApp', [
        'ngAnimate',
        'ngCookies',
        'ngSanitize',
        'ui.bootstrap.collapse',
        'ui.bootstrap.dropdown',
        'ui.bootstrap.modal',
        'ui.bootstrap.modal.dialog',
        'ui.bootstrap.pagination',
        'ui.bootstrap.popover',
        'ui.bootstrap.tabs',
        'ui.bootstrap.tpls',
        'ui.bootstrap.typeahead',
        'ui.bootstrap-slider',
        'ui.calendar',
        'ui.grid',
        'ui.grid.autoResize',
        'ui.grid.expandable',
        'ui.grid.resizeColumns',
        'ui.validate',
        'uuid4',
        'mgcrea.ngStrap.select',
        'pascalprecht.translate',
        'highcharts-ng',
        'frapontillo.bootstrap-switch',
        'tmh.dynamicLocale',
        'templates',
        'ui.tinymce',
        RemeCare.Shared.remeCareSharedModule.name,
        RemeCare.Patient.remeCarePatientModule.name,
        RemeCare.CareRequest.remeCareCareRequestModule.name,
        RemeCare.CarePlanAction.remeCareCarePlanActionModule.name,
        RemeCare.Agenda.remeCareAgendaModule.name,
        RemeCare.Management.remeCareManagementModule.name,
        RemeCare.Inbox.remeCareInboxModule.name,
        RemeCare.Custom.remeCareCustomModule.name,
        RemeCare.SelfService.remeCareSelfServiceModule.name,
        RemeCare.OneTimeLogin.remeCareOneTimeLoginModule.name,
        RemeCare.Connections.remeCareConnectionsModule.name,
        RemeCare.Guidance.remeCareGuidanceModule.name,
        RemeCare.IntegrationMonitor.remeCareIntegrationMonitorModule.name,
    ])
        .config(function ($provide) {
        // Override the ScrollEvent to allow scrolling over grids
        $provide.decorator('ScrollEvent', function ($delegate) {
            $delegate.prototype.atTop = function (scrollTop) {
                return true;
            };
            $delegate.prototype.atBottom = function (scrollTop) {
                return true;
            };
            return $delegate;
        });
    });
    RemeCare.Bootstrap.remeBootModule.constant('appName', RemeCare.remeCareAppModule.name);
    // Shared.Framework.useAngularModule(remeCareAppModule);
})(RemeCare || (RemeCare = {}));
