namespace RemeCare.CarePlanAction {
    export interface IDefaultCarePlanActionScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        registeredCallback: FunctionConstructor;
        cancelledCallback: () => void;
        registerFunctions: (functions: { functions: RemeCare.CarePlanAction.ICarePlanActionModalFunctions }) => void;
        canEdit: () => Promise<boolean>;
        action: CarePlanAction;
        readOnly: boolean;
        showErrors: boolean;
        registrationForm: ng.IFormController;
    }

    export class DefaultCarePlanActionController<TScope extends IDefaultCarePlanActionScope> extends Shared.Framework
        .ControllerBase<TScope> {
        constructor(
            protected $scope: TScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            protected $rootScope: ng.IRootScopeService,
            protected $state: ng.ui.IStateService,
            protected $dialog: Shared.Service.DialogService,
            protected $window: ng.IWindowService,
            protected carePlanActionApiSvc: Core.Services.CarePlanActionApiService,
            protected modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
        ) {
            super($scope, $translate, toaster);
            this.$scope.registerFunctions({
                functions: {
                    register: (permanent) => this.registerAsync(permanent),
                    cancelRegistrations: () => this.cancelRegistrationsAsync(),
                    cancel: () => this.cancel(),
                    hasPhoneCall: () => this.hasPhoneCall(),
                    planNewContact: (i) => this.planNewContact(i),
                },
            });
        }

        public $onInit(): void { }

        public canEdit(): boolean {
            return this.$scope.action.canEditAction;
        }

        protected makeRegistrationAsync<T extends Contract.CarePlanAction.Write.IMakeRegistration>(
            params: T,
            errorCallback?: (httpError: angular.IHttpPromiseCallbackArg<string>) => void
        ): Promise<void> {
            return this.carePlanActionApiSvc.makeRegistrationsAsync(params, errorCallback);
        }

        private cancel(): void {
            if (this.$scope.cancelledCallback) {
                this.$scope.cancelledCallback();
            }
        }

        private async cancelRegistrationsAsync(): Promise<void> {
            const result = await this.$dialog.messageBox(
                this.$translate.instant('Views.Action.General.CancelRegistrations'),
                this.$translate.instant('Views.Action.General.CancelRegistrationsConfirm'),
                [
                    { result: 'cancel', label: this.$translate.instant('General.No'), cssClass: 'btn-default' },
                    { result: 'yes', label: this.$translate.instant('General.Yes'), cssClass: 'btn-primary' },
                ]
            );

            if (result === 'cancel') {
                return;
            }
            const carePlanActionIds = _.map(this.$scope.action.flattenActions(), (carePlanAction: CarePlanAction) => {
                return carePlanAction.carePlanActionId;
            });

            try {
                await this.carePlanActionApiSvc.cancelCarePlanActionsAsync(carePlanActionIds);
                this.toaster.success(this.$translate.instant('Views.Action.General.CancelRegistrationsConfirmed'));
                this.callBackFunction();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private callBackFunction(): void {
            if (this.$scope.registeredCallback && _.isFunction(this.$scope.registeredCallback)) {
                const callBackFunc = this.$scope.registeredCallback();
                if (_.isFunction(callBackFunc)) {
                    callBackFunc(this.$scope.action);
                }
            }
        }

        private async registerAsync(
            permanent: boolean,
            reschedule?: Contract.CarePlanAction.Write.ISchedule
        ): Promise<void> {
            if (this.$scope.readOnly) {
                return;
            }
            this.$scope.registrationForm.$setSubmitted();
            this.$scope.showErrors = true;
            if (permanent && this.$scope.registrationForm.$invalid) {
                this.showValidationErrorMessage();
                return;
            }
            const params = this.$scope.action.toServerWrite(permanent);
            params.reschedule = reschedule;
            try {
                await this.makeRegistrationAsync(params, (e) => this.makeRegistrationErrorCallback(e));
                if (permanent) {
                    this.toaster.success(this.$translate.instant('Views.Action.General.RegistrationSuccess'));
                    this.$scope.action.status = Contract.Core.Codes.CarePlanActionStatus.Completed;
                } else if (reschedule) {
                    this.toaster.success(this.$translate.instant('General.SaveSuccess'));
                } else {
                    this.toaster.success(this.$translate.instant('Views.Action.General.RegistrationTemporarySaved'));
                }
                this.callBackFunction();
            } catch (e) {
                // Error handling is done in error callback
            }
        }

        private makeRegistrationErrorCallback(httpError: angular.IHttpPromiseCallbackArg<string>): void {
            this.toaster.error(httpError.data);
            this.$rootScope.$broadcast('actionSaveError', httpError.headers('X-Errors'));
        }

        private hasPhoneCall(): boolean {
            return this.getPhoneCallComponent() != null;
        }

        private getPhoneCallComponent(): CarePlanAction.PhoneCallDescriptor {
            return _.find(
                this.$scope.action.components,
                (c) => c.actionTypeCode === Shared.Contract.Code.ActionType.TelephoneCallPlanning
            ) as CarePlanAction.PhoneCallDescriptor;
        }

        private planNewContact(includeParts: boolean): void {
            const phoneCall = this.getPhoneCallComponent();
            if (!phoneCall) {
                return;
            }
            this.modalBuilderFactory
                .createModalBuilder<{
                    from: string;
                    until: string;
                    remark: string;
                    type: Shared.Contract.Code.ActionType;
                }>()
                .setController('planNewContactCtrl')
                .setTemplateUrl('views/carePlanAction/contact/planNewContactModal.html')
                .setScope({
                    remark: phoneCall.value,
                    hasTemporarySave: includeParts,
                    actionName: this.$scope.action.name,
                })
                .setResultCallBack((r) => {
                    if (!includeParts) {
                        this.$scope.action.components = _(this.$scope.action.components).filter(
                            (c) =>
                                c.actionTypeCode === Shared.Contract.Code.ActionType.TelephoneCallPlanning ||
                                c.actionTypeCode === Shared.Contract.Code.ActionType.AberrantRegistrations
                        );
                    }
                    phoneCall.value = r.remark;
                    this.registerAsync(false, {
                        from: r.from,
                        until: r.until,
                        type: Shared.Contract.Code.ActionType.TelephoneCallPlanning,
                        reason: r.remark,
                        durationOfAction: null,
                        possibleActionDurationOptionId: null,
                    });
                })
                .build();
        }
    }

    remeCareCarePlanActionModule.controller('defaultCarePlanActionCtrl', DefaultCarePlanActionController);
}
