namespace RemeCare.Model {
    import Guid = Shared.Contract.Guid;
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EnumCodeTranslation = Shared.Contract.IEnumCodeTranslation;
    import CommunicationTypePreference = Shared.Contract.Code.CommunicationTypePreference;

    export class Person {
        public id: Guid;
        public partyId: Guid;
        public firstName: string;
        public lastName: string;
        public middleName: string;
        public initials: string;
        public title: EnumTranslation;
        public gender: EnumTranslation;
        public dateOfBirth: Date;
        public dateOfDeath: Date;
        public language: EnumCodeTranslation;
        public communicationLanguage: EnumCodeTranslation;
        public nationality: EnumCodeTranslation;
        public maritalStatus: EnumTranslation;
        public educationLevel: EnumTranslation;
        public technologicalAffinities: EnumTranslation[];
        public nationalNumber: string;
        public addresses: Address[] = [];
        public telephoneNumbers: TelephoneNumber[] = [];
        public mobileNumbers: TelephoneNumber[] = [];
        public faxNumbers: TelephoneNumber[] = [];
        public emailAddresses: Email[] = [];
        public partyRoles: Contract.Party.Read.IPartyRole[] = [];
        public communicationPreference: CommunicationTypePreference;

        constructor(def?: Contract.Party.Read.IPersonDetail) {
            if (def != null) {
                this.id = def.Id;
                this.partyId = def.PartyId;
                this.firstName = def.FirstName;
                this.lastName = def.LastName;
                this.middleName = def.MiddleName;
                this.initials = def.Initials;
                this.title = def.Title;
                this.gender = def.Gender;
                this.dateOfBirth = Shared.DateHelper.serverDateStringToDate(def.DateOfBirth);
                this.dateOfDeath = Shared.DateHelper.serverDateStringToDate(def.DateOfDeath);
                this.language = def.Language;
                this.communicationLanguage = def.CommunicationLanguage;
                this.nationality = def.Nationality;
                this.maritalStatus = def.MaritalStatus;
                this.educationLevel = def.EducationLevel;
                this.technologicalAffinities = def.TechnologicalAffinities || [];
                this.nationalNumber = def.NationalNumber;
                this.communicationPreference = def.CommunicationPreference;
                this.addresses = _(def.Addresses).map((a) => new Address(a));
                this.telephoneNumbers = _(def.TelephoneNumbers).map((t) => new TelephoneNumber(t));
                this.mobileNumbers = _(def.MobileNumbers).map((m) => new TelephoneNumber(m));
                this.faxNumbers = _(def.FaxNumbers).map((f) => new TelephoneNumber(f));
                this.emailAddresses = _(def.EmailAddresses).map((e) => new Email(e));
                this.partyRoles = def.PartyRoles || [];
            }
        }

        public getAllNumbers(): TelephoneNumber[] {
            return _.union(this.telephoneNumbers, this.mobileNumbers, this.faxNumbers);
        }

        public getDefaultAddress(): Address {
            const addresses = _(this.addresses).filter(
                (a) =>
                    a.validFromDate <= Shared.DateHelper.today() &&
                    (a.validUntilDate >= Shared.DateHelper.today() || a.validUntilDate === null)
            );
            const address = _.find(
                addresses,
                (a) => a.contactPointUsageType === Shared.Contract.Code.ContactPointUsageType.Residence
            );
            if (address != null) {
                return address;
            }
            return _.find(
                addresses,
                (a) => a.contactPointUsageType === Shared.Contract.Code.ContactPointUsageType.Domicile
            );
        }

        public getDefaultTelephone(): TelephoneNumber {
            const telephoneNumbers = _(this.telephoneNumbers).filter(
                (a) =>
                    a.validFromDate <= Shared.DateHelper.today() &&
                    (a.validUntilDate >= Shared.DateHelper.today() || a.validUntilDate === null)
            );
            return (
                _(telephoneNumbers)
                    .chain()
                    .sortBy((t) => t.contactPointUsageType)
                    .first()
                    .value() || new TelephoneNumber()
            );
        }

        public getDefaultMobile(): TelephoneNumber {
            const mobileNumbers = _(this.mobileNumbers).filter(
                (a) =>
                    a.validFromDate <= Shared.DateHelper.today() &&
                    (a.validUntilDate >= Shared.DateHelper.today() || a.validUntilDate === null)
            );
            return (
                _(mobileNumbers)
                    .chain()
                    .sortBy((t) => t.contactPointUsageType)
                    .first()
                    .value() || new TelephoneNumber()
            );
        }

        public getDefaultEmail(): Email {
            const emailAddresses = _(this.emailAddresses).filter(
                (a) =>
                    a.validFromDate <= Shared.DateHelper.today() &&
                    (a.validUntilDate >= Shared.DateHelper.today() || a.validUntilDate === null)
            );
            return _(emailAddresses)
                .chain()
                .sortBy((t) => t.contactPointUsageType)
                .first()
                .value();
        }

        public getPersonalInfo(): Contract.Party.Write.IPersonalInfo {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                title: this.title != null ? this.title.Id : null,
                middleName: this.middleName,
                initials: this.initials,
                gender: this.gender != null ? this.gender.Id : null,
                dateOfBirth: Shared.DateHelper.toServerDateString(this.dateOfBirth),
                dateOfDeath: Shared.DateHelper.toServerDateString(this.dateOfDeath),
                nationalNumber: this.nationalNumber,
                communicationLanguage: this.communicationLanguage != null ? this.communicationLanguage.Id : null,
                language: this.language != null ? this.language.Id : null,
                nationality: this.nationality != null ? this.nationality.Id : null,
                maritalStatus: this.maritalStatus != null ? this.maritalStatus.Id : null,
                educationLevel: this.educationLevel != null ? this.educationLevel.Id : null,
                technologicalAffinities: _(this.technologicalAffinities).map((t) => t.Id),
            };
        }

        public isPatient(): boolean {
            return _(this.partyRoles).any((pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.Patient);
        }

        public getPatientPartyRole(): Contract.Party.Read.IPatient {
            return _(this.partyRoles).find(
                (pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.Patient
            ) as Contract.Party.Read.IPatient;
        }

        public isEmployee(): boolean {
            return _(this.partyRoles).any((pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.Employee);
        }

        public getEmployeePartyRole(): Contract.Party.Read.IPartyRole {
            return _.find(this.partyRoles, (pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.Employee);
        }

        public isHealthCareProfessional(): boolean {
            return _(this.partyRoles).any(
                (pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional
            );
        }

        public getHealthCareProfessionalPartyRole(): Contract.Party.Read.IHealthCareProfessional {
            return _(this.partyRoles).find(
                (pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.HealthCareProfessional
            ) as Contract.Party.Read.IHealthCareProfessional;
        }

        public getContactPartyRoleForPatient(patientId: Guid): Contract.Party.Read.IContactPerson {
            const contactPersons = _(this.partyRoles).filter(
                (pr) => pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.ContactPerson
            ) as Contract.Party.Read.IContactPerson[];
            return _(contactPersons.filter((pr) => pr.ContactFor.PartyRoleId === patientId)).first();
        }

        public getCurrentRelations(): Contract.Party.Read.ICareProviderRelation[] {
            const role = this.getHealthCareProfessionalPartyRole();
            if (role == null) {
                return [];
            }
            const today = Shared.DateHelper.today();
            return _(role.CareProviderRelations).filter((cpr) => {
                return (
                    !moment(Shared.DateHelper.serverDateStringToDate(cpr.ValidFromDate)).isAfter(today) &&
                    (cpr.ValidUntilDate == null ||
                        !moment(Shared.DateHelper.serverDateStringToDate(cpr.ValidUntilDate)).isBefore(today))
                );
            });
        }

        public getOldRelations(): Contract.Party.Read.ICareProviderRelation[] {
            const role = this.getHealthCareProfessionalPartyRole();
            if (role == null) {
                return [];
            }
            const today = Shared.DateHelper.today();
            return _(role.CareProviderRelations).filter((cpr) => {
                return moment(Shared.DateHelper.serverDateStringToDate(cpr.ValidUntilDate)).isBefore(today);
            });
        }
    }

    export class PatientPerson extends Person {
        public healthInsuranceFunds: HealthInsuranceFundAssignment[] = [];
        public externalReferences: RemeCare.Contract.Core.IExternalReference[] = [];
        public remark: string;

        constructor(def?: Contract.Party.Read.IPatientDetail) {
            super(def);
            if (def != null) {
                this.healthInsuranceFunds = _(def.HealthInsuranceFunds)
                    .chain()
                    .map((h) => new HealthInsuranceFundAssignment(h))
                    .sortBy((h) => h.validFromDate)
                    .value();
                this.externalReferences = def.ExternalReferences || [];
                this.remark = def.Remark;
            }
        }

        public getCurrentInsuranceFund(): HealthInsuranceFundAssignment {
            return _.find(
                this.healthInsuranceFunds,
                (h) => h.validUntilDate == null || moment().isBefore(h.validUntilDate)
            );
        }

        public toRegisterPatient(): Contract.Patient.Write.IRegisterPatient {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                middleName: this.middleName,
                initials: this.initials,
                title: this.title ? this.title.Id : null,
                gender: this.gender ? this.gender.Id : null,
                dateOfBirth: Shared.DateHelper.toServerDateString(this.dateOfBirth),
                nationalNumber: this.nationalNumber,
                communicationLanguage: this.communicationLanguage ? this.communicationLanguage.Code : null,
                maritalStatus: this.maritalStatus ? this.maritalStatus.Id : null,
                language: this.language ? this.language.Code : null,
                educationLevel: this.educationLevel ? this.educationLevel.Id : null,
                nationality: this.nationality ? this.nationality.Code : null,
                technologyAffinity: _(this.technologicalAffinities).map((ta) => ta.Id),
                contactPoints: _(this.addresses as ContactPoint[])
                    .chain()
                    .union(this.telephoneNumbers)
                    .union(this.emailAddresses)
                    .filter((cp) => !cp.isEmpty())
                    .map((cp) => cp.toContactPointParameters())
                    .value(),
            } as Contract.Patient.Write.IRegisterPatient;
        }
    }

    export class HealthInsuranceFundAssignment {
        public id: number;
        public healthInsuranceFund: Shared.Contract.Read.IHealthInsuranceFund;
        public healthInsuranceRiskCode: string;
        public validFromDate: Date;
        public validUntilDate: Date;

        constructor(def?: Contract.Party.Read.IHealthInsuranceFundAssignment) {
            if (def != null) {
                this.id = def.Id;
                this.healthInsuranceFund = def.HealthInsuranceFund;
                this.healthInsuranceRiskCode = def.HealthInsuranceRiskCode;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(def.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(def.ValidUntilDate);
            }
        }

        public getCodeAndName(): string {
            return this.healthInsuranceFund.Code + ' - ' + this.healthInsuranceFund.Text;
        }

        public toServerWrite(): Contract.Party.Write.IHealthInsuranceFundAssignment {
            return {
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                healthInsuranceFund: this.healthInsuranceFund.Id,
                healthInsuranceRiskCode: this.healthInsuranceRiskCode,
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
            };
        }
    }

    export class ContactPersonPerson extends Person {
        public relationship: EnumTranslation;
        public isHealthCareNonProfessional: boolean;
        public matchedPartyId: Guid;
        public remarkInternalUse: string;
        public partyRoleId: Guid;
        public isActive: boolean;

        constructor(
            personDetail?: Contract.Party.Read.IPersonDetail,
            roleInfo?: Contract.HealthCareParty.Read.IContactPerson
        ) {
            super(personDetail);
            if (roleInfo != null) {
                this.relationship = roleInfo.Relationship;
                this.isHealthCareNonProfessional = roleInfo.IsHealthCareNonProfessional;
                this.remarkInternalUse = roleInfo.Remark;
                this.partyRoleId = roleInfo.PartyRoleId;
                this.isActive = roleInfo.IsActive;
            }
        }

        public getPartyRoleNotLinkedToPatient(patientId: Guid): Contract.Party.Read.IPartyRole[] {
            return _(this.partyRoles).filter(
                (pr) =>
                    !(
                        pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.ContactPerson &&
                        (pr as Contract.Party.Read.IContactPerson).ContactFor.PartyRoleId === patientId
                    )
            );
        }

        public hasPartyRolesNotLinkedToPatient(patientId: Guid): boolean {
            return _(this.partyRoles).any(
                (pr) =>
                    !(
                        pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.ContactPerson &&
                        (pr as Contract.Party.Read.IContactPerson).ContactFor.PartyRoleId === patientId
                    ) &&
                    !(
                        pr.PartyRoleType.Id === Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional &&
                        _((pr as Contract.Party.Read.IHealthCareNonProfessional).NonProfessionalForPatients).all(
                            (fp) => fp.Patient.PartyRoleId === patientId
                        )
                    )
            );
        }

        public updateContactPoints(person: Contract.Party.Read.IPersonDetail) {
            this.addresses = _(person.Addresses).map((a) => new Address(a));
            this.telephoneNumbers = _(person.TelephoneNumbers).map((t) => new TelephoneNumber(t));
            this.mobileNumbers = _(person.MobileNumbers).map((m) => new TelephoneNumber(m));
            this.faxNumbers = _(person.FaxNumbers).map((f) => new TelephoneNumber(f));
            this.emailAddresses = _(person.EmailAddresses).map((e) => new Email(e));
        }

        public toRegisterContactPerson(): Contract.Party.Write.IContactPersonInfo {
            const personalInfo = this.getPersonalInfo() as Contract.Party.Write.IContactPersonInfo;
            personalInfo.RelationshipId = this.relationship ? this.relationship.Id : null;
            personalInfo.IsHealthCareNonProfessional = this.isHealthCareNonProfessional;
            personalInfo.MatchedPartyId = this.id;
            personalInfo.RemarkInternalUse = this.remarkInternalUse;
            personalInfo.ContactPoints = _(this.addresses as ContactPoint[])
                .chain()
                .union(this.telephoneNumbers)
                .union(this.emailAddresses)
                .filter((cp) => !cp.isEmpty())
                .map((cp) => cp.toContactPointParameters())
                .value();
            personalInfo.IsActive = this.isActive;

            return personalInfo;
        }
    }
}
