/// <reference path="./monitoringPartComponentBase.ts"/>

namespace RemeCare.Patient {
    import MonitoringPartSourceType = Shared.Contract.Code.MonitoringPartSourceType;
    import QuantitativeMeasuringPoint = Contract.CarePlan.Read.Query.IQuantitativeMeasuringPoint;
    import QuantitativeReferencePoint = Contract.CarePlan.Read.Query.IQuantitativeReferencePoint;
    import MonitoringPartSourceParameter = Shared.Framework.Model.MonitoringPartSourceParameter;
    import ChartLine = Shared.Framework.Model.ChartLine;
    import IParameter = Contract.CarePlan.Read.IParameter;

    class LineGraphController extends ChartMonitoringPartController {
        private quantitativeMeasuringPoints: QuantitativeMeasuringPoint[];
        private quantitativeReferencePoints: QuantitativeReferencePoint[];
        private showMultiplePerDay: boolean;

        // @ngInject
        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $locale: ng.ILocaleService,
            protected dateHelper: Shared.DateHelper,
            protected spinnerSvc: Shared.Framework.Service.SpinnerService,
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $q: ng.IQService,
            private readonly carePlanApiSvc: Core.Services.CarePlanApiService
        ) {
            super($rootScope, $locale, dateHelper, spinnerSvc);
        }

        protected init(): ng.IPromise<void> {
            this.chartConfigs = [
                {
                    options: {
                        chart: {
                            animation: false,
                            type: 'line',
                            alignTicks: true,
                            height: 200,
                            zoomType: 'xy',
                            spacingTop: 40,
                            marginLeft: 37,
                            marginRight: 37,
                        },
                        xAxis: this.getXAxisConfig(null, true),
                        credits: {
                            enabled: false,
                        },
                        exporting: {
                            enabled: false,
                        },
                        tooltip: {
                            xDateFormat: this.getDateFormat(false),
                            shared: true,
                        },
                        legend: {
                            enabled: this.showLegend,
                        },
                    },
                    series: [],
                    title: {
                        text: null,
                    },
                } as HighChartsNGConfig,
            ];

            this.quantitativeMeasuringPoints = this.getQuantitativeMeasuringPoints();
            this.quantitativeReferencePoints = this.getQuantitativeReferencePoints();
            return this.$q.resolve();
        }

        protected async onDateChange(): Promise<void> {
            const oneMonthEarlier = moment(this.dateInfo.untilDate);
            oneMonthEarlier.subtract(1, 'months');
            this.showMultiplePerDay = !oneMonthEarlier.isAfter(this.dateInfo.fromDate);
            this.chartConfigs[0].options.tooltip.xDateFormat = this.getDateFormat(false);
            const pointsPromise = this.carePlanApiSvc.findQuantitativeGraphPointsAsync(
                this.carePlanIds,
                this.quantitativeMeasuringPoints,
                this.dateInfo.fromDate,
                this.getUntilDate(),
                this.showMultiplePerDay
            );
            const referencePromise = this.carePlanApiSvc.findQuantitativeReferencePointsAsync(
                this.carePlanIds,
                this.quantitativeReferencePoints,
                this.dateInfo.fromDate,
                this.getUntilDate()
            );
            try {
                let graphPoints: Array<Contract.Core.IGraph<IParameter<number>>>;
                let referencePoints: Array<Contract.Core.IGraph<number>>;
                [graphPoints, referencePoints] = await Promise.all([pointsPromise, referencePromise]);
                const graphs = _(graphPoints).map((g) => new Model.NumberParameterGraph(g, !this.showMultiplePerDay));
                const references = _(referencePoints).map((g) => new Model.NumberGraph(g, true, true));
                this.configureChart(graphs, references);
            } catch (error) {
                this.toaster.error(error);
            }
        }

        private configureChart(graphs: Model.NumberParameterGraph[], references: Model.NumberGraph[]): void {
            this.chartConfigs[0].options.xAxis = this.getXAxisConfig(null, !this.showMultiplePerDay);
            const yAxes = this.getYAxis(_(graphs as Array<Model.NumericGraph<any>>).union(references));
            this.chartConfigs[0].options.yAxis = yAxes;
            this.chartConfigs[0].series = _(graphs)
                .chain()
                .map((g) => this.getDataSeries(g, false, yAxes))
                .union(
                    _(references)
                        .filter((r) => r.scale != null)
                        .map((g) => this.getDataSeries(g, true, yAxes))
                )
                .value();
            this.chartConfigs[0].loading = false;
        }

        private getYAxis(graphs: Array<Model.NumericGraph<any>>): HighchartsAxisOptions[] {
            const units = _(graphs)
                .chain()
                .map((g) => g.scale)
                .uniq(false, (s) => (s != null ? s.Id : null))
                .value();
            const axes = _(units).map((u) => {
                const max = _(graphs)
                    .chain()
                    .filter(
                        (g) => (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id)
                    )
                    .map((g) => this.getMaxYScaleValue(g))
                    .max()
                    .value();
                const min = _(graphs)
                    .chain()
                    .filter(
                        (g) => (g.scale == null && u == null) || (g.scale != null && u != null && g.scale.Id === u.Id)
                    )
                    .map((g) => this.getMinYScaleValue(g))
                    .min()
                    .value();
                return {
                    title: {
                        text: u != null ? u.Text : null,
                    },
                    max: max,
                    min: min,
                    id: u != null ? (u.Id as string) : 'unscaled',
                } as HighchartsAxisOptions;
            });
            const groupedAxes = _(axes)
                .chain()
                .groupBy((a) => `${a.min};${a.max}`)
                .map((ag) => {
                    return {
                        title: {
                            align: 'high',
                            offset: 0,
                            rotation: 0,
                            text: _(ag)
                                .chain()
                                .map((a) => a.title.text)
                                .filter((t) => t != null)
                                .value()
                                .join(' - '),
                            y: -20,
                            x: -27,
                            textAlign: 'left',
                        },
                        max: _(ag).first().max,
                        min: _(ag).first().min,
                        id: _(ag)
                            .map((a) => a.id)
                            .join(';'),
                        startOnTick: this.monitoringPart.ordinatePercentageBelowLowest != null,
                        endOnTick: this.monitoringPart.ordinatePercentageAboveHighest != null,
                    } as HighchartsAxisOptions;
                })
                .each((a, index) => {
                    a.opposite = index % 2 === 1;
                    if (a.opposite) {
                        (a.title as any).textAlign = 'right';
                        a.title.x = 27;
                        a.labels = {
                            align: 'left',
                            x: 0,
                            y: -2,
                        };
                    } else {
                        a.labels = {
                            align: 'right',
                            x: 0,
                            y: -2,
                        };
                    }
                })
                .value();

            if (!groupedAxes[0] || !groupedAxes[0].title.text) {
                this.chartConfigs[0].options.chart.marginLeft = 37;
            }
            if (!groupedAxes[1] || !groupedAxes[1].title.text) {
                this.chartConfigs[0].options.chart.marginRight = 37;
            }

            return groupedAxes;
        }

        private getDataSeries(
            graph: Model.NumericGraph<number | IParameter<number>>,
            isReference: boolean,
            yAxes: HighchartsAxisOptions[]
        ): HighchartsLineChartSeriesOptions {
            let monitoringPartSource: MonitoringPartSourceParameter;
            if (isReference) {
                monitoringPartSource = _.find(
                    this.monitoringPart.monitoringPartSources,
                    (mps) =>
                        (mps.type === MonitoringPartSourceType.QuantitativeReferenceParameterAnamnesis &&
                            mps.hasCharacteristicAndUnit(graph.subject, graph.scale)) ||
                        mps.hasObjective(graph.subject) ||
                        mps.hasRuleThreshold(graph.subject)
                ) as MonitoringPartSourceParameter;
            } else {
                monitoringPartSource = _.find(
                    this.monitoringPart.monitoringPartSources,
                    (mps) =>
                        mps.type === MonitoringPartSourceType.QuantitativeMeasuringPointParameter &&
                        mps.hasCharacteristicAndUnit(graph.subject, graph.scale)
                ) as MonitoringPartSourceParameter;
            }
            const chartLine = monitoringPartSource.sourceParameterRepresentation as ChartLine;
            const id = graph.scale ? (graph.scale.Id as string) : 'unscaled';
            const yAxis = _.find(yAxes, (a) => a.id.indexOf(id) >= 0);
            return {
                animation: false,
                data: _(graph.graphPoints)
                    .chain()
                    .map((gp) => this.getDataPoint(gp))
                    .sortBy((gp) => gp[0])
                    .value(),
                color: chartLine.colour,
                name: graph.subject.Text,
                lineWidth: chartLine.lineType.Id === Shared.Contract.Code.LineType.Thin ? 1 : 2,
                dashStyle: chartLine.lineType.Id === Shared.Contract.Code.LineType.Dashed ? 'Dash' : 'Solid',
                yAxis: yAxis.id,
                step: isReference,
                isReference: isReference,
                tooltip: {
                    xDateFormat: this.getDateFormat(false),
                },
                marker: {
                    enabled: !isReference,
                    symbol: isReference ? 'circle' : null,
                    radius: isReference ? 1 : 4,
                },
            } as HighchartsLineChartSeriesOptions;
        }

        private getDataPoint(graphPoint: Model.GraphPoint<Date, number | IParameter<number>>): HighchartsDataPoint {
            let y: number;
            let exceedsThreshold: boolean;
            if (_.isNumber(graphPoint.y)) {
                y = (graphPoint as Model.GraphPoint<Date, number>).y;
                exceedsThreshold = false;
            } else {
                y = (graphPoint as Model.GraphPoint<Date, IParameter<number>>).y.Value;
                exceedsThreshold = (graphPoint as Model.GraphPoint<Date, IParameter<number>>).y.ExceedsThreshold;
            }
            return {
                x: moment(graphPoint.x).valueOf(),
                y: y,
                marker: exceedsThreshold
                    ? {
                          symbol: 'text:\uf071',
                          fillColor: '#d9534f',
                      }
                    : null,
            };
        }
    }

    class LineGraphComponent extends MonitoringPartComponentBase {
        public controller = LineGraphController;

        public templateUrl = 'views/patient/monitoring/dashboard/charts.html';
    }

    remeCarePatientModule.component('rcMonitoringLineGraph', new LineGraphComponent());
}
