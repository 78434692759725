var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PartyRoleType = RemeCare.Shared.Contract.Code.PartyRoleType;
        var PatientFileGatewayController = /** @class */ (function () {
            function PatientFileGatewayController(toaster, $state, $stateParams, patientSvc) {
                this.toaster = toaster;
                this.$state = $state;
                this.$stateParams = $stateParams;
                this.patientSvc = patientSvc;
            }
            PatientFileGatewayController.prototype.$onInit = function () {
                var _this = this;
                var query = {
                    nationalNumber: this.$stateParams.nationalNumber,
                    extPhoneNumber: this.$stateParams.extPhoneNumber,
                    System: this.$stateParams.system,
                    Attribute: this.$stateParams.attribute,
                    Value: this.$stateParams.value,
                    partyRoleType: PartyRoleType.Patient
                };
                this.patientSvc.findPartyRoles(query)
                    .success(function (result) {
                    if (result.length === 1) {
                        _this.redirectToPatientFile(result[0]);
                    }
                    else {
                        _this.redirectToSearch();
                    }
                })
                    .error(function (err) { return _this.toaster.error(err); });
            };
            PatientFileGatewayController.prototype.redirectToPatientFile = function (patient) {
                this.$state.go('patients.patientfile', { patientId: patient.Id });
            };
            PatientFileGatewayController.prototype.redirectToSearch = function () {
                this.$state.go('patients');
            };
            return PatientFileGatewayController;
        }());
        angular.module("RemeCare.Patient").controller("PatientFileGatewayCtrl", function (toaster, $state, $stateParams, patientSvc) { return new PatientFileGatewayController(toaster, $state, $stateParams, patientSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
