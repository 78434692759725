var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var CarePlanActionHistory = /** @class */ (function () {
            function CarePlanActionHistory(serverObject) {
                this.auditId = serverObject.AuditId;
                this.auditAction = this.mapAction(serverObject.AuditAction);
                this.auditDate = serverObject.AuditDate;
                this.modificationUser = serverObject.ModificationUser;
                this.plannedDateTimeFrom = serverObject.PlannedDateTimeFrom;
                this.plannedDateTimeUntil = serverObject.PlannedDateTimeUntil;
                this.eventDateTimeFrom = serverObject.EventDateTimeFrom;
                this.eventDateTimeUntil = serverObject.EventDateTimeUntil;
                this.status = serverObject.Status;
                this.statusChangedRemark = serverObject.StatusChangedRemark;
                this.location = serverObject.Location;
                this.locationString = this.mapLocation(serverObject.Location);
            }
            CarePlanActionHistory.prototype.setAddress = function (address) {
                this.locationString = this.mapAddress(address);
            };
            CarePlanActionHistory.prototype.mapAddress = function (address) {
                var mapped = '';
                if (address) {
                    if (address.AddressLine1) {
                        mapped += address.AddressLine1 + ' ';
                    }
                    if (address.ZipCode) {
                        mapped += address.ZipCode + ' ';
                    }
                    if (address.City) {
                        mapped += address.City;
                    }
                }
                return mapped;
            };
            CarePlanActionHistory.prototype.mapLocation = function (location) {
                var mapped = '';
                if (location) {
                    if (location.Street) {
                        mapped += location.Street + ' ';
                    }
                    if (location.ZipCode) {
                        mapped += location.ZipCode + ' ';
                    }
                    if (location.City) {
                        mapped += location.City;
                    }
                }
                return mapped;
            };
            CarePlanActionHistory.prototype.mapAction = function (auditAction) {
                switch (auditAction) {
                    case 'I':
                        return 'General.Added';
                    case 'U':
                        return 'General.Updated';
                    case 'D':
                        return 'General.Deleted';
                    default:
                        return auditAction;
                }
            };
            return CarePlanActionHistory;
        }());
        CarePlanAction.CarePlanActionHistory = CarePlanActionHistory;
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
