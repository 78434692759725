var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PartyAssignmentsModalController = /** @class */ (function () {
            function PartyAssignmentsModalController(authservice, $translate, $filter, modalBuilderFactory, toaster, patientSvc, partyApiSvc, gridBuilderSvc) {
                this.authservice = authservice;
                this.$translate = $translate;
                this.$filter = $filter;
                this.modalBuilderFactory = modalBuilderFactory;
                this.toaster = toaster;
                this.patientSvc = patientSvc;
                this.partyApiSvc = partyApiSvc;
                this.gridBuilderSvc = gridBuilderSvc;
            }
            PartyAssignmentsModalController.prototype.$onInit = function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        this.buildAssignmentsGrid();
                        this.partyAssignmentGrid.setData(_(this.party.Assignments)
                            .sortBy(function (x) { return x.ValidFromDate; })
                            .reverse());
                        return [2 /*return*/];
                    });
                });
            };
            PartyAssignmentsModalController.prototype.showCareTeamPartyDetail = function (partyRoleId) {
                return __awaiter(this, void 0, void 0, function () {
                    var personSummaries, healthCareParty, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.partyApiSvc.findPersonSummaryAsync(partyRoleId)];
                            case 1:
                                personSummaries = _a.sent();
                                healthCareParty = {
                                    FirstName: personSummaries.FirstName,
                                    LastName: personSummaries.LastName,
                                    PartyRoleId: personSummaries.PartyRoleId,
                                    ZipCode: personSummaries.ZipCode,
                                    City: personSummaries.City,
                                    AddressLine: personSummaries.AddressLine,
                                    Email: personSummaries.Email,
                                    IdentificationNumber: personSummaries.NationalNumber,
                                    PhoneNumber: personSummaries.Telephone,
                                    MobileNumber: personSummaries.Mobile,
                                    HealthCareSpecialtyProfessions: undefined,
                                    PartyId: personSummaries.PartyId,
                                };
                                this.showCareTeamProfessionalDetailModalAsync(healthCareParty);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PartyAssignmentsModalController.prototype.showCareTeamAssignmentModalAsync = function (careTeamPartyAssignment, partyRoleId, firstName, lastName) {
                var _this = this;
                return new Promise(function (resolve) {
                    var name = lastName == null ? firstName + " " : firstName + " " + lastName;
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('editCareTeamPartyCtrl')
                        .setTemplateUrl('views/patient/careTeam/editCareTeamPartyAssignment.html')
                        .setResolve({
                        patientId: function () { return careTeamPartyAssignment.CarePlan.PatientId; },
                    })
                        .setScope({
                        partyRoleId: partyRoleId,
                        name: name,
                        careTeamParty: angular.copy(careTeamPartyAssignment),
                        readOnly: !careTeamPartyAssignment.CanEdit,
                    })
                        .setResultCallBack(function (assigment) {
                        _this.reloadParty();
                        resolve(careTeamPartyAssignment);
                    })
                        .build();
                });
            };
            PartyAssignmentsModalController.prototype.getCarePlanStatus = function (assignment) {
                var carePlan = assignment.CarePlan;
                return carePlan.Status.Id === RemeCare.Shared.Contract.Code.CarePlanStatus.Open
                    ? carePlan.Name +
                        this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', {
                            CarePlanStatus: carePlan.Status.Text,
                            StatusDate: this.$filter('date')(carePlan.StartDate, 'shortDate'),
                        })
                    : carePlan.Name +
                        this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', {
                            CarePlanStatus: carePlan.Status.Text,
                            StatusDate: this.$filter('date')(carePlan.StatusChangedDate, 'shortDate'),
                        });
            };
            PartyAssignmentsModalController.prototype.buildAssignmentsGrid = function () {
                var _this = this;
                var builder = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('ActorRole.Text', 'General.Role')
                    .addActionOnScopeColumn('$ctrl.parent.getCarePlanStatus', 'General.CarePlan')
                    .addDateColumn('ValidFromDate', 'General.ValidFrom', 'shortDate', { visibleWhenSmall: false })
                    .addDateColumn('ValidUntilDate', 'General.ValidUntilIncluding', 'shortDate', {
                    visibleWhenSmall: false,
                })
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (p) {
                    return _this.showCareTeamAssignmentModalAsync(p, _this.party.PartyRoleId, _this.party.Name, _this.party.LastName);
                }, function (p) { return !p.CanEdit; });
                if (this.editRight) {
                    builder = builder.addConditionalDeleteButtonColumn(function (c) {
                        return moment(c.ValidFromDate).isBefore(moment()) ||
                            !c.CanEdit ||
                            !_(_this.careTeamsCanEdit).any(function (bcr) { return bcr.CarePlan === c.CarePlan.Id; });
                    }, function () { return _this.reloadParty(); }, function (c) { return _this.deleteCareTeamPartyAsync(c); });
                }
                this.partyAssignmentGrid = builder.build();
            };
            PartyAssignmentsModalController.prototype.deleteCareTeamPartyAsync = function (careTeamPartyAssignment) {
                var _this = this;
                return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.removeActorRoleAssignmentAsync(careTeamPartyAssignment.CarePlan.PatientId, careTeamPartyAssignment.CarePlan.TherapyId, careTeamPartyAssignment.Id)];
                            case 1:
                                _a.sent();
                                resolve(true);
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                resolve(false);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            };
            PartyAssignmentsModalController.prototype.reloadParty = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var query;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                query = {
                                    page: 1,
                                    pageSize: 50,
                                    excludeStopped: false,
                                };
                                return [4 /*yield*/, this.patientSvc.getCareTeamAsync(this.patientId, this.partyType, query).then(function (result) {
                                        var newParty = result.Items.filter(function (p) { return p.PartyRoleId === _this.party.PartyRoleId; });
                                        if (newParty.length > 0) {
                                            _this.party = newParty[0];
                                            _this.partyAssignmentGrid.setData(_this.party.Assignments);
                                        }
                                    })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            PartyAssignmentsModalController.prototype.showCareTeamProfessionalDetailModalAsync = function (healthCareParty) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setTemplateUrl('views/patient/careTeam/careTeamProfessionalDetail.html')
                        .setScope({
                        healthCareParty: healthCareParty,
                    })
                        .setResultCallBack(function () { return resolve(); })
                        .build();
                });
            };
            return PartyAssignmentsModalController;
        }());
        Patient.remeCarePatientModule.component('rcPartyAssigmentsModal', {
            bindings: {
                $close: '&',
                $dismiss: '&',
                party: '<',
                partyType: '<',
                patientId: '<',
                editRight: '<',
                careTeamsCanEdit: '<',
            },
            controller: PartyAssignmentsModalController,
            templateUrl: 'patient/components/partyAssignments/partyAssignmentsComponent.html',
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
