namespace RemeCare.OneTimeLogin {
    class OneTimeLoginController implements ng.IComponentController {
        public error: OneTimeLoginFailureReason;
        public loginUrl: string;
        public remedusInfoMail: string;
        public remedusPhone: string;

        // bindings
        public $transition$;

        constructor(
            private $window: ng.IWindowService,
            private appConfig: IAppConfig,
            private toaster: Shared.Framework.Toaster,
            private readonly storageSvc: Shared.Framework.Service.StorageService,
            private oneTimeLoginApi: OneTimeLoginApiService
        ) {}

        public $onInit(): void {
            this.loginUrl = this.appConfig.oneTimeLogin.loginUrl;
            this.remedusInfoMail = this.appConfig.remedusInfoMail;
            this.remedusPhone = this.appConfig.remedusPhone;
            const oneTimeKey = this.$transition$.params().key;
            this.oneTimeLoginApi
                .login(oneTimeKey)
                .then((result) => {
                    if (result.PreferredApplicationProfile) {
                        this.storageSvc.store('preferredApplicationProfile', result.PreferredApplicationProfile, false);
                    }
                    if (result.RedirectUrl) {
                        this.$window.location.href = result.RedirectUrl;
                    } else {
                        this.error = result.Reason;
                    }
                })
                .catch((error: string) => {
                    this.toaster.error(error);
                });
        }
    }

    remeCareOneTimeLoginModule.component('rcOneTimeLogin', {
        bindings: {
            $transition$: '<',
        },
        controller: OneTimeLoginController,
        templateUrl: 'views/oneTimeLogin/oneTimeLogin.html',
    });
}
