var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var RuleThreshold = RemeCare.Shared.Framework.Model.RuleThresholdDefinition;
        var PatientFileRuleThresholdOverviewController = /** @class */ (function (_super) {
            __extends(PatientFileRuleThresholdOverviewController, _super);
            function PatientFileRuleThresholdOverviewController($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.therapyApiSvc = therapyApiSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.$scope.addRuleThresholdValue = function () { return _this.addRuleThresholdValue(); };
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileRuleThresholdOverviewController.prototype.$onInit = function () {
                this.$scope.patientId = this.$stateParams.patientId;
                var firstValue = _(this.$scope.threshold.ruleThresholdValues).chain()
                    .sortBy(function (t) { return t.validFromDate; })
                    .first()
                    .value();
                if (moment(firstValue.validFromDate).isBefore(this.$scope.carePlan.startDate)) {
                    firstValue.validFromDate = this.$scope.carePlan.startDate;
                }
                this.buildValueGrid();
                this.getDefaultRuleThreshold();
            };
            PatientFileRuleThresholdOverviewController.prototype.confirm = function () {
                this.$scope.$close(this.$scope.threshold);
            };
            PatientFileRuleThresholdOverviewController.prototype.getDefaultRuleThreshold = function () {
                var _this = this;
                this.therapyApiSvc.getRuleThreshold(this.$scope.carePlan.therapyId, this.$scope.threshold.id)
                    .success(function (ags) {
                    _this.$scope.defaultThreshold = new RuleThreshold(ags);
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileRuleThresholdOverviewController.prototype.buildValueGrid = function () {
                var _this = this;
                this.$scope.valueGrid = this.gridBuilderSvc.createGridBuilder()
                    .addColumn("getValueString()", "Views.PatientFile.CarePlans.RuleThresholdValue")
                    .addColumn("validFromDate", "General.ValidFrom", { cellFilter: 'date: "shortDate"' })
                    .addColumn("validUntilDate", "General.ValidUntil", { cellFilter: 'date: "shortDate"' })
                    .addBoolColumn("isPersonalised", "Views.PatientFile.CarePlans.Personalised")
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (v) { return _this.editRuleThresholdValue(v); }, function (v) {
                    return ((_this.$scope.threshold.getPreviousRuleThresholdValue(v) === null ||
                        !_this.$scope.threshold.getPreviousRuleThresholdValue(v).isPersonalised)
                        && (!v.isPersonalised || moment().isAfter(moment(v.validFromDate)) || _this.$scope.readOnly));
                })
                    .addConditionalDeleteButtonColumn(function (v) {
                    var personalised = _(_(_this.$scope.threshold.ruleThresholdValues).filter(function (x) { return x.isPersonalised; })).sortBy(function (x) { return x.validFromDate; });
                    return _(personalised).size() === 0
                        || v.validFromDate !== _(personalised).last().validFromDate
                        || !v.isPersonalised
                        || _this.$scope.readOnly;
                }, function (v) { return _this.deleteValue(v); })
                    .setSorting(false)
                    .build();
                this.setValueGridData();
            };
            PatientFileRuleThresholdOverviewController.prototype.setValueGridData = function () {
                this.$scope.valueGrid.setData(this.$scope.threshold.ruleThresholdValues);
            };
            PatientFileRuleThresholdOverviewController.prototype.addRuleThresholdValue = function () {
                var _this = this;
                this.modalBuilderFactory.createModalBuilder()
                    .setController('PatientFileAddRuleThresholdValueModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/addRuleThresholdValueModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    threshold: this.$scope.threshold,
                    defaultValue: this.$scope.defaultThreshold.getCurrentRuleThresholdValue(),
                    values: this.$scope.threshold.ruleThresholdValues,
                    carePlan: this.$scope.carePlan
                })
                    .setResultCallBack(function () {
                    _this.getRuleThreshold(_this.$scope.threshold.id);
                })
                    .build();
            };
            PatientFileRuleThresholdOverviewController.prototype.editRuleThresholdValue = function (value) {
                var _this = this;
                var deferred = jQuery.Deferred();
                this.modalBuilderFactory.createModalBuilder()
                    .setController('PatientFileEditRuleThresholdValueModalCtrl')
                    .setTemplateUrl('views/patient/carePlans/objectivesThresholds/editValueModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    threshold: this.$scope.threshold,
                    defaultValue: this.$scope.defaultThreshold.getCurrentRuleThresholdValue(),
                    carePlan: this.$scope.carePlan,
                    editValue: value
                })
                    .setResultCallBack(function () {
                    _this.getRuleThreshold(_this.$scope.threshold.id);
                })
                    .build();
                return deferred.promise();
            };
            PatientFileRuleThresholdOverviewController.prototype.deleteValue = function (value) {
                var _this = this;
                this.carePlanApiSvc.deletePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, value.id)
                    .success(function () {
                    _this.getRuleThreshold(_this.$scope.threshold.id);
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            PatientFileRuleThresholdOverviewController.prototype.getRuleThreshold = function (thresholdId) {
                var _this = this;
                this.carePlanApiSvc.getRuleThreshold(this.$scope.carePlan.id, thresholdId)
                    .success(function (ags) {
                    _this.$scope.threshold = new RuleThreshold(ags);
                    _this.setValueGridData();
                })
                    .error(function (m) { return _this.errorCallback(m); });
            };
            return PatientFileRuleThresholdOverviewController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('PatientFileRuleThresholdOverviewCtrl', function ($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc) { return new PatientFileRuleThresholdOverviewController($scope, $translate, toaster, $stateParams, carePlanApiSvc, therapyApiSvc, modalBuilderFactory, gridBuilderSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
