var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var ContactPointUsageType = RemeCare.Shared.Contract.Code.ContactPointUsageType;
        var EditAddressController = /** @class */ (function () {
            function EditAddressController($uibModalInstance, address, toaster, partyId, currentAddresses, masterdataSvc, modalBuilderFactory, partyApiSvc, isReadOnly) {
                this.$uibModalInstance = $uibModalInstance;
                this.address = address;
                this.toaster = toaster;
                this.partyId = partyId;
                this.currentAddresses = currentAddresses;
                this.masterdataSvc = masterdataSvc;
                this.modalBuilderFactory = modalBuilderFactory;
                this.partyApiSvc = partyApiSvc;
                this.isReadOnly = isReadOnly;
            }
            EditAddressController.prototype.$onInit = function () {
                var _this = this;
                if (this.address != null) {
                    this.isReadOnly = this.isReadOnly || this.address.isReadOnly;
                }
                this.masterdataSvc
                    .getContactPointUsageTypes(RemeCare.Shared.Contract.Code.PartyType.Person, RemeCare.Shared.Contract.Code.ContactPointType.Address)
                    .success(function (r) { return _this.contactPointUsageTypes = _(r).filter(function (cput) { return cput.Id !== ContactPointUsageType.Default; }); })
                    .error(function (e) { return _this.toaster.error(e); });
                this.masterdataSvc.getCountries()
                    .success(function (r) { return _this.countries = r; })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            EditAddressController.prototype.overlapBetweenContactPoints = function (address, currentAddress) {
                return (currentAddress.validUntilDate == null ||
                    moment(address.validFromDate).isSameOrBefore(currentAddress.validUntilDate)) &&
                    (address.validUntilDate == null ||
                        moment(currentAddress.validFromDate).isSameOrBefore(address.validUntilDate));
            };
            EditAddressController.prototype.confirm = function () {
                var _this = this;
                var canSave = true;
                if (this.form.$invalid) {
                    this.form.$setSubmitted();
                    this.form.showValidationErrorMessage();
                    return;
                }
                if (this.address.contactPointUsageType === ContactPointUsageType.Domicile ||
                    this.address.contactPointUsageType === ContactPointUsageType.Postal ||
                    this.address.contactPointUsageType === ContactPointUsageType.Invoicing) {
                    this.currentAddresses.forEach(function (address) {
                        if (address.contactPointUsageType === _this.address.contactPointUsageType) {
                            if (_this.overlapBetweenContactPoints(address, _this.address)) {
                                canSave = false;
                                return;
                            }
                        }
                    });
                }
                ;
                if (canSave) {
                    this.partyApiSvc.saveContactPoint(this.partyId, this.address.toServerWrite())
                        .success(function (id) {
                        _this.address.id = id || _this.address.id;
                        _this.$uibModalInstance.close(_this.address);
                    })
                        .error(function (e) { return _this.toaster.error(e); });
                }
                else {
                    this.modalBuilderFactory
                        .createModalBuilder()
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                        .setTemplateUrl('views/shared/overlapAddressesModal.html')
                        .build();
                    this.$uibModalInstance.close();
                }
            };
            return EditAddressController;
        }());
        Patient.remeCarePatientModule.controller('editAddressCtrl', EditAddressController);
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
