namespace RemeCare.CarePlanAction {
    import AuthRight = Shared.Framework.AuthRight;
    import ActionPart = Contract.CarePlanAction.Read.IActionPart;
    import Guid = Shared.Contract.Guid;
    import IEntityTranslation = Shared.Contract.IEntityTranslation;

    export interface IAction {
        components: ComponentDescriptor[];
        customScreen: string;
    }

    // tslint:disable: no-bitwise
    export class CarePlanAction extends Model.ActionBase {
        public isSuperUser: boolean;
        public carePlanActionId: Shared.Contract.Guid;
        public carePlanIds: Shared.Contract.Guid[];
        public status: Contract.Core.Codes.CarePlanActionStatus;
        public name: string;
        public textRegistration: string;
        public partyRole: Shared.Contract.IEntityTranslation;
        public patient: Contract.Party.Read.IPerson;
        public patientId: Shared.Contract.Guid;
        public showAsToDo: boolean;
        public suggestedDateTime: Date;
        public executionDateTime: Date;
        public plannedDateTimeFrom: Date;
        public plannedDateTimeUntil: Date;
        public eventDateFrom: Date;
        public eventTimeFrom: Date;
        public eventDateUntil: Date;
        public eventTimeUntil: Date;
        public eventDateTimeLabel: string;
        public includesEventDate: boolean;
        public includesEventTime: boolean;
        public isEventDateTimeRange: boolean;
        public openEventWindowDateTime: Date;
        public closeEventWindowDateTime: Date;
        public therapies: Shared.Contract.IEntityTranslation[];
        public textFooter: string;
        public hasTemporarySave: boolean;
        public isActive: boolean;
        public durationOfAction: Date;
        public readableDurationOfAction: string;
        public possibleActionDurationOptionId: Guid;
        public showActionDuration: boolean;
        public processVersionDate: Date;
        public canEditAction: boolean;

        public readonly maxExecutionRight: AuthRight;
        private readonly patientName: string;

        constructor(
            authservice: Shared.Framework.AuthService,
            serverObject?: Contract.CarePlanAction.Read.ICarePlanAction,
            patient?: Contract.Party.Read.IPerson
        ) {
            super(authservice, serverObject && serverObject.ActionTemplateId, serverObject);
            this.isSuperUser = authservice.getProfile() === Shared.Contract.Code.ApplicationProfileType.Superuser;
            if (serverObject != null) {
                this.carePlanActionId = serverObject.Id;
                this.carePlanIds = serverObject.CarePlanIds;
                this.status = serverObject.Status.Id;
                this.partyRole = serverObject.PartyRole;
                this.patient = patient;
                this.patientName = serverObject.Patient != null ? serverObject.Patient.Text : '';
                this.patientId = serverObject.Patient != null ? serverObject.Patient.Id : null;
                this.location = serverObject.Location;
                this.showAsToDo = serverObject.ShowAsToDo;
                this.suggestedDateTime = Shared.DateHelper.serverDateStringToDateTime(serverObject.SuggestedDateTime);
                this.executionDateTime = Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);
                this.processVersionDate = Shared.DateHelper.serverDateStringToDateTime(serverObject.ProcessVersionDate);
                this.plannedDateTimeFrom = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.PlannedDateTimeFrom
                );
                this.plannedDateTimeUntil = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.PlannedDateTimeUntil
                );
                this.eventDateFrom =
                    serverObject.IncludesEventDate &&
                    Shared.DateHelper.serverDateStringToDateTime(
                        serverObject.EventDateTimeFrom || serverObject.PlannedDateTimeFrom
                    );
                this.eventDateUntil =
                    serverObject.IncludesEventDate &&
                    serverObject.IsEventDateTimeRange &&
                    Shared.DateHelper.serverDateStringToDateTime(
                        serverObject.EventDateTimeUntil || serverObject.PlannedDateTimeUntil
                    );
                this.eventTimeFrom =
                    serverObject.IncludesEventDate &&
                    serverObject.IncludesEventTime &&
                    Shared.DateHelper.serverDateStringToDateTime(
                        serverObject.EventDateTimeFrom || serverObject.PlannedDateTimeFrom
                    );
                this.eventTimeUntil =
                    serverObject.IncludesEventDate &&
                    serverObject.IsEventDateTimeRange &&
                    serverObject.IncludesEventTime &&
                    Shared.DateHelper.serverDateStringToDateTime(
                        serverObject.EventDateTimeUntil || serverObject.PlannedDateTimeUntil
                    );
                this.openEventWindowDateTime = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.OpenEventWindowDateTime
                );
                this.closeEventWindowDateTime = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.CloseEventWindowDateTime
                );
                this.therapies = serverObject.Therapies;
                this.hasTemporarySave = serverObject.HasTemporarySave;
                this.maxExecutionRight = serverObject.MaxExecutionRight;
                this.isActive = serverObject.IsActive;
                this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                this.durationOfAction = Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
                if (this.durationOfAction != null) {
                    this.readableDurationOfAction = Shared.DateHelper.timeHumanReadible(this.durationOfAction);
                }
                if (this.components.length > 0 && this.components[0] instanceof CareActDescriptor) {
                    const cad = this.components[0] as CareActDescriptor;
                    this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(
                        cad.hasActionDurationOption,
                        this.durationOfAction,
                        cad.possibleActionDurationOptions
                    );
                }
                this.canEditAction = serverObject.CanEditAction;
            }
        }

        public getPatientName(): string {
            return this.patient != null ? `${this.patient.FirstName} ${this.patient.LastName}` : this.patientName;
        }

        public canBeRescheduled(): boolean {
            const isCompleted =
                this.status === Contract.Core.Codes.CarePlanActionStatus.Completed ||
                this.status === Contract.Core.Codes.CarePlanActionStatus.Cancelled;
            return (
                ((AuthRight.Create & this.maxExecutionRight) !== 0 && !isCompleted) ||
                ((AuthRight.Write & this.maxExecutionRight) !== 0 && isCompleted)
            );
        }

        public isViewable(): boolean {
            return (AuthRight.Read & this.maxExecutionRight) !== 0;
        }

        public getDirectChildren(excludeEmptyRelatedActions?: boolean): CarePlanAction[] {
            return _(this.components)
                .chain()
                .filter((component) => component.enabled && this.isActiveComponent(component.id))
                .map((component) => {
                    if ((component as MissingRegistrationsDescriptor).relatedActions != null) {
                        return (component as MissingRegistrationsDescriptor).relatedActions;
                    } else if (
                        (component as PreviousRegistrationDescriptor).relatedCarePlanAction != null &&
                        (!excludeEmptyRelatedActions || component.hasValue())
                    ) {
                        return [(component as PreviousRegistrationDescriptor).relatedCarePlanAction];
                    } else {
                        return [] as CarePlanAction[];
                    }
                })
                .flatten()
                .value();
        }

        public flattenActions(): CarePlanAction[] {
            const directChildren = this.getDirectChildren();
            const actions: CarePlanAction[] = _(directChildren)
                .chain()
                .map((relatedAction) => relatedAction.flattenActions())
                .flatten()
                .value();

            actions.push(this);
            return actions;
        }

        public hasRegistrations(): boolean {
            return _(this.components).any((c) => c.hasValue());
        }

        public toServerWrite(permanent: boolean): Contract.CarePlanAction.Write.IMakeRegistration {
            const now = new Date();
            let registrationValues = [];
            _(this.components).forEach((c) => {
                if (this.isActiveComponent(c.id)) {
                    registrationValues = registrationValues.concat(c.createRegistration(now, this));
                }
            });

            const directChildren = this.getDirectChildren(true);
            const childCarePlanActions = _(directChildren).map((relatedAction) => {
                return relatedAction.toServerWrite(permanent);
            });

            const result: Contract.CarePlanAction.Write.IMakeRegistration = {
                patientId: this.patientId,
                actionId: this.carePlanActionId,
                eventDateTimeFrom: this.includesEventDate
                    ? this.includesEventTime
                        ? Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeFrom())
                        : Shared.DateHelper.toServerDateString(this.getEventDateTimeFrom())
                    : null,
                eventDateTimeUntil:
                    this.includesEventDate && this.isEventDateTimeRange
                        ? this.includesEventTime
                            ? Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeUntil())
                            : Shared.DateHelper.toServerDateString(this.getEventDateTimeUntil())
                        : null,
                partyRoleId: this.partyRole ? this.partyRole.Id : null,
                location: this.location
                    ? {
                          city: this.location.City,
                          contactPointUsageId: this.location.ContactPointUsageId,
                          name: this.location.Name,
                          remark: this.location.Remark,
                          street: this.location.Street,
                          zipCode: this.location.ZipCode,
                      }
                    : null,
                permanent: permanent,
                registrationValues: registrationValues,
                childActions: childCarePlanActions,
                reschedule: null,
            };
            return result;
        }

        public isComplete(): boolean {
            return this.status === Contract.Core.Codes.CarePlanActionStatus.Completed;
        }

        public isActiveComponent(componentId: Guid): boolean {
            const componentsWithConditions = _(this.components).filter(
                (c) =>
                    c.actionTypeCode === Shared.Contract.Code.ActionType.QualitativeObservation &&
                    _((c as QualitativeObservationDescriptor).answerConditions).any((a) => a.GoToId === componentId)
            ) as QualitativeObservationDescriptor[];

            if (componentsWithConditions.length === 0) {
                return true;
            }

            return _(componentsWithConditions).any(
                (c) =>
                    _.find(
                        c.answerConditions,
                        (a) => a.GoToId === componentId && _(c.value).contains(a.AnswerCodeSetItem.Id)
                    ) != null
            );
        }

        public setAllUnrequired(): void {
            _(this.components).forEach((c) => c.setUnrequired());
        }

        public canEditItem(): boolean {
            return (this.maxExecutionRight & Shared.Framework.AuthRight.Create) !== 0;
        }

        public canViewItem(): boolean {
            return (this.maxExecutionRight & Shared.Framework.AuthRight.Read) !== 0;
        }
    }

    export abstract class ComponentDescriptor {
        public actionTypeCode: Shared.Contract.Code.ActionType;
        public sequence: number;
        public preceedingTitle: string;
        public guidanceEnabled: boolean;
        public guidanceCode: string;
        public textRegistration: string;
        public enabled: boolean;
        public id: Shared.Contract.Guid;
        public required: boolean;
        public requiredOverriden: boolean;
        public requiredForCodeSetItems: Guid[];
        public hidden: boolean;
        public width: Shared.Contract.Code.PartWidth;
        public guidanceIconLocation: number;
        public guidanceIconLocEnum: object;
        public showGuidance: boolean;

        constructor(serverObject?: ActionPart) {
            if (serverObject != null) {
                this.actionTypeCode = serverObject.ActionType.Id;
                this.sequence = serverObject.Sequence;
                this.preceedingTitle = serverObject.PreceedingTitle;
                this.guidanceEnabled = serverObject.GuidanceEnabled;
                this.guidanceCode = serverObject.GuidanceCode;
                this.textRegistration = serverObject.TextRegistration;
                this.enabled = serverObject.Enabled;
                this.id = serverObject.Id;
                this.required = serverObject.Required;
                this.requiredOverriden = serverObject.RequiredOverriden;
                this.requiredForCodeSetItems = serverObject.RequiredForCodeSetItems;
                this.width = serverObject.Width;
                this.guidanceIconLocation = this.getGuidanceIconLocation(serverObject);
                this.guidanceIconLocEnum = RemeCare.Shared.Contract.Code.GuidanceIconLocation;
                this.guidanceFunction = (uniqueId: Shared.Contract.Guid) => this.guidanceFunctionDef(uniqueId);
                this.showGuidance = false;
            }
        }

        public abstract createRegistration(
            now: Date,
            action: IAction
        ): Contract.CarePlanAction.Write.IRegistrationValue[];

        public guidanceFunction(uniqueId: Shared.Contract.Guid): void {}

        public abstract clearValue(): void;

        public abstract hasValue(): boolean;

        public setUnrequired(): void {
            this.required = false;
        }

        protected guidanceFunctionDef(uniqueId: Shared.Contract.Guid): void {
            angular.element('#' + uniqueId).toggle();
        }

        protected createRegistrationValue(
            now: Date,
            action: IAction
        ): Contract.CarePlanAction.Write.IRegistrationValue {
            return {
                actionPartId: this.id,
                registrationDateTime: Shared.DateHelper.toServerDateTimeString(now),
                registrationSourceCode: Shared.Contract.Code.RegistrationSource.RemeCare,
            };
        }

        private getGuidanceIconLocation(part: ActionPart): RemeCare.Shared.Contract.Code.GuidanceIconLocation {
            if (part.GuidanceCode == null || part.GuidanceCode === '') {
                return RemeCare.Shared.Contract.Code.GuidanceIconLocation.None;
            }

            if (part.PreceedingTitle != null && part.PreceedingTitle !== '') {
                // This needs revision
                return RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle;
            }

            if (part.TextRegistration != null && part.TextRegistration !== '') {
                if (part.ActionType.Id === Shared.Contract.Code.ActionType.Administration) {
                    return RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label;
                }
                return RemeCare.Shared.Contract.Code.GuidanceIconLocation.TextRegistration;
            }

            return RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label;
        }
    }

    export class AdministrationDescriptor extends ComponentDescriptor {
        public value: Contract.CarePlanAction.Read.IChoiceListParameter;
        public medicationDoses: MedicationDose[];
        public personalisedTherapyActionId: Shared.Contract.Guid;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = serverObject.ChoiceListParameter;
                this.medicationDoses = _(serverObject.MedicationDoses).map((x) => new MedicationDose(x));
            }
        }

        public clearValue(): void {
            this.value.ChoiceId = null;
        }

        public hasValue(): boolean {
            return this.value != null && this.value.ChoiceId != null;
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            if (this.value) {
                result.choiceListOptionId = this.value.ChoiceId;
            }
            return [result];
        }
    }

    export class MedicationDose {
        public quantity: number;
        public unit: Shared.Contract.IEntityTranslation;
        public administrationTiming: string;
        public noEatingTimeBefore: Date;
        public noEatingTimeAfter: Date;
        public name: string;
        public personalisedTherapyActionId: Shared.Contract.Guid;
        public solventToAddInformation: Patient.Model.SolventToAddInformation;
        public prescribedAdministrationInformation: Patient.Model.PrescribedAdministrationInformation;

        constructor(serverObject?: Contract.CarePlanAction.Read.IMedicationDose) {
            if (serverObject != null) {
                this.quantity = serverObject.Quantity;
                this.unit = serverObject.Unit;
                this.administrationTiming = serverObject.AdministrationTiming;
                this.noEatingTimeBefore = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingTimeBefore);
                this.noEatingTimeAfter = Shared.DateHelper.serverTimeToDate(serverObject.NoEatingTimeAfter);
                this.name = serverObject.ProductDescription;
                this.personalisedTherapyActionId = serverObject.PersonalisedTherapyActionId;
                this.solventToAddInformation = new Patient.Model.SolventToAddInformation(
                    serverObject.SolventToAddInformation
                );
                this.prescribedAdministrationInformation = new Patient.Model.PrescribedAdministrationInformation(
                    serverObject.PrescribedAdministrationInformation
                );
            } else {
                this.solventToAddInformation = new Patient.Model.SolventToAddInformation(null);
                this.prescribedAdministrationInformation = new Patient.Model.PrescribedAdministrationInformation(null);
            }
        }
    }

    export class DateDescriptor extends ComponentDescriptor {
        public value: DateInterval = new DateInterval();
        public dateType: Shared.Contract.Code.DateTypeCode;
        public hasTime: boolean;
        public name: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = new DateInterval(serverObject.DateParameter.Value);
                this.dateType = serverObject.DateParameter.DateType;
                this.hasTime = serverObject.DateParameter.HasTime;
                this.name = serverObject.DateParameter.Name;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.date = Shared.DateHelper.toServerDateTimeString(this.value.fromDate);
            result.dateUntil = Shared.DateHelper.toServerDateTimeString(this.value.untilDate);
            return [result];
        }

        public clearValue(): void {
            this.value.fromDate = null;
            this.value.untilDate = null;
        }

        public hasValue(): boolean {
            return this.value != null && (this.value.fromDate != null || this.value.untilDate != null);
        }
    }

    export class DateInterval {
        public fromDate: Date;
        public untilDate: Date;

        constructor(serverObject?: Contract.CarePlanAction.Read.IDateInterval) {
            if (serverObject != null) {
                this.fromDate = Shared.DateHelper.serverDateStringToDateTime(serverObject.FromDate);
                this.untilDate = Shared.DateHelper.serverDateStringToDateTime(serverObject.UntilDate);
            }
        }
    }

    export class MissingRegistrationsDescriptor extends ComponentDescriptor {
        public relatedActions: CarePlanAction[] = [];

        constructor(authservice: Shared.Framework.AuthService, serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.relatedActions = _(serverObject.RelatedActions)
                    .chain()
                    .map((c) => new CarePlanAction(authservice, c))
                    .sortBy((c) => c.plannedDateTimeFrom)
                    .value()
                    .reverse();
                if (!this.required) {
                    _(this.relatedActions).forEach((ra) => ra.setAllUnrequired());
                }
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            return [];
        }

        public clearValue(): void {}

        public hasValue(): boolean {
            return _(this.relatedActions).any((ra) => ra.hasRegistrations());
        }

        public setUnrequired(): void {
            super.setUnrequired();
            _(this.relatedActions).forEach((ra) => ra.setAllUnrequired());
        }
    }

    export class PhoneCallDescriptor extends ComponentDescriptor {
        public previousContacts: PreviousRemark[] = [];
        public maxNumberOfAttempts: number;
        public value: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.previousContacts = _(serverObject.PreviousContacts).map((p) => new PreviousRemark(p));
                this.maxNumberOfAttempts = serverObject.MaxNumberOfAttempts;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.remark = this.value;
            return [result];
        }

        public clearValue(): void {
            this.value = null;
        }

        public hasValue(): boolean {
            return this.value != null && this.value !== '';
        }
    }

    export class PreviousRemark {
        public remark: string;
        public registrationDate: Date;
        public actor: string;

        constructor(serverObject: Contract.CarePlanAction.Read.IPreviousRemark) {
            this.remark = serverObject.Remark;
            this.registrationDate = Shared.DateHelper.serverDateStringToDateTime(serverObject.RegistrationDate);
            this.actor = serverObject.Actor;
        }
    }

    export class PreviousRegistrationDescriptor extends ComponentDescriptor {
        public relatedCarePlanAction: CarePlanAction;

        constructor(authservice: Shared.Framework.AuthService, serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null && serverObject.RelatedCarePlanAction != null) {
                this.relatedCarePlanAction = new CarePlanAction(authservice, serverObject.RelatedCarePlanAction);
                if (!this.required) {
                    this.relatedCarePlanAction.setAllUnrequired();
                }
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            return [];
        }

        public clearValue(): void {}

        public hasValue(): boolean {
            return this.relatedCarePlanAction.hasRegistrations();
        }

        public setUnrequired(): void {
            super.setUnrequired();
            if (this.relatedCarePlanAction != null) {
                // no child registrations yet
                this.relatedCarePlanAction.setAllUnrequired();
            }
        }
    }

    export class ProvideInformationObjectDescriptor extends ComponentDescriptor {
        public documentId?: Guid;
        public name: string;
        public description: string;
        public url: string;
        public informationObjectType: Shared.Contract.Code.InformationObjectType;
        public questions: Shared.Contract.IMediaLibItemQuestionDetail[];
        public questionHeader: string;
        public preceedingTitleMedia: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.documentId = serverObject.InformationMessage.DocumentId;
                this.name = serverObject.InformationMessage.Name;
                this.description = serverObject.InformationMessage.Description;
                this.url = serverObject.InformationMessage.Url;
                this.informationObjectType = serverObject.InformationMessage.InformationObjectType;
                this.questions = _(serverObject.Questions).sortBy((q) => q.Sequence);
                this.questionHeader = serverObject.QuestionHeader;
                this.preceedingTitleMedia = serverObject.InformationMessage.PreceedingTitle;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = _(this.questions)
                .chain()
                .filter((q) => this.isActiveQuestion(q.Id))
                .map((question: Shared.Contract.IMediaLibItemQuestionDetail) => {
                    const registration = this.createRegistrationValue(now, action);
                    registration.mediaLibItemQuestionId = question.Id;
                    if (question.UIControlType === Shared.Contract.Code.UIControlType.Checkbox) {
                        registration.multiChoiceListOptionIds = question.SelectedValues;
                    } else {
                        registration.choiceListOptionId = question.SelectedValues[0];
                    }
                    return registration;
                })
                .value();
            return result;
        }

        public clearValue(): void {}

        public hasValue(): boolean {
            return false;
        }

        private isActiveQuestion(questionId: Shared.Contract.Guid): boolean {
            const referringQuestions = _(this.questions).filter((q) =>
                _(q.AnswerConditions).any((ac) => ac.GoToId === questionId)
            );
            if (referringQuestions.length === 0) {
                return true;
            }
            return _(referringQuestions).any((q) =>
                _(q.AnswerConditions).any((ac) => _(q.SelectedValues).contains(ac.AnswerCodeSetItem.Id))
            );
        }
    }

    export class QualitativeObservationDescriptor extends ComponentDescriptor {
        public value: Shared.Contract.Guid[] = [];
        public optionGroupId: Shared.Contract.Guid;
        public answerConditions: Contract.CarePlanAction.Read.IAnswerCondition[];
        public uiControlType: Shared.Contract.Code.UIControlType;
        public observableEntity: Shared.Contract.IEntityTranslation;
        public exceedsThreshold: boolean;
        public showObservableEntity: boolean;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = serverObject.ChoiceListParameter.ChoiceIds;
                this.optionGroupId = serverObject.ChoiceListParameter.ChoiceListId;
                this.answerConditions = serverObject.AnswerConditions;
                this.uiControlType = serverObject.UIControlType;
                this.observableEntity = serverObject.ObservableEntity;
                this.exceedsThreshold = serverObject.ExceedsThreshold;
                this.showObservableEntity = serverObject.ShowObservableEntity;
                this.guidanceIconLocation =
                    this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                    serverObject.PreceedingTitle === '' &&
                    serverObject.TextRegistration === '' &&
                    serverObject.ShowObservableEntity &&
                    serverObject.ObservableEntity !== null &&
                    serverObject.ObservableEntity.Text !== null &&
                    serverObject.ObservableEntity.Text !== ''
                        ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label
                        : this.guidanceIconLocation;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            if (
                this.uiControlType === Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                this.uiControlType === Shared.Contract.Code.UIControlType.DropdownListMultiple
            ) {
                result.multiChoiceListOptionIds = this.value;
            } else {
                result.choiceListOptionId = this.value[0];
            }
            return [result];
        }

        public clearValue(): void {
            this.value = [];
        }

        public hasValue(): boolean {
            return this.value != null && this.value.length > 0;
        }
    }

    export class QuantitativeObservationDescriptor extends ComponentDescriptor {
        public value: number;
        public unit: string;
        public characteristic: string;
        public characteristicId: Shared.Contract.Guid;
        public targetValue: number;
        public upperTargetValue: number;
        public decimals: number;
        public exceedsThreshold: boolean;
        public showCharacteristic: boolean;
        public minimumValue?: number;
        public maximumValue?: number;
        public minMaxPlaceHolder: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = serverObject.NumericCharacteristic.Value;
                this.unit = serverObject.NumericCharacteristic.Unit;
                this.characteristic = serverObject.NumericCharacteristic.Characteristic;
                this.characteristicId = serverObject.NumericCharacteristic.CharacteristicId;
                this.targetValue = serverObject.NumericCharacteristic.TargetValue;
                this.upperTargetValue = serverObject.NumericCharacteristic.UpperTargetValue;
                this.decimals = serverObject.NumericCharacteristic.Decimals;
                this.exceedsThreshold = serverObject.ExceedsThreshold;
                this.showCharacteristic = serverObject.ShowCharacteristic;
                this.minimumValue = serverObject.NumericCharacteristic.MinimumValue;
                this.maximumValue = serverObject.NumericCharacteristic.MaximumValue;
                this.guidanceIconLocation =
                    this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                    serverObject.ShowCharacteristic &&
                    serverObject.PreceedingTitle === '' &&
                    serverObject.TextRegistration === '' &&
                    this.characteristic !== null &&
                    this.characteristic !== ''
                        ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label
                        : this.guidanceIconLocation;

                if (this.minimumValue != null && this.maximumValue != null) {
                    this.minMaxPlaceHolder = this.minimumValue + ' - ' + this.maximumValue;
                } else if (this.minimumValue != null) {
                    this.minMaxPlaceHolder = '>=' + this.minimumValue;
                } else if (this.maximumValue != null) {
                    this.minMaxPlaceHolder = '<=' + this.maximumValue;
                } else {
                    this.minMaxPlaceHolder = '';
                }
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.amount = this.value;
            return [result];
        }

        public clearValue(): void {
            this.value = null;
        }

        public hasValue(): boolean {
            return this.value != null;
        }
    }

    export class RemarkDescriptor extends ComponentDescriptor {
        public value: string;
        public hasStandardPhrases: boolean;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = serverObject.RemarkValue;
                this.hasStandardPhrases = serverObject.HasStandardPhrases;
                this.guidanceIconLocation =
                    this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                    serverObject.PreceedingTitle != null &&
                    serverObject.PreceedingTitle !== ''
                        ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle
                        : this.guidanceIconLocation;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.remark = this.value;
            return [result];
        }

        public clearValue(): void {
            this.value = null;
        }

        public hasValue(): boolean {
            return this.value != null && this.value !== '';
        }
    }

    export class SignatureDescriptor extends ComponentDescriptor {
        public signatureImage: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.signatureImage = serverObject.SignatureImage;
                this.guidanceIconLocation =
                    this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                    serverObject.PreceedingTitle != null &&
                    serverObject.PreceedingTitle !== ''
                        ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle
                        : this.guidanceIconLocation;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.signatureImage = this.signatureImage;
            return [result];
        }

        public clearValue(): void {
            this.signatureImage = null;
        }

        public hasValue(): boolean {
            return !!this.signatureImage;
        }
    }

    export class RequestInformationObjectDescriptor extends ComponentDescriptor {
        public value: Shared.Contract.IDatedDocument[] = [];
        public hasReferenceDate: boolean;
        public requestInfoFileTypes: Shared.Contract.IEntityTranslation[] = [];

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.value = serverObject.RequestInfoParameter.Value;
                this.hasReferenceDate = serverObject.RequestInfoParameter.HasReferenceDate;
                this.requestInfoFileTypes = serverObject.RequestInfoParameter.RequestInfoFileTypes;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const documentRegistrations = _.map(this.value, (d) => {
                const result = this.createRegistrationValue(now, action);
                result.genericDocumentId = d.Id;
                result.referenceDate = d.ReferenceDate;
                result.fileName = d.Name;
                return result;
            });
            return documentRegistrations;
        }

        public clearValue(): void {
            this.value = [];
        }

        public hasValue(): boolean {
            return this.value != null && this.value.length > 0;
        }
    }

    export class SetDetailsDescriptor extends ComponentDescriptor {
        public hasLocation: boolean;
        public hasExecutor: boolean;
        public defaultLocation: Contract.CarePlanAction.Read.IDefaultLocation;
        public value: RegistrationDetailsRelatedAction = new RegistrationDetailsRelatedAction();
        public relatedStructuralTherapyAction: Shared.Contract.IEntityTranslation;
        public hasTime: boolean;
        public isWindow: boolean;
        public hasActionDurationOption: boolean;
        public showActionDuration: boolean;
        public possibleActionDurationOptions: IEntityTranslation[];
        private defaultDate: Date;
        private defaultFromTime: Date;
        private defaultUntilTime: Date;
        private defaultDurationOfAction: Date;
        private defaultPossibleActionDurationOptionId: Guid;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.hasLocation = serverObject.HasLocation;
                this.hasExecutor = serverObject.HasExecutor;
                this.hasTime = serverObject.SetDetailsParameter.HasTime;
                this.isWindow = serverObject.SetDetailsParameter.IsWindow;
                this.defaultLocation = serverObject.SetDetailsParameter.DefaultLocation;
                this.defaultDate = Shared.DateHelper.serverDateStringToDateTime(
                    serverObject.SetDetailsParameter.DefaultDate
                );
                this.defaultFromTime = Shared.DateHelper.serverTimeToDate(
                    serverObject.SetDetailsParameter.DefaultFromTime
                );
                this.defaultUntilTime = Shared.DateHelper.serverTimeToDate(
                    serverObject.SetDetailsParameter.DefaultUntilTime
                );
                this.relatedStructuralTherapyAction = serverObject.SetDetailsParameter.RelatedStructuralTherapyAction;
                this.defaultDurationOfAction = Shared.DateHelper.serverTimeToDate(
                    serverObject.SetDetailsParameter.DefaultDurationOfAction
                );
                this.hasActionDurationOption = serverObject.HasActionDurationOption;
                this.defaultPossibleActionDurationOptionId =
                    serverObject.SetDetailsParameter.DefaultPossibleActionDurationOptionId;
                this.possibleActionDurationOptions = serverObject.PossibleActionDurationOptions;
                this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(
                    this.hasActionDurationOption,
                    this.defaultDurationOfAction,
                    this.possibleActionDurationOptions
                );
                this.value = new RegistrationDetailsRelatedAction(serverObject.SetDetailsParameter.Value);
                if (!this.value.plannedDate) {
                    this.value.plannedDate = this.defaultDate;
                    this.value.plannedTimeFrom = this.defaultFromTime;
                    this.value.plannedTimeUntil = this.defaultUntilTime;
                    this.value.durationOfAction = this.defaultDurationOfAction;
                    this.value.possibleActionDurationOptionId = this.defaultPossibleActionDurationOptionId;
                }
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.partyRoleId = this.value.partyRoleId;
            result.plannedDateTimeFrom = Shared.DateHelper.toServerDateTimeString(this.value.getPlannedDateTimeFrom());
            result.plannedDateTimeUntil = Shared.DateHelper.toServerDateTimeString(
                this.value.getPlannedDateTimeUntil()
            );
            if (this.value.hasLocation()) {
                result.location = this.value.location as Contract.CarePlanAction.Write.ILocationParameters;
            }
            result.possibleActionDurationOptionId = this.value.possibleActionDurationOptionId;
            result.durationOfAction = Shared.DateHelper.toServerTimeString(this.value.durationOfAction);
            return [result];
        }

        public clearValue(): void {}

        public hasValue(): boolean {
            return this.value != null && this.value.hasValue();
        }
    }

    export class RegistrationDetailsRelatedAction {
        public partyRoleId: Shared.Contract.Guid;
        public plannedDate: Date;
        public plannedTimeFrom: Date;
        public plannedTimeUntil: Date;
        public location: Contract.CarePlanAction.Read.ILocation = {};
        public durationOfAction: Date;
        public possibleActionDurationOptionId: Guid;

        constructor(serverObject?: Contract.CarePlanAction.Read.IRegistrationDetailsRelatedAction) {
            if (serverObject != null) {
                this.partyRoleId = serverObject.PartyRoleId;
                this.plannedDate = Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeFrom);
                this.plannedTimeFrom = Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeFrom);
                this.plannedTimeUntil = Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeUntil);
                this.durationOfAction = Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
                this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                this.location = serverObject.Location || {};
            }
        }

        public hasLocation(): boolean {
            return (
                this.location.ContactPointUsageId != null ||
                this.location.Name != null ||
                this.location.City != null ||
                this.location.Street != null ||
                this.location.ZipCode != null
            );
        }

        public getPlannedDateTimeFrom(): Date {
            const result = moment(this.plannedDate).toDate();
            Shared.DateHelper.setTime(result, this.plannedTimeFrom);
            return result;
        }

        public getPlannedDateTimeUntil(): Date {
            const result = moment(this.plannedDate).toDate();
            Shared.DateHelper.setTime(result, this.plannedTimeUntil);
            return result;
        }

        public hasValue(): boolean {
            return (
                this.partyRoleId != null ||
                this.plannedDate != null ||
                this.plannedTimeFrom != null ||
                this.plannedTimeUntil != null ||
                this.hasLocation()
            );
        }
    }

    export class CareActDescriptor extends ComponentDescriptor {
        public hasLocation: boolean;
        public hasExecutor: boolean;
        public value?: Shared.Contract.Guid;
        public choiceListId?: Shared.Contract.Guid;
        public hasActionDurationOption: boolean;
        public possibleActionDurationOptions: IEntityTranslation[];
        public showActionDuration: boolean;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.hasLocation = serverObject.HasLocation;
                this.hasExecutor = serverObject.HasExecutor;
                this.hasActionDurationOption = serverObject.HasActionDurationOption;
                this.possibleActionDurationOptions = serverObject.PossibleActionDurationOptions;
                if (serverObject.ChoiceListParameter) {
                    this.value = serverObject.ChoiceListParameter.ChoiceId;
                    this.choiceListId = serverObject.ChoiceListParameter.ChoiceListId;
                }
                this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(
                    this.hasActionDurationOption,
                    null,
                    this.possibleActionDurationOptions
                );
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.choiceListOptionId = this.value;
            return [result];
        }

        public clearValue(): void {
            this.value = null;
        }

        public hasValue(): boolean {
            return this.value != null;
        }
    }

    export class AberrantRegistrationsDescriptor extends ComponentDescriptor {
        public aberrantRegistrationValueIds: Guid[];

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            if (serverObject != null) {
                this.aberrantRegistrationValueIds = serverObject.AberrantRegistrationValueIds;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            return _(this.aberrantRegistrationValueIds).map((rvId) => {
                const result = this.createRegistrationValue(now, action);
                result.aberrantRegistrationValueId = rvId;
                return result;
            });
        }

        public clearValue(): void {}

        public hasValue(): boolean {
            return false;
        }

        public setUnrequired(): void {
            super.setUnrequired();
        }
    }

    export class MedicationSchemaDescriptor extends ComponentDescriptor {
        public value: {
            modified: Patient.Model.Prescription[];
            deleted: Patient.Model.Prescription[];
        };
        public prescriptions: Contract.Patient.Read.IPrescription[];
        public medicationSchemaTimestamp: string;

        constructor(serverObject?: ActionPart) {
            super(serverObject);
            this.value = {
                modified: [],
                deleted: [],
            };
            if (serverObject) {
                this.prescriptions = serverObject.Prescriptions;
                this.medicationSchemaTimestamp = serverObject.MedicationSchemaTimestamp;
            }
        }

        public createRegistration(now: Date, action: IAction): Contract.CarePlanAction.Write.IRegistrationValue[] {
            const result = this.createRegistrationValue(now, action);
            result.prescriptions = _(this.value.modified).map((p) => p.toWriteModel());
            result.deletedPrescriptions = _(this.value.deleted).map((p) => p.id);
            result.medicationSchemaTimestamp = this.medicationSchemaTimestamp;
            return [result];
        }

        public clearValue(): void {
            this.value = {
                modified: [],
                deleted: [],
            };
        }

        public hasValue(): boolean {
            return this.value.modified.length > 0 || this.value.deleted.length > 0;
        }

        public addOrReplace(prescription: Patient.Model.Prescription): void {
            this.removeFromModified(prescription);
            this.value.modified.push(prescription);
        }

        public delete(prescription: Patient.Model.Prescription): void {
            this.removeFromModified(prescription);
            this.value.deleted.push(prescription);
        }

        private removeFromModified(prescription: Patient.Model.Prescription): void {
            let existing;
            if (!prescription.isFollowedUp) {
                existing = _.find(this.value.modified, (p) => p.id === prescription.id);
            } else {
                if (moment(prescription.validFromDate).isBefore(Shared.DateHelper.tomorrow())) {
                    existing = _.find(
                        this.value.modified,
                        (p) =>
                            p.id === prescription.id &&
                            p.validFromDate.valueOf() === prescription.validFromDate.valueOf()
                    );
                } else {
                    existing = _.find(
                        this.value.modified,
                        (p) =>
                            p.id === prescription.id && !moment(p.validFromDate).isBefore(Shared.DateHelper.tomorrow())
                    );
                }
            }
            if (existing != null) {
                const index = this.value.modified.indexOf(existing);
                this.value.modified.splice(index, 1);
            }
        }
    }
}
