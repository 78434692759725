var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var Hospitalisation = RemeCare.Model.Hospitalisation;
        var Consultation = RemeCare.Model.Consultation;
        var PatientFileHopsiConsOverviewController = /** @class */ (function (_super) {
            __extends(PatientFileHopsiConsOverviewController, _super);
            function PatientFileHopsiConsOverviewController($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$scope = $scope;
                _this.$translate = $translate;
                _this.toaster = toaster;
                _this.$stateParams = $stateParams;
                _this.$dialog = $dialog;
                _this.patientSvc = patientSvc;
                _this.gridBuilderSvc = gridBuilderSvc;
                _this.modalBuilderFactory = modalBuilderFactory;
                _this.authservice = authservice;
                _this.$scope.addHospitalisation = function () { return _this.addHospitalisation(); };
                _this.$scope.addConsultation = function () { return _this.addConsultation(); };
                return _this;
            }
            PatientFileHopsiConsOverviewController.prototype.$onInit = function () {
                this.$scope.hospitalisations = [];
                this.$scope.consultations = [];
                this.buildHospitalisationGrid();
                this.buildConsultationGrid();
                this.getHospitalisationsAsync();
                this.getConsultationsAsync();
                this.$scope.deleteRight = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedical, RemeCare.Shared.Framework.AuthRight.Delete);
                this.$scope.editRight = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedical, RemeCare.Shared.Framework.AuthRight.Write);
                this.$scope.deleteRightHospi = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedicalHospitalisation, RemeCare.Shared.Framework.AuthRight.Delete);
                this.$scope.editRightHospi = this.authservice.hasRight(RemeCare.Shared.Framework.AuthContext.patientMedicalHospitalisation, RemeCare.Shared.Framework.AuthRight.Write);
            };
            PatientFileHopsiConsOverviewController.prototype.getHospitalisationsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getPatientFileHospitalisationsAsync(this.$stateParams.patientId)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.hospitalisations = _(ags).map(function (x) { return new Hospitalisation(x); });
                                this.$scope.hospitalisationsGrid.setData(this.$scope.hospitalisations);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileHopsiConsOverviewController.prototype.getConsultationsAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var ags, e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.patientSvc.getPatientFileConsultationsAsync(this.$stateParams.patientId)];
                            case 1:
                                ags = _a.sent();
                                this.$scope.consultations = _(ags).map(function (x) { return new Consultation(x); });
                                this.$scope.consultationsGrid.setData(this.$scope.consultations);
                                return [3 /*break*/, 3];
                            case 2:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            PatientFileHopsiConsOverviewController.prototype.buildHospitalisationGrid = function () {
                var _this = this;
                this.$scope.hospitalisationsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('startDate', 'General.StartDate', { cellFilter: 'date: "shortDate"' })
                    .addColumn('endDate', 'General.EndDate', { cellFilter: 'date: "shortDate"' })
                    .addColumn('departmentType.Text', 'General.Department')
                    .addColumn('getHospitalisationReason()', 'General.Reason')
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (h) { return _this.editHospitalisation(h); }, function () { return !_this.$scope.editRightHospi; })
                    .addConditionalDeleteButtonColumn(function () { return !_this.$scope.deleteRightHospi; }, null, function (c) {
                    return _this.deleteHospitalisation(c);
                })
                    .build();
            };
            PatientFileHopsiConsOverviewController.prototype.buildConsultationGrid = function () {
                var _this = this;
                this.$scope.consultationsGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('consultationDate', 'Views.PatientFile.ConsultationDate', {
                    cellFilter: 'date: "shortDate"',
                })
                    .addColumn('careProviderType.Text', 'General.CareProvider', { cellFilter: 'date: "shortDate"' })
                    .addColumn('reason', 'General.Reason')
                    .addConditionalEditButtonWithPromiseFunctionColumn(function (c) { return _this.editConsultation(c); }, function () { return !_this.$scope.editRight; })
                    .addConditionalDeleteButtonColumn(function () { return !_this.$scope.deleteRight; }, null, function (c) { return _this.deleteConsultation(c); })
                    .build();
            };
            PatientFileHopsiConsOverviewController.prototype.addHospitalisation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('patientFileAddHospitalisationModalCtrl')
                    .setTemplateUrl('views/patient/patientInfo/hospiCons/addHospitalisationModal.html')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setScope({
                    hospitalisation: new Hospitalisation(),
                })
                    .setResultCallBack(function () {
                    _this.getHospitalisationsAsync();
                })
                    .build();
            };
            PatientFileHopsiConsOverviewController.prototype.addConsultation = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createComponentModalBuilder('rcAddConsultation')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setBindings({
                    consultation: new Consultation(),
                })
                    .setResultCallBack(function () {
                    _this.getConsultationsAsync();
                })
                    .build();
            };
            PatientFileHopsiConsOverviewController.prototype.editHospitalisation = function (hospitalisation) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.modalBuilderFactory
                        .createModalBuilder()
                        .setController('patientFileAddHospitalisationModalCtrl')
                        .setTemplateUrl('views/patient/patientInfo/hospiCons/addHospitalisationModal.html')
                        .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                        .setScope({
                        hospitalisation: angular.copy(hospitalisation),
                    })
                        .setResultCallBack(function (r) {
                        _this.getHospitalisationsAsync();
                        resolve(r);
                    })
                        .build();
                });
            };
            PatientFileHopsiConsOverviewController.prototype.editConsultation = function (consultation) {
                var _this = this;
                return this.modalBuilderFactory
                    .createComponentModalBuilder('rcAddConsultation')
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                    .setBindings({
                    consultation: angular.copy(consultation),
                })
                    .setResultCallBack(function () {
                    _this.getConsultationsAsync();
                })
                    .build().result;
            };
            PatientFileHopsiConsOverviewController.prototype.deleteHospitalisation = function (hospitalisation) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveHospitalisation', 'Views.PatientFile.PatientInfo.RemoveHospitalisationText', function () { return __awaiter(_this, void 0, void 0, function () {
                        var e_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.patientSvc.deletePatientFileHospitalisationAsync(this.$stateParams.patientId, hospitalisation.id)];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_3 = _a.sent();
                                    this.toaster.error(e_3);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, function () {
                        resolve(false);
                    });
                });
            };
            PatientFileHopsiConsOverviewController.prototype.deleteConsultation = function (consultation) {
                var _this = this;
                return new Promise(function (resolve) {
                    _this.$dialog.confirmBox('Views.PatientFile.PatientInfo.RemoveConsultation', 'Views.PatientFile.PatientInfo.RemoveConsultationText', function () { return __awaiter(_this, void 0, void 0, function () {
                        var e_4;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.patientSvc.deletePatientFileConsultationAsync(this.$stateParams.patientId, consultation.id)];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_4 = _a.sent();
                                    this.toaster.error(e_4);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, function () {
                        resolve(false);
                    });
                });
            };
            return PatientFileHopsiConsOverviewController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular
            .module('RemeCare.Patient')
            .controller('patientFileHospiConsOverviewCtrl', function ($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice) {
            return new PatientFileHopsiConsOverviewController($scope, $translate, toaster, $stateParams, $dialog, patientSvc, gridBuilderSvc, modalBuilderFactory, authservice);
        });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
