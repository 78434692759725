var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var ModalityType = RemeCare.Shared.Contract.Code.ModalityType;
            var CareRequestPartControllerBase = /** @class */ (function (_super) {
                __extends(CareRequestPartControllerBase, _super);
                function CareRequestPartControllerBase($scope, $translate, toaster, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.isPresent = function (t) { return _this.isPresent(t); };
                    $scope.isRequired = function (t) { return _this.isRequired(t); };
                    return _this;
                }
                CareRequestPartControllerBase.prototype.convertToTypeAndSpecialties = function (inclusions) {
                    return inclusions
                        .filter(function (i) { return i.healthCareProfessionalTypeId != null; })
                        .map(function (i) {
                        return ({
                            healthCareProfessionalTypeId: i.healthCareProfessionalTypeId,
                            healthCareSpecialtyProfessionId: i.healthCareSpecialtyProfessionId
                        });
                    })
                        .value();
                };
                CareRequestPartControllerBase.prototype.isPresent = function (controlType) {
                    var control = _.find(this.$scope.careRequestPart.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                    if (control == null) {
                        return false;
                    }
                    else {
                        return control.modalityType.Id !== ModalityType.Not;
                    }
                };
                CareRequestPartControllerBase.prototype.isRequired = function (controlType, part) {
                    part = part || this.$scope.careRequestPart;
                    if (part == null) {
                        return false;
                    }
                    if (this.$scope.careRequestTemplate !== undefined && this.$scope.careRequest !== undefined) {
                        if (this.careRequestSvc.isVisibleWithConditionality(part, this.$scope.careRequestTemplate, this.$scope.careRequest) === false) {
                            return false;
                        }
                    }
                    var control = _.find(part.uiControls, function (u) { return u.uiControlTypeId === controlType; });
                    if (control == null) {
                        return false;
                    }
                    else {
                        return control.modalityType.Id === ModalityType.Mandatory;
                    }
                };
                CareRequestPartControllerBase.prototype.removeDeletedDiseasesFromAnswerConditions = function (diseases, answerConditions) {
                    answerConditions.forEach(function (ac, index) {
                        var diseaseIsPresent = diseases.filter(function (d) { return d.TherapyDiseaseId === ac.AnswerTherapyDiseaseId; }).length > 0;
                        if (!diseaseIsPresent) {
                            answerConditions.splice(index, 1);
                        }
                    });
                    this.$scope.careRequestPart.answerConditions = answerConditions;
                };
                return CareRequestPartControllerBase;
            }(RemeCare.Shared.Framework
                .ControllerBase));
            Directive.CareRequestPartControllerBase = CareRequestPartControllerBase;
            var CareRequestPartDirectiveBase = /** @class */ (function () {
                function CareRequestPartDirectiveBase() {
                    this.restrict = 'E';
                    this.scope = {
                        careRequestTemplate: '=',
                        careRequestPart: '=',
                        careRequest: '=',
                        showErrors: '=',
                        stepType: '=',
                        readOnly: '=',
                        onMatchedPatientChanged: '&',
                        validateNationalNumber: '='
                    };
                }
                return CareRequestPartDirectiveBase;
            }());
            Directive.CareRequestPartDirectiveBase = CareRequestPartDirectiveBase;
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
