/// <reference path="careRequestPartDirectiveBase.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var TreatedDiseasesController = /** @class */ (function (_super) {
                __extends(TreatedDiseasesController, _super);
                function TreatedDiseasesController($scope, $translate, toaster, therapyApiSvc, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.therapyApiSvc = therapyApiSvc;
                    _this.careRequestSvc = careRequestSvc;
                    _this.$onInit();
                    $scope.isVisible = function () { return _this.isVisible(); };
                    return _this;
                }
                TreatedDiseasesController.prototype.$onInit = function () {
                    // Don't await
                    this.initDiseasesAsync();
                };
                TreatedDiseasesController.prototype.initDiseasesAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, e_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    _a = this.$scope;
                                    return [4 /*yield*/, this.therapyApiSvc.getTherapyDiseasesAsync(this.$scope.careRequest.therapyId, RemeCare.Shared.Contract.Code.DiseaseType.TreatedDisease)
                                            .then(function (d) { return d.filter(function (d) { return d.IsDeleted === false; }); })];
                                case 1:
                                    _a.diseases = _b.sent();
                                    this.removeDeletedDiseasesFromAnswerConditions(this.$scope.diseases, this.$scope.careRequestPart.answerConditions);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _b.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                TreatedDiseasesController.prototype.isVisible = function () {
                    return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
                };
                return TreatedDiseasesController;
            }(Directive.CareRequestPartControllerBase));
            var TreatedDiseasesDirective = /** @class */ (function (_super) {
                __extends(TreatedDiseasesDirective, _super);
                function TreatedDiseasesDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = [
                        '$scope',
                        '$translate',
                        'toaster',
                        'therapyApiSvc',
                        'careRequestSvc',
                        function ($scope, $translate, toaster, therapyApiSvc, careRequestSvc) {
                            return new TreatedDiseasesController($scope, $translate, toaster, therapyApiSvc, careRequestSvc);
                        },
                    ];
                    _this.templateUrl = 'views/careRequest/directive/treatedDiseases.html';
                    return _this;
                }
                return TreatedDiseasesDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestTreatedDiseases', function () { return new TreatedDiseasesDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
