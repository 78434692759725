module RemeCare.Patient {

    class DeleteInformedConsentModalController implements ng.IComponentController {
        public remark: string;
        public patientName: string;

        public async $onInit(): Promise<void> {
        }
    }

    RemeCare.Patient.remeCarePatientModule.component('rcDeleteInformedConsentModal',
        {
            bindings: {
                $close: '&',
                $dismiss: '&',
                patientName: '<',
            },
            controller: DeleteInformedConsentModalController,
            templateUrl: 'patient/components/informedConsentModal/deleteInformedConsentModal/deleteInformedConsentModal.html'
        });
}