var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var CarePlanActionApiService = /** @class */ (function (_super) {
                __extends(CarePlanActionApiService, _super);
                function CarePlanActionApiService() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.cachedStatusList = {};
                    _this.cacheTimeout = 60000;
                    return _this;
                }
                CarePlanActionApiService.prototype.findActionsInFlowAsync = function (actionId) {
                    return this.getPromise("Actions/" + actionId + "/ActionsInFlow");
                };
                CarePlanActionApiService.prototype.findAgendaActions = function (query) {
                    var _this = this;
                    return new Promise(function (resolve, reject) {
                        _this.getPromise('Actions/Agenda', query, 'actions')
                            .then(function (result) {
                            result.Items.map(function (x) {
                                var item = _this.cachedStatusList[x.Id.toString()];
                                if (item != null) {
                                    var passed = new Date().getTime() - item.added.getTime();
                                    if (passed < _this.cacheTimeout) {
                                        x.Status = item.status;
                                    }
                                    else {
                                        delete _this.cachedStatusList[x.Id.toString()];
                                    }
                                }
                                return x;
                            });
                            resolve(result);
                        })
                            .catch(function (e) { return reject(e); });
                    });
                };
                CarePlanActionApiService.prototype.findPersonalAgendaActions = function (query) {
                    var _this = this;
                    if (query && query.actorRoles && query.actorRoles.length) {
                        return this.getInParts('Actions/PersonalAgenda', query.actorRoles, 50, function (subset) {
                            return (__assign({}, query, { actorRoles: subset }));
                        }).then(function (result) {
                            return _this.handlePersonalAgendaActions(result);
                        });
                    }
                    return this.getPromise('Actions/PersonalAgenda', query, 'actions').then(function (result) {
                        return _this.handlePersonalAgendaActions(result);
                    });
                };
                CarePlanActionApiService.prototype.findRegistrationsAsync = function (query) {
                    var _this = this;
                    return new Promise(function (resolve, reject) {
                        _this.getPromise('Actions/Registrations', query, query.includeCount === true ? 'actionsCount' : 'actions')
                            .then(function (result) {
                            result.Items.map(function (x) {
                                var item = _this.cachedStatusList[x.Id.toString()];
                                if (item != null) {
                                    var passed = new Date().getTime() - item.added.getTime();
                                    if (passed < _this.cacheTimeout) {
                                        x.Status = item.status;
                                    }
                                    else {
                                        delete _this.cachedStatusList[x.Id.toString()];
                                    }
                                }
                            });
                            resolve(result);
                        })
                            .catch(function (e) { return reject(e); });
                    });
                };
                CarePlanActionApiService.prototype.findPossibleRegistrationActionsFilterAsync = function (query) {
                    return this.getPromise('Actions/PossibleRegistrationActions', query, 'registrationActionsFilter');
                };
                CarePlanActionApiService.prototype.findRemarkRegistrationsAsync = function (query) {
                    return this.getPromise('Actions/RemarkRegistrations', query, 'remarks');
                };
                CarePlanActionApiService.prototype.findPossibleRemarkRegistrationActionsFilterAsync = function (query) {
                    return this.getPromise('Actions/PossibleRemarkRegistrationActions', query, 'remarkRegistrationActionsFilter');
                };
                CarePlanActionApiService.prototype.findActionTimingInformations = function (query) {
                    return this.getPromise('Actions/Timings', query, 'actionsTimings');
                };
                CarePlanActionApiService.prototype.getCarePlanActionAsync = function (actionId, query) {
                    return this.getPromise("Actions/" + actionId, query, 'actions');
                };
                CarePlanActionApiService.prototype.getCarePlanActionHistoryAsync = function (actionId) {
                    return this.getPromise("Actions/" + actionId + "/History", null, 'actionHistory');
                };
                CarePlanActionApiService.prototype.getCarePlanActionConfigurationInformationAsync = function (actionId) {
                    return this.getPromise("Actions/" + actionId + "/ConfigurationInformation");
                };
                CarePlanActionApiService.prototype.cancelCarePlanActionsAsync = function (actionIds) {
                    return this.postPromise('Actions/Cancel', { actionIds: actionIds }, 'actions');
                };
                CarePlanActionApiService.prototype.makeRegistrationsAsync = function (registrations, errorCallback) {
                    var params = registrations;
                    // If the action registration is permanent, cache it as being executed
                    if (params.permanent) {
                        this.cachedStatusList[params.actionId.toString()] = { status: 3, added: new Date() };
                    }
                    return this.postPromise("Actions/" + registrations.actionId + "/Registrations", params, "actions-" + registrations.actionId, null, errorCallback);
                };
                // TODO: This should be controlled with header Accept-Language
                CarePlanActionApiService.prototype.getStandardPhrases = function (actionTemplateId, langCode) {
                    var url = 'ActionTemplates/' + actionTemplateId + '/StandardPhrases?langCode=' + langCode;
                    return this.get(url);
                };
                CarePlanActionApiService.prototype.getCarePlanActionRegistrationsAsync = function (actionId) {
                    return this.getPromise("Actions/" + actionId + "/RegistrationValues");
                };
                CarePlanActionApiService.prototype.getCarePlanActionMedicationAsync = function (actionId) {
                    return this.getPromise("Actions/" + actionId + "/MedicationInfo");
                };
                CarePlanActionApiService.prototype.rescheduleAsync = function (actionId, params) {
                    return this.putPromise("Actions/" + actionId + "/Schedule", params, 'reschedule');
                };
                CarePlanActionApiService.prototype.getAberrantRegistrationInformation = function (aberrantRegistrationValueIds) {
                    return this.getInParts('Actions/AberrantRegistrationInformation', aberrantRegistrationValueIds, 10, function (subset) {
                        return { RegistrationValueIds: subset };
                    });
                };
                CarePlanActionApiService.prototype.handlePersonalAgendaActions = function (items) {
                    var _this = this;
                    return new Promise(function (resolve, reject) {
                        items.map(function (x) {
                            var item = _this.cachedStatusList[x.Id.toString()];
                            if (item != null) {
                                var passed = new Date().getTime() - item.added.getTime();
                                if (passed < _this.cacheTimeout) {
                                    x.Status = item.status;
                                }
                                else {
                                    delete _this.cachedStatusList[x.Id.toString()];
                                }
                            }
                            return x;
                        });
                        resolve(items);
                    });
                };
                CarePlanActionApiService.$inject = ['baseUrl', '$http', 'spinnerSvc'];
                return CarePlanActionApiService;
            }(RemeCare.Shared.Framework.ApiServiceBase));
            Services.CarePlanActionApiService = CarePlanActionApiService;
            RemeCare.remeCareAppModule.service('carePlanActionApiSvc', CarePlanActionApiService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
