var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var ContactPointUsageType = RemeCare.Shared.Contract.Code.ContactPointUsageType;
        var MergePersonCandidate = RemeCare.Model.MergePersonCandidate;
        var MergePersonController = /** @class */ (function () {
            function MergePersonController($dialog, $translate, toaster, partyApiSvc, patientSvc, $stateParams, gridBuilderSvc, modalBuilderFactory) {
                this.$dialog = $dialog;
                this.$translate = $translate;
                this.toaster = toaster;
                this.partyApiSvc = partyApiSvc;
                this.patientSvc = patientSvc;
                this.$stateParams = $stateParams;
                this.gridBuilderSvc = gridBuilderSvc;
                this.modalBuilderFactory = modalBuilderFactory;
            }
            MergePersonController.prototype.$onInit = function () {
                this.candidatesSearched = false;
                this.buildGrids();
            };
            MergePersonController.prototype.buildGrids = function () {
                this.mergeCandiateGrid = this.gridBuilderSvc
                    .createGridBuilder()
                    .addColumn('capacities', 'General.Capacity')
                    .addColumn('carePlanNames', 'General.CarePlan')
                    .addExternalLinkColumn('General.Details', 'General.LookDetails', 'management.persons.detail', '_blank', { partyId: 'personId' })
                    .addCheckBoxColumn('Views.Management.Merge', 'isMergeEffective')
                    .build();
            };
            MergePersonController.prototype.checkForMergeCandidates = function () {
                var _this = this;
                this.candidatesSearched = true;
                var domicileAddress = _.find(this.person.addresses, function (x) { return x.contactPointUsageType === ContactPointUsageType.Domicile; });
                var query = {
                    id: this.person.id,
                    firstName: this.person.firstName,
                    lastName: this.person.lastName,
                    identificationNumber: this.person.nationalNumber,
                    birthDate: RemeCare.Shared.DateHelper.toServerDateString(this.person.dateOfBirth)
                };
                if (domicileAddress != null) {
                    query.address = domicileAddress.addressLine1;
                    query.zipCode = domicileAddress.zipCode;
                    query.country = domicileAddress.country;
                }
                this.partyApiSvc
                    .findPersonMergeCandidates(query)
                    .success(function (r) {
                    _this.mergeCandidates = r.map(function (x) { return new MergePersonCandidate(x); });
                    _this.mergeCandidates.forEach(function (x) {
                        x.capacities = x.partyRoles.map(function (x) { return x.Type.Text; }).join(' -');
                        x.carePlans = new Array();
                        var patients = x.partyRoles.filter(function (x) { return x.Type.Id === RemeCare.Contract.Core.Codes.PartyRoleTypeCode.Patient; });
                        patients.forEach(function (p) {
                            _this.patientSvc
                                .getCarePlans(p.Id)
                                .success(function (r) {
                                x.carePlans = x.carePlans.concat(r);
                                x.carePlanNames = x.carePlans.map(function (x) { return x.Name; }).join(' -');
                            })
                                .error(function (e) { return _this.toaster.error(e); });
                        });
                    });
                    _this.mergeCandiateGrid.setData(_this.mergeCandidates);
                })
                    .error(function (e) { return _this.toaster.error(e); });
            };
            MergePersonController.prototype.healthCareDialogBox = function () {
                this.$dialog.messageBox('Views.Management.MergeSelected', 'Views.Management.HealthCareWarning', [
                    { result: 'Ok', label: 'General.Ok', cssClass: 'btn btn-default' }
                ]);
            };
            MergePersonController.prototype.nonHealthCareDialogBox = function () {
                var _this = this;
                this.$dialog
                    .warningMessageBox(this.$translate.instant('Views.Management.MergeSelected'), this.$translate.instant('Views.Management.PopUpWarning'), [
                    { result: 'cancel', label: this.$translate.instant('General.Cancel'), cssClass: 'btn-default' },
                    {
                        result: 'yes',
                        label: this.$translate.instant('Views.Management.Merge'),
                        cssClass: 'btn-primary'
                    }
                ])
                    .then(function (result) {
                    if (result === 'yes') {
                        _this.mergePersonInfo = _this.mergeCandidates
                            .filter(function (x) { return x.isMergeEffective; })
                            .map(function (x) { return x.personId; });
                        _this.partyApiSvc
                            .mergePersons(_this.$stateParams.partyId, _this.mergePersonInfo)
                            .success(function () {
                            _this.candidatesSearched = false;
                        })
                            .error(function (e) {
                            _this.toaster.error(e);
                        });
                    }
                });
            };
            MergePersonController.prototype.hasPartyRoleHealthCare = function (id) {
                return (id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareNonProfessional ||
                    id === RemeCare.Shared.Contract.Code.PartyRoleType.HealthCareProfessional);
            };
            MergePersonController.prototype.confirmMerge = function () {
                var _this = this;
                if (this.mergeCandidates.filter(function (x) { return x.isMergeEffective; }).length === 0) {
                    this.toaster.error(this.$translate.instant('Views.Management.NoMergeEffectiveSelected'));
                    return;
                }
                //check if our survivor has type healthcare
                var survivorHealthCare = _.find(this.person.partyRoles, function (x) {
                    return _this.hasPartyRoleHealthCare(x.PartyRoleType.Id);
                });
                //check how many merge candidates have healthcareprovider roles
                var mergeCandidatesHealthCare = _.filter(this.mergeCandidates, function (x) { return _.find(x.partyRoles, function (y) { return _this.hasPartyRoleHealthCare(y.Type.Id); }) != null; });
                //if more than two mergecandidates have are healthcareproviders or if the merge candidate and at least one mergecandidate have it we can't merge
                if ((survivorHealthCare != null && mergeCandidatesHealthCare.length >= 1) ||
                    mergeCandidatesHealthCare.length >= 2) {
                    this.healthCareDialogBox();
                }
                else {
                    this.nonHealthCareDialogBox();
                }
            };
            return MergePersonController;
        }());
        RemeCare.Management.remeCareManagementModule.component('rcMergePerson', {
            controller: MergePersonController,
            controllerAs: 'mergePersonCtrl',
            bindings: {
                person: '<'
            },
            templateUrl: 'views/management/persons/mergeCandidates.html'
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
