var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var CancelCareRequestController = /** @class */ (function (_super) {
            __extends(CancelCareRequestController, _super);
            function CancelCareRequestController($scope, $translate, toaster) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            CancelCareRequestController.prototype.$onInit = function () { };
            CancelCareRequestController.prototype.confirm = function () {
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.careRequest);
            };
            return CancelCareRequestController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CareRequest.remeCareCareRequestModule.controller('cancelCareRequestModalCtrl', function ($scope, $translate, toaster) { return new CancelCareRequestController($scope, $translate, toaster); });
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
