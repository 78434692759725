namespace RemeCare.CareRequest {
    import Guid = Shared.Contract.Guid;
    import TelephoneNumber = Model.PhoneNumber;
    import GroupAssignment = Management.GroupAssignment;

    export class RequestContactInfo {
        public id: Guid;
        public address: Model.Address;
        public telephone: TelephoneNumber = new TelephoneNumber();
        public mobile: TelephoneNumber = new TelephoneNumber();
        public email: string;
        public update: RequestContactInfoUpdate = new RequestContactInfoUpdate();

        constructor(serverObject?: Contract.CareRequest.Read.IRequestContactInfo) {
            this.address = new Model.Address();
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.address.addressLine1 = serverObject.Address;
                this.address.zipCode = serverObject.ZipCode;
                this.address.city = serverObject.City;
                this.address.country = serverObject.Country;
                this.telephone = new TelephoneNumber(serverObject.Telephone);
                this.mobile = new TelephoneNumber(serverObject.Mobile);
                this.email = serverObject.Email;
                this.update = new RequestContactInfoUpdate(serverObject.Update);
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.IRequestContactInfo {
            return {
                id: this.id,
                address: this.address && this.address.addressLine1,
                zipCode: this.address && this.address.zipCode,
                city: this.address && this.address.city,
                country: this.address && this.address.country,
                telephone: this.telephone.toServerWrite(),
                mobile: this.mobile.toServerWrite(),
                email: this.email,
                update: this.update.toServerWrite(),
            };
        }

        public updateContactInfo(update: RequestContactInfo): void {
            if (update.update.hasUpdatedAddress) {
                this.address = update.address;
            }
            if (update.update.hasUpdatedCity) {
                this.address.city = update.address.city;
            }
            if (update.update.hasUpdatedCountry) {
                this.address.country = update.address.country;
            }
            if (update.update.hasUpdatedEmail) {
                this.email = update.email;
            }
            if (update.update.hasUpdatedMobilePhone) {
                this.mobile = angular.copy(update.mobile);
            }
            if (update.update.hasUpdatedPostalCode) {
                this.address.zipCode = update.address.zipCode;
            }
            if (update.update.hasUpdatedTelephone) {
                this.telephone = angular.copy(update.telephone);
            }
        }
    }

    export class RequestContactInfoUpdate {
        public hasUpdatedAddress: boolean;
        public hasUpdatedCity: boolean;
        public hasUpdatedCountry: boolean;
        public hasUpdatedEmail: boolean;
        public hasUpdatedMobilePhone: boolean;
        public hasUpdatedPostalCode: boolean;
        public hasUpdatedTelephone: boolean;

        constructor(serverObject?: Contract.CareRequest.Read.IRequestContactInfoUpdate) {
            if (serverObject != null) {
                this.hasUpdatedAddress = serverObject.HasUpdatedAddress;
                this.hasUpdatedCity = serverObject.HasUpdatedCity;
                this.hasUpdatedCountry = serverObject.HasUpdatedCountry;
                this.hasUpdatedEmail = serverObject.HasUpdatedEmail;
                this.hasUpdatedMobilePhone = serverObject.HasUpdatedMobilePhone;
                this.hasUpdatedPostalCode = serverObject.HasUpdatedPostalCode;
                this.hasUpdatedTelephone = serverObject.HasUpdatedTelephone;
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.IRequestContactInfoUpdate {
            return {
                hasUpdatedAddress: this.hasUpdatedAddress,
                hasUpdatedCity: this.hasUpdatedCity,
                hasUpdatedCountry: this.hasUpdatedCountry,
                hasUpdatedEmail: this.hasUpdatedEmail,
                hasUpdatedMobilePhone: this.hasUpdatedMobilePhone,
                hasUpdatedPostalCode: this.hasUpdatedPostalCode,
                hasUpdatedTelephone: this.hasUpdatedTelephone,
            };
        }
    }

    export class RequestParty {
        public id: Guid;
        public contactInfo: RequestContactInfo = new RequestContactInfo();
        public group: GroupAssignment;
        protected partyType: Shared.Contract.Code.PartyType;

        constructor(serverObject?: Contract.CareRequest.Read.IRequestParty) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.contactInfo = new RequestContactInfo(serverObject.ContactInfo);
            }
        }

        public getName(): string {
            return '';
        }

        public toServerWrite(): Contract.CareRequest.Write.IRequestParty {
            return {
                id: this.id,
                contactInfo: this.contactInfo.toServerWrite(),
                partyType: this.partyType,
            };
        }
    }

    export class RequestPerson extends RequestParty {
        public static createFromHealthCareProfessional(
            professional: Contract.HealthCareParty.Read.IHealthCareProfessionalParty
        ): RequestPerson {
            const result = new RequestPerson();
            result.firstName = professional.FirstName;
            result.lastName = professional.LastName;
            result.contactInfo.email = professional.Email;
            result.identificationNumber = professional.IdentificationNumber;
            result.contactInfo.telephone = new TelephoneNumber(professional.PhoneNumber);
            result.contactInfo.mobile = new TelephoneNumber(professional.MobileNumber);
            result.contactInfo.address.addressLine1 = professional.AddressLine;
            result.contactInfo.address.zipCode = professional.ZipCode;
            result.contactInfo.address.city = professional.City;
            return result;
        }

        public static createFromPerson(person: Contract.Party.Read.IPerson): RequestPerson {
            if (person != null) {
                const result = new RequestPerson();
                result.firstName = person.FirstName;
                result.lastName = person.LastName;
                result.identificationNumber = person.NationalNumber;
                result.birthDate = Shared.DateHelper.serverDateStringToDate(person.DateOfBirth);
                result.communicationLanguage = person.CommunicationLanguage && person.CommunicationLanguage.Code;
                result.gender = person.Gender && person.Gender.Id;
                result.contactInfo.address = new Model.Address();
                result.contactInfo.address.addressLine1 = person.Address;
                result.contactInfo.address.zipCode = person.ZipCode;
                result.contactInfo.address.city = person.City;
                result.contactInfo.address.country = person.Country;
                result.nationality = person.Nationality && person.Nationality.Code;
                result.contactInfo.email = person.Email;
                result.contactInfo.telephone = new TelephoneNumber(person.Telephone);
                result.contactInfo.mobile = new TelephoneNumber(person.Mobile);
                result.maritalStatus = person.MaritalStatus && person.MaritalStatus.Id;
                result.educationLevel = person.EducationLevel && person.EducationLevel.Id;
                result.technologyAffinities = _(person.TechnologyAffinities).map((t) => t.Id);
                return result;
            }
            return null;
        }
        public firstName: string;
        public lastName: string;
        public identificationNumber: string;
        public birthDate: Date;
        public communicationLanguage: string;
        public gender: Contract.Core.Codes.GenderCode;
        public maritalStatus: number;
        public educationLevel: number;
        public patientCode: string;
        public remark: string;
        public externalReferenceNumber: string;
        public nationality: string;
        public technologyAffinities: number[] = [];
        public update: RequestPersonUpdate = new RequestPersonUpdate();
        public patientFileForExternalRequestId: Guid;
        public externalReferences: RemeCare.Contract.Core.IExternalReference[] = [];

        constructor(serverObject?: Contract.CareRequest.Read.IRequestPerson) {
            super(serverObject);
            this.partyType = Shared.Contract.Code.PartyType.Person;
            if (serverObject != null) {
                this.firstName = serverObject.FirstName;
                this.lastName = serverObject.LastName;
                this.identificationNumber = serverObject.IdentificationNumber;
                this.birthDate = Shared.DateHelper.serverDateStringToDate(serverObject.BirthDate);
                this.communicationLanguage = serverObject.CommunicationLanguage;
                this.gender = serverObject.Gender;
                this.maritalStatus = serverObject.MaritalStatus;
                this.educationLevel = serverObject.EducationLevel;
                this.patientCode = serverObject.PatientCode;
                this.remark = serverObject.Remark;
                this.externalReferenceNumber = serverObject.ExternalReferenceNumber;
                this.externalReferences = serverObject.ExternalReferences || [];
                this.nationality = serverObject.Nationality;
                this.technologyAffinities = serverObject.TechnologyAffinities;
                this.update = new RequestPersonUpdate(serverObject.Update);
            }
        }

        public getName(): string {
            return `${this.lastName != null ? this.lastName : ''} ${this.firstName != null ? this.firstName : ''}`;
        }

        public toServerWrite(): Contract.CareRequest.Write.IRequestPerson {
            const result = super.toServerWrite() as Contract.CareRequest.Write.IRequestPerson;
            result.firstName = this.firstName;
            result.lastName = this.lastName;
            result.birthDate = Shared.DateHelper.toServerDateString(this.birthDate);
            result.communicationLanguage = this.communicationLanguage;
            result.identificationNumber = this.identificationNumber;
            result.gender = this.gender;
            result.maritalStatus = this.maritalStatus;
            result.educationLevel = this.educationLevel;
            result.patientCode = this.patientCode;
            result.remark = this.remark;
            result.externalReferenceNumber = this.externalReferenceNumber;
            result.externalReferences = this.externalReferences;
            result.nationality = this.nationality;
            result.technologyAffinities = this.technologyAffinities;
            result.update = this.update.toServerWrite();
            result.patientFileForExternalRequestId = this.patientFileForExternalRequestId;
            return result;
        }

        public updatePerson(update: RequestPerson): void {
            if (update.update.hasUpdatedBirthDate) {
                this.birthDate = angular.copy(update.birthDate);
            }
            if (update.update.hasUpdatedCommunicationLanguage) {
                this.communicationLanguage = update.communicationLanguage;
            }
            if (update.update.hasUpdatedEducationLevel) {
                this.educationLevel = update.educationLevel;
            }
            if (update.update.hasUpdatedFirstName) {
                this.firstName = update.firstName;
            }
            if (update.update.hasUpdatedGender) {
                this.gender = update.gender;
            }
            if (update.update.hasUpdatedIdentificationNumber) {
                this.identificationNumber = update.identificationNumber;
            }
            if (update.update.hasUpdatedLastName) {
                this.lastName = update.lastName;
            }
            if (update.update.hasUpdatedMaritalStatus) {
                this.maritalStatus = update.maritalStatus;
            }
            if (update.update.hasUpdatedNationality) {
                this.nationality = update.nationality;
            }
            if (update.update.hasUpdatedTechnologyAffinity) {
                this.technologyAffinities = angular.copy(update.technologyAffinities);
            }
            this.contactInfo.updateContactInfo(update.contactInfo);
        }
    }

    export class RequestPersonUpdate {
        public hasUpdatedBirthDate: boolean;
        public hasUpdatedCommunicationLanguage: boolean;
        public hasUpdatedEducationLevel: boolean;
        public hasUpdatedFirstName: boolean;
        public hasUpdatedGender: boolean;
        public hasUpdatedIdentificationNumber: boolean;
        public hasUpdatedLastName: boolean;
        public hasUpdatedMaritalStatus: boolean;
        public hasUpdatedNationality: boolean;
        public hasUpdatedTechnologyAffinity: boolean;

        constructor(serverObject?: Contract.CareRequest.Read.IRequestPersonUpdate) {
            if (serverObject != null) {
                this.hasUpdatedBirthDate = serverObject.HasUpdatedBirthDate;
                this.hasUpdatedCommunicationLanguage = serverObject.HasUpdatedCommunicationLanguage;
                this.hasUpdatedEducationLevel = serverObject.HasUpdatedEducationLevel;
                this.hasUpdatedFirstName = serverObject.HasUpdatedFirstName;
                this.hasUpdatedGender = serverObject.HasUpdatedGender;
                this.hasUpdatedIdentificationNumber = serverObject.HasUpdatedIdentificationNumber;
                this.hasUpdatedLastName = serverObject.HasUpdatedLastName;
                this.hasUpdatedMaritalStatus = serverObject.HasUpdatedMaritalStatus;
                this.hasUpdatedNationality = serverObject.HasUpdatedNationality;
                this.hasUpdatedTechnologyAffinity = serverObject.HasUpdatedTechnologyAffinity;
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.IRequestPersonUpdate {
            return {
                hasUpdatedBirthDate: this.hasUpdatedBirthDate,
                hasUpdatedCommunicationLanguage: this.hasUpdatedCommunicationLanguage,
                hasUpdatedEducationLevel: this.hasUpdatedEducationLevel,
                hasUpdatedFirstName: this.hasUpdatedFirstName,
                hasUpdatedGender: this.hasUpdatedGender,
                hasUpdatedIdentificationNumber: this.hasUpdatedIdentificationNumber,
                hasUpdatedLastName: this.hasUpdatedLastName,
                hasUpdatedMaritalStatus: this.hasUpdatedMaritalStatus,
                hasUpdatedNationality: this.hasUpdatedNationality,
                hasUpdatedTechnologyAffinity: this.hasUpdatedTechnologyAffinity,
            };
        }
    }

    export class RequestOrganisation extends RequestParty {
        public static createFromHealthCareOrganisation(
            organisation: Contract.HealthCareParty.Read.IHealthCareOrganisationParty
        ): RequestOrganisation {
            const result = new RequestOrganisation();
            result.name = organisation.Name;
            result.contactInfo.address.addressLine1 = organisation.AddressLine;
            result.contactInfo.address.zipCode = organisation.ZipCode;
            result.contactInfo.address.city = organisation.City;
            result.contactInfo.email = organisation.Email;
            result.contactInfo.telephone = new TelephoneNumber(organisation.PhoneNumber);
            return result;
        }
        public name: string;

        constructor(serverObject?: Contract.CareRequest.Read.IRequestOrganisation) {
            super(serverObject);
            this.partyType = Shared.Contract.Code.PartyType.Organisation;
            if (serverObject != null) {
                this.name = serverObject.Name;
            }
        }

        public getName(): string {
            return this.name;
        }
    }

    export class PatientContactPerson {
        public id: Guid;
        public type: Shared.Contract.IEnumTranslation;
        public isHCNonProfessional: boolean;
        public matchedPartyId: Guid;
        public person: RequestPerson = new RequestPerson();
        public matchedParty: RequestPerson;

        constructor(serverObject?: Contract.CareRequest.Read.IPatientContactPerson) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.type = serverObject.ContactPersonType;
                this.isHCNonProfessional = serverObject.IsHCNonProfessional;
                this.matchedPartyId = serverObject.MatchedPartyId;
                this.person = new RequestPerson(serverObject.Person);
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.IPatientContactPerson {
            return {
                id: this.id,
                type: this.type && this.type.Id,
                isHCNonProfessional: this.isHCNonProfessional,
                matchedPartyId: this.matchedPartyId,
                person: this.person.toServerWrite(),
            };
        }

        public firstName(): string {
            if (this.matchedParty != null) {
                return this.matchedParty.firstName;
            } else {
                return this.person.firstName;
            }
        }

        public lastName(): string {
            if (this.matchedParty != null) {
                return this.matchedParty.lastName;
            } else {
                return this.person.lastName;
            }
        }
    }
}
