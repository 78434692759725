/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {

    import Document = Shared.Contract.IMetaDataDocument;

    interface ICareRequestDocumentScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.CareRequestDocumentDefinition;
        careRequestDocuments: Array<CareRequestDocument>;
        documents: Array<Document>;
        advancedColumns: Array<string>;
        onDocumentAdded(d: Document): void;
        onDocumentDeleted(d: Document): void;
        getAdvancedColumnData(id: Shared.Contract.Guid): void;
    }

    class CareRequestDocumentController extends CareRequestPartControllerBase<ICareRequestDocumentScope> {

        constructor(
            $scope: ICareRequestDocumentScope,
            $translate,
            toaster,
            private $filter: ng.IFilterService,
            private modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private documentSvc: Shared.Service.DocumentService,
            protected careRequestSvc: CareRequestSvc) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.onDocumentAdded = (d) => this.onDocumentAdded(d);
            $scope.onDocumentDeleted = (d) => this.onDocumentDeleted(d);
            $scope.getAdvancedColumnData = (d) => this.getAdvancedColumnData(d);
            this.$onInit();
            $scope.isVisible = () => this.isVisible();
        }

        public $onInit(): void {
            this.$scope.advancedColumns = this.$scope.careRequestPart.hasReferenceDate
                ? ['General.DocumentObservationDate']
                : [];
            this.$scope.careRequestDocuments = _(this.$scope.careRequest.careRequestDocuments)
                .filter(t => t.careRequestDocumentDefinitionId === this.$scope.careRequestPart.localIdentity);
            this.$scope.documents = _(this.$scope.careRequestDocuments).map(c => c.document);
        }

        protected isVisible(): boolean {
            return this.careRequestSvc.isVisibleWithConditionality(this.$scope.careRequestPart, this.$scope.careRequestTemplate, this.$scope.careRequest);
        }

        private onDocumentAdded(d: Document): void {
            var careRequestDocument = CareRequestDocument.createFromDocument(d, this.$scope.careRequestPart.localIdentity);
            if (this.$scope.careRequestPart.hasReferenceDate) {
                this.showReferenceDateModal()
                    .done(date => {
                        careRequestDocument.referenceDate = date;
                        this.$scope.careRequestDocuments.push(careRequestDocument);
                        this.$scope.careRequest.careRequestDocuments.push(careRequestDocument);
                    })
                    .fail(() => {
                        this.documentSvc.deleteDocument(d.Id);
                        var index = this.$scope.documents.indexOf(d);
                        this.$scope.documents.splice(index, 1);
                    });
            } else {
                this.$scope.careRequestDocuments.push(careRequestDocument);
                this.$scope.careRequest.careRequestDocuments.push(careRequestDocument);
            }
        }

        private onDocumentDeleted(d: Document): void {
            var careRequestDocument = _.find(this.$scope.careRequestDocuments, c => c.id === d.Id);
            var index = this.$scope.careRequestDocuments.indexOf(careRequestDocument);
            this.$scope.careRequestDocuments.splice(index, 1);
            index = this.$scope.careRequest.careRequestDocuments.indexOf(careRequestDocument);
            this.$scope.careRequest.careRequestDocuments.splice(index, 1);
        }

        private getAdvancedColumnData(id: Shared.Contract.Guid): Array<string> {
            if (!this.$scope.careRequestPart.hasReferenceDate) return [];
            var careRequestDocument = _.find(this.$scope.careRequestDocuments, c => c.id === id);
            if (careRequestDocument == null) return [''];
            return [ this.$filter('date')(careRequestDocument.referenceDate, 'shortDate') ];
        }

        private showReferenceDateModal(): JQueryPromise<Date> {
            var deferred = jQuery.Deferred<Date>();
            this.modalBuilderFactory.createModalBuilder<Date>()
                .setController('referenceDateCtrl')
                .setTemplateUrl('views/shared/referenceDate.html')
                .setResultCallBack(r => deferred.resolve(r))
                .setDismissCallBack(() => deferred.reject())
                .build();
            return deferred.promise();
        }
    }

    class CareRequestDocumentDirective extends CareRequestPartDirectiveBase {

        controller = ['$scope', '$translate', 'toaster', '$filter', 'modalBuilderFactory', 'documentSvc', 'careRequestSvc',
            ($scope, $translate, toaster, $filter, modalBuilderFactory, documentSvc, careRequestSvc
            ) => new CareRequestDocumentController($scope, $translate, toaster, $filter, modalBuilderFactory, documentSvc, careRequestSvc)];

        templateUrl = 'views/careRequest/directive/careRequestDocument.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestDocument', () => new CareRequestDocumentDirective());
}