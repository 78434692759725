module RemeCare.Patient.Model {
    import RegistrationValue = RemeCare.Model.RegistrationValue;
    import RegistrationValueFactory = RemeCare.Model.RegistrationValueFactory;
    import Guid = Shared.Contract.Guid;

    export class RegistrationValueOverview {
        id: Guid;
        name: string;
        exceedsThreshold: boolean;
        plannedDate: string;
        registrationDate: string;
        registrationSource: string;
        carePlanActionId: Guid;
        value: RegistrationValue;
        executionRight: Shared.Framework.AuthRight;

        constructor(serverObject?: Contract.Patient.Read.IRegistrationValueOverview) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.name = serverObject.Name;
                this.carePlanActionId = serverObject.CarePlanActionId;
                this.exceedsThreshold = serverObject.ExceedsThreshold;
                this.plannedDate = serverObject.PlannedDate;
                this.registrationDate = serverObject.RegistrationDate;
                this.registrationSource = serverObject.RegistrationSource;
                this.value = RegistrationValueFactory.createRegistrationValueFromServer(serverObject.Value);
                this.executionRight = serverObject.ExecutionRight;
            }
        }
    }
}