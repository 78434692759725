var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var OneTimeLogin;
    (function (OneTimeLogin) {
        var OneTimeLoginApiService = /** @class */ (function (_super) {
            __extends(OneTimeLoginApiService, _super);
            function OneTimeLoginApiService() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            OneTimeLoginApiService.prototype.login = function (oneTimeKey) {
                var _this = this;
                return this.post('OneTimeUrl/Login', {
                    key: oneTimeKey,
                }, 'onetimelogin', true).then(function (httpResponse) {
                    return httpResponse.data;
                }).catch(function (httpResponse) {
                    if (httpResponse.status === 401) {
                        return _this.$q.resolve(httpResponse.data);
                    }
                    return _this.$q.reject(httpResponse.data);
                });
            };
            OneTimeLoginApiService.$inject = RemeCare.Shared.Framework.ApiServiceBase.injectionParameters;
            return OneTimeLoginApiService;
        }(RemeCare.Shared.Framework.ApiServiceBase));
        OneTimeLogin.OneTimeLoginApiService = OneTimeLoginApiService;
        OneTimeLogin.remeCareOneTimeLoginModule.service('oneTimeLoginApi', OneTimeLoginApiService);
    })(OneTimeLogin = RemeCare.OneTimeLogin || (RemeCare.OneTimeLogin = {}));
})(RemeCare || (RemeCare = {}));
