var RemeCare;
(function (RemeCare) {
    var Core;
    (function (Core) {
        var Services;
        (function (Services) {
            var FileIconService = /** @class */ (function () {
                function FileIconService() {
                }
                FileIconService.prototype.getFileIcon = function (extension) {
                    switch (extension.trim().toLowerCase()) {
                        case 'doc':
                        case 'docx':
                            return 'file-word-o';
                        case 'ppt':
                        case 'pptx':
                            return 'file-powerpoint-o';
                        case 'xls':
                        case 'xlsx':
                            return 'file-excel-o';
                        case 'txt':
                            return 'file-text-o';
                        case 'pdf':
                            return 'file-pdf-o';
                        case 'zip':
                            return 'file-archive-o';
                        case 'jpg':
                        case 'jpeg':
                        case 'gif':
                        case 'png':
                            return 'file-image-o';
                        case 'eml':
                            return 'envelope-o';
                        case 'ehealth':
                            return 'lock';
                        default:
                            return 'file-o';
                    }
                };
                return FileIconService;
            }());
            Services.FileIconService = FileIconService;
            RemeCare.remeCareAppModule.service('fileIconSvc', FileIconService);
        })(Services = Core.Services || (Core.Services = {}));
    })(Core = RemeCare.Core || (RemeCare.Core = {}));
})(RemeCare || (RemeCare = {}));
