'use strict';
var RemeCare;
(function (RemeCare) {
    var Model;
    (function (Model) {
        var Hospitalisation = /** @class */ (function () {
            function Hospitalisation(serverParty) {
                if (serverParty != null) {
                    this.id = serverParty.Id;
                    this.startDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverParty.StartDate);
                    this.endDate = RemeCare.Shared.DateHelper.serverDateStringToDate(serverParty.EndDate);
                    this.departmentType = serverParty.DepartmentType;
                    this.hospitalisationReason = serverParty.HospitalisationReason;
                    this.otherReason = serverParty.OtherReason;
                }
            }
            Hospitalisation.prototype.getDuration = function () {
                if (this.startDate == null || this.endDate == null)
                    return 0;
                var momentStart = moment(this.startDate);
                var momentEnd = moment(this.endDate);
                if (momentStart.isAfter(momentEnd, 'day'))
                    return 0;
                return momentEnd.diff(momentStart, 'day') + 1;
            };
            Hospitalisation.prototype.getHospitalisationReason = function () {
                if (this.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                    return this.hospitalisationReason.Text + ': ' + this.otherReason;
                }
                return this.hospitalisationReason.Text;
            };
            Hospitalisation.prototype.toServerWrite = function () {
                return {
                    id: this.id,
                    careType: this.departmentType != null ? this.departmentType.Id : null,
                    reasonId: this.hospitalisationReason != null ? this.hospitalisationReason.Id : null,
                    startDate: RemeCare.Shared.DateHelper.toServerDateString(this.startDate),
                    endDate: RemeCare.Shared.DateHelper.toServerDateString(this.endDate),
                    otherReason: this.hospitalisationReason != null && this.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther
                        ? this.otherReason
                        : null
                };
            };
            Hospitalisation.hospitalisationReasonOther = 'e09020e8-4645-481f-a709-bc5de3acf14c';
            return Hospitalisation;
        }());
        Model.Hospitalisation = Hospitalisation;
    })(Model = RemeCare.Model || (RemeCare.Model = {}));
})(RemeCare || (RemeCare = {}));
