/// <reference path="careRequestPartDirectiveBase.ts"/>
/// <reference path="../../contract/core/codes/genderCode.ts"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var CareRequest;
    (function (CareRequest) {
        var Directive;
        (function (Directive) {
            var CareRequestUIControlType = RemeCare.Shared.Contract.Code.CareRequestUIControlType;
            var PatientApplicationController = /** @class */ (function (_super) {
                __extends(PatientApplicationController, _super);
                // @ngInject
                function PatientApplicationController($scope, $translate, toaster, masterdataSvc, nationalNumberSvc, gridBuilderSvc, modalBuilderFactory, careRequestSvc) {
                    var _this = _super.call(this, $scope, $translate, toaster, careRequestSvc) || this;
                    _this.$scope = $scope;
                    _this.$translate = $translate;
                    _this.toaster = toaster;
                    _this.masterdataSvc = masterdataSvc;
                    _this.nationalNumberSvc = nationalNumberSvc;
                    _this.gridBuilderSvc = gridBuilderSvc;
                    _this.modalBuilderFactory = modalBuilderFactory;
                    _this.careRequestSvc = careRequestSvc;
                    $scope.addContactPerson = function () { return _this.addContactPersonAsync(); };
                    $scope.identificationNumberChanged = function () { return _this.identificationNumberChanged(); };
                    $scope.birthDateChanged = function () { return _this.birthDateChanged(); };
                    $scope.genderChanged = function () { return _this.genderChanged(); };
                    $scope.nationalityChanged = function () { return _this.nationalityChanged(); };
                    $scope.isBirthDateValid = function () { return _this.isBirthDateValid(); };
                    $scope.isGenderValid = function () { return _this.isGenderValid(); };
                    $scope.isNationalityValid = function () { return _this.isNationalityValid(); };
                    $scope.now = new Date();
                    _this.$onInit();
                    return _this;
                }
                PatientApplicationController.prototype.$onInit = function () {
                    this.$scope.noNumberRegex = RemeCare.Framework.Regexes.noNumberRegex;
                    this.buildGrid();
                    this.loadDataAsync();
                };
                PatientApplicationController.prototype.identificationNumberChanged = function () {
                    this.validateIdentificationNumberAndSetProperties(true, true, true, true);
                };
                PatientApplicationController.prototype.isBirthDateValid = function () {
                    return this.nationalNumberSvc.isValidDateOfBirth(this.$scope.careRequest.patient.birthDate, this.$scope.careRequest.patient.identificationNumber);
                };
                PatientApplicationController.prototype.birthDateChanged = function () {
                    this.validateIdentificationNumberAndSetProperties(true, false, false);
                };
                PatientApplicationController.prototype.isGenderValid = function () {
                    return this.nationalNumberSvc.isValidGender(this.$scope.careRequest.patient.gender, this.$scope.careRequest.patient.identificationNumber);
                };
                PatientApplicationController.prototype.genderChanged = function () {
                    this.validateIdentificationNumberAndSetProperties(false, true, false);
                };
                PatientApplicationController.prototype.isNationalityValid = function () {
                    return this.nationalNumberSvc.isValidNationalityCode(this.$scope.careRequest.patient.nationality, this.$scope.careRequest.patient.identificationNumber);
                };
                PatientApplicationController.prototype.nationalityChanged = function () {
                    this.validateIdentificationNumberAndSetProperties(false, false, true);
                };
                PatientApplicationController.prototype.loadDataAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var _a, commlanguages, countries, genders, maritalStatusses, educations, technologicalAffinities, nationalities, e_1;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _b.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, Promise.all([
                                            this.masterdataSvc.getCommLanguagesAsync(),
                                            this.masterdataSvc.getCountriesAsync(),
                                            this.masterdataSvc.getGendersAsync(),
                                            this.masterdataSvc.getMaritalStatussesAsync(),
                                            this.masterdataSvc.getEducationsAsync(),
                                            this.masterdataSvc.getTechnologicalAffinitiesAsync(),
                                            this.masterdataSvc.getNationalitiesAsync(),
                                        ])];
                                case 1:
                                    _a = _b.sent(), commlanguages = _a[0], countries = _a[1], genders = _a[2], maritalStatusses = _a[3], educations = _a[4], technologicalAffinities = _a[5], nationalities = _a[6];
                                    this.$scope.commlanguages = commlanguages;
                                    this.$scope.countries = countries;
                                    this.$scope.genders = genders;
                                    this.$scope.maritalStatusses = maritalStatusses;
                                    this.$scope.educations = educations;
                                    this.$scope.technologicalAffinities = technologicalAffinities;
                                    this.$scope.nationalities = nationalities;
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _b.sent();
                                    this.toaster.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                PatientApplicationController.prototype.validateIdentificationNumberAndSetProperties = function (checkDateOfBirth, checkGender, checkNationality, overwrite) {
                    var _this = this;
                    var dateOfBirthGetterSetter = checkDateOfBirth && this.$scope.isPresent(CareRequestUIControlType.PatientBirthDate)
                        ? function (dateOfBirth) {
                            if (dateOfBirth) {
                                _this.$scope.careRequest.patient.birthDate = dateOfBirth;
                            }
                            return _this.$scope.careRequest.patient.birthDate;
                        }
                        : null;
                    var genderGetterSetter = checkGender && this.$scope.isPresent(CareRequestUIControlType.PatientGender)
                        ? function (gender) {
                            if (gender) {
                                _this.$scope.careRequest.patient.gender = gender;
                            }
                            return _this.$scope.careRequest.patient.gender;
                        }
                        : null;
                    var nationalityGetterSetter = checkNationality && this.$scope.isPresent(CareRequestUIControlType.PatientNationality)
                        ? function (nationality) {
                            if (nationality) {
                                _this.$scope.careRequest.patient.nationality = nationality;
                            }
                            return _this.$scope.careRequest.patient.nationality;
                        }
                        : null;
                    this.nationalNumberSvc.validateAndSetProperties(this.$scope.careRequest.patient.identificationNumber, dateOfBirthGetterSetter, genderGetterSetter, null, nationalityGetterSetter, overwrite);
                };
                PatientApplicationController.prototype.buildGrid = function () {
                    var _this = this;
                    var gridBuilder = this.gridBuilderSvc
                        .createGridBuilder()
                        .addColumn('person.lastName', 'General.LastName')
                        .addColumn('person.firstName', 'General.FirstName')
                        .addColumn('type.Text', 'Views.CareRequest.Details.CareTeamInformal.SupportType')
                        .addBoolColumn('isHCNonProfessional', 'Views.CareRequest.Details.HCNonProf');
                    if (!this.$scope.readOnly) {
                        gridBuilder = gridBuilder
                            .addEditButtonWithPromiseFunctionColumn(function (p) { return _this.editContactPersonAsync(p); })
                            .addDeleteButtonColumn();
                    }
                    this.$scope.contactPersonsGrid = gridBuilder.build();
                    this.$scope.contactPersonsGrid.setData(this.$scope.careRequest.patientContactPersons);
                };
                PatientApplicationController.prototype.addContactPersonAsync = function () {
                    return __awaiter(this, void 0, void 0, function () {
                        var newPerson;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, this.editContactPersonAsync()];
                                case 1:
                                    newPerson = _a.sent();
                                    this.$scope.contactPersonsGrid.addRow(newPerson);
                                    return [2 /*return*/];
                            }
                        });
                    });
                };
                PatientApplicationController.prototype.editContactPersonAsync = function (contactPerson) {
                    var _this = this;
                    return new Promise(function (resolve, reject) {
                        _this.modalBuilderFactory
                            .createModalBuilder()
                            .setController('contactPersonApplicationCtrl')
                            .setTemplateUrl('views/careRequest/directive/contactPersonApplication.html')
                            .setScope({
                            contactPerson: angular.copy(contactPerson) || new CareRequest.PatientContactPerson(),
                            careRequestPart: _this.$scope.careRequestPart,
                        })
                            .setResultCallBack(function (p) { return resolve(p); })
                            .setSize(RemeCare.Shared.Framework.Helper.ModalSize.medium)
                            .build();
                    });
                };
                return PatientApplicationController;
            }(Directive.CareRequestPartControllerBase));
            var PatientApplicationDirective = /** @class */ (function (_super) {
                __extends(PatientApplicationDirective, _super);
                function PatientApplicationDirective() {
                    var _this = _super !== null && _super.apply(this, arguments) || this;
                    _this.controller = PatientApplicationController;
                    _this.templateUrl = 'views/careRequest/directive/patientApplication.html';
                    return _this;
                }
                return PatientApplicationDirective;
            }(Directive.CareRequestPartDirectiveBase));
            CareRequest.remeCareCareRequestModule.directive('rcCareRequestPatientApplication', function () { return new PatientApplicationDirective(); });
        })(Directive = CareRequest.Directive || (CareRequest.Directive = {}));
    })(CareRequest = RemeCare.CareRequest || (RemeCare.CareRequest = {}));
})(RemeCare || (RemeCare = {}));
