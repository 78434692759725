module RemeCare.Agenda {

    class AgendaHeaderController {
        isPatient: boolean;

        constructor(
            private authservice: Shared.Framework.AuthService) {
            
        }

        $onInit(): void {
            this.isPatient = this.authservice.isPatient();
        }
    }

    remeCareAgendaModule.component('rcAgendaHeader',
    {
        controller: AgendaHeaderController,
        controllerAs: 'agendaHeaderCtrl',
        templateUrl: 'views/agenda/agendaHeader.html',
        transclude: true
    });
}