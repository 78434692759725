namespace RemeCare.OneTimeLogin {
    class RemeCareOneTimeLoginConfiguration extends Shared.Framework.RouteConfiguration {
        protected config(): void {
            this.add(
                'onetimelogin',
                {
                    // Be careful changing this route as this is being referenced in the integration API
                    url: '/OneTimeLogin?key',
                    component: 'rcOneTimeLogin',
                },
                ['Views_OneTimeLogin']
            );
        }
    }

    remeCareOneTimeLoginModule.config($stateProvider => new RemeCareOneTimeLoginConfiguration($stateProvider));
}
