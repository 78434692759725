/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import CareRequestUiControlType = Shared.Contract.Code.CareRequestUIControlType;
    import PartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;

    interface ICareRequestorAcceptanceModalScope
        extends ICareRequestPartBaseScope,
            Shared.Framework.IBaseScope,
            ng.ui.bootstrap.IModalScope {
        careRequest: CareRequest;
        careRequestPart: Shared.Framework.Model.CareRequestorDefinition;
        professionals: PartyRoleSummary[];
        professional: Model.HealthCareProfessional;
        organisations: PartyRoleSummary[];
        organisation: Contract.HealthCareParty.Read.IHealthCareOrganisationParty;
        organisationName: string;
        specialties: Shared.Contract.IEnumTranslation[];
        intermediaryInfo: {
            organisation: boolean;
        };
        careRequestorForm: Shared.Framework.Directive.IFormController;

        atLeastOneNumberCareRequestor(): boolean;
        atLeastOneNumberCareRequestorContact(): boolean;
        clearSelectedProfessional(): void;
        searchProfessional(): void;
        clearProfessional(): void;
        searchOrganisation(): void;
        clearOrganisation(): void;
        isMaxReached(role: Shared.Framework.Model.ActorRole): boolean;
        confirm(): void;
        requestorProfessionalRequired(): boolean;
        $close(): boolean;
    }

    class CareRequestorAcceptanceModalController extends CareRequestPartControllerBase<
        ICareRequestorAcceptanceModalScope
    > {
        // @ngInject
        constructor(
            protected $scope: ICareRequestorAcceptanceModalScope,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly therapyApiSvc: Core.Services.TherapyApiService,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.atLeastOneNumberCareRequestor = () => this.atLeastOneNumberCareRequestor();
            $scope.atLeastOneNumberCareRequestorContact = () => this.atLeastOneNumberCareRequestorContact();
            $scope.clearSelectedProfessional = () => this.clearSelectedProfessional();
            $scope.searchProfessional = () => this.searchProfessional();
            $scope.searchOrganisation = () => this.searchOrganisation();
            $scope.clearProfessional = () => this.clearProfessional();
            $scope.clearOrganisation = () => this.clearOrganisation();
            $scope.isMaxReached = a => this.isMaxReached(a);
            $scope.confirm = () => this.confirmAsync();
            $scope.requestorProfessionalRequired = () => this.requestorProfessionalRequired();
        }

        public $onInit(): void {
            if (this.$scope.careRequestPart.roleRequestingHealthCareProfessional) {
                this.loadProfessionalsAsync();
            }
            this.$scope.intermediaryInfo = !this.$scope.careRequestPart.roleRequestingHealthCareOrganisation
                ? { organisation: false }
                : { organisation: null };

            if (this.$scope.careRequest.careRequestor.healthCareOrganisationId != null) {
                this.loadOrganisationAsync();
            }
            if (this.$scope.careRequest.careRequestor.healthCareProfessionalId != null) {
                this.loadProfessionalAsync();
            }
            this.$scope.$watch(
                (s: ICareRequestorAcceptanceModalScope) => s.intermediaryInfo.organisation,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        if (newValue) {
                            this.$scope.careRequest.intermediarySpecialtyId = null;
                            this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = this.$scope.organisation.HealthCareOrganisationType.Id;
                        } else {
                            this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = null;
                        }
                    }
                }
            );
            if (
                this.$scope.isPresent(CareRequestUiControlType.CareRequestorHCOrganisation) &&
                this.$scope.careRequestPart.roleRequestingHealthCareOrganisation
            ) {
                this.loadOrganisationsAsync();
            }
        }

        private async loadOrganisationsAsync(): Promise<void> {
            try {
                this.$scope.organisations = await this.therapyApiSvc.getPossiblePartiesAsync(
                    this.$scope.careRequest.therapyId,
                    this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleId
                );
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadProfessionalAsync(): Promise<void> {
            try {
                const result = await this.healthCareProfessionalSearchSvc.getHealthCareProfessional(
                    this.$scope.careRequest.careRequestor.healthCareProfessionalId
                );
                if (result) {
                    this.$scope.professional = new Model.HealthCareProfessional(result);
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadOrganisationAsync(): Promise<void> {
            try {
                this.$scope.organisation = (await this.healthCareOrganisationSearchSvc.getHealthCareOrganisationsAsync([
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId,
                ]))[0];
                this.$scope.organisationName = this.$scope.organisation.Name;
                if (this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId != null) {
                    this.$scope.intermediaryInfo.organisation = true;
                } else if (this.$scope.careRequest.intermediarySpecialtyId != null) {
                    this.$scope.intermediaryInfo.organisation = false;
                } else if (this.$scope.careRequest.careRequestor.healthCareOrganisationId != null) {
                    this.$scope.intermediaryInfo.organisation = true;
                    this.$scope.careRequest.intermediaryHealthCareOrganisationTypeId = this.$scope.organisation.HealthCareOrganisationType.Id;
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadProfessionalsAsync(): Promise<void> {
            try {
                const professionals = await this.therapyApiSvc.getPossiblePartiesAsync(
                    this.$scope.careRequest.therapyId,
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId
                );
                this.$scope.professionals = professionals;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private requestorProfessionalRequired(): boolean {
            return (
                this.isRequired(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional) ||
                (this.$scope.careRequestPart.roleRequestingHealthCareProfessional &&
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.minimalNumber > 0)
            );
        }

        private clearProfessional(): void {
            this.$scope.professional = null;
            this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
        }

        private atLeastOneNumberCareRequestor(): boolean {
            if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorTelephoneMobile)) {
                return true;
            }
            return (
                this.$scope.careRequest.careRequestor.responsible.contactInfo.telephone.isFilled() ||
                this.$scope.careRequest.careRequestor.responsible.contactInfo.mobile.isFilled()
            );
        }

        private atLeastOneNumberCareRequestorContact(): boolean {
            if (!this.$scope.isRequired(CareRequestUiControlType.CareRequestorContactTelephoneMobile)) {
                return true;
            }
            return (
                this.$scope.careRequest.careRequestor.contactPerson.contactInfo.telephone.isFilled() ||
                this.$scope.careRequest.careRequestor.contactPerson.contactInfo.mobile.isFilled()
            );
        }

        private clearSelectedProfessional(): void {
            this.$scope.professional = null;
            this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
        }

        private searchProfessional(): void {
            const typeAndSpecialties = this.convertToTypeAndSpecialties(
                _(this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleInclusions)
                    .chain()
                    .map(ari => ari.actorRoleInclusionDetails)
                    .flatten()
            );
            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareProfessionalParty>()
                .setController('searchHealthCareProfessionalPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareProfessionalParty.html')
                .setScope({
                    title: this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name,
                    typeAndSpecialties,
                    prefill: this.$scope.careRequestPart.canBeSearchedByExternal
                        ? null
                        : {
                              FirstName: this.$scope.careRequest.careRequestor.responsible.firstName,
                              LastName: this.$scope.careRequest.careRequestor.responsible.lastName,
                              Email: this.$scope.careRequest.careRequestor.responsible.contactInfo.email,
                              IdentificationNumber: this.$scope.careRequest.careRequestor.responsible
                                  .identificationNumber,
                              ZipCode:
                                  this.$scope.careRequest.careRequestor.responsible.contactInfo.address &&
                                  this.$scope.careRequest.careRequestor.responsible.contactInfo.address.addressLine1,
                              City:
                                  this.$scope.careRequest.careRequestor.responsible.contactInfo.address &&
                                  this.$scope.careRequest.careRequestor.responsible.contactInfo.address.city,
                          },
                })
                .setResultCallBack(r => {
                    this.$scope.professional = new Model.HealthCareProfessional(r);
                    this.$scope.careRequest.careRequestor.healthCareProfessionalId = r.PartyRoleId;
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private searchOrganisation(): void {
            const orgTypes = _(this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.actorRoleInclusions)
                .chain()
                .map(ari => ari.actorRoleInclusionDetails)
                .flatten()
                .map(arid => arid.healthCareOrganisationTypeId)
                .value();

            this.modalBuilderFactory
                .createModalBuilder<Contract.HealthCareParty.Read.IHealthCareOrganisationParty>()
                .setController('searchHealthCareOrganisationPartyCtrl')
                .setTemplateUrl('views/shared/searchHealthCareOrganisationParty.html')
                .setScope({
                    title: this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name,
                    organisationTypes: orgTypes,
                })
                .setResultCallBack(r => {
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId = r.PartyRoleId;
                    this.$scope.organisation = r;
                    this.$scope.organisationName = r.Name;
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .build();
        }

        private clearOrganisation(): void {
            this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
            this.$scope.organisation = null;
            this.$scope.organisationName = null;
        }

        private isMaxReached(role: Shared.Framework.Model.ActorRole): boolean {
            if (!role.maximalNumber) {
                return false;
            }
            const requestRoles = _(this.$scope.careRequest.careRequestRoles).filter(
                crr => crr.roleId === role.actorRoleId
            );
            return requestRoles.length >= role.maximalNumber;
        }

        private async confirmAsync(): Promise<void> {
            this.$scope.showErrors = true;
            this.$scope.careRequestorForm.$setSubmitted();
            if (this.$scope.careRequestorForm.$invalid) {
                this.$scope.careRequestorForm.showValidationErrorMessage();
                return;
            }

            this.$scope.$close();
        }
    }

    remeCareCareRequestModule.controller(
        'rcCareRequestCareRequestorAcceptanceModalController',
        CareRequestorAcceptanceModalController
    );
}
