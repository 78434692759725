namespace RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import Consultation = RemeCare.Model.Consultation;

    class AddConsultationModalController implements ng.IComponentController {
        public patientId: Guid;
        public consultation: Consultation;
        public form: ng.IFormController;
        public careProviderTypes: Shared.Contract.IEnumTranslation[];
        public $close: () => void;

        constructor(
            private readonly toaster: Shared.Framework.Toaster,
            private readonly $stateParams,
            private readonly patientSvc: Patient.PatientService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService
        ) {}

        public $onInit(): void {
            this.patientId = this.$stateParams.patientId;
            this.loadDataAsync();
        }

        public async confirmAsync(): Promise<void> {
            if (this.form.$invalid) {
                this.form.showValidationErrorMessage();
                return;
            }

            try {
                if (this.consultation.id === null || this.consultation.id === undefined) {
                    await this.patientSvc.savePatientFileConsultationAsync(
                        this.patientId,
                        this.consultation.toServerWrite()
                    );
                    this.$close();
                } else {
                    await this.patientSvc.updatePatientFileConsultationAsync(
                        this.patientId,
                        this.consultation.id,
                        this.consultation.toServerWrite()
                    );
                    this.$close();
                }
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.careProviderTypes = await this.masterdataSvc.getConsultationCareProviderTypesAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }
    }

    remeCarePatientModule.component('rcAddConsultation', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            consultation: '=',
            careRequestPart: '=',
        },
        controller: AddConsultationModalController,
        templateUrl: 'patient/components/patientInfo/hospiCons/addConsultationModal/addConsultationModal.html',
    });
}
