module RemeCare.Patient {
    import Guid = Shared.Contract.Guid;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import Hospitalisation = RemeCare.Model.Hospitalisation;

    interface IPatientFileAddHospitalisationModalScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Guid;
        hospitalisation: Hospitalisation;

        form: ng.IFormController;

        careTypes: Array<Shared.Contract.IEnumTranslation>;
        hospitalisationReasons: Array<EntityTranslation>;

        allowOtherReason: boolean;

        confirm: () => void;
        checkReason: () => void;
    }

    class PatientFileAddHospitalisationModalController extends Shared.Framework.ControllerBase<IPatientFileAddHospitalisationModalScope> {

        constructor(
            $scope: IPatientFileAddHospitalisationModalScope,
            $translate,
            toaster,
            private $dialog,
            private $stateParams,
            private patientSvc: Patient.PatientService,
            private masterdataSvc: Shared.Framework.MasterdataService
        ) {
            super($scope, $translate, toaster);
            this.$scope.confirm = () => this.confirm();
            this.$scope.checkReason = () => this.checkReason();
        }

        public $onInit(): void {
            this.$scope.patientId = this.$stateParams.patientId;
            this.masterdataSvc.getCareTypes()
                .success((ags) => {
                    this.$scope.careTypes = ags;
                })
                .error((m) => this.errorCallback(m));

            this.patientSvc.getHospitalisationReasons(this.$scope.patientId)
                .success((ags) => {
                    this.$scope.hospitalisationReasons = ags;
                })
                .error((m) => this.errorCallback(m));

            if (this.$scope.hospitalisation.hospitalisationReason != null && this.$scope.hospitalisation.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                this.$scope.allowOtherReason = true;
            } else {
                this.$scope.allowOtherReason = false;
            }
        }

        private confirm(): void {
            if (this.$scope.form.$invalid) {
                this.showValidationErrorMessage();
                return;
            }

            if (this.$scope.hospitalisation.id === null || this.$scope.hospitalisation.id === undefined) {
                this.patientSvc.savePatientFileHospitalisation(this.$scope.patientId, this.$scope.hospitalisation.toServerWrite())
                    .success(() => this.$scope.$close())
                    .error((m) => this.errorCallback(m));
            } else {
                this.patientSvc.updatePatientFileHospitalisation(this.$scope.patientId, this.$scope.hospitalisation.id, this.$scope.hospitalisation.toServerWrite())
                    .success(() => this.$scope.$close())
                    .error((m) => this.errorCallback(m));
            }
            
        }

        private checkReason(): void {
            if (this.$scope.hospitalisation.hospitalisationReason.Id === Hospitalisation.hospitalisationReasonOther) {
                this.$scope.allowOtherReason = true;
            } else {
                this.$scope.allowOtherReason = false;
            }
        }
    }

    angular.module('RemeCare.Patient').controller('patientFileAddHospitalisationModalCtrl', (
        $scope,
        $translate,
        toaster,
        $dialog,
        $stateParams,
        patientSvc: Patient.PatientService,
        masterdataSvc: Shared.Framework.MasterdataService
    ) => new PatientFileAddHospitalisationModalController(
            $scope,
            $translate,
            toaster,
            $dialog,
            $stateParams,
            patientSvc,
            masterdataSvc
    ));
}