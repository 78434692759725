namespace RemeCare.CareRequest {
    import EnumTranslation = Shared.Contract.IEnumTranslation;
    import EntityTranslation = Shared.Contract.IEntityTranslation;
    import CareRequestSetup = Shared.Framework.Model.CareRequestSetup;
    import Guid = Shared.Contract.Guid;
    import Document = Shared.Contract.IMetaDataDocument;
    import Consultation = Model.Consultation;
    import Hospitalisation = Model.Hospitalisation;
    import Medication = Shared.Contract.Read.IMedication;
    import CarePlanParameterValue = Contract.CareRequest.Write.ICarePlanParameterValue;
    import TranslateService = ng.translate.ITranslateService;
    import Periodicity = Shared.Framework.Model.Periodicity;

    export class CareRequestTemplate {
        public therapy: EntityTranslation;
        public careRequestSetup: CareRequestSetup;
        public careRequestStepSequence: number;
        public totalSteps: number;
        public careRequestStepType: EnumTranslation;
        public isFinal: boolean;
        public isStudy: boolean;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestTemplate) {
            if (serverObject != null) {
                this.therapy = serverObject.Therapy;
                this.careRequestSetup = new CareRequestSetup(serverObject.CareRequestSetup);
                this.careRequestStepSequence = serverObject.CareRequestStepSequence;
                this.totalSteps = serverObject.TotalSteps;
                this.careRequestStepType = serverObject.CareRequestStepType;
                this.isFinal = serverObject.IsFinal;
                this.isStudy = serverObject.IsStudy;
            }
        }

        public getSaveButtonTranslation(): string {
            if (this.careRequestStepType.Id === Shared.Contract.Code.CareRequestStepType.Application) {
                return this.isFinal ? 'Views.CareRequest.Details.PermSubmit' : 'Views.CareRequest.Details.TempSubmit';
            } else {
                return this.isFinal ? 'Views.CareRequest.Details.PermAccept' : 'Views.CareRequest.Details.TempAccept';
            }
        }

        public getCancelButtonTranslation(): string {
            if (this.careRequestStepType.Id === Shared.Contract.Code.CareRequestStepType.Application) {
                return 'Views.CareRequest.Details.CancelApplication';
            } else {
                return 'Views.CareRequest.Details.CancelAcceptation';
            }
        }
    }

    export class CareRequest {
        public id: Guid;
        public stepSequence: number;
        public stepType: EnumTranslation;
        public therapyId: Guid;
        public matchedPartyId: Guid;
        public matchedPatientId: Guid;
        public intermediaryHealthCareOrganisationTypeId: number;
        public intermediarySpecialtyId: number;
        public patient: RequestPerson = new RequestPerson();
        public careRequestor: CareRequestor = new CareRequestor();
        public patientContactPersons: PatientContactPerson[] = [];
        public therapyOptions: TherapyOption[] = [];
        public careRequestDates: CareRequestDate[] = [];
        public careRequestStrings: CareRequestString[] = [];
        public careRequestSignatures: CareRequestSignature[] = [];
        public anamneses: Anamnesis[] = [];
        public careRequestDocuments: CareRequestDocument[] = [];
        public careRequestRoles: CareRequestRole[] = [];
        public careRequestMedications: CareRequestMedication[] = [];
        public careRequestStatus: EnumTranslation;
        public comorbidities: Guid[] = [];
        public treatedDiseases: Guid[] = [];
        public consultations: Consultation[] = [];
        public hospitalisations: Hospitalisation[] = [];
        public statusChangeRemark: string;
        public readOnly: boolean;
        public isModified: boolean;
        public externalReferences: RemeCare.Contract.Core.IExternalReference[] = [];
        public CareRequestInitiator?: Guid;
        private timestamp: number[] = [];
        private modifyingEnabled = false;

        constructor(
            serverObject: Contract.CareRequest.Read.ICareRequest,
            private template: Contract.CareRequest.Read.ICareRequestTemplate,
            allowedComorbidities: Shared.Contract.Read.ITherapyDiseaseReadModel[],
            allowedTreatedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[]
        ) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.stepSequence = serverObject.StepSequence;
                this.stepType = serverObject.StepType;
                this.therapyId = serverObject.TherapyId;
                this.matchedPartyId = serverObject.MatchedPartyId;
                this.matchedPatientId = serverObject.MatchedPatientId;
                this.intermediaryHealthCareOrganisationTypeId = serverObject.IntermediaryHealthCareOrganisationTypeId;
                this.intermediarySpecialtyId = serverObject.IntermediarySpecialtyId;
                if (serverObject.Patient == null && serverObject.MatchedPatientId != null) {
                    this.patient = null;
                } else {
                    this.patient = new RequestPerson(serverObject.Patient);
                }
                this.careRequestor = new CareRequestor(serverObject.CareRequestor);
                this.careRequestRoles = _(serverObject.CareRequestRoles).map((crr) => new CareRequestRole(crr));
                this.patientContactPersons = _(serverObject.PatientContactPersons).map(
                    (p) => new PatientContactPerson(p)
                );
                this.therapyOptions = _(serverObject.TherapyOptions).map((t) => new TherapyOption(t));
                this.careRequestDates = _(serverObject.CareRequestDates).map((d) => new CareRequestDate(d));
                this.careRequestStrings = _(serverObject.CareRequestStrings).map((s) => new CareRequestString(s));
                this.careRequestSignatures = _(serverObject.CareRequestSignatures).map(
                    (s) => new CareRequestSignature(s)
                );
                this.anamneses = _(serverObject.Anamneses).map((a) => new Anamnesis(a));
                this.careRequestDocuments = _(serverObject.CareRequestDocuments).map((c) => new CareRequestDocument(c));
                this.careRequestMedications = _(serverObject.CareRequestMedications).map(
                    (c) => new CareRequestMedication(c)
                );
                this.careRequestStatus = serverObject.CareRequestStatus;
                this.comorbidities = this.mapToTherapyDiseaseIds(
                    _(serverObject.CareRequestSufferedDiseases)
                        .chain()
                        .filter((sd) => sd.DiseaseType.Id === Shared.Contract.Code.DiseaseType.Comorbidity)
                        .map((sd) => sd.Disease.Id)
                        .value(),
                    allowedComorbidities
                );
                this.treatedDiseases = this.mapToTherapyDiseaseIds(
                    _(serverObject.CareRequestSufferedDiseases)
                        .chain()
                        .filter((sd) => sd.DiseaseType.Id === Shared.Contract.Code.DiseaseType.TreatedDisease)
                        .map((sd) => sd.Disease.Id)
                        .value(),
                    allowedTreatedDiseases
                );
                this.consultations = _(serverObject.Consultations).map((c) => new Consultation(c));
                this.hospitalisations = _(serverObject.Hospitalisations).map((h) => new Hospitalisation(h));
                this.externalReferences = serverObject.ExternalReferences || [];
                this.timestamp = serverObject.Timestamp;
                this.readOnly = serverObject.ReadOnly;
                this.statusChangeRemark = serverObject.StatusChangeRemark;
                this.CareRequestInitiator = serverObject.CareRequestInitiator;
            }
        }

        public enableModifying(): void {
            this.modifyingEnabled = true;
        }

        public isModifiable(): boolean {
            return !this.careRequestStatus || this.careRequestStatus.Id < Shared.Contract.Code.CareRequestStatus.Final;
        }

        public isReadOnly(): boolean {
            if (this.isModified === true) {
                return true;
            }
            return (
                !this.modifyingEnabled &&
                (this.readOnly || !this.isModifiable() || this.template.CareRequestStepSequence < this.stepSequence)
            );
        }

        public toServerWrite(
            isPermanent: boolean,
            allowedComorbidities: Shared.Contract.Read.ITherapyDiseaseReadModel[],
            allowedTreatedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[]
        ): Contract.CareRequest.Write.ICareRequest {
            const comorbidities = _(this.mapToDiseaseIds(this.comorbidities, allowedComorbidities)).map((c) => {
                return {
                    diseaseId: c,
                    type: Shared.Contract.Code.DiseaseType.Comorbidity,
                } as Contract.CareRequest.Write.ISufferedDisease;
            });
            const treatedDiseases = _(this.mapToDiseaseIds(this.treatedDiseases, allowedTreatedDiseases)).map((t) => {
                return {
                    diseaseId: t,
                    type: Shared.Contract.Code.DiseaseType.TreatedDisease,
                } as Contract.CareRequest.Write.ISufferedDisease;
            });
            const therpyOptions = _(this.therapyOptions).map((to) => to.toServerWrite());
            const dates = _(this.careRequestDates).map((crd) => crd.toServerWrite());
            const strings = _(this.careRequestStrings).map((crs) => crs.toServerWrite());
            const signatures = _(this.careRequestSignatures).map((crs) => crs.toServerWrite());
            const anamneses = this.anamneses.map((a) => a.toServerWrite());

            return {
                id: this.id,
                stepSequence: this.stepSequence,
                stepType: this.stepType.Id,
                therapyId: this.therapyId,
                isPermanent: isPermanent,
                matchedPartyId: this.matchedPartyId,
                matchedPatientId: this.matchedPatientId,
                intermediaryHealthCareOrganisationType: this.intermediaryHealthCareOrganisationTypeId,
                intermediarySpecialty: this.intermediarySpecialtyId,
                patient: this.patient != null ? this.patient.toServerWrite() : null,
                careRequestor: this.careRequestor.toServerWrite(),
                roles: _(this.careRequestRoles).map((crr) => crr.toServerWrite()),
                patientContactPersons: _(this.patientContactPersons).map((pcp) => pcp.toServerWrite()),
                parameterValues: therpyOptions
                    .concat(dates)
                    .concat(strings)
                    .concat(anamneses)
                    .concat(signatures),
                documents: _(this.careRequestDocuments).map((c) => c.toServerWrite()),
                sufferedDiseases: comorbidities.concat(treatedDiseases),
                medications: _(this.careRequestMedications).map((c) => c.toServerWrite()),
                consultations: _(this.consultations).map((c) => c.toServerWrite()),
                hospitalisations: _(this.hospitalisations).map((h) => h.toServerWrite()),
                externalReferences: this.externalReferences,
                timestamp: this.timestamp,
                statusChangeRemark: this.statusChangeRemark,
            };
        }

        private mapToDiseaseIds(
            therapyDiseasesIds: Guid[],
            allowedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[]
        ): Guid[] {
            if (
                !therapyDiseasesIds ||
                therapyDiseasesIds.length === 0 ||
                !allowedDiseases ||
                allowedDiseases.length === 0
            ) {
                return []; // No matches to return
            }
            const diseaseIds: Guid[] = [];
            therapyDiseasesIds.forEach((therapyDiseaseId) => {
                const filtered = allowedDiseases.filter((td) => td.TherapyDiseaseId === therapyDiseaseId)[0];
                if (filtered != null && filtered.Disease != null && filtered.Disease.Id) {
                    diseaseIds.push(filtered.Disease.Id);
                }
            });
            return diseaseIds;
        }

        private mapToTherapyDiseaseIds(
            diseaseIds: Guid[],
            allowedDiseases: Shared.Contract.Read.ITherapyDiseaseReadModel[]
        ): Guid[] {
            if (!diseaseIds || diseaseIds.length === 0 || !allowedDiseases || allowedDiseases.length === 0) {
                return []; // No matches to return
            }

            const therapyDiseaseIds: Guid[] = [];
            diseaseIds.forEach((diseaseId) => {
                const filtered = allowedDiseases.filter((d) => d.Disease.Id === diseaseId)[0];
                if (filtered != null && filtered.TherapyDiseaseId) {
                    therapyDiseaseIds.push(filtered.TherapyDiseaseId);
                }
            });
            return therapyDiseaseIds;
        }
    }

    export class TherapyOption {
        public id: Guid;
        public careRequestPartLocalIdentity: Guid;
        public option: boolean;

        constructor(serverObject?: Contract.CareRequest.Read.ITherapyOption) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                this.option = serverObject.Option;
            }
        }

        public toServerWrite(): CarePlanParameterValue {
            return {
                id: this.id,
                type: Shared.Contract.Code.CareRequestPartType.TherapyOption,
                definitionId: this.careRequestPartLocalIdentity,
                boolValue: this.option,
            };
        }
    }

    export class CareRequestSignature {
        public id: Guid;
        public careRequestPartLocalIdentity: Guid;
        // Base64 image encoded
        public signatureImage: string;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestSignature) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                this.signatureImage = serverObject.SignatureImage;
            }
        }

        public toServerWrite(): CarePlanParameterValue {
            return {
                id: this.id,
                type: Shared.Contract.Code.CareRequestPartType.Signature,
                definitionId: this.careRequestPartLocalIdentity,
                stringValue: this.signatureImage,
            };
        }
    }

    export class CareRequestString {
        public id: Guid;
        public careRequestPartLocalIdentity: Guid;
        public string: string;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestString) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                this.string = serverObject.String;
            }
        }

        public toServerWrite(): CarePlanParameterValue {
            return {
                id: this.id,
                type: Shared.Contract.Code.CareRequestPartType.String,
                definitionId: this.careRequestPartLocalIdentity,
                stringValue: this.string,
            };
        }
    }

    export class CareRequestDate {
        public id: Guid;
        public careRequestPartLocalIdentity: Guid;
        public date: Date;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestDate) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestPartLocalIdentity = serverObject.CareRequestPartLocalIdentity;
                this.date = moment.utc(serverObject.Date).toDate();
            }
        }

        public toServerWrite(): CarePlanParameterValue {
            return {
                id: this.id,
                type: Shared.Contract.Code.CareRequestPartType.Date,
                definitionId: this.careRequestPartLocalIdentity,
                dateTimeValue: Shared.DateHelper.toServerDateString(this.date),
            };
        }
    }

    export class CareRequestDocument {
        public static createFromDocument(
            document: Document,
            careRequestDocumentDefinitionId: Guid
        ): CareRequestDocument {
            const result = new CareRequestDocument();
            result.id = document.Id;
            result.url = document.Url;
            result.name = document.Name;
            result.document = document;
            result.careRequestDocumentDefinitionId = careRequestDocumentDefinitionId;
            return result;
        }
        public id: Guid;
        public careRequestDocumentDefinitionId: Guid;
        public name: string;
        public url: string;
        public referenceDate: Date;
        public document: Document;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestDocument) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.careRequestDocumentDefinitionId = serverObject.CareRequestPartLocalIdentity;
                this.name = serverObject.Name;
                this.url = serverObject.Url;
                this.referenceDate =
                    serverObject.ReferenceDate != null ? moment(serverObject.ReferenceDate).toDate() : null;
                this.document = angular.copy(serverObject);
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.ICareRequestDocument {
            return {
                id: this.id,
                definitionId: this.careRequestDocumentDefinitionId,
                name: this.name,
                url: this.url,
                referenceDate: Shared.DateHelper.toServerDateString(this.referenceDate),
                size: this.document.Size,
            };
        }
    }

    export class CareRequestor {
        public id: Guid;
        public specialityId: number;
        public contactPersonFunction: string;
        public healthCareOrganisationId: Guid;
        public healthCareProfessionalId: Guid;
        public contactPerson: RequestPerson = new RequestPerson();
        public responsible: RequestPerson = new RequestPerson();

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestor) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.specialityId = serverObject.SpecialityId;
                this.contactPersonFunction = serverObject.ContactPersonFunction;
                this.healthCareOrganisationId = serverObject.HealthCareOrganisationId;
                this.healthCareProfessionalId = serverObject.HealthCareProfessionalId;
                this.contactPerson = new RequestPerson(serverObject.ContactPerson);
                this.responsible = new RequestPerson(serverObject.Responsible);
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.ICareRequestor {
            return {
                id: this.id,
                speciality: this.specialityId,
                contactPersonFunction: this.contactPersonFunction,
                healthCareOrganisationId: this.healthCareOrganisationId,
                healthCareProfessionalId: this.healthCareProfessionalId,
                contactPerson: this.contactPerson.toServerWrite(),
                responsible: this.responsible.toServerWrite(),
            } as Contract.CareRequest.Write.ICareRequestor;
        }
    }

    export class CareRequestRole {
        public static create(roleId: Guid, partyType: Shared.Contract.Code.PartyType): CareRequestRole {
            const result = new CareRequestRole();
            result.roleId = roleId;
            if (partyType === Shared.Contract.Code.PartyType.Organisation) {
                result.requestParty = new RequestOrganisation();
            } else {
                result.requestParty = new RequestPerson();
            }
            return result;
        }

        public static createForParty(roleId: Guid, requestParty: RequestParty): CareRequestRole {
            const result = new CareRequestRole();
            result.roleId = roleId;
            result.requestParty = requestParty;
            result.groupId = requestParty.group && requestParty.group.groupId;
            return result;
        }
        public id: Guid;
        public roleId: Guid;
        public partyRoleId: Guid;
        public requestParty: RequestParty = new RequestParty();
        public groupId: Guid;
        public matchedParty: RequestParty;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestRole) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.roleId = serverObject.RoleId;
                this.partyRoleId = serverObject.PartyRoleId;
                this.groupId = serverObject.GroupId;
                if (serverObject.RequestParty != null) {
                    if (serverObject.RequestParty.Type === Shared.Contract.Code.PartyType.Person) {
                        this.requestParty = new RequestPerson(
                            serverObject.RequestParty as Contract.CareRequest.Read.IRequestPerson
                        );
                    } else {
                        this.requestParty = new RequestOrganisation(
                            serverObject.RequestParty as Contract.CareRequest.Read.IRequestOrganisation
                        );
                    }
                }
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.ICareRequestRole {
            return {
                id: this.id,
                roleId: this.roleId,
                partyRoleId: this.partyRoleId,
                groupId: this.groupId,
                requestParty: this.partyRoleId == null ? this.requestParty.toServerWrite() : null,
            } as Contract.CareRequest.Write.ICareRequestRole;
        }

        public getName(): string {
            if (this.matchedParty != null) {
                return this.matchedParty.getName();
            }
            return this.requestParty.getName();
        }
    }

    export class CareRequestMedication {
        public id: Guid;
        public validFromDate: Date;
        public validUntilDate: Date;
        public remarkPeriodicity: string;
        public structuralTherapyActionId: Guid;
        public periodicities: Shared.Framework.Model.Periodicity[] = [];
        public productId: Guid;
        public medication: Medication;
        public medicationSchema: string;
        public prescribedAdministrationInformation: Patient.Model.PrescribedAdministrationInformation;
        public solventToAddInformation: Patient.Model.SolventToAddInformation;

        public isSimplePrescription: boolean;
        public onlyIntakeMoments: boolean;
        public isFollowedUp: boolean;
        public isAdHoc: boolean;

        constructor(serverObject?: Contract.CareRequest.Read.ICareRequestMedication) {
            if (serverObject != null) {
                this.id = serverObject.Id;
                this.validFromDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidFromDate);
                this.validUntilDate = Shared.DateHelper.serverDateStringToDate(serverObject.ValidUntilDate);
                this.remarkPeriodicity = serverObject.RemarkPeriodicity;
                this.structuralTherapyActionId = serverObject.StructuralTherapyActionId;
                const factory = new Shared.Framework.Factory.PeriodicityFactory();
                this.periodicities = _(serverObject.Periodicities).map((p) => factory.createPeriodicity(p));
                this.productId = serverObject.ProductId;

                this.isSimplePrescription = this.canBeShownAsSimplePrescription();
                this.prescribedAdministrationInformation = new Patient.Model.PrescribedAdministrationInformation(
                    serverObject.PrescribedAdministrationInformation
                );
                this.solventToAddInformation = new Patient.Model.SolventToAddInformation(
                    serverObject.SolventToAddInformation
                );
            } else {
                this.solventToAddInformation = new Patient.Model.SolventToAddInformation(null);
                this.prescribedAdministrationInformation = new Patient.Model.PrescribedAdministrationInformation(null);
            }
        }

        public toServerWrite(): Contract.CareRequest.Write.ICareRequestMedication {
            const toReturn = {
                id: this.id,
                validFromDate: Shared.DateHelper.toServerDateString(this.validFromDate),
                validUntilDate: Shared.DateHelper.toServerDateString(this.validUntilDate),
                remark: this.remarkPeriodicity,
                structuralTherapyActionId: this.structuralTherapyActionId,
                productId: this.medication.ProductId,
                periodicities: _(this.periodicities).map((p) => p.toWriteModel()),
            } as Contract.CareRequest.Write.ICareRequestMedication;

            if (this.solventToAddInformation != null) {
                toReturn.solventToAddInformation = this.solventToAddInformation.toServerWrite();
            } else {
                toReturn.solventToAddInformation = null;
            }
            if (this.prescribedAdministrationInformation != null) {
                toReturn.prescribedAdministrationInformation = this.prescribedAdministrationInformation.toServerWrite();
            } else {
                toReturn.prescribedAdministrationInformation = null;
            }
            return toReturn;
        }

        public canBeShownAsSimplePrescription(): boolean {
            if (this.periodicities != null && this.periodicities.length === 1) {
                const periodicity = this.periodicities[0];
                if (
                    _(periodicity.intraDayTimings).any(
                        (idt: Shared.Framework.Model.IntraDayTimingAdministration) =>
                            idt.noEatingWindowBefore != null ||
                            idt.noEatingWindowAfter != null ||
                            moment(idt.preferredTime).minutes() !== 0
                    )
                ) {
                    return false;
                }
                if (periodicity.hasDuration) {
                    return false;
                }
                if (
                    periodicity.recurrence != null &&
                    periodicity.recurrence.Quantity === 1 &&
                    periodicity.recurrence.Unit === Shared.Contract.Code.DurationUnit.Days
                ) {
                    return (
                        this.prescribedAdministrationInformation == null ||
                        this.prescribedAdministrationInformation.prescribedAdministrationMeans == null ||
                        this.prescribedAdministrationInformation.prescribedAdministrationMeans < 1
                    );
                }
            }
            return false;
        }

        public makeDefault(): void {
            const factory = new Shared.Framework.Factory.PeriodicityFactory();
            const periodicity = factory.createPeriodicityFromDurationUnit(Shared.Contract.Code.DurationUnit.Days);
            periodicity.recurrence.Quantity = 1;
            this.periodicities = [periodicity];
            this.isAdHoc = false;
            this.isSimplePrescription = true;
        }

        public makeAdHoc(
            patientLang: string,
            masterdataSvc: Shared.Framework.MasterdataService,
            $translate: TranslateService
        ): void {
            const periodicity = new Periodicity();
            periodicity.unstructuredTargetQuantity = 1;
            this.isAdHoc = true;
            this.isSimplePrescription = false;
            this.periodicities = [periodicity];

            if (!this.isFollowedUp) {
                return;
            }
            // Set 'If needed' as text for followed up ad hoc medication
            if (patientLang) {
                masterdataSvc.getTranslation(patientLang, 'Views.Patient.Medication.IfNeeded').success((trans) => {
                    const ifNeededTrans = trans['Views.Patient.Medication.IfNeeded'];
                    periodicity.unstructuredPeriodicity = ifNeededTrans;
                });
            } else {
                const ifNeededTrans = $translate.instant('Views.Patient.Medication.IfNeeded');
                periodicity.unstructuredPeriodicity = ifNeededTrans;
            }
        }
    }
}
