var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Patient;
    (function (Patient) {
        var PatientFileEditRuleThresholdValueModalController = /** @class */ (function (_super) {
            __extends(PatientFileEditRuleThresholdValueModalController, _super);
            function PatientFileEditRuleThresholdValueModalController($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.$dialog = $dialog;
                _this.$stateParams = $stateParams;
                _this.carePlanApiSvc = carePlanApiSvc;
                _this.$scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            PatientFileEditRuleThresholdValueModalController.prototype.$onInit = function () {
                var _this = this;
                this.$scope.patientId = this.$stateParams.patientId;
                this.$scope.newValidFromDate = this.$scope.editValue.validFromDate;
                var minDate = moment().add(1, 'days').startOf('day').toDate();
                this.$scope.previousValue = _(_(_(this.$scope.threshold.ruleThresholdValues).filter(function (v) { return v.isPersonalised && moment(v.validFromDate).isBefore(_this.$scope.editValue.validFromDate); })).sortBy(function (v) { return v.validFromDate; })).last();
                this.$scope.minDate = this.$scope.previousValue && moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() > minDate ? moment(this.$scope.previousValue.validFromDate).add(1, 'day').toDate() : minDate;
                this.$scope.maxDate = this.$scope.editValue.validUntilDate;
            };
            PatientFileEditRuleThresholdValueModalController.prototype.confirm = function () {
                var _this = this;
                if (!this.$scope.editValue.isPersonalised) {
                    this.$scope.previousValue.validUntilDate = moment(this.$scope.newValidFromDate).add(-1, 'day').toDate();
                    this.carePlanApiSvc.updatePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.previousValue.toServerWrite())
                        .success(function () { _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
                else {
                    this.$scope.editValue.validFromDate = this.$scope.newValidFromDate;
                    this.carePlanApiSvc.savePersonalisedRuleThresholdValue(this.$scope.carePlan.id, this.$scope.threshold.id, this.$scope.editValue.toServerWrite())
                        .success(function () { _this.$scope.$close(); })
                        .error(function (m) { return _this.errorCallback(m); });
                }
            };
            return PatientFileEditRuleThresholdValueModalController;
        }(RemeCare.Shared.Framework.ControllerBase));
        angular.module('RemeCare.Patient').controller('PatientFileEditRuleThresholdValueModalCtrl', function ($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc) { return new PatientFileEditRuleThresholdValueModalController($scope, $translate, toaster, $dialog, $stateParams, carePlanApiSvc); });
    })(Patient = RemeCare.Patient || (RemeCare.Patient = {}));
})(RemeCare || (RemeCare = {}));
