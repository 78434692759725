var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var GlobalActorRoleTranslationsController = /** @class */ (function (_super) {
            __extends(GlobalActorRoleTranslationsController, _super);
            function GlobalActorRoleTranslationsController($scope, $translate, toaster, masterdataSvc) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.masterdataSvc = masterdataSvc;
                $scope.confirm = function () { return _this.confirm(); };
                return _this;
            }
            GlobalActorRoleTranslationsController.prototype.$onInit = function () {
                var _this = this;
                this.masterdataSvc.getInterfaceLanguages()
                    .success(function (r) { return _this.$scope.languages = r; })
                    .error(function (e) { return _this.errorCallback(e); });
            };
            GlobalActorRoleTranslationsController.prototype.confirm = function () {
                if (this.$scope.form.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                this.$scope.$close(this.$scope.globalActorRole);
            };
            return GlobalActorRoleTranslationsController;
        }(RemeCare.Shared.Framework.ControllerBase));
        Management.remeCareManagementModule.controller('globalActorRoleTranslationsCtrl', function ($scope, $translate, toaster, masterdataSvc) { return new GlobalActorRoleTranslationsController($scope, $translate, toaster, masterdataSvc); });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
