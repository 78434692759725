var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction) {
        var PlanNewContactController = /** @class */ (function (_super) {
            __extends(PlanNewContactController, _super);
            function PlanNewContactController($scope, $translate, toaster, carePlanActionApiSvc, authservice) {
                var _this = _super.call(this, $scope, $translate, toaster) || this;
                _this.carePlanActionApiSvc = carePlanActionApiSvc;
                _this.authservice = authservice;
                $scope.planNewContact = function () { return _this.planNewContact(); };
                $scope.fromDateChanged = function () { return _this.fromDateChanged(); };
                return _this;
            }
            PlanNewContactController.prototype.$onInit = function () {
                if (this.$scope.fromDate == null) {
                    this.$scope.fromDate = new Date();
                    this.fromDateChanged();
                }
            };
            PlanNewContactController.prototype.fromDateChanged = function () {
                if (this.$scope.fromDate != null && (this.$scope.untilDate == null || this.$scope.untilDate < this.$scope.fromDate)) {
                    this.$scope.untilDate = new Date(this.$scope.fromDate.getTime());
                }
            };
            PlanNewContactController.prototype.planNewContact = function () {
                if (this.$scope.newContactForm.$invalid) {
                    this.showValidationErrorMessage();
                    return;
                }
                var params = {
                    from: RemeCare.Shared.DateHelper.toServerDateTimeString(this.$scope.fromDate),
                    until: this.$scope.untilDate != null
                        ? RemeCare.Shared.DateHelper.toServerDateTimeString(this.$scope.untilDate)
                        : RemeCare.Shared.DateHelper.toServerDateTimeString(this.$scope.fromDate),
                    remark: this.$scope.remark,
                    type: RemeCare.Shared.Contract.Code.ActionType.TelephoneCallPlanning
                };
                this.$scope.$close(params);
            };
            return PlanNewContactController;
        }(RemeCare.Shared.Framework.ControllerBase));
        CarePlanAction.remeCareCarePlanActionModule.controller('planNewContactCtrl', PlanNewContactController);
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
