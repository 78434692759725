var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var RemeCare;
(function (RemeCare) {
    var CarePlanAction;
    (function (CarePlanAction_1) {
        var AuthRight = RemeCare.Shared.Framework.AuthRight;
        // tslint:disable: no-bitwise
        var CarePlanAction = /** @class */ (function (_super) {
            __extends(CarePlanAction, _super);
            function CarePlanAction(authservice, serverObject, patient) {
                var _this = _super.call(this, authservice, serverObject && serverObject.ActionTemplateId, serverObject) || this;
                _this.isSuperUser = authservice.getProfile() === RemeCare.Shared.Contract.Code.ApplicationProfileType.Superuser;
                if (serverObject != null) {
                    _this.carePlanActionId = serverObject.Id;
                    _this.carePlanIds = serverObject.CarePlanIds;
                    _this.status = serverObject.Status.Id;
                    _this.partyRole = serverObject.PartyRole;
                    _this.patient = patient;
                    _this.patientName = serverObject.Patient != null ? serverObject.Patient.Text : '';
                    _this.patientId = serverObject.Patient != null ? serverObject.Patient.Id : null;
                    _this.location = serverObject.Location;
                    _this.showAsToDo = serverObject.ShowAsToDo;
                    _this.suggestedDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.SuggestedDateTime);
                    _this.executionDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.ExecutionDateTime);
                    _this.processVersionDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.ProcessVersionDate);
                    _this.plannedDateTimeFrom = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeFrom);
                    _this.plannedDateTimeUntil = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeUntil);
                    _this.eventDateFrom =
                        serverObject.IncludesEventDate &&
                            RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.EventDateTimeFrom || serverObject.PlannedDateTimeFrom);
                    _this.eventDateUntil =
                        serverObject.IncludesEventDate &&
                            serverObject.IsEventDateTimeRange &&
                            RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.EventDateTimeUntil || serverObject.PlannedDateTimeUntil);
                    _this.eventTimeFrom =
                        serverObject.IncludesEventDate &&
                            serverObject.IncludesEventTime &&
                            RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.EventDateTimeFrom || serverObject.PlannedDateTimeFrom);
                    _this.eventTimeUntil =
                        serverObject.IncludesEventDate &&
                            serverObject.IsEventDateTimeRange &&
                            serverObject.IncludesEventTime &&
                            RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.EventDateTimeUntil || serverObject.PlannedDateTimeUntil);
                    _this.openEventWindowDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.OpenEventWindowDateTime);
                    _this.closeEventWindowDateTime = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.CloseEventWindowDateTime);
                    _this.therapies = serverObject.Therapies;
                    _this.hasTemporarySave = serverObject.HasTemporarySave;
                    _this.maxExecutionRight = serverObject.MaxExecutionRight;
                    _this.isActive = serverObject.IsActive;
                    _this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                    _this.durationOfAction = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
                    if (_this.durationOfAction != null) {
                        _this.readableDurationOfAction = RemeCare.Shared.DateHelper.timeHumanReadible(_this.durationOfAction);
                    }
                    if (_this.components.length > 0 && _this.components[0] instanceof CareActDescriptor) {
                        var cad = _this.components[0];
                        _this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(cad.hasActionDurationOption, _this.durationOfAction, cad.possibleActionDurationOptions);
                    }
                    _this.canEditAction = serverObject.CanEditAction;
                }
                return _this;
            }
            CarePlanAction.prototype.getPatientName = function () {
                return this.patient != null ? this.patient.FirstName + " " + this.patient.LastName : this.patientName;
            };
            CarePlanAction.prototype.canBeRescheduled = function () {
                var isCompleted = this.status === RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed ||
                    this.status === RemeCare.Contract.Core.Codes.CarePlanActionStatus.Cancelled;
                return (((AuthRight.Create & this.maxExecutionRight) !== 0 && !isCompleted) ||
                    ((AuthRight.Write & this.maxExecutionRight) !== 0 && isCompleted));
            };
            CarePlanAction.prototype.isViewable = function () {
                return (AuthRight.Read & this.maxExecutionRight) !== 0;
            };
            CarePlanAction.prototype.getDirectChildren = function (excludeEmptyRelatedActions) {
                var _this = this;
                return _(this.components)
                    .chain()
                    .filter(function (component) { return component.enabled && _this.isActiveComponent(component.id); })
                    .map(function (component) {
                    if (component.relatedActions != null) {
                        return component.relatedActions;
                    }
                    else if (component.relatedCarePlanAction != null &&
                        (!excludeEmptyRelatedActions || component.hasValue())) {
                        return [component.relatedCarePlanAction];
                    }
                    else {
                        return [];
                    }
                })
                    .flatten()
                    .value();
            };
            CarePlanAction.prototype.flattenActions = function () {
                var directChildren = this.getDirectChildren();
                var actions = _(directChildren)
                    .chain()
                    .map(function (relatedAction) { return relatedAction.flattenActions(); })
                    .flatten()
                    .value();
                actions.push(this);
                return actions;
            };
            CarePlanAction.prototype.hasRegistrations = function () {
                return _(this.components).any(function (c) { return c.hasValue(); });
            };
            CarePlanAction.prototype.toServerWrite = function (permanent) {
                var _this = this;
                var now = new Date();
                var registrationValues = [];
                _(this.components).forEach(function (c) {
                    if (_this.isActiveComponent(c.id)) {
                        registrationValues = registrationValues.concat(c.createRegistration(now, _this));
                    }
                });
                var directChildren = this.getDirectChildren(true);
                var childCarePlanActions = _(directChildren).map(function (relatedAction) {
                    return relatedAction.toServerWrite(permanent);
                });
                var result = {
                    patientId: this.patientId,
                    actionId: this.carePlanActionId,
                    eventDateTimeFrom: this.includesEventDate
                        ? this.includesEventTime
                            ? RemeCare.Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeFrom())
                            : RemeCare.Shared.DateHelper.toServerDateString(this.getEventDateTimeFrom())
                        : null,
                    eventDateTimeUntil: this.includesEventDate && this.isEventDateTimeRange
                        ? this.includesEventTime
                            ? RemeCare.Shared.DateHelper.toServerDateTimeString(this.getEventDateTimeUntil())
                            : RemeCare.Shared.DateHelper.toServerDateString(this.getEventDateTimeUntil())
                        : null,
                    partyRoleId: this.partyRole ? this.partyRole.Id : null,
                    location: this.location
                        ? {
                            city: this.location.City,
                            contactPointUsageId: this.location.ContactPointUsageId,
                            name: this.location.Name,
                            remark: this.location.Remark,
                            street: this.location.Street,
                            zipCode: this.location.ZipCode,
                        }
                        : null,
                    permanent: permanent,
                    registrationValues: registrationValues,
                    childActions: childCarePlanActions,
                    reschedule: null,
                };
                return result;
            };
            CarePlanAction.prototype.isComplete = function () {
                return this.status === RemeCare.Contract.Core.Codes.CarePlanActionStatus.Completed;
            };
            CarePlanAction.prototype.isActiveComponent = function (componentId) {
                var componentsWithConditions = _(this.components).filter(function (c) {
                    return c.actionTypeCode === RemeCare.Shared.Contract.Code.ActionType.QualitativeObservation &&
                        _(c.answerConditions).any(function (a) { return a.GoToId === componentId; });
                });
                if (componentsWithConditions.length === 0) {
                    return true;
                }
                return _(componentsWithConditions).any(function (c) {
                    return _.find(c.answerConditions, function (a) { return a.GoToId === componentId && _(c.value).contains(a.AnswerCodeSetItem.Id); }) != null;
                });
            };
            CarePlanAction.prototype.setAllUnrequired = function () {
                _(this.components).forEach(function (c) { return c.setUnrequired(); });
            };
            CarePlanAction.prototype.canEditItem = function () {
                return (this.maxExecutionRight & RemeCare.Shared.Framework.AuthRight.Create) !== 0;
            };
            CarePlanAction.prototype.canViewItem = function () {
                return (this.maxExecutionRight & RemeCare.Shared.Framework.AuthRight.Read) !== 0;
            };
            return CarePlanAction;
        }(RemeCare.Model.ActionBase));
        CarePlanAction_1.CarePlanAction = CarePlanAction;
        var ComponentDescriptor = /** @class */ (function () {
            function ComponentDescriptor(serverObject) {
                var _this = this;
                if (serverObject != null) {
                    this.actionTypeCode = serverObject.ActionType.Id;
                    this.sequence = serverObject.Sequence;
                    this.preceedingTitle = serverObject.PreceedingTitle;
                    this.guidanceEnabled = serverObject.GuidanceEnabled;
                    this.guidanceCode = serverObject.GuidanceCode;
                    this.textRegistration = serverObject.TextRegistration;
                    this.enabled = serverObject.Enabled;
                    this.id = serverObject.Id;
                    this.required = serverObject.Required;
                    this.requiredOverriden = serverObject.RequiredOverriden;
                    this.requiredForCodeSetItems = serverObject.RequiredForCodeSetItems;
                    this.width = serverObject.Width;
                    this.guidanceIconLocation = this.getGuidanceIconLocation(serverObject);
                    this.guidanceIconLocEnum = RemeCare.Shared.Contract.Code.GuidanceIconLocation;
                    this.guidanceFunction = function (uniqueId) { return _this.guidanceFunctionDef(uniqueId); };
                    this.showGuidance = false;
                }
            }
            ComponentDescriptor.prototype.guidanceFunction = function (uniqueId) { };
            ComponentDescriptor.prototype.setUnrequired = function () {
                this.required = false;
            };
            ComponentDescriptor.prototype.guidanceFunctionDef = function (uniqueId) {
                angular.element('#' + uniqueId).toggle();
            };
            ComponentDescriptor.prototype.createRegistrationValue = function (now, action) {
                return {
                    actionPartId: this.id,
                    registrationDateTime: RemeCare.Shared.DateHelper.toServerDateTimeString(now),
                    registrationSourceCode: RemeCare.Shared.Contract.Code.RegistrationSource.RemeCare,
                };
            };
            ComponentDescriptor.prototype.getGuidanceIconLocation = function (part) {
                if (part.GuidanceCode == null || part.GuidanceCode === '') {
                    return RemeCare.Shared.Contract.Code.GuidanceIconLocation.None;
                }
                if (part.PreceedingTitle != null && part.PreceedingTitle !== '') {
                    // This needs revision
                    return RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle;
                }
                if (part.TextRegistration != null && part.TextRegistration !== '') {
                    if (part.ActionType.Id === RemeCare.Shared.Contract.Code.ActionType.Administration) {
                        return RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label;
                    }
                    return RemeCare.Shared.Contract.Code.GuidanceIconLocation.TextRegistration;
                }
                return RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label;
            };
            return ComponentDescriptor;
        }());
        CarePlanAction_1.ComponentDescriptor = ComponentDescriptor;
        var AdministrationDescriptor = /** @class */ (function (_super) {
            __extends(AdministrationDescriptor, _super);
            function AdministrationDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.value = serverObject.ChoiceListParameter;
                    _this.medicationDoses = _(serverObject.MedicationDoses).map(function (x) { return new MedicationDose(x); });
                }
                return _this;
            }
            AdministrationDescriptor.prototype.clearValue = function () {
                this.value.ChoiceId = null;
            };
            AdministrationDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value.ChoiceId != null;
            };
            AdministrationDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                if (this.value) {
                    result.choiceListOptionId = this.value.ChoiceId;
                }
                return [result];
            };
            return AdministrationDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.AdministrationDescriptor = AdministrationDescriptor;
        var MedicationDose = /** @class */ (function () {
            function MedicationDose(serverObject) {
                if (serverObject != null) {
                    this.quantity = serverObject.Quantity;
                    this.unit = serverObject.Unit;
                    this.administrationTiming = serverObject.AdministrationTiming;
                    this.noEatingTimeBefore = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.NoEatingTimeBefore);
                    this.noEatingTimeAfter = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.NoEatingTimeAfter);
                    this.name = serverObject.ProductDescription;
                    this.personalisedTherapyActionId = serverObject.PersonalisedTherapyActionId;
                    this.solventToAddInformation = new RemeCare.Patient.Model.SolventToAddInformation(serverObject.SolventToAddInformation);
                    this.prescribedAdministrationInformation = new RemeCare.Patient.Model.PrescribedAdministrationInformation(serverObject.PrescribedAdministrationInformation);
                }
                else {
                    this.solventToAddInformation = new RemeCare.Patient.Model.SolventToAddInformation(null);
                    this.prescribedAdministrationInformation = new RemeCare.Patient.Model.PrescribedAdministrationInformation(null);
                }
            }
            return MedicationDose;
        }());
        CarePlanAction_1.MedicationDose = MedicationDose;
        var DateDescriptor = /** @class */ (function (_super) {
            __extends(DateDescriptor, _super);
            function DateDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.value = new DateInterval();
                if (serverObject != null) {
                    _this.value = new DateInterval(serverObject.DateParameter.Value);
                    _this.dateType = serverObject.DateParameter.DateType;
                    _this.hasTime = serverObject.DateParameter.HasTime;
                    _this.name = serverObject.DateParameter.Name;
                }
                return _this;
            }
            DateDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.date = RemeCare.Shared.DateHelper.toServerDateTimeString(this.value.fromDate);
                result.dateUntil = RemeCare.Shared.DateHelper.toServerDateTimeString(this.value.untilDate);
                return [result];
            };
            DateDescriptor.prototype.clearValue = function () {
                this.value.fromDate = null;
                this.value.untilDate = null;
            };
            DateDescriptor.prototype.hasValue = function () {
                return this.value != null && (this.value.fromDate != null || this.value.untilDate != null);
            };
            return DateDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.DateDescriptor = DateDescriptor;
        var DateInterval = /** @class */ (function () {
            function DateInterval(serverObject) {
                if (serverObject != null) {
                    this.fromDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.FromDate);
                    this.untilDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.UntilDate);
                }
            }
            return DateInterval;
        }());
        CarePlanAction_1.DateInterval = DateInterval;
        var MissingRegistrationsDescriptor = /** @class */ (function (_super) {
            __extends(MissingRegistrationsDescriptor, _super);
            function MissingRegistrationsDescriptor(authservice, serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.relatedActions = [];
                if (serverObject != null) {
                    _this.relatedActions = _(serverObject.RelatedActions)
                        .chain()
                        .map(function (c) { return new CarePlanAction(authservice, c); })
                        .sortBy(function (c) { return c.plannedDateTimeFrom; })
                        .value()
                        .reverse();
                    if (!_this.required) {
                        _(_this.relatedActions).forEach(function (ra) { return ra.setAllUnrequired(); });
                    }
                }
                return _this;
            }
            MissingRegistrationsDescriptor.prototype.createRegistration = function (now, action) {
                return [];
            };
            MissingRegistrationsDescriptor.prototype.clearValue = function () { };
            MissingRegistrationsDescriptor.prototype.hasValue = function () {
                return _(this.relatedActions).any(function (ra) { return ra.hasRegistrations(); });
            };
            MissingRegistrationsDescriptor.prototype.setUnrequired = function () {
                _super.prototype.setUnrequired.call(this);
                _(this.relatedActions).forEach(function (ra) { return ra.setAllUnrequired(); });
            };
            return MissingRegistrationsDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.MissingRegistrationsDescriptor = MissingRegistrationsDescriptor;
        var PhoneCallDescriptor = /** @class */ (function (_super) {
            __extends(PhoneCallDescriptor, _super);
            function PhoneCallDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.previousContacts = [];
                if (serverObject != null) {
                    _this.previousContacts = _(serverObject.PreviousContacts).map(function (p) { return new PreviousRemark(p); });
                    _this.maxNumberOfAttempts = serverObject.MaxNumberOfAttempts;
                }
                return _this;
            }
            PhoneCallDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.remark = this.value;
                return [result];
            };
            PhoneCallDescriptor.prototype.clearValue = function () {
                this.value = null;
            };
            PhoneCallDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value !== '';
            };
            return PhoneCallDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.PhoneCallDescriptor = PhoneCallDescriptor;
        var PreviousRemark = /** @class */ (function () {
            function PreviousRemark(serverObject) {
                this.remark = serverObject.Remark;
                this.registrationDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.RegistrationDate);
                this.actor = serverObject.Actor;
            }
            return PreviousRemark;
        }());
        CarePlanAction_1.PreviousRemark = PreviousRemark;
        var PreviousRegistrationDescriptor = /** @class */ (function (_super) {
            __extends(PreviousRegistrationDescriptor, _super);
            function PreviousRegistrationDescriptor(authservice, serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null && serverObject.RelatedCarePlanAction != null) {
                    _this.relatedCarePlanAction = new CarePlanAction(authservice, serverObject.RelatedCarePlanAction);
                    if (!_this.required) {
                        _this.relatedCarePlanAction.setAllUnrequired();
                    }
                }
                return _this;
            }
            PreviousRegistrationDescriptor.prototype.createRegistration = function (now, action) {
                return [];
            };
            PreviousRegistrationDescriptor.prototype.clearValue = function () { };
            PreviousRegistrationDescriptor.prototype.hasValue = function () {
                return this.relatedCarePlanAction.hasRegistrations();
            };
            PreviousRegistrationDescriptor.prototype.setUnrequired = function () {
                _super.prototype.setUnrequired.call(this);
                if (this.relatedCarePlanAction != null) {
                    // no child registrations yet
                    this.relatedCarePlanAction.setAllUnrequired();
                }
            };
            return PreviousRegistrationDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.PreviousRegistrationDescriptor = PreviousRegistrationDescriptor;
        var ProvideInformationObjectDescriptor = /** @class */ (function (_super) {
            __extends(ProvideInformationObjectDescriptor, _super);
            function ProvideInformationObjectDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.documentId = serverObject.InformationMessage.DocumentId;
                    _this.name = serverObject.InformationMessage.Name;
                    _this.description = serverObject.InformationMessage.Description;
                    _this.url = serverObject.InformationMessage.Url;
                    _this.informationObjectType = serverObject.InformationMessage.InformationObjectType;
                    _this.questions = _(serverObject.Questions).sortBy(function (q) { return q.Sequence; });
                    _this.questionHeader = serverObject.QuestionHeader;
                    _this.preceedingTitleMedia = serverObject.InformationMessage.PreceedingTitle;
                }
                return _this;
            }
            ProvideInformationObjectDescriptor.prototype.createRegistration = function (now, action) {
                var _this = this;
                var result = _(this.questions)
                    .chain()
                    .filter(function (q) { return _this.isActiveQuestion(q.Id); })
                    .map(function (question) {
                    var registration = _this.createRegistrationValue(now, action);
                    registration.mediaLibItemQuestionId = question.Id;
                    if (question.UIControlType === RemeCare.Shared.Contract.Code.UIControlType.Checkbox) {
                        registration.multiChoiceListOptionIds = question.SelectedValues;
                    }
                    else {
                        registration.choiceListOptionId = question.SelectedValues[0];
                    }
                    return registration;
                })
                    .value();
                return result;
            };
            ProvideInformationObjectDescriptor.prototype.clearValue = function () { };
            ProvideInformationObjectDescriptor.prototype.hasValue = function () {
                return false;
            };
            ProvideInformationObjectDescriptor.prototype.isActiveQuestion = function (questionId) {
                var referringQuestions = _(this.questions).filter(function (q) {
                    return _(q.AnswerConditions).any(function (ac) { return ac.GoToId === questionId; });
                });
                if (referringQuestions.length === 0) {
                    return true;
                }
                return _(referringQuestions).any(function (q) {
                    return _(q.AnswerConditions).any(function (ac) { return _(q.SelectedValues).contains(ac.AnswerCodeSetItem.Id); });
                });
            };
            return ProvideInformationObjectDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.ProvideInformationObjectDescriptor = ProvideInformationObjectDescriptor;
        var QualitativeObservationDescriptor = /** @class */ (function (_super) {
            __extends(QualitativeObservationDescriptor, _super);
            function QualitativeObservationDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.value = [];
                if (serverObject != null) {
                    _this.value = serverObject.ChoiceListParameter.ChoiceIds;
                    _this.optionGroupId = serverObject.ChoiceListParameter.ChoiceListId;
                    _this.answerConditions = serverObject.AnswerConditions;
                    _this.uiControlType = serverObject.UIControlType;
                    _this.observableEntity = serverObject.ObservableEntity;
                    _this.exceedsThreshold = serverObject.ExceedsThreshold;
                    _this.showObservableEntity = serverObject.ShowObservableEntity;
                    _this.guidanceIconLocation =
                        _this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                            serverObject.PreceedingTitle === '' &&
                            serverObject.TextRegistration === '' &&
                            serverObject.ShowObservableEntity &&
                            serverObject.ObservableEntity !== null &&
                            serverObject.ObservableEntity.Text !== null &&
                            serverObject.ObservableEntity.Text !== ''
                            ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label
                            : _this.guidanceIconLocation;
                }
                return _this;
            }
            QualitativeObservationDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                if (this.uiControlType === RemeCare.Shared.Contract.Code.UIControlType.CheckboxMultiple ||
                    this.uiControlType === RemeCare.Shared.Contract.Code.UIControlType.DropdownListMultiple) {
                    result.multiChoiceListOptionIds = this.value;
                }
                else {
                    result.choiceListOptionId = this.value[0];
                }
                return [result];
            };
            QualitativeObservationDescriptor.prototype.clearValue = function () {
                this.value = [];
            };
            QualitativeObservationDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value.length > 0;
            };
            return QualitativeObservationDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.QualitativeObservationDescriptor = QualitativeObservationDescriptor;
        var QuantitativeObservationDescriptor = /** @class */ (function (_super) {
            __extends(QuantitativeObservationDescriptor, _super);
            function QuantitativeObservationDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.value = serverObject.NumericCharacteristic.Value;
                    _this.unit = serverObject.NumericCharacteristic.Unit;
                    _this.characteristic = serverObject.NumericCharacteristic.Characteristic;
                    _this.characteristicId = serverObject.NumericCharacteristic.CharacteristicId;
                    _this.targetValue = serverObject.NumericCharacteristic.TargetValue;
                    _this.upperTargetValue = serverObject.NumericCharacteristic.UpperTargetValue;
                    _this.decimals = serverObject.NumericCharacteristic.Decimals;
                    _this.exceedsThreshold = serverObject.ExceedsThreshold;
                    _this.showCharacteristic = serverObject.ShowCharacteristic;
                    _this.minimumValue = serverObject.NumericCharacteristic.MinimumValue;
                    _this.maximumValue = serverObject.NumericCharacteristic.MaximumValue;
                    _this.guidanceIconLocation =
                        _this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                            serverObject.ShowCharacteristic &&
                            serverObject.PreceedingTitle === '' &&
                            serverObject.TextRegistration === '' &&
                            _this.characteristic !== null &&
                            _this.characteristic !== ''
                            ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.Label
                            : _this.guidanceIconLocation;
                    if (_this.minimumValue != null && _this.maximumValue != null) {
                        _this.minMaxPlaceHolder = _this.minimumValue + ' - ' + _this.maximumValue;
                    }
                    else if (_this.minimumValue != null) {
                        _this.minMaxPlaceHolder = '>=' + _this.minimumValue;
                    }
                    else if (_this.maximumValue != null) {
                        _this.minMaxPlaceHolder = '<=' + _this.maximumValue;
                    }
                    else {
                        _this.minMaxPlaceHolder = '';
                    }
                }
                return _this;
            }
            QuantitativeObservationDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.amount = this.value;
                return [result];
            };
            QuantitativeObservationDescriptor.prototype.clearValue = function () {
                this.value = null;
            };
            QuantitativeObservationDescriptor.prototype.hasValue = function () {
                return this.value != null;
            };
            return QuantitativeObservationDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.QuantitativeObservationDescriptor = QuantitativeObservationDescriptor;
        var RemarkDescriptor = /** @class */ (function (_super) {
            __extends(RemarkDescriptor, _super);
            function RemarkDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.value = serverObject.RemarkValue;
                    _this.hasStandardPhrases = serverObject.HasStandardPhrases;
                    _this.guidanceIconLocation =
                        _this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                            serverObject.PreceedingTitle != null &&
                            serverObject.PreceedingTitle !== ''
                            ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle
                            : _this.guidanceIconLocation;
                }
                return _this;
            }
            RemarkDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.remark = this.value;
                return [result];
            };
            RemarkDescriptor.prototype.clearValue = function () {
                this.value = null;
            };
            RemarkDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value !== '';
            };
            return RemarkDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.RemarkDescriptor = RemarkDescriptor;
        var SignatureDescriptor = /** @class */ (function (_super) {
            __extends(SignatureDescriptor, _super);
            function SignatureDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.signatureImage = serverObject.SignatureImage;
                    _this.guidanceIconLocation =
                        _this.guidanceIconLocation !== RemeCare.Shared.Contract.Code.GuidanceIconLocation.None &&
                            serverObject.PreceedingTitle != null &&
                            serverObject.PreceedingTitle !== ''
                            ? RemeCare.Shared.Contract.Code.GuidanceIconLocation.PreceedingTitle
                            : _this.guidanceIconLocation;
                }
                return _this;
            }
            SignatureDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.signatureImage = this.signatureImage;
                return [result];
            };
            SignatureDescriptor.prototype.clearValue = function () {
                this.signatureImage = null;
            };
            SignatureDescriptor.prototype.hasValue = function () {
                return !!this.signatureImage;
            };
            return SignatureDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.SignatureDescriptor = SignatureDescriptor;
        var RequestInformationObjectDescriptor = /** @class */ (function (_super) {
            __extends(RequestInformationObjectDescriptor, _super);
            function RequestInformationObjectDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.value = [];
                _this.requestInfoFileTypes = [];
                if (serverObject != null) {
                    _this.value = serverObject.RequestInfoParameter.Value;
                    _this.hasReferenceDate = serverObject.RequestInfoParameter.HasReferenceDate;
                    _this.requestInfoFileTypes = serverObject.RequestInfoParameter.RequestInfoFileTypes;
                }
                return _this;
            }
            RequestInformationObjectDescriptor.prototype.createRegistration = function (now, action) {
                var _this = this;
                var documentRegistrations = _.map(this.value, function (d) {
                    var result = _this.createRegistrationValue(now, action);
                    result.genericDocumentId = d.Id;
                    result.referenceDate = d.ReferenceDate;
                    result.fileName = d.Name;
                    return result;
                });
                return documentRegistrations;
            };
            RequestInformationObjectDescriptor.prototype.clearValue = function () {
                this.value = [];
            };
            RequestInformationObjectDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value.length > 0;
            };
            return RequestInformationObjectDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.RequestInformationObjectDescriptor = RequestInformationObjectDescriptor;
        var SetDetailsDescriptor = /** @class */ (function (_super) {
            __extends(SetDetailsDescriptor, _super);
            function SetDetailsDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.value = new RegistrationDetailsRelatedAction();
                if (serverObject != null) {
                    _this.hasLocation = serverObject.HasLocation;
                    _this.hasExecutor = serverObject.HasExecutor;
                    _this.hasTime = serverObject.SetDetailsParameter.HasTime;
                    _this.isWindow = serverObject.SetDetailsParameter.IsWindow;
                    _this.defaultLocation = serverObject.SetDetailsParameter.DefaultLocation;
                    _this.defaultDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.SetDetailsParameter.DefaultDate);
                    _this.defaultFromTime = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.SetDetailsParameter.DefaultFromTime);
                    _this.defaultUntilTime = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.SetDetailsParameter.DefaultUntilTime);
                    _this.relatedStructuralTherapyAction = serverObject.SetDetailsParameter.RelatedStructuralTherapyAction;
                    _this.defaultDurationOfAction = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.SetDetailsParameter.DefaultDurationOfAction);
                    _this.hasActionDurationOption = serverObject.HasActionDurationOption;
                    _this.defaultPossibleActionDurationOptionId =
                        serverObject.SetDetailsParameter.DefaultPossibleActionDurationOptionId;
                    _this.possibleActionDurationOptions = serverObject.PossibleActionDurationOptions;
                    _this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(_this.hasActionDurationOption, _this.defaultDurationOfAction, _this.possibleActionDurationOptions);
                    _this.value = new RegistrationDetailsRelatedAction(serverObject.SetDetailsParameter.Value);
                    if (!_this.value.plannedDate) {
                        _this.value.plannedDate = _this.defaultDate;
                        _this.value.plannedTimeFrom = _this.defaultFromTime;
                        _this.value.plannedTimeUntil = _this.defaultUntilTime;
                        _this.value.durationOfAction = _this.defaultDurationOfAction;
                        _this.value.possibleActionDurationOptionId = _this.defaultPossibleActionDurationOptionId;
                    }
                }
                return _this;
            }
            SetDetailsDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.partyRoleId = this.value.partyRoleId;
                result.plannedDateTimeFrom = RemeCare.Shared.DateHelper.toServerDateTimeString(this.value.getPlannedDateTimeFrom());
                result.plannedDateTimeUntil = RemeCare.Shared.DateHelper.toServerDateTimeString(this.value.getPlannedDateTimeUntil());
                if (this.value.hasLocation()) {
                    result.location = this.value.location;
                }
                result.possibleActionDurationOptionId = this.value.possibleActionDurationOptionId;
                result.durationOfAction = RemeCare.Shared.DateHelper.toServerTimeString(this.value.durationOfAction);
                return [result];
            };
            SetDetailsDescriptor.prototype.clearValue = function () { };
            SetDetailsDescriptor.prototype.hasValue = function () {
                return this.value != null && this.value.hasValue();
            };
            return SetDetailsDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.SetDetailsDescriptor = SetDetailsDescriptor;
        var RegistrationDetailsRelatedAction = /** @class */ (function () {
            function RegistrationDetailsRelatedAction(serverObject) {
                this.location = {};
                if (serverObject != null) {
                    this.partyRoleId = serverObject.PartyRoleId;
                    this.plannedDate = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeFrom);
                    this.plannedTimeFrom = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeFrom);
                    this.plannedTimeUntil = RemeCare.Shared.DateHelper.serverDateStringToDateTime(serverObject.PlannedDateTimeUntil);
                    this.durationOfAction = RemeCare.Shared.DateHelper.serverTimeToDate(serverObject.DurationOfAction);
                    this.possibleActionDurationOptionId = serverObject.PossibleActionDurationOptionId;
                    this.location = serverObject.Location || {};
                }
            }
            RegistrationDetailsRelatedAction.prototype.hasLocation = function () {
                return (this.location.ContactPointUsageId != null ||
                    this.location.Name != null ||
                    this.location.City != null ||
                    this.location.Street != null ||
                    this.location.ZipCode != null);
            };
            RegistrationDetailsRelatedAction.prototype.getPlannedDateTimeFrom = function () {
                var result = moment(this.plannedDate).toDate();
                RemeCare.Shared.DateHelper.setTime(result, this.plannedTimeFrom);
                return result;
            };
            RegistrationDetailsRelatedAction.prototype.getPlannedDateTimeUntil = function () {
                var result = moment(this.plannedDate).toDate();
                RemeCare.Shared.DateHelper.setTime(result, this.plannedTimeUntil);
                return result;
            };
            RegistrationDetailsRelatedAction.prototype.hasValue = function () {
                return (this.partyRoleId != null ||
                    this.plannedDate != null ||
                    this.plannedTimeFrom != null ||
                    this.plannedTimeUntil != null ||
                    this.hasLocation());
            };
            return RegistrationDetailsRelatedAction;
        }());
        CarePlanAction_1.RegistrationDetailsRelatedAction = RegistrationDetailsRelatedAction;
        var CareActDescriptor = /** @class */ (function (_super) {
            __extends(CareActDescriptor, _super);
            function CareActDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.hasLocation = serverObject.HasLocation;
                    _this.hasExecutor = serverObject.HasExecutor;
                    _this.hasActionDurationOption = serverObject.HasActionDurationOption;
                    _this.possibleActionDurationOptions = serverObject.PossibleActionDurationOptions;
                    if (serverObject.ChoiceListParameter) {
                        _this.value = serverObject.ChoiceListParameter.ChoiceId;
                        _this.choiceListId = serverObject.ChoiceListParameter.ChoiceListId;
                    }
                    _this.showActionDuration = RemeCare.Framework.Helpers.calculateShowActionDuration(_this.hasActionDurationOption, null, _this.possibleActionDurationOptions);
                }
                return _this;
            }
            CareActDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.choiceListOptionId = this.value;
                return [result];
            };
            CareActDescriptor.prototype.clearValue = function () {
                this.value = null;
            };
            CareActDescriptor.prototype.hasValue = function () {
                return this.value != null;
            };
            return CareActDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.CareActDescriptor = CareActDescriptor;
        var AberrantRegistrationsDescriptor = /** @class */ (function (_super) {
            __extends(AberrantRegistrationsDescriptor, _super);
            function AberrantRegistrationsDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                if (serverObject != null) {
                    _this.aberrantRegistrationValueIds = serverObject.AberrantRegistrationValueIds;
                }
                return _this;
            }
            AberrantRegistrationsDescriptor.prototype.createRegistration = function (now, action) {
                var _this = this;
                return _(this.aberrantRegistrationValueIds).map(function (rvId) {
                    var result = _this.createRegistrationValue(now, action);
                    result.aberrantRegistrationValueId = rvId;
                    return result;
                });
            };
            AberrantRegistrationsDescriptor.prototype.clearValue = function () { };
            AberrantRegistrationsDescriptor.prototype.hasValue = function () {
                return false;
            };
            AberrantRegistrationsDescriptor.prototype.setUnrequired = function () {
                _super.prototype.setUnrequired.call(this);
            };
            return AberrantRegistrationsDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.AberrantRegistrationsDescriptor = AberrantRegistrationsDescriptor;
        var MedicationSchemaDescriptor = /** @class */ (function (_super) {
            __extends(MedicationSchemaDescriptor, _super);
            function MedicationSchemaDescriptor(serverObject) {
                var _this = _super.call(this, serverObject) || this;
                _this.value = {
                    modified: [],
                    deleted: [],
                };
                if (serverObject) {
                    _this.prescriptions = serverObject.Prescriptions;
                    _this.medicationSchemaTimestamp = serverObject.MedicationSchemaTimestamp;
                }
                return _this;
            }
            MedicationSchemaDescriptor.prototype.createRegistration = function (now, action) {
                var result = this.createRegistrationValue(now, action);
                result.prescriptions = _(this.value.modified).map(function (p) { return p.toWriteModel(); });
                result.deletedPrescriptions = _(this.value.deleted).map(function (p) { return p.id; });
                result.medicationSchemaTimestamp = this.medicationSchemaTimestamp;
                return [result];
            };
            MedicationSchemaDescriptor.prototype.clearValue = function () {
                this.value = {
                    modified: [],
                    deleted: [],
                };
            };
            MedicationSchemaDescriptor.prototype.hasValue = function () {
                return this.value.modified.length > 0 || this.value.deleted.length > 0;
            };
            MedicationSchemaDescriptor.prototype.addOrReplace = function (prescription) {
                this.removeFromModified(prescription);
                this.value.modified.push(prescription);
            };
            MedicationSchemaDescriptor.prototype.delete = function (prescription) {
                this.removeFromModified(prescription);
                this.value.deleted.push(prescription);
            };
            MedicationSchemaDescriptor.prototype.removeFromModified = function (prescription) {
                var existing;
                if (!prescription.isFollowedUp) {
                    existing = _.find(this.value.modified, function (p) { return p.id === prescription.id; });
                }
                else {
                    if (moment(prescription.validFromDate).isBefore(RemeCare.Shared.DateHelper.tomorrow())) {
                        existing = _.find(this.value.modified, function (p) {
                            return p.id === prescription.id &&
                                p.validFromDate.valueOf() === prescription.validFromDate.valueOf();
                        });
                    }
                    else {
                        existing = _.find(this.value.modified, function (p) {
                            return p.id === prescription.id && !moment(p.validFromDate).isBefore(RemeCare.Shared.DateHelper.tomorrow());
                        });
                    }
                }
                if (existing != null) {
                    var index = this.value.modified.indexOf(existing);
                    this.value.modified.splice(index, 1);
                }
            };
            return MedicationSchemaDescriptor;
        }(ComponentDescriptor));
        CarePlanAction_1.MedicationSchemaDescriptor = MedicationSchemaDescriptor;
    })(CarePlanAction = RemeCare.CarePlanAction || (RemeCare.CarePlanAction = {}));
})(RemeCare || (RemeCare = {}));
