/// <reference path="careRequestPartDirectiveBase.ts"/>

module RemeCare.CareRequest.Directive {
    import EntityTranslation = Shared.Contract.IEntityTranslation;

    interface IPatientNameScope extends ICareRequestPartBaseScope {
        patient: {
            lastName: string;
            firstName: string;
        };

        carePlans: Array<EntityTranslation>;
    }

    class PatientNameController extends CareRequestPartControllerBase<IPatientNameScope> {

        constructor(
            $scope: IPatientNameScope,
            $translate,
            toaster,
            private patientSvc: Patient.PatientService,
            careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            this.$onInit();
        }

        public $onInit(): void {
            this.patientSvc.getContactDetails(this.$scope.careRequest.matchedPatientId)
                .success((ags) => {
                    this.$scope.patient = {
                        firstName: ags.FirstName,
                        lastName: ags.LastName
                    };
                    this.$scope.careRequest.matchedPartyId = ags.PartyId;
                })
                .error((m) => this.errorCallback(m));
            if (this.$scope.careRequestTemplate.isFinal && this.$scope.careRequestTemplate.careRequestStepType.Id === Shared.Contract.Code.CareRequestStepType.Acceptance) {
                this.getCarePlansForPatient(this.$scope.careRequest.matchedPatientId);
            }
        }

        private getCarePlansForPatient(patientId: Shared.Contract.Guid) {
            this.patientSvc.getCarePlans(patientId)
                .success((ags) => {
                    this.$scope.carePlans = _(ags).map(x => new Model.Careplan(x)).map(x => <EntityTranslation>{
                        Id: x.id,
                        Text: x.statusOpen ? x.name + this.$translate.instant('Views.PatientFile.CarePlans.StatusActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStartDate() })
                            : x.name + this.$translate.instant('Views.PatientFile.CarePlans.StatusNotActive', { CarePlanStatus: x.status, StatusDate: x.getFormattedStatusChangedDate() })
                    });
                })
                .error((m) => this.errorCallback(m));
        }
    }

    class PatientNameDirective extends CareRequestPartDirectiveBase {

        controller = ['$scope', '$translate', 'toaster', 'patientSvc',
            ($scope, $translate, toaster, careRequestSvc, patientSvc) =>
                new PatientNameController($scope, $translate, toaster, careRequestSvc, patientSvc)];

        templateUrl = 'views/careRequest/directive/patientName.html';
    }

    remeCareCareRequestModule.directive('rcCareRequestPatientName', () => new PatientNameDirective());
}