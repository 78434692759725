var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var RemeCare;
(function (RemeCare) {
    var Management;
    (function (Management) {
        var UserDetailsController = /** @class */ (function () {
            function UserDetailsController($dialog, $state, modalBuilderFactory, partyApiSvc, securityApiSvc, toaster, $translate) {
                this.$dialog = $dialog;
                this.$state = $state;
                this.modalBuilderFactory = modalBuilderFactory;
                this.partyApiSvc = partyApiSvc;
                this.securityApiSvc = securityApiSvc;
                this.toaster = toaster;
                this.$translate = $translate;
            }
            UserDetailsController.prototype.$onInit = function () {
                this.userLoaded = false;
                this.isEditingUserName = false;
                this.newUserName = "";
                this.loadDataAsync();
            };
            UserDetailsController.prototype.loadDataAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = this;
                                return [4 /*yield*/, this.securityApiSvc.getUserDetailsAsync(this.partyId)];
                            case 1:
                                _a.user = _b.sent();
                                this.userLoaded = true;
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _b.sent();
                                this.toaster.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            };
            UserDetailsController.prototype.$onChanges = function (onChangesObj) {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(onChangesObj['person'] && onChangesObj['person'].currentValue != null)) return [3 /*break*/, 4];
                                this.providersLoading = true;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, , 3, 4]);
                                return [4 /*yield*/, this.getExternalIdentityProvidersAsync()];
                            case 2:
                                _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                this.providersLoading = false;
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            };
            UserDetailsController.prototype.unlinkProvider = function (provider) {
                return __awaiter(this, void 0, void 0, function () {
                    var e_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 4, , 5]);
                                return [4 /*yield*/, this.$dialog.confirm('Views.Management.Persons.UnlinkProvider', 'Views.Management.Persons.UnlinkProviderConfirm', {
                                        isWarning: true,
                                        messageValues: {
                                            providerName: provider.ExternalIdentityProvider.Name,
                                        },
                                    })];
                            case 1:
                                _a.sent();
                                this.providersLoading = true;
                                return [4 /*yield*/, this.partyApiSvc.unlinkPersonFromExternalIdentityProviderAsync(this.partyId, provider.ExternalIdentityProvider.Id)];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, this.getExternalIdentityProvidersAsync()];
                            case 3:
                                _a.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_2 = _a.sent();
                                this.toaster.error(e_2);
                                return [3 /*break*/, 5];
                            case 5:
                                this.providersLoading = false;
                                return [2 /*return*/];
                        }
                    });
                });
            };
            UserDetailsController.prototype.addUser = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('addUserCtrl')
                    .setTemplateUrl('views/management/persons/userDetails/addUser.html')
                    .setResultCallBack(function (r) { return _this.$state.reload(); })
                    .setResolve({
                    partyId: function () { return _this.partyId; },
                    firstName: function () { return _this.person.firstName; },
                    lastName: function () { return _this.person.lastName; },
                })
                    .build();
            };
            UserDetailsController.prototype.manageApplicationProfiles = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('applicationProfileAssignmentsCtrl')
                    .setTemplateUrl('views/management/persons/userDetails/applicationProfileAssignments.html')
                    .setResultCallBack(function () { return _this.$onInit(); })
                    .setResolve({
                    person: function () { return _this.person; },
                })
                    .setSize(RemeCare.Shared.Framework.Helper.ModalSize.large)
                    .build();
            };
            UserDetailsController.prototype.sendResetMail = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('sendResetMailCtrl')
                    .setTemplateUrl('views/management/persons/userDetails/sendResetMail.html')
                    .setResolve({
                    partyId: function () { return _this.partyId; },
                })
                    .build();
            };
            UserDetailsController.prototype.toggleUserState = function () {
                var _this = this;
                var userDetails = angular.copy(this.user);
                userDetails.StatusChangeReason = null;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('toggleUserStateCtrl')
                    .setTemplateUrl('views/management/persons/userDetails/toggleUserState.html')
                    .setScope({
                    userDetails: userDetails,
                })
                    .setResolve({
                    partyId: function () { return _this.partyId; },
                })
                    .setResultCallBack(function (userDetails) { return (_this.user = userDetails); })
                    .build();
            };
            UserDetailsController.prototype.showStatusHistory = function () {
                var _this = this;
                this.modalBuilderFactory
                    .createModalBuilder()
                    .setController('userAccountHistoryCtrl')
                    .setTemplateUrl('views/management/persons/userDetails/userAccountHistory.html')
                    .setScope({
                    name: this.person.firstName + " " + this.person.lastName,
                })
                    .setResolve({
                    partyId: function () { return _this.partyId; },
                })
                    .build();
            };
            UserDetailsController.prototype.changeUserName = function () {
                this.isEditingUserName = true;
            };
            UserDetailsController.prototype.saveUserName = function () {
                var _this = this;
                if (!this.userNameForm.$valid) {
                    this.userNameForm.username.$setDirty();
                    return;
                }
                this.securityApiSvc.checkUserNameExists(this.newUserName)
                    .success(function (r) {
                    if (r !== _this.newUserName) {
                        _this.userNameProposal = r;
                    }
                    else {
                        _this.userNameProposal = undefined;
                        _this.securityApiSvc.changeUsername(_this.partyId, _this.newUserName, _this.changeUsernameSendMailToUser)
                            .success(function () {
                            _this.isEditingUserName = false;
                            _this.user.UserName = _this.newUserName;
                            _this.toaster.success(_this.$translate.instant('General.SaveSuccess'));
                        })
                            .error(function (e) {
                            return _this.toaster.error(e);
                        });
                    }
                })
                    .error(function (e) {
                    return _this.toaster.error(e);
                });
            };
            UserDetailsController.prototype.getExternalIdentityProvidersAsync = function () {
                return __awaiter(this, void 0, void 0, function () {
                    var externalProviderPromises, retrievedProviders, _a, e_3;
                    var _this = this;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                externalProviderPromises = this.person
                                    .getCurrentRelations()
                                    .map(function (cr) { return cr.HealthCareOrganisation.PartyId; })
                                    .map(function (orgId) { return _this.partyApiSvc.getLinkedExternalIdentityProvidersAsync(orgId); });
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, Promise.all(externalProviderPromises)];
                            case 2:
                                retrievedProviders = _b.sent();
                                _a = this;
                                return [4 /*yield*/, this.partyApiSvc.getExternalIdentityProvidersForPersonAsync(this.partyId)];
                            case 3:
                                _a.linkedProviders = _b.sent();
                                this.possibleProviders = _.chain(retrievedProviders)
                                    .flatten()
                                    .filter(function (provider) { return !_.any(_this.linkedProviders, function (lp) { return lp.ExternalIdentityProvider.Id === provider.Id; }); })
                                    .value();
                                return [3 /*break*/, 5];
                            case 4:
                                e_3 = _b.sent();
                                this.toaster.error(e_3);
                                return [3 /*break*/, 5];
                            case 5: return [2 /*return*/];
                        }
                    });
                });
            };
            return UserDetailsController;
        }());
        Management.remeCareManagementModule.component('rcUserDetails', {
            bindings: {
                partyId: '<',
                person: '<',
            },
            controller: UserDetailsController,
            templateUrl: 'views/management/persons/userDetails/userDetails.html',
        });
    })(Management = RemeCare.Management || (RemeCare.Management = {}));
})(RemeCare || (RemeCare = {}));
