module RemeCare.CarePlanAction {
    interface ILocationScope extends Shared.Framework.IBaseScope, ng.ui.bootstrap.IModalScope {
        patientId: Shared.Contract.Guid;
        tabs: Array<{
            heading: string;
            src: string;
        }>;
        oneTimeAddress: Contract.Party.Read.IAddress;
        patientAddressesGrid: Shared.Framework.Grid.Grid<Contract.Party.Read.IAddress>;
        searchGrid: Shared.Framework.Grid.Grid<Contract.Party.Read.IAddress>;
        partyRoleTypes: Array<Shared.Contract.IEnumTranslation>;
        specialties: Array<Shared.Contract.IEnumTranslation>;
        organisationTypes: Array<Shared.Contract.IEnumTranslation>;
        searchAddresses(): void;
    }

    class LocationController extends Shared.Framework.ControllerBase<ILocationScope> {
        constructor(
            protected $scope: ILocationScope,
            protected $translate,
            protected toaster,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly masterdataSvc: Shared.Framework.MasterdataService,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService
        ) {
            super($scope, $translate, toaster);
            $scope.searchAddresses = () => this.searchAddresses();
        }

        public async $onInit(): Promise<void> {
            this.buildPatientAddressesGrid();
            this.buildSearchGrid();
            this.$scope.tabs = [
                {
                    heading: 'Views.Action.Location.PatientAddresses',
                    src: 'views/carePlanAction/location/patientAddress.html'
                },
                {
                    heading: 'Views.Action.Location.SearchAddress',
                    src: 'views/carePlanAction/location/searchAddress.html'
                },
                {
                    heading: 'Views.Action.Location.CustomAddress',
                    src: 'views/carePlanAction/location/customAddress.html'
                }
            ];
            this.$scope.oneTimeAddress = <Contract.Party.Read.IAddress>{};
            try {
                [
                    this.$scope.specialties,
                    this.$scope.partyRoleTypes,
                    this.$scope.organisationTypes
                ] = await Promise.all([
                    this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync(),
                    this.masterdataSvc.getPartyRoleTypesAsync(),
                    this.healthCarePartySvc.getHealthCareOrganisationTypesAsync()
                ]);
            } catch (e) {
                this.toaster.error(e);
            }
            this.$scope.$watch(
                (s: ILocationScope) => s.searchGrid.searchCriteria.partyRoleType,
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        if (
                            newValue === Shared.Contract.Code.PartyRoleType.Patient ||
                            newValue === Shared.Contract.Code.PartyRoleType.ContactPerson
                        ) {
                            this.$scope.searchGrid.searchCriteria.partyRoleId = this.$scope.patientId;
                        } else {
                            this.$scope.searchGrid.searchCriteria.partyRoleId = null;
                        }
                        if (newValue !== Shared.Contract.Code.PartyRoleType.HealthCareOrganisation) {
                            this.$scope.searchGrid.searchCriteria.organisationTypeId = null;
                        }
                        if (newValue !== Shared.Contract.Code.PartyRoleType.HealthCareProfessional) {
                            this.$scope.searchGrid.searchCriteria.specialtyId = null;
                        }
                    }
                }
            );
        }

        private searchAddresses(): void {
            this.$scope.searchGrid.pagingOptions.currentPage = 1;
            this.$scope.searchGrid.search();
        }

        private async searchAsync(
            page: number,
            pageSize: number,
            sortField: string,
            sortDirection: string,
            criteria: any
        ): Promise<Shared.Contract.ISearchResult<Contract.Party.Read.IAddress>> {
            const query = <Contract.Party.Read.Query.IFindAddressesQuery>{
                page: page,
                pageSize: pageSize,
                sortField: sortField,
                sortOrder: sortDirection,
                partyRoleId: criteria.partyRoleId,
                partyRoleType: criteria.partyRoleType,
                organisationTypeId: criteria.organisationTypeId,
                specialtyId: criteria.specialtyId,
                name: criteria.name,
                firstName: criteria.firstName,
                street: criteria.street,
                zipCode: criteria.zipCode,
                city: criteria.city
            };
            try {
                const address = await this.partyApiSvc.findAddressAsync(query);
                address.Items = _(address.Items).map(i => {
                    (<any>i).ZipCity = `${i.ZipCode || ''} ${i.City || ''}`;
                    return i;
                });
                return address;
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private buildSearchGrid(): void {
            this.$scope.searchGrid = this.buildAddressesGrid((page, pageSize, sortField, sortDirection, criteria) =>
                this.searchAsync(page, pageSize, sortField, sortDirection, criteria)
            );
        }

        private buildPatientAddressesGrid(): void {
            this.$scope.patientAddressesGrid = this.buildAddressesGrid((page, pageSize, sortField, sortDirection) =>
                this.searchAsync(page, pageSize, sortField, sortDirection, {
                    partyRoleId: this.$scope.patientId
                })
            );
            this.$scope.patientAddressesGrid.search();
        }

        private buildAddressesGrid(
            searchFunction: (
                page: number,
                pageSize: number,
                sortField: string,
                sortDirection: string,
                criteria: any
            ) => Promise<Shared.Contract.ISearchResult<Contract.Party.Read.IAddress>>
        ): Shared.Framework.Grid.Grid<Contract.Party.Read.IAddress> {
            const grid = this.gridBuilderSvc
                .createGridBuilder<Contract.Party.Read.IAddress>(searchFunction)
                .addColumn('ContactPointUsageType.Text', 'General.AddressType', { width: 100 })
                .addColumn('Name', 'General.Name', { width: '**' })
                .addColumn('AddressLine1', 'General.AddressLine1', { width: '***' })
                .addColumn('ZipCity', 'General.City', { width: '**' })
                .addSelectButtonColumn(r => this.$scope.$close(r))
                .setRowDetailsTemplate('views/carePlanAction/location/locationDetails.html', 160)
                .build();
            grid.pagingOptions.pageSize = 5;
            return grid;
        }
    }

    angular.module('RemeCare.CarePlanAction').controller('locationCtrl', LocationController);
}
