namespace RemeCare.CarePlanAction {
    remeCareCarePlanActionModule.component('rcComponentHeader', {
        bindings: {
            component: '<',
            showRequired: '<',
            uniqueId: '@',
        },
        templateUrl: 'views/carePlanAction/directive/componentHeader.html',
    });
}
