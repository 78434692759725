module RemeCare.Agenda {

    import EnumTranslation = Shared.Contract.IEnumTranslation;;
    import MasterDataService = Shared.Framework.MasterdataService;

    class ColleagueAgendaFilterController implements ng.IComponentController {

        // bindings
        public filter: IAgendaFilter;

        public actionStatuses: Array<EnumTranslation>;

        constructor(
            private readonly masterdataSvc: MasterDataService,
            private readonly toaster: Shared.Framework.Toaster
        ) { }

        public async $onInit(): Promise<void> {
            try {
                this.actionStatuses = await this.masterdataSvc.getCarePlanActionStatuses();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        public clearFilter(): void {
            this.filter.actor = null;
            this.filter.statuses = [];
        }
    }

    remeCareAgendaModule.component('rcColleagueAgendaFilter', {
        bindings: {
            $close: '&',
            $dismiss: '&',
            filter: '<'
        },
        controller: ColleagueAgendaFilterController,
        templateUrl: 'agenda/colleagueAgenda/colleagueAgendaFilter/colleagueAgendaFilter.html'
    });
}