/// <reference path="careRequestPartDirectiveBase.ts"/>

namespace RemeCare.CareRequest.Directive {
    import PartyRoleSummary = Contract.Party.Read.IPartyRoleSummary;
    import IHealthCareProfessionalParty = Contract.HealthCareParty.Read.IHealthCareProfessionalParty;

    interface ICareRequestorAcceptanceScope extends ICareRequestPartBaseScope {
        careRequestPart: Shared.Framework.Model.CareRequestorDefinition;
        professionals: PartyRoleSummary[];
        professional: Model.HealthCareProfessional;
        organisations: PartyRoleSummary[];
        organisation: Contract.HealthCareParty.Read.IHealthCareOrganisationParty;
        organisationName: string;
        specialties: Shared.Contract.IEnumTranslation[];
        intermediaryInfo: {
            organisation: boolean;
        };
        grid: Shared.Framework.Grid.Grid<CareRequestorInfo>;

        requestorRequired(): boolean;
        getName(): string;
        isProfessionalValid(): boolean;
        isOrganisationValid(): boolean;
        clicked(): void;
    }

    class CareRequestorAcceptanceController extends CareRequestPartControllerBase<ICareRequestorAcceptanceScope> {
        // @ngInject
        constructor(
            protected $scope: ICareRequestorAcceptanceScope,
            private readonly $rootScope: ng.IRootScopeService,
            protected $translate: ng.translate.ITranslateService,
            protected toaster: Shared.Framework.Toaster,
            private readonly gridBuilderSvc: Shared.Framework.Grid.GridBuilderFactory,
            private readonly healthCareProfessionalSearchSvc: Core.Services.HealthCareProfessionalSearchSvc,
            private readonly healthCareOrganisationSearchSvc: Core.Services.HealthCareOrganisationSearchSvc,
            private readonly modalBuilderFactory: Shared.Framework.Helper.ModalBuilderFactory,
            private readonly partyApiSvc: Core.Services.PartyApiService,
            private readonly healthCarePartySvc: Shared.Framework.HealthCarePartyService,
            protected careRequestSvc: CareRequestSvc
        ) {
            super($scope, $translate, toaster, careRequestSvc);
            $scope.clicked = () => this.clicked();
            $scope.requestorRequired = () => this.requestorRequired();
            $scope.isProfessionalValid = () => this.isProfessionalValid();
            $scope.isOrganisationValid = () => this.isOrganisationValid();
            $scope.getName = () => this.getName();
            this.$rootScope.$on('CAREREQUEST_ROLE_SELECT', (event, role: IApplyForRole) => {
                if (
                    role.rolePart !== RolePart.CareRequestorRole ||
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId !== role.actorRoleId
                ) {
                    return;
                }

                this.$scope.careRequest.careRequestor.healthCareProfessionalId = role.party.PartyRoleId;
                if (role.organization) {
                    this.$scope.careRequest.careRequestor.healthCareOrganisationId = role.organization.PartyRoleId;
                }
                this.recalculateCareRequestorGridData();
            });
            this.$rootScope.$on('CAREREQUEST_ROLE_UNSELECT', (event, role: IApplyForRole) => {
                if (
                    role.rolePart !== RolePart.CareRequestorRole ||
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.actorRoleId !== role.actorRoleId
                ) {
                    return;
                }

                this.$scope.careRequest.careRequestor.healthCareProfessionalId = null;
                this.$scope.careRequest.careRequestor.healthCareOrganisationId = null;
                this.recalculateCareRequestorGridData();
            });
            this.$onInit();
        }

        public $onInit(): void {
            this.loadDataAsync();
            this.buildGrid();
            this.recalculateCareRequestorGridData();
        }

        private recalculateCareRequestorGridData(): void {
            if (this.$scope.careRequest.careRequestor.healthCareProfessionalId != null) {
                this.loadProfessionalAsync();
            } else {
                this.setCareRequestorInfoAsync();
            }
        }

        private async loadDataAsync(): Promise<void> {
            try {
                this.$scope.specialties = await this.healthCarePartySvc.getHealthCareSpecialtyProfessionsAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async loadProfessionalAsync(): Promise<void> {
            try {
                this.$scope.professional = new Model.HealthCareProfessional(
                    await this.healthCareProfessionalSearchSvc.getHealthCareProfessional(
                        this.$scope.careRequest.careRequestor.healthCareProfessionalId
                    )
                );
                this.setCareRequestorInfoAsync();
            } catch (e) {
                this.toaster.error(e);
            }
        }

        private async setCareRequestorInfoAsync(): Promise<void> {
            const careRequestorInfos: CareRequestorInfo[] = [];
            if (this.$scope.careRequestPart.roleRequestingHealthCareProfessional) {
                const professional = await this.getCareRequestorInfoProfessional();
                if (professional) {
                    careRequestorInfos.push(professional);
                }
            }

            if (this.$scope.careRequestPart.roleRequestingHealthCareOrganisation) {
                const organisation = await this.getCareRequestorInfoOrganisation();
                if (organisation) {
                    careRequestorInfos.push(organisation);
                }
            }

            this.$scope.grid.setData(careRequestorInfos);
        }

        private async getCareRequestorInfoOrganisation(): Promise<CareRequestorInfo> {
            let toReturn: CareRequestorInfo = null;
            if (this.$scope.careRequest.careRequestor.healthCareOrganisationId) {
                const organisation = await this.healthCareOrganisationSearchSvc.searchOrganisationsAsync({
                    partyRoleId: this.$scope.careRequest.careRequestor.healthCareOrganisationId,
                });
                if (organisation.Items && organisation.Items.length > 0) {
                    toReturn = new CareRequestorInfo(
                        this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name,
                        true,
                        null,
                        null,
                        null,
                        organisation.Items[0]
                    );
                }
            }

            return toReturn;
        }

        private async getCareRequestorInfoProfessional(): Promise<CareRequestorInfo> {
            let toReturn: CareRequestorInfo = null;
            if (this.$scope.careRequest.careRequestor.healthCareProfessionalId) {
                const result = await this.partyApiSvc.findPersonsAsync({
                    partyRoleIds: [this.$scope.careRequest.careRequestor.healthCareProfessionalId],
                });
                if (result.Items && result.Items.length > 0) {
                    toReturn = new CareRequestorInfo(
                        this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name,
                        true,
                        result.Items[0]
                    );
                }
            } else if (
                this.$scope.careRequest.careRequestor.responsible.firstName ||
                this.$scope.careRequest.careRequestor.responsible.lastName
            ) {
                toReturn = new CareRequestorInfo(
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name,
                    false,
                    null,
                    null,
                    this.$scope.careRequest.careRequestor.responsible
                );
            }

            return toReturn;
        }

        private isProfessionalValid(): boolean {
            return (
                !this.requestorProfessionalRequired() ||
                !!this.$scope.careRequest.careRequestor.healthCareProfessionalId
            );
        }

        private isOrganisationValid(): boolean {
            return (
                !this.requestorOrganisationRequired() ||
                !!this.$scope.careRequest.careRequestor.healthCareOrganisationId
            );
        }

        private getName(): string {
            if (this.$scope.careRequestPart.roleRequestingHealthCareProfessional) {
                return this.$scope.careRequestPart.roleRequestingHealthCareProfessional.name;
            }

            return this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.name;
        }

        private requestorRequired(): boolean {
            return this.requestorProfessionalRequired() || this.requestorOrganisationRequired();
        }

        private requestorProfessionalRequired(): boolean {
            return (
                this.isRequired(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional) ||
                (this.$scope.careRequestPart.roleRequestingHealthCareProfessional &&
                    this.$scope.careRequestPart.roleRequestingHealthCareProfessional.minimalNumber > 0 &&
                    this.isPresent(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCProfessional))
            );
        }

        private requestorOrganisationRequired(): boolean {
            return (
                this.isRequired(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation) ||
                (this.$scope.careRequestPart.roleRequestingHealthCareOrganisation &&
                    this.$scope.careRequestPart.roleRequestingHealthCareOrganisation.minimalNumber > 0 &&
                    this.isPresent(Shared.Contract.Code.CareRequestUIControlType.CareRequestorHCOrganisation))
            );
        }

        private buildGrid(): void {
            const gridBuilder = this.gridBuilderSvc
                .createGridBuilder<CareRequestorInfo>()
                .addColumn('name', 'General.Name')
                .addColumn('roleName', 'General.Role')
                .addBoolColumn('linked', 'General.Linked')
                .setRowDetailsTemplate('views/careRequest/directive/contactDetailsRequestor.html', 150);
            this.$scope.grid = gridBuilder.build();
        }

        private clicked(): void {
            this.modalBuilderFactory
                .createModalBuilder<void>()
                .setController('rcCareRequestCareRequestorAcceptanceModalController')
                .setTemplateUrl('views/careRequest/directive/careRequestor/careRequestorAcceptanceModal.html')
                .setScope({
                    careRequestPart: this.$scope.careRequestPart,
                    professional: this.$scope.professional,
                    organisations: this.$scope.organisations,
                    organisation: this.$scope.organisations,
                    organisationName: this.$scope.organisationName,
                    specialties: this.$scope.specialties,
                    intermediaryInfo: this.$scope.intermediaryInfo,
                    careRequest: this.$scope.careRequest,
                })
                .setSize(Shared.Framework.Helper.ModalSize.large)
                .setResultCallBack(() => {
                    this.setCareRequestorInfoAsync();
                })
                .build();
        }
    }

    class CareRequestorAcceptanceDirective extends CareRequestPartDirectiveBase {
        public controller = CareRequestorAcceptanceController;

        public templateUrl = 'views/careRequest/directive/careRequestor/careRequestorAcceptance.html';
    }

    remeCareCareRequestModule.directive(
        'rcCareRequestCareRequestorAcceptance',
        () => new CareRequestorAcceptanceDirective()
    );
}
